import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../../core/shared/data-tables-response.model";
import { WelfareEJIPTransaction } from "./welfare-ejip-transaction.model";
import { CommonService } from "../../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class WelfareEjipTransactionService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getWelfareEJIPTransactionDataTable(dataTablesParameters: any) {
		return firstValueFrom(
			this.http.post<DataTablesResponse>(
				this.apiUrl + "WelfareEJIPTransaction/GetWelfareEJIPTransactionDataTable",
				dataTablesParameters,
				{ params: dataTablesParameters },
			),
		);
	}

	getWelfareEJIPTransactionById(ejipTransactionId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "WelfareEJIPTransaction/GetWelfareEJIPTransactionById/" + ejipTransactionId),
		).then((res) => <WelfareEJIPTransaction>res);
	}

	addWelfareEJIPTransaction(ejip: WelfareEJIPTransaction) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(ejip);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "WelfareEJIPTransaction/AddWelfareEJIPTransaction", body, httpOptions);
	}

	editWelfareEJIPTransaction(ejip: WelfareEJIPTransaction) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(ejip);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "WelfareEJIPTransaction/EditWelfareEJIPTransaction", body, httpOptions);
	}

	exportExcelWelfareEJIPTransaction(ejip: any) {
		var body = JSON.stringify(ejip);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "WelfareEJIPTransaction/ExportExcelWelfareEJIPTransaction", body, httpOptions);
	}
}
