import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MssSearchModel } from "src/app/shared/models/mss.model";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { CommonService } from "../../shared/service/common.service";
import { FromPrintCard } from "../../welfare/employee-cards/shared/employee-cards.model";
import { EmployeeMovement } from "../employee-movement/shared/employee-movement.model";
import { EmployeeDocument } from "./employee-document.model";
import { Employee, EmployeeIncomeHistory } from "./employee-movements.model";
import { EmployeePDPA } from "./employee-pdpa.model";
import { firstValueFrom } from "rxjs";
import { SharingService } from "src/app/shared/sharing.service";
import { ApiResult } from "src/app/shared/models/api-result.model";

@Injectable()
export class EmployeeMovementsService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		@Inject("GOFIVE_API_URL") private apiUrlCore: string,
		private commonService: CommonService,
		private sharing: SharingService,
	) {}

	getMyTeamByEmployeeIdDataTable(dataTablesParameters: any) {
		return firstValueFrom(
			this.http.post<DataTablesResponse>(
				this.apiUrl + "Employee/GetMyTeamByEmployeeIdDataTable",
				dataTablesParameters,
				{
					params: dataTablesParameters,
				},
			),
		);
	}

	getEmployeeWorkHolidayDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Employee/GetEmployeeWorkHolidayDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getEmployeeEditHistoryByEmployeeIdDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Employee/GetEmployeeEditHistoryByEmployeeIdDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getMyTeamAttendanceByEmployeeIdDataTable(dataTablesParameters: any) {
		return firstValueFrom(
			this.http.post<DataTablesResponse>(
				this.apiUrl + "Employee/GetMyTeamAttendanceByEmployeeIdDataTable",
				dataTablesParameters,
				{ params: dataTablesParameters },
			),
		);
	}
	transferEmployeeSupervisor(emp: EmployeeMovement) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeMovement/TransferEmployeeSupervisor", body, httpOptions);
	}

	searchEmployee(searchData: string, isHR: boolean) {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/SearchEmployee/" + searchData + "/" + isHR)).then(
			(res) => <any[]>res,
		);
	}

	getMyTeamByEmployeeId(employeeId: number, searchData: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Employee/GetMyTeamByEmployeeId/" + employeeId + "/" + searchData),
		).then((res) => <any[]>res);
	}

	getEmployeeSchedule(model: Employee) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/GetEmployeeSchedule/", body, httpOptions);
	}

	getMssEmployeeSchedule(searchModel: MssSearchModel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(searchModel);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "v1/mss/employee-schedule", body, httpOptions);
	}

	getEmployeeShiftSchedule(model: Employee) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/GetEmployeeShiftSchedule/", body, httpOptions);
	}

	getEmployeeSubordinate() {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/GetEmployeeSubordinate")).then(
			(res) => <Employee[]>res,
		);
	}

	getEmployeeSubordinateByEmployeeId(upperSupervisorUserId: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Employee/GetEmployeeSubordinateByUpperSupervisorUserId/" + upperSupervisorUserId),
		).then((res) => <Employee[]>res);
	}

	getEmployeeById(employeeId: number, isOriginalPic: boolean = true) {
		if (employeeId > 0) {
			return firstValueFrom(
				this.http.get(this.apiUrl + "Employee/GetEmployeeById/" + employeeId + "/" + isOriginalPic),
			).then((res) => <any>res);
		}
	}

	getEmployeePictureByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/GetEmployeePictureByEmployeeId/" + employeeId)).then(
			(res) => <any>res,
		);
	}

	deleteEmployeePicture(employeePictureId: number) {
		return firstValueFrom(this.http.delete(this.apiUrl + "Employee/DeleteEmployeePicture/" + employeePictureId)).then(
			(res) => <any>res,
		);
	}

	getEmployeeDocumentByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeDocument/GetEmployeeDocumentByEmployeeId/" + employeeId),
		).then((res) => <EmployeeDocument[]>res);
	}

	getEmployeeDocumentByDocumentTypeId(employeeId: number, documentTypeId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "EmployeeDocument/GetEmployeeDocumentByDocumentTypeId/" + employeeId + "/" + documentTypeId,
			),
		).then((res) => <EmployeeDocument[]>res);
	}

	editEmployeeDocumentDate(documentTypeId: number, dateDocumentExpired: Date, employeeId: number) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify({
			documentTypeId: documentTypeId,
			dateDocumentExpired: dateDocumentExpired,
			employeeId: employeeId,
		});
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "employee-document/date-expiration", body, httpOptions);
	}

	addEmployeeDocument(emp: EmployeeDocument) {
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeDocument/AddEmployeeDocument", body, httpOptions);
	}
	addEmployeePicture(emp) {
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/AddEmployeePicture", body, httpOptions);
	}

	deleteEmployeeDocument(documentId: number) {
		var body = JSON.stringify(documentId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeDocument/DeleteEmployeeDocument", body, httpOptions);
	}

	getSupervisorChartByEmployeeId(employeeId: number, type: number) {
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "Employee/GetSupervisorChartByEmployeeId/" + employeeId + "/" + type),
		);
	}

	getSupervisorByEmployeeId(employeeId: number, type: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrlCore + "v1/supervisors/" + employeeId + "/" + type));
	}
	getWelfareSalaryDDL(companyId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + `welfares/salary-adjustments/ddl?companyId=${companyId}`));
	}

	getMovementsSalaryAdjustment(employeeId, movementTransactionId = 0) {
		var queryString = this.sharing.getQueryString({ movementTransactionId: movementTransactionId });
		return firstValueFrom(
			this.http.get<ApiResult>(this.apiUrl + `v1/employees/${employeeId}/movements/salary-adjustments${queryString}`),
		);
	}

	getMovementsSalaryBand(employeeId, positionId, rankno) {
		var queryString = this.sharing.getQueryString({ positionId: positionId, rankno: rankno });
		return firstValueFrom(
			this.http.get<ApiResult>(this.apiUrl + `v1/employees/${employeeId}/salary-band${queryString}`),
		);
	}

	printCard(model: FromPrintCard) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeCard/PrintCard", body, httpOptions);
	}

	importEmployeeByExcel(model) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/ImportEmployeeByExcel", body, httpOptions);
	}

	importEmployeeEducationByExcel(model) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "employee/import", body, httpOptions);
	}

	importEmployeeExperienceByExcel(model) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "employee/import/experience", body, httpOptions);
	}

	getEmployeeIncomeHistoryByEmployeeId(employeeId: number, takeRow: number, currentRow: number) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl +
					"EmployeeMovement/GetEmployeeIncomeHistoryByEmployeeId/" +
					employeeId +
					"/" +
					takeRow +
					"/" +
					currentRow,
			),
		).then((res) => <EmployeeIncomeHistory[]>res);
	}

	exportExcelTemplateByTenant(formatDate: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Employee/ExportExcelTemplateByTenant?formatDate=" + formatDate),
		).then((res) => res);
	}

	downloadEmployeeEducationExcel(model) {
		return this.http.get(this.apiUrl + `employee/export/education-excel${this.sharing.getQueryString(model)}`);
	}

	downloadEmployeeExperienceExcel(model) {
		return this.http.get(this.apiUrl + `employee/export/experience${this.sharing.getQueryString(model)}`);
	}

	editEmployeePDPA(emp: EmployeePDPA) {
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeMovement/EditEmployeePDPA", body, httpOptions);
	}

	getAllEmployeeSubordinate() {
		return this.http.get<Employee[]>(this.apiUrl + "Employee/GetAllEmployeeSubordinate");
	}
	getUserInfoByEmployeeNo(employeeNo: string) {
		return firstValueFrom(this.http.get(`${this.apiUrl}Employee/GetUserInfoByEmployeeNo/${employeeNo}`)).then(
			(r) => <any>r,
		);
	}
	getEmployeeFileImport() {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/GetEmployeeFileImport"));
	}
	exportTmpEmployeeExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Employee/ExportEmployeeExcel", body, httpOptions)).then(
			(r) => <any>r,
		);
	}

	getEmployeeEducationFileImport() {
		return this.http.get(this.apiUrl + "employee/import/education-file-history");
	}

	getEmployeeExperienceFileImport() {
		return this.http.get(this.apiUrl + "employee/import/experience-file-history");
	}

	GetEmployMovementCentric(model) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "EmployeeMovement/GetEmployMovementCentric", body, httpOptions);
	}

	exportExcelEditEmployee(formatDate: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/ExportExcelEditEmployee?formatDate=" + formatDate));
	}

	checkPassphrase(employeeId, passCode) {
		return this.http.get<any>(
			this.apiUrl + `Employee/CheckPassphrase?employeeId=${employeeId}&passCode=${encodeURIComponent(passCode)}`,
		);
	}

	updatePassphrase(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(`${this.apiUrl}Employee/UpdatePassphrase/`, body, httpOptions);
	}
	deleteEmployeeFaceScanByEmployeeId(empId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/DeleteEmployeeFaceScanByEmployeeId/" + empId)).then(
			(res) => <any>res,
		);
	}

	getEmployeeByOrganizationId(organizationId: number, levels: number) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}v1/employees/organization/${organizationId}?levels=${levels}`),
		).then((r) => <any>r);
	}

	getCurrentBaseSalaryById(employeeId: number, companyId: number) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}employee/${employeeId}/payroll/base-salary?companyId=${companyId}`),
		).then((r) => <any>r);
	}

	getEmployeeLiteById(employeeId: number) {
		if (employeeId > 0) {
			return firstValueFrom(this.http.get(`${this.apiUrl}v1/employees/${employeeId}/lite`)).then((res) => <any>res);
		} else {
			return Promise.resolve(null);
		}
	}
}
