import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { CommonService } from "../../shared/service/common.service";
import { AssessmentMasterSet } from "./assessment-master-set.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class AssessmentMasterSetService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getAssessmentMasterSetDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "AssessmentMasterSet/GetAssessmentMasterSetDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	addAssessmentMasterSets(model: AssessmentMasterSet) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentMasterSet/AddAssessmentMasterSets", body, httpOptions);
	}

	addAssessmentMasterSetsByAssessmentMasters(model: AssessmentMasterSet) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(
			this.apiUrl + "AssessmentMasterSet/AddAssessmentMasterSetsByAssessmentMasters",
			body,
			httpOptions,
		);
	}

	deleteAssessmentMasterSet(masterSetId: number) {
		var body = JSON.stringify(masterSetId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentMasterSet/DeleteAssessmentMasterSet", body, httpOptions);
	}

	exportAssessmentScoreByMasterSetId(masterSetId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentScore/ExportAssessmentScoreByMasterSetId/" + masterSetId),
		);
	}

	exportAssessmentScoresResultReport(masterSetId: number, no: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentScore/ExportAssessmentScoresResultReport/" + masterSetId + "/" + no),
		);
	}

	exportAssessmentScoreDetailsGroupQuestionTopic(
		employeeAssessmentScoreFilter: any,
		typeReport: number,
		isReport: boolean,
	) {
		employeeAssessmentScoreFilter["typeReport"] = typeReport;
		employeeAssessmentScoreFilter["isReport"] = isReport;
		var body = JSON.stringify(employeeAssessmentScoreFilter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(
				`${this.apiUrl}AssessmentScore/ExportAssessmentScoreDetailsGroupQuestionTopic/`,
				body,
				httpOptions,
			),
		);
	}

	exportEmployeeAnnualAssessment(model: any) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(
			this.http.post(this.apiUrl + "AssessmentScore/ExportEmployeeAnnualAssessment", body, httpOptions),
		);
	}

	importEmployeeAnnualAssessment(file: string) {
		var body = JSON.stringify(file);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentScore/ImportEmployeeAnnualAssessment", body, httpOptions);
	}

	getAssessmentMasterSetById(assessmentSetId: number, no: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentMasterSet/GetAssessmentMasterSetById/" + assessmentSetId + "/" + no),
		).then((res) => <any[]>res);
	}
}
