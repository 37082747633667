<div
	class="bg-default d-flex align-center content-center p-3 direction-column g-24 upgrade-plane-bg"
	[style.min-height]="height"
>
	<div class="d-flex content-center">
		<img class="upgrade-plane-img" alt="apollo-layout" src="assets/images/dialog/promote-feature.png" />
	</div>
	<div class="d-flex align-center content-center direction-column g-8">
		<div class="go5-text-header-2 go5-text-color-12">{{ "common_upgrade_plan_title" | translate }}</div>
		<div class="go5-text-title go5-text-color-9">{{ "common_upgrade_plan_description" | translate }}</div>
	</div>
	<div class="pt-2 d-flex align-center content-center direction-column g-4">
		<div>
			@if (billingType == billingTypeYear && permission?.allowGrant) {
				<go5-button
					data-testid="button_upgrade_plan"
					label="{{ 'common_upgrade_plan' | translate }}"
					category="primary"
					size="medium"
					type="button"
					(click)="upgradePlan()"
				>
				</go5-button>
			}
		</div>
		<div>
			<go5-button
				data-testid="button_learn_more"
				label="{{ 'common_learn_more' | translate }}"
				category="text"
				size="medium"
				type="button"
				(click)="learnMore()"
			>
			</go5-button>
		</div>
	</div>
</div>
