import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeTaxExemptions } from "./employee-tax.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeTaxService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}
	getTaxExemptionsByTaxType(model: EmployeeTaxExemptions) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeTaxExemption/GetTaxExemptionsByTaxType/", body, httpOptions);
	}

	addEmployeeTaxExemptions(model: EmployeeTaxExemptions[]) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeTaxExemption/AddEmployeeTaxExemtions/", body, httpOptions);
	}

	exportEmployeeTax(model: EmployeeTaxExemptions) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeTaxExemption/ExportEmployeeTax", body, httpOptions);
	}
	getForecastIncome(employee: number, year: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "TaxExemption/GetForecastIncome/" + employee + "/" + year));
	}
}
