<two-factor-authentication
	*ngIf="isAuth"
	(authStatus)="receiveMessageAuth($event)"
	(closeStatus)="receiveMessageClose($event)"
></two-factor-authentication>

<div>
	<div
		class="employee-payroll-header"
		[ngClass]="{
			'employee-payroll-header-full-template': fullTemPlate && !isPaymentModal,
			'employee-payroll-header-no-padding': isPaymentModal
		}"
	>
		<span class="go5-text-header-3 go5-text-color-12" *ngIf="isFooter">{{ "ess009_salary_info" | translate }}</span>

		@if (isPaymentModal) {
			<div class="mb-2">
				<go5-avatar-information
					[title]="
						data.getIsTH(data.currentLanguage | async)
							? employeeMovement?.nameSurname
							: employeeMovement?.nameSurname_EN
					"
					[detail]="
						employeeMovement?.driverNo + data.getIsTH(data.currentLanguage | async)
							? employeeMovement?.positionName
							: employeeMovement?.positionName_EN + employeeMovement?.rank
					"
					(click)="viewEmployee(employeeMovement)"
					[src]="employeeMovement?.pictureThumbnailURL"
					[isLazy]="false"
				>
				</go5-avatar-information>
			</div>
		}
		<div
			class="pl-3 pt-0 fadeIn"
			[class]="isFooter ? 'm-top' : ''"
			[ngStyle]="{ 'margin-right': isFooter ? '10px' : '', 'margin-bottom': isFooter ? '0' : '' }"
			style="display: flex; justify-content: flex-end; align-items: center"
		>
			<ng-template [ngIf]="isFooter" [ngIfElse]="btnTopSidebarContent">
				<div class="d-flex d-flex-center" style="gap: 14px">
					<ng-container *ngTemplateOutlet="btnTopContent"></ng-container>
				</div>
			</ng-template>
			<ng-template #btnTopSidebarContent>
				<div class="header-dialog-payroll">
					<ng-container *ngTemplateOutlet="btnTopContent"></ng-container>

					<em
						(click)="closeDialogEvent()"
						go5-tooltip-placement="bottom"
						go5-tooltip-mode="hover"
						go5-tooltip="{{ 'common_close' | translate }}"
						class="gf-icon-close pointer f-24 color-primary"
					></em>
				</div>
			</ng-template>

			<ng-template #btnTopContent>
				<em
					(click)="viewHistory()"
					data-testid="view_history"
					go5-tooltip-placement="bottom"
					go5-tooltip-mode="hover"
					go5-tooltip="{{ 'pay001_operation_history_tooltip' | translate }}"
					class="gf-icon-empeo-history pointer f-24 color-primary"
				></em>

				<em
					(click)="sendEslip()"
					data-testid="send_eslip"
					go5-tooltip-placement="bottom"
					go5-tooltip-mode="hover"
					go5-tooltip="{{ 'pay001_send_eslip_tooltip' | translate }}"
					class="gf-icon-empeo-e-payslip f-24"
					*ngIf="
						hasEmailAddress &&
						(isStatusWaitingVerify ||
							isStatusWaitingApprove ||
							isStatusWaitingForClosingPeriod ||
							isStatusWaitingToPay ||
							isStatusPaid)
					"
				></em>

				<em
					*ngIf="tabPermissionPayroll"
					[class]="isShowSalary ? 'gf-icon-close-eye' : 'gf-icon-eye'"
					data-testid="show_salary"
					class="pointer f-24 color-primary"
					(click)="showSalary()"
					go5-tooltip-placement="bottom"
					go5-tooltip-mode="hover"
					go5-tooltip="{{ (isShowSalary ? 'pay001_close_salary_tooltip' : 'pay001_show_salary_tooltip') | translate }}"
				></em>
			</ng-template>
		</div>
	</div>
	<div
		class="employee-payroll-content"
		[ngClass]="{
			'employee-payroll-content-full-template': fullTemPlate && !isPaymentModal,
			'employee-payroll-content-no-padding': isPaymentModal
		}"
	>
		<go5-tabs
			#tabGo5
			[(activeIndex)]="indexTab"
			[headerPadding]="'0'"
			(selected)="handleChange($event)"
			[contentPadding]="'12px 0px'"
			[allowScroll]="true"
		>
			<go5-tab data-testid="payroll" headerText="{{ 'emp001_t14_payroll' | translate }}" *ngIf="tabPermissionPayroll">
				<ng-template #contentTemplate>
					<app-employee-payroll-history
						[isViewSalary]="false"
						[isShowSalary]="isShowSalary"
						[isShowHistory]="true"
						[isShowForm]="true"
						[permission]="permission"
						[isFooter]="isFooter"
						[isActive2FA]="userInfo?.isActive2FA"
					></app-employee-payroll-history>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="financial"
				headerText="{{ 'emp001_t14_financial' | translate }}"
				*ngIf="tabPermissionPayroll"
			>
				<ng-template #contentTemplate>
					<ng-template [ngTemplateOutlet]="payroll"></ng-template>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="overtime"
				headerText="{{ 'emp001_t14_overtime' | translate }}"
				*ngIf="tabPermissionPayroll && !(periodExtendNo > 0)"
			>
				<ng-template #contentTemplate>
					<ng-template [ngTemplateOutlet]="overtime"></ng-template>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="deducted_not_coming_work"
				headerText="{{ 'emp001_t14_deducted_not_coming_work' | translate }}"
				*ngIf="tabPermissionPayroll && !(periodExtendNo > 0)"
			>
				<ng-template #contentTemplate>
					<ng-template [ngTemplateOutlet]="deductedNotComingWork"></ng-template>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="deducted_late_early"
				headerText="{{ 'emp001_t14_deducted_late_early' | translate }}"
				*ngIf="tabPermissionPayroll && !(periodExtendNo > 0)"
			>
				<ng-template #contentTemplate>
					<ng-template [ngTemplateOutlet]="deductedLateEarly"></ng-template>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="accumulate_tab"
				headerText="{{ 'pay001_t03_accumulate_tab' | translate }}"
				*ngIf="allowGrant && tabPermissionPayroll"
			>
				<ng-template #contentTemplate>
					<app-accumulate *ngIf="tabAccumulate" [dateEmployment]="dateEmployment" [isShowSalary]="isShowSalary">
					</app-accumulate>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="loan"
				headerText="{{ 'emp001_t19_loan' | translate }}"
				*ngIf="isAllowTabLoan && !(periodExtendNo > 0)"
			>
				<ng-template #contentTemplate>
					<app-employee-loan *ngIf="tabLoan"></app-employee-loan>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="allowance"
				headerText="{{ 'tab_allowance' | translate }}"
				*ngIf="allowGrantTax && tabPermissionPayroll"
			>
				<ng-template #contentTemplate>
					<div *ngIf="lazyComponent$ | async">
						<ng-container *ngComponentOutlet="lazyComponent$ | async"></ng-container>
					</div>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="tax"
				headerText="{{ 'emp001_t15_tax' | translate }}"
				*ngIf="allowGrantTax && tabPermissionPayroll && !(periodExtendNo > 0)"
			>
				<ng-template #contentTemplate>
					<div *ngIf="tabTax">
						<ng-template [ngTemplateOutlet]="tax"></ng-template>
					</div>
				</ng-template>
			</go5-tab>
			<go5-tab
				data-testid="social_security"
				headerText="{{ 'emp001_social_security' | translate }}"
				*ngIf="!(periodExtendNo > 0)"
			>
				<ng-template #contentTemplate>
					<app-employee-social-security
						*ngIf="tabSSO"
						[fullTemPlate]="false"
						[isHeader]="false"
						[footerContent]="isPaymentModal"
					></app-employee-social-security>
				</ng-template>
			</go5-tab>
		</go5-tabs>
	</div>
</div>

<ng-template #tax>
	<div style="margin-bottom: 80px">
		<div class="d-flex">
			<div style="width: 350px">
				<go5-dropdown-selection
					[dataSource]="paymentPeriodDDL2"
					[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsPayment : fieldsPayment_EN"
					[mode]="DropdownSelectMode.Single"
					[placeholder]="'common_default_select' | translate"
					[(ngModel)]="paymentId"
					[allowFiltering]="false"
					[allowShowRemove]="false"
					[allowSelected]="false"
					(ngModelChange)="loadTaxDetail()"
					[label]="'choose_payment_period' | translate"
				>
				</go5-dropdown-selection>
			</div>
		</div>
		<div style="margin-top: 24px">
			<ng-template [ngIf]="!isLoadingTaxDetail" [ngIfElse]="loadingTable">
				<table *ngIf="!isLoadingTaxDetail" class="dataTable" aria-describedby="tax-detail">
					<thead>
						<tr>
							<th style="width: 10%" class="text-center">{{ "common_order" | translate }}</th>
							<th class="text-left" style="width: 70%; padding-left: 24px !important">
								{{ "common_items" | translate }}
							</th>
							<th class="text-right" style="width: 20%; padding-right: 48px !important">
								{{ "pay001_t03_amount" | translate }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of taxDetails?.taxDetails; let i = index">
							<td>{{ item.seqNo }}</td>
							<td class="text-left" style="padding-left: 24px !important">
								<div class="d-flex">
									<span class="text-body-strong text-color-12"> {{ item.name | translate }} </span>
									<span style="margin-left: 2px" class="d-flex">
										<einfo-tooltip
											[ngStyle]="{ display: 'flex', 'align-items': 'center' }"
											key="{{ item.tooltip }}"
											size="s"
										>
										</einfo-tooltip>
									</span>
								</div>
								<div class="text-small text-color-8">
									{{ data.getIsTH(data.currentLanguage | async) ? item.detail : item.detail_EN }}
								</div>
							</td>
							<td class="text-right" style="padding-right: 48px !important">
								<span class="text-body-strong text-color-11">
									฿{{ isShowSalary ? (item.amount | number: "0.2-2") : maskAmount }}
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</ng-template>
		</div>
	</div>
</ng-template>

<ng-template #loadingTable>
	<ngx-skeleton-loader [theme]="{ height: '40px', float: 'right', 'margin-bottom': '0px' }" animation="progress-dark">
	</ngx-skeleton-loader>
	<table class="skeleton">
		<caption style="display: none">
			Skeleton
		</caption>
		<thead *ngFor="let number of counter(8)">
			<th style="width: 18%">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '25px', margin: '5px 0 0' }">
				</ngx-skeleton-loader>
			</th>
			<th style="width: 18%">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '25px', margin: '5px 0 0' }">
				</ngx-skeleton-loader>
			</th>
			<th style="width: 16%">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '25px', margin: '5px 0 0' }">
				</ngx-skeleton-loader>
			</th>
			<th style="width: 16%">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '25px', margin: '5px 0 0' }">
				</ngx-skeleton-loader>
			</th>
			<th style="width: 16%">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '25px', margin: '5px 0 0' }">
				</ngx-skeleton-loader>
			</th>
			<th style="width: 16%">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '25px', margin: '5px 0 0' }">
				</ngx-skeleton-loader>
			</th>
		</thead>
	</table>
</ng-template>

<ng-template #payroll>
	<div class="d-grid" *ngIf="paymentPeriod">
		<div class="ui-g-12 pt-0">
			<div class="ui-g-12 ui-md-6 ui-lg-3 pl-0">
				<div class="ui-g-12 ui-g-nopad">
					<go5-dropdown-selection
						[disabled]="periodExtendNo > 0"
						[dataSource]="paymentPeriodDDL"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsPeriod : fieldsPeriod_EN"
						[mode]="DropdownSelectMode.Single"
						[value]="paymentPeriod?.periodCalculateNo"
						[placeholder]="'common_default_select' | translate"
						[allowFiltering]="false"
						[allowShowRemove]="false"
						[allowSelected]="false"
						(selected)="getPaymentPeriod($event)"
						[label]="
							('emp001_t14_pay_period' | translate) +
							(periodExtendNo > 0 ? '(' + ('common_special_period' | translate) + ')' : '')
						"
					>
					</go5-dropdown-selection>
				</div>
			</div>
			<div class="ui-g-12 ui-md-6 ui-lg-2">
				<label class="go5-text-color-8 pb-2" style="display: block">{{ "emp001_t14_payment_type" | translate }}</label>
				<div class="ui-g-12 ui-g-nopad text-body-strong" style="display: flex; height: 40px; align-items: center">
					<ng-template [ngIf]="employeePayroll.isCash" [ngIfElse]="isCash">
						<label>{{ "emp001_t14_cash" | translate }}</label>
					</ng-template>
					<ng-template #isCash>
						<label>{{ "emp001_t14_bank" | translate }}</label>
					</ng-template>
				</div>
			</div>
			<div
				[ngClass]="
					hasEmailAddress &&
					(isStatusWaitingVerify ||
						isStatusWaitingApprove ||
						isStatusWaitingForClosingPeriod ||
						isStatusWaitingToPay ||
						isStatusPaid)
						? 'ui-g-12 ui-md-12 ui-lg-2'
						: 'ui-g-12 ui-md-12 ui-lg-3'
				"
				style="text-align: right; float: right; display: flex; flex-direction: column"
			>
				<label for="input">{{ "emp001_t14_payroll" | translate }}</label>
				<label class="text-header-2 ml-2"> ฿{{ isShowSalary ? (salaryCurrent | number: "1.2-2") : maskAmount }} </label>
			</div>
		</div>
		<!-- table -->
		<div id="tableIncomeDeduct" class="ui-g-12 p-0">
			<div class="p-0">
				<div class="ui-g-12 ui-md-12 ui-lg-6 pr-1">
					<div
						class="ui-g-12 search-table-outter ui-g-nopad"
						[ngClass]="isFooter ? 'height-table-450' : 'height-table-400'"
					>
						<table
							class="hover dataTable dataTables-parti-coloured"
							style="width: 100%; border: 0"
							aria-describedby="income"
						>
							<thead>
								<tr>
									<th class="th-sm-1 border-top-left-default" style="text-align: left; width: 40%">
										<label for="input">{{ "emp001_t14_income" | translate }}</label>
									</th>
									<th class="th-sm-1" style="width: 20%" style="text-align: left">
										<label for="input">{{ "emp001_t14_period_type" | translate }}</label>
									</th>
									<th class="th-sm-1" style="width: 20%" style="text-align: center; z-index: 2">
										<label for="input">{{ "emp001_t14_amount" | translate }}</label>
									</th>
								</tr>
							</thead>
							<tbody *ngIf="isLoadEmployeeFinancialsType">
								<tr *ngFor="let number of counter(5)">
									<td class="pb-0 fadeIn">
										<ngx-skeleton-loader [theme]="{ 'padding-bottom': '0px', 'margin-bottom': '0px', height: '40px' }">
										</ngx-skeleton-loader>
									</td>
									<td class="pb-0 fadeIn">
										<ngx-skeleton-loader [theme]="{ 'padding-bottom': '0px', 'margin-bottom': '0px', height: '40px' }">
										</ngx-skeleton-loader>
									</td>
									<td class="pb-0 fadeIn">
										<ngx-skeleton-loader [theme]="{ 'padding-bottom': '0px', 'margin-bottom': '0px', height: '40px' }">
										</ngx-skeleton-loader>
									</td>
								</tr>
							</tbody>
							<tbody *ngIf="!isLoadEmployeeFinancialsType">
								<tr *ngFor="let item of employeeFinancialsType1; let i = index" class="row-input-payroll">
									<td style="text-align: left">
										<label class="d-flex" style="align-items: center; display: inline-block">
											{{
												data.getIsTH(data.currentLanguage | async) ? item.financialItemName : item.financialItemName_EN
											}}
										</label>
										<em
											class="icon-search pointer mx-2"
											*ngIf="item.category == financialCategoryOvertime"
											(click)="changeTab(item.category)"
											go5-tooltip-placement="bottom"
											go5-tooltip-mode="hover"
											go5-tooltip="{{ 'pay001_search_tooltip' | translate }}"
											style="display: inline-table"
										></em>
									</td>
									<td style="text-align: left">
										{{ data.getIsTH(data.currentLanguage | async) ? item.periodType_TH : item.periodType_EN }}
									</td>
									<td>
										@if (typeAmount === "text") {
											@if (
												isClosed ||
												item.isDisabled ||
												!tabPermissionPayroll ||
												item.category == financialCategory.baseSalary ||
												item.category == financialCategory.severancePay ||
												(item.category != financialCategory.baseSalary && !permission.allowAdd)
											) {
												<div
													class="ui-g-12 p-0"
													style="align-items: center; justify-content: space-between; float: right"
												>
													@if (
														(item.category == financialCategory.baseSalary && item.calculateDetail) ||
														(item.category == financialCategory.severancePay && item.movementCode)
													) {
														<einfo-tooltip
															go5-tooltip-alignment="left"
															go5-tooltip-max-width="900px"
															go5-tooltip="{{
																item.category == financialCategory.baseSalary && item.calculateDetail
																	? data.getIsTH(data.currentLanguage | async)
																		? item.calculateDetail
																		: item.calculateDetail_EN
																	: ('tooltip_pay_from_movement' | translate) + ' ' + item.movementCode
															}}"
															size="s"
															class="pl-2"
															[ngStyle]="{ float: 'left', 'padding-top': '12px' }"
														>
														</einfo-tooltip>
													}
													<div style="padding: 10px; text-align: right">
														{{ typeAmount === "text" ? (item.amount | number: "0.0-2") : maskAmount }}
													</div>
												</div>
											} @else {
												<go5-textfield
													category="primary"
													[currencySymbol]="''"
													type="currency"
													width="200px"
													textAlign="end"
													attr.data-testid="input_number_income_{{ item.financialItemId }}"
													class="input-payroll"
													[id]="'income-input-calculate-' + item.financialItemId"
													size="20"
													[(ngModel)]="item.amount"
													[placeholder]="'Auto'"
													(ngModelChange)="totalAddition(item)"
													[ngClass]="'spinner-cal'"
													(focus)="onFocusInputIncome(item.financialItemId)"
													(blur)="onBlurInputIncome(item.financialItemId, item)"
												>
													<ng-template #inputPrefixContent let-hover="isHover" let-active="isActive">
														@if ((hover || active) && typeAmount === "text" && item.isShow) {
															<span class="custom-field-option-icon">
																<div class="icon-calculate-manual-close">
																	<em
																		go5-tooltip-placement="top"
																		class="gf-icon-manual pointer"
																		go5-tooltip-mode="hover"
																		go5-tooltip="{{ 'pay001_open_cal' | translate }}"
																		(click)="onChangeCal(item)"
																		[hidden]="item.isCalculate"
																		[ngStyle]="{ opacity: this.isClosed || item.category == this.other ? '0.6' : '1' }"
																		style="display: block"
																	></em>
																</div>
																<div
																	id="icon-calculate-manual-close-left-{{ item.financialItemId }}"
																	class="icon-calculate-manual-open"
																>
																	<em
																		go5-tooltip-placement="top"
																		class="gf-icon-manual pointer"
																		[id]="'income-icon-calculate-open-' + item.financialItemId"
																		go5-tooltip-mode="hover"
																		go5-tooltip="{{ 'pay001_closed_cal' | translate }}"
																		(click)="onChangeCal(item, 'income')"
																		[hidden]="!item.isCalculate"
																		style="display: block"
																	></em>
																</div>
															</span>
														}
													</ng-template>
												</go5-textfield>
											}
										} @else if (typeAmount === "password") {
											<go5-textfield
												*ngIf="typeAmount === 'password'"
												category="primary"
												[readonly]="true"
												[placeholder]="maskAmount"
												width="200px"
												textAlign="end"
											>
											</go5-textfield>
										}
									</td>
								</tr>
							</tbody>
							<tr *ngIf="employeeFinancialsType1 == null || employeeFinancialsType1?.length == 0">
								<td colspan="4" class="no-data-available">
									<label class="go5-text-color-8">{{ "common_no_data" | translate }}</label>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="ui-g-12 ui-md-12 ui-lg-6 pr-0">
					<div
						id="deductionTable"
						class="ui-g-12 search-table-outter ui-g-nopad"
						[ngClass]="isFooter ? 'height-table-450' : 'height-table-400'"
					>
						<table class="hover dataTable dataTables-parti-coloured" aria-describedby="deduction">
							<thead>
								<tr>
									<th class="th-sm-1 border-top-left-default" style="text-align: left; width: 40%">
										<label for="input">{{ "emp001_t14_deduction" | translate }}</label>
									</th>
									<th class="th-sm-1" style="width: 20%" style="text-align: left">
										<label for="input">{{ "emp001_t14_period_type" | translate }}</label>
									</th>

									<th class="th-sm-1" style="width: 20%" style="text-align: center; z-index: 2">
										<label for="input">{{ "emp001_t14_amount" | translate }}</label>
									</th>
								</tr>
							</thead>
							<tbody *ngIf="isLoadEmployeeFinancialsType">
								<tr *ngFor="let number of counter(5)">
									<td class="pb-0 fadeIn">
										<ngx-skeleton-loader [theme]="{ 'padding-bottom': '0px', 'margin-bottom': '0px', height: '40px' }">
										</ngx-skeleton-loader>
									</td>
									<td class="pb-0 fadeIn">
										<ngx-skeleton-loader [theme]="{ 'padding-bottom': '0px', 'margin-bottom': '0px', height: '40px' }">
										</ngx-skeleton-loader>
									</td>
									<td class="pb-0 fadeIn">
										<ngx-skeleton-loader [theme]="{ 'padding-bottom': '0px', 'margin-bottom': '0px', height: '40px' }">
										</ngx-skeleton-loader>
									</td>
								</tr>
							</tbody>

							<tbody *ngIf="!isLoadEmployeeFinancialsType">
								<tr *ngFor="let item of employeeFinancialsType2; let i = index" class="row-input-payroll">
									<td style="text-align: left">
										{{
											data.getIsTH(data.currentLanguage | async) ? item.financialItemName : item.financialItemName_EN
										}}
										<em
											class="icon-search pointer mx-2"
											*ngIf="
												item.category == financialCategoryAbsenceDeduct || item.category == financialCategoryLateDeduct
											"
											(click)="changeTab(item.category)"
											go5-tooltip-placement="bottom"
											go5-tooltip-mode="hover"
											go5-tooltip="{{ 'pay001_search_tooltip' | translate }}"
											style="display: inline-table"
										></em>
									</td>
									<td style="text-align: left">
										{{ data.getIsTH(data.currentLanguage | async) ? item.periodType_TH : item.periodType_EN }}
									</td>
									<td>
										@if (typeAmount === "text") {
											@if (
												isClosed ||
												item.isDisabled ||
												!tabPermissionPayroll ||
												item.category == salary ||
												(item.category != salary && !permission.allowAdd)
											) {
												<div style="padding: 10px; text-align: right">
													{{ typeAmount === "text" ? (item.amount | number: "0.0-2") : maskAmount }}
												</div>
											} @else {
												<go5-textfield
													category="primary"
													[currencySymbol]="''"
													type="currency"
													width="200px"
													textAlign="end"
													attr.data-testid="input_number_deduct_{{ item.financialItemId }}"
													class="input-payroll"
													[id]="'deduct-input-calculate-' + item.financialItemId"
													size="20"
													thousandSeparator=","
													decimalSeparator="."
													[(ngModel)]="item.amount"
													[placeholder]="'Auto'"
													(ngModelChange)="totalDeduction(item)"
													[ngClass]="'spinner-cal'"
													(focus)="onFocusInputDeduct(item.financialItemId)"
													(blur)="onBlurInputDeduct(item.financialItemId, item)"
												>
													<ng-template #inputPrefixContent let-hover="isHover" let-active="isActive">
														@if ((hover || active) && typeAmount === "text" && item.isShow) {
															<span class="custom-field-option-icon">
																<div class="icon-calculate-manual-close">
																	<em
																		go5-tooltip-placement="top"
																		class="gf-icon-manual pointer"
																		go5-tooltip-mode="hover"
																		go5-tooltip="{{ 'pay001_open_cal' | translate }}"
																		(click)="onChangeCal(item)"
																		[hidden]="item.isCalculate"
																		[ngStyle]="{ opacity: this.isClosed || item.category == this.other ? '0.6' : '1' }"
																		style="display: block"
																	></em>
																</div>
																<div
																	id="icon-calculate-manual-close-right-{{ item.financialItemId }}"
																	class="icon-calculate-manual-open"
																>
																	<em
																		go5-tooltip-placement="top"
																		class="gf-icon-manual pointer"
																		[id]="'deduct-icon-calculate-open-' + item.financialItemId"
																		go5-tooltip-mode="hover"
																		go5-tooltip="{{ 'pay001_closed_cal' | translate }}"
																		(click)="onChangeCal(item, 'deduct')"
																		[hidden]="!item.isCalculate"
																		style="display: block"
																	></em>
																</div>
															</span>
														}
													</ng-template>
												</go5-textfield>
											}
										} @else if (typeAmount === "password") {
											<go5-textfield
												*ngIf="typeAmount === 'password'"
												category="primary"
												[readonly]="true"
												[placeholder]="maskAmount"
												width="200px"
												textAlign="end"
											>
											</go5-textfield>
										}
									</td>
								</tr>
							</tbody>
							<tr *ngIf="employeeFinancialsType2 == null || employeeFinancialsType2?.length == 0">
								<td colspan="4" class="no-data-available">
									<label class="go5-text-color-8">{{ "common_no_data" | translate }}</label>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		class="ui-g-12 ui-g-nopad bg-default height-70"
		*ngIf="!paymentPeriod"
		style="display: flex; align-items: center; justify-content: center"
	>
		<div class="p-0 py-3" style="display: flex; flex-direction: column; align-items: center">
			<div class="p-2">
				<svg class="no-data"></svg>
			</div>
			<div>
				<label class="go5-text-color-8">{{ "common_no_data" | translate }}</label>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #overtime>
	<div class="p-0" *ngIf="!(employeeFinancialsOT == null || employeeFinancialsOT?.length == 0)">
		<div class="d-flex" style="flex-direction: column; gap: 8px; padding: 0 0 20px 0">
			<label class="go5-text-color-8">{{ "emp001_t14_payment_cycle_cal" | translate }}</label>
			<label class="go5-text-color-12 go5-text-body-strong">{{ dateCycleCalOT }}</label>
		</div>

		<!-- table -->
		<div class="search-table-outter ui-g-nopad" [ngClass]="isFooter ? 'height-table-550' : 'height-table-450'">
			<table
				class="hover dataTable dataTables-parti-coloured"
				style="width: 100%; border: 0"
				aria-describedby="payment-ot"
			>
				<thead>
					<tr>
						<th style="display: none"></th>
						<th class="th-sm-1 border-top-left-default" style="width: 20%" style="text-align: left">
							<label for="input">{{ "emp001_t14_payment_ot" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 20%" style="text-align: left">
							<label for="input">{{ "emp001_t04_check_in_time" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t04_ot1" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t04_ot2" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t04_ot3" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t04_ot4" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t04_ot_fix_rate" | translate }}</label>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of employeeFinancialsOT; let i = index">
						<td style="text-align: left">{{ item.date }}</td>
						<td style="text-align: left">{{ item.stampIn }}-{{ item.stampOut }}</td>
						<td style="text-align: left">{{ item.totalHourOfOT1 != 0 ? item.totalHourOfOT1 : "-" }}</td>
						<td style="text-align: left">{{ item.totalHourOfOT2 != 0 ? item.totalHourOfOT2 : "-" }}</td>
						<td style="text-align: left">{{ item.totalHourOfOT3 != 0 ? item.totalHourOfOT3 : "-" }}</td>
						<td style="text-align: left">{{ item.totalHourOfOT4 != 0 ? item.totalHourOfOT4 : "-" }}</td>
						<td style="text-align: left">
							{{ item.totalHourOfFixRateOT != 0 ? item.totalHourOfFixRateOT : "-" }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="p-0 ui-g-12" *ngIf="employeeFinancialsOT == null || employeeFinancialsOT?.length == 0">
		<div class="height-50 p-2" style="display: flex; flex-direction: row; align-items: center; justify-content: center">
			<div style="display: flex; align-items: center; flex-direction: column">
				<svg class="no-data"></svg>
				<label class="go5-text-color-8">{{ "common_no_data" | translate }}</label>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #deductedNotComingWork>
	<div
		class="p-0 ui-g-12"
		*ngIf="!(employeeFinancialsAbsenceDeduct == null || employeeFinancialsAbsenceDeduct?.length == 0)"
	>
		<div class="d-flex" style="flex-direction: column; gap: 8px; padding: 0 0 20px 0">
			<label class="go5-text-color-8">{{ "emp001_t14_payment_cycle_cal" | translate }}</label>
			<label class="go5-text-color-12 go5-text-body-strong">{{ dateCycleCalAbsenceDeduct }}</label>
		</div>
		<!-- table -->
		<div class="ui-g-12 search-table-outter ui-g-nopad" [ngClass]="isFooter ? 'height-table-500' : 'height-table-450'">
			<table
				class="hover dataTable dataTables-parti-coloured"
				style="width: 100%; border: 0"
				aria-describedby="deducted-not-coming-work"
			>
				<thead>
					<tr>
						<th style="display: none"></th>
						<th class="th-sm-1 border-top-left-default" style="text-align: left; width: 25%">
							<label for="input">{{ "emp001_t14_date" | translate }}</label>
						</th>
						<th class="th-sm-1" style="text-align: left; width: 25%">
							<label for="input">{{ "emp001_t14_shift_time" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t14_stamp_in" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t14_stamp_out" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 15%" style="text-align: left">
							<label for="input">{{ "emp001_t14_absent" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "common_status" | translate }}</label>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of employeeFinancialsAbsenceDeduct; let i = index">
						<td style="text-align: left">{{ item.date }}</td>
						<td style="text-align: left">
							{{ data.getIsTH(data.currentLanguage | async) ? item.shiftName : item.shiftName_EN }}
						</td>
						<td style="text-align: left">{{ item.stampIn != null ? item.stampIn : "-" }}</td>
						<td style="text-align: left">{{ item.stampOut != null ? item.stampOut : "-" }}</td>
						<td style="text-align: left">
							{{ data.getIsTH(data.currentLanguage | async) ? item.absentString : item.absentString_EN }}
						</td>
						<td style="text-align: left">
							<span
								class="badge-status"
								[style.background-color]="'var(--go5-color-primary)'"
								[style.border-color]="'var(--go5-color-primary)'"
							>
								{{
									data.getIsTH(data.currentLanguage | async)
										? item.timeAttendanceStatusName
										: item.timeAttendanceStatusName_EN
								}}
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div
		class="p-0 ui-g-12"
		*ngIf="employeeFinancialsAbsenceDeduct == null || employeeFinancialsAbsenceDeduct?.length == 0"
	>
		<div class="height-50 p-2" style="display: flex; flex-direction: row; align-items: center; justify-content: center">
			<div style="display: flex; align-items: center; flex-direction: column">
				<svg class="no-data"></svg>
				<label class="go5-text-color-8">{{ "common_no_data" | translate }}</label>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #deductedLateEarly>
	<div class="p-0 ui-g-12" *ngIf="!(employeeFinancialsLateDeduct == null || employeeFinancialsLateDeduct?.length == 0)">
		<div class="d-flex" style="flex-direction: column; gap: 8px; padding: 0 0 20px 0">
			<label class="go5-text-color-8">{{ "emp001_t14_payment_cycle_cal" | translate }}</label>
			<label class="go5-text-color-12 go5-text-body-strong">{{ dateCycleCalLateDeduct }}</label>
		</div>
		<!-- table -->
		<div class="ui-g-12 search-table-outter ui-g-nopad" [ngClass]="isFooter ? 'height-table-500' : 'height-table-450'">
			<table
				class="hover dataTable dataTables-parti-coloured"
				style="width: 100%; border: 0"
				aria-describedby="deducted-late-early"
			>
				<thead>
					<tr>
						<th style="display: none"></th>
						<th class="th-sm-1 border-top-left-default" style="width: 30%" style="text-align: left">
							<label for="input">{{ "emp001_t14_date" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 20%" style="text-align: left">
							<label for="input">{{ "emp001_t14_shift_time" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t14_stamp_in" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t14_stamp_out" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t14_late" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "emp001_t14_early" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%" style="text-align: left">
							<label for="input">{{ "common_status" | translate }}</label>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of employeeFinancialsLateDeduct; let i = index">
						<td style="text-align: left">{{ item.date }}</td>
						<td style="text-align: left">
							{{ data.getIsTH(data.currentLanguage | async) ? item.shiftName : item.shiftName_EN }}
						</td>
						<td style="text-align: left">{{ item.stampIn !== "" ? item.stampIn : "-" }}</td>
						<td style="text-align: left">{{ item.stampOut !== "" ? item.stampOut : "-" }}</td>
						<td style="text-align: left">
							{{ data.getIsTH(data.currentLanguage | async) ? item.lateString : item.lateString_EN }}
						</td>
						<td style="text-align: left">
							{{ data.getIsTH(data.currentLanguage | async) ? item.earlyString : item.earlyString_EN }}
						</td>
						<td style="text-align: left">
							<span
								class="badge-status"
								[style.background-color]="'#FFF3EC'"
								[style.border-color]="'#FFF3EC'"
								[style.color]="'var(--go5-color-primary)'"
							>
								{{
									data.getIsTH(data.currentLanguage | async)
										? item.timeAttendanceStatusName
										: item.timeAttendanceStatusName_EN
								}}
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="p-0 ui-g-12" *ngIf="employeeFinancialsLateDeduct == null || employeeFinancialsLateDeduct?.length == 0">
		<div class="height-50 p-2" style="display: flex; flex-direction: row; align-items: center; justify-content: center">
			<div style="display: flex; align-items: center; flex-direction: column">
				<svg class="no-data"></svg>
				<label class="go5-text-color-8">{{ "common_no_data" | translate }}</label>
			</div>
		</div>
	</div>
</ng-template>

<div
	id="footer"
	*ngIf="isShowFooter && paymentPeriod && indexTab != 0 && indexTab != 9"
	[ngStyle]="{ 'pointer-events': 'auto', height: 'auto' }"
	class="bg-default fadeIn"
	[ngClass]="{ 'footer-box': isFooter }"
>
	<ng-template [ngTemplateOutlet]="footer"></ng-template>
</div>

<ng-template #footer>
	<div
		id="total_earning"
		class="d-flex px-3 pt-2 fadeIn"
		*ngIf="indexTab == 1 || indexTab == 2"
		style="display: flex; justify-content: flex-end"
		[ngClass]="{ 'border-top-gray': !isFooter && indexTab >= 0 && indexTab <= 1 }"
	>
		<!-- summary -->
		<div class="ui-g-12 py-0" *ngIf="indexTab == 1">
			<div class="ui-g-12 ui-md-12 ui-lg-6 text-right" style="padding-right: 36px">
				<label class="pr-3">{{ "emp001_t14_total_earning" | translate }}</label>

				<label class="color-caribbean go5-text-title-strong">
					฿{{ isShowSalary ? (sumType1 | number: "1.2-2") : maskAmount }}
				</label>
			</div>
			<div class="ui-g-12 ui-md-12 ui-lg-6 text-right" style="padding-right: 10px">
				<label class="text-right pr-3">{{ "emp001_t14_total_deduction" | translate }}</label>

				<label class="color-validation go5-text-title-strong">
					฿{{ isShowSalary ? (sumType2 | number: "1.2-2") : maskAmount }}
				</label>
			</div>
		</div>
		<div class="ui-g-12 text-right" *ngIf="indexTab == 2">
			<span class="text-right mx-4">
				<label class="pr-3">{{ "emp001_t04_ot1" | translate }}</label>
				฿
				<label>
					{{ isShowSalary ? (sumOT1 | number: "1.2-2") : maskAmount }} ({{ sumTimeOT1 }}
					{{ "emp001_t04_hours" | translate }})
				</label>
			</span>
			<span class="text-right mx-4">
				<label class="text-right pr-3">{{ "emp001_t04_ot2" | translate }}</label>
				฿
				<label>
					{{ isShowSalary ? (sumOT2 | number: "1.2-2") : maskAmount }} ({{ sumTimeOT2 }}
					{{ "emp001_t04_hours" | translate }})
				</label>
			</span>
			<span class="text-right mx-4">
				<label class="text-right pr-3">{{ "emp001_t04_ot3" | translate }}</label>
				฿
				<label>
					{{ isShowSalary ? (sumOT3 | number: "1.2-2") : maskAmount }} ({{ sumTimeOT3 }}
					{{ "emp001_t04_hours" | translate }})
				</label>
			</span>
			<span class="text-right mx-4">
				<label class="text-right pr-3">{{ "emp001_t04_ot4" | translate }}</label>
				฿
				<label>
					{{ isShowSalary ? (sumOT4 | number: "1.2-2") : maskAmount }} ({{ sumTimeOT4 }}
					{{ "emp001_t04_hours" | translate }})
				</label>
			</span>
			<span class="text-right mx-4">
				<label class="text-right pr-3">{{ "emp001_t04_ot_fix_rate" | translate }}</label>
				฿
				<label>
					{{ isShowSalary ? (sumFixRateOT | number: "1.2-2") : maskAmount }} ({{ sumTimeFixRateOT }}
					{{ "emp001_t04_hours" | translate }})
				</label>
			</span>
		</div>
	</div>
	<div
		id="cal_salary"
		class="d-flex p-3 fadeIn"
		style="display: flex; justify-content: flex-end; align-items: center"
		[ngClass]="{ 'border-top-gray': !isFooter && (indexTab == 3 || indexTab == 4) }"
	>
		@if (indexTab == 1 && !(isClosed || !allowAdd || !tabPermissionPayroll)) {
			<go5-button
				data-testid="button_cal_salary"
				class="pr-2"
				iconClass="gf-icon-payroll"
				label="{{ 'pay001_cal_salary' | translate }}"
				category="secondary"
				size="medium"
				type="button"
				(click)="calculateSalary()"
			>
			</go5-button>
		}

		<go5-button
			data-testid="button_button_save"
			*ngIf="indexTab == 1"
			class="pr-2"
			label="{{ 'common_save' | translate }}"
			category="primary"
			size="medium"
			type="button"
			(click)="save()"
			[disabled]="isRequired || isClosed || !allowAdd || !isShowSalary"
		>
		</go5-button>

		<span class="text-right mx-4 fadeIn" *ngIf="indexTab == 3">
			<label *ngIf="indexTab == 3" class="text-right pr-3">{{ "emp001_t04_sum_absent_day" | translate }} :</label>
			<label>
				{{
					data.getIsTH(data.currentLanguage | async)
						? employeeFinancialsAbsenceDeduct[0]?.amountAbsentString
						: employeeFinancialsAbsenceDeduct[0]?.amountAbsentString_EN
				}}
			</label>
		</span>
		<span class="text-right mx-4 fadeIn" *ngIf="indexTab == 4">
			<label *ngIf="indexTab == 4" class="text-right pr-3">{{ "emp001_t04_sum_late_early" | translate }} :</label>
			<label>
				{{
					data.getIsTH(data.currentLanguage | async)
						? employeeFinancialsLateDeduct[0]?.amountLateEarlyString
						: employeeFinancialsLateDeduct[0]?.amountLateEarlyString_EN
				}}
			</label>
		</span>

		<div
			*ngIf="indexTab == 1 || indexTab == 2 || indexTab == 3 || indexTab == 4"
			class="mx-2 px-3"
			style="background: #f6f6f8; border-radius: 6px; height: 40px; display: flex; align-items: center"
		>
			<p *ngIf="!tabTax" class="text-title-strong py-1 m-0">
				<ng-template [ngIf]="indexTab == 3 || indexTab == 4" [ngIfElse]="netPay">
					<label>{{ "emp001_t14_net_deduction" | translate }}:</label>
				</ng-template>
				<ng-template #netPay>
					<label>{{ "emp001_t14_net_pay" | translate }}</label>
				</ng-template>
				<span class="font22 weight-bold color-iron pl-5"
					>฿{{ isShowSalary ? (netpay | number: "1.2-2") : maskAmount }}</span
				>
			</p>
			<p *ngIf="tabTax" class="text-title-strong py-1 m-0">
				<label>{{ "withholding_tax" | translate }}</label>
				<span class="font22 weight-bold color-iron pl-5"
					>฿{{ isShowSalary ? (taxDetails.netAmount | number: "0.2-2") : maskAmount }}</span
				>
			</p>
		</div>
	</div>
</ng-template>

<go5-dialog
	*ngIf="popup_History_Edit"
	[(visible)]="popup_History_Edit"
	[width]="'60vw'"
	[textHeader]="'common_edit_history' | translate"
>
	<payroll-history [employeeId]="id" [isShowSalary]="isShowSalary"></payroll-history>
</go5-dialog>

<go5-dialog [(visible)]="popup_Confirm" [width]="'60vw'" [textHeader]="'emp002_action_information' | translate">
	<div class="ui-g-12 ui-g-nopad" style="text-align: left">
		<div class="ui-g-12">
			<form [formGroup]="form" (ngSubmit)="onSubmitCompare()">
				<div class="ui-g-12">
					<div *ngIf="baseSalary != null" class="ui-g-12 ui-g-nopad">
						<div class="ui-g-12 ui-md-6 ui-lg-4">
							<div class="text-body color-anchor pb-2">{{ "common_reason_edit" | translate }} *</div>
							<div class="ui-g-12 ui-g-nopad">
								<p-selectButton
									[options]="editTypeOption"
									[(ngModel)]="editType"
									formControlName="isEffective"
									(onChange)="updateValidator()"
								>
									<ng-template let-item>
										<div style="padding: 0.2em 1rem">
											{{ item.label | translate }}
										</div>
									</ng-template>
								</p-selectButton>
							</div>
						</div>
						<div class="ui-g-12 ui-md-6 ui-lg-2 ui-lg-offset-2" *ngIf="editType">
							<div class="text-body color-porpoise pb-2">{{ "emp001_t14_effective_date" | translate }} *</div>
							<p-calendar
								data-testid="input_calendar_datecreated"
								icon="icon-calendar-dropdown"
								[showIcon]="true"
								dateFormat="dd/mm/yy"
								showButtonBar="true"
								formControlName="dateCreated"
								[monthNavigator]="true"
								[yearNavigator]="true"
								yearRange="1950:{{ presentYear + 1 }}"
								placeholder="{{ 'common_placeholder_date' | translate }}"
								[(ngModel)]="date"
							>
							</p-calendar>
						</div>
						<div class="ui-g-12 ui-md-6 ui-lg-4" *ngIf="editType">
							<div class="text-body color-porpoise pb-2">{{ "common_remark" | translate }}</div>
							<input
								data-testid="input_text_remarks"
								pInputText
								type="text"
								[(ngModel)]="remarks"
								formControlName="remarks"
							/>
						</div>
					</div>
					<div class="ui-g-12 ui-g-nopad">
						<div class="ui-g-12 ui-md-12 ui-lg-6">
							<div class="text-header-3 text-color-10 border-bottom-gray pb-2">
								{{ "emp002_information_original" | translate }}
							</div>
							<div
								class="ui-g-12"
								style="
									background-color: var(--go5-text-color-3) 33;
									padding: 30px;
									font-size: 1.25em;
									margin-top: 30px;
									line-height: 37px;
								"
							>
								<div
									*ngFor="let item of compareList; let i = index"
									class="ui-g-12 ui-g-nopad"
									style="display: inline-flex"
								>
									<div class="text-body color-iron text-right" style="width: 40%">
										{{ data.getIsTH(data.currentLanguage | async) ? item.name : item.name_EN }}
									</div>
									<div class="text-body color-iron text-center" style="width: 20px">:</div>
									<div class="text-body color-iron" style="width: 60%">
										{{ isShowSalary ? (item.oldValue | number) : maskAmount }}
									</div>
								</div>
							</div>
						</div>
						<div class="ui-g-12 ui-md-12 ui-lg-6">
							<div class="text-header-3 text-color-10 border-bottom-gray pb-2">
								{{ "emp002_adjust_to" | translate }}
							</div>
							<div
								class="ui-g-12"
								style="
									background-color: var(--go5-text-color-3) 33;
									padding: 30px;
									font-size: 1.25em;
									margin-top: 30px;
									line-height: 37px;
								"
							>
								<div
									*ngFor="let item of compareList; let i = index"
									class="ui-g-12 ui-g-nopad"
									style="display: inline-flex"
								>
									<div class="text-body color-iron text-right" style="width: 40%">
										{{ data.getIsTH(data.currentLanguage | async) ? item.name : item.name_EN }}
									</div>
									<div class="text-body color-iron text-center" style="width: 20px">:</div>
									<div class="text-body color-iron" style="width: 60%">
										{{ isShowSalary ? (item.newValue | number) : maskAmount }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="ui-g-12 ui-g-nopad" style="text-align: right">
						<div class="ui-g-12">
							<go5-button
								data-testid="button_button_cancel"
								class="pr-2"
								label="{{ 'common_cancel' | translate }}"
								category="secondary"
								size="medium"
								type="button"
								(click)="closeDialog()"
							>
							</go5-button>
							<go5-button
								data-testid="button_submit_save"
								label="{{ 'common_save' | translate }}"
								category="primary"
								size="medium"
								type="submit"
								[disabled]="!form.valid"
							>
							</go5-button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</go5-dialog>
