export enum PaymentType {
	daily = 1,
	monthly = 2,
}

export enum EslipType {
	eslip = 1,
	noEmailEslip = 2,
	allEmailInPeriod = 3,
	eslipSearched = 4,
}
export enum PndType {
	pnd = 4,
	allPnd = 5,
	pndTxt = 6,
	pnd3 = 8,
	allPnd3 = 9,
	pnd3Txt = 10,
	tavi50 = 11,
	pnd1Kor = 12,
	pnd1KorAll = 13,
	pndNew = 14,
}
export enum SsoType {
	ssoInPeriod = 7,
}

export enum PayrollCategory {
	eslip = 1,
	pnd = 2,
	sso = 3,
}

export enum EmailPayrollDocumentType {
	eslip = 1,
	tavi50 = 2,
	certificateEmployment = 3,
	certificateSalary = 4,
}
