import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";

@Injectable()
export class EmployeeDocumentService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getEmployeeDocumentDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeDocument/GetEmployeeDocumentDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getEmployeeDocuments(employeeId: number) {
		return this.http.get<any[]>(this.apiUrl + "EmployeeDocument/GetEmployeeDocuments/" + employeeId);
	}
}
