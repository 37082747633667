import { Employee } from "../../../employee-movements/shared/employee-movements.model";
import { TimeSpan } from "../../../shared/models/timespan.model";

export class Leave {
	dateTimeStamp: string;
	attachments: any[];
	timelines: any[];
	workInStatusId: number;
	leaveRequestId: number;
	userId: string;
	dateFrom: Date;
	dateTo: Date;
	dateChangeFrom: Date;
	dateChangeTo: Date;
	leaveTypeId: number;
	totalDays: number;
	totalHours: number;
	modifiedBy: string;
	description: string;
	dateCreated: Date;
	documentStatusId: number;
	amountOfHours: number;
	amountOfMinute: number;
	amountOfDay: number;
	status: string;
	requestTypeName: string;
	cancelReason: string;
	leaveFiles: LeaveAttachment[];
	agentId: number;
	leaveCode: string;
	agentName_TH: string;
	agentName_EN: string;
	leaveAttachments: LeaveAttachment[];
	//exchangeableTime: ExchangeableTime[];
	exchangeableTime: ExchangeableTimeModel[];
	processActionId: number;
	employee: string;
	documentStatusName: string;
	employeeId: number;
	employeeNo: string;
	leaveTypeName: string;
	leaveTypeName_EN: string;
	documentStatusName_EN: string;
	requestorFullName: string;
	requestorFullName_EN: string;
	nextApprovalFullName: string;
	nextApprovalFullName_EN: string;
	requestorPicture: string;
	pictureThumbnailURL: string;
	requestorPositionName: string;
	requestorPositionName_EN: string;
	requestorRankName: string;
	leaveCategoryId: number;
	totalOfLeave: string;
	totalOfLeave_EN: string;
	totalOfExchange: string;
	totalOfExchange_EN: string;
	asOfDate_TH: string;
	asOfDate_EN: string;
	dateFrom_TH: string;
	dateFrom_EN: string;
	dateTo_TH: string;
	dateTo_EN: string;
	dateCreate_TH: string;
	dateCreate_EN: string;
	dateRange_TH: string;
	dateRange_EN: string;
	dateChange: string;
	dateChange_EN: string;
	dateChangeFrom_TH: string;
	dateChangeFrom_EN: string;
	dateChangeTo_TH: string;
	dateChangeTo_EN: string;
	dateChangeRange_TH: string;
	dateChangeRange_EN: string;
	employees: Employee[];
	otType: number;
	otValue: number;
	asOfDate: Date;
	dateOT: Date;
	endorseType: string;
	endorseType_EN: string;
	leaveDurationType: number;
	createdBy: string;
	moduleId: number;
	shortDateChange: string;
	shortDateChange_EN: string;
	shortAsOfDate: string;
	shortAsOfDate_EN: string;
	date: Date;
	time: TimeSpan;
	asOfDateStamps: string;
	asOfDateStamps_EN: string;
	dateFromStamps: string;
	dateChangeStamps: string;
	nextApprovalUserId: string;
	nextApprovalEmployeeId?: number;
	shiftOfDate: string;
	shiftOfDate_EN: string;
	otType1_TH: string;
	otType1_EN: string;
	otType2_TH: string;
	otType2_EN: string;
	otType3_TH: string;
	otType3_EN: string;
	otType4_TH: string;
	otType4_EN: string;
	totalOfOT1_TH: string;
	totalOfOT1_EN: string;
	totalOfOT2_TH: string;
	totalOfOT2_EN: string;
	totalOfOT3_TH: string;
	totalOfOT3_EN: string;
	totalOfOT4_TH: string;
	totalOfOT4_EN: string;
	totalMinuteOfOT1: number;
	totalMinuteOfOT2: number;
	totalMinuteOfOT3: number;
	totalMinuteOfOT4: number;
	asOfDate_Fm1_TH: string;
	asOfDate_Fm1_EN: string;
	isHR: boolean;
	isMe: boolean;
	processing: boolean;
	isSupervisor: boolean;
	leaveLogs: any;
	workflows: any;
	functionCode: string;
	isRequireAttachment: boolean;
	longDateRange_TH: string;
	longDateRange_EN: string;
	remarkReturn: string;
	isOnlyCollectTime: boolean;
	isEmergencyLeave: boolean;
	asOfDateStampsIn: string;
	asOfDateStampsOut: string;
	otCompletedHours_TH: string;
	otCompletedHours_EN: string;
	otApprovedHours_TH: string;
	otApprovedHours_EN: string;
	isExpire: boolean;
	timeCollectedRemaining: number;
	timeCollectedRemaining_TH: string;
	timeCollectedRemaining_EN: string;
}

export class LeaveAttachment {
	leaveAttachmentId: number;
	leaveRequestId: number;
	attachmentNo: number;
	attachmentMimeType: string;
	attachmentFilename: string;
	attachmentSize: number;
	attachmentURL: string;
	data: string;
	filename: string;
	extension: string;
	isActive: boolean;
	file: string;
	fileBase64: string;
	fileType: string;
	fileSize: string;
	userId: string;
	date: Date;
	time: TimeSpan;
	isWFH: boolean;
}

export class LeaveTotal {
	leaveDays: string;
	leaveHours: string;
	leaveBalThisYear: string;
	leaveBalLastYear: string;
	leaveBalThisYearHours: string;
	leaveBalLastYearHours: string;
	carryMonthExpire: string;
	leaveDate: Date;
	year: string;
}

export class AbnormalTime {
	times: string;
	times_EN: string;
	timeStart: Date;
	timeEnd: Date;
	stampIn: Date;
	stampOut: Date;
	lateTimeString: string;
	earlyTimeString: string;
	absentTimeString: string;
	lateTimeString_EN: string;
	earlyTimeString_EN: string;
	absentTimeString_EN: string;
	breakMinute: number;
}

export class ExchangeableTime {
	timeStart: Date;
	timeEnd: Date;
	stampOut: Date;
	exchangeable: string;
	exchanged: string;
	balance: string;
	exchangeable_EN: string;
	exchanged_EN: string;
	balance_EN: string;
	balanceTime: number;
}

export class ExchangeableTimeModel {
	//pure create
	exchangeableId: number;
	fullName: string;
	userId: string;
	employeeId: number;
	companyId: number;
	date: Date;
	dateFrom: Date;
	dateTo: Date;
	realStampIn: Date;
	realStampOut: Date;
	shiftId: number;
	shiftName: string;
	timeAttendanceStatusId: number;
	attendanceStatus: string;
	attendanceStatus_EN: string;
	stampIn: string;
	stampOut: string;
	workTime: string;
	isFlexible: boolean;
	minuteFlexible: number;
	totalTime: number;
	totalTime_TH: string;
	totalTime_EN: string;
	balance: number;
	balance_TH: string;
	balance_EN: string;
	exchanged: number;
	exchanged_TH: string;
	exchanged_EN: string;
	dateFrom_TH: string;
	dateFrom_EN: string;
	dateTo_TH: string;
	dateTo_EN: string;
	realStampOut_TH: string;
	realStampOut_EN: string;
}

export class ExchangeableCollectTimeModel extends ExchangeableTimeModel {
	leaveRequestId: number;
	leaveCode: string;
}

export class ActionProcess {
	leaveRequestId: number;
	processActionId: number;
	cancelReason: string;
}

export class LeaveRequestLog {
	logId: number;
	leaveRequestId: number;
	employeeId: number;
	userId: number;
	processId: number;
	remark: string;
	createdBy: string;
	dateCreated: Date;
	processAction: string;
	processAction_EN: string;
	employeeName: string;
	employeeName_EN: string;
	pictureThumbnailURL: string;
	pictureURL: string;
	stepNo: number;
	dateProcessAction_TH: string;
	dateProcessAction_EN: string;
}
