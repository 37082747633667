export enum RouteURL {
	pageNotFound = "page-not-found",
	accessDenied = "access-denied",
	home = "home",
	dashboard = "dashboard",
	report = "report",
	myDocument = "mydocument",
	tenantSetup = "settings",
	billing = "billing",
	integration = "integration",
	team = "team",
	employee = "employee",
	userInfo = "user-info",
	master = "master",
	general = "general",
	masterGeneral = "master-general",
	bankAccount = "bank-account",
	companyProfile = "companies",
	orgstructure = "orgstructure",
	position = "position",
	positions = "positions",
	shift = "shifts",
	location = "locations",
	calendar = "holidays",
	workflow = "workflows",
	leaveType = "leavetypes",
	employmentType = "employment-types",
	financialItem = "income-and-deduction",
	payroll = "payroll",
	period = "periods",
	budget = "budget",
	paymentPeriodAdd = "periods/create",
	attendance = "attendance",
	masterSetupGroup = "master-setup-group",
	socialSecurityRate = "social-contribution",
	accumulateConfig = "year-to-date",
	documentTemplate = "template",
	assetManagement = "assets",
	asset = "asset",
	componentSetting = "component-setting",
	emailNotificationSetting = "email-message",
	emailTemplate = "notification-message",
	expenseSetup = "expense-setup",
	settingTaxExemption = "setting-tax-exemption",
	backgroundRecord = "background-record",
	orgsettings = "orgsettings",
	features = "features",
	users = "users",
	import = "import",
	announcement = "announcement",
	policy = "policy",
	onboarding = "onboarding",
	learn = "learn",

	employeePicture = "employee-picture",
	assessment = "assessment",
	skill = "skill",
	trainingCourse = "training-course",
	leaveHistory = "leave-history",
	leaveBenefit = "leave-benefit",
	movementHistory = "movement-history",
	providentFunds = "provident-funds",
	accumulateIncome = "accumulate-income",
	taxDeclaration = "tax-declaration",
	incomeAndDeduction = "income-and-deduction",
	timeStamps = "time-stamps",
	workInLocation = "workin-location",

	backgroundChecker = "background-checker",
	azureAD = "azure-ad",

	companySimulation = "company-simulation",
	roles = "roles",
	permissions = "permissions",
	payrollPermissions = "payroll-permissions",
	log = "log",
	customField = "custom-field",

	welfare = "welfare",
	liabilities = "liabilities",
	shuttleBus = "shuttle-bus",
	collectTime = "collect-time",
	welfareDocument = "welfare-document",
	employeeCard = "employee-card",
	employeeLocker = "employee-locker",
	foodCoupon = "food-coupon",
	ejib = "ejib",
	commission = "commission",
	calculateDiligentAllowance = "calculate-diligent-allowance",
	branch = "branch",
	condition = "condition",
	overtime = "overtime",
	document = "document",
	eSlip = "e-slip",
	support = "support",
	movement = "movement",
	emconnect = "emconnect",
	applicants = "applicants",
	pending = "pending",
	pms = "pms",
	probation = "probation",
	annual = "annual",
	plan = "plan",
	exit = "exit",
	goal = "goals",
	expense = "expense",
	manage = "manage",
	training = "training",
	WarningLetter = "warning-letter",
	pendingstart = "pending-start",
	resign = "resign",
	permanant = "permanant",
	workSchedule = "workSchedule",
	calendarReport = "calendar",
	manageShift = "manageShift",
	timeStamp = "timeStamp",
	allowanceSetUp = "allowance-setup",
}
export enum ParamURL {
	payslip = "payslip",
}
