import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { ApiResult } from "../../../shared/models/api-result.model";
import { CommonService } from "../../../shared/service/common.service";
import {
	CommissionSaleReport,
	CommissionDeliveryReport,
	CommissionDateChequePassedReport,
	CommissionDateBankDeposit,
	CommissionDocumentTemplate,
} from "./commission.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class CommissionService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getCommissionSaleReportDataTable(dataTablesParameters: any) {
		this.commonService.changeDateJSON();
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Commission/GetCommissionSaleReportDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getCommissionDeliveryReportDataTable(dataTablesParameters: any) {
		this.commonService.changeDateJSON();
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Commission/GetCommissionDeliveryReportDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getCommissionDateChequePassedReportDataTable(dataTablesParameters: any) {
		this.commonService.changeDateJSON();
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Commission/GetCommissionDateChequePassedReportDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getCommissionDateBankDepositDataTable(dataTablesParameters: any) {
		this.commonService.changeDateJSON();
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Commission/GetCommissionDateBankDepositDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getCommissionRateSummaryDataTable(dataTablesParameters: any) {
		this.commonService.changeDateJSON();
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Commission/GetCommissionRateSummaryDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getCommissionApprovalPriceDataTable(dataTablesParameters: any) {
		this.commonService.changeDateJSON();
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Commission/GetCommissionApprovalPriceDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getCommissionDocumentTemplateDataTable(dataTablesParameters: any) {
		this.commonService.changeDateJSON();
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Commission/GetCommissionDocumentTemplateDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	importFileCommissionSaleReport(file: string) {
		var body = JSON.stringify(file);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Commission/ImportFileCommissionSaleReport", body, httpOptions);
	}

	importFileCommissionDeliveryReport(file: string) {
		var body = JSON.stringify(file);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Commission/ImportFileCommissionDeliveryReport", body, httpOptions);
	}

	importFileCommissionDateChequePassedReport(file: string) {
		var body = JSON.stringify(file);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Commission/ImportFileCommissionDateChequePassedReport", body, httpOptions);
	}

	importFileCommissionDateBankDeposit(file: string) {
		var body = JSON.stringify(file);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Commission/ImportFileCommissionDateBankDeposit", body, httpOptions);
	}

	deleteCommissionSaleReport() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/DeleteCommissionSaleReport")).then(
			(res) => <ApiResult>res,
		);
	}

	deleteCommissionDeliveryReport() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/DeleteCommissionDeliveryReport")).then(
			(res) => <ApiResult>res,
		);
	}

	deleteCommissionDateChequePassedReport() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/DeleteCommissionDateChequePassedReport")).then(
			(res) => <ApiResult>res,
		);
	}

	deleteCommissionDateBankDeposit() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/DeleteCommissionDateBankDeposit")).then(
			(res) => <ApiResult>res,
		);
	}

	deleteCommissionRateSummary() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/DeleteCommissionRateSummary")).then(
			(res) => <ApiResult>res,
		);
	}

	deleteCommissionApprovalPrice() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/DeleteCommissionApprovalPrice")).then(
			(res) => <ApiResult>res,
		);
	}

	deleteCommissionDocumentById(templateId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/DeleteCommissionDocumentById/" + templateId)).then(
			(res) => <ApiResult>res,
		);
	}

	getLastCommissionSaleReport() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/GetLastCommissionSaleReport")).then(
			(res) => <CommissionSaleReport>res,
		);
	}

	getLastCommissionDeliveryReport() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/GetLastCommissionDeliveryReport")).then(
			(res) => <CommissionDeliveryReport>res,
		);
	}

	getLastCommissionDateChequePassedReport() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/GetLastCommissionDateChequePassedReport")).then(
			(res) => <CommissionDateChequePassedReport>res,
		);
	}

	getLastCommissionDateBankDeposit() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/GetLastCommissionDateBankDeposit")).then(
			(res) => <CommissionDateBankDeposit>res,
		);
	}

	getLastCommissionRateSummary() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/GetLastCommissionRateSummary")).then(
			(res) => <CommissionDateBankDeposit>res,
		);
	}

	getLastCommissionApprovalPrice() {
		return firstValueFrom(this.http.get(this.apiUrl + "Commission/GetLastCommissionApprovalPrice")).then(
			(res) => <CommissionDateBankDeposit>res,
		);
	}

	addCommissionDocumentTemplate(model: CommissionDocumentTemplate) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Commission/AddCommissionDocumentTemplate", body, httpOptions);
	}

	ExportFileCommissionInitialTyre(model: CommissionDocumentTemplate) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			timeout: 300000,
		};
		return this.http.post(this.apiUrl + "Commission/ExportFileCommissionInitialTyre", body, httpOptions);
	}

	importFileCommissionRateSummary(file: string) {
		var body = JSON.stringify(file);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Commission/ImportFileCommissionRateSummary", body, httpOptions);
	}

	importFileCommissionApprovalPrice(file: string) {
		var body = JSON.stringify(file);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Commission/ImportFileCommissionApprovalPrice", body, httpOptions);
	}
}
