import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable()
export class LeaveExchangeCollectService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getExchangeableCollectTimeByEmployeeId(employeeId: number, date: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "TimeAttendance/GetExchangeableCollectTimeByEmployeeId/" + employeeId + "/" + date),
		).then((res) => <any[]>res);
	}
}
