export class EmployeeSocialSecurity {
	employeeSocialSecurityId: number;
	employeeId: number;
	socialSecurityNo: string;
	evidenceOfRegistrationSSO: string;
	statusChildSSO: number;
	typeCardSSO: number;
	nationalitySSOId: string;
	numberOfChildSSO: number;
	branchId: number;
	typeOfEmploymentSSO: number;
	hospitalId: string;
	dateReceivedSSO: Date;
	hospitalName: string;
	hospitalName_EN: string;
	branchNo: string;
	bankAccountNo: string;
	hospital: string;
	isSoc: boolean;
	terminationReasonSSOId: number;
	businessCode: string;
	rate: number;
	hospitalIdTwo: string;
	hospitalIdThree: string;
}

export class BranchDetail {
	branchId: number;
	businessCode: string;
	rate: number;
	rateBranchStr: string;
	businessCodeBranch: string;
	rateBranch: number;
}
