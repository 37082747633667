import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeEducation } from "./employee-education.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeEducationService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getEmployeeEducationById(employeeEducationId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeEducation/GetEmployeeEducationById/" + employeeEducationId),
		).then((res) => <EmployeeEducation>res);
	}

	getEmployeeEducationByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeEducation/GetEmployeeEducationByEmployeeId/" + employeeId),
		).then((res) => <EmployeeEducation[]>res);
	}

	addEmployeeEducation(emp: EmployeeEducation) {
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeEducation/AddEmployeeEducation", body, httpOptions);
	}

	editEmployeeEducation(emp: EmployeeEducation) {
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeEducation/EditEmployeeEducation", body, httpOptions);
	}

	deleteEmployeeEducation(employeeEducationId: number) {
		var body = JSON.stringify(employeeEducationId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeEducation/DeleteEmployeeEducation", body, httpOptions);
	}
}
