import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LanguageService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getLanguageList() {
		return this.http.get(this.apiUrl + "Common/GetLanguageList");
	}
}
