import { AssessmentScore } from "./assessment-score.model";

export class AssessmentModel extends AssessmentScore {
	title: string;
	title_EN: string;
	weightScore: string;
	score: string;
	answer: string;
	assessorName: string;
	assessorName_EN: string;
	itemNo: string;
	questionType: number;
	assessmentId: number;
	assessmentSetId: number;
	masterName: string;
	masterName_EN: string;
	typeName: string;
	typeName_EN: string;
	assesseeFullName: string;
	assesseeFullName_EN: string;
	assesseePictureURL: string;
	pictureThumbnailURL: string;
	masterSetDateStart: Date;
	masterSetDateEnd: Date;
	assesseeDurationOfEmployment: string;
	assesseeDurationOfEmployment_EN: string;
	masterSetDateStart_TH: string;
	masterSetDateStart_EN: string;
	masterSetDateEnd_TH: string;
	masterSetDateEnd_EN: string;
	dateTimeClosed_TH: string;
	dateTimeClosed_EN: string;
	assesseeEmployeeStatusName: string;
	assesseeEmployeeStatusName_EN: string;
	assesseeEmployeeNo: string;
	assesseeRankName: string;
	isPreview: boolean;
	isWebView: boolean;
	setName: string;
	assessmentPeerReviews: AssessmentPeerReviewModel;
	dateApplied?: Date;
	dateInterview?: Date;
	isOKR: boolean;
	isPeerProbation: boolean;
	assessmentStatus: number;
}

export class AssessmentScoreSearch {
	assessorId: number;
	assessmentId: number;
	assessmentNo: number;
	assesseeId: number;
	assessmentSetId: number;
	assessmentTypeId: number;
	assessmentTypeIds: number[];
	year: number;
	take: number;
}

export class AsssessmentDashboardSearchModel {
	assessmentSetId: number;
	asessmentStatus: number;
	companyId: number;
	name: string;
	orgLevelTwoStr?: string;
	orgLevelThreeStr?: string;
	orgLevelFourStr?: string;
	orgLevelFiveStr?: string;
	orgLevelSixStr?: string;
	organizationId: number;
	orgLevel: number;
	skipRow: number;
	takeRow: number;
	no?: number;
	assessmentTypeId: number;
	filterValue: any;
	isDirect: boolean;
}
export class AsssessmentSetDashboardModel {
	assessmentSetId: number;
	totalEmployee: number;
	maxSelfAssessment: number;
	maxSubordinateAssessment: number;
	maxSupervisorAssessment: number;
	maxOrganizationAssessment: number;
	maxPeerReview: number;
	employeeAsssessmentDashboards: EmployeeAsssessmentDashboard[];
}
export class OrganizaionGroupAssessmentDashboard {
	organizationId: number;
	orgLevel: number;
	organizationName: string;
	organizationName_EN: string;
	totalEmployee: number;
	employee: EmployeeAsssessmentDashboard[];
}

export class EmployeeAsssessmentDashboard {
	assessorEmployeeId: number;
	assessorEmployeeNo: string;
	assessorFullName: string;
	assessorFullName_EN: string;
	assessorPositionName: string;
	assessorPositionName_EN: string;
	assessorPictureURL: string;
	assessorRankNo: string;
	selfAssessments: AssessmentsScore[];
	subordinateAssessments: AssessmentsScore[];
	supervisorAssessments: AssessmentsScore[];
	organizationAssessments: AssessmentsScore[];
	peerReviews: AssessmentsModel[];
}

export class AssessmentScoreSummary {
	total: number;
	assessmentTypeId?: number;
	no: number;
	StackedBar: AssessmentScoreSummaryStackedBar[];
}

export class AssessmentScoreSummaryStackedBar {
	percentage: number;
	count: number;
	result: string;
	assessmentTypeId: number;
}

export class AssessmentsScore {
	scoreId: number;
	assessmentId: number;
	result: string;
	grade: string;
	totalScore: number;
	statusScore: number;
	no: number;
	assesseeEmployeeId: number;
	assesseeEmployeeNo: string;
	assesseeFullName: string;
	assesseeFullName_EN: string;
	assesseePositionName: string;
	assesseePositionName_EN: string;
	assesseeRankNo: string;
	assesseePictureURL: string;
}

export class AssessmentsModel {
	assessmentId?: number;
	seqNo: number;
	assessmentDetails: Array<AssessmentsScore[]>;
	assessmentOther: Array<AssessmentsScore[]>;
}

export class AssessmentQuestionTemplateModel {
	code: string;
	name: string;
	questionType: number;
	modifiedBy: Date;
	dateModified: Date;
	isActive: boolean;
}

export class AssessmentQuestionTemplateSearchModel {
	takeRow = 40;
	skipRow = 0;
	status = 0;
	statusIds: number[] = [0];
	questionTypeId: number;
	keyword = "";
	sort = "name_asc";
}

export class AssessmentQuestionTemplates {
	questionTemplateId: number;
	name: string;
	code: string;
	questionType: number;
	scaleType: number;
	scaleDefineType: number;
	scaleDisplayType: number;
	isHalfPoint: boolean;
	itemNo: number;
	text: string;
	text_En: string;
	isActive: boolean;
	choices: AssessmentChoiceTemplates[] = [];
}

export class AssessmentChoiceTemplates {
	choiceTemplateId: number;
	questionTemplateId: number;
	imageURL: string;
	isDelete: boolean;
	hint: string;
	itemNo: number;
	text: string;
	text_En: string;
}

export class AssessmentPeerReviewModel {
	employeeId: number;
	masterSetId: number;
	no: number;
	minimumPeerReview: number;
	conditionPeerReview: number;
	dateCreated: Date;
	createdBy: string;
	assessmentId: number;
	assessmentName: string;
	assessmentName_EN: string;
	dateStart?: Date;
	dateEnd?: Date;
	dateStart_TH: string;
	dateStart_EN: string;
	dateEnd_TH: string;
	dateEnd_EN: string;
	assessmentTypeId: number;
	assessmentTypeName: string;
	assessmentTypeName_EN: string;
	totalScore: number;
	isDone: number;
	assessmentSetId: number;
}
