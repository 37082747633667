@if (isSidebar) {
	<ng-template [ngTemplateOutlet]="contentSidebar"> </ng-template>
} @else {
	<ng-template [ngTemplateOutlet]="content"> </ng-template>
}

<ng-template #contentSidebar>
	<go5-sidebar
		[(visible)]="isOpen"
		(closed)="onClose()"
		[padding]="'0px'"
		[textHeader]="titleName | translate"
		[isShowFooter]="false"
	>
		@if (isOpen) {
			<ng-container [ngTemplateOutlet]="content"></ng-container>
		}
	</go5-sidebar>
</ng-template>

<ng-template #content>
	@if (isOpen) {
		<div class="bg-default ui-g-12 ui-g-nopad empeo-people-picker">
			<div
				class="ui-g-12 py-0 {{ isMulti ? 'people-picker-content' : 'people-picker-content-single' }}"
				(scroll)="scrolling($event)"
			>
				<div class="ui-g-12 px-0 py-3 title-content-filter">
					<div class="people-picker-filter">
						{{ "people_picker_filter" | translate }}
					</div>
					<label
						class="text-button-clear"
						*ngIf="
							dropdownOrg?.selectedItems?.length ||
							dropdownLocation?.selectedItems?.length ||
							sDropdownRange?.selectedItems?.length ||
							dropdownRankNo?.selectedItems?.length ||
							dropdownRankNo?.selectedItems?.length ||
							dropdownEmploymentTypes?.selectedItems?.length ||
							dropdownEmployeeStatus?.selectedItems?.length ||
							dropdownShift?.selectedItems?.length ||
							dropdownIsSupervisorDDL?.selectedItems?.length ||
							dropdownHasSupervisorDDL?.selectedItems?.length
						"
						(click)="reset()"
					>
						{{ "common_clear" | translate }}
					</label>
				</div>

				<div class="people-picker-group-filter">
					<form [formGroup]="filter" style="flex-flow: row wrap; display: flex">
						<div class="ml-0 mr-2 mb-2">
							<go5-dropdown-selection
								#dropdownOrg
								data-testid="dropdown_single_organizeId"
								[mode]="DropdownSelectMode.Single"
								formControlName="organizeId"
								[allowSelectThis]="true"
								[disabled]="getdisabled(1)"
								[dataSource]="departmentNodeDDL"
								popupType="slide"
								categoryItem="item"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? filedOrganize : filedOrganizeEN"
								popupWidth_px="250"
								(changed)="search($event)"
								placeholder="{{ 'emp001_department' | translate }}"
							>
								<ng-template #toggleTemplate>
									<go5-dropdown-filter-toggle
										size="large"
										[maxWidth]="'250px'"
										label="{{ 'emp001_department' | translate }}"
										[allowShowRemove]="!getdisabled(1)"
										[value]="
											dropdownOrg.selectedItems
												| sSelected
													: (dataSharing.getIsTH(dataSharing.currentLanguage | async) ? filedOrganize : filedOrganizeEN)
												| async
										"
										(clear)="dropdownOrg.value = []; _clear('organizeId')"
										[rotate]="dropdownOrg.isOpen"
									>
									</go5-dropdown-filter-toggle>
								</ng-template>
							</go5-dropdown-selection>
						</div>
						<div *ngIf="viewsValuestr(13) && locationDDL" class="ml-0 mr-2 mb-2">
							<go5-dropdown-selection
								#dropdownLocation
								[dataSource]="locationDDL"
								data-testid="filter_multi_workLocationId"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								[mode]="DropdownSelectMode.Single"
								formControlName="workLocationId"
								[disabled]="getdisabled(13)"
								placeholder="{{ 'sys012_address' | translate }}"
								popupType="slide"
								(changed)="search($event)"
								categoryItem="item"
							>
								<ng-template #toggleTemplate>
									<go5-dropdown-filter-toggle
										size="large"
										label="{{ 'mas004_branch' | translate }}"
										[value]="
											dropdownLocation.selectedItems
												| sSelected
													: (dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN)
												| async
										"
										(clear)="dropdownLocation.value = []; clear($event, 'workLocationId', 13)"
										[rotate]="dropdownLocation.isOpen"
									>
									</go5-dropdown-filter-toggle>
								</ng-template>
							</go5-dropdown-selection>
						</div>

						<div *ngIf="viewsValuestr(7)" class="ml-0 mr-2 mb-2">
							<s-dropdown
								data-testid="dropdown_single_rangdurationemployment"
								#sDropdownRange
								[dataSource]="rangerDDL"
								[mode]="DropdownSelectMode.Single"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								[showTooltip]="false"
								placeholder="{{ 'duration_of_employment' | translate }}"
								cssClass="filter-large-primary disabled-icon ranges"
								mode="single"
								formControlName="durationOfEmployment"
								[showClearButton]="false"
								[popupWidth_px]="350"
								(change)="search($event)"
								[allowFiltering]="false"
							>
								<ng-template #valueTemplate let-items>
									<div class="d-flex d-flex-center filter-content">
										<span *ngIf="items.id == 1" class="ellipsis">
											{{ "duration_of_employment" | translate }}: {{ getRangeDurationOfEmployment }}
											{{ "timer_months" | translate }}
										</span>
										<span *ngIf="items.id != 1" class="ellipsis">
											{{ "duration_of_employment" | translate }}:
											{{ dataSharing.getIsTH(dataSharing.currentLanguage | async) ? items.name : items.name_EN }}
										</span>
									</div>
									<div class="d-flex filter-icon" style="align-items: center">
										<em
											class="gfc-close-mini"
											(click)="sDropdownRange.value = []; clear($event, 'durationOfEmployment', 7)"
										></em>
									</div>
								</ng-template>
								<ng-template #itemTemplate let-items>
									<div *ngIf="items.id == 1" style="user-select: none">
										<div class="mb-2 text-color-12">
											{{ "duration_of_employment" | translate }}: {{ getRangeDurationOfEmployment }}
											{{ "timer_months" | translate }}
										</div>
										<ng-container *ngTemplateOutlet="rangeContent"></ng-container>
										<div class="d-flex min-max-slider-content mt-2" style="justify-content: space-between">
											<span class="min-slider"> 0 </span>
											<span class="max-slider"> 480 </span>
										</div>
									</div>
									<div *ngIf="items.id != 1">
										{{ dataSharing.getIsTH(dataSharing.currentLanguage | async) ? items.name : items.name_EN }}
									</div>
								</ng-template>
							</s-dropdown>
						</div>
						<div *ngIf="viewsValuestr(8)" class="ml-0 mr-2 mb-2">
							<go5-dropdown-selection
								[dataSource]="rankDDL"
								#dropdownRankNo
								data-testid="filter_multi_orgsix"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								[mode]="DropdownSelectMode.Multi"
								[allowFiltering]="false"
								formControlName="rankNoStr"
								[disabled]="getdisabled(8)"
								placeholder="{{ 'wel007_rank' | translate }}"
								popupType="slide"
								(changed)="search($event)"
								categoryItem="item"
							>
								<ng-template #toggleTemplate>
									<go5-dropdown-filter-toggle
										size="large"
										label="{{ 'wel007_rank' | translate }}"
										[selectedItems]="dropdownRankNo.selectedItems"
										[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
										(clear)="dropdownRankNo.value = []; clear($event, 'rankNoStr', 8)"
										[rotate]="dropdownRankNo.isOpen"
										[isSelectAll]="dropdownRankNo.isSelectAll"
									>
									</go5-dropdown-filter-toggle>
								</ng-template>
							</go5-dropdown-selection>
						</div>
						<div *ngIf="viewsValuestr(9) && employmentTypesDDL && employmentTypesDDL.length > 0" class="ml-0 mr-2 mb-2">
							<go5-dropdown-selection
								[dataSource]="employmentTypesDDL"
								#dropdownEmploymentTypes
								data-testid="filter_multi_employmenttype"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								[mode]="DropdownSelectMode.Multi"
								formControlName="employmentTypeStr"
								[disabled]="getdisabled(9)"
								placeholder="{{ 'emp001_employment_type' | translate }}"
								popupType="slide"
								(changed)="search($event)"
								categoryItem="item"
							>
								<ng-template #toggleTemplate>
									<go5-dropdown-filter-toggle
										size="large"
										label="{{ 'emp001_employment_type' | translate }}"
										[selectedItems]="dropdownEmploymentTypes.selectedItems"
										[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
										(clear)="dropdownEmploymentTypes.value = []; clear($event, 'employmentTypeStr', 9)"
										[rotate]="dropdownEmploymentTypes.isOpen"
										[isSelectAll]="dropdownEmploymentTypes.isSelectAll"
									>
									</go5-dropdown-filter-toggle>
								</ng-template>
							</go5-dropdown-selection>
						</div>
						<div
							*ngIf="viewsValuestr(10) && employeeStatusDDL && employeeStatusDDL.length > 0"
							class="ml-0 mr-2 mb-2"
							[ngStyle]="{ 'pointer-events': getdisabled(10) ? 'none' : '' }"
						>
							<go5-dropdown-selection
								[dataSource]="employeeStatusDDL"
								#dropdownEmployeeStatus
								data-testid="filter_multi_employeestatus"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								[mode]="DropdownSelectMode.Multi"
								[allowFiltering]="false"
								formControlName="employeeStatusStr"
								[disabled]="getdisabled(10)"
								placeholder="{{ 'emp001_employee_status' | translate }}"
								popupType="slide"
								(changed)="search($event)"
								categoryItem="item"
							>
								<ng-template #toggleTemplate>
									<go5-dropdown-filter-toggle
										size="large"
										label="{{ 'emp001_employee_status' | translate }}"
										[selectedItems]="dropdownEmployeeStatus.selectedItems"
										[allowShowRemove]="!getdisabled(10)"
										[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
										(clear)="clear($event, 'employeeStatusStr', 10)"
										[rotate]="dropdownEmployeeStatus.isOpen"
										[isSelectAll]="dropdownEmployeeStatus.isSelectAll"
									>
									</go5-dropdown-filter-toggle>
								</ng-template>
							</go5-dropdown-selection>
						</div>

						<div *ngIf="viewsValuestr(14) && shiftDDL && shiftDDL.length > 0" class="ml-0 mr-2 mb-2">
							<go5-dropdown-selection
								#dropdownShift
								data-testid="dropdown_multi_searchshift"
								[mode]="DropdownSelectMode.Multi"
								formControlName="workShiftId"
								[allowSelectThis]="true"
								[dataSource]="shiftDDL"
								popupType="slide"
								categoryItem="item"
								popupWidth_px="300"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								(changed)="search($event)"
								[disabled]="getdisabled(14)"
								placeholder="{{ 'emp001_work_shift' | translate }}"
							>
								<ng-template #toggleTemplate>
									<go5-dropdown-filter-toggle
										size="large"
										[maxWidth]="'250px'"
										label="{{ 'emp001_work_shift' | translate }}"
										[selectedItems]="dropdownShift.selectedItems"
										[allowShowRemove]="!getdisabled(14)"
										[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
										(clear)="dropdownShift.value = []; clear($event, 'workShiftId', 14)"
										[rotate]="dropdownShift.isOpen"
										[isSelectAll]="dropdownShift.isSelectAll"
									>
									</go5-dropdown-filter-toggle>
								</ng-template>
							</go5-dropdown-selection>
						</div>

						<div *ngIf="viewsValuestr(11) && isASMSpecialType" class="ml-0 mr-2 mb-2">
							<go5-dropdown-selection
								[dataSource]="IsSupervisorDDL"
								#dropdownIsSupervisorDDL
								data-testid="filter_multi_issupervisor"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								[mode]="DropdownSelectMode.Single"
								[allowFiltering]="false"
								formControlName="isSupervisor"
								[disabled]="getdisabled(11)"
								placeholder="{{ 'ess009_supervisor' | translate }}"
								popupType="slide"
								(changed)="search($event)"
								categoryItem="item"
							>
								<ng-template #toggleTemplate>
									<go5-dropdown-filter-toggle
										size="large"
										label="{{ 'ess009_supervisor' | translate }}"
										[value]="
											dropdownIsSupervisorDDL.selectedItems
												| sSelected
													: (dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN)
												| async
										"
										(clear)="dropdownIsSupervisorDDL.value = null; clear($event, 'isSupervisor', 11)"
										[rotate]="dropdownIsSupervisorDDL.isOpen"
									>
									</go5-dropdown-filter-toggle>
								</ng-template>
							</go5-dropdown-selection>
						</div>
						<div *ngIf="viewsValuestr(12) && isASMSpecialType" class="ml-0 mr-2 mb-2">
							<go5-dropdown-selection
								[dataSource]="HasSupervisorDDL"
								#dropdownHasSupervisorDDL
								data-testid="filter_multi_hassupervisor"
								[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								[mode]="DropdownSelectMode.Single"
								[allowFiltering]="false"
								formControlName="hasSupervisor"
								[disabled]="getdisabled(12)"
								placeholder="{{ 'mss001_supervisor' | translate }}"
								popupType="slide"
								(changed)="search($event)"
								categoryItem="item"
							>
								<ng-template #toggleTemplate>
									<go5-dropdown-filter-toggle
										size="large"
										label="{{ 'mss001_supervisor' | translate }}"
										[value]="
											dropdownHasSupervisorDDL.selectedItems
												| sSelected
													: (dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN)
												| async
										"
										(clear)="dropdownHasSupervisorDDL.value = null; clear($event, 'hasSupervisor', 12)"
										[rotate]="dropdownHasSupervisorDDL.isOpen"
									>
									</go5-dropdown-filter-toggle>
								</ng-template>
							</go5-dropdown-selection>
						</div>
					</form>
				</div>
				<div class="ui-g-12 p-0">
					<div class="people-picker-content-search">
						<div class="people-picker-contect" style="width: 150px">
							{{ "common_contect" | translate }} ({{ total | number: "0.0" }})
						</div>
						<form [formGroup]="filter" class="text-right people-picker-search">
							<go5-search
								data-testid="input_text_search"
								category="primary"
								[disabled]="false"
								[placeholder]="'common_search_employee' | translate"
								size="large"
								[maxLength]="255"
								formControlName="name"
								(valueChange)="search($event)"
								(clear)="search()"
							>
							</go5-search>
						</form>
					</div>
				</div>
				<div>
					<ng-template [ngIf]="isMulti" [ngIfElse]="atherContent">
						<table class="row-border dataTable focus hover fadeIn" style="width: 100%">
							<thead>
								<tr>
									<th style="position: sticky; top: 0px; z-index: 999; width: 10%">
										<p-checkbox
											data-testid="input_checkbox_selectalldatatable"
											*ngIf="hasMax"
											value="false"
											[(ngModel)]="selectAllDatatable"
											(click)="selectEmp(true)"
											binary="true"
											[disabled]="disabledSelect"
										></p-checkbox>
									</th>
									<th class="th-sm-1 text-left" style="width: 20%">
										<label for="input">{{ "common_code" | translate }}</label>
									</th>
									<th class="th-sm-4 text-left" style="width: 70%">
										<label for="input">{{ "common_employee_name" | translate }}</label>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="let item of employee; let i = index"
									(click)="selectEmp(false, item, item.employeeId)"
									[ngClass]="{ disabled: check(item.employeeId) && disabledSelect }"
									class="fadeIn pointer"
								>
									<td style="width: 10%" class="text-left">
										<p-checkbox
											attr.data-testid="input_checkbox_selecteddatatable_{{ i }}"
											[(ngModel)]="selectedDatatable"
											value="{{ item.employeeId }}"
											[disabled]="check(item.employeeId) && disabledSelect"
										></p-checkbox>
									</td>
									<td style="width: 20%" class="text-left">
										<label
											class="d-flex ellipsis pointer"
											o5-tooltip-placement="top"
											go5-tooltip-mode="hover"
											go5-tooltip="{{ item.employeeNo }}"
										>
											{{ item.employeeNo }}</label
										>
									</td>
									<td style="width: 70%" class="text-left">
										<div class="contacts">
											<div class="ui-g-12 ui-g-nopad ellipsis text-left">
												<img
													class="small-image img-profile"
													library="verona-layout"
													(load)="loadImg($event)"
													(error)="loadImg($event)"
													src="{{ item.pictureURL }}"
												/>
												<p
													go5-tooltip-placement="top"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async) ? item.fullName : item.fullName_EN
													}}"
													class="name ellipsis text-body-strong text-color-12 m-0"
													style="max-width: 200px"
												>
													{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async) ? item.fullName : item.fullName_EN
													}}
												</p>
												<p
													go5-tooltip-placement="top"
													style="max-width: 200px"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? item.positionName
															: item.positionName_EN
													}}"
													class="position ellipsis text-sma m-0"
												>
													{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? item.positionName
															: item.positionName_EN
													}}
												</p>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
							<tr *ngIf="employee == null || employee?.length == 0">
								<td colspan="8" class="no-data-available">
									<div class="empty-image">
										<img alt="apollo-layout" src="assets/images/empty-state-no-data.svg" />
									</div>
									<div class="empty-text text-title-strong color-porpoise py-2 fadeIn">
										<div>{{ "common_no_data" | translate }}</div>
									</div>
								</td>
							</tr>
						</table>
					</ng-template>
					<ng-template #atherContent>
						<table
							datatable
							[dtOptions]="dtOptions"
							ScrollControl
							[scrollDisable]="isLoad"
							(scrollEnd)="load()"
							class="row-border focus"
						>
							<thead>
								<tr>
									<th style="display: none"></th>
									<th class="th-sm-1 text-left" style="width: 30%; text-align: center !important">
										<label for="input">{{ "common_code" | translate }}</label>
									</th>
									<th class="th-sm-4 text-left" style="width: 70%; border-top-right-radius: 6px">
										<label for="input">{{ "common_employee_name" | translate }}</label>
									</th>
									<th style="display: none"></th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="let item of employee; let i = index"
									class="pointer set-active"
									[ngClass]="{ active: selectedUser == item.employeeId }"
									(click)="selectEmp(false, item, item.employeeId)"
								>
									<td
										class="text-left pointer"
										style="width: 30%"
										[ngClass]="{ 'set-active-border': selectedUser == item.employeeId }"
									>
										{{ item.employeeNo }}
									</td>
									<td style="width: 70%" class="text-left">
										<div class="contacts">
											<div class="ui-g-12 ui-g-nopad ellipsis text-left">
												<img
													class="small-image img-profile"
													library="verona-layout"
													(load)="loadImg($event)"
													(error)="loadImg($event)"
													src="{{ item.pictureURL }}"
												/>
												<p
													go5-tooltip-placement="top"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async) ? item.fullName : item.fullName_EN
													}}"
													class="name ellipsis text-body-strong m-0"
												>
													{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async) ? item.fullName : item.fullName_EN
													}}
												</p>
												<p
													go5-tooltip-placement="top"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? item.positionName
															: item.positionName_EN
													}}"
													class="position ellipsis m-0"
													style="max-width: 300px"
												>
													{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? item.positionName
															: item.positionName_EN
													}}
												</p>
											</div>
										</div>
									</td>
								</tr>
							</tbody>

							<ng-template [ngIf]="(employee == null || employee?.length == 0) && !isLoad" [ngIfElse]="loadBlockTable">
								<tr>
									<td colspan="8" style="height: 50vh" class="no-data-available">
										<div class="empty-image">
											<img alt="apollo-layout" src="assets/images/empty-state-no-data.svg" />
										</div>
										<div class="empty-text text-title-strong color-porpoise py-2 fadeIn">
											<div>{{ "common_no_data" | translate }}</div>
										</div>
									</td>
								</tr>
							</ng-template>
						</table>
					</ng-template>
				</div>
			</div>
			<div class="people-picker-footer">
				<div class="d-flex w-100" [ngStyle]="{ 'justify-content': isMulti ? 'space-between' : 'flex-end' }">
					<div class="d-flex select-text" *ngIf="isMulti">
						<span
							[hidden]="showSelect"
							class="txt-select go5-text-body-strong d-flex"
							style="align-items: center"
							(click)="filterEmpSelect(showSelect)"
						>
							{{ "emp_selected" | translate }} {{ selectedDatatable.length }}
							{{ selectedDatatable.length > 1 ? ("common_people" | translate) : ("common_person" | translate) }}
							<i class="gf-icon-right ml-2"> </i>
						</span>

						<span
							[hidden]="!showSelect"
							class="txt-select go5-text-body-strong d-flex"
							style="align-items: center"
							(click)="filterEmpSelect(showSelect)"
						>
							<i class="gf-icon-add float-left mr-2"> </i>
							{{ "emp_select_more" | translate }}
						</span>
					</div>
					<div style="width: auto">
						<go5-button
							class="cancel-button mr-2"
							[label]="'common_cancel' | translate"
							category="secondary"
							size="large"
							type="button"
							(click)="onClose()"
						></go5-button>

						<go5-button
							class="submit-button"
							[label]="btnSubmit | translate"
							category="primary"
							size="large"
							type="button"
							[disabled]="!isValid"
							(click)="submit()"
						></go5-button>
					</div>
				</div>
			</div>
		</div>
	}
</ng-template>
<ng-template #loadBlockTable>
	@if (isOpen) {
		<div *ngIf="employee?.length > 0">
			<tr *ngFor="let number of dataSharing?.counter(5)">
				<td class="py-2">
					<ngx-skeleton-loader class="d-flex" [theme]="{ margin: '0px', width: '100%', height: '45px', float: 'left' }">
					</ngx-skeleton-loader>
				</td>
				<td class="py-2">
					<ngx-skeleton-loader class="d-flex" [theme]="{ margin: '0px', width: '100%', height: '45px', float: 'left' }">
					</ngx-skeleton-loader>
				</td>
			</tr>
		</div>
	}
</ng-template>

<ng-template #rangeContent>
	@if (isOpen) {
		<p-slider
			[(ngModel)]="rangeValues"
			[range]="true"
			[min]="0"
			[max]="480"
			(onChange)="onChangeRangeDurationOfEmployment($event)"
		></p-slider>
	}
</ng-template>
