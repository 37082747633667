import { Pipe, PipeTransform } from "@angular/core";
import { TimeAttandanceStatus } from "../enum/timeAttandance-status.enum";

@Pipe({
	name: "workStatusBackgroundColor",
	standalone: true,
})
export class WorkStatusBackgroundColorPipe implements PipeTransform {
	transform(value: any) {
		switch (value) {
			case TimeAttandanceStatus.absent:
				return "#C81720";
			case TimeAttandanceStatus.late:
				return "#F26C44";
			case TimeAttandanceStatus.early:
				return "#F26C44";
			case TimeAttandanceStatus.noStampIn:
			case TimeAttandanceStatus.noStampOut:
				return "#D2D2DA";
			case TimeAttandanceStatus.holiday:
				return "#FFFFFF";
			case TimeAttandanceStatus.holidayWork:
				return "#c9eeff";
			case TimeAttandanceStatus.leave:
				return "#FD911B";
			case TimeAttandanceStatus.normal:

			default:
				return "white";
		}
	}
}
