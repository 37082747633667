import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CompanyProfile } from "./company-profile.model";
import { CompanyBranchSearch, MasCompanyBranchs } from "./company-branch.model";
import { Dropdown } from "src/app/shared/models/dropdown.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class CompanyProfileService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	addCompanyProfile(comp: CompanyProfile) {
		var body = JSON.stringify(comp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "CompanyProfile/AddCompanyProfile", body, httpOptions);
	}

	editCompanyProfile(comp: CompanyProfile) {
		var body = JSON.stringify(comp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "CompanyProfile/EditCompanyProfile", body, httpOptions);
	}

	deleteCompanyProfile(compId: string[]) {
		var body = JSON.stringify(compId);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "CompanyProfile/DeleteCompanyProfile", body, httpOptions);
	}

	getCompanyById(compId: number) {
		return firstValueFrom(
			this.http.get<CompanyProfile>(this.apiUrl + "CompanyProfile/GetCompanyProfileById/" + compId),
		);
	}

	getCompanyProfileList() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "CompanyProfile/GetCompanyProfileList"));
	}

	deleteCompanyBranch(branchId: number) {
		return this.http.delete(this.apiUrl + "CompanyProfile/DeleteCompanyBranch/" + branchId);
	}

	addCompanyBranch(model: MasCompanyBranchs) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "CompanyProfile/AddCompanyBranch", body, httpOptions);
	}

	getCompanyBranchs(model: CompanyBranchSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<MasCompanyBranchs[]>(this.apiUrl + "CompanyProfile/GetCompanyBranchs ", body, httpOptions);
	}

	getCompanyBranchsDDL(compId: number = 0, empId: number = 0, isSoc: boolean = false) {
		return this.http.get<any[]>(
			this.apiUrl + `CompanyProfile/GetDDLCompanyBranchs?companyId=${compId}&employeeId=${empId}&isSoc=${isSoc}`,
		);
	}
	getCompanyBranchsDDLV2(compId: number = 0, isSoc: boolean = false) {
		return this.http.get<any>(this.apiUrl + `v2/companies/${compId}/branches?isSoc=${isSoc}`);
	}

	getDDLCompanyBranchsDDL(compId: number = 0, empId: number = 0, isSoc: boolean = false) {
		return firstValueFrom(
			this.http.get<any[]>(
				this.apiUrl + `CompanyProfile/GetDDLCompanyBranchs?companyId=${compId}&employeeId=${empId}&isSoc=${isSoc}`,
			),
		).then((res) => <Dropdown[]>res);
	}

	checkCompanyBranchsValid(compId: number = 0, branchNo = "") {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl + `CompanyProfile/CheckCompanyBranchsValid?companyId=${compId}&branchNo=${branchNo}`,
			),
		);
	}

	getResignNoticeByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + `companies/resign-notice/${employeeId}`));
	}

	getMasCompanyAttendanceScores(companyId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + `v1/companies/attendance-scores/${companyId}`));
	}
}
