import {
	booleanAttribute,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import {
	ControlValueAccessor,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	NG_VALUE_ACCESSOR,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { DropdownComponent, AngularCommonModule } from "@gofive/angular-common";
import { DropdownSelectMode, FilterToggleSize, DropdownModule } from "@gofive/design-system-dropdown";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { BehaviorSubject, firstValueFrom, Subscription } from "rxjs";
import { isNullOrUndefined } from "src/app/shared/sharing.service";
import { DataSharingService, UserControlService } from "../../../core";
import { EmployeeService } from "../../../employee-movements/employee/shared/employee.service";
import { Employee, SearchPeoplePickerModel } from "../../../employee-movements/shared/employee-movements.model";
import { Organization, OrganizationConfig } from "../../../master/organization/shared/organization.model";
import { OrganizationService } from "../../../master/organization/shared/organization.service";
import { Level } from "../../enum/level.enum";
import { Module } from "../../enum/module.enum";
import { Dropdown } from "../../models/dropdown.model";
import { MssSearchModel } from "../../models/mss.model";
import { CommonService } from "../../service/common.service";
import { MenuItem } from "primeng/api";
import { Permission } from "../../../shared/models/permission.model";
import { SysComponent } from "src/app/setting/component-setting/shared/component.model";
import { CompanyProfileService } from "src/app/master/company-profile/shared/company-profile.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SliderModule } from "primeng/slider";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ButtonModule } from "@gofive/design-system-button";
import { ScrollDirective } from "../../directive/scroll.directive";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { CheckboxModule } from "primeng/checkbox";
import { InputModule } from "@gofive/design-system-input";
import { SidebarModule } from "@gofive/design-system-navigation";
import { NgTemplateOutlet, NgIf, NgStyle, NgFor, NgClass, AsyncPipe, DecimalPipe } from "@angular/common";
import { ToastHelperService } from "../../service/toast-helper.service";

/**
 *
 *
 * @param ispoen = ตัวเก็บค่า (boolean)
 * @param titleName ชื่อหัวข้อ (key | phrase)
 * @param isMulti ถ้าเป็น false เป็น Sigle Select
 * @param specialType ดูจาก SpecialType
 * @param isWithoutMe เอาตัวเองออกมาด้วยหรือไม่
 * @param maxSelected กำหนดจำนวนสูงสุดในการเลือก ถ้าไม่กำหนด คือ สามารถเลือกทั้งหมดได้
 * @param btnSubmit ชื่อปุ่มที่ใช้ตอนกด submit
 * @param lockFilter object defaultFilter[] = [{ index: เลขฟิลเตอร์ (ดูจาก viewsDDL) } -- or -- { index: 1, value: เลข id ที่จะตั้ง (ดูจาก DDL นั้นๆ) }]
 *
 * @value ngModel -- Or -- formControlName วิธีรับค่า
 *
 * @event closePeoplePicker = หลังจากปิด
 *
 *
 *
 * ### how to use
 *
 *
 * <empeo-people-picker
 *
 *   [isOpen]=" ispoen "
 *   [titleName]=" title "
 *   [(ngModel)]="value" -- or -- formControlName="value"
 *
 *   (close)="isopen = false"
 *
 * ></empeo-people-picker>
 *
 */

export enum SpecialType {
	none = 0,
	asm = 1,
	edit = "edit",
	delete = "delete",
}

export class RangeYear extends Dropdown {
	min: number;
	max: number;
}

export class Filter extends Dropdown {
	disabled?: boolean;
	isCheckDifference?: boolean;
}

export class DefaultFilter {
	index: number;
	value?: any[];
	disabled?: boolean;
	isCheckDifference?: boolean;
}

@Component({
	selector: "empeo-people-picker",
	templateUrl: "./people-picker.component.html",
	styleUrls: ["./people-picker.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PeoplePickerComponent),
			multi: true,
		},
	],
	standalone: true,
	imports: [
		NgTemplateOutlet,
		SidebarModule,
		NgIf,
		FormsModule,
		ReactiveFormsModule,
		DropdownModule,
		AngularCommonModule,
		NgStyle,
		InputModule,
		CheckboxModule,
		NgFor,
		NgClass,
		TooltipModule,
		DataTablesModule,
		ScrollDirective,
		ButtonModule,
		NgxSkeletonLoaderModule,
		SliderModule,
		AsyncPipe,
		DecimalPipe,
		TranslateModule,
	],
})
export class PeoplePickerComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
	public scrollHeight = 0;

	scrolling(e) {
		let element: HTMLElement = e.target;
		if (
			this.scrollHeight < element.scrollHeight &&
			!this.isLoad &&
			element.offsetHeight + element.scrollTop + 500 > element.scrollHeight
		) {
			this.scrollHeight = element.scrollHeight;
			this.load();
		}
	}

	@ViewChild("sDropdownRange") sDropdownRange: DropdownComponent;
	@ViewChild("dropdownOrg") dropdownOrg: DropdownComponent;
	@ViewChild("dropdownLocation") dropdownLocation: DropdownComponent;
	@ViewChild("dropdownRankNo") dropdownRankNo: DropdownComponent;
	@ViewChild("dropdownEmploymentTypes") dropdownEmploymentTypes: DropdownComponent;
	@ViewChild("dropdownEmployeeStatus") dropdownEmployeeStatus: DropdownComponent;
	@ViewChild("dropdownShift") dropdownShift: DropdownComponent;
	@ViewChild("dropdownIsSupervisorDDL") dropdownIsSupervisorDDL: DropdownComponent;
	@ViewChild("dropdownHasSupervisorDDL") dropdownHasSupervisorDDL: DropdownComponent;

	@ViewChild(DataTableDirective, { static: true })
	public dtElement: DataTableDirective;
	public dtOptions: DataTables.Settings = {
		processing: false,
		searching: false,
		paging: false,
		info: false,
		autoWidth: false,
		columnDefs: [{ orderable: false, targets: "_all" }],
	};

	public selectedEmployee: Employee;

	public employeeStatusDDL: Dropdown[];
	public employee = [];
	public employeeTmp = [];
	public employeeTmpSelect = [];
	public employeeAll: Employee[] = [];
	public isLoad = false;
	@Input() public isSidebar = true;
	@Input() public isShowSelect = false;
	@Input() public isMe = true;
	@Input() public employeeId: number = 0;
	public showSelect = false;

	public fieldsDDL = { text: "name", value: "id", children: "children" };
	public fieldsDDL_EN = { text: "name_EN", value: "id", children: "children" };

	public fieldsOrgDDL = { text: "name", value: "organizationId" };
	public fieldsOrgDDL_EN = { text: "name_EN", value: "organizationId" };

	public fields = {
		text: "name",
		value: "organizationId",
		children: {
			name: "children",
			fields: { text: "name", value: "organizationId" },
		},
	};
	public fields_EN = {
		text: "name_EN",
		value: "organizationId",
		children: {
			name: "children",
			fields: { text: "name_EN", value: "organizationId" },
		},
	};

	get DropdownSelectMode() {
		return DropdownSelectMode;
	}

	get FilterToggleSize() {
		return FilterToggleSize;
	}

	public selectedDatatable: string[] = [];

	public selectAllDatatable: boolean;

	public isloadImage = true;
	public countImg = 0;
	@Input() public companyId: number;
	@Input() public isOpen = false;
	@Input() public isMulti = true;
	@Input() public titleName = "common_add_employee";
	@Input() public specialType: SpecialType = SpecialType.asm;
	@Input() public minSelected = 1;
	@Input() public maxSelected: number = null;
	@Input() public isWithoutMe = false;
	@Input() public withOutEmpId = 0;
	@Input() public btnSubmit = "common_add";
	@Input({ transform: booleanAttribute }) public closeAfterSave = true;
	@Input() public lockFilter: DefaultFilter[] = [
		{ index: 1 },
		{ index: 2 },
		{ index: 3 },
		{ index: 6 },
		{ index: 7 },
		{ index: 8 },
		{ index: 9 },
		{ index: 10, value: [1, 2, 4] },
		{ index: 11 },
		{ index: 12 },
		{ index: 13 },
		{ index: 14 },
	];
	@Output() closePeoplePicker = new EventEmitter<boolean>();
	@Output() onChange = new EventEmitter<boolean>();
	@Output() onSubmit = new EventEmitter<boolean>();

	private maxLoad = 50;
	private currentOrganizationConfig$: Subscription;
	private getPeoplePicker$: Subscription;
	private getPeoplePickerByEmployeeIds$: Subscription;
	private organizationName$ = new BehaviorSubject(null);
	public defaultorganizationName$ = this.organizationName$.asObservable();

	permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	componentModel: SysComponent = new SysComponent();

	public organizationName: OrganizationConfig;
	public companyDDL: Dropdown[] = [];
	public orgLevelTwoDDL: Organization[] = [];
	public orgLevelThreeDDL: Organization[] = [];
	public orgLevelFourDDL: Organization[] = [];
	public orgLevelFiveDDL: Organization[] = [];
	public orgLevelSixDDL: Organization[] = [];
	public viewsDDL: Filter[] = [];
	public viewsValue: Filter[] = [];
	public viewsValueId = [];
	public view = new UntypedFormGroup({});
	public filter = new UntypedFormGroup({});
	public total = 0;
	public totalTmp = 0;
	public employmentTypesDDL: Dropdown[];
	public rankDDL: Dropdown[];
	public locationDDL: Dropdown[];
	public shiftDDL = [];
	get isASMSpecialType() {
		return SpecialType.asm == this.specialType;
	}
	get disabledSelect() {
		return isNullOrUndefined(this.maxSelected) ? false : this.employeeAll.length >= this.maxSelected;
	}
	get hasMax() {
		return isNullOrUndefined(this.maxSelected);
	}
	public rangeValues: number[] = [10, 65];
	get getRangeDurationOfEmployment() {
		return `${this.rangeValues[0]} - ${this.rangeValues[1]}`;
	}
	public rangerDDL: RangeYear[] = [];

	public IsSupervisorDDL: Dropdown[] = [];
	public HasSupervisorDDL: Dropdown[] = [];

	public departmentNodeDDL: any[];
	public filedOrganize = {
		text: "name",
		value: "organizationId",
		children: "children",
	};
	public filedOrganizeEN = {
		text: "name_EN",
		value: "organizationId",
		children: "children",
	};
	public searchModel: MssSearchModel = new MssSearchModel();
	public data: DataSharingService;

	listFunctionCode: string[] = [];
	allowSeeAllEmployee: boolean = false;
	private language$: Subscription;
	userInfo: any = null;
	compId: any = null;
	item: MenuItem[];
	public isLoading: boolean;
	constructor(
		private translate: TranslateService,
		private _toastHelperService: ToastHelperService,
		public dataSharing: DataSharingService,
		private builder: UntypedFormBuilder,
		private employeeService: EmployeeService,
		private commonService: CommonService,
		private organizationService: OrganizationService,
		private userControlService: UserControlService,
		private companyProfileService: CompanyProfileService,
		private cdr: ChangeDetectorRef,
	) {
		this.view = this.builder.group({
			// index
			companyId: new UntypedFormControl(null), // 1
			orgLevelTwoStr: new UntypedFormControl(null), // 2
			orgLevelThreeStr: new UntypedFormControl(null), // 3
			orgLevelFourStr: new UntypedFormControl(null), // 4
			orgLevelFiveStr: new UntypedFormControl(null), // 5
			orgLevelSixStr: new UntypedFormControl(null), // 6
			durationOfEmployment: new UntypedFormControl(null), // 7
			ranks: new UntypedFormControl(null), // 8
			employmentType: new UntypedFormControl(null), // 9
			status: new UntypedFormControl(null), // 10
			isSupervisor: new UntypedFormControl(null), // 11
			hasSupervisor: new UntypedFormControl(null), // 12
			location: new UntypedFormControl(null), // 13
			workShiftId: new UntypedFormControl(null), // 14
			organizeId: new UntypedFormControl(null),
		});
		this.filter = this.builder.group({
			companyIdStr: new UntypedFormControl(null),
			orgLevelTwoStr: new UntypedFormControl(null),
			orgLevelThreeStr: new UntypedFormControl(null),
			orgLevelFourStr: new UntypedFormControl(null),
			orgLevelFiveStr: new UntypedFormControl(null),
			orgLevelSixStr: new UntypedFormControl(null),
			durationOfEmployment: new UntypedFormControl(null),
			rankNoStr: new UntypedFormControl(null),
			employmentTypeStr: new UntypedFormControl(null),
			employeeStatusStr: new UntypedFormControl(null),
			isSupervisor: new UntypedFormControl(null),
			hasSupervisor: new UntypedFormControl(null),
			name: new UntypedFormControl(null),
			workLocationId: new UntypedFormControl(null),
			workShiftId: new UntypedFormControl(null),
			organizeId: new UntypedFormControl(null),
		});

		this.organizationService
			.getOrganizationNode()
			.then(async (res: any) => {
				this.departmentNodeDDL = this.mapChildren(res.data, this.filedOrganize);
				if (!isNullOrUndefined(this.companyId) && this.companyId > 0) {
					this.departmentNodeDDL = this.departmentNodeDDL.find(
						(company) => company.companyId === this.companyId,
					)?.dataSource;
				}
			})
			.then(() => {
				this.reset();
			});

		this.rangerDDL = [
			{
				id: 1,
				name: `${this.rangeValues[0]} - ${this.rangeValues[1]}`,
				name_EN: `${this.rangeValues[0]} - ${this.rangeValues[1]}`,
				dataId: 1,
				value: "custom",
				valueNum: 0,
				text: null,
				min: this.rangeValues[0],
				max: this.rangeValues[1],
			},
			{
				id: 2,
				name: "น้อยกว่า 1 เดือน",
				name_EN: "Less than 1 month",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 0,
				max: 1,
			},
			{
				id: 3,
				name: "น้อยกว่า 3 เดือน",
				name_EN: "Less than 3 months",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 0,
				max: 3,
			},
			{
				id: 4,
				name: "น้อยกว่า 6 เดือน",
				name_EN: "Less than 6 months",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 0,
				max: 6,
			},
			{
				id: 5,
				name: "น้อยกว่า 1 ปี",
				name_EN: "Less than 1 year",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 0,
				max: 12,
			},
			{
				id: 6,
				name: "มากกว่า 1 ปี",
				name_EN: "More than 1 year",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 12,
				max: 0,
			},
			{
				id: 7,
				name: "มากกว่า 3 ปี",
				name_EN: "More than 3 years",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 36,
				max: 0,
			},
			{
				id: 8,
				name: "มากกว่า 5 ปี",
				name_EN: "More than 5 years",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 60,
				max: 0,
			},
			{
				id: 9,
				name: "มากกว่า 10 ปี",
				name_EN: "More than 10 years",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 120,
				max: 0,
			},
			{
				id: 10,
				name: "มากกว่า 20 ปี",
				name_EN: "More than 20 years",
				dataId: 0,
				value: null,
				valueNum: 0,
				text: null,
				min: 240,
				max: 0,
			},
		];

		this.IsSupervisorDDL = [
			{ id: 1, name: "เป็นผู้บังคับบัญชา", name_EN: "Supervisor", dataId: 1, value: null, valueNum: 0, text: null },
			{ id: 2, name: "ไม่เป็นผู้บังคับบัญชา", name_EN: "Subordinate", dataId: 0, value: null, valueNum: 0, text: null },
		];

		this.HasSupervisorDDL = [
			{ id: 1, name: "มีหัวหน้า", name_EN: "Supervisor", dataId: 1, value: null, valueNum: 0, text: null },
			{ id: 2, name: "ไม่มีหัวหน้า", name_EN: "Subordinate", dataId: 0, value: null, valueNum: 0, text: null },
		];

		this.currentOrganizationConfig$ = dataSharing.currentOrganizationConfig.subscribe((res) => {
			this.organizationName = res;

			this.viewsDDL = [
				{
					id: 1,
					name: this.organizationName?.org1Name,
					name_EN: this.organizationName?.org1Name_EN,
					dataId: 0,
					value: "organizeId",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 2,
					name: this.organizationName?.org2Name,
					name_EN: this.organizationName?.org2Name_EN,
					dataId: 0,
					value: "organizeId",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 3,
					name: this.organizationName?.org3Name,
					name_EN: this.organizationName?.org3Name_EN,
					dataId: 0,
					value: "organizeId",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 4,
					name: this.organizationName?.org4Name,
					name_EN: this.organizationName?.org4Name_EN,
					dataId: 0,
					value: "organizeId",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 5,
					name: this.organizationName?.org5Name,
					name_EN: this.organizationName?.org5Name_EN,
					dataId: 0,
					value: "organizeId",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 6,
					name: this.organizationName?.org6Name,
					name_EN: this.organizationName?.org6Name_EN,
					dataId: 0,
					value: "organizeId",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 7,
					name: "อายุงานเริ่มต้น - สิ้นสุด",
					name_EN: "Duration of employment",
					dataId: 0,
					value: "durationOfEmployment",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 8,
					name: "ระดับเริ่มต้น - สิ้นสุด",
					name_EN: "RankNo",
					dataId: 0,
					value: "rankNoStr",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 9,
					name: "ประเภทการจ้างงาน",
					name_EN: "Employment Type",
					dataId: 0,
					value: "employmentTypeStr",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 10,
					name: "สถานะพนักงาน",
					name_EN: "Employee Status",
					dataId: 0,
					value: "employeeStatusStr",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 11,
					name: "ผู้บังคับบัญชา",
					name_EN: "Supervisor",
					dataId: 0,
					value: "isSupervisor",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 12,
					name: "หัวหน้า",
					name_EN: "Head",
					dataId: 0,
					value: "hasSupervisor",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 13,
					name: "สถานที่",
					name_EN: "Location",
					dataId: 0,
					value: "workLocationId",
					valueNum: 0,
					text: null,
					disabled: false,
				},
				{
					id: 14,
					name: "กะการทำงาน",
					name_EN: "Shift",
					dataId: 0,
					value: "workShiftId",
					valueNum: 0,
					text: null,
					disabled: false,
					isCheckDifference: false,
				},
			]; // next to -> loadSpecialType

			this.organizationName$.next([
				{
					id: Level.orgLevelTwo,
					name: `${this.organizationName?.org2Name}`,
					name_EN: this.organizationName?.org2Name_EN,
					dataId: 0,
					value: null,
					valueNum: 0,
					text: null,
				},
				{
					id: Level.orgLevelThree,
					name: `${this.organizationName?.org3Name}`,
					name_EN: this.organizationName?.org3Name_EN,
					dataId: 0,
					value: null,
					valueNum: 0,
					text: null,
				},
				{
					id: Level.orgLevelFour,
					name: `${this.organizationName?.org4Name}`,
					name_EN: this.organizationName?.org4Name_EN,
					dataId: 0,
					value: null,
					valueNum: 0,
					text: null,
				},
				{
					id: Level.orgLevelFive,
					name: `${this.organizationName?.org5Name}`,
					name_EN: this.organizationName?.org5Name_EN,
					dataId: 0,
					value: null,
					valueNum: 0,
					text: null,
				},
				{
					id: Level.orgLevelSix,
					name: `${this.organizationName?.org6Name}`,
					name_EN: this.organizationName?.org6Name_EN,
					dataId: 0,
					value: null,
					valueNum: 0,
					text: null,
				},
			]);
			this.loadSpecialType();
		});

		this.commonService.getDDLEmployeeStatus().then((res) => {
			this.employeeStatusDDL = res;
		});
		this.commonService.getDDLEmploymentTypes().then((res) => {
			this.employmentTypesDDL = res;
		});
		this.commonService.getDDLRanks().then((res) => {
			this.rankDDL = res;
		});
		firstValueFrom(this.companyProfileService.getCompanyBranchsDDL(0, 0, false)).then((res) => {
			this.locationDDL = res.map((m) => {
				m.name = m.text;
				m.name_EN = m.text_EN;
				return m;
			});
		});
	}
	ngAfterContentChecked(): void {
		this.cdr.detectChanges();
	}

	loadSpecialType() {
		if (this.isASMSpecialType) {
			var isDul = this.viewsDDL.find((r) => r.id == 11);
			if (isDul == null) {
				this.viewsDDL.push(
					{
						id: 11,
						name: "ผู้บังคับบัญชา",
						name_EN: "Supervisor",
						dataId: 0,
						value: "isSupervisor",
						valueNum: 0,
						text: null,
						disabled: false,
					},
					{
						id: 12,
						name: "หัวหน้า",
						name_EN: "Head",
						dataId: 0,
						value: "hasSupervisor",
						valueNum: 0,
						text: null,
						disabled: false,
					},
				);
			}
		}
	}

	setDefaultFilter() {
		let filterDefault = this.lockFilter?.map((m) => {
			return m.index;
		});
		let valueFilterDefault = this.lockFilter
			?.filter((f) => !isNullOrUndefined(f.value))
			.map((m) => {
				return m.index;
			});
		this.viewsValue = [];
		this.viewsValueId = [];
		this.viewsDDL.forEach((f) => {
			if (filterDefault?.findIndex((index) => index == f.id) > -1) {
				let val = this.lockFilter?.find((value) => value.index == f.id);
				//#region default value
				if (!isNullOrUndefined(valueFilterDefault) && !isNullOrUndefined(val.value)) {
					if (f.id == 1) {
						let organizationIdLevelOne = this.departmentNodeDDL?.find(
							(org) => org.companyId == ((val.value?.length ? val.value[0] : val.value) as number),
						)?.organizationId;
						if (!isNullOrUndefined(organizationIdLevelOne)) {
							this.setFilter(f.value, organizationIdLevelOne);
						}
					} else {
						if (!isNullOrUndefined(val.value)) {
							this.setFilter(f.value, val.value);
						}
					}
				}
				//#endregion
				f.disabled = !isNullOrUndefined(val.disabled) ? val.disabled : false;
				f.isCheckDifference = !isNullOrUndefined(val?.isCheckDifference) ? val?.isCheckDifference : false;
				this.viewsValue.push(f);
				this.viewsValueId.push(f.id);
			}
		});
	}

	ngOnInit() {
		this.listFunctionCode = [Module.EMP001_T18];
		this.showSelect = this.isShowSelect;
		firstValueFrom(this.userControlService.authorizeControlByListFunctionCode(this.listFunctionCode))
			.then((res) => {
				let permissionSeeAllEmployee = !isNullOrUndefined(res)
					? res.find((f) => f.functionCode == Module.EMP001_T18)
					: null;
				this.allowSeeAllEmployee = !isNullOrUndefined(permissionSeeAllEmployee)
					? permissionSeeAllEmployee.allowGrant
					: false;
			})
			.then(() => {
				if (this.allowSeeAllEmployee) {
					this.commonService.getDDLShiftByTenantId().then((res) => {
						this.shiftDDL = res;
					});
				} else {
					this.commonService.getDDLShiftByCompanyId(this.compId).then((res) => {
						this.shiftDDL = res;
					});
				}
			});
	}

	ngOnDestroy(): void {
		this.getPeoplePicker$ && this.getPeoplePicker$.unsubscribe();
		this.getPeoplePickerByEmployeeIds$ && this.getPeoplePickerByEmployeeIds$.unsubscribe();
		this.currentOrganizationConfig$ && this.currentOrganizationConfig$.unsubscribe();
		if (this.language$) this.language$.unsubscribe();
	}

	ngOnChanges(): void {
		if (this.isOpen) {
			if (!this.isMulti) {
				this.maxSelected = 1;
			}
			this.showSelect = this.isShowSelect;
			if (!isNullOrUndefined(this.maxSelected) && this.maxSelected == 1) {
				this.isMulti = false;
			}
			if (!isNullOrUndefined(this.departmentNodeDDL)) this.reset();
		}
	}

	get getParam() {
		let model = new SearchPeoplePickerModel();
		//#region hidec
		Object.keys(this.filter.value).forEach((name) => {
			switch (name) {
				case "takeRow":
					model[name] = this.employee.length;
					break;
				case "durationOfEmployment":
					let range = this.getFieldFilter(name) as RangeYear;
					var tmpRange = this.rangerDDL.find((r) => r.id == Number(range));
					if (!isNullOrUndefined(tmpRange)) {
						if (this.sDropdownRange != null)
							this.sDropdownRange.dropdown.nativeElement.children[0].classList.add("active-sDropdown");
						model["monthStartEmployment"] = tmpRange.min;
						model["monthEndEmployment"] = tmpRange.max;
					} else {
						if (this.sDropdownRange != null) {
							this.sDropdownRange.selectedItems = [];
							this.sDropdownRange.dropdown.nativeElement.children[0].classList.remove("active-sDropdown");
						}
					}
					break;
				case "isSupervisor":
					let isSupervisor = this.getFieldFilter(name) as Dropdown;
					model[name] = !isNullOrUndefined(isSupervisor) ? Number(isSupervisor) == 1 : null;
					break;
				case "hasSupervisor":
					let hasSupervisor = this.getFieldFilter(name) as Dropdown;
					model[name] = !isNullOrUndefined(hasSupervisor) ? Number(hasSupervisor) == 1 : null;
					break;
				case "employmentTypeStr":
					let employmentType = this.getFieldFilter(name) as Dropdown[];
					model[name] =
						!isNullOrUndefined(employmentType) && employmentType.length > 0 ? employmentType.toString() : null;
					break;
				case "employeeStatusStr":
					let employeeStatusStr = this.getFieldFilter(name) as Dropdown[];
					model[name] =
						!isNullOrUndefined(employeeStatusStr) && employeeStatusStr.length > 0 ? employeeStatusStr.toString() : null;
					break;
				case "rankNoStr":
					let rankNoStr = this.getFieldFilter(name) as Dropdown[];
					model[name] = !isNullOrUndefined(rankNoStr) && rankNoStr.length > 0 ? rankNoStr.toString() : null;
					break;
				case "workLocationId":
					let workLocationId = this.getFieldFilter(name) as Dropdown;
					model[name] = !isNullOrUndefined(workLocationId) ? Number(workLocationId) : 0;
					break;
				case "name":
					model[name] = this.getFieldFilter(name);
					break;
				case "companyIdStr":
					let comp = this.getFieldFilter(name) as Dropdown[];
					model[name] = !isNullOrUndefined(comp) && comp.length > 0 ? comp.toString() : null;
					break;
				case "organizeId":
					const tmpOrg = this.getFieldFilter(name);
					let organizeId = tmpOrg?.length ? tmpOrg[0] : (tmpOrg as number);
					let org = typeof organizeId === "object" ? 0 : organizeId;
					model[name] = !isNullOrUndefined(organizeId) ? org : null;
					break;
				case "workShiftId":
					let workShiftId = this.getFieldFilter(name) as number[];
					model[name] = !isNullOrUndefined(workShiftId) && workShiftId.length > 0 ? workShiftId : null;
					break;
				default:
					let datas = this.getFieldFilter(name) as Organization[];
					model[name] = !isNullOrUndefined(datas) && datas.length > 0 ? datas.toString() : null;
					break;
			}
		});
		model.skipRow = this.employee.length;
		model.takeRow = this.maxLoad;
		model.isWithoutMe = this.isWithoutMe;
		model.withOutEmpId = this.withOutEmpId;
		//#endregion
		return model;
	}
	load(skipFilter = false) {
		if (this.isLoad) {
			return;
		}
		this.isLoad = true;
		if (this.showSelect) {
			this.filterEmpSelect(false);
		} else {
			this.getPeoplePicker$ && this.getPeoplePicker$.unsubscribe();
			this.getParam.isWithoutMe = !this.isMe;
			this.getPeoplePicker$ = this.employeeService.getPeoplePicker(this.getParam).subscribe((res) => {
				this.employee = this.employee.concat(isNullOrUndefined(res) ? [] : res);
				this.employeeTmp = Object.assign([], this.employee);
				if (this.employee && this.employee.length > 0) {
					this.total = this.employee[0].totalEmployee;
					this.totalTmp = this.employee[0].totalEmployee;
				}
				if (!isNullOrUndefined(res) && (res.length === this.maxLoad || res?.length === 0)) {
					this.isLoad = false;
				}
				if (this.isMulti) {
					this.selectAllDatatable = this.selectedDatatable.length >= this.total && this.total > 0;
				}
				this.isLoad = false;
				setTimeout(() => {
					this.cdr.detectChanges();
				}, 50);
			});
		}
	}

	loadAll() {
		let model = this.getParam;
		model.skipRow = 0;
		model.takeRow = this.total;
		this.getPeoplePicker$ && this.getPeoplePicker$.unsubscribe();
		this.getPeoplePicker$ = this.employeeService.getPeoplePicker(model).subscribe((res) => {
			this.employeeAll = res;

			this.update();
		});
	}

	selectedUser: number = null;

	selectEmp(isAll: boolean, emp: any = null, empId: number = null) {
		if (!this.isMulti) {
			this.selectedUser = empId;
			this.employeeAll = [emp];
			this.update();
			// this.submit()
			return;
		}
		if (isAll) {
			if (this.selectAllDatatable) {
				this.employeeAll = this.employee;
				if (this.employeeAll.length < this.total) {
					this.loadAll();
				}
			} else {
				this.employeeAll = [];
			}
		} else {
			let index = this.employeeAll.findIndex((s) => s.employeeId === empId);
			if (index < 0 && this.disabledSelect) {
				return;
			}
			this.employeeAll =
				index < 0
					? this.employeeAll.concat(emp)
					: this.employeeAll.slice(0, index).concat(this.employeeAll.splice(index + 1, this.employeeAll.length));
		}
		this.onValidate();
		this.update();
	}

	public isValid: boolean = true;
	onValidate() {
		// on validate shift difference
		let viewShift = this.viewsDDL.find((f) => f.id === 14);
		if (viewShift?.isCheckDifference) {
			let isValidShift = this.employeeAll.every((val, i, arr) => val.shiftId === arr[0].shiftId);
			if (!isValidShift) {
				this.isValid = false;
				this._toastHelperService.validation({
					title: this.translate.instant("toast_title_error_shift_mismatch"),
					description: this.translate.instant("toast_desc_error_shift_mismatch"),
				});
			} else {
				this.isValid = true;
			}
		}
	}

	check(id: string) {
		return isNullOrUndefined(this.selectedDatatable) || this.selectedDatatable.length === 0
			? false
			: this.selectedDatatable.findIndex((f) => f === id) === -1;
	}

	private tmpCompanyId: number;

	mapChildren(datas: any[], fields: any) {
		return datas.map((data) => {
			let model: any = {};
			if (data.levels === 1) {
				this.tmpCompanyId = data.companyId;
			}
			model[fields.text] = data[fields.text];
			model.companyId = this.tmpCompanyId;
			model.name_EN = data.name_EN;
			model[fields.value] = data[fields.value];
			model.dataSource = data[fields.children]?.length ? this.mapChildren(data[fields.children], fields) : [];
			return model;
		});
	}

	// private async loadOrganizationByCompanyId() {
	//   this.organizationService.getOrganization().then((res) => {
	//     let org: TreeNodeOrganization[] = res;
	//     //#region hide
	//     org.forEach(comp => {
	//       if (comp.children && comp.children.length > 0) {
	//         let twoChildren: TreeNodeOrganization[] = comp.children;
	//         if (twoChildren && twoChildren.length > 0) {
	//           this.orgLevelTwoDDL = this.orgLevelTwoDDL.concat(twoChildren.map(m => { return this.getDDL(m); }));

	//           twoChildren.forEach(two => {
	//             if (two.children && two.children.length > 0) {
	//               let threeChildren: TreeNodeOrganization[] = two.children;
	//               if (threeChildren && threeChildren.length > 0) {
	//                 this.orgLevelThreeDDL = this.orgLevelThreeDDL.concat(threeChildren.map(m => { return this.getDDL(m); }));

	//                 threeChildren.forEach(three => {
	//                   if (three.children && three.children.length > 0) {
	//                     let fourChildren: TreeNodeOrganization[] = three.children;
	//                     if (fourChildren && fourChildren.length > 0) {
	//                       this.orgLevelFourDDL = this.orgLevelFourDDL.concat(fourChildren.map(m => { return this.getDDL(m); }));

	//                       fourChildren.forEach(four => {
	//                         if (four.children && four.children.length > 0) {
	//                           let fiveChildren: TreeNodeOrganization[] = four.children;
	//                           if (fiveChildren && fiveChildren.length > 0) {
	//                             this.orgLevelFiveDDL = this.orgLevelFiveDDL.concat(fiveChildren.map(m => { return this.getDDL(m); }));

	//                             fiveChildren.forEach(five => {
	//                               if (five.children && five.children.length > 0) {
	//                                 let sixChildren: TreeNodeOrganization[] = five.children;
	//                                 if (sixChildren && sixChildren.length > 0) {
	//                                   this.orgLevelSixDDL = this.orgLevelSixDDL.concat(sixChildren.map(m => { return this.getDDL(m); }));
	//                                 }
	//                               }
	//                             });
	//                           }
	//                         }
	//                       });
	//                     }
	//                   }
	//                 });
	//               }
	//             }
	//           });
	//         }
	//       }
	//     });
	//     //#endregion
	//   });
	// }

	// getDDL(m: TreeNodeOrganization): Organization {
	//   return {
	//     organizationId: m.organizationId,
	//     parentId: m.parentId,
	//     parentName: null,
	//     name: m.name,
	//     name_EN: m.name_EN,
	//     code: m.code,
	//     levels: m.levels,
	//     level: m.level,
	//     locationType: m.locationType,
	//     workingLocation: m.workingLocation,
	//     isActive: m.isActive
	//   };
	// }

	onClose() {
		setTimeout(() => {
			this.scrollHeight = 0;
			this.isOpen = false;
			this.closePeoplePicker.emit(this.isOpen);
			this.cdr.detectChanges();
		}, 50);
	}

	private setFilter(name: string, value: any) {
		this.filter.get(name).patchValue(value);
	}

	getFieldFilter(name: string) {
		return this.filter.get(name).value;
	}

	viewsValuestr(index: number) {
		return this.viewsValueId.findIndex((f) => f === index) > -1;
	}
	getdisabled(index: number) {
		return this.lockFilter?.find((f) => f.index === index && !isNullOrUndefined(f.disabled) && f.disabled === true)
			?.disabled;
	}

	onChangeRangeDurationOfEmployment(e) {
		this.rangerDDL[0].name_EN = this.rangerDDL[0].name = this.getRangeDurationOfEmployment;
		this.rangerDDL[0].min = this.rangeValues[0];
		this.rangerDDL[0].max = this.rangeValues[1];
		this.setFilter("durationOfEmployment", this.rangerDDL[0]);
	}

	selectedOrganization(e?) {
		this.compId = e.value;
		this.search();
	}

	private timeOut: any;
	search(e?) {
		clearTimeout(this.timeOut);
		this.timeOut = setTimeout(() => {
			this.isLoad = false;
			this.total = 0;
			this.employee = [];
			this.load(true);
		}, 500);
	}

	onKeydown(event) {
		if (event.key === "Enter") this.search();
	}

	loadImg(event) {
		if (event) {
			this.isloadImage = true;
			this.countImg++;
		}
		if (this.countImg === this.employee.length) {
			this.isloadImage = false;
			this.countImg = 0;
		}
	}

	reset() {
		this.selectAllDatatable = false;
		this.loadSpecialType();
		this.filter.reset();
		this.setDefaultFilter();
		this.total = 0;
		this.employee = [];
		this.isLoad = false;
		this.load();
	}

	submit() {
		this._onChange(this.employeeAll);
		this.onChangeValue(this.employeeAll);
		this.onChange.emit();
		this.onSubmit.emit();
		if (this.closeAfterSave) {
			this.onClose();
		}
	}

	onClear(id: number, e = null) {
		let index = this.viewsValue.findIndex((f) => f.id === id);
		if (index > -1) {
			this.viewsValue.splice(index, 1);
		}
		if (id === 7) {
			this.sDropdownRange.selectedItems = [];
			this.sDropdownRange.dropdown.nativeElement.children[0].classList.remove("active-sDropdown");
		}
	}

	clear(e, name: string, id: number) {
		if (isNullOrUndefined(this.getFieldFilter(name))) {
			this.onClear(id, e);
		}
		this.setFilter(name, null);
		this.search();
	}

	_clear(name: string) {
		this.setFilter(name, null);
		this.search();
	}

	update() {
		this.selectedDatatable =
			isNullOrUndefined(this.employeeAll) || this.employeeAll.length === 0
				? []
				: this.employeeAll.map((m) => {
						return m.employeeId?.toString();
					});
		this.selectAllDatatable = this.selectedDatatable.length >= this.total && this.total > 0;
		setTimeout(() => {
			this.cdr.detectChanges();
		}, 50);
	}

	//#region don't edit
	onChangeValue: any = () => {};
	onTouch: any = () => {};

	//propagate changes into the custom form control
	propagateChange = (_: any) => {};

	//From ControlValueAccessor interface
	writeValue(value: any) {
		this.employeeAll = isNullOrUndefined(value) ? [] : value;
		this.update();
	}

	_onChange = (val: any) => {};
	//From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this._onChange = fn;
	}

	//From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouch = fn;
	}
	//#endregion

	filterEmpSelect(event) {
		this.showSelect = !event;
		if (this.showSelect) {
			var param = this.getParam;
			param.takeRow = 0;
			param.skipRow = 0;
			var queryParam = {
				...param,
				employeeIds: this.selectedDatatable,
				withOut: this.withOutEmpId,
			};
			if (this.selectedDatatable?.length > 0) {
				this.getPeoplePickerByEmployeeIds$ && this.getPeoplePickerByEmployeeIds$.unsubscribe();
				this.getPeoplePickerByEmployeeIds$ = this.employeeService.getPeoplePicker(queryParam).subscribe((res) => {
					this.employee = Object.assign([], res);
					if (!this.isMe) {
						this.employee = this.employee.filter((f) => f.employeeId !== this.employeeId);
					}
					this.employeeTmpSelect = this.employee;
					this.selectAllDatatable = true;

					this.total = res.length;
					setTimeout(() => {
						this.cdr.detectChanges();
					}, 50);
				});
			} else {
				this.employeeTmpSelect = [];
				this.employee = [];
				this.selectAllDatatable = true;
			}
		} else {
			this.employeeTmpSelect = [];
			this.employee = Object.assign([], this.employeeTmp);
			this.isLoad = false;
			this.load();
		}
	}
}
