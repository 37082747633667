import { DatePipe, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { DialogService as DesignSystemDialogService } from "@gofive/design-system-dialog";
import { SortService } from "@syncfusion/ej2-angular-grids";
import { MarkdownEditorService } from "@syncfusion/ej2-angular-richtexteditor";
import { AssessmentFormService } from "./assessment/assessment-form/shared/assessment-form.service";
import { AssessmentSetService } from "./assessment/assessment-set/shared/assessment-set.service";
import { AssessmentMasterSetService } from "./assessment/shared/assessment-master-set.service";
import { AssessmentMasterService } from "./assessment/shared/assessment-master.service";
import { AssessmentPercentileRanksService } from "./assessment/shared/assessment-percentile-ranks.service";
import { AssessmentQuestionService } from "./assessment/shared/assessment-question.service";
import { AssessmentSetsService } from "./assessment/shared/assessment-sets.service";
import { AssessmentService } from "./assessment/shared/assessment.service";
import { DataSharingService, LanguageService, UserControlService, UserInfoService } from "./core";
import { DashboardService } from "./dashboard/shared/dashboard.service";
import { ApprovalService } from "./document-forms/approval/shared/approval.service";
import { DocumentFormService } from "./document-forms/document-form/shared/document-form.service";
import { LeaveCollectService } from "./document-forms/leave-collect/shared/leave-collect.service";
import { LeaveEndorseTimeService } from "./document-forms/leave-endorse-time/shared/leave-endorse-time.service";
import { LeaveExchangeCollectService } from "./document-forms/leave-exchange-collect/shared/leave-exchange-collect.service";
import { LeaveExchangeService } from "./document-forms/leave-exchange/shared/leave-exchange.service";
import { LeaveOtService } from "./document-forms/leave-ot/shared/leave-ot.service";
import { LeaveService } from "./document-forms/leave/shared/leave.service";
import { DocumentFormsService } from "./document-forms/shared/document-forms.service";
import { EmployeeAddressService } from "./employee-movements/employee-address/shared/employee-address.service";
import { EmployeeAssessmentService } from "./employee-movements/employee-assessment/shared/employee-assessment.service";
import { EmployeeEducationService } from "./employee-movements/employee-education/shared/employee-education.service";
import { EmployeeFamilyService } from "./employee-movements/employee-family/shared/employee-family.service";
import { EmployeeHistoryService } from "./employee-movements/employee-history/shared/employee-history.service";
import { EmployeeLeaveUsageService } from "./employee-movements/employee-leave-usage/shared/employee-leave-usage.service";
import { EmployeeLEDService } from "./employee-movements/employee-legal-execution/shared/employee-legal-execution.service";
import { EmployeeLoanService } from "./employee-movements/employee-loan/shared/employee-loan.service";
import { EmployeeMovementService } from "./employee-movements/employee-movement/shared/employee-movement.service";
import { EmployeeFinancialService } from "./employee-movements/employee-payroll/shared/employee-financial.service";
import { EmployeeProvidentFundService } from "./employee-movements/employee-provident-fund/shared/employee-provident-fund.service";
import { EmployeePunishmentService } from "./employee-movements/employee-punishment/shared/employee-punishment.service";
import { EmployeeResignService } from "./employee-movements/employee-resign/shared/employee-resign.service";
import { EmployeeShiftService } from "./employee-movements/employee-shift/shared/employee-shift.service";
import { EmployeeSocialSecurityService } from "./employee-movements/employee-social-security/shared/employee-social-security.service";
import { EmployeeTaxService } from "./employee-movements/employee-tax/shared/employee-tax.service";
import { EmployeeTimeAttendanceService } from "./employee-movements/employee-time-attendance/shared/employee-time-attendance.service";
import { EmployeeWorkExperienceService } from "./employee-movements/employee-work-experience/shared/employee-work-experience.service";
import { EmployeeWorkInformationService } from "./employee-movements/employee-work-information/shared/employee-work-information.service";
import { EmployeeService } from "./employee-movements/employee/shared/employee.service";
import { EmployeeAccumulatesService } from "./employee-movements/shared/employee-accumulates.service";
import { EmployeeDocumentService } from "./employee-movements/shared/employee-document.service";
import { EmployeeMovementsService } from "./employee-movements/shared/employee-movements.service";
import { EmployeeTimeStampService } from "./employee-movements/shared/employee-time-stamp.service";
import { CalendarService } from "./master/calendar/shared/calendar.service";
import { CertificateSettingService } from "./master/certificate-setting/shared/certificate-setting.service";
import { CompanyProfileService } from "./master/company-profile/shared/company-profile.service";
import { DiligenceAllowanceService } from "./master/diligence-allowance/shared/diligence-allowance.service";
import { HolidayService } from "./master/holiday/shared/holiday.service";
import { LeaveConditionService } from "./master/leave-condition/shared/leave-condition.service";
import { LeaveTypeService } from "./master/leave-type/shared/leave-type.service";
import { MasterSetupGroupService } from "./master/master-group-setup/shared/master-setup-group.service";
import { MasterSetupService } from "./master/master-setup/shared/master-setup.service";
import { OrganizationService } from "./master/organization/shared/organization.service";
import { AssetsService } from "./shared/service/assets.service";
import { MasTagsService } from "./shared/service/mas-tag.service";
import { PaymentMethodService } from "./master/payment-method/shared/payment-method.service";
import { PositionService } from "./master/position/shared/position.service";
import { ShiftService } from "./master/shift/shared/shift.service";
import { OkrManagementService } from "./okr";
import { AccumulateConfigService } from "./payroll/accumulate/accumulate-config/shared/accumulate-config.service";
import { BudgetService } from "./payroll/budget/shared/budget.service";
import { FinancialItemService } from "./payroll/master/financial-item/shared/financial-item.service";
import { SkillService } from "./manager-self-service/skill-set/shared/skill-set.service";
import { PaymentPeriodService } from "./payroll/master/payment-period/shared/payment-period.service";
import { PaymentService } from "./payroll/payment/shared/payment.service";
import { PaymentTableService } from "./payroll/payment/shared/paymentTable.service";
import { SocialSecurityRateService } from "./payroll/setting/social-security-rate/shared/social-security-rate.service";
import { TaxExemptionService } from "./payroll/setting/tax-exemption/shared/tax-exemption.service";
import { TaxRateService } from "./payroll/setting/tax-rate/shared/tax-rate.service";
import { PermissionService } from "./permission/shared/permission.service";
import { RecruitmentService } from "./recruitment/recruitment.service";
import { ExportSocialSecurityService } from "./reports/export-social-security/shared/export-social-security.service";
import { ReportSS001Service } from "./reports/report-ss001/shared/report-ss001.service";
import { ReportService } from "./reports/shared/report.service";
import { MyDocumentsService } from "./self-service/my-documents/shared/my-documents.service";
import { ComponentService } from "./setting/component-setting/shared/component.service";
import { EmailTemplateService } from "./setting/email-template/shared/email-template.service";
import { RanksService } from "./setting/ranks/shared/ranks.service";
import { RoleAssignmentService } from "./setting/role-assignment/shared/role-assignment.service";
import { RoleService } from "./setting/role/shared/role.service";
import { ImportFileService } from "./setting/tenant-setup/import-file/shared/import-file.service";
import { TenantSetupService } from "./setting/tenant-setup/shared/tenant-setup.service";
import { SuperAdminService } from "./setting/tenant-setup/super-admin/super-admin.service";
import { User_InfoService } from "./setting/user-info/shared/user-info.service";
import { WorkinSiteService } from "./setting/work-in-site/shared/work-in-site.service";
import { WorkflowService } from "./setting/workflow/shared/workflow.service";
import { ConfirmationEmailService } from "./shared/confirmation-email/shared/confirmation-email.service";
import { ColorStatusPipe } from "./shared/pipe";
import { CommonService } from "./shared/service/common.service";
import { CustomFieldService } from "./shared/service/custom-field.service";
import { PendingChangesGuard } from "./shared/service/discard-change";
import { LearnService } from "./shared/service/learn.service";
import { ToastHelperService } from "./shared/service/toast-helper.service";
import { UploadFileService } from "./shared/upload.service";
import { TimeSheetService } from "./time-sheet/time-sheet.service";
import { TrainingCourseService } from "./training/training-course/shared/training-course.service";
import { TrainingService } from "./training/training/shared/training.service";
import { CommissionService } from "./welfare/commission/shared/commission.service";
import { DiligentAllowanceConfigService } from "./welfare/diligent-allowance-config/shared/diligent-allowance-config.service";
import { EmployeeCardsService } from "./welfare/employee-cards/shared/employee-cards.service";
import { EmployeeLockersService } from "./welfare/employee-lockers/shared/employee-lockers.service";
import { FoodCouponService } from "./welfare/food-coupon/shared/food-coupon.service";
import { ProvidentFundPercentageService } from "./welfare/provident-fund/provident-fund-percentage/shared/provident-fund-percentage.service";
import { ProvidentFundPlanService } from "./welfare/provident-fund/provident-fund-plan/shared/provident-fund-plan.service";
import { ProvidentFundRateService } from "./welfare/provident-fund/provident-fund-rate/shared/provident-fund-rate.service";
import { WelfareService } from "./welfare/shared/welfare.service";
import { WelfareEjipService } from "./welfare/welfare-ejip/shared/welfare-ejip.service";
import { WelfareEjipTransactionService } from "./welfare/welfare-ejip/welfare-ejip-transaction/shared/welfare-ejip-transaction.service";
import { MovementService } from "./movement/movement.service";
import { SurveyService } from "./shared/component/survey/servey.service";
import { SkillLevelService } from "./shared/popup/skill-level/skill-level.service";
import { AnnouncementService } from "./shared/service/announcement.service";
import { OnboardingNewEmployeeService } from "./onboarding-new-employee/shared/onboarding-new-employee.service";
import { EmployeeApplicantService } from "./employee-movements/shared/employee-applicant.service";
import { PayRollSharingService } from "./employee-movements/employee-payroll/employee-payroll.component";
import { RemoteService } from "./shared/component/remote/remote.service";
import { EditHistoryLogService } from "./shared/component/edit-history-log/shared/edit-history-log.service";
@NgModule({
	providers: [
		PendingChangesGuard,
		ColorStatusPipe,
		DatePipe,
		DecimalPipe,
		SortService,
		MarkdownEditorService,
		DesignSystemDialogService,
		ReportService,
		ExportSocialSecurityService,
		ReportSS001Service,
		AccumulateConfigService,
		BudgetService,
		FinancialItemService,
		SkillService,
		PaymentService,
		PaymentTableService,
		PaymentPeriodService,
		SocialSecurityRateService,
		TaxExemptionService,
		TaxRateService,
		MasterSetupService,
		MasterSetupGroupService,
		OrganizationService,
		PositionService,
		CalendarService,
		LeaveTypeService,
		LeaveConditionService,
		CompanyProfileService,
		ShiftService,
		PaymentMethodService,
		HolidayService,
		DiligenceAllowanceService,
		CertificateSettingService,
		EmployeeCardsService,
		EmployeeLockersService,
		ProvidentFundPercentageService,
		ProvidentFundRateService,
		ProvidentFundPlanService,
		FoodCouponService,
		WelfareService,
		WelfareEjipService,
		WelfareEjipTransactionService,
		CommissionService,
		DiligentAllowanceConfigService,
		TimeSheetService,
		AssessmentPercentileRanksService,
		AssessmentService,
		DataSharingService,
		CommonService,
		UserInfoService,
		UserControlService,
		User_InfoService,
		ComponentService,
		DashboardService,
		OnboardingNewEmployeeService,
		RoleAssignmentService,
		DocumentFormsService,
		LeaveEndorseTimeService,
		MyDocumentsService,
		ApprovalService,
		EmployeeService,
		EmployeeAddressService,
		EmployeeFamilyService,
		EmployeeEducationService,
		EmployeeWorkInformationService,
		EmployeeWorkExperienceService,
		EmployeeShiftService,
		EmployeeMovementService,
		EmployeeMovementsService,
		EmployeeResignService,
		EmployeeHistoryService,
		LeaveOtService,
		LanguageService,
		LeaveService,
		WorkflowService,
		EmployeeTimeAttendanceService,
		EmailTemplateService,
		LeaveExchangeService,
		Title,
		EmployeeSocialSecurityService,
		TrainingService,
		TrainingCourseService,
		EmployeeProvidentFundService,
		EmployeeLeaveUsageService,
		EmployeeTimeStampService,
		LeaveCollectService,
		LeaveExchangeCollectService,
		RoleService,
		EmployeeFinancialService,
		EmployeeLEDService,
		EmployeeTaxService,
		EmployeeLoanService,
		EmployeeAccumulatesService,
		EmployeeDocumentService,
		EmployeeApplicantService,
		DocumentFormService,
		AssessmentMasterService,
		AssessmentQuestionService,
		AssessmentService,
		AssessmentMasterSetService,
		AssessmentFormService,
		AssessmentSetsService,
		AssetsService,
		MasTagsService,
		EmployeeAssessmentService,
		WorkinSiteService,
		RanksService,
		TenantSetupService,
		ImportFileService,
		EmployeePunishmentService,
		ConfirmationEmailService,
		AssessmentSetService,
		OkrManagementService,
		PermissionService,
		MovementService,
		RecruitmentService,
		UploadFileService,
		SuperAdminService,
		ToastHelperService,
		LearnService,
		SurveyService,
		CustomFieldService,
		SkillLevelService,
		AnnouncementService,
		PayRollSharingService,
		RemoteService,
		EditHistoryLogService,
	],
})
export class ServicesModule {}
