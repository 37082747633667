export class Announcement {
	attachments: Attachments[];
	coverAttachments: Attachments[];
	createdByUser: CreatedByUser;
	dateStart: string | Date;
	dateEnd?: string | Date;
	description: string;
	id: string;
	isAcknowledged: boolean;
	isAllCompany: boolean;
	isPinned: boolean;
	subject: string;
	status: number;
	audiences: AudiencesItem[];
}

export class AudiencesItem {
	public id: number;
	public audienceId: number;
	public groupId: number;
}

export class CreatedByUser {
	fullname: string;
	fullname_EN: string;
	hasPicture: boolean;
	pictureUrl: string;
	role: string;
	teamName: string;
	userId: string;
	username: string;
}

export class Attachments {
	attachmentId: number;
	createdByUserId: string;
	dateCreated: string;
	extension: string;
	filename: string;
	fileSize: string;
	isActive: boolean;
	refId: string;
	type: number;
	url: string;
	result: string | ArrayBuffer | any;
	externalLink: string;
	data: string;
}

export class EncryptionKey {
	storageEncryptionKey: string;
	storageEncryptionKeyHash: string;
}

export class AnnouncementDetails {
	public announcement: Announcement;
	public all: number;
	public view: number;
	public acknowledged: number;
	public coverAttachments: Attachments[];
	public attachments: Attachments[];
}

export class Team {
	public teamId: number;
	public groupId: number;
	public companyId: number;
	public teamName: string;
	public isActive: boolean;
}
