import {
	CUSTOM_ELEMENTS_SCHEMA,
	Component,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from "@angular/core";
import { GofiveCoreModuleEnum } from "../../enum/module.enum";
import { BaseMicroComponent } from "../base-micro";
import { DataSharingService } from "src/app/core";
import { isNullOrUndefined } from "@gofive/angular-common";
import { Subscription } from "rxjs";
import { LazyElementsModule } from "@angular-extensions/elements";
export enum PaymentState {
	ChoosePlan,
	Paynow,
	Renew,
	Credit,
}
@Component({
	selector: "app-payment-modal",
	templateUrl: "./payment-modal.component.html",
	standalone: true,
	imports: [LazyElementsModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaymentModalComponent extends BaseMicroComponent implements OnChanges {
	override module = GofiveCoreModuleEnum.payment;

	@Input() toggleTab: any = null;
	@Input() isBadge: boolean = false;
	isFreePlan: any;
	@Output() paySuccess = new EventEmitter();

	private subscription$: Subscription;
	public currentSub: any;
	constructor(
		public injector: Injector,
		public data: DataSharingService,
	) {
		super(injector);
		this.subscription$ = this.data.currentSubscription$.subscribe((res) => {
			this.currentSub = res;
			if (!isNullOrUndefined(res)) {
				this.isFreePlan = res?.subscriptionItem?.plan?.isFree;
			}
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.toggleTab?.currentValue) {
			this.toggleTab = { ...changes.toggleTab.currentValue };
		}
	}

	ngOnDestroy(): void {
		this.subscription$?.unsubscribe();
	}

	receivePaySuccess() {
		this.paySuccess.emit();
	}
}
