export class LeaveUsage {
	userId: string;
	leaveTypeId: string;
	year: number;
	totalDays: number;
	totalHours: number;
	usedDays: number;
	usedHours: number;
	carryTotalDays: number;
	carryUsedDays: number;
	carryTotalHours: number;
	carryUsedHours: number;
	carryMonthExpired: string;
	monthEndOfYear: string;
	dayEndOfYear: string;
	companyId: string;
	carryMonthExpiredName: string;
	leaveTypeName: string;
	noLimit: boolean;
	leaveTypeName_EN: string;
	leaveThisYear: any;
	leaveLastYear: any;
	leaveThisYear_EN: any;
	leaveLastYear_EN: any;
	leaveTotal: any;
	leaveTotal_EN: any;
	usedText: any;
	usedText_EN: any;
	usedDaysText: string;
	usedHoursText: string;
	usedMinutesText: string;
	carryMonthExpiredName_EN: string;
	monthEndOfYearName: string;
	monthEndOfYearName_EN: string;
	benefitTotalDays: number;
	benefitTotalHours: number;
	benefitUsedTotalDays: number;
	benefitUsedTotalHours: number;

	leaveSumTotal: string;
	leaveSumTotal_EN: string;
	leaveSumUsed: string;
	leaveSumUsed_EN: string;
	leaveSumCarry: string;
	leaveSumCarry_EN: string;
	leaveSumCarryUsed: string;
	leaveSumCarryUsed_EN: string;
	employeeId: number;
	benefitDetail: string;
	benefitDetail_EN: string;
}
export class LeaveUsageModel extends LeaveUsage {
	constructor() {
		super();
		this.dateCreated = new Date();
		this.dateModified = new Date();
	}
	employeeId: number;
	leaveTypeName: string;
	leaveTypeName_EN: string;
	benefitTotalDays: number;
	benefitTotalHours: number;
	benefitUsedTotalDays: number;
	benefitUsedTotalHours: number;
	benefitBalanceTotalDays: number;
	benefitBalanceTotalHours: number;
	benefitBalanceWithoutCarryTotalDays: number;
	benefitBalanceWithoutCarryTotalHours: number;
	isLimit: boolean;
	isUnlimit: boolean;
	isCarry: boolean;
	isShow: boolean;
	isAdjust: boolean;
	benefit: string;
	benefit_EN: string;
	benefitUsed: string;
	benefitUsed_EN: string;
	benefitUsedWithoutPrefix: string;
	benefitUsedWithoutPrefix_EN: string;
	totalBenefit_TH: string;
	totalBenefit_EN: string;
	totalUsedBenefit_TH: string;
	totalUsedBenefit_EN: string;
	benefitCarry: string;
	benefitCarry_EN: string;
	benefitCarryUsed: string;
	benefitCarryUsed_EN: string;
	totalUsedBenefitWithCarry_TH: string;
	totalUsedBenefitWithCarry_EN: string;
	benefitBalance: string;
	benefitBalance_EN: string;
	benefitBalanceLong: string;
	benefitBalanceLong_EN: string;
	conditionLeaveString: string;
	conditionLeaveString_EN: string;
	benefitBalanceWithoutCarry: string;
	benefitBalanceWithoutCarry_EN: string;
	cutoff: string;
	cutoff_EN: string;
	yearBenefit: string;
	yearBenefit_EN: string;
	yearCarryExpire: string;
	yearCarryExpire_EN: string;
	dateCarryExpired: any;
	totalEmergency: number;
	dateCreated: Date;
	dateModified: Date;
}

export class TotalLeaveUsage extends LeaveUsage {
	totalLeaveDays: number;
	totalLeaveHours: number;
	dateStart: Date;
	dateEnd: Date;
	timeStart: Date;
	timeEnd: Date;
	leaveThisYear: string;
	leaveLastYear: string;
	leaveBal: string;
	leaveTotal: string;
	employeeId: number;
}

export class LeaveDurationCalculateTimeOperation {
	day1: any;
	day2: any;
	hour1: any;
	hour2: any;
	operatorType: number;
	userId: string;
	totalDay: any;
	totalHour: any;
	times: string;
	times_EN: string;
	employeeId: number;
}

export class LeaveSearchModel {
	employeeNo: string;
	employeeName: string;
	leaveCode: string;
	companyId: string;
	documentStatusId: string;
	leaveTypeId: string;
	dateFrom: string;
	dateTo: string;
}

export class ShiftModel {
	companyId: string;
	dateTo: string;
	shiftType: string;
	dateFrom: string;
	requestCode: string;
	documentStatusId: string;
	empNoOrName: string;
	nameSurname: string;
	nameSurname_EN: string;
	description: string;
	documentStatus: string;
	documentStatus_EN: string;
	nextApprovalFullName: string;
	nextApprovalFullName_EN: string;
	dateChange: Date;
	shiftTypeId: string;
	shiftTypeName: string;
	shiftTypeName_EN: string;
	shiftRequestId: number;
}

export class ShearchShiftModel {
	companyId: number;
	shiftTypeId: string;
	dateFrom: string;
	dateTo: string;
	empNoOrName: string;
	documentStatusId: string;
	requestCode: string;
}

export class CustomSettingLeaveEmployeeModel {
	employeeId: number;
	fullName: string;
	fullName_EN: string;
	pictureThumbnailURL: string;
	positionName: string;
	positionName_EN: string;
	rankName: string;
	rankName_EN: string;
	totalDays: number;
	totalHours: number;
	fullNameModifiedBy: string;
	fullNameModifiedBy_EN: string;
	dateCreated: Date;
	dateModified: Date;
	employeeCount: number;
}
