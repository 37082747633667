import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { SearchMyDocumentModel, MyDocumentGroupMonth } from "./my-document.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class MyDocumentsService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getLeaveRequestDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "MyDocument/GetLeaveRequestDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getMyLeaveRequest(searchMyDocument: SearchMyDocumentModel) {
		var body = JSON.stringify(searchMyDocument);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post<MyDocumentGroupMonth[]>(this.apiUrl + "MyDocument/GetMyLeaveRequest", body, httpOptions),
		);
	}

	getMyLeaveRequestOfMSS(searchMyDocument: SearchMyDocumentModel) {
		var body = JSON.stringify(searchMyDocument);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post<MyDocumentGroupMonth[]>(this.apiUrl + "MyDocument/GetMyLeaveRequestOfMSS", body, httpOptions),
		);
	}

	getRoleApproveByUserId() {
		return firstValueFrom(this.http.get(this.apiUrl + "MyDocument/GetRoleApproveByUserId/")).then(
			(res) => <boolean>res,
		);
	}

	getDDLYearForMyDocument() {
		return firstValueFrom(this.http.get(this.apiUrl + "MyDocument/GetDDLYearForMyDocument")).then(
			(res) => <Dropdown[]>res,
		);
	}
}
