import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
	name: "bypassSecurityTrustUrl",
	standalone: true,
})
export class BypassSecurityTrustUrlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: string): unknown {
		return <string>this.sanitizer.bypassSecurityTrustUrl(value);
	}
}
