export enum Application {
	Empeo = "Empeo",
	Venio = "Venio",
	empeoId = 102,
	venioId = 101,
	emconnectId = 106,
}

export enum em {
	empeo = 0,
	emsume = 1,
	emconnect = 2,
}
