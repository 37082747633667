import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LeaveUsage, LeaveUsageModel } from "../../../document-forms/shared/document-forms.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeLeaveUsageService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	editLeaveUsage(leaveUsage: LeaveUsage) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(leaveUsage);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveUsage/EditLeaveUsage", body, httpOptions);
	}

	editLeaveUsageList(leaveUsage: LeaveUsage[]) {
		var body = JSON.stringify(leaveUsage);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			observe: "response" as "response",
		};
		return this.http.post(this.apiUrl + "LeaveUsage/EditLeaveUsageList", body, httpOptions);
	}

	getLeaveUsage(year?: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "LeaveUsage/GetLeaveUsage/" + year)).then(
			(res) => <LeaveUsageModel[]>res,
		);
	}

	getLeaveUsageByEmployeeId(employeeId: number, year?: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "LeaveUsage/GetLeaveUsageByEmployeeId/" + employeeId + "/" + year),
		).then((res) => <LeaveUsageModel[]>res);
	}

	calLeaveUsage(year: number, companyId: number) {
		this.commonService.changeDateJSON();

		return firstValueFrom(this.http.get(this.apiUrl + "LeaveUsage/CalLeaveUsage/" + year + "/" + companyId)).then();
	}

	getLeaveUsageById(employeeId: number, year: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "leave-usages/" + employeeId + "/" + year)).then(
			(res) => <LeaveUsageModel[]>res,
		);
	}
}
