import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class OnboardingNewEmployeeService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};
	constructor(
		private http: HttpClient,
		@Inject("GOFIVE_API_URL") private gofiveApiUrl: string,
		@Inject("LEARN_API_URL") private learnAPI: string,
	) {}

	getPoliciesByNewEmployee() {
		return this.http.get<any>(this.gofiveApiUrl + "v1/Policies/acknowledges/");
	}

	getAccessTokenByNewEmployeeId(employeeId: number) {
		return this.http.get<any>(this.gofiveApiUrl + `v1/authorization/onboarding/token?employeeId=${employeeId}`);
	}

	getCourseByNewEmployeeId(employeeId: number) {
		return this.http.get<any>(this.learnAPI + `v1/employees/${employeeId}/learns/summaries`);
	}
}
