export enum SubscriptionStatus {
	Create = 10001,
	Update = 10002,
	Inactive = 10003,
	LIVE = 11001,
	FUTURE = 11002,
	TRIAL = 11003,
	TRIAL_EXPIRED = 11004,
	CANCELED = 11005,
	EXPIRED = 11006,
	NONE_RENEWING = 11007,
	PENDING = 11008,
	PAID = 12001,
	OVERDUE = 12002,
	SENT = 12003,
	CANCELLED = 12004,
}

export enum InvoiceStatus {
	PAID = 12001,
	OVERDUE = 12002,
	SENT = 12003,
}

export enum BillingType {
	Monthly = "Monthly",
	Year = "Year",
}
