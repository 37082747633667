import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { DataSharingService } from "../core";
import { isNullOrUndefined } from "../shared/sharing.service";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "@gofive/design-system-button";

@Component({
	selector: "app-access-denied",
	templateUrl: "./access-denied.component.html",
	styleUrls: ["./access.denied.component.scss"],
	standalone: true,
	imports: [ButtonModule, TranslateModule],
})
export class AccessDeniedComponent implements OnDestroy {
	private currentUserInfo$: Subscription;
	public isActive = false;

	get hasValidAccessToken() {
		return this.authService.hasValidToken();
	}

	constructor(
		private authService: AuthService,
		public data: DataSharingService,
		public router: Router,
	) {
		this.currentUserInfo$ = this.data.currentUserInfo.subscribe((user) => {
			if (!isNullOrUndefined(user)) {
				this.isActive = user.isActive || user.employeeId != 1;
			}
		});
	}

	ngOnDestroy(): void {
		this.currentUserInfo$ && this.currentUserInfo$.unsubscribe();
	}
	btnClick() {
		if (this.hasValidAccessToken) {
			history.go(-1);
		} else {
			this.authService.logout();
		}
	}
	getWidthScreenSize() {
		return window.innerWidth >= 480 ? "medium" : "small";
	}
	logout() {
		this.authService.logout();
	}
}
