import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable()
export class PermissionService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getRolePermissionEditHistories(model) {
		return this.http.get<any>(
			`${this.apiUrl}Permission/GetRolePermissionEditHistories/?RoleId=${model.roleId}&Keyword=${model.keyword}&TakeRow=${model.takeRow}&SkipRow=${model.skipRow}&SortOrder=${model.sortOrder}`,
		);
	}

	getPermissionByEmployeesAndFunctionCode(employee: string[], functionCode: string) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl + "Permission/GetPermissionByEmployeesAndFunctionCode/" + employee + "/" + functionCode,
			),
		);
	}

	editRolePermissionPayroll(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Role/EditRolePermissionPayroll", body, httpOptions);
	}
}
