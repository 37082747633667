import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AssessmentMasterForm } from "../../shared/assessment-master.model";
import { AssessmentFormScore } from "./assessment-form.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class AssessmentFormService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeAssessmentById(assessmentId: number, assessorEmployeeId?: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "AssessmentForm/GetEmployeeAssessmentById/" + assessmentId + "/" + (assessorEmployeeId ?? 0),
			),
		).then((res) => <AssessmentFormScore[]>res);
	}

	getAssessmentFormById(assessmentId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "AssessmentForm/GetAssessmentFormById/" + assessmentId)).then(
			(res) => <AssessmentMasterForm>res,
		);
	}

	addAssessmentForm(assessment: AssessmentFormScore[]) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(assessment);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentForm/AddAssessmentForm", body, httpOptions);
	}
}
