@if ((isDoneLoading | async) === true && signalRService.isUserAgent == true) {
	<div class="remote-border">
		<div class="remote-border-top"></div>
		<div class="remote-border-right"></div>
		<div class="remote-border-left"></div>
		<div class="remote-border-bottom"></div>
		<div class="remote-content-top">
			<div class="remote-content-top-warpper">
				<label class="go5-text-body-strong">{{
					"common_simulation_mode"
						| translate
							: {
									userFirstName: userInfo.defaultLanguage == "th-TH" ? userInfo.fullName : userInfo.fullName_EN
							  }
				}}</label>
				<div class="btn-stop" (click)="stopSupported()">{{ "support_access_mode_stop" | translate }}</div>
			</div>
		</div>
	</div>
}

@if (servicing === enumService.maintenance) {
	<div>
		@defer (when (servicing === enumService.maintenance)) {
			<app-service-info></app-service-info>
		}
	</div>
}
@if (emptyLayout) {
	<router-outlet></router-outlet>
} @else {
	@if ((data.currentUserInfo | async) && userInfo.employeeStatus != newEmployeeStatus && !loadingAuthorize) {
		@defer (when (data.currentUserInfo | async) && userInfo.employeeStatus != newEmployeeStatus && !loadingAuthorize) {
			<empeo-dialog [allowGrant]="allowDisplay"> </empeo-dialog>
		}
	}
	@if (!isMobile()) {
		@defer (when (!isMobile())) {
			<app-survey></app-survey>
		}
	}
	<div
		class="layout-wrapper"
		(click)="onLayoutClick()"
		[ngClass]="{
			'layout-horizontal': isHorizontal(),
			'layout-overlay': isOverlay(),
			'layout-static': isStatic(),
			'layout-slim': isSlim(),
			'layout-static-inactive': staticMenuDesktopInactive,
			'layout-mobile-active': staticMenuMobileActive,
			'layout-overlay-active': overlayMenuActive
		}"
		[hidden]="(isDoneLoading | async) === false"
	>
		@if (servicing === enumService.running) {
			<div>
				@if (displayMenu && emptyLayout === false) {
					<div>
						@if (data.currentUserInfo | async) {
							@defer (when (data.currentUserInfo)) {
								<app-topbar></app-topbar>
							}
						}
						<div
							class="layout-menu-container"
							[ngClass]="isSafari ? 'layout-menu-container-safari' : 'layout-menu-container'"
							(click)="onMenuClick()"
						>
							<p-scrollPanel #layoutMenuScroller [style]="{ height: '100%' }">
								<div class="layout-menu-content">
									@defer (on viewport()) {
										<app-menu [reset]="resetMenu"></app-menu>
									} @placeholder {
										<div></div>
									}
								</div>
							</p-scrollPanel>
						</div>
						@if (staticMenuMobileActive || !isMobile()) {
							<div class="empeo-version text-center">V. {{ version }}</div>
						}
					</div>
				}
				<div [ngClass]="{ 'layout-content': displayMenu && emptyLayout === false, 'layout-margin': !isMobile() }">
					@if (!((data.currentLoading | async) && displayMenu)) {
						<div class="progress-bar {{ isMobile() ? 'mobile' : '' }}"></div>
					}
					@if ((data.currentLoading | async) && displayMenu) {
						<p-progressBar mode="indeterminate" class="customProgress"> </p-progressBar>
					}
					<div
						[ngClass]="
							isDashboard && userInfo.employeeStatus != newEmployeeStatus ? 'dashBoard' : 'layout-content-container'
						"
					>
						<router-outlet></router-outlet>
					</div>
					@if (staticMenuMobileActive) {
						<div class="layout-mask"></div>
					}
					<p-blockUI #blockUI [blocked]="isBlock"></p-blockUI>
				</div>
			</div>
		}
		@if ((isDoneLoading | async) === false) {
			<div class="loader">
				<div>
					<div class="centered spinner l splash">
						<img
							id="web-empeo-loading"
							style="padding-bottom: 20px; width: 180px"
							class="logo"
							alt="apollo-layout"
							src="assets/images/Logo-empeo.svg"
						/>
						<div class="bounce1"></div>
						<div class="bounce2"></div>
						<div class="bounce3"></div>
					</div>
				</div>
			</div>
		}
		@if ((isDoneLoading | async) === true && allowDisplay && isLoading && percentageAllSetup < 50) {
			<div>
				<div class="setup-mark-as-done">
					<div class="setup-mark-as-done-bg">
						<div class="d-flex" style="float: right; margin-top: 19px; margin-right: 19px">
							<em
								class="gf-icon-close"
								style="font-size: 12px; color: white; cursor: pointer"
								(click)="allowDisplay = false"
							></em>
						</div>
						<div class="setup-mark-as-done-content">
							<div class="text-setup">
								{{ "continue_setup_onboarding" | translate }}
							</div>
							<div class="progress-bar-setup">
								<go5-progress-bar [value]="percentageAllSetup" [height]="8"> </go5-progress-bar>
							</div>
							<div class="button-setup">
								<go5-button
									data-testid="button_button_markAsDone"
									label="{{ 'common_setting' | translate }}"
									category="secondary"
									size="extra-small"
									type="button"
									[disabled]="false"
									(click)="backToSetup()"
								>
								</go5-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		}
	</div>
}
