import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MasterSetupGroup } from "./master-setup-group.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class MasterSetupGroupService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	addMasterSetupGroup(mas: MasterSetupGroup) {
		var body = JSON.stringify(mas);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetupGroup/AddMasterSetupGroup", body, httpOptions);
	}

	editMasterSetupGroup(mas: MasterSetupGroup) {
		var body = JSON.stringify(mas);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetupGroup/EditMasterSetupGroup", body, httpOptions);
	}

	deleteMasterSetupGroup(masterSetupId: string[]) {
		var body = JSON.stringify(masterSetupId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetupGroup/DeleteMasterSetupGroup", body, httpOptions);
	}

	getMasterSetupGroupById(groupId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "MasterSetupGroup/GetMasterSetupGroupById/" + groupId)).then(
			(res) => <MasterSetupGroup>res,
		);
	}

	getMasterSetpupGroupBySystemRegisNo(systemRegisNo: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "MasterSetupGroup/GetMasterSetpupGroupBySystemRegisNo/" + systemRegisNo),
		).then((res) => <MasterSetupGroup>res);
	}

	getMasterSetupGroupGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "MasterSetupGroup/GetMasterSetupGroupDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}
}
