import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Application } from "../enum/application.enum";
import { MasterService } from "./master.service";

@Injectable()
export class AnnouncementService extends MasterService {
	constructor(
		public http: HttpClient,
		@Inject("API_URL_LST") public apiUrls: string[],
		@Inject("VENIO_API_URL") private venioApiUrl: string,
	) {
		super(http, apiUrls);
	}

	getAnnouncementHistory(
		isEmpeo: boolean = false,
		history: boolean = true,
		take: number = 10,
		skip: number = 0,
		team: string = null,
	) {
		return this.http.get<any>(
			this.venioApiUrl +
				`announcement/v1/Announcements?isEmpeo=${isEmpeo}&history=${history}&team=${team}&take=${take}&skip=${skip}`,
		);
	}

	getActiveAnnouncement(isEmpeo: boolean = false, isAcknowledge?: boolean) {
		return this.http.get<any>(
			this.venioApiUrl + `announcement/v1/Announcements/Web?isEmpeo=${isEmpeo}&IsAcknowledge=${isAcknowledge || false}`,
		);
	}

	getAnnouncementWhatIsNew() {
		let platform = "?platform=" + Application.empeoId;
		return this.http.get<any>(this.venioApiUrl + `announcement/v1/Announcements/whats-new${platform}`);
	}

	acknowledge(id) {
		return this.http.post(this.venioApiUrl + "announcement/v1/Announcements/" + id + "/Acknowledge", null);
	}
}
