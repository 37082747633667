export class PaymentPeriod {
	paymentMethodId: number;
	year: number;
	companyId: number;
	periodNo: number;
	periodCalculateNo: number;
	periodExtendNo: number;
	maxDatePayment: Date;
	datePayment: Date;
	dateStart: Date;
	dateEnd: Date;
	dateOTStart: Date;
	dateOTEnd: Date;
	dateMonthStart: Date;
	dateMonthEnd: Date;
	isEndOfMonth: boolean;
	isClosed: boolean;
	closedBy: string;
	dateClosed: Date;
	periodDay: boolean;
	createdBy: string;
	modifiedBy: string;
	dateCreated: Date;
	dateModified: Date;
	displayName: string;
	dateModified_TH: string;
	dateModified_EN: string;
	monthEnd_TH: string;
	monthEnd_EN: string;
	dateStart_TH: string;
	dateStart_EN: string;
	dateEnd_TH: string;
	dateEnd_EN: string;
	datePayment_TH: string;
	datePayment_EN: string;
	dateMonthStart_TH: string;
	dateMonthEnd_TH: string;
	dateMonthStart_EN: string;
	dateMonthEnd_EN: string;
	totalPeriodInYear: number;
	monthStart: number;
	monthPaymentStart: number;
	datePeriodStart: Date;
	datePaymentStart: Date;
	urlEslip: string;
	periods: Array<any>;
	payrollStatusId: number;
	payrollStatusName: string;
	payrollStatusName_EN: string;
	paymentPeriodName: string;
	paymentPeriodName_EN: string;
	paymentPeriodNameShort: string;
	paymentPeriodNameShort_EN: string;
	urlTavi50: string;
	balancePeriodInYear: number;
	balanceYear: number;
	urlEslipPeopleNoEmail: string;
	urlEslipSearched: string;
	urlEslipAll: string;
	urlPND: string;
	urlPNDAll: string;
	urlPND3: string;
	urlPND3All: string;
	urlSSO: string;
	urlPnd1Kor: string;
	urlSSOTxt: string;
	urlEJIPTxt: string;
	urlSSOExport: string;
	firstPeriodName: string;
	firstPeriodName_EN: string;
	periodMonth: number;
	paymentPeriods: any;
	label: string;
	label_EN: string;
	title_TH: string;
	title_EN: string;
	companyName: string;
	companyName_EN: string;
}
