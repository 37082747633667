import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeAssessmentScoreFilter } from "../../assessment/assessment-set/shared/assessment-set.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class OkrManagementService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getAssessmentParticipantsById(model: EmployeeAssessmentScoreFilter) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "AssessmentParticipant/GetAssessmentParticipantsById", body, this.httpOptions);
	}

	addAssessmentParticipants(assessmentParticipants: any) {
		var body = JSON.stringify(assessmentParticipants);
		return this.http.post(this.apiUrl + "AssessmentParticipant/AddAssessmentParticipants", body, this.httpOptions);
	}

	deleteAssessmentParticipant(assessmentSetId: number, employeeId: number) {
		return firstValueFrom(
			this.http.delete(
				this.apiUrl +
					"AssessmentParticipant/DeleteAssessmentParticipant?assessmentSetId=" +
					assessmentSetId +
					"&employeeId=" +
					employeeId,
			),
		).then();
	}

	getAssessmentObjectiveById(assessmentSetId: number) {
		return firstValueFrom(
			this.http.get<boolean>(this.apiUrl + "AssessmentParticipant/GetParticipantAllowGrant/" + assessmentSetId),
		);
	}

	getAllowPermissionOKR(employeeId: number) {
		return this.http.get<any>(this.apiUrl + "AssessmentObjective/GetAllowPermissionOKR/" + employeeId);
	}
}
