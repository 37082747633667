<div class="sidebar-position">
	<div class="header-border-bottom">
		<div class="header-content">
			<div class="header-content-left">
				<p class="go5-text-header-4 go5-text-color-12">
					{{ positionId > 0 ? ("common_position_edit" | translate) : ("common_create_position" | translate) }}
				</p>
			</div>
			<div class="header-content-right">
				<span class="d-flex">
					<em class="gf-icon-close" (click)="close()"> </em>
				</span>
			</div>
		</div>
	</div>

	<div class="content">
		<form [formGroup]="form">
			<div class="input-content d-flex">
				<div style="width: calc(50% - 7px)">
					<go5-textfield
						formControlName="positionName"
						data-testid="input_text_positionName"
						[type]="textFieldType.Text"
						[label]="('mas005_position_name_th' | translate) + '*'"
						placeholder="{{ 'complete_position_name' | translate }}"
						[(ngModel)]="positionModel.positionName"
						[error]="isPositionNameDup"
						[errorMessage]="
							(isPositionNameDup ? 'emconnect_position_name_validate' : 'validation_please_field')
								| translate: { fieldName: 'common_position_name' | translate }
						"
						(changed)="isPositionNameENDup = false"
					>
					</go5-textfield>
				</div>

				<div style="width: 14px"></div>

				<div style="width: calc(50% - 7px)">
					<go5-textfield
						formControlName="positionName_EN"
						data-testid="input_text_positionName"
						[type]="textFieldType.Text"
						[label]="'mas005_position_name_en' | translate"
						placeholder="{{ 'complete_position_name_en' | translate }}"
						[(ngModel)]="positionModel.positionName_EN"
						[error]="isPositionNameENDup"
						[errorMessage]="
							(isPositionNameENDup ? 'emconnect_position_name_validate' : 'validation_please_field')
								| translate: { fieldName: 'common_position_name' | translate }
						"
						(changed)="isPositionNameDup = false"
					>
					</go5-textfield>
				</div>
			</div>

			<div class="mb-3">
				<div class="input-header pb-2">
					<span class="text-color-8"> {{ "mas005_rank" | translate }}* </span>
				</div>
				<div>
					<go5-dropdown-selection
						[mode]="'single'"
						formControlName="rankFromId"
						[dataSource]="rankDDL"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldDropdown : fieldDropdown_EN"
						[value]="positionModel.rankFromId"
						placeholder="{{ 'common_please_choose' | translate }}"
						[errorMessage]="'please_choose_rank' | translate"
						[allowFiltering]="false"
						[allowShowRemove]="false"
					>
					</go5-dropdown-selection>
				</div>
			</div>

			<div class="mb-3">
				<go5-textarea
					category="primary"
					size="extra-large"
					label="{{ 'mas005_responsibilities' | translate }}"
					placeholder="{{ 'mas005_responsibilities_placeholder' | translate }}"
					formControlName="responsibility"
					[(ngModel)]="positionModel.responsibility"
				></go5-textarea>
			</div>

			<div class="mb-3">
				<go5-textarea
					category="primary"
					size="extra-large"
					label="{{ 'mas005_qualification' | translate }}"
					placeholder="{{ 'mas005_qualification_placeholder' | translate }}"
					formControlName="qualifications"
					[(ngModel)]="positionModel.qualifications"
				></go5-textarea>
			</div>

			<div class="d-flex pt-2 mb-3" style="justify-content: space-between; align-items: center">
				<span class="d-flex" style="align-items: center">
					<label class="go5-text-header-5 text-color-12" style="align-items: center"
						>{{ "common_position_skill" | translate }}
					</label>
					@if (!isPackagePro) {
						<span class="go5-text-extra-small badge-package-pro ml-1">
							{{ "pro" | translate }}
						</span>
					}
				</span>

				@if (isPackagePro) {
					<div>
						<go5-toggle-switch
							category="secondary"
							formControlName="hasSkill"
							[(ngModel)]="positionModel.hasSkill"
							(valueChange)="setHasSkill($event)"
						>
						</go5-toggle-switch>
					</div>
				}

				@if (!isPackagePro) {
					<div>
						<go5-button
							category="secondary"
							class="feature-icon-unlock"
							size="medium"
							type="button"
							iconClass="gf-icon-unlock"
							[iconColor]="'#FDCA3B'"
							label="{{ 'common_unlock' | translate }}"
							(click)="upgradePlan()"
						>
						</go5-button>
					</div>
				}
			</div>

			@if (isPackagePro && positionModel.hasSkill) {
				<div>
					<div class="pb-2">
						<div class="ui-g-7 pl-0 pb-0">
							<span class="d-flex">
								<label class="d-flex pr-2 text-color-8">{{ "common_skill" | translate }}* </label>
							</span>
						</div>
						<div class="ui-g-4 pb-0">
							<span class="d-flex">
								<label class="d-flex pr-2 text-color-8">{{ "skill_expect_level" | translate }}* </label>
							</span>
						</div>
					</div>
					<div formArrayName="positionSkills">
						@for (positionSkill of positionSkillArr.controls; track positionSkill; let i = $index) {
							<div class="ui-g-12 p-0" [formGroupName]="i">
								<div class="ui-g-7 pl-0 pt-0">
									<div class="p-0">
										<go5-dropdown-selection
											[dataSource]="availableSkillDDL"
											[fields]="skillDropdown"
											[mode]="dropdownSelectMode.Single"
											[placeholder]="'common_please_choose' | translate"
											[allowFiltering]="true"
											formControlName="skillId"
											[allowShowRemove]="false"
											[errorMessage]="'validation_please_choose' | translate: { titleName: 'common_skill' | translate }"
											(open)="filterSkill(i)"
											(selected)="selectSkill($event, i)"
										>
										</go5-dropdown-selection>
									</div>
								</div>
								<div [ngClass]="positionSkillArr.length === 1 ? 'ui-g-5 pt-0' : 'ui-g-4 pt-0'">
									<div class="p-0">
										<go5-dropdown-selection
											[dataSource]="skillLevelDDL"
											[fields]="
												data.getIsTH(data.currentLanguage | async) ? skillLevelsDropdown : skillLevelsDropdown_EN
											"
											[mode]="dropdownSelectMode.Single"
											[placeholder]="'common_please_choose' | translate"
											[allowFiltering]="false"
											formControlName="skillLevelId"
											[allowShowRemove]="false"
											[errorMessage]="'validation_please_choose' | translate: { titleName: 'skill_level' | translate }"
										>
										</go5-dropdown-selection>
									</div>
								</div>
								@if (positionSkillArr.length > 1) {
									<div class="ui-g-1 p-0">
										<div class="ui-g-11 py-0 pl-2 d-flex" style="align-items: center; height: 40px">
											<em class="gf-icon-empeo-bin-vector" (click)="removeSkill(i)"></em>
										</div>
									</div>
								}
							</div>
						}
					</div>
					<go5-button
						label="{{ 'skill_add' | translate }}"
						category="text"
						size="extra-small"
						type="button"
						iconClass="gf-icon-add-general"
						padding="0"
						maring="0"
						(click)="addSkill()"
						[disabled]="selectedSkillSet.size === skillDDL.length"
					></go5-button>
				</div>
			}
		</form>
	</div>

	<div class="footer-content">
		<span class="text-color-7" style="margin-top: 11px">
			{{ "common_enable_toggle" | translate }}
			<einfo-tooltip style="margin-left: 4px" key="common_create_position_tooltip" size="s"> </einfo-tooltip>
		</span>
		<span style="margin-left: 12px; margin-top: 6px">
			<go5-toggle-switch category="secondary" [(ngModel)]="positionModel.isActive"> </go5-toggle-switch>
		</span>
		<div style="margin-left: auto">
			<span>
				<go5-button
					label="{{ 'common_cancel' | translate }}"
					category="secondary"
					size="medium"
					type="button"
					[disabled]="false"
					(click)="close()"
				></go5-button>
			</span>
			<span style="margin-left: 12px">
				<go5-button
					data-testid="button_button_save"
					label="{{ (positionId > 0 ? 'common_save' : 'common_create') | translate }}"
					category="primary"
					size="medium"
					type="button"
					[disabled]="false"
					(click)="savePosition()"
				></go5-button>
			</span>
		</div>
	</div>
</div>

<app-payment-modal [toggleTab]="toggleTab"> </app-payment-modal>
