import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { TrainingCourse } from "./training-course.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TrainingCourseService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getTrainingCourseDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "TrainingCourse/GetTrainingCourseDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getTrainingCourseById(courseId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "TrainingCourse/GetTrainingCourseById/" + courseId)).then(
			(res) => <TrainingCourse>res,
		);
	}

	addTrainingCourse(tc: TrainingCourse) {
		var body = JSON.stringify(tc);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TrainingCourse/AddTrainingCourse", body, httpOptions);
	}

	editTrainingCourse(tc: TrainingCourse) {
		var body = JSON.stringify(tc);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TrainingCourse/EditTrainingCourse", body, httpOptions);
	}

	deleteTrainingCourse(courseId: number) {
		var body = JSON.stringify(courseId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TrainingCourse/DeleteTrainingCourse", body, httpOptions);
	}
}
