@if (!isWebView) {
	<ng-container *ngTemplateOutlet="sidebarTemplate"></ng-container>
} @else {
	<ng-container *ngTemplateOutlet="webViewTemplate"></ng-container>
}

<ng-template #sidebarTemplate>
	<go5-sidebar
		class="go5-text-color-12"
		[(visible)]="displayAssetsSidebar"
		[textHeader]="(isAdd ? 'asset_add_item' : 'edit_asset') | translate"
		[isShowHeader]="isAdd"
		[isShowFooter]="false"
		[padding]="'0px'"
		(cancel)="handleCloseSidebar($event)"
		(close)="handleCloseSidebar($event)"
	>
		<ng-container
			*ngTemplateOutlet="!isAdd && !isEdit && isLoad ? load : !isAdd && !isEdit ? view : AddEdit"
		></ng-container>
	</go5-sidebar>
</ng-template>

<ng-template #webViewTemplate>
	<ng-container
		*ngTemplateOutlet="!isAdd && !isEdit && isLoad ? load : !isAdd && !isEdit ? view : AddEdit"
	></ng-container>
</ng-template>

<ng-template #AddEdit>
	<form [formGroup]="formGroup" class="mb-4">
		@if (isEdit && !isWebView) {
			<div class="asset-sidebar-header">
				<div class="asset-sidebar-header-default">
					<div class="asset-header-title">
						<p class="go5-text-header-4">
							{{ "edit_asset" | translate }}
						</p>
					</div>
					<div class="asset-header-option">
						<i
							go5-sidebar-item
							class="gf-icon-close"
							(click)="handleCloseSidebar($event)"
							data-testid="icon_assets_sidebar_add_edit_close"
						>
						</i>
					</div>
				</div>
			</div>
		}

		<div class="sidebar-body">
			<div class="ui-g-12 ui-g-nopad sidebar-content" style="margin-bottom: 80px">
				<div class="d-flex gap-16">
					<go5-textfield
						type="text"
						category="primary"
						[disabled]="false"
						[placeholder]="'asset_name_placeholder' | translate"
						[label]="('asset_name' | translate) + '*'"
						formControlName="Name"
						[error]="
							!formGroup?.controls['Name'].valid &&
							(formGroup?.controls['Name'].touched || formGroup?.controls['Name'].dirty)
						"
						[errorMessage]="'validate_enter_asset_name' | translate"
						style="flex: 1"
						data-testid="textfield_assets_sidebar_name"
					></go5-textfield>
					<go5-dropdown-selection
						label="{{ 'asset_type' | translate }}*"
						[dataSource]="assetsTypeDDL"
						[allowFiltering]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? filedMasterSetupDDL : filedMasterSetupDDL_EN"
						categoryItem="item"
						popupType="slide"
						placeholder="{{ 'common_default_select' | translate }}"
						formControlName="AssetCategoryId"
						[allowShowRemove]="false"
						style="flex: 1"
						[error]="
							!formGroup?.controls['AssetCategoryId'].valid &&
							(formGroup?.controls['AssetCategoryId'].touched || formGroup?.controls['AssetCategoryId'].dirty)
						"
						[errorMessage]="'validate_select_asset_type' | translate"
						data-testid="dropdown_assets_sidebar_asset_type"
					>
					</go5-dropdown-selection>
				</div>
				<div class="d-flex gap-16">
					<go5-textfield
						type="text"
						category="primary"
						[disabled]="false"
						[placeholder]="'asset_serial_no_placeholder' | translate"
						[label]="'sys012_serial_number' | translate"
						formControlName="SerialNo"
						style="flex: 1"
						data-testid="textfield_assets_sidebar_serial_no"
					></go5-textfield>
					<go5-dropdown-selection
						label="{{ 'sys012_branch' | translate }}"
						[dataSource]="branchDDL"
						[allowFiltering]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? filedBranch : filedBranch_EN"
						categoryItem="item"
						popupType="slide"
						placeholder="{{ 'common_default_select' | translate }}"
						formControlName="BranchId"
						[allowShowRemove]="false"
						style="flex: 1"
						data-testid="dropdown_assets_sidebar_branch"
					>
					</go5-dropdown-selection>
				</div>
				<go5-textfield
					formControlName="Cost"
					type="currency"
					currencySymbol="฿"
					category="primary"
					[disabled]="false"
					[minLength]="0"
					format="0.2-2"
					[label]="'asset_value' | translate"
					data-testid="textfield_assets_sidebar_cost"
				></go5-textfield>
				<go5-textarea
					[label]="'common_details' | translate"
					category="primary"
					formControlName="Remark"
					size="extra-large"
					maxlength="2000"
					placeholder="{{ 'asset_details_placeholder' | translate }}"
					data-testid="textarea_assets_sidebar_remark"
				></go5-textarea>
				<div>
					<p class="mb-2 go5-text-color-8">{{ "common_attachment" | translate }}</p>
					<go5-upload-file
						[linkLabel]="'common_choose_file' | translate"
						[max]="15"
						[maxFileSize]="10485760"
						[allowSetToFirstIndex]="true"
						[allowSortData]="true"
						[textSetFirstIndex]="'Select'"
						[textBadgeFirstIndex]="'Cover'"
						[allowImageCoverOnly]="true"
						(change)="setAttachments($event.value)"
						(selected)="openAttachment($event, attachments)"
						[value]="attachments || []"
						[allowExtensions]="allowExtensions"
						data-testid="upload_file_assets_sidebar_add_edit_attachment"
					></go5-upload-file>
				</div>
				<div>
					<p class="mb-2 go5-text-color-8">{{ "asset_tags" | translate }}</p>
					<go5-tags-option
						size="medium"
						category="custom"
						[allowOption]="false"
						[formControlName]="'TagIds'"
						[dataSource]="tagDDL"
						[fields]="filedTag"
						(change)="handleSelectTag($event)"
						(create)="addAssetTags($event.currentValue.name)"
						[createLabel]="('component_create' | translate) + ' ' + ('asset_tags' | translate)"
						data-testid="tags_assets_sidebar_asset_tag"
					>
					</go5-tags-option>
				</div>
				<div class="d-flex" style="justify-content: space-between; align-items: center">
					<p class="go5-text-body go5-text-color-8">
						{{ "asset_warranty" | translate }}
					</p>
					<go5-toggle-switch
						category="primary"
						[checked]="hasWarranty"
						(valueChange)="hasWarranty = !hasWarranty"
						[formControlName]="'HasWarranty'"
						data-testid="toggle_assets_sidebar_has_warranty"
					></go5-toggle-switch>
				</div>
				@if (hasWarranty) {
					<div class="d-flex gap-16">
						<go5-datepicker
							[label]="('asset_purchase_date' | translate) + '*'"
							[placeholder]="'common_please_choose' | translate"
							[isShowTime]="false"
							[format]="dateFormat.dateShort"
							[allowShowRemove]="false"
							[(ngModel)]="datePurchase"
							[formControlName]="'DatePurchase'"
							style="flex: 1"
							data-testid="datepicker_assets_sidebar_date_purchase"
						>
						</go5-datepicker>
						<div class="assets-sidebar-input-dropdown-split" style="flex: 1">
							<div class="mb-2">
								<span class="go5-text-color-8">
									{{ "common_duration" | translate }}
								</span>
							</div>
							<div class="d-flex">
								<go5-textfield
									[format]="'0.0-0'"
									type="number"
									category="primary"
									size="100"
									[value]="timeOfWarranty"
									(changed)="timeOfWarranty = $event.value"
									[min]="0"
									[max]="999"
									style="flex: 1"
									data-testid="textfield_assets_sidebar_time_of_warranty"
								>
								</go5-textfield>
								<go5-dropdown-selection
									[dataSource]="timePeriodDDL"
									[fields]="fieldsDDL"
									[value]="selectTimePeriod"
									[allowShowRemove]="false"
									mode="single"
									popupType="popup"
									[allowFiltering]="false"
									style="flex: 1"
									data-testid="dropdown_assets_sidebar_time_period"
								>
								</go5-dropdown-selection>
							</div>
						</div>
					</div>
				}
				<div class="d-flex" style="justify-content: space-between; align-items: center">
					<p class="go5-text-body go5-text-color-8">
						{{ "common_company_asset" | translate }}
					</p>
					<go5-toggle-switch
						category="primary"
						[formControlName]="'IsCommon'"
						[checked]="formGroup.get('IsCommon').value"
						data-testid="toggle_assets_sidebar_is_common"
					></go5-toggle-switch>
				</div>
				<div>
					<p class="mb-2" style="font-weight: bold !important; font-size: 16px">
						{{ "asset_responsible_person" | translate }}*
					</p>
					@if (!selectOwnerEmployee || selectOwnerEmployee.length == 0) {
						<go5-link
							[label]="'common_select_employee' | translate"
							[iconClass]="'gf-icon-add-participants'"
							(click)="handleOpenPeoplePicker()"
							[error]="isErrorOwner"
							[errorMessage]="'common_select_employee' | translate"
							data-testid="link_assets_sidebar_owner"
						></go5-link>
					} @else {
						<div class="d-flex" style="justify-content: space-between; align-items: center; padding: 10px 0">
							<go5-avatar-information
								[src]="selectOwnerEmployee[0].pictureURL"
								title="{{
									data.getIsTH(data.currentLanguage | async)
										? selectOwnerEmployee[0].fullName
										: selectOwnerEmployee[0].fullName_EN || selectOwnerEmployee[0].fullName
								}}"
								detail="{{
									data.getIsTH(data.currentLanguage | async)
										? selectOwnerEmployee[0].positionName
										: selectOwnerEmployee[0].positionName_EN || selectOwnerEmployee[0].positionName
								}}"
								[isLazy]="false"
							>
							</go5-avatar-information>
							@if (!hideIconDelete) {
								<em
									class="gf-icon-empeo-bin-vector"
									style="font-size: 20px"
									(click)="selectOwnerEmployee = []"
									data-testid="icon_assets_sidebar_delete"
								>
								</em>
							}
						</div>
					}
				</div>
			</div>
			<div class="asset-sidebar-footer" [ngStyle]="{ display: isMobile ? '' : 'flex' }">
				<div class="active-box">
					<p class="text-color-8" style="margin-right: 8px">
						{{ "button_enable" | translate }}
					</p>
					<go5-toggle-switch
						category="primary"
						value="toggle-switch-primary"
						[checked]="formGroup.get('IsActive').value"
						[formControlName]="'IsActive'"
						data-testid="toggle_assets_sidebar_is_active"
					></go5-toggle-switch>
				</div>
				<div>
					<go5-button
						[size]="isWebView ? 'medium' : 'large'"
						category="secondary"
						[label]="'common_cancel' | translate"
						[margin]="'0px 8px 0px 0px'"
						(click)="handleCloseSidebar($event)"
						data-testid="button_assets_sidebar_cancel"
					>
					</go5-button>
					<go5-button
						[size]="isWebView ? 'medium' : 'large'"
						category="primary"
						[label]="(isAdd ? 'common_add' : 'common_save') | translate"
						(click)="onSubmit()"
						data-testid="button_assets_sidebar_submit"
					>
					</go5-button>
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #view>
	<div class="sidebar-body">
		@if (!isWebView) {
			<div class="asset-sidebar-header">
				<div class="asset-sidebar-header-default">
					<div class="asset-header-title">
						<p class="go5-text-header-4">
							{{ "asset_list" | translate }} {{ asset?.code ? "(" + asset.code + ")" : "" }}
						</p>
					</div>

					<div class="asset-header-option">
						@if (permission.allowEdit && asset) {
							<span
								class="gf-icon-empeo-edit font20"
								go5-tooltip-placement="top"
								go5-tooltip-mode="hover"
								go5-tooltip="{{ 'common_edit' | translate }}"
								(click)="isEdit = true"
								data-testid="icon_assets_sidebar_edit"
							></span>
						}
						<i
							go5-sidebar-item
							class="gf-icon-close"
							(click)="handleCloseSidebar($event)"
							data-testid="icon_assets_sidebar_view_close"
						>
						</i>
					</div>
				</div>
			</div>
		}

		@if (asset) {
			<div class="ui-g-12 ui-g-nopad sidebar-content">
				<div>
					<div class="d-flex" style="justify-content: space-between; align-items: top">
						<div class="flex-column">
							<span class="text-body-strong" style="font-size: 18px !important">{{ asset?.name }}</span>
							<span class="go5-text-color-10">
								{{
									data.getIsTH(data.currentLanguage | async)
										? getLabelAssetCategory(asset?.assetCategoryId)?.name
										: getLabelAssetCategory(asset?.assetCategoryId)?.name_EN
								}}</span
							>
						</div>
						<go5-status
							size="medium"
							type="Assets"
							[label]="data.getIsTH(data.currentLanguage | async) ? asset.statusName : asset.statusName_EN"
							[statusId]="asset?.statusId"
						>
						</go5-status>
					</div>
					@if (asset.tagIds && asset.tagIds.length > 0) {
						<div style="margin-top: 4px">
							<go5-tags-option
								size="medium"
								category="custom"
								[allowOption]="false"
								[dataSource]="tagDDL"
								[fields]="filedTag"
								[value]="asset.tagIds"
								[disabled]="true"
							>
							</go5-tags-option>
						</div>
					}

					@if (asset?.remark && asset?.remark.trim().length > 0) {
						<div style="margin-top: 12px; white-space: break-spaces">
							<span class="go5-text-color-10">{{ asset.remark }}</span>
						</div>
					}
				</div>

				<div class="flex-column gap-8">
					@if (asset?.branchId) {
						<div class="d-flex" style="justify-content: space-between; align-items: center">
							<p class="text-body-strong">
								{{ "sys012_branch" | translate }}
							</p>
							<p class="go5-text-color-10">
								{{ data.getIsTH(data.currentLanguage | async) ? asset.branchName : asset.branchName_EN }}
							</p>
						</div>
					}
					@if (asset?.serialNo) {
						<div class="d-flex" style="justify-content: space-between; align-items: center">
							<p class="text-body-strong">
								{{ "sys012_serial_number" | translate }}
							</p>
							<p class="go5-text-color-10">{{ asset.serialNo }}</p>
						</div>
					}
					@if (asset?.cost) {
						<div class="d-flex" style="justify-content: space-between; align-items: center">
							<p class="text-body-strong">
								{{ "asset_value" | translate }}
							</p>
							<p class="go5-text-color-10">฿{{ asset.cost | number: "1.2-2" }}</p>
						</div>
					}
					@if (asset?.hasWarranty) {
						<div class="d-flex" style="justify-content: space-between; align-items: center">
							<p class="text-body-strong">
								{{ "asset_warranty_expired_on" | translate }}
							</p>
							<p class="go5-text-color-10">{{ warrantyEndDate | sDate: dateFormat.dateShort | async }}</p>
						</div>
					}
				</div>
				@if (attachments && attachments.length > 0) {
					<div>
						<p class="mb-2 text-body-strong">{{ "common_attachment" | translate }}</p>
						<go5-upload-file
							class="d-flex"
							[max]="0"
							[multiple]="false"
							[allowShowRemove]="false"
							(selected)="openAttachment($event, attachments)"
							[value]="attachments || []"
							[allowExtensions]="allowExtensions"
							data-testid="upload_file_assets_sidebar_view_attachment"
						></go5-upload-file>
					</div>
				}

				@if (selectOwnerEmployee && selectOwnerEmployee.length > 0) {
					<div>
						<p class="mb-2 text-body-strong">{{ "asset_responsible_person" | translate }}</p>
						<go5-avatar-information
							[src]="selectOwnerEmployee[0].pictureURL"
							title="{{
								data.getIsTH(data.currentLanguage | async)
									? selectOwnerEmployee[0].fullName
									: selectOwnerEmployee[0].fullName_EN || selectOwnerEmployee[0].fullName
							}}"
							detail="{{
								data.getIsTH(data.currentLanguage | async)
									? selectOwnerEmployee[0].positionName
									: selectOwnerEmployee[0].positionName_EN || selectOwnerEmployee[0].positionName
							}}"
							[isLazy]="false"
							[size]="'large'"
						>
						</go5-avatar-information>
					</div>
				}

				<div class="d-flex flex-column gap-12 mt-3">
					@if (isShowTitle) {
						<p style="font-weight: bold !important; font-size: 16px">
							{{ "common_timeline" | translate }}
						</p>
					}
					@for (item of assetLogs; track item) {
						<div class="contacts">
							<div class="d-flex d-flex-center">
								<span>
									<img
										style="
											max-width: 40px;
											max-height: 40px;
											min-width: 40px;
											min-height: 40px;
											width: 40px;
											height: 40px;
										"
										class="small-image"
										library="verona-layout"
										src="{{ item.pictureThumbnailURL }}"
									/>
								</span>
								<span style="width: 100%; height: 40px; display: flex; flex-direction: column">
									<div class="d-flex d-flex-center" style="justify-content: space-between">
										<span class="go5-text-small-strong go5-text-color-12 name ellipsis">
											{{ data.getIsTH(data.currentLanguage | async) ? item.employeeName : item.employeeName_EN }}
										</span>
										<span class="go5-text-extra-small go5-text-color-7" style="float: right">
											{{ item.dateCreated | sDate: dateFormat.dateShort | async }}
										</span>
									</div>
									<div class="d-flex d-flex-center">
										<em
											[ngClass]="{
												'gf-icon-add-general': item.processId === ProcessAction.Added,
												'gf-icon-venio-unassign': item.processId === ProcessAction.Assigned,
												'gf-icon-venio-assign-case': item.processId === ProcessAction.Transfer
											}"
											class="f-12 go5-color-primary"
										></em>
										<span
											style="display: inline-block; margin: 0px 0px -2px 4px"
											class="{{
												item.processId > 0 ? 'go5-text-color-9' : 'go5-text-color-8'
											}} go5-text-extra-small ellipsis"
										>
											<label
												class="go5-text-small"
												[innerHTML]="
													(data.getIsTH(data.currentLanguage | async) ? item.processAction : item.processAction_EN)
														| safeHtml
												"
											>
											</label>
										</span>
									</div>
								</span>
							</div>
						</div>
					}
				</div>
			</div>
		} @else {
			<div>
				<div class="flex-column gap-16" style="justify-content: center; align-items: center; padding-top: 60px">
					<img [src]="'assets/images/empty-stage/empeo-empty-state-no-data.svg' | sAssets" />
					<span class="go5-text-body go5-text-color-8">{{ "empty_state_no_access_asset_info" | translate }}</span>
				</div>
			</div>
		}
	</div>
</ng-template>

<ng-template #load>
	<div class="ui-g-12 ui-g-nopad">
		<div class="ui-g-12 ui-g-nopad">
			<div class="ui-g-12 ui-g-nopad border-bottom-gray pb-3 pl-3 pr-3 pt-4 text-color-10">
				<ngx-skeleton-loader
					[theme]="{ width: '80%', height: '30px', float: 'left', animation: 'infinite progress' }"
					animation="progress-dark"
				>
				</ngx-skeleton-loader>
			</div>
			<div class="ui-g-12 ui-g-nopad sidebar-load" style="margin-bottom: 16px">
				<div class="d-flex" style="justify-content: space-between">
					<ngx-skeleton-loader
						[theme]="{
							width: '367px',
							height: '60px',
							float: 'left',
							margin: '0 8px 8px 0',
							animation: 'infinite progress'
						}"
						animation="progress-dark"
					>
					</ngx-skeleton-loader>
					<ngx-skeleton-loader
						[theme]="{
							width: '74px',
							height: '30px',
							float: 'left',
							margin: '0',
							animation: 'infinite progress'
						}"
						animation="progress-dark"
					>
					</ngx-skeleton-loader>
				</div>
				<div style="width: 100%">
					<ngx-skeleton-loader
						[theme]="{
							width: '200px',
							height: '20px',
							float: 'left',
							animation: 'infinite progress'
						}"
						animation="progress-dark"
					>
					</ngx-skeleton-loader>
				</div>
				<div>
					@for (number of data.counter(2); track number) {
						<ngx-skeleton-loader
							[theme]="{
								width: '60px',
								height: '30px',
								float: 'left',
								animation: 'infinite progress',
								margin: '0 8px 12px 0'
							}"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
					}
				</div>
				<div>
					<ngx-skeleton-loader
						[theme]="{
							width: '100%',
							height: '120px',
							float: 'left',
							animation: 'infinite progress',
							margin: '0 0 16px 0'
						}"
						animation="progress-dark"
					>
					</ngx-skeleton-loader>
				</div>
				@for (number of data.counter(4); track number) {
					<div class="d-flex" style="justify-content: space-between">
						<ngx-skeleton-loader
							[theme]="{
								width: '150px',
								height: '20px',
								float: 'left',
								animation: 'infinite progress'
							}"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							[theme]="{
								width: '80px',
								height: '20px',
								float: 'left',
								animation: 'infinite progress'
							}"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
					</div>
				}

				<div class="flex-column mb-3">
					<div style="flex: 1">
						<ngx-skeleton-loader
							[theme]="{
								width: '80px',
								height: '20px',
								float: 'left',
								animation: 'infinite progress'
							}"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
					</div>
					<div>
						@for (number of data.counter(3); track number) {
							<ngx-skeleton-loader
								[theme]="{
									width: '100px',
									height: '100px',
									margin: '0 16px 0 0',

									float: 'left',
									animation: 'infinite progress'
								}"
								animation="progress-dark"
							>
							</ngx-skeleton-loader>
						}
					</div>
				</div>
				<div class="flex-column">
					<div style="flex: 1">
						<ngx-skeleton-loader
							[theme]="{
								width: '80px',
								height: '20px',
								float: 'left',
								animation: 'infinite progress'
							}"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
					</div>
					<div>
						<ngx-skeleton-loader
							[theme]="{
								width: '45px',
								height: '45px',
								margin: '0 8px 0 0',
								float: 'left',
								animation: 'infinite progress'
							}"
							animation="progress-dark"
							appearance="circle"
						>
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							[theme]="{ width: '85%', height: '20px', float: 'left', animation: 'infinite progress' }"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
						<ngx-skeleton-loader
							[theme]="{ width: '85%', height: '20px', float: 'left', animation: 'infinite progress' }"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
					</div>
				</div>
				<div class="flex-column mt-3">
					<div style="flex: 1">
						<ngx-skeleton-loader
							[theme]="{
								width: '100px',
								height: '32px',
								float: 'left',
								animation: 'infinite progress'
							}"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
					</div>
					@for (number of data.counter(3); track number) {
						<div class="d-flex">
							<ngx-skeleton-loader
								[theme]="{
									width: '45px',
									height: '45px',
									margin: '0 8px 0 0',
									float: 'left',
									animation: 'infinite progress'
								}"
								animation="progress-dark"
								appearance="circle"
							>
							</ngx-skeleton-loader>
							<div style="flex: 1">
								<div class="d-flex" style="justify-content: space-between">
									<ngx-skeleton-loader
										[theme]="{ width: '200px', height: '20px', float: 'left', animation: 'infinite progress' }"
										animation="progress-dark"
									>
									</ngx-skeleton-loader>
									<ngx-skeleton-loader
										[theme]="{ width: '80px', height: '20px', float: 'left', animation: 'infinite progress' }"
										animation="progress-dark"
									>
									</ngx-skeleton-loader>
								</div>
								<ngx-skeleton-loader
									[theme]="{ width: '180px', height: '20px', float: 'left', animation: 'infinite progress' }"
									animation="progress-dark"
								>
								</ngx-skeleton-loader>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	</div>
</ng-template>

@if (displayPeoplePicker) {
	<empeo-people-picker
		[isOpen]="displayPeoplePicker"
		(closePeoplePicker)="displayPeoplePicker = false"
		[specialType]="1"
		[lockFilter]="defaultLockFilter"
		titleName="common_choose_employee"
		btnSubmit="common_choose"
		[(ngModel)]="selectOwnerEmployee"
		[isMulti]="false"
	></empeo-people-picker>
}
