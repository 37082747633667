import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DataSharingService } from "src/app/core/data-sharing.service";
import { BillingType } from "../../enum/client-portal.enum";
import { RouteURL } from "../../enum/route-path.enum";
import { UserControlService } from "src/app/core";
import { Module } from "../../enum/module.enum";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "@gofive/design-system-button";

@Component({
	selector: "app-upgrade-plan",
	templateUrl: "./upgrade-plan.component.html",
	styleUrls: ["./upgrade-plan.component.scss"],
	standalone: true,
	imports: [ButtonModule, TranslateModule],
})
export class UpgradePlanComponent implements OnDestroy {
	@Input() height: string = "60vh";
	@Output() closeDialog = new EventEmitter();

	public billingTypeYear: string = BillingType.Year;
	public billingType: string;

	private billing$: Subscription;
	public permission: any;

	constructor(
		public data: DataSharingService,
		private router: Router,
		private userControlService: UserControlService,
	) {
		this.billing$ = this.data.currentBillingType.subscribe((datas) => {
			this.billingType = datas;
		});
	}

	ngOnInit() {
		this.userControlService.authorizeControl(Module.SYS018).subscribe((auth) => {
			this.permission = auth;
		});
	}

	ngOnDestroy(): void {
		this.billing$?.unsubscribe();
	}

	upgradePlan() {
		this.data.setEventSubscription("planModal");
		this.router.navigateByUrl(`/${RouteURL.billing}`);
		this.close();
	}

	learnMore() {
		window.open("https://help.empeo.com/th/");
		this.close();
	}

	close() {
		this.closeDialog.emit();
	}
}
