import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class DiligentAllowanceConfigService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	calculateDiligentAllowance(companyId: number, year: number, periodCalculateNo: number) {
		var body = JSON.stringify({ companyId: companyId, year: year, periodCalculateNo: periodCalculateNo });

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "DiligentAllowanceConfig/CalculateDiligentAllowance", body, httpOptions);
	}

	exportDiligentAllowanceTransaction(searchDiligentAllowanceConfigModel: any) {
		var body = JSON.stringify(searchDiligentAllowanceConfigModel);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(
			this.apiUrl + "DiligentAllowanceConfig/ExportDiligentAllowanceTransaction",
			body,
			httpOptions,
		);
	}
}
