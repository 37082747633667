import { Injectable } from "@angular/core";
import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { environment } from "src/environments/environment";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/auth/auth.service";
import { SharingService } from "../../sharing.service";
import { SessionStorageKey } from "../../enum/local-storage-key";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { ToastHelperService } from "../../service/toast-helper.service";
import { DataSharingService } from "src/app/core/data-sharing.service";

@Injectable({
	providedIn: "root",
})
export class SignalRService {
	hubConnection: any;
	isBeingSupport: boolean = false;
	isUserAgent: boolean = false;

	// private isUserAgent = new BehaviorSubject<boolean>(false);
	// currentUserAgent = this.isUserAgent.asObservable();

	// setUserAgent(bool) {
	// 	this.isUserAgent.next(bool);
	// }

	constructor(
		private authService: AuthService,
		private translate: TranslateService,
		private _dialogService: DialogService,
		private router: Router,
		private sharing: SharingService,
		private _toastHelperService: ToastHelperService,
		public data: DataSharingService,
	) {}
	isGuid(input: string): boolean {
		const guidRegex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
		return guidRegex.test(input);
	}

	getSourceUserId() {
		var source_user_id = sessionStorage.getItem(SessionStorageKey.source_user_id);
		return this.isGuid(source_user_id) ? source_user_id : null;
	}

	startConnection(userId: string) {
		var queryString = this.sharing.getQueryString({
			userId: userId,
			sourceUserId: this.getSourceUserId(),
		});
		this.hubConnection = new HubConnectionBuilder()
			.withUrl(`${environment.apiUrl}signalr${queryString}`, {
				transport: HttpTransportType.WebSockets, // Specify WebSockets transport
				skipNegotiation: true, // Bypass negotiation phase
			})
			.withAutomaticReconnect()
			//.withUrl(`${environment.apiUrl}signalr`)
			.configureLogging(LogLevel.Information)
			.build();
		this.hubConnection
			.start()
			.then(() => {
				if (this.hubConnection.connection.baseUrl.includes("sourceUserId")) {
					this.isUserAgent = true;
					this.router.navigate(["/"]);
				}
			})
			.catch((err: any) => console.error("มีปัญหาในการเชื่อมต่อท่อ: " + err));

		this.hubConnection.on("cancelSupported", (result: any) => {
			let dialogModel = <DialogInformationModel>{
				title: this.translate.instant("dialog_title_access_permission_cancel"),
				description: this.translate.instant("dialog_desc_access_permission_cancel"),
				imageUrl: "error.png",
				textButtonConfirm: this.translate.instant("common_dismiss"),
				textButtonCancel: "",
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.closeWeb();
				}
			});
		});

		this.hubConnection.on("stopSupported", (result: any) => {
			this.closeWeb();
		});

		this.hubConnection.on("isBeingSupport", (result: any) => {
			this.isBeingSupport = result;
		});

		this.hubConnection.on("calculateTime", (result: any) => {
			this.data.closeLoading();
			if (result["result"]) {
				this._toastHelperService.success({
					title: this.translate.instant("toast_title_success"),
					description: this.translate.instant("calculate_work_status_toast_success"),
				});
			} else {
				this._toastHelperService.error({
					title: this.translate.instant("toast_title_sorry"),
					description: result["message"],
				});
			}
		});

		this.hubConnection.on("calculatePayroll", (result: any) => {
			this.data.closeLoading();
			if (result["result"]) {
				this._toastHelperService.success({
					title: this.translate.instant("toast_title_success"),
					description: this.translate.instant("calculate_payroll_toast_success"),
				});
			} else {
				this._toastHelperService.error({
					title: this.translate.instant("toast_title_sorry"),
					description: result["message"],
				});
			}
		});
	}

	closeWeb() {
		this.authService.logout();
		window.close();
	}
}
