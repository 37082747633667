<div class="ui-g-12 ui-g-nopad" style="background-color: white; padding: 10px 20px 10px 20px; margin-bottom: 10px">
	<div class="ui-g-12 search-table-outter ui-g-nopad height-table-550">
		<table datatable [dtOptions]="dtOptions" class="hover dataTables-parti-coloured" style="width: 100%; border: 0">
			<thead>
				<tr>
					<th style="display: none"></th>
					<th class="th-sm-1 th-lg-1" style="width: 20%">
						<label for="input">
							{{ "emp001_t14_financial_item" | translate }}
						</label>
					</th>
					<th class="th-sm-1 th-lg-1" style="width: 20%">
						<label for="input">{{ "emp001_old_value" | translate }}</label>
					</th>
					<th class="th-sm-1 th-lg-1" style="width: 20%">
						<label for="input">{{ "emp001_new_value" | translate }}</label>
					</th>
					<th class="th-sm-1 th-lg-1" style="width: 20%">
						<label for="input">{{ "common_modified_by" | translate }}</label>
					</th>
					<th class="th-sm-1 th-lg-1" style="width: 20%">
						<label for="input">{{ "common_date_modified" | translate }}</label>
					</th>
					<th style="display: none"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of employeePayRollHistories; let i = index">
					<td>
						{{
							dataShare.getIsTH(dataShare.currentLanguage | async) ? item.financialItemName : item.financialItemName_EN
						}}
					</td>
					<td>{{ isShowSalary ? (item.oldValue | number: "1.2-2") : maskAmount }}</td>
					<td>{{ isShowSalary ? (item.newValue | number: "1.2-2") : maskAmount }}</td>
					<td>{{ item.modifiedBy }}</td>
					<td>
						{{ dataShare.getIsTH(dataShare.currentLanguage | async) ? item.dateModified_TH : item.dateModified_EN }}
					</td>
				</tr>
			</tbody>
			@if (employeePayRollHistories == null || employeePayRollHistories?.length == 0) {
				<tr>
					<td colspan="5" class="no-data-available">{{ "common_no_data" | translate }}</td>
				</tr>
			}
		</table>
	</div>
</div>
