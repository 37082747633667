import { MasterSetup } from "../../../master/master-setup/shared/master-setup.model";

export class WorkinSite {
	siteId: number;
	companyId: number;
	companyName: string;
	siteCode: string;
	siteName: string;
	notes: string;
	isActive: boolean;
	isDelete: boolean;
	latitude: number;
	longitude: number;
	location: string;
	locationName: string;
	placeId: string;
	createdByUserId: string;
	dateCreated: Date;
	modifiedByUserId: string;
	dateModified: Date;
	radius: number;
	radiusiBeacon: number;
	isGPS: boolean;
	isiBeacon: boolean;
	uuid: string;
	majorId: string;
	minorId: string;
	isAllSite: boolean;
	lalong: string;
	name: string;
	name_EN: string;
	deviceId: number;
	locationId: number;
	ipAddress: string;
	port: number;
	dateLastTransfered: Date;
	fullName: string;
	fullName_EN: string;
	isBiometricScanner: boolean;
	deviceType: string;
	workinType: number;
	bioLocation: string;
	bioLocation_EN: string;
	isIOMO: boolean;
	isWifi: boolean;
	masLocation: MasterSetup;
	isStation: boolean;
	customerCode: string;
	serialNo: string;
	macAddress: string;

	constructor(isAllSite: boolean = false) {
		this.isAllSite = isAllSite;
	}
}

export class BiometricScannersModel {
	deviceId: number;
	name: string;
	name_EN: string;
	ipAddress: string;
	port: string;
	companyId: number;
	deviceType: number;
	dateLastTransfered: string;
	dateCreated: string;
	createdBy: string;
	dateModified: string;
	modifiedBy: string;
	location: MasterSetup;
	fullName: string;
	fullName_EN: string;
	isActive: boolean;
	isDelete: boolean;
	serialNo: string;
	isOnline: boolean;
	isAllSite: boolean;
	isFaceScan: boolean;
	isTimeLock: boolean;
	isAutoAddEmployee: boolean;
	isBiometricScanner: boolean;
	isStation: boolean = false;
	customerCode: string;
}

export class WorkinAndBiometricSearchModel {
	companyId: number;
	workinType: number;
	isActive?: boolean;
	isActiveSystemStatus?: boolean;
	sortBy: number;
}

export class WorkinEmployeeSearchModel {
	deviceId: number;
	serialNo: string;
	searchKeyword?: string;
	searchEmployeeType?: number;
	searchDateFrom?: Date;
	searchDateTo?: Date;
	minuteGMT: string;
	siteId: number;
	SRID?: number;
	latitudeStamp?: number;
	longitudeStamp?: number;
}

export class IomoManagementSearchModel {
	deviceId: number;
	isCommand: boolean;
	searchKeyword?: string;
	searchEmployeeType?: number;
	organizationId?: number;
	takeRow?: number;
	skipRow: number;
	sortOrder: string;
}

export class IomoManagementTimeStampSearchModel {
	serialNo: string;
	searchKeyword?: string;
	searchEmployeeType?: number;
	searchDateFrom?: string;
	searchDateTo?: string;
	minuteGMT: string | number;
	takeRow?: number;
	skipRow: number;
	sortOrder: string;
}
