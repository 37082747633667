export enum AppConfig {
	CLIENT_ID = "CLIENT_ID",
	TXT_EMP_NO_COLUMN = "TXT_EMP_NO_COLUMN",
	TXT_DATE_COLUMN = "TXT_DATE_COLUMN",
	TXT_TIME_COLUMN = "TXT_TIME_COLUMN",
	MONTH_EXCHANGE = "MONTH_EXCHANGE",
}

export enum ApiUrl {
	empeo = 0,
	gofive = 1,
	gofiveDeepLink = 2,
	clientPortol = 3,
	empeoJuno = 4,
}
