import { Component, Input, OnInit } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { Router } from "@angular/router";
import { InterestType } from "../../shared/enum/interest-type.enum";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Subscription, firstValueFrom } from "rxjs";
import { isNullOrUndefined, SharingService } from "src/app/shared/sharing.service";
import { DataSharingService, UserControlService } from "../../core";
import { PaymentPeriod } from "../../payroll/master/payment-period/shared/payment-period.model";
import { PayrollPayment } from "../../payroll/payment/shared/payment.model";
import { FinancialCategory } from "../../shared/enum/financial-category.enum";
import { Module } from "../../shared/enum/module.enum";
import { ApiResult } from "../../shared/models/api-result.model";
import { Dropdown, FinancialItemDDLModel } from "../../shared/models/dropdown.model";
import { FileInfo } from "../../shared/models/file-info.model";
import { Permission } from "../../shared/models/permission.model";
import { CommonService } from "../../shared/service/common.service";
import { EmployeeMovementsService } from "../shared/employee-movements.service";
import { EmployeeLoan } from "./shared/employee-loan.model";
import { EmployeeLoanService } from "./shared/employee-loan.service";
import { DecimalPipe, NgIf, NgFor, NgStyle, AsyncPipe, DatePipe } from "@angular/common";
import { PaymentPeriodService } from "../../../app/payroll/master/payment-period/shared/payment-period.service";
import { LoanStatus } from "src/app/shared/enum/employee-loan.enum";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { Employee } from "../shared/employee-movements.model";
import { OrganizationService } from "src/app/master/organization/shared/organization.service";
import { DropdownSelectMode, DropdownModule } from "@gofive/design-system-dropdown";
import { ToastService } from "@gofive/design-system-toast";
import { EmployeeService } from "../employee/shared/employee.service";
import { environment } from "src/environments/environment";
import { DateFormat, AngularCommonModule } from "@gofive/angular-common";
import { ThaiDatePipe } from "../../shared/pipe/thai-date.pipe";
import { WorkflowLogComponent } from "../../shared/component/workflow-log/workflow-log.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { CalendarModule } from "@gofive/design-system-calendar";
import { InfoTooltipComponent } from "../../shared/template/info-tooltip/info-tooltip.component";
import { SidebarModule } from "@gofive/design-system-navigation";
import { BadgeModule } from "@gofive/design-system-badge";
import { AvatarModule } from "@gofive/design-system-avatar";
import { ScrollInfiniteDirective } from "../../shared/directive/scroll-infinite.directive";
import { ButtonModule } from "@gofive/design-system-button";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { InputModule } from "@gofive/design-system-input";

@Component({
	selector: "app-employee-loan",
	templateUrl: "./employee-loan.component.html",
	styleUrls: ["./employee-loan.component.scss"],
	standalone: true,
	imports: [
		InputModule,
		FormsModule,
		DropdownModule,
		NgIf,
		TooltipModule,
		ButtonModule,
		ScrollInfiniteDirective,
		NgFor,
		AvatarModule,
		BadgeModule,
		SidebarModule,
		ReactiveFormsModule,
		NgStyle,
		InfoTooltipComponent,
		CalendarModule,
		NgxSkeletonLoaderModule,
		WorkflowLogComponent,
		AsyncPipe,
		DecimalPipe,
		DatePipe,
		TranslateModule,
		AngularCommonModule,
		ThaiDatePipe,
	],
})
export class EmployeeLoanComponent implements OnInit {
	private subscriptionEmpId: Subscription;
	private onWelfareEmployeeLoan$: Subscription;
	private export$: Subscription;
	private deleteWelfareEmployeeLoan$: Subscription;

	dtOptions: DataTables.Settings = {};
	form: UntypedFormGroup;
	paymentPeriodAllDDL: PaymentPeriod[];
	paymentPeriodClosedDDL: PaymentPeriod[];
	paymentPeriodDDL: PaymentPeriod[];
	paymentPeriods: PaymentPeriod[] = [];
	paymentPeriod: PaymentPeriod = new PaymentPeriod();
	paymentPeriodObs: PaymentPeriod = new PaymentPeriod();
	currentPayrollPayment: PayrollPayment = new PayrollPayment();

	financialItemDDL: FinancialItemDDLModel[];
	employeeLoans: EmployeeLoan[] = [];

	employeeLoanObj: EmployeeLoan = new EmployeeLoan();
	employee: Employee | any;

	financialItemId: any = null;
	isInterestType: boolean = false;
	employeeLoan: EmployeeLoan;
	loanDetail: any;
	id: number;
	year: number;
	periodCalculateNo: number;
	companyId: number;
	loanAmount: number = 0;
	dateOfPeriodCalculateNo: Date;
	monthlyPaymentPeriod: number = 0;
	paymentPerPeriod: number = 0;
	payPerPeriodSumInterest: number = 0;
	interestAmount: number = 0;
	documentNo: string;
	interestRatePerYear: number = 0;
	remark: string;
	loanId: number = null;
	isReadOnly: boolean = false;
	isEdit: boolean = false;
	isClosePayment: boolean = false;

	confirmation: any;
	saveConfirmation: any;
	deleteConfirmation: any;
	status: number;
	closePaymentLoanId: number;
	displaySidebar: boolean = false;
	displayLoanDetail: boolean = false;
	isLoadtable: boolean = true;
	isInitCal: boolean = false;
	maskAmount: string = "xxxx.xx";
	formatterBaht = (value: number) => `฿ ${this.decimalPipe.transform(value, "0.2-2")}`;
	parserBaht = (value: string) => value.replace("฿ ", "");
	get enumLoanStatus() {
		return LoanStatus;
	}

	dateLastYear: Date;

	statusDDL: Dropdown[];
	orgDDL: Dropdown[];
	typeDDL: any[] = [];
	typeId: number;
	public filedOrganize = {
		text: "name",
		value: "organizationId",
		children: "children",
	};

	public filedOrganize_EN = {
		text: "name_EN",
		value: "organizationId",
		children: "children",
	};

	public fieldsDDL = { text: "name", value: "id" };
	public fieldsDDL_EN = { text: "name_EN", value: "id" };

	public fieldsPaymentDDL = { text: "paymentPeriodName", value: "periodCalculateNo" };
	public fieldsPaymentDDL_EN = { text: "paymentPeriodName_EN", value: "periodCalculateNo" };

	public get dateFormat() {
		return DateFormat;
	}
	listFunctionCode: string[] = [];
	permissionLoanTeam: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	permissionLoanEmp: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	@Input() isWelfareMaster: boolean = false;
	constructor(
		private fb: UntypedFormBuilder,
		private commonService: CommonService,
		private userControlService: UserControlService,
		private employeeLoanService: EmployeeLoanService,
		public data: DataSharingService,
		private employeeService: EmployeeService,
		private router: Router,
		private employeeMovementsService: EmployeeMovementsService,
		private _toastService: ToastService,
		private translate: TranslateService,
		private organizationService: OrganizationService,
		private decimalPipe: DecimalPipe,
		private paymentPeriodService: PaymentPeriodService,
		private _dialogService: DialogService,
		public sharingService: SharingService,
	) {}

	ngOnInit() {
		this.listFunctionCode = [Module.MSS003_02, Module.EMP001_T19];
		firstValueFrom(this.userControlService.authorizeControlByListFunctionCode(this.listFunctionCode)).then((res) => {
			this.permissionLoanTeam = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.MSS003_02) : null;
			this.permissionLoanEmp = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.EMP001_T19) : null;
		});

		const currentDate: Date = new Date();
		this.dateLastYear = new Date(
			currentDate.getFullYear() - 1,
			currentDate.getMonth(),
			currentDate.getDate(),
			0,
			0,
			0,
			0,
		);

		this.dtOptions = {
			processing: false,
			searching: false,
			paging: false,
			info: false,
			autoWidth: false,
			columnDefs: [{ orderable: false, targets: "_all" }],
		};

		if (!this.isWelfareMaster) {
			this.subscriptionEmpId = this.data.currentEmpId.subscribe((data) => {
				this.id = data;
				if (this.id > 0) {
					this.employeeMovementsService
						.getEmployeeById(this.id)
						.then((emp) => {
							this.companyId = emp.companyId;
							this.employee = emp;
							this.commonService.getDDLFinancialItemByCompanyId(this.employee.companyId).then((res) => {
								this.financialItemDDL = res.filter((f) => f.category == FinancialCategory.other);
							});
						})
						.finally(() => {
							this.statusId = [1, 2, 3];
							this.gridLoad();
							this.callAPI();
						});
				} else {
					this.router.navigate(["/employee"]);
				}
			});
		} else {
			this.statusId = [1, 2];
			this.gridLoad();
			this.callAPI();
		}

		this.employeeLoan = new EmployeeLoan();
		this.isReadOnly = false;
		this.form = this.fb.group({
			loanId: this.loanId,
			financialItemId: new UntypedFormControl(0, Validators.required),
			paymentPeriod: new UntypedFormControl(""),
			loanAmount: new UntypedFormControl(0, Validators.required),
			monthlyPaymentPeriod: new UntypedFormControl(0, Validators.required),
			paymentPerPeriod: new UntypedFormControl("", Validators.required),
			payPerPeriodSumInterest: new UntypedFormControl(""),
			dateOfPeriodCalculateNo: new UntypedFormControl(new Date(), Validators.required),
			interestType: new UntypedFormControl(""),
			interestRatePerYear: new UntypedFormControl(0),
			remark: new UntypedFormControl(""),
			remarkLog: new UntypedFormControl(""),
			periodCalculateNo: new UntypedFormControl(""),
			employeeId: new UntypedFormControl("", Validators.required),
			interestAmount: new UntypedFormControl(0),
			status: this.status,
			year: new UntypedFormControl(""),
		});
	}
	private loadOrganizationByCompanyId() {
		this.organizationService.getOrganizationNode().then(async (res: any) => {
			this.orgDDL = this.mapChildren(res.data, this.filedOrganize);
		});
	}
	mapChildren(datas: any[], fields: any) {
		return datas.map((data) => {
			let model: any = {};
			model[fields.text] = data[fields.text];
			model.name_EN = data.name_EN;
			model[fields.value] = data[fields.value];
			model.dataSource = data[fields.children]?.length ? this.mapChildren(data[fields.children], fields) : [];
			return model;
		});
	}

	private currentLanguage$: Subscription;
	private currentUserInfo$: Subscription;
	loadConfirm() {
		this.confirmation = this.translate.instant("common_confirmation");
		this.saveConfirmation = this.translate.instant("common_save_confirmation");
		this.deleteConfirmation = this.translate.instant("common_delete_confirmation");
	}
	checkEmployeeId: boolean = false;
	checkFinancialItemId: boolean = false;
	checkLoanAmount: boolean = false;
	checkMonthlyPaymentPeriod: boolean = false;
	checkPeriodCalculateNo: boolean = false;
	onSubmit() {
		this.sharingService.pushGoogleTagManager("Employment_Details_Salary_Liabilities_Add_Save");
		this.checkEmployeeId = true;
		this.checkFinancialItemId = true;
		this.checkLoanAmount = true;
		this.checkMonthlyPaymentPeriod = true;
		this.checkPeriodCalculateNo = true;
		if (this.form.controls["employeeId"].value != null) {
			this.checkEmployeeId = false;
		}
		if (this.form.controls["financialItemId"].value != 0 && this.form.controls["financialItemId"].value != null) {
			this.checkFinancialItemId = false;
		}
		if (this.form.controls["loanAmount"].value != 0 && this.form.controls["loanAmount"].value != null) {
			this.checkLoanAmount = false;
		}
		if (
			this.form.controls["monthlyPaymentPeriod"].value != 0 &&
			this.form.controls["monthlyPaymentPeriod"].value != null
		) {
			this.checkMonthlyPaymentPeriod = false;
		}
		if (this.form.controls["dateOfPeriodCalculateNo"].value != null) {
			this.checkPeriodCalculateNo = false;
		}

		if (!this.form.valid || this.form.controls["dateOfPeriodCalculateNo"].value == 0 || this.checkFinancialItemId) {
			return this._toastService.Validation({
				title: this.translate.instant("common_heads_up"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
		}

		this.loadConfirm();
		if (isNullOrUndefined(this.loanId)) {
			const dialogModel = <DialogInformationModel>{
				title: this.translate.instant("Please_enter_the_reason"),
				description: null,
				imageUrl: null,
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
				placeholder: this.translate.instant("common_please_state_continue"),
				iconClass: "gf-icon-empeo-document",
				isRequiredRemark: true,
				isShowRemark: true,
				remarkSize: "extra-large",
			};

			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.form.patchValue({
						loanId: 0,
						employeeId: this.isWelfareMaster ? this.form.controls["employeeId"].value : this.id,
						financialItemId: this.financialItemId,
						periodCalculateNo: this.periodCalculateNo,
						loanAmount: this.loanAmount,
						dateOfPeriodCalculateNo: this.dateOfPeriodCalculateNo,
						monthlyPaymentPeriod: this.monthlyPaymentPeriod,
						paymentPerPeriod: this.payPerPeriodSumInterest,
						interestType: this.isInterestType ? InterestType.fixedRate : InterestType.noRate,
						interestRatePerYear: this.interestRatePerYear,
						remark: this.remark,
						remarkLog: response?.remark,
						interestAmount: this.interestAmount,
						status: this.enumLoanStatus.pending,
						year: this.paymentPeriod?.year ?? null,
					});

					if (this.onWelfareEmployeeLoan$) this.onWelfareEmployeeLoan$.unsubscribe();
					this.onWelfareEmployeeLoan$ = this.employeeLoanService
						.addWelfareEmployeeLoan(this.form.value)
						.subscribe((res) => {
							var apiResult = <ApiResult>res;
							if (apiResult.result) {
								this._toastService.Success({
									title: this.translate.instant("toast_title_success"),
									description: this.translate.instant("emp001_t19_liabilities_create_toast"),
								});
								this.gridLoad();
								this.resetForm();
								this.closeSidebar();
							} else {
								this._toastService.Error({
									title: this.translate.instant("common_fail"),
									description: apiResult.message,
								});
							}
						});
				}
			});
		} else {
			const dialogModel = <DialogInformationModel>{
				title: this.translate.instant("Please_enter_the_reason"),
				description: null,
				imageUrl: null,
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
				placeholder: this.translate.instant("common_please_state_continue"),
				iconClass: "gf-icon-empeo-document",
				isRequiredRemark: true,
				isShowRemark: true,
				remarkSize: "extra-large",
			};

			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.payPerPeriodSumInterest = this.form.controls["payPerPeriodSumInterest"].value;
					this.form.patchValue({
						loanId: this.loanId,
						employeeId: this.id,
						financialItemId: this.financialItemId,
						periodCalculateNo: this.periodCalculateNo,
						dateOfPeriodCalculateNo: this.dateOfPeriodCalculateNo,
						loanAmount: this.loanAmount,
						monthlyPaymentPeriod: this.monthlyPaymentPeriod,
						paymentPerPeriod: this.payPerPeriodSumInterest,
						interestType: this.isInterestType ? InterestType.fixedRate : InterestType.noRate,
						interestRatePerYear: this.interestRatePerYear,
						remark: this.remark,
						remarkLog: response?.remark,
						interestAmount: this.interestAmount,
						status: this.status,
						year: this.paymentPeriod.year,
					});
					if (this.onWelfareEmployeeLoan$) this.onWelfareEmployeeLoan$.unsubscribe();
					this.onWelfareEmployeeLoan$ = this.employeeLoanService
						.editWelfareEmployeeLoan(this.form.value)
						.subscribe((res) => {
							var apiResult = <ApiResult>res;
							if (apiResult.result) {
								this._toastService.Success({
									title: this.translate.instant("toast_title_success"),
									description: this.translate.instant("toast_common_save_success"),
								});
								this.gridLoad();
								this.resetForm();
								this.closeSidebar();
							} else {
								this._toastService.Error({
									title: this.translate.instant("common_fail"),
									description: apiResult.message,
								});
								this.gridLoad();
								this.resetForm();
							}
						});
				}
			});
		}
	}

	gridLoad(skip = 0) {
		this.isLoadtable = true;
		if (skip == 0) {
			this.employeeLoans = [];
		}

		var model = {
			financialItemId: !isNullOrUndefined(this.typeId) ? this.typeId : 0,
			orgId: !isNullOrUndefined(this.orgId) ? this.orgId : 0,
			status: this.statusId?.toString(),
			keyword: this.keyword,
			employeeId: this.isWelfareMaster ? 0 : this.id,
			skip: skip,
			take: 30,
		};
		this.employeeLoanService
			.getWelfareEmployeeLoans(model)
			.then((res) => {
				if (res) {
					this.employeeLoans = this.employeeLoans?.concat(res);
				}
			})
			.finally(() => {
				this.isLoadtable = false;
				this.isLoadDataScroll = false;
			});
	}

	listPeople: any;
	peopleFields: any = { text: "fullname", detail: "positionName", picture: "pictureURL", value: "employeeId" };
	peopleFields_EN: any = { text: "fullname_EN", detail: "positionName_EN", picture: "pictureURL", value: "employeeId" };

	get DropdownSelectMode() {
		return DropdownSelectMode;
	}
	callAPI() {
		if (!this.isWelfareMaster) {
			let isAllPeriod = true;
			let isNextYear = true;
			this.commonService.getDDLPaymentPeriodByEmployeeId(this.id, isAllPeriod, isNextYear).then((res) => {
				this.paymentPeriodAllDDL = res;
				this.paymentPeriodClosedDDL = res.filter((f) => f.isClosed != true);
				this.paymentPeriodDDL = this.paymentPeriodClosedDDL.sort(function (a, b) {
					return a["year"] - b["year"] || a["periodNo"] - b["periodNo"];
				});
				// this.paymentPeriodDDL = this.paymentPeriodClosedDDL.toSorted()
				this.paymentPeriod = !isNullOrUndefined(this.paymentPeriodDDL) ? this.paymentPeriodDDL[0] : null;
			});
		}
		this.loadOrganizationByCompanyId();
		this.commonService.getDDLWelfareLoanStatus().then((res) => {
			this.statusDDL = res;
		});
		this.commonService.getPeople().subscribe((res) => {
			this.listPeople = res.data;
			if (!this.isWelfareMaster) {
				var has = this.listPeople.find((r) => r.employeeId == this.employee.employeeId);
				if (has == null) {
					var queryParam = {
						employeeIds: [this.employee.employeeId],
					};
					firstValueFrom(this.employeeService.getProfilesByEmployeeIds(queryParam))
						.then((result) => {
							var modelRes = result;
							modelRes?.forEach((m) => {
								m.fullname = m.fullName;
								this.listPeople.push(m);
							});
						})
						.finally(() => {
							this.form.controls["employeeId"].setValue(this.employee.employeeId);
							this.employeeId = this.employee.employeeId;
						});
				} else {
					this.form.controls["employeeId"].setValue(this.employee.employeeId);
					this.employeeId = this.employee.employeeId;
				}
			}
		});

		this.employeeLoanService.getWelfareEmployeeLoadDDL().then((res) => {
			this.typeDDL = res;
		});
	}

	calculateAmountPeriod() {
		this.checkEmployeeId = false;
		var result = (
			this.loanAmount / this.monthlyPaymentPeriod +
			(this.loanAmount * this.interestRatePerYear) / 100.0 / 12
		).toFixed(2);
		this.paymentPerPeriod = Number(this.loanAmount / this.monthlyPaymentPeriod);
		this.form.controls["paymentPerPeriod"].setValue(this.paymentPerPeriod);
		var resultAmount = Number(!this.isInitCal ? result : this.paymentPerPeriod ?? 1);
		this.payPerPeriodSumInterest = resultAmount > 0 && isFinite(resultAmount) ? resultAmount : 0;
		this.form.controls["payPerPeriodSumInterest"].setValue(this.payPerPeriodSumInterest);
		if (this.isInterestType) {
			this.calculateInterestAmount();
		}
	}

	calculateInterestAmount() {
		var result = ((this.loanAmount * this.interestRatePerYear) / 100.0 / 12) * this.monthlyPaymentPeriod;
		this.interestAmount = Number(result.toFixed(2));
		this.form.controls["interestAmount"].setValue(this.interestAmount);
	}

	checkInterest() {
		if (!this.isInterestType) {
			this.interestRatePerYear = 0;
			this.interestAmount = 0;
			this.calculateAmountPeriod();
		}
	}

	exportExcelEmployeeLoan() {
		var model = {
			financialItemId: !isNullOrUndefined(this.typeId) ? this.typeId : 0,
			orgId: !isNullOrUndefined(this.orgId) ? this.orgId : 0,
			status: this.statusId?.toString(),
			keyword: this.keyword,
			employeeId: this.isWelfareMaster ? 0 : this.id,
		};
		if (this.export$) this.export$.unsubscribe();
		this.export$ = this.employeeLoanService.exportReportEmployeeLoan(model).subscribe((res) => {
			var apiResult = <ApiResult>res;
			if (apiResult.result) {
				let excelFile = <FileInfo>apiResult.data;
				this.downloadDocument(excelFile);
				this._toastService.Success({
					title: this.translate.instant("toast_title_success"),
					description: apiResult.message,
				});
			} else {
				this._toastService.Error({
					title: this.translate.instant("common_fail"),
					description: apiResult.message,
				});
			}
		});
	}

	resetForm() {
		this.form.reset();
		this.checkFinancialItemId = false;
		this.checkLoanAmount = false;
		this.checkEmployeeId = false;
		this.checkMonthlyPaymentPeriod = false;
		this.checkPeriodCalculateNo = false;
		this.paymentPeriod = null;
		this.isInterestType = false;
		this.loanId = null;
		this.documentNo = null;
		this.loanAmount = 0;
		this.monthlyPaymentPeriod = 0;
		this.periodCalculateNo = 0;
		this.paymentPerPeriod = 0;
		this.payPerPeriodSumInterest = null;
		this.interestRatePerYear = 0;
		this.interestAmount = 0;
		this.financialItemId = 0;
		this.remark = "";
		this.checkInterest();
		this.isReadOnly = false;
		this.isEdit = false;
		this.employeeLoan = new EmployeeLoan();

		this.employeeLoan.status = this.enumLoanStatus.pending;
		this.callAPI();
	}

	downloadDocument(data: any) {
		let byteCharacters = atob(data.file);

		let byteNumbers = new Array(byteCharacters.length);
		for (var i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		let byteArray = new Uint8Array(byteNumbers);
		let blob = new Blob([byteArray], { type: data.fileMimeType });

		let link = document.createElement("a");

		link.href = URL.createObjectURL(blob);

		link.setAttribute("visibility", "hidden");
		link.download = data.fileName;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	editLoan(loanId: number, isClosePayment: boolean = false) {
		this.resetForm();
		this.isEdit = true;
		this.isInitCal = true;
		this.isClosePayment = isClosePayment;
		this.employeeLoanService.getWelfareEmployeeLoanByLoanId(loanId).then((res) => {
			this.employeeLoan = res;
			this.loanId = this.employeeLoan.loanId;
			this.remark = !isNullOrUndefined(this.employeeLoan.remark) ? this.employeeLoan.remark : "";
			this.status = this.employeeLoan.status;

			if (this.employeeLoan.status == this.enumLoanStatus.pending) {
				//รอชำระ
				this.isReadOnly = false;
				this.paymentPeriodDDL = this.paymentPeriodClosedDDL;
				this.getPaymentPeriodByAccumulate();
				if (!isNullOrUndefined(this.employeeLoan.periodCalculateNo)) {
					this.paymentPeriod = !isNullOrUndefined(this.paymentPeriodDDL)
						? this.paymentPeriodDDL.find(
								(f) => f.year == this.employeeLoan.year && f.periodCalculateNo == this.employeeLoan.periodCalculateNo,
							)
						: null;
				} else {
					this.paymentPeriod = !isNullOrUndefined(this.paymentPeriodDDL) ? this.paymentPeriodDDL[0] : null;
				}
			} else if (this.employeeLoan.status == this.enumLoanStatus.overdue) {
				//ค้างชำระ

				this.isReadOnly = true;
				this.paymentPeriodDDL = this.paymentPeriodAllDDL;
				this.form.controls["paymentPeriod"].clearValidators();
				this.form.controls["paymentPeriod"].updateValueAndValidity();
				this.getPaymentPeriodByAccumulate();
			}
			this.isInterestType = !isNullOrUndefined(this.employeeLoan.interestType)
				? this.employeeLoan.interestType == InterestType.fixedRate
				: null;
			var hasClose: PaymentPeriod;
			if (!isNullOrUndefined(this.paymentPeriodAllDDL)) {
				hasClose = this.paymentPeriodAllDDL?.find((f) => f.periodCalculateNo == this.periodCalculateNo);
			}
			if (this.isWelfareMaster) {
				this.commonService
					.getDDLPaymentPeriodByEmployeeId(this.employeeLoan.employeeId, true, true)
					.then((result) => {
						this.paymentPeriodAllDDL = result;
						this.paymentPeriodClosedDDL = result.filter((f) => !f.isClosed);
						this.paymentPeriodDDL = this.paymentPeriodClosedDDL.sort(function (a, b) {
							return a["year"] - b["year"] || a["periodNo"] - b["periodNo"];
						});
						this.paymentPeriod = !isNullOrUndefined(this.paymentPeriodDDL) ? this.paymentPeriodDDL[0] : null;
					})
					.finally(() => {
						this.form.controls["periodCalculateNo"].setValue(this.periodCalculateNo);
						this.periodCalculateNo = this.employeeLoan.periodCalculateNo;
						const has = this.paymentPeriodDDL.find((f) => f.periodCalculateNo == this.periodCalculateNo);
						if (isNullOrUndefined(has)) {
							// งวดที่ปิดไปแล้ว

							if (!isNullOrUndefined(hasClose)) {
								this.paymentPeriodDDL.push(hasClose);
							}
						}
					});
			} else {
				this.form.controls["periodCalculateNo"].setValue(this.periodCalculateNo);
				this.periodCalculateNo = this.employeeLoan.periodCalculateNo;
				var has2 = this.paymentPeriodDDL.find((f) => f.periodCalculateNo == this.periodCalculateNo);
				if (isNullOrUndefined(has2)) {
					// งวดที่ปิดไปแล้ว
					if (!isNullOrUndefined(hasClose)) {
						this.paymentPeriodDDL.push(hasClose);
					}
				}
			}
			var has = this.listPeople.find((r) => r.employeeId == this.employeeLoan.employeeId);
			if (isNullOrUndefined(has)) {
				var queryParam = {
					employeeIds: [this.employeeLoan.employeeId],
				};
				firstValueFrom(this.employeeService.getProfilesByEmployeeIds(queryParam))
					.then((result) => {
						var modelRes = result;
						modelRes?.forEach((m) => {
							m.fullname = m.fullName;
							this.listPeople.push(m);
						});
						if (this.isWelfareMaster) {
							this.commonService.getDDLFinancialItemByCompanyId(modelRes[0].companyId).then((financial) => {
								this.financialItemDDL = financial.filter((f) => f.category == FinancialCategory.other);
								this.financialItemId = this.employeeLoan.financialItemId;
								this.form.controls["financialItemId"].setValue(this.financialItemId);
							});
						}
					})
					.finally(() => {
						this.form.controls["employeeId"].setValue(this.employeeLoan.employeeId);
						this.employeeId = this.employeeLoan.employeeId;
					});
			} else {
				this.form.controls["employeeId"].setValue(this.employeeLoan.employeeId);
				this.employeeId = this.employeeLoan.employeeId;
				this.employeeMovementsService.getEmployeeById(this.employeeLoan.employeeId).then((emp) => {
					this.companyId = emp.companyId;
					this.commonService.getDDLFinancialItemByCompanyId(this.companyId).then((result) => {
						this.financialItemDDL = result.filter((f) => f.category == FinancialCategory.other);
						this.financialItemId = this.employeeLoan.financialItemId;
						this.form.controls["financialItemId"].setValue(this.financialItemId);
					});
				});
			}

			this.documentNo = !isNullOrUndefined(this.employeeLoan.documentNo) ? this.employeeLoan.documentNo : null;
			this.loanAmount = !isNullOrUndefined(this.employeeLoan.loanAmount) ? this.employeeLoan.loanAmount : null;
			this.monthlyPaymentPeriod = !isNullOrUndefined(this.employeeLoan.monthlyPaymentPeriod)
				? this.employeeLoan.monthlyPaymentPeriod
				: null;
			this.paymentPerPeriod = !isNullOrUndefined(this.employeeLoan.paymentPerPeriod)
				? Number(this.employeeLoan.loanAmount / this.employeeLoan.monthlyPaymentPeriod)
				: null;
			this.payPerPeriodSumInterest = !isNullOrUndefined(this.employeeLoan.paymentPerPeriod)
				? this.employeeLoan.paymentPerPeriod
				: null;
			this.interestRatePerYear = !isNullOrUndefined(this.employeeLoan.interestRatePerYear)
				? this.employeeLoan.interestRatePerYear
				: null;
			this.interestAmount = !isNullOrUndefined(this.employeeLoan.interestAmount)
				? this.employeeLoan.interestAmount
				: null;
			this.dateOfPeriodCalculateNo = !isNullOrUndefined(this.employeeLoan.dateOfPeriodCalculateNo)
				? this.employeeLoan.dateOfPeriodCalculateNo
				: null;
			this.form.controls["interestRatePerYear"].setValue(this.interestRatePerYear);
			this.form.controls["interestAmount"].setValue(this.interestAmount);
			this.form.controls["monthlyPaymentPeriod"].setValue(this.monthlyPaymentPeriod);
			this.form.controls["paymentPerPeriod"].setValue(this.paymentPerPeriod);
			this.form.controls["loanAmount"].setValue(this.loanAmount);
			this.displaySidebar = true;
		});
	}

	deleteLoan(loanId: number) {
		this.loadConfirm();
		const dialogModel = <DialogInformationModel>{
			title: this.confirmation,
			description: this.deleteConfirmation,
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_delete"),
			textButtonCancel: this.translate.instant("common_cancel"),
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				if (this.deleteWelfareEmployeeLoan$) this.deleteWelfareEmployeeLoan$.unsubscribe();
				this.deleteWelfareEmployeeLoan$ = this.employeeLoanService
					.deleteWelfareEmployeeLoan(loanId)
					.subscribe((res) => {
						if (res) {
							this.loanId = null;
							this.gridLoad();
							this.resetForm();
							this._toastService.Success({
								title: this.translate.instant("toast_title_success"),
								description: this.translate.instant("deleted_successfully"),
							});
						} else {
							this._toastService.Error({
								title: this.translate.instant("common_error"),
								description: this.translate.instant("cannot_delete_data "),
							});
						}
					});
			}
		});
	}

	//#region Destroy unsubscribe
	ngOnDestroy() {
		if (this.subscriptionEmpId) this.subscriptionEmpId.unsubscribe();
		this.currentUserInfo$ && this.currentUserInfo$.unsubscribe();
		this.currentLanguage$ && this.currentLanguage$.unsubscribe();

		if (this.onWelfareEmployeeLoan$) this.onWelfareEmployeeLoan$.unsubscribe();
		if (this.export$) this.export$.unsubscribe();
		if (this.deleteWelfareEmployeeLoan$) this.deleteWelfareEmployeeLoan$.unsubscribe();
	}

	closePayment(loanId) {
		const dialogModel = <DialogInformationModel>{
			title: this.translate.instant("Please_enter_the_reason"),
			description: null,
			imageUrl: null,
			textButtonConfirm: this.translate.instant("comfirm_continue"),
			textButtonCancel: this.translate.instant("common_cancel"),
			placeholder: this.translate.instant("common_please_state_continue"),
			iconClass: "gf-icon-empeo-document",
			isRequiredRemark: true,
			isShowRemark: true,
			remarkSize: "extra-large",
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				var model = {
					loanId: loanId,
					remarkLog: response?.remark,
				};
				this.employeeLoanService.closePaymentWelfareEmployeeLoans(model).subscribe((res) => {
					var apiResult = <ApiResult>res;
					if (apiResult.result) {
						this.loanId = null;
						this.gridLoad();
						this.resetForm();
						this.closeSidebar();
						this._toastService.Success({
							title: this.translate.instant("toast_title_success"),
							description: this.translate.instant("toast_common_save_success"),
						});
					} else {
						this._toastService.Error({
							title: this.translate.instant("common_error"),
							description: apiResult.message,
						});
					}
				});
			}
		});
	}

	onChangeInterestType() {
		if (!this.isEdit) {
			this.calculateAmountPeriod();
		}
	}

	employeeId: number;

	openSidebar() {
		this.sharingService.pushGoogleTagManager("Employment_Details_Salary_Liabilities_Add");
		this.resetForm();

		if (this.isWelfareMaster) {
			this.employeeId = 0;
		} else {
			var has = this.listPeople.find((r) => r.employeeId == this.id);
			if (has == null) {
				var queryParam = {
					employeeIds: [this.id],
				};
				firstValueFrom(this.employeeService.getProfilesByEmployeeIds(queryParam)).then((res) => {
					var modelRes = res;
					modelRes?.forEach((m) => {
						m.fullname = m.fullName;
						this.listPeople.push(m);
					});
					this.employeeId = this.id;
				});
			}
		}
		this.displaySidebar = true;
	}

	closeSidebar() {
		this.sharingService.pushGoogleTagManager("Employment_Details_Salary_Liabilities_Add_Cancel");
		this.displaySidebar = false;
		this.displayLoanDetail = false;
		this.employeeLoan = new EmployeeLoan();
	}

	openLoanDetailSidebar(model: EmployeeLoan) {
		this.employeeLoan = model;
		this.displayLoanDetail = true;
		this.getPaymentPeriodByAccumulate();
		this.getWelfareEmployeeLoanDetailById(this.employeeLoan.loanId);
	}

	getWelfareEmployeeLoanDetailById(loanId) {
		this.employeeLoanService.getWelfareEmployeeLoanDetailById(loanId).then((res) => {
			this.loanDetail = res;
		});
	}

	getPaymentPeriodByAccumulate() {
		this.paymentPeriodService
			.getPaymentPeriodByAccumulate(this.employeeLoan.year, this.employeeLoan.periodCalculateNo)
			.then((res) => {
				this.paymentPeriod = res;
				if (isNullOrUndefined(this.paymentPeriod)) {
					this.paymentPeriod = !isNullOrUndefined(this.paymentPeriodAllDDL)
						? this.paymentPeriodAllDDL.find(
								(f) => f.year == this.employeeLoan.year && f.periodCalculateNo == this.employeeLoan.periodCalculateNo,
							)
						: null;
				}
			});
	}

	setInitCal() {
		this.isInitCal = false;
	}
	public isLoadDataScroll: boolean = false;
	loadMore() {
		this.isLoadDataScroll = true;
		this.gridLoad(this.employeeLoans.length);
	}

	statusId: any;
	onSelectStatus(e?) {
		this.statusId = e.value;
		this.gridLoad();
	}

	clearStatus() {
		this.statusId = null;
		this.gridLoad();
	}
	searchKeyword() {
		this.gridLoad();
	}

	keyword: string = "";
	orgId: number;
	onSelectOrg(e?) {
		this.orgId = e.value;
		this.gridLoad();
	}

	clearOrg() {
		this.orgId = null;
		this.gridLoad();
	}

	onSelectType(e?) {
		this.typeId = e.value;
		this.gridLoad();
	}

	clearType() {
		this.typeId = null;
		this.gridLoad();
	}

	onFilterEmployee(event) {
		event.cancel = true;
		const searchText = event.text.trim().toLowerCase();
		firstValueFrom(this.commonService.getPeople(searchText)).then((res) => {
			this.listPeople = res.data;
		});
	}
	selectDateOfPeriodCalculateNo(event) {
		this.checkPeriodCalculateNo = false;
		this.dateOfPeriodCalculateNo = event.value;
		//this.paymentPeriod = event.selectedItem
	}
	selectEmployee(event) {
		this.checkEmployeeId = false;
		this.employee = event.employeeId;
		if (this.isWelfareMaster) {
			this.commonService.getDDLFinancialItemByCompanyId(event.selectedItem.companyId).then((res) => {
				this.financialItemDDL = res.filter((f) => f.category == FinancialCategory.other);
			});
			this.commonService.getDDLPaymentPeriodByEmployeeId(event.value, true, true).then((res) => {
				this.paymentPeriodAllDDL = res;
				this.paymentPeriodClosedDDL = res.filter((f) => f.isClosed != true);
				this.paymentPeriodDDL = this.paymentPeriodClosedDDL.sort(function (a, b) {
					return a["year"] - b["year"] || a["periodNo"] - b["periodNo"];
				});
				this.paymentPeriod = !isNullOrUndefined(this.paymentPeriodDDL) ? this.paymentPeriodDDL[0] : null;
			});
		}
	}
	checkEmployee() {
		if ((this.isWelfareMaster && this.financialItemDDL == null) || this.financialItemDDL.length == 0) {
			this.checkEmployeeId = true;
		}
	}

	checkDate(dateStart: Date, dateEnd: Date, type: number): boolean {
		if (dateStart != null && dateEnd != null) {
			dateStart = new Date(dateStart);
			dateEnd = new Date(dateEnd);
			if (type == 1) {
				return dateStart.getMonth() == dateEnd.getMonth() && dateStart.getFullYear() == dateEnd.getFullYear()
					? true
					: false;
			} else if (type == 2) {
				return dateStart.getFullYear() == dateEnd.getFullYear() ? true : false;
			} else if (type == 3) {
				return dateStart.getFullYear() != dateEnd.getFullYear() ? true : false;
			}
		} else {
			return false;
		}
	}

	checkDecimal(e) {
		if (e.key == "." || e.key == "+" || e.key == "-" || e.key == "e") {
			return false;
		}
	}

	viewEmployee(loanDetail) {
		this.employeeMovementsService.getEmployeeById(loanDetail.employeeId).then((emp) => {
			this.data.setEmpId(emp.employeeId);
			window.open(`${environment.redirectUri}employee/${emp.employeeNo}`);
		});
	}
}
