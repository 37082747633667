import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject, first, firstValueFrom, map } from "rxjs";
import { environment } from "src/environments/environment";
import { AssessmentTypeModel } from "../../assessment/shared/assessment-type.model";
import { AssessmentScoreSearch } from "../../assessment/shared/assessment.model";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { PaymentPeriod } from "../../payroll/master/payment-period/shared/payment-period.model";
import { ApiUrl } from "../enum/app-config.enum";
import { ApplicationConfig } from "../models/application-config.model";
import { DropdownDataItem } from "../models/dropdown-data-item.model";
import { Dropdown, EmployeeFamilyDDL, FinancialItemDDLModel } from "../models/dropdown.model";
import { PermissionPayroll } from "../models/permission.model";
import { MasterService } from "./master.service";
import { ApiResult } from "../models/api-result.model";
import { SharingService } from "../sharing.service";
import { SessionStorageKey } from "../enum/local-storage-key";
import { isNullOrUndefined } from "@gofive/angular-common";

@Injectable()
export class CommonService extends MasterService {
	private httpOption = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};
	constructor(
		private http: HttpClient,
		@Inject("API_URL_LST") public apiUrls: string[],
		@Inject("API_URL") private apiUrl: string,
		@Inject("VENIO_API_URL") private apiVenioUrl: string,
		@Inject("GOFIVE_API_URL") private gofiveApiUrl: string,
		private sharing: SharingService,
	) {
		super(http, apiUrls);
	}

	getMasterTypeByParentId(parentId: number) {
		return firstValueFrom(this.http.get<any>(`${this.apiUrl}common/masterType?parentId=${parentId}`));
	}

	changeDateJSON() {
		Date.prototype.toJSON = function () {
			var timezoneOffsetInHours = -(this.getTimezoneOffset() / 60); //UTC minus local time

			const integerPart: number = Math.floor(timezoneOffsetInHours);
			const decimalPart: number = timezoneOffsetInHours - integerPart;

			var sign = integerPart >= 0 ? "+" : "-";

			var correctedDate = new Date(
				this.getFullYear(),
				this.getMonth(),
				this.getDate(),
				this.getHours(),
				this.getMinutes(),
				this.getSeconds(),
				this.getMilliseconds(),
			);
			correctedDate.setHours(this.getHours() + integerPart);
			var iso = correctedDate.toISOString().replace("Z", "");
			return (
				iso +
				sign +
				Math.abs(integerPart).toString().padStart(2, "0") +
				":" +
				(decimalPart * 60).toString().padStart(2, "0")
			);
		};
	}

	getPresentYear() {
		let now = new Date();
		var datePipe = new DatePipe("en-US");
		return datePipe.transform(now, "y");
	}

	getDDLProvince(countryId: number = 19) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + `Common/GetDDLProvince?countryId=${countryId}`)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLDistrict() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLDistrict")).then((res) => <Dropdown[]>res);
	}

	getDDLCountries() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLCountries")).then((res) => <Dropdown[]>res);
	}

	getDDLEmployeeType() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLEmployeeType")).then((res) => <Dropdown[]>res);
	}

	getDDLStatus() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLStatus")).then((res) => <Dropdown[]>res);
	}

	getDDLSystemStatus() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLSystemStatus")).then((res) => <Dropdown[]>res);
	}

	getDDLAssessmentStatus() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLAssessmentStatus")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLWelfareCategory() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLWelfareCategory")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLCalendar() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLCalendar")).then((res) => <Dropdown[]>res);
	}

	getDDLAssessmentType() {
		return firstValueFrom(this.http.get(this.apiUrl + "AssessmentMaster/GetDDLAssessmentType/")).then(
			(res) => <AssessmentTypeModel[]>res,
		);
	}

	getDDLFreezeList(companyId: number) {
		var queryString = this.sharing.getQueryString({ companyId: companyId });
		return firstValueFrom(this.http.get(this.apiUrl + `Common/GetDDLFreezeList${queryString}`)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLComponent() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLComponent")).then((res) => <Dropdown[]>res);
	}

	getDDLPaymentMethod() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLPaymentMethod")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLEmployeeStatus() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLEmployeeStatus")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLShiftRequestType() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLShiftRequestType")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLTrainingRequestType() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLTrainingRequestType")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLEmploymentTypes() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLEmploymentTypes")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLGender() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLGender")).then((res) => <Dropdown[]>res);
	}

	getDDLRateTypes() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLRateTypes")).then((res) => <Dropdown[]>res);
	}

	getDDLTitle() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLTitle")).then((res) => <Dropdown[]>res);
	}

	getDDLRanks() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLRanks")).then((res) => <Dropdown[]>res);
	}

	getDDLBankPaymentTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLBankPaymentTemplate")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLIncomeTypes() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLIncomeTypes")).then((res) => <Dropdown[]>res);
	}

	getSPBBankPaymentTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetSPBBankPaymentTemplate")).then((res) => <any[]>res);
	}

	getDDLBankPaymentTemplateIsProvidentFund() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLBankPaymentTemplateIsProvidentFund")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLWorked() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLWorked")).then((res) => <Dropdown[]>res);
	}

	getDDLAccountGroup() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLAccountGroup")).then((res) => <Dropdown[]>res);
	}

	getDDLPosition(positionId?: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLPosition/" + (positionId ? positionId : 0))).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLPositionByName(positionName) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLPositionByName/" + positionName)).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLAccumulateType() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLAccumulateType")).then((res) => <Dropdown[]>res);
	}

	getDDLShift() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLShift")).then((res) => <Dropdown[]>res);
	}
	getDDLShiftByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/getDDLShiftByEmployeeId/" + employeeId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLShiftByCompanyId(companyId: number = 0) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLShiftByCompanyId/" + (companyId > 0 ? companyId : 0)),
		).then((res) => <Dropdown[]>res);
	}

	getDDLMssRosterShiftByCompanyId(companyId: number, employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLMssRosterShiftByCompanyId/" + companyId + "/" + employeeId),
		).then((res) => <Dropdown[]>res);
	}

	getDDLWelfareEJIPStatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLWelfareEJIPStatus")).then((res) => <Dropdown[]>res);
	}
	getGetProcessById(processId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetProcessById/" + processId)).then((res) => <any>res);
	}

	getDDLProcessAction() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProcessAction")).then((res) => <Dropdown[]>res);
	}

	getDDLProcessActionPersonnelMovement() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProcessActionPersonnelMovement")).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLProcessActionHRMovement() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProcessActionHRMovement")).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLProcessActionMovement() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProcessActionMovement")).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLProcessActionByModule(moduleId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProcessActionByModule/" + moduleId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLDocumentStatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLDocumentStatus")).then((res) => <Dropdown[]>res);
	}

	getTestDDLPosition(positionId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetTestDDLPosition/" + positionId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLCompany() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLCompany")).then((res) => <Dropdown[]>res);
	}

	getDDLCompanyByFunctionCode(functionCode: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLCompanyByFunctionCode/" + functionCode)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLCompanyPayroll() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/getDDLCompanyPayroll/")).then((res) => <Dropdown[]>res);
	}

	getDDLCompanyFromLeaveHistory() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLCompanyFromLeaveHistory/")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLSysTenentByTenantId(functionCode?: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLSysTenentByTenantId/" + functionCode)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLCommissionDocumentTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLCommissionDocumentTemplate/")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLLeaveType() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLLeaveType")).then((res) => <Dropdown[]>res);
	}

	getDDLLeaveTypeByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLLeaveTypeByEmployeeId/" + employeeId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLLeaveTypeByEmployeeIdAndLeaveCategoryId(employeeId: number, leaveCategoryId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "Common/GetDDLLeaveTypeByEmployeeIdAndLeaveCategoryId/" + employeeId + "/" + leaveCategoryId,
			),
		).then((res) => <Dropdown[]>res);
	}

	getDDLWorkflow() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLWorkflow")).then((res) => <Dropdown[]>res);
	}

	getDDLMonth() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLMonth")).then((res) => <Dropdown[]>res);
	}

	getDayByMonth(monthNum: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDayByMonth/" + monthNum)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLOrganizationByLevel(parentId: number, level: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLOrganizationByLevel/" + parentId + "/" + level),
		).then((res) => <Dropdown[]>res);
	}

	getSysConfig(code: string, companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetSysConfig/" + code + "/" + companyId)).then(
			(res) => <ApplicationConfig>res,
		);
	}

	getSysConfigByContain(code: string[], companyId: number) {
		var body = JSON.stringify({ code: code, companyId: companyId });
		return this.http.post(this.apiUrl + "Common/GetSysConfigByContain", body, this.httpOption);
	}

	getDDLOrganizationByCompanyId(companyId: number, level: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLOrganizationByCompanyId/" + companyId + "/" + level),
		).then((res) => <Dropdown[]>res);
	}

	getDDLPositionByRankNo(rankNo: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLPositionByRankNo/" + rankNo)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLLeaveTypeByCompanyId(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLLeaveTypeByCompanyId/" + companyId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLWelfareTypeByCompanyId(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLWelfareTypeByCompanyId/" + companyId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLLeaveTypeByCompanyIdAndLeaveCategoryId(companyId: number, leaveCategoryId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLLeaveTypeByCompanyId/" + companyId + "/" + leaveCategoryId),
		).then((res) => <Dropdown[]>res);
	}

	getDDLDistrictByProvinceId(provinceId: number = 0) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLDistrictByProvinceId/" + (provinceId ?? 0))).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLSubDistrictDDLByDistrictId(provinceId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLSubDistrictDDLByDistrictId/" + provinceId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLSysOrganizationByTenantId() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLSysOrganizationByTenantId/")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLMasterSetupById(systemRegisNo: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLMasterSetupById/" + systemRegisNo)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLMasterSetupByIdAndValue(systemRegisNo: number, values: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLMasterSetupByIdAndValue/" + systemRegisNo + "/" + values),
		).then((res) => <Dropdown[]>res);
	}

	getDDLMasterSetupBySystemRegisNoByName(systemRegisNo: number, name: string) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + `Common/GetDDLMasterSetupBySystemRegisNoByName/?systemRegisNo=${systemRegisNo}&name=${name}`,
			),
		).then((res) => <Dropdown[]>res);
	}

	getDDLSysNamePrefixesByName(name: string) {
		return firstValueFrom(this.http.get(this.apiUrl + `Common/getDDLSysNamePrefixesByName/?name=${name}`)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLMasterSetupBySystemRegisNo(systemRegisNos: string, isOrderByValue: boolean = false) {
		const queryString = this.sharing.getQueryString({ isOrderByValue: isOrderByValue });
		return firstValueFrom(
			this.http.get(this.apiUrl + "MasterSetup/GetDDLMasterSetupBySystemRegisNo/" + systemRegisNos + queryString),
		).then((res) => <Dropdown[]>res);
	}

	getMasterSetupBySystemRegisNo(systemRegisNos: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "MasterSetup/GetMasterSetupBySystemRegisNo/" + systemRegisNos),
		).then((res) => <any[]>res);
	}

	getHospitalSSOGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Common/GetHospitalSSODataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}
	getHospitalGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Common/GetHospitalDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}
	getMasMasterSetupDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Common/GetMasMasterSetupDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getDDLLeaveTypeByLeaveCategoryId(leaveCategoryId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLLeaveTypeByLeaveCategoryId/" + leaveCategoryId),
		).then((res) => <Dropdown[]>res);
	}
	getDDLLeaveTypeByLeaveCategoryIdByEmployeeId(leaveCategoryId: number, employeeId) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "Common/GetDDLLeaveTypeByLeaveCategoryIdByEmployeeId/" + leaveCategoryId + "/" + employeeId,
			),
		).then((res) => <Dropdown[]>res);
	}

	getDDLCardstatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLCardstatus")).then((res) => <Dropdown[]>res);
	}

	getDDLLockerstatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLLockerstatus")).then((res) => <Dropdown[]>res);
	}

	getDDLLockerstatusSearch() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLLockerstatusSearch")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLCardstatusSearch() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLCardstatusSearch")).then((res) => <Dropdown[]>res);
	}

	getDDLProvidentFundConfigConditionType() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProvidentFundConfigConditionType")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLPayslipChannelType() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLPayslipChannelType")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLProvidentFundStatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProvidentFundStatus")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLRoundingConfig() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLRoundingConfig")).then((res) => <Dropdown[]>res);
	}

	getDDLAccountCodeType() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLAccountCodeType"));
	}

	getDDLPayrollStatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLPayrollStatus")).then((res) => <Dropdown[]>res);
	}

	getDDLPaymentPeriod(isAllPeriod: boolean = false) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLPaymentPeriod/" + isAllPeriod)).then(
			(res) => <PaymentPeriod[]>res,
		);
	}

	getDDLAssesseement(model: AssessmentScoreSearch) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "AssessmentScore/GetDDLAssesseement", body, this.httpOption);
	}

	getDDLPaymentPeriodByEmployeeId(employeeId: number, isAllPeriod: boolean = false, isNextYear: boolean = false) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "Common/GetDDLPaymentPeriodByEmployeeId/" + employeeId + "/" + isAllPeriod + "/" + isNextYear,
			),
		).then((res) => <PaymentPeriod[]>res);
	}

	getDDLEmployeeFinancialAccumulateByPeriod(employeeId: number, isAllPeriod: boolean = false) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLEmployeeFinancialAccumulateByPeriod/" + employeeId),
		).then((res) => <PaymentPeriod[]>res);
	}

	getDDLProvidentFundPlanByProvidentFundTypeId(providentFundTypeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLProvidentFundPlanByProvidentFundTypeId/" + providentFundTypeId),
		).then((res) => <Dropdown[]>res);
	}

	getDDLProvidentFundPlan() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProvidentFundPlan")).then((res) => <Dropdown[]>res);
	}

	getDDLEmployeeCardByCompanyId(companyId: number, cardCode: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLEmployeeCardByCompanyId/" + companyId + "/" + cardCode),
		).then((res) => <Dropdown[]>res);
	}

	getDDLYearRange(yearRange: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLYearRange/" + yearRange)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLYear() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Calendar/GetDDLYear")).then((res) => <Dropdown[]>res);
	}

	getDDLYearAccumulate(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLYearAccumulate/" + employeeId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLAssessmentMasterSetsBySetId(assessmentSetId: number, employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLAssessmentMasterSetsBySetId/" + assessmentSetId + "/" + employeeId),
		).then((res) => <Dropdown[]>res);
	}

	getDDLFamilyByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeFamily/GetDDLFamilyByEmployeeId/" + employeeId)).then(
			(res) => <EmployeeFamilyDDL[]>res,
		);
	}

	getUserIdByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "TimeAttendance/GetUserIdByEmployeeId/" + employeeId)).then(
			(res) => <string>res,
		);
	}

	validateBarcode(barcodeNo: string) {
		var body = JSON.stringify(barcodeNo);
		return this.http.post(this.apiUrl + "Login/ValidateBarcode", body, this.httpOption);
	}

	sendTimeAttendanceEmailByTemplate() {
		return this.http.post(this.apiUrl + "Service/SendTimeAttendanceEmailByTemplate", this.httpOption);
	}

	getYearCutoff() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetYearCutoff")).then((res) => <number>res);
	}

	getPolicy() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetPolicy")).then((res) => <any>res);
	}

	getPolicyByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetPolicyByEmployeeId/" + employeeId)).then(
			(res) => <any>res,
		);
	}

	getApplicationConfigByTenant() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetApplicationConfigByTenant/")).then((res) => <any>res);
	}

	getTaxCalculateCondition() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetTaxCalculateCondition")).then(
			(res) => <DropdownDataItem[]>res,
		);
	}

	getTavi50ByYear(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetTavi50ByYear/" + employeeId)).then(
			(res) => <PaymentPeriod[]>res,
		);
	}

	getPeriodsCalculateNo(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetPeriodsCalculateNo/" + employeeId)).then(
			(res) => <any>res,
		);
	}

	getDDLFinancialItemByCompanyId(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLFinancialItemByCompanyId/" + companyId)).then(
			(res) => <FinancialItemDDLModel[]>res,
		);
	}

	getDDLInterestType() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLInterestType/")).then((res) => <Dropdown[]>res);
	}
	getDDLWelfareLoanStatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLWelfareLoanStatus/")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLAssessmentSet() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLAssessmentSet")).then((res) => <Dropdown[]>res);
	}
	getDDLPatternReport() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLPatternReport")).then((res) => <Dropdown[]>res);
	}
	getDDLAssessmentSetByEmpId(setId: number, employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLAssessmentSet/" + setId + "/" + employeeId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLComponentReportGroup() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLComponentReportGroup")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLPatientType() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLPatientType")).then((res) => <Dropdown[]>res);
	}

	getDDLAssessmentMasterByAssessmentTypeId(assessmentTypeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Common/GetDDLAssessmentMasterByAssessmentTypeId/" + assessmentTypeId),
		).then((res) => <Dropdown[]>res);
	}

	getDDLWorkinType() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLWorkinType")).then((res) => <Dropdown[]>res);
	}
	getDDLAccountingSystem() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLAccountingSystem")).then((res) => <Dropdown[]>res);
	}

	getDateCycle(dateStart: string, dateEnd: string, iterationType: number) {
		this.changeDateJSON();
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "Common/GetDateCycle/" + dateStart + "/" + dateEnd + "/" + iterationType),
		).then();
	}

	GetAssessmentObjectiveDDL(setId: number, objectiveType: number) {
		return this.http.get<Dropdown[]>(this.apiUrl + "Common/GetAssessmentObjectiveDDL/" + setId + "/" + objectiveType);
	}
	getPathAttachment(model) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Common/GetPathAttachment", model, httpOptions);
	}
	getPathVenioAttachment(model) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post<any>(`${this.apiVenioUrl}storage/v1/Storage/UploadFile/40016`, model, httpOptions),
		).then((res) => res);
	}

	getPathAttachmentEmployee(model) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Common/GetPathAttachmentEmployee", model, httpOptions);
	}
	activeEmployeePicture(model) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.put<any>(this.apiUrl + "Common/ActiveEmployeePicture", model, httpOptions);
	}

	getPathAttachmentForProvidentFund(model) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Common/GetPathAttachmentForProvidentFund", model, httpOptions);
	}

	uploadAttachmentToGCP(url: string, blob: Blob) {
		const httpOptions = {
			headers: {
				"x-goog-encryption-algorithm": "AES256",
				"x-goog-encryption-key": localStorage.getItem("storageEncryptionKey"),
				"x-goog-encryption-key-sha256": localStorage.getItem("storageEncryptionKeyHash"),
			},
		};
		return this.http.put<any>(url, blob, httpOptions);
	}

	GetOrganizationsNode() {
		return this.http.get<any>(this.gofiveApiUrl + "v1/OrganizationsNode/");
	}

	getPeople(searchFullname?: string) {
		return this.http.get<any>(
			this.gofiveApiUrl +
				"v1.1/ProfilesLite?" +
				(searchFullname ? "searchFullname=" + searchFullname + "&" : "") +
				"employeeStatus=1&employeeStatus=2&employeeStatus=4",
		);
	}
	getProfilesLite(queryString = "employeeStatus=1&employeeStatus=2&employeeStatus=4") {
		return this.http.get<any>(this.gofiveApiUrl + "v1.1/ProfilesLite?" + queryString);
	}
	getDDLShiftByTenantId() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLShiftByTenantId")).then(
			(res) => <Dropdown[]>res,
		);
	}
	getAllowPermissionPayroll(empId: number, companyId: number = 0) {
		return this.http.get<PermissionPayroll>(
			this.apiUrl + "Common/GetAllowPermissionPayroll/" + empId + "/" + companyId,
		);
	}

	getDDLProvidentFundBankPaymentTemplated() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLProvidentFundBankPaymentTemplated")).then(
			(res) => <any[]>res,
		);
	}

	getApplicantInterview() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetApplicantInterview")).then((res) => <any[]>res);
	}

	getRecruitmentById(applicantId: string, positionId: number, revisionNo: number, companyId: number) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl +
					"Common/GetRecruitmentById/" +
					applicantId +
					"/" +
					positionId +
					"/" +
					revisionNo +
					"/" +
					companyId,
			),
		).then((res) => <any[]>res);
	}

	getRunningEmployeeNoByTenantId() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetRunningEmployeeNoByTenantId/")).then(
			(res) => <any>res,
		);
	}

	updateRunningNumberEmployeeNo(model: any) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Common/UpdateRunningNumberEmployeeNo", model, httpOptions);
	}

	generateBase32RandomKey(length) {
		return this.http.get<any>(`${this.apiUrl}common/generate-key/${length}`);
	}
	getEncriptKey2FA() {
		return this.http.get<any>(`${this.apiUrl}commons/two-factor-authentications/encript-key`);
	}
	checkAuth2fa(key: string) {
		var queryString = this.sharing.getQueryString({ key: key });
		return this.http.get<any>(`${this.apiUrl}commons/two-factor-authentications/decrypt-key${queryString}`);
	}

	generateCodeName() {
		return this.http.get<string>(`${this.apiUrl}Common/GenerateCodeName`);
	}

	getAddress(taxId: string) {
		return firstValueFrom(this.http.get<any>(`${this.apiVenioUrl}customer/v2/customer/tax-search?text=${taxId}`));
	}

	decryptAssessmentWebview(token: string) {
		let httpOption = {
			headers: new HttpHeaders({ "Content-Type": "application/json", "X-API-KEY-EMPEO": environment.empeoAPIKey }),
		};
		return this.get<any>(`Common/DecryptAssessmentWebview?token=${token}`, null, ApiUrl.empeo, httpOption);
	}

	getDDLMasWelfareHasCondition() {
		return firstValueFrom(this.http.get(`${this.apiUrl}Common/GetDDLMasWelfareHasCondition`)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLMasWelfareByCompanyId(companyId: number) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}Common/GetDDLMasWelfareByCompanyId?companyId=${companyId}`),
		).then((res) => <Dropdown[]>res);
	}

	getHospitalSSODDL(hospitalSearch = "", hospitalId = "", hospitalIdTwo = "", hospitalIdThree = "") {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl +
					`Common/GetHospitalSSODDL?hospitalSearch=${hospitalSearch}&hospitalId=${hospitalId}&hospitalIdTwo=${hospitalIdTwo}&hospitalIdThree=${hospitalIdThree}`,
			),
		).then((res) => <any[]>res);
	}

	getLeaveTypeByCompanyId(companyId?: number) {
		var param = companyId ? `?companyId=${companyId}` : "";
		return firstValueFrom(this.http.get(this.apiUrl + `v1/leavetype${param}`)).then((res) => <ApiResult>res);
	}

	getDDLSysBanks(bankName, isProvidentFund?: boolean) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					`Common/GetDDLSysBanks/?bankName=${bankName}${isProvidentFund ? "&isProvidentFund=" + isProvidentFund : ""}`,
			),
		).then((res) => <Dropdown[]>res);
	}

	addSysBank(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Common/AddSysBank", body, httpOptions);
	}

	getDDLSysBanksByCompanyId(companyId?: number) {
		return firstValueFrom(this.http.get(this.apiUrl + `Common/GetDDLSysBanksByCompanyId?companyId=${companyId}`)).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLMasWelfareByTenantId() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLMasWelfareByTenantId")).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLShiftChangeCondition() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLShiftChangeCondition")).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDateTimeShiftConditionByCompany() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDateTimeShiftConditionByCompany"));
	}
	getDDLProcessActionPunishment() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProcessActionPunishment")).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLProcessActionPunishmentStatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLProcessActionPunishmentStatus")).then(
			(res) => <Dropdown[]>res,
		);
	}
	getDDLSysWorkinSitesByCompanyId(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLSysWorkinSitesByCompanyId/" + companyId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLPaymentAccount(companyId: number, bankId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "companies/" + companyId + "/banks/" + bankId)).then(
			(res) => <any>res,
		);
	}

	getCompanyBranchGroupIsSocById(year: number, companyId: number, periodCalculateNo: number = 0) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + `v2/payroll/branch/${companyId}/${year}?isSoc=${true}&periodCalculateNo=${periodCalculateNo}`,
			),
		).then((res) => <any>res);
	}

	getCompanyBranchsIsSocById(year: number, companyId: number, periodCalculateNo: number = 0) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + `payroll/branch/${companyId}/${year}?isSoc=${true}&periodCalculateNo=${periodCalculateNo}`,
			),
		).then((res) => <any>res);
	}

	getCompanyBranchsById(year: number, companyId: number, periodCalculateNo: number = 0) {
		return firstValueFrom(
			this.http.get(this.apiUrl + `payroll/branch/${companyId}/${year}?periodCalculateNo=${periodCalculateNo}`),
		).then((res) => <any>res);
	}

	getDDLMasSkill() {
		return this.http.get<any[]>(this.apiUrl + "Common/GetDDLMasSkill");
	}

	getEmployeeSkillById(employeeId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "v1/employee-skills?employeeId=" + employeeId)).then(
			(res) => <any>res,
		);
	}
	GetSysModulesByModuleType(type: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "common/module?type=" + type)).then((res) => <Dropdown[]>res);
	}

	checkQueueInProgress(type: number) {
		return firstValueFrom(this.http.get(this.apiUrl + `common/queues/${type}/in-progress`)).then((res) => <boolean>res);
	}
	getDDLWelfareEmployeeResponsibilitiesByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get<any>(`${this.apiUrl}common/welfare-responsibilities/${employeeId}`)).then(
			(res) => <Dropdown[]>res,
		);
	}

	_2faVerificationstatus: Subject<boolean> = new Subject<boolean>();
	current2faVerificationstatus = this._2faVerificationstatus.asObservable();

	twoFactorAuthVerificationstatus() {
		const key = sessionStorage.getItem(SessionStorageKey.TwoFA);
		if (isNullOrUndefined(key)) {
			return this._2faVerificationstatus.next(false);
		}
		this.checkAuth2fa(key)
			.pipe(first())
			.subscribe((res) => {
				return this._2faVerificationstatus.next(res.data);
			});
	}

	clear2faVerificationstatus() {
		this._2faVerificationstatus.next(null);
	}

	encriptKey2FA: EncriptKey2FA;
	setSessionStorage2FA(): Observable<any> {
		return this.getEncriptKey2FA().pipe(
			map((res) => {
				this.encriptKey2FA = res.data;
				sessionStorage.setItem(SessionStorageKey.TwoFA, res.data.key);
				return;
			}),
		);
	}
}
class EncriptKey2FA {
	key: string;
	iv: string;
}
