import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

@Injectable()
export class RecruitmentService {
	constructor(
		private http: HttpClient,
		@Inject("EMCONNECT_API_URL") private emconnectApiUrl: string,
	) {}
	getRecruitmentByApplicantNo(applicantNo: string) {
		return firstValueFrom(
			this.http.get<any>(`${this.emconnectApiUrl}Recruitment/GetRecruitmentByApplicantNo/${applicantNo}`),
		);
	}
}
