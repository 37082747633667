export class SysComponent {
	componentId: number;
	parentId: number;
	levels: number;
	label: string;
	label_EN: string;
	functionCode: string = "";
	functionCaption: string = "";
	functionCaption_EN: string;
	screenSequence: number;
	path: string;
	pathTutorial: string;
	icon: string;
	isActive: boolean;
	componentPermission: string[];
	msgs: string;
	parentName: string;
	parentName_EN: string;
	permissionName: string;
	status: string;
	status_EN: string;
	modifiedBy: string;
	dateModified: Date;
	isConfigured: boolean;
	warningText: string;
	warningText_EN: string;
	requireComponentId: number;
	displayRequireComponent: boolean;
	requireComponentName: string;
	requireComponentName_EN: string;
	tutorialSequence: number;
	componentPicture: string;
	configGroupId: number;
	welcomePath: string;
	isModified: boolean;
}
