import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ApiResult } from "../models/api-result.model";
// import { Dropdown } from "src/app/shared/models/dropdown.model";

@Injectable()
export class MasTagsService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getMasTagsByCategoryId(id: Number) {
		return firstValueFrom(this.http.get<any>(`${this.apiUrl}v1/MasTag/mas-tags?categoryId=${id}`)).then((res) => {
			if (res === null) {
				return <ApiResult>{ result: false, message: "No content", data: null };
			}
			return <ApiResult>res;
		});
	}

	addMasTags(tag) {
		var body = JSON.stringify(tag);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post<any>(this.apiUrl + "v1/MasTag/mas-tags", body, httpOptions)).then(
			(res) => <ApiResult>res,
		);
	}
}
