import { DatePipe, NgIf, NgClass, NgStyle, NgTemplateOutlet, NgFor, AsyncPipe } from "@angular/common";
import { HttpHeaders } from "@angular/common/http";
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AssetsPipe, Attachment, Configuration, DateFormat, PhraseProject } from "@gofive/angular-common";
import { DialogInformationModel, DialogService, DialogModule } from "@gofive/design-system-dialog";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Subscription, debounceTime, firstValueFrom } from "rxjs";
import { Package } from "src/app/shared/enum/package.enum";
import { DefaultFilter } from "src/app/shared/template/people-picker/people-picker.component";
import { DataSharingService, UserControlService } from "../../../core";
import { OrganizationService } from "../../../master/organization/shared/organization.service";
import { TenantSetupService } from "../../../setting/tenant-setup/shared/tenant-setup.service";

import { FinancialCategory } from "../../../shared/enum/financial-category.enum";
import { Gender, GenderEmconnect, Title } from "../../../shared/enum/gender.enum";
import { MasterSetup, MasterTypeEnum } from "../../../shared/enum/master-setup.enum";
import { Module, ModuleWorkflow } from "../../../shared/enum/module.enum";
import { PageType } from "../../../shared/enum/page-type.enum";
import { RecruitmentStatus } from "../../../shared/enum/recruitment-status-enum";
import { ApiResult } from "../../../shared/models/api-result.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { Permission } from "../../../shared/models/permission.model";
import { CommonService } from "../../../shared/service/common.service";
import { isNull, isNullOrUndefined, isUndefined, SharingService } from "../../../shared/sharing.service";
import { EmployeeAddress } from "../../employee-address/shared/employee-address.model";
import { EmployeeAddressService } from "../../employee-address/shared/employee-address.service";
import { EmployeeContactPerson } from "../../employee-address/shared/employee-contactperson.model";
import { EmployeeFinancialService } from "../../employee-payroll/shared/employee-financial.service";
import { Employee, SearchPeoplePickerModel } from "../../shared/employee-movements.model";
import { EmployeeMovementsService } from "../../shared/employee-movements.service";
import { EmployeeService } from "./../shared/employee.service";
import { CompanyProfileService } from "src/app/master/company-profile/shared/company-profile.service";
import { AttachmentViewerService, UploadFileComponent, AttachmentModule } from "@gofive/design-system-attachment";
import { UploadFileService } from "src/app/shared/upload.service";
import { EmployeeStatus } from "src/app/shared/enum/employee-status.enum";
import { EmployeeWorkInformationService } from "../../employee-work-information/shared/employee-work-information.service";
import { ProcessAction } from "src/app/shared/enum/process-action.enum";
import { ChartType } from "src/app/shared/enum/chart-type.enum";
import { Movement } from "src/app/shared/enum/movement.enum";
import { EmployeeMovementService } from "../../employee-movement/shared/employee-movement.service";
import { InfoBarType, SelectedEventArgs, BaseModule } from "@gofive/design-system-base";
import { DiscardChange, DiscardChangeModel } from "src/app/shared/service/discard-change";
import { WorkflowSearch } from "src/app/setting/workflow/shared/workflow.model";
import { WorkflowService } from "src/app/setting/workflow/shared/workflow.service";
import { DataTableDirective } from "angular-datatables";
import { MessageWarningType } from "src/app/shared/enum/message-warning-type.enum";
import { LocalStorageKey } from "src/app/shared/enum/local-storage-key";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { ToastDataModel } from "@gofive/design-system-toast/lib/models/toast.model";
import { CustomFieldService } from "src/app/shared/service/custom-field.service";
import { DropdownSelectMode, DropdownModule } from "@gofive/design-system-dropdown";
import { OnboardingNewEmployeeService } from "src/app/onboarding-new-employee/shared/onboarding-new-employee.service";
import { environment } from "src/environments/environment";
import { ThaiDatePipe } from "../../../shared/pipe/thai-date.pipe";
import { ConvertfieldsDDLPipe } from "../../../shared/pipe/convertfields-ddl.pipe";
import { MovementSidebarAddComponent } from "../../../movement/movement-sidebar-add/movement-sidebar-add.component";
import { UpgradePlanComponent } from "../../../shared/component/upgrade-plan/upgrade-plan.component";
import { EmployeeCustomFieldComponent } from "../../../shared/component/employee-custom-field/employee-custom-field.component";
import { ButtonModule } from "@gofive/design-system-button";
import { InfoBarComponent } from "../../../shared/component/info-bar/info-bar.component";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { LoadingViewComponent } from "../../../shared/template/loading-view/loading-view.component";
import { InfoTooltipComponent } from "../../../shared/template/info-tooltip/info-tooltip.component";
import { SelectionModule } from "@gofive/design-system-selection";
import { CalendarModule } from "@gofive/design-system-calendar";
import { KeyFilterModule } from "primeng/keyfilter";
import { InputModule } from "@gofive/design-system-input";
import { FileUploadModule } from "primeng/fileupload";
import { PositionAddComponent } from "../../../master/position/position-add/position-add.component";
import { SidebarModule } from "@gofive/design-system-navigation";
import { PeoplePickerComponent } from "../../../shared/template/people-picker/people-picker.component";
import { LanguageLocal } from "src/app/shared/enum/language-type.enum";
import { EmployeeSocialSecurity } from "../../employee-social-security/shared/employee-social-security.model";
import { EmployeeSocialSecurityService } from "../../employee-social-security/shared/employee-social-security.service";
import { DropdownDataItem } from "src/app/shared/models/dropdown-data-item.model";
export enum EditType {
	edit = "1",
	movement = "2",
}

@Component({
	selector: "app-employee-add",
	templateUrl: "./employee-add.component.html",
	styleUrls: ["./employee-add.component.scss"],
	standalone: true,
	imports: [
		NgIf,
		PeoplePickerComponent,
		FormsModule,
		SidebarModule,
		PositionAddComponent,
		NgClass,
		NgStyle,
		NgTemplateOutlet,
		ReactiveFormsModule,
		FileUploadModule,
		DropdownModule,
		InputModule,
		KeyFilterModule,
		CalendarModule,
		SelectionModule,
		BaseModule,
		NgFor,
		InfoTooltipComponent,
		LoadingViewComponent,
		AttachmentModule,
		TooltipModule,
		InfoBarComponent,
		ButtonModule,
		EmployeeCustomFieldComponent,
		DialogModule,
		UpgradePlanComponent,
		MovementSidebarAddComponent,
		AsyncPipe,
		DatePipe,
		ConvertfieldsDDLPipe,
		TranslateModule,
		ThaiDatePipe,
	],
})
export class EmployeeAddComponent implements OnInit, DiscardChange {
	currentShift: any;
	tmpShiftId: number;
	isShowInfo: boolean;
	get EditType() {
		return EditType;
	}
	public get DateFormat() {
		return DateFormat;
	}
	@Input() isFromMobile: boolean = false;
	@Input() showPrevButton: boolean = false;
	public accepted: boolean = false;
	@ViewChild("footerSelfService") footerSelfService: ElementRef;
	@HostListener("window:navigate")
	public canDeactivate(): DiscardChangeModel {
		var isMovement = localStorage.getItem(LocalStorageKey.renewal) == "true";
		let discardCM: DiscardChangeModel = new DiscardChangeModel();
		discardCM.titleDialog = "cancel-add-employee";
		discardCM.isDeactive = true;

		if (isMovement) {
			discardCM.isDeactive = true;
			return discardCM;
		}
		if (
			this.formEmployeeFirstPage.dirty ||
			this.formEmployeeSecondPage.dirty ||
			this.formEmployeeThirdPage.dirty ||
			this.formEmployeeFourthPage.dirty
		) {
			discardCM.isDeactive = false;
		}
		if (this.currentEmployeeIndex >= 4 || (!this.userInfo?.hasPayroll && this.currentEmployeeIndex == 3)) {
			discardCM.isDeactive = true;
		}
		return discardCM;
	}

	private language$: Subscription;
	@ViewChild("fileUpload") fileUpload: any;
	@ViewChild("upload") upload: UploadFileComponent;

	@Input() isEdit: boolean = false;
	@Input() selfUpdate: boolean = false;
	@Input() fullTemPlate: boolean = true;
	@Input() isAdditionalField: boolean = false;
	@Output() statusEvent = new EventEmitter<boolean>();
	@Output() selfUpdateEvent = new EventEmitter<any>();
	@Output() errorMessage = new EventEmitter<any>();
	@Output() additionalFieldChanged = new EventEmitter<any>();
	@Input() isAddress: boolean = false;
	@Input() isWorkInformation: boolean = false;
	@Input() isFinished: boolean = false;
	@Input() isMe: boolean = false;
	@Input() module: string = null;
	@Input() isSocialSecurity: boolean = false;
	// @Output() statusEvent = new EventEmitter<boolean>();
	// @Output() selfUpdateEvent = new EventEmitter<any>();

	public colorFlame: string = "#E2E2E2";
	public attachment: any[] = [];
	//#region Variable
	public form: UntypedFormGroup;
	public formEmployeeFirstPage: UntypedFormGroup;
	public formEmployeeSecondPage: UntypedFormGroup;
	public formEmployeeThirdPage: UntypedFormGroup;
	public formEmployeeFourthPage: UntypedFormGroup;
	public formEmployeeSSO: UntypedFormGroup;
	public formCompare: UntypedFormGroup;
	public userInfo: any = null;
	public files: any[] = [];
	public uploadData: any[] = [];
	public isShiftStartDate: boolean = false;
	public skinColorDDL: Dropdown[];
	public religionDDL: Dropdown[];
	public marriageStatusDDL: Dropdown[];
	public militaryStatusDDL: Dropdown[];
	public bloodTypesDDL: Dropdown[];
	public nationalityDDL: Dropdown[];
	public districtDDL: Dropdown[] = [];
	public nationalitySSODDL: DropdownDataItem[];
	public districtDDL_ca: Dropdown[] = [];
	public employeeStatusDDL: Dropdown[];
	public employmentTypesDDL: Dropdown[];
	private searchPeoplePickerModel: SearchPeoplePickerModel = new SearchPeoplePickerModel();
	private getPeoplePicker$: Subscription;
	listOfEmployees: any[] = [];

	public typeCardDDL: Dropdown[] = [];
	public reasonToLeaveDDL: Dropdown[] = [];
	public hospitalDDL: Dropdown[] = [];
	public isSubmitSSO: boolean = false;

	public sysBankDDL: Dropdown[];
	public titlesDDL: Dropdown[];
	public ranksDDL: Dropdown[];
	public workLocationDDL: any[] = [];

	public companyDDL: Dropdown[];
	public orgLevelOneDDL: Dropdown[];
	public orgLevelTwoDDL: any[];
	public orgLevelThreeDDL: any[];
	public orgLevelFourDDL: any[];
	public orgLevelFiveDDL: any[];
	public orgLevelSixDDL: any[];
	public employeeContactPerson: EmployeeContactPerson = new EmployeeContactPerson();

	public currentEmployeeIndex: number = 1;

	private subscription: Subscription;
	private onAdd$: Subscription;
	private currentUserInfo$: Subscription;
	private onEdit$: Subscription;
	public accessTokenByNewEmployeeId$: Subscription;
	public newEmployeeStatus: EmployeeStatus = EmployeeStatus.waitingStartWork;

	get MessageWarningType() {
		return MessageWarningType;
	}
	public confirmation: any;
	public addConfirmation: any;
	public editConfirmation: any;
	public saveFinancialConfirm: any;
	public rankNo: any = null;
	public companyId: any = null;
	public orgLevelOne: any = null;
	public orgLevelFour: any = null;
	public orgLevelFive: any = null;
	public orgLevelSix: any = null;
	public bloodGroup: any = null;
	public nationalityId: any = null;
	public skinColorId: any = null;
	public religionId: any = null;
	public raceId: any = null;
	public employmentType: any = null;
	public titleId: any = null;
	public workLocationId: any = null;
	public employeeStatus: any = null;
	public marriageStatus: any = null;
	public militaryStatus: any = null;

	public dateIdCardIssue: any = null;
	public dateIdCardExpire: any = null;
	public datePassportIssue: any = null;
	public datePassportExpire: any = null;
	public workPermitNo: any = null;
	public dateWorkPermitIssue: any = null;
	public dateWorkPermitExpire: any = null;
	public isUploadingimpPic: boolean = false;
	public shiftDDL: any[] = [];
	public positionDDL: Dropdown[] = [];
	public calendars: any[] = [];
	public paymentMethodDDL: any[] = [];
	public organizeNodeDDL: any[];
	public listPeople: any[] = [];
	public provinceDDL_ca: Dropdown[] = [];
	public image: any = this.defaultPic;
	public originalPicture: any;
	public pictureType: string = "";
	public userIdValidaition: boolean = false;

	public language: string;
	public presentYear: number = null;
	public id: number;
	public gender: any = 1;
	public isActive: boolean = true;
	public hasPicture: boolean = false;
	public validDate: boolean = true;
	public isReplaceEmployee: boolean = false;
	public isForeigner: boolean = false;
	public replaceEmployeeId: any = null;
	public employeeNo: string;
	public emailAddress: string = "";
	public emailAddressPersonal: string = "";
	public totalUsers: number;
	public sysTenant: any;
	public isSelfUpdate: boolean = false;
	public convertEmployee: boolean = false;
	public applicantInformation: any;
	public currentPaymentType: number;
	public isChangePaymentType: boolean = false;
	public isDuplicateEmployeeNo: boolean = false;
	public resignApiError: boolean = false;
	public warningBoxMessage: string = "";

	public currentDate: Date = new Date();

	public arrGender: any[] = [
		{ name: "emp001_male", id: Gender.male },
		{ name: "emp001_female", id: Gender.female },
		{ name: "emp001_other", id: Gender.other },
	];

	public replaceSelected: any[] = [
		{ name: "emp001_new", id: false },
		{ name: "emp001_replace", id: true },
	];

	public foreignerSelected: any[] = [
		{ name: "emp001_domestic", id: false },
		{ name: "emp001_foreigner", id: true },
	];

	public recordSelected: any[] = [
		{ name: "emp001_t04_record", id: true },
		{ name: "emp001_t04_dont_record", id: false },
	];

	public isCashOption: any[] = [
		{ name: "emp001_t14_bank", id: false },
		{ name: "emp001_t14_cash", id: true },
	];

	public fieldsDDL = { text: "name", value: "id" };
	public fieldsDDL_EN = { text: "name_EN", value: "id" };

	public fieldsMasterTypeDDL = { text: "typeName", value: "typeId" };
	public fieldsMasterTypeDDL_EN = { text: "typeNameEn", value: "typeId" };

	public fieldsHospitalDDL = { text: "name", value: "hospitalId" };
	public fieldsHospitalDDL_EN = { text: "name_EN", value: "hospitalId" };

	public fieldsNationalityDDL = { text: "text", value: "value" };
	public fieldsNationalityDDL_EN = { text: "text_EN", value: "value" };

	public peopleFields = { text: "fullname", value: "employeeId", picture: "pictureURL", detail: "positionName" };
	public peopleFields_EN = {
		text: "fullname_EN",
		value: "employeeId",
		picture: "pictureURL",
		detail: "positionName_EN",
	};

	public empFields = { text: "fullName", value: "employeeId", picture: "pictureURL", detail: "positionName" };
	public empFields_EN = {
		text: "fullName_EN",
		value: "employeeId",
		picture: "pictureURL",
		detail: "positionName_EN",
	};

	public fieldsBranchSSODDL = { text: "text", value: "id" };
	public fieldsBranchSSODDL_EN = { text: "text_EN", value: "id" };
	public branchSSO_DDL: any[] = [];

	public fieldsOrg = {
		text: "text",
		value: "organizationId",
		children: {
			name: "children",
			fields: { text: "text", value: "organizationId" },
		},
	};
	public fieldsOrg_EN = {
		text: "name_EN",
		value: "organizationId",
		children: {
			name: "children",
			fields: { text: "name_EN", value: "organizationId" },
		},
	};

	public filedOrganize = {
		text: "name",
		value: "organizationId",
		children: "children",
	};

	public filedOrganizeEN = {
		text: "name_EN",
		value: "organizationId",
		children: "children",
	};

	public isSendEmailTutorial: boolean = false;
	public isSendEmailOnboarding: boolean = false;
	public dialogUpgradePlan: boolean = false;
	public isSetSocialSecurityBranch: boolean = false;
	public isSetKorTor20: boolean = false;
	public organization: any;
	public permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	public displayPeoplePicker: boolean = false;
	public replacedEmpName: string = "";
	public replacedEmpNameEN: string = "";
	public displayPositionAdd: boolean = false;
	public maxOrganizationLevel: number = 1;
	public authorizePosition: any;
	public employeeModel: Employee = new Employee();
	public oldDataOfEmployeeModel: Employee = new Employee();
	public employeeAddress: EmployeeAddress = new EmployeeAddress();
	public employeeAddressContact: EmployeeAddress = new EmployeeAddress();
	public employeeSocialSecurity: EmployeeSocialSecurity = new EmployeeSocialSecurity();
	public employeeContactPersonArray: EmployeeContactPerson[] = [];
	public provinceDDL: Dropdown[] = [];
	public relationShipDDL: Dropdown[] = [];
	public movementTypeDDL: any[] = [];
	public chkAddress: boolean = false;
	public isCash: any = false;
	public iomoAvatarPath: string = "assets/images/avatar/empeo/iomo-avatar.png";
	public iomoAvatarIMG: any;
	public employmentStatusResign: number = EmployeeStatus.resign;
	public employmentStatusWaitingWork: number = EmployeeStatus.waitingStartWork;
	public movementTypeResign: number = Movement.resign;
	public isDoneLoading = false;
	lockFilter: DefaultFilter[] = [
		{ index: 1 },
		{ index: 2 },
		{ index: 3 },
		{ index: 6 },
		{ index: 7 },
		{ index: 8 },
		{ index: 9 },
		{ index: 10, value: [1, 2, 4] },
		{ index: 11 },
		{ index: 12 },
		{ index: 13 },
	];
	public stepsModel = [
		{ label: "emp001_employee_info" },
		{ label: "emp001_address_tab" },
		{ label: "emp001_work_information_tab" },
		{ label: "mas010_salary" },
	];

	//#endregion
	public allowExtensions: string = ".jpg,.jpeg,.bmp,.png,.JPG,.JPEG,.BMP,.PNG";

	get isMobile() {
		return this.sharingService.isMobile();
	}

	get isSmallSizeMobile() {
		return this.sharingService.isSmallSizeMobile();
	}
	// get positionId() { return this.form.get('positionId').value };
	// get orgLevelThree() { return this.form.get('orgLevelThree').value };
	// get orgLevelTwo() { return this.form.get('orgLevelTwo').value };
	get recruitmentStatusEnum() {
		return RecruitmentStatus;
	}
	get packageEnum() {
		return Package;
	}
	get dateFormat() {
		return DateFormat;
	}

	public customFields: any[] = [];

	public regexPattern: RegExp = /[a-zA-Z0-9\s\u00C0-\u1FFF'-.]+/;

	constructor(
		private _dialogService: DialogService,
		private assetsPipe: AssetsPipe,
		private employeeAddressService: EmployeeAddressService,
		private datePipe: DatePipe,
		private employeeFinancialService: EmployeeFinancialService,
		private attachmentService: AttachmentViewerService,
		private employeeMovementsService: EmployeeMovementsService,
		public sharingService: SharingService,
		private uploadFileService: UploadFileService,
		private organizationService: OrganizationService,
		private fb: UntypedFormBuilder,

		private employeeService: EmployeeService,
		private commonService: CommonService,
		private router: Router,
		private userControlService: UserControlService,
		public data: DataSharingService,
		private translate: TranslateService,
		private _toastHelperService: ToastHelperService,
		private employeeWorkInformationService: EmployeeWorkInformationService,
		@Inject("DEFAULT_EMP_PIC") private defaultPic: string,
		private tenantSetupService: TenantSetupService,
		private companyProfileService: CompanyProfileService,
		private workflowService: WorkflowService,
		private employeeMovementService: EmployeeMovementService,
		private customFieldService: CustomFieldService,
		private onboardingNewEmployeeService: OnboardingNewEmployeeService,
		private employeeSocialSecurityService: EmployeeSocialSecurityService,
	) {
		Configuration.setLibConfig({
			phraseProject: PhraseProject.empeo,
		});
	}

	ngOnInit() {
		this.loadEmployees();
		this.isSaveAddress = false;
		this.saveWorkInfo = false;
		this.userControlService.authorizeControl(Module.MAS005).subscribe((auth) => {
			this.authorizePosition = auth;
		});

		this.iomoAvatarIMG = this.assetsPipe.transform(this.iomoAvatarPath);

		if (!this.isEdit) {
			this.employeeService
				.getTotalEmployee()
				.then((res) => {
					this.totalUsers = !isNullOrUndefined(res) ? res : 0;
					this.isSendEmailTutorial = true;
				})
				.then(() => {
					this.tenantSetupService.getSysTenantByTenantId().then((res) => {
						this.sysTenant = res;
						if (this.totalUsers >= this.sysTenant.limitUser) {
							this.router.navigate(["/employee"]);
						}
					});
				});
			this.getMasCustomField();
		}

		this.subscription = this.userControlService
			.authorizeControl(!isNullOrUndefined(this.module) ? this.module : Module.EMP001)
			.subscribe((auth) => {
				let self = this.selfUpdate ? this.selfUpdate : auth["allowEdit"];
				auth["allowEdit"] = this.isEdit ? self : auth["allowEdit"];
				auth["allowGrant"] = this.isEdit ? self : auth["allowGrant"];
				this.permission = this.userControlService.validatePermission(
					this.isEdit ? PageType.search : PageType.add,
					auth,
				);
			});

		//#region Form control
		this.formCompare = this.fb.group({
			movementTypeId: new UntypedFormControl("", Validators.required),
			documentNo: new UntypedFormControl(""),
			dateDocument: new UntypedFormControl(""),
			dateEffective: new UntypedFormControl("", Validators.required),
			upperSupervisorIdInput: new UntypedFormControl(""),
			remarks: new UntypedFormControl(""),
			compareDataList: new UntypedFormControl(""),
			isApprove: new UntypedFormControl(""),
			isWorkInfomation: new UntypedFormControl(""),
			processActionId: new UntypedFormControl(""),
			terminationReasonId: new UntypedFormControl(0, Validators.required),
		});
		this.formEmployeeFirstPage = this.fb.group({
			hasPicture: new UntypedFormControl(""),
			originalPicture: new UntypedFormControl(""),
			pictureType: new UntypedFormControl(""),
			titleId: new UntypedFormControl("", Validators.required),
			name: new UntypedFormControl("", [
				Validators.required,
				Validators.pattern(this.regexPattern),
				Validators.minLength(2),
			]),
			name_EN: new UntypedFormControl("", [
				Validators.required,
				Validators.pattern(this.regexPattern),
				Validators.minLength(2),
			]),
			surname: new UntypedFormControl("", [
				Validators.required,
				Validators.pattern(this.regexPattern),
				Validators.minLength(2),
			]),
			surname_EN: new UntypedFormControl("", [
				Validators.required,
				Validators.pattern(this.regexPattern),
				Validators.minLength(2),
			]),
			nickName: new UntypedFormControl("", Validators.pattern(this.regexPattern)),
			nickName_EN: new UntypedFormControl("", Validators.pattern(this.regexPattern)),
			dateOfBirth: new UntypedFormControl("", Validators.required),
			gender: new UntypedFormControl(null, Validators.required),
			isForeigner: new UntypedFormControl("", Validators.required),
			idCardNo: new UntypedFormControl(
				"",
				Validators.compose([Validators.required, Validators.minLength(13), Validators.maxLength(13)]),
			),
			dateWorkPermitIssue: new UntypedFormControl(""),
			dateWorkPermitExpire: new UntypedFormControl(""),
			dateContractExpired: new UntypedFormControl(""),
			passportNo: new UntypedFormControl(""),
			workPermitNo: new UntypedFormControl(""),

			raceId: new UntypedFormControl(""),
			nationalityId: new UntypedFormControl(""),
			religionId: new UntypedFormControl(""),
			marriageStatus: new UntypedFormControl(""),
			militaryStatus: new UntypedFormControl(""),
			bloodGroup: new UntypedFormControl(""),
			weightInKGS: new UntypedFormControl(""),
			heightInCM: new UntypedFormControl(""),
			remark: new UntypedFormControl(""),
		});

		this.formEmployeeSecondPage = this.fb.group({
			companyId: new UntypedFormControl("", Validators.required),
			departmentId: new UntypedFormControl("", Validators.required),
			positionId: new UntypedFormControl("", Validators.required),
			rankNo: new UntypedFormControl("", Validators.required),
			employeeNo: new UntypedFormControl("", [Validators.required, this.noEmptyStringValidator]),
			workLocationId: new UntypedFormControl(""),
			shiftId: new UntypedFormControl("", Validators.required),
			dateEmployment: new UntypedFormControl("", Validators.required),
			employeeStatus: new UntypedFormControl("", Validators.required),
			employmentType: new UntypedFormControl("", Validators.required),
			dateContractExpired: new UntypedFormControl(""),
			calendarId: new UntypedFormControl(""),
			driverNo: new UntypedFormControl(""),
			// 'emailAddress': new FormControl(''),
			telephoneNo: new UntypedFormControl(""),
			isReplaceEmployee: new UntypedFormControl(""),
			isAttendance: new UntypedFormControl("", Validators.required),
			upperSupervisorId: new UntypedFormControl(""),

			replaceEmployeeId: new UntypedFormControl(""),
			isCash: new UntypedFormControl("", Validators.required),
			bankId: new UntypedFormControl("", Validators.required),
			bankAccountNo: new UntypedFormControl("", Validators.required),
			paymentMethodId: new UntypedFormControl("", Validators.required),
			dateTermination: new UntypedFormControl(null),
			shiftStartDate: new UntypedFormControl(""),
		});

		this.formEmployeeThirdPage = this.fb.group({
			isCash: new UntypedFormControl("", Validators.required),
			bankId: new UntypedFormControl("", Validators.required),
			bankAccountNo: new UntypedFormControl("", Validators.required),
			paymentMethodId: new UntypedFormControl("", Validators.required),
			salary: new UntypedFormControl("", Validators.required),
		});

		this.formEmployeeFourthPage = this.fb.group({
			emailAddress: new UntypedFormControl(""),
			telephoneNo: new UntypedFormControl(""),
			emailAddressPersonal: new UntypedFormControl(""),
			mobileNo: new UntypedFormControl(""),
			lineId: new UntypedFormControl(""),
			address: new UntypedFormControl(""),
			provinceId: new UntypedFormControl(""),
			districtId: new UntypedFormControl(""),
			subdistrict: new UntypedFormControl(""),
			postalCode: new UntypedFormControl(""),
			// 'address': new FormControl('',  Validators.required),
			// 'provinceId' : new FormControl('',  Validators.required),
			// 'districtId' : new FormControl('',  Validators.required),
			// 'subdistrict' : new FormControl('',  Validators.required),
			// 'postalCode': new FormControl('',  Validators.required),
			chkAddress: new UntypedFormControl(""),

			address_ca: new UntypedFormControl(""),
			districtId_ca: new UntypedFormControl(""),
			provinceId_ca: new UntypedFormControl(""),
			postalCode_ca: new UntypedFormControl(""),
			subDistrict_ca: new UntypedFormControl(""),

			// 'address_ca': new FormControl('', Validators.required),
			// 'districtId_ca': new FormControl('', Validators.required),
			// 'provinceId_ca': new FormControl('', Validators.required),
			// 'postalCode_ca': new FormControl('', Validators.required),
			// 'subDistrict_ca': new FormControl('', Validators.required),

			contactPersonName: new UntypedFormControl(""),
			contactPersonSurname: new UntypedFormControl(""),
			contactPersonPhoneNo: new UntypedFormControl(""),
			contactPersonRelationShipId: new UntypedFormControl(""),
			isSendEmailTutorial: new UntypedFormControl(""),
			isSendEmailOnboarding: new UntypedFormControl(false),
		});

		this.formEmployeeSSO = this.fb.group({
			socialSecurityNo: new UntypedFormControl("", Validators.required),
			dateReceivedSSO: new UntypedFormControl(""),
			hospitalId: new UntypedFormControl(""),
			hospitalIdTwo: new UntypedFormControl(""),
			hospitalIdThree: new UntypedFormControl(""),
			evidenceOfRegistrationSSO: new UntypedFormControl(""),
			nationalitySSOId: new UntypedFormControl(""),
			branchId: new UntypedFormControl(""),
			rate: new UntypedFormControl(""),
			isSoc: new UntypedFormControl(false),
			typeCardSSO: new UntypedFormControl(""),
			terminationReasonSSOId: new UntypedFormControl(""),
			businessCode: new UntypedFormControl(""),
		});

		//#endregion

		//#region Call api

		if (!this.isWorkInformation && !this.isAdditionalField) this.loadMasterDDL();
		if (!this.isWorkInformation && !this.isAdditionalField) this.setDefault();

		this.presentYear = Number(this.commonService.getPresentYear());
		//#endregion
		this.subscription = this.data.currentEmpId.subscribe(async (data) => {
			this.id = data;
			if (this.isEdit) {
				this.loadEmployeeInformation();
				if (this.isAddress) {
					this.loadAddressInformation();
				} else if (this.isWorkInformation || localStorage.getItem(LocalStorageKey.renewal) == "true") {
					this.isWorkInformation = true;
					this.currentEmployeeIndex = 3;
				} else if (this.isSocialSecurity) {
					this.currentEmployeeIndex = 5;
				}
			}
			if (this.isSocialSecurity) {
				this.loadSSO();
			}
			if (this.currentEmployeeIndex == 1) this.loadEmployeePicture();
		});
		this.loadUserInfo();

		this.language$ = this.data.currentLanguage.subscribe((lang) => {
			this.language = lang;
		});
	}

	noEmptyStringValidator(control: UntypedFormControl) {
		if (control.value?.trim() === "") {
			return { noEmptyString: true };
		}
		return null;
	}

	getMasCustomField() {
		firstValueFrom(this.customFieldService.getAllMasCustomFields(true)).then((res) => {
			this.customFields = res.data || [];
		});
	}
	loadAddressInformation() {
		this.currentEmployeeIndex = 2;
		this.employeeAddressService.getEmployeeAddressByEmployeeId(this.id).then((res) => {
			this.employeeAddress = res.find((emp) => emp.addressType == 1);
			this.employeeAddressContact = res.find((emp) => emp.addressType == 2);

			this.employeeAddress = !isNullOrUndefined(this.employeeAddress) ? this.employeeAddress : new EmployeeAddress();
			this.employeeAddressContact = !isNullOrUndefined(this.employeeAddressContact)
				? this.employeeAddressContact
				: new EmployeeAddress();

			if (!isNullOrUndefined(this.employeeAddressContact)) {
				this.commonService.getDDLDistrictByProvinceId(this.employeeAddressContact.provinceId).then((result) => {
					this.districtDDL_ca = result;
				});
			}

			if (!isNullOrUndefined(this.employeeAddress)) {
				this.commonService.getDDLDistrictByProvinceId(this.employeeAddress.provinceId).then((result) => {
					this.districtDDL = result;
				});
			}
		});

		this.employeeAddressService.getEmployeeContactPersonByEmployeeId(this.id).then((emp) => {
			this.employeeContactPersonArray = !isNullOrUndefined(emp) ? emp : [];
			this.commonService.getMasterTypeByParentId(MasterTypeEnum.relationShip).then((res) => {
				this.relationShipDDL = res.data?.data;
				if (this.employeeContactPersonArray.length > 0) {
					this.employeeContactPerson = this.employeeContactPersonArray[0];
				}
			});
		});
	}

	public tmpEmployeeStatus: number;
	public employeeCurrentEmailAddress: string = null;

	loadEmployeeInformation() {
		var isOriginalPicture = false;
		this.employeeMovementsService
			.getEmployeeById(this.id, isOriginalPicture)
			.then((emp) => {
				this.employeeModel = emp;
				this.tmpShiftId = this.employeeModel.shiftId;
				this.changeForeigner({
					value: this.employeeModel.isForeigner,
				});
				if (this.employeeModel.idCardNo == "             ") {
					this.employeeModel.idCardNo = "";
				}
				this.checkPublicDomain();
				this.employeeCurrentEmailAddress = this.employeeModel.emailAddress;
				this.currentPaymentType = this.employeeModel.paymentType;
				this.tmpEmployeeStatus = this.employeeModel.employeeStatus;
				this.employeeModel.employmentType =
					this.employeeModel.employmentType > 0 ? this.employeeModel.employmentType : null;
				this.employeeModel.pictureFileName = this.employeeModel.pictureThumbnailURL;
				this.employeeModel.dateOfBirth = !isNullOrUndefined(this.employeeModel.dateOfBirth)
					? new Date(this.employeeModel.dateOfBirth)
					: null;
				this.employeeModel.dateWorkPermitExpire = !isNullOrUndefined(this.employeeModel.dateWorkPermitExpire)
					? new Date(this.employeeModel.dateWorkPermitExpire)
					: null;
				this.employeeModel.dateWorkPermitIssue = !isNullOrUndefined(this.employeeModel.dateWorkPermitIssue)
					? new Date(this.employeeModel.dateWorkPermitIssue)
					: null;
				this.image = !isNullOrUndefined(this.employeeModel.pictureMediumURL)
					? this.employeeModel.pictureMediumURL
					: this.image;
				this.formEmployeeFirstPage.get("titleId").patchValue(this.employeeModel?.titleId);
				this.employeeModel.isSoc = isNullOrUndefined(this.employeeModel.isSoc) ? false : this.employeeModel.isSoc;
			})
			.finally(() => {
				if (this.isWorkInformation && this.employeeModel) {
					this.employeeModel.dateEmployment = !isNullOrUndefined(this.employeeModel?.dateEmployment)
						? new Date(this.employeeModel?.dateEmployment)
						: null;
					this.employeeModel.dateTermination = !isNullOrUndefined(this.employeeModel.dateTermination)
						? new Date(this.employeeModel.dateTermination)
						: null;
					this.employeeModel.dateContractExpired = !isNullOrUndefined(this.employeeModel.dateContractExpired)
						? new Date(this.employeeModel.dateContractExpired)
						: null;
					const organizationId: number =
						this.employeeModel.orgLevelSix ??
						this.employeeModel.orgLevelSix ??
						this.employeeModel.orgLevelFive ??
						this.employeeModel.orgLevelFive ??
						this.employeeModel.orgLevelFour ??
						this.employeeModel.orgLevelFour ??
						this.employeeModel.orgLevelThree ??
						this.employeeModel.orgLevelThree ??
						this.employeeModel.orgLevelTwo ??
						this.employeeModel.orgLevelTwo ??
						null;
					this.loadDropdownWorkinformation(false);
					this.loadDropdownEmployeeStatus();
					this.loadPaymentType();
					this.loadMovementType();
					this.onChangedCash1(this.employeeModel.isCash);
					if (this.employeeModel.companyId) {
						this.loadBankDDL();
						this.loadShiftDDL();
						this.getDropdownBranchSSO(false);
					}
					if (this.employeeModel.isReplaceEmployee) {
						this.replacedEmpName = this.employeeModel.replaceEmployeeName;
						this.replacedEmpNameEN = this.employeeModel.replaceEmployeeName_EN;
					}
					this.employeeModel.departmentId = organizationId;
					this.oldDataOfEmployeeModel = { ...this.employeeModel };
					if (this.employeeModel.userId == null) {
						this.userIdValidaition = true;
					}
				}
			});
	}

	appoint: number = Movement.appoint;
	loadMovementType() {
		this.commonService.getDDLProcessActionByModule(ProcessAction.employeeModule).then((res) => {
			this.movementTypeDDL = res.filter((r) => r.id != this.appoint);
		});
	}
	loadDropdownEmployeeStatus() {
		this.commonService.getDDLEmployeeStatus().then((res) => {
			this.employeeStatusDDL = res;
		});
	}
	loadPaymentType() {
		this.commonService.getDDLPaymentMethod().then((res) => {
			this.paymentMethodDDL = res;
		});
	}

	loadEmployees() {
		this.searchPeoplePickerModel.organizeId = this.employeeModel.companyId;
		this.searchPeoplePickerModel.employeeStatusStr = [
			EmployeeStatus.probation,
			EmployeeStatus.contain,
			EmployeeStatus.waitingStartWork,
		].toString();
		this.getPeoplePicker$ = this.employeeService.getPeoplePicker(this.searchPeoplePickerModel).subscribe((res) => {
			this.listOfEmployees = res;
		});
	}

	loadEmployeePicture() {
		this.attachment = [];
		this.employeeMovementsService.getEmployeePictureByEmployeeId(this.id).then((res) => {
			var modelMap = [];
			res.data?.forEach((file) => {
				let name = file.IsIOMOPicture
					? file.pictureURL
					: file.pictureURL.split("/")[file.pictureURL.split("/").length - 1];
				modelMap.push({
					employeePictureId: file.employeePictureId,
					pictureURL: file.isIOMOPicture ? this.iomoAvatarIMG : file.pictureURL,
					filename: name,
					extension: `image/${name.split(".")[1]}`,
					publicUrl: file.isIOMOPicture ? this.iomoAvatarIMG : file.pictureURL,
					attachmentURL: file.isIOMOPicture ? this.iomoAvatarIMG : file.pictureURL,
					resourceUrl: file.isIOMOPicture ? this.iomoAvatarIMG : file.pictureURL,
					isIOMOPicture: file.isIOMOPicture,
				});
			});
			this.attachment = modelMap;
			this.isUploadingimpPic = false;
		});
	}
	useSameAddress1() {
		if (this.chkAddress) {
			this.employeeAddressContact.address = this.employeeAddress.address;
			this.employeeAddressContact.provinceId = this.employeeAddress.provinceId;
			this.districtDDL_ca = this.districtDDL;
			this.employeeAddressContact.districtId = this.employeeAddress.districtId;
			this.employeeAddressContact.subdistrict = this.employeeAddress.subdistrict;
			this.employeeAddressContact.postalCode = this.employeeAddress.postalCode;
			this.employeeAddressContact.phoneNo = this.employeeAddress.phoneNo;
		} else {
			this.employeeAddressContact.address = null;
			this.provinceDDL_ca = this.provinceDDL;
			this.districtDDL_ca = null;
			this.employeeAddressContact.provinceId = null;
			this.employeeAddressContact.districtId = null;
			this.employeeAddressContact.subdistrict = null;
			this.employeeAddressContact.postalCode = null;
			this.employeeAddressContact.phoneNo = null;
		}
	}

	educationDDL: any[] = [];
	setConvertEmployee() {
		this.convertEmployee = true;
		if (!this.employeeModel.employeeExperienceModels.length) {
			this.employeeModel.employeeExperienceModels = [];
		}
		if (!this.employeeModel.employeeEducationModels.length) {
			this.employeeModel.employeeEducationModels = [];
		}
		this.applicantInformation?.emsume?.experiences?.forEach((f) => {
			let tmpModel = { ...f };
			tmpModel.employeeExperienceId = 0;
			tmpModel.latestPosition = f.position;
			this.employeeModel.employeeExperienceModels.push(tmpModel);
		});
		if (this.applicantInformation?.emsume?.educations?.length) {
			this.commonService
				.getMasterTypeByParentId(MasterTypeEnum.education)
				.then((res) => {
					this.educationDDL = res.data?.data;
				})
				.then(() => {
					this.applicantInformation?.emsume?.educations?.forEach((edu) => {
						let tmpEducationModel = { ...edu };
						tmpEducationModel.educationDegreeId = this.educationDDL.find(
							(f) => (f.typeName = edu.educationName),
						)?.typeId;
						tmpEducationModel.employeeExperienceId = 0;
						this.employeeModel.employeeEducationModels.push(tmpEducationModel);
					});
				});
		}

		this.commonService.getDDLSysNamePrefixesByName("").then((resTitles) => {
			this.titlesDDL = resTitles;
			this.employeeModel.titleId = this.applicantInformationTitle();
			this.formEmployeeFirstPage.get("titleId").patchValue(this.employeeModel.titleId);
		});

		this.image = this.applicantInformation.applicantPictureURL;
		this.employeeAddress.address = this.applicantInformation.emsume?.address;
		this.employeeModel.emailAddressPersonal = !isNullOrUndefined(this.applicantInformation.emsume?.emailAddress)
			? this.applicantInformation.emsume?.emailAddress
			: this.applicantInformation.applicantEmailAddress;
		this.employeeModel.mobileNo = !isNullOrUndefined(this.applicantInformation.emsume?.mobileNo)
			? this.applicantInformation.emsume?.mobileNo
			: this.applicantInformation.applicantMobileNo;
		this.employeeAddressContact.address = this.applicantInformation.emsume?.address;
		this.employeeModel.pictureURL = this.applicantInformation.applicantPictureURL;
		this.employeeModel.pictureMediumURL = this.applicantInformation.applicantPictureURL;
		this.employeeModel.pictureOriginalURL = this.applicantInformation.applicantPictureURL;
		this.employeeModel.name = this.applicantInformation.applicantName;
		this.employeeModel.surname = this.applicantInformation.applicantSurname;
		this.employeeModel.name_EN = this.applicantInformation.applicantNameEN;
		this.employeeModel.surname_EN = this.applicantInformation.applicantSurnameEN;
		this.employeeModel.idCardNo = this.applicantInformation.applicantPassportNo
			? ""
			: this.applicantInformation.applicantIdCardNo;
		this.employeeModel.positionId = this.applicantInformation.positionId;
		this.employeeModel.isForeigner = this.applicantInformation.isForeigner;
		this.employeeModel.passportNo = this.applicantInformation.applicantPassportNo;
		this.employeeModel.dateOfBirth = new Date(this.applicantInformation.dateOfBirth);

		if (!isNullOrUndefined(this.applicantInformation?.genderId)) {
			let genderOther = this.applicantInformation?.genderId == GenderEmconnect.female ? Gender.female : Gender.other;
			this.employeeModel.gender =
				this.applicantInformation.genderId == GenderEmconnect.male ? Gender.male : genderOther;
		} else {
			this.employeeModel.gender = null;
		}

		this.changeForeigner({
			value: this.employeeModel.isForeigner,
		});
		this.data.applicantConverting = null;
	}

	applicantInformationTitle() {
		if (isNullOrUndefined(this.applicantInformation.genderId)) {
			return null;
		}
		return this.applicantInformation.genderId == GenderEmconnect.male ? Title.mr : Title.ms;
	}

	getDistrictRegisAddress() {
		this.districtDDL = [];
		this.commonService.getDDLDistrictByProvinceId(this.employeeAddress.provinceId).then((res) => {
			this.districtDDL = res;
		});
	}

	getDistrictRegisAddress_ca() {
		this.districtDDL_ca = [];
		this.commonService.getDDLDistrictByProvinceId(this.employeeAddressContact.provinceId).then((res) => {
			this.districtDDL_ca = res;
		});
	}

	setDefault() {
		this.employeeModel.gender = Gender.male;
		this.employeeModel.isForeigner = false;
		this.employeeModel.isAttendance = true;
		this.employeeModel.isReplaceEmployee = false;
		this.employeeModel.isCash = false;
		this.employeeModel.amount = 0;
	}

	loadUserInfo() {
		this.currentUserInfo$ = this.data.currentUserInfo.subscribe((user) => {
			if (!isNullOrUndefined(user.employeeId)) {
				this.userInfo = user;
				if (this.isAdditionalField) {
					this.currentEmployeeIndex = this.userInfo?.hasPayroll ? 6 : 4;
				}
				if (this.userInfo.packageId == this.packageEnum.Starter) {
					this.employeeModel.isSelfUpdate = false;
					this.formEmployeeFirstPage.controls["dateOfBirth"].clearValidators();
					this.formEmployeeSecondPage.controls["workLocationId"].setValidators(Validators.required);

					this.formEmployeeFirstPage.controls["dateOfBirth"].updateValueAndValidity();
					this.formEmployeeSecondPage.controls["workLocationId"].updateValueAndValidity();
				}

				if (!this.userInfo.hasPayroll || (this.isWorkInformation && !this.userInfo.hasPayroll)) {
					this.formEmployeeSecondPage.controls["isCash"].clearValidators();
					this.formEmployeeSecondPage.controls["bankId"].clearValidators();
					this.formEmployeeSecondPage.controls["bankAccountNo"].clearValidators();

					this.formEmployeeSecondPage.controls["isCash"].updateValueAndValidity();
					this.formEmployeeSecondPage.controls["bankId"].updateValueAndValidity();
					this.formEmployeeSecondPage.controls["bankAccountNo"].updateValueAndValidity();
				}
				this.maxOrganizationLevel = user.maxOrganizationLevel;
				this.isDoneLoading = true;
			}
		});

		this.organizationService.getOrganizationConfig().then((res) => {
			this.data.setOrganizationConfig(res);
		});
	}

	//#region Destroy unsubscribe
	ngOnDestroy() {
		if (this.language$) this.language$.unsubscribe();
		if (this.subscription) this.subscription.unsubscribe();
		if (this.onAdd$) this.onAdd$.unsubscribe();
		if (this.currentUserInfo$) this.currentUserInfo$.unsubscribe();
		if (this.accessTokenByNewEmployeeId$) this.accessTokenByNewEmployeeId$.unsubscribe();
		this.getPeoplePicker$?.unsubscribe();
	}
	//#endregion

	//#region Load data
	loadMasterDDL() {
		var listOfSystemRegisNo = [
			//MasterSetup.bloodType,
			MasterSetup.skinColor,
			//MasterSetup.nationality,
			//MasterSetup.religion,
			//MasterSetup.marriageStatus,
			//MasterSetup.militaryStatus,
			//MasterSetup.relationship,
		];
		this.commonService.getDDLMasterSetupBySystemRegisNo(listOfSystemRegisNo.join()).then((resSysRegisNoList) => {
			if (!isNullOrUndefined(resSysRegisNoList)) {
				this.skinColorDDL = resSysRegisNoList.filter((f) => f.dataId == MasterSetup.skinColor);
			}
		});

		this.commonService.getMasterTypeByParentId(MasterTypeEnum.nationality).then((res) => {
			this.nationalityDDL = res.data?.data;
		});

		this.commonService.getMasterTypeByParentId(MasterTypeEnum.religion).then((res) => {
			this.religionDDL = res.data?.data;
		});

		this.commonService.getMasterTypeByParentId(MasterTypeEnum.marriageStatus).then((res) => {
			this.marriageStatusDDL = res.data?.data;
		});

		this.commonService.getMasterTypeByParentId(MasterTypeEnum.relationShip).then((res) => {
			this.relationShipDDL = res.data?.data;
		});

		this.commonService.getMasterTypeByParentId(MasterTypeEnum.militaryStatus).then((res) => {
			this.militaryStatusDDL = res.data?.data;
		});

		this.commonService.getMasterTypeByParentId(MasterTypeEnum.bloodType).then((res) => {
			this.bloodTypesDDL = res.data?.data;
		});

		this.commonService.getMasterTypeByParentId(MasterTypeEnum.TerminationReasonSSO).then((res) => {
			this.reasonToLeaveDDL = res.data?.data;
		});

		this.commonService.getMasterTypeByParentId(MasterTypeEnum.typeCardSSO).then((res) => {
			this.typeCardDDL = res.data?.data;
		});

		this.commonService.getHospitalSSODDL().then((res) => {
			this.hospitalDDL = res;
		});

		this.employeeSocialSecurityService.getDDLNationalitySSO().then((res) => {
			this.nationalitySSODDL = res;
		});

		this.commonService.getDDLSysNamePrefixesByName("").then((resTitles) => {
			this.titlesDDL = resTitles;
		});

		this.commonService.getDDLProvince().then((resProvince) => {
			this.provinceDDL = resProvince;
			this.provinceDDL_ca = resProvince;
		});

		this.loadPaymentType();

		this.loadDropdownEmployeeStatus();

		this.getDropdownBranchSSO(false);

		if (!this.isEdit) {
			this.loadDropdownWorkinformation();
		}
	}

	loadDropdownWorkinformation(isReset: boolean = true) {
		this.getPositionDDL();
		this.loadOrganizationByCompanyId();
		this.getPeople();

		this.commonService.getDDLEmploymentTypes().then((res) => {
			this.employmentTypesDDL = res;
		});

		this.commonService.getDDLRanks().then((res) => {
			this.ranksDDL = res;
		});

		this.commonService
			.getDDLCompany()
			.then((res) => {
				this.companyDDL = res;
			})
			.then(() => {
				if (this.data.applicantConverting) {
					this.applicantInformation = this.data.applicantConverting;
					this.setConvertEmployee();
				}
			});

		this.commonService.getDDLCalendar().then((res) => {
			this.calendars = !isNullOrUndefined(res) ? res : [];
		});
	}

	getPositionDDL() {
		this.commonService
			.getDDLPositionByRankNo(isNullOrUndefined(this.employeeModel.rankNo) ? 0 : this.employeeModel.rankNo)
			.then((res) => {
				this.positionDDL = res;
			});
	}
	searchTextSupervisor: string = "";
	private getPeople() {
		let promise: Promise<any>[] = [firstValueFrom(this.commonService.getPeople(this.searchTextSupervisor))];
		if (this.isWorkInformation && this.employeeModel.upperSupervisorId) {
			let search = this.employeeModel.upperSupervisorName;
			promise.push(firstValueFrom(this.commonService.getPeople(search)));
		}
		Promise.all(promise).then((res) => {
			this.listPeople = res[0].data || [];
			if (this.isWorkInformation) {
				res[1].data?.forEach((emp) => {
					if (this.listPeople.findIndex((f) => f.employeeId == emp.employeeId) == -1) {
						this.listPeople.push(emp);
					}
				});
			}
		});
	}

	public eventFilter;
	onFilterDdlUser(e) {
		this.eventFilter = e;
		e.cancel = true;
		const searchText = e.text.trim().toLowerCase();
		firstValueFrom(this.commonService.getPeople(searchText)).then((res) => {
			this.listPeople = res.data;
		});
	}

	onFilterEmployee(e) {
		this.eventFilter = e;
		e.cancel = true;
		const searchText = e.text.trim().toLowerCase();
		firstValueFrom(this.commonService.getPeople(searchText)).then((res) => {
			this.listOfEmployees = this.mapEmployeeFields(res.data);
		});
	}

	private mapEmployeeFields(data: any[]): any[] {
		return data.map((emp) => ({
			fullName: emp.fullname,
			fullName_EN: emp.fullname_EN,
			employeeId: emp.employeeId,
			pictureURL: emp.pictureURL,
			positionName: emp.positionName,
			positionName_EN: emp.positionName_EN,
		}));
	}

	loadShiftDDL() {
		this.commonService.getDDLShiftByCompanyId(this.employeeModel.companyId).then((res) => {
			this.shiftDDL = res;
			this.currentShift = this.shiftDDL.find((f) => f.id === this.employeeModel?.shiftId) ?? [];
		});
	}

	loadBankDDL() {
		this.commonService.getDDLSysBanksByCompanyId(this.employeeModel.companyId).then((res) => {
			this.sysBankDDL = res;
			if (!isNullOrUndefined(this.sysBankDDL) && this.sysBankDDL.length > 0) {
				if (!this.sysBankDDL.some((e) => e.id == this.employeeModel?.bankId) && !this.isEdit) {
					this.employeeModel.bankId = this.sysBankDDL[0].id;
				}
			}
		});
	}

	public tempOrganizationNode: any;
	loadOrganizationByCompanyId() {
		this.organizationService.getOrganizationNode().then(async (res: any) => {
			let data = isNullOrUndefined(this.employeeModel.companyId)
				? []
				: res.data.find((f) => f.companyId == this.employeeModel.companyId);
			this.organizeNodeDDL =
				isNullOrUndefined(this.employeeModel.companyId) || isNullOrUndefined(data)
					? []
					: this.mapChildren(data["children"], this.filedOrganize);
		});
	}

	mapChildren(datas: any[], fields: any) {
		return datas.map((data) => {
			let model: any = {};
			model[fields.text] = data[fields.text];
			model.name_EN = data.name_EN;
			model[fields.value] = data[fields.value];
			model.dataSource = data[fields.children]?.length ? this.mapChildren(data[fields.children], fields) : [];
			return model;
		});
	}

	// mapchildren(data: any[]) {
	//   return data.map(m => <any>{ organizationId: m.organizationId, text: m.name, name_EN: m.name_EN, levels: m.levels, children: isNullOrUndefined(m.children) ? [] : this.mapchildren(m.children.filter(f => f.organizationId > 0 && f.isActive)) })
	// }
	//#endregion

	//#region Action
	onChooseImage(event) {
		this.image = event.files[0];
		if (this.image.size < 10000000) {
			let reader = new FileReader();
			reader.readAsDataURL(this.image);
			reader.onload = (e: any) => {
				this.employeeModel.originalPicture = e.target.result.split(",")[1];
				this.employeeModel.pictureType = this.image.type;
				this.employeeModel.pictureFileName = this.image.name;
				this.image = e.target.result;
			};
			this.employeeModel.hasPicture = true;
			this.fileUpload.clear();
		} else {
			let toast: ToastDataModel = {
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_file_max"),
			};
			this._toastHelperService.warning(toast);
		}
	}

	edit(id) {
		this.data.setEmpId(id);
		this.router.navigate(["/employee"]);
	}

	back() {
		this.router.navigate(["/employee"]);
	}
	//#endregion

	//#region Function on change
	getCurrentEmployeeCode() {
		if (this.isWorkInformation) return;
		this.employeeService
			.getCurrentEmployeeCode(!isNullOrUndefined(this.employeeModel.companyId) ? this.employeeModel.companyId : null)
			.then((res) => {
				this.employeeModel.employeeNo = res;
			});
	}
	//#region Function on change
	getCompany() {
		if (this.companyId != null) {
			this.organizationService.getOrganizationByCompanyId(this.companyId.id).then(async (res) => {
				this.organization = res;
			});
		}
		this.getCurrentEmployeeCode();
	}

	changeCompany() {
		this.getCurrentEmployeeCode();
		this.loadOrganizationByCompanyId();
		this.loadShiftDDL();
		this.loadBankDDL();
		this.getDropdownBranchSSO();
		this.employeeModel.departmentId = null;
		this.formEmployeeSecondPage.get("departmentId").setValue(null);
	}

	// getOrgLevelOne() {
	//   if (this.orgLevelOne == null) {
	//     this.orgLevelTwoDDL = null;
	//     this.orgLevelThreeDDL = null;
	//     this.orgLevelFourDDL = null;
	//     this.orgLevelFiveDDL = null;
	//     this.orgLevelSixDDL = null;
	//   } else {
	//     this.orgLevelTwoDDL = this.organization.filter(r => r.levels == Level.orgLevelTwo && r.parentId == this.orgLevelOne.id);
	//     if (this.orgLevelTwoDDL.length == 0) {
	//       this.orgLevelThreeDDL = null;
	//       this.orgLevelFourDDL = null;
	//       this.orgLevelFiveDDL = null;
	//       this.orgLevelSixDDL = null;
	//     } else {
	//       this.orgLevelThreeDDL = this.organization.filter(r => r.levels == Level.orgLevelThree);
	//       this.orgLevelFourDDL = this.organization.filter(r => r.levels == Level.orgLevelFour);
	//       this.orgLevelFiveDDL = this.organization.filter(r => r.levels == Level.orgLevelFive);
	//       this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix);
	//     }
	//   }
	//   this.form.get('orgLevelTwo').setValue(null);
	//   this.form.get('orgLevelThree').setValue(null);
	//   this.orgLevelFour = null;
	//   this.orgLevelFive = null;
	//   this.orgLevelSix = null;
	// }

	// getOrgLevelTwo() {
	//   if (this.orgLevelTwo == null) {
	//     this.orgLevelThreeDDL = this.organization.filter(r => r.levels == Level.orgLevelThree);
	//   } else {
	//     this.orgLevelThreeDDL = this.organization.filter(r => r.levels == Level.orgLevelThree && r.parentId == this.orgLevelTwo.organizationId);
	//     this.orgLevelFourDDL = this.organization.filter(r => r.levels == Level.orgLevelFour);
	//     this.orgLevelFiveDDL = this.organization.filter(r => r.levels == Level.orgLevelFive);
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix);
	//     this.form.get('orgLevelThree').setValue(null);
	//     this.orgLevelFour = null;
	//     this.orgLevelFive = null;
	//     this.orgLevelSix = null;
	//   }
	// }

	// getOrgLevelThree() {
	//   if (this.orgLevelThree == null && this.orgLevelTwo == null) {
	//     this.orgLevelFourDDL = this.organization.filter(r => r.levels == Level.orgLevelFour);
	//   } else if (this.orgLevelThree == null && this.orgLevelTwo != null) {
	//     this.orgLevelFourDDL = this.organization.filter(r => r.levels == Level.orgLevelFour);
	//     this.orgLevelFour = null;
	//     this.orgLevelFive = null;
	//     this.orgLevelSix = null;
	//   } else {
	//     this.orgLevelFourDDL = this.organization.filter(r => r.levels == Level.orgLevelFour && r.parentId == this.orgLevelThree.organizationId);
	//     this.orgLevelFour = null;
	//     this.orgLevelFive = null;
	//     this.orgLevelSix = null;
	//     this.orgLevelFiveDDL = this.organization.filter(r => r.levels == Level.orgLevelFive);
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix);
	//   }

	// }
	// getOrgLevelFour() {

	//   if (this.orgLevelFour == null && this.orgLevelThree == null) {
	//     this.orgLevelFiveDDL = this.organization.filter(r => r.levels == Level.orgLevelFive);
	//   } else if (this.orgLevelFour == null && this.orgLevelFive != null) {
	//     this.orgLevelFiveDDL = this.organization.filter(r => r.levels == Level.orgLevelFive);
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix);
	//     this.orgLevelFive = null;
	//     this.orgLevelSix = null;
	//   } else if (this.orgLevelFour != null && this.orgLevelThree == null) {
	//     this.orgLevelTwoDDL = this.organization.filter(r => r.levels == Level.orgLevelTwo);
	//     this.orgLevelThreeDDL = this.organization.filter(r => r.levels == Level.orgLevelThree);
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix);
	//     this.form.get('orgLevelTwo').setValue(null);
	//     this.form.get('orgLevelThree').setValue(null);
	//     this.orgLevelFive = null;
	//     this.orgLevelSix = null;
	//   }
	//   if (this.orgLevelFour != null) {
	//     this.orgLevelFiveDDL = this.organization.filter(r => r.levels == Level.orgLevelFive && r.parentId == this.orgLevelFour.organizationId);
	//     this.orgLevelFive = null;
	//     this.orgLevelSix = null;
	//   }
	// }
	// getOrgLevelFive() {
	//   if (this.orgLevelFive == null) {
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix);
	//     this.orgLevelSix = null;
	//   }
	//   if (this.orgLevelFive == null && this.orgLevelFour == null) {
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix);
	//   } else if (this.orgLevelFive != null && this.orgLevelFour == null) {
	//     this.orgLevelTwoDDL = this.organization.filter(r => r.levels == Level.orgLevelTwo);
	//     this.orgLevelThreeDDL = this.organization.filter(r => r.levels == Level.orgLevelThree);
	//     this.orgLevelFourDDL = this.organization.filter(r => r.levels == Level.orgLevelFour);
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix && r.parentId == this.orgLevelFive.organizationId);
	//     this.form.get('orgLevelTwo').setValue(null);
	//     this.form.get('orgLevelThree').setValue(null);
	//     this.orgLevelFour = null;
	//     this.orgLevelSix = null;
	//   } else {
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix && r.parentId == this.orgLevelFive.organizationId);
	//   }
	// }
	// getOrgLevelSix() {
	//   if (this.orgLevelSix == null && this.orgLevelFive == null) {
	//     this.orgLevelTwoDDL = this.organization.filter(r => r.levels == Level.orgLevelTwo);
	//     this.orgLevelThreeDDL = this.organization.filter(r => r.levels == Level.orgLevelThree);
	//     this.orgLevelFourDDL = this.organization.filter(r => r.levels == Level.orgLevelFour);
	//     this.orgLevelFiveDDL = this.organization.filter(r => r.levels == Level.orgLevelFive);
	//     this.orgLevelSixDDL = this.organization.filter(r => r.levels == Level.orgLevelSix);
	//     this.form.get('orgLevelTwo').setValue(null);
	//     this.form.get('orgLevelThree').setValue(null);
	//     this.orgLevelFour = null;
	//     this.orgLevelFive = null;
	//   } else if (this.orgLevelSix != null && this.orgLevelFive == null) {
	//     this.orgLevelTwoDDL = this.organization.filter(r => r.levels == Level.orgLevelTwo);
	//     this.orgLevelThreeDDL = this.organization.filter(r => r.levels == Level.orgLevelThree);
	//     this.orgLevelFourDDL = this.organization.filter(r => r.levels == Level.orgLevelFour);
	//     this.orgLevelFiveDDL = this.organization.filter(r => r.levels == Level.orgLevelFive);
	//     this.form.get('orgLevelTwo').setValue(null);
	//     this.form.get('orgLevelThree').setValue(null);
	//     this.orgLevelFour = null;
	//     this.orgLevelFive = null;
	//   }
	// }
	//#endregion

	getPosition() {
		this.positionDDL = null;
		if (!isNullOrUndefined(this.rankNo)) {
			this.commonService.getDDLPositionByRankNo(this.rankNo.id).then((res) => {
				this.positionDDL = res;
				this.form.get("orgLevelThree").setValue(null);
			});
		} else {
			this.commonService.getDDLPosition().then((res) => {
				this.positionDDL = res;
			});
		}
	}

	shiftStartDate(event) {
		this.isShiftStartDate = event.value;
		if (this.isShiftStartDate) {
			this.formEmployeeSecondPage.controls["shiftStartDate"].setValidators(Validators.required);
			this.formEmployeeSecondPage.controls["shiftStartDate"].updateValueAndValidity();
		} else {
			this.formEmployeeSecondPage.controls["shiftStartDate"].clearValidators();
			this.formEmployeeSecondPage.controls["shiftStartDate"].updateValueAndValidity();
		}
	}

	checkEmailAddressPersonal() {
		this.employeeModel.emailAddressPersonal != ""
			? this.formEmployeeFourthPage.controls["emailAddressPersonal"].setValidators(
					Validators.compose([Validators.email]),
				)
			: this.formEmployeeFourthPage.controls["emailAddressPersonal"].clearValidators();
		this.formEmployeeFourthPage.controls["emailAddressPersonal"].updateValueAndValidity();
	}

	public isEmailChanged: boolean = false;
	public isFirstTimeSetEmail: boolean = false;

	checkEmailAddress() {
		this.employeeModel.emailAddress != ""
			? this.formEmployeeFourthPage.controls["emailAddress"].setValidators(Validators.compose([Validators.email]))
			: this.formEmployeeFourthPage.controls["emailAddress"].clearValidators();
		this.formEmployeeFourthPage.controls["emailAddress"].updateValueAndValidity();
		this.checkPublicDomain();
		if (this.isAddress) {
			const isNotNullAndNoError =
				this.employeeModel.emailAddress && this.formEmployeeFourthPage.controls["emailAddress"].valid;
			if (this.employeeCurrentEmailAddress) {
				this.isEmailChanged =
					this.employeeCurrentEmailAddress !== this.employeeModel.emailAddress && isNotNullAndNoError;
			} else if (!this.employeeCurrentEmailAddress && this.formEmployeeFourthPage.controls["emailAddress"].valid) {
				this.isFirstTimeSetEmail = isNotNullAndNoError;
				this.isSendEmailTutorial = this.isFirstTimeSetEmail;
			}
		}
	}

	changeForeigner(event) {
		if (event.value) {
			this.employeeListDuplicateIDCard = null;
			this.formEmployeeFirstPage.controls["idCardNo"].setValidators(
				Validators.compose([Validators.minLength(13), Validators.maxLength(13)]),
			);
		} else {
			this.formEmployeeFirstPage.controls["idCardNo"].setValidators(
				Validators.compose([Validators.required, Validators.minLength(13), Validators.maxLength(13)]),
			);
			this.checkIdCardExist();
		}
		this.formEmployeeFirstPage.controls["idCardNo"].updateValueAndValidity();
	}
	onChangeReplaceEmployee(e) {
		e.value
			? this.formEmployeeSecondPage.controls["replaceEmployeeId"].setValidators(Validators.required)
			: this.formEmployeeSecondPage.controls["replaceEmployeeId"].clearValidators();
		this.formEmployeeSecondPage.controls["replaceEmployeeId"].updateValueAndValidity();
	}

	// openPeoplePicker() {
	// 	this.displayPeoplePicker = !this.displayPeoplePicker;
	// }

	openAddPosition() {
		this.displayPositionAdd = false;
		this.getPositionDDL();
	}

	closeSidebar() {
		this.displayPositionAdd = false;
	}

	value: any;

	// onOpenPeoplePicker() {
	// 	this.displayPeoplePicker = true;
	// }

	// onselect() {
	// 	this.displayPeoplePicker = !this.displayPeoplePicker;
	// 	this.replaceEmployeeId = !isNullOrUndefined(this.value) ? this.value[0].employeeId : null;
	// 	this.employeeModel.replaceEmployeeId = !isNullOrUndefined(this.value) ? this.value[0].employeeId : null;
	// 	this.replacedEmpName = this.value[0]?.fullName;
	// 	this.replacedEmpNameEN = this.value[0]?.fullName_EN;
	// 	this.value = null;
	// }

	public isSubmittedFirstPage: boolean = false;
	public isSubmittedSecondPage: boolean = false;
	public isSubmittedThirdPage: boolean = false;
	async next() {
		if (this.currentEmployeeIndex == 1 && this.formEmployeeFirstPage.valid) {
			this.currentEmployeeIndex++;
			return;
		} else if (this.currentEmployeeIndex == 1) {
			this.isSubmittedFirstPage = true;
			this._toastHelperService.validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
		}

		if (this.currentEmployeeIndex == 2 && this.formEmployeeFourthPage.valid) {
			this.currentEmployeeIndex++;
			if (this.chkAddress) {
				this.employeeAddressContact.address = this.employeeAddress.address;
				this.employeeAddressContact.provinceId = this.employeeAddress.provinceId;
				this.districtDDL_ca = this.districtDDL;
				this.employeeAddressContact.districtId = this.employeeAddress.districtId;
				this.employeeAddressContact.subdistrict = this.employeeAddress.subdistrict;
				this.employeeAddressContact.postalCode = this.employeeAddress.postalCode;
				this.employeeAddressContact.phoneNo = this.employeeAddress.phoneNo;
			}
			return;
		} else if (this.currentEmployeeIndex == 2) {
			this.isSubmittedSecondPage = true;
			this._toastHelperService.validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
		}

		if (this.currentEmployeeIndex == 3 && this.formEmployeeSecondPage.valid && !this.isDuplicateEmployeeNo) {
			this.save();
		} else if (this.currentEmployeeIndex == 3) {
			this.isSubmittedThirdPage = true;
			this._toastHelperService.validation({
				title: !this.formEmployeeSecondPage.valid
					? this.translate.instant("toast_title_headsup")
					: this.translate.instant("toast_title_sorry"),
				description: !this.formEmployeeSecondPage.valid
					? this.translate.instant("toast_common_complete_all_fields")
					: this.translate.instant("toast_employee_no_exist_desc"),
			});
		}

		if (this.currentEmployeeIndex === 4) {
			this.currentEmployeeIndex++;
			return;
		}

		if (this.currentEmployeeIndex === 5 && this.formEmployeeSSO.valid) {
			this.saveEmployeeSocialSecurity();
		} else if (this.currentEmployeeIndex === 5) {
			this.isSubmitSSO = true;
			this._toastHelperService.validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
		}
	}
	save() {
		this.organizationService
			.getOrganizationByOrganizeId(this.employeeModel.departmentId)
			.then((res) => {
				const employee = res;
				this.employeeModel.orgLevelOne = employee.orgLevelOne;
				this.employeeModel.orgLevelTwo = employee.orgLevelTwo;
				this.employeeModel.orgLevelThree = employee.orgLevelThree;
				this.employeeModel.orgLevelFour = employee.orgLevelFour;
				this.employeeModel.orgLevelFive = employee.orgLevelFive;
				this.employeeModel.orgLevelSix = employee.orgLevelSix;
				this.createModel();
			})
			.then(() => {
				this.employeeModel.dateOfBirth = !isNullOrUndefined(this.employeeModel.dateOfBirth)
					? new Date(this.datePipe.transform(this.employeeModel.dateOfBirth, "yyyy-MM-dd"))
					: null;
				this.employeeModel.dateWorkPermitExpire = !isNullOrUndefined(this.employeeModel.dateWorkPermitExpire)
					? new Date(this.datePipe.transform(this.employeeModel.dateWorkPermitExpire, "yyyy-MM-dd"))
					: null;
				this.employeeModel.dateWorkPermitIssue = !isNullOrUndefined(this.employeeModel.dateWorkPermitIssue)
					? new Date(this.datePipe.transform(this.employeeModel.dateWorkPermitIssue, "yyyy-MM-dd"))
					: null;
				this.employeeModel.replaceEmployeeId = this.employeeModel.isReplaceEmployee
					? this.employeeModel.replaceEmployeeId
					: null;
				this.employeeModel.isSendEmailTutorial = this.isSendEmailTutorial;
				this.employeeModel.shiftStartDate = this.isShiftStartDate ? this.employeeModel.shiftStartDate : null;
				if (isNullOrUndefined(this.employeeModel.idCardNo) && this.employeeModel.isForeigner) {
					this.employeeModel.idCardNo = "";
				}
				if (this.applicantInformation) {
					const recruitmentModel = {
						applicantId: this.applicantInformation.applicantId,
						positionId: this.applicantInformation.positionId,
						companyId: this.applicantInformation.companyId,
					};
					this.employeeModel.recruitment = recruitmentModel;
				}
				if (!this.userInfo.hasPayroll) {
					this.loadConfirm();
					const dialogModel = <DialogInformationModel>{
						title: this.confirmation,
						description: this.addConfirmation,
						imageUrl: "confirmation.png",
						textButtonConfirm: this.translate.instant("common_confirm"),
						textButtonCancel: this.translate.instant("common_cancel"),
					};
					firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
						if (response?.confirm) {
							this.addEmployee();
						}
					});
				} else {
					this.addEmployee();
				}
			});
	}

	addEmployee() {
		if (this.onAdd$) this.onAdd$.unsubscribe();
		this.onAdd$ = this.employeeService.addEmployee(this.employeeModel).subscribe((res) => {
			var apiResult = <ApiResult>res;
			if (apiResult.result) {
				this.formEmployeeSecondPage?.markAsPristine();
				this.employeeModel.employeeId = apiResult.data;
				if (this.convertEmployee) {
					this.applicantInformation.prevStatusId = this.applicantInformation.statusId;
					this.applicantInformation.statusId = this.recruitmentStatusEnum.permanent;
					this.applicantInformation.employeeId = apiResult.data;
					firstValueFrom(this.employeeService.convertApplicantToEmployee(this.applicantInformation)).then(() => {
						this.currentEmployeeIndex++;
					});
				} else {
					this.employeeModel.financialCategoryId = FinancialCategory.baseSalary;
					this.employeeModel.bankId = this.employeeModel.isCash ? null : this.employeeModel.bankId;
					this.employeeModel.bankAccountNo = this.employeeModel.isCash ? null : this.employeeModel.bankAccountNo;

					this.employeeModel.dateOfBirth = !isNullOrUndefined(this.employeeModel.dateOfBirth)
						? new Date(this.datePipe.transform(this.employeeModel.dateOfBirth, "yyyy-MM-dd"))
						: null;
					this.employeeModel.dateWorkPermitExpire = !isNullOrUndefined(this.employeeModel.dateWorkPermitExpire)
						? new Date(this.datePipe.transform(this.employeeModel.dateWorkPermitExpire, "yyyy-MM-dd"))
						: null;
					this.employeeModel.dateWorkPermitIssue = !isNullOrUndefined(this.employeeModel.dateWorkPermitIssue)
						? new Date(this.datePipe.transform(this.employeeModel.dateWorkPermitIssue, "yyyy-MM-dd"))
						: null;
					const model = {
						...this.employeeModel,
					};
					model.isSelfUpdate =
						this.employeeModel.employeeStatus === EmployeeStatus.waitingStartWork &&
						this.employeeModel.isSendEmailOnboarding
							? true
							: this.employeeModel.isSelfUpdate;
					firstValueFrom(this.employeeFinancialService.addEmployeeFinancial(model)).then(() => {
						this.currentEmployeeIndex++;
					});
				}
				this.onChangedCash(this.employeeModel.isCash);
			}
			if (apiResult?.errorField == "employeeNo" && apiResult?.isDuplicate) {
				this.isDuplicateEmployeeNo = true;
				this.showValidationDuplicateToast();
				return;
			}
			this._toastHelperService.alertApiResult(apiResult);
		});
	}

	saveEmployeeSocialSecurity() {
		if (!this.employeeSocialSecurity?.employeeSocialSecurityId) {
			this.employeeSocialSecurity.employeeId = this.employeeModel.employeeId;
			this.employeeSocialSecurityService.addEmployeeSocialSecurity(this.employeeSocialSecurity).subscribe((res) => {
				var apiResult = <ApiResult>res;
				if (this.isSocialSecurity) {
					return;
				}
				if (apiResult.result) {
					if (this.customFields?.length) {
						this.currentEmployeeIndex++;
						this._toastHelperService.alertApiResult(apiResult);
					}
				} else {
					this._toastHelperService.alertApiResult(apiResult);
				}
			});
		} else {
			this.employeeSocialSecurityService.editEmployeeSocialSecurity(this.employeeSocialSecurity).subscribe((res) => {
				var apiResult = <ApiResult>res;
				if (this.isSocialSecurity) {
					return;
				}
				if (apiResult.result) {
					if (this.customFields?.length) {
						this.currentEmployeeIndex++;
						this._toastHelperService.alertApiResult(apiResult);
					}
				} else {
					this._toastHelperService.alertApiResult(apiResult);
				}
			});
		}
	}

	loadSSO() {
		this.employeeSocialSecurityService.getEmployeeSocialSecurityByEmployeeId(this.id).then((res) => {
			this.employeeSocialSecurity = res;
		});
	}

	onChangedCash(isCash) {
		if (!isCash) {
			this.formEmployeeThirdPage.controls["bankId"].setValidators(Validators.compose([Validators.required]));
			this.formEmployeeThirdPage.controls["bankAccountNo"].setValidators(Validators.compose([Validators.required]));
		} else {
			this.formEmployeeThirdPage.controls["bankId"].clearValidators();
			this.formEmployeeThirdPage.controls["bankAccountNo"].clearValidators();
		}
		this.formEmployeeThirdPage.controls["bankId"].updateValueAndValidity();
		this.formEmployeeThirdPage.controls["bankAccountNo"].updateValueAndValidity();
	}

	onChangedCash1(isCash) {
		if (!isCash && this.userInfo?.hasPayroll) {
			this.formEmployeeSecondPage.controls["bankId"].setValidators(Validators.compose([Validators.required]));
			this.formEmployeeSecondPage.controls["bankAccountNo"].setValidators(Validators.compose([Validators.required]));
		} else {
			this.formEmployeeSecondPage.controls["bankId"].clearValidators();
			this.formEmployeeSecondPage.controls["bankAccountNo"].clearValidators();
		}
		this.formEmployeeSecondPage.controls["bankId"].updateValueAndValidity();
		this.formEmployeeSecondPage.controls["bankAccountNo"].updateValueAndValidity();
	}

	public isSubmittedFourthPage: boolean = false;
	saveTransaction() {
		this.isSubmittedFourthPage = true;
		if (this.formEmployeeSSO.valid && this.customFields.length === 0) {
			this.saveEmployeeSocialSecurity();
		} else {
			this._toastHelperService.validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
			return;
		}

		if (this.formEmployeeThirdPage.valid) {
			if (this.customFields.length) {
				this.addEmployeeFinancial();
			} else {
				this.loadConfirm();
				const dialogModel = <DialogInformationModel>{
					title: this.confirmation,
					description: this.addConfirmation,
					imageUrl: "confirmation.png",
					textButtonConfirm: this.translate.instant("common_confirm"),
					textButtonCancel: this.translate.instant("common_cancel"),
				};
				firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
					if (response?.confirm) {
						this.addEmployeeFinancial();
					}
				});
			}
		} else {
			this._toastHelperService.validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
		}
	}

	addEmployeeFinancial() {
		this.employeeModel.financialCategoryId = FinancialCategory.baseSalary;
		this.employeeModel.bankId = this.employeeModel.isCash ? null : this.employeeModel.bankId;
		this.employeeModel.bankAccountNo = this.employeeModel.isCash ? null : this.employeeModel.bankAccountNo;

		this.employeeModel.dateOfBirth = !isNullOrUndefined(this.employeeModel.dateOfBirth)
			? new Date(this.datePipe.transform(this.employeeModel.dateOfBirth, "yyyy-MM-dd"))
			: null;
		this.employeeModel.dateWorkPermitExpire = !isNullOrUndefined(this.employeeModel.dateWorkPermitExpire)
			? new Date(this.datePipe.transform(this.employeeModel.dateWorkPermitExpire, "yyyy-MM-dd"))
			: null;
		this.employeeModel.dateWorkPermitIssue = !isNullOrUndefined(this.employeeModel.dateWorkPermitIssue)
			? new Date(this.datePipe.transform(this.employeeModel.dateWorkPermitIssue, "yyyy-MM-dd"))
			: null;
		const model = {
			...this.employeeModel,
		};
		model.isSelfUpdate =
			this.employeeModel.employeeStatus === EmployeeStatus.waitingStartWork && this.employeeModel.isSendEmailOnboarding
				? true
				: this.employeeModel.isSelfUpdate;
		firstValueFrom(this.employeeFinancialService.addEmployeeFinancial(model)).then((res) => {
			let apiResult = <ApiResult>res;
			if (apiResult.result) {
				this.isSubmittedFourthPage = false;
				if (this.customFields?.length) {
					this.currentEmployeeIndex++;
				} else {
					this.edit(this.employeeModel.employeeId);
				}
				this._toastHelperService.alertApiResult(apiResult);
			} else {
				this._toastHelperService.alertApiResult(apiResult);
			}
		});
	}

	public employmentTypeId: number = Movement.renewal;
	dulEmployeeIdRenewal: number;
	isSidebarRenewalAdd: boolean = false;
	public checkingIdCard: boolean = false;
	checkIdCardExist() {
		this.checkingIdCard = true;
		this.employeeListDuplicateIDCard = null;
		if ((this.employeeModel.idCardNo?.length ?? 0) < 13 || this.employeeModel.idCardNo?.includes(" ")) return;
		this.employeeService
			.checkEmployeeIdCard(this.employeeModel.idCardNo)
			.pipe(debounceTime(50))
			.subscribe((res: any) => {
				var apiResult = <ApiResult>res;
				if (this.isEdit) {
					apiResult.data = apiResult.data.filter((f) => f.employeeId != this.employeeModel.employeeId);
				}
				var employeeResign = apiResult.data.find((f) => f.employeeStatus == EmployeeStatus.resign);
				if (employeeResign && !this.isEdit) {
					if (res?.data.length) {
						const dialogModel = <DialogInformationModel>{
							title: this.translate.instant("common_replace_employee_confirm"),
							description:
								this.translate.instant("emp001_confirm_employee_edit_start") +
								" " +
								(this.language == "th-TH" ? employeeResign?.name : employeeResign?.name_EN) +
								" " +
								this.translate.instant("emp001_confirm_employee_edit_end"),
							imageUrl: "duplication.png",
							textButtonConfirm: this.translate.instant("common_confirm_renewal"),
							textButtonCancel: this.translate.instant("common_cancel"),
						};
						firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
							if (response?.confirm) {
								this.isSidebarRenewalAdd = true;
								this.dulEmployeeIdRenewal = employeeResign.employeeId;
								// this.employeeRenewal(employeeResign)
							} else {
								this.checkingIdCard = false;
							}
						});
					} else {
						this.checkingIdCard = false;
					}
				}
				this.employeeListDuplicateIDCard = apiResult.data;
			});
	}

	employeeListDuplicateIDCard: any;

	employeeRenewal(model) {
		this.data.setEmpId(model.employeeId);
		this.data.isEdit = true;
		localStorage.setItem(LocalStorageKey.renewal, "true");

		this.router.navigate([`/employee/${model.employeeNo}`]);
	}

	async onBackState(index: number) {
		if (index == 1) {
			this.isSubmittedFirstPage = false;
		} else if (index == 2) {
			this.isSubmittedSecondPage = false;
		}
		if (this.currentEmployeeIndex > 3 || this.currentEmployeeIndex <= index) return;

		this.currentEmployeeIndex = index;
		if (this.currentEmployeeIndex == 1) {
			await this.sharingService.delayTime(400);
			await this.setData();
		}
	}

	setData() {
		this.formEmployeeFirstPage.get("idCardNo").setValue(this.employeeModel.idCardNo);
	}

	skip() {
		this.router.navigate(["/employee"]);
	}

	clearRace() {
		this.employeeModel.raceId = null;
	}

	clearReligion() {
		this.employeeModel.religionId = null;
	}

	clearMarriage() {
		this.employeeModel.marriageStatus = null;
	}

	clareShift() {
		this.employeeModel.shiftId = null;
	}

	clareCalendar() {
		this.employeeModel.calendarId = null;
	}

	clarePeople() {
		this.employeeModel.upperSupervisorId = null;
	}

	clearReplacedEmployee() {
		this.replacedEmpName = "";
		this.replaceEmployeeId = null;
		this.formEmployeeSecondPage.controls["replaceEmployeeId"].setValue(null);
	}

	clearMilitary() {
		this.employeeModel.militaryStatus = null;
	}

	clearBloodGroup() {
		this.employeeModel.bloodGroup = null;
	}

	clareWorkIn() {
		this.employeeModel.workLocationId = null;
	}

	clareDropdownRelationShip() {
		this.employeeContactPerson.contactPersonRelationShipId = null;
	}

	clearNationality(isSSO: boolean = false) {
		this.employeeModel.nationalityId = null;
		if (isSSO) {
			this.employeeSocialSecurity.nationalitySSOId = null;
		}
	}

	public editSubmittedFirstPage: boolean = false;
	public compareList: any[] = [];
	public compareListTemp: any[] = [];
	public editType: string = EditType.movement;
	public upperSupervisorId: any = null;
	public popup_Confirm: boolean = false;
	public movementTypeId: any = null;
	public isSubmittedEmployment: boolean = false;
	public countUnder: number = 0;

	public checkField: string[] = [
		"companyId",
		"departmentId",
		"rankNo",
		"positionId",
		"employeeNo",
		"workLocationId",
		"shiftId",
		"dateEmployment",
		"employeeStatus",
		"employmentType",
		"upperSupervisorId",
		"dateContractExpired",
		"calendarId",
		"driverNo",
		"isReplaceEmployee",
		"isAttendance",
		"paymentMethod",
	];
	get DropdownSelectMode() {
		return DropdownSelectMode;
	}
	reasonToResignDDL: Dropdown[];
	checkInformationField() {
		let employeeModelChange = this.checkField.some((fieldName) => {
			return this.employeeModel[fieldName] !== this.oldDataOfEmployeeModel[fieldName];
		});

		if (employeeModelChange) {
			this.popup_Confirm = true;
			this.nextUpperSupervisorId = 0;
			this.commonService.getDDLMasterSetupBySystemRegisNo(`${MasterSetup.reasonToSeparation}`).then((res) => {
				this.reasonToResignDDL = res;
			});
			this.editType = EditType.movement;
		} else {
			this.popup_Confirm = false;
			this.editType = EditType.edit;
			this.saveWorkinformation();
		}
	}

	async checkDuplicateEmployeeNo() {
		if (this.isDuplicateEmployeeNo) {
			return true;
		}
		let isDuplicate = false;
		const res = await firstValueFrom(this.employeeService.CheckEmployeeNo(this.employeeModel));
		const apiResult = <ApiResult>res;
		if (apiResult.data && apiResult.data.employeeId !== this.employeeModel.employeeId) {
			isDuplicate = true;
		}
		return isDuplicate;
	}

	async saveCompareWorkinformation() {
		this.sharingService.pushGoogleTagManager("Employment_Details_Employment_Save");
		if (!this.permission.allowEdit) return;
		this.isSubmittedThirdPage = true;
		this.isDuplicateEmployeeNo = await this.checkDuplicateEmployeeNo();
		if (!this.formEmployeeSecondPage.valid || this.isDuplicateEmployeeNo) {
			this._toastHelperService.validation({
				title: !this.formEmployeeSecondPage.valid
					? this.translate.instant("toast_title_headsup")
					: this.translate.instant("toast_title_sorry"),
				description: !this.formEmployeeSecondPage.valid
					? this.translate.instant("toast_common_complete_all_fields")
					: this.translate.instant("toast_employee_no_exist_desc"),
			});
		} else {
			this.organizationService
				.getOrganizationByOrganizeId(this.employeeModel.departmentId)
				.then((res) => {
					const employee = res;
					this.employeeModel.orgLevelOne = employee.orgLevelOne;
					this.employeeModel.orgLevelTwo = employee.orgLevelTwo;
					this.employeeModel.orgLevelThree = employee.orgLevelThree;
					this.employeeModel.orgLevelFour = employee.orgLevelFour;
					this.employeeModel.orgLevelFive = employee.orgLevelFive;
					this.employeeModel.orgLevelSix = employee.orgLevelSix;
					this.createModel();
				})
				.then(() => {
					this.employeeModel.dateOfBirth = !isNullOrUndefined(this.employeeModel.dateOfBirth)
						? new Date(this.datePipe.transform(this.employeeModel.dateOfBirth, "yyyy-MM-dd"))
						: null;
					this.employeeModel.replaceEmployeeId =
						isNull(this.employeeModel.isReplaceEmployee) || isUndefined(this.employeeModel.isReplaceEmployee)
							? null
							: this.employeeModel.replaceEmployeeId;

					this.compare();
				});
		}
	}
	compare(updateUpper = false) {
		if (this.employeeModel.upperSupervisorId > 0 && !updateUpper) {
			this.nextUpperSupervisorId = this.employeeModel.upperSupervisorId;
		}
		if (this.editType != EditType.movement) {
			this.nextUpperSupervisorId = null;
		}

		let compareData = {
			employeeId: this.employeeModel.employeeId,
			employeeNo: this.employeeModel.employeeNo,
			dateEmployment: this.employeeModel?.dateEmployment,
			isAttendance: this.employeeModel.isAttendance,
			rankNo: !isNullOrUndefined(this.employeeModel.rankNo) ? this.employeeModel.rankNo : null,
			positionId: !isNullOrUndefined(this.employeeModel.positionId) ? this.employeeModel.positionId : null,
			isReplaceEmployee:
				isNull(this.employeeModel.isReplaceEmployee) || isUndefined(this.employeeModel.isReplaceEmployee)
					? false
					: this.employeeModel.isReplaceEmployee,
			replaceEmployeeId: this.employeeModel.isReplaceEmployee ? this.employeeModel?.replaceEmployeeId : null,
			upperSupervisorId: this.employeeModel.upperSupervisorId ?? null,
			employeeStatus: !isNullOrUndefined(this.employeeModel.employeeStatus) ? this.employeeModel.employeeStatus : null,
			employmentType: !isNullOrUndefined(this.employeeModel.employmentType) ? this.employeeModel.employmentType : null,
			calendarId: !isNullOrUndefined(this.employeeModel.calendarId) ? this.employeeModel.calendarId : null,
			companyId: !isNullOrUndefined(this.employeeModel.companyId) ? this.employeeModel.companyId : null,
			orgLevelOne: !isNullOrUndefined(this.employeeModel.orgLevelOne) ? this.employeeModel.orgLevelOne : null,
			orgLevelTwo: !isNullOrUndefined(this.employeeModel.orgLevelTwo) ? this.employeeModel.orgLevelTwo : null,
			orgLevelThree: !isNullOrUndefined(this.employeeModel.orgLevelThree) ? this.employeeModel.orgLevelThree : null,
			orgLevelFour: !isNullOrUndefined(this.employeeModel.orgLevelFour) ? this.employeeModel.orgLevelFour : null,
			orgLevelFive: !isNullOrUndefined(this.employeeModel.orgLevelFive) ? this.employeeModel.orgLevelFive : null,
			orgLevelSix: !isNullOrUndefined(this.employeeModel.orgLevelSix) ? this.employeeModel.orgLevelSix : null,
			dateTermination: !isNullOrUndefined(this.employeeModel.dateTermination)
				? this.employeeModel.dateTermination
				: null,
			workLocationId: !isNullOrUndefined(this.employeeModel.workLocationId) ? this.employeeModel.workLocationId : null,
			shiftId: !isNullOrUndefined(this.employeeModel.shiftId) ? this.employeeModel.shiftId : null,
			dateContractExpired: !isNullOrUndefined(this.employeeModel.dateContractExpired)
				? this.employeeModel.dateContractExpired
				: null,
			driverNo: !isNullOrUndefined(this.employeeModel.driverNo) ? this.employeeModel.driverNo : null,
			bankId: this.employeeModel.bankId,
			bankAccountNo: this.employeeModel.bankAccountNo,
			nextUpperSupervisorId: this.nextUpperSupervisorId ?? null,
			paymentMethod: !isNullOrUndefined(this.employeeModel.paymentMethod) ? this.employeeModel.paymentMethod : null,
		};
		this.employeeWorkInformationService.compareEmployeeDetail(<Employee>compareData).subscribe((res) => {
			var apiResult = <ApiResult>res;
			this.compareList = apiResult.data;
			this.compareListTemp = apiResult.data;
			if (apiResult.result) {
				this.isSubmittedThirdPage = false;
				if (this.compareList.length > 0) {
					if (!updateUpper) {
						this.formCompare.reset();
						this.upperSupervisorId = this.employeeModel.upperSupervisorId;
					}
					this.formCompare.controls["dateEffective"].setValue(new Date());
					this.formCompare.controls["documentNo"].setValue(null);
					this.formCompare.controls["dateDocument"].setValue(new Date());
					this.isSubmittedWorkinformation = false;
					this.isEditType = false;

					if (
						localStorage.getItem(LocalStorageKey.renewal) == "true" ||
						(this.tmpEmployeeStatus == EmployeeStatus.resign &&
							this.tmpEmployeeStatus != this.employeeModel.employeeStatus)
					) {
						this.movementTypeId = this.movementTypeDDL.find((m) => m.id == ProcessAction.renewal).id;
					} else if (this.compareList.filter((x) => x.value1_EN == "Permanent").length > 0) {
						this.movementTypeId = this.movementTypeDDL.find((m) => m.id == ProcessAction.permanent).id;
					} else if (
						this.compareList.filter((x) => x.memberPath == "RankNo").length > 0 ||
						this.compareList.filter((x) => x.memberPath == "PositionId").length > 0
					) {
						this.movementTypeId = this.movementTypeDDL.find((m) => m.id == ProcessAction.promote).id;
					} else if (this.compareList.filter((x) => x.memberPath == "CompanyId").length > 0) {
						this.movementTypeId = this.movementTypeDDL.find((m) => m.id == ProcessAction.crossCompanyTransfer).id;
					} else if (
						this.compareList.filter((x) => x.memberPath == "OrgLevelTwo").length > 0 ||
						this.compareList.filter((x) => x.memberPath == "OrgLevelThree").length > 0 ||
						this.compareList.filter((x) => x.memberPath == "OrgLevelFour.Value").length > 0 ||
						this.compareList.filter((x) => x.memberPath == "OrgLevelFive.Value").length > 0 ||
						this.compareList.filter((x) => x.memberPath == "OrgLevelSix.Value").length > 0
					) {
						this.movementTypeId = this.movementTypeDDL.find((m) => m.id == ProcessAction.agencyTransfer).id;
					} else if (this.compareList.filter((x) => x.value1_EN == "Resign").length > 0) {
						this.movementTypeId = this.movementTypeDDL.find((m) => m.id == ProcessAction.resign).id;
						this.employeeMovementsService.getSupervisorByEmployeeId(this.id, ChartType.under).then((upper) => {
							this.countUnder = upper.data.length > 0 ? upper.data.length - 1 : 0;
						});
					} else {
						this.movementTypeId = this.movementTypeDDL.find((m) => m.id == ProcessAction.employmentConditions).id;
					}
					if (this.movementTypeId == ProcessAction.resign) {
						this.compareList = this.compareListTemp?.filter((x) => x.memberPath != "UpperSupervisorId");
					} else {
						this.compareList = this.compareListTemp?.filter((x) => x.memberPath != "NextUpperSupervisorId");
					}

					this.checkInformationField();
					this.updateValidator();
					this.formCompare.controls["movementTypeId"].setValue(this.movementTypeId);
				} else {
					this.editType = EditType.edit;
					this.updateValidator();
					this.saveWorkinformation();
				}
			} else {
				this._toastHelperService.validation({
					title: this.translate.instant("common_fail"),
					description: apiResult.message,
				});
			}
			this.loadWorkflow();
		});
	}
	get infoBarType() {
		return InfoBarType;
	}
	public isEditType: boolean = false;
	public isApprove: boolean = true;
	public dateEffective: Date = new Date();
	public dateDocument: Date = new Date();
	public upperSupervisor: any;
	nextUpperSupervisorId: number;
	getSupervisor(event) {
		this.upperSupervisorId = event.value;
		this.formCompare.controls["upperSupervisorIdInput"].setValue(this.upperSupervisorId);

		if (this.movementTypeId === Movement.resign) {
			this.nextUpperSupervisorId = this.upperSupervisorId;
			this.compare(true);
		}
	}

	getReplaceEmployee(event: any) {
		this.employeeModel.isReplaceEmployee = true;
		this.replaceEmployeeId = !isNullOrUndefined(event.value) ? event.selectedItem.employeeId : null;
		this.employeeModel.replaceEmployeeId = !isNullOrUndefined(event.value) ? event.selectedItem.employeeId : null;
		this.replacedEmpName = event.selectedItem.fullName;
		this.employeeModel.fullName = this.replacedEmpName;
		this.replacedEmpNameEN = event.selectedItem.fullName_EN;
		this.formEmployeeSecondPage.controls["replaceEmployeeId"].setValue(this.replaceEmployeeId);
	}

	onChangeIsApprove(e) {
		this.isApprove = e.value;
		this.updateValidator();
	}

	onChangeEditType(e) {
		this.isEditType = e.value;
		if (this.isEditType) {
			this.editType = EditType.edit;
			this.upperSupervisorId = this.employeeModel.upperSupervisorId;
		} else {
			this.editType = EditType.movement;
		}
		this.dateEffective = this.currentDate;
		this.updateValidator();
	}

	selectedMovementType() {
		if (this.movementTypeId == ProcessAction.resign) {
			this.employeeMovementsService.getSupervisorByEmployeeId(this.id, ChartType.under).then((upper) => {
				this.countUnder = upper.data.length > 0 ? upper.data.length - 1 : 0;
			});
			this.compareList = this.compareListTemp?.filter((x) => x.memberPath != "UpperSupervisorId");
		} else {
			this.compareList = this.compareListTemp?.filter((x) => x.memberPath != "NextUpperSupervisorId");
		}

		this.loadWorkflow();
	}

	updateValidator() {
		if (this.editType == EditType.movement) {
			// Movement
			this.formCompare.controls["movementTypeId"].setValidators(Validators.required);
			this.formCompare.controls["dateEffective"].setValidators(Validators.required);
			if (this.movementTypeId == Movement.resign) {
				this.formCompare.controls["terminationReasonId"].setValidators(Validators.required);
			} else {
				this.formCompare.controls["terminationReasonId"].clearValidators();
			}

			this.formCompare.controls["movementTypeId"].updateValueAndValidity();
			this.formCompare.controls["dateEffective"].updateValueAndValidity();
			this.formCompare.controls["terminationReasonId"].updateValueAndValidity();
		} else {
			this.clearValidators();
		}
	}

	clearValidators() {
		this.formCompare.controls["movementTypeId"].clearValidators();
		this.formCompare.controls["dateEffective"].clearValidators();
		this.formCompare.controls["terminationReasonId"].clearValidators();

		this.formCompare.controls["movementTypeId"].updateValueAndValidity();
		this.formCompare.controls["dateEffective"].updateValueAndValidity();
		this.formCompare.controls["terminationReasonId"].updateValueAndValidity();
	}

	closeDialog() {
		this.popup_Confirm = false;
		localStorage.removeItem(LocalStorageKey.renewal);
	}
	public isSubmittedWorkinformation: boolean = false;
	public isDulMovement: boolean = false;
	saveWorkinformation() {
		if (this.isDuplicateEmployeeNo) {
			this.showValidationDuplicateToast();
			return;
		}
		const isWaitingEffectiveOnly = true;
		this.employeeMovementService
			.getEmployeeMovementTransactions(this.employeeModel.employeeId, this.movementTypeId, isWaitingEffectiveOnly)
			.then((res) => {
				if (res.length > 0 && this.editType == EditType.movement) {
					return this._toastHelperService.validation({
						title: this.translate.instant("common_error"),
						description: this.translate.instant("emp001_movement_warning_3", { movementCode: res[0].movementCode }),
					});
				} else {
					this.isSubmittedWorkinformation = true;
					if (this.formCompare.invalid) {
						return this._toastHelperService.validation({
							title: this.translate.instant("toast_title_headsup"),
							description: this.translate.instant("toast_common_complete_all_fields"),
						});
					}
					this.loadConfirm();
					const dialogModel = <DialogInformationModel>{
						title: this.confirmation,
						description: this.editConfirmation,
						imageUrl: "confirmation.png",
						textButtonConfirm: this.translate.instant("common_confirm"),
						textButtonCancel: this.translate.instant("common_cancel"),
					};
					firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
						if (response?.confirm) {
							if (this.employeeModel.employeeId == this.userInfo.employeeId && this.movementTypeId == Movement.resign) {
								const dialogConfirm = <DialogInformationModel>{
									title: this.translate.instant("common_comfirm_resign_employee"),
									description: this.translate.instant("common_comfirm_resign_employee_description"),
									imageUrl: "confirmation.png",
									textButtonConfirm: this.translate.instant("comfirm_continue"),
									textButtonCancel: this.translate.instant("common_cancel"),
								};
								this._dialogService.Confirmation(dialogConfirm).subscribe((result) => {
									if (result?.confirm) {
										this.submitAPI();
									}
								});
							} else {
								this.submitAPI();
							}
						}
					});
				}
			});
	}
	isLoadInfoBar: boolean = false;
	submitAPI() {
		if (this.editType == EditType.movement) {
			this.isLoadInfoBar = true;
			let modelMovement = {
				employeeId: this.employeeModel.employeeId,
				employeeNo: this.employeeModel.employeeNo,
				isCash: this.employeeModel.isCash,
				rankNo: !isNullOrUndefined(this.employeeModel.rankNo) ? this.employeeModel.rankNo : null,
				positionId: !isNullOrUndefined(this.employeeModel.positionId) ? this.employeeModel.positionId : null,
				isReplaceEmployee: !isNullOrUndefined(this.employeeModel.isReplaceEmployee)
					? this.employeeModel.isReplaceEmployee
					: false,
				replaceEmployeeId: this.employeeModel.isReplaceEmployee == true ? this.employeeModel.replaceEmployeeId : null,
				upperSupervisorId:
					this.movementTypeId == Movement.resign
						? this.upperSupervisorId
						: this.employeeModel.upperSupervisorId ?? null,
				employeeStatus: !isNullOrUndefined(this.employeeModel.employeeStatus)
					? this.employeeModel.employeeStatus
					: null,
				employmentType: !isNullOrUndefined(this.employeeModel.employmentType)
					? this.employeeModel.employmentType
					: null,
				calendarId: !isNullOrUndefined(this.employeeModel.calendarId) ? this.employeeModel.calendarId : null,
				bankId: !isNullOrUndefined(this.employeeModel.bankId) ? this.employeeModel.bankId : null,
				bankAccountNo: !isNullOrUndefined(this.employeeModel.bankAccountNo) ? this.employeeModel.bankAccountNo : null,
				paymentMethod: !isNullOrUndefined(this.employeeModel.paymentMethod) ? this.employeeModel.paymentMethod : null,
				companyId: !isNullOrUndefined(this.employeeModel.companyId) ? this.employeeModel.companyId : null,
				orgLevelOne: !isNullOrUndefined(this.employeeModel.orgLevelOne) ? this.employeeModel.orgLevelOne : null,
				orgLevelTwo: !isNullOrUndefined(this.employeeModel.orgLevelTwo) ? this.employeeModel.orgLevelTwo : null,
				orgLevelThree: !isNullOrUndefined(this.employeeModel.orgLevelThree) ? this.employeeModel.orgLevelThree : null,
				orgLevelFour: !isNullOrUndefined(this.employeeModel.orgLevelFour) ? this.employeeModel.orgLevelFour : null,
				orgLevelFive: !isNullOrUndefined(this.employeeModel.orgLevelFive) ? this.employeeModel.orgLevelFive : null,
				orgLevelSix: !isNullOrUndefined(this.employeeModel.orgLevelSix) ? this.employeeModel.orgLevelSix : null,
				dateTermination: !isNullOrUndefined(this.employeeModel.dateTermination)
					? this.employeeModel.dateTermination
					: null,
				workLocationId: !isNullOrUndefined(this.employeeModel.workLocationId)
					? this.employeeModel.workLocationId
					: null,
				shiftId: !isNullOrUndefined(this.employeeModel.shiftId) ? this.employeeModel.shiftId : null,
				dateContractExpired: !isNullOrUndefined(this.employeeModel.dateContractExpired)
					? this.employeeModel.dateContractExpired
					: null,
				driverNo: !isNullOrUndefined(this.employeeModel.driverNo) ? this.employeeModel.driverNo : null,
				compareDataList: this.compareList,
				movementTypeId: this.movementTypeId,
				documentNo: this.formCompare.get("documentNo").value,
				processActionId: ProcessAction.sendDocument,
				isWorkInfomation: true,
				isAttendance: this.employeeModel.isAttendance,
				dateDocument: this.dateDocument,
				dateEffective: this.dateEffective,
				remarks: this.formCompare.get("remarks").value,
				terminationReasonId: this.formCompare.get("terminationReasonId").value,
				dateEmployment: this.employeeModel?.dateEmployment,
			};

			// 'movementTypeId': !isNullOrUndefined(this.movementTypeId) ? this.movementTypeId : null,
			// 'processActionId': ProcessAction.sendDocument,
			// 'isApprove': this.isESS009_T01 ? this.isApprove : false,
			// 'isWorkInfomation': true

			this.employeeMovementService.addEmployeeMovementTransaction(modelMovement).subscribe((res) => {
				var apiResult = <ApiResult>res;
				if (apiResult.result) {
					this.formEmployeeSecondPage?.markAsPristine();
					this._toastHelperService.success({
						title: this.translate.instant("toast_title_success"),
						description: this.translate.instant("toast_movement_create_success"),
					});
					this.isLoadInfoBar = false;
					this.closeDialog();
					this.loadEmployeeInformation();
				} else {
					if (apiResult.isResignationFail) {
						this.showValidateSelfResignToast(apiResult);
					} else {
						if (apiResult?.errorField == "employeeNo" && apiResult?.isDuplicate) {
							this.isDuplicateEmployeeNo = true;
							this.showValidationDuplicateToast();
						} else if (apiResult?.message) {
							this._toastHelperService.validation({
								title: this.translate.instant("toast_title_sorry"),
								description: apiResult.message,
							});
						} else {
							this._toastHelperService.validation({
								title: this.translate.instant("toast_title_sorry"),
								description: this.translate.instant("toast_desc_movement_emp_cond_change_error"),
							});
						}
					}
				}
			});
		} else {
			this.employeeModel.compareDataList = this.compareList;
			if (this.onEdit$) this.onEdit$.unsubscribe();
			this.onEdit$ = this.employeeWorkInformationService
				.editEmployeeWorkInformation(this.employeeModel)
				.subscribe((res) => {
					this.isSubmittedWorkinformation = false;
					var apiResult = <ApiResult>res.data;
					if (apiResult.result) {
						this.formEmployeeSecondPage?.markAsPristine();
						this._toastHelperService.success({
							title: this.translate.instant("toast_title_success"),
							description: apiResult.message,
						});
						this.loadEmployeeInformation();
						this.closeDialog();
						this.saveWorkInfo = true;
					} else {
						if (apiResult.isResignationFail) {
							this.showValidateSelfResignToast(apiResult);
						} else {
							this._toastHelperService.validation({
								title: this.translate.instant("common_fail"),
								description: apiResult.message,
							});
						}
					}
				});
		}
	}

	public saveWorkInfo: boolean = false;
	saveEdit() {
		this.sharingService.pushGoogleTagManager("Employee_Employment_Details_Personal_Save");
		if (!this.permission.allowEdit) return;
		this.editSubmittedFirstPage = true;
		if (this.formEmployeeFirstPage.valid) {
			this.loadConfirm();
			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.editConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			if (this.selfUpdate) {
				this.editEmployeeInformation();
			} else {
				firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
					if (response?.confirm) {
						this.editEmployeeInformation();
					}
				});
			}
		} else {
			this._toastHelperService.validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
		}
	}

	editEmployeeInformation() {
		this.employeeModel.dateOfBirth = !isNullOrUndefined(this.employeeModel.dateOfBirth)
			? new Date(this.datePipe.transform(this.employeeModel.dateOfBirth, "yyyy-MM-dd"))
			: null;
		this.employeeModel.dateWorkPermitExpire = !isNullOrUndefined(this.employeeModel.dateWorkPermitExpire)
			? new Date(this.datePipe.transform(this.employeeModel.dateWorkPermitExpire, "yyyy-MM-dd"))
			: null;
		this.employeeModel.dateWorkPermitIssue = !isNullOrUndefined(this.employeeModel.dateWorkPermitIssue)
			? new Date(this.datePipe.transform(this.employeeModel.dateWorkPermitIssue, "yyyy-MM-dd"))
			: null;
		this.employeeModel.isSendEmailConfirm = this.isAddress ? this.isFirstTimeSetEmail || this.isEmailChanged : false;
		if (this.onEdit$) this.onEdit$.unsubscribe();
		this.onEdit$ = this.employeeService.editEmployeeInformation(this.employeeModel).subscribe((res) => {
			var apiResult = <ApiResult>res;
			this.editSubmittedFirstPage = false;
			if (apiResult.result) {
				if (this.selfUpdate) {
					this.selfUpdateEvent.emit({ header: "emp001_address", index: 1 });
				} else {
					this.statusEvent.emit(true);
				}
				this.isSendEmailTutorial = false;
				this.isSendEmailOnboarding = false;
			} else {
				this.isFromMobile ? this.errorMessage.emit(apiResult) : this._toastHelperService.alertApiResult(apiResult);
			}
		});
	}

	checkAmount() {
		if (!this.employeeModel.amount || isNullOrUndefined(this.employeeModel.amount)) {
			this.employeeModel.amount = 0;
		}
	}

	createModel() {
		const isEmpty = !Object.values(this.employeeContactPerson).some((x) => !isNullOrUndefined(x) && x !== "");
		this.employeeAddress.addressType = 1;
		this.employeeAddressContact.addressType = 2;
		let modelTmp = Array<EmployeeAddress>();
		if (this.employeeAddress.address != "" && !isNullOrUndefined(this.employeeAddress.address)) {
			modelTmp.push(this.employeeAddress);
		}
		if (this.employeeAddressContact.address != "" && !isNullOrUndefined(this.employeeAddressContact.address)) {
			modelTmp.push(this.employeeAddressContact);
		}
		this.employeeModel.employeeAddressModel = modelTmp.length > 0 ? modelTmp : [];
		this.employeeModel.employeePersonalContactModel = isEmpty ? null : this.employeeContactPerson;
	}

	saveAddress() {
		this.sharingService.pushGoogleTagManager("Employment_Details_Contact_Save");
		if (!this.permission.allowEdit) return;
		if (!this.formEmployeeFourthPage.valid) {
			this._toastHelperService.validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
			return;
		}
		if (this.selfUpdate) {
			this.onSubmitEmployee();
		} else {
			this.loadConfirm();
			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.editConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.onSubmitEmployee();
				}
			});
		}
	}

	private editEmployee$: Subscription;
	private onEmployeeAddress$: Subscription;
	private onEmployeeContactPerson$: Subscription;
	apiResult: ApiResult = new ApiResult();

	isSubmitted: boolean = false;
	onSubmitEmployee() {
		this.isSubmitted = true;
		this.employeeModel.dateOfBirth = !isNullOrUndefined(this.employeeModel.dateOfBirth)
			? new Date(this.datePipe.transform(this.employeeModel.dateOfBirth, "yyyy-MM-dd"))
			: null;
		this.employeeModel.isSendEmailConfirm = this.isAddress ? this.isFirstTimeSetEmail || this.isEmailChanged : false;
		if (this.editEmployee$) this.editEmployee$.unsubscribe();
		this.editEmployee$ = this.employeeService.editEmployeeInformation(this.employeeModel).subscribe((res) => {
			this.apiResult = <ApiResult>res;
			this.isSendEmailTutorial = false;
			this.isSendEmailOnboarding = false;
			this.isSubmitted = false;
			this.onSubmitAddress();
		});
	}

	employeeAddressSubmit: Array<EmployeeAddress> = [];
	onSubmitAddress() {
		if (this.employeeAddress.employeeAddressId == 0) {
			this.employeeAddressSubmit = [
				{
					employeeId: this.id,
					employeeAddressId: 0,
					addressType: 1,
					address: this.employeeAddress.address,
					subdistrict: this.employeeAddress.subdistrict,
					districtId: !isNullOrUndefined(this.employeeAddress.districtId) ? this.employeeAddress.districtId : null,
					provinceId: !isNullOrUndefined(this.employeeAddress.provinceId) ? this.employeeAddress.provinceId : null,
					postalCode: this.employeeAddress.postalCode,
					phoneNo: this.employeeAddress.phoneNo,
				},
				{
					employeeId: this.id,
					employeeAddressId: 0,
					addressType: 2,
					address: this.employeeAddressContact.address,
					subdistrict: this.employeeAddressContact.subdistrict,
					districtId: !isNullOrUndefined(this.employeeAddressContact.districtId)
						? this.employeeAddressContact.districtId
						: null,
					provinceId: !isNullOrUndefined(this.employeeAddressContact.provinceId)
						? this.employeeAddressContact.provinceId
						: null,
					postalCode: this.employeeAddressContact.postalCode,
					phoneNo: this.employeeAddressContact.phoneNo,
				},
			];
			if (this.onEmployeeAddress$) this.onEmployeeAddress$.unsubscribe();
			this.onEmployeeAddress$ = this.employeeAddressService
				.addEmployeeAddress(this.employeeAddressSubmit)
				.subscribe((res) => {
					this.apiResult.result = this.apiResult.result && <boolean>res;
					this.onSubmitContactPerson();
				});
		} else {
			this.employeeAddressSubmit = [
				{
					employeeId: !isNullOrUndefined(this.id) ? this.id : this.employeeAddress.employeeId,
					employeeAddressId: this.employeeAddress.employeeAddressId,
					addressType: 1,
					address: this.employeeAddress.address,
					subdistrict: this.employeeAddress.subdistrict,
					provinceId: !isUndefined(this.employeeAddress.provinceId) ? this.employeeAddress.provinceId : null,
					districtId: !isUndefined(this.employeeAddress.districtId) ? this.employeeAddress.districtId : null,
					postalCode: this.employeeAddress.postalCode,
					phoneNo: this.employeeAddress.phoneNo,
				},
				{
					employeeId: !isNullOrUndefined(this.id) ? this.id : this.employeeAddressContact.employeeId,
					employeeAddressId: this.employeeAddressContact.employeeAddressId,
					addressType: 2,
					address: this.employeeAddressContact.address,
					subdistrict: this.employeeAddressContact.subdistrict,
					provinceId: !isUndefined(this.employeeAddressContact.provinceId)
						? this.employeeAddressContact.provinceId
						: null,

					districtId: !isUndefined(this.employeeAddressContact.districtId)
						? this.employeeAddressContact.districtId
						: null,
					postalCode: this.employeeAddressContact.postalCode,
					phoneNo: this.employeeAddressContact.phoneNo,
				},
			];
			if (this.onEmployeeAddress$) {
				this.onEmployeeAddress$.unsubscribe();
			}
			this.onEmployeeAddress$ = this.employeeAddressService
				.editEmployeeAddress(this.employeeAddressSubmit)
				.subscribe((res) => {
					this.apiResult.result = this.apiResult.result && <boolean>res;
					this.onSubmitContactPerson();
					this.isSaveAddress = true;
				});
		}
	}
	public isSaveAddress: boolean = false;
	onSubmitContactPerson() {
		this.employeeContactPerson.employeeId = this.id;
		if (isNullOrUndefined(this.employeeContactPerson.contactPersonId)) {
			if (this.onEmployeeContactPerson$) this.onEmployeeContactPerson$.unsubscribe();
			this.onEmployeeContactPerson$ = this.employeeAddressService
				.addEmployeeContactPerson(this.employeeContactPerson)
				.subscribe((res) => {
					this.apiResult.result = this.apiResult.result && <boolean>res;
					if (this.apiResult.result) {
						if (this.selfUpdate) {
							this.changePage("emp001_family_information", 1);
						} else {
							this.loadEmployeeInformation();
							this.loadAddressInformation();
						}
					}

					if (!this.isFromMobile && !this.selfUpdate) this._toastHelperService.alertApiResult(this.apiResult);
				});
		} else {
			if (this.onEmployeeContactPerson$) this.onEmployeeContactPerson$.unsubscribe();
			this.onEmployeeContactPerson$ = this.employeeAddressService
				.editEmployeeContactPerson(this.employeeContactPerson)
				.subscribe((res) => {
					this.apiResult.result = this.apiResult.result && <boolean>res;
					if (this.apiResult.result) {
						if (this.selfUpdate) {
							this.changePage("emp001_family_information", 1);
						} else {
							this.loadEmployeeInformation();
							this.loadAddressInformation();
						}
					}

					if (!this.isFromMobile && !this.selfUpdate) this._toastHelperService.alertApiResult(this.apiResult);
				});
		}
	}

	changePage(header: string, index: number) {
		this.selfUpdateEvent.emit({ header: header, index: index });
	}

	loadConfirm() {
		this.confirmation = this.translate.instant("common_confirmation");
		this.editConfirmation = this.translate.instant("common_edit_confirmation");
		this.addConfirmation = this.translate.instant("common_add_confirmation");
	}

	private getPathAttachment$: Subscription;

	removeAttachment(event) {
		this.deleteEmployeePicture(event.selectedItem.employeePictureId);
	}
	public setAttachment(att: any[]) {
		this.files = [];
		this.isUploadingimpPic = true;

		att
			?.filter((r) => r.data != null)
			.forEach((file, _index) => {
				this.files.push({
					data: file.data,
					attachmentMimeType: file.extension,
					attachmentFilename: file.filename,
					isActive: true,
					attachmentSize: file.fileSize,
					attachmentFile: file.data.split(",")[1],
					employeeId: this.id,
				});
			});
		let newFiles: any = this.files?.[this.files.length - 1];
		if (newFiles != null) {
			if (this.getPathAttachment$) this.getPathAttachment$.unsubscribe();
			this.getPathAttachment$ = this.commonService.getPathAttachmentEmployee(newFiles).subscribe((resPath) => {
				this.uploadData = <any[]>resPath.data;
				if (resPath.result) {
					this.uploadFileService.uploadFile(this.uploadData, "pathUpload", new HttpHeaders()).finally(() => {
						var data = {
							employeePictureId: this.uploadData[0].employeePictureId,
						};
						this.commonService.activeEmployeePicture(data).subscribe(() => {
							this.loadEmployeePicture();
						});
						let toast: ToastDataModel = {
							title: this.translate.instant("toast_title_success"),
							description: this.translate.instant("common_upload_pic"),
						};
						this._toastHelperService.success(toast);
					});
				} else {
					let toast: ToastDataModel = {
						title: this.translate.instant("common_warning"),
						description: resPath.message,
					};
					this._toastHelperService.warning(toast);
					this.loadEmployeePicture();
				}
			});
		} else {
			this.isUploadingimpPic = false;
		}
	}
	deleteEmployeePicture(employeePictureId) {
		if (employeePictureId > 0) {
			this.employeeMovementsService.deleteEmployeePicture(employeePictureId).then((res) => {
				let apiResult = <ApiResult>res.data;
				this.loadEmployeePicture();
				this._toastHelperService.alertDeletePicture(apiResult);
			});
		} else {
			this.employeeMovementsService.deleteEmployeeFaceScanByEmployeeId(this.id).then((resScan) => {
				let apiResult = <ApiResult>resScan.data;
				this.loadEmployeePicture();
				this._toastHelperService.alertDeletePicture(apiResult);
			});
		}
	}

	onChangeEmailTutorial() {
		this.employeeModel.isSendEmailTutorial = this.isSendEmailTutorial;
	}

	onChangeEmailOnboard() {
		this.employeeModel.isSendEmailOnboarding = this.isSendEmailOnboarding;
	}

	previewOnboarding() {
		if (this.employeeModel?.employeeId) {
			this.accessTokenByNewEmployeeId$ = this.onboardingNewEmployeeService
				.getAccessTokenByNewEmployeeId(this.employeeModel.employeeId)
				.subscribe((res) => {
					if (res.data) {
						window.open(`${environment.redirectUri}?token=${res.data}`, "_blank", "noopener");
					}
				});
		}
	}

	backToProfile(mixpanelTagName: string) {
		this.sharingService.pushGoogleTagManager(mixpanelTagName);
		this.isEdit = false;
		this.data.setEmpId(this.id);
		this.router.navigate([`/employee/${this.employeeModel.employeeNo}`]);
	}

	selectBranch(e) {
		this.employeeModel.workLocationId = e.selectValue;
	}

	getDropdownBranchSSO(isReset: boolean = true) {
		firstValueFrom(this.companyProfileService.getCompanyBranchsDDL(this.employeeModel.companyId, 0, false)).then(
			(res) => {
				this.workLocationDDL = res;
			},
		);
		if (isReset) this.clareWorkIn();
	}

	onOpenAttachments(event: SelectedEventArgs) {
		const attachment: Attachment = event?.selectedItem;
		attachment.attachmentId = event?.selectedItem?.employeePictureId;
		this.attachmentService.open(this.attachment, attachment.attachmentId);
	}
	openDialogSubordinate(event) {
		this.isDialogSubordinate = event;
	}
	@ViewChild(DataTableDirective, { static: false })
	public dtElement: DataTableDirective;
	public dtOptions: DataTables.Settings = {
		processing: false,
		searching: false,
		paging: false,
		info: false,
		autoWidth: false,
		columnDefs: [{ orderable: false, targets: "_all" }],
	};
	public isDialogSubordinate: boolean = false;
	public searchWorkflow: WorkflowSearch;
	public workflowStep: any = null;
	public nextApproval: string = "";
	loadWorkflow() {
		this.searchWorkflow = new WorkflowSearch();
		this.searchWorkflow.moduleId = ModuleWorkflow.Movement;
		this.searchWorkflow.referenceId = this.movementTypeId;
		this.searchWorkflow.tenantId = this.userInfo.tenantId;
		this.searchWorkflow.userId = this.employeeModel.userId;
		this.searchWorkflow.isGroup = true;
		this.workflowService.getWorkflowStepNoCore(this.searchWorkflow).then((res) => {
			this.workflowStep = res.data.data;
			if (this.workflowStep.length > 1) {
				this.nextApproval =
					this.language == "th-TH" ? this.workflowStep[1]?.employeeName : this.workflowStep[1]?.employeeName_EN;
			} else {
				this.nextApproval = this.translate.instant("common_no_approval");
			}
		});
	}

	openPopup(e) {
		if (!this.userInfo.hasPayroll) {
			e.cancel = true;
		} else {
			e.cancel = false;
		}
	}

	openDialogUpgradePlan() {
		this.dialogUpgradePlan = true;
	}

	onClearTitle() {
		this.employeeModel.titleId = null;
		this.employeeModel.titleName = null;
		this.employeeModel.titleName_EN = null;
		this.formEmployeeFirstPage.get("titleId").patchValue(null);
	}

	onSelectedTitle(e) {
		this.employeeModel.titleId = e?.value;
		this.employeeModel.titleName = e?.name;
		this.employeeModel.titleName_EN = e?.name_EN;
		this.formEmployeeFirstPage.get("titleId").patchValue(e?.value);

		// default gender after selected title
		if (this.titlesDDL.some((s) => s.id == e?.value)) {
			let genderId = this.titlesDDL.find((f) => f.id == e?.value).valueNum;
			this.employeeModel.gender = genderId;
			this.formEmployeeFirstPage.get("gender").patchValue(genderId);
		}
	}

	newTab(employeeNo) {
		window.open(`employee/${employeeNo}`);
	}

	checkTypePayment(event) {
		var paymentType = event.selectedItem.dataId;
		this.isChangePaymentType = this.currentPaymentType != null && paymentType != this.currentPaymentType;
	}

	gotoManual() {
		window.open(
			"https://help.empeo.com/th/article/4lib4liy4lij4lma4lib4lil4li14lmi4lii4liz4lib4lij4liw4lma4lig4lix4lib4liy4lij4lii4lmi4liy4lii4lma4lih4li04liz4lij4liw4lir4lin4lmi4liy4lih4lih4lin4liu-1193zou/",
		);
	}
	public isPublicDomain: boolean = false;

	checkPublicDomain() {
		this.isPublicDomain =
			this.employeeModel?.emailAddress?.search(
				"@hotmail.com|@yahoo.com|@outlook.com|@outlook.co.th|@gmail.com|mail.com|@icloud.com",
			) > -1;
	}
	skipPayroll() {
		this.currentEmployeeIndex++;
	}

	tmpSaveCustomField: number = 0;

	saveCustomField() {
		this.sharingService.pushGoogleTagManager("Employee_Employment_Details_Personal_Save");
		this.tmpSaveCustomField = new Date().getTime();
	}

	changeToggleSelfUpdate() {
		this.employeeModel.isSelfUpdate = !this.employeeModel.isSelfUpdate;
	}

	checkSelfUpdate() {
		if (this.selfUpdate) {
			this.selfUpdateEvent.emit({ header: "emp001_additional_information", index: 1 });
		}
	}

	onShowDiscardChange(e) {
		this.additionalFieldChanged.emit(e);
	}
	get effectiveDate() {
		var data = new Date(this.dateEffective);
		data.setDate(this.dateEffective?.getDate() - 1);
		return data;
	}
	test: boolean = false;
	toggleClick(e) {
		if (!this.test) {
			e.cancel = true;
			this.test = true;
			const dialogModel = <DialogInformationModel>{
				title: this.translate.instant("upload_pic_title"),
				description: `<div class='dialog-circle-container'> <span class='dialog-circle'></span> <span>${this.translate.instant(
					"upload_pic_description_headshot",
				)} </span></div><div class='dialog-circle-container'> <span class='dialog-circle'></span> <span>${this.translate.instant(
					"upload_pic_description_bg",
				)} </span></div><div class='dialog-circle-container'> <span class='dialog-circle'></span> <span>${this.translate.instant(
					"upload_pic_description_closeup",
				)} </span></div><div class='dialog-circle-container'> <span class='dialog-circle'></span> <span>${this.translate.instant(
					"upload_pic_description_dontwear",
				)} </span></div>`,
				imageUrl: "emsume-Avatar.png",
				textButtonConfirm: this.translate.instant("common_upload"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.upload?.clickToggle();
					setTimeout(() => {
						this.test = false;
					}, 150);
				} else {
					this.test = false;
				}
			});
		}
	}

	private showValidationDuplicateToast() {
		this._toastHelperService.validation({
			title: this.translate.instant("toast_title_sorry"),
			description: this.translate.instant("toast_employee_no_exist_desc"),
		});
	}

	private showValidateSelfResignToast(apiResult = null) {
		if (!apiResult.result) {
			this.resignApiError = true;
			this.warningBoxMessage = this.language == LanguageLocal.th ? apiResult.warning : apiResult.warning_EN;
		}
		let description = "";
		if (apiResult.result && apiResult.message != "" && apiResult.message_EN != "") {
			description = this.language == LanguageLocal.th ? apiResult.message : apiResult.message_EN;
		} else {
			description = this.translate.instant("toast_common_failed_to_proceed");
		}

		this._toastHelperService.validation({
			title: this.translate.instant("toast_title_sorry"),
			description: description,
		});
	}
	getShiftInfo(event) {
		this.currentShift = event.selectedItem;
		this.isShowInfo = this.tmpShiftId !== this.currentShift?.id;
	}

	private capitalizeFirstLetter(value: string): string {
		if (!value) return value;
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	onNameENChange(event: any) {
		this.employeeModel.name_EN = this.capitalizeFirstLetter(event.target.value);
	}

	onSurnameENChange(event: any) {
		this.employeeModel.surname_EN = this.capitalizeFirstLetter(event.target.value);
	}

	onNickNameENChange(event: any) {
		this.employeeModel.nickName_EN = this.capitalizeFirstLetter(event.target.value);
	}

	onSelectCardType(e) {
		this.employeeSocialSecurity.typeCardSSO = e.selectedItem[this.fieldsMasterTypeDDL.value];
	}
	clearCardType() {
		this.employeeSocialSecurity.typeCardSSO = null;
	}

	onSelectHospital(e, hospitalKey) {
		const hospitalIdKey = `${hospitalKey}`;
		if (this.employeeSocialSecurity.hasOwnProperty(hospitalIdKey)) {
			this.employeeSocialSecurity[hospitalIdKey] = e.selectedItem[this.fieldsHospitalDDL.value];
		}
		this.onSearchHospital("");
	}
	clearHospital(hospitalKey) {
		const hospitalIdKey = `hospitalId${hospitalKey}`;
		if (this.employeeSocialSecurity.hasOwnProperty(hospitalIdKey)) {
			this.employeeSocialSecurity[hospitalIdKey] = null;
		}
		this.onSearchHospital("");
	}

	onSearchHospital(search: string) {
		this.commonService.getHospitalSSODDL(search).then((res) => {
			this.hospitalDDL = res;
		});
	}

	onFilterHospital(e) {
		this.onSearchHospital(e.text);
	}

	onSelectReason(event) {
		this.employeeSocialSecurity.terminationReasonSSOId = event.value;
	}

	clearReason() {
		this.employeeSocialSecurity.terminationReasonSSOId = null;
	}
	onChangeSecurityBranch() {
		this.isSetSocialSecurityBranch = !this.isSetSocialSecurityBranch;
		if (!this.isSetSocialSecurityBranch) {
			this.clearBranchSSO();
		}
	}

	onSelectNationality(e) {
		this.employeeSocialSecurity.nationalitySSOId = e.selectedItem[this.fieldsMasterTypeDDL.value];
	}

	selectedBranchId(e) {
		this.employeeSocialSecurity.branchId = e.selectValue;
	}

	clearBranchSSO() {
		this.employeeModel.branchIdSSO = null;
	}

	clearKorTor20() {
		this.employeeModel.businessCodeSSO = null;
		this.employeeModel.rateSSO = null;
	}

	onChangeKorTor20() {
		this.isSetKorTor20 = !this.isSetKorTor20;
		if (!this.isSetKorTor20) {
			this.clearKorTor20;
		}
	}

	saveSocialSecurity() {
		this.saveEmployeeSocialSecurity();
		if (this.selfUpdate) {
			this.changePage("emp001_additional_information", 1);
		}
	}
}
