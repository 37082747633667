import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Employee, SearchPeoplePickerModel } from "../../shared/employee-movements.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { CommonService } from "../../../shared/service/common.service";
import { EmployeeCentricSearch } from "../../shared/employee-document.model";
import { SharingService } from "src/app/shared/sharing.service";
import { Application } from "src/app/shared/enum/application.enum";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		@Inject("CLIENT_PORTAL") private apiClientUrl: string,
		@Inject("GOFIVE_API_URL") private apiCoreUrl: string,
		private commonService: CommonService,
		private sharing: SharingService,
	) {}

	getEmployee(dataTablesParameters: any) {
		return firstValueFrom(
			this.http.post<DataTablesResponse>(this.apiUrl + "Employee/GetEmployee", dataTablesParameters, {
				params: dataTablesParameters,
			}),
		);
	}

	getEmployeeShiftInMonth(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Employee/GetEmployeeShiftDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getEmployeeWelfareDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Employee/GetEmployeeWelfareDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}
	getEmployeeSubordinate(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Employee/GetEmployeeSubordinate", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getCurrentEmployeeCode(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/GetCurrentEmployeeCode/" + companyId)).then(
			(res) => <string>res,
		);
	}

	addEmployee(emp: Employee) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/AddEmployee", body, httpOptions);
	}

	editEmployeeInformation(emp: Employee) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/EditEmployeeInformation", body, httpOptions);
	}
	editEmployeeInformationByTax(emp: Employee) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/EditEmployeeInformationByTax", body, httpOptions);
	}

	deleteEmployee(employeeId: string[]) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/DeleteEmployee", body, httpOptions);
	}

	sendWelcomeEmployeeEmailByTemplate(employeeId: number) {
		var body = JSON.stringify(employeeId);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/SendWelcomeEmployeeEmailByTemplate", body, httpOptions);
	}
	importEmployeeNoByExcel(file: string) {
		var body = JSON.stringify(file);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/ImportEmployeeNoByExcel", body, httpOptions);
	}

	getPeoplePicker(model: SearchPeoplePickerModel) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Employee/GetPeoplePicker", body, httpOptions);
	}

	getEmployeeByCalendarIdss(companyId: number, calendarId?: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Employee/GetEmployeeByCalendarId/" + companyId + "/" + calendarId),
		).then((res) => <any>res);
	}

	getPeoplePickers() {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/GetSupervisorChartByEmployeeId/37365/2")).then(
			(res) => <any>res,
		);
	}

	getProfilesByEmployeeIds(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Employee/GetProfilesByEmployeeIds", body, httpOptions);
	}

	getTotalEmployee() {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/GetTotalEmployee/")).then((res) => <number>res);
	}

	getEmployeeByCalendarId(companyId: number, calendarId?: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Employee/GetEmployeeByCalendarId/" + companyId + "/" + calendarId),
		).then((res) => <any>res);
	}

	getTotalEmployeeByCalendarId(companyId: number, calendarId?: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Employee/GetTotalEmployeeByCalendarId/" + companyId + "/" + calendarId),
		).then((res) => <number>res);
	}

	updateEmployeeCalendars(emp: any[]) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "Employee/UpdateEmployeeCalendars", body, httpOptions);
	}

	getPINByEmployeeId(empId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/GetPINByEmployeeId/" + empId)).then((res) => <any>res);
	}

	updateEmployeePin(empModel: any) {
		var body = JSON.stringify(empModel);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Employee/UpdateEmployeePin", body, httpOptions);
	}

	updateSelfService(empId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/UpdateSelfUpdate/" + empId)).then((res) => <any>res);
	}

	updateSelfServiceStepNo(empId: number, stepNo: number) {
		return this.http.get(this.apiUrl + "Employee/UpdateSelfServiceStepNo/" + empId + "/" + stepNo);
	}

	searchEmployeeCentric(searchModel: EmployeeCentricSearch) {
		var body = JSON.stringify(searchModel);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Employee/GetEmployeeCentric", body, httpOptions);
	}
	convertApplicantToEmployee(model: any) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Recruitment/ConvertApplicantToEmployee", body, httpOptions);
	}

	checkEmployeeIdCard(idCard: any) {
		return this.http.get(this.apiUrl + "Employee/CheckEmployeeIdCard/" + (idCard || null));
	}

	updateSecretKey2FA(model: any) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Employee/updateSecretKey2FA", body, httpOptions);
	}

	update2FA(employeeId, is2FA) {
		return this.http.get(`${this.apiUrl}Employee/Update2FA/${employeeId}/${is2FA}`);
	}

	auth2FA(employeeId, otp) {
		return this.http.get(`${this.apiUrl}Employee/Auth2FA/${employeeId}/${otp}`);
	}

	sendMailReset2FA(employeeId: boolean) {
		return this.http.get(`${this.apiUrl}Employee/SendMailReset2FA/${employeeId}`);
	}
	disabledUserInformation(employeeId) {
		return this.http.delete(`${this.apiUrl}Employee/DisabledUserInformation/${employeeId}`);
	}
	reset2FAandSecretKey(employeeId) {
		return this.http.get(`${this.apiUrl}Service/Reset2FAandSecretKey/${employeeId}`);
	}

	getCriminalChecker(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(`${this.apiCoreUrl}v1/criminal-checker`, body, httpOptions);
	}
	getTokenAppMan(employeeId) {
		return this.http.get<any>(`${this.apiCoreUrl}v1/appman/token?employeeId=${employeeId}`);
	}
	createProprietors(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(`${this.apiCoreUrl}v1/criminal-checker/proprietors`, body, httpOptions);
	}
	withdrawMoney(model, clientId) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(`${this.apiClientUrl}api/GofiveCredit/${clientId}/pay`, body, httpOptions);
	}

	GetCreditBalance(clientId) {
		return this.http.get<any>(`${this.apiClientUrl}api/GofiveCredit/${clientId}/balance`);
	}

	downloadDocumentResult(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(`${this.apiCoreUrl}v1/criminal-checker/document/result`, body, httpOptions);
	}

	getLimitUserStatus(search: any = "") {
		const searchModel = { ...search, applicationId: Application.empeoId };
		return this.http.get<any>(`${this.apiCoreUrl}v1/users/subscription${this.sharing.getQueryString(searchModel)}`);
	}

	sendPayrollDocumentByEmployeeId(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(`${this.apiUrl}employee/payroll/email/send-document`, body, httpOptions);
	}

	CheckEmployeeNo(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(`${this.apiUrl}v1/employees/checks/employee-no`, body, httpOptions);
	}

	getEmployeeContactInformationById(employeeId) {
		return this.http.get(`${this.apiUrl}v1/employees/${employeeId}/contact-information`);
	}

	getEmployeeSkillsById(employeeId) {
		return this.http.get(`${this.apiUrl}v2/employees/${employeeId}/skills`);
	}

	getEmployeeTeam(searchModel) {
		return this.http.get(`${this.apiCoreUrl}v3/my/team${this.sharing.getQueryString(searchModel)}`);
	}

	getEmployeeAccountInformationById(employeeId) {
		return this.http.get(`${this.apiUrl}v1/employees/${employeeId}/account-information`);
	}

	editEmployeeAccountInformation(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(`${this.apiUrl}v1/employees/account-information`, body, httpOptions);
	}

	userIdToResetPassword(userId: string) {
		let model = {
			userId: userId,
		};
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json", "x-AppName": "empeo" }),
		};
		return this.http.post<any>(`${this.apiCoreUrl}v1/users/reset-password`, model, httpOptions);
	}

	userIdUnlock(userId: string) {
		let model = {
			userId: userId,
		};
		return this.http.post<any>(`${this.apiCoreUrl}v1/users/unlock`, model);
	}
}
