export enum TimeAttandanceStatus {
	normal = 1,
	absent = 2,
	late = 3,
	early = 4,
	noStampIn = 5,
	noStampOut = 6,
	holiday = 7,
	holidayWork = 8,
	leave = 9,
}
