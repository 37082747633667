import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeTimeAttendance } from "../../employee-movements/employee-time-attendance/shared/employee-time-attendance.model";
import { UserTimeAttendance } from "../../employee-movements/employee-time-attendance/shared/user-time-attendance.model";
import { EmployeeChainOfCommandSearchModel, MssSearchModel } from "src/app/shared/models/mss.model";
import { isNullOrUndefined } from "@gofive/angular-common";
import { SharingService } from "src/app/shared/sharing.service";
import { CommonService } from "src/app/shared/service/common.service";
import { firstValueFrom } from "rxjs";
import { ApiResult } from "src/app/shared/models/api-result.model";

@Injectable()
export class DashboardService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		@Inject("GOFIVE_API_URL") private apiCoreUrl: string,
		@Inject("LEARN_API_URL") private apiLearn: string,
		private sharing: SharingService,
		private commonService: CommonService,
	) {}
	getMyCalendar(sDate: string, nDate: string) {
		var minuteGMT = new Date().getTimezoneOffset();
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "Dashboard/GetMyCalendar/" + sDate + "/" + nDate + "?minuteGMT=" + minuteGMT),
		)
			.then((res) => <any[]>res)
			.then((data) => data);
	}

	getTeamCalendar(sDate: string, nDate: string) {
		var minuteGMT = new Date().getTimezoneOffset();
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "Dashboard/GetTeamCalendar/" + sDate + "/" + nDate + "?minuteGMT=" + minuteGMT),
		)
			.then((res) => <any[]>res)
			.then((data) => data);
	}

	getCalendarByEmployeeId(sDate: string, nDate: string, employeeId: number) {
		var minuteGMT = new Date().getTimezoneOffset();
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl +
					"Dashboard/GetCalendarByEmployeeId/" +
					sDate +
					"/" +
					nDate +
					"/" +
					employeeId +
					"?minuteGMT=" +
					minuteGMT,
			),
		)
			.then((res) => <any[]>res)
			.then((data) => data);
	}

	checkPolicies(model) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiCoreUrl + "v1/Policies", body, this.httpOptions);
	}
	getTeamCalendarByEmployeeId(sDate: string, nDate: string, employeeId: number) {
		var minuteGMT = new Date().getTimezoneOffset();
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl +
					"Dashboard/GetTeamCalendarByEmployeeId/" +
					sDate +
					"/" +
					nDate +
					"/" +
					employeeId +
					"?minuteGMT=" +
					minuteGMT,
			),
		).then((res) => <any[]>res);
	}

	getDashboardNotification(total: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Dashboard/GetDashboardNotification/" + total)).then(
			(res) => <any[]>res,
		);
	}

	getDashboardNotificationByLeaveRequestIdAndEmployeeId(leaveRequestId: number, employeeId: number) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl +
					"Dashboard/GetDashboardNotificationByLeaveRequestIdAndEmployeeId/" +
					leaveRequestId +
					"/" +
					employeeId,
			),
		)
			.then((res) => <any[]>res)
			.then((data) => data);
	}

	getWorkflowStatuses(leaveRequestId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Profile/WorkflowStatuses/" + leaveRequestId))
			.then((res) => res)
			.then((data) => data);
	}

	getEventDetail(date: string, isSubordinate?: boolean, employeeId?: number) {
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "Dashboard/GetEventDetail/" + date + "/" + isSubordinate + "/" + employeeId),
		).then((data) => data);
	}

	getEventDetailByEmployeeId(date: string, employeeId: number) {
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "Dashboard/GetEventDetailByEmployeeId/" + date + "/" + employeeId),
		).then((data) => data);
	}

	getEmployeeLeaveToday(leaveCategoryId: number = 0) {
		return firstValueFrom(this.http.get(this.apiUrl + "Employee/GetEmployeeLeaveToday/" + leaveCategoryId)).then(
			(res) => <any[]>res,
		);
	}

	getEmployeeTimeAttendanceByUserId(userId: string, year: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "TimeAttendance/GetTimeAttendanceAbnormalByUserId/" + userId + "/" + year),
		).then((res) => <EmployeeTimeAttendance[]>res);
	}

	getEmployeeTimeAttendanceByEmployeeId(employeeId: number, year: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "TimeAttendance/GetEmployeeTimeAttendanceByEmployeeId/" + employeeId + "/" + year),
		).then((res) => <EmployeeTimeAttendance[]>res);
	}

	getAbnormalTimeAttendanceByEmployeeId(employeeId: number, year: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "TimeAttendance/GetAbnormalTimeAttendanceByEmployeeId/" + employeeId + "/" + year),
		).then((res) => <any[]>res);
	}

	getTotalListOfApprove(documentStatusId?: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Dashboard/GetTotalListOfApprove/" + documentStatusId)).then(
			(res) => <any[]>res,
		);
	}

	getTimeAttendance() {
		return firstValueFrom(this.http.get(this.apiUrl + "TimeAttendance/GetTimeAttendance/")).then(
			(res) => <UserTimeAttendance[]>res,
		);
	}

	getTeamActivities(searchModel: MssSearchModel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "v1/mss/activities", body, this.httpOptions);
	}

	getSummaryAttendances(searchModel: MssSearchModel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "v1/mss/summary-attendances", body, this.httpOptions);
	}

	getLeaveUsages(searchModel: MssSearchModel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "v1/mss/leave-usages", body, this.httpOptions);
	}

	getTimeStampMss(searchModel: MssSearchModel) {
		var minuteGMT = new Date().getTimezoneOffset();
		searchModel.minuteGMT = minuteGMT;
		this.commonService.changeDateJSON();
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "v1/mss/time-stamps", body, this.httpOptions);
	}

	getTeamTimeAttendanceSummary(searchModel: MssSearchModel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "v1/mss/time-attendance-summary", body, this.httpOptions);
	}

	getEmployeeSubordinateById(chainSearch: EmployeeChainOfCommandSearchModel) {
		let userId = isNullOrUndefined(chainSearch.userId) ? "" : chainSearch.userId;
		let employeeId = isNullOrUndefined(chainSearch.employeeId) ? "" : chainSearch.employeeId;
		return this.http.get<any>(
			this.apiUrl +
				"v1/mss/subordinates?userId=" +
				userId +
				"&employeeId=" +
				employeeId +
				"&isAll=" +
				chainSearch.isAll +
				"&defaultDirectReport=" +
				chainSearch.defaultDirectReport +
				"&type=" +
				chainSearch.type +
				"&isSupervisor=" +
				chainSearch.isSupervisor,
		);
	}

	getTotalListOfReviseDocument() {
		return firstValueFrom(this.http.get<ApiResult>(this.apiUrl + "Dashboard/GetTotalListOfReviseDocument"));
	}

	getRosterManagements(searchModel: MssSearchModel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "v1/mss/roster-managements", body, this.httpOptions);
	}

	getCourseEmployeeLatestByEmployeeId(model) {
		return this.http.get<any>(
			this.apiLearn + `CourseEmployee/GetCourseEmployeeLatestByEmployeeId${this.sharing.getQueryString(model)}`,
		);
	}

	getEventDetailWorkInByEmployeeId(date: string, employeeId?: number, isSubordinate?: boolean) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "Dashboard/GetEventDetailWorkInByEmployeeId/" + date + "/" + employeeId + "/" + isSubordinate,
			),
		).then((res) => <any>res);
	}
	getOKRNotification() {
		return firstValueFrom(this.http.get(this.apiUrl + "okr-kpi/check-in/notification")).then((res) => <any>res);
	}
	getGoalsNotification() {
		return firstValueFrom(this.http.get(this.apiUrl + "okr-kpi/reminder/notification")).then((res) => <any>res);
	}
}
