import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeLoan } from "./employee-loan.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeLoanService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getWelfareEmployeeLoanByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Welfare/GetWelfareEmployeeLoanByEmployeeId/" + employeeId)).then(
			(res) => <EmployeeLoan[]>res,
		);
	}
	closePaymentWelfareEmployeeLoans(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Welfare/ClosePaymentWelfareEmployeeLoans", body, httpOptions);
	}
	getWelfareEmployeeLoanByFinancialId() {
		return firstValueFrom(this.http.get(this.apiUrl + "Welfare/GetWelfareEmployeeLoanByFinancialId/")).then(
			(res) => <EmployeeLoan[]>res,
		);
	}
	getWelfareEmployeeLoadDDL() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Welfare/GetWelfareEmployeeLoadDDL/"));
	}
	getWelfareEmployeeLoans(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Welfare/GetWelfareEmployeeLoans", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	addWelfareEmployeeLoan(employeeloan: EmployeeLoan) {
		var body = JSON.stringify(employeeloan);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Welfare/AddWelfareEmployeeLoan", body, httpOptions);
	}

	exportReportEmployeeLoan(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Welfare/ExportReportEmployeeLoan", body, httpOptions);
	}

	getWelfareEmployeeLoanByLoanId(loanId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Welfare/GetWelfareEmployeeLoanByLoanId/" + loanId)).then(
			(res) => <EmployeeLoan>res,
		);
	}

	getWelfareEmployeeLoanDetailById(loanId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "welfares/loan-detail/" + loanId)).then((res) => <any>res);
	}

	editWelfareEmployeeLoan(employeeloan: EmployeeLoan) {
		var body = JSON.stringify(employeeloan);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Welfare/EditWelfareEmployeeLoan", body, httpOptions);
	}

	deleteWelfareEmployeeLoan(loanId: number) {
		var body = JSON.stringify(loanId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Welfare/DeleteWelfareEmployeeLoan", body, httpOptions);
	}
}
