export class UserTimeAttendance {
	userId: string;
	date: Date;
	date_TH: string;
	date_EN: string;
	shiftId: number;
	stampIn: Date;
	stampOut: Date;
	realStampIn: Date;
	realStampOut: Date;
	timeAttendanceStatusId: number;
	workTime: number;
	late: number;
	early: number;
	totalOfAbsent: number;
	totalOfLate: number;
	totalOfEarly: number;
	totalOfNoStampIn: number;
	totalOfNoStampOut: number;
	absentDay: number;
	absent: number;
	absent_TH: string;
	absent_EN: string;
	late_TH: string;
	late_EN: string;
	early_TH: string;
	early_EN: string;
	note: string;
	createdBy: string;
	dateCreated: Date;
	modifiedBy: string;
	dateModified: Date;
}
