import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { TaxRate } from "./tax-rate.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TaxRateService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getAllTaxRate() {
		return firstValueFrom(this.http.get(this.apiUrl + "TaxRate/GetAllTaxRate/")).then((res) => <TaxRate[]>res);
	}

	getTaxRateById(taxRateId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "TaxRate/GetTaxRateById/" + taxRateId)).then(
			(res) => <TaxRate>res,
		);
	}

	addTaxRate(tr: TaxRate) {
		var body = JSON.stringify(tr);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TaxRate/AddTaxRate", body, httpOptions);
	}

	editTaxRate(tr: TaxRate) {
		var body = JSON.stringify(tr);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TaxRate/EditTaxRate", body, httpOptions);
	}

	deleteTaxRate(taxRateId: number) {
		var body = JSON.stringify(taxRateId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TaxRate/DeleteTaxRate", body, httpOptions);
	}
}
