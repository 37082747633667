import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { Router } from "@angular/router";
import { SharingService } from "../../shared/sharing.service";
import { DropdownComponent } from "@gofive/angular-common";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { Message, SelectItem } from "primeng/api";
import { Subscription, firstValueFrom } from "rxjs";
import { isNullOrUndefined } from "src/app/shared/sharing.service";
import { DataSharingService, UserControlService } from "../../core";
import { LeaveUsageModel } from "../../document-forms/shared/document-forms.model";
import { DocumentFormsService } from "../../document-forms/shared/document-forms.service";
import { LeaveCategory } from "../../shared/enum/leave-category.enum";
import { Module } from "../../shared/enum/module.enum";
import { PageType } from "../../shared/enum/page-type.enum";
import { ApiResult } from "../../shared/models/api-result.model";
import { Dropdown } from "../../shared/models/dropdown.model";
import { Permission } from "../../shared/models/permission.model";
import { CommonService } from "../../shared/service/common.service";
import { EmployeeLeaveUsageService } from "./shared/employee-leave-usage.service";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { InfoBarType, BaseModule } from "@gofive/design-system-base";
import { LeaveUsageCustomSettingComponent } from "src/app/master/leave-type/leave-usage-custom-setting/leave-usage-custom-setting.component";
import { ThaiDatePipe } from "../../shared/pipe/thai-date.pipe";
import { AsyncPipe, DatePipe } from "@angular/common";
import { LeaveUsageCustomSettingComponent as LeaveUsageCustomSettingComponent_1 } from "../../master/leave-type/leave-usage-custom-setting/leave-usage-custom-setting.component";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { ButtonModule } from "@gofive/design-system-button";
import { DropdownModule } from "@gofive/design-system-dropdown";
import { TableModule } from "@gofive/design-system-table";

@Component({
	selector: "app-employee-leave-usage",
	templateUrl: "./employee-leave-usage.component.html",
	styleUrls: ["./employee-leave-usage.component.scss"],
	standalone: true,
	imports: [
		DropdownModule,
		BaseModule,
		ButtonModule,
		FormsModule,
		ReactiveFormsModule,
		DataTablesModule,
		TooltipModule,
		LeaveUsageCustomSettingComponent_1,
		AsyncPipe,
		DatePipe,
		TranslateModule,
		ThaiDatePipe,
		TableModule,
	],
})
export class EmployeeLeaveUsageComponent implements OnInit {
	//#region Variable
	@ViewChild(DataTableDirective, { static: true })
	dtElement: DataTableDirective;
	dtOptions: DataTables.Settings = {};

	confirmation: any;
	calConfirmation: any;
	addConfirmation: any;

	refYearDDL: Dropdown[];
	leaveTypeDDL: Dropdown[];
	formLeaveUsage: UntypedFormGroup;
	msgs: Message[];
	leaveUsage: LeaveUsageModel;
	leaveUsages: LeaveUsageModel[] = [];
	currentYear: number;
	yearId: any;
	totalDays: any;
	totalHours: any;
	totalCarryDays: any;
	totalCarryHours: any;
	leaveTypeId: any;
	isAdjust: boolean = false;
	isEdit: boolean = false;
	id: number;
	auth: any;
	isAdjustItem: SelectItem[] = [
		{ label: "cal_by_leave_type", value: false },
		{ label: "common_custom", value: true },
	];
	private subscription: Subscription;
	private calLeaveUsage$: Subscription;
	private editLeaveUsage$: Subscription;
	private year$: Subscription;

	@ViewChild("typeDDL") typeDDL: DropdownComponent;
	get InfoBarType() {
		return InfoBarType;
	}
	permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};

	public fieldsDDL = { text: "name", value: "id" };
	public fieldsDDL_EN = { text: "name_EN", value: "id" };
	private isDataLoaded = false;

	@Input() module: string = null;
	@Input() allowGrant: boolean = false;
	@Input() isHR: boolean = true;
	@Input() empHeader: string = "";
	@Input() checkPermission: boolean = true;
	@Input() displayBtnCalculate: boolean = false;
	@Input() displayEdit: boolean = true;
	@Input() leaveUsageCustomSettingComponent: LeaveUsageCustomSettingComponent;
	@Output() isCalLeaveBenefit = new EventEmitter<boolean>();
	//#endregion

	constructor(
		private sharingService: SharingService,
		private fb: UntypedFormBuilder,
		private translate: TranslateService,
		private employeeLeaveUsageService: EmployeeLeaveUsageService,
		private commonService: CommonService,
		private userControlService: UserControlService,
		private documentFormService: DocumentFormsService,
		public data: DataSharingService,
		private router: Router,
		private _toastHelperService: ToastHelperService,
		private _dialogService: DialogService,
		private _cdr: ChangeDetectorRef,
	) {}

	ngOnInit() {
		//Check Permission EMP001_T11 OR EMP006_T02
		if (this.checkPermission) {
			firstValueFrom(this.userControlService.authorizeControl(Module.EMP001_T11)).then((auth) => {
				this.auth = auth;
				this.auth.allowGrant = this.allowGrant ? this.allowGrant : this.auth.allowGrant;
				this.permission = this.userControlService.validatePermission(PageType.search, this.auth);
			});
		} else {
			this.permission.allowEdit = true;
		}

		this.leaveUsage = new LeaveUsageModel();

		//#region Form control
		this.formLeaveUsage = this.fb.group({
			year: new UntypedFormControl("", Validators.required),
			totalDays: new UntypedFormControl("", Validators.required),
			totalHours: new UntypedFormControl("", Validators.required),
			totalCarryDays: new UntypedFormControl(""),
			totalCarryHours: new UntypedFormControl(""),
			leaveTypeId: new UntypedFormControl("", Validators.required),
			isAdjust: new UntypedFormControl(false, Validators.required),
		});
		//#endregion

		this.gridLoad();

		//#region Call api
		this.loadDDLYear();

		this.subscription = this.data.currentEmpId.subscribe((data) => {
			this.id = data;

			if (this.id == 0) {
				this.router.navigate(["/employee"]);
			} else {
				this.loadData();
			}
		});

		//#endregion
	}

	ngOnChanges() {
		this.year$ = this.data.currentYear.subscribe((year) => {
			this.yearId = year;
		});

		this.loadData();
	}

	//#region Destroy unsubscribe
	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		if (this.calLeaveUsage$) this.calLeaveUsage$.unsubscribe();
		if (this.editLeaveUsage$) this.editLeaveUsage$.unsubscribe();
		if (this.year$) this.year$.unsubscribe();
	}
	//#endregion

	//#region Load data
	gridLoad() {
		this.dtOptions = {
			order: [[0, "asc"]],
			columnDefs: [{ orderable: false, targets: "_all" }],
			autoWidth: false,
			dom: "ltip",
			info: false,
			serverSide: true,
			processing: false,
			paging: false,
			searching: false,
			ajax: () => {},
		};
	}

	async loadDDLYear() {
		await this.commonService.getDDLYearRange(2).then((res) => {
			// +- 1 ปี
			this.refYearDDL = res;
		});

		this.commonService.getYearCutoff().then((res) => {
			this.currentYear = res;
			if (isNullOrUndefined(this.yearId)) {
				this.yearId = isNullOrUndefined(this.refYearDDL) ? null : this.currentYear;
			}
			if (!this.isDataLoaded) {
				this.loadData();
			}
		});
	}
	onReloadData(event) {
		if (event) {
			setTimeout(() => {
				this.loadData();
			}, 500);
		}
	}

	closeSidebar(event) {
		if (event) {
			this.isEdit = false;
		}
	}
	loadData() {
		if (this.id) {
			this.commonService.getDDLLeaveTypeByEmployeeIdAndLeaveCategoryId(this.id, LeaveCategory.leave).then((res) => {
				this.leaveTypeDDL = res;
				this._cdr.detectChanges();
			});
			if (this.yearId) {
				this.isDataLoaded = true;
				this.employeeLeaveUsageService.getLeaveUsageByEmployeeId(this.id, this.yearId).then((res) => {
					this.leaveUsages = !isNullOrUndefined(res) ? res : [];
					this._cdr.detectChanges();
				});
			}
		}
		this.formLeaveUsage?.get("year")?.enable();
		this.isEdit = false;
	}

	loadConfirm() {
		this.confirmation = this.translate.instant("common_confirmation");
		this.addConfirmation = this.translate.instant("common_add_confirmation");
		this.calConfirmation = this.translate.instant("common_cal_leave_confirmation");
	}
	//#end region

	//#region Action
	calculate() {
		this.loadConfirm();
		const dialogModel = <DialogInformationModel>{
			title: this.confirmation,
			description: this.calConfirmation,
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm"),
			textButtonCancel: this.translate.instant("common_cancel"),
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				if (this.leaveUsage == null || this.leaveUsage.leaveTypeId == null) {
					if (this.leaveUsages[0] != null) {
						this.leaveUsage = this.leaveUsages[0];
					} else {
						this.leaveUsage = new LeaveUsageModel();
						this.leaveUsage.year = this.yearId;
					}
				} else {
					this.leaveUsage.year = this.yearId;
				}
				this.leaveUsage.employeeId = this.id;

				if (this.calLeaveUsage$) this.calLeaveUsage$.unsubscribe();
				this.calLeaveUsage$ = this.documentFormService.calLeaveUsage(this.leaveUsage).subscribe((res) => {
					if (res) {
						this._toastHelperService.successText("Calculated successfully");
						this.loadData();
						this.isCalLeaveBenefit.emit(true);
					} else {
						this._toastHelperService.validationText("Error!");
					}
				});
			}
		});
	}

	onSubmit() {
		this.loadConfirm();
		const dialogModel = <DialogInformationModel>{
			title: this.confirmation,
			description: this.addConfirmation,
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm"),
			textButtonCancel: this.translate.instant("common_cancel"),
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				this.leaveUsage.totalDays = this.totalDays;
				this.leaveUsage.totalHours = this.totalHours;
				this.leaveUsage.carryTotalDays = this.totalCarryDays;
				this.leaveUsage.carryTotalHours = this.totalCarryHours;
				this.leaveUsage.isAdjust = this.isAdjust;
				if (this.editLeaveUsage$) this.editLeaveUsage$.unsubscribe();
				this.editLeaveUsage$ = this.employeeLeaveUsageService.editLeaveUsage(this.leaveUsage).subscribe((res) => {
					var apiResult = <ApiResult>res;
					if (apiResult.result) {
						this.loadData();
						this.reset();
					}
					this._toastHelperService.alertApiResult(apiResult);
				});
			}
		});
	}

	async edit(leaveUsage: LeaveUsageModel) {
		this.leaveUsage = leaveUsage;
		this.yearId = this.leaveUsage.year;
		this.leaveTypeId = this.leaveUsage.leaveTypeId;
		this.totalDays = this.leaveUsage.totalDays;
		this.totalHours = this.leaveUsage.totalHours;
		this.totalCarryDays = this.leaveUsage.carryTotalDays;
		this.totalCarryHours = this.leaveUsage.carryTotalHours;
		this.isAdjust = this.leaveUsage.isAdjust;

		this.formLeaveUsage.controls.year.setValue(this.yearId);
		this.formLeaveUsage.controls.leaveTypeId.setValue(this.leaveTypeId);
		this.formLeaveUsage.controls.totalDays.setValue(this.totalDays);
		this.formLeaveUsage.controls.totalHours.setValue(this.totalHours);
		this.formLeaveUsage.controls.totalCarryDays.setValue(this.totalCarryDays);
		this.formLeaveUsage.controls.totalCarryHours.setValue(this.totalCarryHours);
		this.formLeaveUsage.controls.isAdjust.setValue(this.isAdjust);
		this.isEdit = true;

		await this.sharingService.delayTime(1);
		this.typeDDL?.setDisabledState(true);
	}

	reset() {
		this.typeDDL?.setDisabledState(false);
		this.totalDays = 0;
		this.totalHours = 0;
		this.totalCarryDays = 0;
		this.totalCarryHours = 0;
		this.leaveTypeId = null;
		this.isEdit = false;
	}
	//#end region
}
