export enum LocalStorageKey {
	timeout = "timeout",
	renewal = "renewal",
}

export enum SessionStorageKey {
	onBoard = "onBoard",
	TwoFA = "two_factor_authentication",
	source_user_id = "source_user_id",
	access_token = "access_token",
	token_expires = "token_expires",
	token_refreshed = "token_refreshed",
	email = "email",
}
