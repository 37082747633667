import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RoleAssignment } from "./role-assignment.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class RoleAssignmentService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getRoleAssignmentList(componentId: number, roleId: number, type: number, level: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "RoleAssignment/RoleAssignmentList/" + componentId + "/" + type + "/" + roleId + "/" + level,
			),
		).then((res) => <RoleAssignment[]>res);
	}
	getRoleAssignmentByFunctionCodeList(fucntionCode: string, roleId: number, type: number) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl + `v1/roles/permisions?fucntionCode=${fucntionCode}&roleId=${roleId}&type=${type}`,
			),
		);
	}

	getDDLRole() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "RoleAssignment/GetDDLRole")).then((res) => <Dropdown[]>res);
	}

	editRoleAssignment(roleAssignment: RoleAssignment[]) {
		var body = JSON.stringify(roleAssignment);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "RoleAssignment/EditRoleAssignment", body, httpOptions);
	}

	resizeImage() {
		var body = JSON.stringify("");
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/ResizeAllEmployeePicture", body, httpOptions);
	}
}
