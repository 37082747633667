import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Tenant } from "./tenant-setup.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TenantSetupService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getSysTenantByTenantId(functionCode?: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "Tenant/GetSysTenentByTenantId/" + functionCode)).then(
			(res) => <Tenant>res,
		);
	}

	easySetUpGetComponentByUserId(functionCode?: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "Tenant/EasySetUpGetComponentByUserId/" + functionCode)).then(
			(res) => <Tenant>res,
		);
	}

	checkNotConfiguredByTenantId(functionCode?: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "Tenant/CheckNotConfiguredByTenantId/" + functionCode)).then(
			(res) => <boolean>res,
		);
	}

	checkModuleConfigured(functionCode: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "Tenant/CheckModuleConfigured/" + functionCode)).then(
			(res) => res,
		);
	}

	getTenantComponentReserveId(componentId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Tenant/GetTenantComponentReserveId/" + componentId)).then(
			(res) => <any>res,
		);
	}

	updateSysTenants(sysTenant: Tenant[]) {
		return this.http.post(this.apiUrl + "Tenant/UpdateSysTenants", sysTenant);
	}
	updateIsActive2FA(isActive2FA: boolean) {
		return this.http.post(this.apiUrl + "Tenant/UpdateIsActive2FA", isActive2FA);
	}
	bulkUpdateSysTenant(sysTenant: any) {
		return this.http.post(this.apiUrl + "Tenant/BulkUpdateSysTenant", sysTenant);
	}
	updateSettingTenants(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Tenant/UpdateSettingTenants", body, httpOptions);
	}

	clearData(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Tenant/ClearData", body, httpOptions);
	}

	getCheckCustomDomainByInvitationCode(invitationCode: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "tenants/check/custom-domain/" + invitationCode)).then(
			(res) => <boolean>res,
		);
	}
}
