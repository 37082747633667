import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Rank } from "./ranks.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class RanksService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getRanks(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Ranks/GetRanks", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}
	getRankById(rankNo: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "ranks/" + rankNo)).then((res) => <Rank>res);
	}
	addRank(tc: any) {
		var body = JSON.stringify(tc);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "ranks", body, httpOptions);
	}
	editRanks(model: Rank) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.put(this.apiUrl + "ranks", body, httpOptions);
	}

	editListOfSysRanks(model: Rank[]) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Ranks/EditListOfSysRanks", body, httpOptions);
	}

	deleteRank(rankNo: number, deletedSEQ: number) {
		return firstValueFrom(this.http.delete(this.apiUrl + "ranks/" + rankNo + "/" + deletedSEQ));
	}

	getRankByTenant() {
		return firstValueFrom(this.http.get(this.apiUrl + "Ranks/GetRankByTenant/")).then((res) => <Rank>res);
	}
	getRankBySearchModel(searchModel) {
		var body = JSON.stringify(searchModel);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any[]>(this.apiUrl + "Ranks/GetRankBySearchModel", body, httpOptions);
	}
}
