import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { DropdownComponent, AngularCommonModule } from "@gofive/angular-common";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { Subscription, firstValueFrom } from "rxjs";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { DataSharingService, UserControlService } from "../../core";
import { EmployeeAccumulates } from "../../employee-movements/shared/employee-accumulates.model";
import { EmployeeAccumulatesService } from "../../employee-movements/shared/employee-accumulates.service";
import { Module } from "../../shared/enum/module.enum";
import { PageType } from "../../shared/enum/page-type.enum";
import { ApiResult } from "../../shared/models/api-result.model";
import { Dropdown } from "../../shared/models/dropdown.model";
import { Permission } from "../../shared/models/permission.model";
import { CommonService } from "../../shared/service/common.service";
import { PaymentPeriodService } from "../master/payment-period/shared/payment-period.service";
import { AccumulateConfig } from "./accumulate-config/shared/accumulate-config.model";
import { AccumulateConfigService } from "./accumulate-config/shared/accumulate-config.service";
import { ThaiDatePipe } from "../../shared/pipe/thai-date.pipe";
import { AsyncPipe, DecimalPipe, DatePipe } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { InputModule } from "@gofive/design-system-input";
import { SidebarModule } from "@gofive/design-system-navigation";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { TableModule } from "@gofive/design-system-table";

@Component({
	selector: "app-accumulate",
	templateUrl: "./accumulate.component.html",
	styleUrls: ["./accumulate.component.scss"],
	standalone: true,
	imports: [
		AngularCommonModule,
		DataTablesModule,
		TooltipModule,
		SidebarModule,
		InputModule,
		FormsModule,
		InputTextModule,
		ButtonModule,
		AsyncPipe,
		DecimalPipe,
		DatePipe,
		TranslateModule,
		ThaiDatePipe,
		TableModule,
	],
})
export class AccumulateComponent implements OnInit {
	@ViewChild("sDropdownYear") sDropdownYear: DropdownComponent;

	//#region Variable
	@ViewChild(DataTableDirective, { static: true })
	dtElements: DataTableDirective;
	dtOptions: DataTables.Settings;

	confirmation: any;
	addConfirmation: any;
	editConfirmation: any;

	subscription: Subscription;
	DDLyear: Dropdown[];
	yearDLL: Dropdown = new Dropdown();
	id: number;
	presentYear: any;
	year: number;
	accumulateId: any = null;
	public fieldsDDL = { text: "name", value: "id" };

	employeeAccumulate: EmployeeAccumulates = new EmployeeAccumulates();

	form: UntypedFormGroup;

	accumulateConfigs: Array<AccumulateConfig> = new Array<AccumulateConfig>();
	accumulateConfig: AccumulateConfig = new AccumulateConfig();
	permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	dialogDisplay: boolean = false;
	isPermissionEdit: boolean = true;
	maskAmount: string = "xxxx.xx";
	//#endregion
	@Input() isShowSalary: boolean = false;
	@Input() dateEmployment: Date;
	constructor(
		private userControlService: UserControlService,
		private employeeAccumulatesService: EmployeeAccumulatesService,
		private commonService: CommonService,
		public data: DataSharingService,
		private router: Router,
		private paymentPeriodService: PaymentPeriodService,
		private accumulateConfigService: AccumulateConfigService,
		private translate: TranslateService,
		private _toastHelperService: ToastHelperService,
		private _dialogService: DialogService,
	) {}

	ngOnInit() {
		this.userControlService.authorizeControl(Module.PAY001_T03).subscribe((auth) => {
			this.permission = this.userControlService.validatePermission(PageType.search, auth);
		});

		//#region Datatable
		this.dtOptions = {
			processing: false,
			searching: false,
			paging: false,
			info: false,
			columnDefs: [{ orderable: false, targets: "_all" }],
		};
		//#endregion

		//#region Call api
		this.subscription = this.data.currentEmpId.subscribe((data) => {
			this.id = data;
			if (this.id == 0) {
				this.router.navigate(["/employee"]);
			}
			this.employeeAccumulate.employeeId = this.id;
		});
		//#endregion

		this.callAPI();
	}
	monthDiff(d1, d2) {
		var months;
		months = (d2.getFullYear() - d1.getFullYear()) * 12;
		months -= d1.getMonth();
		months += d2.getMonth();
		return months <= 0 ? 0 : months;
	}
	//#region Destroy unsubscribe
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
	//#endregion

	//#region Load data
	async callAPI() {
		this.presentYear = this.commonService.getPresentYear();

		await this.commonService.getDDLYearAccumulate(this.id).then((res) => {
			this.DDLyear = res;
			this.yearDLL = res.find((x) => x.id == this.presentYear);
			this.year = this.yearDLL.id;
			this.sDropdownYear.value = [this.year];
			this.sDropdownYear.selectedItem = [
				{
					name: this.year,
					value: this.year,
				},
			];
			this.sDropdownYear.selectedItems = this.sDropdownYear.selectedItem;
		});

		this.loadAccumulate();
	}

	loadAccumulate() {
		this.accumulateConfigService
			.getAccumulateByYear(this.id, this.isNullOrUndefined(this.sDropdownYear) ? 0 : this.sDropdownYear.value[0])
			.then((res) => {
				this.accumulateConfigs = res;
			});
	}
	//#endregion

	//#region Function on change
	yearOnChange() {
		this.year = this.isNullOrUndefined(this.sDropdownYear) ? 0 : this.sDropdownYear.value[0];
		this.loadAccumulate();
		this.loadForm();
	}
	isNullOrUndefined(object) {
		return object == null || object == undefined;
	}
	loadConfirm() {
		this.confirmation = this.translate.instant("common_confirmation");
		this.addConfirmation = this.translate.instant("common_add_confirmation");
		this.editConfirmation = this.translate.instant("common_edit_confirmation");
	}

	loadForm() {
		this.employeeAccumulate = new EmployeeAccumulates();
		this.accumulateId = null;
		this.dialogDisplay = false;
	}

	//#endregion
	edit(accumulateId: number) {
		this.paymentPeriodService.getYearLastPeriodCalculation(this.year).then((res) => {
			this.isPermissionEdit = res;
		});
		this.dialogDisplay = true;
		this.employeeAccumulatesService.getEmployeeAccumulateById(accumulateId, this.id, this.year).then((res) => {
			this.employeeAccumulate = res ? res : new EmployeeAccumulates();
			this.accumulateConfig = this.accumulateConfigs.find((s) => s.accumulateId == accumulateId);
			this.employeeAccumulate.name = this.accumulateConfig.name;
			this.employeeAccumulate.name_EN = this.accumulateConfig.name_EN;
			this.employeeAccumulate.employeeId = this.id;
			this.accumulateId = accumulateId;
		});
	}

	save() {
		this.loadConfirm();
		if (this.employeeAccumulate.accumulateId > 0) {
			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.editConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.employeeAccumulate.year = this.year;
					this.employeeAccumulatesService.editEmployeeAccumulate(this.employeeAccumulate).subscribe((res) => {
						var apiResult = <ApiResult>res;
						if (apiResult.result) {
							this.loadForm();
							this.callAPI();
						}
						this._toastHelperService.alertApiResult(apiResult);
					});
				}
			});
		} else {
			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.addConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.employeeAccumulate.year = this.year;
					this.employeeAccumulate.accumulateId = this.accumulateId;
					this.employeeAccumulatesService.addEmployeeAccumulate(this.employeeAccumulate).subscribe((res) => {
						var apiResult = <ApiResult>res;
						if (apiResult.result) {
							this.loadForm();
							this.callAPI();
						}
						this._toastHelperService.alertApiResult(apiResult);
					});
				}
			});
		}
	}
	onSubmit() {}
	onDialog(event) {
		this.dialogDisplay = event;
	}
	onClearsDropDownYear() {}
}
