export class ApiResult<T = any> {
	result: boolean;
	message: string;
	message_EN: string;
	warning: string;
	warning_EN: string;
	data: T;
	fileName: string;
	leaveRequestId: number;
	welfareDocumentId: number;
	documentFormId: number;
	messageWarning: string;
	hasWarning: boolean;
	logoURL: string;
	isDuplicateInvitationCode: boolean;
	isErrorDate: boolean;
	isDuplicate: boolean;
	isMaximumQuota: boolean;
	isInvalidPackagePro: boolean;
	isResignationFail: boolean;
	errorField: string;
	status: any;
}
