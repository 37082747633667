export class MssSearchModel {
	dateStart: Date;
	dateEnd: Date;
	shiftId: number = null;
	isDirect: boolean = true;
	keyword: string = "";
	takeRow: number = 15;
	skipRow: number = 0;
	category: number = 0;
	dateTime: Date = null;
	organizationId: number = 0;
	positionId: number = 0;
	workinSite: string[] = [];
	workinSiteStr: string = null;
	workinSiteTypeStr: string = "";
	workLocationId: number = 0;
	employmentType: number = 0;
	userId: string = null;
	employeeId: number;
	companyId: number;
	minAttendanceScore: number = 0;
	maxAttendanceScore: number = 100;
	year: number = null;
	date: Date = null;
	minuteGMT: number;
	isShiftSchedule?: boolean = false;
	displayIds?: number[] = null;
}

export class EmployeeChainOfCommandSearchModel {
	userId: string;
	employeeId: string;
	isAll: boolean;
	defaultDirectReport: boolean;
	type: number;
	isSupervisor: boolean;
}
