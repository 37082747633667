import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../../core/shared/data-tables-response.model";
import { ProvidentFundPlan } from "./provident-fund-plan.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ProvidentFundPlanService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getProvidentFundPlanDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "ProvidentFundPlan/GetProvidentFundPlanDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getProvidentFundPlanById(providentFundPlanId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "ProvidentFundPlan/GetProvidentFundPlanById/" + providentFundPlanId),
		).then((res) => <ProvidentFundPlan>res);
	}

	addProvidentFundPlan(pfp: ProvidentFundPlan) {
		var body = JSON.stringify(pfp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "ProvidentFundPlan/AddProvidentFundPlan", body, httpOptions);
	}

	editProvidentFundRate(pfp: ProvidentFundPlan) {
		var body = JSON.stringify(pfp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "ProvidentFundPlan/EditProvidentFundRate", body, httpOptions);
	}

	getProvidentFundPlanBySelfService(isSelfService: boolean) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "ProvidentFundPlan/GetProvidentFundPlanBySelfService/" + isSelfService),
		).then((res) => <ProvidentFundPlan[]>res);
	}
}
