import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeProvidentFund } from "./employee-provident-fund.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeProvidentFundService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getProvidentFundByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeProvidentFund/GetProvidentFundByEmployeeId/" + employeeId),
		).then((res) => <EmployeeProvidentFund[]>res);
	}

	addEmployeeProvidentFund(epf: EmployeeProvidentFund) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(epf);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeProvidentFund/AddEmployeeProvidentFund", body, httpOptions);
	}

	deleteProvidentFund(providentFundId: number) {
		var body = JSON.stringify(providentFundId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeProvidentFund/DeleteProvidentFund", body, httpOptions);
	}
}
