import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { MasterSetup } from "./master-setup.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class MasterSetupService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	addMasterSetup(mas: MasterSetup) {
		var body = JSON.stringify(mas);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetup/AddMasterSetup", body, httpOptions);
	}

	editMasterSetup(mas: MasterSetup) {
		var body = JSON.stringify(mas);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetup/EditMasterSetup", body, httpOptions);
	}

	deleteMasterSetup(masterSetupId: string[]) {
		var body = JSON.stringify(masterSetupId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetup/DeleteMasterSetup", body, httpOptions);
	}

	deleteMasterSetupById(masterSetupId: number) {
		var body = JSON.stringify(masterSetupId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetup/DeleteMasterSetupById", body, httpOptions);
	}

	getMasterSetupById(masterSetupId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "MasterSetup/GetMasterSetupById/" + masterSetupId)).then(
			(res) => <MasterSetup>res,
		);
	}

	getMasterSetupGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "MasterSetup/GetMasterSetupDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getMasterSetupBySystemRegisNoDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "MasterSetup/GetMasterSetupBySystemRegisNoDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getMasMasterSetUpGroup(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any[]>(this.apiUrl + "MasterSetup/GetMasMasterSetUpGroup", body, httpOptions);
	}

	getDDLMasterSetupGroup() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "MasterSetupGroup/GetDDLMasterSetupGroup")).then(
			(res) => <Dropdown[]>res,
		);
	}

	getDDLMasterSetupGroupById() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "MasterSetupGroup/GetMasterSetupGroupById")).then(
			(res) => <any[]>res,
		);
	}

	updateProvidentFund(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetupGroup/UpdateProvidentFund", body, httpOptions);
	}
	addProvidentAttachments(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetupGroup/AddProvidentFundAttachements", body, httpOptions);
	}

	updateProvidentFundAttachments(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetupGroup/UpdateProvidentFundAttachments", body, httpOptions);
	}
}
