import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiUrl } from "../enum/app-config.enum";
import { MasterService } from "./master.service";

@Injectable({
	providedIn: "root",
})
export class ClientPortalService extends MasterService {
	productId = 102;

	constructor(
		public http: HttpClient,
		@Inject("API_URL_LST") public apiUrl: string[],
		@Inject("CLIENT_PORTAL") private apiClient: string,
	) {
		super(http, apiUrl);
	}

	GetCurrentSubscription(productId, clientId) {
		return this.get<any>(
			"api/Subscriptions/current?productId=" + productId + (clientId ? "&clientId=" + clientId : ""),
			null,
			ApiUrl.clientPortol,
		);
	}

	AddSubscriptionsExtend(subscriptionId) {
		return this.http.post<any>(this.apiClient + "api/subscriptions/extend/" + subscriptionId, null);
	}

	GetValumnLicense(clientId: number, applicationId = 102) {
		return this.http.post<any>(
			this.apiClient + "api/Subscriptions/GetVolumnLicense/" + clientId + "/product/" + applicationId,
			null,
		);
	}
}
