<div class="d-grid" [ngStyle]="{ 'margin-bottom': isFooter ? '50px' : '0' }">
	<form [formGroup]="form" *ngIf="isShowForm" style="padding-bottom: 16px">
		<div class="d-grid">
			<info-bar
				*ngIf="!isLoadInfoBar"
				[type]="messageWarningType"
				[employeeId]="id"
				[movementType]="infoBarMovementTypeId"
			></info-bar>
		</div>
		<div class="ui-g-12 ui-md-12 ui-lg-12 px-0 d-flex">
			<div class="ui-g-12 ui-lg-4 px-0">
				<label for="input" class="title-input">{{ "emp001_t14_payment_type" | translate }}*</label>
				<div class="ui-g-12 ui-g-nopad">
					<p-selectButton
						[options]="isCashOption"
						[(ngModel)]="employee.isCash"
						formControlName="isCash"
						[ngClass]="'secondary-select-btn'"
						(onChange)="onIsCashChange($event)"
					>
						<ng-template let-item>
							<div style="padding: 0.2em 1rem">
								{{ item.label | translate }}
							</div>
						</ng-template>
					</p-selectButton>
				</div>
			</div>
		</div>
		<div class="ui-g-12 ui-md-12 ui-lg-12 px-0 d-flex" [hidden]="employee.isCash">
			<div class="ui-g-6 ui-lg-4 pl-0">
				<label for="input" class="title-input">{{ "emp001_t04_bank_account" | translate }}*</label>
				<go5-dropdown-selection
					data-testid="dropdown_single_bankId"
					[mode]="'single'"
					[dataSource]="sysBankDDL"
					[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
					placeholder="{{ 'common_default_select' | translate }}"
					[height]="'40px'"
					[(ngModel)]="employee.bankId"
					(ngModelChange)="selectBank()"
					formControlName="bankId"
					[allowShowRemove]="false"
				>
					<ng-template #valueTemplate let-item>
						<div class="content" style="display: flex">
							<em [class]="item.value"></em>
							<span class="ml-2" style="align-self: center">{{
								data.getIsTH(data.currentLanguage | async) ? item.name : item.name_EN
							}}</span>
						</div>
					</ng-template>
				</go5-dropdown-selection>
			</div>
			<div class="ui-g-6 ui-lg-4 pr-0">
				<label for="input" class="title-input">{{ "emp001_t04_bank_account_no" | translate }}*</label>
				<input
					data-testid="input_text_bankaccountno"
					pInputText
					type="text"
					[(ngModel)]="employee.bankAccountNo"
					formControlName="bankAccountNo"
				/>
			</div>
		</div>
		<div class="ui-g-12 ui-md-12 ui-lg-12 px-0 d-flex">
			<div class="ui-g-6 ui-lg-4 pl-0">
				<div class="ui-g-12 ui-g-nopad d-flex">
					<div class="ui-g-nopad">
						<label for="input" class="title-input">{{ "emp001_t04_pay_type" | translate }}*</label>
					</div>
					<div class="ui-g-nopad px-1" style="padding-top: 1px">
						<einfo-tooltip key="emp001_t14_employment_type_tooltip" size="s"></einfo-tooltip>
					</div>
				</div>
				<s-dropdown
					data-testid="dropdown_single_payment"
					[dataSource]="paymentMethodDDL"
					[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
					[placeholder]="'common_default_select' | translate"
					(selected)="checkTypePayment($event)"
					[showClearButton]="false"
					formControlName="paymentMethod"
					[(ngModel)]="paymentMethod"
					cssClass="dropdown-large-primary"
					mode="single"
					[showTooltip]="false"
				>
				</s-dropdown>
			</div>
			<div class="ui-g-6 ui-lg-4 pr-0">
				<label *ngIf="!isShowSalary" for="input" class="title-input" style="margin: 0px; padding-bottom: 0.5rem"
					>{{
						isHourlyPay
							? ("emp001_t14_hourly_wage" | translate)
							: isPaymentDaily
								? ("emp001_t14_daily_wage" | translate)
								: ("emp001_t14_payroll" | translate)
					}}*</label
				>

				<go5-textfield
					*ngIf="isShowSalary"
					type="currency"
					category="primary"
					[min]="0"
					[disabled]="!tabPermissionEditSalary"
					formControlName="incomeAmount"
					[(ngModel)]="incomeAmount"
					label="{{
						((isHourlyPay ? 'emp001_t14_hourly_wage' : isPaymentDaily ? 'emp001_t14_daily_wage' : 'emp001_t14_payroll')
							| translate) + '*'
					}}"
					format="0.2-2"
				></go5-textfield>

				<div
					go5-tooltip-placement="top"
					go5-tooltip-mode="hover"
					go5-tooltip="{{ 'common_please_view_salary' | translate }}"
				>
					<input
						data-testid="input_text_maskamount"
						pInputText
						style="text-align: right"
						*ngIf="!isShowSalary"
						type="text"
						value="{{ maskAmount }}"
						disabled
					/>
				</div>
			</div>
		</div>
		<div [hidden]="employee.isCash" *ngIf="paymentAccountDropDown" class="ui-g-4 pl-0" style="padding-bottom: 33px">
			<label for="input" class="title-input">{{ "emp001_t04_payment_account" | translate }}*</label>
			<go5-dropdown-selection
				data-testid="dropdown_single_paymentAccount"
				[mode]="'single'"
				[dataSource]="paymentAccount"
				[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsBankDDL : fieldsBankDDL_EN"
				formControlName="companyBankAccountNo"
				[(ngModel)]="employee.companyBankAccountNo"
				placeholder="{{ 'common_default_select' | translate }}"
				[height]="'40px'"
				[allowShowRemove]="false"
			>
				<ng-template #valueTemplate let-item>
					<div class="content" style="display: flex">
						<em [class]="item.icon"></em>
						<span class="ml-2" style="align-self: center">{{
							data.getIsTH(data.currentLanguage | async) ? item.detail : item.detail_EN
						}}</span>
					</div>
				</ng-template>
			</go5-dropdown-selection>
		</div>
		<div *ngIf="isChangePaymentType" class="ui-g-12 pt-0">
			<go5-info-bar [type]="infoBarType.Info">
				<div class="d-grid">
					<span>
						<span>{{ "emp001_t14_employment_type_changed_infobar_1" | translate }}</span>
						<span>
							{{ "emp001_t14_employment_type_changed_infobar_2" | translate }}
						</span>
						<span>{{ "emp001_t14_employment_type_changed_infobar_3" | translate }}</span>
						<span style="text-decoration: underline; cursor: pointer" (click)="gotoManual()">{{
							"emp001_t14_employment_type_changed_infobar_4" | translate
						}}</span>
					</span>
				</div>
			</go5-info-bar>
		</div>
	</form>
	<div class="ui-g-12 p-0 my-3" *ngIf="isShowHistory">
		<label class="text-header-5 color-lead">{{ "emp001_t14_salary_addjustment_history" | translate }}</label>

		<div style="width: 100%" style="height: calc(100vh - 400px)">
			<app-salary-adjustment-history
				[employeeId]="this.id"
				[isMobile]="false"
				[isShowSalary]="isShowSalary"
				[isAllowDelete]="permission?.allowDelete"
				[isActive2FA]="isActive2FA"
				[isAdjSalary]="isAdjustingSalary"
			></app-salary-adjustment-history>
		</div>
	</div>

	<div class="footer-dialog-payroll" [hidden]="isFooter">
		<go5-button
			data-testid="button_button_cancel"
			class="pr-2"
			label="{{ 'common_cancel' | translate }}"
			category="secondary"
			size="medium"
			type="button"
			(click)="onClear()"
			[disabled]="false"
		></go5-button>
		<go5-button
			data-testid="button_button_save"
			label="{{ 'common_save' | translate }}"
			category="primary"
			size="medium"
			type="button"
			[disabled]="!form.valid || !isPAY001T17?.allowGrant"
			(click)="save()"
		>
		</go5-button>
	</div>
</div>

<div id="footer-payment" class="footer-employee-payroll-history bg-default" [hidden]="!isFooter">
	<div class="ui-g-12 d-flex" style="padding: 17px; align-items: center; justify-content: flex-end">
		<div class="ui-g-6 text-right p-0">
			<span>
				<go5-button
					label="{{ 'common_cancel' | translate }}"
					class="mr-2"
					category="secondary"
					size="medium"
					type="button"
					(click)="backToProfile()"
				></go5-button>
			</span>
			<span>
				<go5-button
					data-testid="button_button_save"
					label="{{ 'common_save' | translate }}"
					category="primary"
					size="medium"
					type="button"
					[disabled]="!form.valid || !isPAY001T17?.allowGrant"
					(click)="save()"
				></go5-button>
			</span>
		</div>
	</div>
</div>

<go5-dialog [(visible)]="popup_Confirm" [width]="'60vw'" [isShowCloseIcon]="false" [padding]="'0px'">
	<div>
		<div class="header-border-bottom">
			<div class="header-content d-flex" style="justify-content: space-between; padding: 20px 20px 10px 20px">
				<span class="text-header-3 text-color-10">{{
					editType == EditType.movement
						? ("emp002_action_information" | translate)
						: ("emp002_edit_confirm" | translate)
				}}</span>
				<div class="d-flex" style="align-items: center">
					<go5-checkbox
						data-testid="input_checkbox_without_save_movement"
						[label]="'edit_information_without_save_movement' | translate"
						(changed)="onChangeEditType($event)"
						[checked]="isEditType"
						*ngIf="!isEditAmount"
					>
					</go5-checkbox>
					<label *ngIf="isEditAmount" style="display: inline-flex; width: 400px"> </label>
				</div>
			</div>
		</div>

		<form [formGroup]="form">
			<div
				class="d-flex"
				style="height: 100%; flex-direction: column; justify-content: space-between; padding: 10px 20px 20px 20px"
			>
				<div>
					<div *ngIf="editType == '2'" class="content-input p-0">
						<div class="content-input-right ui-g-12 p-mg-6 ui-lg-6 pl-0 pr-3">
							<label class="text-color-8"> {{ "emp002_movement_type" | translate }}* </label>
							<div class="pt-2" style="pointer-events: none">
								<go5-dropdown-selection
									[dataSource]="movementTypeDDLCondition"
									[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
									[mode]="'single'"
									[placeholder]="'common_default_select' | translate"
									[value]="movementTypeId"
									[disabled]="true"
									[allowFiltering]="true"
									formControlName="movementTypeId"
									[allowShowRemove]="false"
								>
								</go5-dropdown-selection>
							</div>
						</div>
						<div class="content-input-left ui-g-12 p-mg-6 ui-lg-6 pr-0 pl-3">
							<div>{{ "ess009_date_effective" | translate }}*</div>
							<div class="pt-2">
								<go5-datepicker
									data-testid="input_calendar_dateeffective"
									[isShowTime]="false"
									placeholder="{{ 'common_placeholder_date' | translate }}"
									formControlName="dateEffective"
									[format]="dateFormat.dateShort"
								>
								</go5-datepicker>

								<go5-error-message
									[error]="
										!form.controls['dateEffective'].valid && (isSubmitted || form.controls['dateEffective'].touched)
									"
									[errorMessage]="'common_required' | translate"
								>
								</go5-error-message>
							</div>
						</div>
					</div>
					<div *ngIf="editType == '2'" class="content-input" style="padding-top: 10px">
						<div class="content-input-right">
							<div>
								{{ "common_remark" | translate }}
							</div>
							<div class="pt-2">
								<go5-textarea
									category="primary"
									size="extra-large"
									label=""
									maxlength="500"
									formControlName="remarks"
									[error]="!form.controls['remarks'].valid && (isSubmitted || form.controls['remarks'].touched)"
								>
								</go5-textarea>
								<go5-error-message
									[error]="!form.controls['remarks'].valid && (isSubmitted || form.controls['remarks'].touched)"
									[errorMessage]="'common_required' | translate"
								>
								</go5-error-message>
							</div>
						</div>
					</div>

					<div class="content-input">
						<div class="content-input-right">
							<div style="border: 1px solid #d2d2da; border-radius: 4px; padding: 8px 16px; height: 100%">
								<div class="text-body-strong text-color-12">
									{{ "emp002_information_original" | translate }}
								</div>

								<div style="display: grid; grid-template-columns: min-content auto">
									<ng-template ngFor let-item [ngForOf]="compareList">
										<div class="text-color-9 ellipsis" style="margin: 8px 16px 0px 0px">
											{{
												item.label?.indexOf("|") != -1
													? data.getIsTH(data.currentLanguage | async)
														? item.label?.split("|")[0]
														: item.label?.split("|")[1]
													: (item.label | translate)
											}}
										</div>
										<div class="text-body-strong text-color-10" style="margin: 8px 0px 0px 0px">
											{{
												data.getIsTH(data.currentLanguage | async)
													? item.memberPath === "IncomeAmount"
														? "฿" + (item.value2 | number: "1.2-2")
														: item.value2
													: item.memberPath === "IncomeAmount"
														? "฿" + (item.value2_EN | number: "1.2-2")
														: item.value2_EN
											}}
										</div>
									</ng-template>
								</div>
							</div>
						</div>

						<div class="content-input-left">
							<div style="border: 1px solid #d2d2da; border-radius: 4px; padding: 8px 16px; height: 100%">
								<div class="text-body-strong text-color-12">
									{{ "common_new_value" | translate }}
								</div>
								<div style="display: grid; grid-template-columns: min-content auto">
									<ng-template ngFor let-item [ngForOf]="compareList">
										<div class="text-color-9 ellipsis" style="margin: 8px 16px 0px 0px">
											{{
												item.label?.indexOf("|") != -1
													? data.getIsTH(data.currentLanguage | async)
														? item.label?.split("|")[0]
														: item.label?.split("|")[1]
													: (item.label | translate)
											}}
										</div>
										<div class="text-body-strong text-color-10" style="margin: 8px 0px 0px 0px">
											{{
												data.getIsTH(data.currentLanguage | async)
													? item.memberPath === "IncomeAmount"
														? "฿" + (item.value1 | number: "1.2-2")
														: item.value1
													: item.memberPath === "IncomeAmount"
														? "฿" + (item.value1_EN | number: "1.2-2")
														: item.value1_EN
											}}
										</div>
									</ng-template>
								</div>
							</div>
						</div>
					</div>

					<div
						class="content-input"
						style="flex-direction: column"
						*ngIf="userInfo?.hasMovementWorkflow && editType == EditType.movement"
					>
						<label class="text-color-8">
							{{ "next_approver_text" | translate }}
						</label>
						<label class="d-flex text-body-strong color-primary">
							{{ nextApproval ? nextApproval : "-" }}
						</label>
					</div>
				</div>
			</div>
		</form>
		<div style="padding: 0 20px 20px 20px">
			<div class="d-flex" style="justify-content: space-between">
				<div class="warning-box">
					<span class="warning-icon">
						<em class="gf-icon-empeo-information-filled color-flame"> </em>
					</span>
					<span class="warning-text">
						{{
							editType == "1" ? ("warning_salary_calculate" | translate) : ("warning_salary_date_effective" | translate)
						}}
					</span>
				</div>
				<div>
					<go5-button
						data-testid="button_button_close"
						(click)="closeDialog()"
						category="secondary"
						size="medium"
						type="button"
						class="pr-2"
						label="{{ 'common_cancel' | translate }}"
					>
					</go5-button>

					<go5-button
						data-testid="button_button_save"
						category="primary"
						size="medium"
						type="button"
						[label]="(editType == '2' || userInfo?.hasMovementWorkflow ? 'emp002_approve' : 'common_save') | translate"
						(click)="onSubmitCompare()"
					>
					</go5-button>
				</div>
			</div>
		</div>
	</div>
</go5-dialog>
