import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class UserInfoService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		@Inject("VENIO_API_URL") private venioApiUrl: string,
		@Inject("GOFIVE_SERVICE_STATUS_API_URL") private gofiveServiceStatusApiUrl: string,
		@Inject("GOFIVE_API_URL") private gofiveApiUrl: string,
	) {}
	getLanguageList() {
		return this.http.get<any>(this.apiUrl + "Common/GetLanguageList");
	}

	getCurrentUserInfo() {
		return this.http.get<any>(this.apiUrl + "UserInfo/GetCurrentUserInfo");
	}

	getUserInfoMixpanel() {
		return this.http.get<any>(this.apiUrl + "UserInfo/GetUserInfoMixpanel");
	}

	setLanguage(userInfo: any) {
		var body = JSON.stringify(userInfo);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "UserInfo/SetLanguage", body, httpOptions);
	}

	unregisToken(userInfo: any) {
		var body = JSON.stringify({ userId: userInfo.userId });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.venioApiUrl + "User/Notification/UnRegister", body, httpOptions);
	}

	getServiceMaintenance() {
		const controller = new AbortController();
		const signal = controller.signal;

		setTimeout(() => {
			controller.abort();
		}, 5000);

		return fetch(this.gofiveServiceStatusApiUrl, {
			method: "POST",
			headers: {
				"x-AppName": "empeo",
			},
			signal: signal,
		}).then((response) => {
			return response.json();
		});
	}

	getUserInfoById(userId: number) {
		return this.http.get<any>(this.apiUrl + "UserInfo/GetUserInfoById/" + userId);
	}

	editUserSignature(userId, signatureAttachment) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.put<any>(this.gofiveApiUrl + `v1/users/${userId}/signature`, signatureAttachment, httpOptions),
		).then((res) => res);
	}
}
