import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SysComponent } from "./component.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Checkbox } from "../../../shared/models/checkbox.model";
import { Dropdown, SyncTab } from "../../../shared/models/dropdown.model";
import { RouterModel } from "../../../shared/models/common.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ComponentService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getMenuList() {
		return this.http.get<any>(this.apiUrl + "Component/MenuList");
	}

	getComponentList() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Component/ComponentList")).then(
			(res) => <SysComponent[]>res,
		);
	}

	addComponent(com: SysComponent) {
		var body = JSON.stringify(com);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Component/AddComponent", body, httpOptions);
	}

	editComponent(com: SysComponent) {
		var body = JSON.stringify(com);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Component/EditComponent", body, httpOptions);
	}

	deleteComponent(componentID: string[]) {
		var body = JSON.stringify(componentID);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Component/DeleteComponent", body, httpOptions);
	}

	getComponentById(componentID: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Component/GetComponentById/" + componentID)).then(
			(res) => <SysComponent>res,
		);
	}

	getComponentGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Component/GetComponentDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getDDLPermission() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Permission/GetDDLPermission")).then(
			(res) => <Checkbox[]>res,
		);
	}

	getDDLComponentStatus() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLStatus")).then((res) => <Dropdown[]>res);
	}

	getParentComponentLevelThreeByCode(functionCode: string, isAllow: boolean) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Component/GetParentComponentLevelThreeByCode/" + functionCode + "/" + isAllow),
		).then((res) => <RouterModel[]>res);
	}

	getReportGroupAndItemList(functionCode: string, isAllow: boolean) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Component/GetReportGroupAndItemList/" + functionCode + "/" + isAllow),
		).then((res) => <SyncTab[]>res);
	}

	getSysComponentExtensions(roleId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Component/GetSysComponentExtensions/" + roleId));
	}
	getComponentExtensions(filter) {
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "ComponentExtensions", body, httpOptions);
	}

	getComponentListByApplicationId(applicationId: number, isModule: boolean = false) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Component/GetComponentListByApplicationId/" + applicationId + "/" + isModule),
		).then((res) => <any>res);
	}

	updateComponentSetting(sysComponentModel: any) {
		var body = JSON.stringify(sysComponentModel);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Component/UpdateComponentSetting", body, httpOptions);
	}
}
