@if (displayLeaveUsageCustomSetting) {
	<go5-sidebar
		class="go5-text-color-12"
		[(visible)]="displayLeaveUsageCustomSetting"
		[isShowFooter]="isLoading ? false : true"
		[isShowHeader]="isLoading ? false : true"
		(close)="onCloseSideBar()"
		[padding]="'0px'"
		[textHeader]="'mas008_edit_custom_leave_setting' | translate"
		[textConfirmButton]="'common_confirm' | translate"
		[textCancelButton]="'common_cancel' | translate"
		(confirm)="onSubmit()"
		(cancel)="onCloseSideBar()"
	>
		@if (!isLoading) {
			<form [formGroup]="form">
				<div class="ui-g-12 ui-g-nopad sidebar-body">
					<go5-info-bar [type]="infoBarType.Info">
						<span>{{ "edit_custom_leave_setting_infobar" | translate }}</span>
					</go5-info-bar>
					<div class="py-4 d-flex gap-8">
						@if (employeeDetail) {
							<go5-avatar-information
								[title]="
									data.getIsTH(data.currentLanguage | async)
										? employeeDetail?.nameSurname
										: employeeDetail?.nameSurname_EN
								"
								[detail]="
									data.getIsTH(data.currentLanguage | async)
										? employeeDetail?.positionName
										: employeeDetail?.positionName_EN
								"
								[src]="employeeDetail?.pictureMediumURL ? employeeDetail.pictureMediumURL : ''"
								type="picUser"
								size="medium"
								width="40px"
								[isLazy]="false"
							></go5-avatar-information>
						}
					</div>
					<div class="d-flex gap-8" style="flex-direction: column">
						@if (form?.controls?.employeeLeaveUsageList?.value?.length > 0) {
							<div class="d-flex gap-8" style="width: 100%">
								<span class="text-body go5-text-color-8 ellipsis" style="width: 45%"
									>{{ "mas008_leave_category" | translate }}*</span
								>
								<span class="text-body go5-text-color-8 ellipsis" style="width: 25%"
									>{{ "mas008_leave_day" | translate }}*</span
								>
								<span class="text-body go5-text-color-8 ellipsis" style="width: 25%"
									>{{ "mas008_leave_hour" | translate }}*</span
								>
								<div style="width: 5%"></div>
							</div>
						}
						<div formArrayName="employeeLeaveUsageList" class="d-flex gap-8" style="flex-direction: column">
							@for (item of form.get("employeeLeaveUsageList")?.controls; track item; let i = $index) {
								<div class="d-flex gap-8" style="width: 100%">
									<div style="width: 45%">
										<go5-dropdown-selection
											[dataSource]="leaveTypeDDLTemp"
											[allowShowRemove]="false"
											[formControl]="item.get('leaveTypeId')"
											placeholder="{{ 'common_default_select' | translate }}"
											[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDropdown : fieldsDropdown_EN"
											mode="single"
											height="40px"
											[allowFiltering]="false"
											(open)="onChangeLeaveType()"
											(select)="onSelectLeaveType($event, i)"
											[error]="
												!item?.controls['leaveTypeId'].valid &&
												(item?.controls['leaveTypeId'].touched || item?.controls['leaveTypeId'].dirty)
											"
											[errorMessage]="
												'common_validate_please_select' | translate: { dropdownmenu: 'common_leave_type' | translate }
											"
										>
										</go5-dropdown-selection>
									</div>
									<div style="width: 25%">
										<go5-textfield
											[format]="'0.0-1'"
											type="number"
											category="primary"
											size="100"
											[min]="0"
											[step]="0.5"
											(change)="changeTotalDays(item.get('totalDays').value, i)"
											formatInput="true"
											[unit]="(item.get('totalDays').value > 1 ? 'common_days' : 'common_day') | translate"
											[formControl]="item.get('totalDays')"
											[error]="
												!item?.controls['totalDays'].valid &&
												(item?.controls['totalDays'].touched || item?.controls['totalDays'].dirty)
											"
											[errorMessage]="'common_please_specify' | translate"
										></go5-textfield>
									</div>
									<div style="width: 25%">
										<go5-textfield
											[format]="'0.0-1'"
											type="number"
											category="primary"
											size="100"
											[min]="0"
											[step]="0.1"
											formatInput="true"
											[unit]="(item.get('totalHours').value > 1 ? 'common_hours' : 'common_hour') | translate"
											[formControl]="item.get('totalHours')"
											[error]="
												!item?.controls['totalHours'].valid &&
												(item?.controls['totalHours'].touched || item?.controls['totalHours'].dirty)
											"
											[errorMessage]="'common_please_specify' | translate"
										></go5-textfield>
									</div>
									<span class="d-flex" style="width: 5%; align-items: center">
										<em
											class="gf-icon-empeo-bin-vector"
											go5-tooltip="{{ 'common_delete' | translate }}"
											go5-tooltip-mode="hover"
											(click)="deleteEmployeeLeaveUsageList(i)"
										>
										</em>
									</span>
								</div>
							}
						</div>
						<div>
							<go5-button
								[label]="'common_add' | translate"
								iconClass="gf-icon-add-general"
								category="text"
								size="medium"
								[padding]="'6px 0'"
								[margin]="'0'"
								type="button"
								[disabled]="form.get('employeeLeaveUsageList')?.length == leaveTypeDDL?.length"
								(click)="addEmployeeLeaveUsageList()"
							></go5-button>
						</div>
					</div>
					<div class="text-body go5-text-color-8" style="margin-top: auto">
						<span>{{ "common_last_modified" | translate }}: </span>
						<span
							>{{
								data.getIsTH(data.currentLanguage | async)
									? employeeModified?.employeeName
									: employeeModified?.employeeName_EN
							}}
						</span>
						<span>
							{{ lastDateModified ? (lastDateModified | sDate: DateFormat.dateTimeShort | async) : "" }}
						</span>
					</div>
				</div>
			</form>
		} @else {
			<div class="ui-g-12 ui-g-nopad">
				<div class="ui-g-12 ui-g-nopad">
					<div class="ui-g-12 ui-g-nopad border-bottom-gray pb-3 pl-3 pr-3 pt-4 text-color-10">
						<ngx-skeleton-loader
							[theme]="{ width: '80%', height: '30px', float: 'left', animation: 'infinite progress' }"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
					</div>
					<div class="ui-g-12 ui-g-nopad sidebar-body" style="margin-bottom: 16px">
						<div class="contacts">
							<div>
								<div>
									<ngx-skeleton-loader
										[theme]="{
											width: '452px',
											height: '60px',
											float: 'left',
											margin: '0 0 14px 0',
											animation: 'infinite progress'
										}"
										animation="progress-dark"
									>
									</ngx-skeleton-loader>
								</div>
								<div>
									<ngx-skeleton-loader
										[theme]="{
											width: '45px',
											height: '45px',
											margin: '0 8px 0 0',
											float: 'left',
											animation: 'infinite progress'
										}"
										animation="progress-dark"
										appearance="circle"
									>
									</ngx-skeleton-loader>
									<ngx-skeleton-loader
										[theme]="{ width: '85%', height: '20px', float: 'left', animation: 'infinite progress' }"
										animation="progress-dark"
									>
									</ngx-skeleton-loader>
									<ngx-skeleton-loader
										[theme]="{ width: '85%', height: '20px', float: 'left', animation: 'infinite progress' }"
										animation="progress-dark"
									>
									</ngx-skeleton-loader>
								</div>
							</div>
							@for (number of data.counter(3); track number) {
								<div style="width: 100%">
									<div class="d-flex gap-8" style="width: 100%; flex-direction: space-between; padding: 8px 5px">
										<div style="width: 50%">
											<ngx-skeleton-loader
												[theme]="{
													width: '100%',
													height: '20px',
													float: 'left',
													animation: 'infinite progress'
												}"
												animation="progress-dark"
											>
											</ngx-skeleton-loader>
										</div>
										<div style="width: 25%">
											<ngx-skeleton-loader
												[theme]="{
													width: '100%',
													height: '20px',
													float: 'left',
													animation: 'infinite progress'
												}"
												animation="progress-dark"
											>
											</ngx-skeleton-loader>
										</div>
										<div style="width: 25%">
											<ngx-skeleton-loader
												[theme]="{
													width: '100%',
													height: '20px',
													float: 'left',
													animation: 'infinite progress'
												}"
												animation="progress-dark"
											>
											</ngx-skeleton-loader>
										</div>
									</div>
								</div>
							}
						</div>
						<div class="text-body go5-text-color-8" style="margin-top: auto">
							<ngx-skeleton-loader
								[theme]="{
									width: '50%',
									height: '20px',
									float: 'left',
									animation: 'infinite progress'
								}"
								animation="progress-dark"
							>
							</ngx-skeleton-loader>
						</div>
					</div>
				</div>
			</div>
		}
	</go5-sidebar>
}
