@if (employee?.employeeId && isShowDetail) {
	<div class="header-assessment-employee-container">
		<span class="header-section avatar-section">
			<go5-avatar-information
				[size]="'medium'"
				[title]="
					dataSharingService.getIsTH(dataSharingService.currentLanguage | async)
						? employee.nameSurname
						: employee.nameSurname_EN
				"
				[detail]="
					dataSharingService.getIsTH(dataSharingService.currentLanguage | async)
						? employee.positionName
						: employee.positionName_EN
				"
				[src]="employee.pictureThumbnailURL"
				[isLazy]="false"
			>
			</go5-avatar-information>
		</span>
		<span class="header-section">
			<div class="header-section-left go5-text-body go5-text-color-9">#{{ employee.employeeNo }}</div>
			<div class="header-section-right go5-text-body go5-text-color-9">
				{{
					dataSharingService.getIsTH(dataSharingService.currentLanguage | async)
						? employee.dateEmployment_TH
						: employee.dateEmployment_EN
				}}
				&nbsp;
				{{ "(" + (employee.dateEmployment | sTimeAgoSecondary: employee.dateComparator | async) + ")" }}
			</div>
		</span>

		<span class="header-section">
			<div class="header-section-left go5-text-body go5-text-color-9">
				{{
					dataSharingService.getIsTH(dataSharingService.currentLanguage | async)
						? employee.companyName
						: employee.companyName_EN
				}}
			</div>
			<div
				class="header-section-right go5-text-body go5-text-color-9 d-flex align-center g-2 employee-assessment-header"
				[innerHTML]="orgName"
			></div>
		</span>
		<span class="header-section">
			@if (employee.emailAddress) {
				<a
					class="header-section-left go5-text-body go5-text-color-9"
					href="mailto:{{ employee.emailAddress }}Subject=Hello20again"
				>
					<div class="d-flex align-center g-6" go5-tooltip="{{ employee.emailAddress }}" go5-tooltip-placement="left">
						<em class="gf-icon-empeo-email go5-text-color-9" [ngStyle]="{ 'font-size': '16px' }"></em>
						<span class="ellipsis">
							{{ employee.emailAddress }}
						</span>
					</div>
				</a>
			}

			@if (employee.mobileNo) {
				<a class="header-section-right go5-text-body go5-text-color-9" href="tel:{{ employee.mobileNo }}">
					<div class="d-flex align-center g-6" go5-tooltip="{{ employee.mobileNo }}" go5-tooltip-placement="left">
						<em class="gf-icon-phone-1 go5-text-color-9" [ngStyle]="{ 'font-size': '16px' }"></em>
						<span class="ellipsis">
							{{ employee.mobileNo }}
						</span>
					</div>
				</a>
			}
		</span>
	</div>
}

<ma-employee-assessment2
	*axLazyElement="link; loadingTemplate: loadingView"
	[token]="token"
	[theme]="theme"
	[currentUser]="currentUser"
	[language]="language"
	[application]="application"
	[employeeId]="employeeId"
	[scoreId]="scoreId"
	(editCalibration)="onEditCalibration($event)"
	(isDeleteAssessment)="receiveSuccessDeleteEvent($event)"
	(routerChange)="routerChange($event)"
	[url]="currentURL"
	[isWebView]="isWebView"
	[isShowHeader]="isShowHeader"
	[indexTab]="indexTab"
	[isDialog]="isDialog"
	(assessmentModel)="getAssessmentModel($event.detail)"
>
</ma-employee-assessment2>

<ng-template #loadingView>
	@if (isOpenHeader && isEmpeo) {
		<div class="ui-g-12 pl-4 pr-4 border-bottom-gray">
			<div class="ui-g-12 ui-g-nopad d-flex">
				<div style="width: 70px">
					<ngx-skeleton-loader
						[theme]="{ width: '50px', height: '50px', margin: '5px 0px 5px 0px' }"
						appearance="circle"
					>
					</ngx-skeleton-loader>
				</div>
				<div style="width: 500px">
					<div>
						<ngx-skeleton-loader [theme]="{ width: '80%', height: '20px', margin: '5px 0px 5px 0px' }">
						</ngx-skeleton-loader>
					</div>
					<div>
						<ngx-skeleton-loader [theme]="{ width: '60%', height: '20px', margin: '5px 0px 5px 0px' }">
						</ngx-skeleton-loader>
					</div>
				</div>
			</div>
		</div>
	}
	<div class="ui-g-12 p-0">
		<div class="ui-g-3 p-0 border-right-gray">
			<div class="ui-g-12 pl-4 pr-4">
				<div class="ui-g-12 ui-g-nopad">
					<ngx-skeleton-loader [theme]="{ width: '100%', height: '80px', margin: '10px 0px 10px 0px' }" [count]="5">
					</ngx-skeleton-loader>
				</div>
			</div>
		</div>
		<div class="ui-g-9 p-0">
			<div class="ui-g-12 px-3">
				<div class="ui-g-12 ui-g-nopad">
					<ngx-skeleton-loader [theme]="{ width: '50%', height: '20px', margin: '10px 15px 10px 0px' }">
					</ngx-skeleton-loader>
				</div>
				<div class="ui-g-12 ui-g-nopad">
					<ngx-skeleton-loader [theme]="{ width: '30%', height: '80px', margin: '10px 15px 10px 0px' }" [count]="3">
					</ngx-skeleton-loader>
				</div>
				<div class="ui-g-12 ui-g-nopad">
					<ngx-skeleton-loader [theme]="{ width: '100%', height: '30px', margin: '10px 0px 10px 0px' }" [count]="7">
					</ngx-skeleton-loader>
				</div>
			</div>
		</div>
	</div>
</ng-template>
