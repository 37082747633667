import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Leave, LeaveRequestLog } from "./leave.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { DropdownDataItem } from "../../../shared/models/dropdown-data-item.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class LeaveService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	addLeaveExchange(leave: Leave) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(leave);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/AddLeaveExchange", body, httpOptions);
	}
	getLeaveGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Leave/GetLeaveDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getLeaveById(leaveRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Leave/GetLeaveById/" + leaveRequestId)).then(
			(res) => <Leave>res,
		);
	}

	getDDLRanks() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLRanks")).then((res) => <Dropdown[]>res);
	}

	getDDLStatus() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLStatus")).then((res) => <Dropdown[]>res);
	}

	getDayOfWeek() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDayOfWeek")).then((res) => <DropdownDataItem[]>res);
	}

	getDDLApprovalStatus() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLApprovalStatus")).then(
			(res) => <DropdownDataItem[]>res,
		);
	}

	approveSelected(checked: string[]) {
		var body = JSON.stringify(checked);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/ApproveSelected", body, httpOptions);
	}
	addLeaveAttachmentSidebar(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/AddLeaveAttachmentSidebar", body, httpOptions);
	}
	updateIsActiveLeaveAttachment(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/UpdateIsActiveLeaveAttachment", body, httpOptions);
	}
	deleteLeaveAttachment(leaveAttachmentId: number) {
		return this.http.delete(this.apiUrl + "Leave/DeleteLeaveAttachment/" + leaveAttachmentId);
	}

	getLeaveRequestLogById(leaveRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Leave/GetLeaveRequestLogById/" + leaveRequestId)).then(
			(res) => <LeaveRequestLog[]>res,
		);
	}

	getLeaveRequestLogsByLeaveRequestId(leaveRequestId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Leave/GetLeaveRequestLogsByLeaveRequestId/" + leaveRequestId),
		).then((res) => <LeaveRequestLog[]>res);
	}

	getLeaveRequestByLeaveRequestId(leaveRequestId, employeeId) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}Leave/GetLeaveRequestByLeaveRequestId/${leaveRequestId}/${employeeId}`),
		).then((r) => <any>r);
	}

	getDDLYearLeaveUsage() {
		return firstValueFrom(this.http.get<Dropdown[]>(this.apiUrl + "LeaveUsage/GetDDLYearLeaveUsage"));
	}
}
