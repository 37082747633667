import { Pipe, PipeTransform } from "@angular/core";
import { DocumentStatus } from "../enum/document-status.enum";
import { ProcessAction } from "../enum/process-action.enum";

@Pipe({
	name: "categoryButton",
	standalone: true,
})
export class CategoryButtonPipe implements PipeTransform {
	transform(value: any) {
		switch (value) {
			case DocumentStatus.draft:
			case DocumentStatus.waitingApprove:
			case DocumentStatus.waitingRevise:
			case ProcessAction.draftDocument:
				return "secondary";
			case ProcessAction.sendDocument:
			case DocumentStatus.approved:
			case ProcessAction.approvedDocument:
			case ProcessAction.completeDocument:
				return "primary";
			case DocumentStatus.canceled:
			case DocumentStatus.rejected:
			case ProcessAction.canceledDocument:
			case ProcessAction.rejectedDocument:
			case ProcessAction.returnDocument:
				return "secondary";
			default:
				return "secondary";
		}
	}
}
