import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { isNullOrUndefined } from "@gofive/angular-common";
import { RequestHistorySearchModel } from "src/app/document-forms/approval/shared/approval.model";
import { MasterService } from "./master.service";
import { SearchCoursesDashboardModel, SearchCoursesModel } from "src/app/empeo-learn/shared/empeo-learn-model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class LearnService extends MasterService {
	constructor(
		@Inject("API_URL_LST") public apiUrls: string[],
		public http: HttpClient,
		@Inject("LEARN_API_URL") private learnAPI: string,
	) {
		super(http, apiUrls);
	}

	getCourseByName(courseName: string, trainingDateFrom?: Date) {
		return this.http.get<any>(
			this.learnAPI +
				`Courses/GetCoursesByName?courseName=${courseName}` +
				(!isNullOrUndefined(trainingDateFrom) ? `&trainingDateFrom=${trainingDateFrom}` : ""),
		);
	}

	getCourseByCourseId(
		courseId: number,
		employeeId: number = 0,
		registerType: number = 0,
		isGetEmployeeAttended: boolean = true,
	) {
		return firstValueFrom(
			this.http.get<any>(
				this.learnAPI +
					`Courses/GetCourseByCourseId/?courseId=${courseId}&employeeId=${employeeId}&registerType=${registerType}&isGetEmployeeAttended=${isGetEmployeeAttended}`,
			),
		);
	}

	getCoursesDashboard(model: SearchCoursesDashboardModel) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.learnAPI + "Courses/GetCoursesDashboard", body);
	}

	getCourses(model: SearchCoursesModel) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.learnAPI + "Courses/GetCourses", body);
	}

	addCourseAttachment(model: any) {
		var body = JSON.stringify(model);
		return this.http.post(this.learnAPI + "Courses/AddLearnAttachment", body, {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		});
	}

	deleteCourseAttachmentById(courseAttachmentId: number) {
		return this.http.delete(
			`${this.learnAPI}Courses/DeleteCourseAttachmentById?courseAttachmentId=${courseAttachmentId}`,
		);
	}

	getTrainingDocumentForApprove(searchModel: any) {
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(`${this.learnAPI}Courses/GetTrainingDocumentForApprove`, body);
	}

	getTrainingRequestHistoryDataTable(trainingSearchModel: RequestHistorySearchModel) {
		var body = JSON.stringify(trainingSearchModel);
		return this.http.post<any>(this.learnAPI + "CourseLogs/GetCourseLogs", body);
	}

	addProcessTrainings(approvalBySelected: any) {
		var body = JSON.stringify(approvalBySelected);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.learnAPI + "Courses/AddProcessTrainings/", body, httpOptions);
	}

	getTrainingWorkflowLogs(courseId: number) {
		return firstValueFrom(this.http.get<any>(`${this.learnAPI}Courses/GetTrainingWorkflowLogs?courseId=${courseId}`));
	}

	getProcessActionByUserId(courseId: number) {
		return firstValueFrom(this.http.get<any>(`${this.learnAPI}Courses/GetProcessActionByUserId?courseId=${courseId}`));
	}
	onValidateCourse(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.learnAPI + "Courses/OnValidateCourse", body, httpOptions);
	}
}
