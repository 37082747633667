import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PaymentPeriod } from "./payment-period.model";
import { CommonService } from "../../../../shared/service/common.service";
import { ApiResult } from "src/app/shared/models/api-result.model";
import { firstValueFrom } from "rxjs";
import { PaymentPeriodSearchModel } from "src/app/payroll/payment/shared/payment.model";

@Injectable()
export class PaymentPeriodService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getAllPaymentPeriodByPaymentMethodIdAndYear(paymentMethodId: number, year: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "PaymentPeriod/GetAllPaymentPeriodByPaymentMethodIdAndYear/" + paymentMethodId + "/" + year,
			),
		).then((res) => <PaymentPeriod[]>res);
	}

	getPaymentPeriodByPaymentMethodIdAndYearAndPeriodNo(paymentMethodId: number, year: number, periodNo: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"PaymentPeriod/GetPaymentPeriodByPaymentMethodIdAndYearAndPeriodNo/" +
					paymentMethodId +
					"/" +
					year +
					"/" +
					periodNo,
			),
		).then((res) => <PaymentPeriod>res);
	}

	getPaymentPeriodByPaymentMethodIdAndYearAndPeriodCalculateNo(
		paymentMethodId: number,
		year: number,
		periodCalculateNo: number,
	) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"PaymentPeriod/GetPaymentPeriodByPaymentMethodIdAndYearAndPeriodCalculateNo/" +
					paymentMethodId +
					"/" +
					year +
					"/" +
					periodCalculateNo,
			),
		).then((res) => <PaymentPeriod>res);
	}

	getPaymentPeriodByYearAndPeriodCalculateNo(year: number, periodCalculateNo: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "PaymentPeriod/GetPaymentPeriodByYearAndPeriodCalculateNo/" + year + "/" + periodCalculateNo,
			),
		).then((res) => <PaymentPeriod>res);
	}

	getPaymentPeriodByAccumulate(year: number, periodCalculateNo: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "PaymentPeriod/GetPaymentPeriodByAccumulate/" + year + "/" + periodCalculateNo),
		).then((res) => <PaymentPeriod>res);
	}

	getPaymentPeriodsById(year: number, companyId: number, payrollStatusId: number) {
		var body = JSON.stringify({ year: year, companyId: companyId, payrollStatusId: payrollStatusId });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(this.http.post(this.apiUrl + "PaymentPeriod/GetPaymentPeriodsById", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	getPeriodForCalculatePayroll(model: PaymentPeriodSearchModel) {
		const body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(
			this.http.post(this.apiUrl + "PaymentPeriod/GetPeriodForCalculatePayroll", body, httpOptions),
		).then((res) => <any>res);
	}

	getPaymentPeriodYear() {
		return firstValueFrom(this.http.get(this.apiUrl + "PaymentPeriod/GetPaymentPeriodYear")).then((res) => <any>res);
	}

	getYearLastPeriodCalculation(year) {
		return firstValueFrom(this.http.get(`${this.apiUrl}PaymentPeriod/GetYearLastPeriodCalculation/${year}`)).then(
			(res) => <any>res,
		);
	}

	generatePeriod(pp: any) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(pp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "PaymentPeriod/GeneratePeriod", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	getAllPaymentPeriodByPaymentMethodIdsAndYear(pp: any) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(pp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(this.apiUrl + "PaymentPeriod/GetAllPaymentPeriodByPaymentMethodIdsAndYear", body, httpOptions),
		).then((res) => <any>res);
	}

	addPaymentPeriod(pp: any) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(pp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "PaymentPeriod/AddPaymentPeriod", body, httpOptions);
	}

	editPaymentPeriod(pp: PaymentPeriod) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(pp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "PaymentPeriod/EditPaymentPeriod", body, httpOptions);
	}

	editAllPaymentPeriod(pp: any[]) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(pp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "PaymentPeriod/EditAllPaymentPeriod", body, httpOptions);
	}
	payrollReset(model) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ApiResult>(this.apiUrl + "payroll/reset", body, httpOptions);
	}

	addPayrollEmployeeOutPeriods(model) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ApiResult>(this.apiUrl + "PaymentPeriod/AddPayrollEmployeeOutPeriods", body, httpOptions);
	}

	GetPayrollEmployeeOutPeriods(year, companyId, periodCalculateNo) {
		return this.http.get<ApiResult>(
			`${this.apiUrl}PaymentPeriod/GetPayrollEmployeeOutPeriods/${year}/${companyId}/${periodCalculateNo}`,
		);
	}

	checkPaymentPeriodCloseTenant(year) {
		return this.http.get<ApiResult>(`${this.apiUrl}PaymentPeriod/CheckPaymentPeriodClose?year=${year}`);
	}

	deletePaymentPeriod() {
		return this.http.delete(this.apiUrl + "payment/period");
	}
}
