import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DataTableDirective } from "angular-datatables";
import { Observable, Subject, Subscription, combineLatest, firstValueFrom } from "rxjs";
import { isNull, isNullOrUndefined, SharingService } from "src/app/shared/sharing.service";
import { DataSharingService, UserControlService } from "../../../core";
import { action } from "../../../shared/enum/action.enum";
import { MasterSetup } from "../../../shared/enum/master-setup.enum";
import { Module } from "../../../shared/enum/module.enum";
import { WelfareCategory } from "../../../shared/enum/welfare-category.enum";
import { ApiResult } from "../../../shared/models/api-result.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { Permission } from "../../../shared/models/permission.model";
import { CommonService } from "../../../shared/service/common.service";
import { SearchWelfare, Welfare, WelfareDetails } from "../../shared/welfare.model";
import { WelfareService } from "../../shared/welfare.service";
import { DropdownComponent, AngularCommonModule } from "@gofive/angular-common";
import { Router } from "@angular/router";
import { Gender } from "../../../shared/enum/gender.enum";

import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { FinancialItemType } from "src/app/shared/enum/financial-item-type.enum";
import { FinancialItemService } from "src/app/payroll/master/financial-item/shared/financial-item.service";
import { DiligenceAllowanceService } from "src/app/master/diligence-allowance/shared/diligence-allowance.service";
import {
	DiligenceAllowance,
	DiligenceAllowanceDetails,
} from "src/app/master/diligence-allowance/shared/diligence-allowance.model";
import { ToastService } from "@gofive/design-system-toast";
import { HttpStatusCode } from "@angular/common/http";
import { ExpenseCategory, WelfareType } from "src/app/shared/enum/welfare-type.enum";
import { FinancialType } from "src/app/shared/enum/financial-type.enum";
import { FinancialCategory } from "src/app/shared/enum/financial-category.enum";
import { map } from "rxjs/operators";
import { RouteURL } from "src/app/shared/enum/route-path.enum";
import { SelectionDataSource, SelectionModule } from "@gofive/design-system-selection";
import { environment } from "src/environments/environment";
import { ResEventFunction } from "src/app/shared/module/expanse/expense.model";
import { PaymentState } from "src/app/shared/module/payment-modal/payment-modal.component";
import { AvatarModule } from "@gofive/design-system-avatar";
import { FinancialSidebarComponent } from "../../../shared/component/financial-sidebar/financial-sidebar.component";
import { PeoplePickerComponent } from "../../../shared/template/people-picker/people-picker.component";
import { SpinnerModule } from "primeng/spinner";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { ButtonModule } from "@gofive/design-system-button";
import { SelectButtonModule } from "primeng/selectbutton";
import { InfoTooltipComponent } from "../../../shared/template/info-tooltip/info-tooltip.component";
import { BaseModule } from "@gofive/design-system-base";
import { DropdownModule } from "@gofive/design-system-dropdown";
import { InputModule } from "@gofive/design-system-input";
import { NgStyle, NgTemplateOutlet, AsyncPipe, DecimalPipe } from "@angular/common";
import { NavigationModule } from "@gofive/design-system-navigation";
import { PaymentModalComponent } from "../../../shared/module/payment-modal/payment-modal.component";

export enum CreditType {
	salary = 1,
	custom = 2,
}
@Component({
	selector: "app-welfare-master-add",
	templateUrl: "./welfare-master-add.component.html",
	styleUrls: ["./welfare-master-add.component.scss"],
	standalone: true,
	imports: [
		PaymentModalComponent,
		NavigationModule,
		NgStyle,
		InputModule,
		FormsModule,
		DropdownModule,
		NgTemplateOutlet,
		BaseModule,
		InfoTooltipComponent,
		SelectButtonModule,
		ButtonModule,
		SelectionModule,
		TooltipModule,
		ReactiveFormsModule,
		NgxSkeletonLoaderModule,
		SpinnerModule,
		PeoplePickerComponent,
		FinancialSidebarComponent,
		AvatarModule,
		AsyncPipe,
		DecimalPipe,
		TranslateModule,
		AngularCommonModule,
	],
})
export class WelfareMasterAddComponent implements OnInit {
	@ViewChild("sDropdownAgeOfWork") sDropdownAgeOfWork: DropdownComponent;

	get FinancialItemType() {
		return FinancialItemType;
	}
	private language$: Subscription;
	@ViewChildren(DataTableDirective)
	public language: string;
	public lang: string;
	public confirmation: any;
	public saveConfirmation: any;
	public deleteConfirmation: any;
	public placeholder$: Observable<string>;

	public cols: any[];
	public colsWelfare: any[];
	public companyDDL: Dropdown[] = [];
	public positionDDL: Dropdown[];
	public welfareDDL: Dropdown[];
	public creditTypeDDL: any[];
	get CreditType() {
		return CreditType;
	}

	public limitTypeId = 1;

	public formWelfareDetail: UntypedFormGroup;
	public formEmployeePaidVariablePerIndividual: UntypedFormGroup;
	public ranksDDL: Dropdown[];
	public employmentTypeDDL: any;
	public jobLevelDDL: any;
	public welfareId = 0;
	public searchWelfare: any = null;
	public welfareCompany: any = null;
	public welfareCategory: any = null;
	public welfareWorkFlow: any = null;
	public welfareRoundingCode: any = null;
	public welfarePatientType: any = null;
	public requireCompany = false;
	public welfare: Array<any> = [];
	public searchWelfareModel: SearchWelfare = new SearchWelfare();
	public welfareObj: Welfare = new Welfare();
	public action = "";
	public keyword = "";
	public totalAmount: number = null;
	public limitAmountPerTime: number = null;
	public welfareAgeOfWork: any;
	public searchWelfareCategory: any = null;
	public searchCompany: any = null;
	public startTmp = 0;

	public positionId: number;

	public fieldsFinancialItemDDL = { text: "name", value: "financialItemId" };
	public fieldsFinancialItemDDL_EN = { text: "name_EN", value: "financialItemId" };

	public fieldsDDL = { text: "name", value: "id" };
	public fieldsDDL_EN = { text: "name_EN", value: "id" };

	public fieldsGenderDDL = { text: "text", value: "value", iconClass: "iconClass" };
	peopleEmployeeFields: any = { text: "fullName", picture: "pictureURL", value: "employeeId" };
	peopleEmployeeFields_EN: any = { text: "fullName_EN", picture: "pictureURL", value: "employeeId" };

	public fields = { text: "text", value: "value" };

	public isloading = false;
	public welfare$: Subscription;
	public employmentType = false;
	public ranks = false;
	public gender = false;
	public displayEmployee = false;
	public displayEmployee2 = false;
	public monthAgeOfWork = false;
	public withdrawSystem = false;
	public isLoad = true;
	public isStack = false;
	public isExtra = false;
	public showAmountLimit = false;
	public isShowInput = false;
	public welfareCompanyId: number;
	public employeeList: any = [];
	public employeeList2: any = [];
	public companyId: any;
	public isLoadRank = false;
	public numberRights: number;
	public age = 1;
	public ageType = 1;

	public daily = 1;
	public monthly = 2;
	public paymentType = this.monthly;
	public isDaily = false;
	public isPackagePro = false;

	public amountEmployee = 0;
	private month = 1;
	private year = 2;
	public ageTypeDDL: any[];
	public amountAllowanceDDL: any[];
	public monthDDL: any[];
	public welfareEmployeeGroupDDL: any[];
	public amountAllowance = 1;

	public arrGender: SelectionDataSource[];
	public conditionWelfare: any[];
	public wfh: number = WelfareCategory.WFH;
	public diligenceAllowance: number = WelfareCategory.diligenceAllowance;
	public filterEmploymentTypeList: Dropdown[] = [];
	public filterRankList: Dropdown[] = [];
	public filterRankListFrom: any[] = [];
	public filterRankListTo: any[] = [];
	public permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	public permissionPlan: any = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};

	public permissionFinancial: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	public subscriptionUser$: Subscription;
	public currentWelfareId$: Subscription;
	public diligenceAllowanceModel: DiligenceAllowance = new DiligenceAllowance();
	public welfareTypes: any[];
	public welfareType = 1;
	public isManageWFH = false;

	groups: any[] = [];

	conditions: any;
	welfarePaidId = 1;
	limitAmountSalaryPercentage: number = null;

	additionsAll: any;
	additionId: any;
	deductionsAll: any;
	deductionId: any;
	get WelfareTypeEnum() {
		return WelfareType;
	}
	get ExpenseCategoryEnum() {
		return ExpenseCategory;
	}
	get isReimbursementType() {
		return [this.WelfareTypeEnum.payrollReimbursement, this.WelfareTypeEnum.additionalReimbursement].includes(
			this.welfareType,
		);
	}
	optionFinancialType: any;
	hasWelfareModule = false;

	constructor(
		private welfareService: WelfareService,

		private commonService: CommonService,
		public financialItemService: FinancialItemService,
		private diligenceAllowanceService: DiligenceAllowanceService,
		private userControlService: UserControlService,
		private _toastService: ToastService,
		private router: Router,
		public dataShare: DataSharingService,
		public sharingService: SharingService,
		private translate: TranslateService,
		private fb: UntypedFormBuilder,
		private _dialogService: DialogService,
	) {
		this.loadData();
		this._isLoaded = combineLatest([
			this._isLoadDDLPosition,
			this._isLoadDDLRanks,
			this._isLoadDDLMasterSetup,
			this._isLoadIconsExpense,
		]).pipe(
			map((values) => {
				return values.every((value) => value);
			}),
		);
	}

	public _isLoaded: Observable<boolean>;
	public _doneLoading: Subscription;
	public _isLoadDDLCompany: Subject<boolean> = new Subject<boolean>();
	public _isLoadDDLPosition: Subject<boolean> = new Subject<boolean>();
	public _isLoadDDLRanks: Subject<boolean> = new Subject<boolean>();
	public _isLoadDDLMasterSetup: Subject<boolean> = new Subject<boolean>();
	public _isLoadIconsExpense: Subject<boolean> = new Subject<boolean>();
	ngOnInit() {
		window.scrollTo(0, 0);
		this.manageWFH();

		this.formWelfareDetail = this.fb.group({
			items: this.fb.array([]),
		});

		firstValueFrom(
			this.userControlService.authorizeControlByListFunctionCode([
				Module.WEL006,
				Module.MAS010,
				Module.EXP001,
				Module.SYS018,
			]),
		).then((res) => {
			this.permission = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.WEL006) : null;
			this.permissionFinancial = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.MAS010) : null;
			this.permissionPlan = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.SYS018) : null;
			this.hasWelfareModule = this.permission?.allowGrant;
			if (!this.hasWelfareModule) {
				const permissionEXP001 = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.EXP001) : null;
				this.permission = permissionEXP001;
				this.welfareType = WelfareType.additionalReimbursement;
			}
		});

		this.language$ = this.dataShare.currentLanguage.subscribe(async (lang) => {
			this.language = lang;
			this.lang = this.language;
			this.placeholder$ = this.translate.instant("component_welfare");
			this.bonusWillPaidDDL = [
				{
					text: this.translate.instant("wel006_following_payroll_period"),
					value: 1,
				},
				{
					text: this.translate.instant("wel006_specific_payroll_period"),
					value: 2,
					dataSource:
						this.lang == "th-TH" ? this.sharingService.modelMonthName_TH : this.sharingService.modelMonthName_EN,
				},
			];

			this.optionFinancialType = [
				{ label: "common_income", value: FinancialType.income },
				{ label: "common_deduction", value: FinancialType.deduct },
			];

			this.conditions = [
				{ name: this.translate.instant("wel006_welfare_paid_same_amount"), id: ConditionTypeWelfare.PaidSameAmount },
				{
					name: this.translate.instant("wel006_welfare_paid_variable_per_individual"),
					id: ConditionTypeWelfare.PaidVariablePerIndividual,
				},
				{
					name: this.translate.instant("wel006_welfare_paid_variously_group"),
					id: ConditionTypeWelfare.PaidVariouslyGroup,
				},
			];
			this.ageTypeDDL = [
				{
					id: 1,
					name: this.translate.instant("common_month"),
					name_EN: this.translate.instant("common_month"),
					checked: null,
					dataId: 1,
					value: "1",
					valueNum: 0,
				},
				{
					id: 2,
					name: this.translate.instant("common_year"),
					name_EN: this.translate.instant("common_year"),
					checked: null,
					dataId: 2,
					value: "2",
					valueNum: 0,
				},
			];
			this.amountAllowanceDDL = [
				{
					id: this.daily,
					name: this.translate.instant("wel006_day"),
					name_EN: this.translate.instant("wel006_day"),
					checked: null,
					dataId: 1,
					value: "1",
					valueNum: 0,
				},
				{
					id: this.monthly,
					name: this.translate.instant("wel006_month"),
					name_EN: this.translate.instant("wel006_month"),
					checked: null,
					dataId: 1,
					value: "1",
					valueNum: 0,
				},
				// { "id": 2, "name": this.translate.instant("common_year"), "name_EN": this.translate.instant("common_year"), "checked": null, "dataId": 2, "value": "2", "valueNum": 0 },
			];

			this.monthDDL = [
				{
					id: 1,
					name: this.translate.instant("wel006_month"),
					name_EN: this.translate.instant("wel006_month"),
					checked: null,
					dataId: 1,
					value: "1",
					valueNum: 0,
				},
			];

			this.setConditionWelfare();

			this.arrGender = [
				{ text: this.translate.instant("emp001_male"), value: Gender.male },
				{ text: this.translate.instant("emp001_female"), value: Gender.female },
			];
			this.welfareEmployeeGroupDDL = [
				{ value: 1, text: this.translate.instant("wel006_day") },
				{ value: 2, text: this.translate.instant("wel006_month") },
			];

			this.creditTypeDDL = [
				{ id: CreditType.salary, name: "wel006_based_on_salary" },
				{ id: CreditType.custom, name: "wel006_custom" },
			];
		});

		this.welfareTypes = [
			{
				text: "option_welfare_type_general",
				value: WelfareType.general,
				icon: "gf-icon-welfare",
				description: "welfare_type_description_general",
			},
			{
				text: "option_welfare_type_extra_allowance2",
				value: WelfareType.extraAllowance,
				icon: "gf-icon-empeo-team3",
				description: "welfare_type_description_extra_allowance",
			},
			{
				text: "option_welfare_type_payroll_reimbursement2",
				value: WelfareType.payrollReimbursement,
				icon: "i-approve-payroll",
				description: "welfare_type_description_payroll_reimbursement",
			},
			{
				text: "option_welfare_type_additional_reimbursement2",
				value: WelfareType.additionalReimbursement,
				icon: "gf-icon-expenses-fit",
				description: "welfare_type_description_additional_reimbursement",
			},
			{
				text: "option_welfare_type_others",
				value: WelfareType.others,
				icon: "gf-icon-horizontal-dots",
				description: "welfare_type_description_others",
			},
		];

		//#region Column datatable
		this.colsWelfare = [
			{ data: "Name", header: "ชื่อ" },
			{ data: "CompanyId", header: "บริษัท" },
			{ data: "WelfareCategoryId", header: "ประเภทสวัสดิการ" },
			{ data: "IsActive", header: "สถานะ" },
			{ header: "Action" },
		];

		this.subscriptionUser$ = this.dataShare.currentUserInfo.subscribe((res) => {
			if (res) {
				this.isPackagePro = res?.modules?.includes(Module.PRO001) || res?.modules?.includes(Module.PRO002);
				this.setConditionWelfare();
			}
		});

		this._doneLoading = this._isLoaded.subscribe(() => {
			setTimeout(() => {
				this.currentWelfareId$ = this.dataShare.currentWelfareId.subscribe((r) => {
					this.welfareId = r;
					this.welfareId > 0 ? this.onEdit() : this.onAdd();
				});
			}, 100);
		});
		//#endregion
	}
	get FinancialType() {
		return FinancialType;
	}

	manageWFH() {
		const navigateTab = this.router.routerState.snapshot.root.queryParams["redirect"];
		this.isManageWFH = navigateTab == "wfh";
		this.router.navigateByUrl("/welfare-master");
	}

	onAdd() {
		this.action = "add";
		this.welfareObj.isActive = true;
		this.welfareObj.allowGender = 1;
		this.welfareObj.calculateAs = 1;
		this.welfareObj.welfareDetails = [];
		this.welfareObj.isAllowanceFromAttendance = false;
		this.isShowInput = true;
		this.welfareCompanyId = -1;
		this.isLoad = false;
	}
	addCompanyDefault() {
		if (isNullOrUndefined(this.companyDDL)) {
			this.companyDDL = [];
		}
		const hasAllSite = this.companyDDL?.find((f) => f.id == -1);
		if (hasAllSite == null) {
			this.companyDDL.unshift({
				id: -1,
				name: this.translate.instant("wel006_all_company_select "),
				name_EN: this.translate.instant("wel006_all_company_select "),
				dataId: 0,
				value: "0",
				valueNum: 0,
				text: "0",
			});
		}
	}
	dataIcons$: Subscription;
	iconsExpense: any;
	isExpenseCustom = false;
	loadData() {
		this.commonService
			.getDDLCompany()
			.then((res) => {
				this.companyDDL = res;
				if (this.welfareType == this.WelfareTypeEnum.general) this.addCompanyDefault();
			})
			.then(() => {
				this._isLoadDDLCompany.next(true);
			});

		this.commonService
			.getDDLPosition(0)
			.then((res) => {
				this.positionDDL = res;
			})
			.then(() => {
				this._isLoadDDLPosition.next(true);
			});

		this.commonService
			.getDDLRanks()
			.then((res) => {
				this.ranksDDL = res;
			})
			.then(() => {
				this._isLoadDDLRanks.next(true);
			});

		this.commonService
			.getDDLMasterSetupById(MasterSetup.employementType)
			.then((res) => {
				this.employmentTypeDDL = res;
				this.setDefualtAgeOfWork();
			})
			.then(() => {
				this._isLoadDDLMasterSetup.next(true);
			});

		this.dataIcons$ = this.welfareService.getExpenseIcons().subscribe(
			(res) => {
				this.iconsExpense = res.data;
				this._isLoadIconsExpense.next(true);
			},
			(error) => {
				console.error(error);
			},
		);
	}

	public setIconNo(id) {
		this.welfareObj.iconNo = id;
	}

	loadFinancialItemDDL(event = null) {
		this.financialItemService.getAllFinancialItemByCompanyId(this.welfareCompanyId).then((res) => {
			this.additionsAll = !isNullOrUndefined(res)
				? res.filter(
						(f) =>
							f.type == FinancialItemType.addition &&
							f.category == FinancialCategory.other &&
							f.isActive &&
							!f.isDelete,
					)
				: [];
			this.deductionsAll = !isNullOrUndefined(res)
				? res.filter(
						(f) =>
							f.type == FinancialItemType.deduction &&
							f.category == FinancialCategory.other &&
							f.isActive &&
							!f.isDelete,
					)
				: [];
			this.welfareObj.financialItemId = event ?? this.welfareObj.financialItemId;
		});
	}
	onEdit() {
		this.action = "edit";

		this.welfareService
			.getWelfareById(this.welfareId)
			.then((res) => {
				this.welfareObj = res?.data;
				this.isExpenseCustom = this.iconsExpense.some((s) => s.id === this.welfareObj.iconNo);
				this.isShowInput =
					this.welfareObj.welfareCategoryId == WelfareCategory.other ||
					this.welfareObj.welfareCategoryId == WelfareCategory.medicalExpenses ||
					this.welfareObj.welfareCategoryId == WelfareCategory.advancesPayment ||
					this.welfareObj.welfareCategoryId == WelfareCategory.EPP;
				this.gender = this.welfareObj.allowGender > 0;
				this.welfareType = this.welfareObj.welfareType;
				this.isStack = this.welfareObj.isStack;
				this.isExtra = this.welfareObj.isExtra;
				this.monthAgeOfWork = this.welfareObj.monthAgeOfWork > 0;

				if (
					this.welfareObj?.isSystem &&
					(this.welfareType == WelfareType.payrollReimbursement ||
						this.welfareType == WelfareType.additionalReimbursement)
				) {
					this.welfareTypes = this.welfareTypes.filter(
						(f) => f.value == WelfareType.payrollReimbursement || f.value == WelfareType.additionalReimbursement,
					);
				} else {
					this.welfareTypes = this.welfareTypes.filter((f) => f.value == this.welfareType);
				}

				if (this.welfareType != WelfareType.general && this.welfareType != WelfareType.additionalReimbursement) {
					this.companyDDL = this.companyDDL.filter((f) => f.id > 0);
				}

				if (this.welfareObj.companyId > 0) {
					this.welfareCompanyId = this.welfareObj.companyId;
				} else {
					this.addCompanyDefault();
					this.welfareCompanyId = -1;
				}

				this.lockFilter = [
					{ index: 1 },
					{ index: 2 },
					{ index: 3 },
					{ index: 6 },
					{ index: 7 },
					{ index: 8 },
					{ index: 9 },
					{ index: 10, value: [1, 2, 4] },
					{ index: 11 },
					{ index: 12 },
					{ index: 13 },
				];

				this.loadFinancialItemDDL();
				this.welfareObj.isResposiblePerson =
					this.welfareObj.welfareEmployeeResponsibilities?.length > 0 &&
					this.welfareObj.isIndividual &&
					this.welfareObj.conditionType == ConditionTypeWelfare.None;
				if (this.welfareObj.isIndividual) {
					this.employeeList = this.welfareObj.welfareEmployee;
				}
				this.welfareObj.isAmount =
					((this.welfareObj.isSelfService || this.welfareObj.financialItemId > 0) && this.welfareCompanyId > 0) ||
					this.welfareObj.isSelfService;
				this.welfareObj.isFinanCialItem =
					this.welfareObj.financialItemId > 0 &&
					(this.welfareType == WelfareType.extraAllowance ||
						this.welfareType == WelfareType.payrollReimbursement ||
						this.welfareType == WelfareType.others) &&
					this.welfareCompanyId > 0;

				if (
					this.welfareObj.conditionType == ConditionTypeWelfare.PaidVariouslyGroup &&
					this.welfareObj.welfareEmployee != null
				) {
					let groupAmount = [];
					this.welfareObj.welfareEmployee?.forEach((item) => {
						const has = groupAmount.find((f) => f.amount == item.amount && f.paymentType == item.paymentType);
						if (has == null) {
							groupAmount.push(item);
						}
					});
					this.groups = [];
					groupAmount?.forEach((item) => {
						const model = this.welfareObj.welfareEmployee?.filter(
							(f) => f.amount == item.amount && f.paymentType == item.paymentType,
						);

						this.groups.push({
							id: this.groups.length,
							employee: model,
							amount: item.amount,
							paymentType: item.paymentType,
						});
					});
					this.groups = this.groups?.sort();
				}

				if (this.welfareObj.welfareEmploymentTypes != null) {
					this.employmentTypeDDL = this.welfareObj.employmentTypeDDL;
					this.employmentType = true;
				}

				if (this.welfareObj.welfareRanks != null) {
					this.ranksDDL = this.welfareObj.ranksDDL;
					this.ranks = true;
				}

				this.clearFormWelfareDetail();
				if (this.welfareObj.welfareDetails != null) {
					if (
						this.welfareObj.welfareType == this.WelfareTypeEnum.payrollReimbursement ||
						this.welfareObj.welfareType == this.WelfareTypeEnum.additionalReimbursement ||
						this.welfareObj.welfareType == this.WelfareTypeEnum.others
					) {
						this.showAmountLimit = true;
					}
					this.filterRankList = this.ranksDDL.filter((r) => r.selected).sort();

					if (!this.welfareObj.isStack) {
						this.totalAmount =
							this.welfareObj.welfareDetails[0].totalAmount > 0 ? this.welfareObj.welfareDetails[0].totalAmount : null;
						this.numberRights =
							this.welfareObj.welfareDetails[0].totalTime > 0 ? this.welfareObj.welfareDetails[0].totalTime : null;
						this.limitAmountPerTime =
							this.welfareObj.welfareDetails[0].limitAmountPerTime > 0
								? this.welfareObj.welfareDetails[0].limitAmountPerTime
								: null;
						this.limitAmountSalaryPercentage =
							this.welfareObj.welfareDetails[0].limitAmountSalaryPercentage > 0
								? this.welfareObj.welfareDetails[0].limitAmountSalaryPercentage
								: null;
						this.usableAmountPercentage =
							this.welfareObj.welfareDetails[0].usableAmountPercentage > 0
								? this.welfareObj.welfareDetails[0].usableAmountPercentage
								: null;
						this.extraAmountPercentage =
							this.welfareObj.welfareDetails[0].extraAmountPercentage > 0
								? this.welfareObj.welfareDetails[0].extraAmountPercentage
								: null;
						this.extraAmount =
							this.welfareObj.welfareDetails[0].extraAmount > 0 ? this.welfareObj.welfareDetails[0].extraAmount : null;
						this.welfareObj.welfareDetails[0].minimumRank = null;
						this.welfareObj.welfareDetails[0].maximumRank = null;
					} else {
						this.welfareObj.welfareDetails.forEach((item) => {
							item.minimumRankFromDDL = this.filterRankList;
							item.maximumRankToDDL = this.filterRankList;

							item.totalAmount = item.totalAmount > 0 ? item.totalAmount : null;
							item.totalTime = item.totalTime > 0 ? item.totalTime : null;
							item.limitAmountPerTime = item.limitAmountPerTime > 0 ? item.limitAmountPerTime : null;
							const formItems = <UntypedFormArray>this.formWelfareDetail.controls.items;
							formItems.push(this.formAddFieldValue(item.minimumRank, item.maximumRank));
							if (
								this.welfareObj.isIndividual &&
								this.welfareObj.welfareCategoryId != WelfareCategory.other &&
								this.welfareObj.welfareCategoryId != WelfareCategory.medicalExpenses
							) {
								item.maximumRank = null;
								item.minimumRank = null;
							}
						});
					}
				} else {
					this.welfareObj.welfareDetails = [];
					if (this.welfareObj.conditionType == ConditionTypeWelfare.PaidVariouslyGroup) {
						this.addFieldValue(0, 0);
					}
				}

				if (this.welfareObj.conditionType == ConditionTypeWelfare.PaidVariablePerIndividual) {
					this.intitialFormEmployeePaidVariablePerIndividual();
					this.updateDataControls(this.welfareObj.welfareEmployee);
				}
				if (this.welfareObj.isResposiblePerson && this.welfareObj.welfareEmployeeResponsibilities?.length > 0) {
					this.responsiblePersonList = this.welfareObj.welfareEmployeeResponsibilities;
					this.mapDataResponsiblePersonList();
					this.welfareObj.conditionType = ConditionTypeWelfare.None;
				}

				if ((this.welfareObj.monthAgeOfWork / 12) % 1 != 0) {
					this.age = this.welfareObj.monthAgeOfWork;

					this.ageType = this.month;
				} else {
					this.age = this.welfareObj.monthAgeOfWork / 12;
					this.ageType = this.year;
				}
				this.welfareCompany = !isNullOrUndefined(this.companyDDL)
					? this.companyDDL.find((s) => s.id == this.welfareObj.companyId)
					: null;
				this.diligentAllowanceType = [
					{ text: "wel006_fixed", value: this.fixed },
					{ text: "wel006_progressive", value: this.progressive },
				];
				this.diligenceAllowanceService.getDiligenceAllowanceByCompanyId(this.welfareObj.companyId).then((result) => {
					if (result.status == HttpStatusCode.Ok) {
						this.diligenceAllowanceModel = <DiligenceAllowance>result.body;
					} else if (result.status == HttpStatusCode.NoContent) {
						this.diligenceAllowanceService.diligenceAllowanceInitialConfig(this.welfareObj.companyId).then((r) => {
							if (r.status == HttpStatusCode.Ok) {
								const body = <ApiResult>r.body;
								this.diligenceAllowanceModel = body.data.data;
							}
						});
					} else {
						this.diligenceAllowanceModel.isStack = false;
						this.diligenceAllowanceModel.limitDayOfLate = 5;
						this.diligenceAllowanceModel.diligenceAmount = 0;
						this.diligenceAllowanceModel.diligenceAllowanceId = 0;
						this.diligenceAllowanceModel.minuteStampInBefore = 0;
						this.diligenceAllowanceModel.minuteStampOutAfter = 0;
						this.diligenceAllowanceModel.paymentType = this.monthly;
					}

					this.selectPaymentType(this.diligenceAllowanceModel.paymentType);
				});
			})
			.finally(() => {
				if (this.isExtra) {
					this.onChangeIsExtra();
				}
				this.isLoad = false;
			});
	}

	ngOnDestroy(): void {
		this.language$?.unsubscribe();
		this.currentWelfareId$?.unsubscribe();
		this._doneLoading?.unsubscribe();
		this.subscriptionUser$?.unsubscribe();
		this.dataShare.setWelfareId(0);
	}
	//#region Load data

	loadConfirm() {
		this.confirmation = this.translate.instant("common_confirmation");
		this.saveConfirmation = this.translate.instant("common_save_confirmation");
		this.deleteConfirmation = this.translate.instant("common_delete_confirmation");
	}
	//#endregion

	//#region Function on change
	checkWelfare() {
		this.welfareId = !isNullOrUndefined(this.searchWelfare) ? this.searchWelfare.id : 0;
	}
	get hiddenWelfareType() {
		return this.action != "add";
	}
	setDefualtAgeOfWork() {
		this.ageType = this.month;
		this.age = 1;
	}
	changeAge() {
		this.age = this.age < 1 ? 1 : this.age;
	}

	clear() {
		this.welfareObj = new Welfare();
		this.welfareId = 0;
	}

	addFieldValue(minimumRank = null, maximumRank = null): void {
		let welfareDetail: WelfareDetails = {
			welfareDetailId: 0,
			welfareId: this.welfareId,
			minimumRank: null,
			maximumRank: null,
			minimumRankFrom: new Dropdown(),
			maximumRankTo: new Dropdown(),
			minimumAgeOfWork: 0,
			maximumAgeOfWork: 0,
			totalTime: null,
			totalAmount: null,
			limitAmountPerTime: null,
			description: "",
			usableAmountPercentage: null,
			extraAmountPercentage: 0,
			limitAmountSalaryPercentage: null,
			extraAmount: 0,
			minimumRankFromDDL: this.filterRankList,
			maximumRankToDDL: this.filterRankList,
		};
		this.welfareObj.welfareDetails.push(welfareDetail);
		const formItems = <UntypedFormArray>this.formWelfareDetail.controls.items;
		formItems.push(this.formAddFieldValue(minimumRank, maximumRank));
	}

	formAddFieldValue(minimumRank, maximumRank): UntypedFormGroup {
		return this.fb.group({
			minimumRank: new UntypedFormControl(minimumRank, Validators.required),
			maximumRank: new UntypedFormControl(maximumRank, Validators.required),
			totalTime: new UntypedFormControl(null),
			totalAmount: new UntypedFormControl(null),
			limitAmountPerTime: new UntypedFormControl(null),
			limitAmountSalaryPercentage: new UntypedFormControl(null),
			extraAmount: new UntypedFormControl(0),
			usableAmountPercentage: new UntypedFormControl(0),
			extraAmountPercentage: new UntypedFormControl(0),
		});
	}

	deleteFieldValue(i): void {
		this.welfareObj.welfareDetails.splice(i, 1);
		const formItems = <UntypedFormArray>this.formWelfareDetail.controls.items;
		formItems.removeAt(i);
	}

	onChangeCondition(event) {
		this.welfareObj.welfareDetails = [];
		this.showAmountLimit = event.checked;
	}

	onChangeStack(event) {
		this.isStack = event.value;
		this.welfareObj.welfareDetails = [];
		this.clearFormWelfareDetail();
		if (event.value) {
			this.addFieldValue();
		}
	}
	clearFormWelfareDetail() {
		const formItems = <UntypedFormArray>this.formWelfareDetail.controls.items;
		while (formItems.length !== 0) {
			formItems.removeAt(0);
		}
	}

	back() {
		if (this.isManageWFH) {
			this.router.navigateByUrl(`/${RouteURL.tenantSetup}/${RouteURL.location}`);
		} else {
			this.router.navigateByUrl(`${RouteURL.tenantSetup}/${RouteURL.welfare}`);
		}
		this.dataShare.setWelfareId(0);
		this.clear();
	}

	onSelectEmploymentType(item, event) {
		this.onSelectBox(this.filterEmploymentTypeList, item, event.value);
	}
	onChangeRank(item, event) {
		item.selected = event.value;
	}
	onClickRank(item) {
		if (item.selected) {
			const isDul = this.filterRankList.find((r) => r.id == item.id);
			if (isDul == null) {
				this.filterRankList.push(item);
				this.filterRankList.sort((a, b) => (a.valueNum > b.valueNum ? 1 : -1));
			}
		} else {
			const index = this.filterRankList.findIndex((r) => r.id == item.id);
			this.filterRankList.splice(index, 1);
			if (this.welfareObj.welfareDetails.length > 0) {
				const modelData = this.welfareObj.welfareDetails.find(
					(r) => r.maximumRank == item.id || r.minimumRank == item.id,
				);
				if (modelData != null) {
					this._toastService.Warning({
						title: this.translate.instant("common_warning"),
						description: this.translate.instant("wel006_filter_ranks_warn_2"),
					});
					item.selected = true;
				}
			}
		}
	}
	onChangeExtra(event) {
		this.isExtra = event.checked;
	}
	selectCompany(value) {
		this.welfareCompanyId = value;
		this.loadFinancialItemDDL();
		this.setWelfareCompany();
		this.welfareObj.financialItemId = 0;
	}
	setWelfareCompany() {
		this.lockFilter = [
			{ index: 1 },
			{ index: 2 },
			{ index: 3 },
			{ index: 6 },
			{ index: 7 },
			{ index: 8 },
			{ index: 9 },
			{ index: 10, value: [1, 2, 4] },
			{ index: 11 },
			{ index: 12 },
			{ index: 13 },
		];
	}
	onSelectBox(model, item, checked) {
		item.selected = checked;
		if (checked) {
			const isDul = model.find((r) => r.id == item.id);
			if (isDul == null) {
				model.push(item);
				model.sort((a, b) => (a.valueNum > b.valueNum ? 1 : -1));
			}
		} else {
			const index = model.findIndex((r) => r.id == item.id);
			model.splice(index, 1);
		}
	}

	openDDLRankList() {
		if (this.filterRankList.length == 0) {
			this._toastService.Warning({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("wel006_filter_ranks_warn"),
			});
			this.ranks = true;
		}
	}
	selectRankListFrom(item, event) {
		const rankModel = this.filterRankList.find((r) => r.id == event.value);
		item.maximumRankToDDL = this.filterRankList.filter((r) => r.valueNum <= rankModel.valueNum);
	}
	selectRankListTo(item, event) {
		const rankModel = this.filterRankList.find((r) => r.id == event.value);
		item.minimumRankFromDDL = this.filterRankList.filter((r) => r.valueNum >= rankModel.valueNum);
	}
	get enumAction() {
		return action;
	}
	save() {
		this.isValidForm = false;
		this.formEmployeePaidVariablePerIndividual?.markAllAsTouched();
		if (
			this.welfareObj.isResposiblePerson &&
			!this.welfareObj.financialItemId &&
			this.welfareType == this.WelfareTypeEnum.others
		) {
			this.welfareObj.isAmount = true;
			this.welfareObj.isFinanCialItem = true;
			this.isValidForm = true;
			return this._toastService.Validation({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_validate_please_choose", {
					fieldName: this.translate.instant("mas010_financial"),
				}),
			});
		}
		if (this.welfareObj.isResposiblePerson && this.responsiblePersonList?.length <= 0) {
			this.isValidForm = true;
			return this._toastService.Validation({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_validate_please_choose", {
					fieldName: this.translate.instant("welfare_responsible_person"),
				}),
			});
		}
		if (
			(this.welfareType == this.WelfareTypeEnum.extraAllowance ||
				(this.welfareObj.isAmount &&
					this.welfareObj.isFinanCialItem &&
					this.welfareType == this.WelfareTypeEnum.others)) &&
			this.welfareObj.isResposiblePerson == false &&
			this.welfareObj.conditionType === ConditionTypeWelfare.PaidSameAmount &&
			this.welfareObj.amount <= 0 &&
			this.welfareObj.isIndividual == false
		) {
			this.isValidForm = true;
			return this._toastService.Validation({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_please_check_data"),
			});
		}

		const isFuelExpense = this.welfareObj.expenseCategoryId === ExpenseCategory.fuel;
		const isSetFuelRate = this.welfareObj.isSetFuelRate;
		const hasFuelRate = this.welfareObj.fuelRate && this.welfareObj.fuelRate > 0;

		if (isFuelExpense && this.isReimbursementType && isSetFuelRate && !hasFuelRate) {
			this.isValidForm = true;
			return this._toastService.Validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
		}
		if (
			!this.welfareObj.name ||
			this.welfareCompanyId == null ||
			(this.welfareType == this.WelfareTypeEnum.payrollReimbursement &&
				this.welfareObj.financialItemId <= 0 &&
				this.welfareCompanyId > 0) ||
			(this.welfareType == this.WelfareTypeEnum.others &&
				this.welfareObj.isAmount &&
				this.welfareObj.isFinanCialItem &&
				this.welfareObj.financialItemId <= 0 &&
				this.welfareCompanyId > 0) ||
			(this.welfareType == this.WelfareTypeEnum.extraAllowance &&
				this.welfareObj.financialItemId <= 0 &&
				this.welfareCompanyId > 0) ||
			(!this.welfareObj.isIndividual &&
				this.formWelfareDetail.invalid &&
				this.welfareType != this.WelfareTypeEnum.extraAllowance) ||
			(isNullOrUndefined(this.welfareObj.iconNo) &&
				(this.welfareType == this.WelfareTypeEnum.payrollReimbursement ||
					this.welfareType == this.WelfareTypeEnum.additionalReimbursement ||
					(this.welfareType == this.WelfareTypeEnum.others && this.welfareObj.isSelfService))) ||
			((this.welfareType == this.WelfareTypeEnum.extraAllowance || this.welfareType == this.WelfareTypeEnum.others) &&
				this.welfareObj.conditionType == ConditionTypeWelfare.PaidVariablePerIndividual &&
				(this.formEmployeePaidVariablePerIndividual?.invalid ||
					this.employeePaidVariablePerIndividualList?.length == 0)) ||
			((this.welfareType == this.WelfareTypeEnum.extraAllowance || this.welfareType == this.WelfareTypeEnum.others) &&
				this.welfareObj.isResposiblePerson &&
				this.responsiblePersonList?.length == 0)
		) {
			this.isValidForm = true;
			return this._toastService.Validation({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_please_check_data"),
			});
		}
		this.loadConfirm();
		if (this.action == action.add) {
			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.saveConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.mapdata();

					this._toastService
						.Loading(this.upsertMasWelfare(this.welfareObj), {
							title: this.translate.instant("common_sending"),
							description: this.translate.instant("common_please_wait_a_moment"),
						})
						.subscribe((res) => {
							if (res?.data?.result) {
								this._toastService.Success({
									title: this.translate.instant("toast_title_success"),
									description: this.translate.instant("toast_success_created", {
										titleName: this.translate.instant("component_welfare"),
									}),
								});

								this.back();
							} else {
								this._toastService.Error({
									title: this.translate.instant("common_fail"),
									description: res.message,
								});
							}
						});
				}
			});
		} else if (this.action == action.edit) {
			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.translate.instant("common_edit_confirmation"),
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.mapdata();

					this._toastService
						.Loading(this.upsertMasWelfare(this.welfareObj, false), {
							title: this.translate.instant("common_sending"),
							description: this.translate.instant("common_please_wait_a_moment"),
						})
						.subscribe((res) => {
							if (res?.data?.result) {
								this._toastService.Success({
									title: this.translate.instant("toast_title_success"),

									description: this.translate.instant("toast_success_edited", {
										titleName: this.translate.instant("component_welfare"),
									}),
								});

								this.back();
							} else {
								this._toastService.Error({
									title: this.translate.instant("common_fail"),
									description: res.message,
								});
							}
						});
				}
			});
		}
	}
	upsertMasWelfare(model, isAdd = true): Observable<any> {
		if (isAdd) {
			return this.welfareService.addMasWelfare(model);
		} else {
			return this.welfareService.editMasWelfare(model);
		}
	}

	mapdata() {
		this.isValid = false;
		this.loadConfirm();
		this.welfareObj.welfareId = this.welfareId;
		this.welfareObj.companyId = this.welfareCompanyId > 0 ? this.welfareCompanyId : 0;
		this.welfareObj.welfareType = this.welfareType;
		this.welfareObj.name_EN = this.welfareObj.name;
		this.welfareObj.description_en = this.welfareObj.description;

		if (this.action == "add") {
			if (this.welfareObj.isMedicalExpense) {
				this.welfareObj.welfareCategoryId = WelfareCategory.medicalExpenses;
			} else {
				this.welfareObj.welfareCategoryId = WelfareCategory.other;
			}
		} else {
			if (
				this.welfareObj.welfareCategoryId == WelfareCategory.medicalExpenses ||
				this.welfareObj.welfareCategoryId == WelfareCategory.other
			) {
				if (this.welfareObj.isMedicalExpense) {
					this.welfareObj.welfareCategoryId = WelfareCategory.medicalExpenses;
				} else {
					this.welfareObj.welfareCategoryId = WelfareCategory.other;
				}
			}
		}

		this.welfareObj.isStack = this.isStack;
		this.welfareObj.isExtra = this.isExtra;
		this.welfareObj.isAllSite = this.welfareObj.companyId == 0;

		this.welfareObj.financialItemId =
			(this.welfareObj.financialItemId > 0 ||
				this.welfareType == WelfareType.extraAllowance ||
				this.welfareType == WelfareType.payrollReimbursement ||
				this.welfareType == WelfareType.others) &&
			this.welfareCompanyId > 0
				? this.welfareObj.financialItemId
				: 0;

		this.welfareObj.workflowId = null;
		this.welfareObj.patientTypeId = null;
		this.welfareObj.roundingCode = null;

		this.welfareObj.isIndividual = isNullOrUndefined(this.welfareObj.isIndividual)
			? false
			: this.welfareObj.isIndividual;
		if (this.welfareObj.isIndividual) {
			this.welfareObj.welfareEmployee = this.employeeList;
		}
		if (this.welfareObj.isIndividual && this.welfareObj.conditionType !== ConditionTypeWelfare.None) {
			this.welfareObj.allowGender = 0;
			this.welfareObj.welfareRanks = null;
			this.welfareObj.welfareEmploymentTypes = null;
			this.welfareObj.welfareDetails = [];
			this.welfareObj.monthAgeOfWork = 0;

			if (this.showAmountLimit) {
				if (!this.welfareObj.isStack) {
					this.welfareObj.welfareDetails = [];
					this.addFieldValue();
					this.welfareObj.welfareDetails[0].maximumRank = 0;
					this.welfareObj.welfareDetails[0].minimumRank = 0;
					this.welfareObj.welfareDetails[0].totalAmount = this.totalAmount > 0 ? this.totalAmount : null;
					this.welfareObj.welfareDetails[0].usableAmountPercentage =
						this.usableAmountPercentage > 0 ? this.usableAmountPercentage : null;
					this.welfareObj.welfareDetails[0].extraAmountPercentage = this.extraAmountPercentage;
					this.welfareObj.welfareDetails[0].extraAmount = this.extraAmount;
					if (this.welfareObj.creditType != CreditType.salary) {
						this.welfareObj.welfareDetails[0].limitAmountPerTime =
							this.limitAmountPerTime > 0 ? this.limitAmountPerTime : null;
					} else {
						this.welfareObj.welfareDetails[0].limitAmountSalaryPercentage =
							this.limitAmountSalaryPercentage > 0 ? this.limitAmountSalaryPercentage : null;
					}
					this.welfareObj.welfareDetails[0].totalTime = this.numberRights > 0 ? this.numberRights : null;
				}
			}
		} else {
			if (this.showAmountLimit) {
				if (!this.welfareObj.isStack) {
					this.welfareObj.welfareDetails = [];
					this.addFieldValue();
					this.welfareObj.welfareDetails[0].maximumRank = 0;
					this.welfareObj.welfareDetails[0].minimumRank = 0;
					this.welfareObj.welfareDetails[0].totalAmount = this.totalAmount > 0 ? this.totalAmount : null;
					this.welfareObj.welfareDetails[0].usableAmountPercentage =
						this.usableAmountPercentage > 0 ? this.usableAmountPercentage : null;
					this.welfareObj.welfareDetails[0].extraAmountPercentage = this.extraAmountPercentage;
					this.welfareObj.welfareDetails[0].extraAmount = this.extraAmount;
					if (this.welfareObj.creditType != CreditType.salary) {
						this.welfareObj.welfareDetails[0].limitAmountPerTime =
							this.limitAmountPerTime > 0 ? this.limitAmountPerTime : null;
					} else {
						this.welfareObj.welfareDetails[0].limitAmountSalaryPercentage =
							this.limitAmountSalaryPercentage > 0 ? this.limitAmountSalaryPercentage : null;
					}
					this.welfareObj.welfareDetails[0].totalTime = this.numberRights > 0 ? this.numberRights : null;
				}
			}
			this.welfareObj.welfareRanks = !this.ranks ? null : this.ranksDDL.filter((r) => r.selected);
			this.welfareObj.welfareEmploymentTypes = !this.employmentType
				? null
				: this.employmentTypeDDL.filter((r) => r.selected);
			this.welfareObj.allowGender = this.gender ? this.welfareObj.allowGender : 0;
			if (this.ageType == this.year) {
				this.welfareObj.monthAgeOfWork = this.monthAgeOfWork ? this.age * 12 : 0;
			} else {
				this.welfareObj.monthAgeOfWork = this.monthAgeOfWork ? this.age : 0;
			}
		}
		if (this.welfareObj.welfareCategoryId == WelfareCategory.diligenceAllowance) {
			if (
				this.diligenceAllowanceModel.isShiftTimeCal &&
				(this.diligenceAllowanceModel.minuteStampInBefore == null ||
					this.diligenceAllowanceModel.minuteStampOutAfter == null)
			) {
				this.isValid = true;
				return this._toastService.Error({
					title: this.translate.instant("common_error"),
					description: this.translate.instant("common_please_check_data"),
				});
			}
			this.diligenceAllowanceModel.bonusType = this.diligenceAllowanceModel.isBonus ? 2 : 1;
			this.welfareObj.diligenceAllowance = this.diligenceAllowanceModel;
		}
		if (
			this.welfareObj.welfareType == WelfareType.extraAllowance ||
			(this.welfareType == this.WelfareTypeEnum.others &&
				this.welfareObj.isAmount &&
				!this.welfareObj.isSelfService &&
				this.welfareObj.isFinanCialItem)
		) {
			if (this.welfareObj.conditionType == ConditionTypeWelfare.PaidVariouslyGroup) {
				//แต่ละกลุ่มไม่เท่ากัน
				this.welfareObj.welfareEmployee = [];
				this.groups.forEach((f) => {
					f.employee.forEach((emp) => {
						this.welfareObj.welfareEmployee.push({
							employeeId: emp.employeeId,
							amount: f.amount,
							paymentType: f.paymentType,
						});
					});
				});
			}
		}
		if (this.welfareObj.welfareType == this.WelfareTypeEnum.others) {
			if (!this.welfareObj.isFinanCialItem) {
				this.welfareObj.financialItemId = null;
				this.welfareObj.financialType = null;
			}
		}

		if (
			this.welfareObj.welfareType == WelfareType.extraAllowance ||
			this.welfareObj.welfareType == WelfareType.others
		) {
			if (this.welfareObj.conditionType == ConditionTypeWelfare.PaidVariablePerIndividual) {
				const items = (<UntypedFormArray>this.formEmployeePaidVariablePerIndividual?.get("items"))?.value;
				this.welfareObj.welfareEmployee = items;
			}

			if (this.welfareObj.conditionType == ConditionTypeWelfare.None) {
				this.welfareObj.welfareEmployeeResponsibilities = this.responsiblePersonList;
			}
		}

		if (this.welfareObj.welfareType == WelfareType.extraAllowance) {
			this.welfareObj.welfareDetails = null;
		}
		this.welfareObj.isIndividual = this.welfareObj.isIndividual || this.welfareObj.isResposiblePerson;

		if (!this.welfareObj.isExtra) {
			this.welfareObj.welfareDetails?.map((m) => {
				m.extraAmount = 0;
				m.extraAmountPercentage = 0;
			});
		}
	}

	onSelectAgeOfWork(e?) {
		this.sDropdownAgeOfWork.dropdown.nativeElement.children[0].classList.add("active-sDropdown");
		if (!isNullOrUndefined(e)) {
			this.welfareAgeOfWork = this.sDropdownAgeOfWork.value[0];
		}
	}

	get WelfareCategory() {
		return WelfareCategory;
	}

	limitTime = 0;
	usableAmountPercentage = null;
	extraAmountPercentage = 0;
	extraAmount = 0;

	checkNumberRights() {
		if (this.numberRights > 0) {
			this.welfareObj.welfareDetails[0].totalTime = this.numberRights > 0 ? this.numberRights : null;
		} else {
			this.numberRights = this.translate.instant("common_unlimited");
		}
	}

	onChangeIndividual(event) {
		this.welfareObj.isIndividual = event.value;
		if (this.isShowInput) {
			this.welfareObj.welfareDetails = [];
			this.clearFormWelfareDetail();
			if (event.value) {
				this.welfareObj.isStack = false;
				this.isStack = false;
			} else if (this.welfareObj.conditionType == ConditionTypeWelfare.PaidVariouslyGroup) {
				this.addFieldValue();
				this.isStack = true;
			}
		}
	}

	focusIcon(id) {
		document.getElementById(id).classList.add("icon-bath-focus");
	}

	blurIcon(id) {
		document.getElementById(id).classList.remove("icon-bath-focus");
	}
	checkboxIndividual() {
		this.welfareObj.isIndividual = true;
	}
	setSelfService() {
		this.welfareObj.isSelfService = this.welfareObj.isMedicalExpense;
	}
	tabWelfare = true;
	tabDiligentAllowance = false;
	async handleChange(event) {
		const index = event.index;
		this.tabWelfare = false;
		this.tabDiligentAllowance = false;
		switch (index) {
			case 0:
				this.tabWelfare = true;
				break;
			case 1:
				{
					this.tabDiligentAllowance = true;
					await this.sharingService.delayTime(1);
					await this.setInputValue();
					if (
						this.diligenceAllowanceModel.diligenceAllowanceDetails == null ||
						this.diligenceAllowanceModel.diligenceAllowanceDetails.length == 0
					)
						this.addCondition();
				}
				break;
		}
	}
	async setInputValue() {
		await this.onChangeAmountAllowance();
		await this.onChangeReceivingAllowanceConsecutively();
		await this.onChangeDiligentAllowanceType();
	}

	openManual() {
		window.open("https://help.empeo.com/th/");
	}
	public bonusWillPaidDDL: any;
	public bonusWillPaid = 1;

	@ViewChild("spinnerRceivingAlowanceCnsecutively") spinnerRceivingAlowanceCnsecutively: any;
	@ViewChild("spinnerAmountAllowance") spinnerAmountAllowance: any;
	@ViewChild("spinnerMinuteStampInBefore") spinnerMinuteStampInBefore: any;
	@ViewChild("spinnerMinuteStampOutAfter") spinnerMinuteStampOutAfter: any;

	async onChangeReceivingAllowanceConsecutively() {
		if (this.spinnerRceivingAlowanceCnsecutively == null || isNull(this.spinnerRceivingAlowanceCnsecutively.value))
			return;
		await this.sharingService.delayTime(1);
		this.spinnerRceivingAlowanceCnsecutively.formattedValue =
			(await this.spinnerRceivingAlowanceCnsecutively.value) +
			(this.lang == "th-TH" ? " เดือน" : this.spinnerRceivingAlowanceCnsecutively.value > 1 ? " months" : " month");
	}

	async onChangeConsecutiveReceiving(spinnerConsecutiveReceiving, item = null) {
		if (spinnerConsecutiveReceiving == null || isNull(spinnerConsecutiveReceiving.value)) return;
		if (item != null) {
			if (
				item.minimumAgeOfWork != null &&
				item.maximumAgeOfWork != null &&
				item.maximumAgeOfWork < item.minimumAgeOfWork
			) {
				item.minimumAgeOfWork = null;
				this._toastService.Warning({
					title: this.translate.instant("common_warning"),
					description: "ช่วงของเดือนไม่ถูกต้อง",
				});
				spinnerConsecutiveReceiving.formattedValue = "";
			} else {
				item.minimumAgeOfWork = spinnerConsecutiveReceiving.value;
				this.checkRangeDiligenceAllowanceDetails(spinnerConsecutiveReceiving, item);
			}
		} else {
			if (spinnerConsecutiveReceiving == null || isNull(spinnerConsecutiveReceiving.value)) return;
			await this.sharingService.delayTime(1);
			spinnerConsecutiveReceiving.formattedValue =
				(await spinnerConsecutiveReceiving.value) +
				(this.lang == "th-TH" ? " เดือน" : spinnerConsecutiveReceiving.value > 1 ? " months" : " month");
		}
	}

	async onChangeConsecutiveReceivingTo(spinnerConsecutiveReceivingTo, item = null) {
		if (spinnerConsecutiveReceivingTo == null || isNull(spinnerConsecutiveReceivingTo.value)) return;
		if (item != null) {
			if (
				item.minimumAgeOfWork != null &&
				item.maximumAgeOfWork != null &&
				item.maximumAgeOfWork < item.minimumAgeOfWork
			) {
				item.maximumAgeOfWork = null;

				this._toastService.Warning({
					title: this.translate.instant("common_warning"),
					description: "ช่วงของเดือนไม่ถูกต้อง",
				});
				spinnerConsecutiveReceivingTo.formattedValue = "";
			} else {
				item.maximumAgeOfWork = spinnerConsecutiveReceivingTo.value;
				this.checkRangeDiligenceAllowanceDetails(spinnerConsecutiveReceivingTo, item, false);
			}
		} else {
			if (spinnerConsecutiveReceivingTo == null || isNull(spinnerConsecutiveReceivingTo.value)) return;
			await this.sharingService.delayTime(1);
			spinnerConsecutiveReceivingTo.formattedValue =
				(await spinnerConsecutiveReceivingTo.value) +
				(this.lang == "th-TH" ? " เดือน" : spinnerConsecutiveReceivingTo.value > 1 ? " months" : " month");
		}
	}
	async checkRangeDiligenceAllowanceDetails(viewChild, item, type = true) {
		await this.sharingService.delayTime(1);
		let isvalid = true;

		this.diligenceAllowanceModel.diligenceAllowanceDetails
			.filter((f) => f.index != item.index)
			.forEach((f) => {
				// 3-5
				if (
					(item.minimumAgeOfWork <= f.minimumAgeOfWork &&
						item.maximumAgeOfWork >= f.minimumAgeOfWork &&
						item.maximumAgeOfWork <= f.maximumAgeOfWork) ||
					// a <=  3 <= b <= 5
					(item.minimumAgeOfWork >= f.minimumAgeOfWork &&
						item.minimumAgeOfWork <= f.maximumAgeOfWork &&
						item.maximumAgeOfWork >= f.maximumAgeOfWork) ||
					// 3 <= a <= 5 <= b
					(item.minimumAgeOfWork != null &&
						item.maximumAgeOfWork != null &&
						item.minimumAgeOfWork >= f.minimumAgeOfWork &&
						item.maximumAgeOfWork <= f.maximumAgeOfWork) ||
					// 3 <= a b <= 5
					(item.minimumAgeOfWork != null &&
						item.maximumAgeOfWork != null &&
						item.minimumAgeOfWork >= f.minimumAgeOfWork &&
						item.minimumAgeOfWork <= f.maximumAgeOfWork) ||
					// 3 <= a <= 5
					(item.minimumAgeOfWork != null &&
						item.maximumAgeOfWork != null &&
						item.maximumAgeOfWork >= f.minimumAgeOfWork &&
						item.maximumAgeOfWork <= f.maximumAgeOfWork) ||
					// 3 <= b <= 5

					(f.minimumAgeOfWork <= item.minimumAgeOfWork &&
						f.maximumAgeOfWork >= item.minimumAgeOfWork &&
						f.maximumAgeOfWork <= item.maximumAgeOfWork) ||
					// a <=  3 <= b <= 5
					(f.minimumAgeOfWork >= item.minimumAgeOfWork &&
						f.minimumAgeOfWork <= item.maximumAgeOfWork &&
						f.maximumAgeOfWork >= item.maximumAgeOfWork) ||
					// 3 <= a <= 5 <= b
					(item.minimumAgeOfWork != null &&
						item.maximumAgeOfWork != null &&
						f.minimumAgeOfWork >= item.minimumAgeOfWork &&
						f.maximumAgeOfWork <= item.maximumAgeOfWork) ||
					// 3 <= a b <= 5
					(item.minimumAgeOfWork != null &&
						item.maximumAgeOfWork != null &&
						f.minimumAgeOfWork >= item.minimumAgeOfWork &&
						f.minimumAgeOfWork <= item.maximumAgeOfWork) ||
					// 3 <= a <= 5
					(item.minimumAgeOfWork != null &&
						item.maximumAgeOfWork != null &&
						f.maximumAgeOfWork >= item.minimumAgeOfWork &&
						f.maximumAgeOfWork <= item.maximumAgeOfWork)
					// 3 <= b <= 5
				) {
					isvalid = false;
				}
			});
		if (isvalid) {
			let formattedSuffix = "";
			if (this.lang == "th-TH") {
				formattedSuffix = " เดือน";
			} else {
				formattedSuffix = viewChild.value > 1 ? " months" : " month";
			}
			viewChild.formattedValue = (await viewChild.value) + formattedSuffix;
		} else {
			if (type) {
				item.minimumAgeOfWork = null;
			} else {
				item.maximumAgeOfWork = null;
			}
			viewChild.value = null;
			viewChild.formattedValue = "";
			this._toastService.Warning({
				title: this.translate.instant("common_warning"),
				description: "ไม่สามารถใส่ช่วงของเดือนซ้ำ",
			});
		}
	}

	async onChangeAmountAllowance() {
		if (this.spinnerAmountAllowance == null || isNull(this.spinnerAmountAllowance.value)) return;
		await this.sharingService.delayTime(1);
		this.spinnerAmountAllowance.formattedValue = "฿" + (await this.spinnerAmountAllowance.value);
	}

	@ViewChildren("spinnerConsecutiveReceiving") spinnerConsecutiveReceiving: QueryList<any>;
	@ViewChildren("spinnerConsecutiveReceivingTo") spinnerConsecutiveReceivingTo: QueryList<any>;
	@ViewChildren("spinnerAmount") spinnerAmount: QueryList<any>;
	@ViewChildren("spinnerExtraAmountPercentage") spinnerExtraAmountPercentage: QueryList<any>;
	@ViewChildren("spinnerUsableAmountPercentage") spinnerUsableAmountPercentage: QueryList<any>;

	async onChangeDiligentAllowanceType() {
		await this.sharingService.delayTime(1);
		if (this.diligenceAllowanceModel.isStack == this.fixed) {
			await this.onChangeAmountAllowance();
		} else {
			this.spinnerConsecutiveReceiving["_results"].forEach((vh) => {
				this.onChangeConsecutiveReceiving(vh);
			});
			this.spinnerConsecutiveReceivingTo["_results"].forEach((vh) => {
				this.onChangeConsecutiveReceivingTo(vh);
			});
		}
	}

	async onChangeIsExtra() {
		await this.sharingService.delayTime(1);
		if (this.isExtra) {
			this.spinnerUsableAmountPercentage["_results"].forEach((vh) => {
				this.onChangeUsableAmountPercentage(vh);
			});
			this.spinnerExtraAmountPercentage["_results"].forEach((vh) => {
				this.onChangeExtraAmountPercentage(vh);
			});
		}
	}
	async onChangeExtraAmountPercentage(spinnerExtraAmountPercentage) {
		if (spinnerExtraAmountPercentage == null || isNull(spinnerExtraAmountPercentage.value)) return;
		await this.sharingService.delayTime(1);
		spinnerExtraAmountPercentage.formattedValue = (await spinnerExtraAmountPercentage.value) + "%";
	}
	async onChangeUsableAmountPercentage(spinnerUsableAmountPercentage) {
		if (spinnerUsableAmountPercentage == null || isNull(spinnerUsableAmountPercentage.value)) return;
		await this.sharingService.delayTime(1);
		spinnerUsableAmountPercentage.formattedValue = (await spinnerUsableAmountPercentage.value) + "%";
	}

	async addCondition() {
		let minimumAgeOfWork = 1;
		let maximumAgeOfWork = 3;
		if (this.diligenceAllowanceModel?.diligenceAllowanceDetails == null) {
			this.diligenceAllowanceModel.diligenceAllowanceDetails = [];
		}
		const index = this.diligenceAllowanceModel.diligenceAllowanceDetails?.length;
		if (index > 0) {
			minimumAgeOfWork = this.diligenceAllowanceModel.diligenceAllowanceDetails[index - 1].maximumAgeOfWork + 1;
			maximumAgeOfWork = minimumAgeOfWork + 2;
		}
		const data = new DiligenceAllowanceDetails();
		data.index = index + 1;
		data.minimumAgeOfWork = minimumAgeOfWork;
		data.maximumAgeOfWork = maximumAgeOfWork;
		data.amount = 0;
		this.diligenceAllowanceModel.diligenceAllowanceDetails.push(data);
		await this.sharingService.delayTime(1);
		await this.onChangeConsecutiveReceiving(
			this.spinnerConsecutiveReceiving["_results"][this.spinnerConsecutiveReceiving["_results"].length - 1],
		);
		await this.onChangeConsecutiveReceivingTo(
			this.spinnerConsecutiveReceivingTo["_results"][this.spinnerConsecutiveReceivingTo["_results"].length - 1],
		);
	}

	deleteCondition(index) {
		const i = this.diligenceAllowanceModel.diligenceAllowanceDetails.findIndex((r) => r.index == index);
		this.diligenceAllowanceModel.diligenceAllowanceDetails.splice(i, 1);
	}

	public diligentAllowanceType: any[];

	public financialItemId = 0;
	public isDialog = false;
	public isDialogAdd = false;

	public fixed = false;
	public progressive = true;

	onSidebarFinancial(event) {
		if (event) {
			this.financialItemService
				.GetAllFinancialItemByCompanyIdAndTypeAndCategory(this.welfareCompanyId, 1, WelfareCategory.diligenceAllowance)
				.then((res) => {
					if (res != null) {
						this.financialItemId = res.financialItemId;
						this.isDialog = true;
					} else {
						this._toastService.Warning({
							title: this.translate.instant("common_warning"),
							description: this.translate.instant("common_no_data"),
						});
					}
				});
		} else {
			this.isDialog = false;
		}
	}
	public isSelectShow = false;
	public isValid = false;
	public isValidForm = false;
	sidebarEmployee(displayEmployee, selectShow = false) {
		if (isNullOrUndefined(this.welfareCompanyId) || this.welfareCompanyId == 0) {
			this.isValidForm = true;
			return this._toastService.Warning({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_please_choose_company"),
			});
		}

		this.displayEmployee = displayEmployee;
		this.isSelectShow = selectShow;
	}
	responsiblePersonList: any = [];
	responsiblePersonValue: any = [];
	displayEmployeeResponsiblePerson = false;
	sidebarEmployeeResponsiblePerson(isOpen) {
		if (isNullOrUndefined(this.welfareCompanyId) || this.welfareCompanyId == 0) {
			this.isValidForm = true;
			return this._toastService.Warning({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_please_choose_company"),
			});
		}
		this.displayEmployeeResponsiblePerson = isOpen;
	}
	mapDataResponsiblePersonList() {
		this.responsiblePersonValue = this.responsiblePersonList.map((m) => m.employeeId);
	}

	removeResponsiblePersonList(event) {
		this.responsiblePersonList = this.responsiblePersonList.filter(
			(f) => f.employeeId !== event.selectedItem.employeeId,
		);
		this.responsiblePersonValue = event.value;
	}
	filterFinancialItemName = "";

	filterFinancialItem(event) {
		this.filterFinancialItemName = event.text;
	}
	openDDLFinancialItem() {
		this.filterFinancialItemName = "";
		if (isNullOrUndefined(this.welfareCompanyId) || this.welfareCompanyId == 0) {
			this.isValidForm = true;

			this._toastService.Warning({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_please_choose_company"),
			});
		}
	}
	addGroup() {
		this.groups.push({
			id: this.groups.length,
			employee: [],
			amount: 0,
			paymentType: 2,
		});
	}
	tmpIdSelectEmployee = 0;
	selectEmployee(item) {
		if (isNullOrUndefined(this.welfareCompanyId) || this.welfareCompanyId == 0) {
			this.isValidForm = true;
			return this._toastService.Warning({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_please_choose_company"),
			});
		}

		this.tmpIdSelectEmployee = item.id;
		this.displayEmployee2 = true;
		this.employeeList2 = item.employee;
	}
	selectWelfareType(value) {
		if (value != this.welfareType && this.welfareObj?.isSystem && this.isReimbursementType) {
			this.welfareType = value;
			if (value == WelfareType.payrollReimbursement) {
				this.welfareObj.financialType = FinancialType.income;
			} else {
				this.welfareObj.financialType = null;
			}
		} else if (value != this.welfareType) {
			this.welfareType = value;
			this.welfareObj = new Welfare();

			this.employeePaidVariablePerIndividualTempList = [];
			this.employeePaidVariablePerIndividualList = [...this.employeePaidVariablePerIndividualTempList];
			this.responsiblePersonList = [];
			this.setConditionWelfare();

			this.isValidForm = false;
			this.welfareObj.isResposiblePerson = false;
			if (
				(this.welfareType == WelfareType.extraAllowance || this.welfareType == WelfareType.others) &&
				this.isPackagePro
			) {
				this.welfareObj.isResposiblePerson = true;
				this.welfareObj.conditionType = ConditionTypeWelfare.None;
			} else {
				this.welfareObj.conditionType = ConditionTypeWelfare.PaidSameAmount;
			}

			if (this.welfareType == WelfareType.general || this.welfareType == WelfareType.additionalReimbursement) {
				this.addCompanyDefault();
				this.welfareCompanyId = -1;
			} else {
				this.companyDDL = this.companyDDL.filter((f) => f.id > 0);
				this.welfareCompanyId = null;
			}
			this.setWelfareCompany();

			this.showAmountLimit = false;
		}
	}
	mapEmployee(value) {
		this.displayEmployee2 = value;
		const model = this.groups.find((f) => f.id == this.tmpIdSelectEmployee);
		const dulModel = JSON.parse(JSON.stringify(this.employeeList2));
		if (model != null) {
			this.groups
				.filter((f) => f.id != this.tmpIdSelectEmployee)
				.forEach((f) => {
					if (f.employee.length > 0) {
						let employeeIds = [];
						f.employee.forEach((m) => employeeIds.push(m.employeeId));
						if (employeeIds.length > 0) {
							this.employeeList2 = this.employeeList2.filter(
								(e) => !("," + employeeIds.toString() + ",").includes("," + e.employeeId.toString() + ","),
							);
						}
					}
				});
			model.employee = JSON.parse(JSON.stringify(this.employeeList2));
			if (dulModel.length != model.employee.length) {
				this._toastService.Warning({
					title: this.translate.instant("common_warning"),
					description: this.translate.instant("wel005_cannot_add_employee_dul_group"),
				});
			}
		}
	}
	checkedIsAmount(event) {
		this.welfareObj.isAmount = event.checked;
		this.welfareObj.isFinanCialItem = false;
		this.welfareObj.isSelfService = false;
	}

	checkedIsSelfService(event) {
		this.welfareObj.isSelfService = event.checked;
		if (event.checked) {
			this.selectConditionWelfare({ event: false });
			this.setConditionWelfare(true);
			this.welfareObj.isResposiblePerson = false;
		} else {
			this.setConditionWelfare();
		}
		this.showAmountLimit = false;
	}

	checkedIsFinanCialItem(event) {
		this.welfareObj.isFinanCialItem = event.checked;

		if (!event.checked) {
			this.welfareObj.isResposiblePerson = false;
			this.setConditionWelfare(true);
		} else {
			this.welfareObj.conditionType =
				this.welfareObj.conditionType > 0 ? this.welfareObj.conditionType : ConditionTypeWelfare.PaidSameAmount;
			this.setConditionWelfare();
		}
		this.showAmountLimit = false;
	}

	setConditionWelfare(isCustomOnly = false) {
		if (isCustomOnly) {
			this.conditionWelfare = [{ text: this.translate.instant("common_custom"), value: false }];
		} else {
			this.conditionWelfare = [
				{
					text: this.translate.instant("wel006_resposible_person"),
					value: true,
					iconPicture: this.isPackagePro ? null : this.pathIconPro,
				},
				{ text: this.translate.instant("common_custom"), value: false },
			];
		}
	}

	addFinancialItem() {
		this.isDialogAdd = true;
		this.financialItemId = 0;
		this.welfareObj.financialItemId = 0;
	}

	submitAddFinancialItem(event) {
		setTimeout(() => {
			this.welfareObj.financialItemId = 0;
			this.loadFinancialItemDDL(event);
		}, 100);
	}

	selectPaymentType(paymentType: number) {
		this.diligenceAllowanceModel.paymentType = paymentType;
		this.isDaily = this.diligenceAllowanceModel.paymentType == this.daily;
	}
	toggleTab = {
		eventFunction: ResEventFunction.Close,
		model: { state: PaymentState.ChoosePlan },
	};
	conditionTypeTemp: number = ConditionTypeWelfare.None;
	isIndividualTemp = false;
	selectConditionWelfare(event) {
		if (event.value) {
			if (!this.isPackagePro) {
				if (!this.permissionPlan?.allowGrant) {
					const dialogModel = <DialogInformationModel>{
						title: this.translate.instant("resignation_no_client_permission_dialog"),
						description: this.translate.instant("resignation_no_client_permission_dialog_description"),
						imageUrl: "information.png",
						textButtonConfirm: this.translate.instant("common_ok_got_it"),
						textButtonCancel: "",
					};
					event.cancel = true;
					return firstValueFrom(this._dialogService.Confirmation(dialogModel)).then();
				}
				event.cancel = true;
				const toggleTab = {
					eventFunction: ResEventFunction.Open,
					model: { state: PaymentState.ChoosePlan },
				};
				this.toggleTab = toggleTab;
				return toggleTab;
			}
			if (this.welfareObj.welfareEmployeeResponsibilities?.length > 0) {
				this.responsiblePersonList = this.welfareObj.welfareEmployeeResponsibilities;
				this.mapDataResponsiblePersonList();
			}

			this.conditionTypeTemp = this.welfareObj.conditionType;
			this.isIndividualTemp = this.welfareObj.isIndividual;
			this.welfareObj.conditionType = ConditionTypeWelfare.None;
			this.welfareObj.isIndividual = true;
		} else {
			this.welfareObj.conditionType =
				this.conditionTypeTemp > 0 ? this.conditionTypeTemp : ConditionTypeWelfare.PaidSameAmount;
			this.welfareObj.isIndividual = this.isIndividualTemp;
		}

		this.welfareObj.isResposiblePerson = event.value;
	}
	pathIconPro: string = environment.gofiveCoreWeb.assetUrl + "images/empeo/empeo-pro.svg";
	receivePaySuccess() {
		this.conditionWelfare = [
			{
				text: this.translate.instant("wel006_resposible_person"),
				value: true,
				iconPicture: null,
			},
			{ text: this.translate.instant("common_custom"), value: false },
		];
	}

	displayEmployeePaidVariablePerIndividual = false;
	employeePaidVariablePerIndividualList: any;
	employeePaidVariablePerIndividualTempList: any;
	sidebarEmployeePaidVariablePerIndividual(isOpen) {
		if (isNullOrUndefined(this.welfareCompanyId) || this.welfareCompanyId == 0) {
			this.isValidForm = true;
			return this._toastService.Warning({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("common_please_choose_company"),
			});
		} else {
			this.displayEmployeePaidVariablePerIndividual = isOpen;
		}
	}

	get formArr() {
		return this.formEmployeePaidVariablePerIndividual.get("items").value as UntypedFormArray;
	}

	mapDataEmployeePaidVariablePerIndividualList() {
		let items = (<UntypedFormArray>this.formEmployeePaidVariablePerIndividual?.get("items"))?.value;

		this.employeePaidVariablePerIndividualList = [...this.employeePaidVariablePerIndividualTempList];

		items?.map((m) => {
			const data = this.employeePaidVariablePerIndividualList.find((f) => f.employeeId === m.employeeId);
			if (data) {
				m.amount = data.amount;
			}
		});
		this.formEmployeePaidVariablePerIndividual = this.fb.group({
			items: this.fb.array([]),
		});
		this.updateDataControls(this.employeePaidVariablePerIndividualList);
	}

	getValidate(i) {
		return (<UntypedFormArray>this.formEmployeePaidVariablePerIndividual?.get("items"))?.controls[i];
	}

	sumAmountPaidVariablePerIndividual() {
		const items = (<UntypedFormArray>this.formEmployeePaidVariablePerIndividual?.get("items"))?.value;
		return items.reduce((acc, current) => acc + Number(current.amount), 0);
	}

	deleteFormEmployeePaidVariablePerIndividual(i): void {
		this.employeePaidVariablePerIndividualList.splice(i, 1);
		this.employeePaidVariablePerIndividualTempList.splice(i, 1);
		const formItems = <UntypedFormArray>this.formEmployeePaidVariablePerIndividual?.controls?.items;
		formItems.removeAt(i);
	}

	updateDataControls(controls) {
		const controlsForm = <UntypedFormArray>this.formEmployeePaidVariablePerIndividual?.controls?.items;

		for (const c of controls) {
			const form = this.fb.group({
				employeeId: new UntypedFormControl(c.employeeId, Validators.required),
				amount: new UntypedFormControl(c.amount, [Validators.required, Validators.min(1)]),
				fullName: new UntypedFormControl(c.fullName),
				fullName_EN: new UntypedFormControl(c.fullName_EN),
				positionName: new UntypedFormControl(c.positionName),
				positionName_EN: new UntypedFormControl(c.positionName_EN),
				pictureURL: new UntypedFormControl(c.pictureURL),
			});
			controlsForm.push(form);
		}

		this.employeePaidVariablePerIndividualList = controlsForm.value;
		this.employeePaidVariablePerIndividualTempList = [...controlsForm.value];
	}
	intitialFormEmployeePaidVariablePerIndividual() {
		this.employeePaidVariablePerIndividualList = [];
		this.employeePaidVariablePerIndividualTempList = [];
		this.formEmployeePaidVariablePerIndividual = this.fb.group({
			items: this.fb.array([]),
		});
	}
	lockFilter: any = [
		{ index: 1 },
		{ index: 2 },
		{ index: 3 },
		{ index: 6 },
		{ index: 7 },
		{ index: 8 },
		{ index: 9 },
		{ index: 10, value: [1, 2, 4] },
		{ index: 11 },
		{ index: 12 },
		{ index: 13 },
	];
	selectedConditionWelfare(event: any) {
		this.welfareObj.conditionType = event.value;
		if (this.welfareObj.conditionType == ConditionTypeWelfare.PaidVariablePerIndividual) {
			this.intitialFormEmployeePaidVariablePerIndividual();
		}
	}
	get ConditionTypeWelfare() {
		return ConditionTypeWelfare;
	}

	checkIsSetFuelRate(event) {
		this.welfareObj.isSetFuelRate = event.checked;
	}

	removedGroupIds: Set<number> = new Set<number>();

	removeGroup(id: number) {
		let item = this.groups.find((g) => g.id == id);
		if (item) item.employee = [];
		this.removedGroupIds.add(id);
	}

	get activeGroups() {
		return this.groups.filter((g) => !this.removedGroupIds.has(g.id));
	}
}

export enum ConditionTypeWelfare {
	None = 0, // เท่ากันทุกคน
	PaidSameAmount = 1, // เท่ากันทุกคน
	PaidVariablePerIndividual = 2, // แบ่งรายคน
	PaidVariouslyGroup = 3, // แบ่งกลุ่ม
}
