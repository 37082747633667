import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { AssessmentQuestion } from "./assessment-question.model";
import { firstValueFrom } from "rxjs";
@Injectable()
export class AssessmentQuestionService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getAssessmentQuestions(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "AssessmentQuestion/GetAssessmentQuestions",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getAssessmentQuestionById(questionId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentQuestion/GetAssessmentQuestionById/" + questionId),
		).then((res) => <AssessmentQuestion>res);
	}
	editAssessmentQuestion(tc: AssessmentQuestion) {
		var body = JSON.stringify(tc);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentQuestion/EditAssessmentQuestion", body, httpOptions);
	}
	addAssessmentQuestion(assessmentMaster: AssessmentQuestion) {
		var body = JSON.stringify(assessmentMaster);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentQuestion/AddAssessmentQuestion", body, httpOptions);
	}
	deleteAssessmentQuestion(questionId: number) {
		var body = JSON.stringify(questionId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentQuestion/DeleteAssessmentQuestion", body, httpOptions);
	}
}
