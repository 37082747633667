import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { Dropdown } from "../../shared/models/dropdown.model";
import { firstValueFrom } from "rxjs";
import { AsssessmentDashboardSearchModel } from "./assessment.model";
@Injectable()
export class AssessmentSetsService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getAssessmentSetsDataTable(model: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "AssessmentSet/GetAssessmentSetDataTable", model, {
			params: model,
		});
	}

	getDDLAssessmentSet(assessmentTypeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "AssessmentSet/GetDDLAssessmentSet/" + assessmentTypeId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getAllAssessmentScoreNo(assessmentSetId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "AssessmentSet/GetAllAssessmentScoreNo/" + assessmentSetId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getSummaryEmployeeAssessmentScoreBySetId(assessmentSetId: number, no: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + `AssessmentScore/GetSummaryEmployeeAssessmentScoreBySetId/${assessmentSetId}/${no}`),
		).then((res) => <any>res);
	}
	getEmployeeAssessmentDashboard(model: AsssessmentDashboardSearchModel) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "AssessmentScore/GetEmployeeAssessmentDashboard", body, httpOptions);
	}

	getOrganizaionGroupAssessmentDashboard(model: AsssessmentDashboardSearchModel) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(
			this.apiUrl + "AssessmentScore/GetOrganizaionGroupAssessmentDashboard",
			body,
			httpOptions,
		);
	}
}
