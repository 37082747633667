import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Constants } from "../../constant/constants";

@Pipe({
	name: "dateTimeFormat",
	standalone: true,
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		return super.transform(value, Constants.DATE_TIME_FMT);
	}
}
