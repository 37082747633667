import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeAccumulates } from "./employee-accumulates.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeAccumulatesService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getEmployeeAccumulateById(accumulateId: number, employeeId: number, year: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "EmployeeAccumulate/GetEmployeeAccumulateById/" + accumulateId + "/" + employeeId + "/" + year,
			),
		).then((res) => <EmployeeAccumulates>res);
	}

	addEmployeeAccumulate(model: EmployeeAccumulates) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeAccumulate/AddEmployeeAccumulate", body, httpOptions);
	}

	editEmployeeAccumulate(model: EmployeeAccumulates) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeAccumulate/EditEmployeeAccumulate", body, httpOptions);
	}

	getDDLFinancialAccumulateByEmployee(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "employee-accumulates/" + employeeId + "/financials")).then(
			(res) => <any>res,
		);
	}
}
