import { Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "@gofive/design-system-tooltip";

@Component({
	selector: "einfo-tooltip",
	templateUrl: "./info-tooltip.component.html",
	styleUrls: ["./info-tooltip.component.scss"],
	standalone: true,
	imports: [TooltipModule, TranslateModule],
})
export class InfoTooltipComponent {
	@Input() key: string;
	@Input() textTooltip: string;
	@Input() position: string = "top";
	@Input() size: string = "";
	@Input() width: string = "28em";
	@Input() mode: string = "hover";

	public icon: string = "icon-empecon-Tooltip-In-Circle-stroke-Grey";

	get sizei() {
		switch (this.size.toLowerCase()) {
			case "s":
				return "14px";
			case "m":
				return "20px";
			case "l":
				return "22px";
			default:
				return "18px";
		}
	}
}
