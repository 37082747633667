import { RouterModule, Routes } from "@angular/router";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { AuthGuardService } from "./auth/auth-guard.service";
import { RouteURL } from "./shared/enum/route-path.enum";
import { AuthCurrentUrlGuardService } from "./auth/auth-currentUrl-guard";

/**
 * property data
 * @param data (AuthorizeControl) : redirect to page after check user permission and route (data:canBackAfterLogin) ### can't use with lazy module
 * {
 *  module: Module;
 *  pageType: PageType;
 *  canBackAfterLogin: boolean;
 *  emptyLayout: boolean;
 * }
 * **/
export const routes: Routes = [
	// assessment
	{
		path: "m-assessment-form",
		loadComponent: () =>
			import("./assessment/assessment-form/assessment-form.component").then((m) => m.AssessmentFormComponent),
	},
	{
		matcher: (url) => {
			const urlAssessmentService = ["pms", "assessment-form", "assessment-master-add"];
			if (url.length && urlAssessmentService.includes(url[0].path)) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./assessment/assessment.module").then((m) => m.AssessmentModule),
	},
	// custom-sso
	{
		matcher: (url) => {
			if (url.length === 1 && url[0].path.includes("handle-custom-sso")) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./custom-sso/custom-sso.module").then((m) => m.CustomSsoModule),
	},

	// dashboard
	{
		matcher: (url) => {
			if (
				url.length === 0 ||
				url[0]?.path.includes("home") ||
				url[0]?.path.includes("_gl") ||
				url[0]?.path.includes("_ga") ||
				url[0]?.path.includes("onboarding")
			) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
		canActivate: [AuthCurrentUrlGuardService, AuthGuardService],
		data: {
			isBg: true,
			path: RouteURL.home,
		},
	},

	// my-documents
	{
		matcher: (url) => {
			if (url.length > 0 && url[0].path === "mydocument") {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./self-service/my-documents/my-documents.module").then((m) => m.MyDocumentsModule),
	},

	// document-forms
	{
		matcher: (url) => {
			const urlDocumentForms = [
				"approval",
				"document-summary",
				"leave",
				"leave-status-detail",
				"leave-summary",
				"shift-summary",
				"welfare-claim",
			];
			if (
				url.length &&
				(urlDocumentForms.includes(url[0].path) ||
					url[0].path.includes("leave-collect") ||
					url[0].path.includes("leave-exchange"))
			) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./document-forms/document-forms.module").then((m) => m.DocumentFormsModule),
	},

	// learn
	{
		matcher: (url) => {
			const urlEmpeoLearn = ["learn", "training", "m-learn-course-view"];
			if (url.length && (url[0].path.includes("learn") || urlEmpeoLearn.includes(url[0].path))) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./empeo-learn/empeo-learn.module").then((m) => m.EmpeoLearnModule),
	},

	// employee-movements
	{
		matcher: (url) => {
			const urlEmployeeMovementsSelfService = [
				"employee",
				"employee-edit",
				"employee-add",
				"employee-document",
				"employee-movement",
				"employee-payroll",
				"employee-punishment",
				"employee-resign",
				"employee-setting",
				"agent-shift",
				"employee-social-security",
				"employee-tax",
				"stamp",
				"stamp-external",
				"employee-agency",
				"employee-supervisor",
				"employee-transfer-supervisor",
			];
			if (url.length && urlEmployeeMovementsSelfService.includes(url[0].path)) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./employee-movements/employee-movements.module").then((m) => m.EmployeeMovementsModule),
	},

	// expense
	{
		matcher: (url) => {
			if (url.length && (url[0].path === "expense" || url[0].path === "expense-setup")) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./expense/expense.module").then((m) => m.ExpenseModule),
	},

	// manager-self-service
	{
		matcher: (url) => {
			const urlManagerSelfService = ["benefit", "team", "time-management"];
			if (url.length && urlManagerSelfService.includes(url[0].path)) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () =>
			import("./manager-self-service/manager-self-service.module").then((m) => m.ManagerSelfServiceModule),
	},

	// master
	{
		matcher: (url) => {
			const urlMasterService = [
				"account-category",
				"certificate-setting",
				"diligence-allowance",
				"holiday",
				"leave-condition",
				"shift-add",
			];
			if (
				url.length &&
				(urlMasterService.includes(url[0].path) ||
					url[0].path.includes("calendar") ||
					url[0].path.includes("leave-type") ||
					url[0].path.includes("master-setup") ||
					url[0].path.includes("position"))
			) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./master/master.module").then((m) => m.MasterModule),
	},

	// movement
	{
		matcher: (url) => {
			const urlMovementService = ["movement"];
			if (url.length && urlMovementService.includes(url[0].path)) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./movement/movement.module").then((m) => m.MovementModule),
	},

	// okr
	{
		matcher: (url) => {
			const urlOkrService = ["goals"];
			if (url.length && urlOkrService.includes(url[0].path)) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./okr/okr.module").then((m) => m.OkrModule),
	},

	// payroll
	{
		matcher: (url) => {
			const urlPayrollService = [
				"payment",
				"payroll",
				"payment-period-add",
				"document-payroll",
				"accumulate",
				"budget",
				"tax-rate",
				"tax-exemption",
				"printing-payslip",
			];
			if (url.length && urlPayrollService.includes(url[0].path)) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./payroll/payroll.module").then((m) => m.PayrollModule),
		data: {
			canBackAfterLogin: true,
		},
	},

	// permission
	{
		matcher: (url) => {
			const urlPermissionService = ["user-info"];
			if (url.length && (urlPermissionService.includes(url[0].path) || url[0].path.includes("role"))) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./permission/permission.module").then((m) => m.PermissionModule),
		data: {
			canBackAfterLogin: true,
		},
	},

	// recruitment
	{
		matcher: (url) => {
			const urlPermissionService = ["emconnect"];
			if (url.length && urlPermissionService.includes(url[0].path)) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./recruitment/recruitment.module").then((m) => m.RecruitmentModule),
		data: {
			canBackAfterLogin: true,
		},
	},

	// report
	{
		matcher: (url) => {
			const urlReportService = [
				"report",
				"report-OT001",
				"report-C009",
				"report-TRN001",
				"report-TRN002",
				"report-ASM004",
				"report-LRN001",
				"report-SS001",
				"report-WL001",
				"dashboard",
			];
			if (
				url.length &&
				(urlReportService.includes(url[0].path) ||
					url[0].path.includes("report-A0") ||
					url[0].path.includes("report-B0") ||
					url[0].path.includes("report-C0") ||
					url[0].path.includes("report-D0") ||
					url[0].path.includes("report-P0") ||
					url[0].path.includes("report-W0"))
			) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./reports/reports.module").then((m) => m.ReportsModule),
	},

	// time-sheet
	{
		matcher: (url) => {
			const urlTimeSheetService = ["timesheet"];
			if (url.length && urlTimeSheetService.includes(url[0].path)) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./time-sheet/time-sheet.module").then((m) => m.TimeSheetModule),
	},

	// welfare
	{
		matcher: (url) => {
			const urlWelfareService = ["benefits"];
			if (
				url.length &&
				(urlWelfareService.includes(url[0].path) ||
					url[0].path.includes("welfare") ||
					url[0].path.includes("employee-cards") ||
					url[0].path.includes("employee-lockers"))
			) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./welfare/welfare.module").then((m) => m.WelfareModule),
	},

	// setting
	{
		matcher: (url) => {
			const urlSettingService = ["email-template", "ranks", "email-notification", "settings", "manage-device-IOMO"];
			if (
				url.length &&
				(urlSettingService.includes(url[0].path) ||
					url[0].path.includes("component") ||
					url[0].path.includes("user-info-") ||
					url[0].path.includes("workflow"))
			) {
				return {
					consumed: [],
				};
			}
			return null;
		},
		loadChildren: () => import("./setting/setting.module").then((m) => m.SettingModule),
	},
	// service-maintenance
	{
		path: "servicing-maintenance",
		loadComponent: () =>
			import("./service-maintenance/service-info/service-info.component").then((m) => m.ServiceInfoComponent),
	},
	// core module micro
	{
		path: "policy-view",
		loadComponent: () => import("./shared/module/policy-view/policy-view.component").then((m) => m.PolicyViewComponent),
	},
	{
		path: RouteURL.billing,
		loadComponent: () => import("./shared/module/billing/billing.component").then((m) => m.BillingComponent),
		canActivate: [AuthGuardService],
	},
	{
		path: "m-collect-time",
		loadComponent: () =>
			import("./shared/component/collect-time/collect-time.component").then((m) => m.CollectTimeComponent),
	},
	{
		path: "m-personal-detail-update",
		loadComponent: () =>
			import("./../app/shared/component/personal-information/personal-information.component").then(
				(m) => m.PersonalInformationComponent,
			),
	},
	{
		path: "m-punishment",
		loadComponent: () =>
			import("./shared/component/punishment-sidebar/punishment-sidebar.component").then(
				(m) => m.PunishmentSidebarComponent,
			),
	},
	{
		path: "m-punishment-view",
		loadComponent: () =>
			import("./shared/component/punishment-view/punishment-view.component").then((m) => m.PunishmentViewComponent),
	},
	{
		path: "m-punishment-detail",
		loadComponent: () =>
			import("./shared/component/warning-letter-sidebar/warning-letter-sidebar.component").then(
				(m) => m.WarningLetterSidebarComponent,
			),
	},
	{
		path: "m-training-detail",
		loadComponent: () =>
			import("./shared/component/training-detail-sidebar/training-detail-sidebar.component").then(
				(m) => m.TrainingDetailSidebarComponent,
			),
	},
	{
		path: "m-movement",
		loadComponent: () =>
			import("./movement/movement-detail/movement-detail.component").then((m) => m.MovementDetailComponent),
	},
	{
		path: "m-movement-add",
		loadComponent: () =>
			import("./movement/movement-sidebar-add/movement-sidebar-add.component").then(
				(m) => m.MovementSidebarAddComponent,
			),
	},
	{
		path: "m-redeem-time",
		loadComponent: () =>
			import("./shared/component/redeem-time/redeem-time.component").then((m) => m.RedeemTimeComponent),
	},
	{
		path: "m-training-document",
		loadComponent: () =>
			import("./shared/component/training-document/training-document.component").then(
				(m) => m.TrainingDocumentComponent,
			),
	},
	{
		path: "m-emconnect-recruitment",
		loadComponent: () =>
			import("./../app/shared/component/emconnect-recruitment/emconnect-recruitment.component").then(
				(m) => m.EmconnectRecruitmentComponent,
			),
	},
	{
		path: "m-employee-assessment",
		loadComponent: () =>
			import("./../app/shared/component/employee-assessment-result/employee-assessment-result.component").then(
				(m) => m.EmployeeAssessmentResultComponent,
			),
	},
	{
		path: "m-roster-management",
		loadComponent: () =>
			import("./../app/shared/component/roster-management/roster-management.component").then(
				(m) => m.RosterManagementComponent,
			),
	},
	{
		path: "m-leave-status",
		loadComponent: () =>
			import("./../app/shared/component/leave-status/leave-status.component").then((m) => m.LeaveStatusComponent),
	},
	{
		path: "m-leave-status-detail",
		loadComponent: () =>
			import("./../app/shared/component/leave-status-detail/leave-status-detail.component").then(
				(m) => m.LeaveStatusDetailWebviewComponent,
			),
	},
	{
		path: "m-leave-status-detail/:leaveRequestId",
		loadComponent: () =>
			import("./../app/shared/component/leave-status-detail-mobile/leave-status-detail-mobile.component").then(
				(m) => m.LeaveStatusDetailMobileComponent,
			),
	},
	{
		path: "m-document-detail/:documentFormId",
		loadComponent: () =>
			import("./../app/shared/component/document-form-detail-mobile/document-form-detail-mobile.component").then(
				(m) => m.DocumentDetailMobileComponent,
			),
	},
	{
		path: "m-employee-tax-allowance",
		loadComponent: () =>
			import("./../app/shared/component/employee-tax/employee-tax-web-view.component").then(
				(m) => m.EmployeeTaxWebViewComponent,
			),
	},
	{
		path: "m-time-sheet",
		loadComponent: () =>
			import("./../app/shared/component/time-sheet-mobile/time-sheet-mobile.component").then(
				(m) => m.TimeSheetMobileComponent,
			),
	},
	{
		path: "m-peer-assessment",
		loadComponent: () =>
			import("./../app/shared/component/peer-assessment-mobile/peer-assessment-mobile.component").then(
				(m) => m.PeerAssessmentMobileComponent,
			),
	},
	{
		path: "m-assessment-master",
		loadComponent: () =>
			import("./../app/shared/component/annual-assessment-mobile/annual-assessment-mobile.component").then(
				(m) => m.AssessmentMasterMobileComponent,
			),
	},
	{
		path: "m-goals",
		loadComponent: () =>
			import("./../app/shared/component/empeo-goals-mobile/empeo-goals-mobile.component").then(
				(m) => m.EmpeoGoalsMobileComponent,
			),
	},
	{
		path: "m-integration",
		loadComponent: () =>
			import("./../app/shared/component/integration-mobile/integration-mobile.component").then(
				(m) => m.IntegrationMobileComponent,
			),
	},
	{
		path: "m-emconnect-candidate",
		loadComponent: () =>
			import("./../app/shared/component/emconnect-candidate/emconnect-candidate.component").then(
				(m) => m.EmconnectCandidateComponent,
			),
	},

	{
		path: "m-change-shift",
		loadComponent: () =>
			import("./../app/shared/component/change-shift/change-shift.component").then((m) => m.ChangeShiftComponent),
	},

	{
		path: "m-swap-shift",
		loadComponent: () =>
			import("./../app/shared/component/swap-shift/swap-shift.component").then((m) => m.SwapShiftComponent),
	},

	{
		path: "m-endorse-time",
		loadComponent: () =>
			import("./../app/shared/component/endorse-time/endorse-time.component").then((m) => m.EndorseTimeComponent),
	},

	{
		path: "m-offsite-work",
		loadComponent: () =>
			import("./../app/shared/component/offsite-work/offsite-work.component").then((m) => m.OffsiteWorkComponent),
	},

	{
		path: "m-overtime",
		loadComponent: () =>
			import("./../app/shared/component/overtime/overtime.component").then((m) => m.OvertimeComponent),
	},

	{
		path: "m-leave-form",
		loadComponent: () => import("./shared/component/leave-form/leave-form.component").then((m) => m.LeaveFormComponent),
	},

	{
		path: "m-document-request",
		loadComponent: () =>
			import("./../app/shared/component/document-request/document-request.component").then(
				(m) => m.DocumentRequestComponent,
			),
	},
	{
		path: "m-pms/supervisor-feedback",
		loadComponent: () =>
			import("./../app/shared/component/pms-calibration-mobile/pms-calibration-mobile.component").then(
				(m) => m.PMSCalibrationMobileComponent,
			),
	},
	{
		path: "m-pms/calibration",
		loadComponent: () =>
			import("./../app/shared/component/pms-calibration-mobile/pms-calibration-mobile.component").then(
				(m) => m.PMSCalibrationMobileComponent,
			),
	},
	{
		path: "m-assets",
		loadComponent: () =>
			import("./../app/setting/asset-management/asset-view/asset-view.component").then((m) => m.AssetViewComponent),
	},
	{
		path: "m-assets-details",
		loadComponent: () =>
			import("./../app/setting/asset-management/asset-view/asset-view.component").then((m) => m.AssetViewComponent),
	},
	{
		path: "reset-2fa/:id",
		loadComponent: () =>
			import(
				"./../app/shared/component/reset-two-factor-authentication/reset-two-factor-authentication.component"
			).then((m) => m.ResetTwoFactorAuthenticationComponent),
	},

	{
		path: "m-salary-adjustment-history",
		loadComponent: () =>
			import("./shared/component/salary-adjustment-history/salary-adjustment-history.component").then(
				(c) => c.SalaryAdjustmentHistoryComponent,
			),
	},

	{ path: "", pathMatch: "full", redirectTo: RouteURL.home },
	{
		path: "confirmation-email",
		loadComponent: () =>
			import("./../app/shared/confirmation-email/confirmation-email.component").then(
				(m) => m.ConfirmationEmailComponent,
			),
	},
	{
		path: "help-empeo",
		loadComponent: () => import("./shared/help-empeo/help-empeo.component").then((c) => c.HelpEmpeoComponent),
		canActivate: [AuthGuardService],
	},
	{ path: RouteURL.accessDenied, component: AccessDeniedComponent },
	{
		path: RouteURL.pageNotFound,
		loadComponent: () => import("./page-not-found/page-not-found.component").then((c) => c.PageNotFoundComponent),
	},
	{
		path: "subscription-expired",
		loadComponent: () =>
			import("./shared/component/subscription-expired/subscription-expired.component").then(
				(c) => c.SubscriptionExpiredComponent,
			),
	},
	{
		path: RouteURL.support,
		loadComponent: () => import("./shared/component/remote/remote.component").then((c) => c.RemoteComponent),
		canActivate: [AuthGuardService],
	},
	{ path: "**", redirectTo: RouteURL.pageNotFound },
];

export const AppRoutes = RouterModule.forRoot(routes);
