<div [hidden]="isSidebarMovement" class="p-0">
	@if (!isLoadingDisplay) {
		<div class="movement-detail-icon">
			<div>
				<div
					class="d-flex align-center content-space-between border-bottom-gray go5-text-color-10 {{
						isMobile ? 'px-3' : ''
					}}"
					[style.padding]="'20px 24px'"
				>
					<div class="d-flex gap-8" style="justify-content: space-between; width: 100%">
						@if (isMobile) {
							<p class="text-header-4 text-color-12 ellipsis" style="margin-top: -4px">
								{{
									dataSharing.getIsTH(dataSharing.currentLanguage | async)
										? employeeMovement.processActionName
										: employeeMovement.processActionName_EN
								}}{{ employeeMovement.movementCode == null ? "" : " (" + employeeMovement.movementCode + ")" }}
							</p>
						} @else {
							<p
								class="text-header-4 text-color-12 ellipsis"
								go5-tooltip-placement="top"
								go5-tooltip-mode="hover"
								go5-tooltip="{{
									(dataSharing.getIsTH(dataSharing.currentLanguage | async)
										? employeeMovement.processActionName
										: employeeMovement.processActionName_EN) +
										'(' +
										employeeMovement.movementCode +
										')'
								}} "
								style="margin-top: -4px"
							>
								{{
									dataSharing.getIsTH(dataSharing.currentLanguage | async)
										? employeeMovement.processActionName
										: employeeMovement.processActionName_EN
								}}{{ employeeMovement.movementCode == null ? "" : " (" + employeeMovement.movementCode + ")" }}
							</p>
						}
						<div class="d-flex gap-8">
							@if (
								(((isCreated || isMe || permissionDocumentPromotion) &&
									employeeMovement.movementTypeId == enumProcessAction.promote) ||
									((isCreated || isMe || permissionDocumentPayroll) &&
										tabPermissionPayroll &&
										employeeMovement.movementTypeId == enumProcessAction.raiseTheSalary &&
										employeeMovement.isIncomeAdjust)) &&
								(employeeMovement.documentStatusId == DocumentStatus.approved ||
									employeeMovement.documentStatusId == DocumentStatus.completed)
							) {
								<go5-dropdown-selection
									data-testid="input_dropdown_download_document"
									[allowFiltering]="false"
									[allowShowRemove]="false"
									[dataSource]="optionDownloadCertificate"
									[mode]="DropdownSelectMode.Button"
									(selected)="downloadDocument($event)"
								>
									<ng-template #toggleTemplate>
										@if (isMobile) {
											<em class="gf-icon-download-simple mr-2"></em>
										} @else {
											<em
												class="gf-icon-download-simple mr-2"
												go5-tooltip-placement="top"
												go5-tooltip-mode="hover"
												go5-tooltip="{{ 'common_download' | translate }}"
											></em>
										}
									</ng-template>
								</go5-dropdown-selection>
							}
							@if (!isMobile) {
								<i class="gf-icon-close d-flex" aria-hidden="true" (click)="closeSidebar()"></i>
							}
						</div>
					</div>
				</div>
				@if (employeeMovement?.allowSeeOwnMovement) {
					<div class="ui-g-12 ui-g-nopad pb-0" style="padding: 20px 24px">
						<div class="contacts">
							@if (isGroupMovement) {
								<div class="d-flex" style="justify-content: space-between">
									<div class="d-flex">
										@for (emp of tmpEmployeeRequests; track emp; let i = $index) {
											<go5-dropdown-selection
												[mode]="'single'"
												popupWidth_px="300"
												[allowFiltering]="false"
												[dataSource]="[emp]"
												[fields]="
													dataSharing.getIsTH(dataSharing.currentLanguage | async)
														? moreEmpFieldDDL
														: moreEmpFieldDDL_EN
												"
											>
												<ng-template #toggleTemplate>
													<img
														[ngClass]="{ 'employee-image-intersec': i > 0 }"
														[ngStyle]="{ left: -(18 * i) + 'px' }"
														alt="employeesCourse"
														src="{{ emp?.pictureThumbnailURL }}"
														class="employee-image employee-list"
														go5-tooltip-placement="top"
														go5-tooltip-mode="hover"
														go5-tooltip="{{
															dataSharing.getIsTH(dataSharing.currentLanguage | async)
																? emp?.nameSurname
																: emp?.nameSurname_EN
														}}"
													/>
												</ng-template>
											</go5-dropdown-selection>
										}
										@if (employeeMovement?.moreEmployees?.length > 0) {
											<go5-dropdown-selection
												style="position: relative; left: -80px"
												[mode]="'single'"
												popupWidth_px="300"
												[allowFiltering]="false"
												[dataSource]="employeeMovement?.moreEmployees"
												[fields]="
													dataSharing.getIsTH(dataSharing.currentLanguage | async)
														? moreEmpFieldDDL
														: moreEmpFieldDDL_EN
												"
											>
												<ng-template #toggleTemplate>
													<div class="employee-more-intersec">
														<span class="employee-list">+{{ employeeMovement?.moreEmployees?.length }}</span>
													</div>
												</ng-template>
											</go5-dropdown-selection>
										}
									</div>
									<div>
										<span class="d-flex text-small text-color-9" style="justify-content: right; align-items: center">
											{{
												dataSharing.getIsTH(dataSharing.currentLanguage | async)
													? employeeMovement.dateCreated_TH
													: employeeMovement.dateCreated_EN
											}}
										</span>
										<span class="d-flex" style="justify-content: right; margin-top: 4px; align-items: center">
											@if (haveProcessActionCancel) {
												<span
													data-testid="click_cancel"
													class="go5-button-cancel text-small-strong"
													style="text-decoration: underline; margin-right: 8px; cursor: pointer"
													(click)="showIsCancel()"
													>{{ "lev001_cancel_document_short" | translate }}
												</span>
											}
											<go5-status
												size="medium"
												type="myDocumentMovement"
												[label]="sharingService.getLabelDocumentStatus(employeeMovement.documentStatusId) | translate"
												[statusId]="employeeMovement.documentStatusId"
											></go5-status>
										</span>
									</div>
								</div>
							} @else {
								<div class="contact-info-leave ui-g-12 ui-g-nopad ellipsis" style="text-align: left">
									<div>
										<span>
											@if (isloadImage) {
												<ngx-skeleton-loader
													[theme]="{
														width: '45px',
														height: '45px',
														margin: '0px 8px 0px 0px',
														float: 'left',
														animation: 'infinite progress'
													}"
													animation="progress-dark"
													appearance="circle"
												>
												</ngx-skeleton-loader>
											}
											<img
												[hidden]="isloadImage"
												class="small-image pointer"
												(load)="loadImg($event)"
												(error)="loadImg($event)"
												library="verona-layout"
												src="{{ employeeMovement.pictureThumbnailURL }}"
												alt="profileImage"
												(click)="viewEmployee(employeeMovement)"
											/>
										</span>
										<span>
											@if (isMobile) {
												<span class="text-title-strong text-color-12 ellipsis"
													>{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? employeeMovement.requestorFullName
															: employeeMovement.requestorFullName_EN
													}}
												</span>
											} @else {
												<span
													go5-tooltip-placement="top"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? employeeMovement.requestorFullName
															: employeeMovement.requestorFullName_EN
													}}"
													class="text-title-strong text-color-12 ellipsis"
													>{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? employeeMovement.requestorFullName
															: employeeMovement.requestorFullName_EN
													}}
												</span>
											}
											<span class="text-small text-color-9" style="float: right">
												{{
													dataSharing.getIsTH(dataSharing.currentLanguage | async)
														? employeeMovement.dateCreated_TH
														: employeeMovement.dateCreated_EN
												}}
											</span> </span
										><br />
										<div class="ellipsis">
											@if (isMobile) {
												<span class="text-small text-color-8"
													>{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? employeeMovement.requestorPositionName
															: employeeMovement.requestorPositionName_EN
													}}
												</span>
											} @else {
												<span
													go5-tooltip-placement="top"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? employeeMovement.requestorPositionName
															: employeeMovement.requestorPositionName_EN
													}}"
													class="text-small text-color-8"
													>{{
														dataSharing.getIsTH(dataSharing.currentLanguage | async)
															? employeeMovement.requestorPositionName
															: employeeMovement.requestorPositionName_EN
													}}
												</span>
											}
											<span class="d-flex" style="float: right; margin-top: 4px; align-items: center">
												@if (haveProcessActionCancel) {
													<span
														data-testid="click_cancel"
														class="go5-button-cancel text-small-strong"
														style="text-decoration: underline; margin-right: 8px; cursor: pointer"
														(click)="showIsCancel()"
														>{{ "lev001_cancel_document_short" | translate }}
													</span>
												}
												<go5-status
													size="medium"
													type="myDocumentMovement"
													[label]="sharingService.getLabelDocumentStatus(employeeMovement.documentStatusId) | translate"
													[statusId]="employeeMovement.documentStatusId"
												></go5-status>
											</span>
										</div>
									</div>
								</div>
							}

							<div class="contact-info-leave ui-g-12 ui-g-nopad ellipsis" style="margin-top: -8px">
								<div class="ui-g-12 ui-g-nopad pt-3">
									<div
										class="go5-text-body-strong d-flex ellipsis go5-text-color-10"
										style="justify-content: space-between; align-items: center; white-space: initial"
									>
										<div>
											{{
												employeeMovement.isSelfResign
													? ("resignation_request" | translate)
													: dataSharing.getIsTH(dataSharing.currentLanguage | async)
														? employeeMovement.processActionName
														: employeeMovement.processActionName_EN
											}}
											@if (employeeMovement.terminationReasonId) {
												{{
													employeeMovement.movementTypeId == enumProcessAction.resign
														? " - " +
															(dataSharing.getIsTH(dataSharing.currentLanguage | async)
																? employeeMovement.terminationReasonName
																: employeeMovement.terminationReasonName_EN)
														: ""
												}}
											}
										</div>
										@if (isMobile) {
											@if (employeeMovement.isIncomeAdjust || employeeMovement.severanceAmount > 0) {
												<em
													data-testid="button_button_displaymask"
													[class]="displayMask ? 'gf-icon-eye' : 'gf-icon-close-eye'"
													class="pointer f-24 color-primary"
													(click)="showSalary()"
												>
												</em>
											}
										} @else {
											@if (employeeMovement.isIncomeAdjust || employeeMovement.severanceAmount > 0) {
												<em
													data-testid="button_button_displaymask"
													[class]="displayMask ? 'gf-icon-eye' : 'gf-icon-close-eye'"
													class="pointer f-24 color-primary"
													(click)="showSalary()"
													go5-tooltip-placement="left"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														(displayMask ? 'pay001_show_salary_tooltip' : 'pay001_close_salary_tooltip') | translate
													}}"
												>
												</em>
											}
										}
									</div>
									<div class="ui-g-12 ui-g-nopad ellipsis go5-text-color-10 pt-1">
										<label style="white-space: initial">{{ employeeMovement.remarks }}</label>
									</div>
								</div>
								<div class="ui-g-12 ui-g-nopad">
									@if (displayWorkingSummary && !isGroupMovement) {
										<div class="ui-g-12 ui-g-nopad border-bottom-gray">
											<div class="ui-g-12 ui-g-nopad pt-2" style="display: flex; justify-content: space-between">
												<div class="ui-g-nopad pt-2">
													<p class="text-header-5 go5-text-color-10 ellipsis">
														{{ "common_working_summary" | translate }}
													</p>
												</div>
												<div class="ui-g-nopad" style="width: 85px">
													<s-dropdown
														data-testid="dropdown_single_yearid"
														#yearDDL
														[dataSource]="refYearDDL"
														[fields]="
															dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN
														"
														[allowFiltering]="false"
														[placeholder]="''"
														[cssClass]="'dropdown-large-primary'"
														[mode]="'single'"
														[showClearButton]="false"
														(selected)="loadSummary()"
														[(ngModel)]="yearId"
														[showTooltip]="false"
													>
													</s-dropdown>
												</div>
											</div>
											<div class="ui-g-12 ui-g-nopad">
												<div class="ui-g-12 ui-g-nopad pb-2">
													@if (isAttendance && (isMe || userInfo?.isHR || isSupervisor || workInRole)) {
														<div class="ui-g-12 ui-g-nopad py-2">
															<div class="go5-text-body-strong color-iron pb-2" style="display: flex">
																<label>{{ "common_attendance_summary" | translate }}</label>
																@if (isMobile) {
																	<em
																		data-testid="open_emp_view_1"
																		class="gf-icon-show-all pointer color-pewter f-16 px-1"
																		style="padding-top: 1px"
																		(click)="routerWorkinTab(employeeMovement)"
																	>
																	</em>
																} @else {
																	<em
																		data-testid="open_emp_view_1"
																		class="gf-icon-show-all pointer color-pewter f-16 px-1"
																		style="padding-top: 1px"
																		(click)="routerWorkinTab(employeeMovement)"
																		go5-tooltip-placement="top"
																		go5-tooltip-mode="hover"
																		go5-tooltip="{{ 'common_attendance_summary' | translate }}"
																	>
																	</em>
																}
															</div>
															@if (isLoadUserTimeAttendance) {
																<div class="ui-g-12 ui-g-nopad">
																	@for (item of counter(2); track item) {
																		<div class="ui-g-12 ui-g-nopad">
																			<ngx-skeleton-loader
																				[theme]="{ margin: '0px', animation: 'infinite progress' }"
																				animation="progress-dark"
																			>
																			</ngx-skeleton-loader>
																		</div>
																	}
																</div>
															}
															@if (!isLoadUserTimeAttendance) {
																<div class="ui-g-12 ui-g-nopad">
																	@if (hasAttendanceSummary) {
																		<div class="ui-g-12 ui-g-nopad">
																			@if (userTimeAttendance.late > 0) {
																				<div
																					class="go5-text-body go5-text-color-10 py-1"
																					style="display: flex; justify-content: space-between"
																				>
																					<label>{{ "late" | translate }}</label>
																					<label>
																						{{
																							dataSharing.getIsTH(dataSharing.currentLanguage | async)
																								? userTimeAttendance.late_TH
																								: userTimeAttendance.late_EN
																						}}</label
																					>
																				</div>
																			}
																			@if (userTimeAttendance.totalOfAbsent > 0) {
																				<div
																					class="go5-text-body go5-text-color-10 py-1"
																					style="display: flex; justify-content: space-between"
																				>
																					<label>{{ "das001_absence" | translate }}</label>
																					<label>
																						{{
																							dataSharing.getIsTH(dataSharing.currentLanguage | async)
																								? userTimeAttendance.absent_TH
																								: userTimeAttendance.absent_EN
																						}}</label
																					>
																				</div>
																			}
																			@if (userTimeAttendance.early > 0) {
																				<div
																					class="go5-text-body go5-text-color-10 py-1"
																					style="display: flex; justify-content: space-between"
																				>
																					<label>{{ "das001_early" | translate }}</label>
																					<label>
																						{{
																							dataSharing.getIsTH(dataSharing.currentLanguage | async)
																								? userTimeAttendance.early_TH
																								: userTimeAttendance.early_EN
																						}}</label
																					>
																				</div>
																			}
																			@if (
																				userTimeAttendance.totalOfNoStampIn + userTimeAttendance.totalOfNoStampOut > 0
																			) {
																				<div
																					class="go5-text-body go5-text-color-10 py-1"
																					style="display: flex; justify-content: space-between"
																				>
																					<label>{{ "no_stamp" | translate }}</label>
																					<label
																						>{{
																							userTimeAttendance.totalOfNoStampIn + userTimeAttendance.totalOfNoStampOut
																						}}
																						{{
																							userTimeAttendance.totalOfNoStampIn +
																								userTimeAttendance.totalOfNoStampOut >
																							1
																								? ("common_times" | translate)
																								: ("common_time" | translate)
																						}}</label
																					>
																				</div>
																			}
																		</div>
																	}
																	@if (!hasAttendanceSummary) {
																		<div class="ui-g-12 ui-g-nopad">
																			<div class="text-small text-color-7 py-1">
																				<label>{{ "common_no_unusual_status" | translate }}</label>
																			</div>
																		</div>
																	}
																</div>
															}
														</div>
													}
													@if (isMe || userInfo?.isHR || isSupervisor || benefitRole) {
														<div class="ui-g-12 ui-g-nopad py-2" [ngClass]="isAttendance ? 'border-top-gray' : ''">
															<div
																class="go5-text-body-strong color-iron pb-2"
																[ngClass]="isAttendance ? 'pt-1' : ''"
																style="display: flex"
															>
																<label>{{ "common_leave_summary" | translate }}</label>
																@if (isMobile) {
																	<em
																		data-testid="open_emp_view_2"
																		class="gf-icon-show-all pointer color-pewter f-16 px-1"
																		style="padding-top: 1px"
																		(click)="showLeaveBenefitModal(employeeMovement)"
																	>
																	</em>
																} @else {
																	<em
																		data-testid="open_emp_view_2"
																		class="gf-icon-show-all pointer color-pewter f-16 px-1"
																		style="padding-top: 1px"
																		(click)="showLeaveBenefitModal(employeeMovement)"
																		go5-tooltip-placement="top"
																		go5-tooltip-mode="hover"
																		go5-tooltip="{{ 'common_leave_summary' | translate }}"
																	>
																	</em>
																}
															</div>
															@if (isLoadLeaveUsages) {
																<div class="ui-g-12 ui-g-nopad">
																	@for (item of counter(2); track item) {
																		<div class="ui-g-12 ui-g-nopad">
																			<ngx-skeleton-loader
																				[theme]="{ margin: '0px', animation: 'infinite progress' }"
																				animation="progress-dark"
																			>
																			</ngx-skeleton-loader>
																		</div>
																	}
																</div>
															}
															@if (!isLoadLeaveUsages) {
																<div class="ui-g-12 ui-g-nopad">
																	@if (hasLeaveSummary) {
																		<div class="ui-g-12 ui-g-nopad">
																			@for (item of leaveUsages; track item; let i = $index) {
																				<div
																					class="go5-text-body go5-text-color-10 py-1"
																					style="display: flex; justify-content: space-between"
																				>
																					@if (isMobile) {
																						<label class="ellipsis ui-g-8 ui-g-nopad">{{
																							dataSharing.getIsTH(dataSharing.currentLanguage | async)
																								? item.leaveTypeName
																								: item.leaveTypeName_EN
																						}}</label>
																						<label class="ellipsis">{{
																							dataSharing.getIsTH(dataSharing.currentLanguage | async)
																								? item.benefitUsedWithoutPrefix
																								: item.benefitUsedWithoutPrefix_EN
																						}}</label>
																					} @else {
																						<label
																							go5-tooltip-placement="top"
																							go5-tooltip-mode="hover"
																							go5-tooltip="{{
																								dataSharing.getIsTH(dataSharing.currentLanguage | async)
																									? item.leaveTypeName
																									: item.leaveTypeName_EN
																							}}"
																							class="ellipsis ui-g-8 ui-g-nopad"
																							>{{
																								dataSharing.getIsTH(dataSharing.currentLanguage | async)
																									? item.leaveTypeName
																									: item.leaveTypeName_EN
																							}}</label
																						>
																						<label
																							go5-tooltip-placement="top"
																							go5-tooltip-mode="hover"
																							go5-tooltip="{{
																								dataSharing.getIsTH(dataSharing.currentLanguage | async)
																									? item.benefitUsedWithoutPrefix
																									: item.benefitUsedWithoutPrefix_EN
																							}}"
																							class="ellipsis"
																							>{{
																								dataSharing.getIsTH(dataSharing.currentLanguage | async)
																									? item.benefitUsedWithoutPrefix
																									: item.benefitUsedWithoutPrefix_EN
																							}}</label
																						>
																					}
																				</div>
																			}
																		</div>
																	}
																	@if (!hasLeaveSummary) {
																		<div class="ui-g-12 ui-g-nopad">
																			<div class="text-small text-color-7 py-1">
																				<label>{{ "lev001_no_leave" | translate }}</label>
																			</div>
																		</div>
																	}
																</div>
															}
														</div>
													}
													@if (hasAssessment && (isMe || isSupervisor || assessmentRole)) {
														<div class="ui-g-12 ui-g-nopad border-top-gray py-2">
															<div class="go5-text-body-strong color-iron pb-2 pt-1" style="display: flex">
																<label>{{ "common_assessment_summary" | translate }}</label>
																@if (isMobile) {
																	<em
																		data-testid="open_emp_view_3"
																		class="gf-icon-show-all pointer color-pewter f-16 px-1"
																		style="padding-top: 1px"
																		(click)="displayAssessmentSummaryModal = true"
																	>
																	</em>
																} @else {
																	<em
																		data-testid="open_emp_view_3"
																		class="gf-icon-show-all pointer color-pewter f-16 px-1"
																		style="padding-top: 1px"
																		(click)="displayAssessmentSummaryModal = true"
																		go5-tooltip-placement="top"
																		go5-tooltip-mode="hover"
																		go5-tooltip="{{ 'common_assessment_summary' | translate }}"
																	>
																	</em>
																}
															</div>
															@if (isLoadAssessments) {
																<div class="ui-g-12 ui-g-nopad">
																	@for (item of counter(2); track item) {
																		<div class="ui-g-12 ui-g-nopad">
																			<ngx-skeleton-loader
																				[theme]="{ margin: '0px', animation: 'infinite progress' }"
																				animation="progress-dark"
																			>
																			</ngx-skeleton-loader>
																		</div>
																	}
																</div>
															}
															@if (!isLoadAssessments) {
																<div class="ui-g-12 ui-g-nopad">
																	@if (hasAssessmentSummary) {
																		<div class="ui-g-12 ui-g-nopad">
																			@for (item of assessments; track item; let i = $index) {
																				<div
																					class="go5-text-body go5-text-color-10 py-1"
																					style="display: flex; justify-content: space-between"
																				>
																					@if (!isMobile) {
																						<label
																							go5-tooltip-placement="top"
																							go5-tooltip-mode="hover"
																							go5-tooltip="{{
																								dataSharing.getIsTH(dataSharing.currentLanguage | async)
																									? item.assessmentTitle
																									: item.assessmentTitle_EN
																							}}"
																							class="ellipsis ui-g-nopad"
																							[style.max-width.%]="66"
																							>{{
																								dataSharing.getIsTH(dataSharing.currentLanguage | async)
																									? item.assessmentTitle
																									: item.assessmentTitle_EN
																							}}</label
																						>
																						<label
																							go5-tooltip-placement="top"
																							go5-tooltip-mode="hover"
																							go5-tooltip="{{ item.resultSummary }}"
																							class="ellipsis pointer assessment-result"
																							(click)="onClickAssessmentType(item)"
																							>{{ item.resultSummary }}</label
																						>
																					} @else {
																						<label class="ellipsis ui-g-nopad" [style.max-width.%]="66">{{
																							dataSharing.getIsTH(dataSharing.currentLanguage | async)
																								? item.assessmentTitle
																								: item.assessmentTitle_EN
																						}}</label>
																						<label
																							class="ellipsis pointer assessment-result"
																							(click)="onClickAssessmentType(item)"
																							>{{ item.resultSummary }}</label
																						>
																					}
																				</div>
																			}
																		</div>
																	}
																	@if (!hasAssessmentSummary) {
																		<div class="ui-g-12 ui-g-nopad">
																			<div class="text-small text-color-7 py-1">
																				<label>{{ "asm001_no_assessment_result" | translate }}</label>
																			</div>
																		</div>
																	}
																</div>
															}
														</div>
													}
												</div>
											</div>
										</div>
									}
									@if (employeeMovement.movementTypeId === enumProcessAction.resign) {
										<div class="ui-g-12 ui-g-nopad">
											<!-- asset item -->

											@if (this.assetList.length > 0) {
												<div class="d-flex g-4 align-center pt-4">
													<p class="text-title-strong go5-text-color-11">
														{{ "assets_in_possession" | translate }}
													</p>
													<go5-badge [label]="this.assetList.length" size="medium" category="primary"></go5-badge>
												</div>
												<div class="pt-3">
													<app-assets-management-table
														[columns]="columns"
														[(dataSource)]="assetList"
														[module]="'SidebarView'"
														(sortingChange)="onSortTable($event)"
														(openViewSidebar)="onClickView($event)"
													>
													</app-assets-management-table>
												</div>
											}
										</div>
									}
									<div class="ui-g-12 ui-g-nopad pt-4">
										<p class="text-header-5 go5-text-color-10 ellipsis" go5-tooltip-placement="top">
											{{ "common_movement_summary" | translate }}
										</p>
										<div class="ui-g-12 ui-g-nopad">
											@if (employeeMovement.movementTypeId != enumProcessAction.resign || !isGroupMovement) {
												<div class="ui-g-12 ui-g-nopad p-2" style="margin-top: 8px; border: 1px solid #bcbcc8; border-radius: 4px">
													<div class="go5-text-body-strong color-iron pb-2">
														{{ "emp002_information_original" | translate }}
													</div>
													<div>
														@if (
															employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
															employeeMovement.movementTypeId == enumProcessAction.permanent
														) {
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1">
																	{{ "emp001_employee_status" | translate }}
																</div>
																<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																	{{
																		dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.prevEmployeeStatusName
																			: employeeMovement.prevEmployeeStatusName_EN
																	}}
																</div>
															</div>
														}
														@if (
															employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
															employeeMovement.movementTypeId == enumProcessAction.permanent ||
															employeeMovement.movementTypeId == enumProcessAction.renewal
														) {
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1">
																	{{ "emp001_t14_payment_method" | translate }}
																</div>
																<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																	{{
																		dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.prevPaymentMethodName != null
																				? employeeMovement.prevPaymentMethodName
																				: "-"
																			: employeeMovement.prevPaymentMethodName_EN != null
																				? employeeMovement.prevPaymentMethodName_EN
																				: "-"
																	}}
																</div>
															</div>
														}
														@if (
															employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
															employeeMovement.movementTypeId == enumProcessAction.permanent ||
															employeeMovement.movementTypeId == enumProcessAction.renewal
														) {
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1">
																	{{ "emp001_employment_type" | translate }}
																</div>
																<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																	{{
																		dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.prevEmploymentTypeName != null
																				? employeeMovement.prevEmploymentTypeName
																				: "-"
																			: employeeMovement.prevEmploymentTypeName_EN != null
																				? employeeMovement.prevEmploymentTypeName_EN
																				: "-"
																	}}
																</div>
															</div>
														}
														@if (
															employeeMovement.movementTypeId == enumProcessAction.resign ||
															employeeMovement.movementTypeId == enumProcessAction.unresign
														) {
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1">
																	{{ "common_status" | translate }}
																</div>
																<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																	{{
																		dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.prevEmployeeStatusName
																			: employeeMovement.prevEmployeeStatusName_EN
																	}}
																</div>
															</div>
														}
														@if (
															employeeMovement.movementTypeId == enumProcessAction.crossCompanyTransfer ||
															employeeMovement.movementTypeId == enumProcessAction.agencyTransfer ||
															employeeMovement.movementTypeId == enumProcessAction.massTransfer ||
															employeeMovement.movementTypeId == enumProcessAction.promote ||
															employeeMovement.movementTypeId == enumProcessAction.renewal
														) {
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																	{{ "common_company" | translate }}
																</div>
																<div
																	class="go5-text-body-strong go5-text-color-10"
																	style="flex: 3; white-space: pre-line"
																>
																	{{
																		dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.prevOrgCompanyStructure != null
																				? employeeMovement.prevOrgCompanyStructure
																				: "-"
																			: employeeMovement.prevOrgCompanyStructure_EN != null
																				? employeeMovement.prevOrgCompanyStructure_EN
																				: "-"
																	}}
																</div>
															</div>
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																	{{ "emp001_department" | translate }}
																</div>
																<div
																	class="go5-text-body-strong go5-text-color-10"
																	style="flex: 3; white-space: pre-line"
																>
																	{{
																		dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.prevOrgDepartmentStructure != null
																				? employeeMovement.prevOrgDepartmentStructure
																				: "-"
																			: employeeMovement.prevOrgDepartmentStructure_EN != null
																				? employeeMovement.prevOrgDepartmentStructure_EN
																				: "-"
																	}}
																</div>
															</div>
														}
														@if (
															employeeMovement.movementTypeId == enumProcessAction.promote ||
															employeeMovement.movementTypeId == enumProcessAction.raiseTheSalary ||
															employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
															employeeMovement.movementTypeId == enumProcessAction.agencyTransfer ||
															employeeMovement.movementTypeId == enumProcessAction.permanent ||
															employeeMovement.movementTypeId == enumProcessAction.renewal
														) {
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																	{{ "emp001_position" | translate }}
																</div>
																<div
																	class="go5-text-body-strong go5-text-color-10"
																	style="flex: 3; white-space: pre-line"
																>
																	{{
																		(dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.prevPositionName != null
																				? employeeMovement.prevPositionName
																				: "-"
																			: employeeMovement.prevPositionName_EN != null
																				? employeeMovement.prevPositionName_EN
																				: "-") +
																			(dataSharing.getIsTH(dataSharing.currentLanguage | async)
																				? employeeMovement.prevRankName != null
																					? " (" + employeeMovement.prevRankName + ")"
																					: "(-)"
																				: employeeMovement.prevRankName_EN != null
																					? " (" + employeeMovement.prevRankName_EN + ")"
																					: "(-)")
																	}}
																</div>
															</div>
														}
														@if (
															(employeeMovement.movementTypeId == enumProcessAction.crossCompanyTransfer ||
																employeeMovement.movementTypeId == enumProcessAction.raiseTheSalary ||
																employeeMovement.movementTypeId == enumProcessAction.agencyTransfer ||
																employeeMovement.movementTypeId == enumProcessAction.promote ||
																employeeMovement.movementTypeId == enumProcessAction.permanent ||
																employeeMovement.movementTypeId == enumProcessAction.changeOfSupervisor ||
																employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
																employeeMovement.movementTypeId == enumProcessAction.renewal ||
																employeeMovement.movementTypeId == enumProcessAction.massTransfer) &&
															employeeMovement.prevUpperSupervisorId != employeeMovement.upperSupervisorId
														) {
															<div class="flex-row py-1 gap-6">
																@if (isMovementTab) {
																	<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																		{{ "emp001_upper_chain_of_command" | translate }}
																	</div>
																	<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																		{{
																			dataSharing.getIsTH(dataSharing.currentLanguage | async)
																				? employeeMovement.prevUpperSupervisorFullName !== " "
																					? employeeMovement.prevUpperSupervisorFullName
																					: "-"
																				: employeeMovement.prevUpperSupervisorFullName_EN !== " "
																					? employeeMovement.prevUpperSupervisorFullName_EN
																					: "-"
																		}}
																	</div>
																}
															</div>
														}
														@if (employeeMovement.isIncomeAdjust) {
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																	{{ "emp001_salary" | translate }}
																</div>
																<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																	<span>
																		{{
																			baseSalary()?.prevIncomeAmount != null
																				? "&#3647;" +
																					(displayMask
																						? maskAmount
																						: (baseSalary()?.prevIncomeAmount | number: "0.0-2"))
																				: "-"
																		}}
																	</span>
																</div>
															</div>
														}
														<div style="grid-template-columns: 1fr 3fr; display: grid; gap: 4px">
															@for (item of salaryAdjustPrevList(); track $index) {
																@if (!isMobile) {
																	<div
																		class="text-small go5-text-color-10 ellipsis"
																		style="flex: 1; max-width: 80px"
																		go5-tooltip-placement="top"
																		go5-tooltip-mode="hover"
																		go5-tooltip="{{
																			dataSharing.getIsTH(dataSharing.currentLanguage | async)
																				? item.name
																				: item.name_EN
																		}}"
																	>
																		{{
																			dataSharing.getIsTH(dataSharing.currentLanguage | async)
																				? item.name
																				: item.name_EN
																		}}
																	</div>
																} @else {
																	<div class="text-small go5-text-color-10 ellipsis" style="flex: 1; max-width: 80px">
																		{{
																			dataSharing.getIsTH(dataSharing.currentLanguage | async)
																				? item.name
																				: item.name_EN
																		}}
																	</div>
																}
																<div class="go5-text-body-strong go5-text-color-10 d-flex gap-4" style="flex: 3">
																	@if (displayMask) {
																		&#3647;{{ maskAmount }}
																	} @else {
																		&#3647;{{ item.prevIncomeAmount | number: "0.0-2" }}
																	}
																</div>
															}
														</div>
													</div>
												</div>
											}
											<div
												class="ui-g-12 ui-g-nopad p-2"
												style="margin-top: 12px; border: 1px solid #bcbcc8; border-radius: 4px"
											>
												<div class="go5-text-body-strong color-iron pb-2">{{ "emp002_adjust_to" | translate }}</div>
												<div>
													@if (
														employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
														employeeMovement.movementTypeId == enumProcessAction.permanent
													) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																{{ "emp001_employee_status" | translate }}
															</div>
															<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																{{
																	dataSharing.getIsTH(dataSharing.currentLanguage | async)
																		? employeeMovement.employeeStatusName
																		: employeeMovement.employeeStatusName_EN
																}}
															</div>
														</div>
													}
													@if (
														employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
														employeeMovement.movementTypeId == enumProcessAction.permanent ||
														employeeMovement.movementTypeId == enumProcessAction.renewal
													) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																{{ "emp001_t14_payment_method" | translate }}
															</div>
															<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																{{
																	dataSharing.getIsTH(dataSharing.currentLanguage | async)
																		? employeeMovement.paymentMethodName != null
																			? employeeMovement.paymentMethodName
																			: "-"
																		: employeeMovement.paymentMethodName_EN != null
																			? employeeMovement.paymentMethodName_EN
																			: "-"
																}}
															</div>
														</div>
													}
													@if (
														employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
														employeeMovement.movementTypeId == enumProcessAction.permanent ||
														employeeMovement.movementTypeId == enumProcessAction.renewal
													) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																{{ "emp001_employment_type" | translate }}
															</div>
															<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																{{
																	dataSharing.getIsTH(dataSharing.currentLanguage | async)
																		? employeeMovement.employmentTypeName != null
																			? employeeMovement.employmentTypeName
																			: "-"
																		: employeeMovement.employmentTypeName_EN != null
																			? employeeMovement.employmentTypeName_EN
																			: "-"
																}}
															</div>
														</div>
													}
													@if (
														employeeMovement.movementTypeId == enumProcessAction.resign ||
														employeeMovement.movementTypeId == enumProcessAction.unresign
													) {
														<div>
															<div class="flex-row py-1 gap-6">
																<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																	{{ "common_status" | translate }}
																</div>
																<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																	{{
																		dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.employeeStatusName
																			: employeeMovement.employeeStatusName_EN
																	}}
																</div>
															</div>
															@if (countUnder > 0) {
																<div class="flex-row py-1 gap-6">
																	<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																		{{ "ess009_transfer_sub_to" | translate }}
																	</div>
																	<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																		{{
																			dataSharing.getIsTH(dataSharing.currentLanguage | async)
																				? employeeMovement.upperSupervisorFullName
																				: employeeMovement.upperSupervisorFullName_EN
																		}}
																	</div>
																</div>
															}
														</div>
													}
													@if (
														employeeMovement.movementTypeId == enumProcessAction.crossCompanyTransfer ||
														employeeMovement.movementTypeId == enumProcessAction.agencyTransfer ||
														employeeMovement.movementTypeId == enumProcessAction.massTransfer ||
														employeeMovement.movementTypeId == enumProcessAction.promote ||
														employeeMovement.movementTypeId == enumProcessAction.renewal
													) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small color-lead" style="flex: 1; padding-top: 2px">
																{{ "common_company" | translate }}
															</div>
															<div
																class="go5-text-body-strong go5-text-color-10"
																style="flex: 3; white-space: pre-line"
															>
																{{
																	dataSharing.getIsTH(dataSharing.currentLanguage | async)
																		? employeeMovement.orgCompanyStructure != null
																			? employeeMovement.orgCompanyStructure
																			: "-"
																		: employeeMovement.orgCompanyStructure_EN != null
																			? employeeMovement.orgCompanyStructure_EN
																			: "-"
																}}
															</div>
														</div>
														<div class="flex-row py-1 gap-6">
															<div class="text-small color-lead" style="flex: 1; padding-top: 2px">
																{{ "emp001_department" | translate }}
															</div>
															<div
																class="go5-text-body-strong go5-text-color-10"
																style="flex: 3; white-space: pre-line"
															>
																{{
																	dataSharing.getIsTH(dataSharing.currentLanguage | async)
																		? employeeMovement.orgDepartmentStructure != null
																			? employeeMovement.orgDepartmentStructure
																			: "-"
																		: employeeMovement.orgDepartmentStructure_EN != null
																			? employeeMovement.orgDepartmentStructure_EN
																			: "-"
																}}
															</div>
														</div>
													}
													@if (
														employeeMovement.movementTypeId == enumProcessAction.promote ||
														employeeMovement.movementTypeId == enumProcessAction.raiseTheSalary ||
														employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
														employeeMovement.movementTypeId == enumProcessAction.agencyTransfer ||
														employeeMovement.movementTypeId == enumProcessAction.permanent ||
														employeeMovement.movementTypeId == enumProcessAction.renewal
													) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small color-lead" style="flex: 1; padding-top: 2px">
																{{ "emp001_position" | translate }}
															</div>
															<div
																class="go5-text-body-strong go5-text-color-10"
																style="flex: 3; white-space: pre-line"
															>
																{{
																	(dataSharing.getIsTH(dataSharing.currentLanguage | async)
																		? employeeMovement.positionName != null
																			? employeeMovement.positionName
																			: "-"
																		: employeeMovement.positionName_EN != null
																			? employeeMovement.positionName_EN
																			: "-") +
																		(dataSharing.getIsTH(dataSharing.currentLanguage | async)
																			? employeeMovement.rankName != null
																				? " (" + employeeMovement.rankName + ")"
																				: "(-)"
																			: employeeMovement.rankName_EN != null
																				? " (" + employeeMovement.rankName_EN + ")"
																				: "(-)")
																}}
															</div>
														</div>
													}
													@if (
														(employeeMovement.movementTypeId == enumProcessAction.crossCompanyTransfer ||
															employeeMovement.movementTypeId == enumProcessAction.agencyTransfer ||
															employeeMovement.movementTypeId == enumProcessAction.raiseTheSalary ||
															employeeMovement.movementTypeId == enumProcessAction.promote ||
															employeeMovement.movementTypeId == enumProcessAction.permanent ||
															employeeMovement.movementTypeId == enumProcessAction.changeOfSupervisor ||
															employeeMovement.movementTypeId == enumProcessAction.employmentConditions ||
															employeeMovement.movementTypeId == enumProcessAction.renewal ||
															employeeMovement.movementTypeId == enumProcessAction.massTransfer) &&
														employeeMovement.prevUpperSupervisorId != employeeMovement.upperSupervisorId
													) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small go5-text-color-10" style="flex: 1; padding-top: 2px">
																{{ "emp001_upper_chain_of_command" | translate }}
															</div>
															<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																{{
																	dataSharing.getIsTH(dataSharing.currentLanguage | async)
																		? employeeMovement.upperSupervisorFullName !== " "
																			? employeeMovement.upperSupervisorFullName
																			: "-"
																		: employeeMovement.upperSupervisorFullName_EN !== " "
																			? employeeMovement.upperSupervisorFullName_EN
																			: "-"
																}}
															</div>
														</div>
													}
													@if (employeeMovement.isIncomeAdjust) {
														<div class="d-grid pt-1 gap-4">
															<div class="flex-row gap-4">
																<div class="text-small go5-text-color-10" style="flex: 1">
																	{{ "emp001_salary" | translate }}
																</div>
																<div class="go5-text-body-strong go5-text-color-10 d-flex gap-4" style="flex: 3">
																	<label>
																		{{
																			baseSalary()?.incomeAmount != null
																				? "&#3647;" +
																					(displayMask ? maskAmount : (baseSalary()?.incomeAmount | number: "0.0-2"))
																				: "-"
																		}}
																	</label>
																	<div
																		class="tag-badge"
																		[hidden]="
																			baseSalary()?.prevIncomeAmount == 0 ||
																			baseSalary()?.incomeAmount == baseSalary()?.prevIncomeAmount ||
																			employeeMovement.movementTypeId == enumProcessAction.renewal
																		"
																		[go5-tooltip]="
																			!displayMask
																				? dataSharing.getIsTH(dataSharing.currentLanguage | async)
																					? baseSalary()?.detail
																					: baseSalary()?.detail_EN
																				: 'xxxx.xx'
																		"
																	>
																		{{ baseSalary()?.percentageSalaryAdjustment }}%
																	</div>
																</div>
															</div>
															<div style="grid-template-columns: 1fr 3fr; display: grid; gap: 4px">
																@for (item of salaryAdjustList(); track $index) {
																	@if (isMobile) {
																		<div class="text-small go5-text-color-10 ellipsis" style="flex: 1; max-width: 80px">
																			{{
																				dataSharing.getIsTH(dataSharing.currentLanguage | async)
																					? item.name
																					: item.name_EN
																			}}
																		</div>
																	} @else {
																		<div
																			class="text-small go5-text-color-10 ellipsis"
																			style="flex: 1; max-width: 80px"
																			go5-tooltip-placement="top"
																			go5-tooltip-mode="hover"
																			go5-tooltip="{{
																				dataSharing.getIsTH(dataSharing.currentLanguage | async)
																					? item.name
																					: item.name_EN
																			}}"
																		>
																			{{
																				dataSharing.getIsTH(dataSharing.currentLanguage | async)
																					? item.name
																					: item.name_EN
																			}}
																		</div>
																	}
																	<div class="go5-text-body-strong go5-text-color-10 d-flex gap-4" style="flex: 3">
																		<label>
																			@if (displayMask) {
																				&#3647;{{ maskAmount }}
																			} @else {
																				&#3647;{{ item.incomeAmount | number: "0.0-2" }}
																			}
																		</label>
																		<div
																			class="tag-badge"
																			[hidden]="
																				item?.incomeAmount == item?.prevIncomeAmount ||
																				employeeMovement.movementTypeId == enumProcessAction.renewal
																			"
																			[go5-tooltip]="
																				!displayMask
																					? dataSharing.getIsTH(dataSharing.currentLanguage | async)
																						? item?.detail
																						: item?.detail_EN
																					: 'xxxx.xx'
																			"
																		>
																			@if (item?.category == FinancialCategory.bonus) {
																				{{ item?.percentageSalaryAdjustment }}x
																			} @else {
																				{{ item?.percentageSalaryAdjustment }}%
																			}
																		</div>
																	</div>
																}
															</div>
														</div>
													}
													@if (employeeMovement.movementTypeId == enumProcessAction.employmentConditions) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small go5-text-color-10" style="flex: 1">
																{{ "emp001_start_work" | translate }}
															</div>
															<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																{{
																	dataSharing.getIsTH(dataSharing.currentLanguage | async)
																		? employeeMovement.dateEmployment_TH != null
																			? employeeMovement.dateEmployment_TH
																			: "-"
																		: employeeMovement.dateEmployment_EN != null
																			? employeeMovement.dateEmployment_EN
																			: "-"
																}}
															</div>
														</div>
													}
													@if (employeeMovement.movementTypeId == enumProcessAction.employmentConditions) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small go5-text-color-10" style="flex: 1">
																{{ "emp001_probation" | translate }}
															</div>
															<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																{{
																	employeeMovement.dayOfProbation != null
																		? employeeMovement.dayOfProbation + " " + ("common_day" | translate)
																		: "-"
																}}
															</div>
														</div>
													}

													@if (employeeMovement.severanceAmount > 0) {
														<div class="flex-row py-1 gap-6">
															<div class="text-small go5-text-color-10" style="flex: 1">
																{{ "severance_pay" | translate }}
															</div>
															<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
																฿{{ displayMask ? maskAmount : (employeeMovement.severanceAmount | number: "0.0-2") }}
															</div>
														</div>
													}
													<div class="flex-row py-1 gap-6">
														<div class="text-small go5-text-color-10" style="flex: 1">
															{{ "common_effective_date" | translate }}
														</div>
														<div class="go5-text-body-strong go5-text-color-10" style="flex: 3">
															{{
																dataSharing.getIsTH(dataSharing.currentLanguage | async)
																	? employeeMovement.dateEffective_TH != null
																		? employeeMovement.dateEffective_TH
																		: "-"
																	: employeeMovement.dateEffective_EN != null
																		? employeeMovement.dateEffective_EN
																		: "-"
															}}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									@if (isGroupMovement) {
										<div class="ui-g-12 ui-g-nopad" style="padding-top: 24px;">
											<span class="ellipsis go5-text-body-strong go5-text-color-12">
												{{ "movement_name_list" | translate }}
											</span>
											<div style="margin-top: 12px; margin-bottom: 4px">
												<table class="dataTable focus hover fadeIn" style="width: 100%" aria-describedby="employee">
													<thead>
														<tr>
															<th class="text-left pointer" style="width: 100%" (click)="sortingBy('employee')">
																<div class="dt-sorting">
																	<span> {{ "common_employee_actual" | translate }} </span>
																	<em
																		[ngClass]="{
																			'gf-icon-sort': sortOrder !== 'employee' && sortOrder !== 'employee asc',
																			'gf-icon-asc-order': sortOrder === 'employee asc',
																			'gf-icon-desc-order': sortOrder === 'employee'
																		}"
																	>
																	</em>
																</div>
															</th>
														</tr>
													</thead>
													<tbody>
														@for (item of employeeMovement?.employeeRequests; track item; let i = $index) {
															<tr [ngClass]="{ pointer: !isMobile }" (click)="viewEmployee(item)">
																<td class="px-2">
																	<div class="contacts">
																		<div class="ui-g-12 ui-g-nopad ellipsis text-left">
																			<go5-avatar-information
																				[src]="item?.pictureThumbnailURL"
																				title="{{
																					dataSharing.getIsTH(dataSharing.currentLanguage | async)
																						? item?.nameSurname
																						: item?.nameSurname_EN
																				}}"
																				detail="{{
																					dataSharing.getIsTH(dataSharing.currentLanguage | async)
																						? item?.positionName
																						: item?.positionName_EN
																				}}"
																				[isLazy]="false"
																			>
																			</go5-avatar-information>
																		</div>
																	</div>
																</td>
															</tr>
														}
													</tbody>
												</table>
											</div>
										</div>
									}
								</div>
							</div>

							@if (employeeMovement.documentStatusId != enumDocumentStatus.waitingRevise) {
								@if (isMobile) {
									<ng-container *ngTemplateOutlet="footer"></ng-container>
								} @else {
									@if (allowManageLeave && !isCancel && processActions != null && processActions.length > 1) {
										<div class="ui-g-12 ui-g-nopad pt-4" id="textarea_description">
											@if (processActions != null && processActions.length != 0) {
												<div class="ui-g-12 ui-g-nopad pb-2">
													<label class="text-header-5">{{ "emp001_t10_process_action" | translate }}</label>
												</div>
											}
											<div class="ui-g-12 ui-g-nopad p-grid-responsive {{ isClicked ? 'disabled-content' : '' }}">
												<ng-container *ngTemplateOutlet="movement_process_action_detail"></ng-container>
												<div
													class="text-right border-top d-flex gap-8"
													style="margin-top: 10px; flex-direction: row-reverse"
												>
													@for (item of processActions; track item; let i = $index) {
														<span [ngClass]="{ 'faded-btn': isClicked }">
															@if (item.id != cancelProcess && item.id != getProcessActionEnum.returnDocument) {
																<go5-button
																	data-testid="button_button_onprocessaction"
																	[category]="item.id | categoryButton"
																	size="medium"
																	type="button"
																	[loading]="isClicked && idBtn == item.id"
																	[label]="sharingService.getLabelProcessActionBTN(item.id) | translate"
																	(click)="onProcessAction(item.id)"
																	[disabled]="isClicked && idBtn == item.id"
																>
																</go5-button>
															} @else if (item.id === getProcessActionEnum.returnDocument) {
																<go5-button
																	data-testid="button_button_onprocessaction"
																	[category]="item.id | categoryButton"
																	size="medium"
																	type="button"
																	[loading]="isClicked && idBtn == item.id"
																	iconClass="gf-icon-arrow-bend-left-side"
																	(click)="onProcessAction(item.id)"
																	[disabled]="isClicked && idBtn == item.id"
																>
																</go5-button>
															}
														</span>
													}
												</div>
											</div>
										</div>
									}
									@if (isCancel) {
										<div class="ui-g-12 ui-g-nopad pt-4" id="textarea_description">
											@if (processActions != null && processActions.length != 0) {
												<div class="ui-g-12 ui-g-nopad pb-2">
													<label class="text-header-5">{{ "emp001_t10_process_action" | translate }}</label>
												</div>
											}
											@if (processActions != null && processActions.length != 0) {
												<div class="ui-g-12 ui-g-nopad p-grid-responsive {{ isClicked ? 'disabled-content' : '' }}">
													<go5-textarea
														data-testid="input_textarea_description"
														size="large"
														placeholder="{{
															isCancel
																? ('common_placeholder_cancel' | translate)
																: ('common_state_the_reason' | translate)
														}}"
														[(ngModel)]="description"
														maxlength="499"
													></go5-textarea>
													<span
														class="text-right border-top float-right"
														[ngClass]="{ 'faded-btn': isClicked }"
														style="margin-top: 10px"
													>
														<go5-button
															data-testid="button_button_comfirm"
															class="pl-2"
															category="primary"
															size="medium"
															type="button"
															label="{{ 'common_confirm' | translate }}"
															(click)="onProcessAction(getProcessActionEnum.canceledDocument)"
															[disabled]="isClicked && idBtn == getProcessActionEnum.canceledDocument"
														>
														</go5-button>
														@if (isClicked) {
															<div class="sending">
																<app-loading-view [(color)]="colorAnimation"></app-loading-view>
															</div>
														}
													</span>
													<span
														class="text-right border-top float-right"
														[ngClass]="{ 'faded-btn': isClicked }"
														style="margin-top: 10px"
													>
														<go5-button
															data-testid="button_button_cancel"
															class="pl-2"
															category="secondary"
															size="medium"
															type="button"
															label="{{ 'common_cancel' | translate }}"
															(click)="closeIsCancel()"
														>
														</go5-button>
													</span>
												</div>
											}
										</div>
									}
								}
							} @else {
								<span style="padding-top: 8px">
									<span style="float: left; margin-top: 8px">
										<div class="ui-g-12 ui-g-nopad">
											<span class="ellipsis go5-text-body-strong go5-text-color-12">
												{{ "common_reason_return" | translate }}
											</span>
										</div>
										<span class="go5-text-color-10 pt-2" style="float: left">
											{{ showLog?.remark }}
										</span>
									</span>
								</span>
								<go5-button
									data-testId="button_button_edit"
									class="pl-2"
									category="primary"
									size="extra-small"
									type="button"
									label="{{ 'common_edit_document' | translate }}"
									style="float: right; padding-top: 64px"
									width="106px"
									height="32px"
									(click)="checkEdit(employeeMovement)"
								></go5-button>
								@if (employeeMovement.documentStatusId != enumDocumentStatus.waitingRevise) {
									@if (allowManageLeave && !isCancel && processActions != null && processActions.length > 1) {
										<div class="ui-g-12 ui-g-nopad pt-4" id="textarea_description">
											@if (processActions != null && processActions.length != 0) {
												<div class="ui-g-12 ui-g-nopad pb-2">
													<label class="text-header-5">{{ "emp001_t10_process_action" | translate }}</label>
												</div>
											}
											<div class="ui-g-12 ui-g-nopad p-grid-responsive {{ isClicked ? 'disabled-content' : '' }}">
												<go5-textarea
													data-testid="input_textarea_description"
													size="large"
													placeholder="{{
														isCancel
															? ('common_placeholder_cancel' | translate)
															: ('common_state_the_reason' | translate)
													}}"
													[(ngModel)]="description"
													maxlength="499"
												></go5-textarea>
												<div
													class="text-right border-top d-flex gap-8"
													style="margin-top: 10px; flex-direction: row-reverse"
												>
													@for (item of processActions; track item; let i = $index) {
														<span [ngClass]="{ 'faded-btn': isClicked }">
															@if (item.id != cancelProcess) {
																<go5-button
																	data-testid="button_button_onprocessaction"
																	class="pl-2"
																	[category]="item.id | categoryButton"
																	size="medium"
																	type="button"
																	[loading]="isClicked && idBtn == item.id"
																	label="{{
																		dataSharing.getIsTH(dataSharing.currentLanguage | async) ? item.text : item.text_EN
																	}}"
																	(click)="onProcessAction(item.id)"
																	[disabled]="isClicked && idBtn == item.id"
																>
																</go5-button>
															}
														</span>
													}
												</div>
											</div>
										</div>
									}
								}
								@if (isCancel) {
									<div class="ui-g-12 ui-g-nopad pt-4" id="textarea_description">
										@if (processActions != null && processActions.length != 0) {
											<div class="ui-g-12 ui-g-nopad pb-2">
												<label class="text-header-5">{{ "emp001_t10_process_action" | translate }}</label>
											</div>
										}
										@if (processActions != null && processActions.length != 0) {
											<div class="ui-g-12 ui-g-nopad p-grid-responsive {{ isClicked ? 'disabled-content' : '' }}">
												<go5-textarea
													data-testid="input_textarea_description"
													size="large"
													placeholder="{{
														isCancel
															? ('common_placeholder_cancel' | translate)
															: ('common_state_the_reason' | translate)
													}}"
													[(ngModel)]="description"
													maxlength="499"
												></go5-textarea>
												<span
													class="text-right border-top float-right"
													[ngClass]="{ 'faded-btn': isClicked }"
													style="margin-top: 10px"
												>
													<go5-button
														data-testid="button_button_comfirm"
														class="pl-2"
														category="primary"
														size="medium"
														type="button"
														label="{{ 'common_confirm' | translate }}"
														(click)="onProcessAction(getProcessActionEnum.canceledDocument)"
														[disabled]="isClicked && idBtn == getProcessActionEnum.canceledDocument"
													>
													</go5-button>
													@if (isClicked) {
														<div class="sending">
															<app-loading-view [(color)]="colorAnimation"></app-loading-view>
														</div>
													}
												</span>
												<span
													class="text-right border-top float-right"
													[ngClass]="{ 'faded-btn': isClicked }"
													style="margin-top: 10px"
												>
													<go5-button
														data-testid="button_button_cancel"
														class="pl-2"
														category="secondary"
														size="medium"
														type="button"
														label="{{ 'common_cancel' | translate }}"
														(click)="closeIsCancel()"
													>
													</go5-button>
												</span>
											</div>
										}
									</div>
								}
							}
						</div>
					</div>
				} @else {
					<div class="d-flex g-16 align-center content-center direction-column" style="padding-top: 40px">
						<div>
							<img [src]="'assets/images/empty-stage/empeo-empty-state-no-data.svg' | sAssets" />
						</div>
						<p class="go5-text-body go5-text-color-8">{{ "common_access_denied" | translate }}</p>
					</div>
				}
			</div>
			@if (employeeMovement?.allowSeeOwnMovement) {
				<div
					class="ui-g-12 ui-g-nopad pt-4"
					[ngStyle]="{ 'margin-top': isMobile ? '32px' : '', 'padding-bottom': isMobile ? heightFooter + 'px' : '' }"
				>
					<workflow-log
						[workflow]="employeeMovement.logs"
						[documentStatusId]="employeeMovement.documentStatusId"
					></workflow-log>
				</div>
			}
		</div>
	}

	@if (isLoadingDisplay) {
		<div class="ui-g-12 ui-g-nopad">
			<div class="ui-g-12 ui-g-nopad">
				<div class="ui-g-12 ui-g-nopad border-bottom-gray pb-3 pl-3 pr-3 pt-4 go5-text-color-10">
					<ngx-skeleton-loader
						[theme]="{ width: '80%', height: '30px', float: 'left', animation: 'infinite progress' }"
						animation="progress-dark"
					>
					</ngx-skeleton-loader>
				</div>
				<div class="ui-g-12 ui-g-nopad p-3" style="margin: 15px 0">
					<div class="contacts">
						<div class="contact-info-leave ui-g-12 ui-g-nopad ellipsis" style="text-align: left">
							<div>
								<ngx-skeleton-loader
									[theme]="{
										width: '45px',
										height: '45px',
										margin: '0px 8px 0px 0px',
										float: 'left',
										animation: 'infinite progress'
									}"
									animation="progress-dark"
									appearance="circle"
								>
								</ngx-skeleton-loader>
								<ngx-skeleton-loader
									[theme]="{ width: '80%', height: '20px', float: 'left', animation: 'infinite progress' }"
									animation="progress-dark"
								>
								</ngx-skeleton-loader>
								<ngx-skeleton-loader
									[theme]="{ width: '80%', height: '20px', float: 'left', animation: 'infinite progress' }"
									animation="progress-dark"
								>
								</ngx-skeleton-loader>
							</div>
						</div>
						<div class="contact-info-leave ui-g-12 ui-g-nopad ellipsis">
							<div class="ui-g-12 ui-g-nopad">
								<ngx-skeleton-loader
									[theme]="{ width: '80%', height: '20px', float: 'left', animation: 'infinite progress' }"
									animation="progress-dark"
								>
								</ngx-skeleton-loader>
								<ngx-skeleton-loader
									[theme]="{ width: '80%', height: '20px', float: 'left', animation: 'infinite progress' }"
									animation="progress-dark"
								>
								</ngx-skeleton-loader>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ui-g-12 ui-g-nopad py-3">
				<div class="ui-g-12 ui-g-nopad border-top-gray py-1" style="margin-top: 15px; padding-top: 20px !important">
					<div class="ui-g-12 ui-g-nopad px-3">
						<ngx-skeleton-loader
							[theme]="{ width: '40%', height: '30px', float: 'left', animation: 'infinite progress' }"
							animation="progress-dark"
						>
						</ngx-skeleton-loader>
					</div>
					@for (item of counter(2); track item) {
						<div class="ui-g-12 ui-g-nopad pt-3 pb-3 px-3 notification">
							<div class="contacts">
								<div class="contact-info-leave ui-g-12 ui-g-nopad ellipsis" style="text-align: left">
									<div>
										<ngx-skeleton-loader
											[theme]="{
												width: '45px',
												height: '45px',
												margin: '0px 8px 0px 0px',
												float: 'left',
												animation: 'infinite progress'
											}"
											animation="progress-dark"
											appearance="circle"
										>
										</ngx-skeleton-loader>
										<ngx-skeleton-loader
											[theme]="{ width: '80%', height: '20px', float: 'left', animation: 'infinite progress' }"
											animation="progress-dark"
										>
										</ngx-skeleton-loader>
										<ngx-skeleton-loader
											[theme]="{ width: '80%', height: '20px', float: 'left', animation: 'infinite progress' }"
											animation="progress-dark"
										>
										</ngx-skeleton-loader>
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	}
</div>

@if (displayLeaveBenefitModal) {
	<go5-dialog [(visible)]="displayLeaveBenefitModal" [width]="'80vw'">
		<div class="ui-g-12 ui-g-nopad">
			<app-employee-leave-usage
				[empHeader]="
					dataSharing.getIsTH(dataSharing.currentLanguage | async)
						? employeeMovement.requestorFullName
						: employeeMovement.requestorFullName_EN
				"
				[allowGrant]="true"
				[isHR]="userInfo?.isHR"
				[displayEdit]="false"
				[displayBtnCalculate]="false"
			>
			</app-employee-leave-usage>
		</div>
	</go5-dialog>
}

@if (displayAssessmentSummaryModal) {
	<go5-dialog [(visible)]="displayAssessmentSummaryModal" [width]="'80vw'" [isShowHeader]="false">
		<div style="min-height: 780px">
			<gfc-employee-assessment
				[employeeId]="employeeId"
				[isEmpeo]="true"
				[indexTab]="currentHeaderTabIndex"
				[scoreNo]="activeIndexAssessment"
				[isDialog]="true"
				[tokenParams]="tokenParams"
				[scoreId]="scoreId"
				[isDialog]="true"
				[isShowDetail]="true"
			>
			</gfc-employee-assessment>
		</div>
	</go5-dialog>
}

@if (isSidebarMovement) {
	<app-movement-sidebar-add
		[isSidebarAdd]="isSidebarMovement"
		[movementType]="movementTypeId"
		[employeeId]="employeeId"
		[movementTransactionId]="movementTransactionId"
		[isLockSelect]="isLockSelect"
		[employeeMovement]="employeeMovement"
		(closeSidebar)="closeSidebarAdd()"
		(success)="successMovement()"
	>
	</app-movement-sidebar-add>
}

<ng-template #footer>
	@if (allowManageLeave && processActions != null && processActions.length != 0) {
		<div id="footer" class="footer-layout d-grid gap-8" [style.padding-bottom.px]="paddingBottom">
			@if (
				processActions != null &&
				processActions.length != 0 &&
				employeeMovement?.documentStatusId != enumDocumentStatus.waitingRevise
			) {
				<div class="d-flex gap-8">
					@if (processActionReturn) {
						<go5-button
							data-testid="button_button_onprocessaction"
							[category]="processActions[2].id | categoryButton"
							size="medium"
							[iconClass]="'gf-icon-arrow-bend-left-side'"
							type="button"
							(click)="showDialog(processActions[2].id)"
						>
						</go5-button>
					}
					@if (processActionReject) {
						<go5-button
							class="btn-footer"
							data-testid="button_button_onprocessaction"
							[category]="processActions[1].id | categoryButton"
							size="medium"
							type="button"
							label="{{ dataSharing.getIsTH(dataSharing.currentLanguage | async) ? 'ปฏิเสธ' : 'Reject' }}"
							(click)="showDialog(processActions[1].id)"
						>
						</go5-button>
					}
					@if (processActionApprove) {
						<go5-button
							class="btn-footer"
							data-testid="button_button_onprocessaction"
							[category]="processActions[0].id | categoryButton"
							size="medium"
							type="button"
							label="{{ dataSharing.getIsTH(dataSharing.currentLanguage | async) ? 'อนุมัติ' : 'Approve' }}"
							(click)="showDialog(processActions[0].id)"
						>
						</go5-button>
					}
				</div>
			}
		</div>
	}
</ng-template>

<ng-template #movement_process_action_detail>
	<div class="d-grid pt-2 gap-16">
		<go5-datepicker
			data-testid="input-effective-date"
			[format]="DateFormat.dateLong"
			[allowShowRemove]="false"
			[label]="'ess009_date_effective' | translate"
			[(ngModel)]="dateEffective"
			(changed)="changeDateEffective($event)"
			[isShowTime]="false"
		>
		</go5-datepicker>
		<go5-info-bar
			[hidden]="employeeMovement.movementTypeId != ProcessAction.resign || validDateEffective"
			[type]="InfoBarType.Warning"
			>{{
				"ess009_change_effective_date_info"
					| translate
						: {
								dateEffective: dateEffectiveInfo | date: "d MMMM yyyy" : (dataSharing.currentLanguage | async)
						  }
			}}
		</go5-info-bar>
		@if (countUnder > 0 && !isLoadDDLUpper) {
			<go5-dropdown-selection
				data-testid="dropdown_single_orgNode"
				[allowShowRemove]="false"
				[mode]="DropdownSelectMode.Single"
				[dataSource]="listPeople"
				[label]="('ess009_separation_supervisor_new' | translate: { subordinatesamount: countUnder }) + '*'"
				placeholder="{{ 'common_choose_employee' | translate }}"
				popupType="slide"
				categoryItem="item"
				(filtering)="onFilterEmployee($event)"
				[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? peopleFields : peopleFields_EN"
				[(ngModel)]="upperSupervisorId"
			>
			</go5-dropdown-selection>
		}
		<go5-textarea
			data-testid="input_textarea_description"
			size="large"
			placeholder="{{ isCancel ? ('common_placeholder_cancel' | translate) : ('common_state_the_reason' | translate) }}"
			[(ngModel)]="description"
			maxlength="499"
		></go5-textarea>
	</div>
</ng-template>

@if (approveDialog) {
	<go5-dialog [(visible)]="approveDialog" [width]="'90vw'" [isShowCloseIcon]="false" [isFullScreen]="false">
		<div class="d-flex gap-8 d-flex-center">
			<em class="font20 go5-color-primary gf-icon-empeo-document"></em>
			<label class="go5-text-header-5 go5-text-color-11">{{ "emp001_t10_process_action" | translate }}</label>
		</div>
		<ng-container *ngTemplateOutlet="movement_process_action_detail"></ng-container>
		<div clas="gap-8" style="display: flex; justify-content: flex-end; gap: 8px; padding-top: 8px">
			<go5-button
				data-testid="button_button_onprocessaction"
				[category]="getProcessActionEnum.rejectedDocument | categoryButton"
				size="extra-small"
				type="button"
				label="{{ 'common_close' | translate }}"
				(click)="cancelDialogConfirm()"
			>
			</go5-button>
			<go5-button
				data-testid="button_button_onprocessaction"
				[category]="getProcessActionEnum.approvedDocument | categoryButton"
				size="extra-small"
				type="button"
				[loading]="isClicked && idBtn == processActionId"
				[label]="textButtonConfirm"
				(click)="onProcessAction(processActionId)"
			>
			</go5-button>
		</div>
	</go5-dialog>
}

@if (displayAssetsSidebar) {
	<app-assets-sidebar
		[selectAssetId]="selectAssetId"
		(closeSidebar)="onHideSidebarAsset($event)"
		[displayAssetsSidebar]="displayAssetsSidebar"
	>
	</app-assets-sidebar>
}
