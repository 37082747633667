<!-- empty state -->
<go5-dialog [width]="'60vw'" [(visible)]="displayDialog" [isShowCloseIcon]="false" [padding]="'0px'">
	<img src="assets/images/2fa/bg-2fa.png" style="width: 100%; position: absolute" />
	@if (state == stateWelcome || is2FA) {
		<i class="icon-close-empeo-v2 icon-close-2fa" (click)="visibleChange()"></i>
	}
	@if (state == null) {
		<div class="layout-2fa"></div>
	}
	@if (state == stateWelcome) {
		<div class="layout-2fa fadeIn">
			<ng-container *ngTemplateOutlet="welcomContent"></ng-container>
		</div>
	}
	@if (state == stateScan) {
		<div class="layout-2fa fadeIn">
			<ng-container *ngTemplateOutlet="scanContent"></ng-container>
		</div>
	}
	@if (state == stateAuth) {
		<div class="layout-2fa fadeIn">
			<ng-container *ngTemplateOutlet="authContent"></ng-container>
		</div>
	}
	@if (state == stateSuccess) {
		<div class="layout-2fa fadeIn">
			<ng-container *ngTemplateOutlet="successContent"></ng-container>
		</div>
	}
</go5-dialog>

<ng-template #welcomContent>
	<div class="d-flex" style="flex-direction: column; align-items: center; margin-top: 4.5rem">
		<label class="text-header-2 color-charcoal">
			{{ "module_2fa_welcome_title" | translate }}
		</label>
		<label class="text-title">
			{{ "module_2fa_welcome_title_1" | translate }}
		</label>
		<label class="text-title">
			{{ "module_2fa_welcome_title_2" | translate }}
		</label>
		<div class="mt-5 mb-2">
			<img [hidden]="isloadImage" (load)="loadImg($event)" src="assets/images/2fa/2fa-1.png" style="width: 120px" />
			@if (isloadImage) {
				<ngx-skeleton-loader [theme]="{ width: '120px' }" animation="progress-dark"> </ngx-skeleton-loader>
			}
		</div>
		<label class="mt-1 mb-3 text-title color-charcoal">
			{{ "module_2fa_google_authenticator" | translate }}
		</label>
		@if (!data.getIsTH(data.currentLanguage | async)) {
			<div [hidden]="isloadImage" class="mt-3 mb-2 d-flex d-flex-center">
				<img src="assets/images/2fa/app-store-EN.svg" class="pointer mr-2" (click)="downloadAppleStore()" />
				<img src="assets/images/2fa/google-play-EN.png" class="pointer" (click)="downloadGooglePlay()" />
			</div>
		}
		@if (data.getIsTH(data.currentLanguage | async)) {
			<div [hidden]="isloadImage" class="mt-3 mb-2 d-flex d-flex-center">
				<img src="assets/images/2fa/app-store-TH.svg" class="pointer mr-2" (click)="downloadAppleStore()" />
				<img src="assets/images/2fa/google-play-TH.png" class="pointer" (click)="downloadGooglePlay()" />
			</div>
		}

		@if (isloadImage) {
			<div class="mt-3 mb-2" style="height: 54px">
				<ngx-skeleton-loader [theme]="{ width: '160px', 'margin-right': '10px' }" animation="progress-dark">
				</ngx-skeleton-loader>
				<ngx-skeleton-loader [theme]="{ width: '160px' }" animation="progress-dark"> </ngx-skeleton-loader>
			</div>
		}

		<label class="color-charcoal">
			{{ "module_2fa_welcome_title_3" | translate }}
		</label>
	</div>
	<ng-container *ngTemplateOutlet="footerContent; context: { $implicit: stateWelcome }"></ng-container>
</ng-template>

<ng-template #scanContent>
	<div class="d-flex" style="flex-direction: column; align-items: center; margin-top: 4.5rem">
		<label class="text-header-2 color-charcoal">
			{{ "module_2fa_scanqr_title" | translate }}
		</label>
		<label class="text-title">
			{{ "module_2fa_scanqr_title_2" | translate }}
		</label>
		<div class="mt-5 mb-2">
			<qrcode [qrdata]="qrdata" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
		</div>
		<label class="mt-3 mb-2">
			{{ "module_2fa_scanqr_title_3" | translate }}
		</label>
		<label class="text-header-4">
			{{ secretKey.substring(0, 4) }} {{ secretKey.substring(4, 8) }} {{ secretKey.substring(8, 12) }}
			{{ secretKey.substring(12, 16) }}
		</label>
	</div>
	<ng-container *ngTemplateOutlet="footerContent; context: { $implicit: stateScan }"></ng-container>
</ng-template>

<ng-template #authContent>
	<div class="d-flex" style="flex-direction: column; align-items: center; margin-top: 4.5rem">
		<label class="text-header-2 color-charcoal">
			{{ "module_2fa_auth_title" | translate }}
		</label>
		<label class="text-title">
			{{ "module_2fa_auth_title_2" | translate }}
		</label>
		<div class="p-inputgroup" style="z-index: 1; margin-top: 130px">
			<ng-otp-input
				id="integer"
				pKeyFilter="int"
				#ngOtpInput
				id="ngOtpInput"
				style="width: max-content"
				(onInputChange)="onOtpChange($event)"
				[config]="config"
			></ng-otp-input>
		</div>
		@if (isValid && otp.length == 6) {
			<label class="text-title color-ruby mt-3">
				{{ validMessage }}
			</label>
		}
	</div>
	<ng-container *ngTemplateOutlet="footerContent; context: { $implicit: stateAuth }"></ng-container>
</ng-template>

<ng-template #successContent>
	<div class="d-flex" style="flex-direction: column; align-items: center; margin-top: 4.5rem">
		<div class="mt-5 mb-2">
			<img src="assets/images/2fa/confirm.png" style="width: 230px" />
		</div>

		<label class="mt-3 text-header-2 color-charcoal">
			{{ "module_2fa_success_title" | translate }}
		</label>
		<label class="mb-5 text-title">
			{{ "module_2fa_success_title_2" | translate }}
		</label>
		<go5-button
			data-testid="button_button_finished"
			label="{{ 'common_finished' | translate }}"
			category="primary"
			size="medium"
			type="button"
			(click)="visibleChange()"
		>
		</go5-button>
	</div>
</ng-template>

<ng-template #footerContent let-data>
	<div class="d-flex gap-8 p-3" style="justify-content: flex-end">
		@if (data != 0 && data != stateAuth) {
			<go5-button
				data-testid="button_button_prev"
				label="{{ 'common_prev' | translate }}"
				category="secondary"
				size="medium"
				type="button"
				(click)="prev(data)"
			>
			</go5-button>
		}

		@if (data == stateAuth && !is2FA) {
			<go5-button
				data-testid="button_button_prev"
				label="{{ 'common_prev' | translate }}"
				category="secondary"
				size="medium"
				type="button"
				(click)="prev(data)"
			>
			</go5-button>
		}
		<!-- ปุ่มกดต่อไป สำหรับหน้าอื่นๆ -->

		@if (state != stateAuth) {
			<go5-button
				data-testid="button_button_next"
				label="{{ 'common_next' | translate }}"
				category="primary"
				size="medium"
				type="button"
				(click)="next(data)"
			>
			</go5-button>
		}

		<!-- ปุ่มกด ถัดไปสำหรับหน้ายืนยันป้องกันการแก้ css แล้วกดถัดไป -->
	</div>
</ng-template>
