import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeMovementHistory } from "./employee-history.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeHistoryService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeMovementHistoryByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeMovementHistory/GetEmployeeMovementHistoryByEmployeeId/" + employeeId),
		).then((res) => <EmployeeMovementHistory[]>res);
	}

	getEmployeeMovementEditHistoriesById(movementHistoryId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeMovement/GetEmployeeMovementEditHistoriesById/" + movementHistoryId),
		).then((res) => <any[]>res);
	}

	getSearchEmployeeMovementHistory(model: any) {
		this.commonService.changeDateJSON();
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		const body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "employee-movements/histories/search", body, httpOptions);
	}

	updateEffectiveMovement(movement: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(movement);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeMovement/UpdateEffectiveMovement", body, httpOptions);
	}

	deleteEmployeeMovementTransactions(movementTransactionId: number) {
		var body = JSON.stringify(movementTransactionId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(
			this.apiUrl + "EmployeeMovementHistory/DeleteEmployeeMovementTransactions",
			body,
			httpOptions,
		);
	}

	deleteEmployeeMovementHistoryById(movementHistoryId: number) {
		return this.http.delete(this.apiUrl + "employee-movements/histories/" + movementHistoryId);
	}
}
