import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { ApprovalSearchModel, ExpenseSearchModel } from "./approval.model";
import { firstValueFrom } from "rxjs";
export enum TypeApiExpense {
	waiting = "waiting",
	history = "history",
}
@Injectable()
export class ApprovalService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		@Inject("VENIO_API4_URL") private venioApi4Url: string,
		@Inject("VENIO_API_EXPENSE_URL") private venioApiExpenseUrl: string,
	) {}

	getLeaveRequestForApprove(leaveCategoryId?: number, approvalSearchModel?: ApprovalSearchModel) {
		return firstValueFrom(
			this.http.post(this.apiUrl + "MyDocument/GetLeaveRequestForApprove/" + leaveCategoryId, approvalSearchModel),
		).then((res) => <any>res);
	}
	getExpenseList(model: ExpenseSearchModel, type: TypeApiExpense) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json", "x-AppName": "empeo" }),
		};
		return this.http.post<any>(this.venioApiExpenseUrl + `v1/expense/${type}-approve/filter`, body, httpOptions);
	}
	approveExpense(model) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json", "x-AppName": "empeo" }),
		};
		return firstValueFrom(
			this.http.post(this.venioApi4Url + "v3/Expense/Approve?application=empeo", body, httpOptions),
		).then((res) => <any>res);
	}
	cancelExpense(model) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json", "x-AppName": "empeo" }),
		};
		return firstValueFrom(
			this.http.post(this.venioApi4Url + "v2/Expense/Cancel?application=empeo", body, httpOptions),
		).then((res) => <any>res);
	}

	getLeaveRequestDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "MyDocument/GetLeaveRequestDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getLeaveRequestApprovalHistoryDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "MyDocument/GetLeaveRequestApprovalHistoryDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	approveSelected(leaveRequestId: string[]) {
		var body = JSON.stringify(leaveRequestId);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/ApproveSelected", body, httpOptions);
	}

	addProcessBySelected(leaveRequestId: string[], processActionId: number, reason: string) {
		var body = JSON.stringify({ leaveRequestId: leaveRequestId, processActionId: processActionId, reason: reason });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/AddProcessBySelected", body, httpOptions);
	}
}
