import { Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { Router } from "@angular/router";
import { DialogInformationModel, DialogService, DialogModule } from "@gofive/design-system-dialog";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DataTableDirective } from "angular-datatables";
import { Subscription, firstValueFrom } from "rxjs";
import { MessageWarningType } from "src/app/shared/enum/message-warning-type.enum";
import { Movement } from "src/app/shared/enum/movement.enum";
import { isNullOrUndefined, isUndefined } from "src/app/shared/sharing.service";
import { DataSharingService, UserControlService } from "../../../core";
import { FinancialCategory } from "../../../shared/enum/financial-category.enum";
import { MasterSetup } from "../../../shared/enum/master-setup.enum";
import { Module, ModuleWorkflow } from "../../../shared/enum/module.enum";
import { ProcessAction } from "../../../shared/enum/process-action.enum";
import { ApiResult } from "../../../shared/models/api-result.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { Permission } from "../../../shared/models/permission.model";
import { CommonService } from "../../../shared/service/common.service";
import { EmployeeMovementService } from "../../employee-movement/shared/employee-movement.service";
import { EmployeeWorkInformationService } from "../../employee-work-information/shared/employee-work-information.service";
import { Employee } from "../../shared/employee-movements.model";
import { EmployeeMovementsService } from "../../shared/employee-movements.service";
import { EmployeeFinancialService } from "../shared/employee-financial.service";
import { WorkflowSearch } from "src/app/setting/workflow/shared/workflow.model";
import { WorkflowService } from "src/app/setting/workflow/shared/workflow.service";
import { EditType } from "../../employee/employee-add/employee-add.component";
import { DateFormat, AngularCommonModule } from "@gofive/angular-common";
import { PaymentType } from "src/app/shared/enum/payment-type.num";
import { InfoBarType, BaseModule } from "@gofive/design-system-base";
import { SelectItem } from "primeng/api";
import { BusinessCode } from "src/app/shared/enum/businessCode.enum";
import { SessionStorageKey } from "src/app/shared/enum/local-storage-key";
import { CalendarModule } from "@gofive/design-system-calendar";
import { ButtonModule } from "@gofive/design-system-button";
import { SalaryAdjustmentHistoryComponent } from "../../../shared/component/salary-adjustment-history/salary-adjustment-history.component";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { InputModule } from "@gofive/design-system-input";
import { InfoTooltipComponent } from "../../../shared/template/info-tooltip/info-tooltip.component";
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "@gofive/design-system-dropdown";
import { SelectButtonModule } from "primeng/selectbutton";
import { InfoBarComponent } from "../../../shared/component/info-bar/info-bar.component";
import { NgStyle, NgIf, NgClass, NgFor, AsyncPipe, DecimalPipe } from "@angular/common";
@Component({
	selector: "app-employee-payroll-history",
	templateUrl: "./employee-payroll-history.component.html",
	styleUrls: ["./employee-payroll-history.component.scss"],
	standalone: true,
	imports: [
		NgStyle,
		NgIf,
		FormsModule,
		ReactiveFormsModule,
		InfoBarComponent,
		SelectButtonModule,
		NgClass,
		DropdownModule,
		InputTextModule,
		InfoTooltipComponent,
		AngularCommonModule,
		InputModule,
		TooltipModule,
		BaseModule,
		SalaryAdjustmentHistoryComponent,
		ButtonModule,
		DialogModule,
		CalendarModule,
		NgFor,
		AsyncPipe,
		DecimalPipe,
		TranslateModule,
	],
})
export class EmployeePayrollHistoryComponent implements OnInit {
	@ViewChild(DataTableDirective, { static: true })
	public dtElement: DataTableDirective;
	public dtOptions: DataTables.Settings = {};

	@Input() public allowGrant: boolean = false;
	@Input() public isMeAndMySalary: boolean = false;
	@Input() public isViewSalary: boolean = true;
	@Input() public isShowSalary: boolean = false;
	@Input() public viewForm: boolean = true;
	@Input() public isShowHistory: boolean = false;
	@Input() public isShowForm: boolean = false;
	@Input() public isFooter: boolean = false;
	@Input() public isActive2FA: boolean = false;

	public paymentAccountDropDown: boolean = false;
	public isLoadEmpty: boolean = true;
	private subscription: Subscription;
	public employee: Employee = new Employee();
	public oldDataOfEmployee: Employee = new Employee();
	public keyLabelDialog: boolean = false;
	public sysBankDDL: Dropdown[];
	public bankCodeDDL: Dropdown[];
	public paymentAccount: any[];
	public paymentAccountNo: any;
	public paymentMethodDDL: Dropdown[];
	public movementTypeId: any = null;
	public infoBarMovementTypeId: number = Movement.raiseTheSalary;
	public isCash: any;
	public isCashOption: SelectItem[] = [
		{ label: "emp001_t14_cash", value: true },
		{ label: "emp001_t14_bank", value: false },
	];
	public bankIdObj: any = null;
	public bankObj: any = null;
	public fristAccountNo: any = null;
	public bankCodeId: any = null;
	public paymentMethod: any = null;
	public companyBankAccountNo: any = null;
	public id: number;
	public maskAmount: string = "xxxx.xx";
	@Input() permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	public form: UntypedFormGroup;
	public compareList: any;
	public popup_Confirm: boolean = false;
	public isHourlyPay: boolean = false;
	public isPaymentDaily: boolean = false;
	public currentPaymentType: number;
	public isChangePaymentType: boolean = false;

	public isCompare: boolean = false;
	public messageWarningType: number = MessageWarningType.MovementType;

	public movementTypeDDL: Dropdown[];
	public movementTypeDDLCondition: Dropdown[];
	public incomeAmount: number = 0;
	public oldIncomeAmount: number = 0;
	public confirmation: any;
	public saveConfirmation: any;
	public employeeFinancial: any;
	public editType: string = "2";
	public editTypeOption: SelectItem[] = [
		{ label: "common_record", value: "2" },
		{ label: "common_edit_wrong", value: "1" },
	];
	public tabPermissionEditSalary: any;
	public presentYear: number = 0;
	public isApprove: boolean = true;
	public isESS009_T01: boolean = false;
	public nextApproval: string = "";
	public language: string = "";

	public indexTab: number = 0;
	public isPAY001T17: any;
	public fieldsDDL = {
		text: "name",
		value: "id",
		iconClass: "value",
	};
	public fieldsDDL_EN = {
		text: "name_EN",
		value: "id",
		iconClass: "value",
	};
	public fieldsBankDDL = {
		text: "detail",
		value: "bankAccountNo",
		iconClass: "icon",
		detail: "",
	};
	public fieldsBankDDL_EN = {
		text: "detail_EN",
		value: "bankAccountNo",
		iconClass: "icon",
		detail: "",
	};
	private currentUserInfo$: Subscription;
	private language$: Subscription;
	public userInfo: any = null;
	mfaToken: string = null;

	get infoBarType() {
		return InfoBarType;
	}

	constructor(
		private userControlService: UserControlService,
		public data: DataSharingService,
		private employeeMovementsService: EmployeeMovementsService,
		private fb: UntypedFormBuilder,
		private workflowService: WorkflowService,
		private router: Router,
		private translate: TranslateService,
		private employeeMovementService: EmployeeMovementService,
		private employeeWorkInformationService: EmployeeWorkInformationService,
		private commonService: CommonService,
		private employeeFinancialService: EmployeeFinancialService,
		private _dialogService: DialogService,
		private _toastHelperService: ToastHelperService,
	) {}
	ngOnInit() {
		this.dtOptions = {
			processing: false,
			searching: false,
			paging: false,
			info: false,
			columnDefs: [{ orderable: false, targets: "_all" }],
		};

		firstValueFrom(this.userControlService.authorizeControl(Module.PAY001_T17)).then((auth) => {
			this.isPAY001T17 = auth;
		});
		this.form = this.fb.group({
			bankId: new UntypedFormControl(""),
			bankAccountNo: new UntypedFormControl(""),
			isCash: new UntypedFormControl("", Validators.required),
			paymentMethod: new UntypedFormControl("", Validators.required),
			incomeAmount: new UntypedFormControl(0.0),
			editType: new UntypedFormControl(""),
			movementTypeId: new UntypedFormControl(""),
			remarks: new UntypedFormControl(""),
			dateEffective: new UntypedFormControl(new Date()),
			dateDocument: new UntypedFormControl(""),
			companyBankAccountNo: new UntypedFormControl(""),
		});
		this.subscription = this.data.currentEmpId.subscribe((data) => {
			this.id = data;
			if (this.id == 0) {
				this.router.navigate(["/employee"]);
			} else {
				this.loadPermission();
				this.callAPI();
			}
		});
		this.currentUserInfo$ = this.data.currentUserInfo.subscribe((user) => {
			this.userInfo = user;
		});
		this.presentYear = Number(this.commonService.getPresentYear());

		this.language$ = this.data.currentLanguage.subscribe((lang) => {
			this.language = lang;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.isActive2FA && changes?.isShowSalary?.currentValue) {
			this.getEmployeeFinancials();
		}
	}

	async loadPermission() {
		await firstValueFrom(this.userControlService.authorizeControl(Module.PAY001_T17)).then((auth) => {
			this.tabPermissionEditSalary = auth["allowGrant"];
		});
		await firstValueFrom(this.userControlService.authorizeControl(Module.ESS009_T01)).then((auth) => {
			this.isESS009_T01 = auth["allowGrant"];
			if (!this.isESS009_T01) this.isApprove = false;
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) this.subscription.unsubscribe();
		if (this.language$) this.language$.unsubscribe();
		if (this.currentUserInfo$) this.currentUserInfo$.unsubscribe();
	}

	onIsCashChange(e) {
		if (e.value) {
			this.form.get("bankId").clearValidators();
			this.form.get("bankAccountNo").clearValidators();
		} else {
			this.form.get("bankId").setValidators(Validators.required);
			this.form.get("bankAccountNo").setValidators(Validators.required);
		}
		this.form.get("bankId").updateValueAndValidity();
		this.form.get("bankAccountNo").updateValueAndValidity();
	}

	callAPI() {
		this.commonService.getDDLProcessActionByModule(ProcessAction.employeeModule).then((res) => {
			this.movementTypeDDL = res;
		});

		this.employeeMovementsService.getEmployeeById(this.id).then((emp) => {
			this.employee = emp;
			if (this.employee.bankPaymentType == 3) {
				this.paymentAccountDropDown = true;
			}
			this.currentPaymentType = this.employee.paymentType;
			this.isPaymentDaily = this.currentPaymentType == PaymentType.daily;
			this.oldDataOfEmployee = { ...emp };
			this.onIsCashChange({ value: this.employee.isCash });
			this.employeeFinancialService
				.getEmployeeFinancialByEmployeeIdAndCategoryAndCompanyId(
					FinancialCategory.baseSalary,
					this.employee.companyId,
					this.id,
				)
				.then((res) => {
					if (isNullOrUndefined(res)) {
						var model = this.employee;
						model["financialCategoryId"] = FinancialCategory.baseSalary;
						model["amount"] = 0;
						firstValueFrom(this.employeeFinancialService.addEmployeeFinancial(model)).then();
					}
					this.getEmployeeFinancials();
				});

			this.commonService.getDDLMasterSetupById(MasterSetup.bank).then((res) => {
				this.bankCodeDDL = res;
				this.bankCodeId = !isNullOrUndefined(this.employee.bankCodeId)
					? res.find((e) => e.id == this.employee.bankCodeId)
					: null;
			});
			this.commonService.getDDLSysBanksByCompanyId(this.employee.companyId).then((res) => {
				this.sysBankDDL = res;
				this.bankObj = !isNullOrUndefined(this.employee?.bankId)
					? res.find((e) => e.id == this.employee?.bankId)
					: null;
			});
			this.commonService.getDDLPaymentMethod().then((res) => {
				this.paymentMethodDDL = res;
				this.paymentMethod = this.employee.paymentMethod;
			});
			this.commonService.getDDLPaymentAccount(this.employee.companyId, this.employee?.bankId).then((res) => {
				this.paymentAccount = res.data;
				let checkBankNoExist = this.paymentAccount.find((e) => e.bankAccountNo == this.employee.companyBankAccountNo);
				if (isNullOrUndefined(checkBankNoExist)) {
					this.employee.companyBankAccountNo = "";
				}
			});
			const el = document.getElementsByClassName("p-dialog-content")[0];
			if (el != null) el.scrollTop = 0;
		});
	}

	selectBank() {
		this.commonService.getDDLPaymentAccount(this.employee.companyId, this.employee?.bankId).then((res) => {
			this.paymentAccount = res.data;
		});
	}

	showSalary() {
		this.isShowSalary = !this.isShowSalary;
	}

	onLoadEmpty(event) {
		if (event) {
			this.isLoadEmpty = false;
		}
	}

	public isEditAmount: boolean = false;
	public isIncomeAdjust: boolean = false;
	public isAdjustingSalary: boolean = false;
	save() {
		this.updateValidator();
		let compareData = new Employee();
		compareData.employeeId = this.id;
		compareData.companyId = this.employee.companyId;
		compareData.bankId = this.employee.isCash ? null : this.employee.bankId;
		compareData.bankAccountNo = this.employee.isCash ? null : this.employee.bankAccountNo;
		compareData.isCash = this.employee.isCash;
		compareData.paymentMethod = !isUndefined(this.paymentMethod) ? this.paymentMethod : this.employee.paymentMethod;
		compareData.incomeAmount = !isNullOrUndefined(this.incomeAmount) ? this.incomeAmount : this.employee.incomeAmount;
		compareData.companyBankAccountNo = this.employee.companyBankAccountNo ? "" : this.employee.companyBankAccountNo;
		firstValueFrom(this.employeeWorkInformationService.CompareEmployeePayrollDetail(compareData)).then((res) => {
			let apiResult = <ApiResult>res;
			this.compareList = apiResult.data;
			if (apiResult.result) {
				this.isCompare = apiResult.data.length > 0;
				if (this.compareList.length > 0) {
					this.isEditType = false;
					this.isSubmitted = false;
					this.editType = "2";
					this.updateValidator();
					if (
						(this.oldDataOfEmployee.paymentMethod != compareData.paymentMethod &&
							this.oldIncomeAmount == compareData.incomeAmount) ||
						(this.oldIncomeAmount != compareData.incomeAmount && this.oldIncomeAmount != 0)
					) {
						this.popup_Confirm = true;
					} else {
						this.popup_Confirm = false;
						this.isEditType = true;
						this.editType = "1";
						this.updateValidator();
						this.onSubmitCompare();
					}

					if (this.oldIncomeAmount != compareData.incomeAmount) {
						this.movementTypeDDLCondition = this.movementTypeDDL.filter((r) => r.id == Movement.raiseTheSalary);
						this.movementTypeId = Movement.raiseTheSalary;
						this.form.controls["movementTypeId"].setValue(this.movementTypeId);
						this.isEditAmount = true;
						this.isIncomeAdjust = true;
					} else {
						this.movementTypeDDLCondition = this.movementTypeDDL.filter((r) => r.id == Movement.conditions);
						this.movementTypeId = Movement.conditions;
						this.form.controls["movementTypeId"].setValue(this.movementTypeId);
						this.isEditAmount = false;
						this.isIncomeAdjust = false;
					}
				} else {
					this.isEditType = true;
					this.editType = "1";
					this.updateValidator();
					this.onSubmitCompare();
				}
			}
			this.loadWorkflow();
		});
	}
	public get dateFormat() {
		return DateFormat;
	}
	workflowStep: any;
	get EditType() {
		return EditType;
	}
	loadWorkflow() {
		let searchWorkflow = new WorkflowSearch();
		searchWorkflow.moduleId = ModuleWorkflow.Movement;
		searchWorkflow.referenceId = ProcessAction.raiseTheSalary;
		searchWorkflow.tenantId = this.userInfo.tenantId;
		searchWorkflow.userId = this.employee.userId;
		searchWorkflow.isGroup = true;
		this.workflowService.getWorkflowStepNoCore(searchWorkflow).then((res) => {
			this.workflowStep = res.data.data;
			if (this.workflowStep.length > 1) {
				this.nextApproval =
					this.language == "th-TH" ? this.workflowStep[1]?.employeeName : this.workflowStep[1]?.employeeName_EN;
			} else {
				this.nextApproval = this.translate.instant("common_no_approval");
			}
		});
	}
	isLoadInfoBar: boolean = false;
	onSubmitCompare() {
		this.isAdjustingSalary = false;
		this.isLoadInfoBar = true;
		this.isSubmitted = true;
		if (!this.form.valid) {
			this._toastHelperService.validation({
				title: this.translate.instant("fields_missing"),
			});
		} else {
			this.confirmation = this.translate.instant("common_confirmation");
			this.saveConfirmation = this.translate.instant("common_save_confirmation");

			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.saveConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.popup_Confirm = false;
					this.employeeFinancial.amount = Number(this.incomeAmount).toFixed(2);
					let modelData = {
						employeeId: this.id,
						employeeNo: this.employee.employeeNo,
						incomeAmount: Number(this.incomeAmount).toFixed(2),
						bankId: this.employee.bankId,
						bankAccountNo: this.employee.bankAccountNo,
						isCash: this.employee.isCash,
						paymentMethod: !isUndefined(this.paymentMethod) ? this.paymentMethod : this.employee.paymentMethod,
						companyId: this.employee.companyId,
						orgLevelOne: this.employee.orgLevelOne,
						orgLevelTwo: this.employee.orgLevelTwo,
						orgLevelThree: this.employee.orgLevelThree,
						orgLevelFour: this.employee.orgLevelFour,
						orgLevelFive: this.employee.orgLevelFive,
						orgLevelSix: this.employee.orgLevelSix,
						bankCodeId: this.employee.bankCodeId,
						calendarId: this.employee.calendarId,
						cardCode: this.employee.cardCode,
						dateEmployment: !isNullOrUndefined(this.employee.dateEmployment)
							? new Date(this.employee.dateEmployment)
							: null,
						dateTermination: !isNullOrUndefined(this.employee.dateTermination)
							? new Date(this.employee.dateTermination)
							: null,
						employeeStatus: this.employee.employeeStatus,
						employmentType: this.employee.employmentType,
						isAttendance: this.employee.isAttendance,
						positionId: this.employee.positionId,
						isApprove: this.isESS009_T01 ? this.isApprove : false,
						rankNo: this.employee.rankNo,
						replaceEmployeeId: this.employee.replaceEmployeeId,
						upperSupervisorId: this.employee.upperSupervisorId,
						isReplaceEmployee: this.employee.isReplaceEmployee,
						compareDataList: this.compareList,
						companyBankAccountNo: this.employee.companyBankAccountNo,
					};
					if (this.editType == "2") {
						// Movement
						modelData["movementTypeId"] = this.movementTypeId;
						modelData["editType"] = this.editType;
						modelData["remarks"] = this.form.controls["remarks"].value;
						modelData["dateEffective"] = this.form.controls["dateEffective"].value;
						modelData["isIncomeAdjust"] = this.isIncomeAdjust;
						modelData["isWorkInfomation"] = true;
						modelData["isMovement"] = true;
						firstValueFrom(this.employeeMovementService.addEmployeeMovementTransaction(modelData)).then((res2) => {
							const apiResult = <ApiResult>res2;
							modelData["employeeFinancials"] = this.employeeFinancial;
							if (apiResult.result) {
								this.form.reset();
								this.callAPI();
								this.closeDialog();
								this.isAdjustingSalary = true;

								this._toastHelperService.success({
									title: this.translate.instant("toast_title_success"),
									description: this.translate.instant("toast_movement_create_success"),
								});

								this.isLoadInfoBar = false;
							} else {
								this._toastHelperService.alertApiResult(apiResult);
							}
						});
					} else {
						modelData["employeeFinancials"] = this.employeeFinancial;
						firstValueFrom(this.employeeWorkInformationService.updateEmployeeIsCash(modelData)).then((res) => {
							const apiResult = <ApiResult>res;
							if (apiResult.result) {
								this.form.reset();
								this.callAPI();
								this.closeDialog();
								this.isAdjustingSalary = true;
							}
							this._toastHelperService.alertApiResult(apiResult);
						});
					}
				}
			});
		}
	}
	public isEditType: boolean = false;
	public isSubmitted: boolean = false;
	onChangeEditType(e) {
		this.isEditType = e.value;
		if (this.isEditType) {
			this.editType = "1";
		} else {
			this.editType = "2";
		}
		this.updateValidator();
	}

	onChangeIsApprove(e) {
		this.isApprove = e.value;
	}

	updateValidator() {
		if (this.editType == "2") {
			// Movement
			this.form.controls["dateEffective"].setValidators(Validators.required);
			this.form.controls["dateEffective"].updateValueAndValidity();
		} else {
			this.clearValidators();
		}
	}
	clearValidators() {
		this.form.controls["dateEffective"].clearValidators();
		this.form.controls["remarks"].clearValidators();

		this.form.controls["remarks"].updateValueAndValidity();
		this.form.controls["dateEffective"].updateValueAndValidity();
	}
	onClear() {
		this.form.reset();
		this.callAPI();
		this.closeDialog();
	}
	closeDialog() {
		this.popup_Confirm = false;
		this.clearValidators();
	}
	checkTypePayment(event) {
		this.isHourlyPay = event.selectedItem.value == "True"; // isHourlyPay
		const paymentType = event.selectedItem.dataId;
		this.isPaymentDaily = paymentType == PaymentType.daily; // payment daily
		this.isChangePaymentType = this.currentPaymentType != null && paymentType != this.currentPaymentType;
	}

	handleChange(event) {
		this.indexTab = event.index;
	}

	backToProfile() {
		this.data.setEmpId(this.id);
		this.router.navigate([`/employee/${this.id}`]);
	}

	gotoManual() {
		window.open(
			"https://help.empeo.com/th/article/4lib4liy4lij4lma4lib4lil4li14lmi4lii4liz4lib4lij4liw4lma4lig4lix4lib4liy4lij4lii4lmi4liy4lii4lma4lih4li04liz4lij4liw4lir4lin4lmi4liy4lih4lih4lin4liu-1193zou/",
		);
	}

	getEmployeeFinancials() {
		this.mfaToken = sessionStorage.getItem(SessionStorageKey.TwoFA);
		const searchModel = {
			mfaToken: this.mfaToken,
		};
		this.employeeFinancialService.getEmployeeFinancials(this.id, searchModel).then((res) => {
			const apiResult = <ApiResult>res;
			if (apiResult?.status?.code == BusinessCode.Successful) {
				const baseSalary = res.data?.incomes?.find((income) => income.category === FinancialCategory.baseSalary);
				this.incomeAmount = baseSalary?.amount ?? 0;
				this.oldIncomeAmount = this.incomeAmount;
				const employeeFinancialModel = {
					amount: this.incomeAmount,
					financialItemId: baseSalary?.financialItemId,
					employeeId: this.id,
					companyId: this.employee.companyId,
				};
				this.employeeFinancial = employeeFinancialModel;
			}
		});
	}
}
