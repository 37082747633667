import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment";

export const authConfig: AuthConfig = {
	// Url of the Identity Provider
	issuer: environment.issuer,
	requireHttps: true,

	// URL of the SPA to redirect the user to after login
	redirectUri: environment.redirectUri,

	// URL of the SPA to redirect the user after silent refresh
	postLogoutRedirectUri: environment.redirectUri,
	logoutUrl: environment.issuer,
	// The SPA's id. The SPA is registerd with this id at the auth-server
	clientId: environment.clientId,
	dummyClientSecret: "c305f8a6609593d7cdd9c01ca7b25c334374a4a821fe",
	// set the scope for the permissions the client should request
	// The first three are defined by OIDC. The 4th is a usecase-specific one
	scope: "openid profile offline_access IMC.API Venio2.API GOFIVE.API ClientPortal.API",
	showDebugInformation: false,
	clearHashAfterLogin: true,
	customQueryParams: null,
};
