import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AssessmentSet, AssessmentSetEmployeeConditions, EmployeeAssessmentScoreFilter } from "./assessment-set.model";
import { AssessmentQuestionTemplateSearchModel } from "../../shared/assessment.model";
import { firstValueFrom } from "rxjs";
import { SharingService } from "src/app/shared/sharing.service";

@Injectable()
export class AssessmentSetService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private sharing: SharingService,
	) {}

	addAssessmentSet(assessmentSet: AssessmentSet) {
		var body = JSON.stringify(assessmentSet);

		return this.http.post(this.apiUrl + "AssessmentSet/AddAssessmentSet", body, this.httpOptions);
	}

	editAssessmentSet(assessmentSet: AssessmentSet) {
		var body = JSON.stringify(assessmentSet);

		return this.http.post(this.apiUrl + "AssessmentSet/EditAssessmentSet", body, this.httpOptions);
	}

	duplicateAssessmentSet(setId: number) {
		var body = JSON.stringify(setId);
		return this.http.post(this.apiUrl + "AssessmentSet/DuplicateAssessmentSet", body, this.httpOptions);
	}

	deleteAssessmentSet(setId: number) {
		var body = JSON.stringify(setId);
		return this.http.post(this.apiUrl + "AssessmentSet/DeleteAssessmentSet", body, this.httpOptions);
	}

	getAllAssessmentSetsByAssessmentTypeId(assessmentTypeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentSet/GetAllAssessmentSetsByAssessmentTypeId/" + assessmentTypeId),
		).then((res) => <AssessmentSet[]>res);
	}

	getEmployeeAssessmentScore(model: EmployeeAssessmentScoreFilter) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "AssessmentScore/GetEmployeeAssessmentScore", body, this.httpOptions);
	}

	getAssessmentScoreChartScoreAnalysis(model: EmployeeAssessmentScoreFilter) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "AssessmentScore/GetAssessmentScoreChartScoreAnalysis", body, this.httpOptions);
	}

	getAssessmentScoreChartDistribution(model: EmployeeAssessmentScoreFilter) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "AssessmentScore/GetAssessmentScoreChartDistribution", body, this.httpOptions);
	}

	getCycleAssessmentSetsByAssessmentTypeId(assessmentTypeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentSet/GetCycleAssessmentSetsByAssessmentTypeId/" + assessmentTypeId),
		).then((res) => res);
	}

	getAssessmentSetsByAssessmentSetsId(setId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentSet/GetAssessmentSetsByAssessmentSetsId/" + setId),
		).then((res) => <any>res);
	}

	getAssessmentSetDetailBySetId(setId: number, isDuplicate: boolean = false) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "assessments/sets?setId=" + setId + "&isDuplicate=" + isDuplicate),
		).then((res) => <any>res);
	}

	addAssessmentSetDetail(model: AssessmentSet) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "assessments/sets", body, this.httpOptions);
	}

	addAssessmentSetDetailProbation(model: AssessmentSet) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "assessments/sets/probations", body, this.httpOptions);
	}

	resendProbationAssessment(model: AssessmentSetEmployeeConditions) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "assessments/probation/scores/resend", body, this.httpOptions);
	}

	deleteProbationAssessment(no: number, employeeId: number) {
		return this.http.delete(this.apiUrl + "assessments/probation/scores/assessee/" + employeeId + "/no/" + no);
	}

	getAssessmentConditionResend(employeeId: number) {
		return this.http.get<any>(
			this.apiUrl + "assessments/sets/probation/condition/detail/" + employeeId,
			this.httpOptions,
		);
	}

	getAllAssessmentQuestionTemplates(model: AssessmentQuestionTemplateSearchModel) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "questions/templates", body, this.httpOptions);
	}

	getDDLAssessmentType() {
		return this.http.get<any[]>(this.apiUrl + "AssessmentMaster/GetDDLAssessmentType", this.httpOptions);
	}

	getAssessmentMasterByAssessmentSetName(name: string, no: number = 0) {
		return this.http.get<any>(this.apiUrl + `assessments/sets/${name}/${no}`, this.httpOptions);
	}

	checkSalaryAdjustmentApproved(masterSetId: number) {
		return this.http.get<any>(
			this.apiUrl + `v1/movements/salary-adjustment/check-approval?masterSetId=${masterSetId || 0}`,
			this.httpOptions,
		);
	}

	getEmployeeKpi(kpiBody: any) {
		var body = JSON.stringify(kpiBody);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(`${this.apiUrl}kpi/objectives`, body, httpOptions);
	}

	getEmployeeOkr(okr: any) {
		return this.http.get(
			`${this.apiUrl}v1/employees/${okr.employeeId}/okr/summaries${this.sharing.getQueryString(okr)}`,
		);
	}
}
