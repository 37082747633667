import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { Observable, firstValueFrom, throwError } from "rxjs";
import { catchError, finalize, first, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { isNullOrUndefined } from "../shared/sharing.service";
import { DataSharingService } from "./data-sharing.service";
import { ToastHelperService } from "../shared/service/toast-helper.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private authService: AuthService;
	private dialogService: DialogService;
	private callCount = 0;
	private _translate!: TranslateService;

	public get translate(): TranslateService {
		if (typeof this._translate === "undefined") {
			this._translate = this._injector.get(TranslateService);
		}
		return this._translate;
	}

	private _toastHelperService!: ToastHelperService;
	public get toastHelperService(): ToastHelperService {
		if (typeof this._toastHelperService === "undefined") {
			this._toastHelperService = this._injector.get(ToastHelperService);
		}
		return this._toastHelperService;
	}

	private phrase = /api.phrase.com\//;
	private storage = /storage.googleapis.com\//;
	private generatePictureUrl = /GeneratePictureUrl/;
	private client = new RegExp(`\\b${environment.clientPortal}\\b`);
	private noCounting: RegExp[] = [
		/ImportLeaveRequestExcel/,
		/Announcements\/Web/,
		/\/Acknowledge/,
		this.storage,
		/phrase.gofive.co.th/,
		/GeneratePictureUrl/,
		/storage/,
		/D365\/GetUserById/,
		this.client,
	];
	private noAlertModal: RegExp[] = [/api\/Subscriptions\/current/];

	constructor(
		public data: DataSharingService,
		private injector: Injector,
		private route: ActivatedRoute,
		private _injector: Injector,
	) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		//#region Condition Counting
		let errorModal = this.noAlertModal.every((e) => !request.url.match(e));
		let conditionCounting = this.noCounting.every((e) => !request.url.match(e));
		let isPhrase = !isNullOrUndefined(request.url.match(this.phrase));
		//#endregion

		if (conditionCounting) {
			this.callCount++;
			this.data.setLoading(true);
		}

		if (this.getAuthService().hasValidToken()) {
			if (this.storage.exec(request.url)) {
				if (
					localStorage.getItem("storageEncryptionKey") != "null" &&
					!isNullOrUndefined(localStorage.getItem("storageEncryptionKey"))
				) {
					if (request.method.toLowerCase() == "get") {
						if (request.headers.get("isAllCompany") == "false") {
							request = request.clone({ responseType: "blob", headers: this.data.headerGCP });
						} else {
							request = request.clone({ responseType: "blob", headers: new HttpHeaders({}) });
						}
					}
				}
			} else if (request.url.search(environment.gofiveCoreWeb.assetUrl) > -1) {
				request = request.clone({ responseType: "blob", headers: new HttpHeaders({}) });
			} else if (this.generatePictureUrl.exec(request.url)) {
				request = request.clone({ headers: request.headers.set("Content-Type", "application/json") });
			} else {
				if (!request.headers.has("Content-Type")) {
					request = request.clone({ headers: request.headers.set("Content-Type", "application/json") });
				}
				request = request.clone({ headers: request.headers.set("Accept", 'application/json;profile="CamelCase+TZ"') });
				request = request.clone({ setHeaders: { Authorization: "Bearer " + this.getAuthService().accessToken } });
			}
		} else {
			if (this.storage.exec(request.url)) {
				if (
					localStorage.getItem("storageEncryptionKey") != "null" &&
					!isNullOrUndefined(localStorage.getItem("storageEncryptionKey"))
				) {
					if (request.method.toLowerCase() == "get") {
						if (request.headers.get("isAllCompany") == "false") {
							request = request.clone({ responseType: "blob", headers: this.data.headerGCP });
						} else {
							request = request.clone({ responseType: "blob", headers: new HttpHeaders({}) });
						}
					}
				}
			}

			if (sessionStorage.getItem("access_token")) {
				const access_token = sessionStorage.getItem("access_token");
				request = request.clone({ setHeaders: { Authorization: "Bearer " + access_token } });
			} else {
				this.route.queryParams.pipe(first()).subscribe((params: Params) => {
					if (!jQuery.isEmptyObject(params) && !isNullOrUndefined(params["token"])) {
						if (!request.url.match(this.storage)) {
							request = request.clone({ setHeaders: { Authorization: "Bearer " + params["token"] } });
						}
					}
				});
			}
		}

		if (this.getAuthService() && isPhrase) {
			request = request.clone({ headers: request.headers.set("Authorization", environment.phrase.token) });
		}

		return next.handle(request).pipe(
			// Log when response observable either completes or errors
			catchError((error: HttpErrorResponse) => {
				if (errorModal) {
					this.openDialog(error);
				}

				return throwError(() => error);
			}),
			map((result: any) => {
				this.checkStatus(result.status);
				return result.status == 202 ? throwError(() => result.message ?? result) : result;
			}),
			finalize(() => {
				conditionCounting && this.callCount--;
				this.callCount < 1 && this.data.setLoading(false);
				this.data.setDetectionChange();
			}),
		);
	}

	private getAuthService(): AuthService {
		if (typeof this.authService === "undefined") {
			this.authService = this.injector.get(AuthService);
		}
		return this.authService;
	}
	private checkStatus(statusCode: number) {
		if (statusCode == 202) {
			this._toastHelperService.alertError202();
		}
	}

	private getDialogService(): DialogService {
		if (typeof this.dialogService === "undefined") {
			this.dialogService = this.injector.get(DialogService);
		}
		return this.dialogService;
	}

	private openDialog(error: HttpErrorResponse): void {
		if (400 <= error.status && error.status < 500) {
			switch (error.status) {
				case 400:
					firstValueFrom(this.getDialogService().BadRequest()).then();
					break;
				case 401:
					firstValueFrom(this.getDialogService().Unauthorized()).then(() => {
						this.getAuthService().refresh();
					});
					break;
				default:
					const dialogModel = <DialogInformationModel>{
						title: this.translate.instant("alert_dialog_title_client"),
						description: this.translate.instant("alert_dialog_discription_client"),
						imageUrl: "status-code-error.png",
						textButtonConfirm: this.translate.instant("common_go_back"),
					};
					firstValueFrom(this.getDialogService().Error(dialogModel)).then();
					break;
			}
		} else if (500 <= error.status && error.status < 600) {
			switch (error.status) {
				case 500:
					firstValueFrom(this.getDialogService().ServerError()).then();
					break;
				case 502:
					const dialogModel502 = <DialogInformationModel>{
						title: this.translate.instant("alert_dialog_title_bad_gateway"),
						description: this.translate.instant("alert_dialog_description_bad_gateway"),
						imageUrl: "status-code-error.png",
						textButtonConfirm: this.translate.instant("common_go_back"),
					};
					firstValueFrom(this.getDialogService().Error(dialogModel502)).then();
					break;
				case 503:
					const dialogModel503 = <DialogInformationModel>{
						title: this.translate.instant("alert_dialog_title_service_unavailable"),
						description: this.translate.instant("alert_dialog_description_service_unavailable"),
						imageUrl: "status-code-error.png",
						textButtonConfirm: this.translate.instant("common_go_back"),
					};
					firstValueFrom(this.getDialogService().Error(dialogModel503)).then();
					break;
				default:
					firstValueFrom(this.getDialogService().Error()).then();
					break;
			}
		}
	}
}
