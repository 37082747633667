<div class="employee-SSO">
	@if (isHeader) {
		<div class="header-border-bottom" [ngClass]="{ 'full-template': fullTemPlate }">
			<div class="header-content">
				<div class="header-content-left">
					<p class="text-color-12 text-header-3 pl-2">
						{{ "emp001_social_security" | translate }}
					</p>
				</div>
			</div>
		</div>
	}
	<div class="content {{ isHeader ? '' : 'm-0' }}" [ngClass]="{ 'full-template': fullTemPlate }">
		<ng-template [ngTemplateOutlet]="content"></ng-template>
	</div>
	@if (footerContent) {
		<div
			class="ui-g-12 d-flex {{ footerContent ? 'absolute-footer' : '' }}"
			style="padding: 17px; align-items: center; justify-content: flex-end"
		>
			<div class="ui-g-6 text-right p-0">
				<span>
					<go5-button
						data-testid="button_button_cancel"
						class="mr-2"
						label="{{ 'common_cancel' | translate }}"
						category="secondary"
						size="medium"
						type="button"
						[disabled]="false"
						(click)="backToProfile()"
					></go5-button>
				</span>
				<span>
					<go5-button
						data-testid="button_button_save"
						label="{{ 'common_save' | translate }}"
						category="primary"
						size="medium"
						type="button"
						[disabled]="onCheckDataEmployeeSocialSecurity()"
						(click)="onSubmit()"
					></go5-button>
				</span>
			</div>
		</div>
	}
</div>

<ng-template #content>
	<div class="content-width emp-social-security">
		<div class="content-input pt-0">
			<div class="checkbox-sso">
				<go5-checkbox
					data-testid="input_checkbox_isSoc"
					[checked]="employeeSocialSecurity.isSoc"
					label="{{ 'emp001_t09_isSoc' | translate }}"
					(changed)="updateIsSoc()"
				>
				</go5-checkbox>
				@if (employeeSocialSecurity?.isSoc && employee?.age >= conditionAgeSOC) {
					<div class="pt-2">
						<go5-info-bar [type]="infoBarType.Info">
							{{ "emp001_t09_social_security_senior_exempt_infobar" | translate }}</go5-info-bar
						>
					</div>
				}
			</div>
		</div>

		<div class="content-input">
			<div class="content-input-right">
				<div>{{ "emp001_t09_social_security_no" | translate }}*</div>
				<div class="pt-2 pointer">
					<input
						data-testid="input_text_socialsecurityno"
						pInputText
						type="text"
						[(ngModel)]="employeeSocialSecurity.socialSecurityNo"
					/>
				</div>
			</div>
			<div class="content-input-left">
				<div>
					{{ "emp001_t09_typecard_sso_global" | translate }}
				</div>
				<div class="pt-2 pointer">
					<go5-dropdown-selection
						data-testid="dropdown_single_cardType"
						[mode]="'single'"
						[dataSource]="typeCardDDL"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
						[value]="employeeSocialSecurity.typeCardSSO"
						(selected)="onSelectCardType($event)"
						placeholder="{{ 'common_default_select' | translate }}"
						(clear)="clearCardType()"
						[height]="'40px'"
					>
					</go5-dropdown-selection>
				</div>
			</div>
		</div>

		<div class="content-input">
			<div class="content-input-left">
				<div>
					{{ "emp001_t09_nationality_sso" | translate }}
				</div>
				<div class="pt-2 pointer">
					<go5-dropdown-selection
						data-testid="dropdown_nationality_sso"
						[mode]="'single'"
						[dataSource]="nationalityDDL"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsNationalityDDL : fieldsNationalityDDL_EN"
						[value]="employeeSocialSecurity.nationalitySSOId"
						(selected)="onSelectNationality($event)"
						placeholder="{{ 'common_default_select' | translate }}"
						(clear)="clearNationality()"
						[height]="'40px'"
					>
					</go5-dropdown-selection>
				</div>
			</div>
			<div class="content-input-right">
				<div>
					{{ "common_first_choice_hospital" | translate }}
				</div>
				<div class="pt-2 pointer">
					<go5-dropdown-selection
						data-testid="dropdown_single_hospital"
						[mode]="'single'"
						[dataSource]="hospitalDDL"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsHospitalDDL : fieldsHospitalDDL_EN"
						[value]="employeeSocialSecurity.hospitalId"
						(selected)="onSelectHospital($event, 'hospitalId')"
						placeholder="{{ 'common_default_select' | translate }}"
						(clear)="clearHospital('hospitalId')"
						[height]="'40px'"
						(filtering)="onFilterHospital($event)"
					>
					</go5-dropdown-selection>
				</div>
			</div>
		</div>

		<div class="content-input">
			<div class="content-input-left">
				<div>
					{{ "common_second_choice_hospital" | translate }}
				</div>
				<div class="pt-2 pointer">
					<go5-dropdown-selection
						data-testid="dropdown_single_hospital"
						[mode]="'single'"
						[dataSource]="hospitalDDL"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsHospitalDDL : fieldsHospitalDDL_EN"
						[value]="employeeSocialSecurity.hospitalIdTwo"
						(selected)="onSelectHospital($event, 'hospitalIdTwo')"
						placeholder="{{ 'common_default_select' | translate }}"
						(clear)="clearHospital('hospitalIdTwo')"
						[height]="'40px'"
						(filtering)="onFilterHospital($event)"
					>
					</go5-dropdown-selection>
				</div>
			</div>
			<div class="content-input-right">
				<div>
					{{ "common_third_choice_hospital" | translate }}
				</div>
				<div class="pt-2 pointer">
					<go5-dropdown-selection
						data-testid="dropdown_single_hospital"
						[mode]="'single'"
						[dataSource]="hospitalDDL"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsHospitalDDL : fieldsHospitalDDL_EN"
						[value]="employeeSocialSecurity.hospitalIdThree"
						(selected)="onSelectHospital($event, 'hospitalIdThree')"
						placeholder="{{ 'common_default_select' | translate }}"
						(clear)="clearHospital('hospitalIdThree')"
						[height]="'40px'"
						(filtering)="onFilterHospital($event)"
					>
					</go5-dropdown-selection>
				</div>
			</div>
		</div>

		<div class="content-input">
			<div class="content-input-left">
				<div>
					{{ "emp001_t09_date_received_sso" | translate }}
				</div>
				<div class="pt-2 pointer">
					<go5-datepicker
						data-testid="input_calendar_datereceivedsso"
						[format]="dateFormat.dateShort"
						placeholder="{{ 'common_placeholder_date' | translate }}"
						[(ngModel)]="dateReceivedSSO"
						[isShowTime]="false"
						[allowShowRemove]="true"
					></go5-datepicker>
				</div>
			</div>
			<div class="content-input-right">
				<div style="display: flex; align-items: center">
					{{ "ess009_seperation_sso_reason" | translate }}
					<em
						class="gf-icon-empeo-information-vector"
						go5-tooltip-placement="top"
						go5-tooltip-mode="hover"
						go5-tooltip="{{ 'ess009_seperation_sso_reason_tooltip' | translate }}"
						style="cursor: pointer; padding: 3px 3px 0 3px"
					></em>
				</div>
				<div class="pt-2 pointer">
					<go5-dropdown-selection
						data-testid="dropdown_single_hospital"
						[mode]="'single'"
						[dataSource]="reasonToLeaveDDL"
						[value]="employeeSocialSecurity?.terminationReasonSSOId"
						[allowShowRemove]="
							employeeSocialSecurity?.terminationReasonSSOId != defaultReason &&
							employeeSocialSecurity?.terminationReasonSSOId != null
						"
						(selected)="onSelectReason($event)"
						(clear)="clearReason($event)"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
						placeholder="{{ 'common_please_choose' | translate }}"
						[height]="'40px'"
						[allowFiltering]="false"
					>
					</go5-dropdown-selection>
				</div>
			</div>
		</div>
		<div class="content-input">
			<div style="padding-right: 20px; width: 100%">
				<div class="go5-text-color-8">
					{{ "common_remark" | translate }}
				</div>
				<div class="pt-2">
					<go5-textarea
						category="primary"
						size="large"
						(valueChange)="changRemark($event)"
						placeholder="{{ ('common_fill' | translate) + ('common_remark' | translate) }}"
						[value]="employeeSocialSecurity.evidenceOfRegistrationSSO"
					></go5-textarea>
				</div>
			</div>
		</div>
		<div class="content-input">
			<div>
				<go5-checkbox
					data-testid="input_checkbox_securityBranch"
					class="go5-text-color-12"
					[checked]="isSetSocialSecurityBranch"
					label="{{ 'emp001_social_security_office_branch_checkbox' | translate }}"
					(changed)="onChangeSecurityBranch()"
				>
				</go5-checkbox>
			</div>
		</div>
		@if (isSetSocialSecurityBranch) {
			<div class="content-input" style="padding-top: 8px">
				<div class="content-input-left">
					<div>{{ "emp001_t09_branch_sso" | translate }}*</div>
					<div class="pt-2 pointer">
						<go5-dropdown-selection
							data-testid="dropdown_single_branch_sso"
							[mode]="'single'"
							[dataSource]="branchSSO_DDL"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsBranchSSODDL : fieldsBranchSSODDL_EN"
							[value]="employeeSocialSecurity.branchId"
							(selected)="selectedBranchId($event)"
							placeholder="{{ 'common_default_select' | translate }}"
							(clear)="clearBranchSSO()"
							[allowShowRemove]="true"
							[height]="'40px'"
						>
						</go5-dropdown-selection>
					</div>
				</div>
			</div>
		}
		<div class="content-input">
			<div>
				<div class="d-flex direction-row">
					<div style="display: flex; align-items: center">
						<go5-checkbox
							data-testid="input_checkbox_kortor20"
							class="go5-text-color-12"
							[checked]="isSetKorTor20"
							label="{{ 'emp001_kortor20_kor_checkbox' | translate }}"
							(changed)="onChangeKorTor20()"
						>
						</go5-checkbox>
						<em
							class="gf-icon-empeo-information-vector"
							go5-tooltip-placement="top"
							go5-tooltip-mode="hover"
							go5-tooltip="{{ 'emp001_kortor20_kor_tooltip' | translate }}"
							style="cursor: pointer; padding-left: 4px"
						></em>
					</div>
				</div>
			</div>
		</div>
		<form [formGroup]="form">
			@if (isSetKorTor20) {
				<div class="content-input" style="padding-top: 8px">
					<div class="content-input-left">
						<div>{{ "sys008_business_code" | translate }}*</div>
						<div class="pt-2 pointer">
							<go5-textfield
								data-testid="input_text_business_code"
								type="text"
								[(ngModel)]="branchDetail.businessCode"
								[placeholder]="branchDetail.businessCodeBranch"
								formControlName="businessCode"
							>
							</go5-textfield>
							<go5-error-message
								[error]="form.controls['businessCode'].invalid && form.controls['businessCode'].touched"
								[errorMessage]="
									'common_validate_please_fill' | translate: { fieldName: 'sys008_business_code_2' | translate }
								"
							>
							</go5-error-message>
						</div>
					</div>
					<div class="content-input-right">
						<div>{{ "sys008_insurance_contribution_rate" | translate }}*</div>
						<div class="pt-2 pointer">
							<go5-textfield
								data-testid="input_number_contribution_rate"
								type="number"
								[(ngModel)]="branchDetail.rate"
								[placeholder]="branchDetail.rateBranchStr"
								formControlName="rate"
								[format]="'0.0-2'"
								[step]="0.05"
								unit="%"
								min="0.1"
								max="1"
							>
							</go5-textfield>
							<go5-error-message
								[error]="form.controls['rate'].invalid && form.controls['rate'].touched"
								[errorMessage]="
									'common_validate_please_fill'
										| translate: { fieldName: 'sys008_insurance_contribution_rate_2' | translate }
								"
							>
							</go5-error-message>
						</div>
					</div>
				</div>
			}
		</form>
	</div>
</ng-template>
@if (!footerContent && permission?.allowEdit) {
	<div id="footer" class="footer-social-security bg-default">
		<ng-template [ngTemplateOutlet]="footer"></ng-template>
	</div>
}

<ng-template #footer>
	<div
		class="ui-g-12 d-flex {{ footerContent ? 'absolute-footer' : '' }}"
		style="padding: 17px; align-items: center; justify-content: flex-end"
	>
		<div class="ui-g-6 text-right p-0">
			<span>
				<go5-button
					data-testid="button_button_cancel"
					class="mr-2"
					label="{{ 'common_cancel' | translate }}"
					category="secondary"
					size="medium"
					type="button"
					[disabled]="false"
					(click)="backToProfile()"
				></go5-button>
			</span>
			<span>
				<go5-button
					data-testid="button_button_save"
					label="{{ 'common_save' | translate }}"
					category="primary"
					size="medium"
					type="button"
					[disabled]="onCheckDataEmployeeSocialSecurity()"
					(click)="onSubmit()"
				></go5-button>
			</span>
		</div>
	</div>
</ng-template>
