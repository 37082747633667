import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SkillSetItem } from "./skill-set.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class SkillService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	tooltipTextSkillPosition(skill: number, availableSkill: number): string {
		if (availableSkill) {
			if (availableSkill < skill) {
				return "position_skill_expected_level_low";
			} else if (availableSkill == skill) {
				return "position_skill_expected_level_equal";
			} else if (availableSkill > skill) {
				return "position_skill_expected_level_high";
			}
		} else {
			return "position_skill_expected_level_none";
		}
	}
	colorSkillPosition(skill: number, availableSkill: number): string {
		if (availableSkill > 0) {
			if (availableSkill < skill) {
				return "#F26C44";
			} else if (availableSkill == skill) {
				return "#00C291";
			} else if (availableSkill > skill) {
				return "#7E4FFE";
			}
		} else {
			return "#BCBCC8";
		}
	}

	getDDLMasSkills() {
		return this.http.get<any>(this.apiUrl + "v1/MasSkill/GetMasSkills");
	}

	getRankSkills(rankNo) {
		return this.http.get<any>(`${this.apiUrl}ranks/${rankNo}/skills`);
	}

	getSkillLevel() {
		return this.http.get<any>(this.apiUrl + "v1/GetDDLSkillLevels");
	}

	getSkillLogsHistory(employeeId) {
		return this.http.get<any>(`${this.apiUrl}v1/profiles/${employeeId}/skills/histories`);
	}

	skillManagement(model: SkillSetItem) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "v1/mss/skill-management", body, httpOptions);
	}

	updateSkillManagements(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "v1/employee-skills", body, httpOptions);
	}

	exportEmployeeSkill(model, isNeededData: boolean = true) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(
			this.apiUrl + "v1/employee-skills/export-files?isNeededData=" + isNeededData,
			body,
			httpOptions,
		);
	}

	dowloadEmployeeSkillExel(fileName: string) {
		return this.http.get<any>(this.apiUrl + "v1/employee-skills/export-files/" + fileName);
	}

	getMasSkill(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "v1/MasSkill/masSkills", body, httpOptions);
	}

	saveMasSkill(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "v1/MasSkill/save", body, httpOptions);
	}

	getMasSkillById(skillId: number) {
		return this.http.get<any>(this.apiUrl + `v1/MasSkill/masSkill/${skillId}`);
	}

	deleteMasSkillById(skillId: number) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + `v1/MasSkill/delete/${skillId}`, null, httpOptions);
	}
	getEmployeeSkill(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "employee/skills", body, httpOptions);
	}
	getPositionSkillsById(positionId) {
		return firstValueFrom(this.http.get(this.apiUrl + `v1/positions/${positionId}/skills`)).then((res) => <any>res);
	}
}
