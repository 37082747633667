import { Injectable } from "@angular/core";

import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";

export class DiscardChangeModel {
	titleDialog: string = "cancel-edit-leave-type";
	isDeactive: boolean | Promise<boolean>;
	description: string = "common_edit_discard_changes_confirmation";
	imageUrl: string = "confirmation.png";
	textButtonConfirm: string = "common_confirm_discard";
	textButtonCancel: string = "common_keep_editing";
}

export interface DiscardChange {
	canDeactivate: () => DiscardChangeModel;
}

@Injectable({
	providedIn: "root",
})
export class PendingChangesGuard {
	constructor(
		private translate: TranslateService,
		private _dialogService: DialogService,
	) {}
	async canDeactivate(component: DiscardChange): Promise<boolean> {
		const disCM: DiscardChangeModel = component?.canDeactivate();
		return disCM["isDeactive"] ? true : this.openDialog(disCM);
	}

	async openDialog(disCM): Promise<boolean> {
		let isConfirm = false;
		const dialogModel = <DialogInformationModel>{
			title: this.translate.instant(disCM["titleDialog"]),
			description: this.translate.instant(disCM["description"]),
			imageUrl: disCM["imageUrl"],
			textButtonConfirm: this.translate.instant(disCM["textButtonConfirm"]),
			textButtonCancel: disCM["textButtonCancel"] ? this.translate.instant(disCM["textButtonCancel"]) : null,
		};

		await firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				if (disCM.textButtonConfirm == "common_ok_got_it") {
					isConfirm = false;
				} else {
					isConfirm = true;
				}
			} else {
				isConfirm = false;
			}
		});

		return isConfirm;
	}
}
