import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EditUserInfo } from "./edit-user-info.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { CheckboxRole } from "./checkbox-role.model";
import { firstValueFrom } from "rxjs";
import { SharingService } from "src/app/shared/sharing.service";

@Injectable()
export class User_InfoService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		@Inject("EMCONNECT_API_URL") private apiEmconnectUrl,
		@Inject("GOFIVE_API_URL") private apiCoreUrl: string,
		private sharing: SharingService,
	) {}

	editUserInfo(user: EditUserInfo) {
		var body = JSON.stringify(user);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "UserInfo/EditUserInfo", body, httpOptions);
	}

	getUserInfoById(userId: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "UserInfo/GetUserInfoById/" + userId)).then((res) => <any>res);
	}

	getUserInfoGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "UserInfo/GetUserInfoDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getDDLUserInfoStatus() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLStatus")).then((res) => <Dropdown[]>res);
	}

	getCheckboxRole() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "UserInfo/GetCheckboxRole")).then(
			(res) => <CheckboxRole[]>res,
		);
	}
	getRolesDDL() {
		return this.http.get<any>(this.apiEmconnectUrl + "Recruitment/GetRolesDDL");
	}

	getLanguageList() {
		return this.http.get<any>(this.apiUrl + "Common/GetLanguageList");
	}

	setLanguage(userInfo: any) {
		var body = JSON.stringify(userInfo);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "UserInfo/SetLanguage", body, httpOptions);
	}

	editUserManagement(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiEmconnectUrl + "Recruitment/EditUserManagement", body, httpOptions);
	}

	resetPassphrase(user: any) {
		var body = JSON.stringify({ userId: user });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiCoreUrl + "v1/Passphrase/Reset", body, httpOptions);
	}
	GetUserHistory(model) {
		return this.http.get<any>(this.apiCoreUrl + `v1/users/management/histories${this.sharing.getQueryString(model)}`);
	}
}
