import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DataSharingService } from "src/app/core/data-sharing.service";
import { DocumentStatus } from "../../enum/document-status.enum";
import { ThaiDatePipe } from "../../pipe/thai-date.pipe";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgStyle, AsyncPipe, DatePipe } from "@angular/common";
@Component({
	selector: "workflow-log",
	templateUrl: "./workflow-log.component.html",
	styleUrls: ["./workflow-log.component.scss"],
	standalone: true,
	imports: [NgStyle, NgxSkeletonLoaderModule, AsyncPipe, DatePipe, TranslateModule, ThaiDatePipe],
})
export class WorkflowLogComponent implements OnChanges {
	@Input() public isOrderStepNo: boolean = true;
	@Input() public workflow: any;
	@Input() public documentStatusId: number = 0;
	@Input() public isWaitingApproval: boolean = true;
	@Input() public isWebView: boolean = false;
	public isloadImage: boolean = true;
	public currentDate: Date = new Date();
	constructor(
		public translate: TranslateService,
		public data: DataSharingService,
	) {
		this.mapClass();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.workflow?.currentValue) {
			if (this.isOrderStepNo) this.workflow = this.workflow?.sort((a, b) => (a.stepNo < b.stepNo ? 1 : -1));

			if (
				this.documentStatusId == DocumentStatus.approved ||
				this.documentStatusId == DocumentStatus.completed ||
				this.documentStatusId == DocumentStatus.canceled ||
				this.documentStatusId == DocumentStatus.rejected ||
				!this.isWaitingApproval
			) {
				this.workflow = this.workflow?.filter((r) => r.processId > 0);
			}
			this.mapClass();
		}
	}
	mapClass() {
		this.workflow?.forEach((r) => {
			switch (r.processId) {
				case 2001:
					{
						r.classIcon = "gf-icon-add-general color-silver";
						r.processAction = "common_status_draft";
					}
					break;
				case 2002:
					{
						r.classIcon = "gf-icon-venio-assign-case color-light-primary";
						r.processAction = "common_status_send";
					}
					break;
				case 2003:
					{
						r.classIcon = "gf-icon-missed color-pewter";
						r.processAction = "common_status_cancel";
					}
					break;
				case 2005:
					{
						r.classIcon = "gf-icon-missed color-pewter";
						r.processAction = "common_rejected_document";
					}
					break;
				case 2004:
					{
						r.classIcon = "gf-icon-select-profile color-caribbean";
						r.processAction = "common_approved_document";
					}
					break;
				case 2006:
					{
						r.classIcon = "gf-icon-venio-unassign color-pewter";
						r.processAction = "common_returned_document";
					}
					break;
				case 2008:
					{
						r.classIcon = "gf-icon-select-profile color-caribbean";
						r.processAction = "common_acknowledge_document";
					}
					break;
				case 2009:
					{
						r.classIcon = "gf-icon-checked color-caribbean";
						r.processAction = "common_completed_document";
					}
					break;
				case 9001:
					{
						r.classIcon = "gf-icon-venio-assign-case go5-color-primary";
						r.processAction = "create_document";
					}
					break;
				case 9002:
					{
						r.classIcon = "gf-icon-venio-edit-circle go5-color-primary";
						r.processAction = "common_edit_document";
					}
					break;
				case 9003:
					{
						r.classIcon = "gf-icon-select-profile go5-color-success";
						r.processAction = "Paid_off";
					}
					break;
				case 9004:
					{
						r.classIcon = "gf-icon-select-profile go5-color-success";
						r.processAction = "Marked_as_Closed";
					}
					break;

				default: {
					r.classIcon = "gf-icon-waiting color-light-primary";
					r.processAction = "lev004_wait_for_approve";
				}
			}
		});
	}
	loadImg(event) {
		if (event) {
			this.isloadImage = false;
		}
	}
}
