<div class="ui-g-12 ui-g-nopad" [style]="!empHeader ? 'background-color: white; margin-bottom: 10px;' : ''">
	<div class="ui-g-12" style="padding: 0px">
		<div class="d-flex align-center content-space-between">
			<span class="go5-text-header-3 go5-text-color-12 pt-1 pb-2">
				{{ "emp001_t11_benefit" | translate }}
				@if (empHeader) {
					{{ empHeader }}
				}
			</span>

			<span class="d-flex g-16 mr-4">
				<go5-dropdown-selection
					#dropdownOrganizationFive
					[dataSource]="refYearDDL"
					[value]="yearId"
					[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
					(selected)="yearId = $event.value; loadData()"
					[allowFiltering]="false"
					[allowSelectAll]="false"
				>
					<ng-template #toggleTemplate>
						<go5-dropdown-filter-toggle
							[size]="'large'"
							[maxWidth]="'250px'"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
							[selectedItems]="dropdownOrganizationFive.selectedItems"
							[rotate]="dropdownOrganizationFive.isOpen"
							[allowShowRemove]="false"
						>
						</go5-dropdown-filter-toggle>
					</ng-template>
				</go5-dropdown-selection>
				<span [hidden]="leaveUsage.isAdjust == isAdjust || !isEdit">
					<go5-info-bar class="d-flex" [type]="InfoBarType.Info">
						{{ "emp001_t11_leave_switch_infobar" | translate }}
					</go5-info-bar>
				</span>
				@if (permission.allowEdit && displayBtnCalculate) {
					<go5-button
						label="{{ 'common_calculate' | translate }}"
						category="primary"
						size="medium"
						type="button"
						iconClass="gf-icon-empeo-time"
						(click)="calculate()"
					></go5-button>
				}
			</span>
		</div>
		<form [formGroup]="formLeaveUsage">
			<div class="ui-g-12 search-table-outter p-0 pt-2">
				<go5-table-empeo-basic [data]="leaveUsages" [sticky]="true" [topSticky]="60">
					<ng-template #headers>
						<th id="emp001_t11_leave_type" class="th-sm-2" style="width: 25%">
							<label class="text-left">{{ "emp001_t11_leave_type" | translate }}</label>
						</th>
						<th id="emp001_t11_total" class="th-sm-1 text-right" style="width: 15%">
							<label class="text-right">{{ "emp001_t11_total" | translate }}</label>
						</th>
						<th id="emp001_t11_carry" class="th-sm-1 text-right" style="width: 15%">
							<label class="text-center">{{ "emp001_t11_carry" | translate }}</label>
						</th>
						<th id="emp001_t11_used" class="th-sm-1 text-right" style="width: 15%">
							<label class="text-center">{{ "emp001_t11_used" | translate }}</label>
						</th>
						<th id="common_emergency_leave" class="th-sm-1 text-right" style="width: 15%">
							<label class="text-center">{{ "common_emergency_leave" | translate }}</label>
						</th>

						<th id="emp001_t11_expire" class="th-sm-1" style="width: 10%">
							<label class="text-left">{{ "emp001_t11_expire" | translate }}</label>
						</th>
						<th
							id="action"
							style="width: 5%"
							[style]="!permission.allowEdit || !displayEdit ? 'display: none;' : ''"
						></th>
					</ng-template>
					<ng-template #rows>
						@for (item of leaveUsages; track item; let i = $index) {
							<tr class="hover-content">
								<td class="text-left">
									{{ data.getIsTH(data.currentLanguage | async) ? item.leaveTypeName : item.leaveTypeName_EN }}
								</td>
								<td class="text-right">
									@if (item.isAdjust) {
										<span
											class="gf-icon-empeo-manual i-active"
											go5-tooltip-placement="top"
											go5-tooltip-mode="hover"
											go5-tooltip="{{ 'custom_leave_hover' | translate }}"
										></span>
									}
									{{ data.getIsTH(data.currentLanguage | async) ? item.totalBenefit_TH : item.totalBenefit_EN }}
								</td>
								<td class="text-right">
									{{ data.getIsTH(data.currentLanguage | async) ? item.benefitCarry : item.benefitCarry_EN }}
									<div>
										@if (item.dateCarryExpired) {
											<p class="go5-text-small go5-text-color-7">
												{{
													("emp001_t11_valid_untils" | translate) +
														" " +
														(item.dateCarryExpired | date: "dd/MM/yyyy" : (data.currentLanguage | async))
												}}
											</p>
										}
									</div>
								</td>
								<td class="text-right">
									{{
										data.getIsTH(data.currentLanguage | async)
											? item.totalUsedBenefitWithCarry_TH
											: item.totalUsedBenefitWithCarry_EN
									}}
								</td>
								<td class="text-right" style="padding: 12px 30px 12px 30px">
									{{ item.totalEmergency >= 1 ? item.totalEmergency + " " + ("common_times" | translate) : "-" }}
								</td>
								<td class="text-left">
									{{ data.getIsTH(data.currentLanguage | async) ? item.cutoff : item.cutoff_EN }}
								</td>
								@if (permission.allowEdit && displayEdit) {
									<td class="text-center">
										<em class="gf-icon-empeo-edit" (click)="edit(item)"></em>
									</td>
								}
							</tr>
						}
						@if (leaveUsages == null || leaveUsages?.length == 0) {
							<tr>
								<td colspan="7" class="no-data-available">{{ "common_no_data" | translate }}</td>
							</tr>
						}
					</ng-template>
				</go5-table-empeo-basic>
			</div>
		</form>
	</div>
</div>

@if (isEdit) {
	<app-leave-usage-custom-setting
		(hasCloseLeaveUsageSideBar)="closeSidebar($event)"
		(reloadData)="onReloadData($event)"
		[displayLeaveUsageCustomSetting]="isEdit"
		[leaveUsage]="this.leaveUsage"
		[isProfileLeaveUsage]="isEdit"
	>
	</app-leave-usage-custom-setting>
}
