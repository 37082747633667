<div class="d-grid gap-8">
	<label class="go5-text-header-3 go5-text-color-12">{{ "pay001_t03_accumulate_tab" | translate }}</label>

	<div style="display: flex; justify-content: flex-end">
		<div>
			<s-dropdown
				data-testid="dropdown_single_yearonchange"
				#sDropdownYear
				[dataSource]="DDLyear"
				[showTooltip]="false"
				[fields]="fieldsDDL"
				cssClass="filter-large-primary z-index-sDropdown"
				mode="single"
				[showClearButton]="false"
				(selected)="yearOnChange()"
				[allowFiltering]="false"
			>
			</s-dropdown>
		</div>
	</div>
	<div class="search-table-outter">
		<go5-table-empeo-basic [data]="accumulateConfigs">
			<ng-template #headers>
				<th class="th-sm-2 text-left" style="width: 50%">
					<label for="input">{{ "pay001_t03_description" | translate }}</label>
				</th>
				<th class="th-sm-2 text-right" style="width: 15%">
					<label for="input">{{ "pay001_t03_amount" | translate }}</label>
				</th>
				<th class="th-sm-2 text-right" style="width: 5%"><label for="input"></label></th>
				<th class="th-sm-1 text-left" style="width: 30%">
					<label for="input">{{ "common_modified_by" | translate }}</label>
				</th>
				<th class="th-sm-1">
					@if (permission.allowEdit) {
						<label for="input"></label>
					}
				</th>
			</ng-template>
			<ng-template #rows>
				@for (item of accumulateConfigs; track item; let i = $index) {
					<tr class="hover-content">
						<td class="text-left" style="text-align: left">{{ item.name }} ( {{ item.name_EN }} )</td>
						<td class="text-right" style="text-align: right">
							@if (isShowSalary) {
								<div>
									{{ item.amount | number: "1.2-2" }}
								</div>
							}
							@if (!isShowSalary) {
								<div>
									{{ maskAmount }}
								</div>
							}
						</td>
						<td></td>
						<td class="text-left" style="text-align: right">
							<p class="text-body-strong color-charcoal">
								{{ data.getIsTH(data.currentLanguage | async) ? item.fullName : item.fullName_EN }}
							</p>
							<p class="text-small color-porpoise">
								{{
									item.dateModified != null
										? (item.dateModified | date: "dd/MM/yyyy HH:mm" : (data.currentLanguage | async))
										: ""
								}}
							</p>
						</td>
						@if (permission.allowEdit) {
							<td class="center">
								<div class="ui-g-12 ui-g-nopad hover-icon">
									<button
										attr.data-testid="button_button_edit_{{ i }}"
										type="button"
										p-button
										class="p-button-icon-only my-document-edit-button icon-edit-primary label-mr-2"
										go5-tooltip-placement="top"
										go5-tooltip-mode="hover"
										go5-tooltip="{{ 'common_edit' | translate }}"
										(click)="edit(item?.accumulateId)"
										[disabled]="!permission.allowEdit"
										style="margin: auto; margin-top: 2px"
									></button>
								</div>
							</td>
						}
					</tr>
				}
			</ng-template>
		</go5-table-empeo-basic>
	</div>
</div>

<go5-sidebar
	[(visible)]="dialogDisplay"
	(closed)="onDialog(false)"
	[textHeader]="
		('pay001_t03_accumulate' | translate) + ' ' + (employeeAccumulate?.year > 0 ? employeeAccumulate?.year : '')
	"
	[padding]="'0px'"
	[isShowFooter]="false"
>
	<div class="ui-g-12 ui-g-nopad">
		<div class="ui-g-12 p-md-border-hidden border-left">
			<div class="ui-g-12">
				<label for="input">{{ "pay001_t03_category" | translate }}</label>
				<p class="text-body-strong color-charcoal">{{ employeeAccumulate?.name }}</p>
			</div>
			<div class="ui-g-12">
				<label for="input">{{ "pay001_t03_calculate_amount" | translate }} </label>
				@if (employeeAccumulate?.accumulateId == 0 || isShowSalary) {
					<p class="text-body-strong color-charcoal">฿{{ employeeAccumulate?.calculateAmount | number: "1.2-2" }}</p>
				}
				@if (employeeAccumulate?.accumulateId != 0 && !isShowSalary) {
					<p class="text-body-strong color-charcoal">
						{{ maskAmount }}
					</p>
				}
			</div>
			<div class="ui-g-12">
				<label for="input" class="pb-2 go5-text-color-8" style="display: block"
					>{{ "pay001_t03_initial_amount" | translate }} ({{ "pay001_t03_calculate_amount_detail" | translate }})</label
				>

				@if (employeeAccumulate?.accumulateId == 0 || isShowSalary) {
					<go5-textfield
						type="number"
						data-testid="input_number_initialamountfortax"
						category="primary"
						[disabled]="false"
						[min]="0"
						[step]="0.5"
						[disabled]="accumulateId == null"
						[(ngModel)]="employeeAccumulate.initialAmount"
					></go5-textfield>
				}

				@if (employeeAccumulate?.accumulateId != 0 && !isShowSalary) {
					<input
						data-testid="input_text_maskamount1"
						pInputText
						style="text-align: right"
						type="text"
						value="{{ maskAmount }}"
						disabled
					/>
				}
			</div>
			<div class="ui-g-12">
				<label for="input" class="pb-2 go5-text-color-8" style="display: block"
					>{{ "pay001_t03_initial_amount" | translate }} ({{ "pay001_t03_for_tax_calculate" | translate }})</label
				>

				@if (employeeAccumulate?.accumulateId == 0 || isShowSalary) {
					<go5-textfield
						type="number"
						data-testid="input_number_initialamountfortax"
						category="primary"
						[disabled]="false"
						[step]="0.5"
						[disabled]="accumulateId == null"
						[(ngModel)]="employeeAccumulate.initialAmountForTax"
						[min]="0"
					></go5-textfield>
				}

				@if (employeeAccumulate?.accumulateId != 0 && !isShowSalary) {
					<input
						data-testid="input_text_maskamount2"
						pInputText
						style="text-align: right"
						type="text"
						value="{{ maskAmount }}"
						disabled
					/>
				}
			</div>
		</div>

		<!-- footer dialog -->
		<div class="ui-g-12 footer-layout text-right">
			<button
				data-testid="button_button_cancel"
				pButton
				type="button"
				class="button-large-secondary mr-2"
				label="{{ 'common_cancel' | translate }}"
				(click)="loadForm()"
			></button>
			<button
				data-testid="button_button_save"
				pButton
				type="button"
				class="button-large-primary"
				label="{{ employeeAccumulate?.accumulateId != 0 ? ('common_save' | translate) : ('common_add' | translate) }}"
				(click)="save()"
				[disabled]="
					employeeAccumulate?.initialAmount == null ||
					employeeAccumulate?.initialAmountForTax == null ||
					accumulateId == null ||
					isPermissionEdit
				"
			></button>
		</div>
	</div>
</go5-sidebar>
