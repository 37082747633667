import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Position } from "./position.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { PositionSearch } from "src/app/shared/models/position.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class PositionService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	addPosition(pos: Position) {
		var body = JSON.stringify(pos);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Position/AddPosition", body, httpOptions);
	}

	editPosition(pos: Position) {
		var body = JSON.stringify(pos);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Position/EditPosition", body, httpOptions);
	}

	deletePosition(positionId: string[]) {
		var body = JSON.stringify(positionId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Position/DeletePosition", body, httpOptions);
	}

	getPositionGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Position/GetPositionDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getPositionById(positionId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Position/GetPositionById/" + positionId)).then(
			(res) => <Position>res,
		);
	}

	getPosition(model: PositionSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Position/GetPosition", body, httpOptions);
	}
}
