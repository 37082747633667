import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable()
export class AssessmentPercentileRanksService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	GetAssessmentPercentileRankByCompanyId(companyId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentPercentileRanks/GetAssessmentPercentileRankByCompanyId/" + companyId),
		);
	}

	AddAssessmentPercentileRank(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentPercentileRanks/AddAssessmentPercentileRank", body, httpOptions);
	}
}
