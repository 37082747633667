import {
	FromAddCard,
	EmployeeeCard,
	FromEditCard,
	FromCancel,
	FromAddPageEdit,
	FromUpdatStatus,
	FromDelete,
	CardSearch,
} from "./employee-cards.model";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeCardsService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeCard(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "EmployeeCard/GetEmployeeCard", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}
	getEmployeeCardHistory(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeCard/GetEmployeeCardHistory",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}
	getEmployeeCardHistoryAll(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeCard/GetEmployeeCardHistoryAll",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	employeeCardsById(cardCode: string, companyId: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeCard/GetEmployeeCardByCardCode/" + cardCode + "/" + companyId),
		).then((res) => <EmployeeeCard>res);
	}

	AddEmployeeCard(model: FromAddCard) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeCard/AddEmployeeCard", body, httpOptions);
	}

	EditEmployeeCard(model: FromEditCard) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeCard/EditEmployeeCard", body, httpOptions);
	}

	CancelEmployeeCard(model: FromCancel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeCard/CancelEmployeeCard", body, httpOptions);
	}

	AddPageEditEmployeeCard(model: FromAddPageEdit) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeCard/FromAddPageEdit", body, httpOptions);
	}

	UpdateStatusEmployeeCard(model: FromUpdatStatus) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeCard/UpdateStatus", body, httpOptions);
	}

	DeleteEmployeeCard(model: FromDelete) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeCard/DeleteEmployeeCard", body, httpOptions);
	}

	ExportExcelReport2(model: CardSearch) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeCard/ExportExcelReport2", body, httpOptions);
	}
}
