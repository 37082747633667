export enum WelfareCategory {
	all = 0,
	foodCoupon = 1,
	shiftAllowance = 2,
	holidayRefund = 3,
	diligenceAllowance = 4,
	holidayFoodRefund = 5,
	medicalExpenses = 6,
	advancesPayment = 7,
	EPP = 8,
	other = 9,
	WFH = 10,
}
export enum TypeCash {
	bank = 0,
	cash = 1,
}
