import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Role } from "./role.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class RoleService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getRoleId(roleId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Role/GetRoleId/" + roleId)).then((res) => <Role>res);
	}

	getRolePayrolls() {
		return firstValueFrom(this.http.get(this.apiUrl + "Role/GetRolePayrolls")).then((res) => res);
	}

	getRoles(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Role/GetRoles", body, httpOptions);
	}

	addRole(role: Role) {
		var body = JSON.stringify(role);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Role/AddRole", body, httpOptions);
	}

	editRole(role: Role) {
		var body = JSON.stringify(role);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Role/EditRole", body, httpOptions);
	}
}
