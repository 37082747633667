<two-factor-authentication
	*ngIf="isAuth"
	(authStatus)="receiveMessageAuth($event)"
	(closeStatus)="receiveMessageClose($event)"
></two-factor-authentication>
<ng-template [ngIf]="(isLoading$ | async) === false" [ngIfElse]="loadTemplate">
	@if (isMobile) {
		<ng-container *ngIf="displayEmployee; then contentDisplayEmployee; else content"></ng-container>
	} @else {
		<ng-container *ngTemplateOutlet="contentSidebar"></ng-container>
	}

	<ng-template #contentSidebar>
		<go5-sidebar
			*ngIf="isSidebarAdd"
			[(visible)]="isSidebarAdd"
			[textConfirmButton]="confirmButtonLabel"
			[textCancelButton]="'common_cancel' | translate"
			(confirm)="onSubmit()"
			padding="20px 24px"
			(cancel)="cancelSidebarMM()"
			(closed)="closeSidebarMM()"
		>
			<ng-template #headerTemplate>
				<div class="d-flex d-flex-center d-flex-between gap-8">
					<label class="go5-text-header-4 go5-text-color-12">{{ textHeader | translate }}</label>
					<div class="d-flex d-flex-center gap-8">
						@if (movementType == Movement.raiseTheSalary && masterSetId > 0 && movementTransactionId > 0) {
							<em
								data-testid="input_btn_delete"
								class="gf-icon-empeo-bin-vector pointer"
								go5-tooltip-placement="left"
								go5-tooltip-mode="hover"
								go5-tooltip="{{ 'common_delete' | translate }}"
								(click)="deleteMovementSalary()"
							>
							</em>
						}
						@if (selectEmp != null && movementType == Movement.raiseTheSalary) {
							<span
								[class]="isShowSalary ? 'gf-icon-close-eye' : 'gf-icon-eye'"
								class="pointer f-24 color-primary"
								(click)="isMobile ? (isShowSalary = !isShowSalary) : showSalary()"
								go5-tooltip-placement="top"
								go5-tooltip-mode="hover"
								go5-tooltip="{{
									(isShowSalary ? 'pay001_close_salary_tooltip' : 'pay001_show_salary_tooltip') | translate
								}}"
							></span>
						}

						<em class="gf-icon-close" (click)="closeSidebarMM()"></em>
					</div>
				</div>
			</ng-template>

			<ng-container [ngTemplateOutlet]="content"></ng-container>
		</go5-sidebar>
	</ng-template>
</ng-template>

<ng-template #loadTemplate>
	<ng-template [ngIf]="isMobile">
		<div class="layout-content-block">
			<div class="pt-3">
				<ngx-skeleton-loader class="d-flex" [theme]="{ margin: '0px', width: '40%', height: '40px', float: 'left' }">
				</ngx-skeleton-loader>
			</div>
			<div class="pt-4">
				<ngx-skeleton-loader class="d-flex" [theme]="{ margin: '0px', width: '100%', height: '100px', float: 'left' }">
				</ngx-skeleton-loader>
			</div>

			<div class="pt-4 mt-4">
				<ngx-skeleton-loader class="d-flex" [theme]="{ margin: '0px', width: '40%', height: '30px', float: 'left' }">
				</ngx-skeleton-loader>
			</div>

			<div class="pt-4">
				<ngx-skeleton-loader class="d-flex" [theme]="{ margin: '0px', width: '100%', height: '40px', float: 'left' }">
				</ngx-skeleton-loader>
			</div>
			<div class="pt-4">
				<ngx-skeleton-loader class="d-flex" [theme]="{ margin: '0px', width: '100%', height: '100px', float: 'left' }">
				</ngx-skeleton-loader>
			</div>
		</div>
	</ng-template>
</ng-template>
<ng-template #content>
	@if (isSelfResign) {
		<div class="ui-g-12 p-0 py-2 {{ isMobile ? 'px-3' : '' }}" *ngIf="checkMovement">
			<info-bar
				[type]="messageWarningType"
				[employeeId]="employee.employeeId"
				[movementType]="movementType"
				(movementWaitingEffective)="receiveMovementWaitingEffective($event)"
			>
			</info-bar>
		</div>
		<form [formGroup]="form">
			<div class="{{ isMobile ? 'p-3 pb-120' : '' }} mb-4 bg-default ">
				<div class="direction-column gap-16">
					<div class="direction-column gap-16">
						<!-- วันที่มีผล -->
						<div class="direction-column">
							<label class="go5-text-color-8 pb-1" style="display: block"
								>{{ "common_effective_date" | translate }}*
								<einfo-tooltip
									key="resignation_request_effective_date_tooltip"
									position="{{ isMobile ? 'right' : 'top' }}"
									size="s"
								>
								</einfo-tooltip>
							</label>
							<go5-datepicker
								data-testid="input-effective-date"
								[format]="DateFormat.dateLong"
								[allowShowRemove]="false"
								[(ngModel)]="dateEffective"
								formControlName="dateEffective"
								[isShowTime]="false"
								[error]="
									(form.controls['dateEffective'].invalid && form.controls['dateEffective'].touched) ||
									(checkValid && dateEffective == null)
								"
								[errorMessage]="
									'validation_please_choose' | translate: { titleName: 'common_effective_date' | translate }
								"
								placeholder="{{ 'common_please_choose' | translate }}"
								(ngModelChange)="countDateProbationExpire(); selectDate()"
								[minimumDate]="dateEffectiveDefault"
							>
							</go5-datepicker>
						</div>
						<div class="d-flex pt-2">
							<go5-info-bar
								[hidden]="dateEffective == null || selectEmp == null"
								class="d-flex"
								[type]="infoBarType.Info"
								>{{
									"resignation_request_effective_date_infobar"
										| translate
											: {
													dateLast: dateResign | date: "d MMMM yyyy" : (dataShare.currentLanguage | async)
											  }
								}}
							</go5-info-bar>
						</div>
						<!-- เหตุผลการลาออก -->
						<div class="d-flex direction-column pt-3">
							<go5-dropdown-selection
								[dataSource]="reasonToResignDDL"
								[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								[mode]="DropdownSelectMode.Single"
								[height]="'40px'"
								label="{{ ('resignation_request_reason' | translate) + '*' }}"
								[placeholder]="'common_please_choose' | translate"
								formControlName="terminationReasonId"
								[allowFiltering]="false"
								[allowShowRemove]="false"
								[errorMessage]="
									'validation_please_choose' | translate: { titleName: 'resignation_request_reason' | translate }
								"
							>
							</go5-dropdown-selection>
						</div>

						<!-- ข้อมูลเพิ่มเติม -->
						<div class="d-flex direction-column pt-3">
							<go5-textarea
								data-testid="input-text-area"
								category="primary"
								formControlName="remarks"
								size="extra-large"
								label="{{ ('resignation_request_details' | translate) + '*' }}"
								[placeholder]="'resignation_request_details_placeholder' | translate"
								[error]="form.controls['remarks'].invalid && form.controls['remarks'].touched"
								maxlength="2000"
								[errorMessage]="
									'validation_please_field' | translate: { fieldName: 'resignation_request_details' | translate }
								"
							></go5-textarea>
						</div>
						<!-- asset item -->
						@if (this.assetList.length > 0) {
							<div class="d-flex g-4 align-center pt-3">
								<p class="text-title-strong go5-text-color-11">{{ "assets_in_possession" | translate }}</p>
								<go5-badge [label]="this.assetList.length" size="medium" category="primary"></go5-badge>
							</div>
							<div class="pt-3">
								<app-assets-management-table
									[columns]="columns"
									[(dataSource)]="assetList"
									[module]="'SidebarView'"
									(sortingChange)="onSortTable($event)"
									(openViewSidebar)="onClickView($event)"
								>
								</app-assets-management-table>
							</div>
						}

						<div class="d-flex direction-column pt-3">
							<go5-checkbox
								[checked]="isWillingness"
								[label]="
									data.getIsTH(data.currentLanguage | async)
										? ('resignation_request_willingness' | translate) + ' ' + companyProfile?.companyName ?? ''
										: ('resignation_request_willingness' | translate) + ' ' + companyProfile?.companyName_EN ?? ''
								"
								formControlName="isWillingness"
								(changed)="isWillingness = $event.value"
							>
							</go5-checkbox>
							<go5-error-message
								[error]="form.controls['isWillingness'].errors?.isNotCheck && form.controls['isWillingness'].touched"
								[errorMessage]="'resignation_request_willingness_validation' | translate"
							>
							</go5-error-message>
						</div>
						<div class="d-flex direction-column pt-3">
							<go5-checkbox
								[checked]="isAcceptPolicy"
								[label]="'resignation_request_policy' | translate"
								formControlName="isAcceptPolicy"
								(changed)="isAcceptPolicy = $event.value"
							>
							</go5-checkbox>
							<go5-error-message
								[error]="form.controls['isAcceptPolicy'].errors?.isNotCheck && form.controls['isAcceptPolicy'].touched"
								[errorMessage]="'resignation_request_policy_validation' | translate"
							>
							</go5-error-message>
						</div>
						<div class="d-flex direction-column pt-4" *ngIf="userInfo.hasMovementWorkflow">
							<label class="go5-text-color-8 pb-2" style="display: block">{{ "next_approver_text" | translate }}</label>
							<label class="go5-text-body-strong go5-color-primary" style="display: block">{{
								nextApproval ? nextApproval : "-"
							}}</label>
						</div>
					</div>
				</div>
			</div>
			<ng-container *ngIf="isMobile; then footer"></ng-container>
		</form>
	} @else {
		<div class="d-grid gap-8">
			@if (!(masterSetId > 0)) {
				<info-bar
					[hidden]="masterSetId > 0"
					[type]="messageWarningType"
					[employeeId]="employee.employeeId"
					[movementType]="movementType"
					(movementWaitingEffective)="receiveMovementWaitingEffective($event)"
				>
				</info-bar>
			}
			@if (resignApiError && movementType == enumProcessAction.resign) {
				<div class="d-flex">
					<go5-info-bar class="d-flex" [type]="infoBarType.Warning"
						>{{ resignApiError ? warningBoxMessage : ("infobar_resign_self" | translate) }}
					</go5-info-bar>
				</div>
			}
			<form [formGroup]="form">
				<div class="{{ isMobile ? 'p-3 pb-120' : '' }} mb-4 bg-default ">
					<div class="d-flex direction-column gap-24">
						<div *ngIf="movementType != massTransfer && movementType != enumProcessAction.changeOfSupervisor">
							<label class="go5-text-color-8 mb-2" style="display: block" *ngIf="!(masterSetId > 0)">
								{{ selectEmployeeText | translate }}*</label
							>
							<div
								data-testid="open_people_picker"
								*ngIf="selectEmp == null"
								class="select-employee-custom {{ checkValidDateEffective || checkValid ? 'error' : '' }}"
								(click)="openPeoplePicker()"
							>
								<em class="gf-icon-add-participants"></em>
								<label class="go5-text-color-6">{{ "common_select_employee" | translate }}</label>
							</div>

							<go5-error-message
								[error]="(checkValidDateEffective || checkValid) && selectEmp == null"
								[errorMessage]="'common_please_choose_employee' | translate"
							>
							</go5-error-message>

							<div [hidden]="selectEmp == null">
								<div class="contacts">
									<div class="d-flex" style="justify-content: space-between; align-items: center">
										<div class="d-flex" style="align-items: center">
											<img style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover" [src]="image" />
											<div
												class="d-flex"
												[ngStyle]="{ width: isMobile ? '60vw' : '300px' }"
												style="flex-direction: column"
											>
												<span class="name ellipsis go5-text-body-strong go5-text-color-12" style="width: fit-content">
													<label
														go5-tooltip-placement="top"
														go5-tooltip-mode="hover"
														go5-tooltip="{{
															dataShare.getIsTH(dataShare.currentLanguage | async)
																? employee.nameSurname
																: employee.nameSurname_EN
														}}"
													>
														{{
															dataShare.getIsTH(dataShare.currentLanguage | async)
																? employee.nameSurname
																: employee.nameSurname_EN
														}}
													</label>

													<em
														data-testid="open_emp_view_1"
														class="pl-1 gf-icon-show-all go5-text-color-6 pointer"
														go5-tooltip-placement="top"
														go5-tooltip-mode="hover"
														go5-tooltip="{{ 'ess009_salary_adj_profile_hover' | translate }}"
														(click)="openEmployeeView()"
													></em>
												</span>
												<span class="mt-0 position ellipsis go5-text-small go5-text-color-8" style="max-width: 80%">
													<label
														go5-tooltip-placement="top"
														go5-tooltip-mode="hover"
														go5-tooltip="{{
															dataShare.getIsTH(dataShare.currentLanguage | async)
																? employee.positionName
																: employee.positionName_EN
														}}"
													>
														{{
															dataShare.getIsTH(dataShare.currentLanguage | async)
																? employee.positionName
																: employee.positionName_EN
														}}
													</label>
												</span>
											</div>
										</div>
										<div>
											<em
												data-testid="display_emp_true"
												*ngIf="!isLockSelect && !isEdit"
												class="gf-icon-right go5-text-color-6 font24 pointer"
												(click)="displayEmployee = true"
											></em>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="movementType == enumProcessAction.changeOfSupervisor">
							<ng-template [ngIf]="!isEdit" [ngIfElse]="viewEmployee">
								<label class="go5-text-body go5-text-color-8" for="input"
									>{{ "emp010_current_supervisor" | translate }}*</label
								>
								<div
									data-testid="select_supervisor"
									class="p-inputgroup input-icon-search pointer mt-2 border-inputSearch"
									(click)="onclickSelectSupervisor()"
								>
									<input
										data-testid="input_text_curentsupervisorname"
										pInputText
										type="text"
										class="empeo-filter-search focus-inputSearch pointer {{
											!form.controls['upperSupervisorName'].valid &&
											form.controls['upperSupervisorName'].touched &&
											checkValid
												? 'change-supervisor-error'
												: ''
										}}"
										style="pointer-events: none"
										[placeholder]="'popup_searchemployee_click_search_employee' | translate"
										formControlName="upperSupervisorName"
									/>
									<button
										data-testid="button_button_displayemployeecurrent"
										pButton
										type="button"
										class="focus-inputSearch color- focus-inputSearch-btn {{
											!form.controls['upperSupervisorName'].valid &&
											form.controls['upperSupervisorName'].touched &&
											checkValid
												? 'change-supervisor-error'
												: ''
										}}"
										icon="icon-victor-input"
									></button>
								</div>
								<div>
									<go5-error-message
										[error]="
											!form.controls['upperSupervisorName'].valid &&
											form.controls['upperSupervisorName'].touched &&
											checkValid
										"
										[errorMessage]="'please_choose_supervisor' | translate"
									>
									</go5-error-message>
								</div>
							</ng-template>
						</div>
						<div class="w-100" *ngIf="movementType == massTransfer">
							<!-- สังกัดเดิม -->
							<ng-template [ngIf]="!isEdit" [ngIfElse]="viewEmployee">
								<ng-container *ngTemplateOutlet="InputOldDepartment; context: { form: form }"></ng-container>
							</ng-template>
						</div>
						<ng-template [ngIf]="selectEmp != null || movementType == enumProcessAction.massTransfer">
							@switch (movementType) {
								<!-- กลับเข้าทำงาน -->
								@case (enumProcessAction.renewal) {
									<div class="p-0">
										<!-- พ้นสภาพเมื่อ -->
										<div class="py-2" *ngIf="selectEmp != null && employee.dateTermination">
											<label class="go5-text-color-11 text-body-strong pb-1" style="display: block"
												>{{ "movement_separation_on" | translate }}
												{{ employee.dateTermination | date: "d MMM yyyy" : (dataShare.currentLanguage | async) }}</label
											>
											<label
												*ngIf="employee?.terminationReasonId"
												class="go5-text-color-10 text-body-strong pb-1"
												style="display: block"
												>{{
													dataShare.getIsTH(dataShare.currentLanguage | async)
														? employee.terminationReasonName
														: employee.terminationReasonName_EN
												}}</label
											>
											<label *ngIf="employee?.remarkTermination" class="go5-text-color-10" style="display: block">
												{{ employee?.remarkTermination }}
											</label>
										</div>
										<!-- รหัสพนักงาน* -->
										<div class="py-2">
											<go5-textfield
												data-testid="input_text_employee_no"
												type="text"
												formControlName="severanceAmount"
												placeholder="{{ 'emp001_employee_no_placeholder' | translate }}"
												[label]="('emp001_employee_no' | translate) + '*'"
												formControlName="employeeNo"
												[error]="isDuplicateEmployeeNo"
												[errorMessage]="
													(isDuplicateEmployeeNo ? 'validate_enter_another_emp_no' : 'employee_no_validate') | translate
												"
												(changed)="isDuplicateEmployeeNo = false"
											>
											</go5-textfield>
										</div>
										<!-- อายุงาน* -->
										<div class="py-2">
											<label class="go5-text-color-8 pb-1" style="display: block"
												>{{ "emp002_duration_of_employment" | translate }}*</label
											>
											<p-selectButton
												data-testid="new_post_day"
												[options]="optionServiceYearsCont"
												class="selectbutton-seashell"
												formControlName="isServiceYearsCont"
												(click)="getNewPostDays()"
												[(ngModel)]="isServiceYearsCont"
											>
												<ng-template let-item>
													<div style="padding: 0.2em 1rem">
														{{ item.text | translate }}
													</div>
												</ng-template>
											</p-selectButton>
										</div>
										<!-- วันครบทดลองงาน -->
										@if (selectEmp && isServiceYearsCont) {
											<div class="py-2">
												<label class="go5-text-color-8 pb-1" style="display: block">{{
													"emp001_start_work" | translate
												}}</label>
												<label class="go5-text-color-11">
													{{ employee.dateEmployment | date: "dd/MM/yyyy" : (dataShare.currentLanguage | async) }}
													({{
														dataShare.getIsTH(dataShare.currentLanguage | async)
															? employee.durationOfEmployment
															: employee.durationOfEmployment_EN
													}})
												</label>
											</div>
										}
										<div class="d-flex py-2 px-0">
											<!-- บริษัท -->
											<div class="ui-g-6 pr-2 p-0">
												<go5-dropdown-selection
													data-testid="input_dropdown"
													[allowShowRemove]="false"
													[dataSource]="companyDDL"
													label="{{ ('common_company' | translate) + '*' }}"
													placeholder="{{ 'common_please_choose' | translate }}"
													[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
													[mode]="DropdownSelectMode.Single"
													formControlName="companyId"
													(selected)="getCompany($event)"
													[value]="companyId"
													[error]="!form.controls['companyId'].valid && form.controls['companyId'].touched"
													[errorMessage]="'common_required' | translate"
													[allowFiltering]="false"
												>
												</go5-dropdown-selection>
											</div>
											<!-- สังกัด -->
											<div class="ui-g-6 pl-2 p-0">
												<ng-container *ngTemplateOutlet="InputDepartment; context: { form: form }"></ng-container>
											</div>
										</div>
										<div class="d-flex py-2 px-0">
											<!-- rankno -->
											<div class="ui-g-6 p-0 pr-2">
												<ng-container *ngTemplateOutlet="InputRank; context: { form: form }"></ng-container>
											</div>
											<!-- ตำแหน่ง -->
											<div class="ui-g-6 p-0 pl-2">
												<ng-container *ngTemplateOutlet="InputPosition; context: { form: form }"></ng-container>
											</div>
										</div>
										<div class="d-flex py-2 px-0">
											<!-- ประเภทพนักงาน -->
											<div class="ui-g-6 p-0 pr-2">
												<ng-container *ngTemplateOutlet="InputEmploymentType; context: { form: form }"></ng-container>
											</div>
											<!-- ประเภทการจ้าง -->
											<div class="ui-g-6 p-0 pl-2">
												<p class="go5-text-body go5-text-color-8 mb-2">
													{{ ("emp001_t04_pay_type" | translate) + "*" }}
												</p>
												<ng-container *ngTemplateOutlet="InputPaymentMethod; context: { form: form }"></ng-container>
											</div>
										</div>
										<div class="d-flex py-2 px-0">
											<div class="ui-g-6 p-0 pr-2">
												<label class="go5-text-color-8 pb-1" style="display: block"
													>{{ "emp001_rate_type" | translate }}*</label
												>
												<p-selectButton
													[options]="optionReplaceEmployee"
													formControlName="isReplaceEmployee"
													(onChange)="selectIsReplaceEmployee()"
													[(ngModel)]="employee.isReplaceEmployee"
												>
													<ng-template let-item>
														<div
															style="padding: 0.2em 1rem"
															attr.data-testid="isReplaceEmployee_select_button_{{ item.text }}"
														>
															{{ item.text | translate }}
														</div>
													</ng-template>
												</p-selectButton>
											</div>
											<div class="ui-g-6 p-0 pl-2">
												<label class="go5-text-color-8 pb-1" style="display: block"
													>{{ "emp001_t04_record_time" | translate }}*</label
												>
												<p-selectButton
													[options]="optionAttendance"
													(onChange)="selectIsAttendance()"
													formControlName="isAttendance"
													[(ngModel)]="employee.isAttendance"
												>
													<ng-template let-item>
														<div
															style="padding: 0.2em 1rem"
															attr.data-testid="isAttendance_select_button_{{ item.text }}"
														>
															{{ item.text | translate }}
														</div>
													</ng-template>
												</p-selectButton>
											</div>
										</div>
										<div class="py-2" *ngIf="employeeId != null && employee.isReplaceEmployee" class="pointer">
											<go5-dropdown-selection
												data-testid="dropdown_single_orgNode"
												[allowShowRemove]="false"
												[mode]="DropdownSelectMode.Single"
												[dataSource]="listPeople"
												label="{{ ('emp001_replace_employee_placeholder' | translate) + '*' }}"
												placeholder="{{ 'common_default_select' | translate }}"
												popupType="slide"
												categoryItem="item"
												(filtering)="onFilterEmployee($event)"
												[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? peopleFields : peopleFields_EN"
												formControlName="replaceEmployeeId"
												[error]="
													!form.controls['replaceEmployeeId'].valid && form.controls['replaceEmployeeId'].touched
												"
												[errorMessage]="'common_required' | translate"
												[(ngModel)]="replaceEmployeeId"
											>
											</go5-dropdown-selection>
										</div>
										<!-- ผู้บังคับบัญชา -->
										<div class="py-2">
											<ng-container *ngTemplateOutlet="InputUpperSupervisor; context: { form: form }"></ng-container>
										</div>
										<!-- เงินเดือน* -->
										<div class="pt-1">
											<div class="d-flex d-flex-between d-flex-center">
												<span class="mb-2 go5-text-color-8">
													{{ "emp001_salary" | translate }}
												</span>
												<span
													[class]="isShowSalary ? 'gf-icon-close-eye' : 'gf-icon-eye'"
													class="color-primary"
													style="font-size: 20px"
													(click)="isMobile ? (isShowSalary = !isShowSalary) : showSalary()"
													go5-tooltip-placement="left"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														(isShowSalary ? 'pay001_close_salary_tooltip' : 'pay001_show_salary_tooltip') | translate
													}}"
												>
												</span>
											</div>
											<span class="go5-text-color-12 go5-text-body-strong pb-2" style="display: block">
												@if (isShowSalary) {
													<go5-textfield
														data-testid="input_text_sarary"
														type="currency"
														[format]="'0.0'"
														formControlName="incomeAmount"
														[errorMessage]="'validate_please_enter_salary' | translate"
													>
													</go5-textfield>
												} @else {
													<go5-textfield type="currency" [disabled]="true" [placeholder]="markAmount"> </go5-textfield>
												}
											</span>
										</div>
									</div>
								}
								<!-- บรรจุ -->
								@case (enumProcessAction.permanent) {
									<div class="d-flex direction-column gap-16">
										<div class="d-grid gap-16" style="grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)">
											<!-- บริษัท -->
											<div>
												<go5-textfield
													data-testid="input_text_name"
													type="text"
													placeholder="{{ 'emp001_employee_no' | translate }}"
													[label]="('emp001_employee_no' | translate) + '*'"
													formControlName="employeeNo"
													[error]="isDuplicateEmployeeNo"
													[errorMessage]="'validate_employee_code_duplicate' | translate"
													(changed)="isDuplicateEmployeeNo = false"
												>
												</go5-textfield>
											</div>
											<!-- ประเภทพนักงาน -->
											<div>
												<ng-container *ngTemplateOutlet="InputEmploymentType; context: { form: form }"></ng-container>
											</div>
										</div>

										<div class="d-grid gap-16" style="grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)">
											<!-- ประเภทการจ้าง -->
											<div>
												<p class="go5-text-body go5-text-color-8 mb-2">
													{{ ("emp001_t04_pay_type" | translate) + "*" }}
												</p>
												<ng-container *ngTemplateOutlet="InputPaymentMethod; context: { form: form }"></ng-container>
											</div>
											<!-- ระดับ -->
											<div>
												<ng-container *ngTemplateOutlet="InputRank; context: { form: form }"></ng-container>
											</div>
										</div>
										<div class="d-grid gap-16" style="grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)">
											<!-- ตำแหน่ง -->
											<div>
												<ng-container *ngTemplateOutlet="InputPosition; context: { form: form }"></ng-container>
											</div>
											<!-- กะการทำงาน -->
											<div>
												<go5-dropdown-selection
													data-testid="input_choose_shift"
													[dataSource]="shiftDDL"
													[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
													[mode]="DropdownSelectMode.Single"
													[placeholder]="'common_please_choose' | translate"
													label="{{ ('emp001_t06_shift' | translate) + '*' }}"
													[width]="'100%'"
													[allowFiltering]="true"
													[allowShowRemove]="false"
													formControlName="shiftId"
													[error]="!form.controls['shiftId'].valid && form.controls['shiftId'].touched"
													[errorMessage]="'please_choose_shift' | translate"
													[allowSelected]="false"
												>
												</go5-dropdown-selection>
											</div>
										</div>
										<div class="d-grid gap-16" style="grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)">
											<!-- ผู้บังคับบัญชา -->
											<div>
												<ng-container *ngTemplateOutlet="InputUpperSupervisor; context: { form: form }"></ng-container>
											</div>
											<!-- ปฏิทินวันหยุด -->
											<div>
												<go5-dropdown-selection
													data-testid="input_choose_calendar"
													[dataSource]="calendarDDL"
													[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
													[mode]="DropdownSelectMode.Single"
													label="{{ ('emp001_t04_holiday_calendar' | translate) + '*' }}"
													[placeholder]="'common_please_choose' | translate"
													[allowFiltering]="true"
													[error]="!form.controls['calendarId'].valid && form.controls['calendarId'].touched"
													[errorMessage]="'please_choose_calendar' | translate"
													formControlName="calendarId"
													[allowShowRemove]="false"
												>
												</go5-dropdown-selection>
											</div>
										</div>

										<div class="d-grid gap-8" *ngIf="tabPermissionPayroll">
											<div class="d-flex gap-16">
												<!-- ปรับเงินเดือน* -->
												<div>
													<div class="d-flex d-flex-center gap-8">
														<label class="go5-text-color-8 pb-1" style="display: block"
															>{{ "ess009_salary_adjustment" | translate }}*</label
														>
														<einfo-tooltip
															key="movement_new_salary_tooltip"
															position="{{ isMobile ? 'right' : 'top' }}"
															size="s"
														>
														</einfo-tooltip>
													</div>
													<div class="ui-g-12 ui-g-nopad">
														<p-selectButton
															[options]="adjustSelected"
															[ngClass]="'secondary-select-btn'"
															(onChange)="changeIncomeAdjust()"
															formControlName="isIncomeAdjust"
															[(ngModel)]="isIncomeAdjust"
														>
															<ng-template let-item>
																<div
																	style="padding: 0.2em 1rem"
																	attr.data-testid="input_select_button_{{ item.label }}"
																>
																	{{ item.label | translate }}
																</div>
															</ng-template>
														</p-selectButton>
													</div>
												</div>
											</div>
											<div class="d-flex" [hidden]="!isIncomeAdjust">
												<div style="width: calc(50% - 8px)">
													<ng-container *ngTemplateOutlet="InputIncomeAmount; context: { form: form }"></ng-container>
												</div>
											</div>
										</div>
									</div>
								}
								<!-- เปลี่ยนแปลงสภาพการจ้าง -->
								@case (enumProcessAction.employmentConditions) {
									<div class="d-flex direction-column gap-16">
										<div class="d-flex gap-16">
											<!-- รหัสพนักงาน -->
											<div style="width: calc(50% - 8px)">
												<go5-textfield
													data-testid="input_text_name"
													type="text"
													placeholder="{{ 'emp001_employee_no' | translate }}"
													[label]="('emp001_employee_no' | translate) + '*'"
													formControlName="employeeNo"
													[error]="
														(!form.controls['employeeNo'].valid && form.controls['employeeNo'].touched) ||
														isDuplicateEmployeeNo
													"
													[errorMessage]="
														isDuplicateEmployeeNo
															? ('validate_employee_code_duplicate' | translate)
															: ('employee_no_validate' | translate)
													"
													(changed)="isDuplicateEmployeeNo = false"
												>
												</go5-textfield>
											</div>
											<!-- วันที่เริ่มงาน -->
											<div style="width: calc(50% - 8px)">
												<div class="d-flex direction-column">
													<label class="go5-text-color-8 pb-2" style="display: block"
														>{{ "emp001_start_work" | translate }}*</label
													>
													<go5-datepicker
														data-testid="input_date_employment"
														[format]="DateFormat.dateLong"
														[allowShowRemove]="false"
														formControlName="dateEmployment"
														[isShowTime]="false"
														[error]="!form.controls['dateEmployment'].valid && form.controls['dateEmployment'].touched"
														[errorMessage]="'please_choose_start_work_date' | translate"
														placeholder="{{ 'common_please_choose' | translate }}"
														(selected)="countDateProbationExpire()"
													>
													</go5-datepicker>
												</div>
											</div>
										</div>

										<div class="d-flex gap-16">
											<!-- ประเภทพนักงาน -->
											<div style="width: calc(50% - 8px)">
												<ng-container *ngTemplateOutlet="InputEmploymentType; context: { form: form }"></ng-container>
											</div>
											<!-- ประเภทการจ้าง -->
											<div style="width: calc(50% - 8px)">
												<div style="display: flex">
													<p class="go5-text-body go5-text-color-8 mb-2">
														{{ ("emp001_t04_pay_type" | translate) + "*" }}
													</p>
													<span style="margin: 1px 0 0 4px">
														<einfo-tooltip key="emp001_t14_employment_type_tooltip" size="s"> </einfo-tooltip>
													</span>
												</div>
												<ng-container
													*ngTemplateOutlet="InputPaymentMethod; context: { form: form, isLabel: false }"
												></ng-container>
											</div>
										</div>

										<div class="ui-g-12 p-0" *ngIf="infoPaymentMethodChange">
											<go5-info-bar [type]="infoBarType.Info">
												{{ "emp001_t14_employment_type_changed_infobar_1" | translate
												}}<b>{{ "emp001_t14_employment_type_changed_infobar_2" | translate }}</b
												>{{ "emp001_t14_employment_type_changed_infobar_3" | translate
												}}<a
													style="text-decoration: underline"
													href="https://help.empeo.com/th/article/4lib4liy4lij4lma4lib4lil4li14lmi4lii4liz4lib4lij4liw4lma4lig4lix4lib4liy4lij4lii4lmj4liy4lih4lij4liw4lir4lin4lmi4liy4lih4lih4lin4liu-1193zou/?bust=1667378339940"
													>{{ "emp001_t14_employment_type_changed_infobar_4" | translate }}</a
												>
											</go5-info-bar>
										</div>

										<div class="d-flex gap-16">
											<!-- ตำแหน่ง -->
											<div style="width: calc(50% - 8px)">
												<ng-container *ngTemplateOutlet="InputPosition; context: { form: form }"></ng-container>
											</div>
											<!-- ระดับ -->
											<div style="width: calc(50% - 8px)">
												<ng-container *ngTemplateOutlet="InputRank; context: { form: form }"></ng-container>
											</div>
										</div>

										<div class="d-flex gap-16">
											<!-- กะทำงาน -->
											<div style="width: calc(50% - 8px)">
												<go5-dropdown-selection
													data-testid="input_default_select_shift"
													[dataSource]="shiftDDL"
													[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
													[mode]="DropdownSelectMode.Single"
													[placeholder]="'common_default_select' | translate"
													label="{{ ('emp001_t06_shift' | translate) + '*' }}"
													[width]="'100%'"
													[allowFiltering]="true"
													[allowShowRemove]="false"
													formControlName="shiftId"
													[error]="!form.controls['shiftId'].valid && form.controls['shiftId'].touched"
													[errorMessage]="'please_choose_shift' | translate"
													[allowSelected]="false"
												>
												</go5-dropdown-selection>
											</div>
											<!-- ปฏิทินวันหยุด -->
											<div style="width: calc(50% - 8px)">
												<go5-dropdown-selection
													data-testid="input_default_select_calendar"
													[dataSource]="calendarDDL"
													[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
													[mode]="DropdownSelectMode.Single"
													label="{{ ('emp001_t04_holiday_calendar' | translate) + '*' }}"
													[placeholder]="'common_default_select' | translate"
													[allowFiltering]="true"
													[error]="!form.controls['calendarId'].valid && form.controls['calendarId'].touched"
													[errorMessage]="'please_choose_calendar' | translate"
													formControlName="calendarId"
													[allowShowRemove]="false"
												>
												</go5-dropdown-selection>
											</div>
										</div>
									</div>

									<!-- ผู้บังคับบัญชา -->
									<div style="padding-top: 16px">
										<ng-container *ngTemplateOutlet="InputUpperSupervisor; context: { form: form }"></ng-container>
									</div>

									<div class="d-flex" style="padding-top: 16px">
										<!-- ต่ออายุทดลองงาน -->
										<div class="d-flex flex-row" style="width: 50%; align-items: center">
											<go5-checkbox
												data-testid="input_checkbox_isprobation"
												[checked]="isProbation"
												formControlName="isProbation"
												(changed)="getDayOfProbation($event)"
											></go5-checkbox>
											<div style="padding-left: 12px">
												<label style="color: var(--go5-text-color-10)">{{ "renew_probation" | translate }} </label>
											</div>
										</div>
										<div style="width: 50%; padding-left: 8px">
											<div class="bd-highlight" *ngIf="isProbation">
												<go5-textfield
													data-testid="input-text-field"
													type="number"
													category="primary"
													[step]="1"
													[min]="0"
													format="0.0-2"
													[(ngModel)]="dayOfProbation"
													formControlName="dayOfProbation"
													unit="{{ 'common_day' | translate }}"
												></go5-textfield>
											</div>
										</div>
									</div>

									<div class="d-grid gap-8" style="padding-top: 16px">
										<div class="d-flex gap-16">
											<!-- ปรับเงินเดือน* -->
											<div style="width: calc(50% - 8px)" *ngIf="tabPermissionPayroll">
												<div class="d-flex d-flex-center gap-8">
													<label class="go5-text-color-8 pb-1" style="display: block"
														>{{ "ess009_salary_adjustment" | translate }}*</label
													>
													<einfo-tooltip
														key="movement_new_salary_tooltip"
														size="s"
														position="{{ isMobile ? 'right' : 'top' }}"
													>
													</einfo-tooltip>
												</div>
												<div class="ui-g-12 ui-g-nopad">
													<p-selectButton
														[options]="adjustSelected"
														[ngClass]="'secondary-select-btn'"
														(onChange)="changeIncomeAdjust()"
														formControlName="isIncomeAdjust"
														[(ngModel)]="isIncomeAdjust"
													>
														<ng-template let-item>
															<div style="padding: 0.2em 1rem" attr.data-testid="input_calendar_{{ item.label }}">
																{{ item.label | translate }}
															</div>
														</ng-template>
													</p-selectButton>
												</div>
											</div>
											<!-- บันทึกเวลาเข้าออก -->
											<div style="width: calc(50% - 8px)">
												<label class="go5-text-color-8 pb-2" style="display: block"
													>{{ "emp001_t04_record_time" | translate }}*</label
												>
												<p-selectButton
													[options]="optionAttendance"
													(onChange)="selectIsAttendance()"
													[(ngModel)]="employee.isAttendance"
													formControlName="isAttendance"
												>
													<ng-template let-item>
														<div style="padding: 0.2em 1rem" attr.data-testid="input_calendar_{{ item.text }}">
															{{ item.text | translate }}
														</div>
													</ng-template>
												</p-selectButton>
											</div>
										</div>
										<div class="d-flex" [hidden]="!isIncomeAdjust" *ngIf="tabPermissionPayroll">
											<div style="width: calc(50% - 8px)">
												<ng-container *ngTemplateOutlet="InputIncomeAmount; context: { form: form }"></ng-container>
											</div>
										</div>
									</div>
								}
								<!-- พ้นสภาพ -->
								@case (enumProcessAction.resign) {
									<!-- ผู้บังคับบัญชา -->
									<div *ngIf="countUnder > 0" style="padding-top: 8px">
										<go5-dropdown-selection
											data-testid="dropdown_single_orgNode"
											[allowShowRemove]="true"
											[mode]="DropdownSelectMode.Single"
											[dataSource]="listPeople"
											[label]="('ess009_supervisor_new' | translate: { subordinatesamount: countUnder }) + '*'"
											[placeholder]="'common_please_choose' | translate"
											popupType="slide"
											categoryItem="item"
											(filtering)="onFilterEmployee($event)"
											[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? peopleFields : peopleFields_EN"
											formControlName="upperSupervisorId"
											[error]="
												form.get('upperSupervisorId').invalid &&
												(form.get('upperSupervisorId').touched || form.get('upperSupervisorId').dirty)
											"
											[errorMessage]="'ess009_separation_supervisor_new_validate' | translate"
											[(ngModel)]="upperSupervisorId"
										>
										</go5-dropdown-selection>
									</div>

									<!-- เหตุผลการพ้นสภาพ -->
									<go5-dropdown-selection
										data-testid="dropdown_single_terminationReasonId"
										[dataSource]="reasonToResignDDL"
										[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
										[mode]="DropdownSelectMode.Single"
										[height]="'40px'"
										[label]="('ess009_seperation_reason' | translate) + '*'"
										[placeholder]="'common_please_choose' | translate"
										formControlName="terminationReasonId"
										[allowFiltering]="false"
										[allowShowRemove]="true"
										[errorMessage]="'ess009_seperation_reason_validate' | translate"
										(selected)="selectTerminantionReasonId($event)"
										(clear)="clearSeveranceAmount()"
									>
									</go5-dropdown-selection>

									<!-- asset item -->
									@if (this.assetList.length > 0) {
										<div>
											<div class="d-flex g-4 align-center">
												<p class="text-title-strong go5-text-color-11">{{ "assets_in_possession" | translate }}</p>
												<go5-badge [label]="this.assetList.length" size="medium" category="primary"></go5-badge>
											</div>
											<div class="pt-3">
												<app-assets-management-table
													[columns]="columns"
													[(dataSource)]="assetList"
													[module]="'SidebarView'"
													(sortingChange)="onSortTable($event)"
													(openViewSidebar)="onClickView($event)"
												>
												</app-assets-management-table>
											</div>
										</div>
									}

									<!-- เหตุผลการสิ้นสุดการเป็นผู้ประกันตน -->
									@if (reasonToResignSSO) {
										<div class="pt-1">
											<span class="go5-text-color-8 pb-2" style="display: block">
												{{ "resignation_sso_reason" | translate }}
											</span>
											<span class="go5-text-color-12 go5-text-body-strong pb-2" style="display: block">
												{{
													data.getIsTH(data.currentLanguage | async)
														? reasonToResignSSO.typeName
														: reasonToResignSSO.typeNameEn
												}}
											</span>
										</div>
									}

									<!-- เงินชดเชย -->
									@if (this.form.controls["terminationReasonId"].value) {
										<div class="pt-1">
											<div class="d-flex d-flex-between d-flex-center">
												<span class="d-flex mb-2" style="align-items: center">
													<span class="go5-text-color-8">
														{{ "severance_pay" | translate }}
													</span>
													<go5-tooltip-information
														style="margin-left: 2px"
														go5-tooltip-max-width="28em"
														[go5-tooltip]="'separation_severance_pay_tooltip' | translate"
													/>
												</span>
												<span
													[class]="isShowSalary ? 'gf-icon-close-eye' : 'gf-icon-eye'"
													class="color-primary"
													style="font-size: 20px"
													(click)="isMobile ? (isShowSalary = !isShowSalary) : showSalary()"
													go5-tooltip-placement="left"
													go5-tooltip-mode="hover"
													go5-tooltip="{{
														(isShowSalary ? 'pay001_close_salary_tooltip' : 'pay001_show_salary_tooltip') | translate
													}}"
												>
												</span>
											</div>
											<span class="go5-text-color-12 go5-text-body-strong pb-2" style="display: block">
												@if (isShowSalary) {
													<go5-textfield
														data-testid="input_text_severance_pay"
														type="currency"
														[format]="'0.0'"
														formControlName="severanceAmount"
													>
													</go5-textfield>
												} @else {
													<go5-textfield type="currency" [disabled]="true" [placeholder]="markAmount"> </go5-textfield>
												}
											</span>
										</div>
									}
								}
								<!-- เลื่อนตำแหน่ง -->
								@case (enumProcessAction.promote) {
									<div class="d-flex direction-column gap-16">
										<div class="d-flex gap-8">
											<!-- สังกัด -->
											<div style="width: calc(50% - 8px)">
												<ng-container *ngTemplateOutlet="InputDepartment; context: { form: form }"></ng-container>
											</div>
											<!-- ระดับ -->
											<div style="width: calc(50% - 8px)">
												<ng-container *ngTemplateOutlet="InputRank; context: { form: form }"></ng-container>
											</div>
										</div>
										<div class="d-flex gap-8">
											<!-- ตำแหน่ง -->
											<div style="width: calc(50% - 8px)">
												<ng-container *ngTemplateOutlet="InputPosition; context: { form: form }"></ng-container>
											</div>
											<div style="width: calc(50% - 8px)">
												<ng-container *ngTemplateOutlet="InputUpperSupervisor; context: { form: form }"></ng-container>
											</div>
										</div>
										<div class="d-grid gap-8" *ngIf="tabPermissionPayroll">
											<div class="d-flex gap-16">
												<!-- ปรับเงินเดือน* -->
												<div>
													<div class="d-flex d-flex-center gap-8">
														<label class="go5-text-color-8 pb-1" style="display: block"
															>{{ "ess009_salary_adjustment" | translate }}*</label
														>
														<einfo-tooltip
															key="movement_new_salary_tooltip"
															size="s"
															position="{{ isMobile ? 'right' : 'top' }}"
														>
														</einfo-tooltip>
													</div>

													<div class="ui-g-12 ui-g-nopad">
														<p-selectButton
															[options]="adjustSelected"
															[ngClass]="'secondary-select-btn'"
															(onChange)="changeIncomeAdjust()"
															formControlName="isIncomeAdjust"
															[(ngModel)]="isIncomeAdjust"
														>
															<ng-template let-item>
																<div
																	style="padding: 0.2em 1rem"
																	attr.data-testid="input_select_button_{{ item.label }}"
																>
																	{{ item.label | translate }}
																</div>
															</ng-template>
														</p-selectButton>
													</div>
												</div>
											</div>
											<div class="d-flex" [hidden]="!isIncomeAdjust">
												<div style="width: calc(50% - 8px)">
													<ng-container *ngTemplateOutlet="InputIncomeAmount; context: { form: form }"></ng-container>
												</div>
											</div>
										</div>
									</div>
								}
								<!-- ปรับเงินเดือน -->
								@case (enumProcessAction.raiseTheSalary) {
									<div class="d-grid gap-32" *ngIf="salaryAdjustmentDetails.isAllowPermission">
										<div class="d-grid gap-16">
											<div class="box-warpper-detail-salary">
												<div class="d-grid gap-4">
													<div class="d-flex d-flex-between d-flex-center">
														<label class="go5-text-body-strong go5-text-color-12"
															>{{ "ess009_salary_adj_total_amt_latest_month" | translate }} ({{
																salaryAdjustmentDetails.adjustment.date
																	| date: "MMMM yyyy" : (dataShare.currentLanguage | async)
															}})
														</label>
														<label class="go5-text-header-5 go5-text-color-12"
															>฿{{
																isShowSalary
																	? (salaryAdjustmentDetails?.adjustment?.incomeAmount | number: "0.0")
																	: markAmount
															}}</label
														>
													</div>
													<div class="d-flex d-flex-between d-flex-center">
														<label class="go5-text-color-12">{{ "emp001_salary" | translate }}</label>
														<label class="go5-text-title go5-text-color-9"
															>฿{{
																isShowSalary
																	? (salaryAdjustmentDetails?.adjustment?.baseSalary | number: "0.0")
																	: markAmount
															}}</label
														>
													</div>
													<div class="d-flex d-flex-between">
														<span class="d-flex gap-4 d-flex-center">
															<label class="go5-text-color-12">{{
																"ess009_salary_adj_regular_salary" | translate
															}}</label>
															<em
																class="gf-icon-down go5-text-color-6 pointer font16 {{
																	contentFinancial() ? 'open' : ''
																}}"
																(click)="contentFinancial.set(!contentFinancial())"
																*ngIf="salaryAdjustmentDetails?.adjustment?.financial?.amount > 0"
															></em>
														</span>
														<label class="go5-text-title go5-text-color-9"
															>฿{{
																isShowSalary
																	? (salaryAdjustmentDetails?.adjustment?.financial?.amount | number: "0.0")
																	: markAmount
															}}</label
														>
													</div>
													<div class="d-grid slide-down" style="padding-left: 24px" *ngIf="contentFinancial()">
														<div
															class="d-flex d-flex-between d-flex-center"
															*ngFor="let item of salaryAdjustmentDetails?.adjustment?.financial?.items; let i = index"
														>
															<label class="go5-text-color-12">{{
																dataShare.getIsTH(dataShare.currentLanguage | async) ? item?.name : item?.name_EN
															}}</label>
															<label class="go5-text-title go5-text-color-9"
																>฿{{ isShowSalary ? (item?.amount | number: "0.0") : markAmount }}</label
															>
														</div>
													</div>
												</div>
												@if (
													salaryAdjustmentDetails?.adjustment?.history?.salary?.length > 0 ||
													salaryAdjustmentDetails?.adjustment?.history?.positions?.length > 0 ||
													salaryAdjustmentDetails?.adjustment?.history?.bonuses?.length > 0 ||
													salaryAdjustmentDetails?.adjustment?.history?.welfares?.length > 0
												) {
													<div class="d-grid gap-4">
														<div class="d-flex d-flex-center gap-4">
															<label class="go5-text-body-strong go5-text-color-12">{{
																"ess009_adjustment_history" | translate
															}}</label>
															<em
																data-testid="open_emp_view_2"
																class="pl-1 gf-icon-show-all go5-text-color-6 pointer"
																go5-tooltip-placement="top"
																go5-tooltip-mode="hover"
																go5-tooltip="{{ 'ess009_salary_adj_history_page_hover' | translate }}"
																(click)="openEmployeeViewSalary()"
															></em>
														</div>
														<go5-tabs
															category="primary"
															[headerPadding]="'0px 0px 8px 0'"
															[contentPadding]="'0px'"
															[isShowBorder]="false"
															[allowScroll]="true"
														>
															<go5-tab
																[headerText]="'emp001_salary' | translate"
																*ngIf="salaryAdjustmentDetails?.adjustment?.history?.salary?.length > 0"
															>
																<ng-template #contentTemplate>
																	<div
																		class="d-flex d-flex-between d-flex-center"
																		*ngFor="
																			let item of salaryAdjustmentDetails.adjustment.history.salary;
																			let i = index
																		"
																	>
																		<label class="go5-text-color-12">{{
																			item.date | date: "dd/MM/yyyy" : (dataShare.currentLanguage | async)
																		}}</label>
																		<span class="d-flex d-flex-center gap-4">
																			<div
																				class="tag-badge"
																				[go5-tooltip]="
																					isShowSalary
																						? dataShare.getIsTH(dataShare.currentLanguage | async)
																							? item.detail
																							: item.detail_EN
																						: 'xxxx.xx'
																				"
																			>
																				{{ item.percentage }}%
																			</div>
																			<label class="go5-text-title go5-text-color-12"
																				>฿{{ isShowSalary ? (item.amount | number: "0.0") : markAmount }}</label
																			>
																		</span>
																	</div>
																</ng-template>
															</go5-tab>
															<go5-tab
																[headerText]="'emp001_welfare' | translate"
																*ngIf="salaryAdjustmentDetails?.adjustment?.history?.welfares?.length > 0"
															>
																<ng-template #contentTemplate>
																	<div
																		style="
																			display: grid;
																			grid-template-columns: 1fr 3fr 1fr;
																			align-items: center;
																			gap: 8px;
																		"
																		*ngFor="
																			let item of salaryAdjustmentDetails.adjustment.history.welfares;
																			let i = index
																		"
																	>
																		<label class="go5-text-color-12">{{
																			item.date | date: "dd/MM/yyyy" : (dataShare.currentLanguage | async)
																		}}</label>
																		<label class="go5-text-color-12">{{
																			dataShare.getIsTH(dataShare.currentLanguage | async) ? item.name : item.name_EN
																		}}</label>
																		<span class="d-flex d-flex-center gap-4">
																			<div
																				class="tag-badge"
																				[go5-tooltip]="
																					isShowSalary
																						? dataShare.getIsTH(dataShare.currentLanguage | async)
																							? item.detail
																							: item.detail_EN
																						: 'xxxx.xx'
																				"
																			>
																				{{ item.percentage }}%
																			</div>
																			<label class="go5-text-title go5-text-color-12" style="text-align: right"
																				>฿{{ isShowSalary ? (item.amount | number: "0.0") : markAmount }}</label
																			>
																		</span>
																	</div>
																</ng-template>
															</go5-tab>
															<go5-tab
																[headerText]="'emp001_t14_bonus' | translate"
																*ngIf="salaryAdjustmentDetails?.adjustment?.history?.bonuses?.length > 0"
															>
																<ng-template #contentTemplate>
																	<div
																		style="display: grid; grid-template-columns: 1fr 1fr"
																		*ngFor="
																			let item of salaryAdjustmentDetails.adjustment.history.bonuses;
																			let i = index
																		"
																	>
																		<label class="go5-text-color-12">{{
																			item.date | date: "dd/MM/yyyy" : (dataShare.currentLanguage | async)
																		}}</label>
																		<span class="d-flex d-flex-center flex-end gap-4">
																			<div
																				class="tag-badge"
																				[go5-tooltip]="
																					isShowSalary
																						? dataShare.getIsTH(dataShare.currentLanguage | async)
																							? item.detail
																							: item.detail_EN
																						: 'xxxx.xx'
																				"
																			>
																				{{ item.percentage }}x
																			</div>
																			<label class="go5-text-title go5-text-color-12"
																				>฿{{ isShowSalary ? (item.amount | number: "0.0") : markAmount }}</label
																			>
																		</span>
																	</div>
																</ng-template>
															</go5-tab>
															<go5-tab
																[headerText]="'emp001_position' | translate"
																*ngIf="salaryAdjustmentDetails?.adjustment?.history?.positions?.length > 0"
															>
																<ng-template #contentTemplate>
																	<div
																		style="display: grid; grid-template-columns: 1fr 3fr"
																		*ngFor="
																			let item of salaryAdjustmentDetails.adjustment.history.positions;
																			let i = index
																		"
																	>
																		<label class="go5-text-color-12">{{
																			item.date | date: "dd/MM/yyyy" : (dataShare.currentLanguage | async)
																		}}</label>
																		<label
																			class="go5-text-color-12"
																			[innerHTML]="
																				dataShare.getIsTH(dataShare.currentLanguage | async) ? item.name : item.name_EN
																			"
																		>
																		</label>
																	</div>
																</ng-template>
															</go5-tab>
														</go5-tabs>
													</div>
												}
											</div>
											<app-salary-band [salaryBand]="salaryBand" [isShowSalary]="isShowSalary"></app-salary-band>
										</div>
										<div class="d-grid gap-16">
											<div class="d-flex d-flex-between d-flex-center">
												<label class="go5-text-title-strong go5-text-color-12">
													{{ "ess009_salary_adj_section" | translate }}
												</label>
												<go5-dropdown-selection
													data-testid="input_dropdown_welfare_paid"
													[dataSource]="salaryAdjSection"
													[value]="salaryAdjustmentDetails.movement.typeAdjustment"
													[allowFiltering]="false"
													[allowShowRemove]="false"
													[fields]="fieldsDDL"
													mode="single"
													popupType="popup"
													(selected)="selectTypeAdjustment($event)"
													[placeholder]="'common_default_select' | translate"
												>
												</go5-dropdown-selection>
											</div>

											<div
												class="d-grid gap-16"
												style="grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)"
												*ngIf="salaryAdjustmentDetails.movement.typeAdjustment == SalaryAdjSection.position"
											>
												<ng-container *ngTemplateOutlet="InputRank; context: { form: form }"></ng-container>
												<ng-container *ngTemplateOutlet="InputPosition; context: { form: form }"></ng-container>
												<ng-container *ngTemplateOutlet="InputDepartment; context: { form: form }"></ng-container>
												<ng-container *ngTemplateOutlet="InputUpperSupervisor; context: { form: form }"></ng-container>
											</div>
											<div
												[ngStyle]="{
													display: 'grid',
													gap: '16px',
													'grid-template-columns': '2fr 2fr 2.5fr'
												}"
											>
												<!-- อัตราการปรับ-->
												<go5-textfield
													data-testid="input_text_salary_adj_percentage"
													[label]="'ess009_salary_adj_percentage' | translate"
													type="number"
													[placeholder]="'emp002_dont_adjust' | translate"
													[format]="'0.0'"
													[step]="0.5"
													unit="%"
													[disabled]="!isShowSalary || salaryAdjustmentDetails.adjustment?.baseSalary == 0"
													[value]="financialSalary.percentage"
													(changed)="changePercentageSalary($event)"
												>
												</go5-textfield>

												<!-- ปรับเพิ่มขึ้น -->
												<go5-textfield
													*ngIf="!isShowSalary"
													data-testid="input_text_salary_adj_adjusted_amt"
													[label]="'ess009_salary_adj_adjusted_to' | translate"
													type="currency"
													[format]="'0.0'"
													[disabled]="true"
													[placeholder]="markAmount"
												>
												</go5-textfield>
												<go5-textfield
													[hidden]="!isShowSalary"
													data-testid="input_text_salary_adj_adjusted_amt"
													[label]="'ess009_salary_adj_adjusted_to' | translate"
													type="currency"
													[disabled]="!isShowSalary"
													[format]="'0.0-2'"
													placeholder="0"
													[value]="extraAmount"
													(changed)="changeAdjustTo($event)"
												>
												</go5-textfield>

												<!-- เงินเดือนหลังปรับ -->
												<go5-textfield
													*ngIf="!isShowSalary"
													data-testid="input_text_salary_adj_adjusted_amt"
													[label]="'ess009_salary_adj_adjusted_amt' | translate"
													type="currency"
													[disabled]="true"
													[placeholder]="markAmount"
												>
												</go5-textfield>
												<go5-textfield
													[hidden]="!isShowSalary"
													data-testid="input_text_salary_adj_adjusted_amt"
													[label]="'ess009_salary_adj_adjusted_amt' | translate"
													type="currency"
													[format]="'0.0-2'"
													[step]="1000"
													[disabled]="!isShowSalary"
													[value]="financialSalary.amount"
													[error]="!(financialSalary.amount > 0) && checkValid"
													(changed)="changedSalary($event)"
													placeholder="0"
												>
												</go5-textfield>
											</div>
											<div class="d-grid gap-16">
												<div class="d-flex d-flex-between d-flex-center">
													<label class="go5-text-color-8">
														{{ "wel006_adjust_welfare_hover" | translate }}
													</label>

													<go5-toggle-switch
														data-testid="input_switch_adjust_welfare_hover"
														[checked]="isWelfareSalary"
														(valueChange)="isWelfareSalary = $event.checked"
														category="secondary"
													></go5-toggle-switch>
												</div>

												<form [formGroup]="formWelfareSalary" *ngIf="isWelfareSalary" class="fadeIn">
													<div formArrayName="items" class="d-grid gap-8">
														<div class="d-grid gap-16" style="grid-template-columns: 5fr 6fr 1fr">
															<label class="go5-text-color-8">{{ "common_welfare" | translate }}</label>
															<label class="go5-text-color-8">{{ "common_amount" | translate }}</label>
															<div></div>
														</div>
														<div class="d-grid gap-8">
															@for (item of welfareSalaryList; track item; let i = $index) {
																<div
																	[formGroupName]="i"
																	class="d-grid gap-8 fadeId"
																	style="grid-template-columns: 5fr 6fr 30px; align-items: center"
																>
																	<div class="text-left" style="max-width: 180px">
																		<ng-template [ngIf]="item.isNew" [ngIfElse]="contentDDLWelfare">
																			<go5-dropdown-selection
																				attr.data-testid="input_number_welfare_{{ i }}"
																				[dataSource]="welfareSalaryDDL"
																				[allowFiltering]="false"
																				[allowShowRemove]="false"
																				[fields]="
																					dataShare.getIsTH(dataShare.currentLanguage | async)
																						? fieldsWelfareDDL
																						: fieldsWelfareDDL_EN
																				"
																				mode="single"
																				[value]="item.welfareId"
																				formControlName="welfareId"
																				popupType="popup"
																				[error]="
																					getValidate(i, 'welfareId')?.invalid &&
																					getValidate(i, 'welfareId')?.dirty &&
																					checkValid
																				"
																				(select)="selectedWelfareSalary($event, item, i)"
																				[placeholder]="'common_default_select' | translate"
																			>
																			</go5-dropdown-selection>
																		</ng-template>
																		<ng-template #contentDDLWelfare>
																			{{
																				dataShare.getIsTH(dataShare.currentLanguage | async) ? item.name : item.name_EN
																			}}
																		</ng-template>
																	</div>
																	<div class="text-left">
																		<go5-textfield
																			*ngIf="!isShowSalary"
																			attr.data-testid="input_number_amount_{{ i }}"
																			type="currency"
																			[format]="'0.0'"
																			[disabled]="true"
																			[placeholder]="markAmount"
																		>
																		</go5-textfield>
																		<go5-textfield
																			[hidden]="!isShowSalary"
																			attr.data-testid="input_number_amount_{{ i }}"
																			type="currency"
																			category="primary"
																			format="0.0"
																			placeholder="0.00"
																			formControlName="amount"
																			[(ngModel)]="item.amount"
																			(changed)="calTotalAmount()"
																			[error]="
																				getValidate(i, 'amount')?.invalid &&
																				getValidate(i, 'amount')?.dirty &&
																				checkValid
																			"
																		></go5-textfield>
																	</div>
																	<div class="center">
																		<em
																			attr.data-testid="input_btn_delete_{{ i }}"
																			id="deleteBTN"
																			class="gf-icon-empeo-bin-vector pointer"
																			go5-tooltip-placement="top"
																			go5-tooltip-mode="hover"
																			go5-tooltip="{{ 'common_delete' | translate }}"
																			(click)="deleteFormWelfareSalary(i)"
																		>
																		</em>
																	</div>
																</div>
															}

															<go5-error-message
																[error]="
																	(this.formWelfareSalary.invalid || this.welfareSalaryList?.length == 0) &&
																	this.isWelfareSalary &&
																	this.checkValid
																"
																[errorMessage]="'common_please_add_info' | translate"
															>
															</go5-error-message>
															<go5-button
																data-testid="input_btn_financial_type_setting"
																label="{{ 'common_add' | translate }}"
																category="text"
																[margin]="0"
																style="width: min-content"
																[padding]="0"
																iconClass="gf-icon-add-general"
																size="medium"
																type="button"
																(click)="addWelfareSalaryAdjustment($event)"
															>
															</go5-button>
														</div>
													</div>
												</form>

												<div class="d-grid gap-24">
													<div class="d-flex d-flex-between d-flex-center">
														<label class="go5-text-body-strong go5-text-color-11">
															{{ "emp001_t14_net_pay" | translate }}
														</label>
														<div class="d-flex d-flex-center gap-4">
															<div class="tag-badge" *ngIf="totalAmountPercent > 0">
																{{ isShowSalary ? (totalAmountPercent | number: "0.0-2") : "x.x" }}%
															</div>
															<label class="go5-text-header-5 go5-text-color-11">
																฿{{ isShowSalary ? (totalAmount | number: "0.0-2") : markAmount }}
															</label>
														</div>
													</div>
													<div class="d-grid gap-8">
														<div
															[ngStyle]="{
																display: 'grid',
																gap: '16px',
																'grid-template-columns': '2fr 4.5fr'
															}"
														>
															<!-- จำนวนเท่า โบนัส -->
															<go5-textfield
																data-testid="input_text_times"
																[label]="'mas014_times' | translate"
																type="number"
																[disabled]="!isShowSalary"
																[value]="financialBonus.percentage"
																(changed)="changePercentageBonus($event)"
																[format]="'0.0'"
																[min]="0"
																[step]="0.5"
																placeholder="0"
															>
															</go5-textfield>

															<!-- โบนัส -->

															<div class="d-grid gap-8">
																<span class="d-flex gap-4 d-flex-center">
																	<label class="go5-text-color-8" style="display: block">
																		{{ "ess009_salary_adj_bonus_value" | translate }}
																	</label>
																</span>
																<go5-textfield
																	*ngIf="!isShowSalary"
																	data-testid="input_text_salary_adj_bonus_value"
																	type="currency"
																	[disabled]="true"
																	[placeholder]="markAmount"
																>
																</go5-textfield>
																<go5-textfield
																	[hidden]="!isShowSalary"
																	data-testid="input_text_salary_adj_bonus_value"
																	type="currency"
																	[disabled]="!isShowSalary"
																	[value]="financialBonus.amount"
																	(changed)="changeAmountBonus($event)"
																	[format]="'0.0-2'"
																	placeholder="0"
																>
																</go5-textfield>
															</div>
														</div>
														@if (salaryAdjustmentDetails?.adjustment?.hasBonusDetail) {
															<go5-info-bar [type]="InfoBarType.Info">
																<span>{{
																	"ess009_salary_adj_bonus_calculate_infobar"
																		| translate
																			: {
																					asmRound_dateRange:
																						(salaryAdjustmentDetails?.adjustment?.dateRoundStart | date: "d/M/yyyy") +
																						" - " +
																						(salaryAdjustmentDetails?.adjustment?.dateRoundEnd | date: "d/M/yyyy"),
																					bonusAmount: salaryAdjustmentDetails?.adjustment?.avgSalary | number: "0.0-2"
																			  }
																}}</span>
															</go5-info-bar>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								}
								<!-- โอนย้ายหน่วยงาน -->
								@case (enumProcessAction.agencyTransfer) {
									<div style="padding-top: 0px; display: flex; gap: 8px; flex-direction: column">
										<div class="d-flex gap-8" style="justify-content: space-between">
											<!-- สังกัดใหม่ -->
											<div class="p-0" style="width: 48%">
												<ng-container *ngTemplateOutlet="InputNewDepartment; context: { form: form }"></ng-container>
											</div>
											<!-- ตำแหน่ง -->
											<div class="p-0" style="width: 48%">
												<ng-container *ngTemplateOutlet="InputPosition; context: { form: form }"></ng-container>
											</div>
										</div>
										<div class="d-flex" style="justify-content: space-between">
											<!-- ผู้บังคับบัญชา -->
											<div class="p-0" style="width: 48%">
												<ng-container *ngTemplateOutlet="InputUpperSupervisor; context: { form: form }"></ng-container>
											</div>
										</div>
									</div>
								}
								<!-- ย้ายสายบังคับบํญชา -->
								@case (enumProcessAction.changeOfSupervisor) {
									<div style="display: flex; gap: 8px; flex-direction: column">
										<div *ngIf="!isEdit">
											<label class="go5-text-body go5-text-color-8">{{
												"emp010_list_of_subordinates" | translate
											}}</label
											><br />
											<label class="go5-text-title-strong go5-text-color-12" style="margin-top: 8px"
												>{{ countUnder }} {{ "common_people" | translate }}</label
											>
										</div>
										<div *ngIf="!isEdit">
											<label class="go5-text-body go5-text-color-8"
												>{{ "choose_employee_mass_tranfer" | translate }}*</label
											><br />
											<div style="margin-top: 8px; display: flex; flex-wrap: wrap" class="dropdown-picker">
												<go5-dropdown-selection
													data-testid="dropdown_avater_inplace"
													[fields]="dataShare.getIsTH(dataShare.currentLanguage | async)
														? peopleFieldsSupervisor
														: peopleFieldsSupervisor_EN"
													[dataSource]="subordinateList"
													[value]="employees"
													(filtering)="onFilterUnder($event)"
													(selected)="chooseEmployeeTransfer($event)"
													mode="multi"
													style="margin-right: 5px"
													[popupWidth_px]="276"
													textAdd="common_add_new_participant"
												>
													<ng-template #toggleTemplate>
														<div
															style="text-align: center; width: fit-content; display: grid"
															*ngIf="employees?.length != listPeopleInDepartment?.length"
														>
															<em class="gfc-add-participants addIcon"></em>
															<label class="go5-text-extra-small go5-text-color-5" style="padding-top: 2px">{{
																"common_add" | translate
															}}</label>
														</div>
	
														<div style="text-align: center" *ngIf="employees?.length == listPeopleInDepartment?.length">
															<go5-avatar-selected-item
																title="{{ 'common_all' | translate }}"
																[src]="assetUrl + 'images/empeo/assessment/empeo-everyone.png'"
																type="picUser"
																size="medium"
																width="80px"
																[allowRemove]="true"
																iconClass="gf-icon-close"
																(remove)="remove($event, employees)"
															></go5-avatar-selected-item>
														</div>
													</ng-template>
												</go5-dropdown-selection>
												<ng-template [ngIf]="employees?.length != listPeopleInDepartment?.length">
													<ng-template ngFor [ngForOf]="employeesData" let-item>
														<go5-avatar-selected-item
															style="margin-right: 5px"
															title="{{ item.firstname }}"
															src="{{ item.pictureURL }}"
															type="picUser"
															size="medium"
															width="32px"
															[allowRemove]="true"
															iconClass="gf-icon-close"
															(remove)="remove($event, item.employeeId)"
														></go5-avatar-selected-item>
													</ng-template>
												</ng-template>
											</div>
										</div>
										<div *ngIf="isEdit" class="d-grid gap-8">
											<label class="go5-text-color-8">{{ "emp010_current_supervisor" | translate }}</label>
											<label class="go5-text-color-10 go5-text-title-strong">{{ prevUpperSupervisorFullName }}</label>
										</div>
										<!-- ผู้บังคับบัญชาใหม่ -->
										<div style="width: 100%; margin-top: 16px">
											<ng-container *ngTemplateOutlet="newSupervisorId; context: { form: form }"></ng-container>
										</div>
									</div>
								}
								<!-- โอนย้ายทั้งสังกัด -->
								@case (enumProcessAction.massTransfer) {
									<div *ngIf="!isEdit">
										<label class="go5-text-body go5-text-color-8">{{ "department_employee" | translate }}</label
										><br />
										<label class="go5-text-title-strong go5-text-color-12" style="margin-top: 8px"
											>{{ massTransferTotalEmployee }} {{ "common_people" | translate }}</label
										>
									</div>
									<div *ngIf="!isEdit">
										<label class="go5-text-body go5-text-color-8"
											>{{ "choose_employee_mass_tranfer" | translate }}*</label
										><br />
										<div style="margin-top: 8px; display: flex; flex-wrap: wrap" class="dropdown-picker">
											<go5-dropdown-selection
												data-testid="choose_employee_transfer"
												[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? peopleFields : peopleFields_EN"
												[dataSource]="listPeopleInDepartment"
												[value]="employees"
												(filtering)="onFilterOrg($event)"
												(selected)="chooseEmployeeTransfer($event)"
												mode="multi"
												style="margin-right: 5px"
												[popupWidth_px]="276"
											>
												<ng-template #toggleTemplate>
													<div
														style="text-align: center; width: fit-content; display: grid"
														*ngIf="employees?.length != listPeopleInDepartment?.length"
													>
														<em class="gfc-add-participants addIcon"></em>
														<label class="go5-text-extra-small go5-text-color-5" style="padding-top: 2px">{{
															"common_add" | translate
														}}</label>
													</div>

													<div style="text-align: center" *ngIf="employees?.length == listPeopleInDepartment?.length">
														<go5-avatar-selected-item
															data-testid="avatar_selected_item"
															title="{{ 'common_all' | translate }}"
															[src]="assetUrl + 'images/empeo/assessment/empeo-everyone.png'"
															type="picUser"
															size="medium"
															width="80px"
															[allowRemove]="true"
															iconClass="gf-icon-close"
															(remove)="remove($event, employees)"
														></go5-avatar-selected-item>
													</div>
												</ng-template>
											</go5-dropdown-selection>
											<ng-template [ngIf]="employees?.length != listPeopleInDepartment?.length">
												<ng-template ngFor [ngForOf]="employeesData" let-item>
													<go5-avatar-selected-item
														data-testid="avatar_selected_item_fristname"
														style="margin-right: 5px"
														title="{{ item.firstname }}"
														src="{{ item.pictureURL }}"
														type="picUser"
														size="medium"
														width="32px"
														[allowRemove]="true"
														iconClass="gf-icon-close"
														(remove)="remove($event, item.employeeId)"
													></go5-avatar-selected-item>
												</ng-template>
											</ng-template>
										</div>
									</div>
									<!-- สังกัดเดิม -->
									<div class="p-0" *ngIf="isEdit">
										<ng-container *ngTemplateOutlet="InputOldDepartment; context: { form: form }"></ng-container>
									</div>
									<!-- สังกัดใหม่ -->
									<div style="width: 100%">
										<ng-container *ngTemplateOutlet="InputNewDepartment; context: { form: form }"></ng-container>
									</div>
									<!-- ผู้บังคับบัญชา -->
									<div>
										<ng-container *ngTemplateOutlet="InputSupervisor; context: { form: form }"></ng-container>
									</div>
								}
								<!-- โอนย้ายข้ามบริษัท -->
								@case (enumProcessAction.crossCompanyTransfer) {
									<div style="padding-top: 0px">
										<div class="d-flex" style="justify-content: space-between">
											<!-- สังกัดเดิม -->
											<div class="p-0" style="width: 48%">
												<ng-container *ngTemplateOutlet="InputOldDepartment; context: { form: form }"></ng-container>
											</div>
											<!-- สังกัดใหม่ -->
											<div class="p-0" style="width: 48%">
												<ng-container *ngTemplateOutlet="InputNewDepartment; context: { form: form }"></ng-container>
											</div>
										</div>
										<!-- ผู้บังคับบัญชา -->
										<div class="p-0" style="width: 100%; margin-top: 16px">
											<ng-container *ngTemplateOutlet="InputUpperSupervisor; context: { form: form }"></ng-container>
										</div>
										<div class="d-grid gap-8" style="margin-top: 16px" *ngIf="tabPermissionPayroll">
											<div class="d-flex gap-16">
												<!-- ปรับเงินเดือน* -->
												<div>
													<div class="d-flex d-flex-center gap-8">
														<label class="go5-text-color-8 pb-1" style="display: block"
															>{{ "ess009_salary_adjustment" | translate }}*</label
														>
														<einfo-tooltip
															key="movement_new_salary_tooltip"
															size="s"
															position="{{ isMobile ? 'right' : 'top' }}"
														>
														</einfo-tooltip>
													</div>
													<div class="ui-g-12 ui-g-nopad">
														<p-selectButton
															[options]="adjustSelected"
															[ngClass]="'secondary-select-btn'"
															(onChange)="changeIncomeAdjust()"
															formControlName="isIncomeAdjust"
															[(ngModel)]="isIncomeAdjust"
														>
															<ng-template let-item>
																<div
																	style="padding: 0.2em 1rem"
																	attr.data-testid="input_select_button_{{ item.label }}"
																>
																	{{ item.label | translate }}
																</div>
															</ng-template>
														</p-selectButton>
													</div>
												</div>
											</div>
											<div class="d-flex" [hidden]="!isIncomeAdjust">
												<div style="width: calc(50% - 8px)">
													<ng-container *ngTemplateOutlet="InputIncomeAmount; context: { form: form }"></ng-container>
												</div>
											</div>
										</div>
									</div>
								}
							}
						</ng-template>

						<div class="d-flex direction-column gap-16">
							<div class="d-flex">
								<label class="go5-text-header-5 go5-text-color-12">
									{{ "emp001_transfer_information" | translate }}
								</label>
							</div>
							@if (!(masterSetId > 0)) {
								<div class="d-flex direction-column gap-8">
									<!-- วันที่มีผล -->
									@if (!(masterSetId > 0)) {
										<div class="d-flex direction-column">
											<label class="go5-text-color-8 pb-1" style="display: block"
												>{{ "common_effective_date" | translate }}*</label
											>
											<go5-datepicker
												data-testid="input-effective-date"
												[format]="DateFormat.dateLong"
												[allowShowRemove]="false"
												[(ngModel)]="dateEffective"
												formControlName="dateEffective"
												[isShowTime]="false"
												[error]="
													(form.controls['dateEffective'].invalid && form.controls['dateEffective'].touched) ||
													(checkValid && dateEffective == null)
												"
												[errorMessage]="
													'validation_please_choose' | translate: { titleName: 'common_effective_date' | translate }
												"
												placeholder="{{ 'common_please_choose' | translate }}"
												(ngModelChange)="countDateProbationExpire(); selectDate()"
											>
											</go5-datepicker>
										</div>
									}

									<div class="d-flex" *ngIf="movementType == enumProcessAction.renewal">
										<go5-info-bar
											[hidden]="dateEffective == null || selectEmp == null"
											class="d-flex"
											[type]="infoBarType.Info"
											>{{
												"emp001_t04_infobar_message"
													| translate
														: {
																dateEffective: dateEffective | date: "d MMMM yyyy" : (dataShare.currentLanguage | async)
														  }
											}}
										</go5-info-bar>
									</div>
									<div class="d-flex" *ngIf="movementType == enumProcessAction.resign">
										<go5-info-bar
											[hidden]="dateEffective == null || selectEmp == null"
											class="d-flex"
											[type]="infoBarType.Info"
											>{{
												"emp011_date_resign_info_movement"
													| translate
														: {
																dateEffective: dateResign | date: "d MMMM yyyy" : (dataShare.currentLanguage | async)
														  }
											}}
										</go5-info-bar>
									</div>
								</div>
							}
							<!-- รายละเอียด -->
							<div class="d-flex direction-column">
								<label class="go5-text-color-8 pb-1" style="display: block">{{
									"ess001_description" | translate
								}}</label>
								<go5-textarea
									data-testid="input-text-area"
									category="primary"
									formControlName="remarks"
									size="extra-large"
									[placeholder]="'common_detail_placeholder' | translate"
									[error]="form.controls['remarks'].invalid && form.controls['remarks'].touched"
									maxlength="2000"
								></go5-textarea>
							</div>
							<div class="d-flex direction-column" *ngIf="userInfo.hasMovementWorkflow || masterSetId > 0">
								<label class="go5-text-color-8 pb-2" style="display: block">{{
									"next_approver_text" | translate
								}}</label>
								<label class="go5-text-body-strong go5-color-primary" style="display: block">{{
									nextApproval ? nextApproval : "-"
								}}</label>
							</div>
						</div>
					</div>
				</div>
				@if (isMobile) {
					<ng-container [ngTemplateOutlet]="footer" />
				}
			</form>
		</div>
	}
</ng-template>

<ng-template #footer>
	<div id="footer" class="footer-layout d-grid gap-8 px-3">
		<div class="d-flex">
			<go5-button
				class="btn-footer"
				data-testid="button_button_submit"
				category="primary"
				size="medium"
				[label]="confirmButtonLabel"
				type="button"
				[loading]="isClicked"
				(click)="onSubmit()"
			>
			</go5-button>
		</div>
	</div>
</ng-template>

<empeo-people-picker
	*ngIf="displayEmployee && !isMobile"
	[isOpen]="displayEmployee && !isMobile"
	[lockFilter]="lockFilter"
	(closePeoplePicker)="closePeoplePicker()"
	[specialType]="1"
	titleName="common_choose_employee"
	btnSubmit="common_choose"
	[(ngModel)]="selectEmp"
	[isMulti]="false"
	(onChange)="getEmployee()"
></empeo-people-picker>

<ng-template #contentDisplayEmployee>
	<empeo-people-picker
		[isOpen]="displayEmployee && isMobile"
		[lockFilter]="lockFilter"
		(closePeoplePicker)="closePeoplePicker()"
		[specialType]="1"
		titleName="common_choose_employee"
		btnSubmit="common_choose"
		[(ngModel)]="selectEmp"
		[isSidebar]="false"
		[isMulti]="false"
		(onChange)="getEmployee()"
	></empeo-people-picker>
</ng-template>

<go5-sidebar
	[(visible)]="isDialogSubordinate"
	[textHeader]="'emp001_subordinate' | translate"
	(closed)="openDialogSubordinate(false)"
	[isShowFooter]="false"
>
	<div class="ui-g-12 ui-g-nopad">
		<div class="ui-g-12 px-3">
			<div
				class="ui-g-12 search-table-outter ui-g-nopad height-table"
				style="overflow: auto; height: calc(100vh - 100px)"
			>
				<table class="hover" style="width: 100%; border: 0">
					<thead>
						<tr>
							<th id="none" style="display: none"></th>
							<th id="emp001_employee_no" class="th-sm-1 text-center" style="width: 20%" style="text-align: left">
								<label for="input">{{ "emp001_employee_no" | translate }}</label>
							</th>
							<th id="sys012_employee_name" class="th-sm-1 text-left" style="width: 80%" style="text-align: left">
								<label for="input">{{ "sys012_employee_name" | translate }}</label>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of subordinateList; let i = index">
							<td style="display: none"></td>
							<td class="text-center">{{ item.employeeNo }}</td>
							<td class="text-left">
								<div class="contacts">
									<div class="ui-g-12 ui-g-nopad ellipsis" style="text-align: left">
										<img class="small-image" library="verona-layout" src="{{ item.pictureThumbnailURL }}" alt="user" />
										<span
											go5-tooltip-placement="bottom"
											go5-tooltip-mode="hover"
											go5-tooltip="{{
												dataShare.getIsTH(dataShare.currentLanguage | async) ? item.fullname : item.fullname_EN
											}}"
											class="name ellipsis text-body-strong"
											>{{
												dataShare.getIsTH(dataShare.currentLanguage | async) ? item.fullname : item.fullname_EN
											}}</span
										><br />
										<span
											go5-tooltip-placement="bottom"
											go5-tooltip-mode="hover"
											go5-tooltip="{{
												dataShare.getIsTH(dataShare.currentLanguage | async) ? item.positionName : item.positionName_EN
											}}"
											class="position ellipsis m-0"
											>{{
												dataShare.getIsTH(dataShare.currentLanguage | async) ? item.positionName : item.positionName_EN
											}}
											({{
												dataShare.getIsTH(dataShare.currentLanguage | async) ? item.rankName : item.rankName_EN
											}})</span
										>
									</div>
								</div>
							</td>
						</tr>
						<tr *ngIf="subordinateList == null || subordinateList?.length == 0" style="pointer-events: none">
							<td colspan="8">
								<div class="empty-state-body">
									<div class="ui-g-12" style="display: flex; justify-content: center">
										<svg class="no-data"></svg>
									</div>
									<div class="ui-g-12" style="display: flex; flex-direction: column; align-items: center">
										<p>{{ "common_no_data" | translate }}</p>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</go5-sidebar>

<ng-template #InputDepartment let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="dropdown_single_department"
			[allowShowRemove]="false"
			[mode]="DropdownSelectMode.Single"
			[dataSource]="organizeNodeDDL"
			label="{{
				movementType == enumProcessAction.crossCompanyTransfer
					? ('ess009_department_current' | translate) + '*'
					: ('common_department' | translate) + '*'
			}}"
			placeholder="{{ 'common_please_choose' | translate }}"
			popupType="slide"
			categoryItem="item"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? filedOrganize : filedOrganizeEN"
			(selected)="departmentId = $event.value"
			[error]="!form.controls['departmentId'].valid && form.controls['departmentId'].touched"
			[errorMessage]="'please_choose_department' | translate"
			formControlName="departmentId"
			[value]="departmentId"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputOldDepartment let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="dropdown_single_department"
			[dataSource]="organizeNodeDDL"
			[label]="('ess009_department_current' | translate) + '*'"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? filedOrganize : filedOrganizeEN"
			[mode]="DropdownSelectMode.Single"
			[placeholder]="'report_department' | translate"
			[disabled]="movementType == massTransfer ? false : true"
			[allowShowRemove]="false"
			formControlName="departmentIdOld"
			popupType="slide"
			[error]="
				movementType == massTransfer
					? (!form.controls['departmentIdOld'].valid && form.controls['departmentIdOld'].touched) ||
						checkValidDepartmentOld
					: false
			"
			[errorMessage]="'please_choose_department_current' | translate"
			(selected)="movementType == massTransfer ? getListPeople($event) : (departmentIdOld = $event.value)"
			[value]="departmentIdOld"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputNewDepartment let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="dropdown_single_department"
			[allowShowRemove]="false"
			[mode]="DropdownSelectMode.Single"
			[dataSource]="organizeNodeDDL"
			label="{{
				movementType == enumProcessAction.crossCompanyTransfer
					? ('ess009_department_new' | translate) + '*'
					: ('emp007_agency_transfer_to' | translate) + '*'
			}}"
			placeholder="{{ 'common_please_choose' | translate }}"
			popupType="slide"
			categoryItem="item"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? filedOrganize : filedOrganizeEN"
			(selected)="chooseNewOrg($event)"
			[error]="!form.controls['departmentId'].valid && form.controls['departmentId'].touched"
			[errorMessage]="'please_choose_department_new' | translate"
			formControlName="departmentId"
			[value]="departmentId"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputSupervisor let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="dropdown_single_uppersupervisorid"
			[label]="'emp001_upper_chain_of_command' | translate"
			[dataSource]="listPeople"
			[allowLocalize]="false"
			[fields]="data.getIsTH(data.currentLanguage | async) ? peopleFields : peopleFields_EN"
			placeholder="{{ 'emp001_upper_chain_of_command_placeholder' | translate }}"
			(clear)="clearPeople()"
			cssClass="dropdown-large-primary"
			mode="single"
			formControlName="upperSupervisorId"
			[(ngModel)]="employeeModel.upperSupervisorId"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputRank let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="input_dropdown_rank"
			[allowShowRemove]="false"
			[dataSource]="ranksDDL"
			label="{{ ('emp001_rank' | translate) + '*' }}"
			placeholder="{{ 'common_please_choose' | translate }}"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
			[mode]="DropdownSelectMode.Single"
			formControlName="rankNo"
			(selected)="selectRankNo($event)"
			[value]="rankNo"
			[error]="!form.controls['rankNo'].valid && form.controls['rankNo'].touched"
			[errorMessage]="'please_choose_rank' | translate"
			[allowFiltering]="false"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputEmploymentType let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="input_dropdown_employee"
			[allowShowRemove]="false"
			[dataSource]="employmentTypesDDL"
			label="{{ ('emp001_employment_type' | translate) + '*' }}"
			placeholder="{{ 'common_default_select' | translate }}"
			[mode]="DropdownSelectMode.Single"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
			formControlName="employmentType"
			(selected)="employmentType = $event.value"
			[value]="employmentType"
			[error]="!form.controls['employmentType'].valid && form.controls['employmentType'].touched"
			[errorMessage]="'please_choose_employment_type' | translate"
			[allowFiltering]="false"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputPosition let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="dropdown_single_position"
			[allowShowRemove]="false"
			[mode]="DropdownSelectMode.Single"
			[dataSource]="positionDDL"
			label="{{ ('emp001_position' | translate) + '*' }}"
			placeholder="{{ 'common_please_choose' | translate }}"
			popupType="slide"
			categoryItem="item"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
			formControlName="positionId"
			[value]="positionId"
			[error]="!form.controls['positionId'].valid && form.controls['positionId'].touched"
			[errorMessage]="'validation_please_choose' | translate: { titleName: 'emp001_position' | translate }"
			(selected)="selectedPosition($event)"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputUpperSupervisor let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="dropdown_single_upper"
			[allowShowRemove]="false"
			[mode]="DropdownSelectMode.Single"
			[dataSource]="listPeople"
			label="{{
				('emp001_t04_commander' | translate) +
					(movementType == enumProcessAction.agencyTransfer || movementType == enumProcessAction.crossCompanyTransfer
						? ''
						: '*')
			}}"
			placeholder="{{ 'common_default_select' | translate }}"
			popupType="slide"
			categoryItem="item"
			(filtering)="onFilterEmployee($event)"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? peopleFields : peopleFields_EN"
			formControlName="upperSupervisorId"
			[error]="
				movementType == enumProcessAction.agencyTransfer || movementType == enumProcessAction.crossCompanyTransfer
					? false
					: !form.controls['upperSupervisorId'].valid && form.controls['upperSupervisorId'].touched
			"
			[errorMessage]="'please_choose_supervisor' | translate"
			[(ngModel)]="upperSupervisorId"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #newSupervisorId let-form="form">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="dropdown_single_upper"
			[allowShowRemove]="false"
			[mode]="DropdownSelectMode.Single"
			[dataSource]="listPeople"
			label="{{
				('emp010_new_supervisor' | translate) +
					(movementType == enumProcessAction.agencyTransfer || movementType == enumProcessAction.crossCompanyTransfer
						? ''
						: '*')
			}}"
			placeholder="{{ 'common_please_choose' | translate }}"
			popupType="slide"
			categoryItem="item"
			(filtering)="onFilterEmployee($event)"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? peopleFields : peopleFields_EN"
			formControlName="newSupervisorId"
			[error]="
				movementType == enumProcessAction.agencyTransfer || movementType == enumProcessAction.crossCompanyTransfer
					? false
					: !form.controls['newSupervisorId'].valid && form.controls['newSupervisorId'].touched
			"
			[errorMessage]="'please_choose_supervisor' | translate"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputPaymentMethod let-form="form" let-isLabel="isLabel">
	<div [formGroup]="form">
		<go5-dropdown-selection
			data-testid="dropdown_single_payment"
			[allowShowRemove]="false"
			[mode]="DropdownSelectMode.Single"
			[dataSource]="paymentMethodDDL"
			label="{{ isLabel ? ('emp001_t14_payment_method' | translate) + '*' : '' }}"
			placeholder="{{ 'common_default_select' | translate }}"
			popupType="slide"
			categoryItem="item"
			[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
			formControlName="paymentMethod"
			[value]="paymentMethod"
			[error]="!form.controls['paymentMethod'].valid && form.controls['paymentMethod'].touched"
			[errorMessage]="'please_choose_employment_type' | translate"
			(selected)="paymentMethod = $event.value"
		>
		</go5-dropdown-selection>
	</div>
</ng-template>

<ng-template #InputIncomeAmount let-form="form">
	<div [formGroup]="form" class="d-grid gap-8">
		<div class="d-flex d-flex-center gap-8" style="justify-content: space-between">
			<label class="go5-text-color-8" style="display: block">{{ "common_adjust_to" | translate }}*</label>
			@if (isMobile) {
				<span
					[ngStyle]="{
						'margin-bottom':
							(!form.controls['incomeAmount'].valid || !validFormIncomeAmount) && checkValid && this.isShowSalary
								? '20px'
								: ''
					}"
					[class]="isShowSalary ? 'gf-icon-close-eye' : 'gf-icon-eye'"
					class="ml-2 pointer f-24 color-primary"
					(click)="this.isMobile ? (this.isShowSalary = !this.isShowSalary) : showSalary()"
				>
				</span>
			}

			@if (!isMobile) {
				<span
					[ngStyle]="{
						'margin-bottom':
							(!form.controls['incomeAmount'].valid || !validFormIncomeAmount) && checkValid && this.isShowSalary
								? '20px'
								: ''
					}"
					[class]="isShowSalary ? 'gf-icon-close-eye' : 'gf-icon-eye'"
					class="ml-2 pointer f-24 color-primary"
					(click)="this.isMobile ? (this.isShowSalary = !this.isShowSalary) : showSalary()"
					go5-tooltip-placement="top"
					go5-tooltip-mode="hover"
					go5-tooltip="{{ (isShowSalary ? 'pay001_close_salary_tooltip' : 'pay001_show_salary_tooltip') | translate }}"
				>
				</span>
			}
		</div>
		<div>
			<go5-textfield
				data-testid="input-text-field"
				*ngIf="isShowSalary"
				data-testid="input_text_income_amount"
				type="currency"
				[format]="'0.2-2'"
				placeholder="0"
				formControlName="incomeAmount"
				[error]="(!form.controls['incomeAmount'].valid || !validFormIncomeAmount) && checkValid"
				[errorMessage]="'new_salary_validate' | translate"
			>
			</go5-textfield>
			<go5-textfield
				data-testid="input-text-field"
				*ngIf="!isShowSalary"
				data-testid="input_text_income_amount"
				type="currency"
				[format]="'0.2-2'"
				[disabled]="true"
				placeholder="xxxx.xx"
			>
			</go5-textfield>
		</div>
	</div>
</ng-template>
<ng-template #viewEmployee>
	<div class="d-flex" style="justify-content: space-between; align-items: center">
		<div class="d-flex gap-16" style="align-items: center">
			<img style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover" [src]="image" />
			<div class="d-flex" [ngStyle]="{ width: isMobile ? '60vw' : '300px' }" style="flex-direction: column">
				<span class="name ellipsis go5-text-body-strong go5-text-color-12" style="width: fit-content">
					<label
						go5-tooltip-placement="top"
						go5-tooltip-mode="hover"
						go5-tooltip="{{
							dataShare.getIsTH(dataShare.currentLanguage | async) ? employee.nameSurname : employee.nameSurname_EN
						}}"
					>
						{{ dataShare.getIsTH(dataShare.currentLanguage | async) ? employee.nameSurname : employee.nameSurname_EN }}
					</label>

					<em
						data-testid="open_emp_view_3"
						class="pl-1 gf-icon-show-all go5-text-color-6 pointer"
						go5-tooltip-placement="top"
						go5-tooltip-mode="hover"
						go5-tooltip="{{ 'common_view1' | translate }}"
						(click)="openEmployeeView()"
					></em>
				</span>
				<span class="mt-0 position ellipsis go5-text-small go5-text-color-8" style="max-width: 80%">
					<label
						go5-tooltip-placement="top"
						go5-tooltip-mode="hover"
						go5-tooltip="{{
							dataShare.getIsTH(dataShare.currentLanguage | async) ? employee.positionName : employee.positionName_EN
						}}"
					>
						{{
							dataShare.getIsTH(dataShare.currentLanguage | async) ? employee.positionName : employee.positionName_EN
						}}
					</label>
				</span>
			</div>
		</div>
	</div>
</ng-template>
<go5-dialog
	*ngIf="displayAssessment"
	[(visible)]="displayAssessment"
	[width]="'70vw'"
	[textHeader]="dataShare.getIsTH(dataShare.currentLanguage | async) ? assessment?.name : assessment?.name_EN"
	[padding]="0"
	[isShowHeader]="isStartedSurvey"
	[isShowCloseIcon]="!isMobile"
	[isFullScreenTablet]="true"
	(closeClick)="discardChange($event)"
>
	<div [ngStyle]="{ padding: !isStartedSurvey ? '60px 0 0 0' : '' }" style="min-height: 600px">
		<gfc-assessment
			[isDialog]="true"
			(isStartedSurvey)="onUpdateAction($event)"
			(successAssessmentEvent)="successAssessment($event)"
		>
		</gfc-assessment>
	</div>
</go5-dialog>

@if (displayAssetsSidebar) {
	<app-assets-sidebar
		[selectAssetId]="selectAssetId"
		(closeSidebar)="onHideSidebarAsset($event)"
		[displayAssetsSidebar]="displayAssetsSidebar"
	>
	</app-assets-sidebar>
}
