import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeMovement } from "../../employee-movement/shared/employee-movement.model";
import { CommonService } from "../../../shared/service/common.service";

@Injectable()
export class EmployeeResignService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	addEmployeeMovementTransaction(emp: EmployeeMovement) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeMovement/AddEmployeeMovementTransaction", body, httpOptions);
	}
}
