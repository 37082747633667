import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EmployeeMovementService } from "../employee-movements/employee-movement/shared/employee-movement.service";
import { BusinessCode } from "../shared/enum/businessCode.enum";
import { ToastHelperService } from "../shared/service/toast-helper.service";

@Injectable()
export class MovementService {
	constructor(
		private translate: TranslateService,
		private _toastHelperService: ToastHelperService,
		private employeeMovementService: EmployeeMovementService,
	) {}

	downloadDocument(movementTransactionId, language) {
		this.employeeMovementService
			.generateCertificateDocument(movementTransactionId, language)
			.then((res) => {
				if (res.status.code == BusinessCode.Successful) {
					this.genData(res.data);
					this._toastHelperService.successText(this.translate.instant("toast_success_file_downloaded"));
				} else {
					this._toastHelperService.errorText(this.translate.instant("cannot_download_data"));
				}
			})
			.catch(() => {
				this._toastHelperService.errorText(this.translate.instant("cannot_download_data"));
			});
	}
	genData(data: any) {
		let byteCharacters = atob(data.file);

		let byteNumbers = new Array(byteCharacters.length);
		for (var i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		let byteArray = new Uint8Array(byteNumbers);
		let blob = new Blob([byteArray], { type: data.fileMimeType });
		let link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.setAttribute("visibility", "hidden");
		link.download = data.fileName;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
}
