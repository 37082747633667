import { NgClass, NgStyle } from "@angular/common";
import { Component, ViewEncapsulation, Input } from "@angular/core";

@Component({
	selector: "app-loading-view",
	templateUrl: "./loading-view.component.html",
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, NgStyle],
})
export class LoadingViewComponent {
	@Input() size: string = "s"; // s m l xl
	@Input() loadingPic: boolean = false; // s m l xl
	@Input() customClass: boolean = false; // s m l xl
	@Input() color: string = "white"; // color
	@Input() margin: string = "margin: 100px auto 0";
}

@Component({
	selector: "app-loading-view-stand-alone",
	templateUrl: "./loading-view.component.html",
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, NgStyle],
})
export class LoadingViewStanAloneComponent {
	@Input() size: string = "s"; // s m l xl
	@Input() loadingPic: boolean = false; // s m l xl
	@Input() customClass: boolean = false; // s m l xl
	@Input() color: string = "white"; // color
	@Input() margin: string = "margin: 100px auto 0";
}
