import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

@Injectable()
export class SuperAdminService {
	constructor(
		private http: HttpClient,
		@Inject("GOFIVE_API_URL") private apiCoreUrl: string,
	) {}
	getCompany() {
		return firstValueFrom(this.http.get<any>(`${this.apiCoreUrl}v1/CompanyProfiles/empeo`));
	}
	getSystemAccount(companyId: any) {
		return firstValueFrom(this.http.get<any>(`${this.apiCoreUrl}Authorizations/bypass/${companyId}`));
	}
}
