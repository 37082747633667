import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { LeaveType } from "./leave-type.model";
import { firstValueFrom } from "rxjs";
import { ApiResult } from "src/app/shared/models/api-result.model";
import { CustomSettingLeaveEmployeeModel } from "src/app/document-forms/shared/document-forms.model";

@Injectable()
export class LeaveTypeService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getLeaveTypeDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "LeaveType/GetLeaveTypeDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getLeaveTypeByCompanyId(companyId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "LeaveType/GetLeaveTypeByCompanyId/" + companyId));
	}

	getLeaveTypeById(leaveTypeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "LeaveType/GetLeaveTypeById/" + leaveTypeId)).then(
			(res) => <LeaveType>res,
		);
	}

	getListOfLeaveTypeById(employeeId: number, leaveCategoryId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "LeaveType/GetListOfLeaveTypeById/" + employeeId + "/" + leaveCategoryId),
		).then((res) => <LeaveType[]>res);
	}

	addLeaveType(lev: LeaveType) {
		var body = JSON.stringify(lev);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveType/AddLeaveType", body, httpOptions);
	}
	updateLeaveTypeDetailAllSite(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveType/UpdateLeaveTypeDetailAllSite", body, httpOptions);
	}

	addLeaveTypeAndLeaveTypeDetail(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveType/AddLeaveTypeAndLeaveTypeDetail", body, httpOptions);
	}

	editLeaveTypeAndLeaveTypeDetail(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveType/EditLeaveTypeAndLeaveTypeDetail", body, httpOptions);
	}

	editLeaveType(lev: LeaveType) {
		var body = JSON.stringify(lev);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveType/EditLeaveType", body, httpOptions);
	}

	deleteLeaveType(leaveTypeId: string[]) {
		var body = JSON.stringify(leaveTypeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveType/DeleteLeaveType", body, httpOptions);
	}

	getDDLLeaveCategory() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "category/leavetype")).then((res) => <Dropdown[]>res);
	}
	getDDLCategoryLeaveType() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "category/leavetype/leave")).then((res) => <ApiResult>res);
	}

	getCustomSettingEmployeeByLeaveTypeId(model: any) {
		const body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<CustomSettingLeaveEmployeeModel[]>(
			this.apiUrl + "LeaveType/GetCustomSettingEmployeeByLeaveTypeId",
			body,
			httpOptions,
		);
	}
}
