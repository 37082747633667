import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { CommonService } from "../../../shared/service/common.service";
import { EmployeeFinancial, EmployeeFinancialHistories, EmployeePayroll } from "./employee-financial.model";
import { firstValueFrom } from "rxjs";
import { SharingService } from "src/app/shared/sharing.service";
import { ApiResult } from "src/app/shared/models/api-result.model";

@Injectable()
export class EmployeeFinancialService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
		private sharing: SharingService,
	) {}

	getEmployeeFinancialByEmployeeIdAndCategoryAndCompanyId(category: number, companyId: number, employeeId: number) {
		return firstValueFrom(
			this.http.get(
				`${this.apiUrl}EmployeeFinancial/GetEmployeeFinancialByEmployeeIdAndCategoryAndCompanyId/${category}/${companyId}/${employeeId}`,
			),
		).then((res) => <any>res);
	}

	getEmployeeFinancialByEmployeeId(category: number, companyId: number, employeeId: number) {
		return firstValueFrom(
			this.http.get(
				`${this.apiUrl}EmployeeFinancial/GetEmployeeFinancialByEmployeeIdAndCategory/${category}/${companyId}/${employeeId}`,
			),
		).then((res) => <any>res);
	}

	getEmployeeFinancialeId(
		employeeId: number,
		year: number,
		periodCalculateNo: number,
		companyId: number,
		periodExtendNo: number = 0,
	) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"EmployeeFinancial/GetEmployeeFinancialById/" +
					employeeId +
					"/" +
					year +
					"/" +
					periodCalculateNo +
					"/" +
					companyId +
					"?periodExtendNo=" +
					periodExtendNo,
			),
		).then((res) => <EmployeePayroll>res);
	}
	getEmployeeFinancialTransactionByCategory(
		employeeId: number,
		year: number,
		periodCalculateNo: number,
		companyId: number,
		financialCategory: number,
	) {
		return firstValueFrom(
			this.http.get(
				`${this.apiUrl}EmployeeFinancial/GetEmployeeFinancialTransactionByCategory/${employeeId}/${year}/${periodCalculateNo}/${companyId}/${financialCategory}`,
			),
		).then((res) => <any>res);
	}

	getEmployeeEditHistoryByEmployeeIdDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeFinancial/GetEditDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	editEmployeeFinancial(emp: EmployeePayroll) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFinancial/EditEmployeeFinancialById/", body, httpOptions);
	}

	addHistoryEmployeeFinancial(model: EmployeeFinancialHistories[]) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFinancial/AddHistoryEmployeeFinancial/", body, httpOptions);
	}

	compareEmployeeFinancials(newValue: EmployeeFinancial[]) {
		var body = JSON.stringify(newValue);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFinancial/CompareEmployeeFinancials/", body, httpOptions);
	}
	addEmployeeFinancial(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFinancial/AddEmployeeFinancial/", body, httpOptions);
	}

	getBonusHistory(employeeId) {
		return firstValueFrom(this.http.get<any>(`${this.apiUrl}EmployeeFinancial/GetBonusHistory/${employeeId}`));
	}

	getEmployeeTaxDetails(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFinancial/GetEmployeeTaxDetails/", body, httpOptions);
	}

	getEmployeeSalaryAdjustmentHistories(employeeId, search) {
		return firstValueFrom(
			this.http.get(
				`${this.apiUrl}v1/employees/${employeeId}/adjustments/histories${this.sharing.getQueryString(search)}`,
			),
		).then((res) => <ApiResult>res);
	}

	getEmployeeFinancials(employeeId, search) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}v1/employees/${employeeId}/financials${this.sharing.getQueryString(search)}`),
		).then((res) => <ApiResult>res);
	}

	getEmployeeSeverance(employeeId, dateEffective) {
		const queryString = this.sharing.getQueryString({ dateEffective: dateEffective });
		return firstValueFrom(
			this.http.get(`${this.apiUrl}v1/employees/${employeeId}/severance${this.sharing.getQueryString(queryString)}`),
		).then((res) => <ApiResult>res);
	}
}
