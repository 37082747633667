import { Component, EventEmitter, Injector, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { UserInfo } from "src/app/core/shared/user-info.model";
import { environment } from "../../../environments/environment";
import { DataSharingService } from "../../core/data-sharing.service";
import { AppConfig } from "../class/config";
import { GofiveCoreModuleEnum } from "../enum/module.enum";
import { isNullOrUndefined } from "../sharing.service";
import { SessionStorageKey } from "../enum/local-storage-key";

@Component({
	template: "",
	standalone: true,
})
export class BaseMicroComponent {
	private defaultValue: { path: string; version: string } = { path: "/main.js", version: "1.0.0" };
	private _authService!: AuthService;
	public get authService(): AuthService {
		if (typeof this._authService === "undefined") {
			this._authService = this._injector.get(AuthService);
		}
		return this._authService;
	}
	private _dataSharing!: DataSharingService;
	public get dataSharing(): DataSharingService {
		if (typeof this._dataSharing === "undefined") {
			this._dataSharing = this._injector.get(DataSharingService);
		}
		return this._dataSharing;
	}

	private _currentUserInfo: Subscription;

	@Output() onReady: EventEmitter<boolean> = new EventEmitter<boolean>();
	public counter = Array;
	public styleUrl: string;
	public theme: string = "EmpeoLight";
	public application = 102;
	public module: GofiveCoreModuleEnum;
	private componentCreated: boolean = false;
	public styleLoaded: boolean = true;
	public loaded: boolean = false;
	public user: any = null;

	private _language$: Subscription;
	private _language!: string;
	public get language(): string {
		return this.dataSharing.getLocale(this._language);
	}
	public get token(): string {
		const sessionAccessToken = sessionStorage.getItem(SessionStorageKey.access_token);
		if (sessionAccessToken) {
			return this.authService.accessToken || sessionAccessToken;
		} else {
			const urlParams = new URLSearchParams(window.location.search);

			// access token from url
			return urlParams.get("token");
		}
	}
	public get canActive(): boolean {
		return !isNullOrUndefined(this.module) && !isNullOrUndefined(AppConfig.config);
	}
	public versionTmp: number = new Date().getTime();
	public get link() {
		return this.canActive && this.module
			? environment.gofiveCoreWeb.baseUrl +
					this.module +
					this._module.path +
					"?v=" +
					(this._module.version || this.versionTmp)
			: null;
	}
	public get currentUser() {
		return this.user;
	}
	private get _module(): { path: string; version: string } {
		return AppConfig.config[this.module] ?? this.defaultValue;
	}

	constructor(private _injector: Injector) {
		this._language$ = this.dataSharing.currentLanguage.subscribe((lang: string) => (this._language = lang));
		this._currentUserInfo = this.dataSharing.currentUserInfo.subscribe((user: UserInfo) => {
			this.user = user;
			this.user.refCompanyId = 0;
		});
	}

	ngOnDestroy(): void {
		this._language$ && this._language$.unsubscribe();
		this._currentUserInfo && this._currentUserInfo.unsubscribe();
	}
	onCreated(event) {
		this.componentCreated = event.detail;
		this.onLoaded();
	}
	onLoaded() {
		this.loaded = this.componentCreated;
		this.onReady.emit(this.loaded);
	}
}
