export enum Package {
	WorkFromHome = 1,
	LiteMonthly = 2,
	BasicMonthly = 3,
	Basic = 4,
	Lite = 5,
	FreePayroll = 6,
	ProPlus = 7,
	Starter = 8,
	PayrollOutsource = 9,
	LiteQuarterly = 10,
	Free10 = 11,
	Free20 = 12,
	Free = 17,
	Free1 = 1031,
	Pro = 22,
	Free10Quarterly = 27,
	Free10Annual = 28,
	FreeQuarterly = 29,
	FreeAnnual = 30,
	Emconnect = 1061,
}
