import { ChangeDetectorRef, NgZone, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { isNullOrUndefined } from "../sharing.service";

@Pipe({
	name: "duration",
	standalone: true,
})
export class DurationPipe implements PipeTransform {
	constructor(
		private translate: TranslateService,
		private ref: ChangeDetectorRef,
		private _ngZone: NgZone,
	) {}
	/*
    #### Don't remove -> lang <- it use for translate ####
    1. value
    2. getValue is string => 'year','month','week','day','hour','minute','second' => return number
  */
	transform(value: any, lang?: any, enableToday: boolean = false, getValue: string = null): any {
		if (!value) return;
		let d = new Date(value);
		let now = new Date();

		if (enableToday && d.toLocaleDateString() == now.toLocaleDateString()) {
			return this.translate.instant("TIMER.today");
		}

		let seconds = Math.round((now.getTime() - d.getTime()) / 1000);
		let timeToUpdate = Number.isNaN(seconds) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
		this._ngZone.runOutsideAngular(() => {
			if (typeof window !== "undefined") {
				return window.setTimeout(() => {
					this._ngZone.run(() => this.ref.markForCheck());
				}, timeToUpdate);
			}
			return null;
		});

		return this.timeAgoSince(d, now, getValue);
	}

	private getSecondsUntilUpdate(seconds) {
		let min = 60;
		let hr = min * 60;
		let day = hr * 24;
		if (seconds < min) {
			// less than 1 min, update every 2 secs
			return 2;
		} else if (seconds < hr) {
			// less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) {
			// less then a day, update every 5 mins
			return 300;
		} else {
			// update every hour
			return 3600;
		}
	}

	private timeAgoSince(date: Date, now: Date, returnNum: string = null) {
		let second = Math.round((now.getTime() - date.getTime()) / 1000);
		let minute = Math.round((now.getTime() - date.getTime()) / (1000 * 60));
		let hour = Math.round((now.getTime() - date.getTime()) / (1000 * 60 * 60));
		let day = Math.round((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
		let week = Math.round((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 7));
		let month = now.getMonth() - date.getMonth();
		let year = now.getFullYear() - date.getFullYear();

		if (!isNullOrUndefined(returnNum)) {
			switch (returnNum) {
				case "year":
					return year;
				case "month":
					return month;
				case "week":
					return week;
				case "day":
					return day;
				case "hour":
					return hour;
				case "minute":
					return minute;
				default:
					return second;
			}
		}

		if (year >= 1) return `${Math.abs(year)} ${this.translate.instant("timer_yrAgo")}`;
		else if (month >= 1) return `${Math.abs(month)} ${this.translate.instant("timer_moAgo")}`;
		else if (week >= 1) return `${Math.abs(week)} ${this.translate.instant("timer_wkAgo")}`;
		else if (day >= 1) return `${Math.abs(day)} ${this.translate.instant("timer_dAgo")}`;
		else if (hour >= 1) return `${Math.abs(hour)} ${this.translate.instant("timer_hAgo")}`;
		else if (minute >= 1) return `${Math.abs(minute)} ${this.translate.instant("timer_mAgo")}`;
		else if (second >= 3) return `${this.translate.instant("timer_a_seconds_ago")}`;
		else return `${this.translate.instant("timer_justnow")}`;
	}
}
