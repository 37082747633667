import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AssessmentScoreSearch } from "../../../assessment/shared/assessment.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeAssessmentService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getAssessmentScoreByAssessorIdAndAssessmentId(model: AssessmentScoreSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(this.apiUrl + "AssessmentScore/GetScoreByAssessorIdAndAssessmentId", body, httpOptions);
	}
	getDDLAssesseeGroupByAssessmentSets(model: AssessmentScoreSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(this.apiUrl + "AssessmentScore/GetDDLAssesseeGroupByAssessmentSets", body, httpOptions);
	}

	getDDLAssesseeGroupByAssessmentMaster(model: AssessmentScoreSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(
			this.apiUrl + "AssessmentScore/GetDDLAssesseeGroupByAssessmentMaster",
			body,
			httpOptions,
		);
	}

	getDDLAssesseeGroupByAssesseeName(model: AssessmentScoreSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(this.apiUrl + "AssessmentScore/GetDDLAssesseeGroupByAssesseeName", body, httpOptions);
	}

	getDDLAssesseeGroupByAssessorName(model: AssessmentScoreSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(this.apiUrl + "AssessmentScore/GetDDLAssesseeGroupByAssessorName", body, httpOptions);
	}

	getChartAssessmentGroupByAssesseeName(model: AssessmentScoreSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(
			this.apiUrl + "AssessmentScore/GetChartAssessmentGroupByAssesseeName",
			body,
			httpOptions,
		);
	}

	getDDLAssesseeGroupByNo(model: AssessmentScoreSearch) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(this.apiUrl + "AssessmentScore/GetDDLAssesseeGroupByNo", body, httpOptions);
	}

	getAssessmentByScoreId(scoreId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "AssessmentScore/GetAssessmentByScoreId/" + scoreId)).then(
			(res) => <any>res,
		);
	}
}
