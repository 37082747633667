import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApprovalSearchModel, RequestHistorySearchModel } from "src/app/document-forms/approval/shared/approval.model";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { Dropdown } from "../../shared/models/dropdown.model";
import { WelfareDocumentForm, WelfareDocumentFormLog } from "./welfare-document.model";
import {
	SearchEmployeeWelfare,
	SearchWelfareCalculateNetAmount,
	SearchWelfareDocument,
	Welfare,
	WelfareDocumentBySelected,
	WelfareEmployeeUsage,
	WelfareSearchModel,
	WelfareShuttleBusEmployeeSearchModel,
} from "./welfare.model";
import { ApiResult } from "src/app/shared/models/api-result.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class WelfareService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		@Inject("VENIO_API4_URL") private venioApi4Url: string,
	) {}

	getDDLWelfareTypeByEmployeeId(catagoryId: number, employeeId) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Welfare/GetDDLWelfareTypeByEmployeeId/" + employeeId + "/" + catagoryId),
		).then((res) => <Dropdown[]>res);
	}

	getDDLWelfareTypeByCompanyId(catagoryId: number, companyId) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Welfare/GetDDLWelfareTypeByCompanyId/" + companyId + "/" + catagoryId),
		).then((res) => <Dropdown[]>res);
	}

	getFoodCouponDetailByBarcodeNo(barcodeNo: string, couponType: string) {
		var employeeLoginModel = {
			BarcodeNo: barcodeNo,
			CouponType: couponType,
		};
		var body = JSON.stringify(employeeLoginModel);
		return this.http.post(this.apiUrl + "Service/GetFoodCouponDetailByBarcodeNo", body, this.httpOptions);
	}
	getFoodCouponShopDetailByShopCode(couponType: string) {
		var employeeLoginModel = {
			CouponType: couponType,
		};
		var body = JSON.stringify(employeeLoginModel);
		return this.http.post(this.apiUrl + "Service/GetFoodCouponShopDetailByShopCode", body, this.httpOptions);
	}
	getWelfareDocumentForm(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "WelfareDocument/GetWelfareDocumentFormDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}
	getWelfareDataTable(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Welfare/GetWelfareDataTable", body, httpOptions);
	}

	getEmployeeWelfareDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Welfare/GetEmployeeWelfareDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	exportEmployeeWelfareToExcel(EmployeeWelfareSearch: SearchEmployeeWelfare) {
		var body = JSON.stringify(EmployeeWelfareSearch);
		return this.http.post(this.apiUrl + "Welfare/ExportEmployeeWelfareToExcel", body, this.httpOptions);
	}

	getWelfareById(welfareId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Welfare/GetWelfareById/" + welfareId)).then(
			(res) => <ApiResult>res,
		);
	}
	getWelfareDocumentById(welfareDocumentId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "WelfareDocument/GetWelfareDocumentById/" + welfareDocumentId),
		).then((res) => <WelfareDocumentForm>res);
	}
	getWelfareDocumentGroupMonth(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "WelfareDocument/GetWelfareDocumentGroupMonth", body, httpOptions);
	}
	addMasWelfare(model: Welfare) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "v1/welfares", body, this.httpOptions);
	}

	editMasWelfare(model: Welfare) {
		var body = JSON.stringify(model);
		return this.http.put(this.apiUrl + "v1/welfares", body, this.httpOptions);
	}

	addWelfareDocument(model) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WelfareDocument/AddWelfareDocument", body, this.httpOptions);
	}

	editWelfareDocument(model) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WelfareDocument/EditWelfareDocument", body, this.httpOptions);
	}

	deleteMasWelfare(welfareId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Welfare/DeleteMasWelfare/" + welfareId)).then((res) => res);
	}

	getDocumentFormForApprove(approvalSearchModel: ApprovalSearchModel) {
		return firstValueFrom(
			this.http.post(this.apiUrl + "WelfareDocument/GetDocumentFormForApprove", approvalSearchModel),
		).then((res) => res);
	}

	getProcessActionByWelfareDocumentId(welfareDocumenId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "WelfareDocument/GetProcessActionByWelfareDocumentId/" + welfareDocumenId),
		).then((res) => <any[]>res);
	}

	addProcessActionWelfareRequest(welfareDocumenId: any) {
		var body = JSON.stringify(welfareDocumenId);
		return this.http.post(this.apiUrl + "WelfareDocument/AddProcessActionWelfareDocument/", body, this.httpOptions);
	}

	getWelfareEmployeeUsagesByEmployeeId(model: SearchWelfareDocument) {
		var body = JSON.stringify(model);
		return firstValueFrom(
			this.http.post(this.apiUrl + "Welfare/GetWelfareEmployeeUsagesByEmployeeId", body, this.httpOptions),
		);
	}
	getWelfareEmployeeUsage(model: SearchWelfareCalculateNetAmount) {
		var body = JSON.stringify(model);
		return firstValueFrom(this.http.post(this.apiUrl + "Welfare/GetWelfareEmployeeUsage", body, this.httpOptions)).then(
			(r) => <WelfareEmployeeUsage>r,
		);
	}
	manageWelfareEmployeeUsage(welfareEmployeeUsage: WelfareEmployeeUsage) {
		var body = JSON.stringify(welfareEmployeeUsage);
		return this.http.post(this.apiUrl + "Welfare/ManageWelfareEmployeeUsage/", body, this.httpOptions);
	}

	AddProcessActionWelfareDocumentBySelected(welfareDocumentBySelected: WelfareDocumentBySelected) {
		var body = JSON.stringify(welfareDocumentBySelected);
		return this.http.post(
			this.apiUrl + "WelfareDocument/AddProcessActionWelfareDocumentBySelected/",
			body,
			this.httpOptions,
		);
	}

	calculateWelfareEmployeeUsage(year: number, employeeId: number, companyId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Welfare/CalculateWelfareEmployeeUsage/" + year + "/" + employeeId + "/" + companyId),
		).then((res) => <boolean>res);
	}

	getNotificationWelfareDocumentLogByEmployeeId(employeeId: number, welfareDocumentId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"WelfareDocument/GetNotificationWelfareDocumentLogByEmployeeId/" +
					employeeId +
					"/" +
					welfareDocumentId,
			),
		).then((res) => <any[]>res);
	}

	getWorkflowIdByWelfareId(welfareId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "WelfareDocument/GetWorkflowIdByWelfareId/" + welfareId)).then(
			(res) => <any[]>res,
		);
	}
	deleteWelfareAttachment(model) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WelfareDocument/DeleteWelfareAttachment/", body, this.httpOptions);
	}

	verificationWelfareEmployeeUsageByCategory(welfareCategoryId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Welfare/VerificationWelfareEmployeeUsageByCategory/" + welfareCategoryId),
		).then((res) => res);
	}

	getWelfareDocumentFormLogById(welfareDocumentId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "WelfareDocument/GetWelfareDocumentFormLogById/" + welfareDocumentId),
		).then((res) => <WelfareDocumentFormLog[]>res);
	}

	//#region shuttle-bus

	getTableWelfareShuttleBuses(model) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WelfareShuttleBus/GetTableWelfareShuttleBuses/", body, {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			observe: "response",
		});
	}
	changeBusLine(model) {
		var body = JSON.stringify(model);
		return this.http.put(this.apiUrl + "WelfareShuttleBus/ChangeBusLine/", body, this.httpOptions);
	}
	getWelfareShuttleBusByEmployeeId(employeeId) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}WelfareShuttleBus/GetWelfareShuttleBusByEmployeeId/${employeeId}`),
		).then((r) => <any>r);
	}
	getWelfareShuttleBusById(busLineId) {
		return firstValueFrom(this.http.get(`${this.apiUrl}WelfareShuttleBus/GetWelfareShuttleBusById/${busLineId}`)).then(
			(r) => <any>r,
		);
	}
	getTimeInShiftDDL() {
		return firstValueFrom(this.http.get(`${this.apiUrl}WelfareShuttleBus/GetTimeInShiftDDL`)).then((r) => <any>r);
	}

	addWelfareShuttleBus(model) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WelfareShuttleBus/AddWelfareShuttleBus/", body, this.httpOptions);
	}
	editWelfareShuttleBus(model) {
		var body = JSON.stringify(model);
		return this.http.put(this.apiUrl + "WelfareShuttleBus/EditWelfareShuttleBus/", body, this.httpOptions);
	}
	deleteWelfareShuttleBus(model) {
		var body = JSON.stringify(model);
		return this.http.put(this.apiUrl + "WelfareShuttleBus/DeleteWelfareShuttleBus/", body, this.httpOptions);
	}
	getWelfareShuttleBusEmployees(model: WelfareShuttleBusEmployeeSearchModel) {
		var body = JSON.stringify(model);
		return this.http.post<any>(
			this.apiUrl + "WelfareShuttleBusEmployee/GetWelfareShuttleBusEmployees/",
			body,
			this.httpOptions,
		);
	}
	getWelfareShuttleBusEmployeeByBusLineId(model) {
		var body = JSON.stringify(model);
		return this.http.post(
			this.apiUrl + "WelfareShuttleBusEmployee/GetWelfareShuttleBusEmployeeByBusLineId/",
			body,
			this.httpOptions,
		);
	}
	addWelfareShuttleBusEmployee(model) {
		var body = JSON.stringify(model);
		return this.http.post(
			this.apiUrl + "WelfareShuttleBusEmployee/AddWelfareShuttleBusEmployee/",
			body,
			this.httpOptions,
		);
	}
	deleteWelfareShuttleBusEmployee(model) {
		var body = JSON.stringify(model);
		return this.http.put(
			this.apiUrl + "WelfareShuttleBusEmployee/DeleteWelfareShuttleBusEmployee/",
			body,
			this.httpOptions,
		);
	}

	getWelfareShuttleBusTimeTables() {
		return firstValueFrom(this.http.get(`${this.apiUrl}WelfareShuttleBus/getWelfareShuttleBusTimeTables`)).then(
			(r) => <any>r,
		);
	}
	addWelfareShuttleBusTimeTables(model) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WelfareShuttleBus/AddWelfareShuttleBusTimeTables/", body, this.httpOptions);
	}

	getWelfareDocumentFormsByWelfareDocumentId(welfareDocumentId, employeeId) {
		return firstValueFrom(
			this.http.get(
				`${this.apiUrl}Welfare/GetWelfareDocumentFormsByWelfareDocumentId/${welfareDocumentId}/${employeeId}`,
			),
		).then((r) => <any>r);
	}

	getWelfareDocumentFormById(welfareDocumentId) {
		return firstValueFrom(this.http.get(`${this.apiUrl}Welfare/GetWelfareDocumentForm/${welfareDocumentId} `)).then(
			(r) => <any>r,
		);
	}

	addWelfareAttachment(model: any) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Welfare/AddWelfareAttachment", body, this.httpOptions);
	}

	deleteWelfareAttachmentById(welfareAttachmentId) {
		return this.http.delete(`${this.apiUrl}Welfare/DeleteWelfareAttachmentById/${welfareAttachmentId}`);
	}

	getExpenseIcons() {
		return this.http.get<any>(this.venioApi4Url + "Expense/Categories/Icons");
	}

	getWelfareAdjustDocumentForApprove(searchModel: any) {
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(`${this.apiUrl}welfare-documents/adjust/approves`, body);
	}

	getWelfareAdjustRequestHistoryDataTable(searchModel: RequestHistorySearchModel) {
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "welfare-documents/adjust/histories", body);
	}

	getWelfareAdjustRequests(searchModel: WelfareSearchModel) {
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "welfare-documents/adjust/requests", body);
	}

	getWelfareAdjustRequestsGroupMonthAsync(searchModel: WelfareSearchModel) {
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(this.apiUrl + "welfare-documents/adjust/months", body);
	}

	getWelfareEmployeeByWelfareId(welfareId: number) {
		return this.http.get<any>(`${this.apiUrl}welfares/${welfareId}/employees`);
	}

	AddWelfareAdjustRequest(model: any) {
		var body = JSON.stringify(model);
		return this.http.post<any>(`${this.apiUrl}welfare-documents/adjust`, body);
	}

	EditWelfareAdjustRequest(model: any) {
		var body = JSON.stringify(model);
		return this.http.put<any>(`${this.apiUrl}welfare-documents/adjust`, body);
	}

	GetWelfareAdjustRequestWaitingByWelfareId(welfareId: number) {
		return this.http.get<any>(`${this.apiUrl}welfare-documents/adjust/welfare/${welfareId}`);
	}

	deleteWelfareAttachmentsByEmployeeList(model) {
		const body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "welfare-documents/adjust/attachments", body, this.httpOptions);
	}

	getCompanyWelfareDiligenceAllowanceByTenantId() {
		return this.http.get<ApiResult>(this.apiUrl + "welfares/diligence-allowance/companies");
	}

	editWelfareDiligenceAllowance(model, companyId) {
		const body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.put(this.apiUrl + "v1/welfares/diligence-allowance/companies/" + companyId, body, httpOptions);
	}
}
