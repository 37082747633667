import { Injectable } from "@angular/core";
import { ToastService } from "@gofive/design-system-toast";
import { ApiResult } from "../models/api-result.model";
import { TranslateService } from "@ngx-translate/core";
import { ToastDataModel } from "@gofive/design-system-toast/lib/models/toast.model";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ToastHelperService {
	constructor(
		private _toastService: ToastService,
		private translate: TranslateService,
	) {}

	public alertApiResult(apiResult: ApiResult, isTH: boolean = true): void {
		if (apiResult.data == "Warning") {
			this._toastService.Warning({
				title: this.translate.instant("toast_title_warning"),
				description: isTH ? apiResult.message : apiResult.message_EN ?? apiResult.message,
			});
		} else if (apiResult.data == "Cancelled") {
			this._toastService.Cancelled({
				title: this.translate.instant("toast_title_cancelled"),
				description: isTH ? apiResult.message : apiResult.message_EN ?? apiResult.message,
			});
		} else if (apiResult.data == "Rejected") {
			this._toastService.Cancelled({
				title: this.translate.instant("toast_title_rejected"),
				description: isTH ? apiResult.message : apiResult.message_EN ?? apiResult.message,
			});
		} else if (apiResult.result) {
			this._toastService.Success({
				title: this.translate.instant("toast_title_success"),
				description: isTH ? apiResult.message : apiResult.message_EN ?? apiResult.message,
			});
		} else {
			this._toastService.Validation({
				title: this.translate.instant("toast_title_sorry"),
				// description: this.translate.instant('toast_common_complete_all_fields')
				description: isTH ? apiResult.message : apiResult.message_EN ?? apiResult.message,
			});
		}
	}

	public alertDeleteBoolean(response) {
		if (response) {
			this._toastService.Success({
				title: this.translate.instant("toast_title_success"),
				description: this.translate.instant("deleted_successfully"),
			});
		} else {
			this._toastService.Validation({
				title: this.translate.instant("toast_title_error"),
				description: this.translate.instant("cannot_delete_data"),
			});
		}
	}

	public alertDeletePicture(response) {
		if (response) {
			this._toastService.Success({
				title: this.translate.instant("toast_title_success"),
				description: this.translate.instant("toast_desc_image_deleted"),
			});
		} else {
			this._toastService.Validation({
				title: this.translate.instant("toast_title_error"),
				description: this.translate.instant("cannot_delete_data"),
			});
		}
	}

	public alertAddBoolean(response) {
		if (response) {
			this._toastService.Success({
				title: this.translate.instant("toast_title_success"),
				description: this.translate.instant("add_data_successfully"),
			});
		} else {
			this._toastService.Validation({
				title: this.translate.instant("toast_title_error"),
				description: this.translate.instant("cannot_add_data"),
			});
		}
	}

	public alertAddMovementBoolean(response) {
		if (response) {
			this._toastService.Success({
				title: this.translate.instant("toast_title_success"),
				description: this.translate.instant("toast_movement_create_success"),
			});
		} else {
			this._toastService.Validation({
				title: this.translate.instant("toast_title_error"),
				description: this.translate.instant("cannot_add_data"),
			});
		}
	}

	public alertEditBoolean(response) {
		if (response) {
			this._toastService.Success({
				title: this.translate.instant("toast_title_success"),
				description: this.translate.instant("toast_common_save_success"),
			});
		} else {
			this._toastService.Validation({
				title: this.translate.instant("toast_title_error"),
				description: this.translate.instant("cannot_edit"),
			});
		}
	}

	public alertResetBoolean(response) {
		if (response) {
			this._toastService.Success({
				title: this.translate.instant("toast_title_success"),
				description: this.translate.instant("toast_desc_success_data_reset"),
			});
		} else {
			this._toastService.Validation({
				title: this.translate.instant("toast_title_unable_reset_data"),
				description: this.translate.instant("toast_desc_unable_reset_data"),
			});
		}
	}

	public success(data: ToastDataModel) {
		this._toastService.Success(data);
	}
	public warning(data: ToastDataModel) {
		this._toastService.Warning(data);
	}
	public validation(data: ToastDataModel) {
		this._toastService.Validation(data);
	}
	public cancelled(data: ToastDataModel) {
		this._toastService.Cancelled(data);
	}
	public error(data: ToastDataModel) {
		this._toastService.Error(data);
	}
	public information(data: ToastDataModel) {
		this._toastService.Information(data);
	}
	public text(data: ToastDataModel) {
		this._toastService.Text(data);
	}

	public successText(description: string) {
		let toast: ToastDataModel = {
			title: this.translate.instant("toast_title_success"),
			description: this.translate.instant(description),
		};
		this._toastService.Success(toast);
	}

	public warningText(description: string) {
		let toast: ToastDataModel = {
			title: this.translate.instant("toast_title_warning"),
			description: description,
		};
		this._toastService.Warning(toast);
	}

	public validationText(description: string) {
		let toast: ToastDataModel = {
			title: this.translate.instant("toast_title_error"),
			description: description,
		};
		this._toastService.Validation(toast);
	}

	public errorText(description: string) {
		let toast: ToastDataModel = {
			title: this.translate.instant("toast_title_error"),
			description: description,
		};
		this._toastService.Error(toast);
	}

	public loading(observer: Observable<any>, data: ToastDataModel) {
		return this._toastService.Loading(observer, data);
	}

	public alertError500() {
		let toast: ToastDataModel = {
			title: this.translate.instant("toast_title_error"),
			description: this.translate.instant("toast_server_error"),
		};
		this._toastService.Error(toast);
	}

	public alertError202() {
		let toast: ToastDataModel = {
			title: this.translate.instant("toast_title_edit_unavailable_movement"),
			description: this.translate.instant("toast_description_edit_unavailable_movement"),
		};
		this._toastService.Error(toast);
	}

	public alertCompleteAllFields() {
		this._toastService.Validation({
			title: this.translate.instant("toast_title_headsup"),
			description: this.translate.instant("toast_common_complete_all_fields"),
		});
	}
}
