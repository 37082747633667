export class AccumulateConfig {
	accumulateId: number;
	name: string;
	name_EN: string;
	isActive: boolean;
	isDelete: boolean;
	dateCreated: Date;
	createdBy: string;
	dateModified: Date;
	modifiedBy: string;
	companyId: number;
	companyName: string;
	companyName_EN: string;
	accumulateTypeId: number;
	accumulateTypeName: string;
	accumulateTypeName_EN: string;
	fullName: string;
	fullName_EN: string;
	amount: number;
}
