export class EmployeeFinancial {
	employeeId: number;
	financialItemId: number;
	type: number;
	amount: number;
	isCalculate: boolean;
	dateCreated: Date;
	createdBy: string;
	dateModified: Date;
	modiflyBy: string;
	isShow: boolean;
	isDisabled: boolean;
	isClosed: boolean;
	category: number;
	periodType: number;
	periodType_TH: string;
	periodType_EN: string;
	financialItemName: string;
	financialItemName_EN: string;
	movementCode: string;
}

export class EmployeePayroll {
	employeeId: number;
	companyId: number;
	periodCalculateNo: number;
	year: number;
	hasEmailAddress: boolean;
	isClosed: boolean;
	isPaid: boolean;
	isCash: boolean;
	bankAccountNo: string;
	bankAccountName: string;
	bankAccountName_EN: string;
	employeeFinancials: EmployeeFinancial[];
}

export class EmployeeFinancialHistories {
	employeeId: number;
	financialItemId: number;
	category: number;
	oldValue: number;
	newValue: number;
	isEffective: boolean;
	dateModified: Date;
	modifiedBy: string;
	name: string;
	name_EN: string;
}
