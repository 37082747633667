import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../../core/shared/data-tables-response.model";
import { AccumulateConfig } from "./accumulate-config.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class AccumulateConfigService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getDataTableAccumulateConfig(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "AccumulateConfig/GetDataTableAccumulateConfig",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}
	addAccumulateConfig(model: AccumulateConfig) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AccumulateConfig/AddAccumulateConfig", body, httpOptions);
	}

	editAccumulateConfig(model: AccumulateConfig) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AccumulateConfig/EditAccumulateConfig", body, httpOptions);
	}

	getAccumulateConfigById(accumulateId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "AccumulateConfig/GetAccumulateConfigById/" + accumulateId)).then(
			(res) => <AccumulateConfig>res,
		);
	}
	getAccumulateByYear(employeeId: number, year: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AccumulateConfig/GetAccumulateByYear/" + employeeId + "/" + year),
		).then((res) => <AccumulateConfig[]>res);
	}
}
