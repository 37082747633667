<go5-sidebar
	[(visible)]="visible"
	[textHeader]="(fieldId > 0 ? 'employee_field_edit' : 'employee_field_create') | translate"
	[padding]="'0'"
	(close)="onCloseSidebar()"
>
	<ng-container *ngTemplateOutlet="contentCustomFiled"> </ng-container>
	<ng-template #footerTemplate>
		<ng-container *ngTemplateOutlet="footerCustomField"></ng-container>
	</ng-template>
</go5-sidebar>

<ng-template #contentCustomFiled>
	<div class="sidebar-content-wrapper">
		<div class="sidebar-field-container">
			<ng-container [formGroup]="form">
				<div class="sidebar-field">
					<go5-textfield
						[disabled]="fieldId > 0"
						[formControlName]="'name'"
						[label]="('employee_field_name' | translate) + '*'"
						[placeholder]="'employee_field_name_placeholder' | translate"
						[error]="(form.get('name').touched && !form.get('name').valid) || isDuplicate"
						[errorMessage]="
							(isDuplicate ? 'employee_field_name_validate_duplicate' : 'employee_field_name_validate') | translate
						"
					>
					</go5-textfield>
				</div>

				<div class="sidebar-field field-checkbox">
					<div class="d-flex align-center g-2">
						<span>
							<go5-checkbox
								[formControlName]="'isRequire'"
								[label]="'employee_field_required' | translate"
							></go5-checkbox>
						</span>
						<span>
							<einfo-tooltip
								[ngStyle]="{ display: 'flex' }"
								[key]="'employee_field_required_tooltip' | translate"
								size="s"
							>
							</einfo-tooltip>
						</span>
					</div>
				</div>

				<div class="sidebar-field">
					<div class="sidebar-field-label">
						{{ ("employee_field_type" | translate) + "*" }}
					</div>
					<div>
						<go5-selection-single
							[formControlName]="'fieldTypeId'"
							category="primary"
							size="medium"
							[dataSource]="dataSourceFieldType"
							[fields]="dataSharing.getIsTH(dataSharing.currentLanguage | async) ? fieldType : fieldTypeEN"
							(selected)="onTypeChange($event)"
						></go5-selection-single>
					</div>
				</div>

				@if (form?.controls["fieldTypeId"]?.value == customFieldsType.Text) {
					<div class="sidebar-field">
						<div class="sidebar-field-label">
							<div class="d-flex align-center g-2">
								<span>
									{{ ("employee_field_response_validation" | translate) + "*" }}
								</span>
								<span>
									<einfo-tooltip
										[ngStyle]="{ display: 'flex' }"
										[key]="'employee_field_response_validation_tooltip' | translate"
										size="s"
									>
									</einfo-tooltip>
								</span>
							</div>
						</div>
						<div>
							<go5-selection-single
								[formControlName]="'textBoxType'"
								category="primary"
								size="medium"
								[dataSource]="dataSourceFieldValidationType"
								[fields]="fieldValidationType"
								(selected)="onValidationTypeChange($event)"
							></go5-selection-single>
						</div>
					</div>
				}

				@if (
					form?.controls["textBoxType"]?.value == customFieldsTextBoxType.Custom &&
					form?.controls["fieldTypeId"]?.value == customFieldsType.Text
				) {
					<div class="sidebar-field">
						<go5-textfield
							[formControlName]="'regularExpression'"
							[label]="('employee_field_regex' | translate) + '*'"
							[placeholder]="'employee_field_regex_placeholder' | translate"
							[error]="form.get('regularExpression').touched && !form.get('regularExpression').valid"
							[errorMessage]="'employee_field_regex_validate' | translate"
						>
						</go5-textfield>
					</div>
				}

				@if (
					form?.controls["fieldTypeId"]?.value == customFieldsType.SingleSelection ||
					form?.controls["fieldTypeId"]?.value == customFieldsType.MultiSelection
				) {
					<div class="sidebar-field">
						<div class="sidebar-field-label">{{ ("employee_field_options" | translate) + "*" }}</div>
						<div class="d-flex direction-column g-8">
							<ng-container [formArrayName]="'masAdditionalFieldDetails'">
								@for (_ of form.get("masAdditionalFieldDetails")["controls"]; track i; let i = $index) {
									<ng-container [formGroupName]="i">
										<div class="custom-field-option-group">
											<span class="custom-field-option-input">
												<go5-textfield
													[formControlName]="'label'"
													[placeholder]="('asm002_option' | translate) + ' ' + (i + 1)"
													[error]="
														emptyContent ||
														isErrorFieldDetails ||
														(duplicateDetail?.length &&
															checkDuplicateOption(
																form.get('masAdditionalFieldDetails')['controls'][i]['controls']['label']['value'],
																duplicateDetail
															))
													"
												>
													<ng-template #inputSuffixContent let-hover="isHover" let-active="isActive">
														@if (hover || active) {
															<span class="custom-field-option-icon">
																<em class="gf-icon-empeo-bin-vector" (click)="onDeleteOptions(i)"> </em>
															</span>
														}
													</ng-template>
												</go5-textfield>
											</span>
										</div>
									</ng-container>
									@if (
										duplicateDetail?.length &&
										checkDuplicateOption(
											form.get("masAdditionalFieldDetails")["controls"][i]["controls"]["label"]["value"],
											duplicateDetail
										)
									) {
										<go5-error-message
											class="no-margin"
											[error]="duplicateDetail?.length"
											[errorMessage]="'employee_option_name_validate_duplicate' | translate"
										>
										</go5-error-message>
									}
								}
								@if (isErrorFieldDetails || emptyContent) {
									<div>
										<go5-error-message
											[error]="isErrorFieldDetails || emptyContent"
											[errorMessage]="'employee_field_options_validate' | translate"
										>
										</go5-error-message>
									</div>
								}
							</ng-container>
						</div>
						<div class="sidebar-button-add">
							<go5-button
								(click)="onAddOptions()"
								[label]="'employee_field_options_add' | translate"
								iconClass="gf-icon-add-general"
								category="text"
								[padding]="'6px 0px'"
								size="medium"
								type="button"
							></go5-button>
						</div>
					</div>
				}
				@if (form?.controls["fieldTypeId"]?.value == customFieldsType.DropdownList) {
					<div class="sidebar-field">
						<div class="sidebar-field-label">
							<div class="d-flex align-center g-2">
								<span>
									{{ ("employee_field_options" | translate) + "*" }}
								</span>
								<span>
									<einfo-tooltip
										[ngStyle]="{ display: 'flex' }"
										[key]="'employee_field_options_dropdown_tooltip' | translate"
										size="s"
									>
									</einfo-tooltip>
								</span>
							</div>
						</div>
						<div>
							<go5-textarea
								category="primary"
								[formControlName]="'textDropdown'"
								[placeholder]="'employee_field_options_dropdown_placeholder' | translate"
								[size]="'extra-large'"
								[error]="
									(form.get('textDropdown').touched && !form.get('textDropdown').valid) ||
									duplicateDetail?.length ||
									emptyContent
								"
								[errorMessage]="
									(duplicateDetail?.length
										? 'employee_option_name_validate_duplicate_ddl'
										: 'employee_field_options_validate'
									) | translate
								"
							>
							</go5-textarea>
						</div>
					</div>
				}
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #footerCustomField>
	<div class="d-flex content-space-between g-8 w-100">
		<span class="d-flex align-center g-8">
			<span class="d-flex align-center g-2">
				<span class="go5-text-color-12">
					{{ "common_enable_toggle" | translate }}
				</span>

				<span>
					<einfo-tooltip [ngStyle]="{ display: 'flex' }" [key]="'employee_field_enable_tooltip' | translate" size="s">
					</einfo-tooltip>
				</span>
			</span>
			<span>
				<go5-toggle-switch [(ngModel)]="isActive" [checked]="isActive" [category]="'secondary'"> </go5-toggle-switch>
			</span>
		</span>

		<span class="d-flex align-center g-12">
			<go5-button
				[category]="'secondary'"
				[size]="'large'"
				[label]="'common_cancel' | translate"
				(click)="onCloseSidebar()"
			>
			</go5-button>

			<go5-button
				[category]="'primary'"
				[size]="'large'"
				[label]="(fieldId > 0 ? 'common_save' : 'common_create') | translate"
				(click)="onCheckValidation()"
				[loading]="saving"
			>
			</go5-button>
		</span>
	</div>
</ng-template>
