<div class="exception-body">
	<div class="exception-container">
		@if (isActive) {
			<img src="assets/images/state/403-access-denied.svg" alt="access denied" />
			<div class="pt-2">
				<p class="text-header-2">{{ "common_access_denied" | translate }}</p>
				<p class="text-title text-color-8">{{ "common_access_denied_descrption" | translate }}</p>
			</div>
			<div class="py-4">
				<go5-button
					data-testid="button_button_login"
					type="button"
					[size]="getWidthScreenSize()"
					category="primary"
					[label]="(hasValidAccessToken ? 'common_back1' : 'common_login') | translate"
					(click)="btnClick()"
				></go5-button>
			</div>
		} @else {
			<img
				src="assets/images/state/could-not-find-your-account.svg"
				alt="{{ 'common_page_not_found_account' | translate }}"
			/>
			<div class="pt-2">
				<p class="text-header-2">{{ "common_page_not_found_account" | translate }}</p>
				<p class="go5-text-title text-color-8">{{ "common_access_denied_inactive_descrption" | translate }}</p>
			</div>
			<div class="py-4">
				<go5-button
					data-testid="button_button_logout"
					category="primary"
					size="large"
					type="button"
					[label]="'common_logout' | translate"
					(click)="logout()"
				></go5-button>
			</div>
		}
	</div>
</div>
