@if (isShowHeader) {
	<go5-sidebar
		[(visible)]="isDialog"
		(close)="onClose()"
		(closeClick)="closeSidebar($event)"
		[textHeader]="textHeader"
		[isShowFooter]="false"
		[isShowHeader]="isShowHeader"
		[padding]="'0px'"
	>
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</go5-sidebar>
} @else {
	<ng-template *ngTemplateOutlet="content"></ng-template>
}
<ng-template #content>
	<form [formGroup]="form">
		@if (!isLoading) {
			<div class="d-flex content-financialitem p-3 px-4">
				<div class="financial-form flex-direction-column">
					<!-- ส่วนแรก -->
					<div class="block-content-financialitem">
						@if (financialItem.category == categoryOvertimeBackPay) {
							<div class="ui-g-12 ui-g-nopad">
								<go5-info-bar [type]="infoBarType.Info">{{
									"mas010_income_overtime_back_pay_info" | translate
								}}</go5-info-bar>
							</div>
						}
						@if (financialItem.category == categoryAttendanceBackPay) {
							<div class="ui-g-12 ui-g-nopad">
								<go5-info-bar [type]="infoBarType.Info">{{
									"mas010_income_attendance_back_pay_info" | translate
								}}</go5-info-bar>
							</div>
						}
						<!-- รหัส -->
						<div class="ui-g-3 ui-g-nopad">
							<go5-textfield
								data-testid="input_text_code"
								type="text"
								category="primary"
								label="{{ ('mas010_code' | translate) + '*' }}"
								[disabled]="false"
								[error]="!form.controls['code'].valid && form.controls['code'].dirty"
								errorMessage="{{ 'common_required' | translate }}"
								[(ngModel)]="financialItem.code"
								[minLength]="10"
								formControlName="code"
							></go5-textfield>
						</div>
						<!-- ชื่อ -->
						<div class="ui-g-12 ui-g-nopad flex-column gap-8">
							<go5-textfield
								data-testid="input_text_name"
								type="text"
								category="primary"
								label="{{
									((indexTabView == 1 ? 'mas010_addition_name_th' : 'mas010_deduction_name_th') | translate) + '*'
								}}"
								[disabled]="false"
								[error]="!form.controls['name'].valid && form.controls['name'].dirty"
								errorMessage="{{ 'common_required' | translate }}"
								[(ngModel)]="financialItem.name"
								[minLength]="10"
								formControlName="name"
							></go5-textfield>
							@if (isStatusAdd && indexTabView !== 1 && isShowTaxInfo) {
								<go5-info-bar [type]="infoBarType.Info"
									>{{ "mas010_deduction_infobar_include_tax_cal" | translate }}
									<span class="text-underline pointer" (click)="onClickOpenManual()">{{
										"common_click_here" | translate
									}}</span>
									{{ "common_to_view_manual" | translate }}
								</go5-info-bar>
							}
						</div>
						<!-- ชื่อ อังกฤษ -->
						<div class="ui-g-12 ui-g-nopad">
							<go5-textfield
								data-testid="input_text_name_en"
								type="text"
								category="primary"
								label="{{ (indexTabView == 1 ? 'mas010_addition_name_en' : 'mas010_deduction_name_en') | translate }}"
								[disabled]="false"
								[error]="!form.controls['name_EN'].valid && form.controls['name_EN'].dirty"
								errorMessage="{{ 'common_required' | translate }}"
								[(ngModel)]="financialItem.name_EN"
								[minLength]="10"
								formControlName="name_EN"
							></go5-textfield>
						</div>
						<!-- ประเภทเงินได้ -->
						@if (indexTabView == 1) {
							<div class="ui-g-12 ui-g-nopad">
								<go5-dropdown-selection
									data-testid="input_dropdown_incometype"
									label="{{ ('mas010_incometype' | translate) + '*' }}"
									[allowShowRemove]="false"
									[dataSource]="
										financialItem && financialItem.incomeType == incomeType.termination
											? incomeTypeAdditionDDL
											: incomeTypeAdditionNewDDL
									"
									placeholder="{{ 'common_all' | translate }}"
									[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDropdown : fieldsDropdown_EN"
									(selected)="onSelectedIncomeType($event)"
									[mode]="DropdownSelectMode.Single"
									height="40px"
									[value]="financialItem.incomeType"
									formControlName="incomeTypes"
									[allowFiltering]="false"
									[disabled]="financialItem.category == financialCategory.severancePay"
								>
								</go5-dropdown-selection>
								@if (amountEmploymentType > 0) {
									<div class="ui-g-12 p-0">
										<div class="ui-g-12 p-0 mt-2">
											<go5-info-bar [type]="infoBarType.Info">
												<span>
													{{
														"mas010_incometype_employmenttype_infobar_1"
															| translate: { amountEmploymentType: amountEmploymentType }
													}}
												</span>
												<span style="text-decoration: underline; cursor: pointer" (click)="goToPaymentMethod()">{{
													"common_click_here" | translate
												}}</span>
												<span>
													{{ "mas010_incometype_employmenttype_infobar_2" | translate }}
												</span>
											</go5-info-bar>
										</div>
									</div>
								}
							</div>
						}
						<!-- หักภาษี ณ ที่จ่าย (%) * -->
						@if (rentType && indexTabView == 1) {
							<div class="ui-g-12 ui-g-nopad">
								<go5-textfield
									data-testid="input_number_withholdingtax"
									[type]="TextFieldType.Number"
									category="primary"
									label="{{ ('mas010_withholding_tax' | translate) + '(%) *' }}"
									[disabled]="false"
									[error]="
										!form.controls['withholdingTaxPercentage'].valid && form.controls['withholdingTaxPercentage'].dirty
									"
									errorMessage="{{ 'common_required' | translate }}"
									[(ngModel)]="withholdingTax"
									[min]="1"
									[max]="100"
									formControlName="withholdingTaxPercentage"
								></go5-textfield>
							</div>
						}
						<!-- การปัดเศษทศนิยม -->
						<div class="ui-g-12 ui-g-nopad">
							<go5-dropdown-selection
								data-testid="input_dropdown_incometype"
								label="{{ ('mas010_rounding' | translate) + '*' }}"
								[allowShowRemove]="false"
								[dataSource]="roundingConfigDDL"
								placeholder="{{ 'common_default_select' | translate }}"
								[fields]="
									data.getIsTH(data.currentLanguage | async) ? fieldsRoundingDropdown : fieldsRoundingDropdown_EN
								"
								(selected)="onSelectedRoundingCode($event)"
								[mode]="DropdownSelectMode.Single"
								height="40px"
								[value]="financialItem.roundingCode"
								formControlName="roundingCode"
								[error]="!form.controls['roundingCode'].valid && form.controls['roundingCode'].dirty"
								errorMessage="{{ 'common_required' | translate }}"
								[allowFiltering]="false"
							>
							</go5-dropdown-selection>
						</div>
					</div>
					<!-- ส่วนที่สอง -->
					<div class="block-content-financialitem mt-4">
						<label class="text-header-5 text-color-12">{{ "mas015_bonus_type" | translate }}</label>
						<!-- รายได้นี้ทำจ่าย -->
						<div class="ui-g-12 p-0">
							<div class="financial-layout-content-input">
								<span class="d-flex d-flex-center">
									<label for="input" class="text-color-8 pr-2">
										{{ (indexTabView == 1 ? "mas010_period_type" : "mas010_period_type_deductions") | translate }}
									</label>
									<einfo-tooltip
										class="d-flex"
										key="{{
											indexTabView == 1 ? 'mas010_period_type_tooltip' : 'mas010_period_type_deductions_tooltip'
										}}"
										size="s"
									></einfo-tooltip>
								</span>
								<go5-dropdown-selection
									data-testid="input_dropdown_periodtype"
									[dataSource]="periodTypeDDL"
									placeholder="{{ 'common_default_select' | translate }}"
									(selected)="onSelectedPeriodType($event)"
									[mode]="DropdownSelectMode.Single"
									height="32px"
									[fields]="fields"
									width="200px"
									[value]="financialItem.periodType"
									formControlName="periodType"
									[error]="!form.controls['periodType'].valid && form.controls['periodType'].dirty"
									errorMessage="{{ 'common_required' | translate }}"
									[allowShowRemove]="false"
									[allowFiltering]="false"
									[disabled]="
										financialItem.category == categoryOvertimeBackPay ||
										financialItem.category == categoryAttendanceBackPay
									"
								>
								</go5-dropdown-selection>
							</div>
						</div>
						<!-- คำนวณการจ่ายเงินเดือน -->
						@if (
							[
								0,
								financialCategory.other,
								financialCategory.diligence,
								financialCategory.diligenceBonus,
								financialCategory.diligenceStack
							].includes(financialItem.category) && indexTabView != 2
						) {
							<div class="ui-g-12 p-0">
								<div class="financial-layout-content-input">
									<span class="d-flex d-flex-center">
										<label for="input" class="text-color-8 pr-2">
											{{ "mas010_payment" | translate }}
										</label>
										<einfo-tooltip class="d-flex" key="mas010_payment_tooltip" size="s"></einfo-tooltip>
									</span>
									<go5-toggle-switch
										category="secondary"
										[checked]="financialItem.isBaseSalary"
										formControlName="isBaseSalary"
										(valueChange)="financialItem.isBaseSalary = $event.checked"
									></go5-toggle-switch>
								</div>
							</div>
						}
						<!-- เป็นเงินที่จำจ่ายงวดเดียว -->
						<div class="ui-g-12 p-0" [hidden]="!(financialItem.category == 0 || financialItem.category == 2)">
							<div class="financial-layout-content-input">
								<span class="d-flex d-flex-center">
									<label for="input" class="text-color-8 pr-2">
										{{ "mas010_once_pay" | translate }}
									</label>
									<einfo-tooltip class="d-flex" key="mas010_once_pay_tooltip" size="s"></einfo-tooltip>
								</span>
								<go5-toggle-switch
									data-testid="input_switch_isoncepay"
									category="secondary"
									[checked]="financialItem.isOncePay"
									formControlName="isOncePay"
									(valueChange)="financialItem.isOncePay = $event.checked"
								></go5-toggle-switch>
							</div>
						</div>
					</div>
					<!-- เงื่อนไขการคำนวณ -->
					@if (!isCategoryTaxNotCal) {
						<div class="block-content-financialitem mt-4">
							<div class="ui-g-12 p-0">
								<label class="text-header-5 text-color-12">{{ "mas010_calculation_conditions" | translate }}</label>
							</div>
							<!-- นำไปคำนวณภาษี -->
							<div class="ui-g-12 p-0 flex-column gap-8">
								<div class="financial-layout-content-input">
									<span class="d-flex d-flex-center">
										<label for="input" class="text-color-8 pr-2">
											{{ "mas010_used_to_calculate_taxes" | translate }}
										</label>
										<einfo-tooltip class="d-flex" key="mas010_used_to_calculate_tax_tooltip" size="s"></einfo-tooltip>
									</span>
									<go5-dropdown-selection
										data-testid="input_dropdown_periodtype"
										[dataSource]="isTaxDDL"
										placeholder="{{ 'common_default_select' | translate }}"
										(selected)="onSelectedisTaxDDL($event)"
										[mode]="DropdownSelectMode.Single"
										[fields]="fields"
										height="32px"
										width="200px"
										[value]="isTaxType"
										[allowShowRemove]="false"
										[allowFiltering]="false"
										formControlName="isTaxType"
									>
									</go5-dropdown-selection>
								</div>
								@if (isShowWarning) {
									<go5-info-bar [type]="infoBarType.Warning">{{
										"mas010_deduction_infobar_warning_no_tax_cal" | translate
									}}</go5-info-bar>
								}
							</div>
							<!-- ประเภทเงินได้ -->
							@if (indexTabView == 2 && isTaxType != TaxType.none) {
								<div class="ui-g-12 p-0">
									<div class="financial-layout-content-input">
										<span class="d-flex d-flex-center">
											<label for="input" class="text-color-8 pr-2"> {{ "mas010_incometype" | translate }} * </label>
										</span>
										<go5-dropdown-selection
											data-testid="input_dropdown_incometype"
											[dataSource]="
												financialItem && financialItem.incomeType == incomeType.termination
													? incomeTypeDeductionDDL
													: incomeTypeDeductionNewDDL
											"
											placeholder="{{ 'common_default_select' | translate }}"
											(selected)="onSelectedIncomeType($event)"
											[mode]="DropdownSelectMode.Single"
											[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDropdown : fieldsDropdown_EN"
											height="32px"
											width="200px"
											[value]="financialItem.incomeType"
											[allowShowRemove]="false"
											[allowFiltering]="false"
											formControlName="incomeTypes"
										>
											<ng-template #itemTemplate let-item>
												<span
													class="ellipsis"
													go5-tooltip-placement="top"
													go5-tooltip-mode="hover"
													go5-tooltip="{{ data.getIsTH(data.currentLanguage | async) ? item?.name : item?.name_EN }}"
												>
													{{ data.getIsTH(data.currentLanguage | async) ? item?.name : item?.name_EN }}</span
												>
											</ng-template>
										</go5-dropdown-selection>
									</div>
									@if (amountEmploymentType > 0) {
										<div class="ui-g-12 p-0">
											<div class="ui-g-12 p-0 mt-2">
												<go5-info-bar [type]="infoBarType.Info">
													<span>
														{{
															"mas010_deductiontype_employmenttype_infobar_1"
																| translate: { amountEmploymentType: amountEmploymentType }
														}}
													</span>
													<span style="text-decoration: underline; cursor: pointer" (click)="goToPaymentMethod()">{{
														"common_click_here" | translate
													}}</span>
													<span>
														{{ "mas010_deductiontype_employmenttype_infobar_2" | translate }}
													</span>
												</go5-info-bar>
											</div>
										</div>
									}
								</div>
							}
							<!-- ให้คำนวณนอกงวด -->
							@if (showPayPeriod) {
								<div class="ui-g-12 p-0">
									<div class="financial-layout-content-input">
										<span class="d-flex d-flex-center">
											<label for="input" class="text-color-8 pr-2">
												{{ "mas010_pay_period" | translate }}
											</label>
											<einfo-tooltip class="d-flex" key="mas010_pay_period_tooltip" size="s"></einfo-tooltip>
										</span>
										<go5-toggle-switch
											data-testid="input_checkbox_isextendperiod"
											category="secondary"
											[checked]="financialItem.isExtendPeriod"
											(valueChange)="financialItem.isExtendPeriod = $event.checked"
											formControlName="isExtendPeriod"
										></go5-toggle-switch>
									</div>
								</div>
							}
							<!-- คำนวณในงวดถัดไป -->
							@if (showCurrentPeriod) {
								<div class="ui-g-12 p-0">
									<div class="financial-layout-content-input">
										<span class="d-flex d-flex-center">
											<label for="input" class="text-color-8 pr-2">
												{{ "mas010_calculated_from_the_previous_period" | translate }}
											</label>
											<einfo-tooltip
												class="d-flex"
												key="mas010_calculated_from_the_previous_period_tooltip"
												size="s"
											></einfo-tooltip>
										</span>
										<go5-toggle-switch
											data-testid="input_checkbox_isextendperiod"
											category="secondary"
											[disabled]="!permission.allowEdit"
											[checked]="isCurrentPeriod"
											(valueChange)="isCurrentPeriod = $event.checked"
											formControlName="isCurrentPeriod"
										></go5-toggle-switch>
									</div>
								</div>
							}
						</div>
					}
					<!-- นำรายได้นี้ไปคำนวณกับ -->
					@if (!isCategoryTaxNotCal) {
						<div class="block-content-financialitem mt-4">
							<label class="text-color-8"
								>{{
									(indexTabView == 1
										? "mas010_be_calculated_with_other_income"
										: "mas010_be_calculated_with_other_deductions"
									) | translate
								}}
							</label>
							<div class="ui-g-12 p-0">
								<!-- ประกันสังคม -->
								<div class="d-flex pb-2" style="align-items: center">
									<go5-checkbox
										data-testid="input_checkbox_issoc"
										label="{{ 'mas010_social_security' | translate }}"
										[checked]="financialItem.isSOC"
										[disabled]="!permission.allowEdit"
										(changed)="financialItem.isSOC = $event.value"
										formControlName="isSOC"
									></go5-checkbox>
									<einfo-tooltip
										key="mas010_social_security_tooltip"
										class="pl-2 d-flex"
										style="height: 17px"
										size="s"
									></einfo-tooltip>
								</div>
								<!-- กองทุนสำรองเลี้ยงชีพ -->
								<div class="d-flex pb-2" style="align-items: center">
									<go5-checkbox
										data-testid="input_checkbox_ispf"
										label="{{ 'mas010_provident_fund' | translate }}"
										[checked]="financialItem.isPF"
										[disabled]="!permission.allowEdit"
										(changed)="financialItem.isPF = $event.value"
										formControlName="isPF"
									></go5-checkbox>
									<einfo-tooltip
										key="mas010_provident_fund_tooltip"
										style="height: 17px"
										class="pl-2 d-flex"
										size="s"
									></einfo-tooltip>
								</div>
								<!--ค่าล่วงเวลา  -->
								@if (indexTabView == 1) {
									<div class="d-flex pb-2" style="align-items: center">
										<go5-checkbox
											data-testid="input_checkbox_isot"
											label="{{ 'mas010_overtime' | translate }}"
											[checked]="financialItem.isOT"
											[disabled]="!permission.allowEdit"
											(changed)="financialItem.isOT = $event.value"
											formControlName="isOT"
										></go5-checkbox>
										<einfo-tooltip
											key="mas010_overtime_tooltip"
											style="height: 17px"
											class="pl-2 d-flex"
											size="s"
										></einfo-tooltip>
									</div>
								}
								<!-- คำนวณวันมาสาย / ขาดงาน  -->
								@if (indexTabView == 1) {
									<div class="d-flex pb-2" style="align-items: center">
										<go5-checkbox
											data-testid="input_checkbox_isat"
											label="{{ 'mas010_cal_late_absent' | translate }}"
											[checked]="financialItem.isAT"
											[disabled]="!permission.allowEdit"
											(changed)="financialItem.isAT = $event.value"
											formControlName="isAT"
										></go5-checkbox>
										<einfo-tooltip
											key="mas010_late_absent_tooltip"
											style="height: 17px"
											class="pl-2 d-flex"
											size="s"
										></einfo-tooltip>
									</div>
								}
							</div>
						</div>
					}
				</div>
				<!-- ส่วนรายชือ่ผู้แก้ไข -->
				<div class="d-flex">
					<div class="ui-g-12 p-0 mb-4">
						@if (financialItemId != null) {
							<label class="text-body-small text-color-8">
								{{ "common_last_modified" | translate }}:
								{{
									data.getIsTH(data.currentLanguage | async)
										? financialItem.displayName + " " + financialItem.dateModified_TH
										: financialItem.displayName_EN + " " + financialItem.dateModified_EN
								}}
							</label>
						}
					</div>
				</div>
			</div>
		}

		<div class="footer-layout text-right">
			<div class="financial-layout-content-input px-2">
				<div class="ui-g-12 p-0">
					<div class="d-flex" style="align-items: center">
						<label class="text-color-8">
							{{ "common_enable_toggle" | translate }}
						</label>
						<einfo-tooltip key="enable_income_deduction_tooltip" class="pl-2 d-flex" size="s"></einfo-tooltip>
						<go5-toggle-switch
							data-testid="input_switch_isactive"
							category="secondary"
							class="pl-2"
							[checked]="financialItem.isActive"
							formControlName="isActive"
							(valueChange)="financialItem.isActive = $event.checked"
						></go5-toggle-switch>
					</div>
				</div>
				<div class="ui-g-12 p-0">
					<go5-button
						data-testid="button_button_cancel"
						class="pr-2"
						category="secondary"
						size="medium"
						type="button"
						label="{{ 'common_cancel' | translate }}"
						(click)="onCancel()"
					>
					</go5-button>
					<go5-button
						data-testid="button_button_submit"
						category="primary"
						size="medium"
						type="button"
						[disabled]="!form.valid"
						label="{{ financialItemId != null ? ('common_save' | translate) : ('common_add' | translate) }}"
						(click)="onSubmit()"
					>
					</go5-button>
				</div>
			</div>
		</div>
	</form>
</ng-template>
