import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserControlService } from "../core";
import { Module } from "../shared/enum/module.enum";
import { PageType } from "../shared/enum/page-type.enum";
import { RouteURL } from "../shared/enum/route-path.enum";
import { isNullOrUndefined } from "../shared/sharing.service";
import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";

export interface AuthorizeControl {
	module: Module;
	pageType: PageType;
	canBackAfterLogin: boolean;
	emptyLayout: boolean;
}
@Injectable()
export class AuthGuardService {
	private authorizeControl: Subscription;
	private isAuthenticated = false;

	constructor(
		private authService: AuthService,
		private userControlService: UserControlService,
		private router: Router,
	) {
		this.authService.isAuthenticated$.subscribe((i) => {
			this.isAuthenticated = i;
		});
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.authService.isDoneLoading$
			.pipe(filter((isDone) => isDone))
			.pipe(tap((_) => this.isAuthenticated || this.authService.login(state.url)))
			.pipe(
				map((_) => {
					this.isAuthenticated && this.checkPermission(route);
					return this.isAuthenticated;
				}),
			);
	}

	private checkPermission(route: ActivatedRouteSnapshot): boolean {
		let permission = <AuthorizeControl>route.data;
		if (isNullOrUndefined(permission) || isNullOrUndefined(permission.module)) {
			return true;
		} else {
			if (environment.production === false) {
				console.error("### check permission");
			}
			this.authorizeControl && this.authorizeControl.unsubscribe();
			this.authorizeControl = this.userControlService.authorizeControl(permission.module).subscribe((result: any) => {
				let redirectToAccessDenied = false;
				if (
					(permission.pageType == PageType.search && result.allowGrant === false) ||
					(permission.pageType == PageType.add && (result.allowGrant === false || result.allowAdd === false)) ||
					(permission.pageType == PageType.edit && (result.allowGrant === false || result.allowEdit === false)) ||
					(permission.pageType == PageType.view && (result.allowGrant === false || result.allowView === false))
				) {
					redirectToAccessDenied = true;
				}

				if (redirectToAccessDenied) {
					this.router.navigate(["/" + RouteURL.accessDenied]);
				} else {
					return this.isAuthenticated;
				}
			});
		}
	}
}
