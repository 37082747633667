<go5-table
	[category]="'secondary'"
	[stickyMode]="'sticky'"
	[dataSource]="dataSource"
	[columns]="!isMobile && !isWebView ? columns : columnsMobile"
	data-testid="assets-management-table"
>
	<ng-template #headerTemplate>
		@if (!isLoading) {
			<tr>
				@for (col of !isMobile && !isWebView ? columns : columnsMobile; track $index) {
					@if (col.name === "checked") {
						<th
							[ngStyle]="{
								textAlign: col.textAlign,
								paddingHeader: col.paddingHeader,
								padding: col.paddingContent,
								borderTopLeftRadius: $index === 0 ? '6px' : null
							}"
						>
							<div class="d-flex">
								<go5-checkbox
									data-testid="input_checkbox_assets_all"
									[checked]="isCheckAll"
									(changed)="onChangeCheckboxAll($event)"
								>
								</go5-checkbox>
							</div>
						</th>
					} @else if (col.name === "Expand") {
						<th style="display: none"></th>
					} @else {
						<th
							[attr.colspan]="col.name === 'Name' && (isMobile || isWebView) ? 2 : null"
							style="height: 0px !important"
							[ngStyle]="{
								paddingHeader: col.paddingHeader,
								padding: col.paddingContent,
								cursor: col.showIconSort ? 'pointer' : 'defualt',
								borderTopLeftRadius: $index === 0 ? '6px' : null,
								borderTopRightRadius: $index === columns.length - 1 ? '6px' : null
							}"
							(click)="onSortTable(col.name)"
						>
							<div class="d-flex align-center g-4 dt-sorting" [ngStyle]="{ justifyContent: col.textAlign }">
								<p
									class="go5-text-body-bold text-color-11"
									[ngClass]="{
										'pl-3':
											col.name === 'Name' &&
											(!isWebView || !isMobile) &&
											AssetsModules[module] !== AssetsModules.SidebarView &&
											AssetsModules[module] !== AssetsModules.EmployeeView
									}"
								>
									{{ col.key | translate }}
								</p>
								@if (col.showIconSort) {
									<em
										class="f-16"
										[ngClass]="{
											'gf-icon-sort': sortOrder !== col.name && sortOrder !== col.name + ' DESC',
											'gf-icon-asc-order': sortOrder === col.name,
											'gf-icon-desc-order': sortOrder === col.name + ' DESC'
										}"
									>
									</em>
								}
							</div>
						</th>
					}
				}
			</tr>
		} @else {
			<div class="skeleton-header">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100vw', height: '50px', margin: '0px' }">
				</ngx-skeleton-loader>
			</div>
		}
	</ng-template>

	<ng-template #contentTemplate>
		@if (!isLoading) {
			<tbody>
				@for (item of dataSource; track item?.assetId; let i = $index) {
					<tr
						go5-table-row
						[go5-table-row-active]="hoverRow === i"
						(mouseleave)="onMouseLeave()"
						(mouseenter)="onMouseOver(i, item?.assetId)"
						[ngSwitch]="true"
						[ngClass]="{
							'mobile-tr': isWebView || isMobile,
							'mobile-tr-hover': hoverAssetId === item?.assetId
						}"
					>
						<ng-container *ngIf="!isMobile && !isWebView; else tbodyTemplateMobile">
							<ng-template ngFor let-col [ngForOf]="columns">
								<td
									go5-table-cell
									[class]="col.cssClass"
									[ngSwitch]="col.type"
									[ngStyle]="{
										textAlign: col.textAlign,
										padding: col.paddingContent
									}"
									(click)="openAssetSidebar(item?.assetId)"
								>
									<ng-template [ngSwitchCase]="ColumnType.STRING">
										@if (col.name === "checked") {
											<go5-checkbox
												data-testid="input_checkbox_assets"
												[checked]="item.isChecked"
												(changed)="onChangeCheckbox($event, i)"
												(click)="stopPropagation($event)"
											>
											</go5-checkbox>
										}

										@if (col.name === "Name") {
											<div class="assets-details">
												@if (
													AssetsModules[module] !== AssetsModules.SidebarView &&
													AssetsModules[module] !== AssetsModules.EmployeeView
												) {
													<div class="assets-info">
														@if (checkSeparated(item.daysLeftTermination)) {
															<em
																class="gf-icon-exclamation-mark f-16"
																[ngClass]="{
																	'icon-resign': item?.daysLeftTermination === 0,
																	'icon-expire': item?.daysLeftTermination !== 0
																}"
																[go5-tooltip-mode]="'hover'"
																[go5-tooltip]="
																	item?.daysLeftTermination === 0
																		? ('asset_hover_employee_has_been_separated' | translate)
																		: ('asset_hover_employee_separated_in'
																			| translate
																				: {
																						days: item?.daysLeftTermination
																				  })
																"
																tooltipPosition="top"
															></em>
														}
													</div>
												}
												<div class="assets-group-name">
													@if (item?.attachmentURL) {
														<img class="assets-image" [alt]="'assets-image'" [src]="item?.attachmentURL" />
													} @else {
														<img
															class="assets-image"
															[alt]="'assets-empty-image'"
															[src]="'assets/images/empty-stage/empeo-empty-state-no-data.svg' | sAssets"
														/>
													}

													<div>
														<p class="text-body-strong text-color-12 common-content-ellipsis">
															{{ item.name }}
														</p>
														<p class="text-body-small text-color-8">{{ item.code }}</p>
													</div>
												</div>
											</div>
										}

										@if (col.name === "Category") {
											<p class="common-content-ellipsis">
												{{
													data.getIsTH(data.currentLanguage | async) ? item.masterSetupName : item.masterSetupName_EN
												}}
											</p>
										}

										@if (col.name === "Tag") {
											@for (tag of item.tagIds; track $index) {
												<go5-tag-item [data]="mapTags(tag)" [disabled]="true" [fields]="filedTag"> </go5-tag-item>
											}
										}

										@if (col.name === "Cost") {
											<p class="base-content-ellipsis">
												{{ item.cost | currency: "฿" }}
											</p>
										}

										@if (col.name === "Owner") {
											<div class="d-flex flex-direction-column">
												<p class="go5-text-body-strong go5-text-color-12">
													{{ data.getIsTH(data.currentLanguage | async) ? item.ownerFullName : item.ownerFullName_EN }}
												</p>
												<p class="go5-text-small go5-text-color-8">
													{{ item.dateAssigned | sDate: DateFormat.dateShort | async }}
												</p>
											</div>
										}

										@if (col.name === "Status") {
											<go5-status
												size="medium"
												type="Assets"
												[label]="data.getIsTH(data.currentLanguage | async) ? item.statusName : item.statusName_EN"
												[statusId]="item.statusId"
											></go5-status>
										}
									</ng-template>
								</td>
							</ng-template>
						</ng-container>

						<ng-template #tbodyTemplateMobile>
							<td class="asset-table-td" style="text-align: left; max-width: 24px; padding: 4px">
								<div class="d-flex">
									<em
										[ngClass]="{
											open: item?.assetId === expandAsset?.assetId
										}"
										class="gf-icon-down"
										(click)="toggleExpand(item)"
									></em>
								</div>
							</td>
							<td class="asset-table-td" style="padding: 10px 4px" (click)="openAssetSidebar(item?.assetId)">
								<div class="assets-group-name">
									<div class="assets-details">
										@if (
											AssetsModules[module] !== AssetsModules.SidebarView &&
											AssetsModules[module] !== AssetsModules.EmployeeView
										) {
											<div class="assets-info">
												@if (checkSeparated(item.daysLeftTermination)) {
													<em
														class="gf-icon-exclamation-mark f-16"
														[ngClass]="{
															'icon-resign': item?.daysLeftTermination === 0,
															'icon-expire': item?.daysLeftTermination !== 0
														}"
														[go5-tooltip-mode]="'hover'"
														[go5-tooltip]="
															item?.daysLeftTermination === 0
																? ('asset_hover_employee_has_been_separated' | translate)
																: ('asset_hover_employee_separated_in'
																	| translate
																		: {
																				days: item?.daysLeftTermination
																		  })
														"
														tooltipPosition="top"
													></em>
												}
											</div>
										}
										@if (item?.attachmentURL) {
											<img class="assets-image" [alt]="'assets-image'" [src]="item?.attachmentURL" />
										} @else {
											<img
												class="assets-image"
												[alt]="'assets-empty-image'"
												[src]="'assets/images/empty-stage/empeo-empty-state-no-data.svg' | sAssets"
											/>
										}

										<div>
											<p class="text-body-strong text-color-12 common-content-ellipsis">
												{{ item.name }}
											</p>
											<p class="text-small text-color-8">{{ item.code }}</p>
										</div>
									</div>
								</div>
							</td>
							<td
								class="asset-table-td text-center"
								style="padding: 10px 4px"
								(click)="openAssetSidebar(item?.assetId)"
							>
								<go5-status
									size="small"
									type="Assets"
									[label]="data.getIsTH(data.currentLanguage | async) ? item.statusName : item.statusName_EN"
									[statusId]="item.statusId"
								></go5-status>
							</td>
							@if (hasPermissionTransfer) {
								<td style="padding: 12px 8px">
									<go5-checkbox
										data-testid="input_checkbox_assets_mobile"
										[checked]="item.isChecked"
										(changed)="onChangeCheckbox($event, i)"
										(click)="stopPropagation($event)"
									>
									</go5-checkbox>
								</td>
							}
						</ng-template>
					</tr>
					@if ((isMobile || isWebView) && expandAsset && expandAsset.assetId === item.assetId) {
						<ng-template [ngTemplateOutlet]="assetMobileTdTemplate"></ng-template>
					}
				} @empty {
					<tr>
						<td colspan="7" class="py-5">
							<div class="assets-empty-state">
								<img class="empty-image" [src]="'assets/images/empty-stage/empeo-empty-state-no-data.svg' | sAssets" />
								<span class="go5-text-body go5-text-color-8">{{ "asset_empty_state" | translate }}</span>
							</div>
						</td>
					</tr>
				}
			</tbody>
		} @else {
			<tbody>
				@for (skel of skeleton; track $index) {
					<tr class="go5-table-row">
						@for (col of columns; track $index) {
							<td
								class="go5-table-cell"
								[ngStyle]="{ textAlign: col.textAlign, padding: col.paddingContent, cursor: 'default' }"
							>
								<ngx-skeleton-loader [theme]="{ margin: '0px 0px -4px' }"></ngx-skeleton-loader>
							</td>
						}
					</tr>
				}
			</tbody>
		}
	</ng-template>
</go5-table>

<ng-template #assetMobileTdTemplate>
	<tr
		class="mobile-tr"
		[ngClass]="{
			'mobile-tr-hover': hoverAssetId === expandAsset?.assetId
		}"
		(mouseleave)="onMouseLeave()"
		(mouseenter)="onMouseOver(null, expandAsset?.assetId)"
		(click)="openAssetSidebar(expandAsset?.assetId)"
	>
		<td [attr.colspan]="hasPermissionTransfer ? 4 : 3">
			<div class="rec-table-cell-container">
				<div class="go5-text-small-strong go5-text-color-12">
					{{ getColumnKey("Category") | translate }}
				</div>
				<span class="go5-text-body go5-text-color-8">
					{{
						data.getIsTH(data.currentLanguage | async) ? expandAsset.masterSetupName : expandAsset.masterSetupName_EN
					}}
				</span>
			</div>
		</td>
	</tr>
	@if (expandAsset?.tagIds?.length > 0) {
		<tr
			class="mobile-tr"
			[ngClass]="{
				'mobile-tr-hover': hoverAssetId === expandAsset?.assetId
			}"
			(mouseleave)="onMouseLeave()"
			(mouseenter)="onMouseOver(null, expandAsset?.assetId)"
			(click)="openAssetSidebar(expandAsset?.assetId)"
		>
			<td [attr.colspan]="hasPermissionTransfer ? 4 : 3">
				<div class="rec-table-cell-container">
					<div class="go5-text-small-strong go5-text-color-12">
						{{ getColumnKey("Tag") | translate }}
					</div>
					@for (tag of expandAsset?.tagIds; track $index) {
						<go5-tag-item
							[data]="mapTags(tag)"
							[disabled]="true"
							[fields]="filedTag"
							[ngClass]="{ 'asset-st-tag': $index === 0 }"
						>
							>
						</go5-tag-item>
					}
				</div>
			</td>
		</tr>
	}
	@if (expandAsset?.cost && expandAsset?.cost > 0) {
		<tr
			class="mobile-tr"
			[ngClass]="{
				'mobile-tr-hover': hoverAssetId === expandAsset?.assetId
			}"
			(click)="openAssetSidebar(expandAsset?.assetId)"
		>
			<td [attr.colspan]="hasPermissionTransfer ? 4 : 3">
				<div class="rec-table-cell-container">
					<div class="go5-text-small-strong go5-text-color-12">
						{{ getColumnKey("Cost") | translate }}
					</div>
					<p class="base-content-ellipsis go5-text-body go5-text-color-8">
						{{ expandAsset?.cost | currency: "฿" }}
					</p>
				</div>
			</td>
		</tr>
	}
	<tr
		class="mobile-tr"
		[ngClass]="{
			'mobile-tr-hover': hoverAssetId === expandAsset?.assetId
		}"
		(mouseleave)="onMouseLeave()"
		(mouseenter)="onMouseOver(null, expandAsset?.assetId)"
		(click)="openAssetSidebar(expandAsset?.assetId)"
	>
		<td [attr.colspan]="hasPermissionTransfer ? 4 : 3">
			<div class="rec-table-cell-container">
				<div class="go5-text-small-strong go5-text-color-12">
					{{ getColumnKey("Owner") | translate }}
				</div>
				<p class="go5-text-body-strong go5-text-color-8">
					{{ data.getIsTH(data.currentLanguage | async) ? expandAsset.ownerFullName : expandAsset.ownerFullName_EN }}
				</p>
				<p class="go5-text-small go5-text-color-8">
					{{ expandAsset.dateAssigned | sDate: DateFormat.dateShort | async }}
				</p>
			</div>
		</td>
	</tr>
</ng-template>
