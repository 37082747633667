import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { SearchEmployeeTimeAttendance, SearchEmployeeWorkSchedule } from "./employee-time-attendance.model";
import { UserTimeAttendance } from "./user-time-attendance.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeTimeAttendanceService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	callCalTimeAttendance(employeeId: number, dateFrom: string, dateTo: string) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({ employeeId: employeeId, dateFrom: dateFrom, dateTo: dateTo });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeAttendance/CallCalTimeAttendance", body, httpOptions);
	}

	CalTimeAttendanceByType(employeeId: number, dateFrom: string, dateTo: string, isNightShift: boolean) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({
			employeeId: employeeId,
			dateFrom: dateFrom,
			dateTo: dateTo,
			isNightShift: isNightShift,
		});
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeAttendance/CallCalTimeAttendance", body, httpOptions);
	}

	getTimeAttendanceTable(employeeId: number, dateFrom: string, dateTo: string) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({ employeeId: employeeId, dateFrom: dateFrom, dateTo: dateTo });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeAttendance/GetTimeAttendanceTable", body, httpOptions);
	}

	getAbnormalTimeAttendanceByEmployeeId(employeeId: number, year: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "TimeAttendance/GetAbnormalTimeAttendanceByEmployeeId/" + employeeId + "/" + year),
		).then((res) => <any[]>res);
	}

	exportTimeAttendance(model: SearchEmployeeTimeAttendance, version: number = 1) {
		this.commonService.changeDateJSON();
		model.minuteGMT = new Date().getTimezoneOffset();

		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			params: { version },
		};
		return this.http.post(this.apiUrl + "TimeAttendance/ExportTimeAttendance", body, httpOptions);
	}

	CalTimeAttendanceByCompanyId(companyId: number, year: number, month: number, day: number) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({ companyId: companyId, year: year, month: month, day: day });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeAttendance/CalculateTimeAttendanceByCompanyId", body, httpOptions);
	}

	deleteUserTimeAttendances(timeAttendances: any) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(timeAttendances);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeAttendance/DeleteUserTimeAttendances", body, httpOptions);
	}

	getAbnormalSummariesByEmployeeId(employeeId: number, year: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "TimeAttendance/" + year + "/abnormals/" + employeeId + "/summaries"),
		).then((res) => <UserTimeAttendance>res);
	}

	getTimeAttendanceByUserId(userId: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "TimeAttendance/GetTimeAttendanceByUserId/" + userId)).then(
			(res) => <UserTimeAttendance[]>res,
		);
	}

	getStatusCalculateTimeAttendance() {
		return firstValueFrom(this.http.get(`${this.apiUrl}v1/time-attendances/calculate/status`)).then((r) => <any>r);
	}

	addTempCalculateTimeAttendance(model) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(`${this.apiUrl}v1/time-attendances/calculate`, body, httpOptions);
	}

	exportWorkSchedule(model: SearchEmployeeWorkSchedule) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeAttendance/ExportWorkSchedule", body, httpOptions);
	}

	exportWorkSchedulePDF(model: SearchEmployeeWorkSchedule) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeAttendance/ExportWorkSchedulePDF", body, httpOptions);
	}

	calTimeAttendances(employeeId: number, dates: string) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({ employeeId: employeeId, dates: dates });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(`${this.apiUrl}v1/time-attendances/calculation`, body, httpOptions);
	}
}
