import { OAuthModuleConfig } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment";
export const authModuleConfig: OAuthModuleConfig = {
	resourceServer: {
		allowedUrls: [
			environment.issuer.toLowerCase(),
			environment.apiUrl,
			environment.gofiveApiUrl,
			environment.gofiveDeeplinkUrl,
			environment.clientPortal,
			environment.venioApiUrl,
		],
		sendAccessToken: true,
	},
};
