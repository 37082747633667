/// <reference path="export-social-security.model.ts" />

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { DropdownDataItem } from "../../../shared/models/dropdown-data-item.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { ReportSocialSecurity, ExportSocialSecurity, ReportModel } from "./export-social-security.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ExportSocialSecurityService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getDDLTypeOfEmployments() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLTypeOfEmployments")).then((res) => <Dropdown[]>res);
	}

	getDDLNationalitySSO() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLNationalitySSO")).then(
			(res) => <DropdownDataItem[]>res,
		);
	}

	getEmployeeSocialSecurity(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeSocialSecurity/GetExportSocialSecurity",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	SaveTextEmployeeSocialSecurity(model: ExportSocialSecurity) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeSocialSecurity/SaveTextEmployeeSocialSecurity", body, httpOptions);
	}

	getUrlExportSocialSecurity(model: ExportSocialSecurity) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeSocialSecurity/GetUrlExportSocialSecurity", body, httpOptions);
	}

	getReportSSO001(filter: ReportSocialSecurity) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportSSO001", body, httpOptions);
	}
}
