import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Calendar } from "./calendar.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { Checkbox } from "../../../shared/models/checkbox.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class CalendarService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getCalendarDayOffGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Calendar/GetCalendarDayOffDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getDDLHolidays() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Calendar/GetDDLHolidays")).then((res) => <Dropdown[]>res);
	}

	getCBLHolidayTypes() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Calendar/GetCBLHolidayTypes")).then(
			(res) => <Checkbox[]>res,
		);
	}

	getDDLYear() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Calendar/GetDDLYear")).then((res) => <Dropdown[]>res);
	}

	getCalendarDayOff(
		calendarId: number,
		refYear: number,
		isGovernment: boolean,
		isBusiness: boolean,
		isTradition: boolean,
	) {
		let params = new HttpParams();
		params = params.append("calendarId", String(calendarId));
		params = params.append("refYear", String(refYear));
		params = params.append("isGovernment", String(isGovernment));
		params = params.append("isBusiness", String(isBusiness));
		params = params.append("isTradition", String(isTradition));

		return firstValueFrom(this.http.get(this.apiUrl + "Calendar/GetCalendarDayOff", { params })).then(
			(res) => <Calendar[]>res,
		);
	}

	addCalendarDayOff(mas: Calendar) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(mas);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/AddCalendarDayOff", body, httpOptions);
	}
	addDefaultCalendar(calendarId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Calendar/AddDefaultCalendar/" + calendarId));
	}

	getCalendarDayOffById(calendarId: number, year: number, isActive: boolean) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Calendar/GetCalendarDayOffById/" + calendarId + "/" + year + "/" + isActive),
		).then((res) => <any>res);
	}

	getCalendarById(year: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Calendar/GetCalendarById/" + year)).then((res) => <any>res);
	}

	getCalendarByHolidayId(holidayId: number, year: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Calendar/GetCalendarById/" + holidayId + "/" + year)).then(
			(res) => <any>res,
		);
	}

	getDDLCalendarYear() {
		return firstValueFrom(this.http.get(this.apiUrl + "Calendar/GetDDLCalendarYear/")).then((res) => <any>res);
	}

	addCalendarDayOffPageAddYear(holidayId: string[], calendarId: number, refYear: number) {
		var body = JSON.stringify({ holidaysId: holidayId, calendarId: calendarId, refYear: refYear });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/AddCalendarDayOffPageAddYear", body, httpOptions);
	}

	deleteCalendarDayOff(dayOffId: string[]) {
		var body = JSON.stringify(dayOffId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/DeleteCalendarDayOff", body, httpOptions);
	}

	addCalendar(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/AddCalendar", body, httpOptions);
	}

	createCalendarDayOffById(year: number, copyFromYear: number) {
		var body = JSON.stringify({ year: year, copyFromYear: copyFromYear });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/CreateCalendarDayOffById", body, httpOptions);
	}

	copyCalendar(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/CopyCalendar", body, httpOptions);
	}

	deleteCalendarById(calendarId: number) {
		var body = JSON.stringify(calendarId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/DeleteCalendarById", body, httpOptions);
	}

	getCalendarDayOffByDayOffId(dayOffId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Calendar/GetCalendarDayOffByDayOffId/" + dayOffId)).then(
			(res) => <any>res,
		);
	}

	getCalendarByCalendarId(calendarId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Calendar/GetCalendarByCalendarId/" + calendarId)).then(
			(res) => <any>res,
		);
	}

	updateLinkCalendarDayOff(model: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/UpdateLinkCalendarDayOff", body, httpOptions);
	}

	editCalendar(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/EditCalendar", body, httpOptions);
	}

	deleteHoliday(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/DeleteHoliday", body, httpOptions);
	}

	addHoliday(model: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/AddHoliday", body, httpOptions);
	}

	editHoliday(model: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Calendar/EditHoliday", body, httpOptions);
	}
}
