export class MovementSearch {
	employeeId: number;
	companyId: number;
	rankNo: number;
	movementTypeId: number;
	documentStatusId: number;
	organizationId: number;
	keyword: string;
	dateStart: Date;
	dateEnd: Date;
	sortOrder: string;
	skipRow: number;
	takeRow: number;
	isDocumentRevise: boolean = false;
}

export class TotalEmployeeMovementCentric {
	totalOfAgencyTransfer: number;
	totalOfAll: number;
	totalOfAppoint: number;
	totalOfChangeSupervisor: number;
	totalOfCrossCompany: number;
	totalOfEmploymentConditions: number;
	totalOfHistoryGoodness: number;
	totalOfPermanent: number;
	totalOfPromote: number;
	totalOfRaiseSalary: number;
	totalOfRenewal: number;
	totalOfResign: number;
	totalOfStartWorking: number;
	totalOfUnresign: number;
	totalPages: number;
}
export class EmployeeMovementCentric extends TotalEmployeeMovementCentric {
	employeeMovements: any[];
}
