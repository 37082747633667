export enum Module {
	SYS001 = "SYS001",
	SYS001_T04 = "SYS001.T04",
	SYS002 = "SYS002",
	SYS003_T01 = "SYS003.T01",
	SYS003_T02 = "SYS003.T02",
	SYS003 = "SYS003",
	SYS004 = "SYS004",
	SYS005 = "SYS005",
	SYS006 = "SYS006",
	SYS007 = "SYS007",
	SYS008 = "SYS008",
	SYS009 = "SYS009",
	SYS010 = "SYS010",
	SYS011 = "SYS011",
	SYS012 = "SYS012",
	SYS013 = "SYS013",
	SYS014 = "SYS014",
	SYS015 = "SYS015",
	SYS016 = "SYS016",
	SYS018 = "SYS018",
	SYS019 = "SYS019",
	SYS020 = "SYS020",
	SYS021 = "SYS021",
	SYS022 = "SYS022",
	SYS023 = "SYS023",
	SYS024 = "SYS024",
	SYS025 = "SYS025",
	SYS027 = "SYS027",
	SYS027_T01 = "SYS027.T01",
	SYS027_T02 = "SYS027.T02",
	MAS001 = "MAS001",
	MAS002 = "MAS002",
	MAS003 = "MAS003",
	MAS004 = "MAS004",
	MAS005 = "MAS005",
	MAS006 = "MAS006",
	MAS007 = "MAS007",
	MAS008 = "MAS008",
	MAS009 = "MAS009",
	MAS010 = "MAS010",
	MAS011 = "MAS011",
	MAS013 = "MAS013",
	MAS014 = "MAS014",
	MAS015 = "MAS015",
	MAS016 = "MAS016",
	MAS017 = "MAS017",
	MAS018 = "MAS018",
	EMP001 = "EMP001",
	EMP001_T02 = "EMP001.T02",
	EMP001_T03 = "EMP001.T03",
	EMP001_T04 = "EMP001.T04",
	EMP001_T05 = "EMP001.T05",
	EMP001_T06 = "EMP001.T06",
	EMP001_T07 = "EMP001.T07",
	EMP001_T08 = "EMP001.T08",
	EMP001_T09 = "EMP001.T09",
	EMP001_T10 = "EMP001.T10",
	EMP001_T11 = "EMP001.T11",
	EMP001_T12 = "EMP001.T12",
	EMP001_T13 = "EMP001.T13",
	EMP001_T14 = "EMP001.T14",
	EMP001_T15 = "EMP001.T15",
	EMP001_T16 = "EMP001.T16",
	EMP001_T17 = "EMP001.T17",
	EMP001_T18 = "EMP001.T18",
	EMP001_T19 = "EMP001.T19",
	EMP001_T20 = "EMP001.T20",
	EMP001_T21 = "EMP001.T21",
	EMP001_T22 = "EMP001.T22",
	EMP001_T23 = "EMP001.T23",
	EMP001_T24 = "EMP001.T24",
	EMP001_T25 = "EMP001.T25",
	EMP001_T26 = "EMP001.T26",
	EMP001_T27 = "EMP001.T27",
	EMP001_T28 = "EMP001.T28",
	EMP001_T29 = "EMP001.T29",
	EMP001_T30 = "EMP001.T30",
	EMP001_T31 = "EMP001.T31",
	EMP001_T32 = "EMP001.T32",
	EMP001_T34 = "EMP001.T34",
	EMP001_T35 = "EMP001.T35",
	EMP001_T36 = "EMP001.T36",
	EMP002 = "EMP002",
	EMP003 = "EMP003",
	EMP004 = "EMP004",
	EMP005 = "EMP005",
	EMP006 = "EMP006",
	EMP006_T01 = "EMP006.T01",
	EMP006_T02 = "EMP006.T02",
	EMP006_T03 = "EMP006.T03",
	EMP006_T04 = "EMP006.T04",
	EMP006_T05 = "EMP006.T05",
	EMP006_T06 = "EMP006.T06",
	EMP006_T07 = "EMP006.T07",
	EMP006_T08 = "EMP006.T08",
	EMP006_AD = "EMP006.AD",
	EMP007 = "EMP007",
	EMP008 = "EMP008",
	EMP009 = "EMP009",
	EMP010 = "EMP010",
	EMP011 = "EMP011",
	EMP012 = "EMP012",
	EMP012_01 = "EMP012.01",
	EMP012_02 = "EMP012.02",
	LEV001 = "LEV001",
	LEV002 = "LEV002",
	LEV003 = "LEV003",
	LEV004 = "LEV004",
	LEV005 = "LEV005",
	LEV006 = "LEV006",
	LEV007 = "LEV007",
	LEV008 = "LEV008",
	LEV009 = "LEV009",
	LEV010 = "LEV010",
	LRN001 = "LRN001", // empeo Learn
	LRN001_T02_02 = "LRN001.T02.02", // คอร์สเรียน
	LRN001_T02_01 = "LRN001.T02.01", // จัดการคอร์สเรียนทั้งหมดในองค์กรได้
	LRN001_T02_04 = "LRN001.T02.04", // จัดการการอบรมทั้งหมดในองค์กรได้
	ESS001 = "ESS001",
	ESS002 = "ESS002",
	ESS003 = "ESS003",
	ESS004 = "ESS004",
	ESS005 = "ESS005",
	ESS006 = "ESS006",
	ESS007 = "ESS007",
	ESS008 = "ESS008",
	ESS009 = "ESS009",
	ESS009_T01 = "ESS009.T01",
	ESS010 = "ESS010",
	ESS011 = "ESS011",
	TRN001 = "TRN001", // การอบรม
	TRN002 = "TRN002",
	TRN003 = "TRN003",
	WEL001 = "WEL001",
	WEL002 = "WEL002",
	WEL003 = "WEL003",
	WEL004 = "WEL004",
	WEL005 = "WEL005",
	WEL006 = "WEL006",
	WEL007 = "WEL007",
	WEL008 = "WEL008",
	WEL009 = "WEL009",
	WEL010 = "WEL010",
	WEL011 = "WEL011",
	WEL012 = "WEL012",
	WEL014 = "WEL014",
	RPT001 = "RPT001",
	RPT002 = "RPT002",
	RPT003 = "RPT003",
	RPT004 = "RPT004",
	RPT005 = "RPT005",
	RPT006 = "RPT006",
	RPT007 = "RPT007",
	RPT008 = "RPT008",
	RPT009 = "RPT009",
	RPT010 = "RPT010",
	RPT011 = "RPT011",
	RPT012 = "RPT012",
	RPT013 = "RPT013",
	RPT014 = "RPT014",
	RPT015 = "RPT015",
	RPT016 = "RPT016",
	RPT017 = "RPT017",
	RPT018 = "RPT018",
	RPT019 = "RPT019",
	RPT020 = "RPT020",
	RPT021 = "RPT021",
	RPT022 = "RPT022",
	RPT023 = "RPT023",
	RPT024 = "RPT024",
	RPT025 = "RPT025",
	RPT026 = "RPT026",
	RPT027 = "RPT027",
	RPT028 = "RPT028",
	RPT029 = "RPT029",
	RPT030 = "RPT030",
	RPT031 = "RPT031",
	RPT032 = "RPT032",
	RPT033 = "RPT033",
	RPT034 = "RPT034",
	RPT035 = "RPT035",
	RPT036 = "RPT036",
	RPT037 = "RPT037",
	RPT038 = "RPT038",
	RPT039 = "RPT039",
	RPT040 = "RPT040",
	RPT041 = "RPT041",
	MSS001 = "MSS001",
	MSS002 = "MSS002",
	MSS003 = "MSS003",
	MSS001_01 = "MSS001.01",
	MSS001_02 = "MSS001.02",
	MSS001_03 = "MSS001.03",
	MSS001_04 = "MSS001.04",
	MSS001_T01 = "MSS001.T01",
	MSS002_01 = "MSS002.01",
	MSS003_01 = "MSS003.01",
	MSS003_02 = "MSS003.02",
	MSS003_03 = "MSS003.03",
	PRO001 = "PRO001",
	PRO002 = "PRO002",
	PAY001 = "PAY001",
	PAY002 = "PAY002",
	PAY007 = "PAY007",
	PAY001_T01 = "PAY001.T01",
	PAY001_T02 = "PAY001.T02",
	PAY001_T03 = "PAY001.T03",
	PAY001_T04 = "PAY001.T04",
	PAY001_T05 = "PAY001.T05",
	PAY001_T06 = "PAY001.T06",
	PAY001_T07 = "PAY001.T07",
	PAY001_T08 = "PAY001.T08",
	PAY001_T09 = "PAY001.T09",
	PAY001_T10 = "PAY001.T10",
	PAY001_T11 = "PAY001.T11",
	PAY001_T12 = "PAY001.T12",
	PAY001_T13 = "PAY001.T13",
	PAY001_T14 = "PAY001.T14",
	PAY001_T15 = "PAY001.T15",
	PAY001_T16 = "PAY001.T16",
	PAY001_T17 = "PAY001.T17",
	PAY001_T18 = "PAY001.T18",
	PAY001_T19 = "PAY001.T19",
	PAY001_T25 = "PAY001.T25",
	PAY001_T26 = "PAY001.T26",
	PAY001_T27 = "PAY001.T27",
	PAY001_T28 = "PAY001.T28",
	PAY001_T29 = "PAY001.T29",
	PAY001_T30 = "PAY001.T30",
	PAY001_T31 = "PAY001.T31",
	PAY001_T32 = "PAY001.T32",
	PAY001_T33 = "PAY001.T33",
	PAY001_T34 = "PAY001.T34",
	PAY002_T01 = "PAY002.T01",
	PAY002_T02 = "PAY002.T02",
	PAY002_T03 = "PAY002.T03",
	DOC001 = "DOC001",
	ASM001 = "ASM001",
	ASM001_EMP = "ASM001-EMP",
	ASM001_T01 = "ASM001.T01",
	ASM001_T02 = "ASM001.T02",
	ASM001_T03 = "ASM001.T03",
	ASM001_T04 = "ASM001.T04",
	ASM001_T05 = "ASM001.T05",
	ASM001_T06 = "ASM001.T06",
	ASM001_T07 = "ASM001.T07",
	ASM001_T09 = "ASM001.T09",
	ASM001_T10 = "ASM001.T10",
	ASM002 = "ASM002",
	ASM003 = "ASM003",
	ASM004 = "ASM004",
	ASM005 = "ASM005",
	ASM006 = "ASM006",
	OKR001_T01 = "OKR001.T01",
	OKR001_T02 = "OKR001.T02",
	EXP001 = "EXP001",
	EXP1606 = "1606",
	EMC001 = "EMC001",
	TSH001 = "TSH001",
	TSH001_T01 = "TSH001.T01",
	TSH001_T02 = "TSH001.T02",
	TSH002 = "TSH002",
	OKR001 = "OKR001",
	IGT001 = "IGT001",
	ANN001 = "ANN001",
	PLC001 = "PLC001",
	ExpenseView = "1604",
	ExpenseViewAll = "1606",
	ExpenseGod = "1607",
	ExpenseApproveAndReject = "1605",
	RCM001_T03 = "RCM001.T03",
	BI001 = "BI001",
}
export enum ModuleWorkflow {
	//empeo
	Leave = 1,
	Endorse = 2,
	Outside = 3,
	Exchange = 4,
	Overtime = 5,
	CollectHoliday = 6,
	ExchangeHoliday = 7,
	InternalDocument = 8,
	WelfareRequest = 9,
	Punishment = 14,
	//venio
	Activity = 10,
	Expense = 11,
	Quotation = 12,
	Movement = 13,
}

export enum GofiveCoreModuleEnum {
	announcement = "announcement",
	approvalFlow = "approval-flow",
	emconnect = "emconnect",
	assessment = "assessment",
	payment = "payment",
	expense = "expense",
	report = "report",
	integration = "integration",
	emconnectUserManagement = "emconnectUserManagement",
	administrator = "administrator",
	empeoOkr = "empeo-okr",
	policy = "policy",
	criminalChecker = "criminal-checker",
	empeoLearn = "empeo-learn",
	dialog = "dialog",
	assessmentCalibration = "assessment-calibration",
}

export enum ModuleType {
	ModuleType3 = 3,
}
