import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { DataSharingService } from "src/app/core/data-sharing.service";
import { EmployeeMovementService } from "src/app/employee-movements/employee-movement/shared/employee-movement.service";
import { MessageWarningType } from "../../enum/message-warning-type.enum";
import { Movement } from "../../enum/movement.enum";
import { ProcessAction } from "../../enum/process-action.enum";
import { Dropdown } from "../../models/dropdown.model";
import { CommonService } from "../../service/common.service";
import { SharingService } from "../../sharing.service";
import { DateFormat, AngularCommonModule } from "@gofive/angular-common";
import { AsyncPipe } from "@angular/common";
import { MovementDetailComponent } from "../../../movement/movement-detail/movement-detail.component";
import { SidebarModule } from "@gofive/design-system-navigation";
@Component({
	selector: "info-bar",
	templateUrl: "./info-bar.component.html",
	styleUrls: ["./info-bar.component.scss"],
	standalone: true,
	imports: [SidebarModule, MovementDetailComponent, AsyncPipe, TranslateModule, AngularCommonModule],
})
export class InfoBarComponent implements OnInit {
	@Input() public message: string;
	@Input() public type: number = 0;
	@Input() public movementType: number = 0;
	@Input() public employeeId: number = 0;
	@Output() public checkMovementEffectiveEvent = new EventEmitter<boolean>();
	@Output() public movementWaitingEffective = new EventEmitter<boolean>();
	public messageWarn: string;
	private language$: Subscription;
	private userInfo$: Subscription;
	public userInfo: any;

	//#region Type Movement
	public movement: any;
	public movementText: string;
	public movementTransactionId: string;
	public movementTypeDDL: Dropdown[];
	public warningMovement: any = [];
	public displayMovementDetail: boolean = false;
	public isClickSidebar: boolean = false;
	public isMovementType: boolean;
	public raiseTheSalary: number = Movement.raiseTheSalary;
	//#endregion
	public showMulti: boolean = false;
	public language: string;

	get dateFormat() {
		return DateFormat;
	}

	constructor(
		private translate: TranslateService,
		private employeeMovementService: EmployeeMovementService,
		private commonService: CommonService,
		public sharingService: SharingService,
		public dataShare: DataSharingService,
	) {}
	ngOnInit() {
		this.userInfo$ = this.dataShare.currentUserInfo.subscribe((data) => {
			this.userInfo = data;
		});
		this.showMulti = MessageWarningType.Movement == this.type || MessageWarningType.MovementType == this.type;
		if (this.showMulti) this.loadMovementTypeDDL();
		this.language$ = this.dataShare.currentLanguage.subscribe((lang) => {
			this.language = lang;
			setTimeout(() => {
				switch (this.type) {
					case MessageWarningType.DayOfBackdateTime:
						{
							this.messageWarn =
								this.language == "th-TH"
									? `${this.translate.instant(
											"emp001_t08_warning_message_free_en_1",
										)} ${this.userInfo?.dayOfBackdateTime.toLocaleString()} ${this.translate.instant("common_day")}`
									: `${this.translate.instant(
											"emp001_t08_warning_message_free_en_1",
										)} ${this.userInfo?.dayOfBackdateTime.toLocaleString()} ${this.translate.instant(
											"das001_days",
										)} ${this.translate.instant("emp001_t08_warning_message_free_en_2")}`;
						}
						break;
					case MessageWarningType.Movement:
						{
							this.isClickSidebar = true;
							this.loadMovement();
						}
						break;
					case MessageWarningType.MovementType:
						{
							this.isMovementType = this.movementType == this.raiseTheSalary ? false : true;
							this.loadMovementType();
						}
						break;
					default:
						this.messageWarn = this.translate.instant(this.message);
						break;
				}
			}, 1000);
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.employeeId?.currentValue != changes.employeeId?.previousValue) {
			this.warningMovement = [];
			this.employeeId = changes.employeeId?.currentValue;
			this.dataShare.setMovementTransactionId(0);
			if (this.type === MessageWarningType.MovementType) {
				this.isMovementType = true;
				this.loadMovementTypeDDL();
				this.loadMovementType();
			}
		}
	}
	////#region Type Movement
	loadMovementTypeDDL() {
		this.commonService.getDDLProcessActionByModule(ProcessAction.employeeModule).then((res) => {
			this.movementTypeDDL = res;
		});
	}
	async loadMovement() {
		if (this.employeeId > 0) {
			const isWaitingEffectiveOnly = true;
			await this.employeeMovementService
				.getEmployeeMovementTransactions(this.employeeId, null, isWaitingEffectiveOnly)
				.then(async (res) => {
					this.movement = res;
					this.warningMovement = await this.replaceText();
				});
		}
	}
	loadMovementType() {
		if (this.employeeId > 0) {
			const isWaitingEffectiveOnly = true;
			this.employeeMovementService
				.getEmployeeMovementTransactions(this.employeeId, this.movementType, isWaitingEffectiveOnly)
				.then(async (res) => {
					this.movement = res;
					if (this.movement.length > 0) {
						this.dataShare.setMovementTransactionId(this.movement[0].movementTransactionId);
						this.warningMovement = this.replaceText();
					}
					this.checkMovementEffectiveEvent.emit(true);
					this.movementWaitingEffective.emit(this.movement ?? []);
				});
		}
	}
	receiveCloseEvent() {
		this.displayMovementDetail = false;
		this.dataShare.setMovementTransactionId(0);
	}
	receiveMovementTransaction(event) {
		if (event) {
			this.displayMovementDetail = false;
			this.loadMovement();
		}
	}
	replaceText() {
		var res = [];
		if (this.isMovementType) return this.movement;
		this.movement?.forEach((item) => {
			var m = this.movementTypeDDL?.find((f) => f.id == item.movementTypeId);
			res.push({
				movementType: this.language == "th-TH" ? m?.name : m?.name_EN,
				movementCode: item.movementCode,
				status: this.translate.instant(this.sharingService.getLabelDocumentStatus(item.documentStatusId).toString()),
				movementTransactionId: item.movementTransactionId,
				date: item.dateEffective,
			});
		});
		return res;
	}
	viewMovement(movementTransactionId) {
		if (this.isMovementType) return;
		this.dataShare.setMovementTransactionId(movementTransactionId);
		this.movementTransactionId = movementTransactionId;
		this.displayMovementDetail = !this.displayMovementDetail;
	}

	//#endregion
	ngOnDestroy() {
		this.language$ && this.language$.unsubscribe();
		this.userInfo$ && this.userInfo$.unsubscribe();
		this.isMovementType = false;
	}
}
