import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SurveyStatus } from "./servey.model";

@Injectable()
export class SurveyService {
	private headers = new HttpHeaders({
		"Content-Type": "application/json",
		"x-AppName": "empeo",
	});
	private httpOptions = {
		headers: this.headers,
	};
	constructor(
		private http: HttpClient,
		@Inject("GOFIVE_API_URL") private apiUrl: string,
	) {}

	updateDateSurvey(surveyStatus: SurveyStatus) {
		return this.http.put<any>(`${this.apiUrl}v1/simpleSAT/update?surveyStatus=${surveyStatus}`, this.httpOptions);
	}
	createSurvey(model) {
		var body = JSON.stringify(model);
		return this.http.post<any>(`${this.apiUrl}v1/survey/create`, body, this.httpOptions);
	}

	//เรียก proxy จากไฟล์ proxy.conf.json
	//#region Open API Methods

	// getListQuestions() {
	// 	return this.http.get<any>(`/api/v1/questions`, this.httpOptions).);
	// }

	// getListSurveys() {
	// 	return this.http.get<any>(`/api/v1/surveys`, this.httpOptions).);
	// }
	// getAnswers(answers_id: number) {
	// 	return this.http.get<any>(`/api/v1/answers/${answers_id}`, this.httpOptions).);
	// }
	// updateAnswers(answers_id: number, model) {
	// 	var body = JSON.stringify(model);
	// 	return this.http
	// 		.put<any>(`/api/v1/answers/${answers_id}`, body, {
	// 			headers: this.headers,
	// 			observe: "response",
	// 		})
	// 		.);
	// }
	// addSurvey(model: BodyPostSimpleSAT) {
	// 	var body = JSON.stringify(model);
	// 	return this.http.post<any>(`/api/v1/responses/create-or-update`, body, {
	// 		headers: this.headers,
	// 		observe: "response",
	// 	});
	// }
	//#endregion
}
