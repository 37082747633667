export enum DocumentStatus {
	draft = 1,
	waitingApprove = 2,
	waitingRevise = 3,
	approved = 4,
	canceled = 5,
	rejected = 6,
	completed = 7,
	waitingAcknowledge = 8,
	acknowledged = 9,
}
export enum DocumentAction {
	draft = 1,
	send = 2,
}
