export enum AssessmentType {
	assessmentType = 1000,
	assessmentGeneral = 1001,
	assessmentJob = 1002,
	assessmentProbationExam = 1003,
	assessmentAnnual = 1004,
	assessmentSelf = 1005,
	assessmentSupervisor = 1006,
	assessmentProbation = 1007,
	assessmentSubordinate = 1008,
	assessmentProbationSup = 1009,
	assessmentOrganization = 1010,
	assessmentSupervisorSelf = 1011,
	assessmentHeadSubordinate = 1012,
	assessmentPeerReview = 1013,
	assessmentOKR = 1014,
	assessmentResign = 1019,
	candidate = 1015,
	learnAssessment = 1016,
	courseAssessment = 1017,
	trainingAssessment = 1018,
	separationAssessment = 1019,
	scoreType = 2000,
	questionType = 3000,
	oneChoice = 3001,
	scale = 3003,
	text = 3004,
	scaleRemark = 3007,
	new = 4001,
	hasProgress = 4002,
	successfully = 4003,
}
