export class Workflow {
	workflowId: number;
	name: string;
	name_EN: string;
	isActive: boolean;
	isDelete: boolean;
	finalApprovalId: number;
	finalApprovalName: string;
	finalApprovalName_EN: string;
	workflowDetails: Array<any>;
	dateModified: string;
	modifiedBy: string;
	status: string;
	status_EN: string;
}
export class WorkflowSearch {
	moduleId: number;
	referenceId: number;
	userId: string;
	tenantId: string;
	isGroup: boolean;
}
