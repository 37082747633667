import { registerLocaleData } from "@angular/common";
import { HttpBackend } from "@angular/common/http";
import localeTH from "@angular/common/locales/th";
import localeMY from "@angular/common/locales/my";
import localeZH from "@angular/common/locales/zh";
import { L10n, loadCldr } from "@syncfusion/ej2-base";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { environment } from "../environments/environment";

loadCldr(
	require("cldr-data/supplemental/numberingSystems.json"),
	require("cldr-data/main/th/ca-gregorian.json"),
	require("cldr-data/main/th/numbers.json"),
	require("cldr-data/main/th/timeZoneNames.json"),
);
L10n.load(require("./../assets/i18n/syncfusion.json"));

registerLocaleData(localeTH);
registerLocaleData(localeMY);
registerLocaleData(localeZH);

export function HttpLoaderFactory(http: HttpBackend) {
	return new MultiTranslateHttpLoader(http, [
		{ prefix: environment.phrase.backupPrefix, suffix: environment.phrase.backupSuffix + "?v=" + environment.VERSION },
		{ prefix: environment.phrase.prefix, suffix: environment.phrase.suffix + "&v=" + environment.VERSION },
	]);
}
