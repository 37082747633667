import { Injectable, Inject } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable()
export class DiligenceAllowanceService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getDiligenceAllowanceByCompanyId(companyId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "DiligenceAllowance/GetDiligenceAllowanceByCompanyId/" + companyId, {
				observe: "response",
			}),
		).then((response) => response);
	}

	diligenceAllowanceInitialConfig(model) {
		var body = JSON.stringify(model);
		return firstValueFrom(
			this.http.post(this.apiUrl + "DiligenceAllowance/DiligenceAllowanceInitialConfig", body, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				observe: "response",
			}),
		);
	}

	getDiligenceAllowanceListByCompanyId(companyId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "diligence-allowance/companies/" + companyId, {
				observe: "response",
			}),
		).then((response) => response);
	}
}
