export class EmployeeAddress {
	employeeId: number;
	employeeAddressId: number = 0;
	addressType: number;
	address: string;
	subdistrict: string;
	districtId: number;
	provinceId: number;
	postalCode: string;
	phoneNo: string;
}
