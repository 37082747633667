import {
	Component,
	OnChanges,
	SimpleChanges,
	Input,
	Output,
	EventEmitter,
	NgZone,
	ChangeDetectorRef,
	OnDestroy,
} from "@angular/core";
import { CommonModule, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TranslateModule } from "@ngx-translate/core";
import { BadgeModule } from "@gofive/design-system-badge";
import { TagsModule } from "@gofive/design-system-tags";
import { BaseModule } from "@gofive/design-system-base";
import { AttachmentModule } from "@gofive/design-system-attachment";
import { ButtonModule } from "@gofive/design-system-button";
import { COLUMN_TYPE, TableModule } from "@gofive/design-system-table";
import { AssetsModel } from "../../models/assets.model";
import { DataSharingService } from "src/app/core/data-sharing.service";
import { AngularCommonModule, DateFormat } from "@gofive/angular-common";
import { InputModule } from "@gofive/design-system-input";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { ColumnConfiguration, StringColumnConfiguration } from "@gofive/design-system-table";
import { Subscription, fromEvent } from "rxjs";

export enum AssetsModules {
	AssetManagement = 0,
	EmployeeView = 1,
	SidebarView = 2,
}
@Component({
	selector: "app-assets-management-table",
	templateUrl: "./assets-management-table.component.html",
	styleUrls: ["./assets-management-table.component.scss"],
	standalone: true,
	imports: [
		AngularCommonModule,
		AttachmentModule,
		ButtonModule,
		BadgeModule,
		BaseModule,
		InputModule,
		CommonModule,
		NgSwitch,
		NgSwitchCase,
		NgSwitchDefault,
		NgxSkeletonLoaderModule,
		TranslateModule,
		TagsModule,
		TableModule,
		TooltipModule,
	],
})
export class AssetsManagementTableComponent implements OnChanges, OnDestroy {
	@Input() columns;
	@Input() tagList = [];
	@Input() dataSource: AssetsModel[] = [];
	@Input() dataTransfer: any[] = [];
	@Input() isLoading: boolean = false;
	@Input() module: string = "";
	@Input() hasPermissionTransfer = false;
	@Input() isWebView = false;
	@Output() sortingChange = new EventEmitter<string>();
	@Output() openViewSidebar = new EventEmitter<number>();
	@Output() dataTransferChange = new EventEmitter<any[]>();
	@Output() dataSourceChange = new EventEmitter<AssetsModel[]>();

	// Subscription
	private $_resize: Subscription;
	// private $_windowScroll: Subscription;

	// @variable
	public skeleton = Array(8);
	public hoverRow: number | null = null;
	public hoverAssetId: number | null = null;
	public filedTag = { text: "name", value: "tagId" };
	public isCheckAll: boolean;
	public sortOrder = "";
	public selectAssetId: number;
	public displayAssetsSidebar: boolean = false;
	public isMobile = false;
	public paddingTableMobile = "8px 12px";
	public columnsMobile: ColumnConfiguration[] = [
		{
			...new StringColumnConfiguration("Expand", null, null),
			width: "24px",
			minWidth: "24px",
			maxWidth: "24px",
			showIconSort: false,
		},
		{
			...new StringColumnConfiguration("Name", null, "asset_name"),
			width: "65%",
			textAlign: "left",
			minWidth: "0px",
			showIconSort: true,
			paddingHeader: this.paddingTableMobile,
			paddingContent: this.paddingTableMobile,
		},
		{
			...new StringColumnConfiguration("Status", null, "common_status"),
			width: "35%",
			showIconSort: true,
			textAlign: "center",
			minWidth: "0px",
			paddingHeader: this.paddingTableMobile,
			paddingContent: this.paddingTableMobile,
		},
	];
	public expandAsset = null;
	// @getter
	// skipcq: JS-0105
	get ColumnType() {
		return COLUMN_TYPE;
	}

	// skipcq: JS-0105
	get DateFormat() {
		return DateFormat;
	}

	// skipcq: JS-0105
	get AssetsModules() {
		return AssetsModules;
	}

	constructor(
		public data: DataSharingService,
		private zone: NgZone,
		public _cdr: ChangeDetectorRef,
	) {
		this.isMobile = window.innerWidth < 940;
		this.zone.runOutsideAngular(() => {
			this.$_resize = fromEvent(window, "resize").subscribe(() => {
				if ((!this.isMobile && window.innerWidth < 940) || (this.isMobile && window.innerWidth >= 940)) {
					this.isMobile = window.innerWidth < 940;
					this._cdr.detectChanges();
				}
				if (window.innerWidth >= 940) {
					this.expandAsset = null;
				}
			});
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.dataSource?.currentValue) {
			this.updateCheckAllStatus();
		}
		if (changes?.hasPermissionTransfer?.currentValue) {
			if (this.hasPermissionTransfer === true) {
				this.columnsMobile.push({
					...new StringColumnConfiguration("checked", null, ""),
					width: "36px",
					minWidth: "36px",
					textAlign: "center",
					showIconSort: false,
					paddingHeader: "12px 8px",
					paddingContent: "12px 8px",
				});
			}
		}
	}

	ngOnDestroy(): void {
		this.$_resize?.unsubscribe();
	}

	public mapTags(tagId: number) {
		const tagModel = this.tagList.find((m) => m.tagId === tagId);
		if (tagModel) {
			return { name: tagModel.name, tagId: tagModel.tagId };
		}
		return [];
	}

	private mapTransfer(data) {
		if (this.dataTransfer.length === 0) {
			this.dataTransfer = data.filter((item) => item.isChecked).map((item) => ({ ...item }));
		} else {
			const model = data.filter((item) => !item.isChecked).map((item) => ({ ...item }));

			if (model.length > 0) {
				this.dataTransfer = [
					...this.dataTransfer.filter((transfer) => !model.some((data) => data.assetId === transfer.assetId)),
					...data.filter(
						(item) => item.isChecked && !this.dataTransfer.some((transfer) => transfer.assetId === item.assetId),
					),
				];
			} else {
				this.dataTransfer = [
					...this.dataTransfer,
					...data.filter(
						(item) => item.isChecked && !this.dataTransfer.some((transfer) => transfer.assetId === item.assetId),
					),
				];
			}
		}

		this.dataTransferChange.emit(this.dataTransfer);
		this.dataSourceChange.emit(this.dataSource);
	}

	private updateCheckAllStatus() {
		this.isCheckAll = this.dataSource.length > 0 && this.dataSource.every((item) => item.isChecked === true);
	}

	// skipcq: JS-0105
	public stopPropagation(event) {
		event.stopPropagation();
	}

	// skipcq: JS-0105
	public checkSeparated(days: number | null): boolean {
		return days != null;
	}

	public onMouseOver(i: number, assetId: number) {
		this.hoverRow = i;
		if (this.isWebView || this.isMobile) this.hoverAssetId = assetId;
	}

	public onMouseLeave() {
		this.hoverRow = null;
		this.hoverAssetId = null;
	}

	public onSortTable(txt: string) {
		switch (txt) {
			case "Name":
				this.sortOrder = this.sortOrder === "Name" ? "Name DESC" : "Name";
				break;
			case "Category":
				this.sortOrder = this.sortOrder === "Category" ? "Category DESC" : "Category";
				break;
			case "Cost":
				this.sortOrder = this.sortOrder === "Cost DESC" ? "Cost" : "Cost DESC";
				break;
			case "Owner":
				this.sortOrder = this.sortOrder === "Owner" ? "Owner DESC" : "Owner";
				break;
			case "Status":
				this.sortOrder = this.sortOrder === "Status" ? "Status DESC" : "Status";
				break;
			default:
				this.sortOrder = "";
		}
		this.sortingChange.emit(this.sortOrder);
	}

	public onChangeCheckboxAll(ev) {
		this.dataSource = this.dataSource.map((item) => ({
			...item,
			isChecked: ev.value,
		}));

		this.mapTransfer(this.dataSource);
	}

	public onChangeCheckbox(ev, i: number) {
		this.dataSource = this.dataSource.map((item, index) => (index === i ? { ...item, isChecked: ev.value } : item));
		this.mapTransfer(this.dataSource);
	}

	public openAssetSidebar(assetId: number) {
		this.openViewSidebar.emit(assetId);
	}

	public getColumnKey(name: string) {
		const col = this.columns.find((c) => c.name === name);
		return col.key;
	}

	public toggleExpand(asset) {
		if (this.expandAsset?.assetId === asset.assetId) {
			this.expandAsset = null;
		} else {
			this.expandAsset = asset;
		}
	}
}
