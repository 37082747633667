import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { BiometricScannersModel, WorkinAndBiometricSearchModel, WorkinSite } from "./work-in-site.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class WorkinSiteService {
	private httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};
	// VENIO_API_URL
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string, // @Inject("WARRANTY_API_URL") private apiUrlWarranty: string,
	) {}

	getWorkinSites(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "WorkinSite/GetWorkinSites", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getWorkinAndBiometric(model: WorkinAndBiometricSearchModel) {
		return firstValueFrom(
			this.http.get(
				`${this.apiUrl}WorkinSite/GetWorkinSites/?CompanyId=${model.companyId}&WorkinType=${model.workinType}&IsActive=${model.isActive}&IsActiveSystemStatus=${model.isActiveSystemStatus}&sortBy=${model.sortBy}`,
			),
		).then((res) => <any[]>res);
	}

	getWorkinSiteByTenantId(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "WorkinSite/GetWorkinSiteByTenantId",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getUserTimeStampWorkin(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "WorkinSite/GetTimeStampWorkin", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}
	editWorkinSite(tc: WorkinSite) {
		const body = JSON.stringify(tc);
		return this.http.post(this.apiUrl + "WorkinSite/EditWorkinSites", body, this.httpOptions);
	}
	deleteWorkinSite(tc: WorkinSite) {
		const body = JSON.stringify(tc);
		return this.http.post(this.apiUrl + "WorkinSite/DeleteWorkinSite", body, this.httpOptions);
	}
	addWorkinSite(tc: any) {
		const body = JSON.stringify(tc);
		return this.http.post(this.apiUrl + "WorkinSite/AddWorkinSites", body, this.httpOptions);
	}

	addWorkinSiteUserSubcontracts(tc: any) {
		const body = JSON.stringify(tc);
		return this.http.post(this.apiUrl + "WorkinSite/AddWorkinSiteUserSubcontracts", body, this.httpOptions);
	}

	editIsAdminSysBiometricScannerEmployee(model: any) {
		const body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WorkinSite/EditIsAdminSysBiometricScannerEmployee", body, this.httpOptions);
	}

	getBiometricScanners() {
		return this.http.post(this.apiUrl + "WorkinSite/GetBiometricScanners", null, this.httpOptions);
	}

	addBiometricScanners(model: BiometricScannersModel) {
		const body = JSON.stringify(model);
		let url = model.deviceId > 0 ? "WorkinSite/EditBiometricScanners" : "WorkinSite/AddBiometricScanners";
		return this.http.post<any>(this.apiUrl + url, body, this.httpOptions);
	}

	deleteBiometricScanners(model: BiometricScannersModel) {
		const body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WorkinSite/DeleteBiometricScanners", body, this.httpOptions);
	}
	getUserBioMaticScannerFormDeviceId(deviceId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "WorkinSite/GetUserBioMaticScannerFormDeviceId/" + deviceId),
		).then((res) => <any[]>res);
	}
	addUserToDevice(model: any[]) {
		const body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WorkinSite/AddUserToDevice", body, this.httpOptions);
	}
	addCommandToDevice(commandId: number, deviceId: number, autoAddEmployee: boolean = false) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "WorkinSite/AddCommandToDevice/" + commandId + "/" + deviceId + "/" + autoAddEmployee,
			),
		).then((res) => res);
	}
	deleteUserFromDeviceByEmployeeId(sysBioEmp: any[]) {
		const body = JSON.stringify(sysBioEmp);
		return this.http.post(this.apiUrl + "WorkinSite/DeleteUserFromDeviceByEmployeeId/", body, this.httpOptions);
	}
	addCommandAttLog(dateFrom: any, dateTo: any, deviceId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "WorkinSite/AddCommandAttLog/" + dateFrom + "/" + dateTo + "/" + deviceId),
		).then((res) => res);
	}
	getUserWorkIn(empId: number, isAdd: boolean, type?: number, order?: boolean) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "WorkinSite/GetUserWorkIn/" + empId + "/" + isAdd + "/" + type + "/" + order),
		).then((res) => <any[]>res);
	}
	unLockDevice(model: BiometricScannersModel) {
		const body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "WorkinSite/UnLockDevice", body, this.httpOptions);
	}
	getCommandLog(devId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "WorkinSite/GetCommandLog/" + devId)).then((res) => <any[]>res);
	}
	getIOMOManagementData(param: any) {
		const body = JSON.stringify(param);
		return this.http.post(this.apiUrl + "workin-site/iomo-management", body, this.httpOptions);
	}
	getIOMOManagementTimeStampData(param: any) {
		const body = JSON.stringify(param);
		return this.http.post(this.apiUrl + "workin-site/iomo-management-time-stamp", body, this.httpOptions);
	}
	getDeviceIOMOBySN(sn: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "WorkinSite/GetDeviceForIOMO/" + sn)).then((res) => res);
	}

	getSubcontractByDeviceId(deviceId) {
		return firstValueFrom(this.http.get(this.apiUrl + "WorkinSite/GetSubcontractByDeviceId/" + deviceId)).then(
			(res) => <any>res,
		);
	}

	getWarrantyStatus(customerCode: string, serialNo: string) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}biometric-scanners/status?CustomerCode=${customerCode}&SerialNumber=${serialNo}`),
		);
	}
	getWarrantyIbeaconStatus(customerCode: any) {
		var body = JSON.stringify(customerCode);
		return this.http.post(`${this.apiUrl}biometric-scanners/warranties/statuses`, body, this.httpOptions);
	}

	getIOMOWorkinSubcontractsByDeviceId(deviceId: number) {
		return this.http.get(`${this.apiUrl}iomo/subcontracts/${deviceId}/export`);
	}
}
