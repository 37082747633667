import { Inject, Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, BehaviorSubject, of, firstValueFrom } from "rxjs";
import { DataSharingService } from "../core/data-sharing.service";
import { DocumentStatus } from "./enum/document-status.enum";
import { FinancialCategory } from "./enum/financial-category.enum";
import { Package } from "./enum/package.enum";
import { ProcessAction } from "./enum/process-action.enum";
import { RouteURL } from "./enum/route-path.enum";
import { TimeAttandanceStatus } from "./enum/timeAttandance-status.enum";
import { WelfareType } from "./enum/welfare-type.enum";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { DataUrl, NgxImageCompressService } from "ngx-image-compress";
import { UserInfoService } from "../core";
import mixpanel from "mixpanel-browser";
import { environment } from "src/environments/environment";
import { switchMap, tap } from "rxjs/operators";

export function isNullOrUndefined(object: any): boolean {
	if (object instanceof Date) {
		return Date.parse(object?.toString()) == 0;
	} else if (object instanceof Object) {
		return jQuery.isEmptyObject(object) || isUndefined(object) || isNull(object);
	} else {
		return object == null || object == undefined;
	}
}

export function isUndefined(object: any): boolean {
	return object == undefined;
}

export function isNull(object: any): boolean {
	return object == null;
}
@Injectable({
	providedIn: "root",
})
export class SharingService {
	// Assuming you have a private key

	public normalDate: string = "dd/MM/yyyy";

	public fields = {
		text: "text",
		value: "organizationId",
		children: {
			name: "children",
			fields: { text: "text", value: "organizationId" },
		},
	};
	public fields_EN = {
		text: "name_EN",
		value: "organizationId",
		children: {
			name: "children",
			fields: { text: "name_EN", value: "organizationId" },
		},
	};

	private userInfoCache = new BehaviorSubject<any>(null);

	constructor(
		private data: DataSharingService,
		private router: Router,
		private translate: TranslateService,
		private gtmService: GoogleTagManagerService,
		private imageCompress: NgxImageCompressService,
		private userService: UserInfoService,
		@Inject("API_URL_DEFAULT") private apiUrl,
	) {}

	isMobile() {
		return window.innerWidth < 1025;
	}

	isSmallSizeMobile() {
		return window.innerWidth < 431;
	}

	isMediumSizeMobile() {
		return window.innerWidth < 631;
	}

	convertYear(year: number, lang: string) {
		var buddha = 543;
		if (lang == "th-TH") {
			return year + buddha;
		} else {
			return year;
		}
	}

	convertFileSizeFormat(fileSize: number) {
		let value = fileSize / Math.pow(1024, 1);

		if (value > 100000) {
			return (fileSize / Math.pow(1024, 3)).toFixed(0) + " GB";
		} else if (value > 1000) {
			return (fileSize / Math.pow(1024, 2)).toFixed(0) + " MB";
		} else {
			return (fileSize / Math.pow(1024, 1)).toFixed(0) + " KB";
		}
	}

	delayTime(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	convertfields(localize: string = "th-TH", fields: { [key: string]: Object } = { text: "name", value: "id" }) {
		if (localize?.toLowerCase() == "en-GB") {
			fields["text"] = `${fields["text"]}_EN`;
		} else {
			fields["text"] = fields["text"].toString().replace("_EN", "");
		}
		return fields;
	}

	getBadgeClassStatus(statusId: number) {
		switch (statusId) {
			case 3:
				return "flame";
			case 4:
				return "caribbean-br";
			case 6:
				return "flint";
			default:
				return "sky";
		}
	}
	getLabelDocumentStatus(statusId: number) {
		switch (statusId) {
			case DocumentStatus.draft: {
				return "tag_draft";
			}
			case DocumentStatus.canceled: {
				return "tag_cancel";
			}
			case DocumentStatus.rejected: {
				return "tag_reject";
			}
			case DocumentStatus.waitingApprove: {
				return "tag_wait_approve";
			}
			case DocumentStatus.waitingRevise: {
				return "tag_wait_revise";
			}
			case DocumentStatus.approved: {
				return "tag_approved";
			}
			case DocumentStatus.completed: {
				return "tag_completed";
			}
			case DocumentStatus.waitingAcknowledge: {
				return "tag_wait_acknowledge";
			}
			case DocumentStatus.acknowledged: {
				return "tag_acknowledge";
			}
		}
	}
	getLabelProcessActionBTN(processActionId: number) {
		switch (processActionId) {
			case ProcessAction.approvedDocument: {
				return "common_approve";
			}
			case ProcessAction.sendDocument: {
				return "common_send";
			}
			case ProcessAction.rejectedDocument: {
				return "common_reject";
			}
			case ProcessAction.returnDocument: {
				return "common_return";
			}
		}
	}
	getLabelTimeAttendanceStatus(statusId: number) {
		switch (statusId) {
			case TimeAttandanceStatus.normal:
				return "time_attandance_status_normal";
			case TimeAttandanceStatus.holiday:
				return "time_attandance_status_holiday";
			case TimeAttandanceStatus.noStampIn:
				return "time_attandance_status_no_stamp_in";
			case TimeAttandanceStatus.noStampOut:
				return "time_attandance_status_no_stamp_out";
			case TimeAttandanceStatus.absent:
				return "time_attandance_status_absent";
			case TimeAttandanceStatus.late:
				return "time_attandance_status_late";
			case TimeAttandanceStatus.early:
				return "time_attandance_status_early";
		}
	}

	getLabelWelfareType(welfareTypeId: number) {
		switch (welfareTypeId) {
			case WelfareType.general:
				return "option_welfare_type_general";

			case WelfareType.extraAllowance:
				return "option_welfare_type_extra_allowance";

			case WelfareType.payrollReimbursement:
				return "option_welfare_type_payroll_reimbursement";

			case WelfareType.additionalReimbursement:
				return "option_welfare_type_additional_reimbursement";

			case WelfareType.others:
				return "option_welfare_type_others";
		}
	}
	getLabelFinancialCategory(category: number) {
		switch (category) {
			case FinancialCategory.baseSalary:
				return "financial_category_basesalary";
			case FinancialCategory.other:
				return "financial_category_other";
			case FinancialCategory.overtime:
				return "financial_category_overtime";
			case FinancialCategory.diligence:
				return "financial_category_diligence";
			case FinancialCategory.socialFund:
				return "financial_category_socialfund";
			case FinancialCategory.tax:
				return "financial_category_tax";
			case FinancialCategory.absenceDeduct:
				return "financial_category_absencededuct";
			case FinancialCategory.lateDeduct:
				return "financial_category_latededuct";
			case FinancialCategory.providentFund:
				return "financial_category_providentfund";
			case FinancialCategory.bonus:
				return "financial_category_bonus";
			case FinancialCategory.shift:
				return "financial_category_shift";
			case FinancialCategory.foodCoupon:
				return "financial_category_foodcoupon";
			case FinancialCategory.overtimeAllowance:
				return "financial_category_overtimeallowance";
			case FinancialCategory.holidayAllowance:
				return "financial_category_holidayallowance";
			case FinancialCategory.diligenceBonus:
				return "financial_category_diligencebonus";
			case FinancialCategory.diligenceStack:
				return "financial_category_diligencestack";
			case FinancialCategory.holidayRefund:
				return "financial_category_holidayrefund";
			case FinancialCategory.uniformDeduct:
				return "financial_category_uniformdeduct";
			case FinancialCategory.ejipDeduction:
				return "financial_category_ejipductionemployee";
			case FinancialCategory.professionalFee:
				return "financial_category_professionalfee";
			case FinancialCategory.ejipAddition:
				return "financial_category_ejipaddition";
			case FinancialCategory.ejipDeductionCompany:
				return "financial_category_ejipdeductioncompany";
			case FinancialCategory.leaveWithoutPay:
				return "financial_category_leavewithoutpay";
			case FinancialCategory.suspension:
				return "financial_category_suspension";
			case FinancialCategory.fine:
				return "financial_category_fine";
			case FinancialCategory.doubleShift:
				return "financial_category_doubleshift";
			case FinancialCategory.breakTimeDeduct:
				return "financial_category_breaktimededuct";
			case FinancialCategory.workTimeAllowance:
				return "financial_category_worktimeallowance";
			case FinancialCategory.medicalExpense:
				return "financial_category_medicalexpense";
			case FinancialCategory.backPay:
				return "financial_category_backpay";
			case FinancialCategory.advancesPayment:
				return "financial_category_advancespayment";
			case FinancialCategory.EPP:
				return "financial_category_epp";
			case FinancialCategory.oTPublicHoliday:
				return "financial_category_otpublicholiday";
			case FinancialCategory.taxIncomeType6:
				return "financial_category_taxincometype6";
			case FinancialCategory.taxIncomeType3:
				return "financial_category_taxincometype3";
			case FinancialCategory.taxIncomeType4:
				return "financial_category_taxincometype4";
			case FinancialCategory.taxIncomeType5:
				return "financial_category_taxincometype5";
			case FinancialCategory.studentLoanFund:
				return "financial_category_studentloanfund";
			case FinancialCategory.legalExecutionDepartment:
				return "financial_category_legalexecutiondepartment";
			case FinancialCategory.expenseDeduct:
				return "financial_category_expensededuct";
			case FinancialCategory.taxIncomeType6Freelance:
				return "financial_category_taxincometype6freelance";
			case FinancialCategory.leaveBenefitCompensation:
				return "financial_category_leavebenefitcompensation";
			case FinancialCategory.leaveBenefitCompensationDeduct:
				return "financial_category_leavebenefitcompensationdeduct";
			case FinancialCategory.overtimeBackPay:
				return "financial_category_overtimeBackPay";
			case FinancialCategory.attendanceBackPay:
				return "financial_category_attendanceBackPay";
			case FinancialCategory.providentFundCompanyFixed:
				return "financial_category_providentfundcompany";
			case FinancialCategory.severancePay:
				return "severance_pay";
			default:
				return "";
		}
	}

	getLabelPackage(statusId: number) {
		if (statusId == Package.ProPlus) return "pro";
	}
	upgradePlan() {
		this.data.setEventSubscription("planModal");
		this.router.navigateByUrl(`/${RouteURL.billing}`);
	}
	getConvertBadgePayrollByPayslip(statusId: number) {
		switch (statusId) {
			case 4:
				return 3;
			case 3:
				return 4;
			case 2:
				return 1;
			default:
				return 5;
		}
	}

	genarateMonth(monthCount) {
		if (monthCount <= 0) return null;
		function getPlural(number, word) {
			return (number === 1 && word.one) || word.other;
		}
		var months = { one: this.translate.instant("common_month"), other: this.translate.instant("timer_months") },
			years = { one: this.translate.instant("common_year"), other: this.translate.instant("timer_years") },
			m = monthCount % 12,
			y = Math.floor(monthCount / 12),
			result = [];

		y && result.push(y + " " + getPlural(y, years));
		m && result.push(m + " " + getPlural(m, months));
		return result.join(" ");
	}

	createObserver(): Observable<any> {
		return new Observable<any>((observer) => {
			setTimeout(() => {
				observer.next();
				observer.complete();
				return { unsubscribe() {} };
			}, 1000);
		});
	}

	public monthName_TH = [
		"มกราคม",
		"กุมภาพันธ์",
		"มีนาคม",
		"เมษายน",
		"พฤษภาคม",
		"มิถุนายน",
		"กรกฎาคม",
		"สิงหาคม",
		"กันยายน",
		"ตุลาคม",
		"พฤศจิกายน",
		"ธันวาคม",
	];
	public monthName_EN = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	public modelMonthName_TH = [
		{
			text: "มกราคม",
			value: 1,
		},
		{
			text: "กุมภาพันธ์",
			value: 2,
		},
		{
			text: "มีนาคม",
			value: 3,
		},
		{
			text: "เมษายน",
			value: 4,
		},
		{
			text: "พฤษภาคม",
			value: 5,
		},
		{
			text: "มิถุนายน",
			value: 6,
		},
		{
			text: "กรกฎาคม",
			value: 7,
		},
		{
			text: "สิงหาคม",
			value: 8,
		},
		{
			text: "กันยายน",
			value: 9,
		},
		{
			text: "ตุลาคม",
			value: 10,
		},
		{
			text: "พฤศจิกายน",
			value: 11,
		},
		{
			text: "ธันวาคม",
			value: 12,
		},
	];
	public modelMonthName_EN = [
		{
			text: "January",
			value: 1,
		},
		{
			text: "February",
			value: 2,
		},
		{
			text: "March",
			value: 3,
		},
		{
			text: "April",
			value: 4,
		},
		{
			text: "May",
			value: 5,
		},
		{
			text: "June",
			value: 6,
		},
		{
			text: "July",
			value: 7,
		},
		{
			text: "August",
			value: 8,
		},
		{
			text: "September",
			value: 9,
		},
		{
			text: "October",
			value: 10,
		},
		{
			text: "November",
			value: 11,
		},
		{
			text: "December",
			value: 12,
		},
	];

	public dayName_TH = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];
	public dayName_EN = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

	changeFormatString(inputCalendar, language) {
		if (inputCalendar == null) return;
		if (!isNullOrUndefined(inputCalendar?.value)) {
			inputCalendar.locale.monthNames = language === "th-TH" ? this.monthName_TH : this.monthName_EN;
			inputCalendar.weekDays = language === "th-TH" ? this.dayName_TH : this.dayName_EN;
			let month1 =
				language === "th-TH"
					? this.monthName_TH[inputCalendar?.value?.getMonth()]
					: this.monthName_EN[inputCalendar?.value?.getMonth()];
			var date1 = inputCalendar.value.getDate().toString();
			var year1 = inputCalendar.value.getFullYear().toString();
			month1 = month1?.toString();
			if (language === "th-TH") {
				year1 = Number(year1) + 543;
			}
			inputCalendar.inputFieldValue = `${date1} ${month1} ${year1}`;
		}
	}
	setValueForm(form: UntypedFormGroup, data: any) {
		Object.keys(form.value).forEach((name) => {
			if (!isNullOrUndefined(data[name])) {
				form.controls[name].setValue(data[name]);
			}
		});
	}

	changeFormatRangeString(inputCalendar, language) {
		if (inputCalendar == null) return;
		let format = "";

		if (isNullOrUndefined(inputCalendar.value[1])) {
			inputCalendar.value[1] = inputCalendar.value[0];
		}
		if (!isNullOrUndefined(inputCalendar.value[0])) {
			inputCalendar.locale.monthNames = language === "th-TH" ? this.monthName_TH : this.monthName_EN;
			let month1 =
				language === "th-TH"
					? this.monthName_TH[inputCalendar.value[0].getMonth()]
					: this.monthName_EN[inputCalendar.value[0].getMonth()];
			month1 = month1?.toString();
			var date1 = inputCalendar.value[0].getDate().toString();
			var year1 = inputCalendar.value[0].getFullYear().toString();
			if (language === "th-TH") {
				year1 = Number(year1) + 543;
			}
			if (!isNullOrUndefined(inputCalendar.value[1])) {
				let month2 =
					language === "th-TH"
						? this.monthName_TH[inputCalendar.value[1].getMonth()]
						: this.monthName_EN[inputCalendar.value[1].getMonth()];
				month2 = month2?.toString();
				var date2 = inputCalendar.value[1].getDate().toString();
				var year2 = inputCalendar.value[1].getFullYear().toString();
				if (language === "th-TH") {
					year2 = Number(year2) + 543;
				}
				if (year1 == year2) {
					if (month1 != month2) {
						format = `${date1} ${month1} - ${date2} ${month2} ${year2}`;
					} else {
						if (date1 != date2) {
							format = `${date1} - ${date2} ${month2} ${year2}`;
						} else {
							format = `${date2} ${month2} ${year2}`;
						}
					}
				} else {
					format = `${date1} ${month1} ${year1} - ${date2} ${month2} ${year2}`;
				}
			} else {
				format = `${date1} ${month1} ${year1}`;
			}
			inputCalendar.inputFieldValue = format;
		}
	}

	public event(url: string) {
		const option: EventSourceInit = {
			withCredentials: true,
		};

		return new EventSource(this.apiUrl + url, option);
	}
	mapModeltoForm(model, form) {
		Object.keys(model).forEach((field) => {
			if (form.get(field)) {
				form.get(field).setValue(model[field]);
			}
		});
	}

	hexToRgba(hex: string, opacity: number): string {
		const r = parseInt(hex.slice(1, 3), 16);
		const g = parseInt(hex.slice(3, 5), 16);
		const b = parseInt(hex.slice(5, 7), 16);
		return `rgba(${r}, ${g}, ${b}, ${opacity})`;
	}

	getQueryString(search: any) {
		let queryString = Object.entries(search)
			.filter(([key, value]) => value)
			.map(([key, value]) => `${key}=${encodeURIComponent(value.toString())}`)
			.join("&");

		if (queryString) {
			queryString = `?${queryString}`;
		}
		return queryString;
	}

	pushGoogleTagManager = async (eventName: string) => {
		mixpanel.init(environment.mixpanelToken);
		const userInfo = await firstValueFrom(this.getUserInfo());
		const eventData = {
			event: eventName,
			$name: userInfo.fullName_EN,
			$email: userInfo.emailAddress,
			fullName_EN: userInfo.fullName_EN,
			dateOfBirth: userInfo.dateOfBirth,
			employmentDate: userInfo.employmentDate,
			position: userInfo.positionName,
			rank: userInfo.rankName,
			branch: userInfo.branchName_EN,
			employmentType: userInfo.employmentTypeName,
			department: userInfo.departmentName_EN,
			latestLogin: userInfo.dateLastAccess,
			phoneNumber: userInfo.mobileNo,
			companyName_EN: userInfo.companyName_EN,
			//companyType: userInfo.companyType,
			packageName: userInfo.packageName,
			subscriptionStatus: userInfo.subscriptionStatus,
			effectiveDate: userInfo.dateEffective,
			expireDate: userInfo.dateExpire,
			product: userInfo.product.split(","),
			limitUser: userInfo.limitUser,
			additionalSeat: userInfo.additionalSeat,
			billingType: userInfo.PaymentType,
			//isTest: userInfo.isTest,
		};
		this.gtmService.pushTag(eventData);
		mixpanel.track(eventName, eventData);
		mixpanel.identify(userInfo.userId);
		mixpanel.people.set(eventData);
	};

	loadUserInfo(): Observable<any> {
		return this.userService.getUserInfoMixpanel();
	}

	private getUserInfo(): Observable<any> {
		return this.userInfoCache.pipe(
			switchMap((cachedInfo) => {
				if (cachedInfo) {
					return of(cachedInfo);
				} else {
					return this.loadUserInfo().pipe(tap((userInfo) => this.userInfoCache.next(userInfo)));
				}
			}),
		);
	}

	async compressImage(data: any) {
		return new Promise((resolve, reject) => {
			let image = new Image();
			image.src = data;
			image.onload = () => {
				let width = image.width;
				let height = image.height;
				let newWidth;
				let newHeight;
				let ratio = 80;
				if (width > height && width > 854) {
					newHeight = height * (854 / width);
					newWidth = 854;
				} else if (height > width && height > 854) {
					newWidth = width * (854 / height);
					newHeight = 854;
				} else {
					newHeight = height;
					newWidth = width;
					ratio = 100;
				}
				this.imageCompress.compressFile(image.src, -1, ratio, 70, newWidth, newHeight).then((result: DataUrl) => {
					resolve(result);
				});
			};
		});
	}

	calculateTotalAmount(oldAmount = 0, newAmount = 0) {
		let totalAmount = {
			percent: null,
			baht: newAmount,
			percentStr: null,
			bahtStr: null,
		};

		if (!oldAmount || oldAmount == 0) {
			if (!newAmount || newAmount == 0) totalAmount.percent = 0;
			else totalAmount.percent = 100;
		} else {
			totalAmount.percent = ((newAmount - oldAmount) / oldAmount) * 100;
		}

		totalAmount.bahtStr =
			"฿" +
			newAmount.toLocaleString("en-US", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

		totalAmount.percentStr = (totalAmount.percent > 0 ? "+" : "") + totalAmount.percent.toFixed(2) + "%";
		return totalAmount;
	}

	getFirstDateOfWeek(date: Date) {
		let dayOfWeek = date.getDay();
		return date.getDate() - (dayOfWeek == 0 ? 6 : dayOfWeek - 1);
	}
}
