import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { DropdownSelectMode, DropdownModule } from "@gofive/design-system-dropdown";
import { TextFieldType, InputModule } from "@gofive/design-system-input";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DataSharingService } from "src/app/core/data-sharing.service";
import { FinancialItem, MasAccountGroup } from "src/app/payroll/master/financial-item/shared/financial-item.model";
import { FinancialItemService } from "src/app/payroll/master/financial-item/shared/financial-item.service";
import { FinancialCategory } from "../../enum/financial-category.enum";
import { IncomeType } from "../../enum/income-types";
import { ApiResult } from "../../models/api-result.model";
import { Dropdown } from "../../models/dropdown.model";
import { Permission } from "../../models/permission.model";
import { CommonService } from "../../service/common.service";
import { isNullOrUndefined, SharingService } from "../../sharing.service";
import { InfoBarType, BaseModule } from "@gofive/design-system-base";
import { firstValueFrom, Subscription } from "rxjs";
import { PaymentMethodService } from "src/app/master/payment-method/shared/payment-method.service";
import { Router } from "@angular/router";
import { RouteURL } from "../../enum/route-path.enum";
import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import { ButtonModule } from "@gofive/design-system-button";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { InfoTooltipComponent } from "../../template/info-tooltip/info-tooltip.component";
import { SidebarModule } from "@gofive/design-system-navigation";

export enum IsTaxTypeDDL {
	none = 1,
	allYear = 2,
	oneTime = 3,
}

@Component({
	selector: "financial-sidebar",
	templateUrl: "./financial-sidebar.component.html",
	styleUrls: ["./financial-sidebar.component.scss"],
	standalone: true,
	imports: [
		SidebarModule,
		FormsModule,
		ReactiveFormsModule,
		BaseModule,
		InputModule,
		DropdownModule,
		InfoTooltipComponent,
		TooltipModule,
		ButtonModule,
		AsyncPipe,
		TranslateModule,
		NgTemplateOutlet,
	],
})
export class FinancialSidebarComponent implements OnInit {
	@Output() isClose = new EventEmitter();
	@Output() submit = new EventEmitter<any>();
	@Input() companyId: number = 0;
	@Input() indexTabView: number = 0;
	@Input() hasWelfare: boolean = false;
	@Input() financialName: string = "";
	@Input() financialItemId: number = 0;
	@Input() isShowHeader: boolean = true;
	@Input() permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	public isDialog: boolean = true;
	public form: UntypedFormGroup;
	public fieldsDropdown: any = {
		text: "name",
		value: "id",
	};
	public fieldsDropdown_EN: any = {
		text: "name_EN",
		value: "id",
	};

	public fieldsRoundingDropdown: any = {
		text: "name",
		value: "value",
	};
	public fieldsRoundingDropdown_EN: any = {
		text: "name_EN",
		value: "value",
	};

	public fields: any = {
		text: "text",
		value: "value",
	};

	get TextFieldType() {
		return TextFieldType;
	}

	get DropdownSelectMode() {
		return DropdownSelectMode;
	}

	get infoBarType() {
		return InfoBarType;
	}

	get TaxType() {
		return IsTaxTypeDDL;
	}

	get isShowTaxInfo() {
		return (
			this.form.controls["name"].value?.includes("ภาษี") ||
			this.form.controls["name"].value?.toLowerCase().includes("tax") ||
			this.form.controls["name_EN"].value?.includes("ภาษี") ||
			this.form.controls["name_EN"].value?.toLowerCase().includes("tax")
		);
	}

	public financialItem: FinancialItem = new FinancialItem();
	public isCurrentPeriod: boolean = false;
	public showCurrentPeriod: boolean = false;
	public accountGroups2: MasAccountGroup[] = [];
	public isStatusAdd: boolean = true;
	public showPayPeriod: boolean = false;
	public incomeTypeAdditionDDL: Dropdown[] = [];
	public incomeTypeDeductionDDL: Dropdown[] = [];

	public incomeTypeAdditionNewDDL: Dropdown[] = [];
	public incomeTypeDeductionNewDDL: Dropdown[] = [];
	public withholdingTax: number;
	public rentType: boolean = false;
	public roundingConfigDDL: Dropdown[];
	public roundingCode: number = null;
	public accountCodeTypeDDL: Dropdown[];
	public accountCodeType: any = null;
	public confirmation: any;
	public addConfirmation: any;
	public editConfirmation: any;
	public debit: number = 1;
	public credit: number = 2;
	public textHeader: string = "";
	public categoryOvertimeBackPay: number = 46;
	public categoryAttendanceBackPay: number = 47;

	public periodTypeDDL: any[];
	public isTaxDDL: any[];
	isTaxType: number = IsTaxTypeDDL.oneTime;
	public isShowWarning: boolean = false;
	language$: Subscription;
	public paymentMethodIncomeTypes: any;
	public amountEmploymentType: number = 0;
	public paymentMethodId: number;
	public isCategoryTaxNotCal: boolean = false;
	public isLoading: boolean = true;

	constructor(
		private fb: UntypedFormBuilder,
		public translate: TranslateService,
		public data: DataSharingService,
		public sharingService: SharingService,
		private financialItemService: FinancialItemService,
		private _toastHelperService: ToastHelperService,
		private commonService: CommonService,
		private _dialogService: DialogService,
		private paymentMethodService: PaymentMethodService,
		private router: Router,
	) {}
	ngOnInit() {
		this.form = this.fb.group({
			code: new UntypedFormControl("", Validators.required),
			name: new UntypedFormControl("", Validators.required),
			name_EN: new UntypedFormControl("", Validators.required),
			isRegular: new UntypedFormControl(""),
			isOncePay: new UntypedFormControl(""),
			isBaseSalary: new UntypedFormControl(""),
			isTax: new UntypedFormControl(""),
			isOnceWT: new UntypedFormControl(""),
			isSOC: new UntypedFormControl(""),
			isPF: new UntypedFormControl(""),
			isOT: new UntypedFormControl(""),
			isAT: new UntypedFormControl(""),
			isCurrentPeriod: new UntypedFormControl(""),
			periodType: new UntypedFormControl("", Validators.required),
			isActive: new UntypedFormControl(""),
			roundingCode: new UntypedFormControl("", Validators.required),
			incomeTypes: new UntypedFormControl("", Validators.required),
			isExtendPeriod: new UntypedFormControl(""),
			codeManufactory: new UntypedFormControl(""),
			codeOffice: new UntypedFormControl(""),
			codeSupport: new UntypedFormControl(""),
			withholdingTaxPercentage: new UntypedFormControl(),
			isTaxType: new UntypedFormControl(""),
		});

		this.commonService.getDDLIncomeTypes().then((res) => {
			this.incomeTypeAdditionDDL = res ?? [];
			this.incomeTypeAdditionNewDDL = this.incomeTypeAdditionDDL.filter((f) => f.id != IncomeType.termination);
			this.incomeTypeDeductionDDL =
				res?.filter(
					(f) =>
						f.id === IncomeType.salary ||
						f.id === IncomeType.wage ||
						f.id === IncomeType.termination ||
						f.id === IncomeType.taxIncome3per,
				) ?? [];

			this.incomeTypeDeductionNewDDL = this.incomeTypeDeductionDDL.filter((f) => f.id != IncomeType.termination);
		});
		this.commonService.getDDLAccountCodeType().then((res) => {
			this.accountCodeTypeDDL = res;
		});
		this.commonService.getDDLRoundingConfig().then((res) => {
			this.roundingConfigDDL = res;
		});
		if (this.financialItemId > 0) {
			this.isStatusAdd = false;
			this.edit(this.financialItemId);
		} else {
			this.loadForm();
			this.financialItemService.getFinancialItemCode(this.companyId, this.indexTabView == 1).then((res) => {
				this.financialItem.code = res.toString();
				this.financialItem.name = this.financialName;
				this.financialItem.name_EN = this.financialName;
				this.financialItem.isTax = true;
				this.financialItem.isOnceWT = true;
			});
			this.isStatusAdd = true;
			this.isLoading = false;
		}
		let textHeaderIndex1 = this.isStatusAdd
			? this.translate.instant("mas010_addition_add")
			: this.translate.instant("mas010_addition_edit");
		let textHeaderIndex2 = this.isStatusAdd
			? this.translate.instant("mas010_deduction_add")
			: this.translate.instant("mas010_deduction_edit");

		this.language$ = this.data.currentLanguage.subscribe((res) => {
			this.textHeader = this.indexTabView == 1 ? textHeaderIndex1 : textHeaderIndex2;
			this.periodTypeDDL = [
				{
					text: this.translate.instant("mas010_period"),
					value: 1,
				},
				{
					text: this.translate.instant("mas010_month"),
					value: 2,
				},
			];
			this.isTaxDDL = [
				{
					text: this.translate.instant("mas010_tax_ddl_none"),
					value: IsTaxTypeDDL.none,
				},
				{
					text: this.translate.instant("mas010_tax_ddl_all_year"),
					value: IsTaxTypeDDL.allYear,
				},
				{
					text: this.translate.instant("mas010_tax_ddl_one_time"),
					value: IsTaxTypeDDL.oneTime,
				},
			];
		});
	}

	ngOnDestroy() {
		this.language$ && this.language$.unsubscribe();
	}

	loadForm() {
		this.financialItem = new FinancialItem();
		this.financialItem.financialItemId = 0;
		this.financialItem.category = 0;
		this.financialItemId = null;
		this.form.reset();
		this.financialItem.isBaseSalary = false;
		this.financialItem.isTax = true;
		this.financialItem.isOnceWT = false;
		this.financialItem.isSOC = true;
		this.financialItem.isPF = true;
		this.financialItem.isOT = true;
		this.financialItem.isAT = true;
		this.financialItem.isActive = true;
		this.financialItem.periodType = 1;
		this.form.controls["periodType"].setValue(1);
		this.financialItem.isCurrentPeriod = false;
		this.isCurrentPeriod = false;
		this.financialItem.isRegular = true;
		this.financialItem.isOncePay = true;
		this.financialItem.isExtendPeriod = false;
		this.isTaxType = IsTaxTypeDDL.oneTime;
		this.showCurrentPeriod = false;
		this.isStatusAdd = true;
		this.financialItem.incomeType = IncomeType.salary;
		this.form.controls["isTaxType"].setValue(this.isTaxType);
		this.form.controls["isExtendPeriod"].setValue(this.financialItem.isExtendPeriod);
		this.form.controls["isCurrentPeriod"].setValue(this.financialItem.isCurrentPeriod);
		this.form.controls["isSOC"].setValue(this.financialItem.isSOC);
		this.form.controls["isPF"].setValue(this.financialItem.isPF);
		this.form.controls["isOT"].setValue(this.financialItem.isOT);
		this.form.controls["isAT"].setValue(this.financialItem.isAT);
		this.form.controls["incomeTypes"].setValue(this.financialItem.incomeType);
	}

	get financialCategory() {
		return FinancialCategory;
	}

	get incomeType() {
		return IncomeType;
	}

	edit(financialItemId: number) {
		this.isStatusAdd = false;
		this.isLoading = true;
		this.financialItemService.getFinancialItemById(financialItemId).then((res) => {
			this.financialItem = res;
			this.accountGroups2 = this.financialItem.accountGroups;
			this.financialItemId = this.financialItem.financialItemId;
			this.showCurrentPeriod =
				[
					FinancialCategory.overtime,
					FinancialCategory.diligence,
					FinancialCategory.absenceDeduct,
					FinancialCategory.lateDeduct,
					FinancialCategory.shift,
					FinancialCategory.foodCoupon,
					FinancialCategory.overtimeAllowance,
					FinancialCategory.holidayAllowance,
					FinancialCategory.leaveWithoutPay,
					FinancialCategory.doubleShift,
					FinancialCategory.suspension,
					FinancialCategory.breakTimeDeduct,
					FinancialCategory.workTimeAllowance,
					FinancialCategory.diligenceBonus,
					FinancialCategory.diligenceStack,
				].includes(this.financialItem.category) || this.hasWelfare;
			this.isCategoryTaxNotCal = [
				FinancialCategory.socialFund,
				FinancialCategory.tax,
				FinancialCategory.providentFund,
				FinancialCategory.taxIncomeType6,
				FinancialCategory.taxIncomeType3,
				FinancialCategory.taxIncomeType4,
				FinancialCategory.taxIncomeType5,
				FinancialCategory.taxIncomeType6Freelance,
				FinancialCategory.severancePay,
			].includes(this.financialItem.category);

			this.showPayPeriod = this.showCurrentPeriod;
			this.withholdingTax = this.financialItem.withholdingTaxPercentage;

			this.textHeader = `${this.textHeader} (${this.translate.instant(
				this.sharingService.getLabelFinancialCategory(this.financialItem.category),
			)})`;
			this.isTaxType = this.getCalTax(this.financialItem.isTax, this.financialItem.isOnceWT);
			this.form.controls["isTaxType"].setValue(this.isTaxType);
			this.form.controls["isBaseSalary"].setValue(this.financialItem.isBaseSalary);
			this.form.controls["incomeTypes"].setValue(this.financialItem.incomeType ?? IncomeType.salary);
			this.form.controls["roundingCode"].setValue(this.financialItem.roundingCode);
			this.form.controls["periodType"].setValue(this.financialItem.periodType);
			this.form.controls["isExtendPeriod"].setValue(this.financialItem.isExtendPeriod);
			this.form.controls["isCurrentPeriod"].setValue(!this.financialItem.isCurrentPeriod);
			this.isCurrentPeriod = !this.financialItem.isCurrentPeriod;
			this.form.controls["isOnceWT"].setValue(this.financialItem.isOnceWT);
			this.form.controls["isOncePay"].setValue(this.financialItem.isOncePay);
			this.form.controls["isSOC"].setValue(this.financialItem.isSOC);
			this.form.controls["isPF"].setValue(this.financialItem.isPF);
			this.form.controls["isOT"].setValue(this.financialItem.isOT);
			this.form.controls["isAT"].setValue(this.financialItem.isAT);
			this.commonService.getDDLRoundingConfig().then((result) => {
				this.roundingConfigDDL = result;
			});

			this.commonService.getDDLAccountCodeType().then((result) => {
				this.accountCodeTypeDDL = result;
				this.accountCodeType = !isNullOrUndefined(this.financialItem.accountCodeType)
					? this.accountCodeTypeDDL.find((e) => e.id == this.financialItem.accountCodeType)
					: null;
			});
			this.changeIncomeType();
			this.getPaymentMethodIncomeTypesById(this.financialItemId, this.financialItem.incomeType);

			this.form.controls["incomeTypes"].setValidators(Validators.required);
			if (!this.financialItem.isTax && this.financialItem.isOnceWT) {
				this.form.controls["incomeTypes"].clearValidators();
			}

			this.form.controls["incomeTypes"].updateValueAndValidity();

			this.isLoading = false;
		});
	}
	getCalTax(isTax, isOnceWT) {
		if (!isTax && !isOnceWT) {
			return IsTaxTypeDDL.none;
		}
		if (isTax && !isOnceWT) {
			return IsTaxTypeDDL.allYear;
		}
		if (isTax && isOnceWT) {
			return IsTaxTypeDDL.oneTime;
		}
	}
	changeIncomeType() {
		if (!isNullOrUndefined(this.financialItem.incomeType)) {
			this.rentType =
				this.financialItem.incomeType == IncomeType.rent ||
				this.financialItem.incomeType == IncomeType.recipientResidesInCountry ||
				this.financialItem.incomeType == IncomeType.independentProfessionalFees
					? true
					: false;
		} else {
			this.rentType = false;
		}
		if (this.rentType && this.indexTabView == 1) {
			this.form.controls["withholdingTaxPercentage"].setValidators(Validators.required);
		} else {
			this.form.controls["withholdingTaxPercentage"].clearValidators();
		}
		this.form.controls["withholdingTaxPercentage"].updateValueAndValidity();
	}
	loadConfirm() {
		this.confirmation = this.translate.instant("common_confirmation");
		this.addConfirmation = this.translate.instant("common_add_confirmation");
		this.editConfirmation = this.translate.instant("common_edit_confirmation");
	}
	onSubmit() {
		this.sharingService.pushGoogleTagManager("setting_payroll_incomeanddeduction_save");
		this.loadConfirm();
		this.financialItem.accountCodeType = !isNullOrUndefined(this.accountCodeType) ? this.accountCodeType.id : 0;
		this.financialItem.companyId = !isNullOrUndefined(this.companyId) ? this.companyId : 0;
		this.financialItem.withholdingTaxPercentage = this.rentType ? this.withholdingTax : 0;
		this.financialItem.accountType = this.indexTabView == 1 ? this.debit : this.credit;
		this.financialItem.isOnceWT = this.financialItem.isTax ? this.financialItem.isOnceWT : false;
		this.financialItem.isCurrentPeriod = !this.isCurrentPeriod;
		if (isNullOrUndefined(this.financialItemId)) {
			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.addConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.financialItem.accountGroups = this.accountGroups2;
					this.financialItem.type = this.indexTabView;
					firstValueFrom(this.financialItemService.addFinancialItem(this.financialItem)).then((res) => {
						var apiResult = <ApiResult>res;
						if (apiResult.result) {
							this.isDialog = false;
							this.isStatusAdd = false;
							this.submit.emit(apiResult.data);
							this.onClose();
							this._toastHelperService.success({
								title: this.translate.instant("toast_title_success"),
								description: this.translate.instant("toast_common_save_success"),
							});
						} else {
							this._toastHelperService.validation({
								title: this.translate.instant("common_fail"),
								description: apiResult.message,
							});
						}
					});
				}
			});
		} else {
			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.editConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					firstValueFrom(this.financialItemService.editFinancialItem(this.financialItem)).then((res) => {
						var apiResult = <ApiResult>res;
						if (apiResult.result) {
							this.submit.emit(this.financialItemId);
							this.loadForm();
							this.isDialog = false;
							this.isStatusAdd = false;
							this.onClose();
							this._toastHelperService.success({
								title: this.translate.instant("toast_title_success"),
								description: this.translate.instant("toast_common_save_success"),
							});
						} else {
							this._toastHelperService.validation({
								title: this.translate.instant("common_fail"),
								description: apiResult.message,
							});
						}
					});
				}
			});
		}
	}

	closeSidebar(e?) {
		if (this.form.dirty) {
			if (e) e.cancel = true;
			this.disCardChange();
		}
	}

	onCancel() {
		if (this.form.dirty) {
			this.disCardChange();
		} else {
			this.onClose();
		}
	}

	onClose() {
		this.isDialog = false;
		this.isClose.emit();
	}

	disCardChange() {
		const dialogModel = <DialogInformationModel>{
			title: this.isStatusAdd
				? this.translate.instant("cancel-add-dialog")
				: this.translate.instant("common_discard_changes_confirmation"),
			description: this.translate.instant("common_edit_discard_changes_confirmation"),
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm_discard"),
			textButtonCancel: this.translate.instant("common_keep_editing"),
		};

		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				this.onClose();
			}
		});
	}

	onSelectedIncomeType(event) {
		this.financialItem.incomeType = event.value;
		this.changeIncomeType();
		this.getPaymentMethodIncomeTypesById(this.financialItemId, this.financialItem.incomeType);
	}
	onSelectedRoundingCode(event) {
		this.financialItem.roundingCode = event.value;
		this.changeIncomeType();
	}
	onSelectedPeriodType(event) {
		this.financialItem.periodType = event.value;
	}

	onSelectedisTaxDDL(event) {
		this.isShowWarning = event.value === this.TaxType.none;
		this.isTaxType = event.value;
		this.form.controls["incomeTypes"].setValidators(Validators.required);
		switch (this.isTaxType) {
			case IsTaxTypeDDL.none:
				{
					this.financialItem.isTax = false;
					this.financialItem.isOnceWT = false;
					this.form.controls["incomeTypes"].clearValidators();
				}
				break;
			case IsTaxTypeDDL.allYear:
				{
					this.financialItem.isTax = true;
					this.financialItem.isOnceWT = false;
				}
				break;
			case IsTaxTypeDDL.oneTime:
				{
					this.financialItem.isTax = true;
					this.financialItem.isOnceWT = true;
				}
				break;
		}
		this.form.controls["incomeTypes"].updateValueAndValidity();
	}

	getPaymentMethodIncomeTypesById(financialItemId: number, exceptIncomeType: number) {
		this.amountEmploymentType = 0;
		if (financialItemId > 0) {
			var searchModel = { financialItemId: financialItemId, hasIncomeType: true };
			firstValueFrom(this.paymentMethodService.getPaymentMethodIncomeTypesById(searchModel)).then((res) => {
				this.paymentMethodIncomeTypes = res?.data?.filter((f) => f.incomeType != exceptIncomeType);
				this.amountEmploymentType = this.paymentMethodIncomeTypes?.length ?? 0;
				this.paymentMethodId = this.paymentMethodIncomeTypes?.sort(
					(a, b) => a.rowNumber - b.rowNumber,
				)[0].paymentMethodId;
			});
		}
	}

	goToPaymentMethod() {
		this.router.navigate([`/${RouteURL.tenantSetup}/${RouteURL.employmentType}`], {
			queryParams: { paymentMethodId: this.paymentMethodId },
		});
	}

	onClickOpenManual() {
		window.open(
			"https://help.empeo.com/th/article/4lij4liy4lii4lme4liu4lmjioc4oc4suc4ouc4gec4suc4oc4qc4sec4gq-2xvviu/",
		);
	}
}
