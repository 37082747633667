import { Pipe, PipeTransform } from "@angular/core";
import { DocumentStatus } from "../enum/document-status.enum";
import { ProcessAction } from "../enum/process-action.enum";

@Pipe({
	name: "colorStatus",
	standalone: true,
})
export class ColorStatusPipe implements PipeTransform {
	transform(value: any) {
		switch (value) {
			case DocumentStatus.draft:
				return "#838395";
			case DocumentStatus.waitingApprove:
				return "#4C97FF";
			case DocumentStatus.waitingRevise:
				return "var(--go5-color-primary)";
			case ProcessAction.draftDocument:
			case ProcessAction.sendDocument:
				return "#99afc4";
			case DocumentStatus.approved:
				return "#00C291";
			case ProcessAction.approvedDocument:
				return "#00C291";
			case DocumentStatus.canceled:
				return "#838395";
			case DocumentStatus.rejected:
				return "#FFFFFF";
			case ProcessAction.canceledDocument:
			case ProcessAction.rejectedDocument:
			case ProcessAction.returnDocument:
				return "#c7c8ca";
			case DocumentStatus.completed:
				return "#00C291";
			case DocumentStatus.waitingAcknowledge:
				return "#5e5eed";
			case DocumentStatus.acknowledged:
				return "#00C291";
			default:
				return "white";
		}
	}
}
