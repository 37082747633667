import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DocumentFormType } from "./document-form-type.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class CertificateSettingService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getDocumentFormTypeByCompany(companyId: number) {
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "CertificateSetting/GetDocumentFormTypes?companyId=" + companyId),
		);
	}
	getDocumentFormTypeTavi(companyId: number) {
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "CertificateSetting/GetDocumentFormTypeTavi?companyId=" + companyId),
		);
	}

	getDocumentFormTypeById(documentTypeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "CertificateSetting/GetDocumentFormTypeById/" + documentTypeId),
		).then((res) => <any>res);
	}

	editDocumentFormType(tc: DocumentFormType) {
		var body = JSON.stringify(tc);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "CertificateSetting/EditDocumentFormType", body, httpOptions);
	}

	getDocumentFormTypes() {
		return this.http.get<any>(this.apiUrl + "CertificateSetting/GetDocumentFormTypes");
	}

	getDocumentFormTypeTags(categoryId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + `certificate/document-form/${categoryId}`));
	}
}
