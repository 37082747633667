import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FinancialItem, MasAccountGroup } from "./financial-item.model";
import { SharingService } from "src/app/shared/sharing.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class FinancialItemService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private sharing: SharingService,
	) {}

	getAllFinancialItemByCompanyId(companyId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "FinancialItem/GetAllFinancialItemByCompanyId/" + companyId),
		).then((res) => <FinancialItem[]>res);
	}

	getFinancialItemByIncomeType(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "financial-item/incometype", body, httpOptions)).then(
			(res) => <any>res,
		);
	}
	getFinancialItemExtend(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "financial-item/extend", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	getMasAccountGroupsByTenantId() {
		return firstValueFrom(this.http.get(this.apiUrl + "FinancialItem/GetMasAccountGroupsByTenantId/")).then(
			(res) => <MasAccountGroup[]>res,
		);
	}

	getAllFinancialItemByCompanyIdAndType(companyId: number, type: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "FinancialItem/GetAllFinancialItemByCompanyIdAndType/" + companyId + "/" + type),
		).then((res) => <FinancialItem[]>res);
	}
	GetAllFinancialItemByCompanyIdAndTypeAndCategory(companyId: number, type: number, category: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"FinancialItem/GetAllFinancialItemByCompanyIdAndTypeAndCategory/" +
					companyId +
					"/" +
					type +
					"/" +
					category,
			),
		).then((res) => <FinancialItem>res);
	}

	getFinancialItemById(financialItemId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "FinancialItem/GetFinancialItemById/" + financialItemId)).then(
			(res) => <FinancialItem>res,
		);
	}
	getFinancialItemCode(companyId: number, isIncome: boolean) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "FinancialItem/GetFinancialItemCode/" + companyId + "/" + isIncome),
		);
	}
	addFinancialItem(fi) {
		var body = JSON.stringify(fi);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "FinancialItem/AddFinancialItem", body, httpOptions);
	}

	addMasAccountGroup(model: MasAccountGroup) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "FinancialItem/AddMasAccountGroup", body, httpOptions);
	}

	editMasAccountGroup(model: MasAccountGroup) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "FinancialItem/EditMasAccountGroup", body, httpOptions);
	}

	editFinancialItem(fi) {
		var body = JSON.stringify(fi);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "FinancialItem/EditFinancialItem", body, httpOptions);
	}

	getAccountGroupsByFinancialItemId(financialItemId?: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "FinancialItem/GetAccountGroupsByFinancialItemId/" + financialItemId),
		).then((res) => <FinancialItem[]>res);
	}

	deleteMasAccountGroup(accountGroupId: number) {
		return this.http.delete(this.apiUrl + "FinancialItem/DeleteMasAccountGroup/" + accountGroupId);
	}

	getAccountGroupsByCompanyId(CompanyId?: any) {
		return this.http.get<any>(this.apiUrl + "financials/account-groups/" + CompanyId);
	}

	getFinancialItemGroups(search: any) {
		return this.http.get<any>(this.apiUrl + `v1/financial-items/groups${this.sharing.getQueryString(search)}`);
	}
}
