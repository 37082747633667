import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { PageType } from "../shared/enum/page-type.enum";
import { RouteURL } from "../shared/enum/route-path.enum";

@Injectable()
export class UserControlService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private router: Router,
	) {}

	authorizeControl(functionCode: string) {
		var body = JSON.stringify(functionCode);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "UserControl/GetUserPermissionByFunctionCode", body, httpOptions);
	}

	authorizeControlByListFunctionCode(listFunctionCode: string[]) {
		var body = JSON.stringify(listFunctionCode);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "UserControl/GetUserPermissionByListFunctionCode", body, httpOptions);
	}

	authorizeControlByEmployeeIdAndListFunctionCode(employeeId: number, listFunctionCode: string[]) {
		var body = JSON.stringify({ employeeId: employeeId, listFunctionCode: listFunctionCode });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(
			this.apiUrl + "UserControl/GetUserPermissionByEmployeeIdAndListFunctionCode",
			body,
			httpOptions,
		);
	}

	authorizeControlByEmployeeId(employeeId: number, functionCode: string) {
		var body = JSON.stringify({ employeeId: employeeId, functionCode: functionCode });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "UserControl/GetUserPermissionByEmployeeIdAndFunctionCode", body, httpOptions);
	}

	authorizeControlByParentCode(parentCode: string) {
		var body = JSON.stringify(parentCode);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "UserControl/GetAuthorizedComponentByParentCode", body, httpOptions);
	}

	validatePermission(pageType: number, authorList: any) {
		if (
			(pageType == PageType.search && !authorList?.allowGrant) ||
			(pageType == PageType.add && (!authorList?.allowGrant || !authorList?.allowAdd)) ||
			(pageType == PageType.edit && (!authorList?.allowGrant || !authorList?.allowEdit)) ||
			(pageType == PageType.view && (!authorList?.allowGrant || !authorList?.allowView))
		) {
			this.router.navigate(["/" + RouteURL.accessDenied]);
		}

		return {
			allowGrant: authorList?.allowGrant,
			allowAdd: authorList?.allowAdd,
			allowEdit: authorList?.allowEdit,
			allowView: authorList?.allowView,
			allowDelete: authorList?.allowDelete,
		};
	}

	findAccount(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Graph/FindAccount", body, httpOptions);
	}

	getUserById(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Graph/GetUserById", body, httpOptions);
	}
	//#region UserAD
	createUserADById(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "UserControl/CreateUserById", body, httpOptions);
	}
	enableUserADById(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "UserControl/EnableUserById", body, httpOptions);
	}
	disableUserADById(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "UserControl/DisableUserById", body, httpOptions);
	}
	getStatusUserADById(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "UserControl/GetStatusUserById", body, httpOptions);
	}
	getSysActiveDirectoryConfigById(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "UserControl/GetSysActiveDirectoryConfigById", body, httpOptions);
	}
	//#endregion
	getD365UserById(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "D365/GetUserById", body, httpOptions);
	}

	enableUserAccount(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Graph/EnableUserAccount", body, httpOptions);
	}

	disableUserAccount(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Graph/DisableUserAccount", body, httpOptions);
	}

	updateUser(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Graph/UpdateUser", body, httpOptions);
	}

	createUser(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Graph/CreateUser", body, httpOptions);
	}

	createD365User(employeeId: number) {
		var body = JSON.stringify(employeeId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "D365/CreateUser", body, httpOptions);
	}
}
