import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeTimeStamp } from "./employee-time-stamp.model";
import { CommonService } from "../../shared/service/common.service";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { ApplicationConfig } from "../../shared/models/application-config.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeTimeStampService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getUserTimeStampLogByEmployeeIdDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "TimeStamp/GetUserTimeStampLogByEmployeeIdDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getTimeStamp(companyId: number, employeeNoFrom: string, employeeNoTo: string, dateFrom: string, dateTo: string) {
		this.commonService.changeDateJSON();
		var minuteGMT = new Date().getTimezoneOffset();

		var body = JSON.stringify({
			companyId: companyId,
			employeeNoFrom: employeeNoFrom,
			employeeNoTo: employeeNoTo,
			dateFrom: dateFrom,
			dateTo: dateTo,
			minuteGMT: minuteGMT,
		});
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeStamp/GetTimeStampExternalUser", body, httpOptions);
	}

	getTimeStampByEmployeeId(employeeId: number, dateFrom: string, dateTo: string) {
		this.commonService.changeDateJSON();
		var minuteGMT = new Date().getTimezoneOffset();

		var body = JSON.stringify({ employeeId: employeeId, dateFrom: dateFrom, dateTo: dateTo, minuteGMT: minuteGMT });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeStamp/GetTimeStampExternalUser", body, httpOptions);
	}

	exportTimeStampToExcel(
		companyId: number,
		employeeNoFrom: string,
		employeeNoTo: string,
		dateFrom: string,
		dateTo: string,
		supervisorId: number = null,
		workLocationId: number = null,
		positionId: number = null,
		organizationId: number = null,
		employmentType: number = null,
		shiftId: number = null,
	) {
		this.commonService.changeDateJSON();
		var minuteGMT = new Date().getTimezoneOffset();

		var body = JSON.stringify({
			companyId: companyId,
			employeeNoFrom: employeeNoFrom,
			employeeNoTo: employeeNoTo,
			dateFrom: dateFrom,
			dateTo: dateTo,
			minuteGMT: minuteGMT,
			supervisorId: supervisorId,
			workLocationId: workLocationId,
			positionId: positionId,
			organizationId: organizationId,
			employmentType: employmentType,
			shiftId: shiftId,
		});
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeStamp/ExportTimeStampExternalToExcel", body, httpOptions);
	}

	exportTimeStampToExcelByEmployeeId(employeeId: number, dateFrom: string, dateTo: string) {
		this.commonService.changeDateJSON();
		var minuteGMT = new Date().getTimezoneOffset();

		var body = JSON.stringify({ employeeId: employeeId, dateFrom: dateFrom, dateTo: dateTo, minuteGMT: minuteGMT });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeStamp/ExportTimeStampExternalToExcel", body, httpOptions);
	}

	addTimeStamp(emp: EmployeeTimeStamp) {
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "TimeStamp/AddTimeStamp", body, httpOptions);
	}

	importFileTimeStamp(
		file: string,
		companyId: number,
		appConfig: ApplicationConfig[],
		dateFormat: string,
		isAutoCal: boolean = false,
	) {
		var body = JSON.stringify({
			file: file,
			companyId: companyId,
			appConfig: appConfig,
			dateFormat: dateFormat,
			isAutoCal: isAutoCal,
		});

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeStamp/ImportFileTimeStamp", body, httpOptions);
	}

	importFileTimeStampExcel(
		file: string,
		companyId: number,
		appConfig: ApplicationConfig[],
		dateFormat: string,
		isAutoCal: boolean = false,
	) {
		var body = JSON.stringify({
			file: file,
			companyId: companyId,
			appConfig: appConfig,
			dateFormat: dateFormat,
			isAutoCal: isAutoCal,
		});

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeStamp/ImportFileTimeStampExcel", body, httpOptions);
	}
	deleteTimeStamp(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeStamp/DeleteTimeStamp", body, httpOptions);
	}
	getUserTimeStampsByUserIdAndDate(userId: string, date: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "TimeStamp/GetUserTimeStampsByUserIdAndDate/" + userId + "/" + date),
		).then((res) => <any[]>res);
	}
	editTimeAttendance(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "TimeStamp/EditTimeAttendance", body, httpOptions);
	}
}
