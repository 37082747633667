import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Employee } from "../../shared/employee-movements.model";
import { CommonService } from "../../../shared/service/common.service";

@Injectable()
export class EmployeeWorkInformationService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	editEmployeeWorkInformation(emp) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "Employee/EditEmployeeWorkInformation", body, httpOptions);
	}

	updateEmployeeIsCash(emp) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFinancial/UpdateEmployeeIsCash", body, httpOptions);
	}

	compareEmployeeDetail(emp: Employee) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/CompareEmployeeDetail", body, httpOptions);
	}

	CompareEmployeePayrollDetail(emp: Employee) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Employee/CompareEmployeePayrollDetail", body, httpOptions);
	}
}
