import { Component, Input, EventEmitter, Output, ChangeDetectorRef, OnInit } from "@angular/core";
import { BaseModule, InfoBarType } from "@gofive/design-system-base";
import { isNullOrUndefined } from "src/app/shared/sharing.service";
import { DataSharingService, UserInfoService } from "src/app/core";
import { EmployeeLeaveUsageService } from "src/app/employee-movements/employee-leave-usage/shared/employee-leave-usage.service";
import {
	UntypedFormBuilder,
	UntypedFormArray,
	FormGroup,
	FormArray,
	Validators,
	FormControl,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { ToastDataModel } from "@gofive/design-system-toast";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { EmployeeMovementsService } from "src/app/employee-movements/shared/employee-movements.service";
import { DateFormat, AngularCommonModule } from "@gofive/angular-common";
import { LeaveUsageModel } from "src/app/document-forms/shared/document-forms.model";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { HttpStatusCode } from "@angular/common/http";
import { LeaveTypeCustomSettingEmployeeComponent } from "../leave-type-custom-setting-employee/leave-type-custom-setting-employee.component";
import { firstValueFrom } from "rxjs";
import { AsyncPipe } from "@angular/common";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ButtonModule } from "@gofive/design-system-button";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { InputModule } from "@gofive/design-system-input";
import { DropdownModule } from "@gofive/design-system-dropdown";
import { AvatarModule } from "@gofive/design-system-avatar";
import { SidebarModule } from "@gofive/design-system-navigation";

@Component({
	selector: "app-leave-usage-custom-setting",
	templateUrl: "./leave-usage-custom-setting.component.html",
	styleUrls: ["./leave-usage-custom-setting.component.scss"],
	standalone: true,
	imports: [
		SidebarModule,
		FormsModule,
		ReactiveFormsModule,
		AvatarModule,
		DropdownModule,
		InputModule,
		TooltipModule,
		ButtonModule,
		NgxSkeletonLoaderModule,
		AsyncPipe,
		TranslateModule,
		AngularCommonModule,
		BaseModule,
	],
})
export class LeaveUsageCustomSettingComponent implements OnInit {
	@Input() displayLeaveUsageCustomSetting: boolean;
	@Input() leaveUsage: LeaveUsageModel;
	@Input() searchCompanyId: any;
	@Input() isProfileLeaveUsage: boolean = false;
	@Output() hasCloseLeaveUsageSideBar = new EventEmitter<boolean>(false);
	@Output() reloadData = new EventEmitter<boolean>(false);
	@Input() leaveTypeCustomSettingEmployeeComponent: LeaveTypeCustomSettingEmployeeComponent;

	public fieldsDropdown: any = {
		text: "name",
		value: "leaveTypeId",
	};

	public fieldsDropdown_EN: any = {
		text: "name_EN",
		value: "leaveTypeId",
	};
	get infoBarType() {
		return InfoBarType;
	}

	employeeDetail: any;
	employeeModified: any;
	lastDateModified: Date;
	leaveTypeDDL: any[];
	leaveTypeDDLTemp: any[];
	leaveUsages: LeaveUsageModel[];
	leaveUsagesIsAdjust: LeaveUsageModel[];

	public isLoading: boolean = true;

	form: FormGroup;

	public confirmation: any;

	get DateFormat() {
		return DateFormat;
	}

	constructor(
		public data: DataSharingService,
		private employeeLeaveUsageService: EmployeeLeaveUsageService,
		private fb: UntypedFormBuilder,
		private translate: TranslateService,
		private _toastHelperService: ToastHelperService,
		private employeeMovementsService: EmployeeMovementsService,
		private _dialogService: DialogService,
		private userInfoService: UserInfoService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			employeeLeaveUsageList: new UntypedFormArray([]),
		});
		if (this.isProfileLeaveUsage) {
			this.loadLeaveUsageSideBar(this.leaveUsage.employeeId, this.leaveUsage.year);
		}
	}

	async loadLeaveUsageSideBar(employeeId, year) {
		this.employeeDetail = this.loadEmployee(employeeId);

		const res = await this.employeeLeaveUsageService.getLeaveUsageByEmployeeId(employeeId, year);

		this.loadDropdown(res);
		this.leaveUsages = !isNullOrUndefined(res) ? res : [];
		this.leaveUsagesIsAdjust = !isNullOrUndefined(res) ? res.filter((f) => f.isAdjust == true) : [];
		if (this.leaveUsage && this.leaveUsage.isAdjust == false) {
			this.leaveUsagesIsAdjust.push(...[this.leaveUsage]);
		}

		if (this.leaveUsagesIsAdjust && this.leaveUsagesIsAdjust.length > 0) {
			let empModifiedId = this.setLastDateModified(this.leaveUsagesIsAdjust);

			empModifiedId ? (this.employeeModified = await this.loadModifiedBy(empModifiedId)) : (this.employeeModified = "");

			const leaveUsageArray = this.leaveUsagesIsAdjust.map((leave) =>
				this.fb.group({
					leaveTypeId: [leave.leaveTypeId, Validators.required],
					totalDays: [leave.totalDays, Validators.required],
					totalHours: [leave.totalHours, Validators.required],
				}),
			);

			(this.form.get("employeeLeaveUsageList") as FormArray).clear();
			leaveUsageArray.forEach((control) => (this.form.get("employeeLeaveUsageList") as FormArray).push(control));
		}

		(this.form.get("employeeLeaveUsageList") as FormArray).updateValueAndValidity();
		this.isLoading = false;
		this.cdr.detectChanges();
	}

	loadEmployee(employeeId) {
		this.employeeMovementsService.getEmployeeById(employeeId).then((emp) => {
			this.employeeDetail = emp;
		});
	}

	loadModifiedBy(userId) {
		if (userId.toLowerCase() == "system") {
			return {
				employeeName: "System",
				employeeName_EN: "System",
			};
		}
		return firstValueFrom(this.userInfoService.getUserInfoById(userId));
	}

	loadDropdown(employeeLeaveUsage) {
		let leaveTypeArr = [];
		employeeLeaveUsage.forEach((item) => {
			leaveTypeArr.push({
				leaveTypeId: item.leaveTypeId,
				name: item.leaveTypeName,
				name_EN: item.leaveTypeName_EN,
			});
		});
		this.leaveTypeDDL = leaveTypeArr;
		this.leaveTypeDDLTemp = Object.assign([], leaveTypeArr);
	}

	resetValue() {
		this.employeeDetail = null;
		this.employeeModified = null;
		this.lastDateModified = null;
	}

	setLastDateModified(leaveUsagesIsAdjust) {
		let employeeIdModifiedBy = "";
		if (leaveUsagesIsAdjust && leaveUsagesIsAdjust.length > 1) {
			let latestItem = leaveUsagesIsAdjust.reduce((prev, current) => {
				let prevDate = new Date(prev.dateModified || prev.dateCreated);
				let currentDate = new Date(current.dateModified || current.dateCreated);
				return prevDate.getTime() > currentDate.getTime() ? prev : current;
			});

			this.lastDateModified = new Date(latestItem.dateModified || latestItem.dateCreated);
			employeeIdModifiedBy = latestItem.modifiedBy;
		} else if (leaveUsagesIsAdjust && leaveUsagesIsAdjust.length == 1) {
			this.lastDateModified = new Date(leaveUsagesIsAdjust[0].dateModified || leaveUsagesIsAdjust[0].dateCreated);
			employeeIdModifiedBy = leaveUsagesIsAdjust[0].modifiedBy;
		}
		return employeeIdModifiedBy;
	}

	addEmployeeLeaveUsageList() {
		const leaveUsageControl = this.fb.group({
			leaveTypeId: [null, Validators.required],
			totalDays: [null, Validators.required],
			totalHours: [null, Validators.required],
		});
		const formArray = this.form.get("employeeLeaveUsageList") as FormArray;
		formArray.push(leaveUsageControl);
	}

	deleteEmployeeLeaveUsageList(event) {
		let empLeaveUsageArr = this.form.get("employeeLeaveUsageList") as UntypedFormArray;

		empLeaveUsageArr.removeAt(event);
	}

	onChangeLeaveType() {
		this.leaveTypeDDLTemp = Object.assign([], this.leaveTypeDDL);
		let empLeaveUsageArr = this.form.get("employeeLeaveUsageList") as UntypedFormArray;

		let selectedArr = [];
		empLeaveUsageArr.getRawValue().forEach((item) => {
			selectedArr.push(item.leaveTypeId);
		});

		this.leaveTypeDDLTemp = this.leaveTypeDDLTemp.filter((leaveType) => !selectedArr.includes(leaveType.leaveTypeId));
	}

	onSelectLeaveType(event, i) {
		const formArray = this.form.get("employeeLeaveUsageList") as FormArray;

		const selectedLeaveUsage = this.leaveUsages.find((leaveUsage) => leaveUsage.leaveTypeId === event.selectValue);

		if (selectedLeaveUsage) {
			formArray.at(i).get("totalDays").setValue(selectedLeaveUsage.totalDays);
			formArray.at(i).get("totalHours").setValue(selectedLeaveUsage.totalHours);
		}
	}

	onSubmit() {
		let isValidForm = this.form.valid;
		if (isValidForm) {
			const dialogModel = <DialogInformationModel>{
				title: this.translate.instant("common_confirmation"),
				description: this.translate.instant("common_edit_confirmation"),
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				if (response?.confirm) {
					this.getDataSubmit();
					this.submit(this.getDataSubmit());
					this.displayLeaveUsageCustomSetting = false;
					this.reloadData.emit(true);
				}
			});
		} else {
			let toast: ToastDataModel = {
				title: this.translate.instant("common_heads_up"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			};
			this._toastHelperService.validation(toast);
			this.form.markAllAsTouched();
		}
	}

	private getDataSubmit() {
		let updateArr = [];

		const formArray = this.form.get("employeeLeaveUsageList") as UntypedFormArray;

		formArray.controls.forEach((control) => {
			const leaveTypeId = control.get("leaveTypeId").value;

			let updateLeaveUsage = this.leaveUsages.find((leave) => leave.leaveTypeId === leaveTypeId);

			if (updateLeaveUsage) {
				updateLeaveUsage.isAdjust = true;
				updateLeaveUsage.isUnlimit = false;
				updateLeaveUsage.totalDays = control.get("totalDays").value;
				updateLeaveUsage.totalHours = control.get("totalHours").value;
				updateArr.push(updateLeaveUsage);
			}
		});
		const isChangeToIsNotAdjust = this.leaveUsagesIsAdjust
			.filter((leave) => {
				const leaveTypeId = leave.leaveTypeId;
				return formArray.value.findIndex((control) => control.leaveTypeId === leaveTypeId) === -1;
			})
			.map((leave) => leave.leaveTypeId);

		isChangeToIsNotAdjust.forEach((leaveTypeId) => {
			const updateLeaveUsage = this.leaveUsages.find((leave) => leave.leaveTypeId === leaveTypeId);

			if (updateLeaveUsage) {
				updateLeaveUsage.isAdjust = false;
				updateArr.push(updateLeaveUsage);
			}
		});

		return updateArr;
	}

	submit(data) {
		this.employeeLeaveUsageService.editLeaveUsageList(data).subscribe((res) => {
			if (res.status === HttpStatusCode.Ok) {
				let toast: ToastDataModel = {
					title: this.translate.instant("toast_title_success"),
					description: this.translate.instant("common_toast_description_save_successfully"),
				};
				this._toastHelperService.success(toast);
				this.leaveTypeCustomSettingEmployeeComponent.reload();
				this.onCloseSideBar();
			}
		});
	}

	onCloseSideBar() {
		this.isLoading = true;
		this.resetValue();
		this.hasCloseLeaveUsageSideBar.emit(true);
	}

	changeTotalDays(value, i) {
		if (value !== null && value !== undefined) {
			const decimal = value % 1;
			let roundedValue: number;
			if (decimal < 0.25) {
				roundedValue = Math.floor(value);
			} else if (decimal < 0.75) {
				roundedValue = Math.floor(value) + 0.5;
			} else {
				roundedValue = Math.ceil(value);
			}

			const employeeLeaveUsageList = this.form.get("employeeLeaveUsageList") as FormArray;
			const totalDaysControl = employeeLeaveUsageList.at(i).get("totalDays") as FormControl;
			totalDaysControl.setValue(roundedValue);
		}
	}
}
