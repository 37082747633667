import { Component, EventEmitter, Injectable, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { SelectItem } from "primeng/api";
import { BehaviorSubject, Subscription, firstValueFrom } from "rxjs";
import { DataSharingService, UserControlService } from "../../core";
import { PaymentService } from "../../payroll/payment/shared/payment.service";
import { FinancialCategory } from "../../shared/enum/financial-category.enum";
import { FinancialItemType } from "../../shared/enum/financial-item-type.enum";
import { Module } from "../../shared/enum/module.enum";
import { PageType } from "../../shared/enum/page-type.enum";
import { PayrollStatus } from "../../shared/enum/payroll-status.enum";
import { ProcessAction } from "../../shared/enum/process-action.enum";
import { ApiResult } from "../../shared/models/api-result.model";
import { Permission } from "../../shared/models/permission.model";
import { CommonService } from "../../shared/service/common.service";
import { EmployeeMovement } from "../employee-movement/shared/employee-movement.model";
import { EmployeeMovementService } from "../employee-movement/shared/employee-movement.service";
import { EmployeePayrollHistoryComponent } from "./employee-payroll-history/employee-payroll-history.component";
import { EmployeeFinancial, EmployeeFinancialHistories, EmployeePayroll } from "./shared/employee-financial.model";
import { EmployeeFinancialService } from "./shared/employee-financial.service";
import { EmployeeMovementsService } from "../shared/employee-movements.service";
import { PaymentPeriod } from "../../payroll/master/payment-period/shared/payment-period.model";
import { Payment, PayrollPayment } from "../../payroll/payment/shared/payment.model";
import { environment } from "../../../environments/environment";
import { DialogInformationModel, DialogService, DialogModule } from "@gofive/design-system-dialog";
import { DropdownSelectMode, DropdownModule } from "@gofive/design-system-dropdown";
import { ToastHelperService } from "../../shared/service/toast-helper.service";
import { isNullOrUndefined, SharingService } from "../../shared/sharing.service";
import { TabsComponent, NavigationModule } from "@gofive/design-system-navigation";
import { InputTextModule } from "primeng/inputtext";
import { CalendarModule } from "primeng/calendar";
import { SelectButtonModule } from "primeng/selectbutton";
import { PayrollHistoryComponent } from "../../shared/popup/payroll-history/payroll-history.component";
import { ButtonModule } from "@gofive/design-system-button";
import { InputModule } from "@gofive/design-system-input";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { InfoTooltipComponent } from "../../shared/template/info-tooltip/info-tooltip.component";
import { EmployeeSocialSecurityComponent } from "../employee-social-security/employee-social-security.component";
import { EmployeeLoanComponent } from "../employee-loan/employee-loan.component";
import { AccumulateComponent } from "../../payroll/accumulate/accumulate.component";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { AvatarModule } from "@gofive/design-system-avatar";
import { TwoFactorAuthenticationComponent } from "../../shared/component/two-factor-authentication/two-factor-authentication.component";
import {
	NgIf,
	NgClass,
	NgStyle,
	NgTemplateOutlet,
	NgComponentOutlet,
	NgFor,
	AsyncPipe,
	DecimalPipe,
} from "@angular/common";

@Injectable({
	providedIn: "root",
})
export class PayRollSharingService {
	private isShowSalary = new BehaviorSubject(false);
	$_isShowSalary = this.isShowSalary.asObservable();

	private isDialog = new BehaviorSubject(false);
	$_isDialog = this.isDialog.asObservable();

	private allowGrant = new BehaviorSubject(false);
	$_allowGrant = this.allowGrant.asObservable();

	setData(isShowSalary: boolean) {
		this.isShowSalary.next(isShowSalary);
	}

	setIsDialog(isDialog: boolean) {
		this.isDialog.next(isDialog);
	}

	setAllowGrant(allowGrant: boolean) {
		this.allowGrant.next(allowGrant);
	}
}
@Component({
	selector: "app-employee-payroll",
	templateUrl: "./employee-payroll.component.html",
	styleUrls: ["./employee-payroll.component.scss"],
	standalone: true,
	imports: [
		NgIf,
		TwoFactorAuthenticationComponent,
		NgClass,
		NgStyle,
		NgTemplateOutlet,
		AvatarModule,
		TooltipModule,
		NavigationModule,
		EmployeePayrollHistoryComponent,
		AccumulateComponent,
		EmployeeLoanComponent,
		NgComponentOutlet,
		EmployeeSocialSecurityComponent,
		DropdownModule,
		FormsModule,
		NgFor,
		InfoTooltipComponent,
		NgxSkeletonLoaderModule,
		InputModule,
		ButtonModule,
		DialogModule,
		PayrollHistoryComponent,
		ReactiveFormsModule,
		SelectButtonModule,
		CalendarModule,
		InputTextModule,
		AsyncPipe,
		DecimalPipe,
		TranslateModule,
	],
})
export class EmployeePayrollComponent implements OnInit {
	//#region Variable
	@ViewChild("tabGo5") tabGo5: TabsComponent;
	@ViewChild(EmployeePayrollHistoryComponent, { static: false, read: false })
	empPayrollHistory: EmployeePayrollHistoryComponent;
	public lazyComponentLoader = new BehaviorSubject<any>(null);

	form: UntypedFormGroup;
	paymentPeriodDDL: PaymentPeriod[];
	paymentPeriods: PaymentPeriod[] = [];
	paymentPeriod: PaymentPeriod = new PaymentPeriod();
	paymentPeriodObs: PaymentPeriod = new PaymentPeriod();
	currentPayrollPayment: PayrollPayment = new PayrollPayment();
	payment: Payment = new Payment();
	indexTab: number = 0;
	maskAmount: string = "xxxx.xx";
	salaryCurrent: number = 0;
	netAbsenceDeduct: number = 0;
	netLateDeduct: number = 0;
	netOvertime: number = 0;
	tabAccumulate: boolean = false;
	tabLoan: boolean = false;
	tabAllowance: boolean = false;
	tabSSO: boolean = false;
	sumType1: number;
	sumType2: number;
	netpay: number;
	isClosed: boolean = false;
	isPaid: boolean = false;
	year: number;
	periodCalculateNo: number;
	companyId: number;
	id: number;
	confirmation: any;
	addConfirmation: any;
	saveConfirmation: any;
	sendEslipConfirm: any;
	calculateSalaryConfirm: any;
	isRequired: boolean = false;
	isCalculate: boolean = false;
	isShowSalary: boolean = false;
	typeAmount: string = "password";
	temp: number;

	payrollStatusId: number;
	hasEmailAddress: boolean = false;
	isStatusWaitingForCalculation: boolean = false;
	isStatusWaitingForClosingPeriod: boolean = false;
	isStatusWaitingVerify: boolean = false;
	isStatusWaitingApprove: boolean = false;
	isStatusWaitingToPay: boolean = false;
	isStatusPaid: boolean = false;

	other: number = FinancialCategory.other;
	calculate: any = null;
	cols: any[];

	employeePayRollHistories: Array<any> = [];
	popup_History_Edit: boolean;
	editTypeOption: SelectItem[] = [
		{ label: "common_record", value: true },
		{ label: "common_edit_wrong", value: false },
	];

	editType: boolean = false;
	remarks: string;
	popup_Confirm: boolean = false;
	date: Date = new Date();
	today: Date;

	subscription: Subscription;
	userInfo$: Subscription;
	subscription1: Subscription;
	subscriptionTab: Subscription;
	taxDetails: any;

	payrollPayment: PayrollPayment = new PayrollPayment();
	paymentModel: Payment = new Payment();
	employeeMovement: EmployeeMovement = new EmployeeMovement();
	employeePayroll: EmployeePayroll = new EmployeePayroll();
	employeeFinancials: EmployeeFinancial[] = [];
	employeeFinancialsOld: EmployeeFinancial[] = [];
	employeeFinancialsType1: EmployeeFinancial[] = [];
	employeeFinancialsType2: EmployeeFinancial[] = [];
	compareList: EmployeeFinancialHistories[];
	baseSalary: EmployeeFinancialHistories = null;
	salary: number = FinancialCategory.baseSalary;

	employeeFinancialsOT: any[] = [];
	sumOT1: number = 0;
	sumOT2: number = 0;
	sumOT3: number = 0;
	sumOT4: number = 0;
	sumFixRateOT: number = 0;
	sumTimeOT1: number = 0;
	sumTimeOT2: number = 0;
	sumTimeOT3: number = 0;
	sumTimeOT4: number = 0;
	sumTimeFixRateOT: number = 0;
	sumOT: number = 0;
	sumAbsenceDeduct: number = 0;
	sumLateDeduct: number = 0;
	sumAbsent: string = "";
	amonutAbsentDay: string = "";
	employeeFinancialsAbsenceDeduct: any[] = [];
	employeeFinancialsLateDeduct: any[] = [];
	dateCycleCalOT: string;
	dateCycleCalAbsenceDeduct: string;
	dateCycleCalLateDeduct: string;
	language: string;
	financialCategoryBaseSalary: number = FinancialCategory.baseSalary;
	financialCategoryOvertime: number = FinancialCategory.overtime;
	financialCategoryAbsenceDeduct: number = FinancialCategory.absenceDeduct;
	financialCategoryLateDeduct: number = FinancialCategory.lateDeduct;
	presentYear: number = 0;

	isLoadEmployeeFinancialsType: boolean = true;
	isSave: boolean = false;
	tabTax: boolean = false;

	permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	tabPermission: any;

	public fieldsPayment = { text: "name", value: "id" };
	public fieldsPayment_EN = { text: "name_EN", value: "id" };

	public fieldsPeriod = { text: "paymentPeriodName", value: "periodCalculateNo" };
	public fieldsPeriod_EN = { text: "paymentPeriodName_EN", value: "periodCalculateNo" };

	public textHeader: string;

	public paymentId: number = 1;

	get DropdownSelectMode() {
		return DropdownSelectMode;
	}
	//#endregion
	@Input() periodExtendNo: number = 0;
	@Input() allowGrant: boolean = false;
	@Input() isAllowTabLoan: boolean = false;
	@Input() allowGrantTax: boolean = false;
	@Input() isFooter: boolean = false;
	@Input() dateEmployment: Date;
	@Input() tabPermissionPayroll: boolean = false;
	@Input() isPaymentModal: boolean = false;
	@Input() fullTemPlate: boolean = true;
	@Input() isEmpViewProfile: boolean = false;
	@Input() isEmpNo: number;
	@Output() statusEvent = new EventEmitter<boolean>();
	@Output() closeEvent = new EventEmitter();
	@Output() employeeEvent = new EventEmitter<number>();

	isShowFooter: boolean = true;
	allowAdd: boolean = false;
	public isHR: boolean = false;

	constructor(
		private fb: UntypedFormBuilder,
		private commonService: CommonService,
		private userControlService: UserControlService,
		public data: DataSharingService,
		private translate: TranslateService,
		private employeeFinancialService: EmployeeFinancialService,
		private paymentService: PaymentService,
		private router: Router,
		private employeeMovementService: EmployeeMovementService,
		private employeeMovementsService: EmployeeMovementsService,
		private _toastHelperService: ToastHelperService,
		private _dialogService: DialogService,
		private payRollSharingService: PayRollSharingService,
		public sharingService: SharingService,
	) {
		this.loadLazyComponent();
		this.payRollSharingService.setData(false);
	}
	lazyComponent$ = this.lazyComponentLoader.asObservable();

	loadLazyComponent() {
		import("./../employee-tax/employee-tax.component")
			.then((mod) => this.lazyComponentLoader.next(mod.EmployeeTaxComponent))
			.catch((err) => console.error(err));
	}

	ngOnChanges(changes: SimpleChanges) {
		if ("isPaymentModal" in changes) {
			this.payRollSharingService.setIsDialog(this.isPaymentModal);
		}

		if ("allowGrantTax" in changes) {
			this.payRollSharingService.setAllowGrant(this.allowGrantTax);
		}
	}

	ngOnInit() {
		this.subscription = this.data.currentPaymentPeriod.subscribe((data) => {
			this.subscriptionTab = this.data.currentpayrollTab.subscribe((string) => {
				if (string != "") {
					this.textHeader = this.translate.instant(string?.toString());
				}
				switch (string) {
					case this.translate.instant("emp001_t14_payroll").trim():
						{
							this.isShowFooter = true;
							this.netpay = this.sumType1 - this.sumType2;
						}
						break;
					case this.translate.instant("pay001_t03_accumulate_tab").trim():
						{
							this.tabAccumulate = true;
							this.isShowFooter = false;
						}
						break;
					case this.translate.instant("emp001_t19_loan").trim():
						{
							this.tabLoan = true;
							this.isShowFooter = false;
						}
						break;
					case this.translate.instant("tab_allowance").trim():
						{
							this.tabAllowance = true;
							this.isShowFooter = false;
						}
						break;
					case this.translate.instant("emp001_t15_tax").trim():
						{
							this.tabTax = true;
							this.isShowFooter = false;
							this.loadTaxDetail();
						}
						break;
				}
				this.loadTab();
			});
			if (!isNullOrUndefined(data)) {
				this.paymentPeriodObs = data;
				this.year = this.paymentPeriodObs.year;
				this.periodCalculateNo = this.paymentPeriodObs.periodCalculateNo;
				this.companyId = this.paymentPeriodObs.companyId;
			}
		});
		//#region From control
		this.form = this.fb.group({
			dateCreated: new UntypedFormControl("", Validators.required),
			isEffective: new UntypedFormControl("", Validators.required),
			remarks: new UntypedFormControl(""),
		});
		//#endregion

		this.subscription1 = this.data.currentEmpId.subscribe((data) => {
			this.id = data;
			if (this.id == 0) {
				this.router.navigate(["/employee"]);
			} else {
				this.callAPI();
				this.updateValidator();
				this.resetShowSalay();
			}
		});
		this.presentYear = Number(this.commonService.getPresentYear());
		this.loadPermission();

		//#region Call api
	}

	loadTaxDetail() {
		let tmpCurrentPeriod = { ...this.paymentPeriodDDL2.find((f) => f.id == this.paymentId), isView: this.isShowSalary };
		firstValueFrom(this.employeeFinancialService.getEmployeeTaxDetails(tmpCurrentPeriod))
			.then((res) => {
				this.taxDetails = res;
			})
			.finally(() => {
				this.isLoadingTaxDetail = false;
			});
	}

	//#region Destroy unsubscribe
	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.subscription1.unsubscribe();
		this.subscriptionTab.unsubscribe();
		if (this.userInfo$) this.userInfo$.unsubscribe();
	}
	//#endregion

	async loadPermission() {
		await firstValueFrom(this.userControlService.authorizeControl(Module.EMP001_T14)).then((auth) => {
			auth["allowGrant"] = this.allowGrant ? this.allowGrant : auth["allowGrant"];
			this.permission = this.userControlService.validatePermission(PageType.search, auth);
			this.allowAdd = this.permission.allowAdd;
		});
		await firstValueFrom(this.userControlService.authorizeControl(Module.PAY001_T17)).then((auth) => {
			this.tabPermission = auth;
			this.allowAdd = this.allowAdd || this.tabPermission.allowGrant;
			this.loadTab();
		});
	}
	//#region Load data
	public userInfo: any;
	public paymentPeriodDDL2: any[] = [];
	async callAPI() {
		await this.employeeMovementsService.getEmployeeById(this.id).then((emp) => {
			this.employeeMovement = isNullOrUndefined(emp) ? new EmployeeMovement() : emp;
			this.paymentPeriodObs = null;
			if (isNullOrUndefined(this.paymentPeriodObs) || isNullOrUndefined(this.paymentPeriodObs.companyId)) {
				this.companyId = this.employeeMovement.companyId;
			}
		});

		this.commonService.getDDLPaymentPeriodByEmployeeId(this.id).then(async (res) => {
			this.paymentPeriodDDL = res;
			this.paymentPeriodDDL.forEach((paymentPeriod) => {
				paymentPeriod.label = paymentPeriod.paymentPeriodName + " - " + paymentPeriod.payrollStatusName;
				paymentPeriod.label_EN = paymentPeriod.paymentPeriodName + " - " + paymentPeriod.payrollStatusName;
			});

			this.paymentPeriodDDL2 = this.paymentPeriodDDL
				.filter((filter) => filter.year >= 2022)
				.map((payment, _index) => {
					let model: any = {};
					model["id"] = _index + 1;
					model["name"] = payment.paymentPeriodNameShort;
					model["name_EN"] = payment.paymentPeriodNameShort_EN;
					model["periodCalculateNo"] = payment.periodCalculateNo;
					model["year"] = payment.year;
					model["companyId"] = this.employeeMovement.companyId;
					model["employeeId"] = this.id;
					return model;
				});
			if (!isNullOrUndefined(this.year) && !isNullOrUndefined(this.periodCalculateNo)) {
				this.paymentPeriod = res.find((f) => f.year == this.year && f.periodCalculateNo == this.periodCalculateNo);
			} else {
				await this.getCurrentPayrollPayment();
			}
			this.userInfo$ = this.data.currentUserInfo.subscribe((user) => {
				this.userInfo = user;
				this.isHR = this.userInfo.isHR;
				this.authStatus = !user.isActive2FA;
			});
			this.getPaymentPeriod({ selectedItem: this.paymentPeriod });
		});
	}
	loadPayrollPayment() {
		this.paymentService
			.getPayrollPaymentById1(this.id, this.year, this.periodCalculateNo, this.companyId, this.periodExtendNo)
			.then((res) => {
				this.payrollPayment = res;
				this.payrollStatusId = this.payrollPayment.payrollStatusId;
				this.getPayrollStatus();
			});
	}

	viewEmployee(item) {
		this.data.setEmpId(item.employeeId);
		window.open(`${environment.redirectUri}employee/${item.employeeNo}`);
	}

	loadTab() {
		this.tabGo5?.tabs?.forEach((element, index) => {
			if (element.headerText == this.textHeader && this.isEmpViewProfile) {
				this.indexTab = index;
			}
		});
	}

	loadConfirm() {
		this.confirmation = this.translate.instant("common_confirmation");
		this.saveConfirmation = this.translate.instant("common_save_confirmation");
		this.sendEslipConfirm = this.translate.instant("common_e_slip_confirmation");
		this.calculateSalaryConfirm = this.translate.instant("common_cal_salary_confirmation");
	}

	get financialCategory() {
		return FinancialCategory;
	}

	getEmployeeFinancial() {
		this.employeeFinancialService
			.getEmployeeFinancialeId(this.id, this.year, this.periodCalculateNo, this.companyId, this.periodExtendNo)
			.then((emp) => {
				this.employeePayroll = emp;
				if (!isNullOrUndefined(this.employeePayroll)) {
					this.companyId = this.employeePayroll.companyId;
					this.employeeFinancials = this.employeePayroll.employeeFinancials;
					this.isClosed = this.employeePayroll.isClosed; //ถ้ามีข้อมูลสามารถใช้ is closed จากตรงนี้ได้เลย
					this.isPaid = this.employeePayroll.isPaid;
					this.hasEmailAddress = this.employeePayroll.hasEmailAddress;
					this.employeeFinancialsType1 = !isNullOrUndefined(this.employeeFinancials)
						? this.employeeFinancials.filter((f) => f.type == FinancialItemType.addition)
						: [];
					this.employeeFinancialsType2 = !isNullOrUndefined(this.employeeFinancials)
						? this.employeeFinancials.filter((f) => f.type == FinancialItemType.deduction)
						: [];
					var employeeFinancialsType3 = !isNullOrUndefined(this.employeeFinancials)
						? this.employeeFinancials.filter(
								(f) => f.type == FinancialItemType.addition && f.category == FinancialCategory.baseSalary,
							)
						: [];
					var employeeFinancialsType4 = !isNullOrUndefined(this.employeeFinancials)
						? this.employeeFinancials.filter(
								(f) => f.type == FinancialItemType.deduction && f.category == FinancialCategory.absenceDeduct,
							)
						: [];
					var employeeFinancialsType5 = !isNullOrUndefined(this.employeeFinancials)
						? this.employeeFinancials.filter(
								(f) => f.type == FinancialItemType.deduction && f.category == FinancialCategory.lateDeduct,
							)
						: [];

					this.salaryCurrent = employeeFinancialsType3.length > 0 ? employeeFinancialsType3[0].amount : 0;
					this.netAbsenceDeduct = employeeFinancialsType4.length > 0 ? employeeFinancialsType4[0].amount : 0;
					this.netLateDeduct = employeeFinancialsType5.length > 0 ? employeeFinancialsType5[0].amount : 0;

					this.sumType1 = Number(
						this.employeeFinancialsType1.reduce((sum, current) => sum + current.amount, 0).toFixed(2),
					);
					this.sumType2 = Number(
						this.employeeFinancialsType2.reduce((sum, current) => sum + current.amount, 0).toFixed(2),
					);
					this.netpay = this.sumType1 - this.sumType2;
				} else {
					this.employeeFinancials = [];
				}
				this.isLoadEmployeeFinancialsType = false;
			});

		this.loadPayrollPayment();
	}
	//#endregion

	//#region Action
	async save(isConfirm = true) {
		this.employeeFinancials.forEach((f) => {
			if (isNullOrUndefined(f.amount)) {
				f.amount = 0;
			}
		});
		await firstValueFrom(this.employeeFinancialService.compareEmployeeFinancials(this.employeeFinancials)).then(
			(res) => {
				var apiResult = <ApiResult>res;
				if (apiResult.result) {
					this.compareList = apiResult.data;
					this.compareList.forEach((s) => {
						var financialItem = this.employeeFinancials.find((p) => p.financialItemId == s.financialItemId);
						s.name = financialItem.financialItemName;
						s.name_EN = financialItem.financialItemName_EN;
						s.category = financialItem.category;
					});

					if (!isNullOrUndefined(this.compareList) && this.compareList.length > 0) {
						if (isConfirm) {
							this.popup_Confirm = true;
							this.baseSalary = this.compareList.find((f) => f.category == FinancialCategory.baseSalary);
						} else {
							this.onSubmitCompare(isConfirm);
						}
					} else {
						this.popup_Confirm = false;
						this.onSubmitCompare(isConfirm);
					}

					this.resetForm();
				}
			},
		);
	}

	calculateSalary() {
		this.loadConfirm();

		this.paymentModel.year = this.year;
		this.paymentModel.periodCalculateNo = this.periodCalculateNo;
		this.paymentModel.companyId = this.companyId;
		this.paymentModel.employeeId = this.id;
		this.paymentModel.processActionId = ProcessAction.calculateSalary;

		const dialogModel = <DialogInformationModel>{
			title: this.confirmation,
			description: this.calculateSalaryConfirm,
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm"),
			textButtonCancel: this.translate.instant("common_cancel"),
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then(async (response) => {
			if (response?.confirm) {
				this.employeeFinancials.forEach((element) => {
					if (isNullOrUndefined(element.amount)) {
						element.amount = 0;
					}
				});
				if (this.isSave) {
					let isConfirm = false;
					await this.save(isConfirm);
				}

				firstValueFrom(this.paymentService.addProcessActionPayment(this.paymentModel)).then((res) => {
					var apiResult = <ApiResult>res;
					this.statusEvent.emit(apiResult.result);
					this.employeeEvent.emit(this.id);
					if (apiResult.result) {
						this.getEmployeeFinancial();
					}
					this._toastHelperService.alertApiResult(apiResult);
				});
			}
		});
	}
	async editEmployeeFinancial(isAlert = true) {
		this.compareList.forEach((s) => {
			s.isEffective = this.editType;
			s.dateModified = this.date;
		});

		this.employeeMovement.employeeId = this.employeePayroll.employeeId;
		this.employeeMovement.movementTypeId = ProcessAction.raiseTheSalary;
		this.employeeMovement.dateEffective = this.date;
		this.employeeMovement.remarks = this.remarks;
		this.employeeMovement.isApprove = true;

		if (this.editType && !isNullOrUndefined(this.baseSalary)) {
			this.employeeMovement.isIncomeAdjust = true;
			this.employeeMovement.incomeAmount = this.baseSalary.newValue;
			await firstValueFrom(this.employeeMovementService.addEmployeeMovementTransaction(this.employeeMovement)).then();
		}

		firstValueFrom(this.employeeFinancialService.editEmployeeFinancial(this.employeePayroll)).then((res) => {
			var apiResult = <ApiResult>res;

			if (apiResult.result) {
				if (!isNullOrUndefined(this.compareList) && this.compareList.length > 0) {
					if (this.editType) {
						this.compareList = this.compareList.filter((f) => f.category != FinancialCategory.baseSalary);
					}

					firstValueFrom(this.employeeFinancialService.addHistoryEmployeeFinancial(this.compareList)).then();
				}

				this.closeDialog();
				this.resetForm();
				this.getEmployeeFinancial();
				this.isSave = false;
				if (!this.isFooter) this.calculateSalary();
			}

			if (isAlert) {
				this.statusEvent.emit(apiResult.result);
				this._toastHelperService.alertApiResult(apiResult);
			}
		});
	}

	onSubmitCompare(isConfirm = true) {
		if (isConfirm) {
			this.loadConfirm();

			const dialogModel = <DialogInformationModel>{
				title: this.confirmation,
				description: this.saveConfirmation,
				imageUrl: "confirmation.png",
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_cancel"),
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then(async (response) => {
				if (response?.confirm) {
					this.editEmployeeFinancial(isConfirm);
				}
			});
		} else {
			this.editEmployeeFinancial(isConfirm);
		}
	}

	totalAddition(item) {
		this.isOnClose = false;
		this.employeeFinancialsType1 = this.employeeFinancials.filter((f) => f.type == FinancialItemType.addition);
		this.sumType1 = Number(this.employeeFinancialsType1.reduce((sum, current) => sum + current.amount, 0).toFixed(2));
		this.netpay = this.sumType1 - this.sumType2;
		if (isNullOrUndefined(item.amount)) {
			item.isCalculate = true;
		}
		this.checkRequired();
	}

	totalDeduction(item) {
		this.isOnClose = false;
		this.employeeFinancialsType2 = this.employeeFinancials.filter((f) => f.type == FinancialItemType.deduction);
		this.sumType2 = Number(this.employeeFinancialsType2.reduce((sum, current) => sum + current.amount, 0).toFixed(2));
		this.netpay = this.sumType1 - this.sumType2;
		if (isNullOrUndefined(item.amount)) {
			item.isCalculate = true;
		}
		this.checkRequired();
	}

	sendEslip() {
		this.loadConfirm();
		const dialogModel = <DialogInformationModel>{
			title: this.confirmation,
			description: this.sendEslipConfirm,
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm"),
			textButtonCancel: this.translate.instant("common_cancel"),
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				this.payment.companyId = this.companyId == 0 ? this.employeePayroll.companyId : this.companyId;
				this.payment.periodCalculateNo = this.periodCalculateNo;
				this.payment.periodExtendNo = this.periodExtendNo;
				this.payment.year = this.year;
				this.payment.employeeId = this.id;
				firstValueFrom(this.paymentService.sentEslip(this.payment)).then((res) => {
					var apiResult = <ApiResult>res;

					this._toastHelperService.alertApiResult(apiResult);
				});
			}
		});
	}

	viewHistory() {
		this.popup_History_Edit = true;
	}

	getPaymentPeriod(event = null) {
		if (event) {
			this.paymentPeriod = event.selectedItem;
			this.year = event.selectedItem.year;
			this.periodCalculateNo = event.selectedItem.periodCalculateNo;
			this.getEmployeeFinancial();
		}
	}

	getPayrollStatus() {
		this.clearIsData();
		this.loadEmployeeFinancialTransaction(FinancialCategory.overtime);
		this.loadEmployeeFinancialTransaction(FinancialCategory.absenceDeduct);
		this.loadEmployeeFinancialTransaction(FinancialCategory.lateDeduct);
		switch (this.payrollStatusId) {
			case PayrollStatus.waitingForCalculation:
				this.isStatusWaitingForCalculation = true;
				break;
			case PayrollStatus.waitingVerify:
				this.isStatusWaitingVerify = true;
				break;
			case PayrollStatus.waitingApprove:
				this.isStatusWaitingApprove = true;
				break;
			case PayrollStatus.waitingForClosingPeriod:
				this.isStatusWaitingForClosingPeriod = true;
				break;
			case PayrollStatus.waitingToPay:
				this.isStatusWaitingToPay = true;
				break;
			case PayrollStatus.paid:
				this.isStatusPaid = true;
				break;
			default:
				this.isStatusWaitingForCalculation = true;
				break;
		}
	}

	clearIsData() {
		this.isStatusWaitingForCalculation = false;
		this.isStatusWaitingVerify = false;
		this.isStatusWaitingApprove = false;
		this.isStatusWaitingForClosingPeriod = false;
		this.isStatusWaitingToPay = false;
		this.isStatusPaid = false;

		this.dateCycleCalOT = "-";
		this.dateCycleCalAbsenceDeduct = "-";
		this.dateCycleCalLateDeduct = "-";

		this.sumAbsent = "-";

		this.employeeFinancialsOT = [];
		this.employeeFinancialsLateDeduct = [];
		this.employeeFinancialsLateDeduct = [];
	}

	checkRequired() {
		//ถ้าไม่ใส่ข้อมูล excessBaht
		if (!isNullOrUndefined(this.employeeFinancialsType1.find((f) => f.amount == null))) {
			this.isRequired = true;
		} else if (!isNullOrUndefined(this.employeeFinancialsType2.find((f) => f.amount == null))) {
			this.isRequired = true;
		} else {
			this.isRequired = false;
		}

		this.isSave = true;
	}

	closeDialogEvent() {
		this.closeEvent.emit();
	}
	closeDialog() {
		this.popup_Confirm = false;
	}
	isAuth: boolean = false;
	authStatus: boolean = false;
	receiveMessageAuth(event) {
		if (event) {
			this.authStatus = true;
			this.isShowSalary = !this.isShowSalary;

			this.payRollSharingService.setData(this.isShowSalary);
			this.typeAmount = this.isShowSalary ? "text" : "password";
		}
	}
	receiveMessageClose(event) {
		if (event) {
			this.isAuth = false;
		}
	}
	isLoadingTaxDetail: boolean = true;
	showSalary() {
		if (!this.authStatus) {
			this.isAuth = true;
			return;
		}
		this.isShowSalary = !this.isShowSalary;
		this.payRollSharingService.setData(this.isShowSalary);
		this.typeAmount = this.isShowSalary ? "text" : "password";
		this.isLoadingTaxDetail = true;
		this.loadTaxDetail();
	}

	resetShowSalay() {
		this.isShowSalary = false;
		this.typeAmount = "password";
	}

	updateValidator() {
		if (this.editType) {
			this.form.controls["dateCreated"].setValidators(Validators.required);
			this.form.controls["dateCreated"].updateValueAndValidity();
		} else {
			this.form.controls["dateCreated"].clearValidators();
			this.form.controls["dateCreated"].updateValueAndValidity();
		}
	}

	resetForm() {
		this.form.reset();
		this.date = new Date();
		this.form.controls["isEffective"].setValue(false);
		this.updateValidator();
	}

	async getCurrentPayrollPayment() {
		await this.paymentService.getCurrentPayrollPaymentByEmployeeId(this.id).then((res) => {
			this.currentPayrollPayment = res;
			if (!isNullOrUndefined(this.currentPayrollPayment)) {
				this.paymentPeriod = !isNullOrUndefined(this.paymentPeriodDDL)
					? this.paymentPeriodDDL.find(
							(f) =>
								f.year == this.currentPayrollPayment.year &&
								f.periodCalculateNo == this.currentPayrollPayment.periodCalculateNo,
						)
					: null;
			}

			if (isNullOrUndefined(this.paymentPeriod)) {
				this.paymentPeriod = !isNullOrUndefined(this.paymentPeriodDDL) ? this.paymentPeriodDDL[0] : null;
			}
		});
	}
	public isOnClose: boolean = false;
	onChangeCal(item, type: string = "") {
		if (!item.isCalculate) {
			item.amount = null;
		}
		if (!(this.isClosed || item.category == this.other)) {
			if (item.isCalculate && type != "") {
				document
					.getElementById(`${type}-input-calculate-${item.financialItemId}`)
					.getElementsByTagName("input")[0]
					.focus();
			}

			this.isOnClose = true;
			item.isCalculate = !item.isCalculate;
		}
	}
	//#endregion
	handleChange(event) {
		this.indexTab = event.index;
		var innerText = !isNullOrUndefined(event) ? this.tabGo5?.tab?.headerText?.trim() : "";
		switch (innerText) {
			case this.translate.instant("emp001_t14_payroll").trim():
				{
					this.changePath(this.isEmpNo, "salary");
				}
				break;
			case this.translate.instant("emp001_t14_financial").trim():
				{
					this.isShowFooter = true;
					this.netpay = this.sumType1 - this.sumType2;
				}
				break;
			case this.translate.instant("emp001_t14_overtime").trim():
				{
					this.isShowFooter = this.employeeFinancialsOT.length == 0 ? false : true;
					this.netpay = this.sumOT;
				}
				break;
			case this.translate.instant("emp001_t14_deducted_not_coming_work").trim():
				{
					this.isShowFooter = this.employeeFinancialsAbsenceDeduct.length == 0 ? false : true;
					this.netpay = this.sumAbsenceDeduct;
				}
				break;
			case this.translate.instant("emp001_t14_deducted_late_early").trim():
				{
					this.isShowFooter = this.employeeFinancialsLateDeduct.length == 0 ? false : true;
					this.netpay = this.sumLateDeduct;
				}
				break;
			case this.translate.instant("pay001_t03_accumulate_tab").trim():
				{
					this.changePath(this.isEmpNo, "salary/accumulate");
					this.tabAccumulate = true;
					this.isShowFooter = false;
				}
				break;
			case this.translate.instant("emp001_t19_loan").trim():
				{
					this.sharingService.pushGoogleTagManager("Employment_Details_Salary_Liabilities");
					this.changePath(this.isEmpNo, "salary/liabilities");
					this.tabLoan = true;
					this.isShowFooter = false;
				}
				break;
			case this.translate.instant("tab_allowance").trim():
				{
					this.changePath(this.isEmpNo, "salary-information");
					this.tabAllowance = true;
					this.isShowFooter = false;
				}
				break;
			case this.translate.instant("emp001_t15_tax").trim():
				{
					this.tabTax = true;
					this.isShowFooter = true;
					this.loadTaxDetail();
				}
				break;
			case this.translate.instant("emp001_social_security").trim():
				{
					this.sharingService.pushGoogleTagManager("Employment_Details_Salary_SocialSecurity");
					this.tabSSO = true;
				}
				break;
		}
	}

	changePath(empNo, key) {
		if (!this.isEmpViewProfile) return;
		this.router.navigateByUrl(`/employee/${empNo}/${key}`);
	}

	loadEmployeeFinancialTransaction(financialCategory) {
		this.employeeFinancialService
			.getEmployeeFinancialTransactionByCategory(
				this.id,
				this.year,
				this.periodCalculateNo,
				this.companyId,
				financialCategory,
			)
			.then((res) => {
				if (!isNullOrUndefined(res)) {
					switch (financialCategory) {
						case FinancialCategory.overtime:
							{
								this.employeeFinancialsOT = res;
								this.sumOT1 = res[0].amountOT1;
								this.sumOT2 = res[0].amountOT2;
								this.sumOT3 = res[0].amountOT3;
								this.sumOT4 = res[0].amountOT4;
								this.sumFixRateOT = res[0].amountFixRateOT;
								this.sumTimeOT1 = res[0].sumTotalHourOfOT1;
								this.sumTimeOT2 = res[0].sumTotalHourOfOT2;
								this.sumTimeOT3 = res[0].sumTotalHourOfOT3;
								this.sumTimeOT4 = res[0].sumTotalHourOfOT4;
								this.sumTimeFixRateOT = res[0].sumTotalHourOfFixRateOT;
								this.dateCycleCalOT = res[0].dateCycleCal;
								this.sumOT = res[0].amount;
							}
							break;
						case FinancialCategory.absenceDeduct:
							{
								this.employeeFinancialsAbsenceDeduct = res;
								this.dateCycleCalAbsenceDeduct = res[0].dateCycleCal;
								this.sumAbsenceDeduct = res[0].amount;
							}
							break;
						case FinancialCategory.lateDeduct:
							{
								this.employeeFinancialsLateDeduct = res;
								this.dateCycleCalLateDeduct = res[0].dateCycleCal;
								this.sumLateDeduct = res[0].amount;
							}
							break;
					}
				}
			});
	}

	counter(i: number) {
		return new Array(i);
	}

	changeTab(category) {
		switch (category) {
			case this.financialCategoryOvertime:
				{
					this.isShowFooter = this.employeeFinancialsOT.length == 0 ? false : true;
					this.netpay = this.sumOT;
					this.indexTab = 2;
				}
				break;
			case this.financialCategoryAbsenceDeduct:
				{
					this.isShowFooter = this.employeeFinancialsAbsenceDeduct.length == 0 ? false : true;
					this.netpay = this.sumAbsenceDeduct;
					this.indexTab = 3;
				}
				break;
			case this.financialCategoryLateDeduct:
				{
					this.isShowFooter = this.employeeFinancialsLateDeduct.length == 0 ? false : true;
					this.netpay = this.sumLateDeduct;
					this.indexTab = 4;
				}
				break;
		}
	}
	onFocusInputIncome(i) {
		document.getElementById(`icon-calculate-manual-close-left-${i}`)?.classList.add("active-button");
		document.getElementById(`income-icon-calculate-open-${i}`)?.classList.add("opacity-1");
		document.getElementById(`income-icon-calculate-open-${i}`)?.classList.add("icon-calculate-open-manual-active");
	}
	onBlurInputIncome(i, item) {
		document.getElementById(`icon-calculate-manual-close-left-${i}`)?.classList.remove("active-button");
		document.getElementById(`income-icon-calculate-open-${i}`)?.classList.remove("opacity-1");
		document.getElementById(`income-icon-calculate-open-${i}`)?.classList.remove("icon-calculate-open-manual-active");
		if (item.amount > 0 && !this.isOnClose) {
			item.isCalculate = false;
		}
	}

	onFocusInputDeduct(i) {
		document.getElementById(`icon-calculate-manual-close-right-${i}`)?.classList.add("active-button");
		document.getElementById(`deduct-icon-calculate-open-${i}`)?.classList.add("opacity-1");
		document.getElementById(`deduct-icon-calculate-open-${i}`)?.classList.add("icon-calculate-open-manual-active");
	}
	onBlurInputDeduct(i, item) {
		document.getElementById(`icon-calculate-manual-close-right-${i}`)?.classList.remove("active-button");
		document.getElementById(`deduct-icon-calculate-open-${i}`)?.classList.remove("opacity-1");
		document.getElementById(`deduct-icon-calculate-open-${i}`)?.classList.remove("icon-calculate-open-manual-active");
		if (item.amount > 0 && !this.isOnClose) {
			item.isCalculate = false;
		}
	}
}
