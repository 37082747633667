export enum LeaveCategory {
	leave = 1,
	endorse = 2,
	outside = 3,
	exchange = 4,
	collect = 5,
	ot = 6,
	exchangeCollect = 7,
	training = 8,
	leaveWithoutPay = 23,
	additionLeaveBenefitCompensation = 44,
	deductionLeaveBenefitCompensation = 45,
	holidayAdjustmentTab = 999,
	documentForm = 1000,
	welfare = 1001,
	swapShift = 1002,
	changeShift = 1003,
	welfareAdjustRequest = 1004,
}
export enum LeaveStatus {
	draft = 1,
	waitingApprove = 2,
	waitingRevise = 3,
	approveSuccess = 4,
	canceled = 5,
	rejected = 6,
}

export enum LeaveCategoryMSS {
	categoryLeave = 101,
	categoryEndorse = 102,
	categoryOutside = 103,
	categoryNormal = 201,
	categoryAbsent = 202,
	categoryLate = 203,
	categoryEarly = 204,
	categoryNoStampIn = 205,
	categoryNoStampOut = 206,
	categoryHoliday = 207,
	categoryHolidayWork = 208,
	categoryDoubleShift = 210,
	categoryWorking = 301,
	categoryOnTheWay = 302,
	categoryConsecutiveAbsence = 303,
}

export enum LeaveRequestSubCode {
	ShiftsNotHasOT = "ELV1024",
	OverTimeLimit = "ELV1025",
}
