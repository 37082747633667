import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable()
export class LeaveExchangeService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getAbnormalTime() {
		return firstValueFrom(this.http.get(this.apiUrl + "TimeAttendance/GetAbnormalTimeByUserId")).then(
			(res) => <any[]>res,
		);
	}

	getExchangeableTime() {
		return firstValueFrom(this.http.get(this.apiUrl + "TimeAttendance/GetExchangeableTimeByUserId")).then(
			(res) => <any[]>res,
		);
	}

	getAbnormalTimeByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "TimeAttendance/GetAbnormalTimeByEmployeeId/" + employeeId)).then(
			(res) => <any[]>res,
		);
	}

	getExchangeableTimeByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "TimeAttendance/GetExchangeableTimeByEmployeeId/" + employeeId),
		).then((res) => <any[]>res);
	}
}
