import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SocialSecurityRate } from "./social-security-rate.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class SocialSecurityRateService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getAllSocialSecurityRate(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "SocialSecurityRate/GetAllSocialSecurityRate/" + companyId)).then(
			(res) => <SocialSecurityRate[]>res,
		);
	}

	getSocialSecurityRateById(socialSecurityRateId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "SocialSecurityRate/GetSocialSecurityRateById/" + socialSecurityRateId),
		).then((res) => <SocialSecurityRate>res);
	}

	addSocialSecurityRate(ssr: SocialSecurityRate) {
		var body = JSON.stringify(ssr);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "SocialSecurityRate/AddSocialSecurityRate", body, httpOptions);
	}

	editSocialSecurityRate(ssr: SocialSecurityRate[]) {
		var body = JSON.stringify(ssr);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "SocialSecurityRate/EditSocialSecurityRate", body, httpOptions);
	}

	deleteSocialSecurityRate(socialSecurityRateId: number) {
		var body = JSON.stringify(socialSecurityRateId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "SocialSecurityRate/DeleteSocialSecurityRate", body, httpOptions);
	}
}
