export class EmployeeLoan {
	loanId: number;
	documentNo: string;
	financialItemId: number;
	employeeId: number;
	loanAmount: number;
	feeAmount: number;
	periodCalculateNo: number;
	dateOfPeriodCalculateNo: Date;
	year: number;
	monthlyPaymentPeriod: number;
	paymentPerPeriod: number;
	lastPaymentPeriod: number;
	paymentPaidAmount: number = 0;
	refundAmount: number;
	status: number;
	interestType: number;
	interestRatePerYear: number;
	remark: string;
	isDelete: boolean;
	isClosed: boolean;
	dateClosed: Date;
	createdBy: string;
	dateCreated: Date;
	modifiedBy: string;
	dateModified: Date;
	interestAmount: number;
	financialItemName: string;
	financialItemName_EN: string;
	username: string;
	dateModified_TH: string;
	dateModified_EN: string;
	fullname: string;
	fullname_EN: string;
	positionName: string;
	positionName_EN: string;
	status_TH: string;
	status_EN: string;
	interestTypeName: string;
	interestTypeName_EN: string;
	createdByName: string;
	createdByName_EN: string;
}
