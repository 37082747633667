<div class="bg-default" [ngClass]="{ 'empeo-dragging': dragging }">
	<div #headerTable class="empeo-table-wrapper empeo-table-header" [style.top]="top()">
		@if (frozenColumnLeft()?.length) {
			<div class="empeo-table-header-frozen-wrapper" [style.width]="frozenWidth()">
				<table class="empeo-table empeo-table-header-frozen">
					<colgroup>
						@for (col of frozenColumnLeft(); track $index) {
							<col [style.width]="col.width" />
						}
					</colgroup>
					<thead class="empeo-thead">
						<tr [style.height]="headerHeight()">
							<ng-container
								[ngTemplateOutlet]="headerTemplate"
								[ngTemplateOutletContext]="{ $implicit: frozenColumnLeft(), freeze: true, left: true }"
							>
							</ng-container>
						</tr>
					</thead>
				</table>
			</div>
		}

		@if (columns()?.length) {
			<div
				#headerUnfrozen
				class="empeo-table-header-unfrozen-wrapper"
				[style.width]="'calc(100% - ' + frozenWidth() + ')'"
			>
				<table class="empeo-table empeo-table-header-unfrozen">
					<colgroup>
						@for (col of columns(); track trackByHeaderFn) {
							<col [style.width]="col.width" />
						}
					</colgroup>
					<thead class="empeo-thead">
						<tr [style.height]="headerHeight()">
							<ng-container
								[ngTemplateOutlet]="headerTemplate"
								[ngTemplateOutletContext]="{ $implicit: columns(), freeze: false, customColumns: customColumns() }"
							>
							</ng-container>
						</tr>
					</thead>
				</table>
			</div>
		}
		@if (frozenColumnRight()?.length) {
			<div class="empeo-table-header-frozen-wrapper" [style.width]="frozenColumnRightWidth() + 'px'">
				<table class="empeo-table empeo-table-header-frozen">
					<colgroup>
						@for (col of frozenColumnRight(); track $index) {
							<col [style.width]="col.width" />
						}
					</colgroup>
					<thead class="empeo-thead">
						<tr [style.height]="headerHeight()">
							<ng-container
								[ngTemplateOutlet]="headerTemplate"
								[ngTemplateOutletContext]="{ $implicit: frozenColumnRight(), freeze: true, left: false }"
							>
							</ng-container>
						</tr>
					</thead>
				</table>
			</div>
		}
	</div>
	<div class="empeo-table-wrapper" #tableWrapper>
		<div
			empeoVirtualScroll
			#bodyFrozen
			class="empeo-table-body-frozen-wrapper"
			[style.width]="frozenWidth()"
			[itemSize]="height()"
		>
			<cdk-virtual-scroll-viewport scrollWindow [itemSize]="height()" [minBufferPx]="height()">
				<table class="empeo-table empeo-table-body-frozen">
					<colgroup>
						@for (col of frozenColumnLeft(); track $index) {
							<col [style.width]="col.width" />
						}
					</colgroup>
					<tbody virtualHeader class="empeo-tbody">
						<ng-container *cdkVirtualFor="let data of dataSources(); let rowIndex = index; trackBy: trackByFn">
							<tr
								(mouseenter)="onMouseOver(null, rowIndex)"
								(mouseleave)="onMouseOver(null, null)"
								[ngClass]="{ 'empeo-hover-table': rowIndex === index }"
								[style.height.px]="height()"
								[id]="rowIndex"
							>
								<ng-container
									*ngTemplateOutlet="
										bodyTemplate;
										context: {
											$implicit: data,
											rowIndex: rowIndex,
											columns: frozenColumnLeft(),
											isHover: rowIndex === index,
											freeze: true,
											dragging: dragging,
											isLeft: true
										}
									"
								>
								</ng-container>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</cdk-virtual-scroll-viewport>
		</div>

		<div
			#bodyUnfrozen
			empeoVirtualScroll
			[itemSize]="height()"
			class="empeo-table-body-unfrozen-wrapper"
			id="scrollable1"
			[style.width]="'calc(100% - ' + allWidthBody() + 'px)'"
			(scroll)="onScroll($event)"
		>
			<cdk-virtual-scroll-viewport scrollWindow [itemSize]="height()" [minBufferPx]="height()">
				<table class="empeo-table empeo-table-body-unfrozen">
					<colgroup>
						@for (col of columns(); track trackByHeaderFn) {
							<col [style.width]="col.width" />
						}
					</colgroup>
					<tbody virtualHeader class="empeo-tbody">
						<ng-container *cdkVirtualFor="let data of dataSources(); let rowIndex = index; trackBy: trackByFn">
							<tr
								(mouseenter)="onMouseOver(null, rowIndex)"
								(mouseleave)="onMouseOver(null, null)"
								[ngClass]="{ 'empeo-hover-table': rowIndex === index }"
								[style.height.px]="height()"
								[id]="rowIndex"
							>
								<ng-container
									*ngTemplateOutlet="
										bodyTemplate;
										context: {
											$implicit: data,
											rowIndex: rowIndex,
											columns: columns(),
											isHover: rowIndex === index,
											freeze: false,
											dragging: dragging,
											customColumns: customColumns()
										}
									"
								>
								</ng-container>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</cdk-virtual-scroll-viewport>
		</div>
		<div
			empeoVirtualScroll
			#bodyFrozen
			class="empeo-table-body-frozen-wrapper"
			[style.width]="frozenColumnRightWidth() + 'px'"
			[itemSize]="height()"
		>
			<cdk-virtual-scroll-viewport scrollWindow [itemSize]="height()" [minBufferPx]="height()">
				<table class="empeo-table empeo-table-body-frozen-right">
					<colgroup>
						@for (col of frozenColumnRight(); track $index) {
							<col [style.width]="col.width" />
						}
					</colgroup>
					<tbody virtualHeader class="empeo-tbody">
						<ng-container *cdkVirtualFor="let data of dataSources(); let rowIndex = index; trackBy: trackByFn">
							<tr
								(mouseenter)="onMouseOver(null, rowIndex)"
								(mouseleave)="onMouseOver(null, null)"
								[ngClass]="{ 'empeo-hover-table': rowIndex === index }"
								[style.height.px]="height()"
								[id]="rowIndex"
							>
								<ng-container
									*ngTemplateOutlet="
										bodyTemplate;
										context: {
											$implicit: data,
											rowIndex: rowIndex,
											columns: frozenColumnRight(),
											isHover: rowIndex === index,
											freeze: true,
											dragging: dragging,
											isLeft: false
										}
									"
								>
								</ng-container>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
	@if (footerTemplate) {
		<div #footerWrapper class="empeo-table-wrapper empeo-table-footer-wrapper">
			@if (frozenColumnLeft()?.length) {
				<div class="empeo-table-footer-frozen-wrapper" [style.width]="frozenWidth()">
					<table class="empeo-table empeo-table-footer-frozen">
						<colgroup>
							@for (col of frozenColumnLeft(); track $index) {
								<col [style.width]="col.width" />
							}
						</colgroup>
						<thead>
							<tr [style.height]="headerHeight()">
								<ng-container
									[ngTemplateOutlet]="footerTemplate"
									[ngTemplateOutletContext]="{ $implicit: frozenColumnLeft(), freeze: true }"
								>
								</ng-container>
							</tr>
						</thead>
					</table>
				</div>
			}
			@if (columns()?.length) {
				<div
					class="empeo-table-footer-unfrozen-wrapper"
					[style.width]="'calc(100% - ' + frozenWidth() + ')'"
					#footerUnfrozen
				>
					<table class="empeo-table empeo-table-footer-unfrozen">
						<colgroup>
							@for (col of columns(); track trackByHeaderFn) {
								<col [style.width]="col.width" />
							}
						</colgroup>
						<thead>
							<tr [style.height]="headerHeight()">
								<ng-container
									[ngTemplateOutlet]="footerTemplate"
									[ngTemplateOutletContext]="{ $implicit: columns(), freeze: false }"
								>
								</ng-container>
							</tr>
						</thead>
					</table>
				</div>
			}
			@if (frozenColumnRight()?.length) {
				<div class="empeo-table-footer-frozen-wrapper" [style.width]="frozenColumnRightWidth() + 'px'">
					<table class="empeo-table empeo-table-footer-frozen">
						<colgroup>
							@for (col of frozenColumnRight(); track $index) {
								<col [style.width]="col.width" />
							}
						</colgroup>
						<thead>
							<tr [style.height]="headerHeight()">
								<ng-container
									[ngTemplateOutlet]="footerTemplate"
									[ngTemplateOutletContext]="{ $implicit: frozenColumnRight(), freeze: true }"
								>
								</ng-container>
							</tr>
						</thead>
					</table>
				</div>
			}
		</div>
	}

	<div
		class="empeo-scroll-table"
		#scrollElement
		id="scrollable2"
		[style.margin-left]="frozenWidth()"
		[style.margin-right]="frozenColumnRightWidth() + 'px'"
		[style.bottom]="scrollBottom()"
		[style.width]="'calc(100% - ' + allWidthBody() + 'px)'"
		(scroll)="onScroll($event)"
	>
		<div
			class="empeo-scroll-template"
			[style.width.px]="bodyUnfrozenRef()?.nativeElement?.children[0]?.children[0]?.offsetWidth ?? 0"
		></div>
	</div>
</div>
