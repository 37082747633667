import {
	CUSTOM_ELEMENTS_SCHEMA,
	Component,
	EventEmitter,
	Injector,
	Input,
	OnDestroy,
	Output,
	SimpleChanges,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { GofiveCoreModuleEnum } from "../../enum/module.enum";
import { BaseMicroComponent } from "../base-micro";
import { SharingService } from "../../sharing.service";
import { RouteURL } from "../../enum/route-path.enum";
import { AssessmentType } from "../../enum/assessment-type.enum";
import { Subscription, filter } from "rxjs";
import { URLEncoderService } from "../../service/url-encoder.service";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { LazyElementsModule } from "@angular-extensions/elements";

import { AssessmentModel } from "src/app/assessment/shared/assessment.model";
import { AssessmentService } from "src/app/assessment/shared/assessment.service";
import { EmployeeMovementsService } from "src/app/employee-movements/shared/employee-movements.service";
import { AvatarModule } from "@gofive/design-system-avatar";
import { DataSharingService } from "src/app/core";
import { AsyncPipe, NgStyle } from "@angular/common";
import { AngularCommonModule } from "@gofive/angular-common";
import { TooltipModule } from "@gofive/design-system-tooltip";
@Component({
	selector: "gfc-employee-assessment",
	templateUrl: "./employee-assessment.component.html",
	styleUrls: ["./employee-assessment.component.scss"],
	standalone: true,
	imports: [
		LazyElementsModule,
		NgxSkeletonLoaderModule,
		AvatarModule,
		AsyncPipe,
		AngularCommonModule,
		TooltipModule,
		NgStyle,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EmployeeAssessmentComponent extends BaseMicroComponent implements OnDestroy {
	@Input() recruitmentModel: any = null;
	@Input() tokenParams: any = null;
	@Input() employeeId: number;
	@Input() tabAssessmentView: boolean;
	@Input() scoreId: number = 0;
	@Input() scoreNo: number = 0;
	@Input() setId: number = 0;
	@Input() assessmentTypeId: number = 0;
	@Input() assessmentId: number = 0;
	@Input() isEmpeo: boolean = false;
	@Input() isOpenHeader: boolean = false;
	@Input() isShowHeader: boolean = true;
	@Input() headerTabIndex: number = 0;
	@Input() isDialog: boolean = false;
	@Output() isDeleteAssessment = new EventEmitter<boolean>(false);
	@Output() activeCardData = new EventEmitter<any>();
	@Input() indexTab: number = 0;
	@Input() activeCard: any;
	@Input() isWebView: boolean = false;
	@Input() allowChangeRoute: boolean = false;
	@Input() isShowDetail: boolean = false;

	override module = GofiveCoreModuleEnum.emconnect;
	public currentEmployee: any;

	private subscription$: Subscription;

	public currentURL: string = "";
	constructor(
		public injector: Injector,
		public router: Router,
		public sharingService: SharingService,
		public dataSharingService: DataSharingService,
		private assessmentService: AssessmentService,
		private urlEncoder: URLEncoderService,
		private employeeMovementsService: EmployeeMovementsService,
	) {
		super(injector);
		const searchTerm = /probation|exit|job-application|annual/;
		if (searchTerm.test(this.router.url)) {
			this.currentURL = decodeURIComponent(this.replaceAssessmentType(this.router.url));
		}
		this.subscription$ = router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.currentURL = decodeURIComponent(this.replaceAssessmentType(event.url));
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.employeeId?.currentValue >= 0) {
			this.loadEmployee();
		}
	}

	public receiveRouterEvent(e) {
		this.router.navigateByUrl("/" + e.detail.routerName);
	}

	receiveConvertRouterEvent(e) {
		this.dataSharing.applicantConverting = e.detail;
		this.router.navigate(["/employee-add"]);
	}

	onEditCalibration(e) {
		this.sharingService.pushGoogleTagManager("assessment_calibration_edit_asm_result");
		if (e.detail.employeeAssessment) this.dataSharing.setAssessment(e.detail.employeeAssessment);
		if (!this.isWebView) this.router.navigate([`/pms/${e.detail.assessmentSet.name}/calibration/${e.detail.no ?? 0}`]);
	}

	receiveSuccessDeleteEvent(event) {
		if (event.detail) {
			this.isDeleteAssessment.emit(true);
		}
	}

	routerChange(event) {
		if (this.allowChangeRoute) {
			switch (event.detail.assessmentTypeId) {
				case AssessmentType.assessmentProbation:
					this.router.navigateByUrl(
						`pms/${event.detail.currentAssessee?.assessorEmployeeNo}/probation/${this.getAssessmentType(
							event.detail.currentAssessee,
						)}/${event.detail.no}`,
					);
					break;
				case AssessmentType.assessmentJob:
				case AssessmentType.candidate:
					if (event.detail.assessmentType.assessmentTypeId === AssessmentType.candidate) {
						this.router.navigateByUrl(`pms/${event.detail.currentAssessee?.assessorApplicantNo}/job-application`);
					} else {
						this.router.navigateByUrl(
							`pms/${event.detail.currentAssessee?.assessorApplicantNo}/job-application/exam/${event.detail.currentPositionId}/${event.detail.currentAssessmentSetId}`,
						);
					}
					break;
				case AssessmentType.separationAssessment:
					this.router.navigateByUrl("pms/exit/me");
					break;
				default:
					const encodedURL = this.urlEncoder.encodeURL(event.detail.currentAssessee.setName);
					this.router.navigateByUrl(
						`pms/${event.detail.currentAssessee?.assessorEmployeeNo}/annual/${
							event.detail.year
						}/${this.getAssessmentType(event.detail.currentAssessee)}/${encodedURL}/${event.detail.no}`,
					);
					break;
			}
		}
	}

	replaceAssessmentType(url: string) {
		let tmpURL = url.replace(
			/Supervisor-Assessment|Subordinate-Feedback\d+/,
			AssessmentType.assessmentSupervisor.toString(),
		);
		tmpURL = tmpURL.replace(
			/Subordinate-Assessment|Supervisor-Feedback/g,
			AssessmentType.assessmentSubordinate.toString(),
		);
		tmpURL = tmpURL.replace(/Peer-Assessment|Peer-Feedback/g, AssessmentType.assessmentPeerReview.toString());
		tmpURL = tmpURL.replace(/Self-Assessment/g, AssessmentType.assessmentSelf.toString());
		tmpURL = tmpURL.replace(/Supervisor-Feedback/g, AssessmentType.assessmentHeadSubordinate.toString());
		tmpURL = tmpURL.replace(/Exam/g, AssessmentType.assessmentProbationExam.toString());
		tmpURL = tmpURL.replace(/Organization-Assessment/g, AssessmentType.assessmentOrganization.toString());
		return tmpURL;
	}

	getAssessmentType(model) {
		let supervisor =
			model.assessmentType == AssessmentType.assessmentSupervisor && model.isOwnAssessment
				? "Supervisor-Assessment"
				: "Subordinate-Feedback";
		let peer = model.isOwnAssessment ? "Peer-Assessment" : "Peer-Feedback";
		let sub = model.isOwnAssessment ? "Subordinate-Assessment" : "Supervisor-Feedback";
		return model.assessmentType == AssessmentType.assessmentSelf
			? "Self-Assessment"
			: model.assessmentType == AssessmentType.assessmentSupervisor
				? supervisor
				: model.assessmentType == AssessmentType.assessmentPeerReview
					? peer
					: model.assessmentType == AssessmentType.assessmentSubordinate
						? sub
						: model.assessmentType == AssessmentType.assessmentHeadSubordinate
							? "Supervisor-Feedback"
							: model.assessmentType == AssessmentType.assessmentProbationExam
								? "Exam"
								: "Organization-Assessment";
	}

	getPageNotFound(event) {
		let isPageNotFound = event?.detail;
		if (isPageNotFound) {
			this.router.navigate(["/" + RouteURL.pageNotFound]);
		}
	}

	ngOnDestroy(): void {
		this.subscription$?.unsubscribe();
	}
	getAssessmentModel(event: AssessmentModel) {
		let assessment = event;
		let assessee = new Array();
		assessment.assessmentTypeId = AssessmentType.assessmentPeerReview;
		assessee.push(assessment.assesseeEmployeeId);
		if (assessment?.scoreId != 0) {
			window.open(
				`assessment-form?assessmentId=${assessment.assessmentId}&assessee=${assessment.assesseeEmployeeId}&masterSetId=${assessment.masterSetId}&no=${assessment.no}&assessor=${assessment.assessorEmployeeId}&assessmentTypeId=${assessment.assessmentTypeId}`,
			);
		} else {
			let assessmentScore = {
				assessorEmployeeId: assessment.assessorEmployeeId,
				masterSetId: assessment.masterSetId,
				no: assessment.no,
				assessmentTypeId: AssessmentType.assessmentPeerReview,
				assesseeEmployeeIds: assessee,
				assessmentId: assessment.assessmentId,
			};

			this.assessmentService.addAssessmentScore(assessmentScore).subscribe((res) => {
				assessment.assessmentTypeId = AssessmentType.assessmentPeerReview;
				this.dataSharing.setAssessment(assessment);
				window.open(
					`assessment-form?assessmentId=${assessment.assessmentId}&assessee=${assessment.assesseeEmployeeId}&masterSetId=${assessment.masterSetId}&no=${assessment.no}&assessor=${assessment.assessorEmployeeId}&assessmentTypeId=${assessment.assessmentTypeId}`,
				);
			});
		}
	}

	public employee: any;
	loadEmployee() {
		if (this.isShowDetail) {
			this.employeeMovementsService.getEmployeeById(this.employeeId).then((res) => {
				this.employee = res;
				this.getOrgName(res);
			});
		}
	}
	orgName: string = "";
	orgName_EN: string = "";
	getOrgName(employee) {
		this.orgName =
			employee.orgLevelOneName +
			this.getHtmlArrow(employee.orgLevelTwoName) +
			this.getHtmlArrow(employee.orgLevelThreeName) +
			this.getHtmlArrow(employee.orgLevelFourName) +
			this.getHtmlArrow(employee.orgLevelFiveName) +
			this.getHtmlArrow(employee.orgLevelSixName);
		this.orgName_EN =
			employee.orgLevelOneName_EN +
			this.getHtmlArrow(employee.orgLevelTwoName_EN) +
			this.getHtmlArrow(employee.orgLevelThreeName_EN) +
			this.getHtmlArrow(employee.orgLevelFourName_EN) +
			this.getHtmlArrow(employee.orgLevelFiveName_EN) +
			this.getHtmlArrow(employee.orgLevelSixName_EN);
	}

	getHtmlArrow(text?: string) {
		return text && text !== "-" ? '<span class="gf-icon-right"></span>' + text : "";
	}
}
