export class FinancialItem {
	financialItemId: number;
	type: number;
	code: string;
	name: string;
	name_EN: string;
	roundingCode: string;
	periodType: number;
	accountType: number;
	accountCodeType: number;
	isSOC: boolean;
	isPF: boolean;
	isOT: boolean;
	isAT: boolean;
	isTax: boolean;
	isOnceWT: boolean;
	isRegular: boolean;
	isBaseSalary: boolean;
	isCurrentPeriod: boolean;
	isActive: boolean;
	isDelete: boolean;
	companyId: number;
	wtValue: number;
	category?: number;
	createdBy: string;
	modifiedBy: string;
	dateCreated: Date;
	dateModified: Date;
	displayName: string;
	displayName_EN: string;
	typeName: string;
	dateModified_TH: string;
	dateModified_EN: string;
	periodType_TH: string;
	periodType_EN: string;
	status: string;
	status_EN: string;
	withholdingTaxPercentage: number;
	incomeType: number;
	isOncePay: boolean;
	isExtendPeriod: boolean;
	codeManufactory: string;
	codeOffice: string;
	codeSupport: string;
	accountGroups: MasAccountGroup[];
	accountCodeTypeName: string;
	incomeTypeName: string;
}

export class MasAccountGroup {
	accountGroupId: number;
	name: string;
	name_EN: string;
	isActive: boolean;
	tenantId: string;
	financialItemId: number;
	code: string;
	status: string;
	status_EN: string;
	displayName: string;
	dateModified_TH: string;
	dateModified_EN: string;
	isDelete: boolean;
}
