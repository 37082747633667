import { Pipe, PipeTransform } from "@angular/core";
import { SharingService } from "../sharing.service";

@Pipe({
	name: "fieldsDDL",
	standalone: true,
})
export class ConvertfieldsDDLPipe implements PipeTransform {
	constructor(private sharing: SharingService) {}

	transform(localize: string = "th", fields: { [key: string]: Object } = { text: "name", value: "id" }) {
		return this.sharing.convertfields(localize, fields);
	}
}
