export enum PayrollStatus {
	WaitingForThePaymentPeriod = -2,
	cancel = -1,
	waitingForCalculation = 1,
	waitingForClosingPeriod = 2,
	waitingToPay = 3,
	paid = 4,
	waitingApprove = 5,
	waitingVerify = 6,
}
