import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class EditHistoryLogService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getEditHistoryLogs(editHistoryLogsSearchModel) {
		var body = JSON.stringify(editHistoryLogsSearchModel);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "history-log/list", body, httpOptions);
	}
}
