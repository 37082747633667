//Lazy Routes & loading chunk failed or other error

import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	handleError(error: any): void {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		const pattern: RegExp = /\b(?:null|undefined)\b/g;
		const message = error?.message ?? error;
		if (chunkFailedMessage.test(message)) {
			window.location.reload();
			console.error("Found new updated version and now reload is successful!");
		} else if ((!environment.production && error) || pattern.test(message)) {
			console.error(error);
		}
	}
}
