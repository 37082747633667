import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { authConfig } from "./auth-config";
import { SessionStorageKey } from "../shared/enum/local-storage-key";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
	public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

	private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
	public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

	private isAuthorizedSubject$ = new BehaviorSubject<boolean>(false);
	public isAuthorized$ = this.isAuthorizedSubject$.asObservable();

	private isUserinfoLoading = false;
	private isUserinfoLoadedSubject$ = new BehaviorSubject<boolean>(false);
	public isUserinfoLoaded$ = this.isUserinfoLoadedSubject$.asObservable();

	private userInfo: any = null;
	private userInfoSubject$ = new BehaviorSubject<any>(this.userInfo);
	public userInfo$ = this.userInfoSubject$.asObservable();

	public isPhraseLoadedSubject$ = new BehaviorSubject<boolean>(false);
	public isPhraseLoaded$ = this.isPhraseLoadedSubject$.asObservable();

	public get currentUser() {
		return this.userInfo;
	}

	private isAuthenByQueryString = false;

	/**
	 * Publishes `true` if and only if (a) all the asynchronous initial
	 * login calls have completed or errorred, and (b) the user ended up
	 * being authenticated.
	 *
	 * In essence, it combines:
	 *
	 * - the latest known state of whether the user is authorized
	 * - whether the ajax calls for initial log in have all been done
	 */
	public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
		this.isAuthenticated$,
		this.isDoneLoading$,
	]).pipe(map((values) => values.every((b) => b)));

	constructor(
		private oauthService: OAuthService,
		private router: Router,
	) {
		window.addEventListener("storage", (event) => {
			// The `key` is `null` if the event was caused by `.clear()`

			if (event.key === SessionStorageKey.source_user_id && event.newValue == null) {
				sessionStorage.removeItem(SessionStorageKey.access_token);
			}
			if (
				event.key !== SessionStorageKey.source_user_id &&
				event.key !== SessionStorageKey.access_token &&
				event.key !== null
			) {
				return;
			}
			this.checkIsAccessTokenChanged();
		});
		this.oauthService.events.subscribe((e) => {
			if (e.type === SessionStorageKey.token_expires) {
				this.isAuthenticatedSubject$.next(false);
			} else {
				this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
			}
		});

		this.oauthService.events
			.pipe(filter((e) => [SessionStorageKey.token_refreshed.toString()].includes(e.type)))
			.subscribe(() => {
				this.loadUserInfo();
			});
		this.oauthService.events
			.pipe(
				filter((e) =>
					[
						"session_terminated",
						"session_error",
						"invalid_nonce_in_state",
						"token_refresh_error",
						"user_profile_load_error",
						"silent_refresh_timeout",
					].includes(e.type),
				),
			)
			.subscribe((e) => {
				if (e.type === "invalid_nonce_in_state") {
					this.login();
				} else if (e.type === "user_profile_load_error") {
					this.logout();
				}
			});
		this.userInfo$.subscribe((user) => (this.userInfo = user));
	}

	public runInitialLoginSequence(): Promise<void> {
		authConfig.redirectUri = environment.redirectUri;
		authConfig.postLogoutRedirectUri = environment.redirectUri;
		authConfig.customQueryParams = {
			invitation_code: environment.subDomain,
		};

		this.oauthService.configure(authConfig);

		// 0. LOAD CONFIG:
		// First we have to check to see how the IdServer is
		// currently configured:

		return (
			this.oauthService
				.loadDiscoveryDocument()

				// 1. HASH LOGIN:
				// Try to log in via hash fragment after redirect back
				// from IdServer from initImplicitFlow:
				.then(() => {
					this.oauthService.tryLogin({
						preventClearHashAfterLogin: true,
					});
				})
				.then(() => {
					if (this.oauthService.hasValidAccessToken()) {
						if (!this.oauthService.hasValidAccessToken()) {
							this.refresh();
						} else {
							return this.loadUserInfo();
						}
					} else {
						if (this.oauthService.getRefreshToken()) {
							return this.loadUserInfo();
						}
					}
					return Promise.resolve();
				})
				.then(() => {
					this.isDoneLoadingSubject$.next(true);
					// Check for the strings 'undefined' and 'null' just to be sure. Our current
					// login(...) should never have this, but in case someone ever calls
					// initImplicitFlow(undefined | null) this could happen.
					if (
						this.oauthService.state &&
						this.oauthService.state !== "undefined" &&
						this.oauthService.state !== "null"
					) {
						if (this.router.url === "/unauthorized") {
							return;
						}
						const callbackUrl = decodeURIComponent(this.oauthService.state);
						this.router.navigate([callbackUrl]);
					}
				})
				.catch(() => {
					this.isDoneLoadingSubject$.next(true);
				})
		);
	}

	public async loadUserInfo(): Promise<any> {
		if (this.isUserinfoLoading) {
			return Promise.resolve();
		}
		this.isUserinfoLoading = true;
		try {
			const user = await this.oauthService.loadUserProfile();
			if (!user || Object.keys(user).length === 0) {
				return Promise.reject();
			}
			this.userInfoSubject$.next(user);
			this.isUserinfoLoadedSubject$.next(true);
			this.isAuthorizedSubject$.next(true);
			return Promise.resolve();
		} catch (error) {
			this.userInfoSubject$.next(null);
			this.isUserinfoLoadedSubject$.next(false);
			return Promise.reject();
		} finally {
			this.isUserinfoLoading = false;
		}
	}

	public get isAuthenByQuery() {
		return this.isAuthenByQueryString || false;
	}
	public login(targetUrl?: string) {
		if (targetUrl) {
			const hasAccessToken = targetUrl.indexOf("access_token=");
			const hasRefreshToken = targetUrl.indexOf("refresh_token=");
			const hasToken = targetUrl.indexOf("token=");
			const absuluteUrl = new URL(environment.redirectUri + targetUrl);
			let accessToken: string | null = null;
			let refreshToken: string | null = null;
			if (hasAccessToken > -1) {
				accessToken = absuluteUrl.searchParams.get(SessionStorageKey.access_token);
			}
			if (hasRefreshToken > -1) {
				refreshToken = absuluteUrl.searchParams.get("refresh_token");
			} else if (hasToken > -1) {
				accessToken = absuluteUrl.searchParams.get("token");
			}
			if (accessToken) {
				this.isAuthenByQueryString = true;
				sessionStorage.clear();
				sessionStorage.setItem(SessionStorageKey.access_token, accessToken);
				this.isAuthenticatedSubject$.next(true);
				return this.loadUserInfo();
			}
			if (refreshToken) {
				sessionStorage.setItem("refresh_token", refreshToken);
				return this.oauthService.refreshToken().then(() => window.location.reload());
			}
		}
		this.oauthService.initImplicitFlow(targetUrl);
		return Promise.resolve();
	}

	public get accessToken() {
		return this.oauthService.getAccessToken();
	}
	public logout() {
		this.oauthService.logOut();
		this.isAuthorizedSubject$.next(false);
	}
	public refresh() {
		return this.oauthService.silentRefresh();
	}
	public hasValidToken() {
		return this.oauthService.hasValidAccessToken();
	}

	public get identityClaims() {
		return this.oauthService.getIdentityClaims();
	}
	public get idToken() {
		return this.oauthService.getIdToken();
	}
	public get logoutUrl() {
		return this.oauthService.logoutUrl;
	}

	public checkIsAccessTokenChanged() {
		console.warn("Noticed changes to access_token (most likely from another tab)");

		if (!this.oauthService.hasValidAccessToken()) {
			this.navigateToLoginPage();
		}
	}

	private navigateToLoginPage() {
		this.login();
	}

	public signUp() {
		window.location.href = `${environment.issuer}Account/Register?${this.toQueryString(authConfig)}`;
	}

	private toQueryString(queryParams: any) {
		let encodedQueryParams = [];
		for (let key in queryParams) {
			encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
		}
		return encodedQueryParams.join("&");
	}
}
