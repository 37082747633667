import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

@Injectable()
export class EmployeeApplicantService {
	constructor(
		private http: HttpClient,
		@Inject("EMCONNECT_API_URL") private emconnectApiUrl: string,
		@Inject("EMSUME_API_URL") private emsumeApiUrl: string,
		@Inject("EMSUME_API_KEY") private emsumeApiKey: string,
	) {}

	getRecruitmentByEmployeeId(employeeId: number) {
		return this.http.get<any[]>(`${this.emconnectApiUrl}recruitment/applicant-detail?employeeId=${employeeId}`);
	}

	getApplicantById(applicantId: string, revisionNo: number) {
		const httpOptions = {
			headers: new HttpHeaders({ "X-API-KEY-EMSUME": this.emsumeApiKey }),
		};
		return this.http.get<any[]>(
			`${this.emsumeApiUrl}Applicant/GetApplicantById?applicantId=${applicantId}&&revisionNo=${revisionNo}`,
			httpOptions,
		);
	}
}
