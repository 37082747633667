import { Directive, NgZone, OnInit, OnDestroy, EventEmitter, Output, Input } from "@angular/core";
import { Subject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { fromEvent } from "rxjs/internal/observable/fromEvent";
import { Subscription } from "rxjs/internal/Subscription";
import { debounceTime } from "rxjs/operators";

@Directive({
	selector: "[ScrollInfinite]",
	standalone: true,
})
export class ScrollInfiniteDirective implements OnInit, OnDestroy {
	@Output() readonly scrolling = new EventEmitter<any>();
	@Output() readonly load = new EventEmitter<boolean>();

	@Input() disableLoad: boolean = false;

	private _loaded$: Subject<any> = new Subject();
	private _loadedSubscription$: Subscription = new Subscription();

	private $_ngZoneSubscription: Subscription = new Subscription();

	constructor(private _ngZone: NgZone) {
		this._loadedSubscription$ = this._loaded$.pipe(debounceTime(100)).subscribe(() => {
			this.load.emit(true);
		});
	}

	ngOnInit(): void {
		this._ngZone.runOutsideAngular(() => {
			const windowScroll$ = fromEvent<Event>(window, "scroll");

			this.$_ngZoneSubscription.add(this.subscribeWrap("scroll", windowScroll$));

			return () => {
				this.$_ngZoneSubscription?.unsubscribe();
			};
		});
	}

	subscribeWrap(type: string, observable: Observable<any>): Subscription {
		return observable.subscribe((event) => {
			this.scrolling.emit({
				type: type,
				event: event,
			});

			if (!event.defaultPrevented) {
				this.scroll();
			}
		});
	}

	ngOnDestroy() {
		this._loadedSubscription$?.unsubscribe();
		this.$_ngZoneSubscription?.unsubscribe();
	}

	scroll() {
		if (this.disableLoad) return;
		let pos = document.documentElement.scrollTop;
		let max = document.documentElement.scrollHeight - document.documentElement.offsetHeight;
		if (max <= pos + 300) {
			this._loaded$.next(true);
		}
	}
}
