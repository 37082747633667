import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { DataSharingService } from "src/app/core/data-sharing.service";
import { EmployeeFinancialService } from "src/app/employee-movements/employee-payroll/shared/employee-financial.service";
import { SharingService } from "../../sharing.service";
import { TranslateModule } from "@ngx-translate/core";
import { AsyncPipe, DecimalPipe, NgFor } from "@angular/common";
@Component({
	selector: "payroll-history",
	templateUrl: "./payroll-history.component.html",
	styleUrls: ["./payroll-history.component.scss"],
	standalone: true,
	imports: [DataTablesModule, AsyncPipe, DecimalPipe, TranslateModule, NgFor],
})
export class PayrollHistoryComponent implements OnInit {
	@ViewChild(DataTableDirective, { static: false })
	dtElement: DataTableDirective;
	dtOptions: DataTables.Settings = {};

	@Input() public employeeId: number;
	public messageWarn: string;
	public userInfo: any;
	public employeePayRollHistories: Array<any> = [];
	@Input() public isShowSalary: boolean = false;
	public maskAmount: string = "xxxx.xx";

	public language: string;
	public cols: any[];
	constructor(
		private employeeFinancialService: EmployeeFinancialService,
		public sharingService: SharingService,
		public dataShare: DataSharingService,
	) {}
	ngOnInit() {
		this.cols = [
			{ data: "FinancialItemName", header: "ชื่อฟิวส์" },
			{ data: "OldValue", header: "ข้อมูลเก่า" },
			{ data: "NewValue", header: "ข้อมูลใหม่" },
			{ data: "ModifiedBy", header: "ผู้แก้ไขล่าสุุด" },
			{ data: "DateModified", header: "เวลาแก้ไขล่าสุุด" },
			{ data: "EmployeeId", header: "พนักงาน" },
		];

		this.dtOptions = {
			pagingType: "full_numbers",
			dom: "ltip",
			pageLength: 10,
			serverSide: true,
			processing: true,
			searching: true,
			autoWidth: false,
			columns: [{ orderable: false, searchable: false }].concat(this.cols),
			ajax: (dataTablesParameters: any, callback) => {
				dataTablesParameters.columns[6].search.value = this.employeeId;
				this.employeeFinancialService
					.getEmployeeEditHistoryByEmployeeIdDataTable(dataTablesParameters)
					.subscribe((resp) => {
						this.employeePayRollHistories = this.isNullOrUndefined(resp.data) ? [] : resp.data;
						callback({
							recordsTotal: resp.recordsTotal,
							recordsFiltered: resp.recordsFiltered,
							data: [],
						});
					});
			},
		};
	}
	isNullOrUndefined(object) {
		return object == null || object == undefined;
	}
}
