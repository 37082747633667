import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../../core/shared/data-tables-response.model";
import { ProvidentFundRate } from "./provident-fund-rate.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ProvidentFundRateService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getProvidentFundRateDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "ProvidentFundRate/GetProvidentFundRateDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getProvidentFundRateById(providentFundRateId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "ProvidentFundRate/GetProvidentFundRateById/" + providentFundRateId),
		).then((res) => <ProvidentFundRate>res);
	}

	addProvidentFundRate(pfr: ProvidentFundRate) {
		var body = JSON.stringify(pfr);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "ProvidentFundRate/AddProvidentFundRate", body, httpOptions);
	}

	editProvidentFundRate(pfr: ProvidentFundRate) {
		var body = JSON.stringify(pfr);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "ProvidentFundRate/EditProvidentFundRate", body, httpOptions);
	}
}
