<div class="ui-g-12 p-0">
	<div class="flex-row-wrap p-0 pt-4" style="justify-content: space-between; align-items: center">
		<div style="width: 280px !important; display: block">
			<go5-search
				data-testid="input_text_keyword"
				category="primary"
				[placeholder]="'emp001_t19_search_keyword' | translate"
				size="large"
				(change)="searchKeyword()"
				(clear)="searchKeyword()"
				[(ngModel)]="keyword"
			>
			</go5-search>
		</div>
		<div class="d-flex gap-10" style="align-items: center">
			<go5-dropdown-selection
				#sDropdownFinancialType
				data-testid="dropdown_single_department"
				[dataSource]="isWelfareMaster ? typeDDL : financialItemDDL"
				[allowFiltering]="false"
				[popupWidth_px]="300"
				(selected)="onSelectType($event)"
				[value]="typeId"
				[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
				placeholder="{{ 'common_type' | translate }}"
				[allowShowRemove]="false"
			>
				<ng-template #toggleTemplate>
					<go5-dropdown-filter-toggle
						[size]="'large'"
						label="{{ 'common_type' | translate }}"
						[value]="
							sDropdownFinancialType.selectedItems
								| sSelected: (data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN)
								| async
						"
						[rotate]="sDropdownFinancialType.isOpen"
						(clear)="clearType()"
					>
					</go5-dropdown-filter-toggle>
				</ng-template>
			</go5-dropdown-selection>
			<go5-dropdown-selection
				*ngIf="isWelfareMaster"
				#sDropdownOrgNode
				data-testid="dropdown_single_department"
				[dataSource]="orgDDL"
				[allowFiltering]="false"
				categoryItem="item"
				[popupWidth_px]="300"
				popupType="slide"
				(selected)="onSelectOrg($event)"
				[value]="orgId"
				[fields]="data.getIsTH(data.currentLanguage | async) ? filedOrganize : filedOrganize_EN"
				placeholder="{{ 'common_select_department' | translate }}"
				[allowShowRemove]="false"
			>
				<ng-template #toggleTemplate>
					<go5-dropdown-filter-toggle
						[size]="'large'"
						label="{{ 'common_select_department' | translate }}"
						[value]="
							sDropdownOrgNode.selectedItems
								| sSelected: (data.getIsTH(data.currentLanguage | async) ? filedOrganize : filedOrganize_EN)
								| async
						"
						[rotate]="sDropdownOrgNode.isOpen"
						(clear)="clearOrg()"
					>
					</go5-dropdown-filter-toggle>
				</ng-template>
			</go5-dropdown-selection>
			<go5-dropdown-selection
				#dropdownStatus
				data-testid="dropdown_single_statusddl"
				[mode]="'multi'"
				[dataSource]="statusDDL"
				[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
				(selected)="onSelectStatus($event)"
				[value]="statusId"
				[popupWidth_px]="300"
				[allowShowRemove]="false"
				[allowFiltering]="false"
				placeholder="{{ 'common_status' | translate }}"
			>
				<ng-template #toggleTemplate>
					<go5-dropdown-filter-toggle
						[size]="'large'"
						[maxWidth]="'250px'"
						label="{{ 'common_status' | translate }}"
						[selectedItems]="dropdownStatus.selectedItems"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						[rotate]="dropdownStatus.isOpen"
						[isSelectAll]="dropdownStatus.isSelectAll"
						(clear)="clearStatus()"
					>
					</go5-dropdown-filter-toggle>
				</ng-template>
			</go5-dropdown-selection>

			<div class="d-flex gap-10" style="margin-left: 24px">
				<em
					class="gf-icon-excel-export pointer"
					style="font-size: 40px"
					(click)="exportExcelEmployeeLoan()"
					go5-tooltip-mode="hover"
					go5-tooltip="{{ 'common_report' | translate }}"
					go5-tooltip-placement="top"
				></em>
				<div>
					<go5-button
						data-testid="button_button_add"
						label="{{ 'emp001_t19_loan' | translate }}"
						category="primary"
						size="medium"
						type="button"
						(click)="openSidebar()"
						[disabled]="!permissionLoanTeam?.allowAdd && !permissionLoanEmp?.allowGrant"
						iconClass="gf-icon-add"
					>
					</go5-button>
				</div>
			</div>
		</div>
	</div>
	<div class="ui-g-12 search-table-outter p-0 pt-4">
		<div class="ui-g-12 search-table-outter ui-g-nopad height-table">
			<table
				class="dataTable hover"
				style="width: 100%; border: 0"
				ScrollInfinite
				(load)="loadMore()"
				[disableLoad]="isLoadDataScroll || isLoadtable"
			>
				<thead>
					<tr>
						<th class="th-sm-2 text-left" style="width: 5%">
							<label for="input">{{ "emp001_t19_document_no" | translate }}</label>
						</th>
						<th class="th-sm-2 text-left" style="width: 10%">
							<label [hidden]="!isWelfareMaster" for="input">{{ "emp_employee" | translate }}</label>
						</th>
						<th class="th-sm-2 text-left" style="width: 10%">
							<label for="input">{{ "emp001_t19_list_type" | translate }}</label>
						</th>
						<th class="th-sm-1 text-left" style="width: 12%">
							<label for="input">{{ "emp001_t19_first_payment_payroll_period" | translate }}</label>
						</th>
						<th class="th-sm-1 text-center" style="width: 5%">
							<label for="input">{{ "mas011_period" | translate }}</label>
						</th>
						<th class="th-sm-1 text-right" style="width: 5%">
							<label for="input">{{ "emp001_t19_payment_amount" | translate }}</label>
						</th>
						<th class="th-sm-2 text-right" style="width: 5%">
							<label for="input">{{ "emp001_t19_total_amount" | translate }}</label>
						</th>
						<th class="th-sm-1 text-right" style="width: 5%">
							<label for="input">{{ "common_balance" | translate }}</label>
						</th>
						<th class="th-sm-1 text-left" style="width: 12%">
							<label for="input">{{ "common_last_modified" | translate }}</label>
						</th>
						<th class="th-sm-1 text-center" style="width: 5%">
							<label for="input">{{ "common_status" | translate }}</label>
						</th>
						<th class="th-sm-1" style="width: 10%"></th>
					</tr>
				</thead>
				<tbody>
					<tr class="hover-content" *ngFor="let item of employeeLoans; let i = index">
						<td class="text-body color-lead text-left pointer" (click)="openLoanDetailSidebar(item)">
							{{ item.documentNo }}
						</td>
						<td (click)="openLoanDetailSidebar(item)" class="text-left" style="max-width: 220px">
							<go5-avatar-information
								[hidden]="!isWelfareMaster"
								[src]="item.pictureURL"
								title="{{ data.getIsTH(data.currentLanguage | async) ? item.fullname : item.fullname_EN }}"
								detail="{{ data.getIsTH(data.currentLanguage | async) ? item.positionName : item.positionName_EN }}"
								[isLazy]="false"
							>
							</go5-avatar-information>
						</td>
						<td class="text-body color-lead text-left pointer" (click)="openLoanDetailSidebar(item)">
							{{ data.getIsTH(data.currentLanguage | async) ? item.financialItemName : item.financialItemName_EN }}
						</td>

						<td class="text-body color-lead text-left pointer" (click)="openLoanDetailSidebar(item)">
							<label class="go5-text-color-11 go5-text-body-strong">
								{{
									item?.dateStart && item?.dateEnd
										? ("mas011_period_no" | translate) +
											(data.getIsTH(data.currentLanguage | async) ? " " : ".") +
											+item.periodCalculateNo
										: ("emp001_t19_pending_period" | translate)
								}}<br
							/></label>
							<label
								*ngIf="(item?.dateStart && item?.dateEnd) || item?.dateOfPeriodCalculateNo"
								class="go5-text-body-small go5-text-color-8"
								>({{
									checkDate(item.dateStart, item.dateEnd, 1)
										? (item.dateStart | date: "d") + "-" + (item.dateEnd | sDate: dateFormat.dateLong | async)
										: checkDate(item.dateStart, item.dateEnd, 2)
											? (item.dateStart | date: "d") +
												" " +
												(item.dateStart | sDate: dateFormat.monthLong | async) +
												"-" +
												(item.dateEnd | sDate: dateFormat.dateLong | async)
											: checkDate(item.dateStart, item.dateEnd, 3)
												? (item.dateStart | sDate: dateFormat.dateLong | async) +
													"-" +
													(item.dateEnd | sDate: dateFormat.dateLong | async)
												: (item.dateOfPeriodCalculateNo | sDate: dateFormat.dateLong | async)
								}})</label
							>
						</td>
						<td class="text-body color-lead text-center pointer" (click)="openLoanDetailSidebar(item)">
							<label
								go5-tooltip-placement="top"
								go5-tooltip-mode="hover"
								go5-tooltip="{{
									'emp001_t19_monthly_paymentperiod'
										| translate
											: { actualPeriods: item.countPeriodCalculateNo, determinedPeriods: item.monthlyPaymentPeriod }
								}}"
							>
								{{ item.countPeriodCalculateNo }}/{{ item.monthlyPaymentPeriod }}
							</label>
						</td>

						<td class="go5-text-color-11 text-right pointer" (click)="openLoanDetailSidebar(item)">
							<label *ngIf="item.paymentPerPeriod === 0" for="input">{{ "emp001_t19_suspended" | translate }}</label>
							<label *ngIf="item.paymentPerPeriod > 0"> ฿{{ item.paymentPerPeriod | number: "1.2-2" }} </label>
						</td>

						<td class="go5-text-color-11 text-right pointer" (click)="openLoanDetailSidebar(item)">
							฿{{ item.loanAmount + item.interestAmount | number: "1.2-2" }}
						</td>

						<td class="go5-text-color-11 text-right pointer" (click)="openLoanDetailSidebar(item)">
							฿{{ item.loanAmount + item.interestAmount - item.paymentPaidAmount | number: "1.2-2" }}
						</td>

						<td class="text-left pointer" (click)="openLoanDetailSidebar(item)">
							<div class="ui-g-12">
								<div class="go5-text-body-strong go5-text-color-12">
									{{ data.getIsTH(data.currentLanguage | async) ? item.modifiedBy : item.modifiedBy_EN }}
								</div>
								<div class="go5-text-body-samall go5-text-color-8">
									{{ item.dateModified | date: "dd/MM/yyyy HH:mm" : (data.currentLanguage | async) }}
								</div>
							</div>
						</td>
						<td class="text-center pointer" (click)="openLoanDetailSidebar(item)">
							<go5-status
								size="medium"
								type="liabilities"
								[label]="data.getIsTH(data.currentLanguage | async) ? item.status_TH : item.status_EN"
								[statusId]="item.status"
							></go5-status>
						</td>

						<td class="center">
							<div class="d-flex hover-edit gap-32" style="justify-content: center; align-items: center">
								<em
									class="gf-icon-empeo-edit font24"
									go5-tooltip-placement="top"
									go5-tooltip-mode="hover"
									go5-tooltip="{{ 'common_edit' | translate }}"
									(click)="editLoan(item.loanId)"
									*ngIf="
										!(
											(!permissionLoanTeam?.allowEdit && !permissionLoanEmp?.allowGrant) ||
											(item.status != enumLoanStatus.pending && item.status != enumLoanStatus.overdue && item.isClosed)
										)
									"
								>
								</em>
								<em
									class="i-payoff"
									go5-tooltip-placement="top"
									go5-tooltip-mode="hover"
									go5-tooltip="{{ 'emp001_t19_close_payment' | translate }}"
									(click)="closePayment(item.loanId)"
									*ngIf="item.status == 2"
								>
								</em>
								<em
									class="gf-icon-empeo-bin-vector font24"
									go5-tooltip-placement="top"
									go5-tooltip-mode="hover"
									go5-tooltip="{{ 'common_delete' | translate }}"
									(click)="deleteLoan(item.loanId)"
									*ngIf="
										!(
											(!permissionLoanTeam?.allowDelete && !permissionLoanEmp?.allowGrant) ||
											item.status != enumLoanStatus.pending ||
											item.isClosed
										)
									"
								>
								</em>
							</div>
						</td>
					</tr>
					<tr *ngIf="employeeLoans == null || employeeLoans?.length == 0">
						<td colspan="11" class="no-data-available" style="border: none">
							<div class="empty-image">
								<img alt="apollo-layout" src="assets/images/empty-state-no-data.svg" />
							</div>
							<div class="empty-text text-title-strong color-porpoise py-2 fadeIn">
								<div>{{ "common_no_data" | translate }}</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<go5-sidebar
	[(visible)]="displaySidebar"
	[textHeader]="(loanId != null ? 'emp001_t19_liabilities_edit' : 'emp001_t19_liabilities_add') | translate"
	[textConfirmButton]="'common_save' | translate"
	[textCancelButton]="
		(isClosePayment && employeeLoan.status == 2 ? 'emp001_t19_close_payment' : 'common_cancel') | translate
	"
	(confirm)="onSubmit()"
	(cancel)="closeSidebar()"
	(closed)="closeSidebar()"
>
	<div class="ui-g-12">
		<form [formGroup]="form">
			<div class="ui-g-12">
				<go5-dropdown-selection
					data-testid="dropdown_single_orgNode"
					[allowShowRemove]="false"
					[ngStyle]="{ 'pointer-events': !isWelfareMaster || loanId != null ? 'none' : '' }"
					[mode]="DropdownSelectMode.Single"
					[dataSource]="listPeople"
					[disabled]="!isWelfareMaster || loanId != null"
					label="{{ ('emp_employee' | translate) + '*' }}"
					placeholder="{{ 'common_default_select' | translate }}"
					(filtering)="onFilterEmployee($event)"
					[fields]="data.getIsTH(data.currentLanguage | async) ? peopleFields : peopleFields_EN"
					[value]="employeeId"
					(selected)="selectEmployee($event)"
					formControlName="employeeId"
					[error]="form.get('employeeId').invalid && (form.get('employeeId').dirty || checkEmployeeId)"
					[errorMessage]="'common_please_choose_employee' | translate"
				>
				</go5-dropdown-selection>
			</div>

			<div class="ui-g-12" [ngStyle]="{ 'pointer-events': status > 1 && loanId != null ? 'none' : '' }">
				<go5-dropdown-selection
					data-testid="dropdown_single_orgNode"
					[disabled]="status >= 1 && loanId != null"
					[allowShowRemove]="false"
					[mode]="DropdownSelectMode.Single"
					[dataSource]="financialItemDDL"
					label="{{ ('emp001_t19_list_type' | translate) + '*' }}"
					placeholder="{{ 'common_default_select' | translate }}"
					popupType="slide"
					categoryItem="item"
					(open)="checkEmployee()"
					(selected)="financialItemId = $event.value; checkFinancialItemId = false"
					[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
					[(ngModel)]="financialItemId"
					formControlName="financialItemId"
					[error]="(form.get('financialItemId').invalid && form.get('financialItemId').dirty) || checkFinancialItemId"
					[errorMessage]="'liabilities_validate_type' | translate"
				>
				</go5-dropdown-selection>
			</div>
			<!-- ยอดรวม* -->
			<div class="ui-g-12">
				<go5-textfield
					type="currency"
					category="primary"
					formControlName="loanAmount"
					[disabled]="status > 1 && loanId != null"
					[min]="0"
					[value]="loanAmount"
					label="{{ ('emp001_t19_total_amount' | translate) + '*' }}"
					(changed)="loanAmount = $event.value; calculateAmountPeriod(); checkLoanAmount = false"
					format="0.2-2"
					[error]="(form.get('loanAmount').invalid && form.get('loanAmount').dirty) || checkLoanAmount"
					[errorMessage]="'liabilities_validate_total_amount' | translate"
				></go5-textfield>
			</div>
			<div class="ui-g-6">
				<go5-textfield
					type="number"
					category="primary"
					placeholder="0"
					(changed)="monthlyPaymentPeriod = $event.value; calculateAmountPeriod()"
					formControlName="monthlyPaymentPeriod"
					[min]="0"
					[value]="monthlyPaymentPeriod"
					label="{{ ('emp001_t19_number_period' | translate) + '*' }}"
					[disabled]="isReadOnly"
					unit=""
					(keydown)="checkDecimal($event)"
					[error]="
						(form.get('monthlyPaymentPeriod').invalid && form.get('monthlyPaymentPeriod').dirty) ||
						checkMonthlyPaymentPeriod
					"
					[errorMessage]="'liabilities_validate_period' | translate"
				></go5-textfield>
			</div>

			<div class="ui-g-6">
				<go5-textfield
					type="currency"
					category="primary"
					(changed)="paymentPerPeriod = $event.value; calculateAmountPeriod()"
					[disabled]="true"
					formControlName="paymentPerPeriod"
					[value]="paymentPerPeriod"
					label="{{ 'emp001_t19_amount_period' | translate }}"
					format="0.2-2"
				></go5-textfield>
			</div>
			<!-- คิดดอกเบี้ย -->
			<div class="ui-g-12">
				<div class="d-flex p-0" style="align-items: center; justify-content: space-between">
					<label class="text-color-8">
						{{ "emp001_t19_charge_interest" | translate }}
					</label>
					<go5-toggle-switch
						data-testid="input_switch_isactive"
						[disabled]="status > 1 && loanId != null"
						category="secondary"
						class="pl-2"
						[checked]="isInterestType"
						formControlName="interestType"
						(valueChange)="isInterestType = $event.checked; onChangeInterestType()"
					></go5-toggle-switch>
				</div>
			</div>

			<!-- ดอกเบี้ยต่อปี -->
			<div class="ui-g-6" *ngIf="isInterestType">
				<go5-textfield
					type="number"
					category="primary"
					(changed)="interestRatePerYear = $event.value; calculateAmountPeriod()"
					[disabled]="status > 1 && loanId != null"
					[min]="0"
					unit="%"
					formControlName="interestRatePerYear"
					[value]="interestRatePerYear"
					label="{{ 'emp001_t19_interest_year' | translate }}"
					format="0.2-2"
				></go5-textfield>
			</div>
			<!-- ดอกเบี้ยรวม -->

			<div class="ui-g-6" *ngIf="isInterestType">
				<go5-textfield
					type="currency"
					category="primary"
					(changed)="interestAmount = $event.value; calculateAmountPeriod()"
					[disabled]="true"
					unit=""
					[min]="0"
					formControlName="interestAmount"
					[value]="interestAmount"
					label="{{ 'emp001_t19_interest_amount' | translate }}"
					format="0.2-2"
				></go5-textfield>
			</div>
			<!-- จ่ายต่องวด -->
			<div class="ui-g-12">
				<go5-textfield
					type="currency"
					category="primary"
					[max]="loanAmount - employeeLoan.paymentPaidAmount"
					[min]="0"
					formControlName="payPerPeriodSumInterest"
					[(ngModel)]="payPerPeriodSumInterest"
					label="{{ 'emp001_t19_pay_per_period' | translate }}"
					format="0.2-2"
				></go5-textfield>
			</div>

			<div class="ui-g-12" [ngStyle]="{ 'pointer-events': status > 1 && loanId != null ? 'none' : '' }">
				<div style="display: flex">
					<p
						style="
							color: var(--go5-input-label);
							font-family: gofive-medium;
							font-size: 14px !important;
							line-height: 20px !important;
							margin: 0 0 8px;
						"
					>
						{{ ("emp001_t19_date_payment_start" | translate) + "*" }}
					</p>
					<span style="margin: 1px 0 0 4px">
						<einfo-tooltip key="emp001_t19_date_payment_start_tooltip" size="s"> </einfo-tooltip>
					</span>
				</div>
				<go5-datepicker
					data-testid="input_calendar_date"
					[format]="dateFormat.dateLong"
					[disabled]="status > 1 && loanId != null"
					[allowShowRemove]="false"
					[isShowTime]="false"
					placeholder="{{ 'common_default_select' | translate }}"
					[(ngModel)]="dateOfPeriodCalculateNo"
					formControlName="dateOfPeriodCalculateNo"
					(selected)="selectDateOfPeriodCalculateNo($event)"
					[minimumDate]="dateLastYear"
					[value]="dateOfPeriodCalculateNo"
					[error]="
						(form.get('dateOfPeriodCalculateNo').invalid && form.get('dateOfPeriodCalculateNo').dirty) ||
						checkPeriodCalculateNo
					"
					[errorMessage]="'liabilities_validate_first_payment' | translate"
				>
				</go5-datepicker>
			</div>

			<div class="ui-g-12">
				<go5-textarea
					category="primary"
					[(ngModel)]="remark"
					formControlName="remark"
					size="large"
					label="{{ 'doc001_description_placeholder' | translate }}"
					maxlength="2000"
					placeholder="{{ 'common_detail_placeholder' | translate }}"
				></go5-textarea>
			</div>
		</form>
	</div>
</go5-sidebar>

<go5-sidebar
	*ngIf="displayLoanDetail"
	[(visible)]="displayLoanDetail"
	[textHeader]="('emp001_t19_loan' | translate) + ' ' + '(' + loanDetail?.documentNo + ')'"
	[isShowFooter]="false"
	(closed)="displayLoanDetail = false"
	[padding]="'24px'"
>
	<div class="ui-g-12 ui-g-nopad">
		<div class="ui-g-12">
			<div class="d-flex content-space-between">
				<div class="text-header-4">
					<div class="contact-info-leave ui-g-12 ui-g-nopad ellipsis" style="text-align: left">
						<span (click)="viewEmployee(loanDetail)">
							<go5-avatar
								size="medium"
								[src]="loanDetail?.pictureURL"
								[isLazy]="false"
								style="cursor: pointer; margin: 0px 8px 0px 0px; float: left"
							>
							</go5-avatar>
						</span>
						<span class="text-title-strong name">
							{{ data.getIsTH(data.currentLanguage | async) ? loanDetail?.fullname : loanDetail?.fullname_EN }}
						</span>
						<br />
						<span class="position" style="margin-top: -4px; color: var(--go5-text-color-8); font-size: 12px !important">
							{{ data.getIsTH(data.currentLanguage | async) ? loanDetail?.positionName : loanDetail?.positionName_EN }}
						</span>
					</div>
				</div>
				<div class="ui-g-4 right go5-text-small go5-text-color-12 p-0">
					<div class="go5-text-small go5-text-color-9">
						{{ loanDetail?.dateCreated | sDate: dateFormat.dateMonthShort | async }}
						{{ loanDetail?.dateCreated | sDate: dateFormat.yearOnly | async }}
						{{ loanDetail?.dateCreated | sDate: dateFormat.timeOnly | async }}
					</div>
					<go5-status
						size="medium"
						type="liabilities"
						[label]="data.getIsTH(data.currentLanguage | async) ? employeeLoan.status_TH : employeeLoan.status_EN"
						[statusId]="employeeLoan.status"
					></go5-status>
				</div>
			</div>
			<div class="ui-g-12 ui-g-nopad text-body" style="padding-left: 44px">
				<div class="ui-g-nopad">
					<div
						class="ui-g-1 gf-icon-circle-mini p-0"
						style="width: 6%; padding: 2px 8px 0 6px !important"
						[style.color]="
							employeeLoan.status == 1 ? '#4C97FF' : employeeLoan.status == 2 ? 'var(--go5-color-primary)' : '#00C291'
						"
					></div>
					<div class="ui-g-11 left go5-text-body-strong go5-text-color-12 p-0">
						{{
							data.getIsTH(data.currentLanguage | async)
								? loanDetail?.financialItemName
								: loanDetail?.financialItemName_EN
						}}
					</div>
					<div class="ui-g-12 left go5-text-body go5-text-color-10">{{ loanDetail?.remark }}</div>
				</div>
				<div class="ui-g-nopad">
					<div class="ui-g-12 left go5-text-body-strong go5-text-color-12" style="margin-bottom: -8px">
						{{ "emp001_t19_first_payment" | translate }}
					</div>
					<div class="ui-g-12 left go5-text-body go5-text-color-10">
						{{
							data.getIsTH(data.currentLanguage | async)
								? loanDetail?.firstPaymentDetail
								: loanDetail?.firstPaymentDetail_EN
						}}
					</div>
				</div>
			</div>
			<div class="ui-g-12 go5-text-header-5 go5-text-color-11 p-0 pb-3" style="margin-bottom: -8px; margin-top: 8px">
				{{ "Installment_Details" | translate }}
			</div>
			<div class="ui-g-12 ui-g-nopad text-body" style="padding-left: 46px; margin-bottom: 8px">
				<div class="ui-g-8 left go5-text-body go5-text-color-10">{{ "emp001_t19_total_amount" | translate }}</div>
				<div class="ui-g-4 right go5-text-color-10">฿{{ loanDetail?.loanAmount | number: "1.2-2" }}</div>
				<div *ngIf="loanDetail?.interestAmount">
					<div class="ui-g-8 left go5-text-body go5-text-color-10">
						{{ "emp001_t19_interest_amount" | translate }} ({{ "Fixed_Rate" | translate
						}}{{ loanDetail?.interestRatePerYear }}%)
					</div>
					<div class="ui-g-4 right go5-text-color-10">฿{{ loanDetail?.interestAmount | number: "1.2-2" }}</div>
				</div>
				<div class="ui-g-8 left go5-text-body go5-text-color-10">{{ "emp001_t19_payment_amount" | translate }}</div>
				<div
					class="ui-g-4 right go5-text-color-10"
					*ngIf="loanDetail?.paymentPerPeriod != 0 || loanDetail?.periodCalculateNo == null"
				>
					฿{{ loanDetail?.paymentPerPeriod | number: "1.2-2" }}
				</div>
				<div
					class="ui-g-4 right go5-text-color-10"
					*ngIf="loanDetail?.paymentPerPeriod === 0 && loanDetail?.periodCalculateNo > 0"
				>
					<label for="input">{{ "emp001_t19_suspended" | translate }}</label>
				</div>
				<div class="ui-g-8 left go5-text-body go5-text-color-10">{{ "emp001_t19_already_payment" | translate }}</div>
				<div class="ui-g-4 right go5-text-color-10">{{ loanDetail?.periodPaidDetail }}</div>
				<div class="ui-g-8 left go5-text-body go5-text-color-10">{{ "emp001_t19_balance" | translate }}</div>
				<div class="ui-g-4 right go5-text-color-10">฿{{ loanDetail?.netAmount | number: "1.2-2" }}</div>
			</div>
			<table
				class="hover dataTable dataTables-parti-coloured"
				style="width: 100%; border: 0"
				*ngIf="
					loanDetail?.welfareEmployeeLoanTransactions != null && loanDetail?.welfareEmployeeLoanTransactions.length > 0
				"
			>
				<thead>
					<tr>
						<th class="th-sm-2 text-left" style="width: 5%">
							<label for="input">{{ "common_no1" | translate }}</label>
						</th>
						<th class="th-sm-1 text-left" style="width: 10%">
							<label for="input">{{ "common_payroll_period" | translate }}</label>
						</th>
						<th class="th-sm-1 text-right" style="width: 5%">
							<label for="input">{{ "Paid_Amount" | translate }}</label>
						</th>
					</tr>
				</thead>
				<tbody *ngIf="isLoadtable" class="fadeIn">
					<tr *ngFor="let number of data.counter(5)">
						<td *ngFor="let number of data.counter(isWelfareMaster ? 9 : 8)" class="pb-0 fadeIn">
							<ngx-skeleton-loader [theme]="{ 'padding-bottom': '0px', 'margin-bottom': '0px', height: '30px' }">
							</ngx-skeleton-loader>
						</td>
					</tr>
				</tbody>
				<tbody *ngIf="!isLoadtable">
					<tr class="hover-content" *ngFor="let item of loanDetail?.welfareEmployeeLoanTransactions; let i = index">
						<td class="text-body color-lead text-left pointer" *ngIf="item?.amount">
							{{ item.no }}
						</td>
						<td class="text-body color-lead text-left pointer" *ngIf="item?.amount">
							<label class="go5-text-body-strong go5-text-color-11">
								{{ "mas011_period_no" | translate }}{{ data.getIsTH(data.currentLanguage | async) ? "" : "." }}
								{{ item.periodCalculateNo }}<br />
							</label>

							<label class="go5-text-body-small go5-text-color-8">
								{{ data.getIsTH(data.currentLanguage | async) ? item?.dateRangePeriod_TH : item?.dateRangePeriod_EN }}
							</label>
						</td>
						<td class="text-body color-lead text-right pointer" *ngIf="item?.amount">
							฿{{ item.amount | number: "1.2-2" }}
						</td>
					</tr>
				</tbody>
			</table>
			<div
				class="ui-g-12 text-header-5 go5-text-color-12 p-0"
				style="margin-top: 16px; border-bottom: 1px solid rgba(226, 226, 226, 0.6)"
			>
				<workflow-log [workflow]="loanDetail?.timelines"></workflow-log>
			</div>
		</div>
	</div>
</go5-sidebar>
