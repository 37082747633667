<go5-sidebar
	*ngIf="displayPositionAdd"
	[(visible)]="displayPositionAdd"
	[isShowFooter]="false"
	[isShowHeader]="false"
	[padding]="'0px'"
>
	<app-position-add
		(onCloseSidebar)="closeSidebar()"
		(successAdd)="openAddPosition()"
		[rankNo]="employeeModel.rankNo"
		[isEmployeeAdd]="true"
		[isOpenSidebar]="displayPositionAdd"
	></app-position-add>
</go5-sidebar>

<div [ngClass]="{ 'pl-5': fullTemPlate && !isFromMobile && !isSmallSizeMobile && !isMobile }">
	@if (!isFromMobile) {
		<div style="padding: 16px 24px" [ngClass]="{ 'title-tab-container-small-text': isSmallSizeMobile }">
			<span [ngClass]="{ 'pl-4': isEdit && !selfUpdate && fullTemPlate }" class="go5-text-header-3 go5-text-color-12">{{
				isEdit
					? isAddress
						? ("emp001_t01_contact_information" | translate)
						: isAdditionalField
							? ("emp001_additional_information" | translate)
							: ("emp001_employee_info" | translate)
					: ("common_add_employee" | translate)
			}}</span>
		</div>
	}
	@if (!isEdit) {
		<div class="employee-add-state-container" style="padding-top: 0px">
			<ul style="display: flex; justify-content: center">
				<li class="d-inline">
					<div style="display: flex; justify-content: center">
						<div
							class="cycle"
							[ngStyle]="{ 'background-color': currentEmployeeIndex > 1 ? '#21CE9B' : 'transparent' }"
							[ngClass]="{
								'active-cycle': currentEmployeeIndex == 1,
								'primary-pointer': currentEmployeeIndex >= 2 && currentEmployeeIndex < 4
							}"
							(click)="onBackState(1)"
						>
							@if (currentEmployeeIndex > 1) {
								<span class="icon-select-mini" style="margin: auto"></span>
							} @else {
								<span class="order-cycle text-header-5">1</span>
							}
						</div>
						<div class="ui-g-nopad" style="position: absolute; margin-top: 50px">
							<span
								class="go5-text-title-strong color-flint ellipsis-line"
								[ngClass]="{ 'color-iron': currentEmployeeIndex > 1, 'color-flame-im': currentEmployeeIndex == 1 }"
								>{{ "emp001_employee_info" | translate }}</span
							>
						</div>
					</div>
				</li>
				<li class="d-inline">
					<div
						class="border-time-line"
						[ngStyle]="{ 'border-color': currentEmployeeIndex > 1 ? '#21CE9B' : 'var(--go5-text-color-3)' }"
					></div>
				</li>

				<li class="d-inline">
					<div style="display: flex; justify-content: center">
						<div
							class="cycle"
							[ngStyle]="{ 'background-color': currentEmployeeIndex > 2 ? '#21CE9B' : 'transparent' }"
							[ngClass]="{ 'active-cycle': currentEmployeeIndex == 2, 'primary-pointer': currentEmployeeIndex == 3 }"
							(click)="onBackState(2)"
						>
							@if (currentEmployeeIndex > 2) {
								<span class="icon-select-mini" style="margin: auto"></span>
							} @else {
								<span class="order-cycle text-header-5">2</span>
							}
						</div>
						<div class="ui-g-nopad" style="position: absolute; margin-top: 50px">
							<span
								class="go5-text-title-strong color-flint ellipsis-line"
								[ngClass]="{ 'color-iron': currentEmployeeIndex > 2, 'color-flame-im': currentEmployeeIndex == 2 }"
								>{{ "emp001_address_tab" | translate }}</span
							>
						</div>
					</div>
				</li>
				<li class="d-inline">
					<div
						class="border-time-line"
						[ngStyle]="{ 'border-color': currentEmployeeIndex > 2 ? '#21CE9B' : 'var(--go5-text-color-3)' }"
					></div>
				</li>

				<li class="d-inline">
					<div style="display: flex; justify-content: center">
						<div
							class="cycle"
							[ngStyle]="{ 'background-color': currentEmployeeIndex > 3 ? '#21CE9B' : 'transparent' }"
							[ngClass]="{ 'active-cycle': currentEmployeeIndex == 3 }"
						>
							@if (currentEmployeeIndex > 3) {
								<span class="icon-select-mini" style="margin: auto"></span>
							} @else {
								<span class="order-cycle text-header-5">3</span>
							}
						</div>
						<div class="ui-g-nopad" style="position: absolute; margin-top: 50px">
							<span
								class="go5-text-title-strong color-flint ellipsis-line"
								[ngClass]="{ 'color-iron': currentEmployeeIndex > 3, 'color-flame-im': currentEmployeeIndex == 3 }"
								>{{ "emp001_work_information_tab" | translate }}</span
							>
						</div>
					</div>
				</li>
				@if (userInfo?.hasPayroll) {
					<li class="d-inline">
						<div
							class="border-time-line"
							[ngStyle]="{ 'border-color': currentEmployeeIndex > 3 ? '#21CE9B' : 'var(--go5-text-color-3)' }"
						></div>
					</li>
					<li class="d-inline">
						<div style="display: flex; justify-content: center">
							<div
								class="cycle"
								[ngStyle]="{ 'background-color': currentEmployeeIndex > 4 ? '#21CE9B' : 'transparent' }"
								[ngClass]="{ 'active-cycle': currentEmployeeIndex == 4 }"
							>
								@if (currentEmployeeIndex > 4) {
									<span class="icon-select-mini" style="margin: auto"></span>
								} @else {
									<span class="order-cycle f-18" style="font-weight: unset">4</span>
								}
							</div>
							<div class="ui-g-nopad" style="position: absolute; margin-top: 50px">
								<span
									class="go5-text-title-strong color-flint ellipsis-line"
									style="font-weight: unset"
									[ngClass]="{ 'color-iron': currentEmployeeIndex > 4, 'color-flame-im': currentEmployeeIndex == 4 }"
									>{{ "mas010_salary" | translate }}</span
								>
							</div>
						</div>
					</li>
				}
				<li class="d-inline">
					<div
						class="border-time-line"
						[ngStyle]="{ 'border-color': currentEmployeeIndex > 4 ? '#21CE9B' : 'var(--go5-text-color-3)' }"
					></div>
				</li>
				<li class="d-inline">
					<div style="display: flex; justify-content: center">
						<div
							class="cycle"
							[ngStyle]="{
								'background-color': currentEmployeeIndex > (userInfo?.hasPayroll ? 4 : 5) ? '#21CE9B' : 'transparent'
							}"
							[ngClass]="{ 'active-cycle': currentEmployeeIndex == 5 }"
						>
							@if (currentEmployeeIndex > 5) {
								<span class="icon-select-mini" style="margin: auto"></span>
							} @else {
								<span class="order-cycle text-header-5">5</span>
							}
						</div>
						<div class="ui-g-nopad" style="position: absolute; margin-top: 50px">
							<span
								class="go5-text-title-strong color-flint ellipsis-line"
								[ngClass]="{
									'color-iron': currentEmployeeIndex > (userInfo?.hasPayroll ? 5 : 4),
									'color-flame-im': currentEmployeeIndex == (userInfo?.hasPayroll ? 5 : 4)
								}"
								>{{ "emp001_social_security" | translate }}</span
							>
						</div>
					</div>
				</li>
				@if (customFields?.length) {
					<li class="d-inline">
						<div
							class="border-time-line"
							[ngStyle]="{
								'border-color':
									currentEmployeeIndex > (userInfo?.hasPayroll ? 5 : 4) ? '#21CE9B' : 'var(--go5-text-color-3)'
							}"
						></div>
					</li>
					<li class="d-inline">
						<div style="display: flex; justify-content: center">
							<div
								class="cycle"
								[ngStyle]="{
									'background-color': currentEmployeeIndex > (userInfo?.hasPayroll ? 6 : 5) ? '#21CE9B' : 'transparent'
								}"
								[ngClass]="{ 'active-cycle': currentEmployeeIndex == (userInfo?.hasPayroll ? 6 : 5) }"
							>
								@if (currentEmployeeIndex > (userInfo?.hasPayroll ? 6 : 5)) {
									<span class="icon-select-mini" style="margin: auto"></span>
								} @else {
									<span class="order-cycle f-18" style="font-weight: unset">{{ userInfo?.hasPayroll ? 6 : 5 }}</span>
								}
							</div>
							<div class="ui-g-nopad" style="position: absolute; margin-top: 50px">
								<span
									class="go5-text-title-strong color-flint ellipsis-line"
									style="font-weight: unset"
									[ngClass]="{
										'color-iron': currentEmployeeIndex > (userInfo?.hasPayroll ? 6 : 5),
										'color-flame-im': currentEmployeeIndex == (userInfo?.hasPayroll ? 6 : 5)
									}"
									>{{ "emp001_additional_information" | translate }}</span
								>
							</div>
						</div>
					</li>
				}
			</ul>
		</div>
	}
	@if (isDoneLoading) {
		<div
			class="employee-add-container"
			[style.padding-bottom.px]="footerSelfService?.nativeElement?.offsetHeight ?? 100"
			[ngClass]="{
				'employee-left-template': isEdit && !selfUpdate && !fullTemPlate,
				'sso-template': currentEmployeeIndex === 5
			}"
		>
			<ng-container
				*ngTemplateOutlet="
					currentEmployeeIndex == 1
						? firstForm
						: currentEmployeeIndex == 2
							? secondForm
							: currentEmployeeIndex == 3
								? thirdForm
								: !userInfo?.hasPayroll
									? fifthForm
									: currentEmployeeIndex == 4
										? fourthForm
										: currentEmployeeIndex == 5
											? fifthForm
											: sixthForm
				"
			></ng-container>
		</div>
	}
</div>

<ng-template #firstForm>
	<ng-container [formGroup]="formEmployeeFirstPage">
		<div
			class="image-employee-with-camera"
			[ngStyle]="{ 'background-image': 'url(' + image + ')' }"
			style="place-items: center"
		>
			<div id="image-camera-employee-add">
				<p-fileUpload
					class="image-camera-employee-add"
					#fileUpload
					mode="basic"
					accept="image/*"
					maxFileSize="10000000"
					auto="true"
					(onSelect)="onChooseImage($event)"
				></p-fileUpload>
			</div>
		</div>
		@if (isEdit) {
			<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
				{{ "emp001_employee_information" | translate }}
			</div>
		}

		<div class="employee-add-fills-container">
			<div class="employee-add-fill-title">
				<go5-dropdown-selection
					#dropdownTitle
					data-testid="dropdown_single_title"
					[label]="('emp001_title' | translate) + '*'"
					[mode]="'single'"
					[dataSource]="titlesDDL"
					[value]="employeeModel.titleId"
					[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
					placeholder="{{ 'common_please_choose' | translate }}"
					[height]="'40px'"
					popupWidth_px="300"
					formControlName="titleId"
					(clear)="onClearTitle()"
					(selected)="onSelectedTitle($event)"
					[error]="
						!formEmployeeFirstPage.controls['titleId'].valid &&
						(isSubmittedFirstPage || editSubmittedFirstPage || formEmployeeFirstPage.controls['titleId'].touched)
					"
					[errorMessage]="'validation_please_choose' | translate: { titleName: 'emp001_title' | translate }"
				>
				</go5-dropdown-selection>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_name"
						[label]="('emp001_name_th' | translate) + '*'"
						type="text"
						formControlName="name"
						[pKeyFilter]="regexPattern"
						placeholder="{{ 'emp001_name_th_placeholder' | translate }}"
						[(ngModel)]="employeeModel.name"
						[error]="!formEmployeeFirstPage.controls['name'].valid && (isSubmittedFirstPage || editSubmittedFirstPage)"
						[errorMessage]="'error_name_lenght' | translate"
					>
					</go5-textfield>
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_surname"
						[label]="('emp001_surname_th' | translate) + '*'"
						type="text"
						formControlName="surname"
						[pKeyFilter]="regexPattern"
						placeholder="{{ 'emp001_surname_th_placeholder' | translate }}"
						[(ngModel)]="employeeModel.surname"
						[error]="
							!formEmployeeFirstPage.controls['surname'].valid && (isSubmittedFirstPage || editSubmittedFirstPage)
						"
						[errorMessage]="'error_surname_lenght' | translate"
					>
					</go5-textfield>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_name_EN"
						[label]="('emp001_name_en' | translate) + '*'"
						type="text"
						formControlName="name_EN"
						[pKeyFilter]="regexPattern"
						placeholder="{{ 'emp001_name_en_placeholder' | translate }}"
						[(ngModel)]="employeeModel.name_EN"
						(input)="onNameENChange($event)"
						[error]="
							!formEmployeeFirstPage.controls['name_EN'].valid && (isSubmittedFirstPage || editSubmittedFirstPage)
						"
						[errorMessage]="'error_name_lenght' | translate"
					></go5-textfield>
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_surname_EN"
						[pKeyFilter]="regexPattern"
						[label]="('emp001_surname_en' | translate) + '*'"
						type="text"
						formControlName="surname_EN"
						placeholder="{{ 'emp001_surname_en_placeholder' | translate }}"
						[(ngModel)]="employeeModel.surname_EN"
						(input)="onSurnameENChange($event)"
						[error]="
							!formEmployeeFirstPage.controls['surname_EN'].valid && (isSubmittedFirstPage || editSubmittedFirstPage)
						"
						[errorMessage]="'error_surname_lenght' | translate"
					></go5-textfield>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="emp001_nickname_th"
						[label]="'emp001_nickname_th' | translate"
						type="text"
						formControlName="nickName"
						[pKeyFilter]="regexPattern"
						placeholder="{{ 'emp001_nickname_th_placeholder' | translate }}"
						[(ngModel)]="employeeModel.nickName"
					>
					</go5-textfield>
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_nickName_EN"
						[label]="'emp001_nickname_en' | translate"
						type="text"
						formControlName="nickName_EN"
						[pKeyFilter]="regexPattern"
						placeholder="{{ 'emp001_nickname_en_placeholder' | translate }}"
						[(ngModel)]="employeeModel.nickName_EN"
						(input)="onNickNameENChange($event)"
					>
					</go5-textfield>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-datepicker
						data-testid="input_calendar_dateofbirth"
						[format]="dateFormat.dateLong"
						formControlName="dateOfBirth"
						placeholder="{{ 'common_please_choose' | translate }}"
						[(ngModel)]="employeeModel.dateOfBirth"
						[isShowTime]="false"
						[label]="('emp001_date_of_birth' | translate) + (userInfo?.packageId == packageEnum?.Starter ? '' : '*')"
						[maximumDate]="currentDate"
						[error]="
							!formEmployeeFirstPage.controls['dateOfBirth'].valid &&
							(isSubmittedFirstPage || editSubmittedFirstPage || formEmployeeFirstPage.controls['dateOfBirth'].touched)
						"
						[errorMessage]="'validation_please_choose' | translate: { titleName: 'emp001_date_of_birth' | translate }"
					></go5-datepicker>
				</span>
				<span class="employee-add-fill">
					<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">{{ "emp001_gender" | translate }}*</div>
					<div>
						<go5-selection-single-group
							[dataSource]="arrGender"
							[fields]="fieldsDDL"
							[(ngModel)]="employeeModel.gender"
							formControlName="gender"
							[disabled]="selfUpdate"
						></go5-selection-single-group>
					</div>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">{{ "common_type" | translate }}*</div>
					<div>
						<go5-selection-single-group
							[dataSource]="foreignerSelected"
							[fields]="fieldsDDL"
							[(ngModel)]="employeeModel.isForeigner"
							(selected)="changeForeigner($event)"
							formControlName="isForeigner"
							[disabled]="selfUpdate"
						></go5-selection-single-group>
					</div>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						[disabled]="selfUpdate"
						type="text"
						pKeyFilter="int"
						[label]="
							!employeeModel?.isForeigner
								? ('emp001_id_card_no' | translate) + '*'
								: ('emp001_taxpayer_number' | translate)
						"
						formControlName="idCardNo"
						placeholder="{{
							employeeModel?.isForeigner
								? ('emp001_taxpayer_number_placeholder' | translate)
								: ('emp001_id_card_no_placeholder' | translate)
						}}"
						[(ngModel)]="employeeModel.idCardNo"
						label=""
						[maxLength]="13"
						[minLength]="13"
						[error]="
							employeeModel?.isForeigner
								? ''
								: !formEmployeeFirstPage.controls['idCardNo'].valid && (isSubmittedFirstPage || editSubmittedFirstPage)
						"
						(changed)="checkIdCardExist()"
						[errorMessage]="
							employeeModel?.isForeigner
								? ('emp001_taxpayer_number_validate' | translate)
								: ('id_card_no_validate' | translate)
						"
					>
					</go5-textfield
				></span>
				<span class="employee-add-fill">
					@if (employeeModel.isForeigner) {
						<go5-textfield
							data-testid="input_text_passportno"
							[label]="'emp001_passport_no' | translate"
							type="text"
							formControlName="passportNo"
							placeholder="{{ 'emp001_passport_no_placeholder' | translate }}"
							[(ngModel)]="employeeModel.passportNo"
							[disabled]="selfUpdate"
						></go5-textfield>
					}
				</span>
			</div>
			@if (employeeListDuplicateIDCard?.length > 0) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill" style="width: 100% !important">
						<go5-info-bar [type]="infoBarType.Info">
							<p *ngFor="let item of employeeListDuplicateIDCard">
								{{ "emp001_idcard_duplicate" | translate }}
								<a (click)="newTab(item.employeeNo)" style="text-decoration: underline">
									{{
										data.getIsTH(data.currentLanguage | async)
											? item.name + " " + item.surname
											: item.name_EN + " " + item.surname_EN
									}}</a
								>
								{{
									"emp001_idcard_duplicate2"
										| translate
											: {
													companyName: data.getIsTH(data.currentLanguage | async)
														? item.companyName
														: item.companyName_EN
											  }
								}}
							</p>
						</go5-info-bar>
					</span>
				</div>
			}
			@if (employeeModel.isForeigner) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_workpermitno"
							[label]="'emp001_workpermit_no' | translate"
							type="text"
							formControlName="workPermitNo"
							placeholder="{{ 'emp001_workpermit_no_placeholder' | translate }}"
							[(ngModel)]="employeeModel.workPermitNo"
							[disabled]="selfUpdate"
						></go5-textfield>
					</span>
					<span class="employee-add-fill">
						<go5-datepicker
							data-testid="input_calendar_dateworkpermitissue"
							[label]="'emp001_date_work_permit_issue' | translate"
							[format]="dateFormat.dateLong"
							formControlName="dateWorkPermitIssue"
							[(ngModel)]="employeeModel.dateWorkPermitIssue"
							[disabled]="selfUpdate"
							[isShowTime]="false"
							placeholder="{{ 'common_please_choose' | translate }}"
						>
						</go5-datepicker
					></span>
				</div>
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-datepicker
							data-testid="input_calendar_dateworkpermitexpire"
							[label]="'emp001_date_work_permit_expire' | translate"
							[format]="dateFormat.dateLong"
							formControlName="dateWorkPermitExpire"
							[(ngModel)]="employeeModel.dateWorkPermitExpire"
							[disabled]="selfUpdate"
							[isShowTime]="false"
							placeholder="{{ 'common_please_choose' | translate }}"
						>
						</go5-datepicker>
					</span>
					<span class="employee-add-fill"> </span>
				</div>
			}

			@if (isEdit) {
				<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
					{{ "emp001_self_information" | translate }}
				</div>
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_raceId"
							[label]="'emp001_race' | translate"
							[mode]="'single'"
							[dataSource]="nationalityDDL"
							[(ngModel)]="employeeModel.raceId"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							formControlName="raceId"
							(clear)="clearRace()"
						>
						</go5-dropdown-selection>
					</span>
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_nationalityid"
							[label]="'emp001_nationality' | translate"
							[mode]="'single'"
							[dataSource]="nationalityDDL"
							[(ngModel)]="employeeModel.nationalityId"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							formControlName="nationalityId"
							(clear)="clearNationality()"
						>
						</go5-dropdown-selection>
					</span>
				</div>

				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_religionid"
							[label]="'emp001_religion' | translate"
							[mode]="'single'"
							[dataSource]="religionDDL"
							[(ngModel)]="employeeModel.religionId"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							formControlName="religionId"
							(clear)="clearReligion()"
						>
						</go5-dropdown-selection>
					</span>
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_marriagestatus"
							[label]="'emp001_marriage_status' | translate"
							[mode]="'single'"
							[dataSource]="marriageStatusDDL"
							[(ngModel)]="employeeModel.marriageStatus"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							formControlName="marriageStatus"
							(clear)="clearMarriage()"
						>
						</go5-dropdown-selection
					></span>
				</div>

				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_militarystatus"
							[label]="'emp001_military_status' | translate"
							[mode]="'single'"
							[dataSource]="militaryStatusDDL"
							[(ngModel)]="employeeModel.militaryStatus"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							formControlName="militaryStatus"
							(clear)="clearMilitary()"
						>
						</go5-dropdown-selection>
					</span>
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_bloodgroup"
							[label]="'emp001_blood_group' | translate"
							[mode]="'single'"
							[dataSource]="bloodTypesDDL"
							[(ngModel)]="employeeModel.bloodGroup"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							formControlName="bloodGroup"
							(clear)="clearBloodGroup()"
						>
						</go5-dropdown-selection
					></span>
				</div>

				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_weightinkgs"
							pKeyFilter="int"
							[label]="'emp001_weight' | translate"
							type="text"
							formControlName="weightInKGS"
							[(ngModel)]="employeeModel.weightInKGS"
						>
						</go5-textfield>
					</span>
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_heightincm"
							pKeyFilter="int"
							[label]="'emp001_height' | translate"
							type="text"
							formControlName="heightInCM"
							[(ngModel)]="employeeModel.heightInCM"
						>
						</go5-textfield>
					</span>
				</div>

				<div class="employee-add-fill-container">
					<span class="employee-add-fill" style="width: 100% !important">
						<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">
							{{ "common_remark" | translate }}
						</div>
						<div>
							<go5-textarea
								[maxlength]="499"
								formControlName="remark"
								category="primary"
								[(ngModel)]="employeeModel.remark"
							></go5-textarea>
						</div>
					</span>
				</div>

				<div class="employee-add-fill-container">
					<span class="employee-add-fill" style="width: 100% !important">
						<span class="d-flex" style="align-items: center">
							<label class="d-flex pr-2 text-header-5 text-color-11" style="align-items: center"
								>{{ "emp001_emp_pic_scan" | translate }}
							</label>
							<einfo-tooltip [ngStyle]="{ display: 'flex' }" key="emp001_emp_pic_scan_tooltip" size="s">
							</einfo-tooltip>
						</span>
						<div class="go5-text-extra-small go5-text-color-9" style="margin-top: 8px">
							{{ "emp001_emp_pic_scan_description" | translate }}
						</div>
						@if (isUploadingimpPic) {
							<div
								class="ui-g-12 p-0 d-flex pb-4"
								style="
									justify-content: flex-start;
									width: 60%;
									flex-direction: column;
									align-items: center;
									margin-top: 8px;
								"
							>
								<span style="margin-top: -100px">
									<app-loading-view [(color)]="colorFlame" [loadingPic]="false"></app-loading-view>
								</span>
								{{ "common_uploading" | translate }}
							</div>
						} @else {
							<div class="ui-g-12 p-0" style="margin-top: 8px">
								<go5-upload-file
									#upload
									[value]="attachment || []"
									[max]="7"
									[maxFileSize]="10000000"
									[allowExtensions]="allowExtensions"
									[multiple]="false"
									[autoResize]="false"
									category="primary"
									(onClickToggle)="toggleClick($event)"
									(selected)="onOpenAttachments($event)"
									(remove)="removeAttachment($event)"
									(change)="setAttachment($event.value)"
								></go5-upload-file>
							</div>
						}
					</span>
				</div>
			}
		</div>
	</ng-container>
</ng-template>
<ng-template #secondForm>
	<ng-container [formGroup]="formEmployeeFourthPage">
		<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
			{{ "emp001_work_contact_information" | translate }}
		</div>
		<div class="employee-add-fills-container">
			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<div style="margin: 0 0 8px">
						<span class="go5-text-color-8 go5-text-body">
							{{ "emp001_company_email" | translate }}
						</span>
						<span>
							<einfo-tooltip
								key="emp001_company_email_tooltip"
								size="s"
								[position]="isFromMobile ? 'right' : 'top'"
								[width]="isFromMobile ? '15em' : ''"
							></einfo-tooltip>
						</span>
					</div>
					<go5-textfield
						data-testid="input_text_emailaddress"
						inputType="email"
						[email]="true"
						(changed)="checkEmailAddress()"
						placeholder="{{ 'emp001_company_email_placeholder' | translate }}"
						formControlName="emailAddress"
						[(ngModel)]="employeeModel.emailAddress"
						[errorMessage]="'common_required' | translate"
					>
					</go5-textfield>
					@if (isPublicDomain) {
						<div class="pt-2">
							<go5-info-bar [type]="infoBarType.Info">
								{{ "emp001_company_email_infobar_public" | translate }}</go5-info-bar
							>
						</div>
					}
					@if (isAddress && (isEmailChanged || !employeeModel.isEmailConfirm)) {
						<div class="pt-2">
							<go5-info-bar [type]="infoBarType.Info">
								{{ "emp001_company_email_infobar_verify" | translate }}</go5-info-bar
							>
						</div>
					}
					@if (isFirstTimeSetEmail) {
						<div class="pt-2">
							<go5-info-bar [type]="infoBarType.Info">
								{{ "emp001_company_email_infobar_suggest" | translate }}</go5-info-bar
							>
						</div>
					}
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_telephoneno"
						[label]="'emp001_telephone_no' | translate"
						type="text"
						pKeyFilter="num"
						[maxLength]="20"
						placeholder="{{ 'emp001_telephone_no_placeholder' | translate }}"
						formControlName="telephoneNo"
						[(ngModel)]="employeeModel.telephoneNo"
					>
					</go5-textfield>
				</span>
			</div>
			@if (!selfUpdate) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-checkbox
							data-testid="input_checkbox_sendemail"
							[(ngModel)]="isSendEmailTutorial"
							[checked]="isSendEmailTutorial"
							formControlName="isSendEmailTutorial"
							label="{{ 'emp001_send_email_tutorial' | translate }}"
							(ngModelChange)="onChangeEmailTutorial()"
						>
						</go5-checkbox>
					</span>
				</div>
			}

			<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
				{{ "emp001_t01_personal_contact_information" | translate }}
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<div style="margin: 0 0 8px">
						<span class="go5-text-color-8 go5-text-body">
							{{ "emp001_personal_email" | translate }}
						</span>
						<span>
							<einfo-tooltip
								key="emp001_personal_email_tooltip"
								size="s"
								[position]="isFromMobile ? 'right' : 'top'"
								[width]="isFromMobile ? '15em' : ''"
							></einfo-tooltip>
						</span>
					</div>
					<go5-textfield
						data-testid="input_text_emailaddresspersonal"
						inputType="email"
						[(ngModel)]="employeeModel.emailAddressPersonal"
						(changed)="checkEmailAddressPersonal()"
						placeholder="{{ 'emp001_personal_email_placeholder' | translate }}"
						formControlName="emailAddressPersonal"
						[errorMessage]="'common_required' | translate"
					>
					</go5-textfield>
					<div class="new-employee-waiting-start-work">
						<go5-checkbox
							class="d-flex"
							data-testid="input_checkbox_sendemail"
							[(ngModel)]="isSendEmailOnboarding"
							[checked]="isSendEmailOnboarding"
							formControlName="isSendEmailOnboarding"
							label="{{ 'obd_send_work_onboard_email' | translate }}"
							(ngModelChange)="onChangeEmailOnboard()"
						>
						</go5-checkbox>
						@if (employeeModel && employeeModel.employeeId && employeeModel.employeeStatus === newEmployeeStatus) {
							<go5-icon
								(click)="previewOnboarding()"
								iconClass="gf-icon-show-all"
								category="normal"
								size="extra-small"
								go5-tooltip-placement="top"
								go5-tooltip-mode="hover"
								go5-tooltip="{{ 'obd_preview_preobd_page_hover_icon' | translate }}"
							/>
						}
					</div>
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_mobileno"
						[label]="'common_mobile_no' | translate"
						type="text"
						pKeyFilter="num"
						[maxLength]="20"
						[(ngModel)]="employeeModel.mobileNo"
						placeholder="{{ 'emp001_mobile_phone_placeholder' | translate }}"
						formControlName="mobileNo"
					>
					</go5-textfield>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_lineid"
						[label]="'emp001_line' | translate"
						type="text"
						[(ngModel)]="employeeModel.lineId"
						placeholder="{{ 'emp001_line_placeholder' | translate }}"
						formControlName="lineId"
					>
					</go5-textfield>
				</span>
				<span class="employee-add-fill"> </span>
			</div>

			<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
				{{ "emp001_t01_register_address" | translate }}
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_address"
						[label]="'common_address' | translate"
						type="text"
						[(ngModel)]="employeeAddress.address"
						formControlName="address"
						placeholder="{{ 'emp001_complete_address' | translate }}"
					>
					</go5-textfield>
				</span>
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_provinceid"
						[label]="'common_province' | translate"
						[mode]="'single'"
						[dataSource]="provinceDDL"
						[(ngModel)]="employeeAddress.provinceId"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						(changed)="getDistrictRegisAddress()"
						formControlName="provinceId"
						[allowShowRemove]="false"
					>
					</go5-dropdown-selection>
				</span>
			</div>
			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_districtid"
						[label]="'common_district' | translate"
						[mode]="'single'"
						[dataSource]="districtDDL"
						[(ngModel)]="employeeAddress.districtId"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						formControlName="districtId"
						[allowShowRemove]="false"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_subdistrict"
						[label]="'common_subdistrict' | translate"
						type="text"
						[(ngModel)]="employeeAddress.subdistrict"
						formControlName="subdistrict"
						placeholder="{{ 'emp001_complete_subdistrict' | translate }}"
					>
					</go5-textfield
				></span>
			</div>
			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_postalcode"
						[label]="'common_postal_code' | translate"
						type="text"
						[maxLength]="5"
						[(ngModel)]="employeeAddress.postalCode"
						formControlName="postalCode"
						placeholder="{{ 'emp001_complete_postal_code' | translate }}"
					>
					</go5-textfield
				></span>
				<span class="employee-add-fill"> </span>
			</div>

			<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
				{{ "emp001_t01_contact_address" | translate }}
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-checkbox
						data-testid="input_checkbox_chkaddress"
						label="{{ 'emp001_t01_use_same_address' | translate }}"
						[(ngModel)]="chkAddress"
						formControlName="chkAddress"
						(ngModelChange)="useSameAddress1()"
					>
					</go5-checkbox>
				</span>
			</div>

			@if (!chkAddress) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_address"
							[label]="'common_address' | translate"
							type="text"
							[(ngModel)]="employeeAddressContact.address"
							formControlName="address_ca"
							placeholder="{{ 'emp001_complete_address' | translate }}"
						>
						</go5-textfield>
					</span>
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_provinceid"
							[label]="'common_province' | translate"
							[mode]="'single'"
							[dataSource]="provinceDDL_ca"
							[(ngModel)]="employeeAddressContact.provinceId"
							(changed)="getDistrictRegisAddress_ca()"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							formControlName="provinceId_ca"
							[allowShowRemove]="false"
						>
						</go5-dropdown-selection>
					</span>
				</div>

				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_districtid"
							[label]="'common_district' | translate"
							[mode]="'single'"
							[dataSource]="districtDDL_ca"
							[(ngModel)]="employeeAddressContact.districtId"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							formControlName="districtId_ca"
							[allowShowRemove]="false"
						>
						</go5-dropdown-selection>
					</span>
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_subdistrict"
							[label]="'common_subdistrict' | translate"
							type="text"
							[(ngModel)]="employeeAddressContact.subdistrict"
							formControlName="subDistrict_ca"
							placeholder="{{ 'emp001_complete_subdistrict' | translate }}"
						>
						</go5-textfield>
					</span>
				</div>

				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_postalcode"
							[label]="'common_postal_code' | translate"
							type="text"
							[maxLength]="5"
							[(ngModel)]="employeeAddressContact.postalCode"
							formControlName="postalCode_ca"
							placeholder="{{ 'emp001_complete_postal_code' | translate }}"
						>
						</go5-textfield>
					</span>
					<span class="employee-add-fill"> </span>
				</div>
			}

			<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
				{{ "emp001_t01_emergency_contacts" | translate }}
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_contactpersonname"
						[label]="'emp001_t03_name' | translate"
						type="text"
						[(ngModel)]="employeeContactPerson.contactPersonName"
						formControlName="contactPersonName"
						placeholder="{{ 'emp001_complete_contract_name' | translate }}"
					>
					</go5-textfield>
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_contactpersonsurname"
						[label]="'emp001_t03_surname' | translate"
						type="text"
						[(ngModel)]="employeeContactPerson.contactPersonSurname"
						formControlName="contactPersonSurname"
						placeholder="{{ 'emp001_complete_contract_surname' | translate }}"
					>
					</go5-textfield>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_contactpersonrelationshipid"
						[label]="'emp001_t03_relationship' | translate"
						[mode]="'single'"
						[dataSource]="relationShipDDL"
						[(ngModel)]="employeeContactPerson.contactPersonRelationShipId"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						formControlName="contactPersonRelationShipId"
						(clear)="clareDropdownRelationShip()"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_contactpersonphoneno"
						[label]="'common_mobile_no' | translate"
						type="text"
						[(ngModel)]="employeeContactPerson.contactPersonPhoneNo"
						formControlName="contactPersonPhoneNo"
						placeholder="{{ 'emp001_complete_contact_phone_number' | translate }}"
					>
					</go5-textfield
				></span>
			</div>
		</div>
	</ng-container>
</ng-template>
<ng-template #thirdForm>
	<ng-container [formGroup]="formEmployeeSecondPage">
		<div class="employee-add-fills-container">
			<div class="employee-add-fill-container">
				<span class="employee-add-fill" style="width: 100% !important">
					@if (employeeModel.employeeId != null && !isLoadInfoBar) {
						<info-bar [type]="MessageWarningType.Movement" [employeeId]="employeeModel.employeeId"></info-bar>
					}
				</span>
			</div>
			<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
				{{ "emp001_corporate_information" | translate }}
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_companyid"
						[label]="('common_company' | translate) + '*'"
						[mode]="'single'"
						[dataSource]="companyDDL"
						[(ngModel)]="employeeModel.companyId"
						[allowLocalize]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						(changed)="changeCompany()"
						formControlName="companyId"
						[allowShowRemove]="false"
						[error]="
							!formEmployeeSecondPage.controls['companyId'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['companyId'].touched)
						"
						[errorMessage]="'common_required' | translate"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_orgNode"
						[mode]="'single'"
						[allowLocalize]="false"
						[label]="('emp001_department' | translate) + '*'"
						[dataSource]="organizeNodeDDL"
						popupType="slide"
						categoryItem="item"
						[fields]="data.getIsTH(data.currentLanguage | async) ? filedOrganize : filedOrganizeEN"
						formControlName="departmentId"
						[(ngModel)]="employeeModel.departmentId"
						placeholder="{{ 'common_default_select' | translate }}"
						[error]="
							!formEmployeeSecondPage.controls['departmentId'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['departmentId'].touched)
						"
						[errorMessage]="'common_required' | translate"
					>
					</go5-dropdown-selection>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_rankno"
						[allowLocalize]="false"
						[label]="('emp001_rank' | translate) + '*'"
						[mode]="'single'"
						[dataSource]="ranksDDL"
						[(ngModel)]="employeeModel.rankNo"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						(changed)="getPositionDDL()"
						formControlName="rankNo"
						[allowShowRemove]="false"
						[error]="
							!formEmployeeSecondPage.controls['rankNo'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['rankNo'].touched)
						"
						[errorMessage]="'common_required' | translate"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill">
					<go5-dropdown-selection
						#dropdownPosition
						data-testid="dropdown_single_positionid"
						[allowLocalize]="false"
						[label]="('emp001_position' | translate) + '*'"
						[mode]="'single'"
						[dataSource]="positionDDL"
						[(ngModel)]="employeeModel.positionId"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						formControlName="positionId"
						[allowShowRemove]="false"
						[error]="
							!formEmployeeSecondPage.controls['positionId'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['positionId'].touched)
						"
						[errorMessage]="'common_required' | translate"
						[isShowFooter]="authorizePosition?.allowAdd"
					>
						<ng-template #footerTemplate>
							<div class="employee-footer-add-position" (click)="displayPositionAdd = true; dropdownPosition.hide()">
								<span class="employee-footer-add-icon"> <em class="gf-icon-add"></em></span>
								<span> {{ "mas005_add_position" | translate }}</span>
							</div>
						</ng-template>
					</go5-dropdown-selection>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_employeeno"
						[label]="('emp001_employee_no' | translate) + '*'"
						type="text"
						[(ngModel)]="employeeModel.employeeNo"
						placeholder="{{ 'emp001_employee_no_placeholder' | translate }}"
						formControlName="employeeNo"
						[error]="
							(!formEmployeeSecondPage.controls['employeeNo'].valid &&
								(isSubmittedThirdPage || formEmployeeSecondPage.controls['employeeNo'].touched)) ||
							isDuplicateEmployeeNo
						"
						[errorMessage]="
							isDuplicateEmployeeNo && isEdit
								? ('validate_employee_code_duplicate' | translate)
								: isDuplicateEmployeeNo
									? ('validate_fill_new_employee_code' | translate)
									: ('common_required' | translate)
						"
						(changed)="isDuplicateEmployeeNo = false"
					>
					</go5-textfield>
				</span>
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_worklocationid"
						[label]="('emp001_work_location' | translate) + (userInfo?.packageId == packageEnum?.Starter ? '*' : '')"
						[mode]="'single'"
						[dataSource]="workLocationDDL"
						[(ngModel)]="employeeModel.workLocationId"
						[allowLocalize]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsBranchSSODDL : fieldsBranchSSODDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						formControlName="workLocationId"
						[allowShowRemove]="false"
						[error]="
							!formEmployeeSecondPage.controls['workLocationId'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['workLocationId'].touched)
						"
						[errorMessage]="'common_required' | translate"
					>
					</go5-dropdown-selection>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_shiftid"
						[label]="('emp001_t06_shift' | translate) + '*'"
						[mode]="'single'"
						[dataSource]="shiftDDL"
						[(ngModel)]="employeeModel.shiftId"
						[allowLocalize]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						(changed)="getShiftInfo($event)"
						formControlName="shiftId"
						[allowShowRemove]="false"
						[error]="
							!formEmployeeSecondPage.controls['shiftId'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['shiftId'].touched)
						"
						[errorMessage]="'common_required' | translate"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill">
					<go5-datepicker
						data-testid="input_calendar_dateemployment"
						[label]="('emp001_date_employment' | translate) + '*'"
						[format]="dateFormat.dateShort"
						formControlName="dateEmployment"
						placeholder="{{ 'common_placeholder_date' | translate }}"
						[(ngModel)]="employeeModel.dateEmployment"
						[isShowTime]="false"
						[error]="
							!formEmployeeSecondPage.controls['dateEmployment'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['dateEmployment'].touched)
						"
						[errorMessage]="'common_required' | translate"
					></go5-datepicker>
				</span>
			</div>
			@if (isShowInfo) {
				<div class="shift-edit-info-container d-flex">
					<go5-info-bar data-testid="info_shift_shiftEdit" [type]="infoBarType.Info">
						{{
							(employeeModel.dateEmployment > currentDate
								? "infobar_profile_emp_info_change_shift_2"
								: "infobar_profile_emp_info_change_shift"
							) | translate
						}}</go5-info-bar
					>
				</div>
			}
			@if (!isEdit && !isWorkInformation) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill d-flex">
						<go5-checkbox
							[checked]="isShiftStartDate"
							label="{{ 'emp001_is_shift_start_date' | translate }}"
							(changed)="shiftStartDate($event)"
						></go5-checkbox>
						<einfo-tooltip
							class="pl-2 d-flex"
							key="{{ 'tooltip_shift_start_date' | translate }}"
							size="s"
							[ngStyle]="{ 'align-items': 'center' }"
							[position]="isFromMobile ? 'right' : 'top'"
							[width]="isFromMobile ? '15em' : ''"
						>
						</einfo-tooltip>
					</span>
				</div>
			}

			@if (isShiftStartDate) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-datepicker
							data-testid="input_calendar_shiftStartDate"
							[format]="dateFormat.dateShort"
							formControlName="shiftStartDate"
							placeholder="{{ 'common_placeholder_date' | translate }}"
							[(ngModel)]="employeeModel.shiftStartDate"
							[isShowTime]="false"
							[label]="('emp001_shift_start_date' | translate) + '*'"
							[error]="
								!formEmployeeSecondPage.controls['shiftStartDate'].valid &&
								(isSubmittedThirdPage || formEmployeeSecondPage.controls['shiftStartDate'].touched)
							"
							[errorMessage]="'common_required' | translate"
						></go5-datepicker>
					</span>
					<span class="employee-add-fill"> </span>
				</div>
			}

			<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
				{{ "emp001_work_information" | translate }}
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_employeestatus"
						[label]="('emp001_employee_status' | translate) + '*'"
						[dataSource]="employeeStatusDDL"
						[allowLocalize]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[allowShowRemove]="false"
						cssClass="dropdown-large-primary"
						mode="single"
						formControlName="employeeStatus"
						[error]="
							!formEmployeeSecondPage.controls['employeeStatus'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['employeeStatus'].touched)
						"
						[errorMessage]="'common_required' | translate"
						[(ngModel)]="employeeModel.employeeStatus"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_employmenttype"
						[label]="('emp001_employment_type' | translate) + '*'"
						[dataSource]="employmentTypesDDL"
						[allowLocalize]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[allowShowRemove]="false"
						cssClass="dropdown-large-primary"
						mode="single"
						formControlName="employmentType"
						[error]="
							!formEmployeeSecondPage.controls['employmentType'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['employmentType'].touched)
						"
						[errorMessage]="'common_required' | translate"
						[(ngModel)]="employeeModel.employmentType"
					>
					</go5-dropdown-selection>
				</span>
			</div>
			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_uppersupervisorid"
						[label]="'emp_details_report_to' | translate"
						[dataSource]="listPeople"
						[allowLocalize]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? peopleFields : peopleFields_EN"
						(filtering)="onFilterDdlUser($event)"
						placeholder="{{ 'emp001_upper_chain_of_command_placeholder' | translate }}"
						(clear)="clarePeople()"
						cssClass="dropdown-large-primary"
						mode="single"
						formControlName="upperSupervisorId"
						[(ngModel)]="employeeModel.upperSupervisorId"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill">
					<go5-datepicker
						data-testid="input_calendar_datecontractexpired"
						[label]="'emp001_date_contract_expired' | translate"
						[allowShowRemove]="true"
						[format]="dateFormat.dateShort"
						formControlName="dateContractExpired"
						placeholder="{{ 'common_placeholder_date' | translate }}"
						[(ngModel)]="employeeModel.dateContractExpired"
						[isShowTime]="false"
					></go5-datepicker>
				</span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						data-testid="dropdown_single_calendarid"
						[label]="'emp001_t04_holiday_calendar' | translate"
						[dataSource]="calendars"
						[allowLocalize]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						(clear)="clareCalendar()"
						cssClass="dropdown-large-primary"
						mode="single"
						formControlName="calendarId"
						[(ngModel)]="employeeModel.calendarId"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill">
					<go5-textfield
						data-testid="input_text_driverno"
						[label]="'emp001_driver_number' | translate"
						type="text"
						maxlength="50"
						placeholder="{{ 'emp001_driver_number_placeholder' | translate }}"
						formControlName="driverNo"
						[(ngModel)]="employeeModel.driverNo"
					>
					</go5-textfield>
				</span>
			</div>
			@if (isWorkInformation && employeeModel.employeeStatus == employmentStatusResign) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-datepicker
							data-testid="input_calendar_datetermination"
							[format]="dateFormat.dateShort"
							[allowShowRemove]="true"
							formControlName="dateTermination"
							placeholder="{{ 'common_placeholder_date' | translate }}"
							[(ngModel)]="employeeModel.dateTermination"
							[label]="'emp011_date_resign' | translate"
							[isShowTime]="false"
						></go5-datepicker>
					</span>
					<span class="employee-add-fill"></span>
				</div>
			}

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">
						{{ "emp001_rate_type" | translate }}*
					</div>
					<go5-selection-single-group
						[dataSource]="replaceSelected"
						[fields]="fieldsDDL"
						[(ngModel)]="employeeModel.isReplaceEmployee"
						(selected)="onChangeReplaceEmployee($event)"
						formControlName="isReplaceEmployee"
					></go5-selection-single-group>
				</span>
				<span class="employee-add-fill">
					<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">
						{{ "emp001_t04_record_time" | translate }}*
					</div>
					<go5-selection-single-group
						[dataSource]="recordSelected"
						[fields]="fieldsDDL"
						formControlName="isAttendance"
						[(ngModel)]="employeeModel.isAttendance"
					></go5-selection-single-group>
				</span>
			</div>

			@if (employeeModel.isReplaceEmployee) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill d-flex" style="align-items: end">
						@if (data.getIsTH(data.currentLanguage | async)) {
							<go5-dropdown-selection
								class="w-100"
								data-testid="input_text_uppersupervisorname"
								[dataSource]="listOfEmployees"
								[fields]="data.getIsTH(data.currentLanguage | async) ? empFields : empFields_EN"
								mode="single"
								cssClass="dropdown-large-primary"
								[label]="('emp001_replace_employee' | translate) + '*'"
								[(ngModel)]="replacedEmpName"
								[placeholder]="'popup_searchemployee_click_search_employee' | translate"
								formControlName="replaceEmployeeId"
								(selected)="getReplaceEmployee($event)"
								(clear)="clearReplacedEmployee()"
								(filtering)="onFilterEmployee($event)"
								[error]="
									!formEmployeeSecondPage.controls['replaceEmployeeId'].valid &&
									(isSubmittedThirdPage || formEmployeeSecondPage.controls['replaceEmployeeId'].touched)
								"
								[errorMessage]="'common_required' | translate"
							></go5-dropdown-selection>
						} @else {
							<go5-dropdown-selection
								class="w-100"
								data-testid="input_text_uppersupervisorname"
								[dataSource]="listOfEmployees"
								[fields]="data.getIsTH(data.currentLanguage | async) ? empFields : empFields_EN"
								mode="single"
								cssClass="dropdown-large-primary"
								[label]="('emp001_replace_employee' | translate) + '*'"
								[(ngModel)]="replacedEmpName"
								[placeholder]="'popup_searchemployee_click_search_employee' | translate"
								formControlName="replaceEmployeeId"
								(selected)="getReplaceEmployee($event)"
								(clear)="clearReplacedEmployee()"
								(filtering)="onFilterEmployee($event)"
								[error]="
									!formEmployeeSecondPage.controls['replaceEmployeeId'].valid &&
									(isSubmittedThirdPage || formEmployeeSecondPage.controls['replaceEmployeeId'].touched)
								"
								[errorMessage]="'common_required' | translate"
							>
							</go5-dropdown-selection>
						}
					</span>
					<span class="employee-add-fill"> </span>
				</div>
			}
			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<div style="margin: 0 0 8px">
						<span class="go5-text-color-8 go5-text-body">
							{{ ("common_employment_type" | translate) + "*" }}
						</span>
						<span>
							<einfo-tooltip
								key="emp001_t14_employment_type_tooltip"
								size="s"
								[position]="isFromMobile ? 'right' : 'top'"
								[width]="isFromMobile ? '15em' : ''"
							></einfo-tooltip>
						</span>
					</div>
					<go5-dropdown-selection
						data-testid="dropdown_single_paymentmethod"
						[dataSource]="paymentMethodDDL"
						[allowLocalize]="false"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						mode="single"
						[allowShowRemove]="false"
						[(ngModel)]="employeeModel.paymentMethod"
						formControlName="paymentMethodId"
						(changed)="checkTypePayment($event)"
						[error]="
							!formEmployeeSecondPage.controls['paymentMethodId'].valid &&
							(isSubmittedThirdPage || formEmployeeSecondPage.controls['paymentMethodId'].touched)
						"
						[errorMessage]="'common_required' | translate"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill"></span>
			</div>

			@if (isChangePaymentType) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-info-bar [type]="infoBarType.Info">
							<div class="d-grid">
								<span>
									<span>{{ "emp001_t14_employment_type_changed_infobar_1" | translate }}</span>
									<span>
										{{ "emp001_t14_employment_type_changed_infobar_2" | translate }}
									</span>
									<span>{{ "emp001_t14_employment_type_changed_infobar_3" | translate }}</span>
									<span style="text-decoration: underline; cursor: pointer" (click)="gotoManual()">{{
										"emp001_t14_employment_type_changed_infobar_4" | translate
									}}</span>
								</span>
							</div>
						</go5-info-bar>
					</span>
				</div>
			}

			<div class="employee-add-subtitle d-flex">
				<span class="go5-text-header-5 go5-text-color-12">
					{{ "emp001_payment_information" | translate }}
				</span>
				@if (!userInfo?.hasPayroll) {
					<span class="icon-lock-payroll-26" (click)="openDialogUpgradePlan()" style="cursor: pointer"> </span>
				}
			</div>
			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-selection-single-group
						[dataSource]="isCashOption"
						[fields]="fieldsDDL"
						formControlName="isCash"
						[(ngModel)]="employeeModel.isCash"
						(selected)="onChangedCash1($event.value)"
					></go5-selection-single-group>
				</span>
			</div>

			@if (!employeeModel.isCash) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_bankId"
							[mode]="'single'"
							[dataSource]="sysBankDDL"
							[allowLocalize]="false"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							[(ngModel)]="employeeModel.bankId"
							formControlName="bankId"
							[allowShowRemove]="false"
							[label]="('emp001_t04_bank_account' | translate) + (userInfo?.hasPayroll ? '*' : '')"
							[disabled]="!userInfo?.hasPayroll"
							(open)="openPopup($event)"
							[error]="
								!formEmployeeSecondPage.controls['bankId'].valid &&
								(isSubmittedThirdPage || formEmployeeSecondPage.controls['bankId'].touched)
							"
							[errorMessage]="'common_required' | translate"
						>
							<ng-template #valueTemplate let-item>
								<div class="content" style="display: flex">
									<em [class]="item.value"></em>
									<span class="ml-2 go5-dropdown-selection-value-single" style="align-self: center">{{
										data.getIsTH(data.currentLanguage | async) ? item.name : item.name_EN
									}}</span>
								</div>
							</ng-template>
						</go5-dropdown-selection>
					</span>
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_bankaccountno"
							[label]="('emp001_t04_bank_account_no' | translate) + (userInfo?.hasPayroll ? '*' : '')"
							type="text"
							pKeyFilter="int"
							[min]="10"
							[max]="10"
							placeholder="{{ 'emp001_t04_bank_account_no_placeholder' | translate }}"
							[(ngModel)]="employeeModel.bankAccountNo"
							[error]="
								!formEmployeeSecondPage.controls['bankAccountNo'].valid &&
								(isSubmittedThirdPage || formEmployeeSecondPage.controls['bankAccountNo'].touched)
							"
							[errorMessage]="'common_required' | translate"
							formControlName="bankAccountNo"
							[disabled]="!userInfo?.hasPayroll"
						>
						</go5-textfield>
					</span>
				</div>
			}
		</div>
	</ng-container>
</ng-template>
<ng-template #fourthForm>
	<ng-container [formGroup]="formEmployeeThirdPage">
		<div class="employee-add-fills-container">
			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">
						{{ "emp001_t14_payment_method" | translate }}*
					</div>
					<go5-selection-single-group
						[dataSource]="isCashOption"
						[fields]="fieldsDDL"
						formControlName="isCash"
						[(ngModel)]="employeeModel.isCash"
						(selected)="onChangedCash($event.value)"
					></go5-selection-single-group
				></span>
			</div>

			@if (!employeeModel.isCash) {
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-dropdown-selection
							[label]="('emp001_t04_bank_account' | translate) + '*'"
							data-testid="dropdown_single_bankId"
							[mode]="'single'"
							[dataSource]="sysBankDDL"
							[allowLocalize]="false"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							[(ngModel)]="employeeModel.bankId"
							formControlName="bankId"
							[allowShowRemove]="false"
							[error]="
								!formEmployeeThirdPage.controls['bankId'].valid &&
								(isSubmittedThirdPage || formEmployeeThirdPage.controls['bankId'].touched)
							"
							[errorMessage]="'common_required' | translate"
						>
							<ng-template #valueTemplate let-item>
								<div class="content" style="display: flex">
									<em [class]="item.value"></em>
									<span class="ml-2" style="align-self: center">{{
										data.getIsTH(data.currentLanguage | async) ? item.name : item.name_EN
									}}</span>
								</div>
							</ng-template>
						</go5-dropdown-selection></span
					>
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_bankaccountno"
							[label]="('emp001_t04_bank_account_no' | translate) + '*'"
							type="text"
							pKeyFilter="int"
							[min]="10"
							[max]="10"
							placeholder="{{ 'emp001_t04_bank_account_no_placeholder' | translate }}"
							[(ngModel)]="employeeModel.bankAccountNo"
							[error]="
								!formEmployeeThirdPage.controls['bankId'].valid &&
								(isSubmittedThirdPage || formEmployeeThirdPage.controls['bankId'].touched)
							"
							[errorMessage]="'common_required' | translate"
							formControlName="bankAccountNo"
						>
						</go5-textfield>
					</span>
				</div>
			}

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-dropdown-selection
						[label]="('emp001_t04_pay_type' | translate) + '*'"
						data-testid="dropdown_single_paymentmethod"
						[mode]="'single'"
						[dataSource]="paymentMethodDDL"
						[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
						placeholder="{{ 'common_default_select' | translate }}"
						[height]="'40px'"
						[(ngModel)]="employeeModel.paymentMethod"
						formControlName="paymentMethodId"
						[allowShowRemove]="false"
						[error]="
							!formEmployeeThirdPage.controls['paymentMethodId'].valid &&
							(isSubmittedThirdPage || formEmployeeThirdPage.controls['paymentMethodId'].touched)
						"
						[errorMessage]="'common_required' | translate"
					>
					</go5-dropdown-selection>
				</span>
				<span class="employee-add-fill"></span>
			</div>

			<div class="employee-add-fill-container">
				<span class="employee-add-fill">
					<go5-textfield
						[label]="('emp001_salary' | translate) + '*'"
						type="currency"
						category="primary"
						[step]="1"
						format="0.0-2"
						[(ngModel)]="employeeModel.amount"
						formControlName="salary"
					></go5-textfield>
				</span>
				<span class="employee-add-fill"></span>
			</div>
		</div>
	</ng-container>
</ng-template>

<ng-template #fifthForm>
	@if (!isEdit || isSocialSecurity) {
		<ng-container [formGroup]="formEmployeeSSO">
			<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
				{{ "common_social_security_info" | translate }}
			</div>

			<div
				*ngIf="!selfUpdate"
				class="employee-add-fill-container"
				style="
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding-bottom: 16px;
					align-self: stretch;
				"
			>
				<span class="employee-add-fill">
					<go5-checkbox
						data-testid="input_checkbox_issoc"
						[disabled]="false"
						[checked]="employeeSocialSecurity.isSoc"
						label="{{ 'emp001_t09_isSoc' | translate }}"
						formControlName="isSoc"
						[indeterminate]="false"
						state="default"
					></go5-checkbox>
				</span>
			</div>
			<div class="employee-add-fills-container">
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_socialsecurityno"
							[label]="('emp001_t09_social_security_no' | translate) + '*'"
							type="text"
							formControlName="socialSecurityNo"
							[(ngModel)]="employeeSocialSecurity.socialSecurityNo"
							placeholder="{{ 'emp001_t09_social_security_no' | translate }}"
							[error]="!formEmployeeSSO.controls['socialSecurityNo'].valid && isSubmitSSO"
							[errorMessage]="'common_required' | translate"
						>
						</go5-textfield>
					</span>

					<ng-template #typeCardDropdown>
						<span class="employee-add-fill">
							<go5-dropdown-selection
								data-testid="dropdown_single_typecardsso"
								[label]="'emp001_t09_typecard_sso_global' | translate"
								[mode]="'single'"
								[dataSource]="typeCardDDL"
								formControlName="typeCardSSO"
								[(ngModel)]="employeeSocialSecurity.typeCardSSO"
								(selected)="onSelectCardType($event)"
								[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
								placeholder="{{ 'common_default_select' | translate }}"
								[height]="'40px'"
								(clear)="clearCardType()"
							>
							</go5-dropdown-selection>
						</span>
					</ng-template>
					<ng-template [ngIf]="selfUpdate" [ngIfThen]="hospitalDropdown" [ngIfElse]="typeCardDropdown"> </ng-template>
				</div>
				<div *ngIf="!selfUpdate" class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_nationalityid"
							[label]="'emp001_t09_nationality_sso' | translate"
							[mode]="'single'"
							[dataSource]="nationalitySSODDL"
							formControlName="nationalitySSOId"
							[(ngModel)]="employeeSocialSecurity.nationalitySSOId"
							(selected)="onSelectNationality($event)"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsNationalityDDL : fieldsNationalityDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							(clear)="clearNationality(true)"
						>
						</go5-dropdown-selection>
					</span>
					<ng-container [ngTemplateOutlet]="hospitalDropdown"> </ng-container>
				</div>
				<div class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_hospitalidtwo"
							[label]="'common_second_choice_hospital' | translate"
							[mode]="'single'"
							[dataSource]="hospitalDDL"
							formControlName="hospitalIdTwo"
							[(ngModel)]="employeeSocialSecurity.hospitalIdTwo"
							(selected)="onSelectHospital($event, 'hospitalIdTwo')"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsHospitalDDL : fieldsHospitalDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							(clear)="clearHospital('hospitalIdTwo')"
							(filtering)="onFilterHospital($event)"
						>
						</go5-dropdown-selection>
					</span>
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_hospitalidthree"
							[label]="'common_third_choice_hospital' | translate"
							[mode]="'single'"
							[dataSource]="hospitalDDL"
							formControlName="hospitalIdThree"
							[(ngModel)]="employeeSocialSecurity.hospitalIdThree"
							(selected)="onSelectHospital($event, 'hospitalIdThree')"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsHospitalDDL : fieldsHospitalDDL_EN"
							placeholder="{{ 'common_default_select' | translate }}"
							[height]="'40px'"
							(clear)="clearHospital('hospitalIdThree')"
							(filtering)="onFilterHospital($event)"
						>
						</go5-dropdown-selection>
					</span>
				</div>

				<div *ngIf="!selfUpdate" class="employee-add-fill-container">
					<span class="employee-add-fill">
						<go5-datepicker
							data-testid="input_calendar_datereceivedsso"
							[label]="'emp001_t09_date_received_sso' | translate"
							[format]="dateFormat.dateShort"
							placeholder="{{ 'common_placeholder_date' | translate }}"
							formControlName="dateReceivedSSO"
							[(ngModel)]="employeeSocialSecurity.dateReceivedSSO"
							[isShowTime]="false"
							[allowShowRemove]="true"
						></go5-datepicker>
					</span>
					<span class="employee-add-fill">
						<go5-dropdown-selection
							data-testid="dropdown_single_resignationssoreason"
							[label]="'resignation_sso_reason' | translate"
							[mode]="'single'"
							[dataSource]="reasonToLeaveDDL"
							formControlName="terminationReasonSSOId"
							[(ngModel)]="employeeSocialSecurity.terminationReasonSSOId"
							(selected)="onSelectReason($event)"
							(clear)="clearReason()"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsMasterTypeDDL : fieldsMasterTypeDDL_EN"
							placeholder="{{ 'common_please_choose' | translate }}"
							[height]="'40px'"
							[allowFiltering]="false"
						>
						</go5-dropdown-selection>
					</span>
				</div>

				<div *ngIf="!selfUpdate" class="employee-add-fill-container">
					<span class="employee-add-fill" style="width: 100% !important">
						<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">
							{{ "common_remark" | translate }}
						</div>
						<div>
							<go5-textarea
								data-testid="input_textarea_remarksso"
								[maxlength]="499"
								category="primary"
								formControlName="evidenceOfRegistrationSSO"
								[(ngModel)]="employeeSocialSecurity.evidenceOfRegistrationSSO"
							></go5-textarea>
						</div>
					</span>
				</div>

				<div
					*ngIf="!selfUpdate"
					class="employee-add-fill-container"
					style="display: flex; flex-direction: column; align-items: flex-start; gap: 16px; align-self: stretch"
				>
					<span>
						<go5-checkbox
							data-testid="input_checkbox_securityBranch"
							class="go5-text-color-12"
							[checked]="isSetSocialSecurityBranch"
							label="{{ 'emp001_social_security_office_branch_checkbox' | translate }}"
							(changed)="onChangeSecurityBranch()"
						>
						</go5-checkbox>
					</span>
					<span class="employee-add-fill" *ngIf="isSetSocialSecurityBranch">
						<go5-dropdown-selection
							data-testid="dropdown_single_branch_sso"
							[mode]="'single'"
							[label]="'emp001_t09_branch_sso' | translate"
							[dataSource]="workLocationDDL"
							formControlName="branchId"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsBranchSSODDL : fieldsBranchSSODDL_EN"
							[(ngModel)]="employeeSocialSecurity.branchId"
							placeholder="{{ 'common_default_select' | translate }}"
							[allowShowRemove]="true"
							[height]="'40px'"
						>
						</go5-dropdown-selection>
					</span>
					<span>
						<go5-checkbox
							data-testid="input_checkbox_setkortor20"
							[disabled]="false"
							[checked]="isSetKorTor20"
							label="{{ 'emp001_kortor20_kor_checkbox' | translate }}"
							[indeterminate]="false"
							state="default"
							(changed)="onChangeKorTor20()"
						>
							<em
								class="gf-icon-empeo-information-vector"
								go5-tooltip-placement="top"
								go5-tooltip-mode="hover"
								go5-tooltip="{{ 'emp001_kortor20_kor_tooltip' | translate }}"
								style="cursor: pointer; padding-left: 4px"
							></em
						></go5-checkbox>
					</span>
				</div>
				<div class="employee-add-fill-container" *ngIf="!selfUpdate && isSetKorTor20">
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_text_business_code"
							type="text"
							formControlName="businessCode"
							[label]="'sys008_business_code' | translate"
							[(ngModel)]="employeeSocialSecurity.businessCode"
							[placeholder]=""
						>
						</go5-textfield>
					</span>
					<span class="employee-add-fill">
						<go5-textfield
							data-testid="input_number_contribution_rate"
							type="number"
							[label]="'sys008_insurance_contribution_rate' | translate"
							formControlName="rate"
							[(ngModel)]="employeeSocialSecurity.rate"
							[placeholder]=""
							[format]="'0.0-2'"
							[step]="0.05"
							unit="%"
							min="0.1"
							max="1"
						>
						</go5-textfield>
					</span>
				</div>
			</div>
		</ng-container>
	}
</ng-template>
<ng-template #sixthForm>
	@if (customFields?.length || isAdditionalField) {
		<app-employee-custom-field
			[customFields]="customFields"
			[employeeId]="employeeModel?.employeeId"
			[toggleSave]="tmpSaveCustomField"
			[selfUpdate]="
				employeeModel.employeeStatus === employmentStatusWaitingWork && employeeModel.isSendEmailOnboarding
					? true
					: employeeModel?.isSelfUpdate
			"
			(saveSuccess)="isAdditionalField ? checkSelfUpdate() : skip()"
			[isShowHeader]="!isAdditionalField"
			[needToast]="!selfUpdate"
			(isShowDiscardChange)="onShowDiscardChange($event)"
		>
		</app-employee-custom-field>
	}
</ng-template>

<ng-template [formGroup]="formEmployeeSSO" #hospitalDropdown>
	<span class="employee-add-fill">
		<go5-dropdown-selection
			data-testid="dropdown_single_hospitalid"
			[label]="'common_first_choice_hospital' | translate"
			[mode]="'single'"
			[dataSource]="hospitalDDL"
			formControlName="hospitalId"
			[(ngModel)]="employeeSocialSecurity.hospitalId"
			(selected)="onSelectHospital($event, 'hospitalId')"
			[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsHospitalDDL : fieldsHospitalDDL_EN"
			placeholder="{{ 'common_default_select' | translate }}"
			[height]="'40px'"
			(clear)="clearHospital('hospitalId')"
			(filtering)="onFilterHospital($event)"
		>
		</go5-dropdown-selection>
	</span>
</ng-template>

<div class="ui-g-12" [ngClass]="{ 'p-0': isEdit }">
	<div id="footer" class="footer-employee-add bg-default">
		@if (!selfUpdate) {
			@if (!isEdit) {
				<div class="ui-g-12 d-flex align-center content-space-between" style="padding: 17px">
					<div class="ui-g-6 text-left d-flex p-0">
						<ng-template [ngIf]="userInfo?.packageId != packageEnum?.Starter">
							<ng-container *ngTemplateOutlet="toggleSelfService"></ng-container>
						</ng-template>
					</div>
					@switch (currentEmployeeIndex) {
						@case (1) {
							<div class="ui-g-6 text-right p-0">
								<go5-button
									data-testid="button_button_next"
									type="button"
									category="primary"
									label="{{ 'common_next' | translate }}"
									(click)="next()"
								></go5-button>
							</div>
						}
						@case (2) {
							<div class="ui-g-6 text-right p-0">
								<go5-button
									data-testid="button_button_next2"
									type="button"
									category="primary"
									label="{{ 'common_next' | translate }}"
									(click)="next()"
								></go5-button>
							</div>
						}
						@case (3) {
							<div class="ui-g-6 p-0 text-right">
								<go5-button
									data-testid="button_button_save"
									type="button"
									category="primary"
									[label]="
										(userInfo?.hasPayroll ? 'common_next' : customFields?.length ? 'common_next' : 'common_save')
											| translate
									"
									(click)="next()"
								></go5-button>
							</div>
						}
						@case (4) {
							@if (userInfo?.hasPayroll) {
								<div class="ui-g-6 p-0 text-right d-flex align-center content-flex-end">
									<div>
										<go5-button
											data-testid="button_button_skip"
											type="button"
											category="secondary"
											label="{{ 'common_skip' | translate }}"
											(click)="skipPayroll()"
										></go5-button>
									</div>
									<div style="margin-left: 8px">
										<go5-button
											data-testid="button_button_next2"
											type="button"
											color="#00cc99"
											[category]="'primary'"
											label="{{ 'common_next' | translate }}"
											(click)="next()"
										></go5-button>
									</div>
								</div>
							} @else {
								<div class="ui-g-6 text-right p-0">
									<go5-button
										data-testid="button_button_next2"
										type="button"
										category="primary"
										label="{{ 'common_next' | translate }}"
										(click)="next()"
									></go5-button>
								</div>
							}
						}
						@case (5) {
							@if (customFields?.length > 0) {
								<div class="ui-g-6 text-right p-0">
									<go5-button
										data-testid="button_button_next2"
										type="button"
										category="primary"
										label="{{ 'common_next' | translate }}"
										(click)="next()"
									></go5-button>
								</div>
							} @else {
								<div style="margin-left: 8px">
									<go5-button
										data-testid="button_button_saveTransaction"
										type="button"
										category="color"
										color="#00cc99"
										label="{{ 'common_finished' | translate }}"
										(click)="saveTransaction()"
									></go5-button>
								</div>
							}
						}
						@default {
							<div class="ui-g-6 p-0 text-right d-flex align-center content-flex-end">
								<div style="margin-left: 8px">
									<go5-button
										data-testid="button_button_saveCustomField"
										category="color"
										color="#00cc99"
										type="button"
										label="{{ 'common_finished' | translate }}"
										(click)="saveCustomField()"
									></go5-button>
								</div>
							</div>
						}
					}
				</div>
			} @else {
				<div class="ui-g-12 flex-end" style="padding: 17px; align-items: center">
					@if (!isAddress && !isWorkInformation) {
						@if (!isFromMobile) {
							<div
								class="ui-g-6 text-left d-flex p-0"
								[ngStyle]="selfUpdate && isFinished ? { visibility: 'hidden' } : { visibility: '' }"
							>
								<ng-template [ngIf]="userInfo?.packageId != packageEnum?.Starter && !selfUpdate">
									<ng-container *ngTemplateOutlet="toggleSelfService"></ng-container>
								</ng-template>
							</div>
						}
						<div [ngClass]="{ 'ui-g-12': isFromMobile, 'ui-g-6': !isFromMobile }" class="ui-g-6 text-right p-0">
							@if (!selfUpdate) {
								<go5-button
									class="mr-2 pl-3"
									label="{{ 'common_cancel' | translate }}"
									category="secondary"
									size="medium"
									type="button"
									(click)="backToProfile('Employee_Employment_Details_Personal_Cancel')"
								></go5-button>
							}

							<go5-button
								[ngStyle]="{ width: isFromMobile ? '100%' : '' }"
								[width]="isFromMobile ? '100%' : ''"
								label="{{
									(selfUpdate ? (isAdditionalField && isFinished ? 'common_finished' : 'common_next') : 'common_save')
										| translate
								}}"
								[category]="selfUpdate && isAdditionalField && isFinished ? 'color' : 'primary'"
								size="medium"
								type="button"
								color="#00cc99"
								[disabled]="!permission.allowEdit && !isMe"
								(click)="isAdditionalField ? saveCustomField() : saveEdit()"
							></go5-button>
						</div>
					}
					@if (isAddress) {
						@if (selfUpdate) {
							<div class="ui-g-6 text-left d-flex p-0">
								<go5-button
									[ngStyle]="{ width: isFromMobile ? '100%' : '' }"
									[width]="isFromMobile ? '100%' : ''"
									data-testid="button_button_changepage"
									label="{{ 'common_prev' | translate }}"
									(click)="changePage('emp001_employee_info', -1)"
									icon="icon-vector"
									category="secondary"
									size="medium"
									iconPos="left"
								></go5-button>
							</div>
						}

						<div class="text-right" [ngClass]="isFromMobile ? 'ui-g-6 p-0 pl-2' : 'ui-g-12 p-0'">
							<span>
								@if (!selfUpdate) {
									<go5-button
										class="mr-2"
										label="{{ 'common_cancel' | translate }}"
										category="secondary"
										size="medium"
										type="button"
										(click)="backToProfile('Employment_Details_Cancel')"
									></go5-button>
								}
							</span>
							<span>
								<go5-button
									data-testid="button_button_saveaddress"
									[ngStyle]="{ width: isFromMobile ? '100%' : '' }"
									[width]="isFromMobile ? '100%' : ''"
									type="button"
									category="primary"
									size="medium"
									[disabled]="!permission.allowEdit"
									label="{{ selfUpdate ? ('common_next' | translate) : ('common_save' | translate) }}"
									(click)="saveAddress()"
								>
								</go5-button>
							</span>
						</div>
					}
					@if (isWorkInformation) {
						<div class="d-flex">
							@if (userIdValidaition) {
								<go5-info-bar [type]="infoBarType.Warning">{{
									"info_bar_data_import_movement" | translate
								}}</go5-info-bar>
							}
							@if (!selfUpdate) {
								<go5-button
									class="mr-2 pl-3"
									label="{{ 'common_cancel' | translate }}"
									category="secondary"
									size="medium"
									type="button"
									(click)="backToProfile('Employment_Details_Employment_Cancel')"
								></go5-button>
							}

							<go5-button
								data-testid="button_button_saveWorkinformation"
								type="button"
								category="primary"
								size="medium"
								label="{{ 'common_save' | translate }}"
								[disabled]="!permission.allowEdit"
								(click)="saveCompareWorkinformation()"
							></go5-button>
						</div>
					}
				</div>
			}
		} @else {
			<div #footerSelfService class="self-service-footer">
				<div class="d-flex direction-column g-12">
					@if (isFinished) {
						<div class="checkbox-mobile">
							<go5-checkbox
								[label]="'obd_consent_accuracy_employment_terms_checkbox' | translate"
								[checked]="accepted"
								[(ngModel)]="accepted"
							></go5-checkbox>
						</div>
					}

					<div class="d-flex content-space-between">
						<div class="d-flex" style="width: 50%; margin-top: auto">
							@if (showPrevButton || isAddress) {
								<go5-button
									[ngStyle]="{ width: isFromMobile ? '100%' : '' }"
									[width]="isFromMobile ? '100%' : ''"
									label="{{ 'common_prev' | translate }}"
									[category]="'secondary'"
									size="medium"
									type="button"
									(click)="changePage('emp001_work_experience', -1)"
								></go5-button>
							}
						</div>
						<div class="d-flex g-8" style="width: 50%">
							@if (isFinished) {
								<go5-checkbox
									class="checkbox-web"
									[label]="'obd_consent_accuracy_employment_terms_checkbox' | translate"
									[checked]="accepted"
									[(ngModel)]="accepted"
								></go5-checkbox>
							}

							<go5-button
								[ngStyle]="{ width: isFromMobile ? '100%' : '' }"
								[width]="isFromMobile ? '100%' : ''"
								label="{{ (isFinished ? 'common_finished' : 'common_next') | translate }}"
								[category]="isFinished ? 'color' : 'primary'"
								[disabled]="!accepted && isFinished"
								style="margin-left: auto; margin-top: auto"
								size="medium"
								type="button"
								color="#00cc99"
								(click)="
									isAdditionalField
										? saveCustomField()
										: isAddress
											? saveAddress()
											: isSocialSecurity
												? saveSocialSecurity()
												: saveEdit()
								"
							></go5-button>
						</div>
					</div>
				</div>
			</div>
		}
	</div>
</div>

<ng-template #toggleSelfService>
	<div class="d-flex align-center g-6">
		<span class="go5-text-title go5-text-color-8">
			{{ "common_save_self_info" | translate }}
		</span>

		<span>
			<einfo-tooltip
				class="d-flex"
				key="emp001_onboarding_tooltip"
				size="s"
				[position]="isFromMobile ? 'right' : 'top'"
				[width]="isFromMobile ? '15em' : ''"
			></einfo-tooltip
		></span>

		<span style="margin-left: 12px">
			<go5-toggle-switch
				category="secondary"
				[checked]="employeeModel.isSelfUpdate"
				(valueChange)="changeToggleSelfUpdate()"
			>
			</go5-toggle-switch>
		</span>
	</div>
</ng-template>

<go5-dialog
	*ngIf="popup_Confirm"
	[(visible)]="popup_Confirm"
	[width]="'60vw'"
	[isShowCloseIcon]="false"
	[padding]="'24px'"
>
	<div class="d-grid gap-20">
		<div class="header-border-bottom">
			<div class="d-flex" style="justify-content: space-between">
				<span class="text-header-3 go5-text-color-10">{{
					editType == EditType.movement
						? ("emp002_action_information" | translate)
						: ("emp002_edit_confirm" | translate)
				}}</span>
				<div class="d-flex" style="align-items: center">
					@if (movementTypeId != movementTypeResign) {
						<go5-checkbox
							data-testid="input_checkbox_without_save_movement"
							[label]="'edit_information_without_save_movement' | translate"
							(changed)="onChangeEditType($event)"
							[checked]="isEditType"
						>
						</go5-checkbox>
					}
				</div>
			</div>
		</div>

		<form [formGroup]="formCompare" class="d-grid gap-16">
			<div [hidden]="editType != EditType.movement" class="d-flex gap-16">
				<div style="flex-basis: calc(50% - 8px)">
					<go5-dropdown-selection
						[dataSource]="movementTypeDDL"
						[fields]="data.currentLanguage | async | fieldsDDL"
						[mode]="'single'"
						[label]="('emp002_movement_type' | translate) + '*'"
						[placeholder]="'common_default_select' | translate"
						[value]="movementTypeId"
						[allowFiltering]="true"
						formControlName="movementTypeId"
						(selected)="movementTypeId = $event.value; selectedMovementType()"
						[allowShowRemove]="false"
						[disabled]="editType == EditType.movement && movementTypeId == movementTypeResign"
					>
					</go5-dropdown-selection>
				</div>
				<div style="flex-basis: calc(50% - 8px)">
					<go5-datepicker
						data-testid="input_calendar_dateeffective"
						formControlName="dateEffective"
						[format]="DateFormat.dateLong"
						[label]="('ess009_date_effective' | translate) + '*'"
						placeholder="{{ 'common_please_choose' | translate }}"
						[(ngModel)]="dateEffective"
						[isShowTime]="false"
						[error]="
							!formCompare.controls['dateEffective'].valid &&
							(isSubmittedWorkinformation || formCompare.controls['dateEffective'].touched)
						"
						[errorMessage]="'common_required' | translate"
					></go5-datepicker>
				</div>
			</div>
			@if (editType == EditType.movement && movementTypeId == movementTypeResign) {
				<div class="d-flex gap-16">
					@if (countUnder > 0) {
						<div style="flex-basis: calc(50% - 8px)">
							<go5-dropdown-selection
								data-testid="dropdown_single_uppersupervisorid"
								[label]="('ess009_separation_supervisor_new' | translate: { subordinatesamount: countUnder }) + '*'"
								[dataSource]="listPeople"
								[fields]="data.getIsTH(data.currentLanguage | async) ? peopleFields : peopleFields_EN"
								(filtering)="onFilterDdlUser($event)"
								(clear)="clarePeople()"
								cssClass="dropdown-large-primary"
								mode="single"
								formControlName="upperSupervisorIdInput"
								(selected)="getSupervisor($event)"
								[allowShowRemove]="false"
								[(ngModel)]="upperSupervisorId"
							>
							</go5-dropdown-selection>
						</div>
					}

					<div style="flex-basis: calc(50% - 8px)">
						<go5-dropdown-selection
							data-testid="dropdown_single_terminationReasonId"
							[dataSource]="reasonToResignDDL"
							[fields]="data.getIsTH(data.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
							[mode]="DropdownSelectMode.Single"
							[height]="'40px'"
							[label]="('ess009_seperation_reason' | translate) + '*'"
							[placeholder]="'common_please_choose' | translate"
							formControlName="terminationReasonId"
							[allowFiltering]="false"
							[allowShowRemove]="false"
							[error]="
								formCompare.controls['terminationReasonId'].invalid &&
								(isSubmittedWorkinformation || formCompare.controls['terminationReasonId'].touched)
							"
							[errorMessage]="'ess009_seperation_reason_validate' | translate"
						>
						</go5-dropdown-selection>
					</div>
				</div>
			}
			@if (editType == EditType.movement) {
				<go5-textarea
					style="width: calc(50% - 8px)"
					category="primary"
					label="{{ 'common_remark' | translate }}"
					size="extra-large"
					label=""
					maxlength="500"
					formControlName="remarks"
				>
				</go5-textarea>
			}

			<div class="d-flex gap-24">
				<div class="border-box-content" style="flex-basis: calc(50% - 8px)">
					<div class="go5-text-body-strong gp5-text-color-12">
						{{ "emp002_information_original" | translate }}
					</div>
					<div style="display: grid; grid-template-columns: min-content auto">
						<ng-template ngFor let-item [ngForOf]="compareList">
							<div
								class="go5-text-color-9 ellipsis"
								style="margin: 8px 16px 0px 0px"
								[ngStyle]="{
									visibility:
										item.label == 'ess009_sub_transfer' ||
										(item.label == 'ess009_sub_transfer' && editType == EditType.edit)
											? 'hidden'
											: ''
								}"
							>
								{{
									item.label?.indexOf("|") != -1
										? data.getIsTH(data.currentLanguage | async)
											? item.label?.split("|")[0]
											: item.label?.split("|")[1]
										: (item.label | translate)
								}}
							</div>
							<div
								class="go5-text-body-strong go5-text-color-10"
								style="margin: 8px 0px 0px 0px"
								[ngStyle]="{
									visibility:
										item.label == 'ess009_sub_transfer' ||
										(item.label == 'ess009_sub_transfer' && editType == EditType.edit)
											? 'hidden'
											: ''
								}"
							>
								{{ data.getIsTH(data.currentLanguage | async) ? item.value2 : item.value2_EN }}
							</div>
						</ng-template>
					</div>
				</div>

				<div class="border-box-content" style="flex-basis: calc(50% - 8px)">
					<div class="go5-text-body-strong gp5-text-color-12">
						{{ "common_new_value" | translate }}
					</div>
					<div style="display: grid; grid-template-columns: min-content auto">
						<ng-template ngFor let-item [ngForOf]="compareList">
							<div
								class="go5-text-color-9 ellipsis"
								style="margin: 8px 16px 0px 0px"
								[ngStyle]="{
									display: item.label == 'ess009_sub_transfer' && editType == EditType.edit ? 'none' : ''
								}"
							>
								{{
									item.label?.indexOf("|") != -1
										? data.getIsTH(data.currentLanguage | async)
											? item.label?.split("|")[0]
											: item.label?.split("|")[1]
										: (item.label | translate)
								}}
							</div>
							<div
								class="go5-text-body-strong go5-text-color-10"
								style="margin: 8px 0px 0px 0px"
								[ngStyle]="{
									display: item.label == 'ess009_sub_transfer' && editType == EditType.edit ? 'none' : ''
								}"
							>
								{{ data.getIsTH(data.currentLanguage | async) ? item.value1 : item.value1_EN }}
							</div>
						</ng-template>
					</div>
				</div>
			</div>
			@if (userInfo?.hasMovementWorkflow && editType == EditType.movement) {
				<div class="d-grid gap-8">
					<label class="go5-text-color-8">
						{{ "next_approver_text" | translate }}
					</label>
					<label class="d-flex go5-text-body-strong go5-color-primary">
						{{ nextApproval ? nextApproval : "-" }}
					</label>
				</div>
			}
		</form>

		<div class="d-flex gap-8" style="justify-content: space-between">
			<div class="d-flex">
				@if (movementTypeId == movementTypeResign && resignApiError) {
					<go5-info-bar [type]="infoBarType.Warning"
						>{{ resignApiError ? warningBoxMessage : ("infobar_resign_self" | translate) }}
					</go5-info-bar>
				} @else {
					@if (dateEffective != null && movementTypeId == movementTypeResign) {
						<go5-info-bar [type]="infoBarType.Info">
							<span
								[innerHTML]="
									'emp011_date_resign_info_movement'
										| translate
											: { dateEffective: effectiveDate | date: 'dd MMMM yyyy' : (data.currentLanguage | async) }
								"
							></span>
						</go5-info-bar>
					}
				}
			</div>

			<div class="d-flex gap-8">
				<go5-button
					data-testid="button_button_close"
					(click)="closeDialog()"
					category="secondary"
					size="medium"
					type="button"
					label="{{ 'common_cancel' | translate }}"
				></go5-button>
				<go5-button
					data-testid="button_button_save"
					category="primary"
					size="medium"
					type="button"
					[label]="
						(editType == EditType.movement && userInfo?.hasMovementWorkflow ? 'emp002_approve' : 'common_save')
							| translate
					"
					(click)="saveWorkinformation()"
				>
				</go5-button>
			</div>
		</div>
	</div>
</go5-dialog>

<go5-dialog *ngIf="dialogUpgradePlan" [(visible)]="dialogUpgradePlan" [padding]="'0px'" [width]="'50vw'">
	<app-upgrade-plan (closeDialog)="dialogUpgradePlan = false"> </app-upgrade-plan>
</go5-dialog>

<app-movement-sidebar-add
	*ngIf="isSidebarRenewalAdd"
	[isSidebarAdd]="isSidebarRenewalAdd"
	[movementType]="employmentTypeId"
	[employeeId]="dulEmployeeIdRenewal"
	[isLockSelect]="true"
	(closeSidebar)="isSidebarRenewalAdd = false"
>
</app-movement-sidebar-add>
