export enum ResEventType {
	Url = "Url",
	Home = "Home",
	Activity = "Activity",
	Administator = "Administator",
	Case = "Case",
	ConnectAccountSocial = "Connect_Account_Social",
	CodeGuard = "CodeGuard",
	Contact = "Contact",
	Contract = "Contract",
	Customer = "Customer",
	Customer_State = "Customer_State",
	Deal = "Deal",
	DealDetailTab = "DealDetailTab",
	DealLinkActivity = "DealLinkActivity",
	DealLinkConversation = "DealLinkConversation",
	DealLinkQuotation = "DealLinkQuotation",
	DealUpdateState = "DealUpdateState",
	DealUpdateStatus = "DealUpdateStatus",
	Expense = "Expense",
	ImportFileCustomer = "ImportFileCustomer",
	Product = "Product",
	Quotation = "Quotation",
	SalesOrder = "SalesOrder",
	Task = "Task",

	PDF_SalesOrder = "pdf_salesOrder",
	PDF_Quotation = "pdf_quotation",
	PDF_Expense = "pdf_expense",

	Create_Activity = "Create_Activity",
	Create_Key_Activity = "Create_Key_Activity",
	Create_Address = "Create_Address",
	Create_Case = "Create_Case",
	Create_Contact = "Create_Contact",
	Create_Contract = "Create_Contract",
	Create_Conversation = "Create_Conversation",
	Create_Customer = "Create_Customer",
	Create_Deal = "Create_Deal",
	Create_Expense = "Create_Expense",
	Create_LeadForm = "Create_LeadForm",
	Create_Product = "Create_Product",
	Create_Product_Bundle = "Create_Product_Bundle",
	Create_Quotation = "Create_Quotation",
	Create_SalesOrder = "Create_SalesOrder",
	Create_Task = "Create_Task",
	Create_Warranty = "Create_Warranty",

	Create_User = "Create_User",
	Transfer_Data = "Transfer_Data",

	SocailChat = "SocailChat",
	Employee = "Employee",

	AlertStatus = "AlertStatus",

	Remote_User = "Remote_User",

	Activity_Detail_Tab = "Activity_Detail_Tab",
	Expense_Detail_Tab = "Expense_Detail_Tab",
	Conversation_Detail_Tab = "Conversation_Detail_Tab",
	Warranty_Detail_Tab = "Warranty_Detail_Tab",

	Approval_Config_Budget = "Approval_Config_Budget",
	Order_List = "Order_List",
	Product_Picker = "Product_Picker",
	CodeGuard_Warranty_Tab = "CodeGuard_Warranty_Tab",
	Update_Subscription = "Update_Subscription",
	openPeoplePickerOkr = "openPeoplePickerOkr",
	openPeoplePickerLearn = "openPeoplePickerLearn",
	openDepartmentSidebar = "openDepartmentSidebar",
	open = "Open",
	openTrainingDetailSidebar = "openTrainingDetailSidebar",
	openTrainingDocumentSidebar = "openTrainingDocumentSidebar",
	openSidebarAddOrganizationOkr = "openSidebarAddOrganizationOkr",
}
