import { RouterModel } from "./common.model";

export class Dropdown {
	id: number;
	name: string;
	name_EN: string;
	dataId?: number;
	value?: string;
	valueNum?: number;
	text?: string;
	text_EN?: string;
	isTitle?: boolean;
	isActive?: boolean;
	selected?: boolean;
	disabled?: boolean;
	dataSource?: Dropdown[];
}
export class sDropdown {
	text: string;
	value: string;
}
export class SplitButton {
	bankAccountNo: number;
	bankTemplateId: number;
	id: number;
	label: string;
	icon: string;
	value: string;
	command?: (event?: any) => void;
	items: SplitButton[];
}

export class EmployeeFamilyDDL {
	id: number;
	name: string;
	name_EN: string;
	relationShipName: string;
	relationShipName_EN: string;
	dataId: number;
	value: string;
	valueNum: number;
}

export class FinancialItemDDLModel {
	id: number;
	name: string;
	name_EN: string;
	category: number;
}

export class RadarChart {
	labels: string[];
	datasets: DataSet[];
	maxItemNo: number;
}

export class DataSet {
	label: string;
	backgroundColor: string;
	borderColor: string;
	pointBackgroundColor: string;
	pointBorderColor: string;
	pointHoverBackgroundColor: string;
	pointHoverBorderColor: string;
	data: number[];
}

export class SyncTab extends Dropdown {
	router: RouterModel[];
	allRouter: RouterModel[];
	hasLockIcon: boolean;
}

export class BusinessCodeDDLModel extends Dropdown {
	businessCode: string;
	rate: number;
}
