import { DatePipe, NgClass, NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import {
	AbstractControl,
	UntypedFormControl,
	UntypedFormGroup,
	ValidatorFn,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { DateFormat } from "@gofive/angular-common";
import { TextFieldType, InputModule } from "@gofive/design-system-input";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { combineLatest, firstValueFrom, Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResult } from "../../models/api-result.model";
import { CustomFieldService } from "../../service/custom-field.service";
import { ToastHelperService } from "../../service/toast-helper.service";
import {
	CustomFieldsTextBoxType,
	CustomFieldsType,
} from "../../side-bar/sidebar-custom-field/sidebar-custom-field.component";
import { CalendarModule } from "@gofive/design-system-calendar";
import { DropdownModule } from "@gofive/design-system-dropdown";
import { SelectionModule } from "@gofive/design-system-selection";
import { KeyFilterModule } from "primeng/keyfilter";

export class EmployeeCustomFieldModels {
	customFieldModel: CustomFieldModel[] = [];
}

export class EmployeeCustomFieldModel {
	employeeId: number;
	fieldId: number;
	sequence: number;
	value: string;
	masCustomFields: CustomFieldModel;
}

export class CustomFieldModel {
	name: string;
	regularExpression: RegExp;
	isRequire: boolean;
	fieldId: number;
	fieldTypeId: number;
	masAdditionalFieldDetails: CustomFieldDetailsModel[] = [];
	textBoxType: number;
	sequence: number;
}

export class CustomFieldDetailsModel {
	fieldId: number;
	label: string;
	sequence: number;
}

export function letterValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const regex = /^[\u0E00-\u0E7Fa-zA-Z]+$/;
		const isValid = regex.test(control.value);
		return isValid ? null : { thaiLetter: { value: control.value } };
	};
}

@Component({
	selector: "app-employee-custom-field",
	templateUrl: "./employee-custom-field.component.html",
	styleUrls: [
		"./employee-custom-field.component.scss",
		"../../../employee-movements/employee/employee-add/employee-add.component.scss",
	],
	standalone: true,
	imports: [
		NgClass,
		NgTemplateOutlet,
		FormsModule,
		ReactiveFormsModule,
		InputModule,
		KeyFilterModule,
		SelectionModule,
		DropdownModule,
		CalendarModule,
		TranslateModule,
	],
})
export class EmployeeCustomFieldComponent implements OnChanges, OnDestroy {
	@Input() customFields: any[] = [];

	@Input() toggleSave: number = 0;
	@Input() employeeId: number = 0;
	@Input() selfUpdate: boolean = null;
	@Input() isShowHeader: boolean = true;
	@Input() needToast: boolean = true;

	@Output() saveSuccess: EventEmitter<any> = new EventEmitter();
	@Output() isShowDiscardChange: EventEmitter<boolean> = new EventEmitter();

	public customFieldModel: EmployeeCustomFieldModels[] = [];

	public customEmployeeField: EmployeeCustomFieldModel[] = [];

	public isLoadedCustomField: boolean = false;

	public form: UntypedFormGroup = new UntypedFormGroup({});

	public regexLetters: RegExp = /^[\u0E00-\u0E7Fa-zA-Z]+$/;

	public fields = {
		text: "label",
		value: "label",
	};

	public get customFieldsType() {
		return CustomFieldsType;
	}

	public get customFieldsTextBoxType() {
		return CustomFieldsTextBoxType;
	}

	public get textFieldType() {
		return TextFieldType;
	}

	public get dateFormat() {
		return DateFormat;
	}
	public $_isLoaded: Observable<boolean>;
	public $_isLoadedEmployeeCustomField: Subject<boolean> = new Subject<boolean>();
	public $_isLoadedForm: Subject<boolean> = new Subject<boolean>();
	public $_isLoadedModel: Subject<boolean> = new Subject<boolean>();
	public $_doneLoadingPermission: Subscription;
	public $_valueChange: Subscription;

	constructor(
		private customFieldService: CustomFieldService,
		private _toastService: ToastHelperService,
		private translateService: TranslateService,
		private datePipe: DatePipe,
	) {
		this.$_isLoaded = combineLatest([
			this.$_isLoadedEmployeeCustomField,
			this.$_isLoadedForm,
			this.$_isLoadedModel,
		]).pipe(map((values) => values.every((value) => value)));

		this.$_doneLoadingPermission = this.$_isLoaded.subscribe(() => {
			this.setValue();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.customFields) {
			if (changes.customFields.currentValue.length) {
				this.customFields = changes.customFields.currentValue;
				this.createCustomFieldModel();
				this.createCustomFiledForm();
			} else {
				this.getMasCustomField();
			}
		}

		if (changes.toggleSave?.currentValue) {
			this.checkValid();
		}

		if (changes.employeeId?.currentValue) {
			this.employeeId = changes.employeeId.currentValue;
			this.getEmployeeCustomField();
		}

		if (changes.selfUpdate) {
			this.selfUpdate = changes.selfUpdate.currentValue;
		}
	}

	ngOnDestroy(): void {
		this.$_isLoadedEmployeeCustomField?.unsubscribe();
		this.$_isLoadedForm?.unsubscribe();
		this.$_isLoadedModel?.unsubscribe();
		this.$_doneLoadingPermission?.unsubscribe();
		this.$_valueChange?.unsubscribe();
	}

	getMasCustomField() {
		firstValueFrom(this.customFieldService.getAllMasCustomFields(true)).then((res) => {
			this.customFields = res.data;
			this.createCustomFieldModel();
			this.createCustomFiledForm();
		});
	}

	createCustomFieldModel() {
		let tmpIndex: number = 0;
		this.customFields?.forEach((f) => {
			if (
				f.fieldTypeId == CustomFieldsType.Paragraph &&
				this.customFieldModel[tmpIndex]?.customFieldModel?.length == 1
			) {
				tmpIndex++;
			}
			if (!this.customFieldModel[tmpIndex]?.customFieldModel?.length) {
				this.customFieldModel.push({
					customFieldModel: [],
				});
			}

			this.customFieldModel[tmpIndex].customFieldModel = [
				...this.customFieldModel[tmpIndex].customFieldModel,
				{
					...f,
					regularExpression: new RegExp(f.regularExpression),
				},
			];

			if (
				this.customFieldModel[tmpIndex]?.customFieldModel?.length == 2 ||
				this.customFieldModel[tmpIndex]?.customFieldModel[0]?.fieldTypeId == CustomFieldsType.Paragraph
			) {
				tmpIndex++;
			}
		});
		this.isLoadedCustomField = true;
		this.$_isLoadedModel.next(true);
	}

	createCustomFiledForm() {
		this.customFields.forEach((f) => {
			let validatorFn: ValidatorFn[] = [];
			if (f.isRequire) validatorFn = [...validatorFn, Validators.required];
			if (f.fieldTypeId === CustomFieldsType.Text && f.textBoxType === CustomFieldsTextBoxType.Email)
				validatorFn = [...validatorFn, Validators.email];
			if (f.fieldTypeId === CustomFieldsType.Text && f.textBoxType === CustomFieldsTextBoxType.Custom)
				validatorFn = [...validatorFn, Validators.pattern(f.regularExpression)];
			if (f.fieldTypeId === CustomFieldsType.Text && f.textBoxType === CustomFieldsTextBoxType.Letter)
				validatorFn = [...validatorFn, letterValidator()];
			this.form.addControl(f.name, new UntypedFormControl(null, validatorFn));
		});

		this.$_valueChange = this.form.valueChanges.subscribe(() => {
			if (this.form.dirty) {
				this.isShowDiscardChange.emit(true);
			} else {
				this.isShowDiscardChange.emit(false);
			}
		});
		this.$_isLoadedForm.next(true);
	}

	checkValid() {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			this._toastService.validation({
				title: this.translateService.instant("toast_title_headsup"),
				description: this.translateService.instant("toast_common_complete_all_fields"),
			});
		} else {
			this.onSave();
		}
	}
	onSave() {
		let tmpModel = {
			...this.form.value,
		};
		Object.keys(tmpModel).forEach((key) => {
			if (tmpModel[key] instanceof Array) {
				tmpModel[key] = tmpModel[key].toString();
			} else if (tmpModel[key] instanceof Date && tmpModel[key]) {
				tmpModel[key] = this.datePipe.transform(tmpModel[key], "MM/dd/yyyy");
			}
		});
		let jsonModel = JSON.stringify(tmpModel);
		firstValueFrom(this.customFieldService.saveEmployeeCustomFields(jsonModel, this.employeeId, this.selfUpdate)).then(
			(res) => {
				let apiResult = <ApiResult>res.data;
				if (apiResult.result) {
					if (this.needToast) {
						this._toastService.success({
							title: this.translateService.instant("toast_title_success"),
							description: this.translateService.instant("wel003_savesuccess"),
						});
					}
					this.form.markAsPristine();
					this.form.markAsUntouched();
					this.saveSuccess.emit();
					this.isShowDiscardChange.emit(false);
				} else {
					this._toastService.validation({
						title: this.translateService.instant("toast_title_headsup"),
						description: apiResult.message,
					});
				}
			},
		);
	}

	getEmployeeCustomField() {
		firstValueFrom(this.customFieldService.getEmployeeCustomField(this.employeeId)).then((res) => {
			this.customEmployeeField = res.data;
			this.$_isLoadedEmployeeCustomField.next(true);
		});
	}

	setValue() {
		this.customEmployeeField?.forEach((customField) => {
			if (customField.masCustomFields.fieldTypeId === CustomFieldsType.MultiSelection) {
				this.form?.get(customField.masCustomFields.name)?.setValue(customField.value?.split(",") || []);
			} else if (customField.masCustomFields.fieldTypeId === CustomFieldsType.DatePicker) {
				this.form
					?.get(customField.masCustomFields.name)
					?.setValue(customField.value ? new Date(customField.value) : null);
			} else {
				this.form?.get(customField.masCustomFields.name)?.setValue(customField.value || null);
			}
		});
	}
}
