import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, signal } from "@angular/core";
import { DataSharingService, UserControlService, UserInfoService } from "../../../core";
import { OTPConfigModel } from "../../models/common.model";
import { State2FA } from "../../enum/state2FA.enum";
import { EmployeeService } from "../../../employee-movements/employee/shared/employee.service";
import { Router } from "@angular/router";
import { CommonService } from "../../service/common.service";
import { ApiResult } from "../../../shared/models/api-result.model";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { UserInfo } from "./../../../core/shared/user-info.model";
import { Subscription, first, firstValueFrom } from "rxjs";
import { SharingService } from "../../sharing.service";
import { ButtonModule } from "@gofive/design-system-button";
import { KeyFilterModule } from "primeng/keyfilter";
import { NgOtpInputModule } from "ng-otp-input";
import { QRCodeModule } from "angularx-qrcode";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgTemplateOutlet, AsyncPipe } from "@angular/common";
import { DialogModule } from "@gofive/design-system-dialog";
@Component({
	selector: "two-factor-authentication",
	templateUrl: "./two-factor-authentication.component.html",
	styleUrls: ["./two-factor-authentication.component.scss"],
	standalone: true,
	imports: [
		DialogModule,
		NgTemplateOutlet,
		NgxSkeletonLoaderModule,
		QRCodeModule,
		NgOtpInputModule,
		KeyFilterModule,
		ButtonModule,
		AsyncPipe,
		TranslateModule,
	],
})
export class TwoFactorAuthenticationComponent implements OnInit {
	private language$: Subscription;
	private currentUserInfo$: Subscription;

	status = false;
	displayDialog: boolean = false;
	public qrdata: string = null;
	email: string;
	issuer: string = "empeo"; //title
	algorithm: string = "SHA1"; // การเข้ารหัส
	digits: number = 6; // จำนวนเลข
	period: number = 30; // timeout
	secretKey: string;
	isloadImage: boolean = true;

	stateWelcome: number = State2FA.Welcome;
	stateScan: number = State2FA.Scan;
	stateAuth: number = State2FA.Auth;
	stateSuccess: number = State2FA.Success;
	validMessage: string = "";
	config: OTPConfigModel;
	otp: string = "";
	isValid: boolean = true;
	isSuccess: boolean = false;
	state: number;
	dismissableMask: boolean = false;
	isAuthStatus: boolean = false;
	isCloseStatus: boolean = false;
	public userInfo: UserInfo;
	@Input() toPathRouter: boolean = false;
	@Input() pathRouter: string = "/home";
	@Output() authStatus = new EventEmitter<boolean>(false);
	@Output() closeStatus = new EventEmitter<boolean>(false);

	private defualtConfig = {
		allowNumbersOnly: true,
		length: 6,
		isPasswordInput: false,
		disableAutoFocus: false,
		placeholder: "",
		inputStyles: {
			width: window.innerWidth < 420 ? "50px" : "60px",
			height: window.innerWidth < 420 ? "50px" : "60px",
		},
	};
	private defualtValidConfig = {
		allowNumbersOnly: true,
		length: 6,
		isPasswordInput: false,
		disableAutoFocus: false,
		inputClass: "valid",
		placeholder: "",
		inputStyles: {
			width: window.innerWidth < 420 ? "50px" : "60px",
			height: window.innerWidth < 420 ? "50px" : "60px",
		},
	};
	employeeId: number;
	is2FA: boolean = false;
	language: string;
	constructor(
		private router: Router,
		private employeeService: EmployeeService,
		public _cdr: ChangeDetectorRef,
		private userService: UserInfoService,
		private sharing: SharingService,
		public data: DataSharingService,
		public userControlService: UserControlService,
		private translate: TranslateService,
		private commonService: CommonService,
	) {
		this.subscribe$ = this.commonService._2faVerificationstatus.pipe(first()).subscribe((status) => {
			if (status != null) {
				if (status) {
					this.authStatus.emit(true);
					this.displayDialog = false;
					this.isAuthStatus = true;
				} else {
					firstValueFrom(this.commonService.generateBase32RandomKey(10)).then((res) => {
						this.secretKey = res.data;
						if (!this.isNullOrUndefined(this.userInfo.employeeId)) {
							if (this.userInfo.isActive2FA) {
								this.displayDialog = true;
								this.employeeId = this.userInfo.employeeId;
								this.email = this.userInfo.emailAddress;
								this.is2FA = this.userInfo.is2FA;
								this.config = this.defualtConfig;
								if (this.is2FA) {
									this.state = this.stateAuth;
								} else {
									this.state = this.stateWelcome;
								}
								this.qrdata = `otpauth://totp/${this.issuer}:${this.email}?secret=${this.secretKey}&issuer=${this.issuer}&algorithm=${this.algorithm}&digits=${this.digits}&period=${this.period}&lock=false`;
							} else {
								this.authStatus.emit(true);
								this.displayDialog = false;
								this.isAuthStatus = true;
							}
						}
					});
				}
			}
		});
	}
	loadImg(event) {
		if (event) {
			this.isloadImage = false;
		}
	}
	isNullOrUndefined(object) {
		return object == null || object == undefined;
	}

	ngAfterViewInit(): void {
		if (this.displayDialog) {
			this.onShowDialog();
		}
	}
	get isMobile() {
		return this.sharing.isMobile();
	}
	ngOnInit() {
		this.language$ = this.data.currentLanguage.subscribe((lang) => {
			this.language = lang;
		});

		if (this.isMobile) {
			this.currentUserInfo$ = this.userService.getCurrentUserInfo().subscribe((user) => {
				this.userInfo = user;
				this.commonService.twoFactorAuthVerificationstatus();
			});
		} else {
			this.currentUserInfo$ = this.data.currentUserInfo.subscribe((user) => {
				this.userInfo = user;
				this.commonService.twoFactorAuthVerificationstatus();
			});
		}
	}
	subscribe$;

	onShowDialog() {
		if (document.querySelector('[id^="otp_0_"]') != null) {
			var tag = document.getElementById(document.querySelector('[id^="otp_0_"]').id);
			tag.focus();
		}
	}

	ngOnDestroy() {
		this.subscribe$?.unsubscribe();
		this.language$?.unsubscribe();
		this.currentUserInfo$?.unsubscribe();
	}
	visibleChange() {
		new Promise((resolve) => {
			if (this.isSuccess && !this.isValid) {
				this.successState(resolve);
			} else {
				resolve(true);
			}
		}).then(() => {
			this.displayDialog = false;
			this.closeStatus.emit(true);
			if (!this.isAuthStatus) {
				if (this.toPathRouter) {
					this.router.navigateByUrl(this.pathRouter);
				}
			}
		});
	}

	next(index) {
		this.isSuccess = false;
		if (this.state == this.stateScan) {
			firstValueFrom(
				this.employeeService.updateSecretKey2FA({ employeeId: this.employeeId, secretKey: this.secretKey }),
			);
		}
		if (this.state == this.stateAuth) {
			this.checkOtp();
			if (this.isValid) {
				return;
			}
		}
		this.state = index + 1;
		if (this.state == this.stateAuth) {
			this.config = this.defualtConfig;
			this.isValid = true;
			this.otp = "";
		}
		if (this.state == this.stateSuccess) {
			if (!this.isValid) {
				this.isSuccess = true;
			}
			if (this.is2FA) {
				return this.visibleChange();
			}
		}

		this.displayDialog = true;
	}
	prev(index) {
		this.state = index - 1;
	}
	isSetSession = signal(false);
	successState(resolve) {
		if (!this.isSetSession()) {
			this.commonService.setSessionStorage2FA().subscribe((_) => {
				this.authStatus.emit(true);
				this.isAuthStatus = true;
				if (!this.is2FA) {
					firstValueFrom(this.employeeService.update2FA(this.employeeId, true)).then((_) => {
						this.userInfo.is2FA = true;
						this.data.setUserInfo(this.userInfo);
					});
				}
				this.isSetSession.set(true);
				resolve(true);
			});
		}
	}
	onOtpChange(otp) {
		this.otp = otp;
		this.config = this.defualtConfig;
		if (this.otp.length == this.config.length) {
			this.validMessage = "";
			this.checkOtp();
		}
	}

	auth2FA$;
	checkOtp() {
		this.auth2FA$?.unsubscribe();
		this.auth2FA$ = this.employeeService.auth2FA(this.employeeId, this.otp).subscribe((res) => {
			var apiResult = <ApiResult>res;
			let message = this.language == "th-TH" ? apiResult.message : apiResult.message_EN;
			if (apiResult.result) {
				this.isValid = false;
				this.config = this.defualtConfig;
				this.next(this.stateAuth);
			} else {
				this.validMessage = apiResult.message == null ? this.translate.instant("module_2fa_auth_valid") : message;
				this.isValid = true;
				this.config = this.defualtValidConfig;
			}
		});
	}

	@HostListener("document:keypress", ["$event"])
	keyEvent(event: KeyboardEvent) {
		if (this.userInfo?.isActive2FA) {
			if (event.key === "Enter" && this.state != this.stateAuth && this.state != this.stateSuccess) {
				this.next(this.state);
			}
		}
	}

	downloadGooglePlay() {
		window.open("https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2");
	}
	downloadAppleStore() {
		window.open("https://apps.apple.com/us/app/google-authenticator/id388497605");
	}
}
