import {
	FromAddLocker,
	EmployeeeLocker,
	FromEditLocker,
	FromCancel,
	FromAddPageEdit,
	FromUpdatStatus,
	FromDelete,
	LockerSearch,
} from "./employee-lockers.model";
import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeLockersService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeLocker(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "EmployeeLocker/GetEmployeeLocker", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}
	getEmployeeLockerHistory(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeLocker/GetEmployeeLockerHistory",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}
	getEmployeeLockerHistoryAll(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeLocker/GetEmployeeLockerHistoryAll",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	employeeLockersById(employeeLockerId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeLocker/GetEmployeeLockerById?id=" + employeeLockerId),
		).then((res) => <EmployeeeLocker>res);
	}

	AddEmployeeLocker(model: FromAddLocker) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeLocker/AddEmployeeLocker", body, httpOptions);
	}

	EditEmployeeLocker(model: FromEditLocker) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeLocker/EditEmployeeLocker", body, httpOptions);
	}

	CancelEmployeeLocker(model: FromCancel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeLocker/CancelEmployeeLocker", body, httpOptions);
	}

	AddPageEditEmployeeLocker(model: FromAddPageEdit) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeLocker/FromAddPageEdit", body, httpOptions);
	}

	UpdateSatausEmployeeLocker(model: FromUpdatStatus) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeLocker/UpdateSataus", body, httpOptions);
	}

	DeleteEmployeeLocker(model: FromDelete) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeLocker/DeleteEmployeeLocker", body, httpOptions);
	}
	ExportExcelReport2(model: LockerSearch) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeLocker/ExportExcelReport2", body, httpOptions);
	}
}
