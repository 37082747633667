import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeMovement, EmployeeMovementBySelected } from "./employee-movement.model";
import { CommonService } from "../../../shared/service/common.service";
import { ReportFilter, ReportModel } from "../../../reports/shared/report.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { ApprovalSearchModel } from "src/app/document-forms/approval/shared/approval.model";
import { Checkbox } from "primeng/checkbox";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeMovementService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		@Inject("API_URL") private apiUrl: string,
		private http: HttpClient,
		private commonService: CommonService,
	) {}

	getEmployeeMovementTransactionById(movementTransactionId) {
		return this.http.get<any>(
			`${this.apiUrl}EmployeeMovement/GetEmployeeMovementTransactionById/${movementTransactionId}`,
		);
	}

	addEmployeeRenewal(emp: EmployeeMovement) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeMovement/AddEmployeeRenewal", body, httpOptions);
	}
	addEmployeeMovementTransaction(emp) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		return this.http.post(this.apiUrl + "EmployeeMovement/AddEmployeeMovementTransaction", body, this.httpOptions);
	}
	checkPermissionCreatePermanent(companyId, employeeId) {
		return firstValueFrom(
			this.http.get(this.apiUrl + `CheckPermissionCreatePermanentAsync/${companyId}/${employeeId}`),
		);
	}

	exportExcelEmployeeMovementTransactions(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		return this.http.post<ReportModel>(
			this.apiUrl + "EmployeeMovement/ExportExcelEmployeeMovementTransactions",
			body,
			this.httpOptions,
		);
	}

	getProcessActionByMovementTransactionId(movementTransactionId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeMovement/GetProcessActionByMovementTransactionId/" + movementTransactionId),
		).then((res) => <Checkbox[]>res);
	}

	addProcessActionMovementTransaction(model: EmployeeMovement) {
		var body = JSON.stringify(model);
		return this.http.post(
			this.apiUrl + "EmployeeMovement/addProcessActionMovementTransaction/",
			body,
			this.httpOptions,
		);
	}

	getEmployeeMovementTransactionsForApprove(approvalSearchModel: ApprovalSearchModel) {
		return firstValueFrom(
			this.http.post(`${this.apiUrl}EmployeeMovement/GetEmployeeMovementTransactionsForApprove`, approvalSearchModel),
		).then((res) => <EmployeeMovement[]>res);
	}
	getEmployeePunishmentForApprove(approvalSearchModel: ApprovalSearchModel) {
		return firstValueFrom(
			this.http.post(`${this.apiUrl}EmployeePunishment/GetEmployeePunishmentForApprove`, approvalSearchModel),
		).then((res) => <any[]>res);
	}

	AddProcessActionWelfareDocumentBySelected(employeeMovementBySelected: EmployeeMovementBySelected) {
		var body = JSON.stringify(employeeMovementBySelected);
		return this.http.post(
			this.apiUrl + "EmployeeMovement/AddProcessActionEmployeeMovementTransactionBySelected/",
			body,
			this.httpOptions,
		);
	}

	getEmployeeMovementTransactionHistoryDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeMovement/GetEmployeeMovementTransactionHistoryDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getEmployeeMovementTransactions(employeeId: number, movementType?: number, isWaitingEffectiveOnly?: boolean) {
		return firstValueFrom(
			this.http.get<any>(
				`${this.apiUrl}employee-movements/transactions?employeeId=${employeeId}&movementType=${movementType}&isWaitingEffectiveOnly=${isWaitingEffectiveOnly}`,
			),
		);
	}

	generateCertificateDocument(movementTransactionId, language?) {
		return firstValueFrom(
			this.http.get<any>(
				`${this.apiUrl}EmployeeMovement/GenerateCertificateDocument?movementTransactionId=${movementTransactionId}&language=${language}`,
			),
		);
	}

	getSalaryAdjustDocumentForApprove(searchModel: any) {
		var body = JSON.stringify(searchModel);
		return this.http.post<any>(`${this.apiUrl}v1/movements/salary-adjustments/pending-approval`, body);
	}
}
