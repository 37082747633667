import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { EmployeeMovement } from "../../../employee-movements/employee-movement/shared/employee-movement.model";
import { SearchManpower, OrganizationManpower } from "./budget.model";
import { LineChartModel } from "../../../shared/models/common.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class BudgetService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getOrganizationManpower(year: number, rankNoFrom: number, rankNoTo: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Organization/GetOrganizationManpower/" + year + "/" + rankNoFrom + "/" + rankNoTo),
		).then((res) => <any>res);
	}

	exportEmployeeBudgetByOrg(budgetAdjust: any) {
		var body = JSON.stringify(budgetAdjust);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(this.http.post(this.apiUrl + "Budget/ExportEmployeeBudgetByOrg", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	addMovementRaiseSalary(model: EmployeeMovement) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Budget/AddMovementRaiseSalary", body, httpOptions);
	}

	getTransactionHistory() {
		return firstValueFrom(this.http.get(this.apiUrl + "Budget/GetTransactionHistory")).then((res) => <any>res);
	}

	deleteTransactionHistoryDateEffective(dateEffective: string) {
		var body = JSON.stringify(dateEffective);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Budget/DeleteTransactionHistoryDateEffective", body, httpOptions);
	}

	getLineChartManpower(searchManpower: SearchManpower) {
		var body = JSON.stringify(searchManpower);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Budget/GetLineChartManpower", body, httpOptions)).then(
			(res) => <LineChartModel>res,
		);
	}

	getEmployeeManpowerGroupOrganization(searchManpower: SearchManpower) {
		var body = JSON.stringify(searchManpower);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(this.apiUrl + "Budget/GetEmployeeManpowerGroupOrganization", body, httpOptions),
		).then((res) => <any>res);
	}

	exportManpower(searchManpower: SearchManpower) {
		var body = JSON.stringify(searchManpower);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Budget/ExportManpower", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	exportEmployeeManpower(searchManpower: SearchManpower) {
		var body = JSON.stringify(searchManpower);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Budget/ExportEmployeeManpower", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	editOrganizationManpower(manpower: OrganizationManpower) {
		var body = JSON.stringify(manpower);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Budget/EditOrganizationManpower", body, httpOptions);
	}
}
