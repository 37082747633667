import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnInit,
	Output,
	SimpleChanges,
	booleanAttribute,
	forwardRef,
	signal,
} from "@angular/core";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	AbstractControl,
	ValidationErrors,
	ValidatorFn,
	UntypedFormArray,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { Router } from "@angular/router";
import { DateFormat } from "@gofive/angular-common";
import { InfoBarType, BaseModule } from "@gofive/design-system-base";
import { DialogInformationModel, DialogService, DialogModule } from "@gofive/design-system-dialog";
import { DropdownSelectMode, DropdownModule } from "@gofive/design-system-dropdown";
import { ToastDataModel } from "@gofive/design-system-toast/lib/models/toast.model";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DataTableDirective } from "angular-datatables";
import { SelectItem } from "primeng/api/selectitem";
import { BehaviorSubject, Subscription, firstValueFrom } from "rxjs";
import { EmployeeMovementService } from "src/app/employee-movements/employee-movement/shared/employee-movement.service";
import { EmployeeService } from "src/app/employee-movements/employee/shared/employee.service";
import {
	AdjustmentItemSAModel,
	Employee,
	EmployeeSalaryAdjustmentModel,
	MovementIncomeTransactionsSAModel,
	MovementSalaryAdjustmentModel,
} from "src/app/employee-movements/shared/employee-movements.model";
import { EmployeeMovementsService } from "src/app/employee-movements/shared/employee-movements.service";
import { WorkflowService } from "src/app/setting/workflow/shared/workflow.service";
import { ChartType } from "src/app/shared/enum/chart-type.enum";
import { MessageWarningType } from "src/app/shared/enum/message-warning-type.enum";
import { Movement } from "src/app/shared/enum/movement.enum";
import { RecruitmentStatus } from "src/app/shared/enum/recruitment-status-enum";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { isNullOrUndefined, isUndefined } from "src/app/shared/sharing.service";
import { DefaultFilter } from "src/app/shared/template/people-picker/people-picker.component";
import { environment } from "src/environments/environment";
import { DataSharingService, UserControlService, UserInfoService } from "../../core";
import { CompanyProfile } from "../../master/company-profile/shared/company-profile.model";
import { CompanyProfileService } from "../../master/company-profile/shared/company-profile.service";
import { OrganizationService } from "../../master/organization/shared/organization.service";
import { EmployeeStatus } from "../../shared/enum/employee-status.enum";
import { Module, ModuleWorkflow } from "../../shared/enum/module.enum";
import { ProcessAction } from "../../shared/enum/process-action.enum";
import { ApiResult } from "../../shared/models/api-result.model";
import { Dropdown } from "../../shared/models/dropdown.model";
import { CommonService } from "../../shared/service/common.service";
import { MasterSetup, MasterTypeEnum, MasterTypeTerminationSSO } from "src/app/shared/enum/master-setup.enum";
import { AssessmentMasterService } from "src/app/assessment/shared/assessment-master.service";
import { AssessmentModel } from "src/app/assessment/shared/assessment.model";
import { AssessmentService } from "src/app/assessment/shared/assessment.service";
import { AssessmentType } from "src/app/shared/enum/assessment-type.enum";
import { AssessmentFormService } from "src/app/assessment/assessment-form/shared/assessment-form.service";
import { SalaryBand } from "src/app/shared/component/salary-band/salary-band.component";
import { ToastService } from "@gofive/design-system-toast";
import { FinancialCategory } from "src/app/shared/enum/financial-category.enum";
import { RouteURL } from "src/app/shared/enum/route-path.enum";
import { WelfareType } from "src/app/shared/enum/welfare-type.enum";
import { ConditionTypeWelfare } from "src/app/welfare/welfare-master/welfare-master-add/welfare-master-add.component";
import { AssessmentComponent } from "../../shared/module/assessment/assessment.component";
import { PeoplePickerComponent } from "../../shared/template/people-picker/people-picker.component";
import { AvatarModule } from "@gofive/design-system-avatar";
import { SalaryBandComponent } from "../../shared/component/salary-band/salary-band.component";
import { SelectButtonModule } from "primeng/selectbutton";
import { ButtonModule as PrimengButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { InputModule } from "@gofive/design-system-input";
import { CalendarModule } from "@gofive/design-system-calendar";
import { InfoTooltipComponent } from "../../shared/template/info-tooltip/info-tooltip.component";
import { InfoBarComponent } from "../../shared/component/info-bar/info-bar.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { SidebarModule, NavigationModule } from "@gofive/design-system-navigation";
import { TwoFactorAuthenticationComponent } from "../../shared/component/two-factor-authentication/two-factor-authentication.component";
import { NgIf, NgTemplateOutlet, NgStyle, NgClass, NgFor, AsyncPipe, DecimalPipe, DatePipe } from "@angular/common";
import { ButtonModule } from "@gofive/design-system-button";
import { EmployeeMovement } from "src/app/employee-movements/employee-movement/shared/employee-movement.model";
import { LanguageLocal } from "src/app/shared/enum/language-type.enum";
import { EmpeoCommonModule } from "src/app/empeo-common.module";
import { EmployeeFinancialService } from "src/app/employee-movements/employee-payroll/shared/employee-financial.service";
import { AssetsService } from "src/app/shared/service/assets.service";
import { AssetsModel } from "src/app/shared/models/assets.model";
import { BadgeModule } from "@gofive/design-system-badge";
import { AssetsManagementTableComponent } from "src/app/shared/component/assets-management-table/assets-management-table.component";
import { ColumnConfiguration, StringColumnConfiguration } from "@gofive/design-system-table";
import { AssetSlideBarComponent } from "src/app/shared/component/assets-sidebar/assets-sidebar.component";

enum IndexPageMovement {
	home = 1,
	peoplePicker = 2,
}
@Component({
	selector: "app-movement-sidebar-add",
	templateUrl: "./movement-sidebar-add.component.html",
	styleUrls: ["./movement-sidebar-add.component.scss"],
	standalone: true,
	imports: [
		NgIf,
		AssetSlideBarComponent,
		AssetsManagementTableComponent,
		TwoFactorAuthenticationComponent,
		NgTemplateOutlet,
		SidebarModule,
		TooltipModule,
		NgxSkeletonLoaderModule,
		InfoBarComponent,
		FormsModule,
		ReactiveFormsModule,
		InfoTooltipComponent,
		CalendarModule,
		BaseModule,
		DropdownModule,
		InputModule,
		NgStyle,
		InputTextModule,
		ButtonModule,
		PrimengButtonModule,
		SelectButtonModule,
		NgClass,
		NgFor,
		NavigationModule,
		SalaryBandComponent,
		AvatarModule,
		forwardRef(() => PeoplePickerComponent),
		DialogModule,
		AssessmentComponent,
		AsyncPipe,
		DecimalPipe,
		DatePipe,
		TranslateModule,
		EmpeoCommonModule,
		BadgeModule,
	],
})
export class MovementSidebarAddComponent implements OnInit {
	windowWidth: number;
	get enumProcessAction() {
		return ProcessAction;
	}
	currentDate: Date = new Date();
	//#region Variable
	@Input({ transform: booleanAttribute }) isLockSelect: boolean = false;
	@Input({ transform: booleanAttribute }) isSidebarAdd: boolean = false;
	@Input() movementType: number;
	@Input() employeeId: number = null;
	@Input() masterSetId: number = null;
	@Output() closeSidebar = new EventEmitter();
	@Output() success = new EventEmitter();

	@Input() movementTransactionId: number = 0;
	@Input() isMobile: boolean = false;
	@Input() subscribeGoBack: boolean = true;
	@Input() isSelfResign: boolean = false;
	@Input() employeeMovement: EmployeeMovement;
	isGroupMovement: boolean = false;
	public dtOptions: DataTables.Settings = {
		processing: false,
		searching: false,
		paging: false,
		info: false,
		autoWidth: false,
		columnDefs: [{ orderable: false, targets: "_all" }],
	};
	markAmount = "xx,xxxx";
	selectEmp: any;
	optionServiceYearsCont: any[] = [
		{ text: "emp004_renewal", value: true },
		{ text: "emp004_unrenewal", value: false },
	];

	optionReplaceEmployee: any[] = [
		{ text: "emp001_new", value: false },
		{ text: "emp001_replace", value: true },
	];

	optionAttendance: any[] = [
		{ text: "emp001_t04_record", value: true },
		{ text: "emp001_t04_dont_record", value: false },
	];
	public fieldsDDL = { text: "name", value: "id" };
	public fieldsDDL_EN = { text: "name_EN", value: "id" };

	public fieldsWelfareDDL = { text: "name", value: "welfareId" };
	public fieldsWelfareDDL_EN = { text: "name_EN", value: "welfareId" };

	public filedOrganize = {
		text: "name",
		value: "organizationId",
		children: "children",
	};

	public filedOrganizeEN = {
		text: "name_EN",
		value: "organizationId",
		children: "children",
	};

	public displayAssetsSidebar: boolean = false;
	public selectAssetId: number;
	public sortOrder: string = "Cost DESC";
	public paddingTable: string = "12px 16px";
	public columns: ColumnConfiguration[] = [
		{
			...new StringColumnConfiguration("Name", null, "asset_name"),
			width: "70%",
			minWidth: "70%",
			textAlign: "left",
			showIconSort: true,
			paddingHeader: this.paddingTable,
			paddingContent: this.paddingTable,
		},
		{
			...new StringColumnConfiguration("Cost", null, "asset_value"),
			width: "30%",
			minWidth: "180px",
			textAlign: "right",
			showIconSort: true,
			paddingHeader: this.paddingTable,
			paddingContent: this.paddingTable,
		},
	];

	resignApiError: boolean = false;
	warningBoxMessage: string = "";
	dtElement: DataTableDirective;
	employeeModel: Employee = new Employee();
	peopleFieldsSupervisor: any = { text: "fullname", picture: "pictureThumbnailURL", value: "employeeId" };
	peopleFieldsSupervisor_EN: any = { text: "fullname_EN", picture: "pictureThumbnailURL", value: "employeeId" };
	peopleFields: any = { text: "fullname", detail: "fullname", picture: "pictureURL", value: "employeeId" };
	peopleFields_EN: any = { text: "fullname_EN", picture: "pictureURL", value: "employeeId" };
	massTransfer: number = Movement.massTransfer;
	form: UntypedFormGroup;
	formAgencyTransfers: UntypedFormGroup;
	employee: Employee = new Employee();
	oldDataOfEmployee: Employee = new Employee();
	companyProfile: CompanyProfile;
	shiftDDL: Dropdown[];
	calendarDDL: Dropdown[];
	employmentTypesDDL: Dropdown[];
	paymentMethodDDL: Dropdown[];
	bankCodeDDL: Dropdown[];
	ranksDDL: Dropdown[];
	positionDDL: Dropdown[];
	movementTypeDDL: Dropdown[];
	departmentIdOld: any;
	upperSupervisorIdOld: any;
	companyDDL: Dropdown[];
	orgLevelOneDDL: Dropdown[];
	orgLevelTwoDDL: any[];
	orgLevelThreeDDL: any[];
	orgLevelFourDDL: any[];
	orgLevelFiveDDL: any[];
	orgLevelSixDDL: any[];
	displayEmployee: boolean = false;
	confirmation: any;
	saveConfirmation: any;
	employeeNo: string;
	movementTypeId: any = null;
	movementTypeName: string;
	movementTypeName_EN: string;
	employmentType: any = null;
	isServiceYearsCont: boolean = true;
	replaceEmployeeId: any = null;
	upperSupervisorId: any = null;
	upperNewSupervisorId: any = null;
	displayUpperSupervisor: boolean = false;
	paymentMethod: any = null;
	rankNo: any = null;
	listPeople: any;
	listPeopleInDepartment: any;
	listPeopleFilter: any;
	listPeopleInDepartmentTmp: any;
	positionId: any = null;
	companyId: any = null;
	orgLevelOne: any = null;
	orgLevelTwo: any = null;
	orgLevelThree: any = null;
	orgLevelFour: any = null;
	orgLevelFive: any = null;
	orgLevelSix: any = null;
	shiftId: any = null;
	bankId: any = null;
	calendarId: any = null;
	employeesData: any[];
	newPostDays: number;
	employeeStatus: number = EmployeeStatus.resign;
	employeeName: string;
	employees: any[];
	dateNewPostEffective: Date = new Date();
	dateEffective: Date = null;
	uppersupervisorName: string;
	uppersupervisorName_EN: string;
	public organizationId: number = 0;
	public companyIdOrg: any = null;
	public organizationIdNew: number = 0;
	public levelsNew: number = 0;
	public isSelectOrgNew: boolean = false;
	public levels: number = 0;
	public dateResign: Date = null;
	public dateEmployment: any = null;
	public isProbation: boolean = false;
	public dayOfProbation: number = null;
	public formatterDay = (value: number) => `${value} ${this.translate.instant("common_days")}`;
	public parserDate = (value: string) => value.replace(` ${this.translate.instant("common_days")}`, "");
	dateEffectiveDefault: Date = null;
	dateDocument: Date = null;
	organization: any;
	selectEmployeeText: string;
	public countUnder: number = 0;
	public subordinateList: any;
	public subordinateListTmp: any;
	public checkMovement: boolean = false;
	public messageWarningType: number = MessageWarningType.MovementType;
	public infoPaymentMethodChange: boolean = false;
	public isEdit: boolean = false;
	public pageEmployeeView: boolean = false;
	public isDialogSubordinate: boolean = false;
	public applicantInformation: any;
	public convertEmployee: boolean = false;
	public showSupervisor: boolean = false;
	movementTransaction: any;
	image: any = this.defaultPic;
	upperSupervisor: any;
	id: number;
	private language$: Subscription;
	userInfo: any;
	get recruitmentStatusEnum() {
		return RecruitmentStatus;
	}
	public lockFilter: DefaultFilter[];
	get DateFormat() {
		return DateFormat;
	}
	get DropdownSelectMode() {
		return DropdownSelectMode;
	}
	language: string;
	tabPermissionPayroll: any;
	isShowSalary: boolean = false;
	reasonToResignDDL: Dropdown[];
	reasonToResignSSODDL: any;
	reasonToResignSSO: any;
	//#endregion
	public isIncomeAdjust: boolean = false;

	public adjustSelected: SelectItem[] = [
		{ label: "emp002_adjust", value: true },
		{ label: "emp002_dont_adjust", value: false },
	];
	public recordSelected: SelectItem[] = [
		{ label: "emp001_t04_record", value: true },
		{ label: "emp001_t04_dont_record", value: false },
	];
	textHeader: string;
	movementTransactionId$: Subscription;
	currentUserInfo$: Subscription;
	listPeople$: Subscription;

	permissionViewAssessment: boolean = null;
	public isDuplicateEmployeeNo: boolean = false;
	public assetList: AssetsModel[] = [];

	public subscription3: Subscription;
	constructor(
		private employeeService: EmployeeService,
		private router: Router,
		private fb: UntypedFormBuilder,
		private employeeMovementsService: EmployeeMovementsService,
		private companyProfileService: CompanyProfileService,
		private commonService: CommonService,
		public employeeMovementService: EmployeeMovementService,
		public dataShare: DataSharingService,
		private _toastService: ToastService,
		private translate: TranslateService,
		@Inject("DEFAULT_EMP_PIC") private defaultPic: string,
		private _toastHelperService: ToastHelperService,
		private userService: UserInfoService,
		private organizationService: OrganizationService,
		private workflowService: WorkflowService,
		private _dialogService: DialogService,
		public data: DataSharingService,
		public _cdr: ChangeDetectorRef,
		private assessmentMasterService: AssessmentMasterService,
		private assessmentService: AssessmentService,
		private assessmentFormService: AssessmentFormService,
		private userControlService: UserControlService,
		private employeeFinancialService: EmployeeFinancialService,
		public assetsService: AssetsService,
	) {
		const lang = this.router.routerState.snapshot.root.queryParams["lang"];
		if (lang != null && lang != undefined && lang) {
			this.isMobile = true;
			this.movementTransactionId =
				Number.parseInt(this.router.routerState.snapshot.root.queryParams["movementTransactionId"]) ?? 0;
			this.movementType = Number.parseInt(this.router.routerState.snapshot.root.queryParams["movementType"]);
			const paramIsSelfResign = this.router.routerState.snapshot.root.queryParams["isSelfResign"];
			this.isSelfResign = paramIsSelfResign ? JSON.parse(paramIsSelfResign) : false;
			this.language = lang;
			this.dataShare.setLang(this.language);
			if (!document.getElementById("assessment-js")) {
				let script = document.createElement("script");
				script.type = "text/javascript";
				script.src = "https://dev.tks.co.th/gofive-core/assessment/assets/js/assessment.js";
				script.id = "assessment-js";
				document.head.appendChild(script);
			}
		}
		this.windowWidth = window.innerWidth;
	}

	@HostListener("window:resize")
	onResize() {
		this.windowWidth = window.innerWidth;
	}
	public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	isAuth: boolean = false;
	authStatus: boolean = false;
	receiveMessageAuth(event) {
		if (event) {
			this.authStatus = true;

			const isSeparation = this.movementType === ProcessAction.resign && !this.isSelfResign; //เอกสารพ้นสภาพ
			const isRenewal = this.movementType === ProcessAction.renewal;
			const isEditMyDocument = this.isEdit && this.isMyDocument; // HR ที่ไม่มีสิทธิ์เห็นแต่เป็นคนสร้างเอกสาร แก้เงินชดเชยได้
			const canEditSeveranceAmount = (isSeparation || isRenewal) && (isEditMyDocument || !this.isEdit);

			if (this.tabPermissionPayroll || canEditSeveranceAmount) {
				this.isShowSalary = !this.isShowSalary;
			} else {
				const toast: ToastDataModel = {
					title: this.translate.instant("toast_title_sorry"),
					description: this.translate.instant("toast_desc_no_permission_view_salary"),
				};
				return this._toastHelperService.validation(toast);
			}
		}
	}
	receiveMessageClose(event) {
		if (event) {
			this.isAuth = false;
		}
	}
	showSalary() {
		if (!this.authStatus) {
			this.isAuth = true;
			return;
		}
		const isSeparation = this.movementType === ProcessAction.resign && !this.isSelfResign; //เอกสารพ้นสภาพ
		const isRenewal = this.movementType === ProcessAction.renewal;
		const isEditMyDocument = this.isEdit && this.isMyDocument; // HR ที่ไม่มีสิทธิ์เห็นแต่เป็นคนสร้างเอกสาร แก้เงินชดเชยได้
		const canEditSeveranceAmount = (isSeparation || isRenewal) && (isEditMyDocument || !this.isEdit);

		if (this.tabPermissionPayroll || canEditSeveranceAmount) {
			this.isShowSalary = !this.isShowSalary;
		} else {
			const toast: ToastDataModel = {
				title: this.translate.instant("toast_title_sorry"),
				description: this.translate.instant("toast_desc_no_permission_view_salary"),
			};
			return this._toastHelperService.validation(toast);
		}
	}
	closeWebView(event = "close") {
		if (window["closeWebView"]) {
			window["closeWebView"](JSON.stringify({ event: event }));
		}
	}
	closeWebViewMessage(event) {
		if (window["closeWebView"]) {
			window["closeWebView"](JSON.stringify({ message: event }));
		}
	}
	indexView: number = IndexPageMovement.home;
	back() {
		if (this.displayEmployee) {
			this.displayEmployee = false;
		} else {
			this.closeWebView();
		}
	}

	ngOnInit() {
		if (this.subscribeGoBack) {
			window["goBack"] = () => {
				this.back();
				this._cdr.detectChanges();
			};
		}

		const pageEmployeeView = this.router.routerState.snapshot.root.queryParams["employeeView"];
		this.pageEmployeeView = isNullOrUndefined(pageEmployeeView) ? false : pageEmployeeView;
		this.loadAPI();
		if (this.movementType == this.massTransfer) {
			this.getOrganizeNodeDDL();
		}
		this.formInitial();
		this.mapLockFilter();
		if (this.isMobile) {
			this.currentUserInfo$?.unsubscribe();
			this.currentUserInfo$ = this.userService.getCurrentUserInfo().subscribe((user) => {
				if (!isNullOrUndefined(user)) {
					this.userInfo = user;
					if (this.isSelfResign) {
						this.employeeId = user.employeeId;
						this.getEmployee(this.employeeId);
						this.getAssessmentMaster();
					}
				}
				if (this.movementTransactionId > 0) {
					this.isLoading$.next(true);
					this.isEdit = true;
					this.loadMovement(this.movementTransactionId);
				} else {
					this.isLoading$.next(false);
				}
			});
		} else {
			this.currentUserInfo$?.unsubscribe();
			this.currentUserInfo$ = this.dataShare.currentUserInfo.subscribe((res) => {
				if (!isNullOrUndefined(res)) {
					this.userInfo = res;
				}
			});
			this.movementTransactionId$?.unsubscribe();
			this.movementTransactionId$ = this.dataShare.currentdataSourceMovementTransactionId.subscribe(
				(movementTransactionId) => {
					this.movementTransactionId =
						this.movementTransactionId > 0 ? this.movementTransactionId : movementTransactionId;
					if (this.movementTransactionId > 0) {
						this.isEdit = true;
						this.loadMovement(movementTransactionId);
					} else if (this.employeeId <= 0) {
						this.isLoading$.next(false);
					}
				},
			);
		}

		this.onGroupMovement();

		if (!isNullOrUndefined(this.dataShare.applicantConverting)) {
			this.applicantInformation = this.dataShare.applicantConverting;
			this.dataShare.applicantConverting = null;
			this.convertEmployee = true;
		}
		//#region Call api

		this.language$?.unsubscribe();
		this.language$ = this.dataShare.currentLanguage.subscribe((lang) => {
			this.language = lang;
			this.setText();
		});
		//#endregion

		this.getAssessmentMaster();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.masterSetId?.currentValue) {
			this.masterSetId = changes.masterSetId?.currentValue;
		}

		if (changes.isLockSelect?.currentValue) {
			this.isLockSelect = changes.isLockSelect?.currentValue;
		}

		if (changes.employeeId?.currentValue && this.movementTransactionId <= 0) {
			this.employeeId = changes.employeeId?.currentValue;
			this.getEmployee(this.employeeId);
		}
	}
	loadAPI() {
		this.listPeople$?.unsubscribe();
		this.listPeople$ = this.commonService.getPeople().subscribe((res) => {
			this.listPeople = res.data;
			if (this.employeeId > 0 && !this.isEdit) {
				this.getEmployee(this.employeeId);
			}
		});
		this.commonService.getDDLCalendar().then((res) => {
			this.calendarDDL = res;
		});
		this.commonService.getDDLPaymentMethod().then((res) => {
			this.paymentMethodDDL = res;
		});
		this.commonService.getDDLEmploymentTypes().then((res) => {
			this.employmentTypesDDL = res;
		});
		this.commonService.getDDLRanks().then((res) => {
			this.ranksDDL = res;
		});

		const systemRegisNo = this.isSelfResign ? MasterSetup.reasonToLeave : MasterSetup.reasonToSeparation;
		this.commonService.getDDLMasterSetupBySystemRegisNo(systemRegisNo.toString(), !this.isSelfResign).then((res) => {
			this.reasonToResignDDL = res;
		});
		this.organizationService.getOrganizationNode().then((res) => {
			this.organizeNode = res.data;
		});
		this.commonService.getMasterTypeByParentId(MasterTypeEnum.TerminationReasonSSO).then((res) => {
			this.reasonToResignSSODDL = res?.data?.data;
		});

		if (this.movementTransactionId > 0) {
			this.getAssetTable();
		}
	}

	onClickView(id) {
		this.selectAssetId = id;
		this.displayAssetsSidebar = true;
	}

	onHideSidebarAsset(payload) {
		this.displayAssetsSidebar = payload.display;
	}

	onGroupMovement() {
		if (!isNullOrUndefined(this.employeeMovement)) {
			this.isGroupMovement = true;

			this.departmentIdOld = this.employeeMovement.prevOrgDepartmentId;
			this.upperSupervisorIdOld = this.employeeMovement.prevUpperSupervisorId;
			this.form.get("departmentIdOld").patchValue(this.departmentIdOld);
			this.form.get("upperSupervisorIdOld").patchValue(this.upperSupervisorIdOld);
			this.departmentId = this.employeeMovement.orgDepartmentId;
			this.form.get("departmentId").patchValue(this.departmentId);

			this.getListPeople({
				selectedItem: {
					organizationId: this.departmentIdOld,
					name: "",
					employeeId: this.employeeMovement.employeeId,
				},
			});
			let employeeRequest = Object.assign([], this.employeeMovement?.employeeRequests);
			this.employeesData = [
				...employeeRequest.map((m) => {
					m.firstname = m.nameSurname;
					m.fullname = m.nameSurname;
					m.fullname_EN = m.nameSurname_EN;
					m.pictureURL = m.pictureThumbnailURL;
					return m;
				}),
			];
			this.employees = [
				...employeeRequest.map((m) => {
					return m.employeeId;
				}),
			];
			let movementTransactionIds = [
				...employeeRequest.map((m) => {
					return m.movementTransactionId;
				}),
			];
			this.form.get("movementTransactionIds").patchValue(movementTransactionIds);
			this.formAgencyTransfers.get("movementTransactionIds").patchValue(movementTransactionIds);

			this.dateEffective = this.employeeMovement.dateEffective;
			this.form.get("dateEffective").patchValue(this.dateEffective);
			this.form.get("remarks").patchValue(this.employeeMovement.remarks);

			this.upperSupervisorId = this.employeeMovement.upperSupervisorId;
			this.employeeModel.upperSupervisorId = this.employeeMovement.upperSupervisorId;
			this.form.get("upperSupervisorId").patchValue(this.upperSupervisorId);

			if (this.movementType == ProcessAction.changeOfSupervisor) {
				this.selectEmp = this.employees;
				this.form.get("newSupervisorId").patchValue(this.employeeMovement.upperSupervisorId);

				this.employeeMovementsService.getEmployeeById(this.employeeMovement.prevUpperSupervisorId).then((emp) => {
					this.employee = emp;
					this.oldDataOfEmployee = emp;
					this.employeeId = this.employee.employeeId;
					this.employeeNo = this.employee.employeeNo;
					this.rankNo = this.employee.rankNo;
					this.positionId = this.employee.positionId;
					this.image = !isNullOrUndefined(this.employee.pictureThumbnailURL)
						? this.employee.pictureThumbnailURL
						: this.defaultPic;
					this.employeeName = this.language == "th-TH" ? this.employee.nameSurname : this.employee.nameSurname_EN;
					this.form.get("employeeNo").patchValue(this.employeeNo);
					this.form.get("upperSupervisorName").patchValue(this.employee.nameSurname);
					this.form.get("paymentMethod").patchValue(this.employee.paymentMethod);
					this.form.get("employmentType").patchValue(this.employee.employmentType);
					this.form.get("rankNo").patchValue(this.employee.rankNo);
				});

				this.employeeMovementsService
					.getSupervisorByEmployeeId(this.employeeMovement.prevUpperSupervisorId, ChartType.under)
					.then((res) => {
						const filter = res.data.filter((e) => e.employeeId != this.employeeMovement.prevUpperSupervisorId); // ได้ตัวเองออกไปด้วย เลยกรองออก
						this.subordinateList = filter;
						this.subordinateListTmp = filter;
						this.countUnder = filter.length;
						this.onCheckCountUnder();
					});

				firstValueFrom(this.commonService.getPeople(this.employeeMovement.upperSupervisorFullName)).then((res) => {
					this.listPeople = res.data;
				});
			}
		}
	}

	getAssetTable() {
		var sortText = this.sortOrder ? this.sortOrder : "";
		if (
			sortText != "" &&
			sortText != "Name" &&
			this.sortOrder != "Name DESC" &&
			this.sortOrder != "Cost" &&
			this.sortOrder != "Cost DESC" &&
			this.language != "th-TH"
		) {
			sortText = `${this.sortOrder}_EN`;
		}

		const assetSearch = { sortOrder: sortText, employeeId: this.employeeId };
		this.assetsService.getAssets(assetSearch).then((response) => {
			this.assetList = response?.data?.assets || [];
		});
	}

	onSortTable(txt) {
		this.sortOrder = txt;
		this.getAssetTable();
	}

	setText() {
		const movementActions = {
			[ProcessAction.renewal]: {
				textHeader: this.isEdit ? "movement_rehire_edit" : "emp004_renewal_employee",
				selectEmployeeText: "emp004_select_retired_employees",
			},
			[ProcessAction.permanent]: {
				textHeader: this.isEdit ? "movement_permanent_edit" : "movement_permanent",
				selectEmployeeText: "common_select_employee_movement",
			},
			[ProcessAction.employmentConditions]: {
				textHeader: this.isEdit ? "movement_employment_cond_change_edit" : "ess009_employment_change_operation",
				selectEmployeeText: "common_select_employee_movement",
			},
			[ProcessAction.resign]: this.isSelfResign
				? {
						textHeader: "resignation_request",
						selectEmployeeText: "common_select_employee_movement",
					}
				: {
						textHeader: this.isEdit ? "movement_separation_edit" : "movement_resign",
						selectEmployeeText: "common_select_employee_movement",
					},
			[ProcessAction.promote]: {
				textHeader: this.isEdit ? "movement_promotion_edit" : "ess009_promote_rank",
				selectEmployeeText: "common_select_employee_movement",
			},
			[ProcessAction.raiseTheSalary]: {
				textHeader: this.isEdit ? "movement_salary_adjust_edit" : "pay002_salary_adjustment",
				selectEmployeeText: "common_select_employee_movement",
			},
			[ProcessAction.agencyTransfer]: {
				textHeader: this.isEdit ? "movement_department_transfer_edit" : "ess009_transfer_agency",
				selectEmployeeText: "common_select_employee_movement",
			},
			[ProcessAction.crossCompanyTransfer]: {
				textHeader: this.isEdit ? "movement_company_transfer_edit" : "emp001_cross_company_transfer",
				selectEmployeeText: "common_select_employee_movement",
			},
			[Movement.massTransfer]: {
				textHeader: this.isEdit ? "movement_mass_transfer_edit" : "component_emp007",
				selectEmployeeText: "common_select_employee_movement",
			},
			[Movement.transferSupervisor]: {
				textHeader: this.isEdit ? "movement_supervisor_reassign_edit" : "emp010_transfer_supervisor",
				selectEmployeeText: "common_select_employee_movement",
			},
		};

		const selectedAction = movementActions[this.movementType];
		if (selectedAction) {
			this.textHeader = selectedAction.textHeader;
			this.selectEmployeeText = selectedAction.selectEmployeeText;
		}

		this.salaryAdjSection = [
			{
				name: this.translate.instant("ess009_salary_adj_performance_dropdown"),
				id: SalaryAdjSection.performance,
			},
			{
				name: this.translate.instant("ess009_salary_adj_special_dropdown"),
				id: SalaryAdjSection.special,
			},
			{
				name: this.translate.instant("ess009_salary_adj_position_dropdown"),
				id: SalaryAdjSection.position,
			},
		];
	}
	receiveMovementWaitingEffective(event) {
		if (event.length) {
			this.movementTransactionId = event[0].movementTransactionId;
			if (this.movementType == ProcessAction.resign && !this.isSelfResign) {
				const dataWaitingEffective = event;
				this.form.controls["employeeNo"].setValue(this.employee.employeeNo);
				if (dataWaitingEffective.length) {
					this.form.controls["upperSupervisorId"].setValue(dataWaitingEffective[0].upperSupervisorId);
					this.form.controls["dateEffective"].setValue(dataWaitingEffective[0].dateEffective);
					this.form.controls["remarks"].setValue(dataWaitingEffective[0].remarks);
				}

				this.employeeMovementsService
					.getSupervisorByEmployeeId(this.employee.employeeId, ChartType.under)
					.then((res) => {
						const filter = res.data.filter((e) => e.employeeId != this.employee.employeeId); // ได้ตัวเองออกไปด้วย เลยกรองออก
						this.subordinateList = filter;
						this.subordinateListTmp = filter;
						this.countUnder = filter.length;
						this.onCheckCountUnder();
					});
			}

			if (this.movementType == ProcessAction.employmentConditions) {
				const dataWaitingEffective = event;
				this.form.controls["employeeNo"].setValue(this.employee.employeeNo);
				if (dataWaitingEffective.length) {
					this.oldDataOfEmployee = event[0];
					this.form.controls["dateEmployment"].setValue(dataWaitingEffective[0].dateEmployment);
					this.form.controls["paymentMethod"].setValue(dataWaitingEffective[0].paymentMethod);
					this.form.controls["shiftId"].setValue(dataWaitingEffective[0].shiftId);
					this.form.controls["calendarId"].setValue(dataWaitingEffective[0].calendarId);
					this.form.controls["upperSupervisorId"].setValue(dataWaitingEffective[0].upperSupervisorId);
					this.isProbation = dataWaitingEffective[0].isProbation;
					this.form.controls["dayOfProbation"].setValue(dataWaitingEffective[0].dayOfProbation);
					this.form.controls["isIncomeAdjust"].setValue(false);
					this.form.controls["isAttendance"].setValue(dataWaitingEffective[0].isAttendance);
					this.form.controls["dateEffective"].setValue(dataWaitingEffective[0].dateEffective);
					this.form.controls["remarks"].setValue(dataWaitingEffective[0].remarks);
					this.movementTransactionId = dataWaitingEffective[0].movementTransactionId;
				}
			}
		} else {
			if (
				this.movementType == ProcessAction.resign &&
				!this.isSelfResign &&
				!isNullOrUndefined(this.employee.employeeId)
			) {
				this.form.controls["employeeNo"].setValue(this.employee.employeeNo);
				this.form.controls["upperSupervisorId"].setValue(this.employee.upperSupervisorId);
				this.form.controls["dateEffective"].setValue(new Date());
				this.movementTransactionId = 0;

				this.employeeMovementsService
					.getSupervisorByEmployeeId(this.employee.employeeId, ChartType.under)
					.then((res) => {
						const filter = res.data.filter((e) => e.employeeId != this.employee.employeeId); // ได้ตัวเองออกไปด้วย เลยกรองออก
						this.subordinateList = filter;
						this.subordinateListTmp = filter;
						this.countUnder = filter.length;
						this.onCheckCountUnder();
					});
			}

			if (this.movementType == ProcessAction.employmentConditions) {
				this.movementTransactionId = 0;
				this.form.controls["employeeNo"].setValue(this.employee.employeeNo);
				this.form.controls["dateEmployment"].setValue(this.employee.dateEmployment);
				this.form.controls["paymentMethod"].setValue(this.employee.paymentMethod);
				this.form.controls["shiftId"].setValue(this.employee.shiftId);
				this.form.controls["calendarId"].setValue(this.employee.calendarId);
				this.form.controls["upperSupervisorId"].setValue(this.employee.upperSupervisorId);
				this.isProbation = this.employee.employeeStatus == EmployeeStatus.probation;
				this.form.controls["dayOfProbation"].setValue(119);
				this.form.controls["isIncomeAdjust"].setValue(false);
				this.form.controls["isAttendance"].setValue(this.employee.isAttendance);
				this.form.controls["dateEffective"].setValue(new Date());
			}
		}
	}
	formInitial() {
		//#region Form control
		this.formAgencyTransfers = this.fb.group({
			movementTransactionId: new UntypedFormControl(0),
			companyId: new UntypedFormControl(""),
			employeeIds: new UntypedFormControl(""),
			newSupervisorId: new UntypedFormControl(""),
			documentNo: new UntypedFormControl(""),
			organizeId: new UntypedFormControl(""),
			dateDocument: new UntypedFormControl(""),
			orgLevelOne: new UntypedFormControl(""),
			orgLevelTwo: new UntypedFormControl(""),
			orgLevelThree: new UntypedFormControl(""),
			orgLevelFour: new UntypedFormControl(""),
			orgLevelFive: new UntypedFormControl(""),
			orgLevelSix: new UntypedFormControl(""),
			remarks: new UntypedFormControl(""),
			movementTypeId: new UntypedFormControl(""),
			dateEffective: new UntypedFormControl("", Validators.required),
			isApprove: new UntypedFormControl(true),
			movementTransactionIds: new UntypedFormControl([]),
			upperSupervisorIdOld: new UntypedFormControl(0),
		});
		this.form = this.fb.group({
			employeeIds: new UntypedFormControl(""),
			employeeId: new UntypedFormControl(""),
			remarks: new UntypedFormControl(""),
			movementTypeId: new UntypedFormControl(""),
			newPostDays: new UntypedFormControl(""),
			bankId: new UntypedFormControl(""), //ตัดออก
			bankAccountNo: new UntypedFormControl(""),
			replaceEmployeeId: new UntypedFormControl(""),
			upperSupervisorId: new UntypedFormControl(""),
			upperSupervisorIdOld: new UntypedFormControl(0),
			dateNewPostEffective: new UntypedFormControl(""),
			companyId: new UntypedFormControl(""),
			departmentId: new UntypedFormControl(""),
			employeeNo: new UntypedFormControl(""),
			isIncomeAdjust: new UntypedFormControl(false),
			isServiceYearsCont: new UntypedFormControl(true),
			isReplaceEmployee: new UntypedFormControl(false),
			isAttendance: new UntypedFormControl(true),
			documentNo: new UntypedFormControl(""), //ตัดออก
			dateEffective: new UntypedFormControl(""),
			dateEmployment: new UntypedFormControl(""),
			isProbation: new UntypedFormControl(false),
			dayOfProbation: new UntypedFormControl(""),
			dateDocument: new UntypedFormControl(new Date()), //ตัดออก
			employeeStatus: new UntypedFormControl(""),
			employmentType: new UntypedFormControl(""),
			paymentMethod: new UntypedFormControl(""),
			rankNo: new UntypedFormControl(""),
			positionId: new UntypedFormControl(""),
			shiftId: new UntypedFormControl(""),
			calendarId: new UntypedFormControl(""),
			isApprove: new UntypedFormControl(true),
			isWorkInfomation: new UntypedFormControl(true),
			upperSupervisorIdInput: new UntypedFormControl(""),
			orgLevelOne: new UntypedFormControl(""), //ตัดออก
			orgLevelTwo: new UntypedFormControl(""), //ตัดออก
			orgLevelThree: new UntypedFormControl(""), //ตัดออก
			orgLevelFour: new UntypedFormControl(""), //ตัดออก
			orgLevelFive: new UntypedFormControl(""), //ตัดออก
			orgLevelSix: new UntypedFormControl(""), //ตัดออก
			incomeAmount: new UntypedFormControl(0), //ตัดออก
			movementTransactionId: new UntypedFormControl(0),
			organizeId: new UntypedFormControl(0),
			departmentIdOld: new UntypedFormControl(0),
			newSupervisorId: new UntypedFormControl(""),
			upperSupervisorName: new UntypedFormControl(""),
			resignReason: new UntypedFormControl(""),
			isWillingness: new UntypedFormControl(false),
			isAcceptPolicy: new UntypedFormControl(false),
			terminationReasonId: new UntypedFormControl(""),
			isSelfResign: new UntypedFormControl(false),
			scoreId: new UntypedFormControl(""),
			movementTransactionIds: new UntypedFormControl([]),
			severanceAmount: new UntypedFormControl(0),
			createdBy: new UntypedFormControl(null),
		});

		if (this.movementType == ProcessAction.permanent || this.movementType == ProcessAction.employmentConditions) {
			this.form.controls["employeeNo"].setValidators(Validators.required);
			this.form.controls["employmentType"].setValidators(Validators.required);
			this.form.controls["paymentMethod"].setValidators(Validators.required);
			this.form.controls["rankNo"].setValidators(Validators.required);
			this.form.controls["positionId"].setValidators(Validators.required);
			this.form.controls["shiftId"].setValidators(Validators.required);
			this.form.controls["calendarId"].setValidators(Validators.required);
			this.form.controls["upperSupervisorId"].setValidators(Validators.required);
			if (this.tabPermissionPayroll) {
				this.form.controls["isIncomeAdjust"].setValidators(Validators.required);
				this.form.controls["incomeAmount"].setValidators(Validators.required);
			} else {
				this.form.controls["isIncomeAdjust"].clearValidators();
				this.form.controls["incomeAmount"].clearValidators();
			}
		}

		if (this.movementType == ProcessAction.changeOfSupervisor) {
			this.form.controls["upperSupervisorName"].setValidators(Validators.required);
			this.form.controls["newSupervisorId"].setValidators(Validators.required);
			this.form.controls["upperSupervisorId"].clearValidators();
		}

		if (this.movementType == ProcessAction.employmentConditions) {
			this.form.controls["employeeNo"].setValidators(Validators.required);
			this.form.controls["dateEmployment"].setValidators(Validators.required);
			if (this.tabPermissionPayroll) {
				this.form.controls["isIncomeAdjust"].setValidators(Validators.required);
				this.form.controls["incomeAmount"].setValidators(Validators.required);
			} else {
				this.form.controls["isIncomeAdjust"].clearValidators();
				this.form.controls["incomeAmount"].clearValidators();
			}
			this.form.controls["shiftId"].setValidators(Validators.required);
			this.form.controls["calendarId"].setValidators(Validators.required);
			this.form.controls["companyId"].clearValidators();
			this.form.controls["departmentId"].clearValidators();
			this.form.controls["isServiceYearsCont"].clearValidators();
			this.form.controls["isReplaceEmployee"].clearValidators();
		}

		if (this.movementType == Movement.resign) {
			if (this.isSelfResign) {
				this.form.controls["dateEffective"].setValidators(Validators.required);
				this.form.controls["remarks"].setValidators(Validators.required);
				this.form.controls["isWillingness"].setValidators(this.isNotCheck());
				this.form.controls["isAcceptPolicy"].setValidators(this.isNotCheck());
			} else {
				this.form.controls["severanceAmount"].setValidators(Validators.required);
			}
			this.form.controls["terminationReasonId"].setValidators(Validators.required);
		}

		if (this.movementType == Movement.promoteRank) {
			this.form.controls["departmentId"].setValidators(Validators.required);
			this.form.controls["rankNo"].setValidators(Validators.required);
			this.form.controls["positionId"].setValidators(Validators.required);
			if (this.tabPermissionPayroll) {
				this.form.controls["isIncomeAdjust"].setValidators(Validators.required);
				this.form.controls["incomeAmount"].setValidators(Validators.required);
			} else {
				this.form.controls["isIncomeAdjust"].clearValidators();
				this.form.controls["incomeAmount"].clearValidators();
			}
			this.form.controls["upperSupervisorId"].setValidators(Validators.required);
		}

		if (this.movementType == Movement.raiseTheSalary) {
			this.form.controls["incomeAmount"].setValidators(Validators.required);
		}
		if (this.movementType == Movement.transferAgency) {
			this.form.controls["paymentMethod"].clearValidators();
			this.form.controls["upperSupervisorId"].clearValidators();
			this.form.controls["positionId"].setValidators(Validators.required);
			this.form.controls["departmentId"].setValidators(Validators.required);
		}
		if (this.movementType == this.massTransfer) {
			this.form.controls["paymentMethod"].clearValidators();
			this.form.controls["positionId"].clearValidators();
			this.form.controls["rankNo"].clearValidators();
			this.form.controls["upperSupervisorId"].clearValidators();
			this.form.controls["companyId"].clearValidators();
			this.form.controls["employmentType"].clearValidators();
			this.form.controls["dateEffective"].setValidators(Validators.required);
		}
		if (this.movementType == Movement.transferCompany) {
			this.form.controls["upperSupervisorId"].clearValidators();
			this.form.controls["positionId"].clearValidators();
			this.form.controls["departmentId"].setValidators(Validators.required);
			this.form.controls["paymentMethod"].clearValidators();
			if (this.tabPermissionPayroll) {
				this.form.controls["isIncomeAdjust"].setValidators(Validators.required);
				this.form.controls["incomeAmount"].setValidators(Validators.required);
			} else {
				this.form.controls["isIncomeAdjust"].clearValidators();
				this.form.controls["incomeAmount"].clearValidators();
			}
		}
		if (this.movementType === Movement.renewal) {
			this.form.controls["employeeNo"].setValidators(Validators.required);
			this.form.controls["incomeAmount"].setValidators(Validators.required);
		}

		this.form.controls["employeeNo"].updateValueAndValidity();
		this.form.controls["dateEmployment"].updateValueAndValidity();
		this.form.controls["calendarId"].updateValueAndValidity();
		this.form.controls["isIncomeAdjust"].updateValueAndValidity();
		this.form.controls["incomeAmount"].updateValueAndValidity();
		this.form.controls["shiftId"].updateValueAndValidity();
		this.form.controls["companyId"].updateValueAndValidity();
		this.form.controls["departmentId"].updateValueAndValidity();
		this.form.controls["isServiceYearsCont"].updateValueAndValidity();
		this.form.controls["isReplaceEmployee"].updateValueAndValidity();
		this.form.controls["isAttendance"].updateValueAndValidity();
		this.form.controls["employmentType"].updateValueAndValidity();
		this.form.controls["paymentMethod"].updateValueAndValidity();
		this.form.controls["rankNo"].updateValueAndValidity();
		this.form.controls["positionId"].updateValueAndValidity();
		this.form.controls["upperSupervisorId"].updateValueAndValidity();
		this.form.controls["upperSupervisorName"].updateValueAndValidity();
		this.form.controls["newSupervisorId"].updateValueAndValidity();
	}
	get validFormIncomeAmount() {
		return this.form.controls["incomeAmount"].value > 0;
	}

	mapLockFilter() {
		const commonFilter = [
			{ index: 1 },
			{ index: 2 },
			{ index: 3 },
			{ index: 6 },
			{ index: 7 },
			{ index: 8 },
			{ index: 9 },
			{ index: 11 },
			{ index: 12 },
			{ index: 13 },
		];

		const movementTypeFilters = {
			[ProcessAction.renewal]: [...commonFilter, { index: 10, value: [3] }],
			[ProcessAction.permanent]: [...commonFilter, { index: 10, value: [1] }],
			[ProcessAction.changeOfSupervisor]: [...commonFilter, { index: 10, value: [1, 2, 4] }, { index: 11, value: [1] }],
			[ProcessAction.promote]: [...commonFilter, { index: 10, value: [1, 2, 4] }],
			[ProcessAction.raiseTheSalary]: [...commonFilter, { index: 10, value: [1, 2, 4] }],
			[ProcessAction.employmentConditions]: [...commonFilter, { index: 10, value: [1, 2, 4] }],
			[ProcessAction.agencyTransfer]: [...commonFilter, { index: 10, value: [1, 2, 4] }],
			[ProcessAction.resign]: [...commonFilter, { index: 10, value: [1, 2, 4] }],
			[ProcessAction.crossCompanyTransfer]: [...commonFilter, { index: 10, value: [1, 2, 4] }],
			default: [...commonFilter, { index: 10, value: [1, 2] }],
		};

		this.lockFilter = movementTypeFilters[this.movementType] || movementTypeFilters.default;
	}

	nextApproval: string;
	movement$: Subscription;
	allowPermissionPayroll$: Subscription;
	loadMovement(movementTransactionId, movementTransaction = null) {
		if (movementTransaction == null) {
			this.movement$?.unsubscribe();
			this.movement$ = this.employeeMovementService
				.getEmployeeMovementTransactionById(movementTransactionId)
				.subscribe({
					next: (res) => {
						this.movementTransaction = res;
						this.mapDate(res);
					},
				});
		} else {
			this.mapDate(movementTransaction);
			this.movementTransaction = movementTransaction;
		}
	}
	//#region Load data
	mapDate(modelMovementTransaction) {
		if (!isNullOrUndefined(modelMovementTransaction) && modelMovementTransaction?.companyId > 0) {
			this.employeeId = modelMovementTransaction.employeeId;
			this.masterSetId = modelMovementTransaction.masterSetId;
			this.allowPermissionPayroll$?.unsubscribe();
			this.allowPermissionPayroll$ = this.commonService
				.getAllowPermissionPayroll(modelMovementTransaction.employeeId, modelMovementTransaction.companyId)
				.subscribe((r) => {
					this.tabPermissionPayroll = r.allowGrant;
				});

			this.form.patchValue({
				isServiceYearsCont: modelMovementTransaction.isServiceYearsCont,
				rankNo: modelMovementTransaction.rankNo,
				positionId: modelMovementTransaction.positionId,
				employmentType: modelMovementTransaction.employmentType,
				paymentMethod: modelMovementTransaction.paymentMethod,
				isReplaceEmployee: modelMovementTransaction.isReplaceEmployee,
				isAttendance: modelMovementTransaction.isAttendance,
				replaceEmployeeId: modelMovementTransaction.replaceEmployeeId,
				upperSupervisorId: modelMovementTransaction.upperSupervisorId,
				calendarId: modelMovementTransaction.calendarId,
				remarks: modelMovementTransaction.remarks,
				dateEffective: new Date(modelMovementTransaction.dateEffective),
				severanceAmount: modelMovementTransaction.severanceAmount ?? 0,
			});
			if (this.movementType == ProcessAction.permanent) {
				this.form.patchValue({
					employeeNo: modelMovementTransaction.employeeNo,
					calendarId: modelMovementTransaction.calendarId,
					isIncomeAdjust: modelMovementTransaction.isIncomeAdjust,
					incomeAmount: modelMovementTransaction.incomeAmount,
					shiftId: modelMovementTransaction.shiftId,
				});
			}
			if (this.movementType == ProcessAction.employmentConditions) {
				this.form.patchValue({
					isIncomeAdjust: modelMovementTransaction.isIncomeAdjust,
					incomeAmount: modelMovementTransaction.incomeAmount,
					shiftId: modelMovementTransaction.shiftId,
				});
			}
			if (this.movementType == ProcessAction.raiseTheSalary) {
				this.rankNo = modelMovementTransaction.rankNo;
				this.positionId = modelMovementTransaction.positionId;
				this.form.patchValue({
					isIncomeAdjust: modelMovementTransaction.isIncomeAdjust,
					incomeAmount: modelMovementTransaction.incomeAmount,
					shiftId: modelMovementTransaction.shiftId,
					rankNo: modelMovementTransaction.rankNo,
					positionId: modelMovementTransaction.positionId,
					upperSupervisorId: modelMovementTransaction.upperSupervisorId,
				});

				this.loadSalary(modelMovementTransaction.movementTransactionId);
				this.employeeMovementsService.getWelfareSalaryDDL(modelMovementTransaction.companyId).then((res) => {
					this.welfareSalaryDDL = res.data;
				});
			}
			if (this.movementType == ProcessAction.crossCompanyTransfer) {
				this.form.patchValue({
					isIncomeAdjust: modelMovementTransaction.isIncomeAdjust,
					incomeAmount: modelMovementTransaction.incomeAmount,
					shiftId: modelMovementTransaction.shiftId,
				});
			}
			if (this.isSelfResign || this.movementType == ProcessAction.resign) {
				this.form.patchValue({
					dateEffective: modelMovementTransaction.dateEffective,
					remarks: modelMovementTransaction.remarks,
					terminationReasonId: modelMovementTransaction.terminationReasonId,
				});
			}
			this.isIncomeAdjust = modelMovementTransaction.isIncomeAdjust;
			if (this.movementType === ProcessAction.renewal) {
				this.form.patchValue({
					employeeNo: modelMovementTransaction.employeeNo,
					isIncomeAdjust: this.isIncomeAdjust,
				});

				if (this.isIncomeAdjust) {
					const basicSalary = modelMovementTransaction.salaryAdjusts?.find(
						(r) => r.category === FinancialCategory.baseSalary,
					);
					this.form.patchValue({
						incomeAmount: basicSalary?.incomeAmount ?? 0,
					});
					this.prevIncomeAmount = basicSalary?.prevIncomeAmount ?? 0;
				} else {
					this.employeeMovementsService
						.getCurrentBaseSalaryById(modelMovementTransaction.employeeId, modelMovementTransaction.companyId)
						.then((res) => {
							if (!isNullOrUndefined(res)) {
								this.prevIncomeAmount = res.amount;
								this.form.controls["incomeAmount"].setValue(res.amount);
							}
						});
				}
			}
			this.nextApproval =
				this.language == "th-TH"
					? modelMovementTransaction.nextApprovalFullName
					: modelMovementTransaction.nextApprovalFullName_EN;
			if (this.movementType == this.enumProcessAction.raiseTheSalary && this.masterSetId > 0) {
				const searchModel = {
					masterSetId: this.masterSetId,
				};
				this.workflowService.GetEmployeeNextApproval(searchModel).then((res) => {
					let nextApprveName = this.language == "th-TH" ? res?.employeeName : res?.employeeName_EN;
					this.nextApproval = !isNullOrUndefined(res) && nextApprveName ? nextApprveName : "-";
				});
			}

			this.employeeId = modelMovementTransaction.employeeId;
			this.dateEffective = new Date(modelMovementTransaction.dateEffective);
			this.dateEffectiveDefault = new Date(modelMovementTransaction.dateEffective);

			this.employeeMovementsService
				.getEmployeeById(modelMovementTransaction.employeeId)
				.then((emp) => {
					this.employee = emp;
					this.selectEmp = [emp];

					this.image = !isNullOrUndefined(this.employee.pictureThumbnailURL)
						? this.employee.pictureThumbnailURL
						: this.defaultPic;

					if (this.movementType == ProcessAction.permanent) {
						this.commonService.getDDLShiftByCompanyId(this.employee.companyId).then((res) => {
							this.shiftDDL = res;
							this.shiftId = modelMovementTransaction.shiftId;
						});
					}
					this.commonService
						.getDDLCompany()
						.then((res) => {
							this.companyDDL = res;
							this.companyId = modelMovementTransaction.companyId;
							this.form.controls["companyId"].setValue(modelMovementTransaction.companyId);
							const model = JSON.parse(JSON.stringify(this.organizeNode));

							const organizeNode = isNullOrUndefined(this.companyId)
								? []
								: model?.find((f) => f.companyId == this.companyId);
							if (this.movementType == Movement.transferAgency || this.movementType == Movement.transferCompany) {
								this.organizeNodeDDL = this.mapChildren(model, this.filedOrganize);
							} else {
								this.organizeNodeDDL =
									isNullOrUndefined(this.companyId) || isNullOrUndefined(organizeNode)
										? []
										: this.mapChildren(organizeNode["children"], this.filedOrganize);
							}
							this.organizeNodeDDL.sort((a, b) => a.organizationId - b.organizationId);
							const organizationId: number =
								modelMovementTransaction.orgLevelSix ??
								modelMovementTransaction.orgLevelSix ??
								modelMovementTransaction.orgLevelFive ??
								modelMovementTransaction.orgLevelFive ??
								modelMovementTransaction.orgLevelFour ??
								modelMovementTransaction.orgLevelFour ??
								modelMovementTransaction.orgLevelThree ??
								modelMovementTransaction.orgLevelThree ??
								modelMovementTransaction.orgLevelTwo ??
								modelMovementTransaction.orgLevelTwo ??
								null;

							const organizationIdOld: number =
								modelMovementTransaction.prevOrgorgLevelSix ??
								modelMovementTransaction.prevOrgLevelSix ??
								modelMovementTransaction.prevOrgLevelFive ??
								modelMovementTransaction.prevOrgLevelFive ??
								modelMovementTransaction.prevOrgLevelFour ??
								modelMovementTransaction.prevOrgLevelFour ??
								modelMovementTransaction.prevOrgLevelThree ??
								modelMovementTransaction.prevOrgLevelThree ??
								modelMovementTransaction.prevOrgLevelTwo ??
								modelMovementTransaction.prevOrgLevelTwo ??
								null;
							this.departmentIdOld = organizationIdOld;
							this.departmentId = organizationId;
							this.form.controls["departmentId"].setValue(this.departmentId);
							this.form.controls["departmentIdOld"].setValue(this.departmentIdOld);
							this.getNewPostDays();
						})
						.then(() => {
							if (!isNullOrUndefined(modelMovementTransaction.rankNo)) {
								this.commonService.getDDLPositionByRankNo(modelMovementTransaction.rankNo).then((res) => {
									this.positionDDL = res;
									this.positionId = modelMovementTransaction.positionId;
									this.form.controls["positionId"].setValue(modelMovementTransaction.positionId);
								});
							}
						});

					let employeeIdsModel = [];
					if (!isNullOrUndefined(modelMovementTransaction.upperSupervisorId)) {
						employeeIdsModel.push(modelMovementTransaction.upperSupervisorId);
					} else {
						this.upperSupervisorId = null;
					}
					if (!isNullOrUndefined(modelMovementTransaction.replaceEmployeeId)) {
						employeeIdsModel.push(modelMovementTransaction.replaceEmployeeId);
					} else {
						this.replaceEmployeeId = null;
					}
					if (this.movementType == ProcessAction.changeOfSupervisor) {
						employeeIdsModel.push(modelMovementTransaction.prevUpperSupervisorId);
						this.prevUpperSupervisorFullName =
							this.language == "th-TH"
								? modelMovementTransaction.prevUpperSupervisorFullName
								: modelMovementTransaction.prevUpperSupervisorFullName_EN;
					}

					const has = this.listPeople.find((r) => r.employeeId == modelMovementTransaction.upperSupervisorId);
					const has2 = this.listPeople.find((r) => r.employeeId == modelMovementTransaction.replaceEmployeeId);
					if ((has == null || (has2 == null && this.replaceEmployeeId != null)) && employeeIdsModel.length > 0) {
						const queryParam = {
							employeeIds: employeeIdsModel,
						};
						firstValueFrom(this.employeeService.getProfilesByEmployeeIds(queryParam)).then((res) => {
							const modelRes = res;
							modelRes?.forEach((m) => {
								m.fullname = m.fullName;
								m.fullname_EN = m.fullName_EN;
								this.listPeople.push(m);
							});
							this.replaceEmployeeId = modelMovementTransaction.replaceEmployeeId;
							if (this.movementType == ProcessAction.changeOfSupervisor) {
								this.form.controls["newSupervisorId"].setValue(modelMovementTransaction.upperSupervisorId);
							} else {
								this.upperSupervisorId = modelMovementTransaction.upperSupervisorId;
								this.form.controls["upperSupervisorId"].setValue(this.upperSupervisorId);
							}
							this.form.controls["replaceEmployeeId"].setValue(this.replaceEmployeeId);
						});
					} else {
						this.replaceEmployeeId = modelMovementTransaction.replaceEmployeeId;
						if (this.movementType == ProcessAction.changeOfSupervisor) {
							this.upperSupervisorId = modelMovementTransaction.prevUpperSupervisorId;
							this.form.controls["upperSupervisorId"].setValue(modelMovementTransaction.prevUpperSupervisorId);
							this.form.controls["newSupervisorId"].setValue(modelMovementTransaction.upperSupervisorId);
						} else {
							this.upperSupervisorId = modelMovementTransaction.upperSupervisorId;
							this.form.controls["upperSupervisorId"].setValue(this.upperSupervisorId);
						}
						this.form.controls["replaceEmployeeId"].setValue(this.replaceEmployeeId);
					}
					if (
						this.movementType == ProcessAction.massTransfer ||
						this.movementType == ProcessAction.changeOfSupervisor
					) {
						this.employees = [modelMovementTransaction.employeeId];
					}
					if (this.movementType == ProcessAction.changeOfSupervisor) {
						this.form.controls["newSupervisorId"].setValidators(Validators.required);
						this.form.controls["upperSupervisorId"].clearValidators();
						this.form.controls["upperSupervisorName"].clearValidators();

						this.form.controls["upperSupervisorId"].updateValueAndValidity();
						this.form.controls["newSupervisorId"].updateValueAndValidity();
						this.form.controls["upperSupervisorName"].updateValueAndValidity();
					} else {
						this.form.controls["upperSupervisorName"].setValue(emp.nameSurname);
					}

					if (this.movementType == Movement.transferCompany) {
						if (this.tabPermissionPayroll && modelMovementTransaction.isIncomeAdjust) {
							this.form.controls["isIncomeAdjust"].setValidators(Validators.required);
							this.form.controls["incomeAmount"].setValidators(Validators.required);
						} else {
							this.form.controls["isIncomeAdjust"].clearValidators();
							this.form.controls["incomeAmount"].clearValidators();
						}
					}
					this.employeeName = this.language == "th-TH" ? emp?.nameSurname : emp?.nameSurname_EN;
				})
				.finally(() => {
					this.isLoading$.next(false);
				});
		}

		const terminationReasonId = this.reasonToResignDDL?.find(
			(r) => r.id === modelMovementTransaction.terminationReasonId,
		);
		this.reasonToResignSSO = this.reasonToResignSSODDL?.find((r) => r.typeId === terminationReasonId?.valueNum);
		this.isMyDocument = modelMovementTransaction.createdBy === this.userInfo.userId;
	}
	//#endregion
	prevUpperSupervisorFullName: any;
	isMyDocument: boolean = false;

	getDayOfProbation(event?: any) {
		this.isProbation = event ? event.value : this.isProbation;
		if (this.isProbation) {
			this.form.controls["dayOfProbation"].setValidators(Validators.required);
			this.form.controls["dayOfProbation"].clearValidators();
		} else {
			this.form.controls["dayOfProbation"].clearValidators();
		}
		this.form.controls["dayOfProbation"].updateValueAndValidity();
		this.companyProfileService.getCompanyById(this.employee.companyId).then((comp) => {
			this.companyProfile = comp;
			this.dayOfProbation = this.isProbation ? 119 : null;
		});
	}

	ngOnDestroy(): void {
		this.movementTransactionId$?.unsubscribe();
		this.currentUserInfo$?.unsubscribe();
		this.allowPermissionPayroll$?.unsubscribe();
		this.movement$?.unsubscribe();
		this.listPeople$?.unsubscribe();
		this.language$?.unsubscribe();
	}
	//#region Action
	isClicked: boolean = false;
	checkValid: boolean = false;
	checkValidDateEffective: boolean = false;
	checkValidDepartmentOld: boolean = false;
	checkValidincomeAmount: boolean = false;
	checkPaymentMethod: boolean = false;
	checkValidDepartment: boolean = false;
	checkValidRankNo: boolean = false;
	checkValidDateEmployment: boolean = false;
	checkValidNewUpperSupervisor: boolean = false;
	getValidate(i, field) {
		return (<UntypedFormArray>this.formWelfareSalary?.get("items"))?.controls[i].get(field);
	}
	async onSubmit() {
		if (this.movementType == Movement.transferSupervisor) {
			this.form.controls["upperSupervisorId"].clearValidators();
			this.form.controls["upperSupervisorId"].updateValueAndValidity();
		}
		this.form?.markAllAsTouched();
		this.formWelfareSalary?.markAllAsTouched();
		this.form?.updateValueAndValidity();
		this.checkValid = true;
		this.checkMovement = true;
		this.checkValidDateEffective = true;
		if (
			(this.movementType == this.massTransfer || this.movementType == Movement.transferSupervisor) &&
			!this.employees
		) {
			return this._toastHelperService.validationText(this.translate.instant("choose_employee_mass_tranfer"));
		}

		if (this.movementType == Movement.raiseTheSalary) {
			this.form.controls["incomeAmount"].setValue(this.financialSalary.amount);
			if (!this.salaryAdjustmentDetails.isAllowPermission) {
				let toast: ToastDataModel = {
					title: this.translate.instant("common_heads_up"),
					description: this.translate.instant("common_toast_description_check_permission"),
				};
				return this._toastHelperService.validation(toast);
			}

			if (
				((this.formWelfareSalary.invalid || this.welfareSalaryList?.length == 0) && this.isWelfareSalary) ||
				this.financialSalary.amount <= 0
			) {
				let toast: ToastDataModel = {
					title: this.translate.instant("common_heads_up"),
					description: this.translate.instant("toast_common_complete_all_fields"),
				};
				return this._toastHelperService.validation(toast);
			}
		}
		if (!this.isShowSalary && this.movementType == Movement.raiseTheSalary) {
			let toast: ToastDataModel = {
				title: this.translate.instant("common_heads_up"),
				description: this.translate.instant("toast_common_complete_open_salary"),
			};
			return this._toastHelperService.validation(toast);
		}

		if (this.form.invalid || this.checkInValid()) {
			let toast: ToastDataModel = {
				title: this.translate.instant("common_heads_up"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			};
			return this._toastHelperService.validation(toast);
		}

		if (this.isDuplicateEmployeeNo) {
			this.showValidationDuplicateToast();
			return;
		}
		const permission = new Promise((resolve) => {
			if (this.movementType == ProcessAction.permanent) {
				// check can add movement
				this.employeeMovementService
					.checkPermissionCreatePermanent(this.employee.companyId, this.employee.employeeId)
					.then((res) => {
						const apiResult = <ApiResult>res;
						if (!apiResult.data) {
							resolve(false);
						}
						resolve(true);
					});
			} else {
				resolve(true);
			}
		});
		permission.then((res) => {
			if (res) {
				this.confirmation = this.translate.instant("common_confirmation");
				this.saveConfirmation = this.translate.instant("common_save_confirmation");
				this.isClicked = true;
				this.organizationService
					.getOrganizationByOrganizeId(this.departmentId)
					.then((result) => {
						const employee = result;
						this.employee.orgLevelOne = employee.orgLevelOne;
						this.employee.orgLevelTwo = employee.orgLevelTwo;
						this.employee.orgLevelThree = employee.orgLevelThree;
						this.employee.orgLevelFour = employee.orgLevelFour;
						this.employee.orgLevelFive = employee.orgLevelFive;
						this.employee.orgLevelSix = employee.orgLevelSix;
					})
					.finally(() => {
						if (
							this.isSelfResign &&
							this.userInfo?.hasAssessment &&
							this.isAssessmentActive &&
							this.permissionViewAssessment
						) {
							this.getAssessment();
						} else {
							let dialogModel = null;
							if (this.masterSetId > 0) {
								this.submitMovement();
							} else {
								if (this.isSelfResign) {
									dialogModel = <DialogInformationModel>{
										title: this.translate.instant("resignation_request_dialog_confirm"),
										description: this.translate.instant("resignation_request_dialog_confirm_description"),
										imageUrl: "confirmation.png",
										textButtonConfirm: this.translate.instant("common_confirm"),
										textButtonCancel: this.translate.instant("common_cancel"),
									};
								} else {
									dialogModel = <DialogInformationModel>{
										title: this.confirmation,
										description: this.saveConfirmation,
										imageUrl: "confirmation.png",
										textButtonConfirm: this.translate.instant("common_confirm"),
										textButtonCancel: this.translate.instant("common_cancel"),
									};
								}
								firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
									if (response?.confirm) {
										this.submitMovement();
									} else {
										this.isClicked = false;
										this.checkValid = false;
									}
								});
							}
						}
					});
			} else {
				if (this.movementType == ProcessAction.permanent) {
					let toast: ToastDataModel = {
						title: this.translate.instant("common_heads_up"),
						description: this.translate.instant("toast_movement_permanent_incomplete_asm"),
					};
					this._toastHelperService.validation(toast);
				}
			}
		});
	}

	prevIncomeAmount: number = 0;
	submitMovement(scoreId: number = null) {
		let employeeStatus = !this.isServiceYearsCont ? EmployeeStatus.probation : EmployeeStatus.contain;
		let newPostDate = !isNullOrUndefined(this.newPostDays) ? this.newPostDays : 0;
		this.form.patchValue({
			movementTransactionId: this.movementTransactionId > 0 ? this.movementTransactionId : 0,
			employeeId: this.employeeId,
			dateDocument: new Date(),
			dateEffective: this.form.controls["dateEffective"].value,
			dateEmployment: this.form.controls["dateEmployment"].value,
			dateNewPostEffective: this.dateNewPostEffective,
			movementTypeId: this.movementType,
			replaceEmployeeId:
				this.employee.isReplaceEmployee && this.replaceEmployeeId?.length > 0
					? this.replaceEmployeeId[0] ?? null
					: null,
			upperSupervisorId: this.form.controls["upperSupervisorId"].value,
			rankNo: this.form.controls["rankNo"].value,
			positionId: this.form.controls["positionId"].value,
			employmentType: this.form.controls["employmentType"].value,
			paymentMethod: this.form.controls["paymentMethod"].value,
			employeeStatus: this.movementType == ProcessAction.renewal ? employeeStatus : this.employee.employeeStatus,
			bankId: this.employee.bankId ?? 0,
			isApprove: !this.userInfo.hasMovementWorkflow,
			companyId: !isUndefined(this.companyId) ? this.companyId ?? null : this.employee.companyId,
			orgLevelOne: this.employee.orgLevelOne,
			orgLevelTwo: this.employee.orgLevelTwo,
			orgLevelThree: this.employee.orgLevelThree,
			orgLevelFour: this.employee.orgLevelFour,
			orgLevelFive: this.employee.orgLevelFive,
			orgLevelSix: this.employee.orgLevelSix,
			newPostDays: !this.isServiceYearsCont ? newPostDate : 0,
			isWorkInfomation: false,
			isProbation: this.isProbation ?? false,
			dayOfProbation: this.isProbation ? this.form.controls["dayOfProbation"].value : null,
			shiftId: this.form.controls["shiftId"].value,
			calendarId: this.form.controls["calendarId"].value,
			isIncomeAdjust:
				this.movementType == Movement.raiseTheSalary ? true : this.form.controls["isIncomeAdjust"].value ?? false,
			isAttendance: this.form.controls["isAttendance"].value ?? false,
			departmentId: this.form.controls["departmentId"].value ?? 0,
			organizeId: this.form.controls["departmentId"].value ?? 0,
			terminationReasonId: this.form.controls["terminationReasonId"].value ?? null,
			isSelfResign: this.isSelfResign,
			scoreId: scoreId,
			createdBy: this.movementTransaction?.createdBy ?? null,
		});
		let dataSend = this.form.value;
		if (this.movementType == this.massTransfer || this.movementType == Movement.transferSupervisor) {
			this.formAgencyTransfers.patchValue({
				movementTransactionId: this.movementTransactionId > 0 ? this.movementTransactionId : 0,
				employeeIds: this.employees,
				documentNo: "",
				organizeId: this.form.controls["departmentId"].value ?? 0,
				dateDocument: new Date(),
				companyId: null,
				remarks: this.form.controls["remarks"].value,
				dateEffective: this.form.controls["dateEffective"].value,
				isApprove: !this.userInfo.hasMovementWorkflow,
				movementTypeId: this.movementType,
				newSupervisorId: this.form.controls["upperSupervisorId"].value,
			});
			dataSend = this.formAgencyTransfers.value;
		}
		if (this.movementType == Movement.transferSupervisor) {
			this.formAgencyTransfers.patchValue({
				newSupervisorId: this.form.controls["newSupervisorId"].value,
			});
			dataSend = this.formAgencyTransfers.value;
		}

		if (this.movementType == ProcessAction.renewal) {
			let apiResult = null;
			if (this.form.controls["incomeAmount"].value > 0) {
				dataSend.isIncomeAdjust = this.form.controls["incomeAmount"].value !== this.prevIncomeAmount;
			}
			firstValueFrom(this.employeeMovementService.addEmployeeRenewal(dataSend))
				.then((res) => {
					apiResult = <ApiResult>res;
					if (apiResult.result) {
						if (this.convertEmployee) {
							this.applicantInformation.prevStatusId = this.applicantInformation.statusId;
							this.applicantInformation.statusId = this.recruitmentStatusEnum.permanent;
							firstValueFrom(this.employeeService.convertApplicantToEmployee(this.applicantInformation)).then(() => {
								this.reset();
								this.success.emit();
								this.closeSidebar.emit();
							});
						} else if (!this.isMobile) {
							this.reset();
							this.success.emit();
							this.closeSidebar.emit();
						}
						apiResult.message = this.isEdit
							? this.translate.instant("toast_common_save_success")
							: this.translate.instant("toast_movement_create_success");
					} else {
						this.isClicked = false;
					}
					if (this.isMobile && apiResult.result) {
						if (this.subscribeGoBack) {
							this.closeWebView("success");
						} else {
							this.closeWebViewMessage(ProcessAction.sendDocument);
						}
					}
				})
				.finally(() => {
					if (apiResult?.errorField == "employeeNo" && apiResult?.isDuplicate) {
						this.isDuplicateEmployeeNo = true;
						return this.showValidationDuplicateToast();
					}
					this.movementTransactionId > 0
						? this._toastHelperService.alertEditBoolean(apiResult?.result ?? false)
						: this._toastHelperService.alertAddMovementBoolean(apiResult?.result ?? false);
					this.isClicked = false;
				});
		} else {
			if (this.movementType == Movement.raiseTheSalary) {
				dataSend.typeAdjustment = this.salaryAdjustmentDetails.movement.typeAdjustment;
				let financialWelfare = null;
				if (this.salaryAdjustmentDetails.movement?.incomeTransactions?.length > 0) {
					financialWelfare = this.salaryAdjustmentDetails.movement.incomeTransactions.filter((f) => f.welfareId > 0);
				} else {
					financialWelfare = this.salaryAdjustmentDetails.adjustment?.financial?.items.filter(
						(f) =>
							f.welfareId > 0 &&
							f.welfareType == WelfareType.extraAllowance &&
							f.conditionType !== ConditionTypeWelfare.PaidSameAmount,
					);
				}
				this.financialBonus.avgSalary = this.salaryAdjustmentDetails.adjustment.avgSalary;
				dataSend.salaryAdjustmentDetails = {
					welfareSalaries: financialWelfare,
					newWelfareSalaries: this.isWelfareSalary ? this.welfareSalaryList : [],
					financialSalary: this.financialSalary,
					financialBonus: this.financialBonus,
				};
				dataSend.incomeAmount = this.financialSalary.amount;
				dataSend.masterSetId = this.masterSetId;
			}
			firstValueFrom(this.employeeMovementService.addEmployeeMovementTransaction(dataSend)).then((result) => {
				const apiResult = <ApiResult>result;
				if (apiResult.result) {
					if (!this.isMobile) {
						this.reset();
						this.success.emit();
						this.isSidebarAdd = false;
						this.closeSidebarMM();
					}
					this.showSuccessToast(apiResult);
				} else {
					if (this.movementType != Movement.resign) {
						this.movementTransactionId > 0
							? this._toastHelperService.alertEditBoolean(false)
							: this._toastHelperService.alertAddBoolean(false);
						this.isClicked = false;
					}
					this.showValidateSelfResignToast(apiResult);
				}
				if (apiResult?.errorField == "employeeNo" && apiResult?.isDuplicate) {
					this.isDuplicateEmployeeNo = true;
					this.showValidationDuplicateToast();
					return;
				}

				if (this.isMobile && apiResult.result && this.subscribeGoBack) {
					this.closeWebView("success");
				} else {
					this.closeWebViewMessage(ProcessAction.sendDocument);
				}
			});
		}
	}

	showSuccessToast(apiResult = null) {
		apiResult.message = this.isEdit
			? this.translate.instant("toast_common_save_success")
			: this.translate.instant("toast_movement_create_success");
		this._toastHelperService.alertApiResult(apiResult);
	}

	selectTypeAdjustment(event) {
		this.salaryAdjustmentDetails.movement.typeAdjustment = event.value;

		const typeAdjustment = this.salaryAdjustmentDetails.movement.typeAdjustment;
		if (typeAdjustment === SalaryAdjSection.performance || typeAdjustment === SalaryAdjSection.special) {
			this.resetData();
		}
	}
	resetData() {
		const change = this.rankNo != this.employee.rankNo || this.positionId != this.employee.positionId;
		this.rankNo = this.employee.rankNo;
		this.positionId = this.employee.positionId;
		this.form.controls["rankNo"].setValue(this.employee.rankNo);
		this.form.controls["positionId"].setValue(this.employee.positionId);
		this.form.controls["orgLevelOne"].setValue(this.employee.orgLevelOne);
		this.form.controls["orgLevelTwo"].setValue(this.employee.orgLevelTwo);
		this.form.controls["orgLevelThree"].setValue(this.employee.orgLevelThree);
		this.form.controls["orgLevelFour"].setValue(this.employee.orgLevelFour);
		this.form.controls["orgLevelFive"].setValue(this.employee.orgLevelFive);
		this.form.controls["orgLevelSix"].setValue(this.employee.orgLevelSix);
		this.form.controls["departmentId"].setValue(this.departmentIdOld);
		this.form.controls["upperSupervisorIdOld"].setValue(this.upperSupervisorIdOld);
		if (change) {
			this.getSalaryBand();
		}
	}
	paymentMethodChange() {
		this.checkPaymentMethod = false;
		if (this.oldDataOfEmployee.paymentMethod != this.paymentMethod) {
			this.infoPaymentMethodChange = true;
		} else this.infoPaymentMethodChange = false;
	}

	public salaryBand: SalaryBand = new SalaryBand();
	public salaryBandCurrent: SalaryBand = new SalaryBand();

	salaryAdjustmentDetails: EmployeeSalaryAdjustmentModel = new EmployeeSalaryAdjustmentModel();
	contentFinancial = signal(false);
	salaryAdjSection: any = [
		{
			name: this.translate.instant("ess009_salary_adj_performance_dropdown"),
			id: SalaryAdjSection.performance,
		},
		{
			name: this.translate.instant("ess009_salary_adj_special_dropdown"),
			id: SalaryAdjSection.special,
		},
		{
			name: this.translate.instant("ess009_salary_adj_position_dropdown"),
			id: SalaryAdjSection.position,
		},
	];
	get SalaryAdjSection() {
		return SalaryAdjSection;
	}
	get InfoBarType() {
		return InfoBarType;
	}
	isWelfareSalary: boolean = false;
	welfareSalaryList: MovementIncomeTransactionsSAModel[] = [];
	formWelfareSalary: UntypedFormGroup;
	welfareSalaryDDL: [] = [];
	intitialFormWelfareSalary() {
		this.welfareSalaryList = [];
		this.formWelfareSalary = this.fb.group({
			items: this.fb.array([]),
		});
	}

	extraAmount: number;
	updateDataControls(controls) {
		const controlsForm = <UntypedFormArray>this.formWelfareSalary?.controls?.items;

		for (const c of controls) {
			const form = this.fb.group({
				isNew: new UntypedFormControl(c.isNew ?? 0, Validators.required),
				incomeTransactionId: new UntypedFormControl(c.incomeTransactionId),
				isIndividual: new UntypedFormControl(c.isIndividual ?? false),
				welfareType: new UntypedFormControl(c.welfareType ?? false),
				welfareId: new UntypedFormControl(c.welfareId, [Validators.required, Validators.min(1)]),
				financialItemId: new UntypedFormControl(c.financialItemId),
				amount: new UntypedFormControl(c.amount, [Validators.required, Validators.min(1)]),
				prevAmount: new UntypedFormControl(c.prevAmount),
				name: new UntypedFormControl(c.name),
				name_EN: new UntypedFormControl(c.name_EN),
			});
			controlsForm.push(form);
		}

		this.welfareSalaryList = controlsForm.value;
	}

	financialOthers: AdjustmentItemSAModel[] = [];
	financialSalary: MovementIncomeTransactionsSAModel = new MovementIncomeTransactionsSAModel();
	financialBonus: MovementIncomeTransactionsSAModel = new MovementIncomeTransactionsSAModel();
	loadSalary(movementTransactionId = 0) {
		if (this.movementType == Movement.raiseTheSalary) {
			this.employeeMovementsService
				.getMovementsSalaryAdjustment(this.employeeId, movementTransactionId)
				.then((res) => {
					this.salaryAdjustmentDetails = res.data;
					this.intitialFormWelfareSalary();
					if (!this.salaryAdjustmentDetails.isAllowPermission) {
						let toast: ToastDataModel = {
							title: this.translate.instant("common_heads_up"),
							description: this.translate.instant("common_toast_description_check_permission"),
						};
						return this._toastHelperService.validation(toast);
					}

					if (this.salaryAdjustmentDetails.movement?.incomeTransactions?.length > 0) {
						this.updateDataControls(
							this.salaryAdjustmentDetails.movement.incomeTransactions.filter((f) => f.welfareId > 0 && f.amount > 0),
						);
						this.financialSalary = this.salaryAdjustmentDetails.movement.incomeTransactions.find(
							(f) => f.category == FinancialCategory.baseSalary,
						);
						this.financialSalary.percentage =
							this.financialSalary.percentage == 0 ? null : this.financialSalary.percentage;
						this.financialBonus = this.salaryAdjustmentDetails.movement.incomeTransactions.find(
							(f) => f.category == FinancialCategory.bonus,
						);
						this.financialBonus ??= new MovementIncomeTransactionsSAModel();
						this.financialBonus.percentage =
							this.financialBonus.percentage == 0 ? null : this.financialBonus.percentage;
					} else {
						this.salaryAdjustmentDetails.movement = new MovementSalaryAdjustmentModel();
						this.financialSalary.amount = this.salaryAdjustmentDetails?.adjustment?.baseSalary;
						this.financialBonus.amount = 0;
						this.financialBonus.percentage = 0;
						if (this.salaryAdjustmentDetails.adjustment?.financial?.items?.length > 0) {
							let financialWelfare = [];
							let financialWelfareList = this.salaryAdjustmentDetails.adjustment?.financial?.items.filter(
								(f) =>
									f.welfareId > 0 &&
									f.welfareType == WelfareType.extraAllowance &&
									f.conditionType != ConditionTypeWelfare.PaidSameAmount,
							);

							financialWelfareList?.forEach((item) => {
								item.prevAmount = item.amount;
								financialWelfare.push(item);
							});
							this.financialOthers = this.salaryAdjustmentDetails.adjustment?.financial?.items.filter(
								(f) =>
									!(
										f.welfareId > 0 &&
										f.welfareType == WelfareType.extraAllowance &&
										f.conditionType != ConditionTypeWelfare.PaidSameAmount
									),
							);
							this.updateDataControls(financialWelfare);
						}
					}

					if (this.movementTransactionId > 0) {
						this.extraAmount = this.financialSalary.amount - this.salaryAdjustmentDetails.adjustment.baseSalary;
					}

					this.isWelfareSalary = this.welfareSalaryList?.length > 0;

					this.calTotalAmount();
					this.calTotalAmountPercent();
				})
				.then((_) => {
					this.getSalaryBand();
				});
		}
	}
	getEmployee(id = null) {
		this.employeesData = [];
		this.employees = [];
		this.employeeId = id ?? this.selectEmp?.[0]?.employeeId ?? null;
		if (!isNullOrUndefined(this.employeeId)) {
			if (this.movementType == Movement.transferSupervisor) {
				this.employeeMovementsService.getSupervisorByEmployeeId(this.employeeId, ChartType.under).then((res) => {
					const filter = res.data.filter((e) => e.employeeId != this.employeeId); // ได้ตัวเองออกไปด้วย เลยกรองออก
					this.subordinateList = filter;
					this.subordinateListTmp = filter;
					this.countUnder = filter.length;
					this.onCheckCountUnder();
				});
			}

			if (this.movementType == Movement.resign) {
				this.getAssetTable();
			}

			this.employeeMovementsService
				.getEmployeeById(this.employeeId)
				.then((emp) => {
					this.employee = emp;
					this.oldDataOfEmployee = emp;
					if (this.selectEmp == null) this.selectEmp = [emp];
					this.form.controls["upperSupervisorName"].setValue(emp.nameSurname);
				})
				.then((_) => {
					this.employeeMovementsService.getWelfareSalaryDDL(this.employee.companyId).then((res) => {
						this.welfareSalaryDDL = res.data;
					});
					firstValueFrom(this.commonService.getAllowPermissionPayroll(this.employeeId, this.employee.companyId)).then(
						(r) => {
							this.tabPermissionPayroll = r.allowGrant;
							if (this.tabPermissionPayroll && this.employee?.companyId > 0) {
								this.employeeMovementsService
									.getCurrentBaseSalaryById(this.employeeId, this.employee.companyId)
									.then((res) => {
										if (!isNullOrUndefined(res)) {
											this.prevIncomeAmount = res.amount;
											this.form.controls["incomeAmount"].setValue(res.amount);
										}
									});
							}
						},
					);

					if (id == null) {
						this.employeeName = this.language == "th-TH" ? this.selectEmp[0].fullName : this.selectEmp[0].fullName_EN;
					} else {
						this.employeeName = this.language == "th-TH" ? this.employee.nameSurname : this.employee.nameSurname_EN;
					}

					this.employeeId = this.employee.employeeId;
					this.employeeNo = this.employee.employeeNo;
					this.form.controls["employeeNo"].setValue(this.employeeNo);
					this.rankNo = this.employee.rankNo;
					this.positionId = this.employee.positionId;
					this.image = !isNullOrUndefined(this.employee.pictureThumbnailURL)
						? this.employee.pictureThumbnailURL
						: this.defaultPic;

					this.commonService.getDDLShiftByCompanyId(this.employee.companyId).then((res) => {
						this.shiftDDL = res;
					});

					if (this.isSelfResign) {
						this.companyProfileService.getResignNoticeByEmployeeId(this.employeeId).then((res) => {
							if (res?.data?.length > 0) {
								const tempDateEffective = new Date().setDate(
									new Date().getDate() + res.data[0].dayResignAdvanceNotice + 1,
								);
								this.form.controls["dateEffective"].setValue(tempDateEffective);
								this.dateEffective = new Date(tempDateEffective);
								this.dateEffectiveDefault = new Date(tempDateEffective);
							} else {
								this.companyProfileService.getCompanyById(this.employee.companyId).then((comp) => {
									this.companyProfile = comp;
									const tempDateEffective = new Date().setDate(new Date().getDate() + comp.dayResignAdvanceNotice + 1);
									this.form.controls["dateEffective"].setValue(tempDateEffective);
									this.dateEffective = new Date(tempDateEffective);
									this.dateEffectiveDefault = new Date(tempDateEffective);
								});
							}
						});
					} else {
						this.form.controls["dateEffective"].setValue(new Date());
						this.dateEffective = new Date();
						this.dateEffectiveDefault = new Date();
					}
					this.form.controls["dateEmployment"].setValue(this.employee.dateEmployment);
					this.dateDocument = new Date();
					this.form.controls["dateDocument"].setValue(new Date());
					this.isServiceYearsCont = true;

					this.form.controls["isIncomeAdjust"].setValue(false);
					this.form.controls["isServiceYearsCont"].setValue(true);
					this.form.controls["isAttendance"].setValue(this.employee.isAttendance);
					this.form.controls["isReplaceEmployee"].setValue(this.employee.isReplaceEmployee);
					this.newPostDays = 0;

					let employeeIdsModel = [];
					if (!isNullOrUndefined(this.employee.upperSupervisorId)) {
						employeeIdsModel.push(this.employee.upperSupervisorId);
					} else {
						this.upperSupervisorId = null;
					}
					if (!isNullOrUndefined(this.employee.replaceEmployeeId)) {
						employeeIdsModel.push(this.employee.replaceEmployeeId);
					} else {
						this.replaceEmployeeId = null;
					}
					const has = this.listPeople.find((r) => r.employeeId == this.employee.upperSupervisorId);
					const has2 = this.listPeople.find((r) => r.employeeId == this.employee.replaceEmployeeId);

					if ((has == null || (has2 == null && this.replaceEmployeeId != null)) && employeeIdsModel.length > 0) {
						const queryParam = {
							employeeIds: employeeIdsModel,
						};
						firstValueFrom(this.employeeService.getProfilesByEmployeeIds(queryParam)).then((res) => {
							let modelRes = res;
							modelRes?.forEach((m) => {
								m.fullname = m.fullName;
								m.fullname_EN = m.fullName_EN;
								this.listPeople.push(m);
							});
							this.upperSupervisorId = this.employee.upperSupervisorId;
							this.replaceEmployeeId = this.employee.replaceEmployeeId;
							this.form.controls["upperSupervisorId"].setValue(this.employee.upperSupervisorId);
							this.form.controls["replaceEmployeeId"].setValue(this.employee.replaceEmployeeId);
						});
					} else {
						this.form.controls["upperSupervisorId"].setValue(this.employee.upperSupervisorId);
					}

					this.commonService.getDDLProcessAction().then((res) => {
						this.movementTypeDDL = res;
						this.movementTypeId = res.find((m) => m.id == ProcessAction.renewal);
						this.movementTypeName = this.movementTypeId.name;
						this.movementTypeName_EN = this.movementTypeId.name_EN;
					});

					this.commonService
						.getDDLCompany()
						.then((res) => {
							this.companyDDL = res;
							this.companyId = this.employee.companyId;
							this.form.controls["companyId"].setValue(this.employee.companyId);
							const organizeNodeModel = JSON.parse(JSON.stringify(this.organizeNode));
							const organizeNode = isNullOrUndefined(this.companyId)
								? []
								: this.movementType == Movement.transferAgency
									? organizeNodeModel
									: organizeNodeModel?.find((f) => f.companyId == this.companyId);
							if (this.movementType == Movement.transferAgency || this.movementType == Movement.transferCompany) {
								this.organizeNodeDDL = this.mapChildren(organizeNodeModel, this.filedOrganize);
							} else {
								this.organizeNodeDDL =
									isNullOrUndefined(this.companyId) || isNullOrUndefined(organizeNode)
										? []
										: this.mapChildren(organizeNode["children"], this.filedOrganize);
							}
							this.organizeNodeDDL.sort((a, b) => a.organizationId - b.organizationId);

							const organizationId: number =
								this.employee.orgLevelSix ??
								this.employee.orgLevelSix ??
								this.employee.orgLevelFive ??
								this.employee.orgLevelFive ??
								this.employee.orgLevelFour ??
								this.employee.orgLevelFour ??
								this.employee.orgLevelThree ??
								this.employee.orgLevelThree ??
								this.employee.orgLevelTwo ??
								this.employee.orgLevelTwo ??
								null;
							if (this.organizeNodeDDL.length == 0) {
								this.getOrganizeNodeDDL();
								this.departmentId = null;
								this.checkValidDepartment = true;
							} else {
								this.departmentId = organizationId;
								this.departmentIdOld = organizationId;
							}

							this.form.controls["departmentId"].setValue(this.departmentId);
							this.form.controls["departmentIdOld"].setValue(this.departmentIdOld);

							this.getNewPostDays();
						})
						.then(() => {
							if (!isNullOrUndefined(this.employee.rankNo)) {
								this.commonService.getDDLPositionByRankNo(this.employee.rankNo).then((res) => {
									this.positionDDL = res;
									this.positionId = this.employee.positionId;
									this.form.controls["positionId"].setValue(this.positionId);
								});
							}
						});

					this.form.controls["paymentMethod"].setValue(this.employee.paymentMethod);
					this.form.controls["employmentType"].setValue(this.employee.employmentType);
					this.form.controls["rankNo"].setValue(this.employee.rankNo);
					if (this.movementType == ProcessAction.permanent) {
						this.form.controls["employeeNo"].setValue(this.employee.employeeNo);
						this.form.controls["calendarId"].setValue(this.employee.calendarId);
						this.form.controls["isIncomeAdjust"].setValue(false);
						this.form.controls["shiftId"].setValue(this.employee.shiftId);
					}
					if (this.movementType == ProcessAction.raiseTheSalary) {
						if (this.tabPermissionPayroll) {
							this.form.controls["isIncomeAdjust"].setValidators(Validators.required);
							this.form.controls["incomeAmount"].setValidators(Validators.required);
						} else {
							this.form.controls["isIncomeAdjust"].clearValidators();
							this.form.controls["incomeAmount"].clearValidators();
						}
						this.form.controls["shiftId"].setValue(this.employee.shiftId);
					}
					if (this.movementType == ProcessAction.crossCompanyTransfer) {
						this.form.controls["departmentId"].setValidators(Validators.required);
						this.form.controls["shiftId"].setValue(this.employee.shiftId);
						if (this.tabPermissionPayroll) {
							this.form.controls["isIncomeAdjust"].setValidators(Validators.required);
							this.form.controls["incomeAmount"].setValidators(Validators.required);
						} else {
							this.form.controls["isIncomeAdjust"].clearValidators();
							this.form.controls["incomeAmount"].clearValidators();
						}
					}

					if (this.movementType == this.massTransfer) {
						this.form.controls["departmentIdOld"].setValidators(Validators.required);
						this.form.controls["departmentId"].setValidators(Validators.required);
					}
					this.form.controls["isIncomeAdjust"].updateValueAndValidity();
					this.form.controls["incomeAmount"].updateValueAndValidity();

					if (this.movementType == this.enumProcessAction.raiseTheSalary && this.masterSetId > 0) {
						const searchModel = {
							masterSetId: this.masterSetId,
						};
						this.workflowService.GetEmployeeNextApproval(searchModel).then((res) => {
							let nextApprveName = this.language == "th-TH" ? res?.employeeName : res?.employeeName_EN;
							this.nextApproval = !isNullOrUndefined(res) && nextApprveName ? nextApprveName : "-";
						});
					} else {
						const searchModel = {
							moduleId: ModuleWorkflow.Movement,
							referenceId: this.movementType,
							ownerUserId: this.employee.userId,
						};
						this.workflowService.GetEmployeeNextApproval(searchModel).then((res) => {
							let nextApprveName = this.language == "th-TH" ? res?.employeeName : res?.employeeName_EN;
							this.nextApproval = !isNullOrUndefined(res) && nextApprveName ? nextApprveName : "-";
						});
					}

					this.loadSalary(this.movementTransactionId);
				})
				.then((_) => {
					this.isLoading$.next(false);
				});
		} else {
			this.selectEmp = null;
		}
	}

	onCheckCountUnder() {
		if (this.countUnder > 0) {
			this.form.controls["upperSupervisorId"].setValidators(Validators.required);
		} else {
			this.form.controls["upperSupervisorId"].clearValidators();
		}
	}

	onclickSelectSupervisor() {
		if (this.isLockSelect) return;
		this.displayEmployee = true;
	}
	onFilterEmployee(event) {
		event.cancel = true;
		const searchText = event.text.trim().toLowerCase();
		firstValueFrom(this.commonService.getPeople(searchText)).then((res) => {
			this.listPeople = res.data;
		});
	}
	onFilterUnder(event) {
		const keyword = event.text;
		if (keyword) {
			this.subordinateList = this.subordinateListTmp?.filter(
				(r) =>
					r.employeeId != this.employee.employeeId &&
					(r.employeeNo?.toLowerCase().includes(keyword.toLowerCase()) ||
						r.nickName?.toLowerCase().includes(keyword.toLowerCase()) ||
						r.nickName_EN?.toLowerCase().includes(keyword.toLowerCase()) ||
						r.fullname?.toLowerCase().includes(keyword.toLowerCase()) ||
						r.fullname_EN?.toLowerCase().includes(keyword.toLowerCase())),
			);
		} else {
			this.subordinateList = this.subordinateListTmp;
		}
	}

	public timeoutSearch: any;
	async onFilterOrg(event) {
		clearTimeout(this.timeoutSearch);
		this.timeoutSearch = setTimeout(() => {
			const keyword = event.text;
			if (keyword) {
				const queryString = `searchFullname=${encodeURIComponent(keyword)}&organizationId=${
					this.filterOrgId
				}&employeeStatus=1&employeeStatus=2&employeeStatus=4`;
				firstValueFrom(this.commonService.getProfilesLite(queryString)).then((res) => {
					this.listPeopleFilter = res.data.filter(
						(f) =>
							f.orgLevelSix == this.filterOrgId ||
							f.orgLevelFive == this.filterOrgId ||
							f.sectionId == this.filterOrgId ||
							f.divisionId == this.filterOrgId ||
							f.departmentId == this.filterOrgId ||
							f.orgLevelOne == this.filterOrgId ||
							f.companyId == this.filterOrgId,
					);
				});
			} else {
				this.listPeopleInDepartment = this.listPeopleInDepartmentTmp;
			}
		}, 500);
	}

	reset() {
		this.isClicked = false;
		this.employee = new Employee();
		this.intitialFormWelfareSalary();
		this.image = this.defaultPic;
		this.form?.reset();
		this.isProbation = false;
		this.dateNewPostEffective = new Date();
		this.checkValid = false;
		this.selectEmp = null;
		if (this.movementType == this.enumProcessAction.raiseTheSalary) {
			this.salaryAdjustmentDetails = new EmployeeSalaryAdjustmentModel();
			this.financialOthers = [];
			this.financialSalary = new MovementIncomeTransactionsSAModel();
			this.financialBonus = new MovementIncomeTransactionsSAModel();
		}
	}
	//#endregion

	//#region Function on change
	public organizeNodeDDL: any[];
	public organizeNode: any[];
	public departmentId: number;
	public departmentIdNew: number;
	getCompany(event) {
		this.companyId = event.value;
		if (this.companyId != null) {
			const model = JSON.parse(JSON.stringify(this.organizeNode));

			const organizeNode = isNullOrUndefined(this.companyId) ? [] : model?.find((f) => f.companyId == this.companyId);
			this.organizeNodeDDL =
				isNullOrUndefined(this.companyId) || isNullOrUndefined(organizeNode)
					? []
					: this.mapChildren(organizeNode["children"], this.filedOrganize);
			this.organizeNodeDDL.sort((a, b) => a.organizationId - b.organizationId);

			this.form.controls["departmentId"].setValue(null);
		}
	}

	mapChildren(datas: any[], fields: any) {
		return datas?.map((data) => {
			let model: any = {};
			model[fields.text] = data[fields.text];
			model.name_EN = data.name_EN;
			model[fields.value] = data[fields.value];
			model.dataSource = data[fields.children]?.length ? this.mapChildren(data[fields.children], fields) : [];
			return model;
		});
	}
	selectRankNo(event) {
		this.rankNo = event.value;
		if (this.movementType == Movement.raiseTheSalary) {
			this.getSalaryBand();
		}
		this.getPosition();
	}
	selectedPosition(event) {
		this.positionId = event.value;
		if (this.movementType == Movement.raiseTheSalary) {
			this.getSalaryBand();
		}
	}
	getSalaryBand() {
		this.salaryBand = new SalaryBand();
		this.employeeMovementsService.getMovementsSalaryBand(this.employeeId, this.positionId, this.rankNo).then((res) => {
			this.salaryBand = res.data;

			this.salaryBand.positionName = this.salaryBand.positionName.concat(` (${this.salaryBand.rankName})`);
			this.salaryBand.positionName_EN = this.salaryBand.positionName.concat(` (${this.salaryBand.rankName})`);
			if (this.salaryBand.totalEmployee == 0) {
				this.salaryBand.max = this.salaryBand.baseSalary;
				this.salaryBand.median = this.salaryBand.baseSalary;
				this.salaryBand.min = this.salaryBand.baseSalary;
			}
			this.salaryBand.newBaseSalary =
				this.movementTransactionId > 0 || this.financialSalary.amount > 0
					? this.financialSalary.amount
					: this.salaryBand.baseSalary;

			this.salaryBandCurrent = { ...this.salaryBand };
		});
	}

	getPosition() {
		this.checkValidRankNo = false;
		this.positionDDL = null;
		if (!isNullOrUndefined(this.rankNo)) {
			this.commonService.getDDLPositionByRankNo(this.rankNo).then((res) => {
				this.positionDDL = res;
				this.positionId = null;
			});
		}
	}

	getOrganizeNodeDDL() {
		this.organizationService.getOrganizationNode().then((res) => {
			this.organizeNode = res.data;
			const model = JSON.parse(JSON.stringify(this.organizeNode));
			this.organizeNodeDDL = this.mapChildren(model, this.filedOrganize);
			this.organizeNodeDDL.sort((a, b) => a.organizationId - b.organizationId);
		});
	}

	getNewPostDays() {
		if (!isNullOrUndefined(this.companyId)) {
			this.companyProfileService.getCompanyById(this.companyId).then((comp) => {
				this.companyProfile = comp;
				this.newPostDays =
					this.isServiceYearsCont && !isNullOrUndefined(this.companyProfile.dayOfProbation)
						? this.companyProfile.dayOfProbation
						: 0;
				this.countDateProbationExpire();
			});
		}
	}

	loadEmployeeInformation() {
		this.employeeMovementsService.getEmployeeById(this.employeeId, false).then((res) => {
			this.employeeModel = res;
			if (this.employeeModel.upperSupervisorId > 0 && !isNullOrUndefined(this.employeeModel.upperSupervisorId)) {
				this.upperSupervisorId = this.employeeModel.upperSupervisorId;
				this.upperSupervisor =
					this.language == "th-TH" ? this.employeeModel.upperSupervisorName : this.employeeModel.upperSupervisorName_EN;
				this.search();
			}
		});
	}

	search() {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			dtInstance.column(1).search(this.upperSupervisorId.toString());
			dtInstance.draw();
		});
	}
	massTransferTotalEmployee: number = 0;
	filterOrgId: number = 0;
	getListPeople(event) {
		if (this.isEdit && this.movementType == Movement.massTransfer) {
			let toast: ToastDataModel = {
				title: this.translate.instant("common_heads_up"),
				description: this.translate.instant("ess009_org_duplicate"),
			};
			this.form.controls.departmentIdOld.setValue(this.departmentIdOld);
			return this._toastHelperService.validation(toast);
		}
		this.checkValidDepartmentOld = false;
		this.showSupervisor = true;
		if (this.movementType == Movement.massTransfer) {
			this.filterOrgId = event.selectedItem.organizationId;
			var queryString = `organizationName=${encodeURIComponent(event.selectedItem.name)}&organizationId=${
				this.filterOrgId
			}&employeeStatus=1&employeeStatus=2&employeeStatus=4`;
			firstValueFrom(this.commonService.getProfilesLite(queryString)).then((res) => {
				this.massTransferTotalEmployee = res.total;
				this.listPeopleInDepartment = res.data;
				this.listPeopleInDepartmentTmp = res.data;
			});
		} else {
			firstValueFrom(this.commonService.getPeople(event.selectedItem.employeeId)).then((res) => {
				this.listPeople = res.data.filter((f) => f.departmentId == event.selectedItem.organizationId);
				this.listPeopleInDepartment = this.listPeople;
				this.listPeopleInDepartmentTmp = this.listPeople;
			});
		}
		this.employeesData = [];
		this.employees = [];
	}

	countDateProbationExpire() {
		if (!isNullOrUndefined(this.employeeId)) {
			const dateEmploymentClone = new Date(this.employee.dateEmployment);
			this.dateNewPostEffective = !isNullOrUndefined(this.employee.dateEmployment)
				? new Date(dateEmploymentClone.setDate(dateEmploymentClone.getDate() + this.newPostDays))
				: null;
		}
		this.checkValidDateEffective = false;
		this.checkValidDateEmployment = false;
	}
	get infoBarType() {
		return InfoBarType;
	}
	selectedWelfareSalary(event, item, i) {
		const has = this.welfareSalaryList.find((f, index) => f.welfareId == event.selectValue && i != index);
		if (has) {
			event.cancel = true;
			return this._toastService.Warning({
				title: this.translate.instant("common_warning"),
				description: this.translate.instant("wel005_cannot_add_welfare_dup"),
			});
		}
		event.cancel = false;

		let hasMovement = null;
		if (this.salaryAdjustmentDetails.movement?.incomeTransactions?.length > 0) {
			hasMovement = this.salaryAdjustmentDetails.movement?.incomeTransactions?.find(
				(f) => f.financialItemId == event.selectedItem.financialItemId && event.selectedItem.welfareId == f.welfareId,
			);
		}
		if (this.salaryAdjustmentDetails.adjustment.financial.items?.length > 0) {
			const hasWelfare = this.salaryAdjustmentDetails.adjustment.financial.items?.find(
				(f) => f.financialItemId == event.selectedItem.financialItemId && event.selectedItem.welfareId == f.welfareId,
			);
			if (hasMovement == null) {
				hasMovement = hasWelfare;
			} else {
				hasMovement.amount = hasWelfare?.amount ?? 0;
			}
		}

		let controlsForm = <UntypedFormArray>(<UntypedFormArray>this.formWelfareSalary?.controls?.items).controls[i];
		controlsForm.controls["welfareId"].setValue(event.selectValue);
		controlsForm.controls["isIndividual"].setValue(event.selectedItem.isIndividual);
		controlsForm.controls["welfareType"].setValue(event.selectedItem.welfareType);
		controlsForm.controls["incomeTransactionId"].setValue(hasMovement?.incomeTransactionId ?? 0);
		controlsForm.controls["financialItemId"].setValue(event.selectedItem.financialItemId);
		controlsForm.controls["amount"].setValue(hasMovement?.amount ?? 0);
		controlsForm.controls["prevAmount"].setValue(hasMovement?.amount);
		controlsForm.controls["isNew"].setValue(Number(hasMovement == null));
		controlsForm.controls["name"].setValue(event.selectedItem.name);
		controlsForm.controls["name_EN"].setValue(event.selectedItem.name_EN);

		item.welfareId = event.selectValue;
		item.financialItemId = event.selectedItem.financialItemId;
		item.prevAmount = hasMovement?.amount;
		item.amount = hasMovement?.amount ?? 0;
		item.name = event.selectedItem.name;
		item.name_EN = event.selectedItem.name_EN;
		item.isIndividual = event.selectedItem.isIndividual;
		item.welfareType = event.selectedItem.welfareType;
		item.incomeTransactionId = hasMovement?.incomeTransactionId;
		item.isNew = Number(hasMovement == null);
	}
	//#endregion
	selectIsReplaceEmployee() {
		if (this.form.controls["isReplaceEmployee"].value) {
			this.form.controls["replaceEmployeeId"].setValidators(Validators.required);
		} else {
			this.form.controls["replaceEmployeeId"].clearValidators();
		}
		this.form.controls["replaceEmployeeId"].updateValueAndValidity();
	}
	selectIsAttendance() {
		this.form.controls["isAttendance"].setValue(this.employee.isAttendance);
	}
	deleteFormWelfareSalary(i): void {
		this.welfareSalaryList.splice(i, 1);
		const formItems = <UntypedFormArray>this.formWelfareSalary?.controls?.items;
		formItems.removeAt(i);
		this.calTotalAmount();
	}
	addWelfareSalaryAdjustment(event) {
		const controlsForm = <UntypedFormArray>this.formWelfareSalary?.controls?.items;
		let form = this.fb.group({
			isNew: new UntypedFormControl(1, Validators.required),
			incomeTransactionId: new UntypedFormControl(0),
			welfareType: new UntypedFormControl(0),
			isIndividual: new UntypedFormControl(false),
			welfareId: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
			financialItemId: new UntypedFormControl(0),
			amount: new UntypedFormControl(0, [Validators.required, Validators.min(1)]),
			prevAmount: new UntypedFormControl(0),
			name: new UntypedFormControl(""),
			name_EN: new UntypedFormControl(""),
		});
		controlsForm.push(form);
		this.welfareSalaryList = controlsForm.value;
	}
	changeIncomeAdjust() {
		this.form.controls["isIncomeAdjust"].setValue(this.isIncomeAdjust);
		if (this.form.controls["isIncomeAdjust"].value) {
			this.form.controls["incomeAmount"].setValidators(Validators.required);
		} else {
			this.form.controls["incomeAmount"].clearValidators();
		}
		this.form.controls["incomeAmount"].updateValueAndValidity();
	}
	cancelSidebarMM() {
		if (this.isGroupMovement) {
			this.data.setMovementTransactionId(this.employeeMovement.movementTransactionId);
		}
		this.isSidebarAdd = false;
	}
	closeSidebarMM() {
		setTimeout(() => {
			if (this.isGroupMovement) {
				this.data.setMovementTransactionId(this.employeeMovement.movementTransactionId);
			}
			this.closeSidebar.emit();
		}, 150);
	}
	openEmployeeView() {
		window.open(`${environment.redirectUri}employee/${this.employee.employeeNo}`);
	}
	openEmployeeViewSalary() {
		window.open(`${environment.redirectUri}employee/${this.employee.employeeNo}?tab=${RouteURL.payroll}`);
	}
	openPeoplePicker() {
		this.displayEmployee = true;
	}
	openDialogSubordinate(event) {
		this.isDialogSubordinate = event;
	}
	selectDate() {
		if (this.form.controls["dateEffective"].value != null) {
			let someDate = new Date(this.form.controls["dateEffective"].value);
			const numberOfDaysToDelete = 1;
			this.dateResign = new Date(someDate.setDate(someDate.getDate() - numberOfDaysToDelete));
		}
	}
	chooseNewOrg(event) {
		this.departmentId = event.selectValue;
		this.checkValidDepartment = false;
		this.levelsNew = event.selectedItem.levels;
		this.isSelectOrgNew = true;
	}
	chooseEmployeeTransfer(event) {
		this.employeesData = event.selectedItems;
		this.employees = event.value;
	}

	checkInValid(): boolean {
		if (this.movementType == this.massTransfer || this.movementType == Movement.transferSupervisor) {
			return false;
		} else {
			return (
				(this.movementType == Movement.renewal &&
					this.employee.isReplaceEmployee &&
					this.form.controls["replaceEmployeeId"].value == null) ||
				(this.form.controls["upperSupervisorId"].value == null && this.countUnder > 0) ||
				this.employeeId == null ||
				((this.form.controls["isIncomeAdjust"].value || this.movementType == Movement.raiseTheSalary) &&
					!this.validFormIncomeAmount &&
					this.tabPermissionPayroll) ||
				(this.movementType != Movement.renewal &&
					this.form.controls["isIncomeAdjust"].value &&
					!this.isShowSalary &&
					this.tabPermissionPayroll)
			);
		}
	}
	get Movement() {
		return Movement;
	}
	remove(event, id) {
		if (id.length > 1) {
			this.employeesData = [];
			this.employees = [];
			// this.chooseEmployeeTransfer([])
		} else {
			this.employeesData = this.employeesData.filter((f) => f.employeeId != id);
			const tmpEmployees = this.employees.filter((f) => f != id);
			this.employees = [...tmpEmployees];
		}
	}

	public isWillingness: boolean = false;
	public isAcceptPolicy: boolean = false;

	public isNotCheck(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;
			return !value ? { isNotCheck: true } : null;
		};
	}

	get confirmButtonLabel() {
		if (this.isSelfResign && this.userInfo?.hasAssessment && this.isAssessmentActive && this.permissionViewAssessment)
			return this.translate.instant("button_continue");
		else if (this.isSelfResign && this.userInfo?.hasMovementWorkflow)
			return this.translate.instant("button_resignation_request_dialog");
		else if (this.userInfo?.hasAssessment) return this.translate.instant("common_submit_movement");
		else return this.translate.instant("common_save");
	}

	public isStartedSurvey: boolean = false;
	public displayAssessment: boolean = false;
	public assessment: any;
	public isAssessmentActive: boolean = false;

	onUpdateAction(event) {
		this.isStartedSurvey = event;
	}

	getAssessmentMaster() {
		if (this.isSelfResign && this.userInfo?.hasAssessment) {
			firstValueFrom(this.userControlService.authorizeControlByListFunctionCode([Module.ASM001])).then((auth) => {
				this.permissionViewAssessment = !isNullOrUndefined(auth)
					? auth.find((f) => f.functionCode == Module.ASM001).allowGrant
					: false;
				if (this.permissionViewAssessment) {
					firstValueFrom(
						this.assessmentMasterService.getAssessmentMasterByParentId(0, AssessmentType.assessmentResign, ""),
					).then((result) => {
						this.assessment = result[0];
						this.isAssessmentActive = result[0].isActive ?? false;
					});
				}
			});
		}
	}

	async getAssessment() {
		if (
			!this.isSelfResign ||
			!this.userInfo?.hasAssessment ||
			!this.isAssessmentActive ||
			!this.permissionViewAssessment
		) {
			this.displayAssessment = false;
			return;
		}
		firstValueFrom(this.assessmentService.addAssessmentScoreTermination(this.employeeId)).then((res) => {
			let apiResult = <ApiResult>res;
			if (apiResult.result) {
				let assessment: AssessmentModel = new AssessmentModel();
				assessment.assessmentId = this.assessment.assessmentId;
				this.data.setAssessment(assessment);
				this.displayAssessment = true;
			}
		});
	}
	successAssessment(event) {
		if (event?.detail) {
			this.displayAssessment = false;
			this.submitMovement(event.detail.scoreId);
		}
	}

	discardChange(event) {
		event.cancel = true;
		const dialogModel = <DialogInformationModel>{
			title: this.translate.instant("common_create_discard_changes"),
			description: this.translate.instant("common_create_discard_changes_description"),
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm_discard"),
			textButtonCancel: this.translate.instant("button_keep_it"),
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				this.isClicked = false;
				this.assessmentFormService.getEmployeeAssessmentById(this.assessment.assessmentId).then((score) => {
					if (score[0]?.scoreId) {
						firstValueFrom(this.assessmentService.deleteAssessmentScoreAndDetails(score[0].scoreId)).then((result) => {
							let apiResult = <ApiResult>result;
							if (apiResult.result) {
								event.cancel = false;
								this.displayAssessment = false;
								this.isStartedSurvey = false;
							}
						});
					} else {
						event.cancel = false;
						this.displayAssessment = false;
						this.isStartedSurvey = false;
					}
				});
			}
		});
	}
	totalAmount: number;
	totalAmountPercent: number = 0;
	calTotalAmount() {
		const sumWelfare = this.welfareSalaryList?.reduce((acc, current) => acc + Number(current.amount), 0);
		const sumOther = this.financialOthers?.reduce((acc, current) => acc + Number(current.amount), 0);
		this.totalAmount = this.financialSalary?.amount + sumWelfare + (sumOther ?? 0);

		this.calTotalAmountPercent();
	}
	calTotalAmountPercent() {
		this.totalAmountPercent =
			(((this.totalAmount ?? 0) - this.salaryAdjustmentDetails?.adjustment?.incomeAmount) /
				this.salaryAdjustmentDetails?.adjustment?.incomeAmount) *
			100;

		if (this.salaryAdjustmentDetails.adjustment?.baseSalary == 0) {
			this.financialSalary.percentage = 100;
		}

		this.totalAmountPercent = Number(this.totalAmountPercent) ? 0 : this.totalAmountPercent;
	}
	changeAdjustTo(event) {
		this.extraAmount = event.value;
		this.changedSalary({ value: this.salaryAdjustmentDetails?.adjustment?.baseSalary + (this.extraAmount ?? 0) });

		this.calTotalAmount();
		this.calTotalAmountPercent();
	}

	changedSalary(event) {
		this.salaryBand = null;
		this.financialSalary.amount = event.value;
		this.salaryBand = { ...this.salaryBandCurrent };
		this.salaryBand.newBaseSalary = event.value;

		this.financialSalary.percentage = parseFloat(
			(((this.financialSalary.amount - this.salaryBand.baseSalary) / this.salaryBand.baseSalary) * 100).toFixed(2),
		);

		if (this.salaryAdjustmentDetails.adjustment?.baseSalary == 0) {
			this.financialSalary.percentage = 100;
			this.salaryAdjustmentDetails.adjustment.avgSalary = this.financialSalary.amount;
			this.financialBonus.amount = this.salaryAdjustmentDetails.adjustment?.avgSalary * this.financialBonus.percentage;
		}
		this.extraAmount = this.financialSalary.amount - this.salaryAdjustmentDetails?.adjustment?.baseSalary;
		this.calTotalAmount();
	}
	changePercentageSalary($event: any) {
		this.salaryBand = null;
		this.financialSalary.percentage = $event.value;
		if (this.financialSalary.percentage == 0) {
			this.financialSalary.amount = this.salaryAdjustmentDetails.adjustment?.baseSalary;
		} else {
			this.financialSalary.amount =
				this.salaryAdjustmentDetails.adjustment?.baseSalary +
				(this.salaryAdjustmentDetails.adjustment?.baseSalary * $event.value) / 100;
		}

		this.financialSalary.percentage = this.financialSalary.percentage == 0 ? null : this.financialSalary.percentage;
		this.financialBonus.amount = this.salaryAdjustmentDetails.adjustment?.avgSalary * this.financialBonus.percentage;
		this.extraAmount = this.financialSalary.amount - this.salaryAdjustmentDetails?.adjustment?.baseSalary;

		this.salaryBand = { ...this.salaryBandCurrent };
		this.salaryBand.newBaseSalary = this.financialSalary.amount;
		this.calTotalAmount();
	}
	changePercentageBonus($event: any) {
		this.financialBonus.percentage = $event.value;
		this.financialBonus.amount = this.salaryAdjustmentDetails.adjustment?.avgSalary * $event.value;
		this.calTotalAmount();
	}

	changeAmountBonus($event: any) {
		this.financialBonus.amount = $event.value;
		this.financialBonus.percentage = parseFloat(
			(this.financialBonus.amount / this.salaryAdjustmentDetails.adjustment?.avgSalary).toFixed(2),
		);
		this.calTotalAmount();
	}

	deleteMovementSalary() {
		const dialogModel = <DialogInformationModel>{
			title: this.translate.instant("common_confirmation"),
			description: this.translate.instant("common_delete_confirmation"),
			imageUrl: "confirmation.png",
			isRequiredRemark: true,
			isShowRemark: true,
			textButtonConfirm: this.translate.instant("common_delete"),
			textButtonCancel: this.translate.instant("common_cancel"),
			colorButtonConfirm: "#DE3A45",
		};

		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				this.movementTransaction.processActionId = ProcessAction.canceledDocument;
				this.movementTransaction.remark = response?.remark;
				this.movementTransaction.dateEffective = this.dateEffective;
				this.movementTransaction.movementTransactionId = this.movementTransactionId;
				this.employeeMovementService.addProcessActionMovementTransaction(this.movementTransaction).subscribe((res) => {
					let apiResult = <ApiResult>res;
					if (apiResult.result) {
						this.success.emit();
						this.closeSidebarMM();
						this.closeWebView(ProcessAction.canceledDocument.toString());
						apiResult.message = this.translate.instant("toast_success_deleted", {
							titleName: this.translate.instant("ess009_salary_adjustment_2"),
						});

						this.isSidebarAdd = false;
					}
					this._toastHelperService.alertApiResult(apiResult);
				});
			}
		});
	}

	get assetUrl() {
		return environment.gofiveCoreWeb.assetUrl;
	}

	private showValidationDuplicateToast() {
		this._toastHelperService.validation({
			title: this.translate.instant("toast_title_no_alr_exists"),
			description: this.translate.instant("validate_enter_another_emp_no"),
		});
	}

	private showValidateSelfResignToast(apiResult = null) {
		if (this.movementType == Movement.resign) {
			if (!apiResult.result) {
				this.resignApiError = true;
				this.warningBoxMessage = this.language == LanguageLocal.th ? apiResult.warning : apiResult.warning_EN;
			}
			let description = "";
			if (apiResult.result && apiResult.message != "" && apiResult.message_EN != "") {
				description = this.language == LanguageLocal.th ? apiResult.message : apiResult.message_EN;
			} else {
				description = this.translate.instant("toast_common_failed_to_proceed");
			}

			this._toastHelperService.validation({
				title: this.translate.instant("common_heads_up"),
				description: description,
			});
		}
	}

	selectTerminantionReasonId(event) {
		const terminationReasonSSOId = event?.selectedItem?.valueNum;
		this.reasonToResignSSO = this.reasonToResignSSODDL?.find((r) => r.typeId === terminationReasonSSOId);

		if (this.reasonToResignSSO?.typeId === MasterTypeTerminationSSO.LayOff) {
			this.getEmployeeSeverance();
		} else {
			this.clearSeveranceAmount();
		}
	}

	clearSeveranceAmount() {
		this.form.controls["severanceAmount"].setValue(0);
	}

	get masterTypeTerminationSSO() {
		return MasterTypeTerminationSSO;
	}

	getEmployeeSeverance() {
		this.employeeFinancialService.getEmployeeSeverance(this.employeeId, this.dateEffective).then((res) => {
			const severance = res?.data;
			this.form.controls["severanceAmount"].setValue(severance?.severanceAmount ?? 0);
		});
	}

	closePeoplePicker() {
		this.displayEmployee = false;
		if (this.reasonToResignSSO?.typeId === MasterTypeTerminationSSO.LayOff) {
			this.getEmployeeSeverance();
		} else {
			this.reasonToResignSSO = null;
			this.clearSeveranceAmount();
		}
	}

	clearPeople() {
		this.employeeModel.upperSupervisorId = null;
	}
}

export enum SalaryAdjSection {
	performance = 1,
	special,
	position,
}
