<app-payment-modal [toggleTab]="toggleTab" (paySuccess)="receivePaySuccess()"> </app-payment-modal>

<div class="ui-g-12 p-0 bg-default" style="min-height: 90vh">
	<div class="ui-g-12 px-0 pt-2">
		<div class="d-flex px-4 py-3 border-bottom-gray" style="justify-content: space-between">
			<label class="go5-text-header-3 go5-text-color-12">{{
				isManageWFH
					? ("sys012_wfh_setting" | translate)
					: ((welfareId > 0 ? "wel012_welfare_edit" : "wel012_welfare_add") | translate)
			}}</label>
			<div class="d-flex primary-pointer ml-1" style="padding: 5px 12px" (click)="openManual()">
				<span class="d-flex" style="align-items: center; margin-right: 8px">
					<em class="icon-lightbulb"></em>
				</span>
				<span class="text-title-strong color-primary" style="min-width: fit-content">
					{{ "user_manual" | translate }}
				</span>
			</div>
		</div>
		@if (welfareObj.welfareCategoryId == diligenceAllowance) {
			<div class="px-4">
				<go5-tabs (selected)="handleChange($event)" [contentPadding]="'0px'">
					<go5-tab [headerText]="'common_home' | translate"> </go5-tab>
					<go5-tab [headerText]="'wel006_setting_diligent_allowance' | translate"> </go5-tab>
				</go5-tabs>
			</div>
		}
	</div>
	<div [hidden]="!tabWelfare" class="ui-g-12 p-0 fadeIn" [hidden]="isLoad">
		<div class="ui-g-12 px-4 vertical-center" [ngStyle]="{ 'padding-bottom': !showAmountLimit ? '160px' : '0' }">
			<div class="layout-width-70">
				<div class="d-grid gap-16">
					<div class="d-grid gap-24">
						<div class="d-grid gap-16">
							<label [hidden]="hiddenWelfareType" class="go5-text-color-12 go5-text-header-5">
								{{ "welfare_setting" | translate }}
							</label>

							<div class="d-grid gap-8">
								<label class="go5-text-color-8" style="display: block">
									{{ "wel006_welfare_type" | translate }}
								</label>
								<div class="layout-card-welfare-type">
									@for (item of welfareTypes; track item; let i = $index) {
										@if (
											hasWelfareModule ||
											item.value === WelfareTypeEnum.additionalReimbursement ||
											(welfareId !== 0 && item.value === WelfareTypeEnum.general)
										) {
											<div
												attr.data-testid="input_card-welfare-type_{{ i }}"
												class="pointer card-welfare-type fadeIn {{ welfareType == item.value ? 'active' : '' }}"
												(click)="selectWelfareType(item.value)"
											>
												<em class="{{ item.icon }} font24"> </em>
												<label> {{ item.text | translate }}</label>
											</div>
										}
									}
								</div>
								<label class="go5-text-color-9" id="welfare_type_description">
									{{ welfareTypes[action == "edit" ? 0 : welfareType - 1].description | translate }}
								</label>
							</div>
						</div>

						<div class="d-grid gap-32" style="grid-template-columns: 1fr 1fr">
							<go5-textfield
								data-testid="input_text_welfare_name"
								type="text"
								category="primary"
								maxlength="150"
								placeholder="{{
									isManageWFH ? ('sys012_wfh_type' | translate) : ('wel006_benefit_name_placeholder' | translate)
								}}"
								[label]="(isManageWFH ? ('sys012_wfh_type' | translate) : ('wel006_welfare_name' | translate)) + '*'"
								[(ngModel)]="welfareObj.name"
								[error]="isValidForm && !(welfareObj.name != null && welfareObj.name != '')"
								[errorMessage]="
									'common_validate_please_fill'
										| translate
											: {
													fieldName: isManageWFH ? ('sys012_wfh_type' | translate) : ('wel006_welfare_name' | translate)
											  }
								"
							></go5-textfield>
							<go5-dropdown-selection
								data-testid="input_dropdown_common_company"
								[dataSource]="companyDDL"
								[allowShowRemove]="false"
								[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								mode="single"
								size="large"
								popupType="popup"
								[allowFiltering]="false"
								[value]="welfareCompanyId"
								(selected)="selectCompany($event.value)"
								[error]="isValidForm && (welfareCompanyId == null || welfareCompanyId == 0)"
								[label]="('common_company' | translate) + '*'"
								[errorMessage]="
									'common_validate_please_choose'
										| translate
											: {
													fieldName: ('common_company' | translate)
											  }
								"
								[disabled]="
									welfareId > 0 ||
									welfareObj.welfareCategoryId == diligenceAllowance ||
									welfareObj.welfareCategoryId == wfh
								"
								[placeholder]="'common_default_select' | translate"
							>
							</go5-dropdown-selection>
						</div>
					</div>
					@if (
						(welfareType == WelfareTypeEnum.payrollReimbursement ||
							welfareType == WelfareTypeEnum.additionalReimbursement) &&
						((isExpenseCustom && welfareId > 0) || action == "add")
					) {
						<div>
							<ng-template [ngTemplateOutlet]="iconExpenseTemplate"></ng-template>
						</div>
					}
					<ng-template #iconExpenseTemplate>
						<div class="d-grid gap-8">
							<label class="go5-text-color-8">{{ "sales_assistant_expense_category_icon" | translate }}*</label>
							<div class="list-icon-expense">
								@for (item of iconsExpense; track item) {
									<img
										(click)="setIconNo(item.id)"
										[src]="
											'assets/icon/expense/empeo-light/' +
												(item.id === welfareObj.iconNo ? '/active-' : '/inactive-') +
												item.id +
												'.png' | sAssets
										"
										alt="iconExpense"
									/>
								}
							</div>
							<go5-error-message
								[error]="isValidForm && !welfareObj.iconNo"
								[errorMessage]="
									'common_validate_please_choose'
										| translate: { fieldName: ('sales_assistant_expense_category_icon' | translate) }
								"
							>
							</go5-error-message>
						</div>
					</ng-template>
					<go5-textarea
						data-testid="input_text_common_description"
						category="primary"
						[disabled]="false"
						maxlength="255"
						size="large"
						[(ngModel)]="welfareObj.description"
						label="{{ isManageWFH ? ('sys012_wfh_description' | translate) : ('common_description' | translate) }}"
						placeholder="{{
							isManageWFH
								? ('sys012_wfh_description_placeholder' | translate)
								: ('wel006_benefit_detail_placeholder' | translate)
						}}"
					></go5-textarea>

					<!-- กำหนดอัตราค่าน้ำมันตามระยะทาง -->
					@if (
						welfareObj?.expenseCategoryId == ExpenseCategoryEnum.fuel &&
						(welfareType == WelfareTypeEnum.payrollReimbursement ||
							welfareType == WelfareTypeEnum.additionalReimbursement)
					) {
						<div class="d-grid gap-16">
							<ng-template [ngTemplateOutlet]="fuelRateConfig"></ng-template>
						</div>
					}
					<ng-template #fuelRateConfig>
						<div class="d-flex d-flex-between">
							<span class="d-flex" style="align-items: center">
								<label for="input" class="title-input">{{
									"wel012_set_fuel_reimburse_rate_distance" | translate
								}}</label>
							</span>
							<go5-toggle-switch
								data-testid="input_switch_set_fuelrate"
								category="secondary"
								[checked]="welfareObj.isSetFuelRate"
								(valueChange)="checkIsSetFuelRate($event)"
							></go5-toggle-switch>
						</div>
						@if (welfareObj.isSetFuelRate) {
							<div class="d-flex d-flex-between">
								<span class="d-flex" style="align-items: center">
									<label for="input" class="title-input">{{ "wel012_rate_per_km" | translate }}</label>
								</span>
								<go5-textfield
									data-testid="input_text_fuelrate_per_km"
									type="currency"
									[(ngModel)]="welfareObj.fuelRate"
									[min]="1"
									[format]="'0.2-2'"
									placeholder="0.00"
									[error]="isValidForm && (!welfareObj.fuelRate || welfareObj.fuelRate < 0)"
									[errorMessage]="'common_validate_amount' | translate"
								>
								</go5-textfield>
							</div>
						}
					</ng-template>

					@if (welfareType == WelfareTypeEnum.others) {
						<!-- ให้สวัสดิการที่เป็นตัวเงิน -->
						<div class="d-flex d-flex-between">
							<span class="d-flex" style="align-items: center">
								<label for="input" class="title-input">{{ "wel006_provided_money" | translate }}</label>
								<einfo-tooltip class="d-flex" key="{{ 'wel006_provided_money_tooltip' | translate }}" size="s">
								</einfo-tooltip>
							</span>
							<go5-toggle-switch
								data-testid="input_switch_isamount"
								category="secondary"
								[checked]="welfareObj.isAmount"
								(valueChange)="checkedIsAmount($event)"
								[disabled]="welfareObj.isMedicalExpense"
							></go5-toggle-switch>
						</div>
						<!-- ต้องเบิกผ่านระบบ -->
						<div class="d-flex d-flex-between" [hidden]="!welfareObj.isAmount">
							<span class="d-flex" style="align-items: center">
								<label for="input" class="title-input">{{ "wel006_must_request_via_expense" | translate }}</label>
								<einfo-tooltip
									class="d-flex"
									key="{{ 'wel006_must_request_via_expense_tooltip' | translate }}"
									size="s"
								>
								</einfo-tooltip>
							</span>
							<go5-toggle-switch
								data-testid="input_switch_isselfservice"
								category="secondary"
								[checked]="welfareObj.isSelfService"
								(valueChange)="checkedIsSelfService($event)"
							></go5-toggle-switch>
						</div>
						@if (welfareType == WelfareTypeEnum.others && welfareObj.isSelfService) {
							<div>
								<ng-template [ngTemplateOutlet]="iconExpenseTemplate"></ng-template>
							</div>
						}
						<!-- คำนวณสวัสดิการกับเงินเดือน -->
						<div class="d-flex d-flex-between" [hidden]="!welfareObj.isAmount || !(this.welfareCompanyId > 0)">
							<span>
								<label for="input" class="title-input">{{ "wel006_calculated_payroll" | translate }}</label>
							</span>
							<go5-toggle-switch
								data-testid="input_switch_is_financialitem"
								category="secondary"
								[checked]="welfareObj.isFinanCialItem"
								(valueChange)="checkedIsFinanCialItem($event)"
							></go5-toggle-switch>
						</div>
					}

					<!-- กำหนดสวัสดิการงวดนี้เป็น -->
					@if (
						welfareType == WelfareTypeEnum.extraAllowance ||
						welfareType == WelfareTypeEnum.payrollReimbursement ||
						(welfareType == WelfareTypeEnum.others && welfareObj.isFinanCialItem) ||
						welfareObj.welfareCategoryId == WelfareCategory.advancesPayment
					) {
						<div
							class="d-flex input-layout-switch"
							[hidden]="welfareCompanyId == 0 || welfareObj.welfareCategoryId == WelfareCategory.advancesPayment"
						>
							<label for="input" class="d-flex go5-text-color-8" style="align-items: center"
								>{{ "wel006_calculate_as" | translate }}
								<einfo-tooltip class="pl-2 d-flex" key="{{ 'wel006_calculate_as_tooltip' | translate }}" size="s">
								</einfo-tooltip>
							</label>
							<p-selectButton
								data-testid="input_select_btn_financial_item_name"
								[options]="optionFinancialType"
								[(ngModel)]="welfareObj.financialType"
								(onChange)="filterFinancialItemName = ''; welfareObj.financialItemId = 0"
							>
								<ng-template let-item>
									<div style="padding: 0.2em 1rem">
										{{ item.label | translate }}
									</div>
								</ng-template>
							</p-selectButton>
						</div>
						<!-- ชื่อรายได้/รายหัก -->
						<div class="d-grid" [hidden]="welfareCompanyId == 0">
							<div class="input-layout-switch gap-32">
								<span style="flex: 1">
									<label for="input" class="title-input"
										>{{ "common_name" | translate
										}}{{
											welfareObj.financialType == FinancialType.income
												? ("common_income" | translate)
												: ("common_deduction" | translate)
										}}
										*
									</label>
								</span>
								<go5-dropdown-selection
									style="flex: 1"
									data-testid="input_ddl_financial_item"
									#dropdownFinancialItem
									[dataSource]="welfareObj.financialType == FinancialType.income ? additionsAll : deductionsAll"
									[value]="welfareObj.financialItemId"
									(selected)="welfareObj.financialItemId = $event.value"
									[allowShowRemove]="false"
									[fields]="
										dataShare.getIsTH(dataShare.currentLanguage | async)
											? fieldsFinancialItemDDL
											: fieldsFinancialItemDDL_EN
									"
									mode="single"
									popupType="popup"
									[error]="isValidForm && !(welfareObj.financialItemId > 0)"
									(open)="openDDLFinancialItem()"
									(filtering)="filterFinancialItem($event)"
									[placeholder]="'common_default_select' | translate"
								>
									<ng-template #footerTemplate>
										<span
											[hidden]="filterFinancialItemName === ''"
											(click)="addFinancialItem(); dropdownFinancialItem.hide()"
										>
											<div class="footer-dropdown-add-event pt-2">
												<em class="gf-icon-add-general go5-color-primary"></em>
												@if (welfareObj.financialType == FinancialType.income) {
													<div>{{ "wel006_add_income_name " | translate }} :</div>
												}
												@if (welfareObj.financialType == FinancialType.deduct) {
													<div>{{ "wel006_add_deduction_name " | translate }} :</div>
												}
												{{ filterFinancialItemName }}
											</div>
										</span>
									</ng-template>
								</go5-dropdown-selection>
							</div>
							<div class="d-grid" style="margin-left: 50%">
								<go5-error-message
									[error]="isValidForm && !(welfareObj.financialItemId > 0)"
									[errorMessage]="
										'common_validate_please_choose'
											| translate
												: {
														fieldName:
															welfareObj.financialType == FinancialType.income
																? ('common_income' | translate)
																: ('common_deduction' | translate)
												  }
									"
								>
								</go5-error-message>
							</div>
						</div>
						@if (welfareObj.financialItemId > 0) {
							<div class="border-bottom-gray pb-4 d-flex" style="justify-content: flex-end">
								<go5-button
									data-testid="input_btn_financial_type_setting"
									label="{{
										(welfareObj.financialType == FinancialType.income
											? 'welf006_income_setting'
											: 'welf006_deduct_setting'
										) | translate
									}}"
									category="secondary"
									margin="0"
									iconClass="gf-icon-venio-menu-admin1"
									size="medium"
									type="button"
									(click)="isDialogAdd = true"
								>
								</go5-button>
							</div>
						}
					}
				</div>

				<!-- เงื่อนไขการได้รับสวัสดิการ -->
				<!-- hidden when close isSeleservice and type other -->
				<div class="d-grid gap-16">
					<div class="d-flex d-flex-between d-flex-center">
						<label class="go5-text-header-5">{{
							isManageWFH
								? ("sys012_wfh_receiving_condition" | translate)
								: ("wel006_eligibility_conditions" | translate)
						}}</label>
						@if (welfareType == WelfareTypeEnum.extraAllowance || welfareType == WelfareTypeEnum.others) {
							<go5-selection-single-group
								data-testid="input_select_btn_isDividual"
								[dataSource]="conditionWelfare"
								[fields]="fieldsGenderDDL"
								[value]="welfareObj.isResposiblePerson"
								(select)="selectConditionWelfare($event)"
								size="medium"
							>
							</go5-selection-single-group>
						}
					</div>
					@if (!welfareObj.isResposiblePerson) {
						<!-- ทำจ่ายสวัสดิการให้ -->
						@if (
							welfareType == WelfareTypeEnum.extraAllowance ||
							(welfareType == WelfareTypeEnum.others &&
								welfareObj.isAmount &&
								!welfareObj.isSelfService &&
								welfareObj.isFinanCialItem)
						) {
							<div>
								<div class="d-grid" style="grid-template-columns: 1fr 1fr">
									<span class="d-flex" style="align-items: center">
										<label for="input" class="title-input">{{ "wel006_welfare_paid" | translate }}</label>
										<einfo-tooltip class="d-flex" key="{{ 'wel006_welfare_paid_tooltip' | translate }}" size="s">
										</einfo-tooltip>
									</span>
									<go5-dropdown-selection
										data-testid="input_dropdown_welfare_paid"
										#dropdownWelfarePaid
										[dataSource]="conditions"
										[value]="welfareObj.conditionType"
										(selected)="selectedConditionWelfare($event)"
										[allowFiltering]="false"
										[allowShowRemove]="false"
										[fields]="fieldsDDL"
										mode="single"
										popupType="popup"
										[placeholder]="'common_default_select' | translate"
									>
									</go5-dropdown-selection>
								</div>
							</div>
						}
						<!-- เท่ากันทุกคน -->
						<ng-container>
							<!-- เป็นจำนวนเงิน -->
							@if (
								(welfareObj.conditionType == ConditionTypeWelfare.PaidSameAmount &&
									welfareType == WelfareTypeEnum.extraAllowance) ||
								(welfareObj.conditionType == ConditionTypeWelfare.PaidSameAmount &&
									welfareType == WelfareTypeEnum.others &&
									welfareObj.isAmount &&
									!welfareObj.isSelfService &&
									welfareObj.isFinanCialItem)
							) {
								<div class="d-grid gap-16">
									<div class="input-layout-switch">
										<span>
											<label for="input" class="title-input">{{ "wel006_paid_amount" | translate }} </label>
										</span>
										<div class="ui-g-6 p-0 d-flex input-dropdown-split">
											<div class="ui-g-8 p-0">
												<go5-textfield
													#textFieldAmounts
													data-testid="input_text_paid_amount_amount"
													type="currency"
													[(ngModel)]="welfareObj.amount"
													[min]="1"
													[format]="'0.2-2'"
													[error]="!textFieldAmounts.valid && isValidForm"
													[errorMessage]="'common_validate_amount' | translate"
												></go5-textfield>
											</div>
											<div class="ui-g-4 p-0">
												<go5-dropdown-selection
													data-testid="input_dropdown_welfare_employee_group"
													[dataSource]="welfareEmployeeGroupDDL"
													[value]="welfareObj.paymentType"
													(selected)="welfareObj.paymentType = $event.value"
													[allowFiltering]="false"
													[allowShowRemove]="false"
													[fields]="fields"
													mode="single"
													popupType="popup"
													[placeholder]="'common_default_select' | translate"
												>
												</go5-dropdown-selection>
											</div>
										</div>
									</div>
									<ng-template [ngTemplateOutlet]="allowanceFromAttendance"></ng-template>
								</div>
							}
							@if (
								welfareObj.conditionType == ConditionTypeWelfare.PaidSameAmount ||
								(welfareType != WelfareTypeEnum.extraAllowance && welfareType != WelfareTypeEnum.others) ||
								(welfareObj.isAmount && welfareObj.isSelfService && welfareObj.isFinanCialItem)
							) {
								<div class="d-flex d-flex-center">
									<label class="pointer pr-2">
										<go5-checkbox
											class="go5-text-color-10"
											data-testid="input_checkbox_isindividual"
											label="{{ 'wel006_set_individual_level' | translate }}"
											[checked]="welfareObj.isIndividual"
											(changed)="onChangeIndividual($event)"
										>
										</go5-checkbox>
									</label>
									<label
										[hidden]="!welfareObj.isIndividual"
										class="hover-text pointer"
										(click)="sidebarEmployee(true, true)"
										style="text-decoration: underline"
									>
										{{
											employeeList?.length > 0
												? "(" +
													("wel006_selected" | translate) +
													" " +
													employeeList.length +
													" " +
													("common_employees" | translate) +
													")"
												: ""
										}}
									</label>
								</div>
								@if (welfareObj.isIndividual) {
									<div class="d-flex" style="align-items: center; user-select: none">
										<go5-button
											data-testid="button_button_save"
											label="{{ 'common_add_employee' | translate }}"
											iconClass="gf-icon-add"
											padding="0"
											margin="0"
											category="text"
											size="medium"
											type="button"
											(click)="sidebarEmployee(true)"
										></go5-button>
									</div>
								}
								@if (!welfareObj.isIndividual) {
									<div class="p-0 w-100 d-grid gap-16">
										<!-- ประเภทการจ้างงาน -->
										<div class="d-grid gap-16">
											<div class="input-layout-switch">
												<label for="input" class="title-input">{{ "wel006_employment_type" | translate }}</label>
												<go5-toggle-switch
													data-testid="input_toggle_switch_employment_type"
													category="secondary"
													[checked]="employmentType"
													(valueChange)="employmentType = $event.checked"
												></go5-toggle-switch>
											</div>
											<div class="layout-checkbox" [hidden]="!employmentType">
												@for (item of employmentTypeDDL; track item) {
													<div>
														<label class="pointer d-flex">
															<go5-checkbox
																class="go5-text-color-10"
																attr.data-testid="input_checkbox_selected"
																label="{{ item.name }}"
																[checked]="item.selected"
																(changed)="onSelectEmploymentType(item, $event)"
															>
															</go5-checkbox>
														</label>
													</div>
												}
											</div>
										</div>
										<!-- ระดับงาน -->
										<div class="d-grid gap-16">
											<div class="input-layout-switch">
												<label for="input" class="title-input">{{ "wel006_rank" | translate }}</label>
												<go5-toggle-switch
													data-testid="input_switch_ranks"
													category="secondary"
													[checked]="ranks"
													(valueChange)="ranks = $event.checked"
												></go5-toggle-switch>
											</div>
											<div class="layout-checkbox" [hidden]="!ranks">
												@for (item of ranksDDL; track item; let i = $index) {
													<div>
														<label class="pointer d-flex">
															<go5-checkbox
																class="go5-text-color-10"
																attr.data-testid="input_checkbox_selected_{{ i }}"
																label="{{ item.name }}"
																[checked]="item.selected"
																(changed)="onChangeRank(item, $event); onClickRank(item)"
															>
															</go5-checkbox>
														</label>
													</div>
												}
											</div>
										</div>
										<!-- ผ่านทดลองงาน -->
										<div class="input-layout-switch">
											<span>
												<label for="input" class="title-input">{{ "wel006_permanent" | translate }}</label>
											</span>
											<go5-toggle-switch
												data-testid="input_switch_ispermanent"
												category="secondary"
												[checked]="welfareObj.isPermanent"
												(valueChange)="welfareObj.isPermanent = $event.checked"
											></go5-toggle-switch>
										</div>
										<!-- อายุงาน -->
										<div class="d-grid gap-16">
											<div class="input-layout-switch">
												<span>
													<label for="input" class="title-input">{{ "wel006_age_of_work" | translate }}</label>
												</span>
												<go5-toggle-switch
													data-testid="input_switch_monthageofwork"
													category="secondary"
													[checked]="monthAgeOfWork"
													(valueChange)="monthAgeOfWork = $event.checked; setDefualtAgeOfWork()"
												></go5-toggle-switch>
											</div>
											<div class="fadeIn border-bottom-gray pb-4" [hidden]="!monthAgeOfWork">
												<div class="ui-g-12 ui-lg-6 ui-md-6 p-0 input-dropdown-split">
													<div class="ui-g-12 p-0 py-1">
														<label for="input" class="title-input">{{ "common_at_least" | translate }}</label>
													</div>
													<div class="ui-g-8 p-0">
														<go5-textfield
															data-testid="input_currency_age"
															type="number"
															min="1"
															(changed)="changeAge()"
															[(ngModel)]="age"
														></go5-textfield>
													</div>
													<div class="ui-g-4 p-0">
														<go5-dropdown-selection
															data-testid="input_dropdown_age_of_work"
															[dataSource]="ageTypeDDL"
															[value]="ageType"
															(selected)="ageType = $event.value"
															[allowFiltering]="false"
															[allowShowRemove]="false"
															[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
															mode="single"
															popupType="popup"
															[placeholder]="'wel006_age_of_work' | translate"
														>
														</go5-dropdown-selection>
													</div>
												</div>
											</div>
										</div>
										<!-- เพศ -->
										<div class="d-grid gap-16">
											<div class="input-layout-switch">
												<span>
													<label for="input" class="title-input">{{ "common_gender_used" | translate }}</label>
												</span>
												<go5-toggle-switch
													data-testid="input_switch_gender"
													category="secondary"
													[checked]="gender"
													(valueChange)="gender = $event.checked; setDefualtAgeOfWork()"
												></go5-toggle-switch>
											</div>
											<div class="fadeIn border-bottom-gray pb-4" [hidden]="!gender">
												<go5-selection-single-group
													data-testid="input_select_btn_gender"
													[dataSource]="arrGender"
													[fields]="fieldsGenderDDL"
													[(ngModel)]="welfareObj.allowGender"
													size="medium"
												>
												</go5-selection-single-group>
											</div>
										</div>
									</div>
								}
							}
							<!-- กำหนดวงเงิน -->
							<div
								class="ui-g-12 p-0 pt-3"
								[hidden]="
									!isShowInput ||
									welfareType == WelfareTypeEnum.general ||
									welfareType == WelfareTypeEnum.extraAllowance ||
									(!welfareObj.isSelfService && welfareType == WelfareTypeEnum.others)
								"
							>
								<div class="ui-g-12 p-0 py-1 input-layout-switch">
									<span>
										<label class="text-header-5" for="input">{{ "wel006_set_limit" | translate }}</label>
									</span>
									<go5-toggle-switch
										data-testid="input_switch_iscondition"
										category="secondary"
										[checked]="showAmountLimit"
										(valueChange)="onChangeCondition($event)"
									>
									</go5-toggle-switch>
								</div>
								<div class="fadeIn" [hidden]="!showAmountLimit || welfareObj.isIndividual">
									<!-- จำกัดวงเงินตาม -->
									<div class="ui-g-12 p-0 py-1">
										<div class="ui-g-12 p-0 py-1 input-layout-switch">
											<span class="d-flex" style="align-items: center">
												<label for="input" class="title-input">{{ "wel006_limi_type" | translate }}</label>
												<einfo-tooltip class="d-flex" key="{{ 'wel006_limi_type_tooltip' | translate }}" size="s">
												</einfo-tooltip>
											</span>
											<div class="ui-g-4 p-0">
												<go5-dropdown-selection
													data-testid="input_dropdown_credit_type"
													[dataSource]="creditTypeDDL"
													[value]="welfareObj.creditType"
													(selected)="welfareObj.creditType = $event.value"
													[allowFiltering]="false"
													[allowShowRemove]="false"
													[fields]="fieldsDDL"
													mode="single"
													popupType="popup"
													[placeholder]="'common_default_select' | translate"
												>
												</go5-dropdown-selection>
											</div>
										</div>
									</div>
									<div class="ui-g-12 p-0 py-1">
										<div class="d-flex">
											<go5-checkbox
												class="go5-text-color-10"
												data-testid="input_checkbox_isstack"
												label=" {{ 'wel006_set_limit_ladder' | translate }}"
												[checked]="isStack"
												(changed)="onChangeStack($event)"
											>
											</go5-checkbox>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
						@if (
							welfareObj.conditionType == ConditionTypeWelfare.PaidVariouslyGroup &&
							(welfareType == WelfareTypeEnum.extraAllowance ||
								(welfareType == WelfareTypeEnum.others &&
									welfareObj.isAmount &&
									!welfareObj.isSelfService &&
									welfareObj.isFinanCialItem))
						) {
							<ng-template [ngTemplateOutlet]="allowanceFromAttendance"></ng-template>
							@for (item of activeGroups; track item.id) {
								<div class="input-layout-switch">
									<span>
										<label for="input" class="title-input text-action">
											<span class="go5-text-color-8"> {{ "wel006_detail_group" | translate }} </span>
											<span
												class="hover-text go5-text-color-12 pointer"
												style="text-decoration: underline"
												(click)="selectEmployee(item)"
												>({{ item.employee.length }}
												{{ (item.employee.length > 1 ? "common_employee" : "common_employees") | translate }})</span
											>
											<span class="go5-text-color-8"> {{ "wel006_detail_group2" | translate }}</span>
										</label>
									</span>
									<div class="ui-g-6 p-0 d-flex input-dropdown-split">
										<div class="ui-g-8 p-0">
											<go5-textfield
												data-testid="input_currency_input_dropdown_split_amont"
												type="currency"
												[(ngModel)]="item.amount"
											></go5-textfield>
										</div>
										<div class="ui-g-4 p-0">
											<go5-dropdown-selection
												data-testid="input_currency_input_dropdown_split_payment_type"
												[dataSource]="welfareEmployeeGroupDDL"
												[value]="item.paymentType"
												(selected)="item.paymentType = $event.value"
												[allowFiltering]="false"
												[allowShowRemove]="false"
												[fields]="fields"
												mode="single"
												popupType="popup"
												[placeholder]="'common_default_select' | translate"
											>
											</go5-dropdown-selection>
										</div>
										<span class="icon-center pl-2">
											<go5-icon
												go5-tooltip-mode="hover"
												go5-tooltip="{{ 'common_delete' | translate }}"
												[iconClass]="'gf-icon-empeo-bin-vector'"
												[category]="'normal'"
												[size]="'small'"
												(click)="removeGroup(item.id)"
											>
											</go5-icon>
										</span>
									</div>
								</div>
							}
							<go5-button
								data-testid="button_button_save"
								label="{{ 'wel006_add_group' | translate }}"
								iconClass="gf-icon-add"
								padding="0"
								margin="0"
								category="text"
								size="medium"
								type="button"
								(click)="addGroup()"
							>
							</go5-button>
						}
						<!-- conditionType = 2 จ่ายเงินแบบรายคน -->
						@if (
							welfareObj.conditionType == ConditionTypeWelfare.PaidVariablePerIndividual &&
							(welfareType == WelfareTypeEnum.extraAllowance ||
								(welfareType == WelfareTypeEnum.others &&
									welfareObj.isAmount &&
									!welfareObj.isSelfService &&
									welfareObj.isFinanCialItem))
						) {
							<ng-template [ngTemplateOutlet]="allowanceFromAttendance"></ng-template>
							<ng-template [ngTemplateOutlet]="PaidVariablePerIndividualContent"></ng-template>
						}
					} @else {
						<div class="d-grid">
							<span class="d-flex d-flex-center gap-8">
								<label for="input" class="go5-text-color-8">{{ "welfare_responsible_person" | translate }}</label>
								<einfo-tooltip class="d-flex" key="{{ 'wel006_responsible_person_tooltip' | translate }}" size="s">
								</einfo-tooltip>
							</span>
							<go5-dropdown-avatar-inplace
								class="d-flex"
								[dataSource]="responsiblePersonList"
								[value]="responsiblePersonValue"
								[fields]="
									dataShare.getIsTH(dataShare.currentLanguage | async) ? peopleEmployeeFields : peopleEmployeeFields_EN
								"
								placeholder=""
								(open)="$event.cancel = true; sidebarEmployeeResponsiblePerson(true)"
								(remove)="removeResponsiblePersonList($event)"
								mode="multi"
								popupType="popup"
								iconClass="gf-icon-add-participants"
								[textAdd]="'common_add_new' | translate"
							>
							</go5-dropdown-avatar-inplace>
							<go5-error-message
								[error]="isValidForm && responsiblePersonList?.length == 0"
								[errorMessage]="
									'common_validate_please_choose' | translate: { fieldName: 'welfare_responsible_person' | translate }
								"
							>
							</go5-error-message>
						</div>
					}
				</div>
			</div>
		</div>
		@if (!isStack && showAmountLimit) {
			<div class="ui-g-12 vertical-center" [ngStyle]="{ 'padding-bottom': '100px' }">
				<div class="ui-g-12 ui-md-9 px-4" [class]="isExtra ? 'ui-lg-10' : 'ui-lg-8 '">
					<table class="hover dataTable dataTables-parti-coloured" style="width: 100%; border: 0">
						<thead>
							<tr>
								<th class="th-sm-1 border-top-left-default text-left" [ngStyle]="{ width: isExtra ? '15%' : '20%' }">
									<label for="input">{{
										(welfareObj.creditType == CreditType.salary ? "wel006_limit_time" : "wel006_number_rights")
											| translate
									}}</label>
								</th>
								<th class="th-sm-1 text-left" [ngStyle]="{ width: isExtra ? '15%' : '20%' }">
									<label for="input">{{
										(welfareObj.creditType == CreditType.salary ? "wel006_limit_amount_unit" : "wel006_limit_amount")
											| translate
									}}</label>
								</th>
								<th class="th-sm-1 text-left" [ngStyle]="{ width: isExtra ? '15%' : '20%' }">
									<label
										for="input"
										class="ellipsis"
										go5-tooltip-placement="top"
										go5-tooltip-mode="hover"
										go5-tooltip="{{
											(welfareObj.creditType == CreditType.salary ? 'wel006_maximum_salary' : 'wel006_rights_per_time')
												| translate
										}}"
									>
										{{
											(welfareObj.creditType == CreditType.salary ? "wel006_maximum_salary" : "wel006_rights_per_time")
												| translate
										}}
									</label>
								</th>
								<th
									[ngStyle]="{ width: isExtra ? '15%' : '20%' }"
									id="wel012_usable_amount_percentage"
									class="th-sm-1 text-left"
								>
									<label for="input">{{ "wel012_usable_amount_percentage" | translate }}</label>
								</th>
							</tr>
						</thead>
						<!-- % ของฐานเงินเดือน -->
						<tbody>
							<tr class="row-input-payroll">
								<td>
									<go5-textfield
										testid="input_number_number_rights"
										#limitTimeInput
										type="number"
										category="primary"
										[disabled]="false"
										[maxLength]="255"
										[minLength]="0"
										[placeholder]="'common_unlimited' | translate"
										min="1"
										[step]="1"
										[(ngModel)]="numberRights"
										[unit]="(limitTime > 1 ? 'common_times' : 'common_time') | translate"
										format="0.0-2"
									></go5-textfield>
								</td>
								<td>
									<go5-textfield
										testid="input_currency_total_amount"
										type="currency"
										category="primary"
										[placeholder]="'common_unlimited' | translate"
										[disabled]="false"
										[maxLength]="255"
										[minLength]="0"
										[(ngModel)]="totalAmount"
										[step]="1"
										format="0.0-2"
									></go5-textfield>
								</td>
								<td>
									@if (welfareObj.creditType == CreditType.salary) {
										<go5-textfield
											testid="input_number_limit_amount_salary_percentage"
											type="number"
											category="primary"
											[placeholder]="'common_one_hundred_percent' | translate"
											[disabled]="false"
											[max]="100"
											[min]="0"
											[step]="1"
											[unit]="'%'"
											[(ngModel)]="limitAmountSalaryPercentage"
										></go5-textfield>
									}
									@if (welfareObj.creditType == CreditType.custom) {
										<go5-textfield
											testid="input_number_limit_amount_pertime"
											type="number"
											category="primary"
											[disabled]="false"
											[placeholder]="'common_unlimited' | translate"
											[min]="0"
											[step]="1"
											[unit]="''"
											[(ngModel)]="limitAmountPerTime"
										></go5-textfield>
									}
								</td>
								<td class="center">
									<go5-textfield
										testid="input_number_usable_amount_percentage"
										type="number"
										category="primary"
										[disabled]="false"
										[placeholder]="'common_one_hundred_percent' | translate"
										[max]="100"
										[min]="0"
										[step]="0.5"
										unit="%"
										[(ngModel)]="usableAmountPercentage"
									></go5-textfield>
								</td>
								<!-- <td class="center" [hidden]="!isExtra">
          <go5-textfield
            testid="input_number_extra_amount_percentage"
            type="number"
            category="primary"
            [disabled]="false"
            [max]="100"
            [min]="0"
            [step]="0.5"
            unit="%"
            [(ngModel)]="extraAmountPercentage"
          ></go5-textfield>
        </td>
        <td class="center" [hidden]="!isExtra">
          <go5-textfield
            testid="input_currency_extra_amount"
            type="currency"
            category="primary"
            [disabled]="false"
            [min]="0"
            [(ngModel)]="extraAmount"
          ></go5-textfield>
        </td> -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		}
		@if (isStack && showAmountLimit) {
			<div
				class="ui-g-12 vertical-center"
				style="padding-top: 70px"
				[ngStyle]="{ 'padding-bottom': isStack ? '100px' : '' }"
			>
				<div class="ui-g-12 ui-md-9 px-4" [class]="isStack ? 'ui-lg-10' : 'ui-lg-8 '">
					<div class="search-table-outter">
						<form [formGroup]="formWelfareDetail">
							<table class="hover dataTable dataTables-parti-coloured" style="width: 100%; border: 0">
								<thead>
									<tr>
										<th id="wel006_minimum_rank" class="th-sm-1" style="width: 10%">
											<label for="input">{{ "wel006_minimum_rank" | translate }}*</label>
										</th>
										<th id="wel006_maximum_rank" class="th-sm-1" style="width: 10%">
											<label for="input">{{ "wel006_maximum_rank" | translate }}*</label>
										</th>
										<th class="th-sm-1 text-left" style="width: 10%">
											<label for="input">{{
												(welfareObj.creditType == CreditType.salary ? "wel006_limit_time" : "wel006_number_rights")
													| translate
											}}</label>
										</th>
										<th class="th-sm-1 text-left" style="width: 10%">
											<label for="input">{{
												(welfareObj.creditType == CreditType.salary
													? "wel006_limit_amount_unit"
													: "wel006_limit_amount"
												) | translate
											}}</label>
										</th>
										<th class="th-sm-1 text-left" style="width: 10%">
											<label
												for="input"
												class="ellipsis"
												go5-tooltip-placement="top"
												go5-tooltip-mode="hover"
												go5-tooltip="{{
													(welfareObj.creditType == CreditType.salary
														? 'wel006_maximum_salary'
														: 'wel006_rights_per_time'
													) | translate
												}}"
											>
												{{
													(welfareObj.creditType == CreditType.salary
														? "wel006_maximum_salary"
														: "wel006_rights_per_time"
													) | translate
												}}
											</label>
										</th>
										<th id="wel012_usable_amount_percentage" class="th-sm-1" style="width: 10%">
											<label for="input">{{ "wel012_usable_amount_percentage" | translate }}</label>
										</th>
										<!-- <th id="wel012_extra_amount_percentage" class="th-sm-1" style="width: 10%" [hidden]="!isExtra">
                <label for="input">{{ "wel012_extra_amount_percentage" | translate }}</label>
              </th>
              <th id="wel012_extra_amount" class="th-sm-2" style="width: 10%" [hidden]="!isExtra">
                <label for="input">{{ "wel012_extra_amount" | translate }}</label>
              </th> -->
										@if (permission.allowDelete) {
											<th id="action" class="th-sm-1" style="width: 5%">
												<label for="input"> </label>
											</th>
										}
									</tr>
								</thead>
								<tbody formArrayName="items">
									@for (item of welfareObj.welfareDetails; track item; let i = $index) {
										<tr [formGroupName]="i" class="opacity-table">
											<td class="center" style="max-width: 100px">
												<go5-dropdown-selection
													attr.data-testid="dropdown_single_minimumrankfromddl_{{ i }}"
													[dataSource]="item.minimumRankFromDDL"
													(open)="openDDLRankList()"
													(selected)="selectRankListFrom(item, $event)"
													[allowFiltering]="false"
													[allowShowRemove]="false"
													[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
													mode="single"
													popupType="popup"
													[(ngModel)]="item.minimumRank"
													formControlName="minimumRank"
													placeholder="{{ 'common_default_select' | translate }}"
												>
												</go5-dropdown-selection>
											</td>
											<td class="center" style="max-width: 100px">
												<go5-dropdown-selection
													attr.data-testid="dropdown_single_maximumranktoddl_{{ i }}"
													[dataSource]="item.maximumRankToDDL"
													(open)="openDDLRankList()"
													(selected)="selectRankListTo(item, $event)"
													[allowFiltering]="false"
													[allowShowRemove]="false"
													[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
													mode="single"
													popupType="popup"
													[(ngModel)]="item.maximumRank"
													formControlName="maximumRank"
													placeholder="{{ 'common_default_select' | translate }}"
												>
												</go5-dropdown-selection>
											</td>
											<td class="center">
												<go5-textfield
													attr.data-testid="input_number_totaltime_{{ i }}"
													type="number"
													category="primary"
													[placeholder]="'common_unlimited' | translate"
													[disabled]="false"
													[maxLength]="255"
													[minLength]="0"
													min="1"
													formControlName="totalTime"
													[step]="1"
													[(ngModel)]="item.totalTime"
													[unit]="(limitTime > 1 ? 'common_times' : 'common_time') | translate"
													format="0.0-2"
												></go5-textfield>
											</td>
											<td class="center">
												<go5-textfield
													attr.data-testid="input_number_totalamount_{{ i }}"
													type="currency"
													[placeholder]="'common_unlimited' | translate"
													category="primary"
													[disabled]="false"
													[maxLength]="255"
													[minLength]="0"
													[step]="1"
													formControlName="totalAmount"
													[(ngModel)]="item.totalAmount"
													format="0.0-2"
												></go5-textfield>
											</td>
											<td class="center">
												@if (welfareObj.creditType == CreditType.salary) {
													<go5-textfield
														attr.data-testid="input_number_limit_amount_salary_percentage_{{ i }}"
														type="number"
														[placeholder]="'common_one_hundred_percent' | translate"
														category="primary"
														[disabled]="false"
														[max]="100"
														[min]="0"
														[formControlName]="'limitAmountSalaryPercentage'"
														[step]="1"
														[unit]="'%'"
														[(ngModel)]="item.limitAmountSalaryPercentage"
													></go5-textfield>
												}
												@if (welfareObj.creditType == CreditType.custom) {
													<go5-textfield
														attr.data-testid="input_number_limit_amount_pertime_percentage_{{ i }}"
														type="number"
														category="primary"
														[disabled]="false"
														[min]="0"
														[formControlName]="'limitAmountPerTime'"
														[placeholder]="'common_unlimited' | translate"
														[step]="1"
														[(ngModel)]="item.limitAmountPerTime"
													></go5-textfield>
												}
											</td>
											<td class="center">
												<go5-textfield
													attr.data-testid="input_number_usableamountpercentage_{{ i }}"
													type="number"
													category="primary"
													[max]="100"
													[step]="1"
													[placeholder]="'common_one_hundred_percent' | translate"
													formControlName="usableAmountPercentage"
													[(ngModel)]="item.usableAmountPercentage"
													unit="%"
													format="0.0-2"
												>
												</go5-textfield>
											</td>
											<!-- <td class="center" [hidden]="!isExtra">
                <go5-textfield
                  attr.data-testid="input_number_extraamountpercentage_{{ i }}"
                  type="number"
                  category="primary"
                  [max]="100"
                  [step]="1"
                  formControlName="extraAmountPercentage"
                  [(ngModel)]="item.extraAmountPercentage"
                  unit="%"
                  format="0.0-2"
                  >
                </go5-textfield>
              </td>
              <td class="center" [hidden]="!isExtra">
                <go5-textfield
                  attr.data-testid="input_number_extraamount_{{ i }}"
                  type="currency"
                  category="primary"
                  [step]="1"
                  formControlName="extraAmount"
                  [(ngModel)]="item.extraAmount"
                  format="0.0-2"
                  >
                </go5-textfield>
              </td> -->
											@if (permission.allowDelete) {
												<td class="center">
													<em
														attr.data-testid="input_btn_delete_{{ i }}"
														id="deleteBTN"
														class="gf-icon-empeo-bin-vector pointer"
														(click)="deleteFieldValue(i)"
														go5-tooltip-placement="top"
														go5-tooltip-mode="hover"
														go5-tooltip="{{ 'common_delete' | translate }}"
													>
													</em>
												</td>
											}
										</tr>
									}
									@if (welfareObj.welfareDetails == null && welfareObj.welfareDetails?.length == 0) {
										<tr>
											<td colspan="10" class="no-data-available">{{ "common_no_data" | translate }}</td>
										</tr>
									}
								</tbody>
							</table>
						</form>
					</div>
					<div class="ui-g-12 p-0 pt-3">
						<div class="d-flex" style="align-items: center; user-select: none">
							<go5-button
								data-testid="button_button_save"
								label="{{ 'common_add_list' | translate }}"
								iconClass="gf-icon-add"
								category="text"
								padding="0"
								margin="0"
								size="medium"
								type="button"
								(click)="addFieldValue()"
							></go5-button>
						</div>
					</div>
				</div>
			</div>
		}
	</div>
	@if (isLoad) {
		<div>
			<div class="ui-g-12 px-4 vertical-center">
				<div class="layout-width-70">
					<div class="ui-g-12 p-0">
						<div class="ui-g-12 d-flex gap-16">
							<ngx-skeleton-loader [theme]="{ height: '90px', width: '112px' }" animation="progress-dark">
							</ngx-skeleton-loader>
							<ngx-skeleton-loader [theme]="{ height: '90px', width: '112px' }" animation="progress-dark">
							</ngx-skeleton-loader>
							<ngx-skeleton-loader [theme]="{ height: '90px', width: '112px' }" animation="progress-dark">
							</ngx-skeleton-loader>
							<ngx-skeleton-loader [theme]="{ height: '90px', width: '112px' }" animation="progress-dark">
							</ngx-skeleton-loader>
							<ngx-skeleton-loader [theme]="{ height: '90px', width: '112px' }" animation="progress-dark">
							</ngx-skeleton-loader>
						</div>
						<div class="ui-g-12 ui-md-6 ui-lg-6 mt-3">
							<ngx-skeleton-loader
								[theme]="{ height: '40px', float: 'right', 'margin-bottom': '0px' }"
								animation="progress-dark"
							>
							</ngx-skeleton-loader>
						</div>
						<div class="ui-g-12 ui-md-6 ui-lg-6 mt-3">
							<ngx-skeleton-loader
								[theme]="{ height: '40px', float: 'right', 'margin-bottom': '0px' }"
								animation="progress-dark"
							>
							</ngx-skeleton-loader>
						</div>
						<div class="ui-g-12 mt-3">
							<ngx-skeleton-loader
								[theme]="{ height: '100px', float: 'right', 'margin-bottom': '0px' }"
								animation="progress-dark"
							>
							</ngx-skeleton-loader>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
	<div [hidden]="!tabDiligentAllowance">
		<div class="ui-g-12 px-4 vertical-center" style="padding-bottom: 100px">
			<div class="layout-width-70 d-grid gap-16" style="gap: 8px">
				<div class="d-flex pb-2">
					<label class="text-color-12 text-header-5">{{ "wel006_diligence_allowance" | translate }}</label>
				</div>

				<!-- รูปแบบการให้เบี้ยขยัน -->
				<div class="ui-g-12 d-flex pb-2 px-0">
					<div class="d-flex ui-g-6 p-0" style="align-items: center">
						<label class="title-input"> {{ "wel006_allowance_pay_rate" | translate }} </label>
						<einfo-tooltip
							class="pl-2 d-flex"
							key="{{ 'wel006_allowance_pay_rate_tooltip' | translate }}"
							class="d-flex"
							size="s"
						>
						</einfo-tooltip>
					</div>
					<div class="ui-g-6 p-0" style="text-align: end">
						<p-selectButton
							data-testid="input_select_btn_diligence_allowance"
							[options]="diligentAllowanceType"
							[(ngModel)]="diligenceAllowanceModel.isStack"
							(onChange)="onChangeDiligentAllowanceType()"
						>
							<ng-template let-item>
								<div style="padding: 0.2em 1rem">
									{{ item.text | translate }}
								</div>
							</ng-template>
						</p-selectButton>
					</div>
				</div>
				<!-- ได้รับเบี้ยขยันเป็นเงิน -->
				<div
					[hidden]="!(diligenceAllowanceModel.isStack == fixed)"
					class="ui-g-12 d-flex pb-2 px-0"
					style="justify-content: space-between"
				>
					<div class="ui-g-8 p-0 py-1 d-flex" style="align-items: center">
						<label for="input" class="title-input">{{ "wel006_amount_of_allowance" | translate }}</label>
					</div>
					<div class="ui-g-4 p-0 d-flex input-dropdown-split">
						<div class="ui-g-7 p-0">
							<go5-textfield
								data-testid="input_currency_diligence_amount"
								type="currency"
								[(ngModel)]="diligenceAllowanceModel.diligenceAmount"
							></go5-textfield>
						</div>
						<div class="ui-g-5 p-0">
							<go5-dropdown-selection
								data-testid="input_dropdown_diligence_allowance_paymenttype"
								[dataSource]="amountAllowanceDDL"
								[value]="diligenceAllowanceModel.paymentType"
								[allowFiltering]="false"
								[allowShowRemove]="false"
								(selected)="selectPaymentType($event.value)"
								[fields]="dataShare.getIsTH(dataShare.currentLanguage | async) ? fieldsDDL : fieldsDDL_EN"
								mode="single"
								popupType="popup"
								[placeholder]="'common_default_select' | translate"
							>
							</go5-dropdown-selection>
						</div>
					</div>
				</div>
				<div
					[hidden]="!(diligenceAllowanceModel.isStack == progressive)"
					class="ui-g-12 leave-condition-detail-box px-0 mb-3"
				>
					<div class="ui-g-12 pt-0 pb-1 mb-2 border-bottom-gray">
						<div class="ui-g-12 pr-3 d-flex" style="justify-content: flex-end">
							<div class="pt-1">
								<label class="title-input"> {{ "wel006_daily_rate" | translate }} </label>
							</div>

							<div>
								<go5-toggle-switch
									data-testid="input_switch_daily_rate"
									category="secondary"
									[checked]="isDaily"
									(valueChange)="selectPaymentType($event.checked ? daily : monthly)"
								></go5-toggle-switch>
							</div>
						</div>
					</div>
					@for (item of diligenceAllowanceModel.diligenceAllowanceDetails; track item; let i = $index) {
						<div class="leave-condition-detail-content fadeIn">
							<div
								class="d-flex px-3"
								style="width: 100%; align-items: center; align-items: flex-start; flex-direction: column"
							>
								<span style="margin-bottom: 6px">
									<label for="input" class="title-input">{{ "wel006_consecutive_receiving" | translate }}</label>
								</span>
								<p-spinner
									attr.data-testid="input_minimum_ageof_work_{{ i }}"
									#spinnerConsecutiveReceiving
									size="100"
									min="0"
									[(ngModel)]="item.minimumAgeOfWork"
									formatInput="true"
									(onBlur)="onChangeConsecutiveReceiving(spinnerConsecutiveReceiving, item)"
								>
								</p-spinner>
							</div>
							<div
								class="d-flex px-3"
								style="width: 100%; align-items: center; align-items: flex-start; flex-direction: column"
							>
								<span style="margin-bottom: 6px">
									<label for="input" class="title-input">{{ "wel006_to" | translate }}</label>
								</span>
								<p-spinner
									attr.data-testid="input_maximum_ageof_work_{{ i }}"
									#spinnerConsecutiveReceivingTo
									size="100"
									min="0"
									[(ngModel)]="item.maximumAgeOfWork"
									formatInput="true"
									(onBlur)="onChangeConsecutiveReceivingTo(spinnerConsecutiveReceivingTo, item)"
								>
								</p-spinner>
							</div>
							<div
								class="d-flex px-3"
								style="width: 70%; align-items: center; align-items: flex-start; flex-direction: column"
							>
								<go5-textfield
									attr.data-testid="input_currency_allowance_given_amount_{{ i }}"
									type="currency"
									[format]="'0.2-2'"
									[(ngModel)]="item.amount"
									[label]="
										isDaily
											? ('wel006_daily_allowance_given' | translate)
											: ('wel006_monthly_allowance_given' | translate)
									"
									currencySymbol="฿"
								>
								</go5-textfield>
							</div>
							<span
								[ngStyle]="{ visibility: i > 0 ? '' : 'hidden' }"
								style="display: flex; align-items: flex-end; margin-bottom: 10px"
							>
								<em class="gf-icon-empeo-bin-vector pointer" (click)="deleteCondition(item.index)"></em>
							</span>
						</div>
					}
					<div class="pl-3 pt-2">
						<go5-button
							data-testid="button_button_save"
							label="{{ 'common_add' | translate }}"
							iconClass="gf-icon-add"
							category="text"
							size="medium"
							type="button"
							(click)="addCondition()"
						></go5-button>
					</div>
				</div>

				<!-- มีโบนัสเบี้ยขยัน -->
				<div class="ui-g-12 d-flex pb-0 px-0" style="justify-content: space-between">
					<div class="d-flex ui-g-6 px-0" style="align-items: center">
						<label class="title-input"> {{ "wel006_allowance_bonus" | translate }} </label>
						<einfo-tooltip
							class="pl-2 d-flex"
							key="{{ 'wel006_allowance_bonus_tooltip' | translate }}"
							class="d-flex"
							size="s"
						>
						</einfo-tooltip>
					</div>

					<go5-toggle-switch
						data-testid="input_switch_allowance_bonus"
						category="secondary"
						[checked]="diligenceAllowanceModel.isBonus"
						(valueChange)="diligenceAllowanceModel.isBonus = $event.checked"
					></go5-toggle-switch>
				</div>
				<div
					[hidden]="!diligenceAllowanceModel.isBonus"
					class="ui-g-12 d-flex py-2 px-0 mb-3"
					style="justify-content: space-between"
				>
					<!-- เมื่อได้รับเบี้ยขยันติดต่อกันทุกๆ -->
					<div class="p-0 ui-g-12 ui-mg-6 ui-lg-6 pr-3">
						<div style="margin-bottom: 6px">
							<label for="input" class="title-input">{{
								"wel006_receiving_allowance_consecutively" | translate
							}}</label>
						</div>
						<p-spinner
							data-testid="input_receiving_allowance_consecutively"
							#spinnerRceivingAlowanceCnsecutively
							size="100"
							min="0"
							class="input-spinner-text-left"
							[(ngModel)]="diligenceAllowanceModel.bonusMonth"
							formatInput="true"
							(onBlur)="onChangeReceivingAllowanceConsecutively()"
						>
						</p-spinner>
					</div>
					<!-- จะได้รับโบนัส* -->
					<div class="p-0 ui-g-12 ui-mg-6 ui-lg-6 pl-3">
						<go5-textfield
							data-testid="input_text_name"
							type="currency"
							[format]="'0.2-2'"
							[(ngModel)]="diligenceAllowanceModel.bonusAmount"
							currencySymbol="฿"
							[label]="'wel006_bonus_given' | translate"
						>
						</go5-textfield>
					</div>
				</div>

				<div class="ui-g-12 d-flex pb-2 pt-3 mt-4 px-0">
					<label class="text-color-12 text-header-5">{{ "wel006_conditions_receiving" | translate }}</label>
				</div>

				<!-- เมื่อมาสายไม่เกิน -->
				@if (!isDaily) {
					<div class="ui-g-12 d-flex p-0 py-2" style="justify-content: space-between">
						<div class="d-flex ui-g-6 p-0" style="align-items: center">
							<label class="title-input"> {{ "wel006_late_status_allowed" | translate }} </label>
							<einfo-tooltip
								class="pl-2 d-flex"
								key="{{ 'wel006_late_status_allowed_tooltip' | translate }}"
								class="d-flex"
								size="s"
							>
							</einfo-tooltip>
						</div>
						<div class="ui-g-4 p-0">
							<go5-textfield
								data-testid="input_number_late_status_allowed"
								type="number"
								[(ngModel)]="diligenceAllowanceModel.limitDayOfLate"
								[unit]="
									diligenceAllowanceModel.limitDayOfLate > 1
										? ('common_times' | translate)
										: ('common_time' | translate)
								"
							>
							</go5-textfield>
						</div>
					</div>
				}
				<!-- เมื่อขาดงานไม่เกิน -->
				@if (!isDaily) {
					<div class="ui-g-12 d-flex p-0 py-2" style="justify-content: space-between">
						<div class="d-flex ui-g-6 p-0" style="align-items: center">
							<label class="title-input"> {{ "wel006_absent_status_allowed" | translate }} </label>
							<einfo-tooltip
								class="pl-2 d-flex"
								key="{{ 'wel006_absent_status_allowed_tooltip' | translate }}"
								class="d-flex"
								size="s"
							>
							</einfo-tooltip>
						</div>
						<div class="ui-g-4 p-0">
							<go5-textfield
								data-testid="input_number_absent_status_allowed"
								type="number"
								[(ngModel)]="diligenceAllowanceModel.limitDayOfAbsence"
								[unit]="
									diligenceAllowanceModel.limitDayOfAbsence > 1
										? ('common_times' | translate)
										: ('common_time' | translate)
								"
							>
							</go5-textfield>
						</div>
					</div>
				}
				<div class="ui-g-12 d-flex px-0 pt-3" style="justify-content: space-between">
					<div class="d-flex ui-g-8 p-0" style="align-items: center">
						<label class="title-input"> {{ "wel006_shift_time_cal" | translate }} </label>
						<einfo-tooltip
							class="pl-2 d-flex"
							key="{{ 'wel006_shift_time_cal_tooltip' | translate }}"
							class="d-flex"
							size="s"
						>
						</einfo-tooltip>
					</div>

					<go5-toggle-switch
						data-testid="input_switch_allowance_bonus"
						category="secondary"
						[checked]="diligenceAllowanceModel.isShiftTimeCal"
						(valueChange)="diligenceAllowanceModel.isShiftTimeCal = $event.checked"
					></go5-toggle-switch>
				</div>
				<div class="ui-g-12 p-0 py-2" [hidden]="!diligenceAllowanceModel.isShiftTimeCal">
					<div class="d-flex ui-g-12 p-0">
						<div class="ui-g-12 ui-md-6 ui-lg-6 p-0 pr-3">
							<span style="display: block; padding-bottom: 7px" class="text-color-8">
								{{ "wel006_minute_stamp_in_before_break" | translate }}*
							</span>
							<span style="margin-left: auto; width: 250px">
								<go5-textfield
									data-testid="input_number_minuteStampInBefore"
									type="number"
									[(ngModel)]="diligenceAllowanceModel.minuteStampInBefore"
									[unit]="
										diligenceAllowanceModel.minuteStampInBefore > 1
											? ('common_minutes' | translate)
											: ('common_minute' | translate)
									"
								>
								</go5-textfield>
							</span>
							<go5-error-message
								[error]="isValid && diligenceAllowanceModel.minuteStampInBefore == null"
								[errorMessage]="'common_required' | translate"
							>
							</go5-error-message>
						</div>
						<div class="ui-g-12 ui-md-6 ui-lg-6 p-0 pl-3">
							<span style="display: block; padding-bottom: 7px" class="text-color-8">
								{{ "wel006_minute_stamp_out_before_break" | translate }}*
							</span>
							<span style="margin-left: auto; width: 250px">
								<go5-textfield
									data-testid="input_number_minuteStampOutAfter"
									type="number"
									[(ngModel)]="diligenceAllowanceModel.minuteStampOutAfter"
									[unit]="
										diligenceAllowanceModel.minuteStampOutAfter > 1
											? ('common_minutes' | translate)
											: ('common_minute' | translate)
									"
								>
								</go5-textfield>
							</span>

							<go5-error-message
								[error]="isValid && diligenceAllowanceModel.minuteStampOutAfter == null"
								[errorMessage]="'common_required' | translate"
							>
							</go5-error-message>
						</div>
					</div>
				</div>

				<!-- ไม่ได้เบี้ยขยัน หากได้รับจดหมายเตือน -->
				<div class="ui-g-12 d-flex px-0 pt-3" style="justify-content: space-between">
					<div class="d-flex ui-g-6 p-0" style="align-items: center">
						<label class="title-input"> {{ "wel006_skip_receive_warning_letter" | translate }} </label>
						<einfo-tooltip
							class="pl-2 d-flex"
							key="{{ 'wel006_skip_receive_warning_letter_tooltip' | translate }}"
							class="d-flex"
							size="s"
						>
						</einfo-tooltip>
					</div>

					<go5-toggle-switch
						data-testid="input_switch_allowance_bonus"
						category="secondary"
						[checked]="diligenceAllowanceModel.isPunishEffect"
						(valueChange)="diligenceAllowanceModel.isPunishEffect = $event.checked"
					></go5-toggle-switch>
				</div>

				@if (permissionFinancial?.allowGrant) {
					<div class="ui-g-12 d-flex pb-2 px-0">
						<go5-button
							label="{{ 'wel006_setting_diligent_income' | translate }}"
							category="secondary"
							margin="0"
							iconClass="gf-icon-venio-menu-admin1"
							size="medium"
							type="button"
							(click)="onSidebarFinancial(true)"
						></go5-button>
					</div>
				}
			</div>
		</div>
	</div>
	@if (isLoad) {
		<div class="ui-g-12 p-0" style="height: 75vh"></div>
	}
</div>

<div class="ui-g-12 footer-layout text-right" style="z-index: 1">
	<div style="display: flex; justify-content: space-between">
		<div class="ui-g-12 p-0 d-flex" style="margin-left: 70px; align-items: center">
			<label for="input" class="title-input d-flex pr-3" style="align-items: center"
				>{{ isManageWFH ? ("common_usage_status" | translate) : ("wel006_enable_welfare" | translate) }}
			</label>
			<go5-toggle-switch
				data-testid="input_switch_isactive"
				category="secondary"
				[checked]="welfareObj.isActive"
				(valueChange)="welfareObj.isActive = $event.checked"
			></go5-toggle-switch>
		</div>
		<div class="ui-g-12 p-0">
			<go5-button
				data-testid="button_button_back"
				class="pr-2"
				label="{{ tabDiligentAllowance ? ('common_back' | translate) : ('common_cancel' | translate) }}"
				category="secondary"
				size="medium"
				type="button"
				(click)="back()"
			></go5-button>
			<go5-button
				data-testid="button_button_save"
				label="{{
					tabDiligentAllowance || isManageWFH
						? ('common_save' | translate)
						: welfareId != 0
							? ('wel006_edit_welfare' | translate)
							: ('wel006_create_welfare' | translate)
				}}"
				category="primary"
				size="medium"
				type="button"
				(click)="save()"
			>
			</go5-button>
		</div>
	</div>
</div>

@if (displayEmployee) {
	<empeo-people-picker
		[isOpen]="displayEmployee"
		[isShowSelect]="isSelectShow"
		[lockFilter]="lockFilter"
		(closePeoplePicker)="sidebarEmployee(false)"
		[specialType]="1"
		titleName="common_add_employee"
		btnSubmit="common_save"
		[(ngModel)]="employeeList"
		(ngModelChange)="checkboxIndividual()"
		[companyId]="welfareCompanyId"
	></empeo-people-picker>
}

@if (welfareType == WelfareTypeEnum.extraAllowance || welfareType == WelfareTypeEnum.others) {
	<empeo-people-picker
		[isOpen]="displayEmployee2"
		[isShowSelect]="isSelectShow"
		[lockFilter]="lockFilter"
		(onChange)="mapEmployee(false)"
		(closePeoplePicker)="displayEmployee2 = false"
		[specialType]="1"
		titleName="common_add_employee"
		btnSubmit="common_save"
		[(ngModel)]="employeeList2"
		[companyId]="welfareCompanyId"
	></empeo-people-picker>
}

@if (displayEmployeeResponsiblePerson) {
	<empeo-people-picker
		[isOpen]="displayEmployeeResponsiblePerson"
		[lockFilter]="lockFilter"
		(closePeoplePicker)="sidebarEmployeeResponsiblePerson(false)"
		(onChange)="mapDataResponsiblePersonList()"
		[specialType]="1"
		titleName="common_add_employee"
		btnSubmit="common_save"
		[(ngModel)]="responsiblePersonList"
		[companyId]="welfareCompanyId"
	></empeo-people-picker>
}

@if (isDialog) {
	<financial-sidebar
		[companyId]="welfareCompanyId"
		[permission]="permission"
		[hasWelfare]="true"
		[indexTabView]="FinancialItemType.addition"
		[financialItemId]="financialItemId"
		(isClose)="onSidebarFinancial(false)"
	></financial-sidebar>
}

@if (isDialogAdd) {
	<financial-sidebar
		[companyId]="welfareCompanyId"
		[permission]="permission"
		[hasWelfare]="true"
		[financialName]="filterFinancialItemName"
		[indexTabView]="welfareObj.financialType"
		[financialItemId]="welfareObj.financialItemId"
		(submit)="submitAddFinancialItem($event)"
		(isClose)="isDialogAdd = false"
	></financial-sidebar>
}

<ng-template #allowanceFromAttendance>
	<div class="input-layout-switch">
		<span>
			<label for="input" class="title-input">{{ "wel006_only_normal_status" | translate }}</label>
		</span>
		<go5-toggle-switch
			data-testid="input_switch_ispermanent"
			category="secondary"
			[checked]="welfareObj.isAllowanceFromAttendance"
			(valueChange)="welfareObj.isAllowanceFromAttendance = $event.checked"
		></go5-toggle-switch>
	</div>
	@if (welfareObj.isAllowanceFromAttendance) {
		<div class="ui-g-12 d-flex gap-16 p-0 pb-3">
			<div style="width: calc(50% - 8px)">
				<span class="d-flex pb-2" style="align-items: center">
					<label for="input" class="title-input">{{ "wel006_only_normal_status_before" | translate }}</label>
					<einfo-tooltip class="d-flex" key="{{ 'wel006_only_normal_status_before_tooltip' | translate }}" size="s">
					</einfo-tooltip>
				</span>
				<go5-textfield
					data-testid="input_number_minutestampinbefore"
					type="number"
					category="primary"
					[min]="0"
					[placeholder]="'0 ' + ((welfareObj.minuteStampInBefore > 1 ? 'common_minutes' : 'common_minute') | translate)"
					[unit]="(welfareObj.minuteStampInBefore > 1 ? 'common_minutes' : 'common_minute') | translate"
					[value]="welfareObj.minuteStampInBefore"
					(changed)="welfareObj.minuteStampInBefore = $event.value == null ? 0 : $event.value"
				></go5-textfield>
			</div>
			<div style="width: calc(50% - 8px)">
				<span class="d-flex pb-2" style="align-items: center">
					<label for="input" class="title-input">{{ "wel006_only_normal_status_after" | translate }}</label>
					<einfo-tooltip class="d-flex" key="{{ 'wel006_only_normal_status_after_tooltip' | translate }}" size="s">
					</einfo-tooltip>
				</span>
				<go5-textfield
					data-testid="input_number_minutestampinafter"
					type="number"
					category="primary"
					[min]="0"
					[placeholder]="'0 ' + ((welfareObj.minuteStampInBefore > 1 ? 'common_minutes' : 'common_minute') | translate)"
					[unit]="(welfareObj.minuteStampOutAfter > 1 ? 'common_minutes' : 'common_minute') | translate"
					[value]="welfareObj.minuteStampOutAfter"
					(changed)="welfareObj.minuteStampOutAfter = $event.value == null ? 0 : $event.value"
				></go5-textfield>
			</div>
		</div>
	}
</ng-template>

@if (displayEmployeePaidVariablePerIndividual) {
	<empeo-people-picker
		[isOpen]="displayEmployeePaidVariablePerIndividual"
		[lockFilter]="lockFilter"
		(closePeoplePicker)="sidebarEmployeePaidVariablePerIndividual(false)"
		(onChange)="mapDataEmployeePaidVariablePerIndividualList()"
		[specialType]="1"
		titleName="common_add_employee"
		btnSubmit="common_save"
		[(ngModel)]="employeePaidVariablePerIndividualTempList"
	></empeo-people-picker>
}

<ng-template #PaidVariablePerIndividualContent>
	<div class="d-flex d-flex-center d-flex-between">
		<span>
			<label class="go5-text-title-strong go5-text-color-12">{{ "common_contact" | translate }}</label>
		</span>
		<go5-button
			data-testid="button_button_add_employee_paid_variable_per_individual"
			label="{{ 'common_add_employee' | translate }}"
			iconClass="gf-icon-add"
			padding="0"
			margin="0"
			category="text"
			size="medium"
			type="button"
			(click)="sidebarEmployeePaidVariablePerIndividual(true)"
		></go5-button>
	</div>
	<div class="w-100" style="min-height: 200px">
		<form [formGroup]="formEmployeePaidVariablePerIndividual">
			<table class="hover dataTable dataTables-parti-coloured" style="width: 100%; border: 0">
				<thead>
					<tr>
						<th id="emp_employee" class="th-sm-1" style="width: 65%">
							<label for="input">{{ "emp_employee" | translate }}</label>
						</th>
						<th id="common_amount" class="th-sm-1 text-right" style="width: 25%">
							<label for="input">{{ "common_amount" | translate }}</label>
						</th>
					</tr>
				</thead>
				<tbody formArrayName="items">
					@for (item of employeePaidVariablePerIndividualList; track item; let i = $index) {
						<tr [formGroupName]="i" class="opacity-table">
							<td class="center" style="max-width: 100px">
								<go5-avatar-information
									[src]="item.pictureURL"
									title="{{
										dataShare.getIsTH(dataShare.currentLanguage | async)
											? item.fullName
											: item.fullName_EN || item.fullName
									}}"
									detail="{{
										dataShare.getIsTH(dataShare.currentLanguage | async)
											? item.positionName
											: item.positionNameEN || item.positionName
									}}"
									[isLazy]="false"
								>
								</go5-avatar-information>
							</td>
							<td class="text-right">
								<go5-textfield
									attr.data-testid="input_number_amount_{{ i }}"
									type="currency"
									category="primary"
									format="0.2-2"
									placeholder="0.00"
									[min]="1"
									formControlName="amount"
									[step]="1"
									[(ngModel)]="item.amount"
									[error]="getValidate(i)?.invalid && getValidate(i)?.dirty && isValidForm"
								></go5-textfield>
							</td>
						</tr>
					}
					@if (employeePaidVariablePerIndividualList?.length > 0) {
						<tr>
							<td class="go5-text-body-strong text-left go5-text-color-11">
								{{ "common_total_amount" | translate }}
							</td>
							<td colspan="2" class="text-right">
								<label class="go5-text-color-11">฿{{ sumAmountPaidVariablePerIndividual() | number: "0.2-2" }} </label>
							</td>
						</tr>
					}
					@if (employeePaidVariablePerIndividualList == null || employeePaidVariablePerIndividualList?.length == 0) {
						<tr>
							<td colspan="3" class="go5-text-color-11">{{ "common_empty_state_no_employee" | translate }}</td>
						</tr>
					}
				</tbody>
			</table>
		</form>
		<go5-error-message
			[error]="
				isValidForm &&
				(employeePaidVariablePerIndividualList == null || employeePaidVariablePerIndividualList?.length == 0)
			"
			[errorMessage]="'common_validate_please_choose' | translate: { fieldName: ('common_employee_list' | translate) }"
		>
		</go5-error-message>
	</div>
</ng-template>
