export enum Gender {
	male = 1,
	female = 2,
	other = 3,
}

export enum Title {
	mr = 2, // นาย
	ms = 3, // น.ส.
	mrs = 4, // นาง
}

export enum GenderEmconnect {
	male = 5001,
	female = 5002,
	other = 5003,
}
