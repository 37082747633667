import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage, ValidationHandler } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { authConfig } from "./auth-config";
import { AuthGuardService } from "./auth-guard.service";
import { authModuleConfig } from "./auth-module-config";
import { AuthService } from "./auth.service";

@NgModule({ declarations: [], imports: [OAuthModule.forRoot(authModuleConfig, JwksValidationHandler)], providers: [AuthService, AuthGuardService, provideHttpClient(withInterceptorsFromDi())] })
export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				{ provide: AuthConfig, useValue: authConfig },
				{ provide: OAuthModuleConfig, useValue: authModuleConfig },
				{ provide: ValidationHandler, useClass: JwksValidationHandler },
				{ provide: OAuthStorage, useValue: sessionStorage },
			],
		};
	}

	constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
		if (parentModule) {
			throw new Error("AuthModule has already been loaded. Import Core modules in the AppModule only.");
		}
	}
}
