export class EmployeeAccumulates {
	accumulateId: number = 0;
	employeeId: number;
	year: number;
	initialAmount: number = 0;
	calculateAmount: number;
	initialAmountForTax: number = 0;
	createdBy: string;
	dateCreated: Date;
	modifiedBy: string;
	dateModified: Date;
	name: string;
	name_EN: string;
	username: string;
	dateModified_TH: string;
	dateModified_EN: string;
}
