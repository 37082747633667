import {
	ApplicationRef,
	enableProdMode,
	ErrorHandler,
	APP_INITIALIZER,
	APP_ID,
	importProvidersFrom,
} from "@angular/core";
import {
	BrowserProfilingIntegration,
	browserTracingIntegration,
	dedupeIntegration,
	init,
	metrics,
	replayIntegration,
	createErrorHandler,
	TraceService,
} from "@sentry/angular-ivy";

import { HttpLoaderFactory } from "./app/app.module";
import { AppConfig } from "./app/shared/class/config";
import { environment } from "./environments/environment";
import { enableDebugTools, BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { registerLicense } from "@syncfusion/ej2-base";
import { AppComponent } from "./app/app.component";
import { ShareModule } from "./app/share.module";
import { AppRoutes } from "./app/app.routes";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { GoogleMapsModule } from "@angular/google-maps";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { AuthModule } from "./app/auth/auth.module";
import { ServicesModule } from "./app/services.module";
import { GlobalErrorHandler } from "./app/shared/class/global-error-handler";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TokenInterceptor } from "./app/core";
import { HttpBackend, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthCurrentUrlGuardService } from "./app/auth/auth-currentUrl-guard";
import { SubscriptionGuardService } from "./app/shared/service/subscription-guard.service";
import { DevGuardService } from "./app/shared/service/dev-guard.service";
import { AuthGuardService } from "./app/auth/auth-guard.service";
import { provideLottieOptions } from "ngx-lottie";
import { AngularCommonModule, Application, PhraseProject } from "@gofive/angular-common";

export function getBaseUrl() {
	return document.getElementsByTagName("base")[0].href;
}

registerLicense(environment.licenseSysfusion);

fetch(environment.gofiveCoreWeb.baseUrl + "assets/configurations/config.json?v=" + new Date().getTime())
	.then((response) => response.json())
	.then((config) => {
		const tmpVersion = new Date().getTime();
		if (!environment.production) {
			Object.keys(config).forEach((key) => {
				if (config[key].version) {
					config[key].version = tmpVersion;
				}
			});
		}

		AppConfig.config = config;

		if (environment.production) {
			enableProdMode();
			if (environment.sentry_dsn) {
				init({
					release: `${environment.VERSION}`,
					dsn: environment.sentry_dsn,
					integrations: [
						metrics.metricsAggregatorIntegration(),
						dedupeIntegration(),
						replayIntegration(),
						new BrowserProfilingIntegration(),
						browserTracingIntegration({
							enableInp: true,
							tracePropagationTargets: [environment.api_url, /^\//],
						}),
					],
					tracesSampleRate: 1.0,
					replaysSessionSampleRate: 0.5,
				});
			}
		}

		bootstrapApplication(AppComponent, {
			providers: [
				importProvidersFrom(
					ServicesModule,
					AuthModule.forRoot(),
					CommonModule,
					ReactiveFormsModule,
					AngularCommonModule.forRoot({
						apiUrlBase: "",
						phraseProject: PhraseProject.empeo,
						production: environment.production,
						application: Application.Empeo,
						assetsEnvironment: environment.envName,
					}),
					FormsModule,
					BrowserModule,
					GoogleMapsModule,
					TranslateModule.forRoot({
						loader: {
							provide: TranslateLoader,
							useFactory: HttpLoaderFactory,
							deps: [HttpBackend],
						},
						useDefaultLang: true,
						defaultLanguage: "en",
					}),
					AppRoutes,
					ShareModule.forRoot(),
				),
				provideLottieOptions({
					player: () => import("lottie-web"),
				}),
				AuthGuardService,
				DevGuardService,
				SubscriptionGuardService,
				AuthCurrentUrlGuardService,
				{ provide: "API_URL", useValue: environment.apiUrl },
				{ provide: "API_URL_DEFAULT", useValue: environment.apiUrlDefault },
				{ provide: "GOFIVE_DEEPLINK_URL", useValue: environment.gofiveDeeplinkUrl },
				{ provide: "GOFIVE_API_URL", useValue: environment.gofiveApiUrl },
				{ provide: "API_JUNO_URL", useValue: environment.empeoJunoUrl },
				{ provide: "CLIENT_PORTAL", useValue: environment.clientPortal },
				{ provide: "DEFAULT_EMP_PIC", useValue: environment.defaultPicture },
				{ provide: "DEFAULT_IMAGE", useValue: environment.defaultImage },
				{ provide: "VENIO_API_URL", useValue: environment.venioApiUrl },
				{ provide: "VENIO_API4_URL", useValue: environment.venioApi4Url },
				{ provide: "VENIO_API_EXPENSE_URL", useValue: environment.venioApiExpenseUrl },
				{ provide: "EMCONNECT_API_URL", useValue: environment.emconnectAPI },
				{ provide: "EMSUME_API_URL", useValue: environment.emsume.emsumeAPI },
				{ provide: "EMSUME_API_KEY", useValue: environment.emsume.emsumeApiKey },
				{ provide: "GOFIVE_SERVICE_STATUS_API_URL", useValue: environment.gofiveServiceStatusApiUrl },
				{
					provide: "API_URL_LST",
					useValue: [
						environment.apiUrl,
						environment.gofiveApiUrl,
						environment.gofiveDeeplinkUrl,
						environment.clientPortal,
						environment.empeoJunoUrl,
						environment.gofiveServiceStatusApiUrl,
					],
				},
				{ provide: "LEARN_API_URL", useValue: environment.learnAPI },
				{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
				{ provide: MAT_DIALOG_DATA, useValue: {} },
				{
					provide: MatDialogRef,
					useValue: {},
				},
				{
					provide: ErrorHandler,
					useValue: createErrorHandler({
						showDialog: false,
					}),
				},
				{
					provide: TraceService,
					deps: [Router],
				},
				{
					provide: APP_INITIALIZER,
					useFactory: () => () => {},
					deps: [TraceService],
					multi: true,
				},
				{ provide: APP_ID, useValue: "ng-cli-universal" },
				{ provide: "googleTagManagerId", useValue: environment.gtmId },
				{ provide: ErrorHandler, useClass: GlobalErrorHandler },
				provideAnimations(),
			],
		})
			.then((module) => {
				if (!environment.production) {
					const appRef = module.injector.get(ApplicationRef);
					const appComponent = appRef.components[0];
					enableDebugTools(appComponent);
				}
				//how to run use inspect ng.profiler.timeChangeDetection();

				const version = environment.production ? config["gofive-styles-css"].version : new Date().getTime();
				const assesets = ["fonts/gofive/styles.css", "icons/go5-icon/style.css", "icons/goficon/style.css"];
				assesets.forEach((url) => {
					var x = document.createElement("LINK");
					x.setAttribute("rel", "stylesheet");
					x.setAttribute("type", "text/css");
					x.setAttribute("href", environment.gofiveCoreWeb.assetUrl + url + "?v=" + version);
					document.head.appendChild(x);
				});
				document
					.getElementById("micro-front-stylesheet")
					.setAttribute("href", environment.gofiveCoreWeb.baseUrl + "styles/styles.css?v=" + new Date().getTime());
				document
					.getElementById("micro-front-emconnect")
					.setAttribute("href", environment.gofiveCoreWeb.baseUrl + "emconnect/styles.css?v=" + new Date().getTime());
				document
					.getElementById("antd-stylesheet")
					.setAttribute(
						"href",
						environment.gofiveCoreWeb.baseUrl + "emconnect/assets/style/gofive-core-ant.css?v=" + new Date().getTime(),
					);

				var msteams = document.createElement("script");
				msteams.type = "text/javascript";
				msteams.innerText = `microsoftTeams.initialize();
  microsoftTeams.authentication.getAuthToken({ successCallback: (result) => {
    localStorage.setItem('access_token', result);
  }, failureCallback: function (error) {
    console.error("Error getting token: " + error);
  }
});
microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
  microsoftTeams.settings.setSettings({ websiteUrl: '${environment.redirectUri}m-okr', contentUrl: '${environment.redirectUri}m-okr', entityId: "Configure", suggestedDisplayName: "OKR"});
  saveEvent.notifySuccess();
}); `;
				msteams.id = "ms-teams";
				document.getElementsByTagName("head")[0].appendChild(msteams);
			})
			.catch((err) => console.error(err));
	});
