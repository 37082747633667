import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class URLEncoderService {
	private reservedMapping: Map<string, string> = new Map([
		[";", "%3B"],
		["/", "%2F"],
		["?", "%3F"],
		[":", "%3A"],
		["@", "%40"],
		["&", "%26"],
		["=", "%3D"],
		["+", "%2B"],
		["$", "%24"],
		[",", "%2C"],
		["#", "%23"],
		["(", "%28"],
		[")", "%29"],
		["-", "%2D"],
		[".", "%2E"],
		["!", "%21"],
		["~", "%7E"],
		["*", "%2A"],
		["'", "%27"],
		["%", "%25"],
	]);

	encodeURL(url: string): string {
		return encodeURIComponent(
			url.replace(/[\-./!~*'();/?:@&=+$,#]/g, (char) => {
				return this.reservedMapping.get(char) || char;
			}),
		);
	}
}
