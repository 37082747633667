import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeShift } from "./employee-shift.model";
import { CommonService } from "../../../shared/service/common.service";
import { EmployeeShiftInMonth } from "../../shared/employee-shift-in-month.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { ApiResult } from "../../../shared/models/api-result.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeShiftService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getUserShiftByEmployeeIdDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeShift/GetUserShiftByEmployeeIdDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getUserShiftById(userShiftId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeShift/GetUserShiftById/" + userShiftId)).then(
			(res) => <EmployeeShift>res,
		);
	}

	getUserShiftByEmployeeId(employeeId: number, pageSize?: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeShift/GetUserShiftByEmployeeId/" + employeeId + "/" + pageSize),
		).then((res) => <EmployeeShift[]>res);
	}

	addUserShift(shift: EmployeeShift) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(shift);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "EmployeeShift/AddUserShift", body, httpOptions);
	}

	addShiftInMonth(shift: EmployeeShiftInMonth[]) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(shift);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeShift/AddShiftInMonth", body, httpOptions);
	}

	addUserShiftBySupervisor(shift: EmployeeShift) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(shift);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeShift/AddUserShiftBySupervisor", body, httpOptions);
	}

	editUserShift(shift: EmployeeShift) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(shift);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeShift/EditUserShift", body, httpOptions);
	}

	deleteUserShift(userShiftId: number) {
		var body = JSON.stringify(userShiftId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeShift/DeleteUserShift", body, httpOptions);
	}

	importShiftExcel(file: string, employeeId: number, formatDate: string = "d/MM/yyyy") {
		var body = JSON.stringify({ file: file, employeeId: employeeId, dateFormate: formatDate });

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "EmployeeShift/ImportFileShift", body, httpOptions);
	}

	exportShiftExcel(employeeId: number, formatDate: string = "d/MM/yyyy") {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "EmployeeShift/ExportFileShift?employeeId=" + employeeId + "&formatDate=" + formatDate,
			),
		).then((res) => <ApiResult>res);
	}
}
