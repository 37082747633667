import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Subscription, firstValueFrom } from "rxjs";
import { DataSharingService } from "src/app/core";
import { UserInfo } from "src/app/core/shared/user-info.model";
import { MasterTypeEnum } from "../../enum/master-setup.enum";
import { ApiResult } from "../../models/api-result.model";
import { CommonService } from "../../service/common.service";
import { CustomFieldService } from "../../service/custom-field.service";
import { ToastHelperService } from "../../service/toast-helper.service";
import { ButtonModule } from "@gofive/design-system-button";
import { BaseModule } from "@gofive/design-system-base";
import { SelectionModule } from "@gofive/design-system-selection";
import { InfoTooltipComponent } from "../../template/info-tooltip/info-tooltip.component";
import { InputModule } from "@gofive/design-system-input";
import { NgTemplateOutlet, NgStyle, AsyncPipe } from "@angular/common";
import { SidebarModule } from "@gofive/design-system-navigation";

export enum CustomFieldsType {
	Text = 20001,
	SingleSelection = 20002,
	MultiSelection = 20003,
	DropdownList = 20004,
	DatePicker = 20005,
	Paragraph = 20006,
}

export enum CustomFieldsTextBoxType {
	None = 1,
	Letter = 2,
	Number = 3,
	Email = 4,
	Custom = 5,
}

@Component({
	selector: "app-sidebar-custom-field",
	templateUrl: "./sidebar-custom-field.component.html",
	styleUrls: ["./sidebar-custom-field.component.scss"],
	standalone: true,
	imports: [
		SidebarModule,
		NgTemplateOutlet,
		FormsModule,
		ReactiveFormsModule,
		InputModule,
		InfoTooltipComponent,
		NgStyle,
		SelectionModule,
		BaseModule,
		ButtonModule,
		AsyncPipe,
		TranslateModule,
	],
})
export class SidebarCustomFieldComponent implements OnInit, OnChanges, OnDestroy {
	@Output() closeSidebar = new EventEmitter<any>();
	@Input() visible: boolean = true;
	@Input() fieldId: number = 0;
	public form: UntypedFormGroup;

	private $userInfo: Subscription;
	public userInfo: UserInfo;

	constructor(
		private formBuilder: UntypedFormBuilder,
		public dataSharing: DataSharingService,
		private commonService: CommonService,
		private _toastHelperService: ToastHelperService,
		private customFieldService: CustomFieldService,
		private translateService: TranslateService,
	) {
		this.$userInfo = this.dataSharing.currentUserInfo.subscribe((user) => {
			this.userInfo = user;
		});
		this.form = this.formBuilder.group({
			name: new UntypedFormControl("", [Validators.required, this.noEmptyStringValidator]),
			isRequire: new UntypedFormControl(false, Validators.required),
			fieldTypeId: new UntypedFormControl(null, Validators.required),
			textBoxType: new UntypedFormControl(CustomFieldsTextBoxType.None),
			regularExpression: new UntypedFormControl(""),
			masAdditionalFieldDetails: new UntypedFormArray([]),
			textDropdown: new UntypedFormControl(""),
		});
	}

	noEmptyStringValidator(control: UntypedFormControl) {
		if (control.value?.trim() === "") {
			return { noEmptyString: true };
		}
		return null;
	}

	dataSourceFieldType: any[] = [];

	public saving: boolean = false;
	public emptyContent: boolean = false;

	public duplicateDetail: any[] = [];

	dataSourceFieldValidationType: any[] = [
		{
			name: "employee_field_response_validation_none",
			id: CustomFieldsTextBoxType.None,
		},
		{
			name: "common_letter",
			id: CustomFieldsTextBoxType.Letter,
		},
		{
			name: "common_number",
			id: CustomFieldsTextBoxType.Number,
		},
		{
			name: "common_email",
			id: CustomFieldsTextBoxType.Email,
		},
		{
			name: "common_custom",
			id: CustomFieldsTextBoxType.Custom,
		},
	];

	public fieldType: any = {
		text: "typeName",
		value: "typeId",
		iconClass: "typeValuePreference",
	};

	public fieldTypeEN: any = {
		text: "typeNameEn",
		value: "typeId",
		iconClass: "typeValuePreference",
	};

	public fieldValidationType: any = {
		text: "name",
		value: "id",
	};

	public get customFieldsType() {
		return CustomFieldsType;
	}

	public get customFieldsTextBoxType() {
		return CustomFieldsTextBoxType;
	}

	public isActive: boolean = true;
	public isDuplicate: boolean = false;

	public get isErrorFieldDetails() {
		const formMasAdditionalFieldDetails = this.form.get("masAdditionalFieldDetails") as UntypedFormArray;
		const isSelection =
			this.form.controls["fieldTypeId"].value === CustomFieldsType.SingleSelection ||
			this.form.controls["fieldTypeId"].value === CustomFieldsType.MultiSelection;
		return (
			isSelection &&
			formMasAdditionalFieldDetails.controls.some((field) => field.get("label").touched) &&
			formMasAdditionalFieldDetails.controls.every((field) => !field.get("label").value)
		);
	}

	ngOnInit(): void {
		if (!this.fieldId) {
			this.form.controls["textBoxType"].setValue(CustomFieldsTextBoxType.None);
			this.getMasterType();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.fieldId?.currentValue) {
			this.fieldId = changes.fieldId.currentValue;
			this.getMasterField();
		}
		if (changes.visible) {
			this.visible = changes.visible.currentValue;
		}
	}

	ngOnDestroy(): void {
		this.$userInfo?.unsubscribe();
	}

	getMasterField() {
		firstValueFrom(this.customFieldService.getMasterCustomField(this.fieldId)).then((res) => {
			let masCustom = res.data;
			if (masCustom) {
				this.isActive = masCustom.isActive;
				Object.keys(masCustom)?.forEach((key) => {
					if (this.form.controls[key] instanceof UntypedFormArray) {
						masCustom[key]?.forEach((customField) => {
							let tmpFormGroup = new UntypedFormGroup({});
							Object.keys(customField)?.forEach((customKey) => {
								tmpFormGroup.addControl(customKey, new UntypedFormControl(customField[customKey]));
							});
							(this.form.controls[key] as UntypedFormArray).push(tmpFormGroup);
						});
					} else {
						this.form.controls[key]?.setValue(masCustom[key]);
					}
				});
				if (
					this.form.controls["fieldTypeId"].value == CustomFieldsType.DropdownList &&
					masCustom.masAdditionalFieldDetails.length
				) {
					this.form.controls["textDropdown"].setValue(
						masCustom.masAdditionalFieldDetails.map((m) => m.label).join("\n"),
					);
				}
			}
			this.getMasterType();
		});
	}

	getMasterType() {
		this.commonService
			.getMasterTypeByParentId(MasterTypeEnum.fieldType)
			.then((res) => {
				this.dataSourceFieldType = res?.data?.data;
				if (this.dataSourceFieldType.length && !this.fieldId) {
					this.form.controls["fieldTypeId"].setValue(this.dataSourceFieldType[0][this.fieldType.value]);
				}
				if (this.fieldId) {
					this.dataSourceFieldType = this.dataSourceFieldType
						.filter((f) => f[this.fieldType.value] === this.form.controls["fieldTypeId"].value)
						.map((m) => {
							return {
								...m,
								disabled: true,
							};
						});

					let isSelection =
						this.form.controls["fieldTypeId"].value === CustomFieldsType.SingleSelection ||
						this.form.controls["fieldTypeId"].value === CustomFieldsType.MultiSelection;

					if (
						isSelection &&
						(this.form.controls["masAdditionalFieldDetails"] as UntypedFormArray).controls.length === 0
					) {
						let tmpFormGroup = new UntypedFormGroup({
							label: new UntypedFormControl(""),
						});
						(this.form.controls["masAdditionalFieldDetails"] as UntypedFormArray).push(tmpFormGroup);
					}

					this.onTypeChange(null, this.form.controls["fieldTypeId"].value);
				}
			})
			.catch(() => {
				this.dataSourceFieldType = [];
			});
	}
	public a: number;
	onTypeChange(e?, fieldTypeId?) {
		this.form.controls["fieldTypeId"].setValue(e ? e.selectedItem[this.fieldType.value] : fieldTypeId);

		let isSelection =
			this.form.controls["fieldTypeId"].value === CustomFieldsType.SingleSelection ||
			this.form.controls["fieldTypeId"].value === CustomFieldsType.MultiSelection;

		if (isSelection && (this.form.controls["masAdditionalFieldDetails"] as UntypedFormArray).controls.length === 0) {
			let tmpFormGroup = new UntypedFormGroup({
				label: new UntypedFormControl(""),
			});
			(this.form.controls["masAdditionalFieldDetails"] as UntypedFormArray).push(tmpFormGroup);
		}

		this.form.controls["fieldTypeId"].value === CustomFieldsType.Text
			? this.updateValidatorTextBoxType(this.form.controls["textBoxType"].value)
			: this.updateValidatorTextBoxType(-1);

		this.form.controls["fieldTypeId"].value === CustomFieldsType.DropdownList
			? this.updateValidatorTextDropdown(true)
			: this.updateValidatorTextDropdown(false);
	}

	onValidationTypeChange(e) {
		this.updateValidatorTextBoxType(e.value);
	}

	updateValidatorTextBoxType(textBoxType: number) {
		if (textBoxType === CustomFieldsTextBoxType.Custom) {
			this.form.controls["regularExpression"].setValidators([Validators.required]);
		} else {
			this.form.controls["regularExpression"].clearValidators();
		}
		this.form.controls["regularExpression"].updateValueAndValidity();
	}

	updateValidatorTextDropdown(isSetValidation: boolean = false) {
		if (isSetValidation) {
			this.form.controls["textDropdown"].setValidators([Validators.required]);
		} else {
			this.form.controls["textDropdown"].clearValidators();
		}
		this.form.controls["textDropdown"].updateValueAndValidity();
	}

	onAddOptions() {
		(this.form.controls["masAdditionalFieldDetails"] as UntypedFormArray).push(
			new UntypedFormGroup({
				label: new UntypedFormControl(
					"",
					(this.form.controls["masAdditionalFieldDetails"] as UntypedFormArray).controls.length === 0
						? Validators.required
						: null,
				),
			}),
		);
	}

	onDeleteOptions(index: number) {
		(this.form.controls["masAdditionalFieldDetails"] as UntypedFormArray).removeAt(index);
	}

	onCheckValidation() {
		this.emptyContent = false;
		this.isDuplicate = false;
		if (!this.form.valid || this.isErrorFieldDetails) {
			this.form.markAllAsTouched();
			this._toastHelperService.alertCompleteAllFields();
		} else {
			this.onSave();
		}
	}

	onSave() {
		this.duplicateDetail = [];
		let model = { ...this.form.value, isActive: this.isActive, fieldId: this.fieldId ?? 0 };
		if (model["fieldTypeId"] === CustomFieldsType.DropdownList) {
			model["masAdditionalFieldDetails"] =
				model["textDropdown"]?.split("\n")?.map((m) => {
					return {
						label: m,
					};
				}) || [];
		}
		if (
			this.form.controls["fieldTypeId"].value === CustomFieldsType.SingleSelection ||
			this.form.controls["fieldTypeId"].value === CustomFieldsType.MultiSelection ||
			this.form.controls["fieldTypeId"].value === CustomFieldsType.DropdownList
		) {
			model.masAdditionalFieldDetails = model.masAdditionalFieldDetails?.filter((f) => f.label?.trim()) || [];
			if (model.masAdditionalFieldDetails.length === 0) {
				this._toastHelperService.validation({
					title: this.translateService.instant("toast_title_headsup"),
					description: this.translateService.instant("toast_common_recheck"),
				});
				this.emptyContent = true;
				return;
			}
			let duplicateName = model.masAdditionalFieldDetails?.filter((f, index) =>
				model.masAdditionalFieldDetails.some((s, index2) => s.label === f.label && index !== index2),
			);
			if (duplicateName?.length) {
				this._toastHelperService.validation({
					title: this.translateService.instant("toast_title_headsup"),
					description: this.translateService.instant("toast_common_recheck"),
				});
				this.duplicateDetail = duplicateName;
				return;
			}
		}

		this.saving = true;
		firstValueFrom(this.customFieldService.saveMasterCustomField(model))
			.then((res) => {
				this.saving = false;
				let apiResult = <ApiResult>res.data;
				if (apiResult.isMaximumQuota) {
					this._toastHelperService.validation({
						title: this.translateService.instant("toast_title_headsup"),
						description: this.translateService
							.instant("employee_field_quota_dialog_description")
							.replace("{{i}}", this.userInfo?.maxCustomField),
					});
				} else if (apiResult.isInvalidPackagePro) {
					this._toastHelperService.validation({
						title: this.translateService.instant("toast_title_sorry"),
						description: this.translateService.instant("toast_custom_field_turnoff_pro_description"),
					});
				} else if (apiResult.isDuplicate) {
					this._toastHelperService.validation({
						title: this.translateService.instant("toast_title_headsup"),
						description: this.translateService.instant("employee_field_name_validate_duplicate_toast"),
					});
					this.isDuplicate = true;
				} else if (apiResult.result) {
					this._toastHelperService.success({
						title: this.translateService.instant("toast_title_success"),
						description:
							model.fieldId > 0
								? this.translateService.instant("employee_field_edit_toast_success")
								: this.translateService.instant("employee_field_create_toast_success"),
					});
					this.onCloseSidebar(true);
				} else {
					this._toastHelperService.validation({
						title: this.translateService.instant("common_error"),
						description: apiResult.message,
					});
				}
			})
			.catch(() => {
				this.saving = false;
			});
	}

	onCloseSidebar(isReload: boolean = false) {
		this.closeSidebar.emit({
			isReload: isReload,
		});
	}

	checkDuplicateOption(label: string, modelChecked: any[]): boolean {
		return modelChecked.some((s) => s.label === label);
	}
}
