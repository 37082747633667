import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Checkbox } from "../../../shared/models/checkbox.model";
import { CommonService } from "../../../shared/service/common.service";
import { ApprovalSearchModel } from "../../approval/shared/approval.model";
import {
	DocumentForm,
	DocumentFormGroupMonth,
	DocumentFormLog,
	DocumentFormSummaryModel,
	DocumentFormType,
	SearchDocumentFormModel,
} from "./document-form.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class DocumentFormService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getMyDocumentForms(doc: SearchDocumentFormModel) {
		var body = JSON.stringify(doc);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post<DocumentFormGroupMonth[]>(this.apiUrl + "DocumentForm/GetMyDocumentForms", body, httpOptions),
		);
	}

	getDocumentFormHistoryDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "DocumentForm/GetDocumentFormHistoryDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getDocumentFormForApprove(
		employeeId?: number,
		documentCategoryId?: number,
		approvalSearchModel?: ApprovalSearchModel,
	) {
		return firstValueFrom(
			this.http.post(
				this.apiUrl + "DocumentForm/GetDocumentFormForApprove/" + employeeId + "/" + documentCategoryId,
				approvalSearchModel,
			),
		).then((res) => <DocumentForm[]>res);
	}

	getDocumentFormById(documentFormId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "DocumentForm/GetDocumentFormById/" + documentFormId)).then(
			(res) => <DocumentForm>res,
		);
	}

	getDocumentFormToSummary(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "DocumentForm/GetDocumentFormToSummary",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	exportDocumentFormToSummaryToExcel(searchDocumentFormSummary: DocumentFormSummaryModel) {
		var body = JSON.stringify(searchDocumentFormSummary);
		return this.http.post(this.apiUrl + "DocumentForm/exportDocumentFormToSummaryToExcel", body, this.httpOptions);
	}

	getProcessActionByDocumentFormId(documentFormId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "DocumentForm/GetProcessActionByDocumentFormId/" + documentFormId),
		).then((res) => <Checkbox[]>res);
	}

	getNotificationDocumentLogByEmployeeId(employeeId: number, documentFormId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "DocumentForm/GetNotificationDocumentLogByEmployeeId/" + employeeId + "/" + documentFormId,
			),
		).then((res) => <any[]>res);
	}

	getDocumentFormMasterById(documentFormId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "DocumentForm/GetDocumentFormMasterById/" + documentFormId)).then(
			(res) => <DocumentForm>res,
		);
	}

	getDocumentFormTypeByCompanyId(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "DocumentForm/GetDocumentFormTypeByCompanyId/" + companyId)).then(
			(res) => <DocumentFormType[]>res,
		);
	}

	getDocumentFormTypeByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "DocumentForm/GetDocumentFormTypeByEmployeeId/" + employeeId),
		).then((res) => <DocumentFormType[]>res);
	}
	getUrlDocumentForm(documentFormId: number, documentCategory: number = 0, empId: number = 0) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "DocumentForm/GetUrlDocumentForm/" + documentFormId + "/" + empId + "/" + documentCategory,
			),
		).then((res) => <DocumentForm>res);
	}
	getExampleDocumentForm(categoryId: number, companyId: number, model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(
				this.apiUrl + `DocumentForm/ExampleDocumentForm/?categoryId=${categoryId || 0}&companyId=${companyId || 0}`,
				body,
				httpOptions,
			),
		).then((res) => <any>res);
	}
	getDocumentFormTypes() {
		return firstValueFrom(this.http.get(this.apiUrl + "DocumentForm/GetDocumentFormTypes/")).then(
			(res) => <DocumentFormType[]>res,
		);
	}

	addProcessActionDocumentForm(doc: DocumentForm) {
		var body = JSON.stringify(doc);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "DocumentForm/AddProcessActionDocumentForm/", body, httpOptions);
	}

	addProcessDocumentFormBySelected(documentFormId: string[], processActionId: number, reason: string) {
		var body = JSON.stringify({ documentFormId: documentFormId, processActionId: processActionId, reason: reason });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "DocumentForm/AddProcessDocumentFormBySelected/", body, httpOptions);
	}

	addDocumentForm(doc: DocumentForm) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(doc);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "DocumentForm/AddDocumentForm", body, httpOptions);
	}

	editDocumentForm(doc: DocumentForm) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(doc);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "DocumentForm/EditDocumentForm", body, httpOptions);
	}
	getDocumentFormLogByDocumentFormId(documentFormId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "DocumentForm/GetDocumentFormLogByDocumentFormId/" + documentFormId),
		).then((res) => <DocumentFormLog[]>res);
	}
	getDocumentFromByEmployeeIdAndCategoryId(empId: number, categoryId: number, documentTypeId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"DocumentForm/GetDocumentFromByEmployeeIdAndCategoryId/" +
					empId +
					"/" +
					categoryId +
					"/" +
					documentTypeId,
			),
		).then((res) => <DocumentForm>res);
	}

	getDocumentFormByDocumentFormId(documentFormId, employeeId) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}DocumentForm/GetDocumentFormByDocumentFormId/${documentFormId}/${employeeId}`),
		).then((r) => <any>r);
	}
	getDocumentFormContentReplace(employeeId: number, categoryId: number, isPreview: boolean = false) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}document-form/replace/${employeeId}/${categoryId}/${isPreview}`),
		).then((r) => <any>r);
	}
}
