import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeChildren } from "./employee-children.model";
import { EmployeeFamily } from "./employee-family.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeFamilyService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeFamilyById(employeeFamilyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeFamily/GetEmployeeFamilyById/" + employeeFamilyId)).then(
			(res) => <EmployeeFamily>res,
		);
	}

	getEmployeeFamilyByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeFamily/GetEmployeeFamilyByEmployeeId/" + employeeId),
		).then((res) => <EmployeeFamily[]>res);
	}
	getFamilyByEmployeeIdAndRelationShipId(employeeId: number, relationShipId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "EmployeeFamily/GetFamilyByEmployeeIdAndRelationShipId/" + employeeId + "/" + relationShipId,
			),
		).then((res) => <EmployeeFamily>res);
	}

	getEmployeeChildrenById(employeeChildrenId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeChildren/GetEmployeeChildrenById/" + employeeChildrenId),
		).then((res) => <EmployeeFamily>res);
	}

	getEmployeeChildrenByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeChildren/GetEmployeeChildrenByEmployeeId/" + employeeId),
		).then((res) => <EmployeeFamily[]>res);
	}

	addEmployeeFamily(emp: EmployeeFamily) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFamily/AddEmployeeFamily", body, httpOptions);
	}

	editEmployeeFamily(emp: EmployeeFamily) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFamily/EditEmployeeFamily", body, httpOptions);
	}

	deleteEmployeeFamily(employeeFamilyId: number) {
		var body = JSON.stringify(employeeFamilyId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeFamily/DeleteEmployeeFamily", body, httpOptions);
	}

	addEmployeeChildren(emp: EmployeeChildren) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeChildren/AddEmployeeChildren", body, httpOptions);
	}

	editEmployeeChildren(emp: EmployeeChildren) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeChildren/EditEmployeeChildren", body, httpOptions);
	}

	deleteEmployeeChildren(employeeChildrenId: number) {
		var body = JSON.stringify(employeeChildrenId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeChildren/DeleteEmployeeChildren", body, httpOptions);
	}
}
