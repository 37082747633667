import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
@Injectable({
	providedIn: "root",
})
export class UploadFileService {
	constructor(private http: HttpClient) {}

	async uploadFile(items: any[], fields: string, headers: HttpHeaders) {
		const process = items?.map(async (file, i) => {
			let blob = this.convertDataUrlToBlob(file.data);
			if (blob) {
				await firstValueFrom(this.uploadGCP(file[fields], blob, file.extension || file.attachmentMimeType, headers))
					.then((res) => {
						file["status"] = true;
					})
					.catch((error) => {
						file["error"] = error;
						file["status"] = false;
					});
			}
		});
		await Promise.all(process);
		return items;
	}

	private convertDataUrlToBlob(dataUrl): Blob | null {
		const arr = dataUrl?.split(",");
		if (arr?.length) {
			const mime = arr[0].match(/:(.*?);/)[1];
			const bstr = atob(arr[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], { type: mime });
		} else {
			return null;
		}
	}

	public uploadGCP<T>(url: string, blob: Blob, type: string, headers: HttpHeaders) {
		headers["Content-Type"] = type;
		const httpOptions = { headers: headers };
		return this.http.put<any>(url, blob, httpOptions);
	}
}
