import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

@Injectable()
export class CustomFieldService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	saveMasterCustomField(masCustomFieldModel: any, neededDeleteDetails: boolean = true) {
		const body = JSON.stringify(masCustomFieldModel);
		return this.http.post<any>(
			`${this.apiUrl}CustomField/SaveMasterCustomField/${neededDeleteDetails}`,
			body,
			this.httpOptions,
		);
	}

	getMasterCustomField(fieldId: number) {
		return this.http.get<any>(`${this.apiUrl}CustomField/GetMasterCustomField?fieldId=${fieldId}`);
	}

	saveListMasCustomField(masCustomFieldModels: any[]) {
		const body = JSON.stringify(masCustomFieldModels);
		return this.http.post<any>(`${this.apiUrl}CustomField/SaveListMasCustomField`, body, this.httpOptions);
	}

	deleteMasCustomField(fieldId: number) {
		return this.http.post<any>(`${this.apiUrl}CustomField/DeleteMasCustomField?fieldId=${fieldId}`, null);
	}

	getAllMasCustomFields(needDetails: boolean = false) {
		return this.http.post<any>(`${this.apiUrl}CustomField/GetAllMasCustomFields/${needDetails}`, null);
	}

	saveEmployeeCustomFields(jsonString: string, employeeId: number, isSelfUpdate: boolean = null) {
		let model = {
			employeeId: employeeId,
			jsonString: jsonString,
			isSelfUpdate: isSelfUpdate,
		};
		const body = JSON.stringify(model);
		return this.http.post<any>(`${this.apiUrl}CustomField/SaveEmployeeCustomFields`, body, this.httpOptions);
	}

	getEmployeeCustomField(employeeId: number) {
		return this.http.get<any>(`${this.apiUrl}CustomField/GetEmployeeCustomField/${employeeId}`);
	}
}
