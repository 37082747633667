import { SysComponent } from "../../component-setting/shared/component.model";

export class Tenant {
	tenantName: string;
	tenantName_EN: string;
	packageId: number;
	packageName: string;
	packageName_EN: string;
	isActive: boolean;
	createdBy: string;
	dateCreated: Date;
	modifiedBy: string;
	dateModified: Date;
	accountingSystem: number;
	token: string;
	componentModel: SysComponent[];
	configGroupModel: ConfigGroup[];
	percentageAllSetting: number;
	dateGoLive: Date;
	subscriptionStatusId: number;
}

export class ConfigGroup {
	id: number;
	name: string;
	name_EN: string;
	icon: string;
	value: number;
	all: number;
	groupId: number;
	componentModels: SysComponent[];
	percent: string | number;
}
