import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class LeaveEndorseTimeService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getTimeAttendance(date: Date, userId: string) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({ date: date, userId: userId });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TimeAttendance/GetTimeAttendance", body, httpOptions);
	}

	getTimeAttendanceForEndorse(userId: string, date: Date) {
		this.commonService.changeDateJSON();
		return firstValueFrom(this.http.get(`${this.apiUrl}v1/time-attendances/${userId}/endorse/${date}`)).then(
			(res) => res,
		);
	}

	GetTimeAttendanceEndorseByUserId(userId: string) {
		return this.http.get(`${this.apiUrl}TimeAttendance/GetTimeAttendanceEndorseByUserId/${userId}`);
	}
}
