import { QueryParamsHandling } from "@angular/router";

export class Payment {
	employeeId: number;
	paymentMethodId: number;
	year: number;
	periodNo: number;
	payrollStatusId: number;
	additionAmount: number;
	deductionAmount: number;
	totalAmount: number;
	createdBy: string;
	modifiedBy: string;
	dateCreated: Date;
	dateModified: Date;
	displayName: string;
	periodExtendNo: number;
	dateModified_TH: string;
	dateModified_EN: string;
	status: string;
	status_EN: string;
	nameSurname: string;
	nameSurname_EN: string;
	rank: string;
	positionName: string;
	positionName_EN: string;
	bankAccountNo: string;
	bankName: string;
	bankName_EN: string;
	picture: string;
	payrollStatus_TH: string;
	payrollStatus_EN: string;
	paymentPeriodName: string;
	paymentPeriodName_EN: string;
	remark: string;
	processActionId: number;
	companyId: number;
	periodCalculateNo: number;
	employeeNo: string;
	name: string;
	dateCreated_TH: string;
	dateCreated_EN: string;
	bankTemplateId: number;
	month: number;
	type: number;
	orgId: number;
	branchNo: string;
	isStartWork: boolean;
	isResign: boolean;
	isMissing: boolean;
	isMovementPayroll: boolean;
	processName: string;
	processName_EN: string;
	pictureThumbnailURL: string;
	datePayment: Date;
	datePayment_TH: string;
	orgLevelTwo?: number;
	orgLevelThree?: number;
	orgLevelFour?: number;
	orgLevelFive?: number;
	orgLevelSix?: number;
	paymentTypeId: number;
	workLocationId: number;
	rankNo: number;
	url: string;
	companyBankAccountNo: string;
	file: string;
	isAutoCal: boolean;
	language: string;
	paymentPeriod_TH: string;
	paymentPeriod_EN: string;
	branchId: number;
	orgLevel: number;
	recalculateAccumulated: boolean;
	organizationId: number;
	keyword: string;
}

export class PayrollPayment {
	periodCalculateNo: number;
	periodExtendNo: number;
	year: number;
	companyId: number;
	payrollStatusId: number;
	payrollStatus_TH: string;
	payrollStatus_EN: string;
	totalEmployee: number;
	totalAdditionAmount: number;
	totalDeductionAmount: number;
	totalAmount: number;
	totalEmployeePerPeriod: number;
	totalEmployeeStartWorkPerPeriod: number;
	totalEmployeeResignPerPeriod: number;
	totalEmployeeMissingPerPeriod: number;
	totalEmployeeMovementPayrollPerPeriod: number;
	isClosed: boolean;
	isShowPaySlip: boolean;
	isPaySlipConfig: boolean;
	isPendingCalculate: boolean;
	payrollPayment: number;
}

export class EmployeeFinancialEditHistoryModel {
	employeeId: number;
	employeeNo: string;
	fullName: string;
	fullName_EN: string;
	financialItemId: number;
	financialItemName: string;
	financialItemName_EN: string;
	paymentMethodName: string;
	paymentMethodName_EN: string;
	oldValue: string;
	newValue: string;
	oldValue1: number;
	newValue1: number;
	dateModified: Date;
	dateModified_TH: string;
	dateModified_EN: string;
	modified: string;
	modified_EN: string;
	displayName: string;
	pictureURL: string;
}

export class ExportTextPayment {
	employeeNo: string;
	name: string;
	surname: string;
	bankAccountNo: string;
	totalAmount: number;
}

export class BankPaymentTemplate {
	banktemplateId: number;
	name: string;
	name_EN: string;
	hasTemplate: number;
	pathTemplate: string;
	typeTemplate: number;
}

export class PaymentFileInfo {
	file: string;
	fileBase64: string;
	fileType: string;
	type: string;
	fileSize: string;
	fileMimeType: string;
	fileName: string;
}

export interface MenuItemModel {
	label?: string;
	icon?: string;
	command?: (event?: any) => void;
	url?: string;
	items?: MenuItemModel[];
	expanded?: boolean;
	disabled?: boolean;
	visible?: boolean;
	target?: string;
	routerLinkActiveOptions?: any;
	separator?: boolean;
	badge?: string;
	badgeStyleClass?: string;
	style?: any;
	styleClass?: string;
	title?: string;
	id?: string;
	automationId?: any;
	tabindex?: string;
	routerLink?: any;
	queryParams?: {
		[k: string]: any;
	};
	fragment?: string;
	queryParamsHandling?: QueryParamsHandling;
	preserveFragment?: boolean;
	skipLocationChange?: boolean;
	replaceUrl?: boolean;
	state?: {
		[k: string]: any;
	};
	value?: string;
	value2?: string;
}

export class SummaryFinancialAccumulate {
	amountAddition: number;
	amountBank: number;
	amountCash: number;
	amountDeduction: number;
	amountIsSoc: number;
	amountIsTax: number;
	amountNet: number;
	amountTax: number;
	companyId: number;
	isEndOfMonth: boolean;
	month: number;
	periodCalculateNo: number;
	year: number;
}

export class PayrollSlipCostConfigCompany {
	amount: number;
	shippingAmount: number;
	companyCode: string;
	companyId: number;
	companyName: string;
	companyName_EN: string;
	costConfigId: number;
	createdBy: string;
	dateCreated: string;
	dateModified: string;
	discountAmount: number;
	isActive: boolean;
	logo: string;
	modifiedBy: string;
	netAmount: number;
	totalPage: number;
	hasPayrollSlipLog: boolean;
	periodCalculateNo: number;
	periodExtendNo: number;
	year: number;
	vatValue: number;
	vatAmount: number;
	pageDiscountPercentage: number;
	pageAmountPromotion: number;
	pageAmount: number;
	addressName: string;
	addressDetail: string;
	addressName_EN: string;
	addressDetail_EN: string;
}

export class PayrollSlipLog {
	amount: number;
	companyId: number;
	createdBy: string;
	dateCreated: string;
	datePayment: string;
	datePrinted: string;
	discountAmount: number;
	netAmount: number;
	paymentNo: string;
	payrollStatusName: string;
	payrollStatusName_EN: string;
	periodMonthName: string;
	periodMonthName_EN: string;
	periodCalculateNo: number;
	periodExtendNo: number;
	statusId: number;
	title_EN: string;
	title_TH: string;
	totalPage: number;
	vatAmount: number;
	vatValue: number;
	year: number;
	paymentType: any;
}

export class InvoiceData {
	public shippingAmount: number;
	public amount: number;
	public companyId: number;
	public periodCalculateNo: number;
	public periodExtendNo: number;
	public year: number;
	public clientId: number;
	public companyName: string;
	public createdByUserId: string;
	public dateDue: Date;
	public dateInvoice: Date;
	public discount: number;
	public invoiceFrom: string;
	public invoiceId: number;
	public invoiceItems: InvoiceItem[];
	public invoiceNumber: string;
	public isIncludeVat: boolean;
	public itemCode: string;
	public itemDescription: string;
	public itemName: string;
	public itemPrice: number;
	public paymentGateway: number;
	public productId: number;
	public qty: number;
	public ref1: string;
	public ref2: string;
	public referenceId: number;
	public status: number;
	public statusName: string;
	public totalAmount: number;
	public type: number;
	public typeItem: number;
	public vat: number;
	public vatAmount: number;
	public billingInterval: string;
}
export class InvoiceItem {
	public itemName: string;
	public qty: number;
	public itemPrice: number;
	public discount: number;
	public vat: number;
	public vatAmount: number;
	public totalAmount: number;
}
export class PaymentPeriodSearchModel {
	userId: string;
	companyId: number;
	year: number;
	paymentMethodId: number;
	payrollStatusId: number;
	periodCalculateNo: number;
	isWaitingPayroll?: boolean;
	isGroupYear: boolean;
	isExtendPeriod: boolean;
	isIncludeNextMonth: boolean;
}
