export enum WelfareType {
	general = 1,
	extraAllowance = 2,
	payrollReimbursement = 3,
	additionalReimbursement = 4,
	others = 5,
}

export enum ExpenseCategory {
	accommodation = 1001,
	entertainment = 1002,
	fuel = 1003,
	others = 1004,
	training = 1005,
	travel = 1006,
	meeting = 1007,
	staffAllowance = 1008,
	advanceExpense = 1009,
}
