import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { EmployeeAddress } from "./employee-address.model";
import { EmployeeContactPerson } from "./employee-contactperson.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeAddressService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeAddressById(employeeAddressId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeAddress/GetEmployeeAddressById/" + employeeAddressId),
		).then((res) => <EmployeeAddress>res);
	}

	getEmployeeAddressByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeAddress/GetEmployeeAddressByEmployeeId/" + employeeId),
		).then((res) => <EmployeeAddress[]>res);
	}

	addEmployeeAddress(emp: EmployeeAddress[]) {
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeAddress/AddEmployeeAddress", body, httpOptions);
	}

	editEmployeeAddress(emp: EmployeeAddress[]) {
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeAddress/EditEmployeeAddress", body, httpOptions);
	}

	getEmployeeContactPersonById(employeeContactPersonId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeContactPerson/GetEmployeeContactPersonById/" + employeeContactPersonId),
		).then((res) => <EmployeeContactPerson>res);
	}

	getEmployeeContactPersonByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeContactPerson/GetEmployeeContactPersonByEmployeeId/" + employeeId),
		).then((res) => <EmployeeContactPerson[]>res);
	}

	addEmployeeContactPerson(emp: EmployeeContactPerson) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeContactPerson/AddEmployeeContactPerson", body, httpOptions);
	}

	editEmployeeContactPerson(emp: EmployeeContactPerson) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeContactPerson/EditEmployeeContactPerson", body, httpOptions);
	}

	deleteEmployeeContactPerson(contactPersonId: number) {
		var body = JSON.stringify(contactPersonId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeContactPerson/DeleteEmployeeContactPerson", body, httpOptions);
	}
}
