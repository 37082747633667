import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../../core/shared/data-tables-response.model";
import { ProvidentFundPercentage } from "./provident-fund-percentage.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ProvidentFundPercentageService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getProvidentFundPercentageDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "ProvidentFundPercentage/GetProvidentFundPercentageDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getProvidentFundPercentageById(providentFundPercentageId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "ProvidentFundPercentage/GetProvidentFundPercentageById/" + providentFundPercentageId,
			),
		).then((res) => <ProvidentFundPercentage>res);
	}

	addProvidentFundPercentage(pfp: ProvidentFundPercentage) {
		var body = JSON.stringify(pfp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "ProvidentFundPercentage/AddProvidentFundPercentage", body, httpOptions);
	}

	editProvidentFundPercentage(pfp: ProvidentFundPercentage) {
		var body = JSON.stringify(pfp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "ProvidentFundPercentage/EditProvidentFundPercentage", body, httpOptions);
	}
}
