import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AssessmentTypeModel } from "./assessment-type.model";
import { CommonService } from "../../shared/service/common.service";
import { Dropdown } from "primeng/dropdown";
import { firstValueFrom } from "rxjs";

@Injectable()
export class AssessmentService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeAssessmentByAssessorId(assessorEmployeeId?: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentScore/GetEmployeeAssessmentByAssessorId/" + assessorEmployeeId),
		).then((res) => <AssessmentTypeModel[]>res);
	}

	getPendingAssessments() {
		return firstValueFrom(this.http.get(this.apiUrl + "assessments/pending")).then((res) => <AssessmentTypeModel[]>res);
	}

	getAssessmentSetDDL(filterActive: boolean = false) {
		return firstValueFrom(this.http.get(`${this.apiUrl}Common/GetAssessmentSetDDL?filterActive=${filterActive}`)).then(
			(res) => <any[]>res,
		);
	}

	getAssessmentMasterDDL(setId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetAssessmentMasterDDL/" + setId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	getAssessmentNoDDL(setId: number, assessmentId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetAssessmentNoDDL/" + setId + "/" + assessmentId)).then(
			(res) => <Dropdown[]>res,
		);
	}

	addAssessmentScore(assessmentScore: any) {
		var body = JSON.stringify(assessmentScore);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentScore/AddAssessmentScore", body, httpOptions);
	}

	addAssessmentScorePeerReview(assessmentScore: any) {
		var body = JSON.stringify(assessmentScore);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "v1/assessments/peer-reviews/scores", body, httpOptions);
	}

	addAssessmentMasterSetParticipants(assessmentScore: any) {
		var body = JSON.stringify(assessmentScore);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentScore/AddAssessmentMasterSetParticipants", body, httpOptions);
	}
	// addAssessmentScores(assessmentScore: any) {
	//   var body = JSON.stringify(assessmentScore);

	delteAssessmentScore(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.put(this.apiUrl + "AssessmentScore/DeleteAssessmentScore/", body, httpOptions);
	}
	updateAssessmentScore(assessmentScore: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(assessmentScore);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "AssessmentScore/UpdateAssessmentScore", body, httpOptions);
	}

	updateAssessmentScoreDateTimeReviseEnd(assessmentScore: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(assessmentScore);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(
			this.apiUrl + "AssessmentScore/UpdateAssessmentScoreDateTimeReviseEnd",
			body,
			httpOptions,
		);
	}

	updateAssessmentMasterSetsByMasterSetIdAndNo(model) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(
			this.apiUrl + "AssessmentScore/UpdateAssessmentMasterSetsByMasterSetIdAndNo",
			body,
			httpOptions,
		);
	}

	getAssessmentScoresById(assessmentSearchModel: any) {
		var body = JSON.stringify(assessmentSearchModel);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(this.apiUrl + "AssessmentScore/GetAssessmentScoresById", body, httpOptions),
		).then((res) => <any[]>res);
	}

	addAssessmentMasterSetOKRParticipants(model) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "assessments/master-sets/okr/participants", body, httpOptions);
	}

	addAssessmentScoreTermination(employeeId: number) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "assessments/termination/scores/" + employeeId, null, httpOptions);
	}

	deleteAssessmentScoreAndDetails(scoreId: number) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.delete(this.apiUrl + "assessments/scores/" + scoreId, httpOptions);
	}

	calculatesGrade(item: any, setId: number, reCalculateScore: boolean = false) {
		let model = { masterSetId: item?.masterSetId || null, okrSetId: item?.okrSetId || null, reCalculateScore, setId };
		let body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.put(this.apiUrl + "v1/assessments/scores/calculates/grade", body, httpOptions);
	}

	getAssesseePeerReview(model: any) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "assessments/peer-reviews", model, httpOptions);
	}
}
