import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
@Injectable()
export class SkillLevelService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getMasSkillLevelList() {
		return this.http.get(`${this.apiUrl}v1/SkillLevel`);
	}
	checkSkillLevelEmployee(skillLevelId) {
		return firstValueFrom(this.http.get(`${this.apiUrl}v1/SkillLevel/CheckEmployee?skillLevelId=${skillLevelId}`));
	}

	upsertSkillLevel(model) {
		var body = JSON.stringify(model);
		return this.http.post(`${this.apiUrl}v1/SkillLevel`, body, this.httpOptions);
	}
}
