import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EditShift, ShiftManagerAvailables, ShiftRequest, SearchShiftRequest, UserShiftDetail } from "./shift.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { DropdownDataItem } from "../../../shared/models/dropdown-data-item.model";
import { CommonService } from "../../../shared/service/common.service";
import { Recurrence } from "../../../shared/models/recurrence.model";
import { ApprovalSearchModel } from "src/app/document-forms/approval/shared/approval.model";
import { Checkbox } from "primeng/checkbox";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ShiftService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	addShift(shift: EditShift) {
		var body = JSON.stringify(shift);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/AddShift", body, httpOptions);
	}

	addListShiftManagerAvailable(model: string[], employeeId: number) {
		var body = JSON.stringify(model);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/AddListShiftManagerAvailable/" + employeeId, body, httpOptions);
	}

	addShiftRequest(shiftRequest: ShiftRequest) {
		var body = JSON.stringify(shiftRequest);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/AddShiftRequest", body, httpOptions);
	}

	addShiftRequestList(shiftRequestList: ShiftRequest[]) {
		var body = JSON.stringify(shiftRequestList);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/AddShiftRequestList", body, httpOptions);
	}

	editShift(shift: EditShift) {
		var body = JSON.stringify(shift);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/EditShift", body, httpOptions);
	}

	deleteShift(shiftId: string[]) {
		var body = JSON.stringify(shiftId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/DeleteShift", body, httpOptions);
	}
	insertShiftSchedule(shiftSchedule: Object[]) {
		var body = JSON.stringify(shiftSchedule);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/InsertShiftSchedule", body, httpOptions);
	}

	getShiftRequests(model: SearchShiftRequest) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/GetShiftRequests", body, httpOptions);
	}

	getShiftRequestForApprove(shiftRequestId?: number, approvalSearchModel?: ApprovalSearchModel) {
		return firstValueFrom(
			this.http.post(this.apiUrl + "Shift/GetShiftRequestForApprove/" + shiftRequestId, approvalSearchModel),
		).then((res) => <ShiftRequest[]>res);
	}

	getProcessActionByShiftRequestId(shiftRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Shift/GetProcessActionByShiftRequestId/" + shiftRequestId)).then(
			(res) => <Checkbox[]>res,
		);
	}

	getNotificationShiftRequestByEmployeeId(employeeId: number, shiftRequestId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Shift/GetNotificationShiftRequestByEmployeeId/" + employeeId + "/" + shiftRequestId),
		).then((res) => <any[]>res);
	}

	getShiftRequestById(shiftRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Shift/GetShiftRequestById/" + shiftRequestId)).then(
			(res) => <ShiftRequest>res,
		);
	}

	getUserShiftDetailFromTo(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Shift/GetUserShiftDetailFromTo/", body, httpOptions)).then(
			(res) => <UserShiftDetail>res,
		);
	}

	addProcessShiftRequestBySelected(shiftRequestId: string[], processActionId: number, reason: string) {
		var body = JSON.stringify({ shiftRequestId: shiftRequestId, processActionId: processActionId, reason: reason });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/AddProcessShiftRequestBySelected/", body, httpOptions);
	}

	addProcessActionShiftRequest(shiftRequest: ShiftRequest) {
		var body = JSON.stringify(shiftRequest);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/AddProcessActionShiftRequest/", body, httpOptions);
	}

	getShiftGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Shift/GetShiftDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}
	getMasShifts(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/GetMasShifts", body, httpOptions);
	}

	getShiftById(shiftId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Shift/GetShiftById/" + shiftId)).then((res) => <EditShift>res);
	}

	getRecurrenceDateTimeCollection(
		employeeId: number,
		shiftId: number,
		rule: string,
		recStartDate: string,
		recException: string,
	) {
		this.commonService.changeDateJSON();
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"Shift/GetRecurrenceDateTimeCollection/" +
					employeeId +
					"/" +
					shiftId +
					"/" +
					rule +
					"/" +
					recStartDate +
					"/" +
					recException,
			),
		).then((res) => <Recurrence[]>res);
	}

	getShiftManagerAvailablesByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Shift/GetShiftManagerAvailablesByEmployeeId/" + employeeId),
		).then((res) => <ShiftManagerAvailables[]>res);
	}

	getShiftRequestHistoryDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Shift/GetShiftRequestHistoryDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getCurrentShiftByEmployeeId(employeeId: number, date?: string) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Shift/GetCurrentShiftByEmployeeId/" + employeeId + "/" + date),
		).then((res) => res);
	}

	getDDLRanks() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLRanks")).then((res) => <Dropdown[]>res);
	}

	getDDLStatus() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Common/GetDDLStatus")).then((res) => <Dropdown[]>res);
	}

	getDayOfWeek() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDayOfWeek")).then((res) => <DropdownDataItem[]>res);
	}

	getWeekOfMonth() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetWeekOfMonth")).then((res) => <DropdownDataItem[]>res);
	}

	getShiftRequestsWaitingApprove(employeeId: number, shiftTypeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Shift/GetShiftRequestsWaitingApprove/" + employeeId + "/" + shiftTypeId),
		).then((res) => <ShiftRequest[]>res);
	}

	getShiftRequestLogsByWorkflow(shiftRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Shift/GetShiftRequestLogsByWorkflow/" + shiftRequestId)).then(
			(res) => <any[]>res,
		);
	}
	getShiftRequestsByShiftRequestId(shiftRequestId, employeeId) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}Shift/GetShiftRequestsByShiftRequestId/${shiftRequestId}/${employeeId}`),
		).then((r) => <any>r);
	}
	getMasShiftsByTenant() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Shift/GetMasShifts"));
	}

	swapShiftRequestMSSTemp(shiftRequest: ShiftRequest) {
		var body = JSON.stringify(shiftRequest);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/SwapShiftRequestMSSTemp", body, httpOptions);
	}

	updateRosterShiftRequestList(rosterManagementDetailList: any[]) {
		var body = JSON.stringify(rosterManagementDetailList);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Shift/UpdateRosterShiftRequestList", body, httpOptions);
	}
}
