import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TaxExemption } from "./tax-exemption.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TaxExemptionService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getAllTaxExemptionByTaxType(taxType: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "TaxExemption/GetAllTaxExemptionByTaxType/" + taxType)).then(
			(res) => <TaxExemption[]>res,
		);
	}

	editTaxExemption(te: TaxExemption[]) {
		var body = JSON.stringify(te);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "TaxExemption/EditTaxExemption", body, httpOptions);
	}
}
