export class Position {
	positionId: number = 0;
	positionName: string;
	positionName_EN: string;
	isLimitRank: boolean = false;
	isActive: boolean = true;
	rankFromId: number;
	rankToId: number;
	rankFromName: string;
	rankToName: string;
	isSoc: boolean;
	status: string;
	status_EN: string;
	soc: string;
	soc_EN: string;
	modifiedBy: string;
	dateModified: string;
	qualifications: string;
	responsibility: string;
	hasSkill: boolean = false;
	positionSkills: any[];
	careerSite: any[];
}
