import { Input, SimpleChanges, OnChanges, Component } from "@angular/core";
import { Subject, debounceTime } from "rxjs";
import { DataSharingService } from "src/app/core";
import { TranslateModule } from "@ngx-translate/core";
import { NgStyle, AsyncPipe, DecimalPipe } from "@angular/common";
import { TooltipModule } from "@gofive/design-system-tooltip";
@Component({
	selector: "app-salary-band",
	templateUrl: "./salary-band.component.html",
	styleUrls: ["./salary-band.component.scss"],
	standalone: true,
	imports: [TooltipModule, NgStyle, AsyncPipe, DecimalPipe, TranslateModule],
})
export class SalaryBandComponent implements OnChanges {
	@Input() public salaryBand: SalaryBand;
	@Input() public isShowSalary: boolean = true;
	public percentage: number;
	public percentagePrev: number;
	public percentageDiifBar: number;
	settings: SettingsModel = new SettingsModel();
	markAmount = "xx,xxxx";
	loading$: Subject<any> = new Subject();

	constructor(public data: DataSharingService) {
		this.loading$?.pipe(debounceTime(500)).subscribe((_) => {
			this.calAmount();
		});
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.isShowSalary?.currentValue) {
			this.isShowSalary = changes.isShowSalary?.currentValue;
		}
		if (changes.salaryBand?.currentValue) {
			this.salaryBand = changes.salaryBand?.currentValue;
			this.loading$?.next(true);
		}
	}

	calAmount() {
		if (this.salaryBand.min === this.salaryBand.max) {
			if (this.salaryBand.newBaseSalary < this.salaryBand.min) {
				this.salaryBand.min = this.salaryBand.newBaseSalary;
			} else {
				this.salaryBand.max = this.salaryBand.newBaseSalary;
			}
		}
		if (
			(this.salaryBand.min === this.salaryBand.median || this.salaryBand.max === this.salaryBand.median) &&
			this.salaryBand.min !== this.salaryBand.max
		) {
			this.salaryBand.median = (this.salaryBand.max + this.salaryBand.min) / 2;
		}

		if (this.salaryBand.newBaseSalary > this.salaryBand.median) {
			this.percentage =
				50 +
				((this.salaryBand.newBaseSalary - this.salaryBand.median) / (this.salaryBand.max - this.salaryBand.median)) *
					50;
		} else {
			this.percentage =
				((this.salaryBand.newBaseSalary - this.salaryBand.min) / (this.salaryBand.median - this.salaryBand.min)) * 50;
		}

		if (this.salaryBand.baseSalary > this.salaryBand.median) {
			this.percentagePrev =
				50 +
				((this.salaryBand.baseSalary - this.salaryBand.median) / (this.salaryBand.max - this.salaryBand.median)) * 50;
		} else {
			this.percentagePrev =
				((this.salaryBand.baseSalary - this.salaryBand.min) / (this.salaryBand.median - this.salaryBand.min)) * 50;
		}

		this.percentage = this.percentage > 100 ? 100 : this.percentage;
		this.percentage = this.percentage < 0 ? 0 : this.percentage ?? 0;

		this.percentagePrev = this.percentagePrev > 100 ? 100 : this.percentagePrev;
		this.percentagePrev = this.percentagePrev < 0 ? 0 : this.percentagePrev ?? 0;

		this.percentageDiifBar = Math.abs(this.percentagePrev - this.percentage) ?? 0;

		if (!this.conditionShow()) {
			this.percentage = 0;
			this.percentagePrev = 0;
			this.percentageDiifBar = 0;
		}

		this.settings = {
			circle1: {
				left: `${this.percentage}%`,
				backgroundColor: this.calCircle1_BackgroundColor(),
				borderColor: this.calCircle1_BorderColor(),
			},
			circle2: {
				left: `${this.percentagePrev}%`,
				backgroundColor: ColorSetiting.white,
				borderColor: this.calCircle2_BorderColor(),
			},
			rangeBar: {
				backgroundColor: this.calRangeBar_BackgroundColor(),
				left: `${this.percentagePrev < this.percentage ? this.percentagePrev : this.percentage}%`,
				width: `calc( ${this.percentageDiifBar}% + 12px  )`,
			},
			showMin: true,
			showMedian: this.salaryBand?.totalEmployee > 1 && this.conditionShow(),
			showMax:
				this.salaryBand?.totalEmployee > 1 ||
				this.conditionShow() ||
				this.salaryBand.newBaseSalary !== this.salaryBand.baseSalary,
		};
	}

	conditionShow() {
		return (
			this.salaryBand?.min !== this.salaryBand?.median &&
			this.salaryBand?.median !== this.salaryBand?.max &&
			this.salaryBand?.min !== this.salaryBand?.max
		);
	}

	calCircle1_BackgroundColor() {
		if (this.salaryBand.newBaseSalary > this.salaryBand.max) {
			return ColorSetiting.over;
		} else {
			return this.percentageDiifBar > 0 && this.percentagePrev < this.percentage
				? ColorSetiting.increase
				: this.percentagePrev > this.percentage
					? ColorSetiting.reduce
					: ColorSetiting.white;
		}
	}
	calCircle1_BorderColor() {
		if (this.salaryBand.newBaseSalary > this.salaryBand.max) {
			return ColorSetiting.overOpacity;
		} else {
			return this.percentageDiifBar > 0
				? this.percentagePrev < this.percentage
					? ColorSetiting.increaseOpacity
					: ColorSetiting.reduceOpacity
				: ColorSetiting.primaryOpacity;
		}
	}

	calCircle2_BorderColor() {
		if (this.salaryBand.newBaseSalary > this.salaryBand.max) {
			return ColorSetiting.overOpacity;
		} else {
			return this.percentagePrev < this.percentage ? ColorSetiting.increaseOpacity : ColorSetiting.reduceOpacity;
		}
	}

	calRangeBar_BackgroundColor() {
		if (this.salaryBand.newBaseSalary > this.salaryBand.max) {
			return ColorSetiting.over;
		} else {
			return this.percentagePrev < this.percentage ? ColorSetiting.increase : ColorSetiting.reduce;
		}
	}
}
class CircleSettings {
	left: string;
	backgroundColor: string;
	borderColor: string;
}
class RangeBarSettings {
	backgroundColor: string;
	left: string;
	width: string;
}

class SettingsModel {
	circle1: CircleSettings;
	circle2: CircleSettings;
	rangeBar: RangeBarSettings;
	showMin: boolean;
	showMedian: boolean;
	showMax: boolean;
}

enum ColorSetiting {
	increase = "#00C291",
	increaseOpacity = "#b2ecde",
	white = "#fff",
	reduce = "#838395",
	reduceOpacity = "#d9d9df",
	over = "#FFB31A",
	overOpacity = "#ffe8ba",
	primaryOpacity = "#facdc0",
}

export class SalaryBand {
	positionName: string;
	rankName?: string;
	positionName_EN: string;
	max: number;
	min: number;
	median: number;
	baseSalary: number;
	newBaseSalary: number;
	totalEmployee: number;
}
