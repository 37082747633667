export enum IncomeType {
	salary = 1,
	wage = 2,
	termination = 3,
	recipientResidesInCountry = 4,
	rent = 5,
	taxIncome3per = 6,
	independentProfessionalFees = 7,
	otherIncome = 8,
}
