import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
	name: "date",
	standalone: true,
})
export class ThaiDatePipe implements PipeTransform {
	private yearYY: boolean = false;

	constructor(private datePipe: DatePipe) {}

	transform(value: string | Date, format?: string, locale?: string): any {
		if (locale && format && this.getIsTH(locale)) {
			if (format.match("yyyy")) {
				this.yearYY = false;
			} else if (format.match("yy")) {
				format = format.replace("yy", "yyyy");
				this.yearYY = true;
			}
			return this.convertYear(value, this.datePipe.transform(value, format, null, locale));
		}
		return this.datePipe.transform(value, format, null, "en-GB");
	}

	private convertYear(value: string | Date, newValue: string) {
		let year = this.datePipe.transform(value, "yyyy");
		let newYear = (parseInt(year) + 543).toString();

		if (this.yearYY) {
			newYear = newYear.substring(2, 4);
		}

		return !newValue ? "" : newValue.replace(year, newYear);
	}

	getIsTH(val: string) {
		return val?.toLowerCase()?.search("th") > -1;
	}
}
