<ma-assessment-form
	*axLazyElement="link"
	[currentUser]="currentUser"
	[token]="tokenCore"
	[theme]="theme"
	[currentUser]="currentUser"
	[language]="language"
	[application]="application"
	[assessmentId]="assessmentId"
	[assessmentObs]="assessmentObs"
	[isLeftMenu]="true"
	[isWebView]="isWebView"
	[empeoHeight]="empeoHeight"
	(onBackEvent)="receiveBackEvent($event)"
	(isChangedAnswer)="onChangedAnswer($event)"
	(successAssessmentEvent)="receiveSuccessEvent($event)"
	(onStartedSurvey)="onStartedSurvey($event)"
	[isDialog]="isDialog"
></ma-assessment-form>
