import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { Holiday } from "./holiday.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class HolidayService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getHolidayById(holidayId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Holiday/GetHolidayById/" + holidayId)).then(
			(res) => <Holiday>res,
		);
	}

	getHolidayDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Holiday/GetHolidayDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	addHoliday(holiday: Holiday) {
		var body = JSON.stringify(holiday);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Holiday/AddHoliday", body, httpOptions);
	}

	editHoliday(holiday: Holiday) {
		var body = JSON.stringify(holiday);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Holiday/EditHoliday", body, httpOptions);
	}
}
