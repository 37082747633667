import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeeWorkExperience } from "./../../employee-work-experience/shared/employee-work-experience.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeWorkExperienceService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeExperienceById(employeeExperienceId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeExperience/GetEmployeeExperienceById/" + employeeExperienceId),
		).then((res) => <EmployeeWorkExperience>res);
	}

	getEmployeeExperienceByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeExperience/GetEmployeeExperienceByEmployeeId/" + employeeId),
		).then((res) => <EmployeeWorkExperience[]>res);
	}

	addEmployeeExperience(emp: EmployeeWorkExperience) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeExperience/AddEmployeeExperience", body, httpOptions);
	}

	editEmployeeExperience(emp: EmployeeWorkExperience) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeExperience/EditEmployeeExperience", body, httpOptions);
	}

	deleteEmployeeExperience(employeeExperienceId: number) {
		var body = JSON.stringify(employeeExperienceId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeExperience/DeleteEmployeeExperience", body, httpOptions);
	}
}
