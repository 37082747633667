export enum ProcessAction {
	startWorking = 1001,
	crossCompanyTransfer = 1002,
	agencyTransfer = 1003,
	promote = 1004,
	raiseTheSalary = 1005,
	employmentConditions = 1006,
	appoint = 1007,
	resign = 1008,
	unresign = 1009,
	renewal = 1010,
	permanent = 1011,
	changeOfSupervisor = 1012,
	massTransfer = 1013,
	draftDocument = 2001,
	sendDocument = 2002,
	canceledDocument = 2003,
	approvedDocument = 2004,
	rejectedDocument = 2005,
	returnDocument = 2006,
	completeDocument = 2009,
	Membered = 3001,
	MovingFund = 3002,
	ChangeFund = 3003,
	ResignationOfTheFund = 3004,
	InformBank = 3005,
	ChangeResignationOfTheFund = 3006,
	calculateSalary = 4001,
	closePeriod = 4002,
	payment = 4003,
	adjustPeriod = 4004,
	sendEslip = 4005,
	sendToApprove = 4006,
	approve = 4007,
	sendApprove = 4010,
	memberedEJIP = 5001,
	resignationOfTheFundEJIP = 5002,
	suspension = 6003,
	disciplineFine = 6004,
	termination = 6005,

	employeeModule = 1,
	leaveModule = 2,
	providentFund = 3,
	payrollModule = 4,
	ejipModule = 5,
	penalties = 6,
	statusFreeze = 7,
}
