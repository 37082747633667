export class EmployeeContactPerson {
	contactPersonId: number;
	employeeId: number;
	contactPersonName: string;
	contactPersonSurname: string;
	contactPersonRelationShipId: number;
	contactPersonOthRelationship: string;
	contactPersonAddress: string;
	contactPersonSubdistrict: string;
	contactPersonDistrictId: number;
	contactPersonProvinceId: number;
	contactPersonPostalCode: string;
	contactPersonPhoneNo: string;
	contactPersonRelationShipName: string;
	contactPerson: string;
	address: string;
}
