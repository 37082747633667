export enum LanguageType {
	all = -1,
	th = 1,
	en = 2,
}

export enum LanguageLocal {
	en = "en-GB",
	th = "th-TH",
	zh = "zh-CN",
	km = "km-KH",
	my = "my-MM",
}
