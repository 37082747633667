import { DiligenceAllowance } from "src/app/master/diligence-allowance/shared/diligence-allowance.model";
import { FinancialType } from "src/app/shared/enum/financial-type.enum";
import { UserInfo } from "../../core/shared/user-info.model";
import { Dropdown } from "../../shared/models/dropdown.model";
import { MovementSearch } from "../../shared/models/movement.model";
import { ConditionTypeWelfare } from "../welfare-master/welfare-master-add/welfare-master-add.component";

export class Welfare {
	welfareId: number;
	welfareType: number;
	name: string;
	name_EN: string;
	description: string;
	description_en: string;
	welfareCategoryId: number;
	financialItemId: number;
	documentCode: string;
	isUseOnce: boolean;
	isCondition: boolean = false;
	conditionType: ConditionTypeWelfare = ConditionTypeWelfare.PaidSameAmount;
	isActive: boolean = true;
	isDelete: boolean;
	dateCreated: Date;
	companyId: number;
	companyName: string;
	companyName_EN: string;
	welfareCategoryName: string;
	welfareCategoryName_EN: string;
	status: string;
	status_EN: string;
	allowGender: number = 1;
	calculateAs: number;
	workflowId: number;
	creditType: number = 1;
	nameSurname: string;
	nameSurname_EN: string;
	amount: number = 0;
	maswelfareName: string;
	maswelfareName_EN: string;
	nextApprovalFullName: string;
	nextApprovalFullName_EN: string;
	documentStatus: string;
	documentStatus_EN: string;
	documentStatusId: number;
	invoiceNo: string;
	roundingCode: string;
	isAllowanceFromAttendance: boolean;
	patientTypeId: number;
	financialType: number = FinancialType.income;
	welfareDetails: WelfareDetails[] = [];
	isMedicalExpense: boolean;
	isAllSite: boolean;
	isIndividual: boolean;
	isResposiblePerson: boolean;
	isExtra: boolean;
	isStack: boolean;
	isSelfService: boolean;
	welfareEmploymentTypes: any[];
	welfareRanks: any[];
	employmentTypeDDL: Dropdown[];
	ranksDDL: Dropdown[];
	monthAgeOfWork: number;
	welfareEmployeeResponsibilities: any;
	welfareEmployee: any[] = [];
	diligenceAllowance: DiligenceAllowance;
	isFinanCialItem: boolean;
	isAmount: boolean;
	positionId: number;
	paymentType: number = 2;
	minuteStampInBefore: number = 0;
	isPermanent: boolean;
	minuteStampOutAfter: number = 0;
	iconNo: number;
	isSystem: boolean;
	expenseCategoryId: number;
	isSetFuelRate: boolean;
	fuelRate: number;
}
export class WelfareDetails {
	welfareDetailId: number;
	welfareId: number;
	minimumRank: number;
	maximumRank: number;
	minimumAgeOfWork: number;
	maximumAgeOfWork: number;
	totalTime: number;
	totalAmount: number;
	limitAmountPerTime: number;
	description: string;
	maximumRankTo: Dropdown;
	minimumRankFrom: Dropdown;
	usableAmountPercentage: number = 100;
	limitAmountSalaryPercentage: number;
	extraAmountPercentage: number;
	extraAmount: number;
	minimumRankFromDDL: any[];
	maximumRankToDDL: any[];
}
export class SearchWelfare {
	name: string;
	welfareTypeId: any;
	companyId: any;
	isActive: boolean;
}
export class SearchWelfareDocument {
	employeeId: number;
	welfareStatusId: number;
	documentCode: string;
	year: string;
	documentStatusIds: string;
	keyword: string;
	welfareTypeId?: number;
	sorting: string;
}
export class SearchWelfareCalculateNetAmount {
	welfareId: number;
	employeeId: number;
	netAmount: number;
	netExtraAmount: number;
	amount: number;
	action: boolean;
	year: number;
}
export class WelfareDocumentForms {
	welfareDocumentId: number;
	documentCode: string;
	documentStatusId: number;
	dateDocument: Date;
	dateInvoice: Date;
	invoiceNo: string;
	dateTreatmentFrom?: Date;
	dateTreatmentTo?: Date;
	patientTypeId: number;
	welfareId: number;
	patientRelationshipId: number;
	diseaseId?: number;
	employeeId?: number;
	hospitalId?: number;
	isCash: boolean;
	amount: number;
	netAmount: number;
	reason: string;
	remark: string;
	createdBy: string;
	dateCreated: Date;
	modifiedBy: string;
	dateModified?: Date;
	nextApprovalEmployeeId: number;
	welfare: Welfare;
	employee: UserInfo;
}

export class WelfareDocumentFormModel extends WelfareDocumentForms {
	welfareName: string;
	welfareName_EN: string;
	fullName: string;
	fullName_EN: string;
	pictureURL: string;
	positionName: string;
	positionName_EN: string;
	diseaseName: string;
	diseaseName_EN: string;
	hospitalName: string;
	hospitalName_EN: string;
	documentStatusName: string;
	documentStatusName_EN: string;
	companyId: string;
	recordCount: number;
}
export class WelfareDocuments {
	welfareId: number;
	welfareDocumentId: number;
	documentCode: string;
	welfareTypeName: string;
	welfareTypeName_EN: string;
	dateDocument_TH: string;
	dateDocument_EN: string;
	dateDocument: Date;
	documentStatusId: number;
	documentStatusName: string;
	documentStatusName_EN: string;
	patientTypeName: string;
	patientTypeName_EN: string;
	newApprovalNameEmployee: string;
	newApprovalNameEmployee_EN: string;
	netAmount: string;
	netExtraAmount: string;
	reason: string;
	monthName: string;
	dateInvoice: Date;
	processActionId: number;
	remark: string;
	employeeId: number;
}
export class WelfareGroupMonth {
	year: string;
	monthId: number;
	monthName: string;
	monthName_EN: string;
	welfareDocument: WelfareDocuments[];
}

export class WelfareDocumentFormGroupMonthModel {
	year: string;
	monthId: number;
	monthName: string;
	monthName_EN: string;
	welfareDocumentForm: any[];
}

export class SearchEmployeeWelfare {
	companyId: number;
	documentType: string;
	documentOrInvoiceNo: string;
	documentStatusId: string;
	name: string;
	dateFrom: string;
	dateTo: string;
	isCash: string;
}

export class WelfareEmployeeUsage {
	welfareId: number;
	year: string;
	totalTime: number;
	employeeId: number;
	totalAmount: number;
	totalUsedTime: number;
	totalUsedAmount: number;
	limitAmountPerTime: number;
	usableAmountPercentage: number;
	balance: number;
	netAmount: number;
	netExtraAmount: number;
	netAmountMessage: string;
	name: string;
	name_EN: string;
	description: string;
	description_EN: string;
	modifiedByFullname: string;
	modifiedByFullname_EN: string;
	dateModified: Date;
	createdByFullname: string;
	createdByFullname_EN: string;
	dateCreated: Date;
	welfareType: number;
}

export class WelfareDocumentBySelected {
	welfareDocumentId: number[];
	reason: string;
	processActionId: number;
}

export class ProvidentFundMasterModel {
	dateCreatedString: string;
	dateCreatedString_EN: string;
	dateModified: Date;
	dateModified_EN: string;
	dateModified_TH: string;
	groupName: string;
	groupName_EN: string;
	groupId: number;
	isActive: boolean;
	isDelete: boolean;
	masterSetupId: number;
	name: string;
	name_EN: string;
	status: string;
	status_EN: string;
	dateCreate: Date;
	masMasSetUpId: number;
	value: string;
	description: string;
	description_EN: string;
	createBy: string;
	modifiedBy: string;
	displayName: string;
	dateFounding: Date;
	dateFoundingString: string;
	bankCodeId: number;
	rateType: number = 1;
	percentageType: number = 1;
	isAddConfig: boolean = true;
	dateFoundingString_TH: string;
	dateFoundingString_EN: string;
	isSelfService: boolean = true;
	remark: string;
	attachments: any[];
	isLimitCompanyRate: boolean = false;
	isPermanentNumber: number = 0;
	conditionType: number = 1;
	monthOfRenew: number = 12;
	maximumOfRenew: number = 1;
	isCalByDayOfPeriod: boolean = false;
}

export class ProvidentFundPercentageModel {
	lengthInMinMonth: number;
	lengthInMaxMonth: number;
	minRankTypeId: number;
	maxRankTypeId: number;
	percentageEmployee: number;
	percentageCompany: number;
	isActive: boolean = true;
	activeId: number = 1;
	providentFundTypeId: number;
}

export class ProvidentFundRateModel {
	lengthInMinMonth: number;
	lengthInMaxMonth: number;
	companyRatePercent: number;
	isActive: boolean = true;
	activeId: number = 1;
	providentFundTypeId: number;
}
export class ProvidentFundPlanModel {
	providentFundPlanId: number;
	providentFundPlanName: string;
	providentFundPlanName_EN: string;
	isActive: boolean = true;
	activeId: number = 1;
	providentFundTypeId: number;
}

export class MasMasSetUpModel {}

export class WelfareShuttleBusEmployeeSearchModel extends MovementSearch {
	timeType: number;
	timeEstimate: Date;
	busLineId: number;
	shiftId: number;
	date: Date;
	organizationId: number;
	companyId: number;
}

export class WelfareSearchModel {
	keyword: string = "";
	takeRow: number = 30;
	skipRow: number = 0;
	employeeId: number;
	companyId: number;
	sorting: string = "";
	date: Date = null;
	organizationId: number = 0;
	rankNo: number = 0;
	welfareId: number = 0;
	documentStatusIds: string = "";
	year: number = 0;
	welfareDocumentId: number = 0;
}
