import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PaymentMethod } from "./payment-method.model";
import { firstValueFrom } from "rxjs";
import { SharingService } from "src/app/shared/sharing.service";

@Injectable()
export class PaymentMethodService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private sharing: SharingService,
	) {}

	getPaymentMethodList() {
		return firstValueFrom(this.http.get(this.apiUrl + "PaymentMethod/GetPaymentMethodList/")).then(
			(res) => <PaymentMethod[]>res,
		);
	}

	getPaymentMethodById(paymentMethodId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "PaymentMethod/GetPaymentMethodById/" + paymentMethodId)).then(
			(res) => <PaymentMethod>res,
		);
	}

	addPaymentMethod(paymentMethod: PaymentMethod) {
		var body = JSON.stringify(paymentMethod);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "PaymentMethod/AddPaymentMethod", body, httpOptions);
	}

	editPaymentMethod(paymentMethod: PaymentMethod) {
		var body = JSON.stringify(paymentMethod);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "PaymentMethod/EditPaymentMethod", body, httpOptions);
	}

	getPaymentMethodIncomeTypesById(search: any) {
		return this.http.get<any>(this.apiUrl + `v1/payment-methods/income-types${this.sharing.getQueryString(search)}`);
	}

	getPaymentMethods() {
		return this.http.get<any>(this.apiUrl + "v2/payment-methods");
	}
}
