import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SharingService } from "src/app/shared/sharing.service";

@Injectable()
export class ImportFileService {
	constructor(
		private http: HttpClient,
		private sharing: SharingService,
		@Inject("API_URL") private apiUrl: string,
	) {}
	//#region LeaveRequest
	getLeaveRequestFileImport() {
		return this.http.get(this.apiUrl + "Leave/GetLeaveRequestFileImport");
	}
	exportExcelLeaveRequestTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "Leave/ExportExcelLeaveRequestTemplate"));
	}
	importLeaveRequestExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/ImportLeaveRequestExcel", body, httpOptions);
	}
	exportTmpLeaveRequestExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Leave/ExportTmpLeaveRequestExcel", body, httpOptions)).then(
			(r) => <any>r,
		);
	}
	//#endregion

	//#region LeaveRequest
	getLeaveUsagesFileImport() {
		return this.http.get(this.apiUrl + "LeaveUsage/GetLeaveUsagesFileImport");
	}
	exportExcelLeaveUsageTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "LeaveUsage/ExportExcelLeaveUsageTemplate"));
	}
	importLeaveUsageExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveUsage/ImportLeaveUsageExcel", body, httpOptions);
	}
	exportTmpLeaveUsageExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "LeaveUsage/ExportTmpLeaveUsageExcel", body, httpOptions)).then(
			(r) => <any>r,
		);
	}
	//#endregion

	//#region MovementHistory
	getMovementHistoryFileImport() {
		return this.http.get(this.apiUrl + "employee-movements/histories/files");
	}
	exportExcelMovementHistoryTemplate(fileName: string = null) {
		return this.http.get(this.apiUrl + "employee-movements/histories/export" + (fileName ? `/${fileName}` : ""));
	}
	importMovementHistoryExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "employee-movements/histories/import", body, httpOptions);
	}
	//#endregion

	//#region ProvidentFund
	getProvidentFundFileImport() {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeProvidentFund/GetProvidentFundFileImport"));
	}

	exportExcelProvidentFundTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeProvidentFund/ExportExcelProvidentFundTemplate"));
	}
	importProvidentFundExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeProvidentFund/ImportProvidentFundExcel", body, httpOptions);
	}
	exportProvidentFundExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(this.apiUrl + "EmployeeProvidentFund/ExportProvidentFundExcel", body, httpOptions),
		).then((r) => <any>r);
	}
	//#endregion

	//#region EmployeeAccumulate
	getEmployeeAccumulatesFileImport() {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeAccumulate/GetEmployeeAccumulatesFileImport"));
	}

	getPositionFileImport() {
		return this.http.get(this.apiUrl + "position/files");
	}
	exportExcelEmployeeAccumulatesTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeAccumulate/ExportExcelEmployeeAccumulatesTemplate"));
	}
	importEmployeeAccumulatesExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeAccumulate/ImportEmployeeAccumulatesExcel", body, httpOptions);
	}
	exportEmployeeAccumulatesExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(this.apiUrl + "EmployeeAccumulate/ExportEmployeeAccumulatesExcel", body, httpOptions),
		).then((r) => <any>r);
	}

	//#endregion

	//#region EmployeeTaxException
	getEmployeeTaxExceptionsFileImport() {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeTaxExemption/GetEmployeeTaxExceptionFileImport"));
	}
	exportExcelEmployeeTaxExceptionTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeTaxExemption/ExportExcelEmployeeTaxExceptionTemplate"));
	}
	importEmployeeTaxExceptionsExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeTaxExemption/ImportEmployeeTaxExceptionExcel", body, httpOptions);
	}
	exportEmployeeTaxExceptionsExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(this.apiUrl + "EmployeeTaxExemption/ExportEmployeeTaxExceptionExcel", body, httpOptions),
		).then((r) => <any>r);
	}

	//#endregion

	//#region IncomeDeductionFile
	getWelfareEmployeeLoanFileImport() {
		return firstValueFrom(this.http.get(this.apiUrl + "FinancialItem/GetWelfareEmployeeLoanFileImport"));
	}
	exportWelfareEmployeeLoanTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "FinancialItem/ExportWelfareEmployeeLoanTemplate"));
	}
	importWelfareEmployeeLoanExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "FinancialItem/importWelfareEmployeeLoanExcel", body, httpOptions);
	}
	exportWelfareEmployeeLoansExcel(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(this.apiUrl + "FinancialItem/ExportWelfareEmployeeLoanExcel", body, httpOptions),
		).then((r) => <any>r);
	}

	importEmployeeProfiles(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Employee/ImportEmployeeProfiles", body, httpOptions)).then(
			(res) => <any>res,
		);
	}
	uploadEmployeeProfiles(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Employee/UploadEmployeeProfiles", body, httpOptions)).then(
			(res) => <any>res,
		);
	}
	//#endregion
	exportDSDExcel(trainingId: number) {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.get(this.apiUrl + `Training/ExportDSDExcel/${trainingId}`, httpOptions)).then(
			(r) => <any>r,
		);
	}

	getSkillsFileImport(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "v1/employee-skills/skill-files", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	employeeSkillImport(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "MasterSetup/ImportSkillSetByExcel", body, httpOptions);
	}

	getEmployeeDiligenceAllowancesFiles(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(
			this.http.post(`${this.apiUrl}v1/employees/diligence-allowances/files`, body, httpOptions),
		).then((res) => <any>res);
	}

	exportEmployeeDiligenceAllowancesExcel(model) {
		return this.http.get<any>(
			this.apiUrl + `v1/employees/diligence-allowances/export-file${this.sharing.getQueryString(model)}`,
		);
	}

	importEmployeeDiligenceAllowancesExcel(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(`${this.apiUrl}v1/employees/diligence-allowances/import`, body, httpOptions);
	}

	getAssessmentScoresFileImport(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "v1/assessments/scores/files", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	exportAssessmentScoresExcel(model) {
		return this.http.get<any>(this.apiUrl + `v1/assessments/scores/export-file${this.sharing.getQueryString(model)}`);
	}

	importAssessmentScoresExcel(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(`${this.apiUrl}v1/assessments/scores/import`, body, httpOptions);
	}

	getTimeStampsFileImport(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "v1/time-stamps/files", body, httpOptions)).then(
			(res) => <any>res,
		);
	}

	exportTimeStampsExcel(model) {
		return this.http.get<any>(this.apiUrl + `v1/time-stamps/export-file${this.sharing.getQueryString(model)}`);
	}

	importTimeStampsExcel(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(`${this.apiUrl}v1/time-stamps/import`, body, httpOptions);
	}

	exportWorkInSiteLocationTemplate(isNeedData: boolean, filename: string) {
		return this.http.get<any>(
			this.apiUrl + `workin-site/location/export-file/${isNeedData}` + (filename ? `/${filename}` : ""),
		);
	}
	importWorkInSiteLocationExcel(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(`${this.apiUrl}workin-site/location/import`, body, httpOptions);
	}
	getWorkInSiteLocationFileImport() {
		return firstValueFrom(this.http.get(this.apiUrl + "workin-site/location/files"));
	}

	//#region Assets
	exportExelAssets(isNeedData = false, fileName = "") {
		let url = `${this.apiUrl}v1/assets/export-files/${isNeedData}`;
		if (fileName && fileName.trim() !== "") {
			url += `?fileName=${fileName}`;
		}
		return this.http.get<any>(url);
	}
	importExelAssets(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(`${this.apiUrl}v1/assets/import`, body, httpOptions);
	}
	getExelFileAssetsImport() {
		return firstValueFrom(this.http.get(`${this.apiUrl}v1/assets/asset-files`));
	}
	//endregion
}
