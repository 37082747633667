import { ModuleWithProviders, NgModule } from "@angular/core";
import { DesignSystemModule } from "./design-system.module";
import { EmpeoCommonModule } from "./empeo-common.module";
import { NgCircleProgressModule } from "ng-circle-progress";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

const modules = [DesignSystemModule, EmpeoCommonModule];

@NgModule({
	imports: [
		...modules,
		NgCircleProgressModule.forRoot({
			radius: 100,
			animationDuration: 1000,
			responsive: true,
			space: -15,
			outerStrokeWidth: 15,
			innerStrokeWidth: 15,
			outerStrokeColor: "#F08A3F",
			innerStrokeColor: "#E4E4E4",
			outerStrokeLinecap: "square",
			titleFontSize: "44",
			subtitleFontSize: "44",
			unitsFontSize: "44",
			titleFontWeight: "600",
			subtitleFontWeight: "600",
			unitsFontWeight: "600",
			titleColor: "#000000",
			subtitleColor: "#000000",
			unitsColor: "#000000",
			showBackground: false,
			showSubtitle: false,
		}),
	],
	exports: [...modules, NgCircleProgressModule],
	providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class ShareModule {
	static forRoot(): ModuleWithProviders<ShareModule> {
		return {
			ngModule: ShareModule,
			providers: [],
		};
	}
	static forChild(): ModuleWithProviders<ShareModule> {
		return {
			ngModule: ShareModule,
			providers: [],
		};
	}
}
