export class EmployeeMovement {
	allowSeeOwnMovement: boolean = true;
	movementTransactionId: number;
	movementCode: string;
	createdBy: string;
	employeeId: number;
	dateCreated: Date;
	dateEffective: Date;
	movementTypeId: number;
	remarks: string;
	isApprove: boolean;
	isWorkInfomation: boolean;
	documentNo: string;
	dateDocument: Date;
	prevEmployeeStatus: number;
	prevEmploymentType: number;
	employeeStatus: number;
	employmentType: number;
	prevOrgLevelOne: number;
	prevOrgLevelTwo: number;
	prevOrgLevelThree: number;
	prevOrgLevelFour: number;
	prevOrgLevelFive: number;
	prevOrgLevelSix: number;
	prevWorkingLocationCode: string;
	prevLocationTypeCode: string;
	prevRankNo: number;
	prevRankName: string;
	prevPositionId: number;
	prevUpperSupervisorId: number;
	prevMentorUserId: number;
	prevShiftId: number;
	prevIsAttendance: boolean;
	orgLevelOne: number;
	orgLevelTwo: number;
	orgLevelThree: number;
	orgLevelFour: number;
	orgLevelFive: number;
	orgLevelSix: number;
	workingLocationCode: string;
	locationTypeCode: string;
	rankNo: number;
	rankName: string;
	positionId: number;
	upperSupervisorId: number;
	mentorUserId: number;
	isAttendance: boolean;
	shiftId: number;
	isEffectived: boolean;
	isIncomeAdjust: boolean;
	isConfirmed: boolean;
	isProbation: boolean;
	isServiceYearsCont: boolean;
	dayOfProbation: number;
	replaceEmployeeId: number;
	dateOrigEmployment: Date;
	calendarId: number;
	paymentMethod: number;
	taxType: number;
	bankCodeId: number;
	companyId: number;
	incomeAmount: number;
	file: string;
	bankAccountNo: string;
	documentStatusId: number;
	processActionName: string;
	processActionName_EN: string;
	dateCreated_TH: string;
	dateCreated_EN: string;
	dateDocument_TH: string;
	dateDocument_EN: string;
	nextApprovalFullName: string;
	nextApprovalFullName_EN: string;
	documentStatusName: string;
	documentStatusName_EN: string;
	requestorFullName: string;
	requestorFullName_EN: string;
	requestorPositionName: string;
	requestorPositionName_EN: string;
	requestorRankName: string;
	requestorPicture: string;
	requestorPictureURL: string;
	pictureThumbnailURL: string;
	userId: string;
	prevOrgLevelOneName: string;
	prevOrgLevelOneName_EN: string;
	prevOrgLevelTwoName: string;
	prevOrgLevelTwoName_EN: string;
	prevOrgLevelThreeName: string;
	prevOrgLevelThreeName_EN: string;
	prevOrgLevelFourName: string;
	prevOrgLevelFourName_EN: string;
	prevOrgLevelFiveName: string;
	prevOrgLevelFiveName_EN: string;
	prevOrgLevelSixName: string;
	prevOrgLevelSixName_EN: string;
	orgLevelOneName: string;
	orgLevelOneName_EN: string;
	orgLevelTwoName: string;
	orgLevelTwoName_EN: string;
	orgLevelThreeName: string;
	orgLevelThreeName_EN: string;
	orgLevelFourName: string;
	orgLevelFourName_EN: string;
	orgLevelFiveName: string;
	orgLevelFiveName_EN: string;
	orgLevelSixName: string;
	orgLevelSixName_EN: string;
	upperSupervisorTransactionId: string;
	prevEmployeeStatusName: string;
	prevEmployeeStatusName_EN: string;
	employeeStatusName: string;
	employeeStatusName_EN: string;
	prevPaymentMethodName: string;
	prevPaymentMethodName_EN: string;
	paymentMethodName: string;
	paymentMethodName_EN: string;
	prevEmploymentTypeName: string;
	prevEmploymentTypeName_EN: string;
	employmentTypeName: string;
	employmentTypeName_EN: string;
	prevOrgDepartmentId: number;
	prevOrgDepartmentStructure: string;
	prevOrgDepartmentStructure_EN: string;
	orgDepartmentId: number;
	orgDepartmentStructure: string;
	orgDepartmentStructure_EN: string;
	prevOrgCompanyStructure: string;
	prevOrgCompanyStructure_EN: string;
	orgCompanyStructure: string;
	orgCompanyStructure_EN: string;
	prevPositionName: string;
	prevPositionName_EN: string;
	positionName: string;
	positionName_EN: string;
	prevRankName_EN: string;
	rankName_EN: string;
	prevUpperSupervisorFullName: string;
	prevUpperSupervisorFullName_EN: string;
	upperSupervisorFullName: string;
	upperSupervisorFullName_EN: string;
	prevDateEmployment_TH: string;
	prevDateEmployment_EN: string;
	dateEmployment_TH: string;
	dateEmployment_EN: string;
	dateEffective_TH: string;
	dateEffective_EN: string;
	nextApprovalEmployeeId: number;
	remark: string;
	processActionId: number;
	nameSurname: string;
	nameSurname_EN: string;
	employeeNo: string;
	nickName: string;
	nickName_EN: string;
	driverNo: string;
	rank: string;
	employeeMovementTransactionLogs: EmployeeMovementLogs[];
	logs: EmployeeMovementLogs[];
	isSelfResign: boolean;
	terminationReasonId: number;
	terminationReasonName: number;
	terminationReasonName_EN: number;
	salaryAdjusts: SalaryAdjustsModel[];
	employeeRequests: any[];
	moreEmployees: any[];
	movementTransactionIds: any[];
	severanceAmount: number;
}
export class SalaryAdjustsModel {
	name: string;
	name_EN: string;
	incomeAmount: number;
	extraAmount?: number;
	category?: number;
	prevIncomeAmount?: number;
	percentageSalaryAdjustment?: number;
	isIncreaseSalaryAdjustment?: number;
}
export class EmployeeMovementLogs {
	movementLogId: number;
	movementTransactionId: number;
	employeeId: number;
	processId: number;
	remark: string;
	createdBy: string;
	dateCreated: Date;
	processAction: string;
	processAction_EN: string;
	employeeName: string;
	employeeName_EN: string;
	pictureThumbnailURL: string;
	pictureURL: string;
	dateProcessAction_TH: string;
	dateProcessAction_EN: string;
}

export class EmployeeMovementBySelected {
	movementTransactionId: number[];
	reason: string;
	processActionId: number;
}
