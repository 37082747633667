import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { RouteURL } from "../enum/route-path.enum";

@Injectable({
	providedIn: "root",
})
export class DevGuardService {
	constructor(private router: Router) {}

	canActivate() {
		if (environment.production === false) {
			return true;
		} else {
			this.router.navigateByUrl("/" + RouteURL.home);
			return false;
		}
	}
}
