import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeLEDService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getEmployeeLED(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeLED/GetEmployeeLED/" + employeeId)).then(
			(res) => <any>res,
		);
	}
	getEmployeeLEDById(caseId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeeLED/GetEmployeeLEDById/" + caseId)).then(
			(res) => <any>res,
		);
	}
	deleteEmployeeLEDById(caseId: number) {
		return firstValueFrom(this.http.delete(this.apiUrl + "EmployeeLED/deleteEmployeeLEDById/" + caseId)).then(
			(res) => <any>res,
		);
	}
	getEmployeeFinancialTransaction(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeLED/GetEmployeeFinancialTransaction/" + employeeId),
		).then((res) => <any>res);
	}
	getEmployeeFinancialTransactionDetail(employeeId: number, caseId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeLED/GetEmployeeFinancialTransactionDetail/" + employeeId + "/" + caseId),
		).then((res) => <any>res);
	}

	addEmployeeLED(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeLED/AddEmployeeLED/", body, httpOptions);
	}

	editEmployeeLED(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.put(this.apiUrl + "EmployeeLED/EditEmployeeLED/", body, httpOptions);
	}
}
