export enum MasterSetup {
	education = 1,
	religion = 2,
	bloodType = 3,
	institution = 4,
	nationality = 5,
	companyGroup = 6,
	skinColor = 7,
	bank = 8,
	marriageStatus = 9,
	militaryStatus = 10,
	educationDocuments = 11,
	taxType = 12,
	relationship = 13,
	documentType = 14,
	occupation = 15,
	reasonToLeave = 16,
	courseTypes = 17,
	// nationalitySSO = 17,
	typeCardSSO = 18,
	instructor = 19,
	providentFundName = 20,
	cardType = 21,
	lockerLocation = 22,
	cardCancelReason = 23,
	lockerCancelReason = 24,
	restaurant = 25,
	typeDocument = 26,
	employementType = 27,
	companyRegulation = 28,
	courseCategory = 29,
	workLocation = 30,
	//hospitalType = 31,
	disease = 32,
	//patientType = 33,
	factorType = 34,
	prefix = 35,
	skillType = 37,
	skillSet = 38,
	reasonToSeparation = 39,
	jobLocation = 42,
}

export enum MasterTypeEnum {
	fieldType = 20000,
	education = 100000,
	relationShip = 130000,
	child = 130010,
	religion = 200000,
	bloodType = 300000,
	nationality = 500000,
	marriageStatus = 900000,
	militaryStatus = 1000000,
	taxType = 1200000,
	single = 1200001,
	typeCardSSO = 1900000,
	TerminationReasonSSO = 17000,
	AssetStatus = 60000,
}

export enum SystemRegisNo {
	customField = 36,
	skillType = 37,
	skillSet = 38,
	assetType = 41,
	jobLocation = 42,
}

export enum MasterTypeTerminationSSO {
	LayOff = 17003,
}
