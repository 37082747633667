import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { isNullOrUndefined } from "../sharing.service";

@Directive({
	selector: "[ScrollControl]",
	standalone: true,
})
export class ScrollDirective {
	isOnDiv: boolean = false;
	@HostListener("mouseenter") onMouseEnter() {
		this.isOnDiv = true;
	}
	@HostListener("mouseleave") onMouseLeave() {
		this.isOnDiv = false;
	}
	/** **/
	@Input() scrollActive: boolean = true;
	@Input() scrollDisable: boolean = false;
	@Input() onmouseenter: boolean = true;
	@Output() scrollEnd = new EventEmitter<boolean>();
	constructor(private el: ElementRef) {
		document.addEventListener(
			"scroll",
			(event) => {
				if (!this.scrollActive || isNullOrUndefined(this.el.nativeElement)) {
					return;
				}

				if (this.onmouseenter && this.scrollDisable == false && this.isOnDiv) {
					this.onListenerTriggered(event);
				} else if (this.onmouseenter == false && this.scrollDisable == false) {
					if (isNullOrUndefined(document.getElementById(this.el.nativeElement.id))) {
						return;
					}
					this.onscroll();
				}
			},
			false,
		);
	}
	onListenerTriggered(event): void {
		const elementHeight: number = this.el.nativeElement.offsetHeight;
		const clientHeight: number = window.innerHeight;
		if (elementHeight < clientHeight) {
			return;
		}
		const scrollContainerScrollTop = this.el.nativeElement.offsetParent.scrollTop + clientHeight;
		const currentScrollTop: number = scrollContainerScrollTop;
		if (currentScrollTop / elementHeight > 0.9) {
			this.scrollEnd.emit(true);
		}
	}

	onscroll() {
		let divParent: any = document.getElementById(this.el.nativeElement.id).offsetParent;
		let lastHeight = divParent.offsetHeight + divParent.offsetTop - document.body.offsetHeight;
		if (document.documentElement.scrollTop >= lastHeight) {
			this.scrollEnd.emit(true);
		}
	}
}
