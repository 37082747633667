import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";

@Injectable()
export class LeaveCollectService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	calculateLeaveDurationByHoursForCollect(
		employeeId: number,
		dateFrom: Date,
		timeFrom: Date,
		timeTo: Date,
		leaveTypeId: number,
		leaveRequestId: number = 0,
	) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({
			employeeId: employeeId,
			dateFrom: dateFrom,
			timeFrom: timeFrom,
			timeTo: timeTo,
			leaveTypeId: leaveTypeId,
			leaveRequestId: leaveRequestId,
		});
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "LeaveType/CalculateLeaveDurationByHoursForCollect", body, httpOptions);
	}
}
