import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { isNullOrUndefined } from "src/app/shared/sharing.service";
import { AssessmentModel } from "../../../assessment/shared/assessment.model";
import { DataSharingService } from "../../../core";
import { GofiveCoreModuleEnum } from "../../enum/module.enum";
import { BaseMicroComponent } from "../base-micro";
import { AssessmentType } from "../../enum/assessment-type.enum";
import { URLEncoderService } from "../../service/url-encoder.service";
import { LazyElementsModule } from "@angular-extensions/elements";

@Component({
	selector: "gfc-assessment",
	templateUrl: "./assessment.component.html",
	standalone: true,
	imports: [LazyElementsModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AssessmentComponent extends BaseMicroComponent implements OnInit {
	@Input() empeoHeight: boolean = false;
	@Output() isChangedAnswer: EventEmitter<boolean> = new EventEmitter();
	@Output() successAssessmentEvent: EventEmitter<any> = new EventEmitter();
	@Output() isStartedSurvey: EventEmitter<boolean> = new EventEmitter();
	private currentAssessment$: Subscription;

	userInfo: any;
	assessmentId: number = 0;
	@Input() isDialog: boolean = false;
	assessmentObs: AssessmentModel = new AssessmentModel();
	isWebView: boolean = false;
	isSubordinate: boolean = false;

	public tokenCore: string;
	override module = GofiveCoreModuleEnum.assessment;

	constructor(
		public injector: Injector,
		public data: DataSharingService,
		private router: Router,
		private urlEncoder: URLEncoderService,
	) {
		super(injector);
	}

	ngOnInit() {
		this.tokenCore = this.token;
		if (!this.tokenCore) {
			this.tokenCore = this.router.routerState.snapshot.root.queryParams["token"];
		}
		this.currentAssessment$ = this.data.currentAssessment.subscribe((data) => {
			if (data?.assessmentId) {
				this.assessmentId = (!isNullOrUndefined(data) ? data : new AssessmentModel()).assessmentId;
				this.assessmentObs.assessorEmployeeId = data.assessorEmployeeId;
				this.assessmentObs.assesseeEmployeeId = data.assesseeEmployeeId;
				this.assessmentObs.assessmentId = data.assessmentId;
				this.assessmentObs.emailAddress = data.emailAddress;
				this.assessmentObs.no = data.no;
				this.assessmentObs.isPreview = data.isPreview;
				this.assessmentObs.masterSetId = data.masterSetId || 0;
				this.assessmentObs.assessmentTypeId = data.assessmentTypeId;
				this.assessmentObs.isOKR = data?.isOKR ?? false;
				this.assessmentObs.isPeerProbation = data?.isPeerProbation;

				this.isWebView = data.isWebView;
				this.isSubordinate =
					this.assessmentObs.assessmentTypeId === AssessmentType.assessmentSubordinate ||
					this.assessmentObs.assessmentTypeId === AssessmentType.assessmentHeadSubordinate;
				if (this.isSubordinate && !this.isWebView) {
					const encodedURL = this.urlEncoder.encodeURL(data.setName);
					this.router.navigate([`/pms/${encodedURL}/supervisor-feedback/${data.no}`]);
				}
			} else {
				this.router.navigate(["/pms"]);
			}
		});
	}

	ngOnDestroy() {
		this.currentAssessment$ && this.currentAssessment$.unsubscribe();
	}

	onChangedAnswer(e) {
		this.isChangedAnswer.emit(e.detail);
	}

	back() {
		this.router.navigate(["/pms"]);
	}

	receiveBackEvent(e: boolean) {
		if (e && !this.isDialog) {
			this.back();
		}
	}
	receiveSuccessEvent(event) {
		this.successAssessmentEvent.emit(event);
	}

	onStartedSurvey(event) {
		this.isStartedSurvey.emit(event?.detail);
	}
}
