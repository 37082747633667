import { Component, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataSharingService } from "src/app/core";
import { EmployeeFinancialService } from "src/app/employee-movements/employee-payroll/shared/employee-financial.service";
import { SalaryAdjustmentHistory } from "src/app/employee-movements/shared/employee-movements.model";
import { FinancialCategory } from "../../enum/financial-category.enum";
import { ApiResult } from "../../models/api-result.model";
import { BusinessCode } from "../../enum/businessCode.enum";
import { DateFormat, AngularCommonModule } from "@gofive/angular-common";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Subscription, firstValueFrom } from "rxjs";
import { EmployeeMovementService } from "src/app/employee-movements/employee-movement/shared/employee-movement.service";
import { EmployeeMovement } from "src/app/employee-movements/employee-movement/shared/employee-movement.model";
import { ProcessAction } from "../../enum/process-action.enum";
import { ToastHelperService } from "../../service/toast-helper.service";
import { SessionStorageKey } from "../../enum/local-storage-key";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NavigationModule } from "@gofive/design-system-navigation";
import { NgClass, NgTemplateOutlet, NgIf, NgFor, AsyncPipe, DecimalPipe } from "@angular/common";
import { TableModule } from "@gofive/design-system-table";

@Component({
	selector: "app-salary-adjustment-history",
	templateUrl: "./salary-adjustment-history.component.html",
	styleUrls: ["./salary-adjustment-history.component.scss"],
	standalone: true,
	imports: [
		NgClass,
		NavigationModule,
		NgTemplateOutlet,
		NgIf,
		NgFor,
		NgxSkeletonLoaderModule,
		TooltipModule,
		AsyncPipe,
		DecimalPipe,
		TranslateModule,
		AngularCommonModule,
		TableModule,
	],
})
export class SalaryAdjustmentHistoryComponent implements OnInit, OnDestroy, OnChanges {
	@Input() isShowSalary: boolean = false;
	@Input() isMobile: boolean = true;
	@Input() isAllowDelete: boolean = false;
	@Input() employeeId: number = 0;
	@Input() isActive2FA: boolean = false;
	@Input() isAdjSalary: boolean = false;
	mfaToken: string = null;
	language: string = null;
	toggleIndex: number = -1;
	salaryAdjustmentHistory: SalaryAdjustmentHistory[];
	bonusAdjustmentHistory: SalaryAdjustmentHistory[];
	maskAmount: string = "xxxx.xx";
	currentIndex: number = 0;
	salaryIndex: number = 0;
	bonusIndex: number = 1;
	loadingData: boolean = true;
	counter = Array;
	movement$: Subscription;
	onAdd$: Subscription;
	language$: Subscription;
	isSmallScreen: boolean = false;

	get isSalaryTab() {
		return this.currentIndex === this.salaryIndex;
	}
	get isBonusTab() {
		return this.currentIndex === this.bonusIndex;
	}
	get dataList() {
		if (this.isSalaryTab) return this.salaryAdjustmentHistory;
		else if (this.isBonusTab) return this.bonusAdjustmentHistory;
		else return [];
	}
	get dateFormat() {
		return DateFormat;
	}

	constructor(
		private router: ActivatedRoute,
		public data: DataSharingService,
		private ngZone: NgZone,
		private translate: TranslateService,
		private _dialogService: DialogService,
		private _toastHelperService: ToastHelperService,
		private employeeFinancialService: EmployeeFinancialService,
		private employeeMovementService: EmployeeMovementService,
	) {
		this.isSmallScreen = window.innerWidth < 1024;
	}

	@HostListener("window:resize", ["$event"])
	onResize() {
		this.isSmallScreen = window.innerWidth < 1024;
	}

	ngOnInit() {
		if (this.isMobile) {
			this.router.queryParamMap.subscribe((params) => {
				this.employeeId = params.has("employeeId") ? JSON.parse(params.get("employeeId")) : 0;
				this.isShowSalary = params.has("isShowSalary") ? JSON.parse(params.get("isShowSalary")) : false;
				this.language = params.get("lang") === "th-TH" ? "th-TH" : "en-GB";
				this.mfaToken = params.get("mfaToken");
				this.data.setLang(this.language);
			});

			window["angularComponentReference"] = {
				component: this,
				zone: this.ngZone,
				setMfaToken: (e = null) => {
					this.ngZone.run(() => {
						this.setMfaToken(e);
					});
				},
				goBack: () => {
					window.close();
				},
			};
		} else {
			this.language$ = this.data.currentLanguage.subscribe((lang) => {
				this.language = lang;
			});
		}
		this.getSalaryAdjustmentHistories();
		this.getBonusAdjustmentHistories();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			(!this.isMobile && this.isActive2FA && changes?.isShowSalary?.currentValue) ||
			changes?.isAdjSalary?.currentValue
		) {
			this.get2FAKey();
		}
	}
	get2FAKey() {
		this.mfaToken = sessionStorage.getItem(SessionStorageKey.TwoFA);
		this.getSalaryAdjustmentHistories();
		this.getBonusAdjustmentHistories();
	}

	ngOnDestroy(): void {
		if (this.movement$) this.movement$.unsubscribe();
		if (this.onAdd$) this.onAdd$.unsubscribe();
		if (this.language$) this.language$.unsubscribe();
	}

	toggleExpand(index) {
		if (this.toggleIndex !== index) {
			this.toggleIndex = index;
		} else {
			this.toggleIndex = -1;
		}
	}

	onchangeTab() {
		this.toggleIndex = -1;
	}

	getAdjustmentHistories(category: FinancialCategory) {
		const searchModel = {
			category: category,
			mfaToken: this.mfaToken,
		};
		return this.employeeFinancialService.getEmployeeSalaryAdjustmentHistories(this.employeeId, searchModel);
	}

	getSalaryAdjustmentHistories() {
		this.getAdjustmentHistories(FinancialCategory.baseSalary).then((res) => {
			const apiResult = <ApiResult>res;
			if (apiResult?.status?.code == BusinessCode.Successful) {
				this.salaryAdjustmentHistory = <SalaryAdjustmentHistory[]>res.data;
			} else {
				this.salaryAdjustmentHistory = [];
			}
			this.loadingData = false;
		});
	}

	getBonusAdjustmentHistories() {
		this.getAdjustmentHistories(FinancialCategory.bonus).then((res) => {
			const apiResult = <ApiResult>res;
			if (apiResult?.status?.code == BusinessCode.Successful) {
				this.bonusAdjustmentHistory = <SalaryAdjustmentHistory[]>res.data;
			} else {
				this.bonusAdjustmentHistory = [];
			}
			this.loadingData = false;
		});
	}

	setMfaToken(value: string) {
		this.mfaToken = value;
		this.getSalaryAdjustmentHistories();
		this.getBonusAdjustmentHistories();
	}

	cancelMovementIncome(data) {
		const dialogModel = <DialogInformationModel>{
			title: this.translate.instant("common_confirmation"),
			description: this.translate.instant("confirmation_delete"),
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm"),
			textButtonCancel: this.translate.instant("common_cancel"),
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				this.movement$ = this.employeeMovementService
					.getEmployeeMovementTransactionById(data.movementTransactionId)
					.subscribe((res) => {
						let employeeMovement = <EmployeeMovement>res;
						employeeMovement.processActionId = ProcessAction.canceledDocument;
						employeeMovement.remark = "ลบ ประวัติการปรับเงินเดือน";
						if (this.onAdd$) this.onAdd$.unsubscribe();
						this.onAdd$ = this.employeeMovementService
							.addProcessActionMovementTransaction(employeeMovement)
							.subscribe((result) => {
								const apiResult = <ApiResult>result;
								apiResult.message = this.translate.instant("deleted_successfully");
								this._toastHelperService.alertApiResult(apiResult);
								if (apiResult.result) {
									this.loadingData = true;
									this.getSalaryAdjustmentHistories();
								}
							});
					});
			}
		});
	}
}
