import { DOCUMENT, NgClass, NgStyle, AsyncPipe, DecimalPipe, DatePipe, NgTemplateOutlet } from "@angular/common";
import {
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	EventEmitter,
	Inject,
	Injector,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewEncapsulation,
	forwardRef,
	signal,
} from "@angular/core";
import { Subscription, firstValueFrom } from "rxjs";
import { TwoFactorAuthenticationComponent } from "src/app/shared/component/two-factor-authentication/two-factor-authentication.component";
import { CommonService } from "src/app/shared/service/common.service";
import { isNullOrUndefined } from "src/app/shared/sharing.service";
import { environment } from "src/environments/environment";
import { DataSharingService, UserControlService, UserInfoService } from "../../core";
import {
	EmployeeMovement,
	EmployeeMovementLogs,
	SalaryAdjustsModel,
} from "../../employee-movements/employee-movement/shared/employee-movement.model";
import { EmployeeMovementService } from "../../employee-movements/employee-movement/shared/employee-movement.service";
import { WorkflowStep } from "../../setting/workflow/shared/workflow-step.model";
import { WorkflowSearch } from "../../setting/workflow/shared/workflow.model";
import { DocumentStatus } from "../../shared/enum/document-status.enum";
import { LeaveStatus } from "../../shared/enum/leave-category.enum";
import { ProcessAction } from "../../shared/enum/process-action.enum";
import { ApiResult } from "../../shared/models/api-result.model";
import { SharingService } from "../../shared/sharing.service";
import { Dropdown } from "../../shared/models/dropdown.model";
import { ActivatedRoute, Router } from "@angular/router";
import { EmployeeTimeAttendanceService } from "src/app/employee-movements/employee-time-attendance/shared/employee-time-attendance.service";
import { UserTimeAttendance } from "src/app/employee-movements/employee-time-attendance/shared/user-time-attendance.model";
import { EmployeeLeaveUsageService } from "src/app/employee-movements/employee-leave-usage/shared/employee-leave-usage.service";
import { LeaveUsageModel } from "src/app/document-forms/shared/document-forms.model";
import { AssessmentType } from "src/app/shared/enum/assessment-type.enum";
import { EmployeeMovementsService } from "src/app/employee-movements/shared/employee-movements.service";
import { Employee } from "src/app/employee-movements/shared/employee-movements.model";
import { Module } from "src/app/shared/enum/module.enum";
import { EmployeeAssessmentService } from "src/app/employee-movements/employee-assessment/shared/employee-assessment.service";
import { AssessmentScoreSearch } from "src/app/assessment/shared/assessment.model";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DropdownSelectMode, DropdownModule } from "@gofive/design-system-dropdown";
import { MovementService } from "../movement.service";
import { DateFormat, AngularCommonModule } from "@gofive/angular-common";
import { ChartType } from "src/app/shared/enum/chart-type.enum";
import { EmployeeService } from "src/app/employee-movements/employee/shared/employee.service";
import { InfoBarType, BaseModule } from "@gofive/design-system-base";
import { DialogService, DialogModule, DialogInformationModel } from "@gofive/design-system-dialog";
import { FinancialCategory } from "src/app/shared/enum/financial-category.enum";
import { ThaiDatePipe } from "../../shared/pipe/thai-date.pipe";
import { CategoryButtonPipe } from "../../shared/pipe/category-btn.pipe";
import { MovementSidebarAddComponent } from "../movement-sidebar-add/movement-sidebar-add.component";
import { EmployeeAssessmentComponent } from "../../shared/module/employee-assessment/employee-assessment.component";
import { EmployeeLeaveUsageComponent } from "../../employee-movements/employee-leave-usage/employee-leave-usage.component";
import { WorkflowLogComponent } from "../../shared/component/workflow-log/workflow-log.component";
import { LoadingViewComponent } from "../../shared/template/loading-view/loading-view.component";
import { ButtonModule } from "@gofive/design-system-button";
import { InputModule } from "@gofive/design-system-input";
import { CalendarModule } from "@gofive/design-system-calendar";
import { FormsModule } from "@angular/forms";
import { BadgeModule } from "@gofive/design-system-badge";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { AvatarModule } from "@gofive/design-system-avatar";
import { ColumnConfiguration, StringColumnConfiguration } from "@gofive/design-system-table";
import { AssetsModel } from "src/app/shared/models/assets.model";
import { AssetSlideBarComponent } from "src/app/shared/component/assets-sidebar/assets-sidebar.component";
import { AssetsManagementTableComponent } from "src/app/shared/component/assets-management-table/assets-management-table.component";
import { AssetsService } from "src/app/shared/service/assets.service";
@Component({
	selector: "app-movement-detail",
	templateUrl: "./movement-detail.component.html",
	styleUrls: ["./movement-detail.component.scss"],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		AssetSlideBarComponent,
		AssetsManagementTableComponent,
		NgTemplateOutlet,
		TooltipModule,
		DropdownModule,
		NgxSkeletonLoaderModule,
		BadgeModule,
		AngularCommonModule,
		FormsModule,
		NgClass,
		CalendarModule,
		BaseModule,
		InputModule,
		ButtonModule,
		LoadingViewComponent,
		NgStyle,
		WorkflowLogComponent,
		DialogModule,
		EmployeeLeaveUsageComponent,
		EmployeeAssessmentComponent,
		forwardRef(() => MovementSidebarAddComponent),
		AsyncPipe,
		DecimalPipe,
		DatePipe,
		CategoryButtonPipe,
		TranslateModule,
		ThaiDatePipe,
		AvatarModule,
	],
})
export class MovementDetailComponent implements OnInit {
	//#region Variable
	private subscription: Subscription;
	private userInfo$: Subscription;
	public onAdd$: Subscription;
	private movement$: Subscription;
	private currentEmpId$: Subscription;
	upperSupervisorId: number;

	public description: string;
	public processActions: any = null;
	public processActionReturn: any;
	public processActionSend: any;
	public processActionApprove: any;
	public processActionReject: any;
	public processActionCancel: any;

	public processActionId: number;
	public historyMessage: any[];
	public isClicked: boolean = false;
	public id: number;
	public currentEmpId: number;
	public cancelProcess: number;
	public employeeId: number = 0;
	public idBtn: number;
	public colorAnimation: string;

	public isSupervisor: boolean = false;
	tokenParams: any;
	setId: any;
	get DateFormat() {
		return DateFormat;
	}
	get FinancialCategory() {
		return FinancialCategory;
	}
	public userInfo: any;
	public documentStatus: boolean = false;
	public isloadImage: boolean = true;
	public isCancel: boolean = false;
	public workflowSteps: WorkflowStep[] = [];
	public searchWorkflow: WorkflowSearch;
	public isLoadingDisplay: boolean = true;
	public dateSendDocument: Date;
	public employeeMovement: EmployeeMovement = new EmployeeMovement();
	public employee: Employee = new Employee();
	public displayMask: boolean = true;
	public maskAmount: string = "xx,xxx.xx";
	public logs: EmployeeMovementLogs[];

	public displayWorkingSummary: boolean = true;
	public hasAttendanceSummary: boolean = false;
	public hasLeaveSummary: boolean = false;
	public hasAssessmentSummary: boolean = false;

	public displayLeaveBenefitModal: boolean = false;
	public displayAssessmentSummaryModal: boolean = false;

	public currentYear: number;
	public yearId: any;
	public refYearDDL: Dropdown[];

	public isLoadUserTimeAttendance: boolean = true;
	public isLoadLeaveUsages: boolean = true;
	public isLoadAssessments: boolean = true;

	public userTimeAttendance: UserTimeAttendance = new UserTimeAttendance();
	public leaveUsages: LeaveUsageModel[] = [];
	public assessments: any[] = [];

	public workInRole: boolean = false;
	public tabPermissionPayroll: boolean = false;
	public permissionDocumentPromotion: boolean = false;
	public permissionDocumentPayroll: boolean = false;
	public benefitRole: boolean = false;
	public assessmentRole: boolean = false;
	public isAttendance: boolean = false;
	public hasAssessment: boolean = false;
	public isEmployeeView: boolean = false;
	public isCreated: boolean = false;
	public isMe: boolean = false;
	public isSidebarMovement: boolean = false;
	public sortOrder: string = "Cost DESC";
	public tmpEmployeeRequests: any[] = [];
	public isLockSelect: boolean = true;

	public assetList: AssetsModel[] = [];
	public displayAssetsSidebar: boolean = false;
	public selectAssetId: number;
	public sortOrderTable: string = "";
	public paddingTable: string = "12px 16px";
	public columns: ColumnConfiguration[] = [
		{
			...new StringColumnConfiguration("Name", null, "asset_name"),
			width: "70%",
			minWidth: "70%",
			textAlign: "left",
			showIconSort: true,
			paddingHeader: this.paddingTable,
			paddingContent: this.paddingTable,
		},
		{
			...new StringColumnConfiguration("Cost", null, "asset_value"),
			width: "30%",
			minWidth: "180px",
			textAlign: "right",
			showIconSort: true,
			paddingHeader: this.paddingTable,
			paddingContent: this.paddingTable,
		},
	];

	private assessmentSearchModel: AssessmentScoreSearch = new AssessmentScoreSearch();

	public fieldsDDL = { text: "name", value: "id" };
	public fieldsDDL_EN = { text: "name_EN", value: "id" };
	public moreEmpFieldDDL = {
		text: "nameSurname",
		value: "employeeId",
		picture: "pictureThumbnailURL",
		detail: "positionName",
	};

	public moreEmpFieldDDL_EN = {
		text: "nameSurname_EN",
		value: "employeeId",
		picture: "pictureThumbnailURL",
		detail: "positionName_EN",
	};
	private isDataLoaded = false;
	public scoreId: number = 0;
	get enumLeaveStatus() {
		return LeaveStatus;
	}
	get enumProcessAction() {
		return ProcessAction;
	}
	isMobile: boolean = false;
	@Input() movementTransactionId: any;
	@Input() allowManageLeave: boolean = true;
	@Input() isGroupMovement: boolean = false;
	@Input() isMovementTab: boolean = false;
	@Output() statusEvent = new EventEmitter<boolean>();
	@Output() backSidebar = new EventEmitter<boolean>();
	@Output() closeEvent = new EventEmitter<boolean>();
	//#endregion

	optionDownloadCertificate: any;
	private resolver: ComponentFactoryResolver;
	private injector: Injector;
	private renderer: Renderer2;
	private appRef: ApplicationRef;
	constructor(
		public dataSharing: DataSharingService,
		public sharingService: SharingService,
		public _dialogService: DialogService,
		public _toastHelperService: ToastHelperService,
		public employeeMovementService: EmployeeMovementService,
		private movementService: MovementService,
		resolver: ComponentFactoryResolver,
		public translate: TranslateService,
		injector: Injector,
		@Inject(DOCUMENT) document,
		renderer: Renderer2,
		appRef: ApplicationRef,
		private router: Router,
		private commonService: CommonService,
		private employeeTimeAttendanceService: EmployeeTimeAttendanceService,
		private employeeLeaveUsageService: EmployeeLeaveUsageService,
		private employeeService: EmployeeService,
		private employeeMovementsService: EmployeeMovementsService,
		private userControlService: UserControlService,
		private route: ActivatedRoute,
		private employeeAssessmentService: EmployeeAssessmentService,
		public _cdr: ChangeDetectorRef,
		private userInfoService: UserInfoService,
		public assetsService: AssetsService,
	) {
		this.resolver = resolver;
		this.injector = injector;
		this.renderer = renderer;
		this.appRef = appRef;
		this.language = this.router.routerState.snapshot.root.queryParams["lang"];
		if (!document.getElementById("assessment-js")) {
			let script = document.createElement("script");
			script.type = "text/javascript";
			script.src = "https://dev.tks.co.th/gofive-core/assessment/assets/js/assessment.js";
			script.id = "assessment-js";
			document.head.appendChild(script);
		}
		this.movementTransactionId = this.router.routerState.snapshot.root.queryParams["movementTransactionId"];
		if (this.movementTransactionId > 0) {
			this.isMobile = true;
			this.tokenParams = this.router.routerState.snapshot.root.queryParams["token"];
			this.dataSharing.setLang(this.language);
		}
	}
	public language: string;
	private language$: Subscription;

	get enumDocumentStatus() {
		return DocumentStatus;
	}
	get assessmentTypeEnum() {
		return AssessmentType;
	}

	showLog: any;
	ngOnInit() {
		window["goBack"] = () => {
			if (this.isSidebarMovement) {
				this.closeSidebarEdit();
			} else {
				if (window["closeWebView"]) {
					window["closeWebView"](JSON.stringify({ event: "close" }));
				}
			}
		};

		this.userInfo$ = this.dataSharing.currentUserInfo.subscribe((user) => {
			if (jQuery.isEmptyObject(user)) {
				firstValueFrom(this.userInfoService.getCurrentUserInfo()).then((profile) => {
					this.dataSharing.setUserInfo(profile);
				});
			} else {
				this.userInfo = user;
				this.hasAssessment = this.userInfo.hasAssessment;
				this.isSupervisor = this.userInfo.isSupervisor;
				this.cancelProcess = ProcessAction.canceledDocument;
				//#region load data
				this.subscription = this.dataSharing.currentdataSourceMovementTransactionId.subscribe((data) => {
					this.id = data;
					this.isLoadingDisplay = true;
					if (this.id != 0 || this.movementTransactionId > 0) {
						if (this.id <= 0) {
							this.id = this.movementTransactionId;
						}
						this.loadData();
					}
				});

				this.isEmployeeView = this.route.snapshot.url.join("/").includes("employee");
			}
		});

		//#end region
	}

	//#region Destroy unsubscribe
	ngOnDestroy() {
		if (this.language$) this.language$.unsubscribe();
		if (this.listPeople$) this.listPeople$.unsubscribe();
		if (this.subscription) this.subscription.unsubscribe();
		if (this.userInfo$) this.userInfo$.unsubscribe();
		if (this.onAdd$) this.onAdd$.unsubscribe();
		if (this.movement$) this.movement$.unsubscribe();
		if (this.currentEmpId$) this.currentEmpId$.unsubscribe();
		this.processActions = null;
		this.isMovementTab = false;
	}
	get DropdownSelectMode() {
		return DropdownSelectMode;
	}
	get DocumentStatus() {
		return DocumentStatus;
	}
	get ProcessAction() {
		return ProcessAction;
	}
	//#endregion
	downloadDocument(event) {
		this.movementService.downloadDocument(this.movementTransactionId, event.value);
	}
	get InfoBarType() {
		return InfoBarType;
	}
	changeDateEffective(event) {
		const date = event.value;
		this.dateEffectiveInfo = new Date(date);
		this.dateEffectiveInfo.setDate(new Date(date).getDate() - 1);
	}
	//#region Load data
	isLoadDDLUpper = true;
	dateEffective: Date;
	dateEffectiveInfo: Date;

	closeSidebarEdit() {
		this.isSidebarMovement = false;
		this._cdr.detectChanges();
	}
	baseSalary = signal(new SalaryAdjustsModel());
	salaryAdjustList = signal([]);
	salaryAdjustPrevList = signal([]);
	reasonToResign: any;
	loadData() {
		// if (isMe) {
		// }
		this.optionDownloadCertificate = [
			{ text: this.translate.instant("common_file_thai"), value: 1 },
			{ text: this.translate.instant("common_file_english"), value: 2 },
		];
		this.employeeMovementService.getProcessActionByMovementTransactionId(this.id).then((res) => {
			this.processActions = res;
			if (res) {
				this.processActionReturn = this.processActions.find((f) => f.id == ProcessAction.returnDocument);
				this.processActionSend = this.processActions.find((f) => f.id == ProcessAction.sendDocument);
				this.processActionApprove = this.processActions.find((f) => f.id == ProcessAction.approvedDocument);
				this.processActionReject = this.processActions.find((f) => f.id == ProcessAction.rejectedDocument);
				this.processActionCancel = this.processActions.find((f) => f.id == ProcessAction.canceledDocument);
			}
		});
		this.movement$ = this.employeeMovementService.getEmployeeMovementTransactionById(this.id).subscribe((res) => {
			this.employeeMovement = <EmployeeMovement>res;
			this.isGroupMovement = this.employeeMovement?.employeeRequests?.length > 1;
			if (this.isMovementTab) {
				this.isGroupMovement = false;
			}
			if (
				this.isGroupMovement &&
				(this.employeeMovement.movementTypeId == ProcessAction.resign ||
					this.employeeMovement.movementTypeId == ProcessAction.changeOfSupervisor ||
					this.employeeMovement.movementTypeId == ProcessAction.massTransfer)
			) {
				this.employeeMovement.movementCode = null;
				this.tmpEmployeeRequests = Object.assign([], this.employeeMovement?.employeeRequests);
				if (this.tmpEmployeeRequests?.length > 5) {
					let data: any[] = Object.assign([], this.tmpEmployeeRequests);
					this.tmpEmployeeRequests = data?.slice(0, 4);
					this.employeeMovement.moreEmployees = data?.slice(4);
				}
			}

			this.employeeMovement.allowSeeOwnMovement = true;
			if (this.employeeMovement.allowSeeOwnMovement) {
				this.isMe = this.userInfo?.employeeId == this.employeeMovement?.employeeId;
				this.isCreated = this.userInfo?.userId == this.employeeMovement?.createdBy;
				this.dateEffective = this.employeeMovement.dateEffective;
				this.upperSupervisorId = this.employeeMovement.upperSupervisorId;
				this.showLog = this.employeeMovement.logs?.find((log) => log.processId == ProcessAction.returnDocument);
				this.commonService
					.getAllowPermissionPayroll(this.employeeMovement.employeeId, this.employeeMovement.companyId)
					.subscribe((r) => {
						this.tabPermissionPayroll = r.allowGrant;
					});
				let salary = this.employeeMovement.salaryAdjusts?.find((f) => f.category == FinancialCategory.baseSalary);
				this.baseSalary.set(salary);

				if (salary?.extraAmount > 0) {
					this.employeeMovement.salaryAdjusts.unshift({
						name: this.translate.instant("mas015_bonus"),
						name_EN: this.translate.instant("mas015_bonus"),
						incomeAmount: salary?.extraAmount,
					});
				}
				this.salaryAdjustList.set(
					this.employeeMovement.salaryAdjusts?.filter(
						(f) => f.category != FinancialCategory.baseSalary && f.incomeAmount > 0,
					),
				);
				this.salaryAdjustPrevList.set(
					this.employeeMovement.salaryAdjusts?.filter(
						(f) => f.category != FinancialCategory.baseSalary && f.prevIncomeAmount > 0,
					),
				);
				this.documentStatus = this.employeeMovement.documentStatusId == DocumentStatus.approved ? true : false;
				if (!isNullOrUndefined(this.employeeMovement)) {
					this.employeeId = this.employeeMovement.employeeId;
					this.displayWorkingSummary =
						this.employeeMovement.movementTypeId == ProcessAction.promote ||
						this.employeeMovement.movementTypeId == ProcessAction.raiseTheSalary ||
						this.employeeMovement.movementTypeId == ProcessAction.appoint ||
						this.employeeMovement.movementTypeId == ProcessAction.resign ||
						this.employeeMovement.movementTypeId == ProcessAction.permanent;

					if (this.displayWorkingSummary) {
						var isOriginalPic = false;
						this.employeeMovementsService.getEmployeeById(this.employeeId, isOriginalPic).then((emp) => {
							this.employee = emp;
							this.isAttendance = !isNullOrUndefined(this.employee) ? this.employee.isAttendance : false;
						});
						this.loadPermissionWorkingSummary();
						this.loadDDLYear();
					}

					if (this.employeeMovement.movementTypeId == ProcessAction.resign) {
						this.listPeople$ = this.commonService.getPeople().subscribe((people) => {
							this.listPeople = people.data.filter((e) => e.employeeId != this.employeeMovement.employeeId);
							if (this.employeeId > 0) {
								this.employeeMovementsService
									.getSupervisorByEmployeeId(this.employeeMovement.employeeId, ChartType.under)
									.then((supervisor) => {
										var filter = supervisor.data.filter((e) => e.employeeId != this.employeeMovement.employeeId); // ได้ตัวเองออกไปด้วย เลยกรองออก
										this.countUnder = filter.length;
									})
									.then(() => {
										var has = this.listPeople.find((r) => r.employeeId == this.employeeMovement.upperSupervisorId);
										if (has == null) {
											var queryParam = {
												employeeIds: [this.employeeMovement?.upperSupervisorId?.toString()],
											};
											firstValueFrom(this.employeeService.getProfilesByEmployeeIds(queryParam)).then(
												(profilesByEmployeeId) => {
													var modelRes = profilesByEmployeeId;
													modelRes?.forEach((m) => {
														m.fullname = m.fullName;
														m.fullname_EN = m.fullName_EN;
														this.listPeople.push(m);
													});
													this.isLoadDDLUpper = false;
												},
											);
										} else {
											this.isLoadDDLUpper = false;
										}
									});
							}
						});
						this.getAssetTable();
					}
				}
				this.isLoadingDisplay = false;
			} else {
				this.isLoadingDisplay = false;
			}
		});
	}

	getAssetTable() {
		var sortText = this.sortOrder ? this.sortOrder : "";
		if (
			sortText != "" &&
			sortText != "Name" &&
			this.sortOrder != "Name DESC" &&
			this.sortOrder != "Cost" &&
			this.sortOrder != "Cost DESC" &&
			this.language != "th-TH"
		) {
			sortText = `${this.sortOrder}_EN`;
		}

		const assetSearch = { sortOrder: sortText, employeeId: this.employeeId };
		this.assetsService.getAssets(assetSearch).then((response) => {
			this.assetList = response?.data?.assets || [];
		});
	}

	onSortTable(txt) {
		this.sortOrder = txt;
		this.getAssetTable();
	}

	onClickView(id) {
		this.selectAssetId = id;
		this.displayAssetsSidebar = true;
	}

	onHideSidebarAsset(payload) {
		this.displayAssetsSidebar = payload.display;
	}

	getListPeople(event) {
		firstValueFrom(this.commonService.getPeople(event.selectedItem.employeeId)).then((res) => {
			this.listPeople = res.data.filter((e) => e.employeeId != this.employeeMovement.employeeId);
		});
	}
	onFilterEmployee(event) {
		event.cancel = true;
		const searchText = event.text.trim().toLowerCase();
		firstValueFrom(this.commonService.getPeople(searchText)).then((res) => {
			this.listPeople = res.data;
		});
	}
	listPeople$: Subscription;
	peopleFields: any = { text: "fullname", picture: "pictureURL", value: "employeeId" };
	peopleFields_EN: any = { text: "fullname_EN", picture: "pictureURL", value: "employeeId" };
	listPeople: any;
	public countUnder: number = 0;

	async loadDDLYear() {
		var yearEffective = new Date(this.employeeMovement.dateEffective).getFullYear();
		var yearDiff = new Date().getFullYear() - yearEffective + 1;
		var isFuture = false;

		if (yearDiff <= 0) {
			yearDiff = 1;
			isFuture = true;
		}

		await this.commonService.getDDLYearRange(yearDiff).then((res) => {
			// +- 1 ปี
			this.refYearDDL = res;
		});

		this.commonService.getYearCutoff().then((res) => {
			this.currentYear = res;
			this.yearId = isNullOrUndefined(this.refYearDDL) ? null : isFuture ? this.currentYear : yearEffective;
			if (this.yearId > 0) {
				this.refYearDDL = this.refYearDDL.filter((f) => f.id <= this.currentYear);
			}
			if (!this.isDataLoaded) {
				this.loadSummary();
			}
		});
	}

	loadPermissionWorkingSummary() {
		var listFunctionCode = [Module.EMP001_T11, Module.ASM001_T01, Module.ESS003, Module.EMP012_01, Module.EMP012_02];

		firstValueFrom(this.userControlService.authorizeControlByListFunctionCode(listFunctionCode)).then((res) => {
			let permissionBenefit = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.EMP001_T11) : null;
			let permissionAssessment = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.ASM001_T01) : null;
			let permissionWorkIn = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.ESS003) : null;
			let permissionDocumentPromotion = !isNullOrUndefined(res)
				? res.find((f) => f.functionCode == Module.EMP012_01)
				: null;
			let permissionDocumentPayroll = !isNullOrUndefined(res)
				? res.find((f) => f.functionCode == Module.EMP012_02)
				: null;

			this.benefitRole = !isNullOrUndefined(permissionBenefit) ? permissionBenefit.allowGrant : false;
			this.assessmentRole = !isNullOrUndefined(permissionAssessment) ? permissionAssessment.allowGrant : false;
			this.workInRole = !isNullOrUndefined(permissionWorkIn) ? permissionWorkIn.allowGrant : false;
			this.permissionDocumentPromotion = !isNullOrUndefined(permissionDocumentPromotion)
				? permissionDocumentPromotion.allowGrant
				: false;
			this.permissionDocumentPayroll = !isNullOrUndefined(permissionDocumentPayroll)
				? permissionDocumentPayroll.allowGrant
				: false;
		});
	}

	loadSummary() {
		this.isLoadUserTimeAttendance = true;
		this.isLoadLeaveUsages = true;
		this.isLoadAssessments = true;
		this.employeeTimeAttendanceService.getAbnormalSummariesByEmployeeId(this.employeeId, this.yearId).then((res) => {
			this.userTimeAttendance = res;
			this.hasAttendanceSummary =
				!isNullOrUndefined(this.userTimeAttendance) &&
				(this.userTimeAttendance.totalOfAbsent > 0 ||
					this.userTimeAttendance.late > 0 ||
					this.userTimeAttendance.early > 0 ||
					this.userTimeAttendance.totalOfNoStampIn > 0 ||
					this.userTimeAttendance.totalOfNoStampOut > 0);
			this.isLoadUserTimeAttendance = false;
		});

		this.employeeLeaveUsageService.getLeaveUsageById(this.employeeId, this.yearId).then((res) => {
			this.leaveUsages = res;
			this.leaveUsages = !isNullOrUndefined(this.leaveUsages)
				? this.leaveUsages.filter((f) => f.benefitUsedTotalDays > 0 || f.benefitUsedTotalHours > 0)
				: [];
			this.hasLeaveSummary = !isNullOrUndefined(this.leaveUsages) && this.leaveUsages.length > 0;
			this.isLoadLeaveUsages = false;
		});

		this.assessmentSearchModel.assesseeId = this.employeeId;
		this.assessmentSearchModel.assessmentTypeId = AssessmentType.assessmentAnnual;
		this.assessmentSearchModel.year = this.yearId;
		this.assessmentSearchModel.take = 5;
		this.assessmentSearchModel.assessmentTypeIds = [
			AssessmentType.assessmentSelf,
			AssessmentType.assessmentProbation,
			AssessmentType.assessmentSubordinate,
			AssessmentType.assessmentProbationSup,
			AssessmentType.assessmentOrganization,
			AssessmentType.assessmentSupervisorSelf,
			AssessmentType.assessmentHeadSubordinate,
			AssessmentType.separationAssessment,
		];

		firstValueFrom(
			this.employeeAssessmentService.getAssessmentScoreByAssessorIdAndAssessmentId(this.assessmentSearchModel),
		).then((res) => {
			this.assessments = res.filter((obj, index) => {
				return index === res.findIndex((o) => obj.scoreId === o.scoreId);
			});

			this.hasAssessmentSummary = !isNullOrUndefined(this.assessments) && this.assessments.length > 0;
			this.isLoadAssessments = false;
		});
	}
	//#endregion

	//#region Action
	onProcessAction(id: number) {
		this.idBtn = id;
		this.colorAnimation =
			this.idBtn == DocumentStatus.approved ||
			this.idBtn == ProcessAction.approvedDocument ||
			(this.isCancel && this.idBtn == ProcessAction.canceledDocument)
				? "white"
				: "#F08A3F";
		this.processActionId = id;
		this.onSubmit();
	}
	onSubmit() {
		if (
			!this.description &&
			(this.processActionId == ProcessAction.canceledDocument ||
				this.processActionId == ProcessAction.returnDocument ||
				this.processActionId == ProcessAction.rejectedDocument)
		) {
			return this._toastHelperService.warningText(this.translate.instant("common_please_input_reason"));
		}
		if (!this.isClicked) {
			this.isClicked = true;
		}

		this.employeeMovement.processActionId = this.processActionId;
		this.employeeMovement.remark = this.description;
		this.employeeMovement.upperSupervisorId = this.upperSupervisorId;
		this.employeeMovement.dateEffective = this.dateEffective;

		if (this.isGroupMovement) {
			let employeeRequest = Object.assign([], this.employeeMovement?.employeeRequests);
			this.employeeMovement.movementTransactionIds = [
				...employeeRequest.map((m) => {
					return m.movementTransactionId;
				}),
			];
		}

		if (this.onAdd$) this.onAdd$.unsubscribe();
		this.onAdd$ = this.employeeMovementService
			.addProcessActionMovementTransaction(this.employeeMovement)
			.subscribe((res) => {
				var apiResult = <ApiResult>res;
				if (apiResult.result) {
					this.closeWebView(this.processActionId);
					this.statusEvent.emit(apiResult.result);
					apiResult.message = this.translate.instant("common_toast_description_save_successfully");
				}
				this._toastHelperService.alertApiResult(apiResult);
				this.isClicked = false;
				this.idBtn = 0;
			});
	}

	closeWebView(event) {
		if (window["closeWebView"]) {
			window["closeWebView"](JSON.stringify({ message: event }));
		}
	}
	openInNewTab(value: any) {
		window.open(value);
	}

	loadImg(event) {
		if (event) {
			this.isloadImage = false;
		}
	}

	closeSidebar() {
		this.closeEvent.emit(true);
	}

	back() {
		this.backSidebar.emit(true);
	}

	showIsCancel() {
		this.isCancel = true;
		if (this.isMobile) {
			const dialogModel = <DialogInformationModel>{
				title: this.translate.instant("common_title_cancel"),
				description: null,
				imageUrl: null,
				textButtonConfirm: this.translate.instant("common_confirm"),
				textButtonCancel: this.translate.instant("common_dismiss"),
				placeholder: this.translate.instant("punishment_placeholder_cancel"),
				iconClass: "gf-icon-empeo-document",
				isRequiredRemark: false,
				isShowRemark: true,
				remarkSize: "extra-large",
			};
			firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
				this.description = response.remark;
				if (response?.confirm) {
					this.onProcessAction(ProcessAction.canceledDocument);
				} else {
					this.closeIsCancel();
				}
			});
		} else {
			this.scrollTextArea();
		}
		this._cdr.detectChanges();
	}

	scrollTextArea() {
		const element = document.getElementById("textarea_description");
		element?.scrollIntoView();
	}

	closeIsCancel() {
		this.isCancel = false;
		this.description = null;
	}

	get getProcessActionEnum() {
		return ProcessAction;
	}

	counter(i: number) {
		return new Array(i);
	}

	get haveProcessActionCancel() {
		if (
			this.employeeMovement.isSelfResign &&
			this.isMe &&
			this.employeeMovement.documentStatusId !== DocumentStatus.waitingApprove
		) {
			return false;
		} else {
			return this.processActions != null && this.processActions.length != 0
				? this.processActions.some((f) => f.id == this.getProcessActionEnum.canceledDocument)
				: false;
		}
	}

	viewEmployee(item) {
		if (!this.isMobile) {
			this.dataSharing.setEmpId(item.employeeId);
			window.open(`${environment.redirectUri}employee/${item.employeeNo}`);
		}
	}

	showSalary() {
		if (!this.authStatus) {
			setTimeout(() => {
				const element = this.renderer.createElement("div");
				this.renderer.appendChild(document.body, element);
				const factory = this.resolver.resolveComponentFactory(TwoFactorAuthenticationComponent);
				const compRef = factory.create(this.injector, [], element);
				this.appRef.attachView(compRef.hostView);
				//subscribe output parent component
				compRef.instance.authStatus.subscribe((event) => this.receiveMessageAuth(event));
				compRef.instance.closeStatus.subscribe((event) => this.receiveMessageClose(event));
			});
		} else {
			this.displayMask = !this.displayMask;
		}
	}

	isAuth: boolean = false;
	authStatus: boolean = false;
	receiveMessageAuth(event) {
		if (event) {
			this.authStatus = true;
			this.displayMask = !this.displayMask;
		}
	}
	receiveMessageClose(event) {
		if (event) {
			this.isAuth = false;
		}
	}

	showLeaveBenefitModal(data: any) {
		this.dataSharing.setYear(this.yearId);

		if (!this.isEmployeeView) {
			this.dataSharing.setEmpId(data.employeeId);
		}

		this.displayLeaveBenefitModal = true;
	}

	routerWorkinTab(data: any) {
		if (!this.isEmployeeView) {
			this.dataSharing.setEmpId(data.employeeId);
		}

		window.open(`${environment.redirectUri}employee/${data.employeeNo}/workin`);
	}
	movementTypeId: number = 0;
	checkEdit(item) {
		this.movementTypeId = item.movementTypeId;
		if (this.isGroupMovement) {
			this.isLockSelect = false;
			this.employeeId = 0;
			this.movementTransactionId = 0;
			this.dataSharing.setMovementTransactionId(this.movementTransactionId);
		}
		this.isSidebarMovement = true;
	}

	closeSidebarAdd() {
		this.isLoadingDisplay = false;
		this.isSidebarMovement = false;
		this.loadData();
	}

	get validDateEffective(): boolean {
		var date1 = new Date(this.employeeMovement.dateEffective);
		var date2 = new Date(this.dateEffective);
		return (
			date1.getDate() === date2.getDate() &&
			date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth()
		);
	}

	public currentHeaderTabIndex: number = 0;
	public activeIndexAssessment: number = 0;
	onClickAssessmentType(element) {
		this.setId = element?.setId;
		this.activeIndexAssessment = element?.no;
		this.scoreId = element?.scoreId;
		this.displayAssessmentSummaryModal = false;
		this.displayAssessmentSummaryModal = true;
		if (element?.assessmentType === this.assessmentTypeEnum.separationAssessment) {
			this.currentHeaderTabIndex = 3;
		} else if (element?.assessmentType === this.assessmentTypeEnum.candidate) {
			this.currentHeaderTabIndex = 2;
		} else if (element?.assessmentType === this.assessmentTypeEnum.assessmentProbation) {
			this.currentHeaderTabIndex = 1;
		} else {
			this.currentHeaderTabIndex = 0;
		}
	}

	sortingBy(field: "employee" = null) {
		if (field === "employee") {
			this.sortOrder = this.sortOrder === "employee" ? "employee asc" : "employee";
		} else {
			this.sortOrder = "employee";
		}
		this.employeeMovement?.employeeRequests.sort((a, b) => {
			const charA = (this.language === "th-TH" ? a?.nameSurname : a?.nameSurname_EN).toLowerCase();
			const charB = (this.language === "th-TH" ? b?.nameSurname : b?.nameSurname_EN).toLowerCase();
			if (this.sortOrder === "employee asc") return charA > charB ? -1 : 1;
			else if (this.sortOrder === "employee") return charA < charB ? -1 : 1;
		});
	}

	approveDialog: boolean = false;
	textButtonConfirm = "";
	placeholder = "";
	showDialog(data) {
		this.processActionId = data;
		this.placeholder = this.translate.instant("popup_approval_enter_reason_here") + "*";
		switch (data) {
			case this.getProcessActionEnum.approvedDocument:
				{
					this.textButtonConfirm = this.translate.instant("common_approve");
					this.placeholder = this.translate.instant("popup_approval_enter_reason_here");
				}
				break;
			case this.getProcessActionEnum.rejectedDocument:
				{
					this.textButtonConfirm = this.translate.instant("common_reject");
				}
				break;
			case this.getProcessActionEnum.returnDocument:
				{
					this.textButtonConfirm = this.translate.instant("common_return");
				}
				break;
		}

		if (this.processActions != null && this.processActions.length != 0) {
			this.approveDialog = true;
		}
	}

	cancelDialogConfirm() {
		this.approveDialog = false;
		this.description = null;
	}

	successMovement() {
		this.statusEvent.emit(true);
	}

	get paddingBottom() {
		var bar = getComputedStyle(document.documentElement).getPropertyValue("--sab").replace("px", "");
		if (parseInt(bar) != 0) {
			return 16 + parseInt(bar);
		} else {
			return 16;
		}
	}

	get heightFooter() {
		return document.getElementById("footer")?.offsetHeight + 7;
	}
}

//#end region
