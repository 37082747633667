<div class="d-grid gap-8" [hidden]="showMulti">
	<div class="w-100 p-2 message-warning">
		<em class="gfc-exclamation-mark mx-2"></em>
		<label class="go5-color-primary">{{ messageWarn }}</label>
	</div>
</div>

<div class="d-grid gap-8" [hidden]="!showMulti">
	@for (item of warningMovement; track item) {
		<div class="w-100 p-2 message-warning" (click)="viewMovement(item.movementTransactionId)">
			<em class="gfc-exclamation-mark mx-2"></em>
			<label [hidden]="isMovementType" class="go5-color-primary pointer">{{
				"emp001_movement_warning"
					| translate
						: {
								movemenType: item.movementType,
								status: item.status,
								date: (item.date | sDate: dateFormat.dateShort | async)
						  }
			}}</label>
			<label [hidden]="!isMovementType" class="go5-color-primary">{{
				"emp001_movement_warning_2" | translate: { movementCode: item.movementCode ?? "-" }
			}}</label>
		</div>
	}
</div>

@if (displayMovementDetail) {
	<go5-sidebar
		[(visible)]="displayMovementDetail"
		(closed)="receiveCloseEvent()"
		[isShowFooter]="false"
		[isShowHeader]="false"
		[padding]="'0px'"
	>
		<app-movement-detail
			[movementTransactionId]="movementTransactionId"
			(statusEvent)="receiveMovementTransaction($event)"
			(closeEvent)="receiveCloseEvent()"
		>
		</app-movement-detail>
	</go5-sidebar>
}
