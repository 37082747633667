export class Organization {
	organizationId: number;
	parentId: number;
	parentName: string;
	name: string;
	name_EN: string;
	code: string;
	levels: number;
	level: string;
	locationType: number;
	workingLocation: number;
	isActive: boolean;
}
export class UpdateOrganizationSEQModel {
	source: OrganizationSEQModel;
	dest: OrganizationSEQModel;
}

export class OrganizationSEQModel {
	organizationId: number;
	seq?: number;
}
export class OrganizationConfig {
	org1Name: string;
	org1Name_EN: string;
	org2Name: string;
	org2Name_EN: string;
	org3Name: string;
	org3Name_EN: string;
	org4Name: string;
	org4Name_EN: string;
	org5Name: string;
	org5Name_EN: string;
	org6Name: string;
	org6Name_EN: string;
}

export class OrganizationConfigs {
	organizationConfigId: number;
	level: number;
	name: string;
	name_EN: string;
	tenantId: string;
	dateCreated: Date;
	createdBy: string;
	dateModified: Date;
	modifiedBy: string;
}
