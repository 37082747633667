/// <reference path="report-ss001.model.ts" />

import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { DropdownDataItem } from "../../../shared/models/dropdown-data-item.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { ReportSS001Detail, ReportSS001, ReportModel } from "./report-ss001.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class ReportSS001Service {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getDDLTypeOfEmployments() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLTypeOfEmployments")).then((res) => <Dropdown[]>res);
	}

	getDDLNationalitySSO() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLNationalitySSO")).then(
			(res) => <DropdownDataItem[]>res,
		);
	}

	getEmployeeSocialSecurity(companyId: number, dateFrom: string, dateTo: string) {
		const body = JSON.stringify({
			companyId,
			dateFrom,
			dateTo,
		});
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any[]>(this.apiUrl + "EmployeeSocialSecurity/GetExportSocialSecurity", body, httpOptions);
	}

	SaveTextEmployeeSocialSecurity(model: ReportSS001) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeSocialSecurity/SaveTextEmployeeSocialSecurity", body, httpOptions);
	}

	getUrlExportSocialSecurity(model: ReportSS001) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeSocialSecurity/GetUrlExportSocialSecurity", body, httpOptions);
	}

	getReportSSO001(filter: ReportSS001Detail) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportSSO001", body, httpOptions);
	}
}
