export class LineChartModel {
	labels: string[];
	labels_TH: string[];
	labels_EN: string[];
	datasets: DatasetModel[];
}

export class DatasetModel {
	label: string;
	label_TH: string;
	label_EN: string;
	data: number[];
	fill: boolean;
	borderColor: string;
	lineTension: number;
	totalEmployee: number[];
	totalBudget: number[];
}

export class RouterModel {
	icon: string;
	label: string;
	label_EN: string;
	functionCode: string;
	functionCaption: string;
	functionCaption_EN: string;
	routerLink: string[];
	isActive: boolean;
	isAllow: boolean;
	isConfigured: boolean;
	isDelete: boolean;
	items: RouterModel[];
	itemHide: RouterModel[];
}

export class CompanyFilterModel {
	name: string;
	companyId: number;
	orgLevelTwoStr: string;
	orgLevelThreeStr: string;
	orgLevelFourStr: string;
	orgLevelFiveStr: string;
	orgLevelSixStr: string;
}

export class AttachmentUploadModel {
	attachmentURL: string;
	pathUpload: string;
}
export class DefaultUserInfo {
	employeeId: number;
	employeeNo: string;
	fullName: string;
	fullName_EN: string;
	pictureURL: string;
	positionId: number;
	positionName: string;
	positionName_EN: string;
	rankName: string;
	rankName_EN: string;
	rankNo: number;
}

export class OTPConfigModel {
	inputStyles?: { [key: string]: any };
	containerStyles?: { [key: string]: any };
	allowKeyCodes?: string[];
	length: number;
	allowNumbersOnly?: boolean;
	inputClass?: string;
	containerClass?: string;
	isPasswordInput?: boolean;
	disableAutoFocus?: boolean;
	placeholder?: string;
	letterCase?: "Upper" | "Lower";
}

export class CommonSearchModel {
	sortby: string;
	sortDir: string;
	take: number;
	skip: number;
	keyword: string;
	employeeId: number;
}

export class TableColumns {
	praseKey: string;
	width: string;
	[key: string]: any;
}
