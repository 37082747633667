import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
@Pipe({
	name: "momentPipe",
	standalone: true,
})
export class DateMomentPipe implements PipeTransform {
	transform(date, format) {
		return moment(date).format(format);
	}
}
