import { EmployeePDPA } from "../../employee-movements/shared/employee-pdpa.model";

export class UserInfo {
	userId: string;
	displayName: string;
	fullName: string;
	fullName_EN: string;
	positionName: string;
	positionName_EN: string;
	rankName: string;
	companyId: number;
	companyName: string;
	companyName_EN: string;
	tenantId: string;
	defaultLanguage: string;
	picture: string;
	pictureURL: string;
	pictureThumbnailURL: string;
	mobileNo: string;
	telephoneNo: string;
	emailAddress: string;
	employeeId: number;
	employeeNo: string;
	employeeName: string;
	extensionNo: string;
	isHR: boolean;
	isAttendance: boolean;
	isFreelance: boolean;
	employeeStatus: number;
	employeePDPA: EmployeePDPA;
	isActive: boolean;
	dateEffective: Date;
	dateExpire: Date;
	driverNo: string;
	defaultDisplayBirthDay: boolean;
	defaultDirectReport: boolean;
	storageEncryptionKey: string;
	storageEncryptionKeyHash: string;
	maxOrganizationLevel: number;
	isMultiLanguage: boolean;
	subscriptionStatusId: number;
	isSelfUpdate: boolean;
	refClientId: number;
	is2FA: boolean;
	isActive2FA: boolean;
	secretKey: string;
	needOnboard: boolean;
	packageId: number;
	has2FA: boolean;
	hasPayroll: boolean;
	hasAzureAD: boolean;
	hasMovementWorkflow: boolean;
	hasCustomDomain: boolean;
	invitationCode: string;
	isActiveInvitation: boolean;
	maxShifts: number;
	dayOfBackdateTime: number;
	isSynced: boolean;
	isSSO: boolean;
	isDisplayBirthDay: boolean;
	isDisplayMobileNo: boolean;
	subscriptionStatus: string;
	packageName: string;
	limitUser: number;
	activeUser: number;
	planType: string;
	nextBilling: string;
	validUntil: string;
	percentageAllSetting: number;
	passphraseType: number;
	isCustomDomain: boolean;
	logoURL: string;
	colorCode: string;
	maxCustomField: number;
	dateLastAccess: any;
	workinPhotoType: number;
	selfUpdateStepNo: number;
	modules: string;
	maxDocumentCategory: number;
	maxDocumentFile: number;
	maxOtherDocumentFile: number;
	remoteAccessStatusId: number;
	dateRemoteAccess: Date;
	signatureURL: string;
	isSupervisor: boolean;
}
export class UserInfoEmployee extends UserInfo {
	employeeId: number;
}
