<div class="salary-band-warpper">
	<div class="salary-band-content">
		<label class="go5-text-title-strong">{{ "ess009_salary_adj_band" | translate }}</label>
		<label class="go5-text-small">{{
			data.getIsTH(data.currentLanguage | async) ? salaryBand.positionName : salaryBand.positionName_EN
		}}</label>
	</div>
	<div class="salary-band-content-bar-warpper">
		<div class="salary-band-content-bar-warpper-item">
			<div class="salary-band-content-bar-line"></div>
			<div class="salary-band-content-bar">
				@if (isShowSalary) {
					<div
						id="salary-band-content-bar-circle-new"
						class="salary-band-content-bar-circle fadeId"
						go5-tooltip-placement="top"
						go5-tooltip-mode="hover"
						go5-tooltip="฿{{ isShowSalary ? (salaryBand.newBaseSalary | number: '0.0-2') : markAmount }}"
						[ngStyle]="{
							left: settings.circle1.left,
							'background-color': settings.circle1.backgroundColor,
							'border-color': settings.circle1.borderColor,
							'z-index': 2
						}"
					></div>
				}
				@if (percentageDiifBar > 0 && isShowSalary) {
					<div
						class="salary-band-content-bar-circle fadeId"
						go5-tooltip-placement="top"
						go5-tooltip-mode="hover"
						go5-tooltip="฿{{ isShowSalary ? (salaryBand.baseSalary | number: '0.0-2') : markAmount }}"
						[ngStyle]="{
							left: settings.circle2.left,
							'background-color': settings.circle2.backgroundColor,
							'border-color': settings.circle2.borderColor
						}"
					></div>
				}

				@if (percentagePrev != percentage && isShowSalary) {
					<div
						class="salary-band-content-bar-rangebar fadeId"
						[ngStyle]="{
							'background-color': settings.rangeBar.backgroundColor,
							left: settings.rangeBar.left,
							width: settings.rangeBar.width
						}"
					></div>
				}
			</div>
		</div>
		<div class="salary-band-content-bar-label go5-text-small go5-text-color-12">
			@if (settings.showMin) {
				<label>฿{{ isShowSalary ? (salaryBand.min | number: "0.0-2") : markAmount }}</label>
			}
			@if (settings.showMedian) {
				<label>฿{{ isShowSalary ? (salaryBand.median | number: "0.0-2") : markAmount }}</label>
			}
			@if (settings.showMax) {
				<label>฿{{ isShowSalary ? (salaryBand.max | number: "0.0-2") : markAmount }}</label>
			}
		</div>
	</div>
</div>
