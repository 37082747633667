export class DiligenceAllowance {
	diligenceAllowanceId: number;
	companyId: number;
	diligenceAmount: number;
	isBonus: boolean;
	bonusType: number;
	bonusMonth: number = 0;
	bonusAmount: number = 0;
	isStack: boolean;
	isPunishEffect: boolean = false;
	limitDayOfLate: number = 0;
	limitDayOfAbsence: number = 0;
	limitDayOfAdjustTime: number = 0;
	isShiftTimeCal: boolean = false;
	minuteStampInBefore: number = 0;
	minuteStampOutAfter: number = 0;
	diligenceAllowanceDetails: DiligenceAllowanceDetails[];
	paymentType: number = 2;
	limitDayOfEarly: number = 0;
	organizationIds: string;
	isActive: boolean;
}

export class DiligenceAllowanceDetails {
	index: number;
	minimumAgeOfWork: number;
	maximumAgeOfWork: number;
	amount: number;
}
