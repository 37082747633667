export class MasCompanyBranchs {
	branchId: number = 0;
	companyId: number;
	name: string;
	name_EN: string;
	branchNo: string;
	bankAccountNo: string;
	address: string;
	address_EN: string;
	subdistrictId: number;
	districtId: number;
	provinceId: number;
	zipCode: string;
	phone: string;
	businessCode: string;
	rate: number;
	highestSubvention: number;
	isActive: boolean = true;
	isDelete: boolean;
	isSSO: boolean = false;
	hasLocation: boolean = false;
	provinceName: string;
	provinceName_EN: string;
	statusName: string;
	statusName_EN: string;
	rowCount: number;
	provinceCode: string;
	provinceBranchCode: string;
}

export class CompanyBranchSearch {
	provinceId: number;
	keyword: string = "";
	status: any;
	skipRow: number = 0;
	takeRow: number = 30;
	companyId: number;
	sortOrder: string;
}
