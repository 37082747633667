import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";

@Injectable()
export class LeaveConditionService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getLeaveTypeDetailsDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "LeaveTypeDetail/GetLeaveTypeDetailsDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	addLeaveTypeDetail(leaveConditions: Array<any>) {
		var body = JSON.stringify(leaveConditions);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveTypeDetail/AddLeaveTypeDetail", body, httpOptions);
	}

	editLeaveTypeDetail(leaveCondition: Array<any>) {
		var body = JSON.stringify(leaveCondition);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveTypeDetail/EditLeaveTypeDetail", body, httpOptions);
	}

	getLeaveTypeDetailsByLeaveTypeId(leaveTypeId) {
		return this.http.get<any>(this.apiUrl + "LeaveTypeDetail/GetLeaveTypeDetailsByLeaveTypeId/" + leaveTypeId);
	}
}
