import { DatePipe, isPlatformBrowser, NgClass, AsyncPipe } from "@angular/common";
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router, RouterOutlet } from "@angular/router";
import { Applications, Configuration as ConfigDesignSystem, Themes } from "@gofive/design-system-base";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { setCulture } from "@syncfusion/ej2-base";
import { OAuthService } from "angular-oauth2-oidc";
import { Cookie } from "ng2-cookies";
import { Observable, Subscription, firstValueFrom } from "rxjs";
import { environment } from "../environments/environment";
import { AssessmentFormScore } from "./assessment/assessment-form/shared/assessment-form.model";
import { AssessmentFormService } from "./assessment/assessment-form/shared/assessment-form.service";
import { AssessmentModel } from "./assessment/shared/assessment.model";
import { authConfig } from "./auth/auth-config";
import { AuthService } from "./auth/auth.service";
import { DataSharingService, LanguageService, UserControlService, UserInfoService } from "./core/index";
import { UserInfo } from "./core/shared/user-info.model";
import { OrganizationConfig } from "./master/organization/shared/organization.model";
import { OrganizationService } from "./master/organization/shared/organization.service";
import { ComponentService } from "./setting/component-setting/shared/component.service";
import { Tenant } from "./setting/tenant-setup/shared/tenant-setup.model";
import { TenantSetupService } from "./setting/tenant-setup/shared/tenant-setup.service";
import { SubscriptionStatus } from "./shared/enum/client-portal.enum";
import { SessionStorageKey } from "./shared/enum/local-storage-key";
import { Module } from "./shared/enum/module.enum";
import { Package } from "./shared/enum/package.enum";
import { RouteURL } from "./shared/enum/route-path.enum";
import { Language } from "./shared/models/language.model";
import { Permission } from "./shared/models/permission.model";
import { ClientPortalService } from "./shared/service/client-portal.service";
import { isNullOrUndefined, SharingService } from "./shared/sharing.service";
import { LANGUAGE_KEY } from "@gofive/angular-common";
import { ResEventType } from "src/app/shared/enum/event.enum";
import { ResEventFunction } from "src/app/shared/module/expanse/expense.model";
import { Application } from "./shared/enum/application.enum";
import { ScrollPanel, ScrollPanelModule } from "primeng/scrollpanel";
import { BlockUI, BlockUIModule } from "primeng/blockui";
import { EmployeeStatus } from "./shared/enum/employee-status.enum";
import { RemoteService } from "./shared/component/remote/remote.service";
import { SignalRService } from "./shared/component/remote/signalr.serivice";
import { setUser } from "@sentry/angular-ivy";
import { ButtonModule } from "@gofive/design-system-button";
import { ProgressModule } from "@gofive/design-system-progress";
import { ProgressBarModule } from "primeng/progressbar";
import { AppSideBarComponent } from "./shared/side-bar/app.sidebar.component";
import { AppTopBarComponent } from "./shared/top-bar/app.topbar.component";
import { SurveyComponent } from "./shared/component/survey/survey.component";
import { EmpeoDialogComponent } from "./shared/template/empeo-dialog/empeo-dialog.component";
import { ServiceInfoComponent } from "./service-maintenance/service-info/service-info.component";

export enum EnumService {
	maintenance = "Maintenance",
	running = "Running",
}
setCulture("th-TH");
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		ServiceInfoComponent,
		RouterOutlet,
		EmpeoDialogComponent,
		SurveyComponent,
		NgClass,
		AppTopBarComponent,
		ScrollPanelModule,
		AppSideBarComponent,
		ProgressBarModule,
		BlockUIModule,
		ProgressModule,
		ButtonModule,
		AsyncPipe,
		TranslateModule,
	],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
	public isAuthorized: boolean = false;
	public version: string = environment.VERSION;
	// service maintenance
	public servicing: string;

	displayMenu: boolean = false;
	counter = Array;

	darkTheme = false;
	menuMode = "slim";
	topbarMenuActive: boolean;
	overlayMenuActive: boolean;
	staticMenuDesktopInactive: boolean;
	staticMenuMobileActive: boolean;
	layoutMenuScroller: HTMLDivElement;
	menuClick: boolean;
	topbarItemClick: boolean;
	activeTopbarItem: any;
	currentSubscription: any;
	resetMenu: boolean;
	menuHoverActive: boolean;
	isAllConfigured: boolean;
	showOnboard: boolean = true;
	isSafari: boolean = false;
	organization: OrganizationConfig = new OrganizationConfig();
	private language$: Subscription;
	private languageList$: Subscription;
	private menuList$: Subscription;
	private auth$: Subscription;
	private currentUserInfo$: Subscription;
	private currentLoading$: Subscription;
	private currentSubscription$: Subscription;
	private detectSubscription$: Subscription;
	private ngxCountDown$: Subscription;
	private router$: Subscription;
	private route$: Subscription;
	private routerEvent$: Subscription;
	permissionTenantSetting: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	public openPayment: boolean = true;

	userInfo: UserInfo = new UserInfo();
	language: string = null;
	isLoading: boolean = false;

	isDashboard: boolean = false;

	listFunctionCode: string[] = [];

	tenant: Tenant = new Tenant();

	newEmployeeStatus: EmployeeStatus = EmployeeStatus.waitingStartWork;
	emptyLayout: boolean = false;
	emptyComponent: string[] = [
		"pms",
		"assessment-form",
		"m-assessment-form",
		"mydocument",
		"learn",
		"learn/course-view",
		"learn/course-view/post-test",
		"learn/training",
		"training",
		"m-empeo-learn",
		"m-learn-course-view",
		"m-learn-course-view/post-test",
		"employee",
		"employee-add",
		"expense",
		"team",
		"payroll",
		"welfare",
		"settings",
		"settings/billing",
		"settings/orgsettings",
		"settings/integration",
		"settings/master",
		"settings/import",
		"settings/master/bank-account",
		"settings/locations",
		"settings/employment-types",
		"policy-view",
		"billing",
		"m-personal-detail-update",
		"m-punishment,",
		"m-punishment-detail",
		"m-training-detail",
		"m-movement",
		"m-movement-add",
		"reset-2fa",
		"m-emconnect-recruitment",
		"emconnect",
		"goals",
		"m-emconnect-candidate",
		"m-employee-assessment",
		"m-goals",
	];
	isDashboards: string[] = [];
	redirectPath: string;
	stateUrl: string;
	public get isDoneLoading(): Observable<boolean> {
		return this.authService.isDoneLoading$;
	}
	get packageEnum() {
		return Package;
	}

	get enumService() {
		return EnumService;
	}

	@ViewChild("layoutMenuScroller", { static: true }) layoutMenuScrollerViewChild: ScrollPanel;
	@ViewChild("blockUI") blockUI: BlockUI;
	isBlock: boolean = false;
	constructor(
		private router: Router,
		public data: DataSharingService,
		@Inject(PLATFORM_ID) private platformId: Object,
		private authService: AuthService,
		private userService: UserInfoService,
		private organizationService: OrganizationService,
		private languageService: LanguageService,
		private componentService: ComponentService,
		private userControlService: UserControlService,
		private sharing: SharingService,
		private assessmentFormService: AssessmentFormService,
		private translate: TranslateService,
		private clientPortalService: ClientPortalService,
		private datepipe: DatePipe,
		private route: ActivatedRoute,
		private tenantSetupService: TenantSetupService,
		private oauthService: OAuthService,
		public _cdr: ChangeDetectorRef,
		private remoteService: RemoteService,
		public signalRService: SignalRService,
	) {
		this.isDashboards = this.router.config.filter((f) => f.data?.isBg).map((m) => m.path || m.data.path);
		this.routerEvent$ = router.events.subscribe((res) => {
			if (res instanceof NavigationEnd) {
				this.emptyLayout = this.checkIsEmptyPage(res.url);
				this.isDashboard = this.checkIsDashboard(res.url);
				if (res.url == "/") {
					this.isDashboard = true;
				}
				if (!isNullOrUndefined(sessionStorage.getItem("stateUrl")) && res.url == sessionStorage.getItem("stateUrl")) {
					sessionStorage.removeItem("stateUrl");
				}
			}
		});

		this.currentLoading$ = this.data.currentLoading.subscribe((res) => {
			this.isBlock = res;
			if (this.blockUI) {
				this.blockUI._blocked = this.isBlock;
			}
		});

		this.translate.addLangs(["en", "th", "my", "zh", "km"]);
		this.userService
			.getServiceMaintenance()
			.then((res) => {
				this.servicing = res.data.status;
			})
			.catch(() => {
				this.servicing = EnumService.running;
			});
		this.route$ = this.route.queryParams.subscribe((params: Params) => {
			const theme = params["theme"] || "empeo";
			if (theme?.toLowerCase() == "venio") {
				ConfigDesignSystem.setLibConfig({
					application: Applications.Venio,
					theme: Themes.VenioLight,
				});
			} else {
				ConfigDesignSystem.setLibConfig({
					application: Applications.Empeo,
					theme: Themes.EmpeoLight,
				});
			}
			this.route$?.unsubscribe();
		});

		sessionStorage.removeItem(SessionStorageKey.onBoard);
		this.isSafari = this.getBrowserName() == "safari";
	}

	ngAfterContentChecked(): void {
		this._cdr.detectChanges();
	}
	async ngOnInit() {
		const queryString = window.location.href;
		const urlParams = new URLSearchParams(queryString);
		const paramValue = urlParams.get("lang");
		this.language = paramValue;

		let defaultSubDomain = environment.defaultSubDomain;
		let locationOrigin = location.origin + "/";

		if (
			locationOrigin != environment?.redirectUri &&
			environment?.redirectUri?.indexOf("empeo") >= 0 &&
			environment?.enableCustomDomain
		) {
			let redirectUri = environment.redirectUri;
			let preDomain = redirectUri.substring(0, redirectUri.indexOf(defaultSubDomain));
			let postDomain = redirectUri.substring(
				redirectUri.indexOf(defaultSubDomain) + defaultSubDomain.length,
				redirectUri.length,
			);

			environment.subDomain = locationOrigin.substring(preDomain.length, locationOrigin.indexOf(postDomain));

			await this.tenantSetupService.getCheckCustomDomainByInvitationCode(environment.subDomain).then((res) => {
				if (!res) {
					environment.subDomain = defaultSubDomain;
				}

				this.replaceRedirectUri(environment.subDomain);
			});
		}

		if (isPlatformBrowser(this.platformId)) {
			let currentUrl = window.location.href;
			if (currentUrl.length >= environment.redirectUri.length) {
				currentUrl = currentUrl.replace(locationOrigin, "");
			} else {
				currentUrl = "/";
			}
			if (
				(isNullOrUndefined(currentUrl) || currentUrl != "/") &&
				isNullOrUndefined(sessionStorage.getItem("stateUrl")) &&
				!currentUrl.includes("_gl=") &&
				!currentUrl.includes("_ga=") &&
				!currentUrl.includes("token") &&
				!currentUrl.includes("refresh_token")
			) {
				currentUrl = currentUrl.replace(
					environment.appPath[0] === "/" ? environment.appPath.replace("/", "") : environment.appPath,
					"",
				);
				sessionStorage.setItem("stateUrl", (currentUrl[0] === "/" ? "" : "/") + `${currentUrl}`);
			}
			this.authService.runInitialLoginSequence();

			/*
			 ** first step after login
			 * load default data
			 */
			this.auth$ = this.authService.canActivateProtectedRoutes$.subscribe((isAuthorized) => {
				this.isAuthorized = isAuthorized;
				this.stateUrl = sessionStorage.getItem("stateUrl");
				if (currentUrl.includes(RouteURL.support) && currentUrl.includes("email")) {
					let email = this.stateUrl.split("email=")[1];
					if (email) {
						sessionStorage.setItem(SessionStorageKey.email, email);
					}
				} else {
					this.stateUrl =
						isNullOrUndefined(this.stateUrl) || this.stateUrl.includes("token")
							? "/" + currentUrl?.split("?")[0]
							: this.stateUrl?.split("?")[0];
				}

				if (this.isAuthorized === true) {
					this.setUserInfo();
					this.displayMenu = isAuthorized;
					sessionStorage.removeItem("stateUrl");
					this.setLanguageList();
					this.beginNgxCountDown();
				}
			});
		}

		this.language$ = this.data.currentLanguage.subscribe((lang) => {
			if (lang) {
				switch (lang) {
					case "th-TH":
					case LANGUAGE_KEY.TH:
					case "th":
						this.translate.use("th");
						break;
					case "my-MM":
					case LANGUAGE_KEY.MY:
						this.translate.use("my");
						break;
					case LANGUAGE_KEY.ZH:
					case "zh":
						this.translate.use("zh");
						break;
					case LANGUAGE_KEY.KM:
					case "km":
						this.translate.use("km");
						break;
					default:
						this.translate.use("en");
				}
			}
		});
		this.openConnectAccountSidebarWithAction();
		this.openSubscribeRefreshToken();
	}

	ngOnDestroy() {
		this.language$?.unsubscribe();
		this.languageList$?.unsubscribe();
		this.menuList$?.unsubscribe();
		this.auth$?.unsubscribe();
		this.currentUserInfo$?.unsubscribe();
		this.currentLoading$?.unsubscribe();
		this.detectSubscription$?.unsubscribe();
		this.ngxCountDown$?.unsubscribe();
		this.router$?.unsubscribe();
		this.auth$?.unsubscribe();
		this.routerEvent$?.unsubscribe();
		this.currentSubscription$?.unsubscribe();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (!isNullOrUndefined(this.layoutMenuScrollerViewChild)) {
				this.layoutMenuScrollerViewChild.moveBar();
			}
		}, 100);
	}

	openSubscribeRefreshToken() {
		window["webviewGetTokenFromNative"] = {
			setNativeAccessToken: (e) => {
				sessionStorage.setItem(SessionStorageKey.access_token, e);
			},
		};
	}

	onLayoutClick() {
		if (!this.topbarItemClick) {
			this.activeTopbarItem = null;
			this.topbarMenuActive = false;
		}

		if (!this.menuClick) {
			if (this.isHorizontal() || this.isSlim()) {
				this.resetMenu = true;
			}

			if (this.overlayMenuActive || this.staticMenuMobileActive) {
				this.hideOverlayMenu();
			}

			this.menuHoverActive = false;
		}

		this.topbarItemClick = false;
		this.menuClick = false;
	}

	onMenuButtonClick(event) {
		this.menuClick = true;
		this.topbarMenuActive = false;

		if (this.isOverlay()) {
			this.overlayMenuActive = !this.overlayMenuActive;
		}
		if (this.isDesktop()) {
			this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
		} else {
			this.staticMenuMobileActive = !this.staticMenuMobileActive;
		}

		event?.preventDefault();
	}

	onMenuClick() {
		this.menuClick = true;
		this.resetMenu = false;
		if (!this.isHorizontal()) {
			setTimeout(() => {
				if (!isNullOrUndefined(this.layoutMenuScrollerViewChild)) {
					this.layoutMenuScrollerViewChild.moveBar();
				}
			}, 500);
		}
	}

	onTopbarMenuButtonClick(event) {
		this.topbarItemClick = true;
		this.topbarMenuActive = !this.topbarMenuActive;

		this.hideOverlayMenu();

		event?.preventDefault();
	}

	onTopbarItemClick(event, item) {
		this.topbarItemClick = true;

		if (this.activeTopbarItem === item) {
			this.activeTopbarItem = null;
		} else {
			this.activeTopbarItem = item;
		}

		event?.preventDefault();
	}

	isHorizontal() {
		return this.menuMode === "horizontal";
	}

	isSlim() {
		return this.menuMode === "slim";
	}

	isOverlay() {
		return this.menuMode === "overlay";
	}

	isStatic() {
		return this.menuMode === "static";
	}

	isMobile() {
		return this.sharing.isMobile();
	}

	isDesktop() {
		return window.innerWidth > 1024;
	}

	isTablet() {
		const width = window.innerWidth;
		return width <= 1024 && width > 640;
	}

	hideOverlayMenu() {
		this.overlayMenuActive = false;
		this.staticMenuMobileActive = false;
	}

	replaceRedirectUri(subDomain) {
		const urlPaths = {
			redirectUri: environment.customDomainURL + "/",
		};

		const createUrl = (url, subDomain2) => {
			let newUrl = url;
			Object.values(subDomain2).forEach((value) => {
				newUrl = newUrl.replace(environment.defaultSubDomain, value);
			});
			return newUrl;
		};

		const cumtomDomain = {
			portal: subDomain,
		};

		environment.redirectUri = createUrl(urlPaths.redirectUri, cumtomDomain);
		authConfig.redirectUri = environment.redirectUri;
		authConfig.postLogoutRedirectUri = environment.redirectUri;
		authConfig.customQueryParams = {
			invitation_code: environment.subDomain,
		};

		this.oauthService.configure(authConfig);

		if (subDomain == environment.defaultSubDomain) {
			window.location.replace(environment.redirectUri);
		}
	}

	changeTheme(theme) {
		const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById("theme-css");
		themeLink.href = "assets/theme/theme-" + theme + ".css";
		const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById("layout-css");
		layoutLink.href = "assets/layout/css/layout-" + theme + ".css";

		if (theme.indexOf("dark") !== -1) {
			this.darkTheme = true;
		} else {
			this.darkTheme = false;
		}
	}

	private getOrganizationConfig() {
		this.organizationService.getOrganizationConfig().then((res) => {
			this.data.setOrganizationConfig(res);
			this.organization = !isNullOrUndefined(res) ? res : new OrganizationConfig();
		});
	}

	private setLanguageList() {
		this.languageList$ && this.languageList$.unsubscribe();
		this.languageList$ = this.languageService.getLanguageList().subscribe((lang: Language[]) => {
			this.data.setLanguageList(lang);
		});
	}

	public static percentageAllSetupStatic: number = 0;

	get percentageAllSetup() {
		return AppComponent.percentageAllSetupStatic;
	}

	sentryConfigPersonTracking(user: any) {
		setUser({
			id: user?.userId,
			username: user?.username,
			email: user?.emailAddress,
			company: user?.companyName,
		});
	}

	private setUserInfo() {
		this.data.isProd = environment.production;
		this.currentUserInfo$ && this.currentUserInfo$.unsubscribe();
		this.currentUserInfo$ = this.userService.getCurrentUserInfo().subscribe((res) => {
			this.userInfo = res;
			this.checkTenantSetup(this.stateUrl);

			if (
				this.userInfo?.employeeStatus &&
				this.userInfo?.employeeStatus != this.newEmployeeStatus &&
				this.router.url.split("?")[0]?.includes(RouteURL.onboarding)
			) {
				this.router.navigate(["/" + RouteURL.pageNotFound]);
			}

			if (this.userInfo == null || this.userInfo.employeeId == 1 || !this.userInfo.isActive) {
				this.authService.logout();
				this.router.navigate(["/" + RouteURL.accessDenied]);
				return;
			}

			AppComponent.percentageAllSetupStatic = this.userInfo.percentageAllSetting;
			if (this.language && this.userInfo.defaultLanguage != this.language && !this.userInfo.dateLastAccess) {
				this.userInfo.defaultLanguage = this.language;
				firstValueFrom(this.userService.setLanguage({ defaultLanguage: this.userInfo.defaultLanguage })).then(() => {});
			}
			this.data.setUserInfo(this.userInfo);
			this.showOnboard = isNullOrUndefined(sessionStorage.getItem(SessionStorageKey.onBoard));
			if (this.userInfo.needOnboard && this.userInfo?.employeeStatus != this.newEmployeeStatus) {
				window.location.href = `${environment.issuer}/manage/setpassword?returnUrl=${
					environment.redirectUri
				}&accessToken=${sessionStorage.getItem("access_token")}`;
			}

			this.getOrganizationConfig();
			if (this.userInfo?.refClientId) {
				this.getCurrentSubscription();
			}
			this.setMenu();
			if (
				!isNullOrUndefined(this.userInfo.fullName) &&
				!isNullOrUndefined(this.userInfo.companyName) &&
				environment.production
			) {
				this.currentSubscription$ = this.data.currentSubscription$.subscribe((data) => {
					this.data.setBillingType(data?.billingType);

					let nextBilling = !isNullOrUndefined(data?.dateNextBilling)
						? this.datepipe.transform(data?.dateNextBilling, "yyyy-MM-dd")
						: "-";
					let validUntil = !isNullOrUndefined(data?.dateExpired)
						? this.datepipe.transform(data?.dateExpired, "yyyy-MM-dd")
						: "-";
					let model = {
						planName: data?.planName,
						nextBilling: nextBilling,
						validUntil: validUntil,
						planType: data?.billingType,
						activeUser: data?.activeUser,
						limitUser: data?.seat,
						customerCode: data?.customerCode,
						uniqueName: data?.uniqueName,
					};

					this.data.loadCrispChat(
						this.userInfo,
						model,
						`${environment.redirectUri}${RouteURL.support}?email=${this.userInfo.emailAddress}`,
					);
					this.data.closeCrisp();
				});
			}
			if (
				environment.subDomain != environment.defaultSubDomain &&
				this.userInfo.invitationCode.toLowerCase() != environment.subDomain
			) {
				environment.subDomain = environment.defaultSubDomain;
				this.replaceRedirectUri(environment.subDomain);
			}
			//this.initEventNotification(this.userInfo.userId);
			var sourceUserId = this.router.routerState.snapshot.root.queryParams["sourceUserId"];
			if (!isNullOrUndefined(sourceUserId)) {
				sessionStorage.setItem(SessionStorageKey.source_user_id, sourceUserId);
			}

			this.signalRService.startConnection(this.userInfo.userId);
			this.sentryConfigPersonTracking(this.userInfo);
			this.isLoading = true;
		});
	}
	private beginNgxCountDown() {
		let dateStart: string = Cookie.get("DateStart");
		let dateEnd: string = Cookie.get("DateEnd");
		let completedData: string = Cookie.get("CompletedData");
		if (dateStart != "") {
			if (new Date(dateEnd).getTime() >= new Date().getTime()) {
				let assessment: AssessmentModel = new AssessmentModel();
				assessment.assessmentId = parseInt(completedData);
				this.data.setAssessment(assessment);
				this.router.navigateByUrl("/assessment-form");
			} else {
				this.data.setNgxCountDownCompleted(completedData);
			}
		}
		this.ngxCountDown$ = this.data.ngxCountDownCompleted$.subscribe((res1) => {
			let assessmentId: number = parseInt(res1);
			if (assessmentId > 0) {
				this.assessmentFormService.getEmployeeAssessmentById(assessmentId).then((res2) => {
					let data: AssessmentFormScore[] = !isNullOrUndefined(res2) ? res2 : [];
					if (data.length != 0) {
						data.forEach((f) => {
							f.isTimeOut = true;
							f.dateTimeStart = new Date(Cookie.get("DateStart"));
							f.dateTimeEnd = new Date(Cookie.get("DateEnd"));
						});
						this.assessmentFormService.addAssessmentForm(data).subscribe(() => {
							if (this.router.url === "/assessment-form") {
								this.router.navigateByUrl("/pms");
							}
						});
					}
					Cookie.deleteAll();
				});
			}
		});
	}

	private setMenu() {
		this.menuList$?.unsubscribe();
		this.menuList$ = this.componentService.getMenuList().subscribe((data: any[]) => {
			this.data.setMenuList(data);
			this.data.setMenuList(
				data.concat({
					label: "ช่วยเหลือ",
					label_EN: "Help",
					keyPhrase: "sidemenu_help",
					icon: "gf-icon-help",
					routerLink: null,
				}),
			);
		});
	}

	/**
	 * first page
	 * @param state (string) : redirect to page after check user permission and route (data:canBackAfterLogin)
	 * **/
	allowDisplay: boolean;
	loadingAuthorize: boolean = true;
	private checkTenantSetup(state: string) {
		this.redirectPath = "/" + RouteURL.home;
		if (!isNullOrUndefined(state)) {
			if (this.userInfo?.employeeStatus == this.newEmployeeStatus) {
				this.redirectPath = this.checkConditionNavigateToOnBoarding();
			} else {
				let checkPath = this.emptyComponent.some((f) => state.includes(f));
				if (state?.startsWith("/" + RouteURL.team)) {
					this.data.setPreviousRouteName(state);
					state = RouteURL.team;
				}
				if (state.includes(RouteURL.support)) {
					this.redirectPath = state;
				} else {
					this.redirectPath = checkPath ? state : this.redirectPath;
				}
			}
		}
		this.listFunctionCode = [Module.SYS013, Module.SYS018];
		firstValueFrom(this.userControlService.authorizeControlByListFunctionCode(this.listFunctionCode))
			.then((res) => {
				let configured = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.SYS013) : null;
				let plan = !isNullOrUndefined(res) ? res.find((f) => f.functionCode == Module.SYS018) : null;
				this.data.setPermissionPlan(plan);
				this.allowDisplay = !isNullOrUndefined(configured) ? configured?.allowGrant : false;
				this.loadingAuthorize = false;
			})
			.then(() => {
				if (!this.redirectPath.includes("home") || this.router.url.includes("_gl") || this.router.url.includes("_ga")) {
					this.router.navigateByUrl(this.redirectPath);
				}
			});
	}

	private checkConditionNavigateToOnBoarding() {
		let cleanedUrl = this.router.url.replace(/^\/+/g, "");
		const navigateEmployeeUrl = `${RouteURL.employee}/${this.userInfo.employeeNo}`;
		const allowedUrls = [RouteURL.learn, navigateEmployeeUrl];
		return allowedUrls.indexOf(cleanedUrl) !== -1 ? `/${cleanedUrl}` : `/${RouteURL.onboarding}`;
	}

	private checkIsEmptyPage(state: string) {
		if (!isNullOrUndefined(state)) {
			state = state.slice(1, state.length);

			let hasParam = state.search("/");
			if (hasParam > -1) {
				state = state.slice(0, hasParam);
			}
			return state === "" ? false : state.startsWith("m-");
		}
	}

	private checkIsDashboard(state: string) {
		if (!isNullOrUndefined(state)) {
			state = state?.slice(1, state.length);
			let hasParam = state.search("/");
			if (hasParam > -1) {
				state = state?.slice(0, hasParam);
			}
			return state === "" && isNullOrUndefined(state) ? false : this.isDashboards.some((url) => url.startsWith(state));
		}
	}

	getBrowserName(): string {
		const agent = window.navigator.userAgent.toLowerCase();
		switch (true) {
			case agent.indexOf("edge") > -1:
				return "edge";
			case agent.indexOf("opr") > -1 && !!(<any>window).opr:
				return "opera";
			case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
				return "chrome";
			case agent.indexOf("trident") > -1:
				return "ie";
			case agent.indexOf("firefox") > -1:
				return "firefox";
			case agent.indexOf("safari") > -1:
				return "safari";
			default:
				return "other";
		}
	}

	getCurrentSubscription() {
		firstValueFrom(this.clientPortalService.GetCurrentSubscription(Application.empeoId, this.userInfo?.refClientId))
			.then((res) => {
				let datas = res.data;
				this.currentSubscription = res.data;
				const isInactive = this.currentSubscription?.isInactive;
				if (
					!datas &&
					(this.userInfo.subscriptionStatusId == SubscriptionStatus.EXPIRED ||
						(this.userInfo.subscriptionStatusId == SubscriptionStatus.CANCELED && !isInactive))
				) {
					this.authService.logout();
					this.router.navigate(["/" + RouteURL.accessDenied]);
					return;
				}

				this.data.setSubscription(datas);
				this.setMenu();
			})
			.catch(() => {
				this.setMenu();
			});
	}

	backToSetup() {
		this.router.navigate(["/settings"]);
	}

	private getReturnUrlParamsObj() {
		let urlParams = new URLSearchParams(window.location.search);
		if (Object.keys(Object.fromEntries(urlParams.entries())).length === 0) {
			urlParams = new URLSearchParams(window.location.href);
		}
		return Object.fromEntries(urlParams.entries());
	}

	openConnectAccountSidebarWithAction() {
		const returnUrlParamsObj = this.getReturnUrlParamsObj();
		const actionToCore = this.SetConnectAccountSidebarWithAction(returnUrlParamsObj);
		if (actionToCore) {
			let model = {
				type: ResEventType.ConnectAccountSocial,
				eventFunction: ResEventFunction.Open,
				model: actionToCore,
			};
			const navigationExtras: NavigationExtras = { state: model };
			setTimeout(() => {
				this.router.navigate(["/employee-setting"], navigationExtras);
			}, 2000);
		}
	}

	private SetConnectAccountSidebarWithAction(returnUrlParamsObj) {
		let actionToCore: any = {};
		if (returnUrlParamsObj.localizedDescription && returnUrlParamsObj.provider) {
			actionToCore = {
				localizedDescription: returnUrlParamsObj.localizedDescription,
				provider: returnUrlParamsObj.provider,
			};
			if (returnUrlParamsObj.linkedInfo) {
				actionToCore = { ...actionToCore, linkedInfo: returnUrlParamsObj.linkedInfo };
			}
			return actionToCore;
		} else if (returnUrlParamsObj.action_result) {
			actionToCore = {
				action_result: returnUrlParamsObj.action_result,
			};
			return actionToCore;
		}
		return null;
	}

	stopSupported() {
		this.remoteService.stopSupported(this.signalRService.getSourceUserId()).subscribe();
	}
	//#endregion
}
