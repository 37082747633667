@if (!isBadge) {
	<ma-payment-modal
		*axLazyElement="link"
		[token]="token"
		[theme]="theme"
		[currentUser]="currentUser"
		[language]="language"
		[application]="application"
		[isFreePlan]="isFreePlan"
		[toggleTab]="toggleTab"
		(paySuccess)="receivePaySuccess()"
	>
	</ma-payment-modal>
} @else {
	<ma-payment-badge
		*axLazyElement="link"
		[token]="token"
		[theme]="theme"
		[currentUser]="currentUser"
		[language]="language"
		[application]="application"
		[currentSubscription]="currentSub"
	>
	</ma-payment-badge>
}
