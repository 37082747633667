import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ContentChild, Directive, ElementRef, Input, NgZone, Renderer2 } from "@angular/core";
import { Subscription } from "rxjs";
@Directive({
	selector: "[virtualHeader]",
	standalone: true,
})
export class VirtualHeaderDirective {
	@Input() elementRecycle: string = "tr";

	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2,
	) {}

	public placeholder: HTMLElement | null = null;
	public bottomPlaceholder: HTMLElement | null = null;

	ngAfterContentInit() {
		this.createPlaceholder();
	}
	createPlaceholder() {
		const element = this.elementRef.nativeElement;
		this.placeholder = this.renderer.createElement(this.elementRecycle);
		this.renderer.setStyle(this.placeholder, "height", "0", 1);
		this.renderer.insertBefore(element, this.placeholder, element.firstChild);
	}

	setPositionPlaceholder(height: number) {
		if (this.placeholder) {
			this.renderer.setStyle(this.placeholder, "height", `${height ?? 0}px`);
		}
	}
}

@Directive({
	selector: "[empeoVirtualScroll]",
	standalone: true,
	host: {
		class: "empeo-virtual-scroll",
	},
})
export class EmpeoVirtualScrollDirective {
	@Input() itemSize: number;

	@ContentChild(VirtualHeaderDirective) virtualHeader: VirtualHeaderDirective | null = null;
	@ContentChild(CdkVirtualScrollViewport) cdkViewport: CdkVirtualScrollViewport | null = null;

	private _subscription$ = new Subscription();
	constructor(private zone: NgZone) {}
	ngAfterContentInit() {
		this.zone.runOutsideAngular(() => {
			this._subscription$ = this.cdkViewport?.renderedRangeStream.subscribe((event) => {
				const height = event.start * this.itemSize;
				this.virtualHeader.setPositionPlaceholder(height);
			});
		});
	}

	ngOnDestroy() {
		this._subscription$?.unsubscribe();
	}
}
