import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { WorkflowStep } from "../../setting/workflow/shared/workflow-step.model";
import { CommonService } from "../../shared/service/common.service";
import {
	LeaveUsage,
	LeaveDurationCalculateTimeOperation,
	LeaveSearchModel,
	LeaveUsageModel,
	ShearchShiftModel,
} from "../../document-forms/shared/document-forms.model";
import { Leave, LeaveAttachment } from "../../document-forms/leave/shared/leave.model";
import { Checkbox } from "../../shared/models/checkbox.model";
import { CompanyProfile } from "../../master/company-profile/shared/company-profile.model";
import { LeaveType } from "../../master/leave-type/shared/leave-type.model";
import { UserInfoEmployee } from "../../core/shared/user-info.model";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { TimeSpan } from "../../shared/models/timespan.model";
import { firstValueFrom } from "rxjs";
import { LeaveDurationType } from "src/app/shared/enum/leave-duration-type.enum";

@Injectable()
export class DocumentFormsService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	calculateLeaveDurationByDays(
		employeeId: number,
		leaveTypeId: number,
		dateFrom: Date,
		dateTo: Date,
		leaveRequestId?: number,
		isEmergencyLeave?: boolean,
	) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({
			employeeId: employeeId,
			leaveTypeId: leaveTypeId,
			dateFrom: dateFrom,
			dateTo: dateTo,
			leaveRequestId: leaveRequestId,
			isEmergencyLeave: isEmergencyLeave,
		});
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveType/CalculateLeaveDurationByDays", body, httpOptions);
	}

	calculateLeaveDurationByHours(
		employeeId: number,
		dateFrom: Date,
		timeFrom: Date,
		timeTo: Date,
		leaveTypeId: number,
		leaveRequestId?: number,
		isEmergencyLeave?: boolean,
		leaveDurationType: number = LeaveDurationType.hour,
	) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({
			employeeId: employeeId,
			dateFrom: dateFrom,
			timeFrom: timeFrom,
			timeTo: timeTo,
			leaveTypeId: leaveTypeId,
			leaveRequestId: leaveRequestId,
			isEmergencyLeave: isEmergencyLeave,
			leaveDurationType: leaveDurationType,
		});
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "LeaveType/CalculateLeaveDurationByHours", body, httpOptions));
	}

	getShiftDetailByEmployeeId(employeeId: number, dateFrom: Date) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({ employeeId: employeeId, dateFrom: dateFrom });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "LeaveType/GetShiftDetailByEmployeeId", body, httpOptions));
	}

	getShiftDetailByUserId(userId: string, dateFrom: Date) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({ userId: userId, dateFrom: dateFrom });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "LeaveType/GetShiftDetailByUserId", body, httpOptions));
	}

	getWorkflowByLeaveTypeId(leaveTypeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Workflow/GetWorkflowByLeaveTypeId/" + leaveTypeId)).then(
			(res) => <WorkflowStep[]>res,
		);
	}

	getWorkflowByEmployeeId(employeeId: number, leaveTypeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Workflow/GetWorkflowByEmployeeId/" + employeeId + "/" + leaveTypeId),
		).then((res) => <WorkflowStep[]>res);
	}

	getWorkflowStatusByLeaveRequestId(leaveRequestId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Workflow/GetWorkflowStatusByLeaveRequestId/" + leaveRequestId),
		).then((res) => <WorkflowStep[]>res);
	}

	getProcessActionByUserId(leaveRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Leave/GetProcessActionByUserId/" + leaveRequestId)).then(
			(res) => <Checkbox[]>res,
		);
	}

	getLeaveUsage(leaveTypeId: number, date: string) {
		return firstValueFrom(this.http.get(this.apiUrl + "LeaveUsage/GetLeaveUsage/" + leaveTypeId + "/" + date)).then(
			(res) => <LeaveUsage>res,
		);
	}

	getLeaveUsageByEmployeeId(employeeId: number, leaveTypeId: number, date: string) {
		this.commonService.changeDateJSON();

		return firstValueFrom(
			this.http.get(
				this.apiUrl + "LeaveUsage/GetLeaveUsageByEmployeeId/" + employeeId + "/" + leaveTypeId + "/" + date,
			),
		).then((res) => <LeaveUsageModel>res);
	}

	getLeaveRequestById(leaveRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Leave/GetLeaveRequestById/" + leaveRequestId)).then(
			(res) => <Leave>res,
		);
	}

	getPreviewLeaveRequestById(leaveRequestId: number) {
		return this.http.get(this.apiUrl + "Leave/GetPreviewLeaveRequestById/" + leaveRequestId);
	}

	getPreviewUserTimeStampByTime(userId: string, date: Date, time: TimeSpan) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify({ userId: userId, date: date, time: time });

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(this.http.post(this.apiUrl + "Leave/GetPreviewUserTimeStampByTime", body, httpOptions)).then(
			(res) => <Leave>res,
		);
	}

	getLeaveAttachmentById(leaveAttachmentId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Leave/GetLeaveAttachmentById/" + leaveAttachmentId)).then(
			(res) => <LeaveAttachment>res,
		);
	}

	getLeaveById(leaveRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Leave/GetLeaveById/" + leaveRequestId)).then(
			(res) => <Leave>res,
		);
	}

	getLeaveAttachmentsByLeaveRequestId(leaveRequestId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Leave/GetLeaveAttachmentsByLeaveRequestId/" + leaveRequestId),
		).then((res) => <LeaveAttachment[]>res);
	}

	addLeave(leave: Leave) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(leave);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/AddLeave", body, httpOptions);
	}

	editLeave(leave: Leave) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(leave);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/EditLeave", body, httpOptions);
	}

	calLeaveUsage(leaveUsage: LeaveUsage) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(leaveUsage);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "LeaveUsage/CalLeaveUsage/", body, httpOptions);
	}

	AddProcessAction(leave: Leave) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(leave);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/AddProcessAction/", body, httpOptions);
	}

	getCompanyProfileByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "CompanyProfile/GetCompanyProfileByEmployeeId/" + employeeId),
		).then((res) => <CompanyProfile>res);
	}

	getLeaveTypeByLeaveTypeId(leaveTypeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "LeaveType/GetLeaveTypeDetailById/" + leaveTypeId)).then(
			(res) => <LeaveType[]>res,
		);
	}

	getWorkflowByLeaveCategoryId(leaveCategoryId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Workflow/GetWorkflowByLeaveCategoryId/" + leaveCategoryId)).then(
			(res) => <WorkflowStep[]>res,
		);
	}

	getWorkflowByEmployeeIdAndWorkflowId(employeeId: number, workflowId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Workflow/GetWorkflowByEmployeeIdAndWorkflowId/" + employeeId + "/" + workflowId),
		).then((res) => <WorkflowStep[]>res);
	}

	getWorkflowByEmployeeIdAndLeaveCategoryId(employeeId: number, leaveCategoryId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "Workflow/getWorkflowByEmployeeIdAndLeaveCategoryId/" + employeeId + "/" + leaveCategoryId,
			),
		).then((res) => <WorkflowStep[]>res);
	}

	getWorkflowByUserIdAndLeaveCategoryId(userId: string, leaveCategoryId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Workflow/GetWorkflowByLeaveCategoryId/" + userId + "/" + leaveCategoryId),
		).then((res) => <WorkflowStep[]>res);
	}

	calculateTimeOperation(leaveDurationCalculateTimeOperation: LeaveDurationCalculateTimeOperation) {
		var body = JSON.stringify(leaveDurationCalculateTimeOperation);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Common/CalculateTimeOperation/", body, httpOptions);
	}

	calculateTimeOperationByEmployeeId(leaveDurationCalculateTimeOperation: LeaveDurationCalculateTimeOperation) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(leaveDurationCalculateTimeOperation);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Common/CalculateTimeOperation/", body, httpOptions);
	}

	getUserInfoByLeaveRequestId(leaveRequestId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "UserInfo/GetUserInfoByLeaveRequestId/" + leaveRequestId)).then(
			(res) => <UserInfoEmployee>res,
		);
	}

	exportLeaveSummaryToExcel(model: LeaveSearchModel) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Leave/ExportLeaveSummaryToExcel", body, httpOptions);
	}
	getShiftSummaryDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Common/GetShiftSummaryDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}
	exportShiftToExcel(searchShift: ShearchShiftModel) {
		var body = JSON.stringify(searchShift);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Common/ExportShiftToExcel", body, httpOptions);
	}
}
