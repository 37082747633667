import { Pipe, PipeTransform } from "@angular/core";
import { TimeAttandanceStatus } from "../enum/timeAttandance-status.enum";

@Pipe({
	name: "workStatusColor",
	standalone: true,
})
export class WorkStatusColorPipe implements PipeTransform {
	transform(value: any): unknown {
		switch (value) {
			case TimeAttandanceStatus.normal:
				return "#525260";
			case TimeAttandanceStatus.holidayWork:
			case TimeAttandanceStatus.holiday:
				return "rgba(66, 66, 66, 0.65)";
			case TimeAttandanceStatus.noStampIn:
			case TimeAttandanceStatus.noStampOut:
			case TimeAttandanceStatus.absent:
				return "#FFFFFF";
			case TimeAttandanceStatus.late:
			case TimeAttandanceStatus.early:
			default:
				return "white";
		}
	}
}
