import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, Subscription, firstValueFrom } from "rxjs";
import { DataSharingService } from "src/app/core";
import { ClientPortalService } from "./client-portal.service";
import { UserInfo } from "src/app/core/shared/user-info.model";
import { SubscriptionStatus } from "../enum/client-portal.enum";
import { Application } from "../enum/application.enum";
import { AuthService } from "src/app/auth/auth.service";
import { Injectable } from "@angular/core";
@Injectable()
export class SubscriptionGuardService {
	userInfo: UserInfo = new UserInfo();
	currentSubscription: any;
	private currentUserInfo$: Subscription;
	private isSubscriptionExpired = false;
	private isAuthenticated = false;

	constructor(
		private router: Router,
		private clientPortalService: ClientPortalService,
		public data: DataSharingService,
		private authService: AuthService,
	) {
		this.authService.isAuthenticated$.subscribe((i) => {
			this.isAuthenticated = i;
		});
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.isAuthenticated) {
			this.setUserInfo();
		}
		return !this.isSubscriptionExpired;
	}

	async setUserInfo() {
		if (this.userInfo?.employeeId > 0) return;
		this.currentUserInfo$?.unsubscribe();
		this.currentUserInfo$ = this.data.currentUserInfo.subscribe((res) => {
			this.userInfo = res;
			if (this.userInfo?.refClientId) {
				this.getCurrentSubscription();
			}
		});
	}

	getCurrentSubscription() {
		firstValueFrom(this.clientPortalService.GetCurrentSubscription(Application.empeoId, this.userInfo?.refClientId))
			.then((res) => {
				this.currentSubscription = res.data;
				if (
					this.userInfo.subscriptionStatusId == SubscriptionStatus.EXPIRED ||
					this.userInfo.subscriptionStatusId == SubscriptionStatus.TRIAL_EXPIRED ||
					this.userInfo.subscriptionStatusId == SubscriptionStatus.CANCELED
				) {
					this.isSubscriptionExpired = true;
				} else {
					this.isSubscriptionExpired = false;
				}
				if (this.isSubscriptionExpired) {
					this.router.navigate(["/subscription-expired"]);
				}
			})
			.catch(() => {
				this.isSubscriptionExpired = false;
			});
	}
}
