import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Workflow, WorkflowSearch } from "./workflow.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { WorkflowStep } from "./workflow-step.model";
import { SharingService } from "src/app/shared/sharing.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class WorkflowService {
	constructor(
		private http: HttpClient,
		private sharing: SharingService,
		@Inject("API_URL") private apiUrl: string,
		@Inject("GOFIVE_API_URL") private apiUrlCore: string,
	) {}
	getWorkflowStepNoCore(search: WorkflowSearch) {
		var isGroup = search.isGroup != null ? `&isGroup=${search.isGroup}` : "";
		var referenceId = search.referenceId != null ? `&referenceId=${search.referenceId}` : "";
		return firstValueFrom(
			this.http.get(
				this.apiUrlCore +
					`v1/workflows/?moduleId=${search.moduleId}&userId=${search.userId}&tenantId=${search.tenantId}${isGroup}${referenceId}`,
			),
		).then((res) => <any>res);
	}
	getWorkflowById(workflowId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Workflow/GetWorkflowById/" + workflowId)).then(
			(res) => <Workflow>res,
		);
	}

	getWorkflowGridList(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Workflow/GetWorkflowDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	addWorkflow(workflow: Workflow) {
		var body = JSON.stringify(workflow);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Workflow/AddWorkflow", body, httpOptions);
	}

	editWorkflow(workflow: Workflow) {
		var body = JSON.stringify(workflow);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Workflow/EditWorkflow", body, httpOptions);
	}

	deleteWorkflow(workflowId: string[]) {
		var body = JSON.stringify(workflowId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Workflow/DeleteWorkflow", body, httpOptions);
	}

	GetEmployeeNextApproval(search: any) {
		return firstValueFrom(
			this.http.get(this.apiUrl + `workflows/next-approval${this.sharing.getQueryString(search)}`),
		).then((res) => <WorkflowStep>res);
	}
}
