import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	Output,
	TemplateRef,
	ViewEncapsulation,
	computed,
	contentChildren,
	effect,
	signal,
	viewChild,
} from "@angular/core";
import { fromEvent, Observable, Subject, Subscription } from "rxjs";
import { TableColumns } from "src/app/shared/models/common.model";
import {
	CdkVirtualScrollViewport,
	CdkVirtualScrollableWindow,
	CdkFixedSizeVirtualScroll,
	CdkVirtualForOf,
} from "@angular/cdk/scrolling";
import { EmpeoVirtualScrollDirective, VirtualHeaderDirective } from "../../../directive/empeo-for";
import { NgClass, NgTemplateOutlet } from "@angular/common";
@Directive({
	selector: "[empeoTemplate]",
	standalone: true,
})
export class EmpeoTemplate {
	constructor(public template: TemplateRef<any>) {}
	@Input("empeoTemplate") templateName: string;
}

@Component({
	selector: "empeo-table",
	templateUrl: "./empeo-table.component.html",
	styleUrls: ["./empeo-table.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgClass,
		NgTemplateOutlet,
		EmpeoVirtualScrollDirective,
		CdkVirtualScrollViewport,
		CdkVirtualScrollableWindow,
		CdkFixedSizeVirtualScroll,
		VirtualHeaderDirective,
		CdkVirtualForOf,
	],
})
export class EmpeoTableComponent {
	@Input({ alias: "frozenColumn" }) set _frozenColumn(frozenColumn: any[]) {
		this.frozenColumn.set(frozenColumn);
	}
	@Input({ alias: "dataSources" }) set _dataSources(dataSources: any[]) {
		this.dataSources.set(dataSources);
	}

	@Input({ alias: "frozenWidth" }) set _frozenWidth(frozenWidth: string) {
		this.frozenWidth.set(frozenWidth);
	}

	@Input({ alias: "columns" }) set _columns(columns: TableColumns[]) {
		this.columns.set(columns);
	}

	@Input({ alias: "scrollBottom" }) set _scrollBottom(scrollBottom: string) {
		this.scrollBottom.set(scrollBottom);
	}

	@Input({ alias: "height" }) set _height(height: number) {
		this.height.set(height);
	}

	@Input({ alias: "headerHeight" }) set _headerHeight(headerHeight: string) {
		this.headerHeight.set(headerHeight);
	}

	@Input({ alias: "top" }) set _top(top: string) {
		this.top.set(top);
	}

	@Input({ alias: "allowGrabbingMove" }) set _allowGrabbingMove(allowGrabbingMove: boolean) {
		this.allowGrabbingMove.set(allowGrabbingMove);
	}

	@Input({ alias: "bodyUnFrozenWidth" }) set _bodyUnFrozenWidth(bodyUnFrozenWidth: number) {
		this.bodyUnFrozenWidth.set(bodyUnFrozenWidth);
	}

	public dataSources = signal<any[]>([]);
	public frozenColumn = signal<any[]>([]);
	public frozenWidth = signal<string>("0px");
	public columns = signal<TableColumns[]>([]);
	public scrollBottom = signal<string>("0px");
	public height = signal<number>(60);
	public headerHeight = signal<string>("46px");
	public top = signal<string>("60px");
	public allowGrabbingMove = signal<boolean>(true);
	public bodyUnFrozenWidth = signal<number>(0);

	protected frozenColumnRight = computed(() => {
		return this.frozenColumn().filter((f) => f?.align == "right");
	});

	protected frozenColumnLeft = computed(() => {
		return this.frozenColumn().filter((f) => f?.align !== "right");
	});

	protected frozenColumnRightWidth = computed(() => {
		return this.frozenColumnRight().reduce((acc, f) => acc + parseInt(f?.width.split("px")), 0) || 0;
	});

	protected allWidthBody = computed(() => {
		return parseInt(this.frozenWidth().split("px").toString()) + this.frozenColumnRightWidth();
	});

	protected bodyWidth = computed(() => {
		let widthHeader = this.headerTableRef()?.nativeElement?.clientWidth || 0;
		return widthHeader - this.allWidthBody();
	});

	protected customColumns = computed(() => {
		return this.columns().slice(0, Math.ceil(this.bodyWidth() / this.bodyUnFrozenWidth()));
	});

	@Input() set customLoopTrackBy(fn: (index: number, item: any) => any) {
		this.trackByFn = fn;
	}

	@Input() set customLoopHeaderTrackBy(fn: (index: number, item: any) => any) {
		this.trackByHeaderFn = fn;
	}

	public trackByFn: (index: number, item: any) => any;
	public trackByHeaderFn: (index: number, item: any) => any;

	@Input() rowClickFunction: () => {};

	@Output() onHoverRow: EventEmitter<number> = new EventEmitter<number>();

	public classUnfrozen: HTMLElement;
	public headerUnfrozen: HTMLElement;
	public bodyUnfrozen: HTMLElement;
	public footerUnfrozen: HTMLElement;

	private templates = contentChildren(EmpeoTemplate);

	// @ContentChildren(EmpeoTemplate) templates: QueryList<EmpeoTemplate>;
	public bodyUnfrozenRef = viewChild<ElementRef<HTMLElement> | undefined>("bodyUnfrozen");
	public scrollElementRef = viewChild<ElementRef<HTMLElement> | undefined>("scrollElement");
	public headerUnfrozenRef = viewChild<ElementRef<HTMLElement> | undefined>("headerUnfrozen");
	public footerUnfrozenRef = viewChild<ElementRef<HTMLElement> | undefined>("footerUnfrozen");
	public tableWrapperRef = viewChild<ElementRef<HTMLElement> | undefined>("tableWrapper");
	public footerWrapperRef = viewChild<ElementRef<HTMLElement> | undefined>("footerWrapper");
	public bodyFrozenRef = viewChild<ElementRef<HTMLElement> | undefined>("bodyFrozen");
	public headerTableRef = viewChild<ElementRef<HTMLElement> | undefined>("headerTable");

	public headerTemplate: TemplateRef<any>;
	public headerRightTemplate: TemplateRef<any>;
	public bodyTemplate: TemplateRef<any>;
	public footerTemplate: TemplateRef<any>;
	public expendFreeze: TemplateRef<any>[] = [];
	public expandUnFreeze: TemplateRef<any>[] = [];
	public index: number = -1;
	public isDown: boolean = false;
	public dragging: boolean = false;
	public startX: number = 0;
	public scrollLeft: number = 0;
	// public currentExpandHeight = signal<number>(0);

	private $_zoneSubscription: Subscription = new Subscription();
	private $_zoneSubscriptionScroll: Subscription = new Subscription();
	private _setValue$: Subject<any> = new Subject();
	private _setValueSubscription$: Subscription = new Subscription();

	constructor(
		private zone: NgZone,
		private _cdr: ChangeDetectorRef,
	) {
		this._setValueSubscription$ = this._setValue$.subscribe((scrollLeft: number) => {
			this.headerUnfrozenRef().nativeElement.scrollLeft = scrollLeft;
			this.bodyUnfrozenRef().nativeElement.scrollLeft = scrollLeft;
			this.scrollElementRef().nativeElement.scrollLeft = scrollLeft;
			if (this.footerUnfrozenRef()) this.footerUnfrozenRef().nativeElement.scrollLeft = scrollLeft;
		});

		effect(() => {
			this.headerTemplate = this.templates().find((f) => f.templateName === "header")?.template;
			this.bodyTemplate = this.templates().find((f) => f.templateName === "body")?.template;
			this.footerTemplate = this.templates().find((f) => f.templateName === "footer")?.template;
		});
	}

	onWheel = (event: any, index) => {
		if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
			event.preventDefault();
			this._setValue$.next(this.headerUnfrozenRef().nativeElement.scrollLeft + event.deltaX);
		}
	};

	ngOnDestroy(): void {
		this.$_zoneSubscriptionScroll?.unsubscribe();
		this.$_zoneSubscription?.unsubscribe();
		this._setValueSubscription$?.unsubscribe();
	}

	ngAfterViewInit() {
		this.zone.runOutsideAngular(() => {
			const wheel = fromEvent(this.tableWrapperRef().nativeElement, "wheel");
			if (this.allowGrabbingMove()) {
				const mousedown = fromEvent(this.tableWrapperRef().nativeElement, "mousedown");
				const mousemove = fromEvent(this.tableWrapperRef().nativeElement, "mousemove");
				const mouseup = fromEvent(this.tableWrapperRef().nativeElement, "mouseup");
				const mouseleave = fromEvent(this.tableWrapperRef().nativeElement, "mouseleave");
				this.$_zoneSubscriptionScroll.add(this.subscribeWrap(mousedown, this.onMousedown));
				this.$_zoneSubscriptionScroll.add(this.subscribeWrap(mousemove, this.onMousemove));
				this.$_zoneSubscriptionScroll.add(this.subscribeWrap(mouseup, this.onMouseup));
				this.$_zoneSubscriptionScroll.add(this.subscribeWrap(mouseleave, this.onMouseup));
			}

			if (this.footerWrapperRef()) {
				const wheelFooter = fromEvent(this.footerWrapperRef().nativeElement, "wheel");
				this.$_zoneSubscriptionScroll.add(this.subscribeWrap(wheelFooter, this.onWheel));
			}

			this.$_zoneSubscriptionScroll.add(this.subscribeWrap(wheel, this.onWheel));
			return () => {
				this.$_zoneSubscriptionScroll?.unsubscribe();
			};
		});
	}

	activeHoverIndex(currentIndex: number) {
		this.index = currentIndex;
	}

	onScroll(e) {
		if (this.dragging || this.isDown) {
			return;
		}
		this._setValue$.next(e.target.scrollLeft);
	}

	onMousedown = (e: any, index) => {
		if (this.scrollElementRef().nativeElement?.clientWidth >= this.scrollElementRef().nativeElement?.scrollWidth)
			return;
		this.isDown = true;
		if (e) {
			this.startX = e.pageX - this.tableWrapperRef().nativeElement?.offsetLeft;
			this.scrollLeft = this.bodyUnfrozenRef().nativeElement?.scrollLeft;
		}
	};

	onMouseup = (e: any, index) => {
		e.preventDefault();
		e.stopPropagation();
		setTimeout(() => {
			this.dragging = false;
			this._cdr.markForCheck();
		}, 100);
		this.isDown = false;
	};

	onMousemove = (e: any, index) => {
		if (!this.isDown || !e) return;
		const x = e.pageX - this.tableWrapperRef().nativeElement?.offsetLeft;
		const scroll = x - this.startX;
		if (x != this.startX) {
			this.dragging = true;
		}
		const tmpPosition = this.scrollLeft - scroll;
		const maxWidth = (this.headerUnfrozenRef().nativeElement?.children[0] as HTMLElement)?.offsetWidth ?? 0;
		const pos = tmpPosition > maxWidth && this.frozenColumnRight().length === 0 ? maxWidth : tmpPosition;
		const positionScroll = tmpPosition < 0 ? 0 : pos;
		this.headerUnfrozenRef().nativeElement.scrollLeft = positionScroll;
		this.bodyUnfrozenRef().nativeElement.scrollLeft = positionScroll;
		this.scrollElementRef().nativeElement.scrollLeft = positionScroll;
		if (this.footerUnfrozenRef()) this.footerUnfrozenRef().nativeElement.scrollLeft = positionScroll;
	};

	interval: any;

	onMouseOver = (event: any, index) => {
		this.index = index;
		this.onHoverRow.emit(index);
	};

	subscribeWrap(observable: Observable<any>, handler: any, index?: number): Subscription {
		return observable.subscribe((event) => {
			if (!event.defaultPrevented) {
				handler(event, index);
			}
		});
	}
}
