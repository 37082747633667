import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { CommonService } from "../../../shared/service/common.service";
import { BankPaymentTemplate, Payment, PayrollPayment, SummaryFinancialAccumulate } from "./payment.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class PaymentService {
	private httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeFinancialEditHistoryDataTable(dataTablesParameters: any) {
		return this.http.post<any>(this.apiUrl + "Payment/GetEmployeeFinancialEditHistoryDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getPayrollPaymentByEmployeeIdAndYearAndPeriodCalculateNo(
		employeeId: number,
		year: number,
		periodCalculateNo: number,
	) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"Payment/GetPayrollPaymentByEmployeeIdAndYearAndPeriodCalculateNo/" +
					employeeId +
					"/" +
					year +
					"/" +
					periodCalculateNo,
			),
		).then((res) => <PayrollPayment>res);
	}

	getPayrollPaymentById1(
		employeeId: number,
		year: number,
		periodCalculateNo: number,
		companyId: number,
		periodExtendNo: number = 0,
	) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"Payment/GetPayrollPaymentById/" +
					employeeId +
					"/" +
					year +
					"/" +
					periodCalculateNo +
					"/" +
					companyId +
					`?periodExtendNo=${periodExtendNo}`,
			),
		).then((res) => <PayrollPayment>res);
	}

	getPayrollPaymentById(companyId: number, year: number, periodCalculateNo: number, periodExtendNo: number = 0) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"Payment/GetPayrollPaymentById/" +
					companyId +
					"/" +
					year +
					"/" +
					periodCalculateNo +
					`?periodExtendNo=${periodExtendNo}`,
			),
		).then((res) => <PayrollPayment>res);
	}

	getCurrentPayrollPaymentByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "Payment/GetCurrentPayrollPaymentByEmployeeId/" + employeeId),
		).then((res) => <PayrollPayment>res);
	}

	getCurrentPayrollPaymentByCompanyId(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Payment/GetCurrentPayrollPaymentByCompanyId/" + companyId)).then(
			(res) => <PayrollPayment>res,
		);
	}

	getUrlMultiEslip(payment: Payment) {
		var body = JSON.stringify(payment);
		return firstValueFrom(this.http.post(this.apiUrl + "Payment/GetUrlMultiEslip", body, this.httpOptions));
	}

	getUrlPnd1Kor(payment: Payment) {
		var body = JSON.stringify(payment);
		return this.http.post(this.apiUrl + "Payment/GetUrlPnd1Kor", body, this.httpOptions);
	}

	getUrlWelfareEJIP(payment: Payment) {
		var body = JSON.stringify(payment);
		return this.http.post(this.apiUrl + "Payment/GetUrlWelfareEJIP", body, this.httpOptions);
	}

	getUrlPaymentReport(payment: Payment) {
		var body = JSON.stringify(payment);
		return this.http.post(this.apiUrl + "Payment/getUrlPaymentReport", body, this.httpOptions);
	}

	addProcessActionPayment(payment: Payment) {
		var body = JSON.stringify(payment);
		return this.http.post(this.apiUrl + "Payment/AddProcessActionPayment", body, this.httpOptions);
	}

	exportPayrollToExcel(paymentExport: Payment) {
		var body = JSON.stringify(paymentExport);
		return this.http.post(this.apiUrl + "Payment/ExportPayrollByPeriod", body, this.httpOptions);
	}

	importPaymentByExcel(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ImportPaymentByExcel", body, this.httpOptions);
	}

	exportFileDeliverBank(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ExportFileDeliverBank", body, this.httpOptions);
	}
	getDatePaymentForDeliverBank(companyId: number, year: number, periodCalculateNo: number) {
		var body = JSON.stringify({ companyId: companyId, year: year, periodCalculateNo: periodCalculateNo });
		return this.http.post(this.apiUrl + "Payment/GetDatePaymentForDeliverBank", body, this.httpOptions);
	}

	exportProvidentFundBankReport(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ExportProvidentFundBankReport", body, this.httpOptions);
	}

	exportWelfareEJIPBankReport(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ExportWelfareEJIPBankReport", body, this.httpOptions);
	}

	exportSocialSecurityInPeriod(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ExportSocialSecurityInPeriod", body, this.httpOptions);
	}

	exportEmployeeIncomeTax(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ExportEmployeeIncomeTax", body, this.httpOptions);
	}

	exportEmployeeIncomeTaxNew(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ExportEmployeeIncomeTaxNew", body, this.httpOptions);
	}

	exportEmployeeIncomeTaxPND3(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ExportEmployeeIncomeTaxPND3", body, this.httpOptions);
	}

	exportERPExcel(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ExportERPExcel", body, this.httpOptions);
	}

	sentEslip(model: Payment) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/SentESlipByTemplate", body, this.httpOptions);
	}

	getPayrollPaymentLogDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Payment/GetPayrollPaymentLogDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	getEmployeeAccumulateDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(this.apiUrl + "Payment/GetAccumulateDataTable", dataTablesParameters, {
			params: dataTablesParameters,
		});
	}

	getBankPaymentTemplate() {
		return firstValueFrom(this.http.get(this.apiUrl + "Payment/GetBankPaymentTemplate")).then(
			(res) => <BankPaymentTemplate>res,
		);
	}

	exportSummaryPayroll(model: Payment) {
		var body = JSON.stringify(model);
		return firstValueFrom(this.http.post(this.apiUrl + "Payment/ExportSummaryPayroll", body, this.httpOptions)).then(
			(res) => <any>res,
		);
	}

	exportEmployeeFinancialEditHistories(model: Payment) {
		var body = JSON.stringify(model);
		return firstValueFrom(
			this.http.post(this.apiUrl + "Payment/ExportEmployeeFinancialEditHistories", body, this.httpOptions),
		).then((res) => <any>res);
	}

	exportPayrollBankReport(model) {
		var body = JSON.stringify(model);
		return firstValueFrom(this.http.post(this.apiUrl + "Payment/ExportPayrollBankReport", body, this.httpOptions)).then(
			(res) => <any>res,
		);
	}

	exportSummaryPayrollToAx2012(model: Payment) {
		var body = JSON.stringify(model);
		return firstValueFrom(
			this.http.post(this.apiUrl + "Payment/ExportSummaryPayrollToAx2012", body, this.httpOptions),
		).then((res) => <any>res);
	}

	exportSOSReportExcel1(model: Payment) {
		model.type = 3;
		var body = JSON.stringify(model);
		return firstValueFrom(this.http.post(this.apiUrl + "Payment/ExportSOSReportExcel1", body, this.httpOptions));
	}
	getEmployeeFinancialTransactionsSocialSecurity(model: Payment) {
		model.type = 3;
		var body = JSON.stringify(model);
		return firstValueFrom(
			this.http.post<any>(
				this.apiUrl + "Payment/GetEmployeeFinancialTransactionsSocialSecurity",
				body,
				this.httpOptions,
			),
		).then((r) => r.data);
	}

	getSummaryEmployeeFinancialAccumulate(
		caompany: number,
		year: number,
		periodCalculateNo: number,
		periodExtendNo: number = 0,
	) {
		return firstValueFrom(
			this.http.get<SummaryFinancialAccumulate>(
				this.apiUrl +
					`Payment/GetSummaryEmployeeFinancialAccumulate/${caompany}/${year}/${periodCalculateNo}?periodExtendNo=${periodExtendNo}`,
			),
		).then((res) => <any>res);
	}

	editPayrollEmployeeFinancials(pp: any) {
		this.commonService.changeDateJSON();

		var body = JSON.stringify(pp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Payment/EditPayrollEmployeeFinancials", body, httpOptions);
	}

	enablePayslip(payrollPayment: PayrollPayment) {
		var body = JSON.stringify(payrollPayment);
		return firstValueFrom(this.http.post(this.apiUrl + "Payment/EnablePayslip", body, this.httpOptions));
	}

	exportExcelKorYorSor(model: Payment) {
		var body = JSON.stringify(model);
		return firstValueFrom(this.http.post(this.apiUrl + "Payment/ExportKorYorSor", body, this.httpOptions)).then(
			(res) => <any>res,
		);
	}

	exportLegalExecutionExcel(model: Payment) {
		var body = JSON.stringify(model);
		return firstValueFrom(
			this.http.post(this.apiUrl + "Payment/ExportLegalExecutionExcel", body, this.httpOptions),
		).then((res) => <any>res);
	}
	exportTemplatePayrollOutsource() {
		return firstValueFrom(this.http.get(this.apiUrl + "Payment/ExportExcelPayrollOutsource/")).then((res) => <any>res);
	}
	importFilePayrollOutsource(model: any) {
		var body = JSON.stringify(model);
		return this.http.post(this.apiUrl + "Payment/ImportPayrollOutsource", body, this.httpOptions);
	}
	getPayrollPaymentLogsById(companyId: number, year: number, periodCalculateNo: number, periodExtendNo: number = 0) {
		return this.http.get<any>(
			this.apiUrl +
				`payroll/logs?companyId=${companyId}&year=${year}&periodCalculateNo=${periodCalculateNo}&periodExtendNo=${periodExtendNo}`,
		);
	}

	addD365ExpenseCompanyAsync(model: any) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "payroll/expenses/d365/companies", body, this.httpOptions);
	}

	addExpenseEmployeeAsync(model: any) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "payroll/expenses/d365/employees", body, this.httpOptions);
	}

	addPeakExpenseAsync(model: any) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "payroll/expenses/peak", body, this.httpOptions);
	}

	getSPBBankPaymentTemplateById(companyId: number, year: number, periodCalculateNo: number, periodExtendNo = 0) {
		var body = JSON.stringify({
			companyId: companyId,
			year: year,
			periodCalculateNo: periodCalculateNo,
			periodExtendNo: periodExtendNo,
		});
		return firstValueFrom(
			this.http.post(this.apiUrl + "Payment/GetSPBBankPaymentTemplateById", body, this.httpOptions),
		).then((res) => <any>res);
	}

	verifyPayrollPeriod(companyId: number, year: number, periodCalculateNo: number, periodExtendNo: number = 0) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl +
					`payroll/verify-period?companyId=${companyId}&year=${year}&periodCalculateNo=${periodCalculateNo}&periodExtendNo=${periodExtendNo}`,
			),
		);
	}

	getPayrollQueueById(companyId: number, year: number, periodCalculateNo: number, periodExtendNo: number = 0) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl +
					`payroll/queue?companyId=${companyId}&year=${year}&periodCalculateNo=${periodCalculateNo}&periodExtendNo=${periodExtendNo}`,
			),
		);
	}

	// importFilePayrollOutsource(model: any) {
	//   var body = JSON.stringify(model);
	//   return this.http.post(this.apiUrl + 'Payment/ImportPayrollOutsource', body, this.httpOptions);
	// }

	upsertPayrollPeriodExtend(payment) {
		var body = JSON.stringify(payment);
		return this.http.post<any>(this.apiUrl + "payroll/period/extend", body, { observe: "response" });
	}

	getPayrollSlipLogs(model) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "PayrollSlipLog/GetPayrollSlipLogs", body, this.httpOptions);
	}

	deletePayrollPeriodExtend(payment) {
		const options = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
			}),
			body: JSON.stringify(payment),
		};

		return this.http.delete<any>(this.apiUrl + "payroll/period/extend", options);
	}
	getEmployeePaymentDataTable(model) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "Payment/GetEmployeePaymentDataTable", body, this.httpOptions);
	}

	getPaymentPaymentsById(companyId: number, year: number) {
		return firstValueFrom(this.http.get(this.apiUrl + `v1/payments/${companyId}/periods/years/${year}`)).then(
			(res) => <any>res,
		);
	}

	payrollPaymentReset(model) {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.apiUrl + "v1/payroll/reset", body, this.httpOptions);
	}
}
