import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SearchWarningLetter } from "../../employee-movements/employee-punishment/shared/employee-punishment.model";
import { EmployeeCentricSearch } from "../../employee-movements/shared/employee-document.model";
import { Payment } from "../../payroll/payment/shared/payment.model";
import { CommonService } from "../../shared/service/common.service";
import { ReportFilter, ReportModel } from "./report.model";
import { firstValueFrom } from "rxjs";
import { SharingService } from "src/app/shared/sharing.service";
@Injectable()
export class ReportService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
		private sharing: SharingService,
	) {}

	getReportResign(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportResign", body, httpOptions);
	}

	getReportA002(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportA002", body, httpOptions);
	}

	getReportA012(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportA012", body, httpOptions);
	}

	getReportA014(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportA014", body, httpOptions);
	}

	getReportA015(filter: SearchWarningLetter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportA015", body, httpOptions);
	}

	getReportA016(punishId: number) {
		return this.http.get<ReportModel>(this.apiUrl + "Report/GetReportA016/" + punishId);
	}
	getReportCareerProgress(employeeId: number) {
		return this.http.get<ReportModel>(this.apiUrl + "Report/GetReportCareerProgress/" + employeeId);
	}

	getReportA017(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportA017", body, httpOptions);
	}

	getReportASM004(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportASM004", body, httpOptions);
	}

	getReportA003(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportA003", body, httpOptions);
	}

	getReportC003(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportC003", body, httpOptions);
	}

	getReportC003Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Report/GetReportC003Excel", body, httpOptions);
	}

	getReportC004(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportC004", body, httpOptions);
	}

	getReportC004Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "v1/reports/c004/excel", body, httpOptions);
	}

	getReportC006(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportC006", body, httpOptions);
	}

	getReportC006Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "Report/GetReportC006Excel", body, httpOptions);
	}
	getReportC007(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportC007", body, httpOptions);
	}
	getReportC007Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "Report/GetReportC007Excel", body, httpOptions);
	}

	getReportC008(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportC008", body, httpOptions);
	}

	getReportC008Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "Report/GetReportC008Excel", body, httpOptions);
	}

	getReportC009(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportC009", body, httpOptions);
	}

	getReportD0001(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportD0001", body, httpOptions);
	}

	getReportD0002(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportD0002", body, httpOptions);
	}

	getReportD0003(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportD0003", body, httpOptions);
	}

	getReportD0008(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportD0008", body, httpOptions);
	}

	getReportOT001(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportOT001", body, httpOptions);
	}

	getReportOT001Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "v1/reports/ot001/excel", body, httpOptions);
	}

	getReportB013(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportB013", body, httpOptions);
	}

	getReportTRN001(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportTRN001", body, httpOptions);
	}

	getReportB006(filter: EmployeeCentricSearch) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportB006", body, httpOptions);
	}

	getReportB008(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportB008", body, httpOptions);
	}
	getReportB009(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportB009", body, httpOptions);
	}

	getReportB011(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportB011", body, httpOptions);
	}

	getReportB012(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportB012", body, httpOptions);
	}

	getReportB014(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportB014", body, httpOptions);
	}

	getReportW001(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportW001", body, httpOptions);
	}

	getReportW002(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportW002", body, httpOptions);
	}

	getReportW003(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportW003", body, httpOptions);
	}

	getReportW004(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportW004", body, httpOptions);
	}

	getReportP004(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP004", body, httpOptions);
	}

	getReportP005(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP005", body, httpOptions);
	}

	getReportP006(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP006", body, httpOptions);
	}

	getReportP007(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP007", body, httpOptions);
	}

	getReportP007Attachment(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Report/GetReportP007Attachment", body, httpOptions);
	}

	getReportP008(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP008", body, httpOptions);
	}

	getReportP011(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP011", body, httpOptions);
	}

	getReportP012(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP012", body, httpOptions);
	}

	getReportP012TextFile(filter: ReportFilter) {
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Payment/ExportEmployeeIncomeTaxYear", body, httpOptions);
	}

	getReportASM001(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportASM001", body, httpOptions);
	}

	getReportESS002(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportESS002", body, httpOptions);
	}

	getReportP015(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP015", body, httpOptions);
	}

	getReportP016(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP016", body, httpOptions);
	}

	getReportP017(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportP017", body, httpOptions);
	}

	getReportC019_2Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "Report/GetReportP019_2Excel", body, httpOptions);
	}

	getReportW006(filter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportW006", body, httpOptions);
	}
	getReportW007(filter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "Report/GetReportW007", body, httpOptions);
	}

	SendEmailReportP006(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<any>(this.apiUrl + "Payment/SendEmailReportTavi", body, httpOptions);
	}

	getReportW005(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "Welfare/ExportReportEmployeeLoanByCompanyId", body, httpOptions);
	}

	getReportP001(filter: Payment) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(this.http.post(this.apiUrl + "Report/GetReportP001", body, httpOptions)).then((r) => <any>r);
	}
	getReportTimeSheet(filter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "Report/GetReportTimeSheet", body, httpOptions);
	}

	getReportP019(filter: Payment) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(this.http.post(this.apiUrl + "Report/GetReportP019", body, httpOptions)).then((r) => <any>r);
	}
	getReportP020Excel(filter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(this.http.post(this.apiUrl + "Report/GetReportP020Excel", body, httpOptions));
	}

	getReportP020ExcelForMonth(filter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(this.http.post(this.apiUrl + "Report/GetReportP020ExcelForMonth", body, httpOptions));
	}

	getReportP016Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post(this.apiUrl + "Report/GetReportP016Excel", body, httpOptions);
	}

	getReportP021(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Report/GetReportP021", body, httpOptions);
	}

	getReportP022(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Report/GetReportP022", body, httpOptions);
	}

	getReportP023(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "report/sso-resign-p023", body, httpOptions);
	}

	embedReportPowerBI(dashboardId: number) {
		const searchModel = { dashboardId: dashboardId };
		return this.http.get<any>(`${this.apiUrl}v1/reports/power-bi/embed${this.sharing.getQueryString(searchModel)}`);
	}

	getLearningReport(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(filter);

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return this.http.post<ReportModel>(this.apiUrl + "v1/reports/learning-report", body, httpOptions);
	}
	getReportP011v2Excel(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		const body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "v1/reports/p011-2/excel", body, httpOptions);
	}

	getReportP011v2(filter: ReportFilter) {
		this.commonService.changeDateJSON();
		const body = JSON.stringify(filter);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<ReportModel>(this.apiUrl + "v1/reports/p011-2", body, httpOptions);
	}
}
