<div
	class="ui-g-12 ui-g-nopad {{ documentStatusId > 0 && !isWebView ? '' : '' }} p-0 {{
		documentStatusId >= 0 ? '' : 'pb-4'
	}}"
>
	@if (workflow != null && workflow.length != 0) {
		<div class="ui-g-12 ui-g-nopad px-4" style="padding-bottom: 5px">
			<p
				class="{{ documentStatusId >= 0 ? ' text-color-12' : 'text-color-12 border-bottom-gray py-2' }} {{
					isWebView ? 'px-3 text-header-5' : 'text-header-5 '
				}}"
			>
				{{ "common_timeline" | translate }}
			</p>
		</div>
	}
	<div class="p-0">
		@for (item of workflow; track item) {
			<div
				class="ui-g-12 ui-g-nopad notification {{ isWebView ? 'px-3' : '' }} {{
					documentStatusId > 0 ? 'pt-3 pb-3 ' : 'py-3'
				}}"
				[ngStyle]="{ background: item.processId > 0 ? '' : documentStatusId > 0 ? 'rgba(255, 243, 236, 0.6)' : '' }"
			>
				<div class="contacts px-4">
					<div class="d-flex d-flex-center">
						<span>
							@if (isloadImage) {
								<ngx-skeleton-loader
									[theme]="{
										width: '40px',
										height: '40px',
										margin: '0px 8px 0px 0px',
										float: 'left',
										animation: 'infinite progress'
									}"
									animation="progress-dark"
									appearance="circle"
								>
								</ngx-skeleton-loader>
							}
							<img
								style="max-width: 40px; max-height: 40px; min-width: 40px; min-height: 40px; width: 40px; height: 40px"
								[hidden]="isloadImage"
								class="small-image"
								(load)="loadImg($event)"
								(error)="loadImg($event)"
								library="verona-layout"
								src="{{ item.pictureThumbnailURL }}"
							/>
						</span>
						<span style="width: 100%; height: 40px; display: flex; flex-direction: column">
							<div class="d-flex d-flex-center" style="justify-content: space-between">
								<span class="go5-text-body-strong go5-text-color-12 name ellipsis">
									{{ data.getIsTH(data.currentLanguage | async) ? item.employeeName : item.employeeName_EN }}
								</span>
								@if (item.processId > 0) {
									<span class="go5-text-small go5-text-color-9" style="float: right">
										{{
											(item.dateCreated != null ? item.dateCreated : currentDate)
												| date: "dd MMM yyyy HH:mm" : (data.currentLanguage | async)
										}}
									</span>
								}
							</div>
							<div class="d-flex d-flex-center">
								<em class="{{ item.classIcon }} f-12"></em>
								<span
									style="display: inline-block; margin: 0px 0px -2px 4px"
									class="{{ item.processId > 0 ? 'go5-text-color-9' : 'go5-text-color-8' }} go5-text-small ellipsis"
								>
									<label>
										{{ item.processAction | translate }}
									</label>
								</span>
							</div>
						</span>
					</div>
				</div>
				@if (item.remark != null && item.remark !== "") {
					<div class="text-color-10 reason ellipsis pre-wrap pr-4" style="padding-left: 74px; padding-top: 8px">
						<div [innerHTML]="item.remark != null ? item.remark : ''"></div>
					</div>
				}
			</div>
		}
	</div>
</div>
