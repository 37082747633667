import { SalaryAdjSection } from "src/app/movement/movement-sidebar-add/movement-sidebar-add.component";
import { EmployeeAddress } from "../employee-address/shared/employee-address.model";
import { EmployeeContactPerson } from "../employee-address/shared/employee-contactperson.model";
import { EmployeeEducation } from "../employee-education/shared/employee-education.model";
import { EmployeeWorkExperience } from "../employee-work-experience/shared/employee-work-experience.model";

export class Employee {
	companyBankAccountNo: string;
	nextUpperSupervisorId: number;
	applicantNo: string;
	employeeId: number;
	employeeNo: string;
	incomeAmount: number;
	titleId: number;
	name: string;
	surname: string;
	name_EN: string;
	surname_EN: string;
	nickName: string;
	nickName_EN: string;
	fullName: string;
	fullName_EN: string;
	gender: number;
	heightInCM: number;
	weightInKGS: number;
	skinColorId: number;
	otherSkinColor: string;
	marriageStatus: number;
	militaryStatus: number;
	militaryComments: string;
	nationalityId: number;
	otherNationality: string;
	raceId: number;
	otherRace: string;
	religionId: number;
	otherReligion: string;
	bloodGroup: number;
	idCardNo: string;
	idCardIssuePlace: string;
	dateIDCardIssue: Date;
	dateIDCardExpire: Date;
	dateOfBirth: any;
	employeeStatus: number;
	employmentType: number;
	paymentMethod: number;
	dateEmployment: Date;
	shiftStartDate: Date = new Date();
	probationDays: number;
	dateEmploymentEffective: Date;
	newPostDays: number;
	dateNewPostEffective: Date;
	contractMonths: number;
	dateProvidentFundStart: Date;
	isMarryLeave: boolean;
	isMonkLeave: boolean;
	isCash: boolean;
	taxType: number;
	cardCode: string;
	orgLevelOne: number;
	orgLevelTwo: number;
	orgLevelThree: number;
	orgLevelFour: number;
	orgLevelFive: number;
	orgLevelSix: number;
	workingLocationCode: string;
	locationTypeCode: string;
	rankNo: number;
	rankName: string;
	isAttendance: boolean = true;
	dateRankStart: Date;
	positionLevelNo: number;
	positionId: number;
	positionCardId: number;
	positionCardName: string;
	datePositionStart: Date;
	jobLineId: number;
	jobCodeId: number;
	emailAddress: string;
	telephoneNo: string;
	mobileNo: string;
	extensionNo: string;
	hasPicture: boolean;
	upperSupervisorId: number;
	hasOwnVehicle: boolean;
	ownVehicleType: string;
	bankId?: number;
	bankCodeId: number;
	bankAccountNo: string;
	dateTermination: Date;
	remarkTermination: string;
	terminationReasonId: number;
	terminationReasonName: string;
	terminationReasonName_EN: string;
	socialSecurityHospital: string;
	movementHistoryId: number;
	newEntryType: string;
	refEmployeeNo: string;
	isAD: boolean;
	isReplaceEmployee: boolean = false;
	replaceEmployeeId: number;
	lineId: string;
	bankaccountno: string;
	isActive: boolean;
	signature: string;
	passportNo: string;
	datePassportIssue: Date;
	datePassportExpire: Date;
	isVendorSupport: boolean;
	vendorSupport: string;
	maxSupportPerMonth: number;
	remark: string;
	oriPic: any;
	originalPicture: string;
	picture: string;
	empPicture: string;
	shiftId?: number;
	dayOfProbation: number;
	calendarId: number;
	companyId: number;
	durationOfEmployment_EN: string;
	durationOfEmployment: string;
	nameSurname_EN: string;
	nameSurname: string;
	shiftName: string;
	shiftName_EN: string;
	attendance: string;
	orgLevelOneName: string;
	orgLevelOneName_EN: string;
	orgLevelTwoName: string;
	orgLevelTwoName_EN: string;
	orgLevelThreeName: string;
	orgLevelThreeName_EN: string;
	orgLevelFourName: string;
	orgLevelFourName_EN: string;
	orgLevelFiveName: string;
	orgLevelFiveName_EN: string;
	orgLevelSixName: string;
	orgLevelSixName_EN: string;
	orgLevelOneCode: string;
	orgLevelTwoCode: string;
	orgLevelThreeCode: string;
	orgLevelFourCode: string;
	orgLevelFiveCode: string;
	orgLevelSixCode: string;
	upperSupervisorName: string;
	upperSupervisorName_EN: string;
	positionName: string;
	positionName_EN: string;
	rank: string;
	employmentTypes: string;
	employmentTypes_EN: string;
	replaceEmployeeName: string;
	replaceEmployeeName_EN: string;
	dateModified: string;
	modifiedBy: string;
	rateTypeName: string;
	rateTypeName_EN: string;
	marriageStatusName: string;
	marriageStatusName_EN: string;
	employeeStatusName: string;
	employeeStatusName_EN: string;
	religionName: string;
	religionName_EN: string;
	raceName: string;
	raceName_EN: string;
	status: string;
	status_EN: string;
	militaryStatusName: string;
	militaryStatusName_EN: string;
	nationalityName: string;
	nationalityName_EN: string;
	skinColorName: string;
	skinColorName_EN: string;
	bloodGroupName: string;
	bloodGroupName_EN: string;
	genderName: string;
	genderName_EN: string;
	titleName: string;
	titleName_EN: string;
	displayName: string;
	asOfDate_TH: string;
	asOfDate_EN: string;
	companyName: string;
	companyName_EN: string;
	pipeEmployeeId: string;
	userId: string;
	absenceDay: number;
	lateDay: number;
	earlyDay: number;
	noStampInDay: number;
	noStampOutDay: number;
	isFoodCoupon: boolean;
	isBirthdayCoupon: boolean;
	age: string;
	age_EN: string;
	dateOfBirth_TH: string;
	dateOfBirth_EN: string;
	dateEmployment_TH: string;
	dateEmployment_EN: string;
	fullDateEmployment_TH: string;
	fullDateEmployment_EN: string;
	username: string;
	logoCompany: string;
	taxCalculateType: number;
	isLeaveCollect: boolean;
	refUserGUID: string;
	pictureURL: string;
	pictureFullURL: string;
	originalPictureFullURL: string;
	assessmentStatus: number;
	assessmentStatus_TH: string;
	assessmentStatus_EN: string;
	dateTermination_TH: string;
	dateTermination_EN: string;
	userShiftId: number;
	dateFrom: Date;
	dateTo: Date;
	dateFrom_Str: string;
	dateTo_Str: string;
	description: string;
	pictureThumbnailURL: string;
	pictureMediumURL: string;
	pictureOriginalURL: string;
	pictureType: string;
	pictureFileName: string;
	driverNo: string;
	workLocationId: number;
	isForeigner: boolean;
	dateModified_TH: string;
	dateModified_EN: string;
	dateStart: Date;
	isBirthDayCoupon: boolean;
	workPermitNo: string;
	dateWorkPermitIssue: Date;
	dateWorkPermitExpire: Date;
	invitationCode: string;
	dateInvitationCodeExpired: Date;
	scoreId: number;
	isSelfUpdate: boolean;
	emailAddressPersonal: string;
	pin: string;
	departmentId: number;
	dateContractExpired: Date;
	salary: number;
	financialCategoryId: number;
	amount: number;
	assessmentSetId: number;
	candidateAssessmentSetId: number;
	employeeAddressModel: EmployeeAddress[];
	employeePersonalContactModel: EmployeeContactPerson;
	isTimeLock: boolean = false;
	paySlipChannelType: number;
	isSendEmailTutorial: boolean;
	isSendEmailOnboarding: boolean;
	compareDataList: any[] = [];
	employeeExperienceModels: EmployeeWorkExperience[] = [];
	employeeEducationModels: EmployeeEducation[] = [];
	password: string;
	rolesId: string = "";
	userIsActive: boolean = true;
	newRolesName: string;
	oldRolesName: string;
	passphrase: string;
	paymentType: number;
	bankPaymentType: number;
	isSendEmailConfirm: boolean = true;
	isEmailConfirm: boolean;
	isDisplayBirthDay: boolean;
	dateComparator: Date;
	recruitment: any;
	defaultLanguage: any;
	attendanceScore: EmployeeAttendanceScoreDetailModel;
	socialSecurityNo: number;
	typeCardSSO: number;
	nationalitySSOId: string;
	hospitalId: string;
	hospitalIdTwo: string;
	hospitalIdThree: string;
	dateReceivedSSO: Date;
	terminationReasonSSOId: number;
	evidenceOfRegistrationSSO: string;
	branchIdSSO: number;
	businessCodeSSO: string;
	rateSSO: number;
	isSoc: boolean;
}

export class EmployeeAttendanceScoreDetailModel {
	totalAttendanceScore: number;
	totalLateScoreDeduct: number;
	totalAbsentScoreDeduct: number;
	totalNoStampScoreDeduct: number;
	totalEndorseTimeScoreDeduct: number;
	totalBusinessLeaveScoreDeduct: number;
	totalSickLeaveScoreDeduct: number;
}

export class EmployeeIncomeHistory {
	employeeId: number;
	dateEffective: Date;
	dateEffective_TH: string;
	dateEffective_EN: string;
	prevIncomeAmount: number;
	incomeAmount: number;
	movementResult: string;
	movementResult_EN: string;
	processActionName: string;
	processActionName_EN: string;
	fineRate: number;
	modified: string;
	modified_EN: string;
	dateModified: string;
	dateModified_EN: string;
	recordCount: number;
}

export class BonusHistoryModel {
	datePaymentPeriod: Date;
	bonus: number;
	bonusDetail: number;
	dateModified: Date;
	modifiedBy: string;
}

export class ShiftSchedule {
	id: number;
	userShiftId: number;
	name: string;
	startTime: Date;
	endTime: Date;
	asOfDate: Date;
	description: string;
	departmentID: number;
	consultantID: number;
	departmentName: number;
	subject: string;
	categoryColor: string;
	isAllDay: boolean;
	index: number;
}

export class EmployeeShiftSchedule {
	rowCount?: number;
	employeeShifts: EmployeeShift[];
	shiftSchedule: ShiftSchedule[];
}

export class EmployeeShift {
	employeeId: number;
	employeeNo?: string;
	nameSurname: string;
	nameSurname_EN: string;
	positionName: string;
	positionName_EN: string;
	picture: string;
}

export class SearchPeoplePickerModel {
	name: string;
	organizeId: number;
	companyIdStr: string;
	orgLevelTwoStr: string;
	orgLevelThreeStr: string;
	orgLevelFourStr: string;
	orgLevelFiveStr: string;
	orgLevelSixStr: string;
	rankNoStr: string;
	employmentTypeStr: string;
	employeeStatusStr: string;
	monthStartEmployment: number;
	monthEndEmployment: number;
	workLocationId: number;
	workShiftId: number[];
	hasSupervisor: boolean;
	isSupervisor: boolean;
	isWithoutMe: boolean;
	skipRow: number;
	takeRow: number;
	paymentMethod: number;
	withOutEmpId: number;
}

export class AdjustmentItemSAModel {
	category: number;
	percentage: number;
	avgSalary?: number;
	financialItemId: number;
	name: string;
	name_EN: string;
	amount: number;
	prevAmount: number;
	welfareId?: number;
	welfareType?: number;
	conditionType?: number;
	isSelfService?: number;
	isIndividual?: number;
	isWelfare?: boolean;
}

export class AdjustmentSAModel {
	date: string;
	incomeAmount: number;
	avgSalary: number;
	hasBonusDetail: boolean;
	dateRoundStart: number;
	dateRoundEnd: number;
	totalAmount: number;
	totalAmountPercentage: number;
	baseSalary: number;
	financial: {
		amount: number;
		items: AdjustmentItemSAModel[];
	};
	history: {
		salary: AdjustmentItemSAModel[];
		welfares: AdjustmentItemSAModel[];
		bonuses: AdjustmentItemSAModel[];
		positions: {
			date: string;
			name: string;
		}[];
	};
}

export class MovementIncomeTransactionsSAModel {
	incomeTransactionId: number;
	financialItemId: number;
	category: number;
	percentage: number;
	name: string;
	name_EN: string;
	amount: number;
	prevAmount: number;
	avgSalary?: number;
	welfareId: number;
	isIndividual: boolean;
	isNew: boolean;
	welfareType: number;
}

export class MovementSalaryAdjustmentModel {
	typeAdjustment: number = SalaryAdjSection.performance;
	positionId: number;
	rankNo: number;
	organizationId: number;
	upperSupervisorId: number;
	incomeTransactions: MovementIncomeTransactionsSAModel[];
}

export class EmployeeSalaryAdjustmentModel {
	isAllowPermission: string;
	employeeId: number;
	pictureUrl: string;
	fullName: string;
	fullName_EN: string;
	positionName: string;
	positionName_EN: string;
	rankName: string;
	adjustment: AdjustmentSAModel;
	movement: MovementSalaryAdjustmentModel;
}

export class SalaryAdjustmentHistory {
	dateEffective: Date;
	amount: number;
	prevAmount: number;
	name: string;
	name_EN: string;
	remark: string;
	modifiedBy: string;
	modifiedBy_EN: string;
	dateModified: Date;
	movementTransactionId: number;
}
