import { AttachmentModule as DesignSystemAttachmentModule } from "@gofive/design-system-attachment";
import { AvatarModule as DesignSystemAvatarModule } from "@gofive/design-system-avatar";
import { BadgeModule as DesignSystemBadgeModule } from "@gofive/design-system-badge";
import { ButtonModule as DesignSystemButtonModule } from "@gofive/design-system-button";
import { DialogModule as DesignSystemDialogModule } from "@gofive/design-system-dialog";
import { DropdownModule as DesignSystemDropdownModule } from "@gofive/design-system-dropdown";
import { InputModule as DesignSystemInputModule } from "@gofive/design-system-input";
import { ProgressModule as DesignSystemProgressModule } from "@gofive/design-system-progress";
import { SelectionModule as DesignSystemSelectionModule } from "@gofive/design-system-selection";
import { TagsModule as DesignSystemTagsModule } from "@gofive/design-system-tags";
import { ToastModule as DesignSystemToastModule } from "@gofive/design-system-toast";
import { TooltipModule as DesignSystemTooltipModule } from "@gofive/design-system-tooltip";
import { BaseModule as DesignSystemBaseModule } from "@gofive/design-system-base";
import { LinkModule as DesignSystemLinkModule } from "@gofive/design-system-link";
import { CalendarModule as DesignSystemCalendarModule } from "@gofive/design-system-calendar";
import { NavigationModule as DesignSystemNavigationModule } from "@gofive/design-system-navigation";
import { TableModule as DesignSystemTableModule } from "@gofive/design-system-table";
import { NgModule } from "@angular/core";
import { InfoTooltipComponent } from "./shared/template/info-tooltip/info-tooltip.component";
import { EmpeoCommonModule } from "./empeo-common.module";
import { EmpeoTableComponent, EmpeoTemplate } from "./shared/component/table/empeo-table/empeo-table.component";
import { EmpeoVirtualScrollDirective, VirtualHeaderDirective } from "./shared/directive/empeo-for";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

const designSystems = [
	DesignSystemInputModule,
	DesignSystemButtonModule,
	DesignSystemDialogModule,
	DesignSystemAttachmentModule,
	DesignSystemAvatarModule,
	DesignSystemDropdownModule,
	DesignSystemBadgeModule,
	DesignSystemTooltipModule,
	DesignSystemToastModule,
	DesignSystemProgressModule,
	DesignSystemSelectionModule,
	DesignSystemTagsModule,
	DesignSystemBaseModule,
	DesignSystemLinkModule,
	DesignSystemCalendarModule,
	DesignSystemNavigationModule,
	DesignSystemTableModule,
	EmpeoCommonModule,
	EmpeoVirtualScrollDirective,
	VirtualHeaderDirective,
	ScrollingModule,
];

const components = [InfoTooltipComponent, EmpeoTableComponent, EmpeoTemplate];

@NgModule({
	imports: [...designSystems, FormsModule, CommonModule, ...components],
	exports: [...designSystems, ...components],
})
export class DesignSystemModule {}
