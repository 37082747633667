export class Assets {
	assetId: number;
	code: string;
	name: string;
	assetCategoryId: number;
	serialNo: string;
	branchId: number;
	cost: number;
	remark: string;
	tag: string;
	hasWarranty: boolean;
	monthOfWarranty: number;
	datePurchase: Date;
	ownerEmployeeId: number;
	statusId: number;
	isActive: boolean;
	isDelete: boolean;
	createdBy: string;
	dateCreated: Date;
	modifiedBy: string;
	dateModified: Date;
	isCommon: boolean;
}

export class AssetAttachments {
	assetAttachmentId: number;
	assetId: number;
	fileName: string;
	fileSize: number;
	fileType: string;
	attachmentURL: string;
	isCover: boolean;
	isActive: boolean;
	createdBy: string;
	dateCreated: Date;
}

export class AssetsModel extends Assets {
	attachments: AssetAttachments[];
	attachmentURL: string | null;
	masterSetupName: string;
	masterSetupName_EN: string;
	branchName: string | null;
	branchName_EN: string | null;
	employeeName: string | null;
	employeeName_EN: string | null;
	processName: string;
	processName_EN: string;
	statusName: string;
	statusName_EN: string;
	isEdit: boolean;
	ownerFullName: string;
	ownerFullName_EN: string;
	dateAssigned: Date;
	tagIds: number[];
	isChecked: boolean;
	daysLeftTermination: number | null;
}

export class AssetLogsModel {
	assetLogId: number;
	assetId: number;
	pictureThumbnailURL: string;
	employeeName: string;
	employeeName_EN: string;
	processId: number;
	processAction: string;
	processAction_EN: string;
	dateCreated: Date;
	createdBy: string;
}
