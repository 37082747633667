import {
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter,
	OnDestroy,
	OnChanges,
	SimpleChanges,
	forwardRef,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { firstValueFrom, Subscription, BehaviorSubject } from "rxjs";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DataSharingService, UserInfoService, UserControlService } from "src/app/core";
import { AssetsService } from "../../service/assets.service";
import { isNullOrUndefined } from "../../sharing.service";
import { ToastHelperService } from "../../service/toast-helper.service";
import { CommonService } from "../../service/common.service";
import { CompanyProfileService } from "src/app/master/company-profile/shared/company-profile.service";
import { UploadFileService } from "../../upload.service";
import { EmployeeMovementsService } from "src/app/employee-movements/shared/employee-movements.service";
import { AttachmentViewerService, AttachmentModule } from "@gofive/design-system-attachment";
import { MasTagsService } from "../../service/mas-tag.service";
import { DropdownModule } from "@gofive/design-system-dropdown";
import { BadgeModule } from "@gofive/design-system-badge";
import { InputModule, optionModel } from "@gofive/design-system-input";
import { TagsModule } from "@gofive/design-system-tags";
import { BaseModule } from "@gofive/design-system-base";
import { ButtonModule } from "@gofive/design-system-button";
import { SidebarModule } from "@gofive/design-system-navigation";
import { CalendarModule } from "@gofive/design-system-calendar";
import { LinkModule } from "@gofive/design-system-link";
import { AvatarModule } from "@gofive/design-system-avatar";
import { DateFormat } from "@gofive/angular-common";
import { EmpeoCommonModule } from "src/app/empeo-common.module";
import { PeoplePickerComponent } from "../../template/people-picker/people-picker.component";
import { Employee } from "../../../employee-movements/shared/employee-movements.model";
import { ApiResult } from "../../models/api-result.model";
import { AssetsModel, AssetLogsModel } from "../../models/assets.model";
import { SystemRegisNo } from "../../enum/master-setup.enum";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { Permission } from "../../models/permission.model";
import { Module } from "../../enum/module.enum";
import { SafeHtmlPipe } from "src/app/shared/pipe/safe-html/safe-html.pipe";

export enum respStatus {
	success = 1000,
	fail = 400,
}

export enum TimePeriod {
	month = 1,
	year = 2,
}

export enum ProcessAction {
	Added = 12001,
	Assigned = 12002,
	Transfer = 12004,
}

@Component({
	selector: "app-assets-sidebar",
	templateUrl: "./assets-sidebar.component.html",
	styleUrls: ["./assets-sidebar.component.scss"],
	standalone: true,
	imports: [
		SidebarModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		InputModule,
		TranslateModule,
		DropdownModule,
		TagsModule,
		BaseModule,
		NgxSkeletonLoaderModule,
		AttachmentModule,
		ButtonModule,
		BadgeModule,
		forwardRef(() => PeoplePickerComponent),
		CalendarModule,
		LinkModule,
		AvatarModule,
		TooltipModule,
		EmpeoCommonModule,
		SafeHtmlPipe,
	],
})
export class AssetSlideBarComponent implements OnInit, OnDestroy, OnChanges {
	public isTH;

	get dateFormat() {
		return DateFormat;
	}
	get TimePeriod() {
		return TimePeriod;
	}
	get ProcessAction() {
		return ProcessAction;
	}

	@Input() employeeId = 0;
	@Input() displayAssetsSidebar: boolean;
	@Input() selectAssetId = 0;
	@Input() hideIconDelete = false;

	@Output() readonly closeSidebar = new EventEmitter<{ display: boolean; assetId: any; status: any }>();
	@Input() isWebView = false;
	@Output() isOpenPeoplePicker = new EventEmitter<boolean>();

	permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	public asset: AssetsModel;
	private permissionSubject = new BehaviorSubject<Permission>(this.permission);
	public permission$ = this.permissionSubject.asObservable();
	private assetSubject = new BehaviorSubject<AssetsModel | null>(null);
	public asset$ = this.assetSubject.asObservable();
	public fieldsDDL = { text: "text", value: "value" };
	public filedMasterSetupDDL = { text: "name", value: "id" };
	public filedMasterSetupDDL_EN = { text: "name_EN", value: "id" };
	public filedBranch = { text: "text", value: "id" };
	public filedBranch_EN = { text: "text_EN", value: "id" };
	public filedTag = { text: "name", value: "tagId" };
	public allowExtensions =
		".jpg,.jpeg,.bmp,.png,.pdf,.mp4,.mov,.wmv,.avi,.JPG,.JPEG,.BMP,.PNG,.PDF,.MP4,.MOV,.WMV,.AVI";

	public defaultLockFilter = [
		{ index: 1 },
		{ index: 2 },
		{ index: 3 },
		{ index: 6 },
		{ index: 7 },
		{ index: 8 },
		{ index: 9 },
		{ index: 10 },
		{ index: 11 },
		{ index: 12 },
		{ index: 13 },
	];
	public isAdd = true;
	public isEdit = false;
	public isLoad = true;
	public isShowTitle = false;
	public formGroup: UntypedFormGroup;
	public files = [];
	public attached = [];
	public attachments = [];
	public isMobile = false;
	public userInfo = null;
	public assetsTypeDDL = [];
	public branchDDL = [];
	public tagDDL = [];
	public displayPeoplePicker = false;
	public selectOwnerEmployee: Employee[] = [];
	public isErrorOwner = false;
	public hasWarranty = false;
	public datePurchase: Date = new Date();
	public warrantyEndDate: Date = new Date();
	public lang$: Subscription;
	public user$: Subscription;
	public timePeriodDDL: optionModel[];
	public selectTimePeriod: number = TimePeriod.year;
	public timeOfWarranty = 1;
	public isActive = true;
	public assetLogs: AssetLogsModel[] = [];
	public assetTag = [];

	constructor(
		public assetsService: AssetsService,
		public userInfoService: UserInfoService,
		public data: DataSharingService,
		private _toastHelperService: ToastHelperService,
		private translate: TranslateService,
		private commonService: CommonService,
		private uploadFileService: UploadFileService,
		private companyProfileService: CompanyProfileService,
		private employeeMovementsService: EmployeeMovementsService,
		private attachmentViewerService: AttachmentViewerService,
		private masTagsService: MasTagsService,
		private userControlService: UserControlService,
	) {
		this.formGroup = new UntypedFormGroup({
			Name: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
			AssetCategoryId: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
			SerialNo: new UntypedFormControl(null),
			BranchId: new UntypedFormControl(null),
			Cost: new UntypedFormControl(0),
			Remark: new UntypedFormControl(null),
			TagIds: new UntypedFormControl(null),
			HasWarranty: new UntypedFormControl(false),
			MonthOfWarranty: new UntypedFormControl(null),
			DatePurchase: new UntypedFormControl(null),
			IsCommon: new UntypedFormControl(false),
			OwnerEmployeeId: new UntypedFormControl(null),
			IsActive: new UntypedFormControl(true),
			Code: new UntypedFormControl(0),
			CreatedBy: new UntypedFormControl(0),
		});
	}

	ngOnInit() {
		firstValueFrom(this.userControlService.authorizeControl(Module.SYS027)).then((auth) => {
			this.permission = auth;
			this.permissionSubject.next(this.permission);
			this.loadData();
		});

		this.lang$ = this.data.currentLanguage?.subscribe((lang) => {
			this.isTH = this.data.getIsTH(lang);
			this.translateKey();
		});

		this.user$ = this.data.currentUserInfo.subscribe((res) => {
			if (isNullOrUndefined(res?.userId)) {
				firstValueFrom(this.userInfoService.getCurrentUserInfo()).then((profile) => {
					this.data.setUserInfo(profile, this.isWebView ? false : true);
					this.userInfo = profile;
				});
			} else {
				this.userInfo = res;
			}
		});
	}

	ngOnDestroy() {
		if (this.lang$) this.lang$.unsubscribe();
		if (this.user$) this.user$.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.selectAssetId) {
			if (this.selectAssetId && this.selectAssetId > 0) {
				this.isAdd = false;
				this.assetsService.getAssetsById(this.selectAssetId).then((response) => {
					const res = response.data;
					this.asset = res;
					this.assetSubject.next(res);
					if (res) {
						if (res.ownerEmployeeId > 0) {
							this.employeeMovementsService.getEmployeeById(res.ownerEmployeeId).then((res) => {
								const own = [];
								own.push({
									employeeId: res.employeeId,
									fullName: res.nameSurname,
									fullName_EN: res.nameSurname_EN,
									pictureURL: res.pictureThumbnailURL,
									positionName: res.positionName,
									positionName_EN: res.positionName_EN,
								});
								this.selectOwnerEmployee = own;
							});
						}
						if (res.hasWarranty) {
							this.calculateDateWarranty();
							this.hasWarranty = res.hasWarranty;
							this.selectTimePeriod = res.monthOfWarranty % 12 === 0 ? TimePeriod.year : TimePeriod.month;
							this.timeOfWarranty =
								this.selectTimePeriod === TimePeriod.year ? res.monthOfWarranty / 12 : res.monthOfWarranty;
						}
						if (!isNullOrUndefined(res.attachments)) {
							this.attached = res.attachments;
							this.attached.forEach((item, i) => {
								const file = {
									attachmentId: i + 1,
									assetAttachmentId: item?.assetAttachmentId,
									publicUrl: item?.attachmentURL,
									filename: item?.fileName,
									fileSize: item?.fileSize,
									extension: item?.fileType,
									isActive: item?.isActive,
									assetId: item?.assetId,
									createdBy: item?.createdBy,
								};
								this.attachments.push(file);
							});
						}
						this.assetTag = res.tagIds;
						this.formGroup.patchValue({
							Name: res.name,
							AssetCategoryId: res.assetCategoryId,
							SerialNo: res.serialNo,
							BranchId: res.branchId,
							Cost: res.cost,
							Remark: res.remark,
							TagIds: res.tagIds,
							HasWarranty: res.hasWarranty,
							MonthOfWarranty: res.monthOfWarranty,
							DatePurchase: res.datePurchase,
							IsCommon: res.isCommon,
							OwnerEmployeeId: res.ownerEmployeeId,
							IsActive: res.isActive,
							Code: res.code,
						});
					}
					this.isLoad = false;
				});
				this.assetsService.getAssetLogs(this.selectAssetId).then((response) => {
					const res = response.data;
					this.assetLogs = res;
					!isNullOrUndefined(this.assetLogs) ? (this.isShowTitle = true) : (this.isShowTitle = false);
				});
			}
		}

		if (changes.employeeId?.currentValue > 0) {
			const employeeId = changes.employeeId.currentValue;
			this.employeeMovementsService.getEmployeeById(employeeId).then((res) => {
				const own = [];
				own.push({
					employeeId: res.employeeId,
					fullName: res.nameSurname,
					fullName_EN: res.nameSurname_EN,
					pictureURL: res.pictureThumbnailURL,
					positionName: res.positionName,
					positionName_EN: res.positionName_EN,
				});
				this.selectOwnerEmployee = own;
			});
		}
	}

	loadData() {
		if (this.selectAssetId && this.selectAssetId > 0) {
			this.isAdd = false;
		}
		if (this.permission.allowEdit || this.permission.allowAdd) {
			this.loadAssetType();
			this.loadBranchDDL();
		}

		this.loadAssetTagDDL();
	}

	translateKey() {
		this.timePeriodDDL = [
			{
				text: this.translate.instant("timer_months"),
				value: 1,
			},
			{
				text: this.translate.instant("timer_years"),
				value: 2,
			},
		];
	}

	loadAssetType() {
		this.commonService.getDDLMasterSetupBySystemRegisNo(SystemRegisNo.assetType.toString()).then((res) => {
			this.assetsTypeDDL = isNullOrUndefined(res) ? [] : res;
		});
	}

	loadBranchDDL() {
		this.companyProfileService.getDDLCompanyBranchsDDL(this.userInfo?.companyId || 0, 0, false).then((res: any) => {
			this.branchDDL = isNullOrUndefined(res) ? [] : res;
		});
	}

	loadAssetTagDDL() {
		this.masTagsService.getMasTagsByCategoryId(1).then((response) => {
			const res = response.data;
			this.tagDDL = isNullOrUndefined(res) ? [] : res;
		});
	}

	setAttachments(item) {
		if (isNullOrUndefined(item)) return;

		const removeAttachments = this.attached.filter(
			(old) => !item.some((newFile) => newFile.assetAttachmentId === old.assetAttachmentId),
		);

		removeAttachments.forEach((attachment) => {
			attachment.isActive = false;
			attachment.isCover = false;
		});

		this.files = [];
		item?.forEach((file, index) => {
			const isCover = index === 0;
			if (
				isNullOrUndefined(
					this.files.find((f) => f.attachmentFilename === file.filename && f.attachmentSize === file.fileSize),
				)
			) {
				if (!file.assetAttachmentId) {
					this.files.push({
						...file,
						attachmentNo: index + 1,
						url: null,
						data: file.data,
						attachmentFilename: file.filename,
						attachmentMimeType: file.extension,
						attachmentSize: file.fileSize,
						isActive: true,
						isCover: isCover,
						attachmentUploadAddress: "AssetsManagementAttachmentFile",
						storageEncryptionKey: this.userInfo.storageEncryptionKey,
					});
				} else {
					const updateOld = this.attached.find((att) => att.assetAttachmentId === file.assetAttachmentId);
					if (updateOld) {
						updateOld.isCover = isCover;
					}
				}
			} else {
				this._toastHelperService.warning({
					title: this.translate.instant("common_warning"),
					description: this.translate.instant("common_duplicate_file"),
				});
			}
		});
	}

	async uploadGCP(files) {
		await this.uploadFileService.uploadFile(files, "pathUpload", this.data.headerGCP);
	}

	openAttachment(event, attachments) {
		this.attachmentViewerService.open(attachments, event?.selectedItem?.attachmentId);
	}

	onSubmit() {
		this.isErrorOwner =
			this.selectOwnerEmployee.length > 0 && this.selectOwnerEmployee[0].employeeId > 0 ? false : true;
		if (this.formGroup.valid && !this.isErrorOwner) {
			const attachments = [];
			if (this.files.length > 0) {
				firstValueFrom(
					this.commonService.getPathAttachment(
						this.files.filter((f) => (f.attachmentNo !== 0 && isNullOrUndefined(f.assetId)) || f.assetId <= 0),
					),
				).then((resFiles) => {
					const files = resFiles.data.concat(this.files.filter((f) => f.applicantId > 0));
					files.forEach((file, index) => {
						attachments.push({
							FileName: file.attachmentFilename,
							FileSize: file.attachmentSize,
							FileType: file.attachmentMimeType,
							AttachmentURL: file.attachmentURL,
							IsCover: this.files[index].isCover,
							IsActive: file.isActive,
							CreatedBy: 0,
						});
					});
					this.files.forEach((f) => {
						if (files.isActive === 0) {
							attachments.push({
								assetId: f.assetId,
								FileName: f.attachmentFilename,
								FileSize: f.attachmentSize,
								FileType: f.attachmentMimeType,
								AttachmentURL: f.attachmentURL,
								IsCover: f.isCover,
								IsActive: f.isActive,
								CreatedBy: f.createdBy,
							});
						}
					});
					this.uploadGCP(files);
					const model = this.getDataSend(attachments);
					if (this.isAdd) this.addAssets(model);
					else this.editAssets(model);
				});
			} else {
				const model = this.getDataSend([]);
				if (this.isAdd) this.addAssets(model);
				else this.editAssets(model);
			}
		} else {
			this._toastHelperService.validation({
				title: this.translate.instant("toast_title_headsup"),
				description: this.translate.instant("toast_common_complete_all_fields"),
			});
			this.formGroup.markAllAsTouched();
		}
	}

	getDataSend(attachments) {
		const att = attachments.concat(this.attached);
		const calMonth = this.hasWarranty
			? this.selectTimePeriod === TimePeriod.year
				? this.timeOfWarranty * 12
				: this.timeOfWarranty
			: 0;
		const assetModel = {
			AssetId: !this.isAdd ? this.asset.assetId : 0,
			Name: this.formGroup.controls["Name"].value,
			AssetCategoryId: this.formGroup.controls["AssetCategoryId"].value,
			SerialNo: this.formGroup.controls["SerialNo"].value,
			BranchId: this.formGroup.controls["BranchId"].value,
			Cost: this.formGroup.controls["Cost"].value ? this.formGroup.controls["Cost"].value : 0,
			Remark: this.formGroup.controls["Remark"].value,
			TagIds: this.assetTag && this.assetTag.length > 0 ? this.assetTag : [],
			HasWarranty: this.hasWarranty,
			MonthOfWarranty: this.hasWarranty ? calMonth : null,
			DatePurchase: this.hasWarranty ? this.formGroup.controls["DatePurchase"].value : null,
			IsCommon: this.formGroup.controls["IsCommon"].value,
			OwnerEmployeeId:
				this.selectOwnerEmployee && this.selectOwnerEmployee.length > 0 ? this.selectOwnerEmployee[0].employeeId : null,
			IsActive: this.formGroup.controls["IsActive"].value,
			Attachments: att,
			CreatedBy: 0,
			Code: "",
		};
		if (this.asset?.code) {
			assetModel.Code = this.asset.code;
		}
		return assetModel;
	}

	getLabelAssetCategory(id) {
		if (!this.assetsTypeDDL || this.assetsTypeDDL.length == 0) return { name: "", name_EN: "" };
		const category = this.assetsTypeDDL.find((category) => category.id === id);
		return category;
	}

	addAssets(assetModel) {
		this.assetsService.addAssets(assetModel).then((response) => {
			const res = response.data.result;
			if (res && res > 0) {
				this._toastHelperService.success({
					title: this.translate.instant("toast_title_success"),
					description: this.translate.instant("toast_desc_success_asset_added"),
				});
				this.cancel(respStatus.success, res);
			} else {
				this._toastHelperService.validation({
					title: this.translate.instant("common_fail"),
					//	description: this.isTH ? res.data.message : res.data.message_EN,
				});
				this.cancel(respStatus.fail);
			}
		});
	}

	editAssets(assetModel) {
		return new Promise<void>((resolve, reject) => {
			firstValueFrom(this.assetsService.editAssets(assetModel)).then((res) => {
				const apiResult = <ApiResult>res;
				if (apiResult.data.result) {
					this._toastHelperService.success({
						title: this.translate.instant("toast_title_success"),
						description: this.translate.instant("common_toast_description_save_successfully"),
					});
					this.cancel(respStatus.success);
					resolve();
				} else {
					this._toastHelperService.validation({
						title: this.translate.instant("common_fail"),
						description: this.isTH ? apiResult.data.message : apiResult.data.message_EN,
					});
					this.cancel(respStatus.fail);
					reject();
				}
			});
		});
	}

	addAssetTags(name) {
		if (!name || name.trim() === "") return;
		this.masTagsService.addMasTags({ name: name, tagCategory: 1 }).then((response) => {
			if (response?.data.result) {
				const tagId = response?.data.metadata.tagId;
				this.tagDDL.push({
					tagId: tagId,
					name: name,
				});
				this.assetTag.push(tagId);
				this.formGroup.get("TagIds").patchValue(this.assetTag);
			}
		});
	}

	cancel(status: number = null, assetAddId = null) {
		if (status === respStatus.success) {
			const payload = {
				display: true,
				assetId: assetAddId ? assetAddId : this.selectAssetId,
				status: 200,
			};
			setTimeout(() => {
				this.closeSidebar.emit(payload);
			}, 150);
		}
		if (!this.isWebView) {
			this.clearData();
		}
	}

	handleCloseSidebar(e) {
		if (this.isEdit) {
			this.isEdit = false;
			return;
		}
		if (e.target && e.target.id === "go5-upload-file") {
			return;
		}
		const payload = {
			display: false,
			assetId: null,
			status: 204,
		};
		this.clearData();
		setTimeout(() => {
			this.closeSidebar.emit(payload);
		}, 150);
	}

	clearData() {
		this.asset = new AssetsModel();
		this.displayAssetsSidebar = false;
		this.displayPeoplePicker = false;
		this.selectAssetId = null;
		this.isAdd = true;
		this.isEdit = false;
		this.attached = [];
		this.attachments = [];
		this.hasWarranty = false;
		this.datePurchase = new Date();
		this.selectTimePeriod = TimePeriod.year;
		this.timeOfWarranty = 1;
		this.formGroup.reset();
	}

	handleSelectTag(e) {
		const list = [];
		e.selectedItems.forEach((item) => {
			list.push(item.tagId);
		});
		this.assetTag = list;
	}

	calculateDateWarranty() {
		this.warrantyEndDate = new Date(this.datePurchase);
		// skipcq: JS-0047
		switch (this.selectTimePeriod) {
			case TimePeriod.year:
				this.warrantyEndDate.setFullYear(this.warrantyEndDate.getFullYear() + this.timeOfWarranty);
				break;
			case TimePeriod.month:
				this.warrantyEndDate.setMonth(this.warrantyEndDate.getMonth() + this.timeOfWarranty);
				// skipcq: JS-0054
				const targetDay = this.datePurchase.getDate();
				this.warrantyEndDate.setDate(Math.min(targetDay, this.warrantyEndDate.getDate()));
				break;
		}
	}

	handleOpenPeoplePicker() {
		this.isErrorOwner = false;
		if (!this.isWebView) {
			this.displayPeoplePicker = true;
		} else {
			this.isOpenPeoplePicker.emit(true);
		}
	}
}
