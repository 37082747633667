import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { ApiResult } from "../../models/api-result.model";
import { RemoteAccessHistories, RemoteAccesssLogs } from "../remote-access/remote-access.component";
import { SharingService } from "../../sharing.service";

@Injectable()
export class RemoteService {
	httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private http: HttpClient,
		private sharing: SharingService,
		@Inject("API_URL") private apiUrl: string,
		@Inject("GOFIVE_API_URL") private apiCoreUrl: string,
	) {}

	getPermissionRemote(emailObj: { emailAddress: string }): any {
		var body = JSON.stringify(emailObj);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};

		return firstValueFrom(this.http.post<any>(`${this.apiCoreUrl}v1/remote-accesses/supports`, body, httpOptions));
	}

	stopSupported(source_user_id): any {
		return this.http.post<any>(
			`${this.apiUrl}v1/remote-accesses/supports/stop`,
			{ sourceUserId: source_user_id },
			this.httpOptions,
		);
	}
	getRemoteAccesssLogs(): Promise<ApiResult<RemoteAccesssLogs[]>> {
		return firstValueFrom(this.http.get<any>(`${this.apiCoreUrl}v1/remote-accesses/logs`));
	}
	getRemoteAccessHistories(model): Observable<ApiResult<RemoteAccessHistories[]>> {
		return this.http.get<any>(`${this.apiCoreUrl}v1/remote-accesses/histories${this.sharing.getQueryString(model)}`);
	}

	allowRemote(isAllow: boolean): Promise<ApiResult> {
		var body = JSON.stringify(isAllow);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.patch<any>(`${this.apiUrl}v1/tenants/remotes-accesses/status`, body, httpOptions));
	}
}
