import { Component, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Subscription, firstValueFrom } from "rxjs";
import { isNullOrUndefined } from "src/app/shared/sharing.service";
import { DataSharingService, UserControlService } from "../../../core";
import { ApiResult } from "../../../shared/models/api-result.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { CommonService } from "../../../shared/service/common.service";
import { Position } from "../shared/position.model";
import { PositionService } from "../shared/position.service";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { TextFieldType, InputModule } from "@gofive/design-system-input";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	UntypedFormArray,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { ComponentService } from "src/app/setting/component-setting/shared/component.service";
import { DropdownSelectMode, DropdownModule } from "@gofive/design-system-dropdown";
import { SkillService } from "src/app/manager-self-service/skill-set/shared/skill-set.service";
import { PaymentState } from "src/app/shared/module/payment-modal/payment-modal.component";
import { Application } from "src/app/shared/enum/application.enum";
import { Module } from "src/app/shared/enum/module.enum";
import { Permission } from "src/app/shared/models/permission.model";
import { ResEventType } from "src/app/shared/enum/event.enum";
import { UserInfo } from "src/app/core/shared/user-info.model";
import { PaymentModalComponent } from "../../../shared/module/payment-modal/payment-modal.component";
import { InfoTooltipComponent } from "../../../shared/template/info-tooltip/info-tooltip.component";
import { NgClass, AsyncPipe } from "@angular/common";
import { ButtonModule } from "@gofive/design-system-button";

@Component({
	selector: "app-position-add",
	templateUrl: "./position-add.component.html",
	styleUrls: ["./position-add.component.scss"],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		InputModule,
		DropdownModule,
		ButtonModule,
		NgClass,
		InfoTooltipComponent,
		PaymentModalComponent,
		AsyncPipe,
		TranslateModule,
	],
})
export class PositionAddComponent {
	@Input() positionId: number = 0;
	@Input() rankNo: number = 0;
	@Input() isEmployeeAdd: boolean = false;
	@Input() isOpenSidebar: boolean = false;
	@Output() successAdd = new EventEmitter<boolean>();
	@Output() onCloseSidebar = new EventEmitter<boolean>();

	private dialog$: Subscription;

	public positionModel: Position = new Position();
	public rankDDL: Dropdown[];
	public skillDDL: any[];
	public skillLevelDDL: any[];
	public selectedSkillSet: Set<number> = new Set<number>();
	public availableSkillDDL: any[];

	public fieldDropdown: any = {
		text: "name",
		value: "id",
	};
	public fieldDropdown_EN: any = {
		text: "name_EN",
		value: "id",
	};
	public skillDropdown: any = {
		text: "name",
		value: "skillId",
	};
	public skillLevelsDropdown: any = {
		text: "name",
		value: "skillLevelId",
	};
	public skillLevelsDropdown_EN: any = {
		text: "name_EN",
		value: "skillLevelId",
	};

	public form: UntypedFormGroup;
	rankSkills: any;

	constructor(
		private _toastHelperService: ToastHelperService,
		public data: DataSharingService,
		private positionService: PositionService,
		private commonService: CommonService,
		private translate: TranslateService,
		private _dialogService: DialogService,
		private fb: UntypedFormBuilder,
		public componentService: ComponentService,
		private skillService: SkillService,
		private userControlService: UserControlService,
	) {
		this.form = this.fb.group({
			positionId: new UntypedFormControl(0),
			positionName: new UntypedFormControl("", Validators.required),
			positionName_EN: new UntypedFormControl(""),
			rankFromId: new UntypedFormControl("", Validators.required),
			qualifications: new UntypedFormControl(""),
			responsibility: new UntypedFormControl(""),
			hasSkill: new UntypedFormControl(false),
			positionSkills: new UntypedFormArray([]),
			isActive: new UntypedFormControl(true),
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.isOpenSidebar?.currentValue) {
			this.clearValidation();
			this.checkPackage();
			this.checkUserPermission();

			this.getDDLRanks();
			this.getDDLSkills();
			this.getDDLSkillLevels();

			if (this.positionId > 0) {
				this.getPosition();
			} else {
				this.positionModel = new Position();
			}
			this.positionModel.positionId = this.positionId ?? 0;
			this.form.controls["positionId"].setValue(this.positionId ?? 0);
		}
	}

	getDDLSkills() {
		firstValueFrom(this.skillService.getDDLMasSkills()).then((result) => {
			this.skillDDL = result.data;
			this.availableSkillDDL = result.data;
		});
	}

	getDDLSkillLevels() {
		firstValueFrom(this.skillService.getSkillLevel()).then((result) => {
			this.skillLevelDDL = result.data.filter((level) => level.skillLevelId != 0);
		});
	}

	public get textFieldType() {
		return TextFieldType;
	}

	getPosition() {
		this.positionService.getPositionById(this.positionId).then((res) => {
			this.positionModel = res;
			this.positionModel.rankFromId = this.positionModel.rankFromId ?? 0;
			this.form.controls["rankFromId"].setValue(this.positionModel.rankFromId ?? 0);
			if (this.positionModel.hasSkill && this.positionModel.positionSkills.length > 0) {
				this.positionModel.positionSkills.forEach((item) => {
					const formGroup = this.fb.group({
						skillId: [item?.skillId, Validators.required],
						skillLevelId: [item?.skillLevelId, Validators.required],
					});
					this.positionSkillArr.push(formGroup);
					this.selectedSkillSet.add(item?.skillId);
				});
				this.form.controls["positionSkills"].setValue(this.positionSkillArr.value);
			}
			let rankNo = this.positionModel?.rankFromId ?? 0;
			firstValueFrom(this.skillService.getRankSkills(rankNo)).then((result) => {
				this.rankSkills = result;
			});
		});
	}

	getDDLRanks() {
		this.commonService.getDDLRanks().then((res) => {
			this.rankDDL = [
				{
					id: 0,
					name: "ทุกระดับ",
					name_EN: "All Ranks",
					dataId: null,
					value: null,
					valueNum: null,
					text: null,
				},
			];

			const sortedRank = res.filter((item) => item.valueNum).sort((a, b) => a.valueNum - b.valueNum);
			this.rankDDL.push(...sortedRank);
		});
	}

	close() {
		this.onCloseSidebar.emit(false);
	}
	successPosition() {
		this.onCloseSidebar.emit(false);
		this.successAdd.emit(true);
	}

	resetForm() {
		this.form.reset();
		this.form.markAsPristine();
		this.form.markAsUntouched();
		this.form.setErrors(null);
	}

	ngOnDestroy(): void {
		if (this.dialog$) this.dialog$.unsubscribe();
		if (this.subscription$) this.subscription$.unsubscribe();
	}

	onChoseRankFrom(e) {
		this.positionModel.rankFromId = e.selectedItem[this.fieldDropdown.value];
		if (isNullOrUndefined(this.positionModel.rankToId)) {
			this.positionModel.rankToId = e.selectedItem[this.fieldDropdown.value];
		}
	}

	onChoseRankTo(e) {
		this.positionModel.rankToId = e.selectedItem[this.fieldDropdown.value];
	}

	clearRankFrom() {
		this.positionModel.rankFromId = null;
	}

	clearRankTo() {
		this.positionModel.rankToId = null;
	}

	savePosition() {
		if (isNullOrUndefined(this.positionModel.positionName_EN) || this.positionModel.positionName_EN == "") {
			this.positionModel.positionName_EN = this.positionModel.positionName;
		}
		this.form.controls["isActive"].setValue(this.positionModel.isActive);

		this.positionModel = this.form.value;
		this.positionModel.isLimitRank = this.positionModel.rankFromId !== 0;
		this.positionModel.rankFromId = this.positionModel.rankFromId !== 0 ? this.positionModel.rankFromId : null;
		this.positionModel.rankToId = this.positionModel.rankFromId !== 0 ? this.positionModel.rankFromId : null;
		this.positionModel.hasSkill = this.positionModel.hasSkill ?? false;

		this.form.markAllAsTouched();

		if (!this.form.valid) {
			return this.toastEmptyFieldValidation();
		}

		let dialogModel = <DialogInformationModel>{
			title: this.translate.instant("common_confirmation"),
			description: this.translate.instant("confirm_description"),
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm"),
			textButtonCancel: this.translate.instant("common_cancel"),
		};

		if (this.dialog$) this.dialog$.unsubscribe();
		if (this.positionModel.positionId > 0) {
			dialogModel.description = this.translate.instant("common_edit_confirmation");
			this.editPosition(dialogModel);
		} else {
			dialogModel.description = this.translate.instant("common_add_confirmation");
			this.addPosition(dialogModel);
		}
	}

	public isPositionNameDup: boolean = false;
	public isPositionNameENDup: boolean = false;
	public canNotDisabled: boolean = false;

	addPosition(dialogModel) {
		this.dialog$ = this._dialogService.Confirmation(dialogModel).subscribe((response) => {
			if (response?.confirm) {
				this.positionService.addPosition(this.positionModel).subscribe((res) => {
					let apiResult = <ApiResult>res;
					if (apiResult?.result) {
						this._toastHelperService.success({
							title: this.translate.instant("toast_title_success"),
							description: this.translate.instant("toast_success_created", {
								titleName: this.translate.instant("common_position"),
							}),
						});
						this.successPosition();
					} else {
						this.isPositionNameDup = apiResult["isPositionNameDup"] ?? false;
						this.isPositionNameENDup = apiResult["isPositionNameENDup"] ?? false;
						this.setDefaultRankFromId();
						this._toastHelperService.validation({
							title: this.translate.instant("toast_title_headsup"),
							description: this.descriptionToastValidation(),
						});
					}
				});
			}
		});
	}

	editPosition(dialogModel) {
		this.dialog$ = this._dialogService.Confirmation(dialogModel).subscribe((response) => {
			if (response?.confirm) {
				this.positionService.editPosition(this.positionModel).subscribe((res) => {
					let apiResult = <ApiResult>res;
					if (apiResult?.result) {
						this._toastHelperService.success({
							title: this.translate.instant("toast_title_success"),
							description: this.translate.instant("toast_success_edited", {
								titleName: this.translate.instant("common_position"),
							}),
						});
						this.successPosition();
					} else {
						this.isPositionNameDup = apiResult["isPositionNameDup"] ?? false;
						this.isPositionNameENDup = apiResult["isPositionNameENDup"] ?? false;
						this.canNotDisabled = apiResult["canNotDisabled"] ?? false;

						this.setDefaultRankFromId();
						this._toastHelperService.validation({
							title: this.translate.instant("toast_title_headsup"),
							description: this.descriptionToastValidation(),
						});
					}
				});
			}
		});
	}

	descriptionToastValidation() {
		if (this.canNotDisabled) return this.translate.instant("mas005_position_edit_disable_dialog");
		else if (this.isPositionNameDup) return this.translate.instant("common_please_check_data");
		else if (this.isPositionNameENDup) return this.translate.instant("common_please_check_data");
		else return this.translate.instant("toast_common_complete_all_fields");
	}

	validateEmptyFields() {
		this.form.markAllAsTouched();
	}

	clearValidation() {
		this.positionSkillArr = this.form.get("positionSkills") as UntypedFormArray;
		this.positionSkillArr.clear();
		this.form.reset();
		this.isPositionNameDup = false;
		this.isPositionNameENDup = false;
		this.selectedSkillSet.clear();
	}

	public positionSkillArr: UntypedFormArray;

	get dropdownSelectMode() {
		return DropdownSelectMode;
	}

	createSkillTemplate() {
		return this.fb.group({
			skillId: [null, Validators.required],
			skillLevelId: [null, Validators.required],
		});
	}

	setHasSkill(event) {
		if (event.checked) {
			this.positionSkillArr.push(this.createSkillTemplate());
		} else {
			this.positionSkillArr.clear();
			this.selectedSkillSet.clear();
		}
	}

	addSkill() {
		this.positionSkillArr.markAllAsTouched();
		if (this.positionSkillArr.valid) {
			this.positionSkillArr.push(this.createSkillTemplate());
		} else {
			this.toastEmptyFieldValidation();
		}
	}

	clearSkill(index) {
		const target = this.positionSkillArr.at(index);
		const skillId = target.get("skillId").value;

		if (this.selectedSkillSet.has(skillId)) {
			this.selectedSkillSet.delete(skillId);
		}
	}

	selectSkill(event, index) {
		this.clearSkill(index);
		this.selectedSkillSet.add(event.selectValue);
		let rankSkills = this.rankSkills?.find((f) => f.skillId === event.selectValue);
		if (!isNullOrUndefined(rankSkills)) {
			const target = this.positionSkillArr.at(index);
			target.get("skillLevelId").setValue(rankSkills?.skillLevelId);
		}
	}

	removeSkill(index) {
		this.clearSkill(index);
		this.positionSkillArr.removeAt(index);
	}

	filterSkill(index) {
		const target = this.positionSkillArr.at(index);
		const skillId = target.get("skillId").value;

		if (this.selectedSkillSet.size > 0) {
			this.availableSkillDDL = this.skillDDL.filter(
				(skill) => !this.selectedSkillSet.has(skill.skillId) || skill.skillId === skillId,
			);
		} else {
			this.availableSkillDDL = this.skillDDL;
		}
	}

	toastEmptyFieldValidation() {
		let toast = {
			title: this.translate.instant("toast_title_headsup"),
			description: this.translate.instant("toast_common_complete_all_fields"),
		};
		return this._toastHelperService.validation(toast);
	}

	setDefaultRankFromId(defaultId = 0) {
		this.positionModel.rankFromId = this.positionModel.rankFromId !== 0 ? this.positionModel.rankFromId : defaultId;
		this.form.controls["rankFromId"].setValue(this.positionModel.rankFromId ?? defaultId);
	}
	subscription$: Subscription;
	checkPackage() {
		this.subscription$ = this.data.currentUserInfo.subscribe((res) => {
			if (res) {
				const apiResult = <UserInfo>res;
				this.isPackagePro = apiResult?.modules.includes(Module.PRO001) || apiResult?.modules.includes(Module.PRO002);
			}
		});
	}

	isPackagePro: boolean;
	applicationId: number = Application.empeoId;
	toggleTab: any = null;
	permissionPlan: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};

	upgradePlan() {
		if (this.permissionPlan.allowGrant) {
			this.toggleTab = {
				...this.toggleTab,
				eventFunction: ResEventType.open,
				model: {
					state: PaymentState.ChoosePlan,
				},
			};
		} else {
			const dialogModel = <DialogInformationModel>{
				title: this.translate.instant("resignation_no_client_permission_dialog"),
				description: this.translate.instant("resignation_no_client_permission_dialog_description"),
				textButtonConfirm: this.translate.instant("common_ok_got_it"),
			};
			this._dialogService.Information(dialogModel).subscribe();
		}
	}

	checkUserPermission() {
		const listFunctionCode = [Module.SYS018];
		this.userControlService.authorizeControlByListFunctionCode(listFunctionCode).subscribe((auth) => {
			this.permissionPlan = auth ? auth.find((f) => f.functionCode == Module.SYS018) : this.permissionPlan;
		});
	}
}
