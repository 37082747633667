const version = require("../../package.json").version;

export const environment = {
	production: true,
	envName: "prod",
	redirectUri: "https://app.empeo.com/",
	issuer: "https://login.gofive.co.th",
	apiUrl: "https://api.empeo.com/api/",
	apiUrlDefault: "https://api.empeo.com/",
	empeoJunoUrl: "https://juno.tks.co.th/IMCOnline2API/api/",
	emconnectAPI: "https://api-emconnect.empeo.com/api/v1/",
	emsume: {
		emsumeApiKey: "7682cb92/a229=4f5d+b82dW3f69ee0e5899",
		emsumeAPI: "https://api.emsume.com/api/",
	},
	gofiveApiUrl: "https://api-core.empeo.com/api/",
	gofiveDeeplinkUrl: "https://redirect.gofive.co.th/api/Empeo",
	clientPortal: "https://portal.gofive.co.th/api/v1/",
	appPath: "/",
	defaultPicture: "assets/images/Profile-on-circle.png",
	defaultImage: "assets/images/default_image.png",
	VERSION: version,
	eppUrl: "https://www.epp-program.com/Components/Login.aspx?TKSToken=",
	venioApiUrl: "https://portal.veniocrm.com/api/",
	venioApi4Url: "https://portal.veniocrm.com/api/V4/",
	venioApiExpenseUrl: "https://portal.veniocrm.com/api/expense/",
	assetUrl: "https://app.gofive.co.th/assets/",
	changePwUrl: "https://login.gofive.co.th/Manage/ChangePassword",
	googleMapKey: "AIzaSyC-WBePt4-cKeg55SVo8xK3XxYATI36rNQ",
	empeoAPIKey: "f8c93472/1qR8uBUtD4zhGw1rs427erQUQcwQcqtDa16347kxBrQUQcwQY/UtD4zhGw1r/1qR8uB7YPbkcqtDqtDso0Gw==",
	gofiveCoreWeb: {
		baseUrl: "https://app.gofive.co.th/modules/",
		assetUrl: "https://app.gofive.co.th/assets/",
	},
	phrase: {
		token: "token a06db78ca236d4e0bed85b7088fe9d81724597434ae99b9e6b32111c4b001590",
		prefix: "https://phrase.gofive.co.th/api/dca7aeaa2aa3ad4cf5cb68d1760854fa/locales/",
		suffix: "/download?file_format=angular_translate&code=06sJg7tWHkbhrCrRTFLCedhB-fDZPf1aJ6LMxI_1l3lkAzFuqo8dJw==",
		backupPrefix: "./assets/i18n/phrase_",
		backupSuffix: ".json",
	},
	gofiveServiceStatusApiUrl: "https://status.gofive.co.th/api/services/current",
	api_url:
		"https://o4503923706560512.ingest.sentry.io/api/4504086901948416/security/?sentry_key=249ea23201f14758a8896b203bb152f3",
	sentry_dsn: "https://249ea23201f14758a8896b203bb152f3@o4503923706560512.ingest.sentry.io/4504086901948416",
	learnAPI: "https://api-learn.empeo.com/api/",
	customDomainURL: "https://app.empeo.com",
	subDomain: "app",
	defaultSubDomain: "app",
	clientId: "IMC",
	enableCustomDomain: true,
	gtmId: "GTM-TJD5LNWP",
	licenseSysfusion: "ORg4AjUWIQA/Gnt2UVhiQlZPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSXxRdkVnXH1ddXZURmU=",
	crispWebsiteId: "9767c348-d681-44ab-99d2-7b16c7531719",
	mixpanelToken: "81fb047b58943eb660aa0401da991ba7",
};
