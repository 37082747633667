export enum FinancialCategory {
	baseSalary = 1,
	other = 2,
	overtime = 3,
	diligence = 4,
	socialFund = 5,
	tax = 6,
	absenceDeduct = 7,
	lateDeduct = 8,
	providentFund = 9,
	bonus = 10,
	shift = 11,
	foodCoupon = 12,
	overtimeAllowance = 13,
	holidayAllowance = 14,
	diligenceBonus = 15,
	diligenceStack = 16,
	holidayRefund = 17,
	uniformDeduct = 18,
	ejipDeduction = 19,
	professionalFee = 20,
	ejipAddition = 21,
	ejipDeductionCompany = 22,
	leaveWithoutPay = 23,
	suspension = 24,
	fine = 25,
	doubleShift = 26,
	breakTimeDeduct = 27,
	workTimeAllowance = 30,
	medicalExpense = 31,
	backPay = 32,
	advancesPayment = 33,
	EPP = 34,
	oTPublicHoliday = 35,
	taxIncomeType6 = 36,
	taxIncomeType3 = 37,
	taxIncomeType4 = 38,
	taxIncomeType5 = 39,
	studentLoanFund = 40,
	legalExecutionDepartment = 41,
	expenseDeduct = 42,
	taxIncomeType6Freelance = 43,
	leaveBenefitCompensation = 44,
	leaveBenefitCompensationDeduct = 45,
	overtimeBackPay = 46,
	attendanceBackPay = 47,
	providentFundCompanyFixed = 48,
	severancePay = 49,
}
