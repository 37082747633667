import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataResult, DataStateChangeEventArgs } from "@syncfusion/ej2-angular-grids";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { isNullOrUndefined } from "src/app/shared/sharing.service";

@Injectable()
export class PaymentTableService extends Subject<DataStateChangeEventArgs> {
	private columnTable = new BehaviorSubject(null);
	getEmployeePaymentColumnTable = this.columnTable.asObservable();

	private dataTableLoading = new BehaviorSubject(true);
	getDataTableLoading = this.dataTableLoading.asObservable();

	private employeePayment = new BehaviorSubject(null);
	getEmployeePayment = this.employeePayment.asObservable();

	private httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {
		super();
	}

	EmployeePaymentDataTable: Subscription;
	private GetEmployeePaymentDataTable = this.apiUrl + "Payment/GetEmployeePaymentDataTable";
	public execute(model): void {
		this.setDataTableLoading(true);
		this.EmployeePaymentDataTable = this.getEmployeePaymentDataTable(model).subscribe((x) => {
			super.next(x);
			this.setDataTableLoading(false);
		});
	}

	public cancelEmployeePaymentDataTable() {
		this.EmployeePaymentDataTable && this.EmployeePaymentDataTable.unsubscribe();
	}

	public cancelAll() {
		this.setEmployeePayment(null);
		this.setColumnTable(null);
	}

	protected getEmployeePaymentDataTable(model: any): Observable<DataStateChangeEventArgs> {
		var body = JSON.stringify(model);
		return this.http.post<any>(this.GetEmployeePaymentDataTable, body, this.httpOptions).pipe(
			map((data) => {
				var filterColumn = this.columnTable.value?.filter((f) => f.financialItemId > 0);
				if (
					(isNullOrUndefined(this.columnTable.value) ||
						(filterColumn?.length > 0 && filterColumn?.length != data.financialItems?.length)) &&
					data.financialItems?.length > 0
				) {
					this.setColumnTable(data.financialItems);
				}

				if (isNullOrUndefined(data.employeePayments)) {
					data.employeePayments = [];
				} else {
					data.employeePayments.forEach((person, index) => {
						person.employeeFinancials.forEach((cols) => {
							person[`${cols.financialItemId}`] = cols.category == 5 && !person.isSoc ? 0 : cols.amount;
							person[`${cols.financialItemId}isCalculate`] = cols.isCalculate;
							person[`${cols.financialItemId}isShow`] = cols.isShow;
							person[`${cols.financialItemId}isDisabled`] = cols.isDisabled;
							person[`${cols.financialItemId}isOnFocus`] = false;
							person[`${cols.financialItemId}isSocialFund`] = cols.category == 5;
						});

						person["index"] = index;
					});
				}

				if (!isNullOrUndefined(data)) {
					this.setEmployeePayment(data);
				}
				return <any>(<DataResult>{
					result: data.employeePayments,
					count: data.recordCount,
				});
			}),
		);
	}

	setColumnTable(data) {
		this.columnTable.next(data);
	}

	setDataTableLoading(isLoad) {
		this.dataTableLoading.next(isLoad);
	}

	setEmployeePayment(employeePayment: any) {
		this.employeePayment.next(employeePayment);
	}
}
