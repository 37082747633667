import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Application, Configuration, LANGUAGE_KEY, PhraseProject, Theme } from "@gofive/angular-common";
import { SelectItem } from "primeng/api";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AssessmentModel } from "../assessment/shared/assessment.model";
import { Leave } from "../document-forms/leave/shared/leave.model";
import { OrganizationConfig } from "../master/organization/shared/organization.model";
import { PaymentPeriod } from "../payroll/master/payment-period/shared/payment-period.model";
import { PayrollSlipCostConfigCompany } from "../payroll/payment/shared/payment.model";
import { BiometricScannersModel, WorkinSite } from "../setting/work-in-site/shared/work-in-site.model";
import { DocumentStatus } from "../shared/enum/document-status.enum";
import { ProcessAction } from "../shared/enum/process-action.enum";
import { CurrentSubscription } from "../shared/models/client-portal.model";
import { RouterModel } from "../shared/models/common.model";
import { Language } from "../shared/models/language.model";
import { EncryptionKey } from "../shared/models/venio.model";
import { isNullOrUndefined } from "../shared/sharing.service";
import { UserInfo } from "./shared/user-info.model";
import { WelfareSearchModel } from "../welfare/shared/welfare.model";
import { Crisp } from "crisp-sdk-web";

@Injectable({
	providedIn: "root",
})
export class DataSharingService {
	public isProd = false;

	private dataSource = new BehaviorSubject(0);
	currentData = this.dataSource.asObservable();

	private empId = new BehaviorSubject(0);
	currentEmpId = this.empId.asObservable();

	private swapFromEmpId = new BehaviorSubject(0);
	currentSwapFromEmpId = this.swapFromEmpId.asObservable();

	private swapToEmpId = new BehaviorSubject(0);
	currentSwapToEmpId = this.swapToEmpId.asObservable();

	private calendarEmpId = new BehaviorSubject(0);
	currentCalendarEmpId = this.calendarEmpId.asObservable();

	private year = new BehaviorSubject(null);
	currentYear = this.year.asObservable();

	private dataSourceArray = new BehaviorSubject<any[]>([]);
	currentDataArray = this.dataSourceArray.asObservable();

	private dataSourceArrayFilterPayroll = new BehaviorSubject<any[]>([]);
	currentDataArrayFilterPayroll = this.dataSourceArrayFilterPayroll.asObservable();

	private language = new BehaviorSubject<string>(null);
	currentLanguage = this.language.asObservable();

	private organizationConfig = new BehaviorSubject<OrganizationConfig>(new OrganizationConfig());
	currentOrganizationConfig = this.organizationConfig.asObservable();

	private strDataSourse = new BehaviorSubject<string>(null);
	currentStrData = this.strDataSourse.asObservable();

	private loading = new BehaviorSubject<boolean>(false);
	currentLoading = this.loading.asObservable();

	private detection$ = new BehaviorSubject<boolean>(false);
	_detection$ = this.detection$.asObservable();

	private isOnlyCollectTime = new BehaviorSubject<boolean>(null);
	currentIsOnlyCollectTime = this.isOnlyCollectTime.asObservable();

	private date = new BehaviorSubject(null);
	currentDate = this.date.asObservable();

	private userInfo = new BehaviorSubject<UserInfo>(new UserInfo());
	currentUserInfo = this.userInfo.asObservable();

	private userInfoMss = new BehaviorSubject<UserInfo>(new UserInfo());
	currentUserInfoMss = this.userInfoMss.asObservable();

	private paymentPeriod = new BehaviorSubject<PaymentPeriod>(new PaymentPeriod());
	currentPaymentPeriod = this.paymentPeriod.asObservable();

	private paymentPeriods = new BehaviorSubject<PaymentPeriod[]>([]);
	currentPaymentPeriods = this.paymentPeriods.asObservable();

	private assessment = new BehaviorSubject<AssessmentModel>(new AssessmentModel());
	currentAssessment = this.assessment.asObservable();

	private welfareSearchModel = new BehaviorSubject<WelfareSearchModel>(new WelfareSearchModel());
	currentWelfareSearchModel = this.welfareSearchModel.asObservable();

	private langList = new BehaviorSubject<Language[]>([]);
	currentLangList = this.langList.asObservable();

	private menuList = new BehaviorSubject<RouterModel[]>([]);
	currentMenuList = this.menuList.asObservable();

	private dataSourceLeaveId = new BehaviorSubject(0);
	currentLeaveIdData = this.dataSourceLeaveId.asObservable();

	private dataSourcePunishId = new BehaviorSubject(0);
	currentPunishIdData = this.dataSourcePunishId.asObservable();

	private dataSourceCourseId = new BehaviorSubject(0);
	currentCourseIdData = this.dataSourceCourseId.asObservable();

	private dataSourceShiftRequestId = new BehaviorSubject(0);
	currentShiftRequestId = this.dataSourceShiftRequestId.asObservable();

	private previousRouteName = new BehaviorSubject("");
	currentPreviousRouteName = this.previousRouteName.asObservable();

	private dataSourceWorkin = new BehaviorSubject(new Leave());
	currentWorkinData = this.dataSourceWorkin.asObservable();

	private dataSourceDocumentFormId = new BehaviorSubject(0);
	currentDocumentFormId = this.dataSourceDocumentFormId.asObservable();

	private dataSourceDocumentFormCategoryId = new BehaviorSubject(0);
	currentDocumentFormCategoryId = this.dataSourceDocumentFormCategoryId.asObservable();

	private dataSourceTrainingRequestId = new BehaviorSubject(0);
	currentTrainingRequestId = this.dataSourceTrainingRequestId.asObservable();

	private dataSourceWelfareDocumentId = new BehaviorSubject(0);
	currentWelfareDocumentId = this.dataSourceWelfareDocumentId.asObservable();

	private dataEditWelfareAdjustRequestId = new BehaviorSubject(0);
	currentEditWelfareAdjustDocumentId = this.dataEditWelfareAdjustRequestId.asObservable();

	private dataSourceAssessmentSetId = new BehaviorSubject(0);
	currentAssessmentSetId = this.dataSourceAssessmentSetId.asObservable();

	private dataSourceWelfareId = new BehaviorSubject(0);
	currentWelfareId = this.dataSourceWelfareId.asObservable();

	private dataSourceMovementTransactionId = new BehaviorSubject(0);
	currentdataSourceMovementTransactionId = this.dataSourceMovementTransactionId.asObservable();

	private dataMovementTypeId = new BehaviorSubject(0);
	currentdataMovementTypeId = this.dataMovementTypeId.asObservable();

	private componentCode = new BehaviorSubject("");
	currentComponentCode = this.componentCode.asObservable();

	private companyId = new BehaviorSubject(0);
	currentCompanyId = this.companyId.asObservable();

	public importEmployeeSuccess = new Subject();

	private routerLinkActive = new BehaviorSubject(new RouterModel());
	currentRouterLinkActive = this.routerLinkActive.asObservable();

	private workInSite = new BehaviorSubject(new WorkinSite());
	currentWorkInSite = this.workInSite.asObservable();

	private encryptionKey = new BehaviorSubject(new EncryptionKey());
	currentEncryptionKey = this.encryptionKey.asObservable();

	private objectiveId = new BehaviorSubject(null);
	currentObjectiveId = this.objectiveId.asObservable();

	private permissionPlan = new BehaviorSubject({});
	permissionPlan$ = this.permissionPlan.asObservable();

	private afterannouncement = new BehaviorSubject(false);
	afterannouncement$ = this.afterannouncement.asObservable();

	private ngxCountDownInterval = new BehaviorSubject(0);
	ngxCountDownInterval$ = this.ngxCountDownInterval.asObservable();

	private ngxCountDownCompleted = new BehaviorSubject("0");
	ngxCountDownCompleted$ = this.ngxCountDownCompleted.asObservable();

	private cacheTabHeader = new BehaviorSubject([]);
	cacheTabHeader$ = this.cacheTabHeader.asObservable();

	private cacheFilterCentric = new BehaviorSubject(new Array(5));
	cacheFilterCentric$ = this.cacheFilterCentric.asObservable();

	private cacheDataEmployeeCentric = new BehaviorSubject([]);
	cacheDataEmployeeCentric$ = this.cacheDataEmployeeCentric.asObservable();

	private payrollSlipCostConfigCompany = new BehaviorSubject<PayrollSlipCostConfigCompany>(null);
	currentPayrollSlipCostConfigCompany = this.payrollSlipCostConfigCompany.asObservable();

	private payrollTab = new BehaviorSubject("");
	currentpayrollTab = this.payrollTab.asObservable();

	private currentTabCentric = new BehaviorSubject({});
	currentTabCentric$ = this.currentTabCentric.asObservable();

	private employeeEmconnect = new BehaviorSubject(null);
	currentEmployeeEmconnect$ = this.employeeEmconnect.asObservable();

	private componentConfigOnboarding = new BehaviorSubject(null);
	componentConfigOnboarding$ = this.componentConfigOnboarding.asObservable();

	private componentConfig = new BehaviorSubject(null);
	componentConfig$ = this.componentConfig.asObservable();

	private backBreadcrumb = new BehaviorSubject<string>(null);
	backBreadcrumb$ = this.backBreadcrumb.asObservable();

	private eventSubscription = new BehaviorSubject<string>(null);
	eventSubscription$ = this.eventSubscription.asObservable();

	private subscription = new BehaviorSubject<CurrentSubscription>(null);
	currentSubscription$ = this.subscription.asObservable();

	private billingType = new BehaviorSubject("");
	currentBillingType = this.billingType.asObservable();

	private policyDetail = new BehaviorSubject<any>(null);
	currentPolicyDetail = this.policyDetail.asObservable();

	private paymentMethodPeriod = new BehaviorSubject<any>(null);
	currentPaymentMethodPeriod = this.paymentMethodPeriod.asObservable();

	public $keyWordSearch: Subject<any> = new Subject<any>();
	public $_loadingSubscription: Subject<any> = new Subject<any>();
	public $_loadingSubscriptionCheck: Subscription;

	private onclick = new Subject<any>();

	public selectedEmployee: { employeeId: number; fullName: string; fullName_EN: string } = null;

	public assessmentResult: { setId: number; scoreId: number } = null;

	public sysBiometricScanner: BiometricScannersModel;

	public isEdit = false;

	public applicantConverting: any = null;

	public headerGCP: HttpHeaders = new HttpHeaders();
	// {
	//   'x-goog-encryption-algorithm': 'AES256',
	//   'x-goog-encryption-key': localStorage.getItem('storageEncryptionKey'),
	//   'x-goog-encryption-key-sha256': localStorage.getItem('storageEncryptionKeyHash')
	// }

	// ----------------- Default Status

	activeStatus: SelectItem[] = [
		{ label: "common_active", value: true },
		{ label: "common_inactive", value: false },
	];
	private activeStatus$ = new BehaviorSubject(this.activeStatus);
	defaultActiveStatus$ = this.activeStatus$.asObservable();

	yesNoStatus: SelectItem[] = [
		{ label: "common_yes", value: true },
		{ label: "common_no", value: false },
	];
	private yesNoStatus$ = new BehaviorSubject(this.yesNoStatus);
	defaultYesNoStatus$ = this.yesNoStatus$.asObservable();

	haveNotStatus: SelectItem[] = [
		{ label: "common_have", value: true },
		{ label: "common_havenot", value: false },
	];
	private haveNotStatus$ = new BehaviorSubject(this.haveNotStatus);
	defaultHaveNotStatus$ = this.haveNotStatus$.asObservable();

	genderUsedStatus: SelectItem[] = [
		{ label: "common_all", value: 0 },
		{ label: "common_male", value: 1 },
		{ label: "common_female", value: 2 },
	];
	private genderUsedStatus$ = new BehaviorSubject(this.genderUsedStatus);
	defaultGenderUsedStatus$ = this.genderUsedStatus$.asObservable();

	setEmpId(empId: number) {
		this.empId.next(empId);
	}

	setSwapFromEmpId(swapFromEmpId: number) {
		this.swapFromEmpId.next(swapFromEmpId);
	}

	setSwapToEmpId(swapToEmpId: number) {
		this.swapToEmpId.next(swapToEmpId);
	}

	setCalendarEmpId(calendarEmpId: number) {
		this.calendarEmpId.next(calendarEmpId);
	}

	setYear(year: number) {
		this.year.next(year);
	}

	setData(data: number) {
		this.dataSource.next(data);
	}

	setDocumentFormData(data: number) {
		this.dataSourceDocumentFormId.next(data);
	}

	setDocumentFormCategoryId(data: number) {
		this.dataSourceDocumentFormCategoryId.next(data);
	}

	setdataAssessmentSetId(data: number) {
		this.dataSourceAssessmentSetId.next(data);
	}

	setObjectiveId(data: number) {
		this.objectiveId.next(data);
	}

	setShiftRequestData(data: number) {
		this.dataSourceShiftRequestId.next(data);
	}

	setTrainingRequestData(data: number) {
		this.dataSourceTrainingRequestId.next(data);
	}

	setLeaveRequestData(data: number) {
		this.dataSourceLeaveId.next(data);
	}

	setPunishmentData(data: number) {
		this.dataSourcePunishId.next(data);
	}

	setWorkinData(data: Leave) {
		this.dataSourceWorkin.next(data);
	}

	setWelfareRequestData(data: number) {
		this.dataSourceWelfareDocumentId.next(data);
	}
	setEditWelfareAdjustRequestId(data: number) {
		this.dataEditWelfareAdjustRequestId.next(data);
	}

	setWelfareId(data: number) {
		this.dataSourceWelfareId.next(data);
	}
	setMovementTransactionId(data: number) {
		this.dataSourceMovementTransactionId.next(data);
	}

	setMovementTypeId(data: number) {
		this.dataMovementTypeId.next(data);
	}

	setStrData(data: string) {
		this.strDataSourse.next(data);
	}

	setBackBreadcrumb(data: string) {
		this.backBreadcrumb.next(data);
	}

	setEventSubscription(data: string) {
		this.eventSubscription.next(data);
	}

	setDataArray(data: any[]) {
		this.dataSourceArray.next(data);
	}

	setDataArrayFilterPayroll(data: any[]) {
		this.dataSourceArrayFilterPayroll.next(data);
	}

	setPaymentMethodPeriod(data: any) {
		this.paymentMethodPeriod.next(data);
	}

	setLang(language: string) {
		switch (language) {
			case "th-TH":
			case "th":
			case LANGUAGE_KEY.TH:
				Configuration.setLanguage(LANGUAGE_KEY.TH);
				this.language.next("th-TH");
				break;
			case LANGUAGE_KEY.ZH:
			case "zh":
				Configuration.setLanguage(LANGUAGE_KEY.ZH);
				this.language.next("zh-CN");
				break;

			case LANGUAGE_KEY.KM:
			case "km":
				Configuration.setLanguage(LANGUAGE_KEY.KM);
				this.language.next("km-KH");
				break;
			case "my":
			case LANGUAGE_KEY.MY:
				Configuration.setLanguage(LANGUAGE_KEY.MY);
				this.language.next("my-MM");
				break;
			default:
				Configuration.setLanguage(LANGUAGE_KEY.EN);
				this.language.next("en-GB");
		}
	}

	setOrganizationConfig(currentOrganizationConfig: OrganizationConfig) {
		this.organizationConfig.next(currentOrganizationConfig);
	}

	setLoading(loading: boolean) {
		this.loading.next(loading);
	}

	setDetectionChange() {
		this.detection$.next(null);
	}

	setDate(date: Date) {
		this.date.next(date);
	}

	setUserInfo(userInfo: UserInfo, useDefaultLang: boolean = true) {
		this.userInfo.next(userInfo);
		this.setStorageEncryptionKey(userInfo);
		this.setEncryptionKey(userInfo.storageEncryptionKey, userInfo.storageEncryptionKeyHash);
		if (!isNullOrUndefined(userInfo.defaultLanguage) && useDefaultLang) {
			this.setLang(userInfo.defaultLanguage);
		}
		Configuration.setLibConfig({
			phraseProject: PhraseProject.empeo,
			application: Application.Empeo,
			theme: Theme.EmpeoLight,
			storageEncryptionKey: userInfo.storageEncryptionKey,
			storageEncryptionKeyHash: userInfo.storageEncryptionKeyHash,
			apiUrlBase: environment.apiUrl,
		});
	}

	setUserInfoMss(userInfoMss: UserInfo) {
		this.userInfoMss.next(userInfoMss);
	}

	setStorageEncryptionKey(userInfo: UserInfo) {
		this.headerGCP = new HttpHeaders({
			"x-goog-encryption-algorithm": "AES256",
			"x-goog-encryption-key": userInfo.storageEncryptionKey,
			"x-goog-encryption-key-sha256": userInfo.storageEncryptionKeyHash,
		});
	}

	setPaymentPeriod(period: PaymentPeriod) {
		this.paymentPeriod.next(period);
	}

	setPaymentPeriods(periods: PaymentPeriod[]) {
		this.paymentPeriods.next(periods);
	}

	setAssessment(assessment: AssessmentModel) {
		this.assessment.next(assessment);
	}

	setWelfareSearchModel(welfareSearchModel: WelfareSearchModel) {
		this.welfareSearchModel.next(welfareSearchModel);
	}

	setLanguageList(lang: Language[]) {
		this.langList.next(lang);
	}

	setMenuList(menu: any[]) {
		this.menuList.next(menu);
	}

	setComponentCode(code: string) {
		this.componentCode.next(code);
	}

	setCompanyId(id: number) {
		this.companyId.next(id);
	}

	setRouterLinkActive(data: RouterModel) {
		this.routerLinkActive.next(data);
	}

	setWorkInSite(data: WorkinSite) {
		this.workInSite.next(data);
	}

	setPermissionPlan(data: any) {
		this.permissionPlan.next(data);
	}

	setAfterAnnouncement(data: boolean) {
		this.afterannouncement.next(data);
	}

	setIsOnlyCollectTime(data: boolean) {
		this.isOnlyCollectTime.next(data);
	}

	setFilterCentric(data: any, tmp: any) {
		let tmpData = data.map((m) => Object.assign({}, m));
		let check: boolean = false;
		tmpData.forEach((f, _index) => {
			Object.keys(f).forEach(function (key) {
				if (isNullOrUndefined(tmp[_index])) {
					if (key == "dataCache") {
						tmpData[_index][key] = new Array(tmpData[_index][key].length);
					}
					return;
				}
				check = true;
				if (key == "filterCache") {
					tmpData[_index][key].forEach((fTmp, _indexTmp) => {
						if (!isNullOrUndefined(fTmp)) {
							tmp[_index][key][_indexTmp] = fTmp;
						}
					});
				} else {
					tmp[_index][key] = new Array(tmpData[_index][key].length);
				}
			});
		});
		this.cacheFilterCentric.next(check ? tmp : tmpData);
	}

	setNgxCountDownInterval(interval: number) {
		this.ngxCountDownInterval.next(interval);
	}
	setHeaderEmployeeCentric(newData: any[], oldData: any[] = []) {
		let tmpData: any[];
		if (newData.length == oldData.length) {
			newData.forEach((f) => {
				if (f.employeeStatus != 1) {
					let index = oldData.findIndex(
						(old) => old.employeeStatus == f.employeeStatus && old.tabSecond == f.tabSecond && old.value == f.value,
					);
					oldData[index] = f;
				}
			});
			tmpData = oldData;
		} else if (oldData.length == 0) {
			tmpData = newData;
		} else {
			newData.forEach((f) => {
				let index = oldData.findIndex(
					(old) => old.employeeStatus == f.employeeStatus && old.tabSecond == f.tabSecond && old.value == f.value,
				);
				oldData[index] = f;
			});
			tmpData = oldData;
		}
		this.cacheTabHeader.next(tmpData);
	}
	setDataEmployeeCentric(data: any) {
		this.cacheDataEmployeeCentric.next(data);
	}

	setNgxCountDownCompleted(data: any) {
		this.ngxCountDownCompleted.next(data);
	}
	setpayrollTab(string: string) {
		this.payrollTab.next(string);
	}
	setEncryptionKey(storageEncryptionKey: string, storageEncryptionKeyHash: string) {
		localStorage.removeItem("storageEncryptionKey");
		localStorage.removeItem("storageEncryptionKeyHash");
		localStorage.setItem("storageEncryptionKey", isNullOrUndefined(storageEncryptionKey) ? null : storageEncryptionKey);
		localStorage.setItem(
			"storageEncryptionKeyHash",
			isNullOrUndefined(storageEncryptionKeyHash) ? null : storageEncryptionKeyHash,
		);
	}

	setPayrollSlipCostConfigCompany(data: any) {
		this.payrollSlipCostConfigCompany.next(data);
	}

	sendClickEvent() {
		this.onclick.next(null);
	}

	setCurrentTabCentric(index = 0, childIndex = 0) {
		let objCurrentTab = {
			index: index,
			childIndex: childIndex,
		};
		this.currentTabCentric.next(objCurrentTab);
	}

	setEmployeeEmconnect(emp: any) {
		this.employeeEmconnect.next(emp);
	}

	setComponentConfigOnboarding(model: any) {
		this.componentConfigOnboarding.next(model);
	}
	setComponentConfig(model: any) {
		this.componentConfig.next(model);
	}
	setSubscription(model: any) {
		this.subscription.next(model);
	}

	setCourseData(data: number) {
		this.dataSourceCourseId.next(data);
	}

	setPreviousRouteName(routeName: string) {
		this.previousRouteName.next(routeName);
	}

	setBillingType(billingType: string) {
		this.billingType.next(billingType);
	}

	setPolicyDetail(policyDetail: string) {
		this.policyDetail.next(policyDetail);
	}

	getClickEvent(): Observable<any> {
		return this.onclick.asObservable();
	}

	getIsTH(val: string) {
		return val?.toLowerCase()?.search("th") > -1;
	}

	getIsMY(val: string) {
		return val?.toLowerCase()?.search("my") > -1;
	}

	getIsZH(val: string) {
		return val?.toLowerCase()?.search("zh") > -1;
	}

	getIsKM(val: string) {
		return val?.toLowerCase()?.search("km") > -1;
	}

	getLocal(val: string) {
		return this.getIsTH(val) ? "th" : "en";
	}

	getLocalGB(val: string) {
		return this.getIsTH(val) ? "th" : "gb";
	}

	getLocale(val: string) {
		if (this.getIsTH(val)) {
			return LANGUAGE_KEY.TH;
		} else if (this.getIsZH(val)) {
			return LANGUAGE_KEY.ZH;
		} else if (this.getIsKM(val)) {
			return LANGUAGE_KEY.KM;
		} else if (this.getIsMY(val)) {
			return LANGUAGE_KEY.MY;
		} else {
			return LANGUAGE_KEY.EN;
		}
	}

	getTextColor(documentStatus) {
		switch (documentStatus) {
			case DocumentStatus.canceled:
			case DocumentStatus.rejected:
			case ProcessAction.canceledDocument:
			case ProcessAction.rejectedDocument:
			case ProcessAction.returnDocument:
				return "#777777";
			default:
				return "white";
		}
	}

	getVal(localize: boolean, val: any[]) {
		if (isNullOrUndefined(val)) {
			return "";
		}
		if (val.length == 0) {
			return "";
		}
		let valNew = val
			.map((m) => {
				return m[localize ? "name" : "name_EN"];
			})
			.join(", ");
		return valNew.length > 30;
	}

	// ----------------- set Crisp Chat
	loadCrispChat(model: UserInfo, element, accessEmail: string = "") {
		Crisp.configure(environment.crispWebsiteId, { locale: "th-TH" });

		const script = document.createElement("script");
		script.src = "https://client.crisp.chat/l.js";
		script.type = "text/javascript";
		script.async = true;
		document.getElementsByTagName("head")[0].appendChild(script);
		this.setCrispChat(
			"infoCrispChat",
			`
			$crisp.push(["set", "user:email", ['${model.emailAddress}']]);
			$crisp.push(["set", "user:company", ['${model.companyName_EN}', {
				employment: ['${model.positionName_EN}', '${model.rankName}']
			}]]);
			$crisp.push(["set", "user:nickname", ['${model.fullName_EN}']]);
			$crisp.push(["set", "user:phone", ['${model.mobileNo}']]);
			$crisp.push(["set", "user:avatar", ['${model.pictureThumbnailURL}']]);
			`,
		);
		if (!isNullOrUndefined(element?.planName)) {
			this.setCrispChat(
				"infoCrispChatData",
				`
				$crisp.push(["set", "session:data", [
					[['Status','${model.subscriptionStatus}'],
					 ['Plan','${element?.planName}'],
					 ['Seat','${element?.limitUser}'],
					 ['ActiveUser','${element?.activeUser}'],
					 ['PlanType','${element?.planType}'],
					 ['NextBilling','${element?.nextBilling}'],
					 ['ValidUntil','${element?.validUntil}'],
					 ['CustomerCode','${element?.customerCode}'],
					 ['CustomerUniqueName','${element?.uniqueName}'],
					 ['AccessRemoteEmail','${accessEmail}']
					]
				]]);
				`,
			);
		}

		this.setCrispChat("infoCrispToken", `CRISP_TOKEN_ID = '` + model.userId + `'`);
		if (!model.isHR) {
			this.setCrispChat(
				"closeCrispChat",
				`$crisp.push(["on", "chat:closed", () => { if(document.location.href.search('help-empeo') == -1){ $crisp.push(['do', 'chat:hide']);} }])`,
			);
			this.setCrispChat("displaycrisp", `$crisp.push(['do', 'chat:hide']);`);
		}
	}

	// ----------------- Event Setup Crisp Chat
	sendEventCrispChat(keyName: string) {
		this.setCrispChat("eventKey", `$crisp.push(["set","session:event", ['` + keyName + `']]);`);
	}

	// ----------------- clear Crisp Chat
	clearCrispChat() {
		this.setCrispChat("clearCrispChat", `window.CRISP_TOKEN_ID=undefined;$crisp.push(["do", "session:reset"]);`);
	}

	// ----------------- event close Crisp Chat
	closeCrisp() {
		if (!isNullOrUndefined(this.userInfo.value) && this.userInfo.value.isHR == false) {
			let windowCrisp: HTMLElement = document.querySelector(".crisp-ewasyx");
			if (
				isNullOrUndefined(windowCrisp) ||
				(!isNullOrUndefined(windowCrisp) && windowCrisp.getAttribute("data-visible") == "false")
			) {
				this.setCrispChat("displaycrisp", `$crisp.push(['do', 'chat:hide']);`);
			}
		}
	}

	// ----------------- event open Crisp Chat
	openCrisp() {
		this.setCrispChat("displaycrisp", `$crisp.push(['do', 'chat:show']);`);
	}

	// ----------------- default write script Crisp Chat
	setCrispChat(name: string, condition: string) {
		if (!isNullOrUndefined(document.getElementById(name))) {
			document.getElementById(name).remove();
		}
		const initCrispChat = document.createElement("script");
		initCrispChat.type = "text/javascript";
		initCrispChat.innerText = condition;
		initCrispChat.id = name;
		document.getElementsByTagName("head")[0].appendChild(initCrispChat);
	}

	// End class

	openCrispChat() {
		this.setCrispChat("displaycrisp", `$crisp.push(['do', 'chat:open']);`);
	}

	/**
	 * Starts the implicit flow and redirects to user to
	 * the auth servers login url.
	 *
	 * @param msg string message
	 */
	setMessage(msg: string) {
		this.openCrispChat();
		let msgForTest = environment.envName == "prod" ? "" : `[Testing on ${environment.envName}] `;
		this.setCrispChat("displaycrisp", `$crisp.push(['do', 'message:send', ['text','${msgForTest + msg}']]);`);
	}

	counter(i: number) {
		return new Array(i);
	}

	openBase64InNewTab(data, mimeType) {
		const byteCharacters = atob(data);
		const byteNumbers = new Array(byteCharacters.length);
		for (var i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const file = new Blob([byteArray], { type: mimeType + ";base64" });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	}

	loadingSubscription(): Observable<any> {
		this.$_loadingSubscriptionCheck = this.$_loadingSubscription.subscribe();
		return this.$_loadingSubscription.pipe(
			map((res) => {
				return res;
			}),
		);
	}

	closeLoading() {
		this.$_loadingSubscription.next(null);
		if (this.$_loadingSubscriptionCheck) {
			this.$_loadingSubscriptionCheck?.unsubscribe();
		}
	}
}
