@if (isShowHeader) {
	<div class="employee-add-subtitle go5-text-header-5 go5-text-color-12">
		{{ "emp001_additional_information" | translate }}
	</div>
}
@if (form) {
	<div class="employee-add-fills-container">
		@for (data of customFieldModel; track data; let i = $index) {
			<div class="employee-add-fill-container">
				@for (item of data.customFieldModel; track item; let i = $index) {
					<span
						class="employee-add-fill"
						[ngClass]="{ 'custom-field-text-area': item.fieldTypeId == customFieldsType.Paragraph }"
					>
						<ng-container
							*ngTemplateOutlet="
								item.fieldTypeId == customFieldsType.Text
									? customText
									: item.fieldTypeId == customFieldsType.SingleSelection
										? customSingleSelection
										: item.fieldTypeId == customFieldsType.MultiSelection
											? customMultiSelection
											: item.fieldTypeId == customFieldsType.DropdownList
												? customDropdownList
												: item.fieldTypeId == customFieldsType.DatePicker
													? customDatePicker
													: customParagraph;
								context: { $implicit: item }
							"
						></ng-container>
					</span>
				}
			</div>
		}
		@if (customFieldModel?.length == 0 && isLoadedCustomField) {
			<div class="d-flex direction-column align-center g-8" style="padding-top: 7%">
				<div>
					<img class="responsive-image" src="assets/images/empty-state-no-data.svg" alt="apollo-layout" />
				</div>
				<div class="go5-text-title-strong go5-text-color-8">
					<div>{{ "common_no_data" | translate }}</div>
				</div>
			</div>
		}
	</div>
}

<ng-template #customText let-data>
	<ng-container [formGroup]="form">
		<ng-container
			*ngTemplateOutlet="
				data.textBoxType == customFieldsTextBoxType.Number
					? textFieldNumber
					: data.textBoxType == customFieldsTextBoxType.Letter
						? textFieldLetter
						: data.textBoxType == customFieldsTextBoxType.Custom
							? textFieldCustom
							: defaultTextField
			"
		></ng-container>

		<ng-template #textFieldNumber>
			<go5-textfield
				[label]="data.name + (data.isRequire ? '*' : '')"
				[type]="textFieldType.Text"
				[pKeyFilter]="'num'"
				[placeholder]="('common_fill' | translate) + data.name"
				[formControlName]="data.name"
				[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
				[errorMessage]="'common_validate_please_fill' | translate: { fieldName: data.name }"
			>
			</go5-textfield>
		</ng-template>

		<ng-template #textFieldLetter>
			<go5-textfield
				[label]="data.name + (data.isRequire ? '*' : '')"
				[type]="textFieldType.Text"
				[pKeyFilter]="regexLetters"
				[formControlName]="data.name"
				[placeholder]="('common_fill' | translate) + data.name"
				[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
				[errorMessage]="'common_validate_please_fill' | translate: { fieldName: data.name }"
			>
			</go5-textfield>
		</ng-template>

		<ng-template #textFieldCustom>
			<go5-textfield
				[label]="data.name + (data.isRequire ? '*' : '')"
				[type]="textFieldType.Text"
				[placeholder]="('common_fill' | translate) + data.name"
				[formControlName]="data.name"
				[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
				[errorMessage]="'common_validate_please_fill' | translate: { fieldName: data.name }"
			>
			</go5-textfield>
		</ng-template>

		<ng-template #defaultTextField>
			<go5-textfield
				[label]="data.name + (data.isRequire ? '*' : '')"
				[type]="textFieldType.Text"
				[placeholder]="('common_fill' | translate) + data.name"
				[formControlName]="data.name"
				[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
				[errorMessage]="
					data.textBoxType == customFieldsTextBoxType.Email && form?.controls[data.name]?.value
						? ('common_email_format' | translate)
						: ('common_validate_please_fill' | translate: { fieldName: data.name })
				"
			>
			</go5-textfield>
		</ng-template>
	</ng-container>
</ng-template>

<ng-template #customSingleSelection let-data>
	<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">
		{{ data.name + (data.isRequire ? "*" : "") }}
	</div>
	<ng-container [formGroup]="form">
		<go5-selection-single
			category="primary"
			size="medium"
			[dataSource]="data.masAdditionalFieldDetails"
			[fields]="fields"
			[formControlName]="data.name"
			[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
			[errorMessage]="'common_select_one_option' | translate"
		></go5-selection-single>
	</ng-container>
</ng-template>

<ng-template #customMultiSelection let-data>
	<div class="go5-text-color-8 go5-text-body" style="margin: 0 0 8px">
		{{ data.name + (data.isRequire ? "*" : "") }}
	</div>
	<ng-container [formGroup]="form">
		<go5-selection-multi
			category="primary"
			size="medium"
			[dataSource]="data.masAdditionalFieldDetails"
			[fields]="fields"
			[formControlName]="data.name"
			[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
			[errorMessage]="'common_select_one_option' | translate"
		></go5-selection-multi>
	</ng-container>
</ng-template>

<ng-template #customDropdownList let-data>
	<ng-container [formGroup]="form">
		<go5-dropdown-selection
			[dataSource]="data.masAdditionalFieldDetails"
			[label]="data.name + (data.isRequire ? '*' : '')"
			[fields]="fields"
			[placeholder]="'common_please_choose' | translate"
			[formControlName]="data.name"
			[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
			[errorMessage]="'common_validate_please_choose' | translate: { fieldName: data.name }"
		></go5-dropdown-selection>
	</ng-container>
</ng-template>

<ng-template #customDatePicker let-data>
	<ng-container [formGroup]="form">
		<go5-datepicker
			[label]="data.name + (data.isRequire ? '*' : '')"
			[isShowTime]="false"
			[placeholder]="'common_please_choose' | translate"
			[formControlName]="data.name"
			[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
			[errorMessage]="'common_validate_please_choose' | translate: { fieldName: data.name }"
			[format]="dateFormat.dateLong"
		></go5-datepicker>
	</ng-container>
</ng-template>

<ng-template #customParagraph let-data>
	<ng-container [formGroup]="form">
		<go5-textarea
			[label]="data.name + (data.isRequire ? '*' : '')"
			[size]="'extra-large'"
			[placeholder]="('common_fill' | translate) + data.name"
			[formControlName]="data.name"
			[error]="!form?.controls[data.name]?.valid && form?.controls[data.name]?.touched"
			[errorMessage]="'common_validate_please_fill' | translate: { fieldName: data.name }"
		></go5-textarea>
	</ng-container>
</ng-template>
