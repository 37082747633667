import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { firstValueFrom } from "rxjs";
import { ApiResult } from "../models/api-result.model";
// import { Dropdown } from "src/app/shared/models/dropdown.model";

@Injectable()
export class AssetsService {
	constructor(
		private http: HttpClient,
		private commonService: CommonService,
		@Inject("API_URL") private apiUrl: string,
	) {}

	addAssets(assetDetail: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(assetDetail);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post<any>(this.apiUrl + "v1/assets/add-assets", body, httpOptions)).then(
			(res) => <ApiResult>res,
		);
	}

	editAssets(assetDetail: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(assetDetail);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "v1/assets/edit-assets", body, httpOptions);
	}

	getAssets(assetSearch: any) {
		const body = JSON.stringify(assetSearch);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
			body: body,
		};

		return firstValueFrom(this.http.post<any>(this.apiUrl + "v1/assets/list", body, httpOptions)).then((res) => {
			if (res === null) {
				return <ApiResult>{ result: false, message: "No content", data: null };
			}
			return <ApiResult>res;
		});
	}

	getAssetsById(assetId: Number) {
		return firstValueFrom(this.http.get<any>(`${this.apiUrl}v1/assets/assets?assetId=${assetId}`)).then((res) => {
			if (res === null) {
				return <ApiResult>{ result: false, message: "No content", data: null };
			}
			return <ApiResult>res;
		});
	}

	getAssetLogs(assetId: Number) {
		return firstValueFrom(this.http.get<any>(`${this.apiUrl}v1/assets/asset-logs?assetId=${assetId}`)).then((res) => {
			if (res === null) {
				return <ApiResult>{ result: false, message: "No content", data: null };
			}
			return <ApiResult>res;
		});
	}

	getAssetPendingReturn() {
		return firstValueFrom(this.http.get<any>(`${this.apiUrl}v1/assets/pending-returns`)).then((res) => {
			if (res === null) {
				return <ApiResult>{ result: false, message: "No content", data: null };
			}
			return <ApiResult>res;
		});
	}

	transferAssets(assets) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(assets);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post<any>(this.apiUrl + "v1/assets/transfer-assets", body, httpOptions)).then(
			(res) => {
				return <ApiResult>res;
			},
		);
	}
}
