import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiUrl } from "../enum/app-config.enum";

@Injectable({
	providedIn: "root",
})
export class MasterService {
	private httpOptions = {
		headers: new HttpHeaders({ "Content-Type": "application/json" }),
	};

	constructor(
		private _http: HttpClient,
		@Inject("API_URL_LST") private _apiUrl: string[],
	) {}

	changeDateJSON() {
		Date.prototype.toJSON = function () {
			var timezoneOffsetInHours = -(this.getTimezoneOffset() / 60); //UTC minus local time

			const integerPart: number = Math.floor(timezoneOffsetInHours);
			const decimalPart: number = timezoneOffsetInHours - integerPart;

			var sign = integerPart >= 0 ? "+" : "-";

			var correctedDate = new Date(
				this.getFullYear(),
				this.getMonth(),
				this.getDate(),
				this.getHours(),
				this.getMinutes(),
				this.getSeconds(),
				this.getMilliseconds(),
			);
			correctedDate.setHours(this.getHours() + integerPart);
			var iso = correctedDate.toISOString().replace("Z", "");

			return (
				iso +
				sign +
				Math.abs(integerPart).toString().padStart(2, "0") +
				":" +
				(decimalPart * 60).toString().padStart(2, "0")
			);
		};
	}
	public get<T>(url: string, model = null, apiVer = ApiUrl.empeo, httpOptions?) {
		if (model) {
			this.changeDateJSON();
		}
		const modelStr = model
			? "?" +
				Object.keys(model)
					.map((key) => {
						if (Array.isArray(model[key])) {
							return model[key].map((s) => key + "=" + s).join("&");
						} else {
							return model[key] ? key + "=" + model[key] : "";
						}
					})
					.filter((s) => s)
					.join("&")
			: "";
		if (httpOptions) return this._http.get<T>(this._apiUrl[apiVer] + url + modelStr, httpOptions);
		else return this._http.get<T>(this._apiUrl[apiVer] + url + modelStr, this.httpOptions);
	}

	public post<T>(url: string, model?: any, apiVer = ApiUrl.empeo): Observable<HttpEvent<T>> {
		if (model != null) {
			const body = JSON.stringify(model);
			this.changeDateJSON();
			return this._http.post<T>(this._apiUrl[apiVer] + url, body, this.getHttpParams(model));
		}
	}

	public postDownload(url: string, model?: any, apiVer = ApiUrl.empeo) {
		let req: Observable<HttpResponse<Blob>>;
		if (model != null) {
			const body = JSON.stringify(model);
			req = this._http.post(this._apiUrl[apiVer] + url, body, {
				observe: "response",
				responseType: "blob",
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
			});
		} else {
			req = this._http.post(this._apiUrl[apiVer] + url, null, {
				observe: "response",
				responseType: "blob",
			});
		}
		return req;
	}

	public downloadFile(blob: Blob, headers: HttpHeaders) {
		const header: string = headers.get("content-disposition");
		let fileName: string = null;
		if (header && header.indexOf("attachment") !== -1) {
			const regex = /name[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			const matches = regex.exec(header);
			if (matches != null && matches[1]) {
				fileName = matches[1].replace(/['"]/g, "");
			}
		}
		const url = window.URL.createObjectURL(blob);
		if (fileName) {
			const anchor = document.createElement("a");
			anchor.download = fileName;
			anchor.href = url;
			anchor.click();
		} else {
			const pwa = window.open(url);
			if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
				alert("Please disable your Pop-up blocker and try again.");
			}
		}
	}

	public put<T>(url: string, model?: any, apiVer = ApiUrl.empeo, httpOptions?) {
		if (model != null) {
			const body = JSON.stringify(model);
			if (httpOptions) {
				return this._http.put<T>(this._apiUrl[apiVer] + url, body, httpOptions);
			} else {
				return this._http.put<T>(this._apiUrl[apiVer] + url, body, this.httpOptions);
			}
		}
		return this._http.put<T>(this._apiUrl[apiVer] + url, null);
	}

	public delete<T>(url: string, model?: any, apiVer = ApiUrl.empeo, httpOptions?) {
		const options = {
			headers: httpOptions ? httpOptions : this.httpOptions.headers,
			body: JSON.stringify(model),
		};
		return this._http.delete<T>(this._apiUrl[apiVer] + url, options);
	}

	public event(url: string, apiVer = ApiUrl.empeo) {
		const option: EventSourceInit = {
			withCredentials: true,
		};
		return new EventSource(this._apiUrl[apiVer] + url, option);
	}

	public createWhereCause(preCondition: any, param?: Array<string>, search?: string, filter?: object) {
		const newCondition: Object = {};

		if (filter) {
			newCondition["dtFilter"] = {};
			Object.keys(filter).forEach((e) => {
				newCondition["dtFilter"][e] = filter[e];
			});
		}
		if (search && param) {
			newCondition["dtSearch"] = {};
			param.forEach((e) => {
				newCondition["dtSearch"][e] = search;
			});
		}
		return JSON.stringify(newCondition);
	}

	public uploadGCP<T>(url: string, blob: Blob, sendHeader: boolean = true, key: string = null, hash: string = null) {
		return this._http.put<any>(url, blob, {
			headers: !sendHeader
				? null
				: {
						"x-goog-encryption-key": key,
						"x-goog-encryption-key-sha256": hash,
						"x-goog-encryption-algorithm": "AES256",
					},
		});
	}

	protected getHttpParams(obj: object) {
		const requestOptions: any = {};
		requestOptions.headers = new HttpHeaders({
			"Content-Type": "application/json",
		});

		if (obj !== null) {
			requestOptions.params = this.objectToHttpParams(obj);
		}

		return requestOptions;
	}

	protected objectToHttpParams(obj: object): HttpParams {
		let params = new HttpParams();
		for (const key of Object.keys(obj)) {
			params = params.set(key, obj[key] as unknown as string);
		}

		return params;
	}
}
