import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmployeePunishment, EmployeePunishmentsAddModel, MssSearchWarningLetter } from "./employee-punishment.model";
import { EmployeePunishmentAttacheds } from "./employee-punishment-attacheds.model";
import { CommonService } from "../../../shared/service/common.service";
import { Checkbox } from "primeng/checkbox";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeePunishmentService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	addEmployeePunishment(employeePunishment: EmployeePunishment, fileModel: EmployeePunishmentAttacheds[]) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify({ employeePunishment: employeePunishment, fileModel: fileModel });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "EmployeePunishment/AddEmployeePunishment", body, httpOptions);
	}

	editEmployeePunishment(employeePunishment: EmployeePunishment, fileModel: EmployeePunishmentAttacheds[]) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify({ employeePunishment: employeePunishment, fileModel: fileModel });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "EmployeePunishment/EditEmployeePunishment", body, httpOptions);
	}

	getEmployeePunishmentByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}EmployeePunishment/GetEmployeePunishmentByEmployeeId/${employeeId}`),
		).then((res) => <EmployeePunishment[]>res);
	}
	getEmployeePunishmentWaitingReviseByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}EmployeePunishment/GetEmployeePunishmentWaitingReviseByEmployeeId/${employeeId}`),
		).then((res) => <EmployeePunishment[]>res);
	}

	getEmployeePunishmentByEmployeeId2(employeeId: number, isDashboard: boolean = false) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}EmployeePunishment/GetEmployeePunishmentByEmployeeId/${employeeId}/${isDashboard}`),
		).then((res) => <EmployeePunishment[]>res);
	}

	getEmployeePunishmentByPunishId(punishId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeePunishment/GetEmployeePunishmentByPunishId/" + punishId),
		).then((res) => <EmployeePunishmentsAddModel>res);
	}

	getEmployeePunishmentAttachedsByPunishId(punishId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeePunishment/GetEmployeePunishmentAttachedsByPunishId/" + punishId),
		).then((res) => <any[]>res);
	}

	getEmployeePunishmentAttachedsByPunishAttachmentId(punishAttachmentId: number) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl + "EmployeePunishment/GetEmployeePunishmentAttachedsByPunishAttachmentId/" + punishAttachmentId,
			),
		).then((res) => res);
	}

	deleteEmployeePunishment(punishId: number) {
		var body = JSON.stringify(punishId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeePunishment/DeleteEmployeePunishment", body, httpOptions);
	}

	getMssEmployeePunishment(searchModel: MssSearchWarningLetter) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(searchModel);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeePunishment/GetMssEmployeePunishment", body, httpOptions);
	}

	updateIsActiveEmployeePunishmentAttachment(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(
			this.apiUrl + "EmployeePunishment/UpdateIsActiveEmployeePunishmentAttachment",
			body,
			httpOptions,
		);
	}

	getProcessActionByUserId(punishId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "EmployeePunishment/GetProcessActionByUserId/" + punishId)).then(
			(res) => <Checkbox[]>res,
		);
	}

	addProcessAction(model: EmployeePunishment) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeePunishment/AddProcessActionEmployeePunishment/", body, httpOptions);
	}

	getEmployeePunishmentWorkflowLogs(punishId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeePunishment/GetEmployeePunishmentWorkflowLogs/" + punishId),
		).then((res) => <any[]>res);
	}

	editEmployeePunishmentAcknowledge(punishId: number) {
		var body = JSON.stringify(punishId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeePunishment/EditEmployeePunishmentAcknowledge", body, httpOptions);
	}
	getEmployeeDocumentPunishment(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeePunishment/GetEmployeeDocumentPunishment", body, httpOptions);
	}
	checkDocument(punishId, employeeId) {
		return firstValueFrom(
			this.http.get(`${this.apiUrl}EmployeePunishment/CheckDocument/${punishId}/${employeeId}`),
		).then((r) => <any>r);
	}
}
