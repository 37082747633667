<div [ngClass]="isMobile ? 'ui-g-12 p-3' : 'my-2'">
	<go5-tabs
		[(activeIndex)]="currentIndex"
		[headerPadding]="'0px 0px 16px 0px'"
		[contentPadding]="'0'"
		[isShowBorder]="false"
		(changed)="onchangeTab()"
	>
		<go5-tab headerText="{{ 'emp001_t14_payroll' | translate }}">
			<ng-template #contentTemplate>
				<ng-container *ngTemplateOutlet="salaryTabWeb"></ng-container>
			</ng-template>
		</go5-tab>
		<go5-tab headerText="{{ 'emp001_t14_bonus' | translate }}">
			<ng-template #contentTemplate>
				<ng-container *ngTemplateOutlet="bonusTabWeb"></ng-container>
			</ng-template>
		</go5-tab>
	</go5-tabs>

	<go5-table-empeo-basic *ngIf="(isMobile || isSmallScreen) && !loadingData; else loadingState" [data]="dataList">
		<ng-template #headers>
			<th style="width: 4%">
				<div class="d-flex" style="align-items: center"></div>
			</th>
			<th style="width: 48%">
				<span>{{ (isSalaryTab ? "emp001_t14_movement_type" : "common_date") | translate }}</span>
			</th>
			<th class="text-right" style="width: 48%">
				<span>{{ (isSalaryTab ? "ess009_salary_adj_adjusted_amt" : "common_amount") | translate }}</span>
			</th>
		</ng-template>
		<ng-template #rows>
			<ng-template ngFor [ngForOf]="dataList" let-item let-i="index">
				<tr class="hover-content table-hover" style="cursor: pointer" (click)="toggleExpand(i)">
					<td class="go5-text-color-7" style="font-size: large">
						<div>
							<em
								class="expand-row gf-icon-down"
								[ngClass]="{
									open: toggleIndex === i
								}"
							></em>
						</div>
					</td>

					<td class="text-left">
						<div *ngIf="isSalaryTab">
							<p class="go5-text-body-bold go5-text-color-11">{{ language === "th-TH" ? item.name : item.name_EN }}</p>
							<p class="go5-text-small go5-text-color-8">
								{{ item.dateEffective | sDate: dateFormat.dateShort | async }}
							</p>
						</div>
						<div *ngIf="isBonusTab">
							<p class="go5-text-body-bold go5-text-color-11 my-2">
								{{ item.dateEffective | sDate: dateFormat.dateShort | async }}
							</p>
						</div>
					</td>
					<td class="text-right go5-text-color-11">
						{{ isShowSalary && item.amount != null ? "฿" + (item.amount | number: "1.2-2") : maskAmount }}
					</td>
				</tr>
				<tr *ngIf="toggleIndex === i && isSalaryTab">
					<td><div></div></td>
					<td colspan="2">
						<div>
							<div class="d-flex" style="flex-direction: column; align-items: flex-start">
								<span class="go5-text-small-strong go5-text-body-bold">
									{{ "emp001_t14_salary_before_adjust" | translate }}
								</span>
								<span class="go5-text-color-8">
									{{ isShowSalary && item.prevAmount != null ? "฿" + (item.prevAmount | number: "1.2-2") : maskAmount }}
								</span>
							</div>
						</div>
					</td>
				</tr>
				<tr *ngIf="toggleIndex === i && isSalaryTab">
					<td><div></div></td>
					<td colspan="2">
						<div>
							<div class="d-flex" style="flex-direction: column; align-items: flex-start">
								<span class="go5-text-small-strong go5-text-body-bold">
									{{ "emp001_t14_fine_rate" | translate }}
								</span>
								<span class="go5-text-color-8">
									{{ isShowSalary && item.percentage != null ? (item.percentage | number: "0.0-2") + "%" : maskAmount }}
								</span>
							</div>
						</div>
					</td>
				</tr>
				<tr *ngIf="toggleIndex === i && isSalaryTab">
					<td><div></div></td>
					<td colspan="2">
						<div>
							<div class="d-flex" style="flex-direction: column; align-items: flex-start">
								<span class="go5-text-small-strong go5-text-body-bold">
									{{ "emp010_remarks" | translate }}
								</span>
								<span class="go5-text-color-8">
									{{ item.remark && item.remark != "" ? item.remark : "-" }}
								</span>
							</div>
						</div>
					</td>
				</tr>
				<tr *ngIf="toggleIndex === i && isBonusTab">
					<td><div></div></td>
					<td colspan="2">
						<div>
							<div class="d-flex" style="flex-direction: column; align-items: flex-start">
								<span class="go5-text-small-strong go5-text-body-bold">
									{{ "emp001_t14_bonus" | translate }}
								</span>
								<span class="go5-text-color-8">
									{{
										isShowSalary && item.percentage != null
											? (item.percentage | number: "0.0-2") + " " + ("common_month" | translate)
											: maskAmount
									}}
								</span>
							</div>
						</div>
					</td>
				</tr>
				<tr *ngIf="toggleIndex === i">
					<td><div></div></td>
					<td colspan="2">
						<div>
							<div class="d-flex" style="flex-direction: column; align-items: flex-start">
								<span class="go5-text-small-strong go5-text-body-bold">
									{{ "common_modified_by" | translate }}
								</span>
								<span class="go5-text-body-strong go5-text-color-8">
									{{ language === "th-TH" ? item.modifiedBy : item.modifiedBy_EN }}
								</span>
								<span class="go5-text-small go5-text-color-8">
									{{ item.dateModified | sDate: dateFormat.dateTimeShort | async }}
								</span>
							</div>
						</div>
					</td>
				</tr>
			</ng-template>
		</ng-template>
	</go5-table-empeo-basic>
	<div *ngIf="dataList?.length <= 0 && !loadingData">
		<div class="d-flex pt-5" style="justify-content: center; width: 100%">
			<svg class="not-found-history"></svg>
		</div>
		<div class="d-flex pt-2" style="justify-content: center; width: 100%">
			<p class="go5-text-color-8">{{ "common_no_data" | translate }}</p>
		</div>
	</div>
	<ng-template #loadingState>
		<div *ngIf="loadingData" class="table-content">
			<ngx-skeleton-loader
				[theme]="{ height: '33px', float: 'right', 'margin-bottom': '0px' }"
				animation="progress-dark"
			>
			</ngx-skeleton-loader>
			<table class="skeleton" aria-describedby="loading-salary-adjustment-history">
				<tr>
					<th style="width: 4%"></th>
					<th style="width: 48%"></th>
					<th style="width: 48%"></th>
				</tr>
				<tbody>
					<tr *ngFor="let number of counter(5)">
						<td style="width: 4%">
							<ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '30px', margin: '10px 0px 0px 20px' }">
							</ngx-skeleton-loader>
						</td>
						<td style="width: 48%">
							<ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '30px', margin: '10px 0px 0px 20px' }">
							</ngx-skeleton-loader>
						</td>
						<td style="width: 48%">
							<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '30px', margin: '10px 0px 0px 0px' }">
							</ngx-skeleton-loader>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</ng-template>
</div>

<ng-template #salaryTabWeb>
	<div *ngIf="!isMobile && !isSmallScreen">
		<go5-table-empeo-basic [data]="salaryAdjustmentHistory">
			<ng-template #headers>
				<th class="th-sm-2 border-top-left-default" style="width: 10%">
					<label for="input">{{ "emp001_t14_effective_date" | translate }}</label>
				</th>
				<th class="th-sm-2 text-left" style="width: 15%">
					<label for="input">{{ "emp001_t14_movement_type" | translate }}</label>
				</th>
				<th class="th-sm-1 text-right" style="width: 10%">
					<label for="input">{{ "emp001_t14_salary_before_adjust" | translate }}</label>
				</th>
				<th class="th-sm-1 text-right" style="width: 10%">
					<label for="input">{{ "emp001_t14_salary_after_adjust" | translate }}</label>
				</th>
				<th class="th-sm-1 text-right" style="width: 10%">
					<label for="input">{{ "emp001_t14_fine_rate" | translate }}</label>
				</th>
				<th class="th-sm-2 text-left" style="width: 15%">
					<label for="input">{{ "emp001_t14_remark" | translate }}</label>
				</th>
				<th class="th-sm-2 text-left" style="width: 15%">
					<label for="input">{{ "common_modified_by" | translate }}</label>
				</th>
				<th id="action" *ngIf="isAllowDelete" class="th-sm-1" style="width: 5%">
					<label for="input"> </label>
				</th>
			</ng-template>
			@if (loadingData) {
				<ng-template #rows>
					<tr *ngFor="let number of counter(10)">
						<td class="pb-0 fadeIn">
							<ngx-skeleton-loader
								[theme]="{
									'padding-bottom': '0px',
									'margin-bottom': '0px',
									height: '40px'
								}"
							>
							</ngx-skeleton-loader>
						</td>
						<td class="pb-0 fadeIn">
							<ngx-skeleton-loader
								[theme]="{
									'padding-bottom': '0px',
									'margin-bottom': '0px',
									height: '40px'
								}"
							>
							</ngx-skeleton-loader>
						</td>
						<td class="pb-0 fadeIn">
							<ngx-skeleton-loader
								[theme]="{
									'padding-bottom': '0px',
									'margin-bottom': '0px',
									height: '40px'
								}"
							>
							</ngx-skeleton-loader>
						</td>
						<td class="pb-0 fadeIn">
							<ngx-skeleton-loader
								[theme]="{
									'padding-bottom': '0px',
									'margin-bottom': '0px',
									height: '40px'
								}"
							>
							</ngx-skeleton-loader>
						</td>
						<td class="pb-0 fadeIn">
							<ngx-skeleton-loader
								[theme]="{
									'padding-bottom': '0px',
									'margin-bottom': '0px',
									height: '40px'
								}"
							>
							</ngx-skeleton-loader>
						</td>
						<td class="pb-0 fadeIn">
							<ngx-skeleton-loader
								[theme]="{
									'padding-bottom': '0px',
									'margin-bottom': '0px',
									height: '40px'
								}"
							>
							</ngx-skeleton-loader>
						</td>
						<td class="pb-0 fadeIn">
							<ngx-skeleton-loader
								[theme]="{
									'padding-bottom': '0px',
									'margin-bottom': '0px',
									height: '40px'
								}"
							>
							</ngx-skeleton-loader>
						</td>
					</tr>
				</ng-template>
			} @else {
				<ng-template #rows>
					<tr
						class="opacity-table"
						style="border-bottom: 1px solid #eaeaea; padding-bottom: 15px"
						*ngFor="let item of salaryAdjustmentHistory"
					>
						<td class="color-iron">
							{{ item.dateEffective | sDate: dateFormat.dateShort | async }}
						</td>
						<td class="color-iron text-left">
							{{ language === "th-TH" ? item.name : item.name_EN }}
						</td>
						<td class="color-iron text-right">
							{{ isShowSalary && item.prevAmount != null ? "฿" + (item.prevAmount | number: "1.2-2") : maskAmount }}
						</td>
						<td class="color-iron text-right">
							{{ isShowSalary && item.amount != null ? "฿" + (item.amount | number: "1.2-2") : maskAmount }}
						</td>
						<td class="color-iron text-right">
							{{ isShowSalary && item.percentage != null ? (item.percentage | number: "0.0-2") + "%" : maskAmount }}
						</td>
						<td class="color-iron text-left">
							{{ item.remark }}
						</td>
						<td class="text-left">
							<p class="text-body-strong">
								{{ language === "th-TH" ? item.modifiedBy : item.modifiedBy_EN }}
							</p>
							<p class="text-small">
								{{ item.dateModified | sDate: dateFormat.dateTimeShort | async }}
							</p>
						</td>

						<td class="center" *ngIf="isAllowDelete">
							<em
								id="deleteBTN"
								class="font22 go5-text-color-6 gf-icon-empeo-bin-vector pointer"
								(click)="cancelMovementIncome(item)"
								go5-tooltip-placement="top"
								go5-tooltip-mode="hover"
								go5-tooltip="{{ 'common_delete' | translate }}"
							>
							</em>
						</td>
					</tr>
				</ng-template>
			}
		</go5-table-empeo-basic>
	</div>
</ng-template>

<ng-template #bonusTabWeb>
	<div *ngIf="!isMobile && !isSmallScreen">
		<go5-table-empeo-basic [data]="bonusAdjustmentHistory">
			<ng-template #headers>
				<th style="display: none"></th>
				<th class="th-sm-2 text-left border-top-left-default" style="width: 25%">
					<label for="input">{{ "emp001_t14_date" | translate }}</label>
				</th>
				<th class="th-sm-2 text-right" style="width: 25%">
					<label for="input">{{ "emp001_t14_amount" | translate }}</label>
				</th>
				<th class="th-sm-1 text-center" style="width: 25%">
					<label for="input">{{ "emp001_t14_bonus" | translate }}</label>
				</th>
				<th class="th-sm-1 text-left" style="width: 25%">
					<label for="input">{{ "emp001_t14_create_by" | translate }}</label>
				</th>
			</ng-template>
			<ng-template #rows>
				<tr *ngFor="let item of bonusAdjustmentHistory">
					<td class="text-color-10 text-left">
						{{ item.dateEffective | sDate: dateFormat.dateShort | async }}
					</td>
					<td class="text-color-11 text-right">
						{{ isShowSalary && item.amount != null ? "฿" + (item.amount | number: "1.2-2") : maskAmount }}
					</td>
					<td class="text-color-10 text-center">
						{{
							isShowSalary && item.percentage != null
								? (item.percentage | number: "0.0-2") + " " + ("common_month" | translate)
								: maskAmount
						}}
					</td>
					<td class="text-left">
						<p class="text-body-strong text-color-12">
							{{ language === "th-TH" ? item.modifiedBy : item.modifiedBy_EN }}
						</p>
						<p class="text-small text-color-8">
							{{ item.dateModified | sDate: dateFormat.dateTimeShort | async }}
						</p>
					</td>
				</tr>
			</ng-template>
		</go5-table-empeo-basic>
	</div>
</ng-template>
