import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, first, map } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthCurrentUrlGuardService {
	constructor(
		private authService: AuthService,
		private router: Router,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.authService.canActivateProtectedRoutes$.pipe(
			first((isDone) => isDone === true),
			map(() => {
				const currentSessionUrl = sessionStorage.getItem("stateUrl");
				if (
					currentSessionUrl &&
					currentSessionUrl !== "/" &&
					currentSessionUrl !== "/home" &&
					!currentSessionUrl.includes("token") &&
					!currentSessionUrl.includes("?_gl=") &&
					!currentSessionUrl.includes("?_ga=") &&
					!currentSessionUrl.includes("onboarding") &&
					!currentSessionUrl.includes("?refresh_token")
				) {
					this.router.navigate([currentSessionUrl]);
					return false;
				}
				return true;
			}),
		);
	}
}
