import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { SearchTrainingRequest, TrainingEvent } from "./training.model";
import { CommonService } from "../../../shared/service/common.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TrainingService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
		@Inject("LEARN_API_URL") private apiLearn: string,
	) {}

	getTrainingEventsByEmployeeIdDataTable(parameters: any) {
		return this.http.post<TrainingEvent[]>(
			this.apiUrl + "Training/GetTrainingEventsByEmployeeIdDataTable",
			parameters,
			{ params: parameters },
		);
	}

	getTrainingEventById(trainingEventId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Training/GetTrainingEventById/" + trainingEventId)).then(
			(res) => <TrainingEvent>res,
		);
	}

	addTrainingEvent(te: TrainingEvent) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(te);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Training/AddTrainingEvent", body, httpOptions);
	}

	editTrainingEvent(te: TrainingEvent) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(te);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Training/EditTrainingEvent", body, httpOptions);
	}

	getTrainingEventDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "Training/GetTrainingEventDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	exportTrainingExcel(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return firstValueFrom(this.http.post(this.apiUrl + "Training/ExportTrainingExcel/", body, httpOptions)).then(
			(r) => <any>r,
		);
	}

	addTrainingRequest(courseDetail: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(courseDetail);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiLearn + "Courses/ManagementTrainingRequest", body, httpOptions);
	}

	getTrainingRequests(model: SearchTrainingRequest) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiLearn + "Courses/GetTrainingRequests", body, httpOptions);
	}

	editTrainingRequest(courseDetail: any) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(courseDetail);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiLearn + "Courses/ManagementTrainingRequest", body, httpOptions);
	}

	getCourseByCourseId(
		courseId: number,
		employeeId: number = 0,
		registerType: number = 0,
		isGetEmployeeAttended: boolean = true,
	) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiLearn +
					`Courses/GetCourseByCourseId/?courseId=${courseId}&employeeId=${employeeId}&registerType=${registerType}&isGetEmployeeAttended=${isGetEmployeeAttended}`,
			),
		);
	}

	getTrainingWorkflowLogs(courseId: number) {
		return firstValueFrom(this.http.get<any>(this.apiLearn + `Courses/GetTrainingWorkflowLogs?courseId=${courseId}`));
	}

	downloadEmployeeTrainingExcel(isNeededData: boolean = false, fileName: string = "") {
		return this.http.get<any>(
			this.apiUrl + `trainings/export-files/${isNeededData}` + (fileName ? `/${fileName}` : ""),
		);
	}

	downloadSkillPositionExcel(isNeededData: boolean = false, fileName: string = "") {
		return this.http.get<any>(this.apiUrl + `position/export-files/${isNeededData}` + (fileName ? `/${fileName}` : ""));
	}

	importTrainingExcel(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "trainings/import-excel", body, httpOptions);
	}

	importSkillPositionExcel(model: any) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "position/import", body, httpOptions);
	}

	getTrainingFileImport() {
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any[]>(this.apiUrl + "trainings/training-files", null, httpOptions);
	}
}
