import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Payment } from "../../../payroll/payment/shared/payment.model";
import { Organization, OrganizationConfig, OrganizationConfigs } from "./organization.model";

@Injectable()
export class OrganizationService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		@Inject("GOFIVE_API_URL") private gofiveApiUrl: string,
	) {}

	getOrganization() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Organization/GetOrganization"));
	}
	getOrganizationNode() {
		return firstValueFrom(this.http.get<any>(`${this.gofiveApiUrl}v1/OrganizationsNode`));
	}
	getSysOrganizationConfig() {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Organization/GetSysOrganizationConfig"));
	}

	editListOfSysOrganizationConfig(config: OrganizationConfigs[]) {
		var body = JSON.stringify(config);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Organization/EditListOfSysOrganizationConfig", body, httpOptions);
	}

	editSysOrganizationConfig(config: OrganizationConfigs) {
		var body = JSON.stringify(config);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Organization/EditSysOrganizationConfig", body, httpOptions);
	}
	updateSEQMasOrganization(model) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Organization/UpdateSEQMasOrganization", body, httpOptions);
	}

	addOrganization(organ: Organization) {
		var body = JSON.stringify(organ);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Organization/AddOrganization", body, httpOptions);
	}

	editOrganization(organ: Organization) {
		var body = JSON.stringify(organ);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Organization/EditOrganization", body, httpOptions);
	}

	deleteOrganization(organizationId: number) {
		var body = JSON.stringify(organizationId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Organization/DeleteOrganization", body, httpOptions);
	}

	decreaseOrganizationLevel(isDelete: boolean) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Tenant/DecreaseOrganizationLevel/" + isDelete));
	}

	getOrganizationConfig() {
		return firstValueFrom(this.http.get<OrganizationConfig>(this.apiUrl + "Organization/GetOrganizationConfig/"));
	}

	getOrganizationChartByEmployeeId(employeeId: number, isDepartment?: boolean) {
		return firstValueFrom(
			this.http.get<any>(
				this.apiUrl + "Organization/GetOrganizationChartByEmployeeId/" + employeeId + "/" + isDepartment,
			),
		);
	}

	getOrganizationChartByCompanyId(companyId: number) {
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "Organization/GetOrganizationChartByCompanyId/" + companyId),
		);
	}

	getOrganizationLevelTwoGroupByBranchNo(model: Payment) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Organization/GetOrganizationLevelTwoGroupByBranchNo", body, httpOptions);
	}

	getOrganizationsByLevelAndWorkingLocation(model: Payment) {
		var body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Organization/GetOrganizationsByLevelAndWorkingLocation", body, httpOptions);
	}

	getOrganizationByCompanyAndLevelExists(
		companyId: number,
		year: number,
		month: number,
		workingLocation: number,
		periodExtendNo: number = 0,
	) {
		return firstValueFrom(
			this.http.get(
				this.apiUrl +
					"Organization/GetOrganizationByCompanyAndLevelExists/" +
					companyId +
					"/" +
					year +
					"/" +
					month +
					"/" +
					workingLocation +
					`&periodExtendNo=${periodExtendNo}`,
			),
		).then((res) => <boolean>res);
	}
	getOrganizationByCompanyId(companyId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Organization/GetOrganizationByCompanyId/" + companyId));
	}

	getOrganizationByLevels(organizationId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "Organization/getOrganizationByLevels/" + organizationId));
	}

	getOrganizationStructure(organizationId: number) {
		return firstValueFrom(this.http.get<any>(this.apiUrl + "Organization/GetOrganizationStructure/" + organizationId));
	}
	getOrganizationByOrganizeId(organizationId: number) {
		return firstValueFrom(
			this.http.get<any>(this.apiUrl + "Organization/GetOrganizationByOrganizeId/" + organizationId),
		);
	}
}
