export class MasterSetup {
	masterSetupId: number;
	groupId: number;
	groupName: string;
	groupName_EN: string;
	code: string;
	value: string;
	name: string;
	name_EN: string;
	description: string;
	description_EN: string;
	isActive: boolean;
	isPayroll: boolean;
	status: string;
	status_EN: string;
	modifiedBy: string;
	dateModified: Date;
	displayName: string;
	dateModified_TH: string;
	dateModified_EN: string;
	systemRegisNo: number;
}

export class MasMasterSetupGroupSearch {
	takeRow: number = 40;
	skipRow: number = 0;
	groupId: number = 0;
	keyword: string = "";
	sorting: string = "";
	status: number = 1;
	statusIds: number[] = [1];
	systemRegisNo: number;
	fieldTypeId: number;
}
