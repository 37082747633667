import { Pipe, PipeTransform } from "@angular/core";
import { DocumentStatus } from "../enum/document-status.enum";
import { ProcessAction } from "../enum/process-action.enum";

@Pipe({
	name: "colorButton",
	standalone: true,
})
export class ColorButtonPipe implements PipeTransform {
	transform(value: any) {
		switch (value) {
			case DocumentStatus.draft:
			case DocumentStatus.waitingApprove:
			case DocumentStatus.waitingRevise:
			case ProcessAction.draftDocument:
				return "button-secondary";
			case ProcessAction.sendDocument:
			case DocumentStatus.approved:
			case ProcessAction.approvedDocument:
				return "button-medium-primary";
			case DocumentStatus.canceled:
			case DocumentStatus.rejected:
			case ProcessAction.canceledDocument:
			case ProcessAction.rejectedDocument:
			case ProcessAction.returnDocument:
				return "button-medium-secondary";
			default:
				return "button-white";
		}
	}
}
