export enum Movement {
	transferCompany = 1002,
	transferAgency = 1003,
	promoteRank = 1004,
	raiseTheSalary = 1005,
	conditions = 1006,
	appoint = 1007,
	resign = 1008,
	unResign = 1009,
	renewal = 1010,
	contain = 1011,
	transferSupervisor = 1012,
	massTransfer = 1013,
}
