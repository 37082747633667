import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FoodCoupon } from "./food-coupon.model";
import { Employee } from "../../../employee-movements/shared/employee-movements.model";

@Injectable()
export class FoodCouponService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	addEmployeeWelfare(emp: Employee[], welfareId: number) {
		var body = JSON.stringify({ employees: emp, welfareId: welfareId });
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Welfare/AddEmployeeCoupon", body, httpOptions);
	}

	editEmployeeCoupon(fc: FoodCoupon) {
		var body = JSON.stringify(fc);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "Welfare/EditEmployeeCoupon", body, httpOptions);
	}
}
