import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../../../shared/service/common.service";
import { DropdownDataItem } from "../../../shared/models/dropdown-data-item.model";
import { BranchDetail, EmployeeSocialSecurity } from "./employee-social-security.model";
import { Dropdown } from "../../../shared/models/dropdown.model";
import { DataTablesResponse } from "../../../core/shared/data-tables-response.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class EmployeeSocialSecurityService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
		private commonService: CommonService,
	) {}

	getEmployeeSocialSecurityByEmployeeId(employeeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "EmployeeSocialSecurity/GetEmployeeSocialSecurityByEmployeeId/" + employeeId),
		).then((res) => <EmployeeSocialSecurity>res);
	}
	getBranchDetailByEmployeeId(employeeId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + `v1/employees/${employeeId}/branch`)).then(
			(res) => <BranchDetail>res,
		);
	}

	addEmployeeSocialSecurity(emp: EmployeeSocialSecurity) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "EmployeeSocialSecurity/AddEmployeeSocialSecurity", body, httpOptions);
	}

	editEmployeeSocialSecurity(emp: EmployeeSocialSecurity) {
		this.commonService.changeDateJSON();
		var body = JSON.stringify(emp);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeSocialSecurity/EditEmployeeSocialSecurity", body, httpOptions);
	}

	deleteEmployeeExperience(employeeExperienceId: number) {
		var body = JSON.stringify(employeeExperienceId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "EmployeeExperience/DeleteEmployeeExperience", body, httpOptions);
	}

	getDDLTypeOfEmployments() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLTypeOfEmployments")).then((res) => <Dropdown[]>res);
	}

	getDDLNationalitySSO() {
		return firstValueFrom(this.http.get(this.apiUrl + "Common/GetDDLNationalitySSO")).then(
			(res) => <DropdownDataItem[]>res,
		);
	}

	getEmployeeSocialSecurity(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "EmployeeSocialSecurity/GetExportSocialSecurity",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}
}
