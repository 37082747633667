import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class ConfirmationEmailService {
	constructor(
		private http: HttpClient,
		@Inject("GOFIVE_API_URL") private gofiveApiUrl: string,
	) {}

	confirmEmail(token: string, userId: string) {
		var body = JSON.stringify({ token: token, userId: userId });

		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.gofiveApiUrl + "v1/confirmation-emails", body, httpOptions);
	}
}
