import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { DataTablesResponse } from "../../core/shared/data-tables-response.model";
import { AssessmentMaster } from "./assessment-master.model";
import { AssessmentTypeModel } from "./assessment-type.model";
import { AssessmentScaleTemplate } from "./assessment-question.model";
import { firstValueFrom } from "rxjs";
@Injectable()
export class AssessmentMasterService {
	constructor(
		private http: HttpClient,
		@Inject("API_URL") private apiUrl: string,
	) {}

	getDDLAssessmentType(parantId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "AssessmentMaster/GetDDLAssessmentType/" + parantId)).then(
			(res) => <AssessmentTypeModel[]>res,
		);
	}

	getDDLConditionPeerReview() {
		return firstValueFrom(this.http.get(this.apiUrl + "AssessmentMaster/GetDDLConditionPeerReview")).then(
			(res) => <AssessmentTypeModel[]>res,
		);
	}

	getAssessmentMasterSetBySetId(setId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + `assessments/master-sets?setId=${setId}`));
	}
	getAssessmentMasterByAssessmentTypeId(assessmentTypeId: number) {
		return firstValueFrom(
			this.http.get(this.apiUrl + "AssessmentMaster/GetAssessmentMasterByAssessmentTypeId/" + assessmentTypeId),
		).then((res) => res);
	}
	getAssessmentMasterByParentId(parentId: number, type: number = null, keyWord = "") {
		const model = {
			parentId: parentId,
			assessmentTypeId: type,
			keyWord: keyWord,
		};
		const body = JSON.stringify(model);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "AssessmentMaster/GetAssessmentMasterByParentId", body, httpOptions);
	}

	getAssessmentMasterById(assessmentId: number) {
		return firstValueFrom(this.http.get(this.apiUrl + "AssessmentMaster/GetAssessmentMasterById/" + assessmentId)).then(
			(res) => <AssessmentMaster>res,
		);
	}

	addAssessmentMaster(assessmentMaster: AssessmentMaster) {
		const body = JSON.stringify(assessmentMaster);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentMaster/AddAssessmentMaster", body, httpOptions);
	}

	getAssessmentMasterDataTable(dataTablesParameters: any) {
		return this.http.post<DataTablesResponse>(
			this.apiUrl + "AssessmentMaster/GetAssessmentMasterDataTable",
			dataTablesParameters,
			{ params: dataTablesParameters },
		);
	}

	editAssessmentMaster(am: AssessmentMaster) {
		const body = JSON.stringify(am);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentMaster/EditAssessmentMaster", body, httpOptions);
	}

	copyAssessmentMaster(am: AssessmentMaster) {
		const body = JSON.stringify(am);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentMaster/CopyAssessmentMaster", body, httpOptions);
	}

	deleteAssessmentMaster(assessmentId: number) {
		const body = JSON.stringify(assessmentId);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "AssessmentMaster/DeleteAssessmentMaster", body, httpOptions);
	}

	getTemplateById(template: AssessmentScaleTemplate) {
		const body = JSON.stringify(template);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post<any>(this.apiUrl + "questions/templates", body, httpOptions);
	}

	getAssessmentTermination(searchModel: any) {
		const body = JSON.stringify(searchModel);
		const httpOptions = {
			headers: new HttpHeaders({ "Content-Type": "application/json" }),
		};
		return this.http.post(this.apiUrl + "movement-termination/assessment", body, httpOptions);
	}
	getAssessmentPercentileRankByPercentileRankId(percentileId: number) {
		return this.http.get(
			this.apiUrl + "AssessmentPercentileRanks/GetAssessmentPercentileRankByPercentileRankId/" + percentileId,
		);
	}
}
