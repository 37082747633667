import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DateFormat } from "@gofive/angular-common";
import { DialogInformationModel, DialogService } from "@gofive/design-system-dialog";
import { ToastHelperService } from "src/app/shared/service/toast-helper.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Subscription, firstValueFrom } from "rxjs";
import { CompanyBranchSearch, MasCompanyBranchs } from "src/app/master/company-profile/shared/company-branch.model";
import { CompanyProfileService } from "src/app/master/company-profile/shared/company-profile.service";
import { ApiResult } from "src/app/shared/models/api-result.model";
import { isNullOrUndefined, SharingService } from "src/app/shared/sharing.service";
import { DataSharingService, UserControlService } from "../../core";
import { MasterTypeEnum } from "../../shared/enum/master-setup.enum";
import { Module } from "../../shared/enum/module.enum";
import { PageType } from "../../shared/enum/page-type.enum";
import { DropdownDataItem } from "../../shared/models/dropdown-data-item.model";
import { Dropdown } from "../../shared/models/dropdown.model";
import { Permission } from "../../shared/models/permission.model";
import { CommonService } from "../../shared/service/common.service";
import { BranchDetail, EmployeeSocialSecurity } from "./shared/employee-social-security.model";
import { EmployeeSocialSecurityService } from "./shared/employee-social-security.service";
import { MasMasterSetupGroupSearch } from "src/app/master/master-setup/shared/master-setup.model";
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from "@angular/forms";
import { EmployeeMovementsService } from "../shared/employee-movements.service";
import { InfoBarType, BaseModule } from "@gofive/design-system-base";
import { TooltipModule } from "@gofive/design-system-tooltip";
import { CalendarModule } from "@gofive/design-system-calendar";
import { DropdownModule } from "@gofive/design-system-dropdown";
import { InputTextModule } from "primeng/inputtext";
import { InputModule } from "@gofive/design-system-input";
import { ButtonModule } from "@gofive/design-system-button";
import { NgClass, NgTemplateOutlet, AsyncPipe } from "@angular/common";
@Component({
	selector: "app-employee-social-security",
	templateUrl: "./employee-social-security.component.html",
	styleUrls: ["./employee-social-security.component.scss"],
	standalone: true,
	imports: [
		NgClass,
		NgTemplateOutlet,
		ButtonModule,
		InputModule,
		BaseModule,
		FormsModule,
		InputTextModule,
		DropdownModule,
		CalendarModule,
		TooltipModule,
		ReactiveFormsModule,
		AsyncPipe,
		TranslateModule,
	],
})
export class EmployeeSocialSecurityComponent implements OnInit {
	@Input() fullTemPlate = true;
	@Input() isHeader = true;
	@Input() footerContent = false;

	public defaultReason = 17001;

	private language$: Subscription;
	private editEmpSSO$: Subscription;
	private subscription: Subscription;

	public fieldsDDL = { text: "name", value: "id" };
	public fieldsDDL_EN = { text: "name_EN", value: "id" };

	public fieldsMasterTypeDDL = { text: "typeName", value: "typeId" };
	public fieldsMasterTypeDDL_EN = { text: "typeNameEn", value: "typeId" };

	public fieldsHospitalDDL = { text: "name", value: "hospitalId" };
	public fieldsHospitalDDL_EN = { text: "name_EN", value: "hospitalId" };

	public fieldsNationalityDDL = { text: "text", value: "value" };
	public fieldsNationalityDDL_EN = { text: "text_EN", value: "value" };

	public hospitalDDL: any[] = [];

	public fieldsBranchSSODDL = { text: "text", value: "id" };
	public fieldsBranchSSODDL_EN = { text: "text_EN", value: "id" };

	public masCompanyBranchs: MasCompanyBranchs = new MasCompanyBranchs();
	public companyBranchSearch: CompanyBranchSearch = new CompanyBranchSearch();
	public companyBranchs: MasCompanyBranchs[] = [];
	public branchSearch: CompanyBranchSearch[] = [];

	//#region Variable
	id: number;
	language: string;
	confirmation: any;
	addConfirmation: any;
	editConfirmation: any;
	deleteConfirmation: any;
	public masMasterSetupGroupSearch: MasMasterSetupGroupSearch = new MasMasterSetupGroupSearch();
	nationalityDDL: DropdownDataItem[];
	numberOfChildDDL: Dropdown[];
	typeCardDDL: Dropdown[] = [];
	typeOfEmploymentDDL: Dropdown[];
	isSoc: boolean;
	isDataValid = false;
	dateReceivedSSO: any;
	isSetSocialSecurityBranch = false;
	isSetKorTor20 = false;
	public branchSSO_DDL = [];
	public employee: any;
	public conditionAgeSOC = 60;

	form: UntypedFormGroup;
	employeeSocialSecurity: EmployeeSocialSecurity = new EmployeeSocialSecurity();
	branchDetail: BranchDetail = new BranchDetail();

	permission: Permission = {
		allowGrant: false,
		allowAdd: false,
		allowEdit: false,
		allowView: false,
		allowDelete: false,
	};
	masterSetupGroupDDL: any[];
	reasonToLeaveDDL: any[];

	//#endregion
	get dateFormat() {
		return DateFormat;
	}
	constructor(
		private fb: UntypedFormBuilder,
		private companyProfileService: CompanyProfileService,
		private employeeSocialSecurityService: EmployeeSocialSecurityService,
		private commonService: CommonService,
		private router: Router,
		private userControlService: UserControlService,
		public data: DataSharingService,
		private translate: TranslateService,
		private _toastHelperService: ToastHelperService,
		private _dialogService: DialogService,
		private employeeMovementsService: EmployeeMovementsService,
		private sharingService: SharingService,
	) {}
	ngOnInit() {
		firstValueFrom(this.userControlService.authorizeControl(Module.EMP001_T09)).then((auth) => {
			this.permission = this.userControlService.validatePermission(PageType.search, auth);
		});
		this.subscription = this.data.currentEmpId.subscribe((data) => {
			this.id = data;
			if (this.id === 0) {
				this.router.navigate(["/employee"]);
			} else {
				this.loadSSO();
				this.loadSocialSecurity();
				this.getDropdownBranchSSO();
				this.getEmployeeById();
			}
		});

		this.form = this.fb.group({
			businessCode: new UntypedFormControl("", Validators.required),
			rate: new UntypedFormControl(0, Validators.required),
		});

		this.language$ = this.data.currentLanguage.subscribe((lang) => {
			this.language = lang;
		});
	}

	get infoBarType() {
		return InfoBarType;
	}

	getBranchDetail() {
		this.employeeSocialSecurityService.getBranchDetailByEmployeeId(this.id).then((res: BranchDetail) => {
			if (res) {
				this.branchDetail = res;
				this.branchDetail.rateBranchStr = res.rateBranch.toFixed(2) + "%";
				if (
					isNullOrUndefined(this.branchDetail.businessCodeBranch) &&
					(isNullOrUndefined(this.branchDetail.rateBranch) || this.branchDetail.rateBranch === 0)
				) {
					this.branchDetail.businessCodeBranch = "";
					this.branchDetail.rateBranchStr = "";
				}
				if (
					!isNullOrUndefined(this.branchDetail.businessCode) &&
					!isNullOrUndefined(this.branchDetail.rate) &&
					this.branchDetail.rate > 0
				) {
					this.branchDetail.rate = Number(this.branchDetail.rate.toFixed(2));
					this.isSetKorTor20 = true;
				} else {
					this.branchDetail.businessCode = "";
					this.branchDetail.rate = null;
				}
			}
		});
	}

	loadSocialSecurity() {
		this.employeeSocialSecurityService.getDDLNationalitySSO().then((res) => {
			this.nationalityDDL = res;
		});
		this.commonService.getMasterTypeByParentId(MasterTypeEnum.typeCardSSO).then((res) => {
			this.typeCardDDL = res.data?.data;
		});
		this.commonService.getMasterTypeByParentId(MasterTypeEnum.TerminationReasonSSO).then((res) => {
			this.reasonToLeaveDDL = res?.data?.data;
		});
		this.employeeSocialSecurityService.getDDLTypeOfEmployments().then((res) => {
			this.typeOfEmploymentDDL = res;
		});
	}

	loadSSO() {
		this.employeeSocialSecurityService.getEmployeeSocialSecurityByEmployeeId(this.id).then((res) => {
			console.log(res);
			this.employeeSocialSecurity = res;
			this.getBranchDetail();
			this.checkSecurityBranch();
			this.dateReceivedSSO = isNullOrUndefined(this.employeeSocialSecurity.dateReceivedSSO)
				? null
				: new Date(this.employeeSocialSecurity.dateReceivedSSO);
			this.isDataValid = this.employeeSocialSecurity.hospitalId == null ? true : false;
			this.onSearchHospital(
				"",
				this.employeeSocialSecurity.hospitalId,
				this.employeeSocialSecurity.hospitalIdTwo,
				this.employeeSocialSecurity.hospitalIdThree,
			);
		});
	}

	checkSecurityBranch() {
		if (isNullOrUndefined(this.employeeSocialSecurity.branchId)) {
			this.isSetSocialSecurityBranch = false;
		} else {
			this.isSetSocialSecurityBranch = true;
		}
	}

	onSearchHospital(search: string, hospitalId = "", hospitalIdTwo = "", hospitalIdThree = "") {
		this.commonService.getHospitalSSODDL(search, hospitalId, hospitalIdTwo, hospitalIdThree).then((res) => {
			this.hospitalDDL = res;
		});
	}

	//#region load data
	loadConfirm() {
		this.confirmation = this.translate.instant("common_confirmation");
		this.addConfirmation = this.translate.instant("common_add_confirmation");
		this.editConfirmation = this.translate.instant("common_edit_confirmation");
		this.deleteConfirmation = this.translate.instant("common_delete_confirmation");
	}
	//#end region

	//#region Destroy unsubscribe
	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
		if (this.language$) this.language$.unsubscribe();
		if (this.editEmpSSO$) this.editEmpSSO$.unsubscribe();
	}
	//#endregion

	//#region action
	onSubmit() {
		this.sharingService.pushGoogleTagManager("Employment_Details_Salary_SocialSecurity_Save");
		if (this.isSetKorTor20) {
			if (!this.form.valid) {
				this.form.markAllAsTouched();
				return this._toastHelperService.validation({
					title: this.translate.instant("common_heads_up"),
					description: this.translate.instant("toast_common_complete_all_fields"),
				});
			}
		}
		this.checkSetCheckbox();
		this.loadConfirm();
		const dialogModel = <DialogInformationModel>{
			title: this.confirmation,
			description: this.addConfirmation,
			imageUrl: "confirmation.png",
			textButtonConfirm: this.translate.instant("common_confirm"),
			textButtonCancel: this.translate.instant("common_cancel"),
		};
		firstValueFrom(this._dialogService.Confirmation(dialogModel)).then((response) => {
			if (response?.confirm) {
				this.mappingEmployeeSocialSecurity();
				if (!this.employeeSocialSecurity?.employeeSocialSecurityId) {
					firstValueFrom(
						this.employeeSocialSecurityService.addEmployeeSocialSecurity(this.employeeSocialSecurity),
					).then((res) => {
						let apiResult = <ApiResult>res;
						if (apiResult.result) {
							this.loadSSO();
						}
						this._toastHelperService.alertApiResult(apiResult);
					});
				} else {
					firstValueFrom(
						this.employeeSocialSecurityService.editEmployeeSocialSecurity(this.employeeSocialSecurity),
					).then((res) => {
						let apiResult = <ApiResult>res;
						if (apiResult.result) {
							this.loadSSO();
						}
						this._toastHelperService.alertApiResult(apiResult);
					});
				}
			}
		});
	}

	mappingEmployeeSocialSecurity() {
		this.employeeSocialSecurity.dateReceivedSSO = this.dateReceivedSSO ? new Date(this.dateReceivedSSO) : null;
		this.employeeSocialSecurity.employeeId = this.id;
		this.employeeSocialSecurity.socialSecurityNo = isNullOrUndefined(this.employeeSocialSecurity.socialSecurityNo)
			? ""
			: this.employeeSocialSecurity.socialSecurityNo;
		this.employeeSocialSecurity.isSoc = isNullOrUndefined(this.employeeSocialSecurity.isSoc)
			? false
			: this.employeeSocialSecurity.isSoc;
	}
	//#end region

	//#region clear

	updateIsSoc() {
		this.employeeSocialSecurity.isSoc = !this.employeeSocialSecurity.isSoc;
	}

	//#end region

	onSelectCardType(e) {
		this.employeeSocialSecurity.typeCardSSO = e.selectedItem[this.fieldsMasterTypeDDL.value];
	}
	clearCardType() {
		this.employeeSocialSecurity.typeCardSSO = null;
	}

	onSelectHospital(e, hospitalKey) {
		const hospitalIdKey = `${hospitalKey}`;
		if (this.employeeSocialSecurity.hasOwnProperty(hospitalIdKey)) {
			this.employeeSocialSecurity[hospitalIdKey] = e.selectedItem[this.fieldsHospitalDDL.value];
		}
		this.onSearchHospital(
			"",
			this.employeeSocialSecurity.hospitalId,
			this.employeeSocialSecurity.hospitalIdTwo,
			this.employeeSocialSecurity.hospitalIdThree,
		);
	}
	clearHospital(hospitalKey) {
		const hospitalIdKey = `hospitalId${hospitalKey}`;
		if (this.employeeSocialSecurity.hasOwnProperty(hospitalIdKey)) {
			this.employeeSocialSecurity[hospitalIdKey] = null;
		}
		this.onSearchHospital(
			"",
			this.employeeSocialSecurity.hospitalId,
			this.employeeSocialSecurity.hospitalIdTwo,
			this.employeeSocialSecurity.hospitalIdThree,
		);
	}
	onFilterHospital(e) {
		this.onSearchHospital(e.text);
	}
	changRemark(e) {
		this.employeeSocialSecurity.evidenceOfRegistrationSSO = e.value;
	}
	onSelectNationality(e) {
		this.employeeSocialSecurity.nationalitySSOId = e.selectedItem[this.fieldsNationalityDDL.value];
	}
	clearNationality() {
		this.employeeSocialSecurity.nationalitySSOId = null;
	}

	backToProfile() {
		this.sharingService.pushGoogleTagManager("Employment_Details_Salary_SocialSecurity_Cancel");
		if (this.isHeader) {
			this.data.setEmpId(this.id);
			this.router.navigate([`/employee/${this.id}`]);
		}
	}
	getDropdownBranchSSO() {
		firstValueFrom(this.companyProfileService.getCompanyBranchsDDL(0, this.id, true)).then((res) => {
			this.branchSSO_DDL = res;
		});
	}

	getEmployeeById() {
		this.employeeMovementsService.getEmployeeLiteById(this.id).then((res) => {
			this.employee = res?.data;
		});
	}

	selectedBranchId(e) {
		this.employeeSocialSecurity.branchId = e.selectValue;
	}

	clearBranchSSO() {
		this.employeeSocialSecurity.branchId = null;
	}

	onSelectReason(event) {
		this.employeeSocialSecurity.terminationReasonSSOId = event.value;
	}
	clearReason(event) {
		this.employeeSocialSecurity.terminationReasonSSOId = this.defaultReason;
	}

	onCheckDataEmployeeSocialSecurity() {
		return (
			isNullOrUndefined(this.employeeSocialSecurity.socialSecurityNo) ||
			this.employeeSocialSecurity.socialSecurityNo === "" ||
			(this.isSetSocialSecurityBranch === true && isNullOrUndefined(this.employeeSocialSecurity.branchId))
		);
	}

	onChangeSecurityBranch() {
		this.isSetSocialSecurityBranch = !this.isSetSocialSecurityBranch;
		if (!this.isSetSocialSecurityBranch) {
			this.clearBranchSSO();
		}
	}

	clearKorTor20() {
		this.branchDetail.businessCode = null;
		this.branchDetail.rate = null;
	}

	onChangeKorTor20() {
		this.isSetKorTor20 = !this.isSetKorTor20;
		if (!this.isSetKorTor20) {
			this.clearKorTor20();
		}
	}

	checkSetCheckbox() {
		if (!this.isSetKorTor20) {
			this.employeeSocialSecurity.businessCode = null;
			this.employeeSocialSecurity.rate = null;
		} else if (this.isSetKorTor20) {
			this.employeeSocialSecurity.businessCode = this.branchDetail.businessCode;
			this.employeeSocialSecurity.rate = this.branchDetail.rate;
		}
		if (!this.isSetSocialSecurityBranch) {
			this.clearBranchSSO();
		}
	}
}
