import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiUrl } from "../shared/enum/app-config.enum";
import { MasterService } from "../shared/service/master.service";
import { DataTablesResponse } from "../core/shared/data-tables-response.model";
import { TimeSheetModel } from "./time-sheet.model";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TimeSheetService extends MasterService {
	constructor(
		public http: HttpClient,
		@Inject("API_URL_LST") public apiUrl: string[],
	) {
		super(http, apiUrl);
	}
	editTaskTimeSheet(model: any) {
		return firstValueFrom(this.post("TimeSheet/EditTaskTimeSheet", model));
	}
	addTaskTimeSheet(model: any) {
		return firstValueFrom(this.post("TimeSheet/AddTaskTimeSheet", model));
	}
	getTaskTimeSheet(dataTablesParameters: any) {
		return this.post<DataTablesResponse>("TimeSheet/GetTaskTimeSheetDataTable", dataTablesParameters, ApiUrl.empeo);
	}
	deleteTaskTimeSheet(model: any) {
		return firstValueFrom(this.post("TimeSheet/DeleteTaskTimeSheet", model));
	}
	addProjectTimeSheet(model: any) {
		return firstValueFrom(this.post("TimeSheet/AddProjectTimeSheet", model));
	}
	getTimeSheetProjectDataTable(dataTablesParameters: any) {
		return this.post<DataTablesResponse>("TimeSheet/GetTimeSheetProjectDataTable", dataTablesParameters, ApiUrl.empeo);
	}
	deleteProjectByProjectId(model: any) {
		return firstValueFrom(this.post("TimeSheet/DeleteProjectByProjectId", model));
	}
	editTimeSheetProject(model) {
		return firstValueFrom(this.post("TimeSheet/EditTimeSheetProject", model));
	}
	dashboard(model) {
		return this.post("Report/GetReportTimeSheet", model, ApiUrl.empeo);
	}
	getTimesheets(model) {
		return this.post<TimeSheetModel>("TimeSheet/GetTimesheets", model);
	}
	updateTimesheet(model) {
		return this.post("TimeSheet/UpdateTimesheet", model);
	}
	deleteTimesheet(model) {
		return this.delete("TimeSheet/DeleteTimesheets", model, ApiUrl.empeo);
	}
	getTimeSheetProject() {
		return this.get("TimeSheet/GetTimeSheetProject");
	}
	getTimeSheetTask() {
		return this.get("TimeSheet/GetTimeSheetTask");
	}
}
