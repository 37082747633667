export class AssessmentScore {
	scoreId: number;
	no: number;
	masterSetId: number;
	assessorEmployeeId: number;
	assessorFullName: string;
	assessorFullName_EN: string;
	assessorPositionName: string;
	assessorPositionName_EN: string;
	assessorPictureURL: string;
	assessorRankNo: string;
	assesseeEmployeeId: number;
	assesseeFullName: string;
	assesseeFullName_EN: string;
	assesseePositionName: string;
	assesseePositionName_EN: string;
	assesseePictureURL: string;
	assesseeRankNo: string;
	assessmentTypeId: number;
	idCardNo: string;
	totalScore: number;
	result: string;
	dateTimeStart: Date;
	dateTimeEnd: Date;
	dateTimeReviseEnd: Date;
	emailAddress: string;
}

export class UpdateDateStartEndModel {
	masterSetId?: number;
	assessmentSetId?: number;
	scoreId?: number;
	no?: number;
	dateStart: Date;
	dateEnd: Date;
	dateEffective: Date;
	dateTimeReviseEnd: Date;
	dateDisclosure: Date;
	dateReviewStart: Date;
	dateReviewEnd: Date;
	isCal?: boolean;
	okrSetId?: number;
	isAssessmentOKR?: boolean;
	assessmentTypeId?: number;
	dateRoundStart: Date;
	dateRoundEnd: Date;
	isSalaryAdjustment?: boolean;
	approvalEmployeeId?: number;
	dateEffectiveMovement: Date;
}
